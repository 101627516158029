import * as React from "react"
import styles from "./Navbar.module.css" 
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box" 
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton" 
import Toolbar from "@mui/material/Toolbar" 
import Button from "@mui/material/Button"
import Header from "../Header/Header"
import { Badge, Menu, MenuItem, Grid, Container } from "@mui/material"
import { Link, useLocation, useNavigate } from "react-router-dom"
import homeImage from "../../../constant"
import { useDispatch, useSelector } from "react-redux"
import { setActiveStatus } from "../../../State/Features/user/activetabSlice"
import { removeUser } from "../../../State/Features/common/userSlice"
import { setLoader } from "../../../State/Features/common/loaderSlice"
import { useEffect } from "react"
import api from "../../../State/api/Api"
import { useState } from "react"
import {
  fetchArtistTypes,
  fetchCategoryData,
  fetchFetchAllcart,
  fetchProductType,
  fetchUserArtist,
} from "../../../State/api/userApi"
import CircularLoader from "../../../common/loader/Loader"
import {
  setArtistFilterNav,
  setNoFilter,
  setStyleFilterNav,
} from "../../../State/Features/user/filterationSlice"
import axios from "axios"
import { Errornotify, Successnotify } from "../../../common/alert/Alert"
import {
  handleFetchStyle,
  getAllMenuSubMenu,
} from "../../../State/api/superadminApi"
import Webpopup from "../../../gallery/components/popups/galleryWeb/Webpopup"
import CloseIcon from "@mui/icons-material/Close"
import capitalizeWords from "../../../utils/capitalizeContent"
import { FiSearch, FiShoppingBag, FiMenu, FiUser, FiChevronDown, FiChevronUp } from "react-icons/fi";
import TranslateWrapper from "../../../languageTranslator/TranslateWrapper";
import { useTranslation } from 'react-i18next';
const drawerWidth = 280

function Navbar(props) {
  const { windows } = props
  const location = useLocation()
  const { t } = useTranslation()
  const [isSubMenuHas, setIsSubMenuHas] = React.useState(false)
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [submenu, setSubmenu] = React.useState({})
  const [rsubmenu, setRsubmenu] = React.useState({
    cart: false,
  })
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorElSearch, setAnchorElSearch] = React.useState(null)
  const [name, setName] = React.useState("")
  const [condition, setCondition] = useState({
    country: null,
    orientation: null,
    color: null,
  })
  const handleOnclickRightMaintab = (url) => {
    if (url.includes("https")) {
      window.open(url, "_blank")
    } else {
    }
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const auctionstatus = useSelector((state) => state.activetab)
  const usercart = useSelector((state) => state.mycartData.data)
  const usertype = useSelector((state) => state.user.data)
  const loader = useSelector((state) => state.loader)
  const productType = useSelector((state) => state.productType)
  const artistTypes = useSelector((state) => state.artistTypedata)

  const getAllMenuSubMenudata = useSelector(
    (state) => state.getAllMenuSubMenudata.data
  )

  const userartists = useSelector((state) => state.userartistData)
  const allcategory = useSelector((state) => state.allcategoryData)
  const filteration = useSelector((state) => state.filteration)
  const activetab = useSelector((state) => state.activetab)
  const user = useSelector((state) => state.user.data)
  const open = Boolean(anchorEl)
  const openSearch = Boolean(anchorElSearch)
  const [signPop, setSignPop] = useState(true)

  const [subject, setSubject] = useState([])
  const [medium, setMedium] = useState([])
  const [style, setStyle] = useState({ style: "" })

  const fetchSubjects = async (id) => {
    dispatch(setLoader(true))
    try {
      const response = await api.applicationApi(
        "api/itemSubject/fetchItemSubjects",
        "POST",
        { category_id: id }
      )
      if (response.status === 200) {
        setSubject(response.data.data)
        dispatch(setLoader(false))
      }
    } catch (error) {
    }
  }
  const fetchMedium = async (id) => {
    dispatch(setLoader(true))
    try {
      const response = await api.applicationApi(
        "api/itemMedium/fetchItemMediums",
        "POST",
        { category_id: id }
      )
      if (response.status === 200) {
        setMedium(response.data.data)
        dispatch(setLoader(false))
      }
    } catch (error) {
    }
  }
  useEffect(() => {
    if (filteration.style.length === 0) {
      dispatch(setActiveStatus({ status: "style_all", maintab: "" }))
    }
  }, [filteration])
  const handleFetchCondition = async function (id) {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(`api/user/getcountry`)
      const orientationResult = await api.applicationApi(
        `api/user/getorientation`
      )
      const colorResult = await api.applicationApi(`api/user/getcolors`)
      // const styleResult = await api.applicationApi(
      //   `api/itemStyle/fetchItemStyles`,
      //   "POST",
      //   { category_id: id }
      // );
      if (result && result.status === 200) {
        setCondition({
          ...condition,
          country: result.data.response,
          orientation: orientationResult.data.response,
          color: colorResult.data.response,
        })
        dispatch(setLoader(false))
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  }
  const fetchStyle = (id) => {
    dispatch(handleFetchStyle({ category_id: id }, setStyle))
  }

  // heading menu--
  const fetchHeadingMenuData = () => {
    dispatch(getAllMenuSubMenu())
  }
  React.useEffect(() => {
    fetchHeadingMenuData()
  }, [])

  const handleFetchOrientation = async function () {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(`api/user/getorientation`)
      if (result && result.status === 200) {
        setCondition({ ...condition, orientation: result.data.response })
        dispatch(setLoader(false))
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  }
  const handleFetchColor = async function () {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(`api/user/getcolors`)
      if (result && result.status === 200) {
        setCondition({ ...condition, color: result.data.response })
        dispatch(setLoader(false))
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  }
  useEffect(() => {
    handleFetchCondition()
    dispatch(fetchProductType())
    dispatch(fetchArtistTypes())
    dispatch(fetchUserArtist({}))
    dispatch(fetchCategoryData({}))
  }, [])
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const handleHoverOnProfile = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleOnclickProfile = (val) => {
    setAnchorEl(null)
    if (val === "apply") {
      navigate("/applynow")
    } else {
      navigate("/dashboard")
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleCloseSearch = () => {
    setAnchorElSearch(null)
  }

  useEffect(() => {
    if (getAllMenuSubMenudata) {
      const initialSubmenuState = getAllMenuSubMenudata.reduce((acc, item) => {
        acc[item.menu?._id] = false
        return acc
      }, {})
      setSubmenu(initialSubmenuState)
    }
  }, [getAllMenuSubMenudata])

  const handleLeftHover = (e) => {
    const id = e.target.getAttribute("data-id");
  
    if (!id) {
      setSubmenu({});
      return;
    }
  
    // Ensure the category exists
    const nav_cat = allcategory.data?.allcategories.find(
      (val) => val._id === id
    );
  
    if (nav_cat) {
      fetchSubjects(nav_cat._id);
      fetchMedium(nav_cat._id);
      fetchStyle(nav_cat._id);
    } else {
      setSubject([]);
      setMedium([]);
      setStyle({ style: "" });
    }
  
    // Use functional state update to ensure correct state updates
    setSubmenu((prevState) => {
      const updatedSubmenu = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false; // Reset all submenu states to false
        return acc;
      }, {});
  
      return { ...updatedSubmenu, [id]: true }; // Activate hovered menu item
    });
  };
  

  // Ensure that the state of the submenu is updated only when hovering over the cart button
const handleRightHover = (e) => {
  const name = e.target.getAttribute("name");
  
  // Only set the cart submenu to true if the hovered element is the cart button
  if (name === "cart") {
    setRsubmenu((prevState) => ({
      ...prevState,
      cart: true,
    }));
  }
};


  const handleNavigateAuctionExhibition = (route, status, maintab) => {
    navigate(route)

    // Dynamically set all submenu IDs to false
    if (getAllMenuSubMenudata) {
      const updatedSubmenuState = getAllMenuSubMenudata.reduce((acc, item) => {
        acc[item.menu?._id] = false
        return acc
      }, {})
      setSubmenu(updatedSubmenuState)
    }

    dispatch(setActiveStatus({ status: status, maintab: maintab }))
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const handleSignoutbtn = () => {
    localStorage.removeItem("login_token")
    localStorage.removeItem("artsmiley-backtrack-url")
    localStorage.removeItem("usertype")
    localStorage.removeItem("user_id")
    localStorage.removeItem("user_type_id")
    localStorage.removeItem("password")
    dispatch(
      removeUser(
        {
          signed: false,
          type: "",
          data: "",
        },
        navigate("/")
      )
    )
    window.location.reload()
  }
  const [data, setData] = useState(1);
  const userty = useSelector((state) => state.user.data);
  const container =
    windows !== undefined ? () => windows().document.body : undefined

  const filteredSubmenus = getAllMenuSubMenudata
    ?.filter((menuItem) => submenu[menuItem?.menu?._id])
    .map((menuItem) => menuItem)

  //
  const handleNavigateCategory = (id) => {
    const searchParams = new URLSearchParams()
    searchParams.set("category", id.toLowerCase())

    const newSearch = searchParams.toString()
      ? `?${decodeURIComponent(searchParams.toString())}`
      : ""

    navigate(`/user/search${newSearch}`, { replace: true })
  }

  const handleRemoveItemcart = async function (ids) {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(
        `api/user/removeFromCart/${ids}`,
        "DELETE"
      );
      if (result.status === 200 || result.status === 201) {
        dispatch(setLoader(false));
        userty.signed === true &&
          dispatch(
            fetchFetchAllcart({
              offset: data,
              user_id: localStorage.getItem("user_id"),
            })
          );
        dispatch(
          fetchFetchAllcart({
            offset: 1,
            user_id: localStorage.getItem("user_id"),
          })
        );
        Successnotify(result.data.message);
      } else if (result.status === 400) {
        Errornotify(result.data.message);
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };
  //

  const [activeCategory, setActiveCategory] = useState(null);

  const handleCategoryClick = (item) => {
    // Toggle submenu: If clicked again, close it; otherwise, open it
    setActiveCategory(activeCategory === item.menu._id ? null : item.menu._id);
  };
  
  return (
    <>
      {/* <Header /> */}
      {signPop ? <Webpopup /> : ""}
      <Box
        className={styles.nav_main}
        style={{
          padding: "0px",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          margin: "auto",
        }}
      >
        <AppBar
          className={styles.nav_mains}
          style={{
            padding: "0px",
            align: "center",
            backgroundColor: "white",
            color: "#636365",
            boxShadow: "none",
            marginLeft: "100px",
          }}
          component="nav"
        >
          <div >
            <div className={styles.mobileviewtop}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
              <Grid container spacing={2} sx={{alignItems:"center"}}>
                 <Grid item xs={4}>
                 <div className={styles.navLeft}>
                <FiMenu onClick={handleDrawerToggle} className={styles.menuicn} />
                
              </div>
                 </Grid>
                 <Grid item xs={4}>
                 <div><Link
                onClick={() => navigate("/")}
                className={styles.logo_smll}
              > 
                <img
               src="/img/smlogo.png"
                  className="img-fluid"
                  alt="img"
                />
              </Link></div>
                 </Grid>
                 <Grid item xs={4}>
                 <div className={styles.navRight}>
                <div className={styles.profilebtn}>
                  {user.signed && localStorage.getItem("login_token") ? (
                    <>
                    <button  aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleHoverOnProfile}>
                    <FiUser className={styles.rficon} />
                    </button>
                     

                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        sx={{ left: "-35px", top: "5px" }}
                      >
                        {localStorage.getItem("usertype") === "buyer" ? (
                          <MenuItem
                            onClick={() => handleOnclickProfile("profile")}
                          >
                            {t('profile')}
                          </MenuItem>
                        ) : localStorage.getItem("is_accepted") ===
                          "Pending" ? (
                          <MenuItem
                            onClick={() => handleOnclickProfile("apply")}
                          >
                            {t('apply')}
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={() => handleOnclickProfile("profile")}
                          >
                            {t('profile')}
                          </MenuItem>
                        )}
                        <MenuItem onClick={handleSignoutbtn}>{t('log_out')}</MenuItem>
                      </Menu>
                    </>
                  ) : (
                    <>
                     <button onClick={() =>navigate("/login",{state: {type: "buyer"}})} className={styles.signup_btn}>
              <FiUser className={styles.rficon} />
              </button>
                    </>
                  )}
                </div>
                <button onClick={() => navigate("/user/search")}>
                <FiSearch className={styles.rficon} />
                  
                </button>
                <button
                  name="cart"
                  onClick={() => navigate("/user/mycart")}
                  // onMouseEnter={handleRightHover}
                  sx={{ minWidth: 0 }}
                >
                  <Badge
                    name="cart"
                    // onMouseEnter={handleRightHover}
                    badgeContent={usercart && usercart.result.length}
                    color="primary"
                    sx={{ ml: "-2" }}
                  >
                    {/* <LocalMallOutlinedIcon
                      
                      name="cart"
                    />
                    // onMouseEnter={handleRightHover} */}
                    <FiShoppingBag name="cart" className={styles.rficon} />

                    {/* <img
                  src={ "/img/Cart.png"}
                  height="20px"
                  width="20px"
                  style={{ margin: "0px 10px" }}
                /> */}
                  </Badge>
                </button>
              </div>
                 </Grid>
              </Grid>
            </Container>
            </div>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
              <div className={styles.button_box}>
              <div
                onClick={() => navigate("/")}
                className={styles.logo_wrapper}
              >
                <img
               src="/img/updated_logo.png"
                  className={styles.logo_img}
                  alt="img"
                />
              </div>
              <div className={styles.nav_btn_box}>
              <ul className={styles.menulsit}>
                  {getAllMenuSubMenudata?.slice(0, 10).map((item) => (
                  <li>
                  <Link
                    key={item.menu.name}
                    className={styles.nav_btn}
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "17px",
                      lineHeight: "23px",
                      color:
                        item.value === activetab.maintab ? "white" : "#636365",
                      backgroundColor:
                        item.value === activetab.maintab ? "#636365" : "",
                    }}
                    onMouseEnter={(e) => {
                      if (item?.headings?.some((val) => val?.submenus?.length > 0)) {
                        setIsSubMenuHas(true)
                        handleLeftHover(e); // Pass the event object
                      }else {
                        setIsSubMenuHas(false)
                      }
                    }}
                    
                    data-id={
                      item?.headings?.some((val) => val?.submenus?.length)
                        ? item?.menu?._id
                        : null
                    }
                    onClick={() => window.location.href = item?.menu?.link}
                  >
                  
                    {capitalizeWords(item?.menu?.name)}
                    
                  </Link>
                  </li>
                  
                ))}
                  </ul>
                {isSubMenuHas
                  ? filteredSubmenus?.map((menuItem, i) => (
                      <div key={i} className={styles.submenu}>
                        <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md={2}>
                        <div>
                          <img
                            alt="img"
                            src={ menuItem?.menu?.image}
                             className="img-fluid"
                          />
                        </div>
                        </Grid>
                        <Grid item xs={12} sm={9} md={10}>
                        <Grid container spacing={2}>
                        {menuItem?.headings?.map((head, i) => (
                          <Grid item xs={12} sm={2.4} md={2.4}>
<div key={i} className={styles.submenuli}>
                              <h4 className={styles.submenu_header}>
                                {head?.name}
                              </h4>
                              <ul>
                              {head?.submenus?.map((subm, j) => (
                                <li key={j}>
                                  <Link
                                    id="/exhibition"
                                    
                                    name="upcoming"
                                    onClick={() => window.location.href = subm.link}
                                   
                                    
                                    className={
                                      location.pathname.includes(
                                        "exhibition"
                                      ) && auctionstatus.status === "upcoming"
                                        ? styles.submenu_links
                                        : styles.submenu_links
                                    }
                                  >
                                    {subm.name}
                                  </Link>
                                </li>
                              ))}
                              </ul>
                              
                            </div>
                          </Grid>
                            
                          ))}
                        </Grid>
                        </Grid>
                        </Grid>
                        
                        <div className={styles.vertical_line}></div>
                        <>
                          
                        </>
                      </div>
                    ))
                  : ""}



              </div>
<div className={styles.nav_btns_box}>
              <ul className={styles.headderacion}>
    {/* Search button - No submenu will appear */}
    <li>
      <button onClick={() => navigate("/user/search")}>
        <FiSearch className={styles.rficon} />
      </button>
    </li>
    {/* Cart button - Submenu should appear when hovered */}
    <li>
      <button 
        name="cart" 
        className={styles.cartbtn} 
        onMouseEnter={handleRightHover} 
       // onMouseLeave={handleMouseLeave} // Hide submenu when mouse leaves
      >
        <Badge
          name="cart"
          badgeContent={usercart && usercart.result.length}
          color="primary"
          sx={{ ml: "-2" }}
        >
          <FiShoppingBag name="cart" className={styles.rficon} />
        </Badge>
      </button>
    </li>
  </ul>
               
                {rsubmenu.cart ? (
                  <div className={styles.submenu_cart}>
                    {usercart?.result?.map((data) => (
                      <div className={styles.card_row}>
                        <div className={styles.submenu_img_cart}>
                          <img
                            src={` ${data.item_id?.image}`}
                            height="80px"
                            width="80px"
                            style={{ margin: "10px 10px" }} alt=""
                          />
                        </div>
                        <div className={styles.submenu_header_card1}>
                          <article className={styles.submenu_header_cart}>
                            {data.item_id?.name}
                          </article>
                          <article className={styles.submenu_links_cart}>
                            {`${data.item_id?.width}*${data.item_id?.height}*${data.item_id?.depth}cm`}
                          </article>
                        </div>
                        <div className={styles.btn_cart}>
                          <CloseIcon onClick={() => handleRemoveItemcart(data._id)}/>
                        </div>
                      </div>
                    ))}
                    <div className={styles.btn_button}>
                      <Button
                        variant="contained"
                        onClick={() => navigate("user/mycart")}
                      >
                        {t("view_cart")}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => navigate("user/allcategory")}
                      >
                        {t("shop_more")}
                      </Button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div> 
              </div>
            </Container>
          </div>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                margin: "auto",
              },
            }}
          >

<div className="dropdown">
    <ul className={styles.category}>
      {getAllMenuSubMenudata?.slice(0, 10).map((item) => (
        <li key={item.menu._id} className="dropdown-toggle">
          <Link
            className="mincateg"
          >
           <span className={styles.mobilespn} onClick={() => window.location.href = item?.menu?.link}>{item?.menu?.name}</span>
            <FiChevronDown
            onClick={() => handleCategoryClick(item)}
              className={`icon ${activeCategory === item.menu._id ? "rotate" : ""}`}
            />
          </Link>

          {/* Show submenu if this category is active */}
          {activeCategory === item.menu._id && (
             <div className={`submenu ${activeCategory === item.menu._id ? "submenu-open" : ""}`}>
              {item?.headings?.map((head, i) => (
                <div key={i} className={styles.subctry}>
                  <div className={styles.submenuli}>
                    <h5>{head?.name}</h5>
                    <ul>
                      {head?.submenus?.map((subm, j) => (
                        <li key={j}>
                          <a href={subm.link} className={styles.submenu_links}>
                            {subm.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          )}
        </li>
      ))}
    </ul>
  </div>
            {/* drawer */}
          </Drawer>
        </Box>
        {/* <Box component="main" sx={{ p: 3 }}>
          <Toolbar />
          <Typography></Typography>
        </Box> */}
      </Box>
    </>
  )
}

export default Navbar
