import {
  Button,
  Container,
  createTheme,
  Grid,
  Modal,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./RentDetails.module.css";
// import "fontsource-marcellus";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ProdDetsOther from "./ProdDetsOther";
import ProdDetsqna from "./ProdDetsqna";
import PolicySidebar from "./PolicySidebar";
import { Box } from "@mui/system";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import homeImage from "../../../constant";
import { useSelector } from "react-redux";

const theme = createTheme({
  palette: {
    primary: {
      light: "#C1C1C1",
      main: "#000000",
      dark: "#C1C1C1",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffffff",
      main: "#C1C1C1",
      dark: "#ffffff",
      contrastText: "#000",
    },
  },
  typography: {
    prodheading: {
      fontFamily: "Marcellus",
      fontSize: "2.5rem",
    },
  },
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  borderRadius: "3px",
  boxShadow: 24,
};
const cardDetails = [
  {
    id: 1,
    imgUrl: "/img/Rectangle 368.png",
  },
  {
    id: 2,
    imgUrl: "/img/Rectangle 369.png",
  },
  {
    id: 3,
    imgUrl: "/img/Rectangle 370.png",
  },
  {
    id: 4,
    imgUrl: "/img/Rectangle 5199.png",
  },
];
function RentDetails() {
  const [openquestion, setOpenQuestion] = React.useState(false);
  const handleOpenQuestion = () => setOpenQuestion(true);
  const handleClose = () => setOpenQuestion(false);

  const [productdetails, setProductdetails] = useState();
  const allitemdt = useSelector((state) => state.allitemData);
  const artist_id =
    productdetails &&
    productdetails.artwork.map((vl) => {
      return vl.artist_id._id;
    });
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.productdetails}>
        <div className={styles.proddets__top}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <div className={styles.prod__img}>
                  <img
                    className={styles.main__img}
                    src={"/img/Rectangle 5269.png"}
                  />
                  <br />
                  <div className={styles.previewimg__div}>
                    <div className={styles.underimg__div}>
                      <img
                        className={styles.clickImage}
                        src={"/img/rent 1 (Traced).png"}
                      />
                      <Typography fontSize="0.9rem">Rental</Typography>
                      <img
                        className={styles.downarrow}
                        src={"/img/image 8 (Traced).png"}
                      />
                    </div>
                    <div className={styles.underimg__div}>
                      <img
                        className={styles.clickImage}
                        src={"/img/purchase 1 (Traced).png"}
                      />
                      <Typography
                        fontSize="0.85rem"
                        sx={{ textAlign: "center" }}
                      >
                        14 days free returns
                      </Typography>
                      <img
                        className={styles.downarrow}
                        src={"/img/image 8 (Traced).png"}
                      />
                    </div>
                    <div className={styles.underimg__div}>
                      <img
                        className={styles.clickImage}
                        src={"/img/delivery-truck 1 (Traced).png"}
                      />
                      <Typography
                        fontSize="0.85rem"
                        sx={{ textAlign: "center" }}
                      >
                        Free Shipping
                      </Typography>
                      <img
                        className={styles.downarrow}
                        src={"/img/image 8 (Traced).png"}
                      />
                    </div>
                    <div className={styles.underimg__div}>
                      <img
                        className={styles.clickImage}
                        src={"/img/support 1 (Traced).png"}
                      />
                      <Typography
                        fontSize="0.9rem"
                        sx={{ textAlign: "center" }}
                      >
                        Need Some Help?
                      </Typography>
                      <img
                        className={styles.downarrow}
                        src={"/img/image 8 (Traced).png"}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className={styles.prodDetails}>
                  <Typography variant="prodheading">
                    See The Light Of Sea
                  </Typography>
                  <a href="#">
                    <Typography
                      fontSize="0.8rem"
                      ml={0.5}
                      mt={2}
                      sx={{ color: "#636365" }}
                    >
                      by Acrymx, France -2022
                    </Typography>
                  </a>
                  <div className={styles.pricing__section}>
                    <Typography
                      mt={1}
                      ml={0.5}
                      fontWeight="500"
                      fontSize="2rem"
                    >
                      $ 20,2500
                    </Typography>
                    <Typography ml={2} mt={1} color="#2FAC4B" fontSize="0.9rem">
                      In Stock
                    </Typography>
                    <div className={styles.ellipseshare}>
                      <img
                        className={styles.vectorimages}
                        src={"/img/Vector.png"}
                      />
                    </div>
                    <div className={styles.ellipseheart}>
                      <img
                        className={styles.vectorheart}
                        src={"/img/Vector-heart.png"}
                      />
                    </div>
                  </div>
                  <div className={styles.ratings__reactions}>
                    <img
                      className={styles.starrating}
                      src={"/img/Gold Star.png"}
                    />
                    <img
                      className={styles.starrating}
                      src={"/img/Gold Star.png"}
                    />
                    <img
                      className={styles.starrating}
                      src={"/img/Gold Star.png"}
                    />
                    <img
                      className={styles.starrating}
                      src={"/img/Silver Star.png"}
                    />
                    <img
                      className={styles.starrating}
                      src={"/img/Silver Star.png"}
                    />
                    <ThumbUpAltOutlinedIcon
                      className={styles.likeIcon}
                      fontSize="small"
                    />
                    <Typography
                      mt={1}
                      ml={0.5}
                      fontWeight="400"
                      fontSize="0.85rem"
                    >
                      255
                    </Typography>
                    <RemoveRedEyeOutlinedIcon className={styles.viewIcon} />
                    <Typography
                      mt={0.95}
                      ml={0.5}
                      fontWeight="400"
                      fontSize="0.85rem"
                    >
                      30
                    </Typography>
                  </div>
                  <div className={styles.proddets_dets}>
                    <div>
                      <Typography
                        fontWeight="600"
                        fontSize="0.85rem"
                        color="#1C1C1C"
                      >
                        Original Artwork
                      </Typography>
                    </div>
                    <div className={styles.greydescr}>
                      <Typography fontWeight="400" fontSize="0.85rem">
                        Painting, Acrylic on Canvas
                      </Typography>
                    </div>
                  </div>
                  <hr className={styles.dets__divider} />
                  <div className={styles.proddets_dets}>
                    <div>
                      <Typography
                        fontWeight="600"
                        fontSize="0.85rem"
                        color="#1C1C1C"
                      >
                        Dimension (H * W) Cm
                      </Typography>
                    </div>
                    <div className={styles.greydescr}>
                      <Typography fontWeight="400" fontSize="0.85rem">
                        97x130 cm
                      </Typography>
                    </div>
                  </div>
                  <hr className={styles.dets__divider} />
                  <div className={styles.proddets_dets}>
                    <div>
                      <Typography
                        fontWeight="600"
                        fontSize="0.85rem"
                        color="#1C1C1C"
                      >
                        Rent
                      </Typography>
                    </div>
                    <div className={styles.greydescr}>
                      <Typography fontWeight="400" fontSize="0.85rem">
                        Per Month
                      </Typography>
                    </div>
                  </div>
                  <hr className={styles.dets__divider} />
                  <div className={styles.proddets_dets}>
                    <div>
                      <Typography
                        fontWeight="600"
                        fontSize="0.85rem"
                        color="#1C1C1C"
                      >
                        Damage Waiver
                      </Typography>
                    </div>
                    <div className={styles.greydescr}>
                      <div className={styles.remove}>
                        <a href="#">
                          <Typography color="black" fontSize="0.75rem">
                            -Remove
                          </Typography>
                        </a>
                        <Typography ml={1} fontWeight="400" fontSize="0.85rem">
                          $250
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <hr className={styles.dets__divider} />
                  <div className={styles.proddets_dets}>
                    <div>
                      <Typography
                        fontWeight="600"
                        fontSize="0.85rem"
                        color="#1C1C1C"
                      >
                        QTY
                      </Typography>
                    </div>
                    <div className={styles.greydescr}>
                      <Typography fontWeight="400" fontSize="0.85rem">
                        1
                      </Typography>
                    </div>
                  </div>
                  <Typography fontSize="0.8rem" fontWeight="600" mt={2}>
                    Ships from United Kindgdom on or before Friday, 20 May 2022
                  </Typography>
                  <Typography fontSize="0.9rem" fontWeight="600" mt={2}>
                    Total
                  </Typography>
                  <hr className={styles.dets__divider} />
                  <div className={styles.proddets_dets}>
                    <div>
                      <Typography
                        fontWeight="600"
                        fontSize="0.85rem"
                        color="#1C1C1C"
                      >
                        Sub Total
                      </Typography>
                    </div>
                    <div className={styles.greydescr}>
                      <Typography fontWeight="600" fontSize="0.95rem">
                        $32.00
                      </Typography>
                    </div>
                  </div>
                  <hr className={styles.dets__divider} />
                  <div className={styles.proddets_dets}>
                    <div>
                      <Typography
                        fontWeight="600"
                        fontSize="0.85rem"
                        color="#1C1C1C"
                      >
                        Shipping
                      </Typography>
                      <Typography fontSize="0.75rem" ml={0.5}>
                        Free Shipping
                      </Typography>
                    </div>
                    <div className={styles.greydescr}>
                      <Typography fontWeight="600" fontSize="0.95rem">
                        $00.00
                      </Typography>
                    </div>
                  </div>
                  <div className={styles.proddets_dets}>
                    <div>
                      <Typography
                        fontWeight="600"
                        fontSize="0.85rem"
                        color="#1C1C1C"
                      >
                        Packaging
                      </Typography>
                      <Typography fontSize="0.75rem" ml={0.5}>
                        Free Basic Packaging
                      </Typography>
                    </div>
                    <div className={styles.greydescr}>
                      <Typography fontWeight="600" fontSize="0.95rem">
                        $00.00
                      </Typography>
                    </div>
                  </div>
                  <hr className={styles.dets__divider} />
                  <div className={styles.proddets_dets}>
                    <div>
                      <Typography
                        fontWeight="600"
                        fontSize="0.85rem"
                        color="#1C1C1C"
                      >
                        Total
                      </Typography>
                    </div>
                    <div className={styles.greydescr}>
                      <Typography fontWeight="600" fontSize="1rem">
                        $32.00
                      </Typography>
                    </div>
                  </div>
                  <hr className={styles.dets__divider} />
                  <Button
                    onClick={handleOpenQuestion}
                    sx={{
                      textTransform: "none",
                      color: "white",
                      backgroundColor: "black",
                      width: "82.5%",
                      marginTop: 1,
                      "&.MuiButtonBase-root:hover": { bgcolor: "black" },
                      borderRadius: "8px",
                      height: "3rem",
                    }}
                  >
                    Continue to Checkout
                  </Button>
                  <Modal
                    open={openquestion}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <img
                        className={styles.modalclosebutton}
                        onClick={handleClose}
                        src={"/img/image 86.png"}
                      />
                      <Typography ml={24.5} fontWeight="600" fontSize="1.25rem">
                        Make an Offer
                      </Typography>
                      <Typography mt={3} ml={4} fontSize="0.9rem">
                        Email
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Enter Email"
                        size="small"
                        sx={{
                          backgroundColor: "#F3F3F3",
                          width: "87%",
                          marginLeft: "6%",
                          border: "none",
                        }}
                      />

                      <div className={styles.first__lastname}>
                        <div>
                          <Typography mt={3} ml={0.7} fontSize="0.9rem">
                            First Name
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter Name"
                            size="small"
                            sx={{
                              backgroundColor: "#F3F3F3",
                              width: "100%",
                              marginLeft: "3%",
                              border: "none",
                            }}
                          />
                        </div>
                        <div>
                          <Typography mt={3} ml={-1.5} fontSize="0.9rem">
                            Last Name
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter Name"
                            size="small"
                            sx={{
                              backgroundColor: "#F3F3F3",
                              width: "100%",
                              border: "none",
                              marginLeft: "-5%",
                            }}
                          />
                        </div>
                      </div>
                      <div className={styles.offer__number}>
                        <div>
                          <Typography mt={3} ml={0.7} fontSize="0.9rem">
                            Phone number
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter number"
                            size="small"
                            sx={{
                              backgroundColor: "#F3F3F3",
                              width: "100%",
                              marginLeft: "3%",
                              border: "none",
                            }}
                          />
                        </div>
                        <div>
                          <Typography mt={3} ml={-1.5} fontSize="0.9rem">
                            Offer Price
                          </Typography>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter price"
                            size="small"
                            sx={{
                              backgroundColor: "#F3F3F3",
                              width: "100%",
                              border: "none",
                              marginLeft: "-5%",
                            }}
                          />
                        </div>
                      </div>
                      <Typography mt={3} ml={4} fontSize="0.9rem">
                        Message
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Enter Message"
                        sx={{
                          backgroundColor: "#F3F3F3",
                          width: "87%",
                          marginLeft: "6%",
                          border: "none",
                          "& .MuiInputBase-root": {
                            height: 120,
                          },
                        }}
                      />
                      <Button
                        sx={{
                          textTransform: "none",
                          marginLeft: "36.5%",
                          border: "1px solid black",
                          marginTop: "3%",
                          marginBottom: "4%",
                          color: "white",
                          width: "30%",
                          backgroundColor: "black",
                        }}
                      >
                        Make Offer
                      </Button>
                    </Box>
                  </Modal>
                </div>
              </Grid>
            </Grid>
          </Container>

          <div className={styles.features}>
            <Container>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4} md={4}>
                  <div className={styles.safe__shipping}>
                    <img
                      className={styles.mini__truck}
                      src={"/img/Mini Truck.png"}
                      alt="img"
                    />
                    <div>
                      <Typography fontWeight="600" fontSize="0.9rem" ml={2}>
                        Safe Shipping Globally
                      </Typography>
                      <Typography fontSize="0.9rem" ml={1.75}>
                        We ensure that your artwork is shipped securely
                        worldwide.
                      </Typography>
                      <Typography fontSize="0.9rem" ml={1.75}>
                        Our reliable shipping partners guarantee safe handling
                        and timely delivery.
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div className={styles.safe__shipping}>
                    <img
                      className={styles.package}
                      src={"/img/Package.png"}
                      alt="img"
                    />
                    <div>
                      <Typography fontWeight="600" fontSize="0.9rem" ml={2}>
                        Free Return
                      </Typography>
                      <Typography fontSize="0.9rem" ml={1.75}>
                        If your purchase doesn't meet your expectations,
                        returning it is simple and free.
                      </Typography>
                      <Typography fontSize="0.9rem" ml={1.75}>
                        We offer a hassle-free return policy with no hidden
                        fees, allowing you to return the artwork within 14 days.
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div className={styles.safe__shipping}>
                    <img
                      className={styles.secure}
                      src={"/img/Secure.png"}
                      alt="img"
                    />
                    <div>
                      <Typography fontWeight="600" fontSize="0.9rem" ml={2}>
                        Secure
                      </Typography>
                      <Typography fontSize="0.9rem" ml={1.75}>
                        All transactions on Art Smiley are encrypted and
                        processed securely.
                      </Typography>
                      <Typography fontSize="0.9rem" ml={1.75}>
                        We collaborate with trusted financial partners to
                        provide a safe and professional experience, ensuring
                        your personal information remains protected.
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        {/* middle section */}
        <div className={styles.proddets__middle}>
          <div className={styles.design__headingImage}>
            <img className={styles.design__image} src={"/img/Design.png"} />
          </div>
          <div className={styles.design__heading}>
            <Typography mt={1} fontSize="2rem" fontFamily="marcellus">
              More art WORKS FROM EMILY
            </Typography>
          </div>

          <div className={styles.ProductCards}>
            <Container>
              <Grid container>
                {cardDetails.map((item) => (
                  <Grid item xs={12} sm={6} md={3}>
                    <div className={styles.card__main}>
                      <div className={styles.card__top}>
                        <img className={styles.cardImage} src={item.imgUrl} />
                      </div>
                      <div className={styles.card__middle}>
                        <div className={styles.card__middleLeft}>
                          <Typography
                            fontSize="0.95rem"
                            fontWeight="700"
                            mt={1}
                          >
                            Galexy On Earth
                          </Typography>
                          <Typography
                            fontSize="0.8rem"
                            fontWeight="700"
                            mt={1}
                            color="#ccc"
                          >
                            John Deo
                          </Typography>
                        </div>
                        <div className={styles.card__middleRight}>
                          <FavoriteBorderIcon fontSize="small" />
                          <Typography fontWeight="400" ml={0.5}>
                            522
                          </Typography>
                        </div>
                      </div>
                      <hr />
                      <div className={styles.card__bottom}>
                        <div className={styles.buy__text}>
                          <Button>
                            <Typography
                              fontWeight="600"
                              color="#ccc"
                              fontSize="0.65rem"
                            >
                              Buy
                            </Typography>
                            <Typography
                              color="black"
                              fontWeight="500"
                              fontSize="0.85rem"
                              mt={4}
                              ml={-3}
                            >
                              <b>$ 3254</b>
                            </Typography>
                          </Button>
                        </div>
                        <div className={styles.verticalLine}></div>
                        <div className={styles.rent__text}>
                          <Button>
                            <Typography
                              fontWeight="600"
                              color="#ccc"
                              fontSize="0.65rem"
                            >
                              Rent/Month
                            </Typography>
                            <Typography
                              color="black"
                              fontWeight="500"
                              fontSize="0.85rem"
                              mt={4}
                              ml={-6.5}
                              mr={3}
                            >
                              <b>$ 680</b>
                            </Typography>
                          </Button>
                        </div>
                        <div className={styles.verticalLine}></div>
                        <div className={styles.print__text}>
                          <Button>
                            <Typography
                              fontWeight="600"
                              color="#ccc"
                              fontSize="0.65rem"
                            >
                              Print
                            </Typography>
                            <Typography
                              color="black"
                              fontWeight="500"
                              fontSize="0.85rem"
                              mt={4}
                              ml={-3.75}
                            >
                              <b>$ 356</b>
                            </Typography>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </div>
          <PolicySidebar />
        </div>
        {/* bottom section */}
        <ProdDetsOther artist_id={artist_id} allitemdt={allitemdt} />
        {/* qna section */}
        <ProdDetsqna />
      </div>
    </ThemeProvider>
  );
}

export default RentDetails;
