import { ThemeProvider } from "@emotion/react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Link,
  createTheme,
  Grid,
  InputAdornment,
  TextField,
  Container,
} from "@mui/material";
import styles from "./ArtistList.module.css";
import { useNavigate } from "react-router-dom";
import homeImage from "../../../constant";
import { useSelector } from "react-redux";
import ArtistSidebar from "./ArtistSidebar";

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
    accordionheading: {
      fontSize: "14px",
      fontWeight: 500,
    },
    radioname: {
      fontSize: "0.95vw",
      fontWeight: "400",
    },
  },
});
function GalleryList() {
  const navigate = useNavigate();
  
  const handleNavigate = (route) => {
    navigate(route);
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={styles.artist__list}>
          <div className={styles.headerCategory}>
            <Container
              maxWidth="xl"
              sx={{
                paddingLeft: {
                  xs: "16px",
                  sm: "24px",
                  md: "60px",
                  lg: "70px",
                  xl: "100px",
                },
                paddingRight: {
                  xs: "16px",
                  sm: "24px",
                  md: "60px",
                  lg: "70px",
                  xl: "100px",
                },
              }}
            >
              <div className={styles.pageitle}>
                <h1>Gallery</h1>
                <ul>
                  <li>
                    <Link onClick={() => handleNavigate("/")}>Home / </Link>
                  </li>
                  <li>Gallery List</li>
                </ul>
              </div>
            </Container>
          </div>
          <div className={styles.headerMain}>
            <Container
              maxWidth="xl"
              sx={{
                paddingLeft: {
                  xs: "16px",
                  sm: "24px",
                  md: "60px",
                  lg: "70px",
                  xl: "100px",
                },
                paddingRight: {
                  xs: "16px",
                  sm: "24px",
                  md: "60px",
                  lg: "70px",
                  xl: "100px",
                },
              }}
            >
              <div className={styles.tosearch}>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item xs={12} md={3}>
                    <div className={styles.searchheading}>
                      <h3>Gallery</h3>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="input-with-icon-textfield"
                      placeholder="Search"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <ArtistSidebar />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={styles.otherartistcards}>
                        <div className={styles.otherartistcardmain}>
                          <div className={styles.Other_otheim}>
                            <img
                              className={styles.otherartimg}
                              src="/img/ourartist3.png"
                              alt=""
                            />
                          </div>

                          <div className={styles.otherartistimaged}>
                            <img
                              className={styles.otherartistimg}
                              src="/img/artist_profile.png"
                              alt=""
                            />
                          </div>
                          <div className={styles.Other_otherartist}>
                            <h4>Morgan Wave</h4>
                            <p>Japan</p>
                            <button
                              className={`apply_ltbtn  ${styles.widthfull} ${styles.mt_2}`}
                            >
                              Explore
                            </button>
                          </div>
                          {/* <div className={styles.ratingdiv}>
                            <img
                            src={"/img/Gold Star.png"}
                            className={styles.art_de_rating_img}
                          />
                          <Typography>0</Typography>
                         </div> */}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={styles.otherartistcards}>
                        <div className={styles.otherartistcardmain}>
                          <div className={styles.Other_otheim}>
                            <img
                              className={styles.otherartimg}
                              src="/img/ourartist3.png"
                              alt=""
                            />
                          </div>

                          <div className={styles.otherartistimaged}>
                            <img
                              className={styles.otherartistimg}
                              src="/img/artist_profile.png"
                              alt=""
                            />
                          </div>
                          <div className={styles.Other_otherartist}>
                            <h4>Morgan Wave</h4>
                            <p>Japan</p>
                            <button
                              className={`apply_ltbtn  ${styles.widthfull} ${styles.mt_2}`}
                            >
                              Explore
                            </button>
                          </div>
                          {/* <div className={styles.ratingdiv}>
                            <img
                            src={"/img/Gold Star.png"}
                            className={styles.art_de_rating_img}
                          />
                          <Typography>0</Typography>
                         </div> */}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={styles.otherartistcards}>
                        <div className={styles.otherartistcardmain}>
                          <div className={styles.Other_otheim}>
                            <img
                              className={styles.otherartimg}
                              src="/img/ourartist3.png"
                              alt=""
                            />
                          </div>

                          <div className={styles.otherartistimaged}>
                            <img
                              className={styles.otherartistimg}
                              src="/img/artist_profile.png"
                              alt=""
                            />
                          </div>
                          <div className={styles.Other_otherartist}>
                            <h4>Morgan Wave</h4>
                            <p>Japan</p>
                            <button
                              className={`apply_ltbtn  ${styles.widthfull} ${styles.mt_2}`}
                            >
                              Explore
                            </button>
                          </div>
                          {/* <div className={styles.ratingdiv}>
                            <img
                            src={"/img/Gold Star.png"}
                            className={styles.art_de_rating_img}
                          />
                          <Typography>0</Typography>
                         </div> */}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={styles.otherartistcards}>
                        <div className={styles.otherartistcardmain}>
                          <div className={styles.Other_otheim}>
                            <img
                              className={styles.otherartimg}
                              src="/img/ourartist3.png"
                              alt=""
                            />
                          </div>

                          <div className={styles.otherartistimaged}>
                            <img
                              className={styles.otherartistimg}
                              src="/img/artist_profile.png"
                              alt=""
                            />
                          </div>
                          <div className={styles.Other_otherartist}>
                            <h4>Morgan Wave</h4>
                            <p>Japan</p>
                            <button
                              className={`apply_ltbtn  ${styles.widthfull} ${styles.mt_2}`}
                            >
                              Explore
                            </button>
                          </div>
                          {/* <div className={styles.ratingdiv}>
                            <img
                            src={"/img/Gold Star.png"}
                            className={styles.art_de_rating_img}
                          />
                          <Typography>0</Typography>
                         </div> */}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={styles.otherartistcards}>
                        <div className={styles.otherartistcardmain}>
                          <div className={styles.Other_otheim}>
                            <img
                              className={styles.otherartimg}
                              src="/img/ourartist3.png"
                              alt=""
                            />
                          </div>

                          <div className={styles.otherartistimaged}>
                            <img
                              className={styles.otherartistimg}
                              src="/img/artist_profile.png"
                              alt=""
                            />
                          </div>
                          <div className={styles.Other_otherartist}>
                            <h4>Morgan Wave</h4>
                            <p>Japan</p>
                            <button
                              className={`apply_ltbtn  ${styles.widthfull} ${styles.mt_2}`}
                            >
                              Explore
                            </button>
                          </div>
                          {/* <div className={styles.ratingdiv}>
                            <img
                            src={"/img/Gold Star.png"}
                            className={styles.art_de_rating_img}
                          />
                          <Typography>0</Typography>
                         </div> */}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={styles.otherartistcards}>
                        <div className={styles.otherartistcardmain}>
                          <div className={styles.Other_otheim}>
                            <img
                              className={styles.otherartimg}
                              src="/img/ourartist3.png"
                              alt=""
                            />
                          </div>

                          <div className={styles.otherartistimaged}>
                            <img
                              className={styles.otherartistimg}
                              src="/img/artist_profile.png"
                              alt=""
                            />
                          </div>
                          <div className={styles.Other_otherartist}>
                            <h4>Morgan Wave</h4>
                            <p>Japan</p>
                            <button
                              className={`apply_ltbtn  ${styles.widthfull} ${styles.mt_2}`}
                            >
                              Explore
                            </button>
                          </div>
                          {/* <div className={styles.ratingdiv}>
                            <img
                            src={"/img/Gold Star.png"}
                            className={styles.art_de_rating_img}
                          />
                          <Typography>0</Typography>
                         </div> */}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
}
export default GalleryList;