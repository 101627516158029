import React from "react";
import styles from "./WebpopupCuretorAndAffilate.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Grid, Link } from "@mui/material";
import { RiCloseCircleFill, RiArrowRightDoubleLine } from "react-icons/ri";
import homeImage from "../../../../constant";
import { useNavigate } from "react-router-dom";
import CommoncardCurator from "./common/CommancardCurator";

import WebpopupArtist from "./artistAndGalleryPopup/webPopupArtist";
import WebpopupGallery from "./artistAndGalleryPopup/webPopupGallery";
import WebpopupCuretor from "./curatorAndAffiliatePopup/webPopupCuretor";
import WebpopupAffiliate from "./curatorAndAffiliatePopup/webPopupAffiliate";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height:'700px',
  backgroundColor: "#FFFFFF",
  // width: 445,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  p: 0,
};

export default function WebpopupCuratorAndAffiliate({ open,setOpen,setSignPop,setCuratorPopSign}) {
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  return (
    <>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={`${styles.modal_del} ${
    open === "AffiliateAndCuratorSignIn" ? styles.yourNewClass : ""
  }`}>
            <div className={styles.card_main}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
            <div className={styles.card_odd}>
                <WebpopupCuretor open={open} setOpen={setOpen} />
                {/* <hr className={styles.hrs} />
                <div className={styles.card_footer}>
                  <h6 className={styles.already_signin}>
                    Already have account ?
                  </h6>
                  <div>
                    <p
                      className={styles.already_signin_btn}
                      onClick={() => navigate("/artsmiley/login")}
                    >
                      Sign in{" "}
                    </p>
                    <img
                      src={ "/img/next.png"}
                      height="12px"
                      alt="img"
                      width="7px"
                    />
                  </div>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
            <div className={styles.card}>
                <WebpopupAffiliate open={open} setOpen={setOpen} />
                {/* <hr className={styles.hrs} />
                <div className={styles.card_footer}>
                  <h6 className={styles.already_signin}>
                    Already have account ?
                  </h6>
                  <div>
                    <p
                      className={styles.already_signin_btn}
                      onClick={() => navigate("/artsmiley/login")}
                    >
                      Sign in{" "}
                    </p>
                    <img
                      src={ "/img/next.png"}
                      height="12px"
                      alt="img"
                      width="7px"
                    />
                  </div>
                </div> */}
              </div>
            </Grid>
            </Grid>
             
            <div
                sx={{ mt: { xs: "-20px", sm: "-64px" } }}
                className={styles.cancel_imgweb}
              >
                <Link onClick={() => {
                    setOpen(false)
                    setSignPop(false)
                  }}><RiCloseCircleFill className={styles.closicon}/></Link>
                
              </div>
              

            </div>
           <hr className={styles.hrs} />
          {open !== "AffiliateAndCuratorSignIn" &&  <div className={styles.card_footer}>
                  <h6 className={styles.already_signin}>
                  Already An Art Smiley Curator / Affiliate?
                  </h6>
                  <div>
                    <p
                      className={styles.already_signin_btn}
                      onClick={() => {
                        setCuratorPopSign("AffiliateAndCuratorSignIn")
                        setOpen(false)
                     }}
                    >
                      Sign in{" "}
                    </p>
                    <RiArrowRightDoubleLine className={styles.dcbn}/>
                  </div>
                  
                </div> }
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
