import { FormControl, MenuItem, Select, } from "@mui/material";
import styles from "./Tableheader.module.css";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { useSelector } from "react-redux";
export default function Tableheader(props) {
  console.log("Table Header props====>", props);
  const location = useLocation();
  const navigate = useNavigate();
  const handleAddbtn = () => {
    if (window.location.pathname === "/arts") {
      navigate("/upload/stepfirst");
    }else if(window.location.pathname === '/myartist'){
      navigate("/myartist/registrationform")
    } else {
      props.setOpen && props.setOpen(true);
    }
  };
  const usertype = useSelector((state) => state.user.data);
  return (
    <div>
      <div className={styles.buyer_header}>
        <div className={styles.input_div}>
          <input
            id="input-with-icon-textfield"
            placeholder="Search by..."
            onChange={props.handleSearch}
            name="search"
            value={props.search}
            className="form-control"
          />
          <div className={styles.sericon}><FiSearch /></div>
        </div>
        <div className={styles.header_action}>

          {props?.namefilter?.length > 0 &&
            <FormControl>
              <Select
                value={props.condition?.name || ""}
                onChange={props.handleNameFilter && props.handleNameFilter}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiInputBase-input": {
                    padding: ".45rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "16px",
                    fontWeight: "400"
                  },
                  border: "1px solid #ced4da",
                  borderRadius: ".25rem",
                  width: "100%",
                  background: "#fff"
                }}
              >
                <MenuItem value="">Sort by Name</MenuItem>
                {props.namefilter &&
                  props.namefilter.map((val) => (
                    <MenuItem value={val.value}>{val.label}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          }



          {!window.location.href.includes("print-pricing") &&
            !window.location.href.includes("reports") &&
            !window.location.href.includes("policies") &&
            !window.location.href.includes("enquiry") &&
            props.activetab !== "artist_art_pending" &&
            props.activetab !== "artist_art_rejected" &&
            props.activetab !== "gallery_art_pending" &&
            props.activetab !== "gallery_art_rejected" &&
            // props.activetab === "gallery_artist" &&

            (
              <>
                <FormControl>
                  <Select
                    value={props.condition?.sorting || ""}
                    onChange={props.handleSorting && props.handleSorting}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input": {
                        padding: ".45rem .75rem",
                        height: "auto",
                        borderRadius: ".25rem",
                        fontSize: "16px",
                        fontWeight: "400"
                      },
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                      width: "100%",
                      background: "#fff"
                    }}

                  >
                    <MenuItem value="">Sort by</MenuItem>
                    {props.sortdata &&
                      props.sortdata.map((val) => (
                        <MenuItem value={val.value}>{val.label}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {
                  !window.location.href.includes("reports") &&
                  !window.location.href.includes("orders") &&
                  !window.location.href.includes("auctions") && 
                  props.activetab !== "gallery_artist" &&
                  (usertype.type !== "superAdmin" && location.pathname !== "/curators")&&
                  (
                    <FormControl>
                      <Select
                        value={props.condition?.filter || ""}
                        onChange={props.handleFilter && props.handleFilter}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            padding: ".45rem .75rem",
                            height: "auto",
                            borderRadius: ".25rem",
                            fontSize: "16px",
                            fontWeight: "400"
                          },
                          border: "1px solid #ced4da",
                          borderRadius: ".25rem",
                          width: "100%",
                          background: "#fff"
                        }}
                      >
                         { (usertype.type === "curator" && location.pathname === "/art")? "" : <MenuItem value="">All</MenuItem>}
                        {props.filterdata &&
                          props.filterdata.map((val) => (
                            <MenuItem value={val.value} >{val.label}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
              </>
            )}

          {props?.activetab === "gallery_artist" && props?.button?.type === "add" && (
            <button className="action_btn" onClick={handleAddbtn}>
              {props?.button?.text}
            </button>
          )}
          {!window.location.href.includes("artist") &&
            !window.location.href.includes("curator") &&
            !window.location.href.includes("buyerlist") &&
            !window.location.href.includes("affiliate") &&
            !window.location.href.includes("affiliate") &&
            !window.location.href.includes("exhibitionartist") &&
            !window.location.href.includes("curator-testimonials") &&
            !window.location.href.includes("exhibitionsgallery") &&
            !window.location.href.includes("policies") &&

            props.button &&
            props.button.type === "add" ? (
            <button
              className="action_btn"
              onClick={handleAddbtn}
            >
              {props.button && props.button.text}
            </button>
          ) : (
            <></>
          )}
          {
            window.location.href.includes("policies") &&(
              props?.policydata?.data?.length >=1  ?'' :(
                props.button &&
                props.button.type === "add" ? (
                <button
                  className="action_btn"
                  onClick={handleAddbtn}
                >
                  {props.button && props.button.text}
                </button>
              ) : (
                <></>
              )
              )

              )
             

          }
        </div>
      </div>
    </div>
  );
}
