import {
  Autocomplete,
  Box,
  Button,
  Fade,
  Grid,
  Link,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./Enterdetails.module.css";
import Backdrop from "@mui/material/Backdrop";
import ReactQuill from "react-quill";
import homeImage from "../../../../../constant";
import {
  deletePrintProductPrice,
  handleAddFAQcat,
} from "../../../../../State/api/superadminApi";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AlertsSuccess, Errornotify } from "../../../../../common/alert/Alert";
import { handlechangePassword } from "../../../../../State/api/superadminApi";
import { inviteArtist } from "../../../../../State/api/curatorApi";
import { validateAdminPolicy } from "../../../../../validation/validationAdminPolicy";
import { validateAdminFaq } from "../../../../../validation/validationAdminFaq";
import { validateAdminPassword } from "../../../../../validation/validationAdminPasswodchng";
import { RiStarFill, RiCloseCircleFill} from "react-icons/ri";

export default function Enterdetails(props) {
  console.log(props, "props--artistcurat");
  const dispatch = useDispatch();
  const [policyErrors, setPolicyErrors] = useState({});
  const [faqErrors, setFaqErrors] = useState({});
  const [passwordErrors, setPasswordErrors] = useState({});
  const email_regex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+[A-Za-z]+$/;
  const [invite_mail, setInvite_mail] = useState("");
  const handleClose = () => props.setOpen(false);
  const [pass, setPass] = useState({
    oldPassword: "",
    newPassword: "",
    confirm_new_password: "",
  });
  const handleClosereject = () =>
    props.setOpens({ open: false, rejectReason: "", rejectid: "" });

  const { id } = useParams();
  // const handleClosereject = () =>
  //   props.setOpens({ ...props.opens, open: false })
  const handlemultiClose = () =>
    props.setOpens({ open: false, rejectReason: "", rejectid: "" });
  const [acc_type, setAcc_type] = useState("0");
  const [faq, setFaq] = useState(
    props.editdata
      ? props.editdata
      : {
          question: "",
          answer: "",
          user_type_id: id,
          faq_type: "",
        }
  );
  console.log(faq, "faq--111");
  const [policy, setPolicy] = useState(
    props.editdata ? props.editdata : { title: "", description: "" }
  );
  const [product_id, setProduct_id] = useState("");
  const [faqCat, setFaqCat] = useState("");
  const [print, setPrint] = useState(
    props.editdata ? props.editdata : { name: "" }
  );
  const [printPrice, setPrintPrice] = useState(
    props.editdata ? props.editdata : { print_Price: "" }
  );

  const handleChangeFaq = (e) => {
    const { name, value } = e.target;
    setFaq({ ...faq, [name]: value });
  };
  const onChangePass = (e) => {
    const { name, value } = e.target;
    props.setPass({ ...props.pass, [name]: value });
  };

  //new pass curator
  const onChangePasscur = (e) => {
    const { name, value } = e.target;
    setPass({ ...pass, [name]: value });
  };
  const handleChangePolicy = (e) => {
    const { name, value } = e.target;
    setPolicy({ ...policy, [name]: value });
  };
  const [errors, setErrors] = useState({});

  const validatePrint = () => {
    let errors = {};
  
    // Validate name
    if (!print.name.trim()) {
      errors.name = "Name is required and should contain only alphabets.";
    } else if (!/^[a-zA-Z\s]+$/.test(print.name)) {
      errors.name = "Name should contain only alphabets and spaces.";
    }
  
    setErrors(errors);
  
    // Return true if no errors
    return Object.keys(errors).length === 0;
  };
  const handleChangePrint = (e) => {
    const { name, value } = e.target;
    let validValue = value;
  
    if (name === "name") {
      // Allow only alphabets and spaces for the name field
      validValue = value.replace(/[^a-zA-Z\s]/g, "");
    }
  
    setPrint({ ...print, [name]: validValue });
  
    // Clear error if input becomes valid
    if (name === "name" && validValue.trim() !== "" && /^[a-zA-Z\s]+$/.test(validValue)) {
      setErrors((prev) => ({ ...prev, name: "" }));
    }
  };

  const handleAddClick = () => {
    if (validatePrint()) {
      props.handleAdd(print); // Proceed only if validation passes
    }
  };
  
  const handleEditClick = () => {
    if (validatePrint()) {
      props.handleEditMaterial(print); // Proceed only if validation passes
    }
  };

  const handleChangePrintPrice = (e) => {
    const { name, value } = e.target;
    let validValue = value;
    setPrintPrice({ ...printPrice, [name]: validValue });
  };

  const handleChangeFAQcat = (e) => {
    setFaqCat(e.target.value);
  };

  const handleChange = (e) => {
    const val = e.target.value;
    setAcc_type(val);
  };
  const handleChangeProduct = (e) => {
    setProduct_id(e.target.value);
  };

  const handlePolicyAdd = () => {
    const { policyErrors, isValid } = validateAdminPolicy(policy);

    if (!isValid) {
      setPolicyErrors(policyErrors);
    } else {
      setPolicyErrors({});
      props.editdata ? props.handleAdd(policy) : props.handleAdd(policy);
    }
  };
  const handleFaqAdd = () => {
    const { faqErrors, isValid } = validateAdminFaq(faq);

    if (!isValid) {
      setFaqErrors(faqErrors);
    } else {
      setFaqErrors({});
      props.editdata
        ? props.handleEditfaq(props.editdata._id, faq)
        : props.handleAddfaq(faq);
      // props.editdata ? props.handleAdd(policy) : props.handleAdd(policy)
    }
  };

  const handleSubmitChangePassword = () => {
    const { passErrors, isValid } = validateAdminPassword(pass, props.password);
    if (!isValid) {
      setPasswordErrors(passErrors);
    } else {
      
      dispatch(handlechangePassword(pass, handleClose))
      setPasswordErrors({})
   
    }
  };
  return (
    <div>
      {props.poptype === "account" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_details}>
              <div className={styles.cancel_img}>
                <img
                  onClick={handleClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Add Account Type
              </p>
              <label className={styles.input_label}>Account Type</label>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={acc_type}
                label="Account Type"
                placeholder="Account Type"
                fullWidth
                size="small"
                sx={{
                  backgroundColor: "#f6f6f6",
                  borderRadius: "6px",
                  color: "#636365",
                  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  "& .MuiInputBase-root": {
                  },
                }}
                onChange={handleChange}
              >
                <MenuItem value="0">Account Type</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              {/* </FormControl> */}
              {/* <Autocomplete
        // disablePortal
        id="combo-box-demo"
        size="small"
        options={top100Films}
        sx={{ mt: 0.5, backgroundColor: "#F6F6F6", border: "none", borderRadius: "6px" }}
        className={styles.search_select}
        fullwidth
        renderInput={(params) => (
         <TextField
          sx={{
           backgroundColor: "#f6f6f6",
           borderRadius: "6px",
           color: "#636365",
           "& .MuiOutlinedInput-notchedOutline": { border: "none" },
           "& .MuiInputBase-root": {
            // height: 40
           },
          }}
          {...params}
          fullwidth
          placeholder="Select Type"
         />
        )}
       /> */}
              {/* </div> */}
              <div>
                <label className={styles.input_label}>Email</label>
                <br />
                <TextField
                  sx={{
                    mt: 0.5,
                    backgroundColor: "#f6f6f6",
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    borderRadius: "6px",
                  }}
                  size="small"
                  fullWidth
                  placeholder="Enter Email"
                  type="text"
                />
              </div>
              <div className={styles.action}>
                <button
                  variant="contained"
                  sx={{ borderRadius: "10px" }}
                  className="action_btn"
                >
                  Add
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "newpassword" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_details}>
            <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  Set Password
              </p>
              <div className="form-group">
              <label className={styles.input_label}>Current Password *</label>
              <input
                className="form-control"
                variant="outlined"
                type="password"
                name="oldPassword"
                value={pass.oldPassword}
                placeholder="Enter current password"
                onChange={onChangePasscur}
                
              />
              {passwordErrors?.oldPassword && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {passwordErrors?.oldPassword}
                </span>
              )}
              </div>
              <div className="form-group">
              <label className={styles.input_label}>New Password *</label>
                <input
                className="form-control"
                  variant="outlined"
                  type="password"
                  name="newPassword"
                  value={pass.newPassword}
                  placeholder="Enter new password"
                  onChange={onChangePasscur}
                  
                />
                {passwordErrors?.newPassword && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {passwordErrors?.newPassword}
                  </span>
                )}
              </div>
              <div className="form-group">
              <label className={styles.input_label}>
                  {" "}
                  Confirm New Password *
                </label>
                <input
                className="form-control"
                variant="outlined"
                type="password"
                name="confirm_new_password"
                value={pass.confirm_new_password}
                placeholder="Enter confirm new password"
                onChange={onChangePasscur}
                
              />
              {passwordErrors?.confirm_new_password && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {passwordErrors?.confirm_new_password}
                </span>
              )}
              </div>
              <div className={styles.action}>
                <button
                  variant="contained"
                  onClick={handleSubmitChangePassword}
                 className="action_btn"
                >
                  Update
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "faquestion" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_details}>
            <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  {props.editdata ? "Edit" : "Add"} FAQ Question
              </p>
               
              <div className="form-group">
                <label>Question <span className={styles.astricksymb}>*</span></label>
                <input
                className="form-control"
                  placeholder="Enter Question"
                  type="text"
                  name="question"
                  value={faq.question}
                  onChange={handleChangeFaq}
                />
                {faqErrors?.question && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {faqErrors?.question}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>FAQ Type <span className={styles.astricksymb}>*</span></label>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={faq.faq_type}
                  label="Faq type"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiInputBase-input":{
                      padding:".45rem .75rem",
                      height:"auto",
                      borderRadius:".25rem",
                      fontSize:"16px",
                      fontWeight:"400"
                    },
                    border:"1px solid #ced4da",
                    borderRadius:".25rem",
                    width:"100%"
                  }}
                  placeholder="select type"
                  onChange={handleChangeFaq}
                  name="faq_type"
                  // name="blog_type"
                >
                  <MenuItem value="For FAQ">For FAQ</MenuItem>
                  <MenuItem value="For Applicaiton">For Applicaiton</MenuItem>
                </Select>
                {faqErrors?.faq_type && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {faqErrors?.faq_type}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label>Answer <span className={styles.astricksymb}>*</span></label>
                <ReactQuill               
                  theme="snow"
                  placeholder={`Enter Answer`}
                  className={styles.customEditor}
                  // className={`${styles.textarea_blog}  ${styles.customEditor} `}
                  value={faq.answer}
                  onChange={(content) =>
                    handleChangeFaq({
                      target: { name: "answer", value: content },
                    })
                  }
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                />
              </div>
              {faqErrors?.answer && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginTop:"65px",
                    position:'absolute'
                  }}
                >
                  {faqErrors?.answer}
                </span>
              )}
              <div className={styles.action_career}>
                <button
                  variant="contained"
                  
                  className="action_btn"
                  onClick={handleFaqAdd}
                >
                  Add
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "policy" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_details}>
            <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  {props.editdata ? "Edit" : "Add"} Policy
              </p>
               
              <div className="form-group">
                <label>Title <span className={styles.astricksymb}>*</span></label>
                <input
                className="form-control"
                  placeholder="Enter Title"
                  type="text"
                  name="title"
                  value={policy.title}
                  onChange={handleChangePolicy}
                />
                {policyErrors?.title && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {policyErrors?.title}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label>Description <span className={styles.astricksymb}>*</span></label>
                <ReactQuill
                  theme="snow"
                  placeholder={`Enter description`}
                  className={`${styles.textarea_blog}  ${styles.customEditor}`}
                  value={policy.description}
                  onChange={(content) =>
                    handleChangePolicy({
                      target: { name: "description", value: content },
                    })
                  }
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                />
                {policyErrors?.description && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginTop:"70px",
                      position:"absolute"
                    }}
                  >
                    {policyErrors?.description}
                  </span>
                )}
              </div>
             
              <div className={styles.action_career}>
                {props.editdata ? (
                  <button
                    variant="contained"
                  
                    className="action_btn"
                    onClick={handlePolicyAdd}
                  >
                    Edit
                  </button>
                ) : (
                  <button
                    variant="contained"
                    
                    className="action_btn"
                    onClick={handlePolicyAdd}
                  >
                    Add
                  </button>
                )}
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "curator_invite_artist" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_decline}>
            <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
                Invite Artist
              </p>
              <div className="form-group">
                <label>Email</label>
                <input
                  className="form-control"
                  value={invite_mail}
                  placeholder="Enter Email"
                  type="email"
                  error={!invite_mail.match(email_regex)}
                  onChange={(e) => setInvite_mail(e.target.value)}
                />
              </div>
              <div className={styles.action}>
                <button
                  
                  className="action_btn"
                  // disabled={!invite_mail.match(email_regex)}
                  onClick={() => props.handleInviteArtist(invite_mail)}
                >
                  Invite
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "curator_art_cancel" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.opens.open}
          onClose={handleClosereject}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.opens.open}>
            <Box className={styles.modal_decline}>
              <div className={styles.clobtn}>
                <Link onClick={handlemultiClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
                {props.text}
              </p>
              <div className="form-group">
                <label>Write a Remark</label>
                <textarea
                  
                  value={props.opens.rejectReason}
                  onChange={(e) =>
                    props.setOpens({
                      ...props.opens,
                      rejectReason: e.target.value,
                    })
                  }
                  placeholder=""
                  maxRows={5}
                  className="form-control"
                  fullWidth
                  minRows={5}
                />
              </div>
              {/* <TextareaAutosize
                                    maxRows={6}
                                    fullWidth
                                    sx={{ width: '99%' }}
                                    aria-label="maximum height"
                                    placeholder="Maximum 4 rows"
                                    defaultValue="Lorem ipsum dolor sit"
                                /> */}
              <div className={styles.action}>
                <button
                
                  className="action_btn"
                  // onClick={() => props.handleAcceptRejectArts("Rejected")}
                  onClick={() => {
                    if (!props.opens.rejectReason || props.opens.rejectReason.trim() === "") {
                      Errornotify("Please enter a remark before submitting.");
                      return;
                    }                    
                    props.handleAcceptRejectArts("Rejected");
                  }}
                >
                  Submit
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "affliate_copy_link" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_details}>
              <div className={styles.cancel_img}>
                <img
                  onClick={handleClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Generate Link
              </p>
              <div>
                <label className={styles.input_label}>Text link</label>
                <br />
                <TextField
                  sx={{
                    mt: 0.5,
                    "& .MuiOutlinedInput-notchedOutline": { border: "noe" },
                    borderRadius: "6px",
                    height: "54px",
                  }}
                  fullWidth
                  placeholder="https://artsmiley.to/2ghfdkH"
                  type="text"
                />
              </div>
              <div className={styles.action}>
                <Button
                  variant="contained"
                  sx={{
                    display: "flex",
                    columnGap: "5px",
                    alignItems: "center",
                    borderRadius: "10px",
                    width: "159px",
                    height: "54px",
                  }}
                  className="action_btn"
                >
                  <img src={"/img/"} height="20px" width="20px" />
                  Copy Link
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "print" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_details}>
            <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  {props.editdata ? "Edit" : "Add"} Product
              </p>
               
              <div className="form-group">
                <label>Name</label>
                <input
                  className="form-control"
                  placeholder="Enter name"
                  type="text"
                  name="name"
                  value={print.name}
                  onChange={handleChangePrint}              
                />
                {errors.name && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginTop: "4px",
                      display: "block", // Ensure the error spans the full width
                    }}
                  >
                    {errors.name}
                  </span>
                )}
              </div>
              <div className={styles.action}>
                {props.editdata ? (
                  <button
                    className="action_btn"
                    // onClick={() => props.handleEditMaterial(print)}
                    onClick={handleEditClick} // Use the edit handler
                  >
                    Edit
                  </button>
                ) : (
                  <button
                    className="action_btn"
                    // onClick={() => props.handleAdd(print)}
                    onClick={handleAddClick} // Use the add handler
                  >
                    Add
                  </button>
                )}
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "print_prcie" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_details}>
            <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  Print Price
              </p>
               <div className="form-group">
               <label>Select Product</label>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={product_id}
                  onChange={handleChangeProduct}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiInputBase-input":{
                      padding:".45rem .75rem",
                      height:"auto",
                      borderRadius:".25rem",
                      fontSize:"16px",
                      fontWeight:"400"
                    },
                    border:"1px solid #ced4da",
                    borderRadius:".25rem",
                    width:"100%"
                  }}
                >
                  <MenuItem value="">Select Product</MenuItem>
                  {props.productDt &&
                    props.productDt.data.map((v) => (
                      <MenuItem value={v._id}>{v.name}</MenuItem>
                    ))}
                </Select>
               </div>
               <div className="form-group">
               <label>Price</label>
                <input
                  className="form-control"
                  fullWidth
                  placeholder="Enter name"
                  type="number"
                  name="print_Price"
                  value={printPrice.print_Price}
                  onChange={handleChangePrintPrice}
                />
               </div>
              <div className={styles.action}>
                <button
                  variant="contained"
                 
                  className="action_btn"
                  onClick={() =>
                    props.handleAdd(printPrice.print_Price, product_id)
                  }
                >
                  Add
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_details}>
              <div className={styles.cancel_img}>
                <img
                  onClick={handleClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Add FAQ Category
              </p>
              <div>
                <label className={styles.input_label}>Category name</label>
                <br />
                <TextField
                  sx={{
                    mt: 0.5,
                    "& .MuiOutlinedInput-notchedOutline": { border: "noe" },
                    borderRadius: "6px",
                    height: "54px",
                  }}
                  fullWidth
                  placeholder="Enter Name"
                  type="text"
                  value={faqCat}
                  name="FaqCat"
                  onChange={handleChangeFAQcat}
                />
              </div>
              <div className={styles.action}>
                <Button
                  variant="contained"
                  sx={{ borderRadius: "10px", width: "156px", height: "54px" }}
                  className="action_btn"
                  onClick={() => props.handleAddfaqCat(faqCat)}
                >
                  Add
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </div>
  );
}
