import validator from "validator"
import isEmpty from "./isEmpty"

export const validationForGalleryApplication = (portfolio_file, description,award,degree_year

) => {
  const isFile = file => file instanceof File;
  let galleryApplicationErrors = {}
 
  if (!isFile(portfolio_file) || !portfolio_file.name) {
    galleryApplicationErrors.portfolio_file = "Portfolio file is required";
  }
 

  if(isEmpty(description) || description === "<p><br></p>") {
    galleryApplicationErrors.description = "About yourself is required";
  }

  if(isEmpty(award) || award === "<p><br></p>") {
    galleryApplicationErrors.award = "Exhibitions , awards/prizes are required ";
  }

  // if(isEmpty(degree_year) || degree_year === "<p><br></p>") {
  //   galleryApplicationErrors.degree_year = "Please provide a detailed description, including the name, year, and place of each experience.";
  // }
  return {
    galleryApplicationErrors,
    isValid: isEmpty(galleryApplicationErrors),
  }
}
