import { IconButton, Grid } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AlertsSuccess } from "../../../../common/alert/Alert"
import CircularLoader from "../../../../common/loader/Loader"
import { FiTrash2, FiEdit3 } from "react-icons/fi";
import {
  deleteSubscription,
  editSubscription,
  fetchsubscriptionData,
} from "../../../../State/api/superadminApi"
import Addsubscription from "../Popupdelete/addSubscription/Addsubscription"
import Threedots from "../Table/Threedots/Threedots"
import styles from "./Subscription.module.css"
import { useNavigate } from "react-router-dom"
const data = [
  {
    dark: false,
    price: "$100",
    validity: "/month",
    heading: "Base",
    description: "For most bussiness that want to optimize web queries",
    detail: [
      {
        content: "All limited links",
      },
      {
        content: "Own analytics platform",
      },
      {
        content: "Chat Support",
      },
      {
        content: "Optimize hashtags",
      },
      {
        content: "Unlimited users",
      },
    ],
  },
  {
    dark: true,
    price: "$100",
    validity: "/month",
    heading: "Pro",
    description: "For most bussiness that want to optimize web queries",
    detail: [
      {
        content: "All limited links",
      },
      {
        content: "Own analytics platform",
      },
      {
        content: "Chat Support",
      },
      {
        content: "Optimize hashtags",
      },
      {
        content: "Unlimited users",
      },
    ],
  },
  {
    dark: false,
    price: "$100",
    validity: "/month",
    heading: "Enterprice",
    description: "For most bussiness that want to optimize web queries",
    detail: [
      {
        content: "All limited links",
      },
      {
        content: "Own analytics platform",
      },
      {
        content: "Chat Support",
      },
      {
        content: "Optimize hashtags",
      },
      {
        content: "Unlimited users",
      },
    ],
  },
]
const threedotData = [
  {
    link: "Inactive",
  },
]
export default function Subscription() {
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [editdata, setEditdata] = useState(null)

  const loader = useSelector((state) => state.loader)
  const dispatch = useDispatch()
  const pagination = useSelector((state) => state.pagination)
  const subscriptionData = useSelector((state) => state.subscriptionData.data)
  const navigate = useNavigate()
  const alert = useSelector((state) => state.alert)
  
  

  useEffect(() => {
    fetchData()
  }, [])
  const fetchData = () => {
    dispatch(fetchsubscriptionData())
  }

  const handlePopup = () => {
    setOpen(true)
  }
  const handleDelete = useCallback((id) => {
    dispatch(deleteSubscription(id, fetchData))
  }, [])
  const handleEditpopup = (val) => {
    setEdit(true)
    setEditdata(val)
  }
  const handleEdit = (id, editeddata) => {
    dispatch(editSubscription(id, editeddata, setEdit, fetchData))
  }
  const handleEdits = (vl) => {
    handleEditpopup(vl)
    setEdit(true)
  }

  const handleArtistDetails = (id) => {
    navigate(`artistlist/${id}`)
  }
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Addsubscription
          Poptype="add_stage_one"
          open={open}
          setOpen={setOpen}
          type=""
          fetchData={fetchData}
        />
      ) : (
        ""
      )}

      {edit ? (
        <Addsubscription
          Poptype="add_stage_one"
          open={edit}
          setOpen={setEdit}
          fetchData={fetchData}
          handleEdit={handleEdit}
          type="edit"
          val={editdata}
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <div className={styles.add_btn_div}>
          <button
            className="action_btn"
            variant="contained"
            onClick={handlePopup}
          >
            Add
          </button>
        </div>
        {loader.loader ? (
          <div className={styles.card_div}>
            <CircularLoader />
          </div>
        ) : (
          <div className={styles.card_div}>
            <Grid container spacing={2}>
            {subscriptionData &&
              subscriptionData.map((val, i) => (
                <Grid item xs={12} sm={6} md={4}
                  key={val._id}
                  
                >
                  <div className={(i + 1) % 2 === 0 ? styles.card_dark : styles.card}>
                  <div className={styles.edit_more}>
                  <IconButton
                  sx={{
                    background:"#fff"
                  }}
                  onClick={() => handleEdits(val)}><FiEdit3 className={styles.editicon} /></IconButton>
                    {/* <img
                      src={
                        (i + 1) % 2 === 0 ? "/img/edit-2.png" : "/img/Edit.png"
                      }
                      width="23px"
                      height="25px"
                      className={styles.subs_edit}
                      
                    /> */}
                    {/* <Threedots
                      _id={val._id}
                      handleDelete={handleDelete}
                      handleEditpopup={handleEditpopup}
                      handleArtistDetails={handleArtistDetails}
                      is_active={val.is_active}
                      data={threedotData}
                      dark={true}
                      val={val}
                    /> */}
              <IconButton sx={{
                    background:"#fff"
                  }} onClick={() => handleDelete(val._id)}><FiTrash2 className={styles.deleicon}/></IconButton>
                    {/* <img
                      alt="img"
                      src={
                        (i + 1) % 2 === 0
                          ? "/img/deleteWhite.png"
                          : "/img/delete.png"
                      }
                      height="20px"
                      width="18px"
                      
                    /> */}
                  </div>
                  <div className={styles.detail_main}>
                  <h1
                      className={
                        (i + 1) % 2 === 0 ? styles.heading_dark : styles.heading
                      }
                    >
                      {val.plan_name}
                    </h1>
                  </div>
                  
                  <div className={styles.plan_price}>
                    <span
                      className={
                        (i + 1) % 2 === 0 ? styles.heading_dark : styles.heading
                      }
                    >
                      ${val.plan_price}
                    </span>
                    <span
                      className={
                        (i + 1) % 2 === 0 ? styles.times_dark : styles.times
                      }
                    >
                       /month
                    </span>
                  </div>
                  <div className={styles.detail_main}>
                    
                    <p
                      className={
                        (i + 1) % 2 === 0 ? styles.para_dark : styles.para
                      }
                    >
                      {val.plan_description}
                    </p>
                    {/* {val.detail.map((item, i) => ( */}
                    {val.feature_ids &&
                      val.feature_ids.map((val) => (
                        <div className={styles.detail_flex}>
                          {/* <div
                            className={
                              val.dark
                                ? styles.right_icon_dark
                                : styles.right_icon
                            }
                          >
                            {(i + 1) % 2 === 0 ? (
                              <img
                                src="/img/right_icon_white.png"
                                width="12px"
                                height="10px"
                              />
                            ) : (
                              <img
                                src="/img/righticon.png"
                                width="12px"
                                height="10px"
                              />
                            )}
                          </div> */}

                          <span
                            className={
                              (i + 1) % 2 === 0 ? styles.para_dark : styles.para
                            }
                          >
                            {val?._id?.name}
                          </span>
                          <span>
                            {[
                              "promotion artist on Art Smiley blogs",
                              "solo online exhibitions",
                              "discount on first refusal opportunity on exhibitions",
                            ].includes(val._id.name) ? (
                              <input
                                type="text"
                                className={styles.inputField}
                                value={val._id.count === 1 ? "Yes" : "No"}
                                readOnly
                              ></input>
                            ) : (
                              <input
                                type="number"
                                className={styles.inputField}
                                value={val._id.count}
                                readOnly
                              />
                            )}
                          </span>
                        </div>
                      ))}
                    {/* ))} */}
                  </div>
                  </div>
                </Grid>
              ))}
              
  </Grid>
            
          </div>
        )}
      </div>
    </div>
  )
}
