import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,

}

const giftcardSlice = createSlice({
    name: 'giftcardData',
    initialState,
    reducers: {
        setGiftcarddata: (state, action) => {
            state.data = action.payload
        }
    }
});

export const { setGiftcarddata } = giftcardSlice.actions;

// export const subscriptionfeatureData = (state) => state.subscriptionfeatureData;

export default giftcardSlice.reducer;