import { Button, Grid, Typography, Container } from "@mui/material";
import React from "react";
import homeImage from "../../../constant";
import styles from "./CuratorCircle.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"
function CuratorCircle() {
  const navigate = useNavigate()
  
    const handleNavigate = (route) => {
      navigate(route)
    }
  return (
    <div className={styles.curatorcircle}>
      <div className={styles.header}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
      <div className={styles.pageitle}>
              <h1>Join the Art Smiley Curator’s Community</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li>Curator’s Community</li>
              </ul>
            </div>
      </Container>
        
      </div>
      <div className={styles.member__benefits}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
      <Grid container spacing={2} sx={{alignItems:"center"}}>
          {/* Static Left Section */}
          <Grid item xs={12} md={2}>
          <div className={styles.imgcontainer}>
                  <img
                    className={styles.artistImg}
                    src={ "/img/Ellipse 251.png"} alt=""
                  />
                </div>
          </Grid>
          <Grid item xs={12} md={10}>
          <div className={styles.benefit__text}>
                <h3>Drive Art Sales Through Engagement and Storytelling with Art Smiley Curator’s Community</h3>
                <p>
                  Art Smiley Curator’s Community makes a strong contribution to multifaceted art. By fostering connections with art lovers and patrons, curators can greatly improve artwork sales through the artful narration of compelling stories behind the art pieces. Furthermore, by curating exhibitions that showcase a variety of artistic expressions, curators foster a collaborative environment that nurtures personal connections between artists and buyers. Promoting these exhibitions through multiple channels enhances the relationship between the art and its audience, ultimately enriching the overall experience and deepening appreciation for the artworks.
                  
                </p>
              </div>
          </Grid>
      </Grid>
      </Container> 
      </div>
      <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <Grid container spacing={5} sx={{alignItems:"center"}}>
        <Grid item xs={12} md={6} order={{ xs: 2, sm: 1 }}>
            <div>
            <img class="img-fluid" src="/img/marketplace.png" alt="" />
            </div>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, sm: 2 }}>
            <div>
              
              <div className={styles.info__div}>
              <h3>How to Join the Art Smiley Curator’s Community?</h3>
                    <ul>
                      <li>
                        Submit Your Application Begin your journey by providing
                       the necessary details for registration. This is the first
                       step towards becoming a valued member of the Art Smiley Curator's Community. 
                        <button className={styles.apply_btn}>Apply</button> 
                      </li>
                      <li>
                        Review and Approval Once your information is submitted,  
                        our team will review your application within 3-4 weeks.  
                        During this period, we’ll assess your profile to ensure it 
                         aligns with our curatorial standards and goals. Upon approval,
                          you will receive a confirmation email to create a password for
                           your account and login to your dashboard.

                      </li>
                     
                    </ul>
                  </div>
            </div>
          </Grid>
          
        </Grid>
        </Container>
      </section>
      <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <Grid container spacing={5} >
          <Grid item xs={12} md={6}>
            <div>
            <div className={styles.info__div}>
              <h3>Curator Privileges</h3>
              <ul>
      <li>
          Collaborate with a Global Community: Connect with artists, collectors,
          and curators from around the world. Exchange ideas, inspire others, and
           help shape the narrative of contemporary art.
      </li>
      <li>
          Access to Diverse Artworks: Explore a wide range of artworks,
          from traditional to contemporary, and curate collections that
           reflect your unique perspective.
      </li>
      <li>
          Curate Exhibitions to Sell Artworks: As a curator,
           you have the opportunity to organize physical and
            virtual exhibitions specifically designed to showcase and sell artworks.
      </li>
      <li>
          Earn Commissions: By curating and promoting exceptional artworks,
           you will earn commissions, adding value to both your career and the artists you support.
      </li>
      <li>
          Support and Guidance: Our dedicated team is here to assist
           you every step of the way. Whether you need help navigating
            the platform or advice on curating, we’re just a message away.
      </li>
      <li>
          Track Your Commissions: Use our platform to easily monitor
           and track your commissions, providing you with clear insights
            into your earnings and helping you manage your curatorial activities effectively.
      </li>
      <li>
          Select Featured Artists: As a curator, you play a pivotal role
           in highlighting talent. You have the authority to select and
            showcase featured artists, drawing attention to their work
            and giving them a platform to reach wider audiences.
      </li>
      <li>
          Specially Curated Collections: Manage the curated artworks that
           reflect your unique artistic vision. All curated collections are
            featured on a dedicated page, allowing you to showcase your selections
             in a professional, organized manner, making it easier for collectors to explore and purchase the artworks.
       
      </li>
    </ul>
    </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
            <img class="img-fluid" src="/img/curatorprivilege.png" alt="" />
            </div>
          </Grid>
        </Grid>
        </Container>
      </section>
      <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <Grid container spacing={5} sx={{alignItems:"center"}}>
          <Grid item xs={12} md={6} order={{ xs: 2, sm: 1 }}>
            <div>
            <img class="img-fluid" src="/img/members-onlyinsights.png" alt="" />
            </div>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, sm: 2 }}>
            <div>
              <div className={styles.info__div}>
              <h3>Members-Only Insights</h3>
                    <ul>
                      <li>
                        Exclusive Artworks: Discover a curated selection of exceptional and original pieces that represent the cutting edge of contemporary art.
                        </li>
                      <li>
                        Curator Insights: Gain deeper understanding with detailed commentary and stories from our curators about each artwork.
                        </li>
                      <li>
                        Early Previews: Get first access to new arrivals and special releases, keeping you ahead of the latest art trends
                        </li>

                    </ul>
                  </div>
            </div>
          </Grid>
        </Grid>
        </Container>
      </section>
      <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <Grid container spacing={5} sx={{alignItems:"center"}}>
          <Grid item xs={12} md={6}>
            <div>
            <div className={styles.info__div}>
              <h3> Dashboard Features and Benefits</h3>
                  <ul>
      <li>
          Total Featured Artists: Track the number of artists in your collections.
      </li>
      <li>
          Total Arts: Monitor the total amount of art represented.
      </li>
      <li>
          Curated Collections: Count your specially curated artwork collections.
      </li>
      <li>
          Total Earnings: Check your earnings to track financial progress.
      </li>
      <li>
          Total Number of Artists: Know how many artists you’re engaging currently.
      </li>
      <li>
          Total Weekly Earnings: Stay updated on weekly earnings.
      </li>
      <li>
          Total Monthly Earnings: Track your monthly earnings.
      </li>
      <li>
          Total Yearly Earnings: View your cumulative earnings for the year.
      </li>
      <li>
          Total Curated Exhibitions: Review the total number of the curated exhibitions by you.
      </li>
    </ul>
                  </div> 
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
            <img class="img-fluid" src="/img/dashboardfeature.png" alt="" />
            </div>
          </Grid>
        </Grid>
        </Container>
      </section>
      <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <Grid container spacing={5} sx={{alignItems:"center"}}>
          <Grid item xs={12} md={6} order={{ xs: 2, sm: 1 }}>
            <div>
            <img class="img-fluid" src="/img/members-onlyinsights.png" alt="" />
            </div>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, sm: 2 }}>
            <div>
              <div className={styles.info__div}>
              <h3>Remarkable Events</h3>
              <ul>
                      <li>
                        Private Viewings: Enjoy exclusive events where you can see artworks up close and interact with artists and curators.
                      </li>
                      <li>
                        Curator-led Tours: Join guided tours to explore the collection and appreciate the diverse styles and techniques of featured pieces.
                      </li>
                      <li>
                        Special Collaborations: Participate in unique events that foster connections between artists, collectors, and art enthusiasts.
                      </li>

                    </ul>
                  </div>
            </div>
          </Grid>
        </Grid>
        </Container>
      </section>
      
    </div>
  );
}

export default CuratorCircle;
