import React from 'react'
import styles from "./ArtConsultancy.module.css"
import Tableheader from '../Table/Tableheader/Tableheader'
import SubmissionFormTable from './SubmissionFormTable';
import { useLocation } from 'react-router-dom';

const ArtConsultancy = () => {
  const location = useLocation(); 
  const path = location.pathname; 

  const exhibitionhead = [
    {
      id: "artist_name ",
      label: "Dispute ID",
    },
    {
      id: "mobile_number",
      label: "Order ID",
    },
    {
      id: "date",
      label: "Created Date",
    },
    {
      id: "assign_to",
      label: "Name",
    },
    {
      id: "actiont",
      label: "Refund",
    },
    // {
    //   id: "action",
    //   label: "Status",
    // },
  ];

  const getData = () => {
    if (path === "/submissionforms/art-for-interior-designers") {
      return ["Interior Designers Data 1", "Interior Designers Data 2"];
    } else if (path === "/submissionforms/art-smiley-for-business") {
      return ["Business Data 1", "Business Data 2"];
    } else if (path === "/submissionforms/questionnaire") {
      return ["Questionnaire Data 1", "Questionnaire Data 2"];
    } else {
      return [];
    }
  };



  return (
    <div className={styles.components}>
       <Tableheader
            // search={condition.search}
            // handleSearch={handleSearch}
        />
       <SubmissionFormTable
         head={exhibitionhead}
         data={getData()}
       />
    </div>
  )
}

export default ArtConsultancy
