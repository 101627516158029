import {
  Grid, 
  Container
} from "@mui/material";
import { useState } from "react";
import styles from "./Unsubscribe.module.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
function Unsubscribe() {
  const theme = createTheme();
  const [selectedReason, setSelectedReason] = useState("");
   
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.contactus}>
        <div className={styles.headerCategory}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={styles.pageitle}>
              <h1>Unsubscribe</h1>
              <p>If you'd like to unsubscribe from all emails.</p>
              </div>
          </Container> 
            
        </div>
        <div className={styles.formsec}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md: '100px' },
    
  }}>
          <Grid container spacing={2} sx={{justifyContent:"space-around"}}>
            <Grid item xs={12} sm={5} md={4}>
              <img
                className={styles.img_fluid}
                src={"/img/unsubscribe.jpg"}
                alt="img"
              />
             
            </Grid>
            <Grid item xs={12} sm={5} md={6}>
      <div className={styles.mainForm}>
        <h3>Reason for Unsubscribing</h3>
        <div className={styles.unsubreson}>
          <h5>Please let us know why you are unsubscribing:
          </h5>
        </div>

        {/* Radio Buttons */}
        <div className="form-group">
          <label>
            <input 
              type="radio" 
              name="unsubscribeReason" 
              value="too_frequent" 
              onChange={(e) => setSelectedReason(e.target.value)} 
            /> Receiving too many emails
          </label>
        </div>
        <div className="form-group">
          <label>
            <input 
              type="radio" 
              name="unsubscribeReason" 
              value="never_signed_up" 
              onChange={(e) => setSelectedReason(e.target.value)} 
            /> No longer interested in this content

          </label>
        </div>
        <div className="form-group">
          <label>
            <input 
              type="radio" 
              name="unsubscribeReason" 
              value="inappropriate" 
              onChange={(e) => setSelectedReason(e.target.value)} 
            /> Email address is no longer valid
          </label>
        </div>
        <div className="form-group">
          <label>
            <input 
              type="radio" 
              name="unsubscribeReason" 
              value="neversigned" 
              onChange={(e) => setSelectedReason(e.target.value)} 
            /> I never signed up for this mailing list 
          </label>
        </div>
        <div className="form-group">
          <label>
            <input 
              type="radio" 
              name="unsubscribeReason" 
              value="other" 
              onChange={(e) => setSelectedReason(e.target.value)} 
            /> Other
          </label>
        </div>

        {/* Show textarea only if "Other" is selected */}
        {selectedReason === "other" && (
          <div className="form-group">
            <textarea className="form-control" placeholder="Please specify your reason"></textarea>
          </div>
        )}

        <div className={styles.text_left}>
          <button className="apply_btn">
            Submit
          </button>
        </div>
      </div>
    </Grid>
          </Grid>
          </Container>
        </div>
        
        
      </div>
    </ThemeProvider>
  );
}

export default Unsubscribe;
