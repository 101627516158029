import React, { useState } from "react";
import styles from "./Addartistype.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import {
  editArtistType,
  handleAddartistType,
} from "../../../../../../State/api/superadminApi";
import { useDispatch } from "react-redux";

export default function Addartistype(props) {
  const [name, setName] = useState("");
  const [edit, setEdit] = useState(props.editdata && props.editdata.name);
  const dispatch = useDispatch();
  const handleClose = () => props.setOpen(false);
  const handleChange = (e) => {
    setName(e.target.value);
    console.log(name);
  };
  const handleChangeEdit = (e) => {
    setEdit(e.target.value);
  };


  return (
    <div>
      {props.type && props.type === "add" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_del}>
              <div className={styles.cancel_img}>
                <img
                  onClick={() => props.setOpen(false)}
                  src="/img/cancel_cross.png"
                  height="20px"
                  width="20px"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                {props.heading}
              </p>
              {/* <div className={styles.textarea}>
              <textarea
                className={styles.textareas}
                placeholder="Write a Remark"
                rows="10"
              />
            </div> */}
              <div className={styles.labels}>Name</div>
              <TextField
                variant="outlined"
                placeholder="Enter Artist Type name"
                fullWidth
                sx={{ mb: 4 }}
                value={name}
                onChange={handleChange}
              />
              <div className={styles.action}>
                <button
                  onClick={() => props.handleSubmitAdd(name)}
                  variant="contained"
                  className="action_btn"
                >
                  Submit
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_del}>
              <div className={styles.cancel_img}>
                <img
                  onClick={() => props.setOpen(false)}
                  src="/img/cancel_cross.png"
                  height="20px"
                  width="20px"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                {props.heading}
              </p>
              {/* <div className={styles.textarea}>
              <textarea
                className={styles.textareas}
                placeholder="Write a Remark"
                rows="10"
              />
            </div> */}
              <div className={styles.labels}>Name</div>
              <TextField
                variant="outlined"
                placeholder="Enter Artist Type name"
                fullWidth
                sx={{ mb: 4 }}
                onChange={handleChangeEdit}
                value={edit}
              />
              <div className={styles.action}>
                <button
                  onClick={() => props.handleSubmitEdit(edit)}
                  variant="contained"
                  className="action_btn"
                >
                  Submit
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </div>
  );
}
