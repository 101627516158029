import { Button, FormControl, MenuItem, Select } from "@mui/material"
import styles from "./Tableheader.module.css"
import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { FiSearch } from "react-icons/fi"
export default function TableheaderExhibitions(props) {
  console.log(props, "props-tableheader--")
  // const [search, setSearch] = useState("");
  // const [age, setAge] = React.useState("");

  const location = useLocation()
  const navigate = useNavigate()

  const handleAddbtn = () => {
    if (window.location.pathname === "/arts") {
      navigate("/upload/stepfirst")
    } else {
      props.setOpen && props.setOpen(true)
    }

  }

  return (
    <div>
      <div className={styles.buyer_header}>
        <div className={styles.input_div}>
          <input
            onChange={props.handleSearch}
            name="search"
            // onClick={handleOnSearch}
            value={props.search}
            className="form-control"
            type="text"
            placeholder="Search by..."
          />
          <div className={styles.sericon}><FiSearch /></div>
        </div>
        <div className={styles.header_action}>

          <>
            <FormControl>
              <Select
                value={props.condition?.sorting || ""}
                onChange={props.handleSorting && props.handleSorting}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiInputBase-input": {
                    padding: ".45rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "16px",
                    fontWeight: "400"
                  },
                  border: "1px solid #ced4da",
                  borderRadius: ".25rem",
                  width: "100%",
                  background: "#fff"
                }}
              >
                <MenuItem value="">Sort by</MenuItem>
                {props.sortdata &&
                  props.sortdata.map((val) => (
                    <MenuItem value={val.value}>{val.label}</MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl>
              <Select
                value={props.condition?.filter || ""}
                onChange={props.handleFilter && props.handleFilter}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiInputBase-input": {
                    padding: ".45rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "16px",
                    fontWeight: "400"
                  },
                  border: "1px solid #ced4da",
                  borderRadius: ".25rem",
                  width: "100%",
                  background: "#fff"
                }}
              >
                <MenuItem value="">All</MenuItem>
                {props.filterdata &&
                  props.filterdata.map((val) => (
                    <MenuItem value={val.value}>{val.label}</MenuItem>
                  ))}
              </Select>
            </FormControl>

          </>


        </div>
      </div>
    </div>
  )
}
