import { Box, Pagination, PaginationItem, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertsSuccess } from "../../../../common/alert/Alert";
import {
  acceptDeclineExshibition,
  deleteExhibtion,
  fetchExshibitionAdmin,
  handleAddExshibition,
  // fetchCuratorData,
  fetchCuratorDataList,
} from "../../../../State/api/superadminApi";
import { setExshibitiondata } from "../../../../State/Features/superadmin/exshibitionSlice";
// import Pagination from "../Pagination/Pagination";
import Exhibitionmodal from "../Popupdelete/Exhibition/Exhibitionmodal";
import Tablesfivecol from "../Table/Fivecolumn/Tablefivecol";
import Tablesfour from "../Table/Fourcolumn/Tables";
import Moreoption from "../Table/Moreoption/Moreoption";
import Tables from "../Table/sixcolumn/Tables";
import Tableheader from "../Table/Tableheader/Tableheader";
import styles from "./Exhibition.module.css";
import Csvfile from "../../../../downloadFiles/Csvfile";
import AdminTableExhibition from "../Table/sixcolumn/AdminTableExhibition";
import dayjs from "dayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const moreOptions = [
  {
    id: "exhibitionlive",
    label: "Live",
    value: "exhibitionlive",
  },
  {
    id: "exhibitionupcoming",
    label: "Upcoming",
    value: "exhibitionupcoming",
  },
  {
    id: "exhibitionpast",
    label: "Past",
    value: "exhibitionpast",
  },
];
const exhibitionhead = [
  {
    id: "artist_name ",
    label: "Axhibitions Title",
  },
  {
    id: "mobile_number",
    label: "Artist Name",
  },
  {
    id: "email",
    label: "Free/Paid",
  },
  {
    id: "assign_to",
    label: "Start Date",
  },
  {
    id: "type",
    label: "End Date",
  },
  {
    id: "action",
    label: "Action",
  },
];

const exhibitionDeclinehead = [
  {
    id: "artist_name ",
    label: "Exhibition Title",
  },
  {
    id: "mobile_number",
    label: "Artist Name",
  },
  {
    id: "email",
    label: "Free/Paid",
  },
  {
    id: "assign_to",
    label: "Start Date",
  },
  {
    id: "type",
    label: "End Date",
  },
];

const livehead = [
  {
    id: "artist_name ",
    label: "Exhibition's Title",
  },

  {
    id: "like",
    label: "Paid Type",
  },
  {
    id: "created_date",
    label: "Created Date",
  },
  {
    id: "like",
    label: "Start Date",
  },
  {
    id: "followe",
    label: "End Date",
  },
  {
    id: "application_end_date",
    label: "Application End Date",
  },
];

const upcominghead = [
  {
    id: "artist_name ",
    label: "Exhibition Title",
  },
  {
    id: "location",
    label: "Artist Name",
  },
  {
    id: "like",
    label: "Free/Paid",
  },
  {
    id: "like",
    label: "Start Date",
  },
  {
    id: "follow",
    label: "End Date",
  },
];
const sortdata = [
  { label: "By Start Date ↓", value: "low" },
  { label: "By Start Date ↑", value: "high" },
  { label: "By End Date ↓", value: "endlow" },
  { label: "By End Date ↑", value: "endhigh" },
];
const filterdata = [
  { label: "Free", value: "free" },
  { label: "Paid", value: "paid" },
  //  { label: "Rejected", value: "rejected" },
];
export default function Exhibition() {
  const [button, setButton] = useState({ type: "add", text: "Create" });
  const [open, setOpen] = useState(false);
  const [activetab, setAcitvetab] = useState("exhibitionlive");
  const [data, setData] = useState({ is_accepted: "", offset: 1 });
  const [curData, setCurData] = useState({ is_accepted: "Approved" });
  const curatorsData = useSelector((state) => state.curatorData.data);
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  });

  const exshibitiondata = useSelector(
    (state) => state.exhibitionAdminData?.data
  );
  console.log(exshibitiondata, "exshibitiondata--=--");
  const handleMorecategories = (e) => {
    setAcitvetab(e);
  };
  console.log("datafromexhibitaion", data);

  const dispatch = useDispatch();
  // const fetchData = () => {
  //   dispatch(fetchExshibitionAdmin(data))
  // }

  const fetchData = () => {
    dispatch(
      fetchExshibitionAdmin({
        event:
          activetab === "exhibitionlive"
            ? "exhibitionlive"
            : activetab === "exhibitionupcoming"
            ? "exhibitionupcoming"
            : activetab === "exhibitionpast"
            ? "exhibitionpast"
            : "exhibitionpast",
      })
    );
  };
  useEffect(() => {
    fetchData();
  }, [activetab]);

  const loader = useSelector((state) => state.loader);
  console.log(loader);
  useEffect(() => {
    fetchData();
  }, [data]);

  useEffect(() => {
    fetchDataCurator();
  }, [curData]);
  const fetchDataCurator = () => {
    dispatch(fetchCuratorDataList(curData, 100));
  };
  const acceptReject = (id, dt) => {
    dispatch(acceptDeclineExshibition(id, { is_accepted: dt }, fetchData));
  };
  const handleAdd = (dt) => {
    dispatch(handleAddExshibition(dt, setOpen, fetchData));
  };
  const handleDelete = (id) => {
    dispatch(deleteExhibtion(id, fetchData));
  };
  const handleFilter = (event) => {
    const value = event.target.value;
    setCondition({ ...condition, filter: value });
  };


  const [currentPage, setCurrentPage] = useState(1);
            const NoOfItemsPerPage = 10; // Adjust how many items you want per page
          
          
            const handlePageChange = (event, page) => {
              setCurrentPage(page);
            };
  
      const startIndex = (currentPage - 1) * NoOfItemsPerPage;
      const endIndex = startIndex + NoOfItemsPerPage;
      const currentItems = exshibitiondata?.data?.slice(startIndex, endIndex);

  const liveTabData = exshibitiondata?.data?.filter((val) => {
    const currentDate = new Date();
    const startDate = new Date(val.start_date);
    const endDate = new Date(val.end_date);
    const isSameDayEvent =
      startDate.getTime() === endDate.getTime() &&
      startDate.toDateString() === currentDate.toDateString();
    const end_date_registration = dayjs(val.end_date_registration)
      .format("DD-MM-YYYY")
      .includes(condition.search.toLowerCase());
    const start_date = dayjs(val.start_date)
      .format("DD-MM-YYYY")
      .includes(condition.search.toLowerCase());
    const end_date = dayjs(val.end_date)
      .format("DD-MM-YYYY")
      .includes(condition.search.toLowerCase());
    const createdAt = dayjs(val.createdAt)
      .format("DD-MM-YYYY")
      .includes(condition.search.toLowerCase());
    return (
      (isSameDayEvent ||
        (startDate <= currentDate && endDate >= currentDate)) &&
      (val.title.toLowerCase().includes(condition.search.toLowerCase()) ||
        end_date_registration ||
        start_date ||
        end_date ||
        createdAt)
    );
  });

  const handleSearch = (e) => {
    let value = e.target.value;
    setCondition({ ...condition, search: value });
  };

  // const handleSorting = (e) => {
  //   let value = e.target.value
  //   if (value === "low") {
  //     setCondition({ ...condition, sorting: value })
  //     dispatch(
  //       setExshibitiondata({
  //         data:
  //           exshibitiondata.data &&
  //           [...exshibitiondata.data].sort((a, b) => {
  //             if (a.start_date.toLowerCase() < b.start_date.toLowerCase())
  //               return -1
  //           }),
  //         totalCount: exshibitiondata.totalCount,
  //       })
  //     )
  //   } else if (value === "high") {
  //     setCondition({ ...condition, sorting: value })
  //     dispatch(
  //       setExshibitiondata({
  //         data:
  //           exshibitiondata.data &&
  //           [...exshibitiondata.data].sort((a, b) => {
  //             if (a.start_date.toLowerCase() > b.start_date.toLowerCase())
  //               return -1
  //           }),
  //         totalCount: exshibitiondata.totalCount,
  //       })
  //     )
  //   } else if (value === "endlow") {
  //     setCondition({ ...condition, sorting: value })
  //     dispatch(
  //       setExshibitiondata({
  //         data:
  //           exshibitiondata.data &&
  //           [...exshibitiondata.data].sort((a, b) => {
  //             if (a.end_date.toLowerCase() < b.end_date.toLowerCase()) return -1
  //           }),
  //         totalCount: exshibitiondata.totalCount,
  //       })
  //     )
  //   } else if (value === "endhigh") {
  //     setCondition({ ...condition, sorting: value })
  //     dispatch(
  //       setExshibitiondata({
  //         data:
  //           exshibitiondata.data &&
  //           [...exshibitiondata.data].sort((a, b) => {
  //             if (a.end_date.toLowerCase() > b.end_date.toLowerCase()) return -1
  //           }),
  //         totalCount: exshibitiondata.totalCount,
  //       })
  //     )
  //   } else {
  //     fetchData()
  //     setCondition({ ...condition, sorting: value })
  //   }
  // }
  const handleSorting = (e) => {
    let value = e.target.value;
    setCondition({
      ...condition,
      sorting: value,
    });
  };
  return (
    <>
      {open ? (
        <Exhibitionmodal
          handleAdd={handleAdd}
          open={open}
          setOpen={setOpen}
          curatorsData={curatorsData}
        />
      ) : (
        <></>
      )}
      <AlertsSuccess />
      <div className={styles.mains}>
        <div className={styles.compoents}>
          <Tableheader
            handleFilter={handleFilter}
            handleSorting={handleSorting}
            handleSearch={handleSearch}
            filterdata={filterdata}
            sortdata={sortdata}
            condition={condition}
            search={condition.search}
            setOpen={setOpen}

            button={(activetab ==='exhibitionupcoming' ||activetab ==='exhibitionlive')? button:'' }
          />
          <div className={styles.more_download}>
            <Moreoption
              setButton={setButton}
              activetab={activetab}
              moreOptions={moreOptions}
              handleMorecategories={handleMorecategories}
              setData={setData}
            />
            {/* {exshibitiondata && (
              <Csvfile
                exshibitiondata={exshibitiondata && exshibitiondata.data}
              />
            )} */}
          </div>
          {/* 
          {activetab === "request" ? (
            <AdminTableExhibition
              acceptReject={acceptReject}
              activetab={activetab}
              head={exhibitionhead}
              handleDelete={handleDelete}
              data={
                exshibitiondata &&
                exshibitiondata.data
                  // .filter((val) => {
                  //   return val.is_accepted === "Pending"
                  // })
                  .filter((val) =>
                    val.title
                      .toLowerCase()
                      .includes(condition.search.toLowerCase())
                  )
                  .filter((val) => {
                    if (condition.filter === "free") {
                      return val.is_paid === false
                    } else if (condition.filter === "paid") {
                      return val.is_paid === true
                    } else {
                      return val
                    }
                    //  }).filter(val =>{
                    //   if(condition.sorting === 'low'){
                    //     return val
                    //   }
                  })
              }
            /> */}
          {activetab === "exhibitionupcoming" ? (
            <Tablesfivecol
              activetab={activetab}
              head={livehead}
              data={exshibitiondata?.data
                ?.filter((val) => {
                  const currentDate = new Date();
                  const startDate = new Date(val.start_date);
                  const endDate = new Date(val.end_date);
                  const end_date_registration = dayjs(val.end_date_registration)
                    .format("DD-MM-YYYY")
                    .includes(condition.search.toLowerCase());
                  const start_date = dayjs(val.start_date)
                    .format("DD-MM-YYYY")
                    .includes(condition.search.toLowerCase());
                  const end_date = dayjs(val.end_date)
                    .format("DD-MM-YYYY")
                    .includes(condition.search.toLowerCase());
                  const createdAt = dayjs(val.createdAt)
                    .format("DD-MM-YYYY")
                    .includes(condition.search.toLowerCase());
                  // Combine both conditions using logical AND
                  return (
                    startDate > currentDate && // Condition 1: Upcoming exhibitions
                    (val.title
                      .toLowerCase()
                      .includes(condition.search.toLowerCase()) ||
                      end_date_registration ||
                      start_date ||
                      end_date ||
                      createdAt)
                  );
                })
                .filter((item) => {
                  if (condition.filter === "paid") {
                    return item.is_paid === true; // Return only paid items
                  } else if (condition.filter === "free") {
                    return item.is_paid === false; // Return only free items
                  }
                  return true; // If no filter is applied, return all items
                })
                .sort((a, b) => {
                  // Apply sorting based on the selected condition
                  const sorting = condition.sorting || "default"; // Default condition if not set
                  switch (sorting) {
                    case "low":
                      return new Date(a.start_date) - new Date(b.start_date); // Start date ascending
                    case "high":
                      return new Date(b.start_date) - new Date(a.start_date); // Start date descending
                    case "endlow":
                      return new Date(a.end_date) - new Date(b.end_date); // End date ascending
                    case "endhigh":
                      return new Date(b.end_date) - new Date(a.end_date); // End date descending
                    default:
                      return new Date(b.start_date) - new Date(a.start_date); // Default: start_date descending
                  }
                })}
            />
          ) : // ) : activetab === "exshibitiondecline" ? (
          //   <>
          //     <Tablesfivecol
          //       activetab={activetab}
          //       head={exhibitionDeclinehead}
          //       data={
          //         exshibitiondata &&
          //         exshibitiondata.data.filter((val) => {
          //           return (
          //             // val.is_accepted === "Rejected" &&
          //             val.title
          //               .toLowerCase()
          //               .includes(condition.search.toLowerCase())
          //           )
          //         })
          //       }
          //     />{" "}
          //   </>
          activetab === "exhibitionlive" ? (
            <Tablesfour
              activetab={activetab}
              head={livehead}
              data={
                liveTabData
                  ?.filter((item) => {
                    if (condition.filter === "paid") {
                      return item.is_paid === true; // Return only paid items
                    } else if (condition.filter === "free") {
                      return item.is_paid === false; // Return only free items
                    }
                    return true; // If no filter is applied, return all items
                  })
                  .sort((a, b) => {
                    const sorting = condition.sorting || "default"; // Default condition if not set
                    switch (sorting) {
                      case "low":
                        return new Date(a.start_date) - new Date(b.start_date); // Start date ascending
                      case "high":
                        return new Date(b.start_date) - new Date(a.start_date); // Start date descending
                      case "endlow":
                        return new Date(a.end_date) - new Date(b.end_date); // End date ascending
                      case "endhigh":
                        return new Date(b.end_date) - new Date(a.end_date); // End date descending
                      default:
                        return new Date(b.start_date) - new Date(a.start_date); // Default: start_date descending
                    }
                  })
                .slice(startIndex, endIndex)
              }
            />
          ) : (
            <Tablesfivecol
              activetab={activetab}
              head={livehead}
              data={exshibitiondata?.data
                ?.filter((val) => {
                  const currentDate = new Date();
                  const endDate = new Date(val.end_date);

                  // Check if the event ended before today
                  const isPastExhibition = endDate < currentDate;

                  // Convert search term to lowercase for case-insensitive matching
                  const searchTerm = condition.search.toLowerCase();

                  // Check if any of the date fields match the search term
                  const matchesSearch =
                    val.title.toLowerCase().includes(searchTerm) ||
                    dayjs(val.end_date_registration)
                      .format("DD-MM-YYYY")
                      .includes(searchTerm) ||
                    dayjs(val.start_date)
                      .format("DD-MM-YYYY")
                      .includes(searchTerm) ||
                    dayjs(val.end_date)
                      .format("DD-MM-YYYY")
                      .includes(searchTerm) ||
                    dayjs(val.createdAt)
                      .format("DD-MM-YYYY")
                      .includes(searchTerm);

                  return isPastExhibition && matchesSearch;
                })

                .filter((item) => {
                  if (condition.filter === "paid") {
                    return item.is_paid === true; // Return only paid items
                  } else if (condition.filter === "free") {
                    return item.is_paid === false; // Return only free items
                  }
                  return true; // If no filter is applied, return all items
                })
                .sort((a, b) => {
                  // Apply sorting based on the selected condition
                  const sorting = condition.sorting || "default"; // Default condition if not set
                  switch (sorting) {
                    case "low":
                      return new Date(a.start_date) - new Date(b.start_date); // Start date ascending
                    case "high":
                      return new Date(b.start_date) - new Date(a.start_date); // Start date descending
                    case "endlow":
                      return new Date(a.end_date) - new Date(b.end_date); // End date ascending
                    case "endhigh":
                      return new Date(b.end_date) - new Date(a.end_date); // End date descending
                    default:
                      return new Date(b.start_date) - new Date(a.start_date); // Default: start_date descending
                  }
                })}
            />
          )}
           {activetab === "exhibitionlive" &&
        <Box className={styles.pagination_box} sx={{}}>
        <Pagination
          count={Math.ceil(liveTabData?.length / NoOfItemsPerPage)
          } // Total pages
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          renderItem={(item) => (
            <PaginationItem
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "black", // Set the selected background to black
                  color: "white", // Ensure the text color is visible
                  "&:hover": {
                    backgroundColor: "black", // Maintain black on hover
                  },
                },
              }}
              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...item}
            />
          )}
        />
      </Box>}
        </div>
      </div>
    </>
  );
}
