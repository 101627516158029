import React, { useState } from "react";

import Fivecolimg from "../../../../components/Pages/List/Table/Fivecolumn/Fivecolimg/Fivecolimg";
import Tablesfivecol from "../../../../components/Pages/List/Table/Fivecolumn/Tablefivecol";
import Tableheader from "../../../../components/Pages/List/Table/Tableheader/Tableheader";
import styles from "./Commonadminoffer.module.css";
import TableForAdminOffer from "../../../../components/Pages/List/Table/Fivecolumn/Fivecolimg/TableForAdminOffer";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Pagination, PaginationItem } from "@mui/material";
import TableheaderQuote from "../../../../components/Pages/List/Table/Tableheader/TableheaderForQuote";
export default function Commonadminoffer(props) {
  // const [button, setButton] = useState({ type: "add", text: "Add" });
    console.log("Commonadminoffer Tables",props);
  const handleOpen = () => {
    props.setOpen(true)
  }
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust how many items you want per page

  // Function to handle page changes
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  // Slice the data based on current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = props.data?.slice(startIndex, endIndex);
  return (
    <div>
      <div className={styles.tableheader}>
        <TableheaderQuote 
        search={props.search} 
        handleSearch={props.handleSearch} 
        handleFilter={props.handleFilter} 
        condition={props.condition} 
        filterdata={props.filterdata} 
        sortdata={props.sortdata} 
        handleSorting={props.handleSorting} 
        />
      </div>
      {/* <Tablesfivecol activetab={props.activetab} head={props.head} data={props.data} /> */}
      <TableForAdminOffer
        handleOpen={handleOpen}
        activetab={props.activetab}
        head={props.head}
        data={currentItems}
        setOfferId={props.setOfferId}
      />
         <Box className={styles.pagination_box} sx={{}}>
          <Pagination   count={Math.ceil(props.data?.length / itemsPerPage)} // Total pages
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        renderItem={(item) => (
          <PaginationItem
          sx={{
            "&.Mui-selected": {
              backgroundColor: "black", // Set the selected background to black
              color: "white", // Ensure the text color is visible
              "&:hover": {
                backgroundColor: "black", // Maintain black on hover
              },
            },
          }}
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )} />
          </Box>
      {/* <Pagination
        totaldata={props.totalData}
        pagination={props.pagination}
        setPagination={props.setDataArts}
      /> */}
    </div>
  );
}
