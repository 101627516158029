import React, { useEffect, useState } from "react"
import styles from "./Artpage.module.css"
import Artdetails from "./Artdetails/Artdetails"
import Artable from "../Table/Arttable/Artable"
import { useDispatch, useSelector } from "react-redux"
import {
  acceptDeclineArts,
  fetchArtsData,
  setBestSeller,
  setMostPopular,
  fetchOfferData,
} from "../../../../State/api/superadminApi"
import Enterdetails from "../../../../components/Pages/List/Popupdelete/enterDetails/Enterdetails"
import { acceptDeclineCuratorArts } from "../../../../State/api/curatorApi"
import { AlertsSuccess, Errornotify } from "../../../../common/alert/Alert"
import { deleteArtistArt } from "../../../../State/api/artistapi"
import SuperadminOfferpopup from "../../../../gallery/components/popups/artOfferAdmin/SuperAdminOffer"


const artOptions = [
  {
    id: "pending",
    label: "Pending",
    value: "pending",
  },
  {
    id: "approved",
    label: "Approved",
    value: "approved",
  },
  {
    id: "seller",
    label: "Best Seller",
    value: "seller",
  },
  {
    id: "popular",
    label: "Most Popular",
    value: "popular",
  },
  // {
  //   id: "deleted",
  //   label: "Deleted Arts",
  //   value: "deleted",
  // },
  {
    id: "decline",
    label: "Decline",
    value: "decline",
  },
]
const pendinghead = [
  {
    id: "image",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "art_name",
    label: "Art name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "artist_name",
    label: "Artist name",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "assign_to",
    label: "Assign to",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buy",
    label: "Buy",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "rent",
    label: "Rent",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "print",
    label: "Print",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "action",
    label: "Action",
    format: (value) => value.toLocaleString(),
  },
]
const approvedhead = [
  {
    id: "image",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "art_name",
    label: "Art name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "artist_name",
    label: "Artist name",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "buy",
    label: "Buy",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "rent",
    label: "Rent",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "print",
    label: "Print",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "action",
    label: "Action",
    format: (value) => value.toLocaleString(),
  },
]
const sellerhead = [
  {
    id: "image",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "art_name",
    label: "Art name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "artist_name",
    label: "Artist name",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "buy",
    label: "Buy",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "rent",
    label: "Rent",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "print",
    label: "Print",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "action",
    label: "Action",
    format: (value) => value.toLocaleString(),
  },
]
const popularhead = [
  {
    id: "image",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "art_name",
    label: "Art name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "artist_name",
    label: "Artist name",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "buy",
    label: "Buy",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "rent",
    label: "Rent",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "print",
    label: "Print",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "action",
    label: "Action",
    format: (value) => value.toLocaleString(),
  },
]
export default function Artpage() {
  const [page, setPage] = useState("")
  const [detailData, setDetailData] = useState()
  const [data, setData] = useState({ is_accepted: "Pending", offset: 1 })
  const [open, setOpen] = useState(false)
  const [dataOffer, setDataOffer] = useState({ offset: 1 })
  const [artId, setArtId] = useState("")
  const [offerId, setOfferId] = useState("")
  const dispatch = useDispatch()
  const pendingDatas = useSelector((state) => state.artsdata.data)
  console.log("pendingDatas->",pendingDatas)

  const offerData = useSelector((state) => state.offerdata.data)
  const [opens, setOpens] = useState({
    open: false,
    rejectReason: "",
    rejectid: "",
  })
  const fetchData = () => {
    dispatch(fetchArtsData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])

  const fetchOffer = () => {
    dispatch(fetchOfferData(dataOffer))
  }
  useEffect(() => {
    fetchOffer()
  }, [dataOffer])

  const handleRejectArts = (dt) => {
    if (opens.rejectReason.length < 10) {
      Errornotify("Please Enter atleast 10 Character")
    } else {
      dispatch(
        acceptDeclineCuratorArts(
          opens.rejectid,
          { is_accepted: dt, reject_reason: opens.rejectReason },
          fetchData
        )
      )
      setOpens({ open: false, rejectReason: "", rejectid: "" })
    }
  }

  const handleArtsAcceptDecline = (id, data) => {
    dispatch(acceptDeclineArts(id, { is_accepted: data }, fetchData))
  }

  const handleReject = (id) => {
    setOpens({ open: true, rejectid: id })
  }

  const handleBestSeller = (id, data) => {
    dispatch(setBestSeller(id, { is_best_seller: data }, fetchData))
  }
  const handleMostPopular = (id, data) => {
    dispatch(setMostPopular(id, { is_most_popular: data }, fetchData))
  }

  const handleDelete = (id) => {
    dispatch(deleteArtistArt(id, fetchData))
  }

  const handleOpen = () => {
    setOpen(true)
  }
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {opens.open ? (
        <Enterdetails
          text="Reason for Rejection"
          poptype="curator_art_cancel"
          opens={opens}
          setOpens={setOpens}
          handleAcceptRejectArts={handleRejectArts}
        />
      ) : (
        ""
      )}
      {open ? (
        <SuperadminOfferpopup
          open={open}
          setOpen={setOpen}
          offerData={offerData}
          artId={artId}
          offerId={offerId}
          fetchData={fetchData}
        />
      ) : (
        ""
      )}

     
      <Artable
        setPage={setPage}
        artOptions={artOptions}
        popularhead={popularhead}
        popularData={
          pendingDatas &&
          pendingDatas.data.filter((val) => {
            return val.is_most_popular === true
          })
        }
        sellerhead={sellerhead}
        sellerData={
          pendingDatas &&
          pendingDatas.data.filter((val) => {
            return val.is_best_seller === true
          })
        }
        pendinghead={pendinghead}
        pendingData={
          pendingDatas &&
          pendingDatas.data.filter((val) => {
            return val.is_accepted === "Pending"
          })
        }
        approvedhead={approvedhead}
        approvedData={
          pendingDatas &&
          pendingDatas.data.filter((val) => {
            return val.is_accepted === "Approved"
          })
        }
        deletedData={
          pendingDatas &&
          pendingDatas.data.filter((val) => {
            return val.is_deleted === true
          })
        }
        declineData={
          pendingDatas &&
          pendingDatas.data.filter((val) => {
            return val.is_accepted === "Rejected"
          })
        }
        handleArtsAcceptDecline={handleArtsAcceptDecline}
        handleReject={handleReject}
        handleBestSeller={handleBestSeller}
        handleMostPopular={handleMostPopular}
        data={data}
        setData={setData}
        handleDelete={handleDelete}
        handleOpen={handleOpen}
        setArtId={setArtId}
        setOfferId={setOfferId}
      />
    </div>
  )
}
