import { Button, FormControlLabel, MenuItem, Radio, RadioGroup, Container, Grid, Select, TextField, Autocomplete, Dialog, DialogTitle, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./Applyform.module.css";
import ReactQuill from "react-quill";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleApplyProfile } from "../../../State/api/curatorApi";
import { AlertsSuccess, Errornotify, Successnotify } from "../../../common/alert/Alert";
import Header from "../../../components/Pages/Header/Header";
import Navbar from "../../../components/Pages/Navbar/Navbar";
import Footer from "../../../components/Pages/Footer/Footer";
import country from "../../../Country";
import EntermobileArtist from "./mobileNumber/EntermobileArtist";
import api from "../../../State/api/Api";
import { FiUpload } from "react-icons/fi";

const nationalities = [
  { code: "AD", label: "Andorran" },
  { code: "AE", label: "Emirati" },
  { code: "AF", label: "Afghan" },
  { code: "AG", label: "Antiguan or Barbudan" },
  { code: "AI", label: "Anguillian" },
  { code: "AL", label: "Albanian" },
  { code: "AM", label: "Armenian" },
  { code: "AO", label: "Angolan" },
  { code: "AQ", label: "Antarctican" },
  { code: "AR", label: "Argentine" },
  { code: "AS", label: "American Samoan" },
  { code: "AT", label: "Austrian" },
  { code: "AU", label: "Australian" },
  { code: "AW", label: "Aruban" },
  { code: "AX", label: "Ålander" },
  { code: "AZ", label: "Azerbaijani" },
  { code: "BA", label: "Bosnian" },
  { code: "BB", label: "Barbadian" },
  { code: "BD", label: "Bangladeshi" },
  { code: "BE", label: "Belgian" },
  { code: "BF", label: "Burkinabe" },
  { code: "BG", label: "Bulgarian" },
  { code: "BH", label: "Bahraini" },
  { code: "BI", label: "Burundian" },
  { code: "BJ", label: "Beninese" },
  { code: "BL", label: "Barthélemois" },
  { code: "BM", label: "Bermudian" },
  { code: "BN", label: "Bruneian" },
  { code: "BO", label: "Bolivian" },
  { code: "BR", label: "Brazilian" },
  { code: "BS", label: "Bahamian" },
  { code: "BT", label: "Bhutanese" },
  { code: "BV", label: "Bouvet Islander" },
  { code: "BW", label: "Botswana" },
  { code: "BY", label: "Belarusian" },
  { code: "BZ", label: "Belizean" },
  { code: "CA", label: "Canadian" },
  { code: "CC", label: "Cocos Islander" },
  { code: "CD", label: "Congolese (DRC)" },
  { code: "CF", label: "Central African" },
  { code: "CG", label: "Congolese (Republic)" },
  { code: "CH", label: "Swiss" },
  { code: "CI", label: "Ivorian" },
  { code: "CK", label: "Cook Islander" },
  { code: "CL", label: "Chilean" },
  { code: "CM", label: "Cameroonian" },
  { code: "CN", label: "Chinese" },
  { code: "CO", label: "Colombian" },
  { code: "CR", label: "Costa Rican" },
  { code: "CU", label: "Cuban" },
  { code: "CV", label: "Cape Verdean" },
  { code: "CW", label: "Curaçaoan" },
  { code: "CX", label: "Christmas Islander" },
  { code: "CY", label: "Cypriot" },
  { code: "CZ", label: "Czech" },
  { code: "DE", label: "German" },
  { code: "DJ", label: "Djiboutian" },
  { code: "DK", label: "Danish" },
  { code: "DM", label: "Dominican" },
  { code: "DO", label: "Dominican" },
  { code: "DZ", label: "Algerian" },
  { code: "EC", label: "Ecuadorian" },
  { code: "EE", label: "Estonian" },
  { code: "EG", label: "Egyptian" },
  { code: "EH", label: "Western Saharan" },
  { code: "ER", label: "Eritrean" },
  { code: "ES", label: "Spanish" },
  { code: "ET", label: "Ethiopian" },
  { code: "FI", label: "Finnish" },
  { code: "FJ", label: "Fijian" },
  { code: "FK", label: "Falkland Islander" },
  { code: "FM", label: "Micronesian" },
  { code: "FO", label: "Faroe Islander" },
  { code: "FR", label: "French" },
  { code: "GA", label: "Gabonese" },
  { code: "GB", label: "British" },
  { code: "GD", label: "Grenadian" },
  { code: "GE", label: "Georgian" },
  { code: "GF", label: "French Guianese" },
  { code: "GG", label: "Guernsey" },
  { code: "GH", label: "Ghanaian" },
  { code: "GI", label: "Gibraltarian" },
  { code: "GL", label: "Greenlandic" },
  { code: "GM", label: "Gambian" },
  { code: "GN", label: "Guinean" },
  { code: "GP", label: "Guadeloupean" },
  { code: "GQ", label: "Equatorial Guinean" },
  { code: "GR", label: "Greek" },
  { code: "GS", label: "South Georgian" },
  { code: "GT", label: "Guatemalan" },
  { code: "GU", label: "Guamanian" },
  { code: "GW", label: "Bissau-Guinean" },
  { code: "GY", label: "Guyanese" },
  { code: "HK", label: "Hong Konger" },
  { code: "HM", label: "Heard Islander" },
  { code: "HN", label: "Honduran" },
  { code: "HR", label: "Croatian" },
  { code: "HT", label: "Haitian" },
  { code: "HU", label: "Hungarian" },
  { code: "ID", label: "Indonesian" },
  { code: "IE", label: "Irish" },
  { code: "IL", label: "Israeli" },
  { code: "IM", label: "Manx" },
  { code: "IN", label: "Indian" },
  { code: "IO", label: "British Indian Ocean Territory" },
  { code: "IQ", label: "Iraqi" },
  { code: "IR", label: "Iranian" },
  { code: "IS", label: "Icelander" },
  { code: "IT", label: "Italian" },
  { code: "JE", label: "Jerseyman" },
  { code: "JM", label: "Jamaican" },
  { code: "JO", label: "Jordanian" },
  { code: "JP", label: "Japanese" },
  { code: "KE", label: "Kenyan" },
  { code: "KG", label: "Kyrgyzstani" },
  { code: "KH", label: "Cambodian" },
  { code: "KI", label: "Kiribati" },
  { code: "KM", label: "Comoran" },
  { code: "KN", label: "Kittitian or Nevisian" },
  { code: "KP", label: "North Korean" },
  { code: "KR", label: "South Korean" },
  { code: "KW", label: "Kuwaiti" },
  { code: "KY", label: "Caymanian" },
  { code: "KZ", label: "Kazakh" },
  { code: "LA", label: "Laotian" },
  { code: "LB", label: "Lebanese" },
  { code: "LC", label: "Saint Lucian" },
  { code: "LI", label: "Liechtensteiner" },
  { code: "LK", label: "Sri Lankan" },
  { code: "LR", label: "Liberian" },
  { code: "LS", label: "Basotho" },
  { code: "LT", label: "Lithuanian" },
  { code: "LU", label: "Luxembourger" },
  { code: "LV", label: "Latvian" },
  { code: "LY", label: "Libyan" },
  { code: "MA", label: "Moroccan" },
  { code: "MC", label: "Monegasque" },
  { code: "MD", label: "Moldovan" },
  { code: "ME", label: "Montenegrin" },
  { code: "MF", label: "Saint Martin" },
  { code: "MG", label: "Malagasy" },
  { code: "MH", label: "Marshallese" },
  { code: "MK", label: "Macedonian" },
  { code: "ML", label: "Malian" },
  { code: "MM", label: "Burmese" },
  { code: "MN", label: "Mongolian" },
  { code: "MO", label: "Macao" },
  { code: "MP", label: "Northern Mariana Islander" },
  { code: "MQ", label: "Martinican" },
  { code: "MR", label: "Mauritanian" },
  { code: "MS", label: "Montserratian" },
  { code: "MT", label: "Maltese" },
  { code: "MU", label: "Mauritian" },
  { code: "MV", label: "Maldivian" },
  { code: "MW", label: "Malawian" },
  { code: "MX", label: "Mexican" },
  { code: "MY", label: "Malaysian" },
  { code: "MZ", label: "Mozambican" },
  { code: "NA", label: "Namibian" },
  { code: "NC", label: "New Caledonian" },
  { code: "NE", label: "Nigerien" },
  { code: "NF", label: "Norfolk Islander" },
  { code: "NG", label: "Nigerian" },
  { code: "NI", label: "Nicaraguan" },
  { code: "NL", label: "Dutch" },
  { code: "NO", label: "Norwegian" },
  { code: "NP", label: "Nepali" },
  { code: "NR", label: "Nauruan" },
  { code: "NU", label: "Niuean" },
  { code: "NZ", label: "New Zealander" },
  { code: "OM", label: "Omani" },
  { code: "PA", label: "Panamanian" },
  { code: "PE", label: "Peruvian" },
  { code: "PF", label: "French Polynesian" },
  { code: "PG", label: "Papua New Guinean" },
  { code: "PH", label: "Filipino" },
  { code: "PK", label: "Pakistani" },
  { code: "PL", label: "Polish" },
  { code: "PM", label: "Saint-Pierrais or Miquelonnais" },
  { code: "PN", label: "Pitcairn Islander" },
  { code: "PR", label: "Puerto Rican" },
  { code: "PT", label: "Portuguese" },
  { code: "PW", label: "Palauan" },
  { code: "PY", label: "Paraguayan" },
  { code: "QA", label: "Qatari" },
  { code: "RE", label: "Réunionese" },
  { code: "RO", label: "Romanian" },
  { code: "RS", label: "Serbian" },
  { code: "RU", label: "Russian" },
  { code: "RW", label: "Rwandan" },
  { code: "SA", label: "Saudi Arabian" },
  { code: "SB", label: "Solomon Islander" },
  { code: "SC", label: "Seychellois" },
  { code: "SD", label: "Sudanese" },
  { code: "SE", label: "Swedish" },
  { code: "SG", label: "Singaporean" },
  { code: "SH", label: "Saint Helenian" },
  { code: "SI", label: "Slovenian" },
  { code: "SJ", label: "Svalbardian" },
  { code: "SK", label: "Slovak" },
  { code: "SL", label: "Sierra Leonean" },
  { code: "SM", label: "Sammarinese" },
  { code: "SN", label: "Senegalese" },
  { code: "SO", label: "Somali" },
  { code: "SR", label: "Surinamese" },
  { code: "SS", label: "South Sudanese" },
  { code: "ST", label: "Sao Tomean" },
  { code: "SV", label: "Salvadoran" },
  { code: "SX", label: "Sint Maarten" },
  { code: "SY", label: "Syrian" },
  { code: "SZ", label: "Swazi" },
  { code: "TC", label: "Turks and Caicos Islander" },
  { code: "TD", label: "Chadian" },
  { code: "TF", label: "French Southern and Antarctic Lands" },
  { code: "TG", label: "Togolese" },
  { code: "TH", label: "Thai" },
  { code: "TJ", label: "Tajikistani" },
  { code: "TK", label: "Tokelauan" },
  { code: "TL", label: "Timorese" },
  { code: "TM", label: "Turkmen" },
  { code: "TN", label: "Tunisian" },
  { code: "TO", label: "Tongan" },
  { code: "TR", label: "Turkish" },
  { code: "TT", label: "Trinidadian" },
  { code: "TV", label: "Tuvaluan" },
  { code: "TZ", label: "Tanzanian" },
  { code: "UA", label: "Ukrainian" },
  { code: "UG", label: "Ugandan" },
  { code: "UM", label: "U.S. Minor Outlying Islands" },
  { code: "UN", label: "United Nations" },
  { code: "US", label: "American" },
  { code: "UY", label: "Uruguayan" },
  { code: "UZ", label: "Uzbekistani" },
  { code: "VA", label: "Vatican" },
  { code: "VC", label: "Saint Vincentian" },
  { code: "VE", label: "Venezuelan" },
  { code: "VG", label: "British Virgin Islander" },
  { code: "VI", label: "American Virgin Islander" },
  { code: "VN", label: "Vietnamese" },
  { code: "VU", label: "Vanuatuan" },
  { code: "WF", label: "Wallis and Futunan" },
  { code: "WS", label: "Samoan" },
  { code: "YE", label: "Yemeni" },
  { code: "YT", label: "Mayottean" },
  { code: "ZA", label: "South African" },
  { code: "ZM", label: "Zambian" },
  { code: "ZW", label: "Zimbabwean" },
];
const phoneLengthByCountry = {
  ad: 9,    // Andorra
  af: 9,    // Afghanistan
  al: 9,    // Albania
  dz: 9,    // Algeria
  ao: 9,    // Angola
  ag: 10,   // Antigua and Barbuda
  ar: 10,   // Argentina
  am: 8,    // Armenia
  aw: 7,    // Aruba
  au: 9,    // Australia
  at: 10,   // Austria
  az: 9,    // Azerbaijan
  bh: 8,    // Bahrain
  bd: 10,   // Bangladesh
  bn: 7,     // Brunei
  bs: 10,   // Bahamas
  bb: 10,   // Barbados
  by: 9,    // Belarus
  bq: 7,    // Caribbean Netherlands
  be: 9,    // Belgium
  bz: 7,    // Belize
  bj: 8,    // Benin
  bt: 8,    // Bhutan
  bo: 8,    // Bolivia
  ba: 8,    // Bosnia and Herzegovina
  bw: 7,    // Botswana
  br: 11,   // Brazil
  bg: 9,    // Bulgaria
  bf: 8,    // Burkina Faso
  bi: 8,    // Burundi
  kh: 9,    // Cambodia
  cm: 9,    // Cameroon
  ca: 10,   // Canada
  cv: 7,    // Cape Verde
  ci: 8,    // Ivory Coast
  cw: 7,    // Curaçao
  cg: 9,    // Congo - Brazzaville
  cd: 9,    // Congo - Kinshasa
  cf: 8,    // Central African Republic
  td: 8,    // Chad
  cl: 9,    // Chile
  cn: 11,   // China
  co: 10,   // Colombia
  km: 7,    // Comoros
  cr: 8,    // Costa Rica
  hr: 9,    // Croatia
  cu: 8,    // Cuba
  cy: 8,    // Cyprus
  cz: 9,    // Czech Republic
  dk: 8,    // Denmark
  dj: 8,    // Djibouti
  dm: 10,   // Dominica
  do: 10,   // Dominican Republic
  ec: 9,    // Ecuador
  eg: 10,   // Egypt
  sv: 8,    // El Salvador
  gq: 9,    // Equatorial Guinea
  er: 7,    // Eritrea
  ee: 7,    // Estonia
  et: 9,    // Ethiopia
  fj: 7,    // Fiji
  fi: 9,    // Finland
  fr: 9,    // France
  ga: 7,    // Gabon
  gm: 8,    // Gambia
  gp: 9,    // Guadeloupe
  gu: 10,   // Guam
  ge: 9,    // Georgia
  de: 10,   // Germany
  gh: 9,    // Ghana
  gr: 10,   // Greece
  gd: 10,   // Grenada
  gf: 9,    // French Guiana
  gt: 8,    // Guatemala
  gn: 9,    // Guinea
  gw: 9,    // Guinea-Bissau
  gy: 7,    // Guyana
  ht: 8,    // Haiti
  hn: 8,    // Honduras
  hk: 8,    // Hong Kong
  hu: 9,    // Hungary
  is: 7,    // Iceland
  in: 10,   // India
  id: 10,   // Indonesia
  io: 7,   // British Indian Ocean Territory
  ir: 10,   // Iran
  iq: 10,   // Iraq
  ie: 9,    // Ireland
  il: 9,    // Israel
  it: 10,   // Italy
  jm: 7,    // Jamaica
  jp: 10,   // Japan
  jo: 9,    // Jordan
  kz: 10,   // Kazakhstan
  kp: 9,    // North Korea
  ke: 10,   // Kenya
  ki: 7,    // Kiribati
  ks: 8,    // Kosovo
  kn: 7,    // Saint Kitts and Nevis
  kw: 8,    // Kuwait
  kg: 9,    // Kyrgyzstan
  la: 9,    // Laos
  lv: 8,    // Latvia
  lb: 8,    // Lebanon
  ls: 8,    // Lesotho
  lr: 7,    // Liberia
  ly: 9,    // Libya
  li: 7,    // Liechtenstein
  lt: 8,    // Lithuania
  lu: 9,    // Luxembourg
  lc: 7,    // Saint Lucia
  mo: 8,    // Macau
  mg: 10,   // Madagascar
  mw: 9,    // Malawi
  my: 10,   // Malaysia
  mv: 7,    // Maldives
  ml: 8,    // Mali
  mk: 8,    // Macedonia
  mq: 9,    // Martinique
  mt: 8,    // Malta
  mh: 7,    // Marshall Islands
  mr: 8,    // Mauritania
  mu: 8,    // Mauritius
  mx: 10,   // Mexico
  fm: 7,    // Micronesia
  md: 8,    // Moldova
  mc: 9,    // Monaco
  mn: 8,    // Mongolia
  me: 8,    // Montenegro
  ma: 9,    // Morocco
  mz: 9,    // Mozambique
  mm: 8,    // Myanmar
  na: 9,    // Namibia
  nc: 8,    // New Caledonia
  nr: 7,    // Nauru
  np: 10,   // Nepal
  nl: 9,    // Netherlands
  nz: 9,    // New Zealand
  ni: 8,    // Nicaragua
  ne: 8,    // Niger
  ng: 10,   // Nigeria
  no: 8,    // Norway
  om: 8,    // Oman
  pk: 10,   // Pakistan
  pw: 7,    // Palau
  pa: 7,    // Panama
  pf: 8,    // French Polynesia
  pg: 8,    // Papua New Guinea
  py: 9,    // Paraguay
  pe: 9,    // Peru
  ph: 10,   // Philippines
  pl: 9,    // Poland
  pt: 9,    // Portugal
  ps: 9,    // Palestine
  pr: 10,   // Puerto Rico
  qa: 8,    // Qatar
  ro: 10,   // Romania
  ru: 10,   // Russia
  re: 9,    // Reunion
  rw: 9,    // Rwanda
  ws: 7,    // Samoa
  sa: 9,    // Saudi Arabia
  sn: 9,    // Senegal
  sm: 9,    // San Marino
  st: 7,    // Sao Tome and Principe
  ss: 9,    // South Sudan
  sz: 8,    // Swaziland
  rs: 9,    // Serbia
  sc: 7,    // Seychelles
  sl: 8,    // Sierra Leone
  sg: 8,    // Singapore
  sk: 9,    // Slovakia
  si: 8,    // Slovenia
  sb: 7,    // Solomon Islands
  so: 7,    // Somalia
  za: 9,    // South Africa
  kr: 10,   // South Korea
  es: 9,    // Spain
  lk: 9,    // Sri Lanka
  sd: 9,    // Sudan
  sr: 7,    // Suriname
  se: 9,    // Sweden
  ch: 9,    // Switzerland
  sy: 9,    // Syria
  tw: 9,    // Taiwan
  tj: 9,    // Tajikistan
  tz: 9,    // Tanzania
  th: 9,    // Thailand
  tl: 7,    // Timor-Leste
  tg: 8,    // Togo
  to: 7,    // Tonga
  tt: 10,   // Trinidad and Tobago
  tn: 8,    // Tunisia
  tr: 10,   // Turkey
  tm: 8,    // Turkmenistan
  tv: 6,    // Tuvalu
  ug: 9,    // Uganda
  ua: 9,    // Ukraine
  vc: 7,    // Saint Vincent and the Grenadines
  va: 9,    // Vatican CityS
  ae: 9,    // United Arab Emirates
  gb: 10,   // United Kingdom
  us: 10,   // United States
  uy: 9,    // Uruguay
  uz: 9,    // Uzbekistan
  vu: 7,    // Vanuatu
  ve: 7,    // Venezuela
  vn: 10,   // Vietnam
  ye: 9,    // Yemen
  zm: 9,    // Zambia
  zw: 9     // Zimbabwe
};
export default function Applyform({
  apply,
  handleChangeApplyData,

  setApply,
}) {
  const [errors, setErrors] = useState({});

  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email_errors, setEmail_Errors] = useState({});
  const [isEmailValid, setIsEmailValid] = useState(true);


  const [image, setImage] = useState("");
  const [src, setSrc] = useState({
    profile: "",
    id: "",
    cover: "",
    cv: ""
  });


  const handleChangeImage = (e) => {
    const name = e.target.name;
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileUrl = URL.createObjectURL(file);

      if (name === "ID_proof") {
        // Check if file is a PDF

        const allowedTypes = [
          "application/pdf",
          "application/msword", // For .doc files
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // For .docx files
          "image/jpeg",
          "image/png"
        ];
        const fileSizeInMB = file.size / (1024 * 1024);
        if (!allowedTypes.includes(file.type)) {
          Errornotify("Please upload a file in PDF, Word, or image format (JPG, JPEG, PNG)");
          e.target.value = "";
        } else if (fileSizeInMB > 2) {
          Errornotify("File size should be less than 2MB");
          e.target.value = "";
        } else {
          setSrc({ ...src, id: fileUrl });
          setApply({ ...apply, ID_proof: file });
        }

        // Check if file size exceeds 500KB
      } else if (name === "profile_image") {
        if (file.type !== "image/png" && file.type !== "image/jpeg") {
          Errornotify("Please upload an image in png/jpeg format");
          e.target.value = ""; // Clear input field for invalid file type
        } else if (file.size > 2 * 1024 * 1024) {
          // Check if the file size exceeds 1 MB
          Errornotify("Please upload an image less than 2MB");
          e.target.value = ""; // Clear input field for large file
        } else {
          setSrc({ ...src, profile: fileUrl });
          setApply({ ...apply, profile_image: file });
        }
      } else if (name === "cover") {
        if (file.type !== "image/png" && file.type !== "image/jpeg") {
          Errornotify("Please upload an image in png/jpeg format");
          e.target.value = ""; // Clear input field for invalid file type
        } else if (file.size > 2 * 1024 * 1024) {
          // Check if the file size exceeds 1 MB
          Errornotify("Please upload an image less than 2MB");
          e.target.value = ""; // Clear input field for large file
        } else {
          setSrc({ ...src, cover: fileUrl });
          setApply({ ...apply, cover_image: file });
        }
      }

      else if (name === "CV") {
        if (
          file.type !== "application/pdf" &&
          file.type !== "application/msword" &&
          file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          Errornotify("Please upload a file in PDF or Word document format");

          e.target.value = ""; // Clear input field for invalid file type
        } else if (file.size > 10 * 1024 * 1024) { // 10 MB in bytes
          Errornotify("File size should be less than or equal to 10 MB");
          e.target.value = "";
        }

        else {
          setSrc({ ...src, cv: fileUrl });
          setApply({ ...apply, CV: file });
        }
      }

      setImage(file);
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!apply.first_name) newErrors.first_name = "First name is required";
    if (!apply.last_name) newErrors.last_name = "Last name is required";
    if (!apply.curator_email) {
      newErrors.curator_email = "Email is required.";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(apply.curator_email)) {
      newErrors.curator_email = "Please enter a valid email";
    }

    if (!apply.phone) {
      newErrors.phone = "Phone number is required";
    }
    if (apply.phone.startsWith("91") && apply.phone.length <= 2) {
      newErrors.phone = "Please Enter phone number more than 4 characters";
    }
    if (!apply.qualification || apply.qualification === "<p><br></p>") {
      newErrors.qualification = "Professional Qualification is required";
    }
    if (!apply.shows_curated || apply.shows_curated === "<p><br></p>") {
      newErrors.shows_curated = "This Field is required";
    }

    if (!apply.achievement || apply.achievement === "<p><br></p>") {
      newErrors.achievement = "Achievement is required";
    }
    if (!apply.country) newErrors.country = "Country is required";
    if (!apply.nationality) newErrors.nationality = "Nationality is required";
    if (!apply.CV) newErrors.CV = "Upload profile is required";
    if (!apply.ID_proof) newErrors.ID_proof = "ID proof upload is required";

    if (!apply.profile_image) newErrors.profile_image = "Image profile is required";
    if (!apply.cover_image) newErrors.cover_image = "Background Cover Image is required";


    if (!apply.completion) {
      newErrors.completion = "Please confirm the information";
    } else if (apply.completion === "No") {
      newErrors.completion = "Please Select Yes to confirm the information is accurate";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState("");


  const [otpError, setOtpError] = useState("");

  const [emailfield, setEmailfield] = useState(true)

  const handleSubmitOtp = async () => {
    // Validate OTP input
    if (!otp) {
      setOtpError("OTP is required");
      return;
    }

    try {
      // Make API call
      const response = await api.applicationApi(
        "api/user/otpValidationAndUpdateStaus",
        "PATCH",
        { email: apply.curator_email, role: "curator", otp }
      );

      const { success, message } = response.data;

      if (message === "Email is already verified.") {
        // Handle already verified email
        Errornotify("Email is already verified.");
        setIsModalOpen(false);
      } else if (!success) {
        // Handle invalid OTP
        // Errornotify("Invalid OTP. Please try again.");
        setOtpError("Invalid OTP");
      } else {
        // OTP verified successfully
        Successnotify("OTP Verified Successfully");
        setIsModalOpen(false);
        setEmailfield(false);
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("Error during OTP validation:", error);
      setOtpError("An error occurred. Please try again.");
    }
  };

  const handleCheckEmailExistOrNot = async () => {
    const { data } = await api.applicationApi("api/user/checkEmailIsExistOrNot", "POST", { email: apply.curator_email, role: "curator" })

    if (data.success === false) {
      setEmail_Errors({ ...email_errors, email: "This email is already registered. Please use a different email address." })

      setIsEmailValid(false);
    } else {
      setEmail_Errors({ ...email_errors, email: "" })
      setIsEmailValid(true);
      // setIsModalOpen(true); 
    }
  }

  const handleEmailValidation = (email) => {
    // Regex to validate email ending with .com
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) && email.endsWith(".com");
  };

  // useEffect(() => {
  //   if ( apply.curator_email && handleEmailValidation(apply.curator_email)) {
  //  handleCheckEmailExistOrNot()
  //   }
  // },[apply.curator_email])

  const handleUpdateProfiles = () => {


    if (validate() && error.length === 0 && isEmailValid) {

      dispatch(handleApplyProfile(apply, navigate));

    }
  };

  const handleChangeNumber = (value, country) => {
    // Get country code and expected length
    const countryCode = country.countryCode; // e.g., 'in'
    const dialCode = country.dialCode; // e.g., '91'
    const expectedLength = phoneLengthByCountry[countryCode]; // e.g., 10 for India

    // Clean the phone number input, stripping non-numeric characters
    let phoneNumber = value.replace(/[^0-9]/g, "");

    // Remove the dial code from the phone number before validation
    if (phoneNumber.startsWith(dialCode)) {
      phoneNumber = phoneNumber.slice(dialCode.length);
    }

    // Limit the input to the expected length
    if (expectedLength && phoneNumber.length === expectedLength) {
      phoneNumber = phoneNumber.slice(0, expectedLength);
    }

    // Construct the formatted value with the country dial code
    const formattedValue = `+${dialCode}${phoneNumber}`;

    // Update the phone state with the formatted value
    setApply({ ...apply, phone: formattedValue });

    // Validate length for error display
    if (expectedLength && phoneNumber.length !== expectedLength) {
      setError(`Phone number must be ${expectedLength} digits long for this country code`);
    } else {
      setError(""); // Clear error if the length is valid
    }
  };

  return (
    <>
      <Header />
      <Navbar />
      <AlertsSuccess />
      <div className={styles.mains}>
        <Dialog open={isModalOpen}>
          <DialogTitle>OTP Verification</DialogTitle>
          <DialogContent>
            <TextField
              label="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              fullWidth
              margin="normal"
              error={otpError !== ""}
              helperText={otpError}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmitOtp} // Submit OTP on button click
            >
              Submit OTP
            </Button>
          </DialogContent>
        </Dialog>
        <Container maxWidth="xl" sx={{
          paddingLeft: { xs: '16px', sm: '24px', md: '60px', lg: '70px', xl: '100px' },
          paddingRight: { xs: '16px', sm: '24px', md: '60px', lg: '70px', xl: '100px' },
        }}>
          <div className={`${styles.text_center} ${styles.max_width1}`}>
            <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
            <h3 className={styles.sec_title}>Personal Details</h3>
          </div>
          <Grid container spacing={2} sx={{
            justifyContent: "center",
            alignItems: "center",
          }}>
            <Grid item xs={12} sm={10} md={8}>
              <div className={styles.components}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                    <div className="form-group">
                  <label>
                    What is your first name? *
                  </label>
                  <input
                    className="form-control"
                    placeholder="Enter your first name"
                    name="first_name"
                    value={apply.first_name}
                    onChange={handleChangeApplyData}
                    variant="outlined"

                    fullWidth
                  />
                  {errors.first_name && <small style={{ color: 'red' }}>{errors.first_name}</small>}
                </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                    <div className="form-group">
                  <label>     What is your last name? *</label>
                  <input
                    className="form-control"
                    placeholder="Enter your last name"
                    name="last_name"
                    value={apply.last_name}
                    onChange={handleChangeApplyData}
                    variant="outlined"
                    // error={!!errors.last_name}
                    // helperText={errors.last_name}
                    fullWidth
                  />
                  {errors.last_name && <small style={{ color: 'red' }}>{errors.last_name}</small>}
                </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                    <div className="form-group">
                  <label>
                    What is your phone number? *
                  </label>
                  <EntermobileArtist value={apply.phone}
                    handleChangeNumber={(value, country) => handleChangeNumber(value, country)} />

                  {errors.phone && <small style={{ color: 'red' }}>{errors.phone}</small>}
                  {!errors?.phone && error && <small style={{ color: "red"}}>{error}</small>}
                </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                    <div className="form-group">
                  <label>What is your email address? *</label>
                  <input
                    className={`${styles.input_top} form-control ${emailfield === false ? 'disabled-input' : ''}`}
                    placeholder="Enter your email address"
                    name="curator_email"
                    disabled={emailfield === false ? true : false}
                    value={apply.curator_email}
                    onChange={handleChangeApplyData}
                    variant="outlined"

                    fullWidth
                  />
                  {errors.curator_email && !handleEmailValidation(apply.curator_email) && (

                    <small style={{ color: 'red' }}>{errors.curator_email}</small >)}

                  {!errors?.curator_email && email_errors?.email && (
                    <small
                      style={{ color: "red"}}
                    >
                      {email_errors.email}
                    </small>
                  )}
                </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                    <div className="form-group">
                  <label>Please write about yourself, education, art qualifications, achievements, etc * </label>
                  <ReactQuill
                    className="reactQuilStle"

                    value={apply.achievement}
                    onChange={(value) =>
                      handleChangeApplyData({
                        target: { name: "achievement", value },
                      })
                    }
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                        ["clean"],
                      ],
                      clipboard: {
                        matchVisual: false,
                      },
                    }}
                    formats={[
                      "header",
                      "font",
                      "size",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "list",
                      "bullet",
                      "indent",

                    ]}
                  />
                  {errors.achievement && <small style={{ color: 'red' }}>{errors.achievement}</small>}
                </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                    <div className="form-group">
                  <label>
                    Professional Qualifications *
                  </label>
                  <ReactQuill
                    className="reactQuilStle"
                    value={apply.qualification}
                    onChange={(value) =>
                      handleChangeApplyData({
                        target: { name: "qualification", value },
                      })
                    }
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                        ["clean"],
                      ],
                      clipboard: {
                        matchVisual: false,
                      },
                    }}
                    formats={[
                      "header",
                      "font",
                      "size",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "list",
                      "bullet",
                      "indent",

                    ]}
                  />
                  {errors.qualification && <small style={{ color: 'red' }}>{errors.qualification}</small>}
                </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                    <div className="form-group">
                  <label>Shows Curated * </label>
                  <ReactQuill
                    className="reactQuilStle"
                    value={apply.shows_curated}
                    onChange={(value) =>
                      handleChangeApplyData({
                        target: { name: "shows_curated", value },
                      })
                    }
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                        ["clean"],
                      ],
                      clipboard: {
                        matchVisual: false,
                      },
                    }}
                    formats={[
                      "header",
                      "font",
                      "size",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "list",
                      "bullet",
                      "indent",

                    ]}
                  />
                  {errors.shows_curated && <small style={{ color: 'red' }}>{errors.shows_curated}</small>}
                </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                    <div className="form-group">
                  <label>
                  Upload your profile detailing your experience? * {" "}
                  <small className="wrtsmall">
                            (File format in PDF or Word, Max Size: 10MB)
                          </small>
                  </label>
                  <div className={styles.preview_img}>
                    {apply.CV === "" ? (
                      <div className={styles.img_box}>
                        <div
                          htmlFor="input"
                          style={{ textAlign: "center" }}
                          onClick={handleChangeImage}
                        >
                          <input
                            name="CV"
                            onChange={handleChangeImage}
                            type="file"
                            id="CV"
                            hidden
                            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          />
                          <label htmlFor="CV" className={styles.labelv}>
                            <FiUpload />
                            <p>Upload Your Profile</p>
                          </label>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className={styles.img_box}>
                          <div
                            htmlFor="input"
                            style={{ textAlign: "center" }}
                            onClick={handleChangeImage}
                          >
                            <input
                              name="CV"
                              onChange={handleChangeImage}
                              type="file"
                              id="CV"
                              hidden
                              accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            />
                            <label htmlFor="CV" className={styles.labelv}>
                              <FiUpload />
                              <p>{apply?.CV?.name}</p>
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className={styles.file_div}></div>
                  {errors.CV && <small style={{ color: 'red' }}>{errors.CV}</small>}
                </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                    <div className="form-group">
                  <label className={styles.input_label}>
                  Upload your ID (i.e. Emirates ID, National ID, Aadhar Card, Passport, Driving License etc) *
                    <small className="wrtsmall">
                    (File format: PDF/PNG/JPEG/, Max Size: 2MB)
                    </small>
                  </label>
                  <div className={styles.preview_img}>
                    {apply.ID_proof === "" ? (
                      <div className={styles.img_box}>
                        <div
                          htmlFor="input"
                          style={{ textAlign: "center" }}
                          onClick={handleChangeImage}
                        >
                          <input
                            name="ID_proof"
                            onChange={handleChangeImage}
                            type="file"
                            id="ID_proof"
                            hidden
                            accept=".pdf, .jpeg, .jpg, .png, .doc, .docx"

                          />
                          <label htmlFor="ID_proof" className={styles.labelv}>
                            <FiUpload />
                            <p>Upload Your ID Proof</p>
                          </label>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className={styles.img_box}>
                          <div
                            htmlFor="input"
                            style={{ textAlign: "center" }}
                            onClick={handleChangeImage}
                          >
                            <input
                              name="ID_proof"
                              onChange={handleChangeImage}
                              type="file"
                              id="ID_proof"
                              hidden
                              accept=".pdf, .jpeg, .jpg, .png, .doc, .docx"
                            />
                            <label htmlFor="ID_proof" className={styles.labelv}>
                              <FiUpload />
                              <p>{apply?.ID_proof?.name}</p>
                            </label>
                          </div>
                        </div>
                      </>
                    )}


                  </div>

                  <div className={styles.file_div}></div>
                  {errors.ID_proof && (
                    <small style={{ color: 'red' }}>{errors.ID_proof}</small>
                  )}
                </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="form-group">
                                    <label>Upload your Profile Image (Your Photo) *
                                    <small className="wrtsmall">(File format: PNG/JPEG, Dimension: 200px - 200px, Max Size: 2MB)</small>
                                    </label>
                                    <div className={styles.preview_img} style={{width:'200px',height:"200px"}}>
                                    {src.profile === "" ? (
                                      <div className={styles.img_box} style={{width:'200px',height:'200px'}}>
                                        <div
                          htmlFor="input"
                          style={{ textAlign: "center" }}
                          onClick={handleChangeImage}
                        >
                          <input
                            name="profile_image"
                            onChange={handleChangeImage}
                            type="file"
                            id="profile_image"
                            hidden
                            accept="image/*"
                          />
    {apply?.profile_image instanceof File ? (
      <img
        name="profile_image"
        src={URL.createObjectURL(apply.profile_image)}
        alt="img"
        className={styles.img_view}
      />
    ) : (
      <>
      <label htmlFor="profile_image" className={styles.labelv}>
        <FiUpload />
        <p>Upload Your Profile Image</p>
        </label>
      </>
    )}
  
                        </div>
                                      </div>
                                    ) : (
                                      <>
                        <input
                          onChange={handleChangeImage}
                          name="profile_image"
                          type="file"
                          id="profile_image"
                          hidden
                          accept="image/*"
                        />
                        <label htmlFor="profile_image">
                          <img
                            src={src.profile}
                            alt="img"
                            id="img"
                            style={{ width: "200px", height: "200px" }}
                            className={image ? styles.img_view : styles.img_view_hide}
                          />
                        </label>
                      </>
                                    )}
                                    <div className={styles.file_div}></div>
                                  </div>
                                  {errors?.profile_image && !apply.profile_image?.name ? (
                    <small style={{ color: 'red' }}>
                      {errors?.profile_image}
                    </small>
                  ) : null}
                                  </div>
                    
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                    <div className="form-group">
                  <label>
                  Upload Cover image (It can be horizontal artwork or collage) *
                  <small className="wrtsmall">(File format: PNG/JPEG, Dimension: 1520px - 275px, Max Size: 2MB)</small>
                  </label>
                  <div>


                    <div className={styles.preview_img}>
                      <input
                        name="cover"
                        onChange={handleChangeImage}
                        type="file"
                        id="cover"
                        hidden
                        accept="image/*"
                      />

                      {/* If no cover image is provided, show upload button */}
                      {!apply?.cover_image ? (
                        <div className={styles.img_box} style={{ height: "275px" }}>
                          <div style={{ textAlign: "center" }} onClick={handleChangeImage}>
                            <label htmlFor="cover" className={styles.labelv}>
                              <FiUpload />
                              <p>{!apply.cover_image && "Upload Cover Image"}</p>
                            </label>

                          </div>
                        </div>
                      ) : (
                        /* If cover image exists, show the selected image */
                        <label htmlFor="cover">
                          <img
                            src={
                              apply.cover_image instanceof File
                                ? URL.createObjectURL(apply.cover_image)
                                : apply.cover_image
                            }
                            alt="Cover"
                            className={apply.cover_image ? styles.img_view : styles.img_view_hide}
                          />

                        </label>
                      )}
                    </div>

                    {errors?.cover_image && !apply.cover_image?.name ? (
                      <small style={{ color: 'red' }}>
                        {errors?.cover_image}
                      </small>
                    ) : null}
                  </div>
                </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                    <div className="form-group">
                  <label>Enter your country of residence *</label>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    name="country"
                    value={apply.country}
                    onChange={handleChangeApplyData}
                    fullWidth
                    sx={{
                      "& .MuiSelect-select": {
                          padding: "7.5px 4px 7.5px 5px",
                          paddingLeft:".75rem",
                          fontSize:"14px"// Adjust padding as needed
                        },
                        "& .MuiAutocomplete-input":{
                          paddingLeft:"0 !important"
                        },
                      width: "100%", 
                      border: "none",
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {country.map((c) => (
                      <MenuItem key={c.label} value={c.label}>
                        {c.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.country && (
                    <small style={{ color: 'red' }}>{errors.country}</small>
                  )}
                </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                    <div className="form-group">
                  <label>
                    What is your nationality? *
                  </label>
                  <Autocomplete
                    id="combo-box-demo"
                    disableClearable
                    options={nationalities.map((vl) => vl.label)}
                    name="nationality"
                    value={apply.nationality}
                    onChange={(event, newValue) => {
                      setApply({ ...apply, nationality: newValue });
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                          padding: "0px",
                          paddingLeft:".75rem", 
                          fontSize:"14px"// Adjust padding as needed
                        },
                        "& .MuiAutocomplete-input":{
                          paddingLeft:"0 !important"
                        },
                      width: "100%", 
                      border: "none",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ border: "none", color: "#636365" }}
                        placeholder="Search"
                      />
                    )}
                  />
                  {errors?.nationality && !apply.nationality ? (
                    <small style={{ color: 'red' }}>
                      {errors?.nationality}
                    </small>
                  ) : null}
                </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                    <div className="form-group">
                  <label className={styles.input_label}>
                    I confirm that the information given in this form is true,
                    complete and accurate *
                  </label>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={apply.completion}
                    onClick={handleChangeApplyData}
                    name="completion"
                    sx={{ display: "flex", flexDirection: "row", mt: 0.8 }}
                    className={styles.radio_button}
                  >
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                  {errors?.completion ? (
                    <small style={{ color: 'red' }}>{errors.completion}</small>
                  ) : null}
                </div>
                    </Grid>
                    </Grid>
                <div className={styles.submit_btn}>
                  <button
                    onClick={() => {

                      handleUpdateProfiles();

                    }}
                    // onClick={handleUpdateProfiles}
                    variant="contained"
                    className="apply_btn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>

      </div>
      <Footer/>
    </>
  );
}
