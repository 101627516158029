import { storeData } from "../Features/superadmin/subscriptionSlice"
import { setBuyerdata } from "../Features/superadmin/buyerSlice"
import api from "./Api"
import { setBtnLoader, setLoader } from "../Features/common/loaderSlice"
import { useSelector } from "react-redux"
import { Errornotify, Successnotify } from "../../common/alert/Alert"
import { categoryData } from "../Features/superadmin/categorySlice"
import { menuData } from "../Features/superadmin/menuSlice"
import { submenuData } from "../Features/superadmin/subMenuSlice"
import { setArtisttypedata } from "../Features/superadmin/artisttypeSlice"
import { setCuratorEarningdata } from "../Features/superadmin/curatorEarningSlice"
import { setArtistdata } from "../Features/superadmin/ArtistSlice"
import { setMenuSubMenudata } from "../Features/superadmin/mainMenuSubMenuSlice"
import { setGetallMenuSubMenudata } from "../Features/superadmin/getallmenusubmenuSlice"
import { setCuratordata } from "../Features/superadmin/curatorSlice"
import { setsubscriptionFeature } from "../Features/superadmin/subscriptionfeatureSlice"
import { setcareerdetails } from "../Features/superadmin/careerdetailsSlice"
import { setsubscriptionArtist } from "../Features/superadmin/subscriptionArtistSlice"
import { setArtistprofiledata } from "../Features/superadmin/artistprofileSlice"
import { setArtsdata } from "../Features/superadmin/artsSlice"
import { setAffiliatedata } from "../Features/superadmin/affiliateSlice"
import { setCompaingnsdata } from "../Features/superadmin/compaingnSlice"
import { setCareerdata } from "../Features/superadmin/careerSlice"
import { setPrintPricingdata } from "../Features/superadmin/printPricingSlice"
import { setEnquirydata } from "../Features/superadmin/enquirySlice"
import { setTestimonialdata } from "../Features/superadmin/testimonialSlice"
import { setBlogdata } from "../Features/superadmin/blogSlice"
import { setBlogSlugdata } from "../Features/superadmin/blogSliceSlug"
import { setItemtype } from "../Features/superadmin/itemtypeSlice"
import { setPressrelease } from "../Features/superadmin/pressreleaseSlice"
import { setFaqlist } from "../Features/superadmin/faqlistSlice"
import { setAffiliateprofiledata } from "../Features/superadmin/affiliateprofileSlice"
import { setAllAffiliateLinkdata } from "../Features/superadmin/getAllaffiliateLinkSlice"
import { setSubMenuByMenuiddata } from "../Features/superadmin/submenuByMenuidSlice"
import { setHeadingByMenudata } from "../Features/superadmin/headingByMenuSlice"
import { setArtsprofiledata } from "../Features/superadmin/artsproflieSlice"
import { setAffiliateEarningdata } from "../Features/superadmin/affiliateearningSlice"
import { setOfferdata } from "../Features/superadmin/offerSlice"
import { setGiftcarddata } from "../Features/superadmin/giftcardSlice"
import { setHerodata } from "../Features/superadmin/herosectionSlice"
import { setPolicydata } from "../Features/superadmin/policySlice"
import { setDisputedata } from "../Features/superadmin/disputeSlice"
import { setGallery } from "../Features/superadmin/gallerySlice"
import { setPermission } from "../Features/superadmin/permissionSlice"
import { setExshibitiondata } from "../Features/superadmin/exshibitionSlice"
import { setAddMarketingdata } from "../Features/superadmin/addMarketingDataSlice"
import { setExhibitiondata } from "../Features/superadmin/exhibitionAdminSlice"
import { setAuctionData } from "../Features/superadmin/auctionSlice"
import { setSubadmin } from "../Features/superadmin/subadminSlice"
import { setExhibitionalldata } from "../Features/common/exhibitionartdetailSlice"
import { setOrderdata } from "../Features/superadmin/orderSlice"
import { removeUser } from "../Features/common/userSlice"
import { setCuratordetailsdata } from "../Features/superadmin/curatorDetailsSlice"
import { setCuratorExhibitiondetailsdata } from "../Features/superadmin/curatorExhibitionDetailsSlice"
import { setRecreateData } from "../Features/Artist/recreateSlice"
import { setCuratorGalleryData } from "../Features/superadmin/CuratorGallerySlice"
import { setGalleryprofiledata } from "../Features/superadmin/galleryprofileSlice"
import { setNotificationdata } from "../Features/superadmin/notificationsSlice"
import { setuserenquirydata } from "../Features/superadmin/userenquirySlice"
import { setEnquiryByEnquiryId } from "../Features/superadmin/EnquiryByEnquiryIdSlice"
import { enquiryreplybyiddata, setenquiryreplybyiddata } from "../Features/superadmin/enquiryReplyByIdSlice"

// change password

export function handlechangePassword(data, handleClose) {
  return async function addsubscriptionFeatureThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/user/updatePassword`,
        "POST",
        { oldPassword: data.oldPassword, newPassword: data.newPassword }
      )
      if (apiResponse.status === 200) {
        Successnotify("Password Changed Successfully!.")
        handleClose()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)

          // return 401;
        } else {
          Errornotify("Invalid password")

          // return false;
        }
      }
    } catch (err) {}
  }
}
// SEND OTP API
export function handleSendOTP(data, setType) {
  return async function addsubscriptionFeatureThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/user/userForgotPassword`,
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        setType("otp_verification")
        Successnotify(apiResponse.data.message)
        localStorage.setItem("art_mail_otp", apiResponse.data.data.otp)
        localStorage.setItem("art_mail_email", data.email)
        localStorage.setItem("art_mail_role", data.role)
        localStorage.setItem("art_mail_dp", "otp_verification")
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          // return 401;
        } else {
          Errornotify(apiResponse.data.message)
          // return false;
        }
      }
    } catch (err) {}
  }
}
export function handleResePass(data, navigate) {
  return async function addsubscriptionFeatureThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/user/userResetPassword`,
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        localStorage.removeItem("art_mail_email")
        localStorage.removeItem("art_mail_role")
        localStorage.removeItem("art_mail_dp")
        navigate("/login")
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          // return 401;
        } else {
          Errornotify(apiResponse.data.message)
          // return false;
        }
      }
    } catch (err) {}
  }
}
// Art api's
// curator panel also call art api
// GALLERY PANEL ALSO

export function fetchArtsData(data) {
  console.log("Artist data",data);  
  return async function fetchCuratotDataThunk(dispatch) {   
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/art/fetchArts",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        console.log("Artist Arts",apiResponse.data.data);        
        dispatch(setArtsdata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {}
  }
}
export function fetchArtsDataForSubscription() {
  return async function fetchCuratotDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi("api/art/fetchArts", "POST")
      if (apiResponse.status === 200) {
        localStorage.setItem(
          "count_of_arts",
          JSON.stringify(apiResponse.data.data.totalCount)
        )

        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {}
  }
}
export function acceptDeclineArts(id, data, fetchData) {
  return async function blockArtistDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/art/acceptDeclineArt/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function fetchArtsProfileData(id) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/art/fetchArtById/${id}`,
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setArtsprofiledata(apiResponse.data.data))
        dispatch(setLoader(false))
        // fetchData();
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
// for curator arts delete
// for gallery also
export function deleteArts(id, dt) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/art/deleteArtById/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        dispatch(fetchArtsData(dt))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
// Exshibition api's
// curator panel api's also call

export function fetchExshibitionData(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/exhibition/fetchExhibitions",
        "POST",
        data
      )
      if (apiResponse) {
        if (apiResponse.status === 200) {
          dispatch(setExshibitiondata(apiResponse.data.data))
          dispatch(setLoader(false))
        } else {
          if (apiResponse.status === 401) {
            dispatch(
              removeUser({
                signed: false,
                type: "",
                data: "",
              })
            )
            return 401
          }
          return false
        }
      } else {
        return false
      }
    } catch (err) {}
  }
}

//exhibition for admin only
export function fetchExhibitionForArtistAndGallery(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/exhibition/fetchExhibitionsForArtistAndGallery",
        "POST",
        data
      )
      if (apiResponse) {
        if (apiResponse.status === 200) {
          dispatch(setExhibitiondata(apiResponse.data.data))
          dispatch(setLoader(false))
        } else {
          if (apiResponse.status === 401) {
            dispatch(
              removeUser({
                signed: false,
                type: "",
                data: "",
              })
            )
            return 401
          }
          return false
        }
      } else {
        return false
      }
    } catch (err) {}
  }
}
export function fetchAuctionForArtistAndGallery(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/auction/fetchAuctionsForArtistAndgallery",
        "POST",
        data
      )
      if (apiResponse) {
        if (apiResponse.status === 200) {
          dispatch(setAuctionData(apiResponse.data.data))
          dispatch(setLoader(false))
        } else {
          if (apiResponse.status === 401) {
            dispatch(
              removeUser({
                signed: false,
                type: "",
                data: "",
              })
            )
            return 401
          }
          return false
        }
      } else {
        return false
      }
    } catch (err) {}
  }
}

export function fetchExshibitionAdmin(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/exhibition/fetchExhibitions",
        "POST",
        data
      )
      if (apiResponse) {
        if (apiResponse.status === 200) {
          console.log("fetchExibitation",apiResponse)
          dispatch(setExhibitiondata(apiResponse.data.data))
          dispatch(setLoader(false))
        } else {
          if (apiResponse.status === 401) {
            dispatch(
              removeUser({
                signed: false,
                type: "",
                data: "",
              })
            )
            return 401
          }
          return false
        }
      } else {
        return false
      }
    } catch (err) {}
  }
}

export function fetchExhibitionArtData(id) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/exhibition/fetchExhibitionArtDetails/${id}`,
        "POST"
      )
      if (apiResponse.status === 200) {
        dispatch(setExhibitionalldata(apiResponse.data))
        dispatch(setLoader(false))
        // fetchData();
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function handleAddExshibition(data, setOpen, fetchData) {
  return async function addsubscriptionFeatureThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/exhibition/addExhibition",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
        setOpen(false)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          // return 401;
        } else {
          Errornotify(apiResponse.data.message)
          // return false;
        }
      }
    } catch (err) {}
  }
}
export function acceptDeclineExshibition(id, data, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/exhibition/acceptRejectExhibition/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function acceptDeclineExshibitionArt(id, data, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/exhibition/acceptDeclineExhibitionArt/${id}`,
        "PATCH",
        data
      );

      if (apiResponse.status === 200) {
       // Successnotify(apiResponse.data.message);
        if (typeof fetchData === "function") {
          fetchData(); // Call the callback to fetch updated data
        }
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          );
         // Errornotify(apiResponse.data.message);
        } else {
         // Errornotify(apiResponse.data.message);
        }
      }
    } catch (err) {
      // Handle error if needed
    }
  };
}

export function deleteExhibtion(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/exhibition/deleteExhibition/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
// Affiliate api's

export function handleAddAffiliate(data, setAdd, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/affiliate/addAffiliate",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setAdd(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setAdd(false)
        } else {
          Errornotify(apiResponse.data.message)
          setAdd(false)
        }
      }
    } catch (err) {
      setAdd(false)
    }
  }
}
export function fetchAffiliateData(data) {
  return async function fetchCuratotDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/affiliate/fetchAffiliates",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setAffiliatedata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {}
  }
}
export function fetchAffiliateProfileData(id) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/affiliate/fetchAffiliateById/${id}`,
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setAffiliateprofiledata(apiResponse.data.data))
        dispatch(setLoader(false))
        // fetchData();
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
// no need
export function acceptDeclineAffiliate(id, data, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/affiliate/acceptDeclineAffiliate/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

export function deleteAffiliate(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/affiliate/deleteAffiliate/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

// subscription api's

export function handleAddSubscription(data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/subscription/addSubscription",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
        setOpen(false)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
          // return 401;
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
          // return false;
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function fetchsubscriptionData(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/subscription/fetchSubscriptions",
        "GET",
        data
      )
      if (apiResponse) {
        if (apiResponse.status === 200) {
          dispatch(storeData(apiResponse.data.data.data))
          dispatch(setLoader(false))
        } else {
          if (apiResponse.status === 401) {
            dispatch(
              removeUser({
                signed: false,
                type: "",
                data: "",
              })
            )
            return 401
          }
          return false
        }
      } else {
        return false
      }
    } catch (err) {}
  }
}
export function deleteSubscription(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/subscription/deleteSubscription/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

export function getSubscriptionArtistByid(id) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/subscription/getArtistsBySubscription/${id}`,
        "GET"
      )
      if (apiResponse) {
        if (apiResponse.status === 200) {
          dispatch(setsubscriptionArtist(apiResponse.data.data))
          dispatch(setLoader(false))
        } else {
          if (apiResponse.status === 401) {
            dispatch(
              removeUser({
                signed: false,
                type: "",
                data: "",
              })
            )
            return 401
          }
          return false
        }
      } else {
        return false
      }
    } catch (err) {}
  }
}
// SUBSCRIPTION FEATURE API'S

export function fetchsubscriptionFeature(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/feature/fetchSubscriptionFeatures",
        "GET"
      )
      if (apiResponse) {
        if (apiResponse.status === 200) {
          dispatch(setsubscriptionFeature(apiResponse.data.data.data))
          dispatch(setLoader(false))
        } else {
          if (apiResponse.status === 401) {
            dispatch(
              removeUser({
                signed: false,
                type: "",
                data: "",
              })
            )
            return 401
          }
          return false
        }
      } else {
        return false
      }
    } catch (err) {}
  }
}
export function handleAddSubscriptionFeature(data, setOpen, fetchData) {
  return async function addsubscriptionFeatureThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/feature/addSubscriptionfeature",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
        setOpen(false)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
          // return 401;
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
          // return false;
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function deleteSubscriptionFeature(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/feature/deleteSubscriptionFeature/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function editSubscriptionfeaturedata(id, data, setOpen, fetchData) {
  return async function editSubscriptionfeatureThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/feature/editSubscriptionFeature/${id}`,
        "PUT",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function editSubscription(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/subscription/editSubscription/${id}`,
        "PUT",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

//  Buyer api's
export function fetchBuyerData(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/buyer/fetchBuyers",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        console.log("apiBuyerData->",apiResponse.data.data)
        dispatch(setBuyerdata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {}
  }
}
export function handleAddBuyer(data, setAdd, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/buyer/addBuyer",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setAdd(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setAdd(false)
        } else {
          Errornotify(apiResponse.data.message)
          setAdd(false)
        }
      }
    } catch (err) {
      setAdd(false)
    }
  }
}
export function deleteBuyer(id, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/buyer/deleteBuyer/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function blockUnblockBuyer(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/buyer/blockUnblockBuyer/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

// CATEGORY API'S

export function fetchCategories(setCategory_id) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/category/fetchCategories`,
        "GET"
      )
      if (apiResponse.status === 200) {
        setCategory_id && setCategory_id(apiResponse.data.data.data[0]._id)
        dispatch(categoryData(apiResponse.data.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function handleAddCategoy(data, setOpen, fetchData) {
  return async function handleAddCategoryThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/category/addCategory",
        "POST",
        { name: data }
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen({ add: false, edit: false })
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function handleAddCategory(data, setOpen, fetchData) {
  return async function handleAddCategoryThunk(dispatch) {
    dispatch(setBtnLoader(true))
    try {
      var bodyFormData = new FormData()
      bodyFormData.set("name", data.name)
      bodyFormData.append("image", data.image)
      const apiResponse = await api.multipartApi(
        "api/category/addCategory",
        "POST",
        bodyFormData
      )
      if (apiResponse.status === 200) {
        dispatch(setBtnLoader(false))
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          dispatch(setBtnLoader(false))
          setOpen(false)
        }
      }
    } catch (err) {
      Errornotify("Network Error")
      setOpen(false)
      dispatch(setBtnLoader(false))
    }
  }
}
export function editCategorydata(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.multipartApi(
        `api/category/editCategory/${id}`,
        "PUT",
        {
          name: data.name,
          image: data.image,
        }
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

export function deleteCategory(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/category/deleteCategory/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
//ADD STYLE

export function handleAddStyle(url, data, setOpen, fetchData) {
  return async function handleAddStyleThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(url, "POST", data)
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        // setOpen({ add: false, edit: false });
        // fetchData();
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          // setOpen(false);
        } else {
          Errornotify(apiResponse.data.message)
          // setOpen(false);
        }
      }
    } catch (err) {
      // setOpen(false);
    }
  }
}
// FETCH CATEGORY SUBCATEGORIES
export function handleFetchStyle(data, setData) {
  return async function handleAddStyleThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/itemStyle/fetchItemStyles",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        setData(apiResponse.data.data.data)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          // setOpen(false);
        } else {
          Errornotify(apiResponse.data.message)
          // setOpen(false);
        }
      }
    } catch (err) {
      // setOpen(false);
    }
  }
}
export function handleFetchMedium(data, setData) {
  return async function handleAddStyleThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/itemMedium/fetchItemMediums",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        setData(apiResponse.data.data.data)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          // setOpen(false);
        } else {
          Errornotify(apiResponse.data.message)
          // setOpen(false);
        }
      }
    } catch (err) {
      // setOpen(false);
    }
  }
}
export function handleFetchSubject(data, setData) {
  return async function handleAddStyleThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/itemSubject/fetchItemSubjects",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        setData(apiResponse.data.data.data)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          // setOpen(false);
        } else {
          Errornotify(apiResponse.data.message)
          // setOpen(false);
        }
      }
    } catch (err) {
      // setOpen(false);
    }
  }
}
export function handleFetchMaterial(data, setData) {
  return async function handleAddStyleThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/itemMaterial/fetchItemMaterials",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        setData(apiResponse.data.data.data)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          // setOpen(false);
        } else {
          Errornotify(apiResponse.data.message)
          // setOpen(false);
        }
      }
    } catch (err) {
      // setOpen(false);
    }
  }
}
// EDIT CATEGORY SUBCATEGORIES

export function handleEdit(url, data, fetchData) {
  return async function handleEditThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(url, "PUT", {
        name: data.name,
      })
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          // dispatch(
          //     removeUser({
          //         signed: false,
          //         type: "",
          //         data: "",
          //     })
          // );
          Errornotify(apiResponse.data.message)
          // setOpen(false);
        } else {
          Errornotify(apiResponse.data.message)
          // setOpen(false);
        }
      }
    } catch (err) {
      // setOpen(false);
    }
  }
}

export function handleDeletes(url, fetchData) {
  return async function handleEditThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(url, "DELETE")
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        // setOpen({ add: false, edit: false });
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          // setOpen(false);
        } else {
          Errornotify(apiResponse.data.message)
          // setOpen(false);
        }
      }
    } catch (err) {
      // setOpen(false);
    }
  }
}
// SUBCATEGORY API'S

export function fetchSubcategoryData(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/subCategory/fetchSubCategories",
        "GET"
      )
      if (apiResponse) {
        if (apiResponse.status === 200) {
          // dispatch(setsubscriptionFeature(apiResponse.data.data.data));
        } else {
          if (apiResponse.status === 401) {
            dispatch(
              removeUser({
                signed: false,
                type: "",
                data: "",
              })
            )
            return 401
          }
          return false
        }
      } else {
        return false
      }
    } catch (err) {}
  }
}
export function handleAddSubcategroy(data, setOpen, fetchData) {
  return async function addsubscriptionFeatureThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/subCategory/addSubCategory",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
        setOpen(false)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
          // return 401;
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
          // return false;
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function deleteSubcategory(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/subCategory/deleteSubCategory/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function editSubcategory(id, data, setOpen, fetchData) {
  return async function editSubscriptionfeatureThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/subCategory/editSubCategory/${id}`,
        "PUT",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

//add Menu

export function handleAddMenu(
  menudata,
  setmenuOpen,
  fetchMenuData,
  getAllMenuSubMenu
) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/menu/addmenu",
        "POST",
        menudata
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        await fetchMenuData()
        dispatch(getAllMenuSubMenu())
        setmenuOpen(false)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setmenuOpen(false)
          // return 401;
        } else {
          Errornotify(apiResponse.data.message)
          setmenuOpen(false)
          // return false;
        }
      }
    } catch (err) {
      setmenuOpen(false)
    }
  }
}

export function fetchMainMenu(setMenu_id) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(`api/menu/fetchMenus`, "GET")
      if (apiResponse.status === 200) {
        setMenu_id && setMenu_id(apiResponse.data.data.data[0]._id)
        dispatch(menuData(apiResponse.data.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

export function editMenudata(id, data, setOpen, fetchData, getAllMenuSubMenu) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.multipartApi(
        `api/menu/editMenu/${id}`,
        "PUT",
        {
          name: data.name,
          link: data.link,
          image: data.image,
        }
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        await fetchData()
        dispatch(getAllMenuSubMenu())
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

export function deleteMainmenu(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/menu/deleteMenu/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

//sub menu

export function handleAddSubMenu(menu_id, data, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/submenu/addSubmenu",
        "POST",
        { menu_id, ...data }
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

export function fetchSubMenu(setMenu_id) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/submenu/fetchSubmenu`,
        "GET"
      )
      if (apiResponse.status === 200) {
        setMenu_id && setMenu_id(apiResponse.data.data.data[0]._id)
        dispatch(submenuData(apiResponse.data.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

export function fetchMenuSubMenu() {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/menu/fetchMenuswithSubmenus",
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setMenuSubMenudata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

export function getAllMenuSubMenu() {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/menu/getallmenus",
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setGetallMenuSubMenudata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

// test
// export function getHeadingMenuSubMenu(id) {
//   return async function fetchArtistDataThunk(dispatch) {
//     dispatch(setLoader(true))
//     try {
//       const apiResponse = await api.applicationApi(
//         `api/menu/getmenudetails/${id}`,
//         "GET"
//       )
//       if (apiResponse.status === 200) {
//         dispatch(setGetallMenuSubMenudata(apiResponse.data.data))
//         dispatch(setLoader(false))
//         // fetchData();
//       } else {
//         if (apiResponse.status === 401) {
//           dispatch(
//             removeUser({
//               signed: false,
//               type: "",
//               data: "",
//             })
//           )
//           return 401
//         }
//         return false
//       }
//     } catch (err) {

//       // dispatch(setStatus(STATUSES.ERROR));
//     }
//   }
// }
//

export function fetchSubmenuData(id) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/submenu/fetchSubmenusByMenuId/${id}`,
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setSubMenuByMenuiddata(apiResponse.data.data))
        dispatch(setLoader(false))
        // fetchData();
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

export function editSubmenudata(id, data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/submenu/editSubmenu/${id}`,
        "PUT",
        {
          name: data.name,
          link: data.link,
   
        }
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchSubmenuData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

export function deleteSubmenu(id, fetchMenuData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/submenu/deleteSubmenu/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchMenuData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

// heading menus
export function handleAddHeading(menu_id, data, fetchHeading) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/heading/addHeading",
        "POST",
        { menu_id, name: data }
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        dispatch(fetchHeading(menu_id))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

export function fetchHeadingByID(id) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/heading/fetchHeadingsByMenuId/${id}`,
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setHeadingByMenudata(apiResponse.data.data))
        dispatch(setLoader(false))
        // fetchData();
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

export function editHeadingmenu(id, data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/heading/editHeading/${id}`,
        "PUT",
        {
          name: data.name,
        }
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchSubmenuData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

export function deleteHeadingMenu(id, fetchMenuData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/heading/deleteHeading/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchMenuData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
// ARTIST TYPE API'S

export function fetchArtistTypes() {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/artistType/fetchArtistTypes",
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setArtisttypedata(apiResponse.data.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function handleAddartistType(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/artistType/addArtistType",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function editArtistType(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/artistType/editArtistType/${id}`,
        "PUT",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
// ARTIST API'S
// curator panel also api call

export function fetchArtistData(data) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/artist/fetchArtists",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        console.log("dataofconsol",apiResponse.data.data)
        dispatch(setArtistdata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}


export function fetchCuratorGalleryData(data) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/gallery/fetchCuratorGalleries",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setCuratorGalleryData(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function handleAddArtist(data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/artist/addArtist",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function deleteArtist(id, fetchData) {
  const delete_id = id._id
  return async function deleteArtistThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/artist/deleteArtist/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function blockUnblockArtist(id, data, fetchData) {
  return async function blockArtistDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/artist/blockUnblockArtist/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function acceptDeclineArtist(id, data, fetchData) {
  return async function blockArtistDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/artist/acceptDeclineArtist/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

//artist type post api

export function handleArtistTypeChange(id, selectedType) {
  console.log("cehedk",id,selectedType)
  return async function blockArtistDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/artist/changeartisttype/${id}`,
        "POST",
        { artist_type: selectedType }
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          // Errornotify(apiResponse.data.message)
        } else {
          // Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function SetfeaturedArtist(id, data, fetchData) {
  return async function blockArtistDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/artist/setFeaturedArtist/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

export function SetExclusiveArtist(id, data, fetchData) {
  return async function blockArtistDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/artist/setExclusiveArtist/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}




export function fetchArtistProfileData(id) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/artist/fetchArtistById/${id}`,
        "GET"
      )
      if (apiResponse.status === 200) {
        console.log("my_consoletest",apiResponse.data)
        dispatch(setArtistprofiledata(apiResponse.data.data))
        dispatch(setLoader(false))
        // fetchData();
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

export function fetchGalleryProfileData(id) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/user/getGallery/${id}`,
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setGalleryprofiledata(apiResponse.data.data))
        dispatch(setLoader(false))
        // fetchData();
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}


export function fetchEnquiryById(id) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/artsmileyenquiry/getEnquiryByUserId/${id}`,
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setuserenquirydata(apiResponse.data.data))
        dispatch(setLoader(false))
        // fetchData();
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

export function fetchEnquiryReplyByEnquiryId(id) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/artsmileyenquiryreply/getEnquiryReplyByEnquiryId/${id}`,
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setenquiryreplybyiddata(apiResponse.data.data))
        dispatch(setLoader(false))
       
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function fetchEnquiryByEnquiryId(id) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/artsmileyenquiry/getEnquiryById/${id}`,
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setEnquiryByEnquiryId(apiResponse.data.data))
        dispatch(setLoader(false))
        // fetchData();
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
     
    }
  }
}
// notification api
export function fetchNotificationData(id) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/notification/fetchNotifications`,
        "POST"
      )
      if (apiResponse.status === 200) {
        dispatch(setNotificationdata(apiResponse.data.data))
        dispatch(setLoader(false))
        // fetchData();
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
// CURATOR API'S

export function handleAddCurator(data, setAdd, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/curator/addCurator",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setAdd(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setAdd(false)
        } else {
          Errornotify(apiResponse.data.message)
          setAdd(false)
        }
      }
    } catch (err) {
      setAdd(false)
    }
  }
}
export function fetchCuratorData(data) {
  console.log("dataforCurator",data)
  console.log("Api Check Data",data);
  
  return async function fetchCuratotDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/curator/fetchCurators",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setCuratordata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {}
  }
}

// test curator list

export function fetchCuratorDataList(data, limit = 100, offset = 0) {
  // Add limit and offset
  return async function fetchCuratotDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/curator/fetchCurators",
        "POST",
        { ...data, limit, offset } // Pass limit and offset in the API request
      )
      if (apiResponse.status === 200) {
        dispatch(setCuratordata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {}
  }
}

export function curatorAcceptDcline(id, data, fetchData) {
  return async function blockArtistDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/curator/acceptDeclineCurator/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        fetchData()
        Successnotify(apiResponse.data.message)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function curatorBlockUnblock(id, data, fetchData) {
  return async function blockArtistDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/curator/blockUnblockCurator/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        fetchData()
        Successnotify(apiResponse.data.message)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function deleteCurator(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/curator/deleteCurator/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
// ORDER API'S

export function fetchOrderData(data) {
  return async function fetchCuratotDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/order/fetchOrders",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setOrderdata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {}
  }
}

export function fetchOrderDetail(id, setData) {
  return async function blockArtistDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/orderArt/fetchOrderArtDetails/${id}`,
        "POST"
      )
      if (apiResponse.status === 200) {
        setData(apiResponse.data)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
        } else {
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
// compaingns

export function fetcCompaingnsData(data) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/campaign/fetchCampaigns",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setCompaingnsdata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function handleAddCompaingns(data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/campaign/addCompaign",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
// career api

export function fetchCareerData(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/career/fetchCareers",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setCareerdata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function handleAddCareers(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/career/addCareer",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}

export function editCareer(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/career/editCareer/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function deleteCareer(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/career/deleteCareer/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

export function getCareerdetailsByid(id) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/career/getCareerDetails/${id}`,
        "GET"
      )
      if (apiResponse) {
        if (apiResponse.status === 200) {
          dispatch(setcareerdetails(apiResponse.data.data))
          dispatch(setLoader(false))
        } else {
          if (apiResponse.status === 401) {
            dispatch(
              removeUser({
                signed: false,
                type: "",
                data: "",
              })
            )
            return 401
          }
          return false
        }
      } else {
        return false
      }
    } catch (err) {}
  }
}

//print pricing

export function handleAddPrintPricings(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/printprice/addPrintPrice",
        "POST",
        data
      )
      if (apiResponse.status === 201) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}

export function fetchPrintPricing(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/printprice/getAllPrintPrices",
        "GET",
        data
      )
      if (apiResponse) {
        if (apiResponse.status === 200) {
          dispatch(setPrintPricingdata(apiResponse.data.data))
          dispatch(setLoader(false))
        } else {
          if (apiResponse.status === 401) {
            dispatch(
              removeUser({
                signed: false,
                type: "",
                data: "",
              })
            )
            return 401
          }
          return false
        }
      } else {
        return false
      }
    } catch (err) {}
  }
}

export function editprintPricing(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/printprice/editPrintPrice/${id}`,
        "PUT",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

export function deletePrintPricing(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/printprice/deletePrintPrice/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
// Enquiries
export function fetchEnquiryData(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/enquiry/fetchEnquiries",
        "POST"
      )
      if (apiResponse.status === 200) {
        dispatch(setEnquirydata(apiResponse.data.data.enquiries))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
// TESTIMONIALS
export function fetchTestimonialData(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/testimonial/fetchTestimonials",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        console.log("appres",apiResponse.data.data)
        dispatch(setTestimonialdata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function editTestimonial(id, data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/testimonial/editTestimonial/${id}`,
        "PUT",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    
    }
  }
}
export function handleAddTestimonial(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.multipartApi(
        "api/testimonial/addTestimonial",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
// BLOG

export function fetchBlogData(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/blog/fetchBlogs",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setBlogdata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

export function fetchBlogDataByslug(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/blog/fetchBlogBySlug/${slug}",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setBlogSlugdata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

export function handleAddBlog(data, setOpen, fetchData) {
  console.log("handleblogData",data)
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.multipartApi(
        "api/blog/addBlog",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function editBlog(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.multipartApi(
        `api/blog/editBlog/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function deleteBlog(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/blog/deleteBlog/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
// PRES RELEASE
export function fetchPressData(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/pressRelease/fetchPressReleases",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setPressrelease(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function handleAddPress(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.multipartApi(
        "api/pressRelease/addPressRelease",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function editPress(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.multipartApi(
        `api/pressRelease/editPressRelease/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function deletePress(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/pressRelease/deletePressRelease/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
// SETTING

export function handleUpdateSetting(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/superAdmin/updateSettings",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}

// ITEM TPYE

export function fetchItemType(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/itemType/fetchItemTypes",
        "GET",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setItemtype(apiResponse.data.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function handleItemType(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/itemType/addItemType",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
// FAQ

export function fetchFaqData(id, data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/faq/fetchFAQS/${id}`,
        "GET",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setFaqlist(apiResponse.data.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      Errornotify("Network Error")
    }
  }
}
export function handleAddFaq(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/faq/addFAQ",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function editFaq(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/faq/editFAQ/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function deleteFaq(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/faq/deleteFAQ/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

// OFFER
export function fetchOfferData(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/offer/fetchOffers",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setOfferdata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      Errornotify("Network Error")
    }
  }
}
export function handleAddOffer(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.multipartApi(
        "api/offer/addOffer",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function deleteOffer(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/offer/deleteOffer/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function editOffer(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.multipartApi(
        `api/offer/offerUpdateById/${id}`,
        "PUT",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

//campaign

export function deleteCampaign(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/campaign/deleteCampaign/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function editCampaign(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/campaign/upddateCampaign/${id}`,
        "PUT",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
// GIFT CARD

export function fetchGiftcardData(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/giftCard/fetchGiftCards",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setGiftcarddata(apiResponse.data.data))
        console.log("fetchData",apiResponse.data)
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      Errornotify("Network Error")
    }
  }
}
export function handleAddGiftcard(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.multipartApi(
        "api/giftCard/addGiftCard",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}

// HERO SECTION

export function fetchHeroSectionData(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/heroSection/fetchHeroSections",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setHerodata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      Errornotify("Network Error")
    }
  }
}
export function handleAddHeroSection(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.multipartApi(
        "api/heroSection/addHeroSection",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

export function editHero(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.multipartApi(
        `api/heroSection/updateHeroSection/${id}`,
        "PUT",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function deleteHero(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/heroSection/deleteHeroSection/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

// PRIVACY POLICY
// COMMON
export function fetchPrivacyPolicyData(url, data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(url, "POST", data)
      if (apiResponse.status === 200) {
        dispatch(setPolicydata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      Errornotify("Network Error")
    }
  }
}

export function handleAddPrivacyPolicy(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/privacyPolicy/addPrivacyPolicy",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function editPrivacyPolicy(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/privacyPolicy/editPrivacyPolicy/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function deletePrivacyPolicy(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/privacyPolicy/deletePrivacyPolicy/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
// SHIPPING POLICY

export function handleAddShippingPolicy(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/shippingPolicy/addShippingPolicy",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function editShippingPolicy(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/shippingPolicy/editShippingPolicy/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function deleteShippingPolicy(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/shippingPolicy/deleteShippingPolicy/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
// RETURN POLICY

export function handleAddReturnPolicy(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/returnPolicy/addReturnPolicy",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function editReturnPolicy(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/returnPolicy/editReturnPolicy/${id}`,
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function deleteReturnPolicy(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/returnPolicy/deleteReturnPolicy/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
// RENTAL AGREEMENT

export function handleAddRentAlagreement(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/rentalAgreement/addRentalAgreement",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function editRentalAgreement(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/rentalAgreement/editRentalAgreement/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function deleteRentalAgreement(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/rentalAgreement/deleteRentalAgreement/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
// TERMS & CONDITION

export function handleAddTermsCondition(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/termsCondition/addTermsCondition",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function editTermsCondition(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/termsCondition/editTermsCondition/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function deleteTermsCondition(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/termsCondition/deleteTermsCondition/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
// DISPUTE API'S
export function fetchDisputeData(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/dispute/fetchDisputes",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setDisputedata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      Errornotify("Network Error")
    }
  }
}
export function acceptRejectRefundStatus(id, data, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/dispute/acceptRejectRefundStatus/${id}`,
        "PATCH",
        data
      )
     
      if (apiResponse.status === 200) {
        console.log(apiResponse, "apiResponse->o")
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
// GALLERY API'S
export function fetchGalleryData(data) {
  
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/gallery/fetchGalleries",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        console.log("apiREsonseData",apiResponse)
        dispatch(setGallery(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      Errornotify("Network Error")
    }
  }
}
export function acceptRejectGallery(id, data, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/gallery/acceptRejectGallery/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function blockUnblockGallery(id, data, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/gallery/blockUnblockGallery/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function setRecommendedGallery(id, data, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/gallery/setRecommendGallery/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

export function deleteGallery(id, fetchData) {
  console.log("idm",id)
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/gallery/deleteGallery/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

// PERMISSION API'S

export function fetchPermissionData(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/permission/fetchPermissions",
        "GET",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setPermission(apiResponse.data.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      Errornotify("Network Error")
    }
  }
}
export function handleAddPermisssion(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/permission/addPermission",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
// AUCTION API'S

// export function fetchAuctionData(data) {
//  return async function fetchsubscriptionDataThunk(dispatch) {
//   dispatch(setLoader(true));
//   try {
//    const apiResponse = await api.applicationApi("api/auction/fetchAuctions", "POST", data);
//    if (apiResponse.status === 200) {
//     dispatch(setAuctionData(apiResponse.data.data));
//     dispatch(setLoader(false));
//    } else {
//     if (apiResponse.status === 401) {
//      dispatch(
//       removeUser({
//        signed: false,
//        type: "",
//        data: "",
//       })
//      );
//     } else {
//      Errornotify(apiResponse.data.message);
//     }
//    }
//   } catch (err) {

//   }
//  };
// }

export function fetchAuctionData(data) {
  return async function addArtistTypeDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/auction/fetchAuctions",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setAuctionData(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

export function fetchAuctionArtData(id) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/auction/fetchAuctionArtDetails/${id}`,
        "POST"
      )
      if (apiResponse.status === 200) {
        dispatch(setExhibitionalldata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
        }
      }
    } catch (err) {}
  }
}
export function acceptDeclineAuctionArt(id, data, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/auction/acceptDeclineAuctionArt/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function handleAddAuction(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/auction/addAuction",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function deleteAuction(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/auction/deleteAuction/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function handleAcceptRejectAuction(id, data, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/auction/acceptRejectAuction/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          fetchData()
        } else {
          Errornotify(apiResponse.data.message)
          fetchData()
        }
      }
    } catch (err) {}
  }
}
// SUBADMIN API'S
export function fetchSubadminData(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/subAdmin/fetchSubAdmins",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setSubadmin(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      Errornotify("Network Error")
    }
  }
}
export function handleAddSubadmin(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/subAdmin/addSubAdmin",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function deleteSubadmin(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/subAdmin/deleteSubAdmin/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function editedSubadmin(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/subadmin/editSubAdmin/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      setOpen(false)
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

// SETTING API

// FRAME
// api/framePermission/addEditFramePermission

export function fetchFrameCountry(setFrameCountry) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/framePermission/fetchFramePermissions",
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setLoader(false))
        setFrameCountry(apiResponse.data.data.countries)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
        } else {
        }
      }
    } catch (err) {}
  }
}

export function handleAddFrameCountry(data, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/framePermission/addEditFramePermission",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

// PRINT MATERIAL

export function fetchPrintMaterialData(setData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/fetchPrintMaterials",
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setData(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      dispatch(setLoader(false))
    }
  }
}
export function handleAddPrintMaterial(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/printMaterial/addPrintMaterial",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function deletePrintMaterial(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/printMaterial/deletePrintMaterial/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function editedPrintMaterial(id, data, setOpen, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/printMaterial/editPrintMaterial/${id}`,
        "PUT",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
        setOpen(false)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

// PRINT PRODUCT

export function fetchPrintProductData(setData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    // dispatch(setLoader(true));
    try {
      const apiResponse = await api.applicationApi(
        "api/user/fetchPrintProducts",
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setData(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
export function handleAddPrintProduct(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/printProduct/addPrintProduct",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function editedPrintProduct(id, data, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/printProduct/editPrintProduct/${id}`,
        "PUT",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
export function deletePrintProduct(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/printProduct/deletePrintProduct/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

// PRINT PRICE

export function fetchPrintPriceData(data, setData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    // dispatch(setLoader(true));
    try {
      const apiResponse = await api.applicationApi(
        "api/user/fetchPrintProductPrices",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setData(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

export function handleAddPrintProductPrice(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/printProductPrice/addPrintProductPrice",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}
export function editedPrintProductPrice(id, data, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/printProductPrice/editPrintProductPrice/${id}`,
        "PUT",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

export function deletePrintProductPrice(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/printProductPrice/deletePrintProductPrice/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

// BEST SELLER ART
export function setBestSeller(id, data, fetchData) {
  return async function blockArtistDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/art/setBestSellerArt/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

// MOST POPULAR ART
export function setMostPopular(id, data, fetchData) {
  return async function blockArtistDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/art/setMostPopularArt/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

// AFFILIATE TYPE API

export function fetchAffiliateType(setType) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(
        `api/affiliateAccountType/fetchAffiliateAccountTypes`,
        "GET"
      )
      if (result && result.status === 200) {
        setType(result.data.data.data)
        dispatch(setLoader(false))
      } else if (result.status === 401) {
        dispatch(
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
        )
      }
    } catch (err) {}
  }
}

// AFFILIATE EARNINGS
export function affiliateOrders(setData) {
  return async function fetchArtistDataThunk(dispatch) {
    try {
      const result = await api.applicationApi(
        `api/orderArt/fetchAffiliateOrders`,
        "POST"
      )
      if (result && result.status === 200) {
        setData(result.data.data)
      } else if (result.status === 401) {
        dispatch(
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
        )
      }
    } catch (err) {}
  }
}

// AFFILIATE LINK GENERATION API
export function generateAffiliateLink(data) {
  return async function fetchArtistDataThunk(dispatch) {
    try {
      const result = await api.applicationApi(
        `api/affiliate/generateAffiliateLink`,
        "POST",
        data
      )
      if (result && result.status === 200) {
        Successnotify(result.data.message)
      } else if (result.status === 401) {
        dispatch(
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
        )
      }
    } catch (err) {}
  }
}

export function getAffilateLink(setData) {
  return async function fetchArtistDataThunk(dispatch) {
    try {
      const result = await api.applicationApi(
        `api/affiliate/getAffiliateLink`,
        "GET"
      )
      if (result && result.status === 200) {
        setData(result.data.data)
      } else if (result.status === 401) {
        dispatch(
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
        )
      }
    } catch (err) {}
  }
}

// fetch all link

// export function getAllAffilateLink() {
//   return async function fetchArtistDataThunk(dispatch) {
//     try {
//       const result = await api.applicationApi(
//         `api/affiliate/getAffiliateLinks`,
//         "GET"
//       )

//       if (result && result.status === 200) {

//         setAllAffiliateLinkdata(result.data.data)
//       } else if (result.status === 401) {
//         dispatch(
//           removeUser({
//             signed: false,
//             type: "",
//             data: "",
//           })
//         )
//       }
//     } catch (err) {

//     }
//   }
// }

// hhhttt

export function getAllAffilateLink() {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/affiliate/getAffiliateLinks",
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setAllAffiliateLinkdata(apiResponse.data.links))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
// Recommended Artist APT

export function setRecommendedArtist(id, data, fetchData) {
  return async function blockArtistDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/artist/setRecommendArtist/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}
// Contact Us API
export function fetchContactUsQuery(setData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/contact-us/fetchContactUsQueries",
        "GET"
      )
      if (apiResponse) {
        if (apiResponse.status === 200) {
          setData(apiResponse.data.data)
          dispatch(setLoader(false))
        } else {
          if (apiResponse.status === 401) {
            dispatch(
              removeUser({
                signed: false,
                type: "",
                data: "",
              })
            )
            return 401
          }
          return false
        }
      } else {
        return false
      }
    } catch (err) {}
  }
}

export function deleteContactUsQuery(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/contact-us/deleteContactUsQuery/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

// Add Gallery API

export function handleAddGallery(data, setAdd, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/gallery/addGallery",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setAdd(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setAdd(false)
        } else {
          Errornotify(apiResponse.data.message)
          setAdd(false)
        }
      }
    } catch (err) {
      setAdd(false)
    }
  }
}
export function  handleFetchCuratorProfile() {
  return async function fetchArtistDataThunk(dispatch) {
  dispatch(setLoader(true))
  try {
    const apiResponse  = await api.applicationApi(
      `api/curator/fetchCuratorDetails`,
      "GET"
    )
    if (apiResponse  && apiResponse.status === 200) {
     
       dispatch(setRecreateData(apiResponse.data.data))
      dispatch(setLoader(false))
    } else  {
      if (apiResponse.status === 401) {
        dispatch(
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
        )
        return 401
      }
      return false
    }
  } catch (err) {
  }
}
}

export function fetchcuratorDetails(id) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/curator/fetchCuratorById/${id}`,
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setCuratordetailsdata(apiResponse.data.data))
        dispatch(setLoader(false))
        // fetchData();
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

export function subscriptionPurchaseSubadmin({
  subscriptionName,
  artistId,
  subscriptionId,
  subscription_tenure,
  payment_mode,
  transactionId,
  amount,
}) {
  return async function subscriptionPurchaseDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/subscription/purchaseSubscription`,
        "POST",
        {
          name: subscriptionName,
          artist_id: artistId,
          subscription_id: subscriptionId,
          subscription_tenure: subscription_tenure,
          payment_mode: payment_mode,
          transaction_id: transactionId,
          amount: amount,
        }
      )
      if (apiResponse.status === 200 || apiResponse.status === 201) {
      } else {
        // Errornotify("Subscription Purchased Failed!")
      }
    } catch (err) {
      // Errornotify("Network Error")
    }
  }
}

//Faq category post
export function handleAddFAQcat(data, setOpen, fetchData) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/userType/addUserType",
        "POST",
        { name: data }
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}

//curator assign

export function fetchCuratorList(countryName, setData) {
  return async function subscriptionPurchaseDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/curator/getSameCountryCurator",
        "POST",
        {
          country: countryName,
        }
      )
      if (apiResponse.status === 200 || apiResponse.status === 201) {
        setData(apiResponse.data.data)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

export function assignCurator(id, data, fetchData) {
  return async function blockArtistDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/curator/assignTheArtsitForTheDiffrentCurator/${id}`,
        "PATCH",
        { artist_id: data }
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

//EARNING CURATOR
export function fetchCuratorEarning() {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/curator/getCuratorEarningDetails",
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setCuratorEarningdata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}

//curator exhibition
export function fetchcuratorExhibitionDetails(id) {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/exhibition/fetchExhibitionsbyID/${id}`,
        "POST"
      )
      if (apiResponse.status === 200) {
        dispatch(setCuratorExhibitiondetailsdata(apiResponse.data.data))
        dispatch(setLoader(false))
        // fetchData();
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

// affiliate

export function fetchAffiliateEarning() {
  return async function fetchArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/affiliate/getAffiliateEarning`,
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setAffiliateEarningdata(apiResponse.data.data))
        dispatch(setLoader(false))
        // fetchData();
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
      // dispatch(setStatus(STATUSES.ERROR));
    }
  }
}

// add marketing data

// export function addmarketingdata(id, feature, description) {
//   return async function fetchsubscriptionDataThunk(dispatch) {
//     dispatch(setLoader(true))
//     try {
//       const apiResponse = await api.applicationApi(
//         "api/artist/addmarketingdata",
//         "POST",
//         { artist_id: id, feature, description }
//       )
//       if (apiResponse) {

//         if (apiResponse.status === 200) {
//            Successnotify(apiResponse.data.message)
//           dispatch(setAddMarketingdata(apiResponse.data.data))
//           dispatch(setLoader(false))
//         } else {
//           if (apiResponse.status === 401) {
//             dispatch(
//               removeUser({
//                 signed: false,
//                 type: "",
//                 data: "",
//               })
//             )
//             return 401
//           }
//           return false
//         }
//       } else {
//         return false
//       }
//     } catch (err) {

//     }
//   }
// }

export function addmarketingdata(id, feature, description, setOpen) {
  return async function addArtistTypeDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/artist/addmarketingdata",
        "POST",
        { artist_id: id, feature, description }
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        dispatch(fetchArtistProfileData(id))
        setOpen(false)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          setOpen(false)
        } else {
          Errornotify(apiResponse.data.message)
          setOpen(false)
        }
      }
    } catch (err) {
      setOpen(false)
    }
  }
}

//reports
export function handleAddReports(data) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/report/fetchReports",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        return apiResponse.data
        // setAdd(false)
        // fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
          // setAdd(false)
        } else {
          Errornotify(apiResponse.data.message)
          // setAdd(false)
        }
      }
    } catch (err) {
      // setAdd(false)
    }
  }
}

//remve curator art from curator colection
export function removeCuratorArt(id, fetchData) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/art/removeArtToCuratorCollection/${id}`,
        "PATCH"
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        fetchData()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) {}
  }
}
