import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../Fivecolumn/Tablefivecol.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addData } from "../../../../../State/Features/Artist/profileSlice";
import homeImage from "../../../../../constant";
import CircularLoader from "../../../../../common/loader/Loader";
import moment from "moment";
import { Errornotify } from "../../../../../common/alert/Alert";
import api from "../../../../../State/api/Api";
import dateFormat from "../../../../../utils/dateFormat";

export default function ArtistOrderTable(props) {
  const [orderType, setOrdertype] = useState("Buy");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const loader = useSelector((state) => state.loader);
  const [threedot_id, setThreedot_id] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const threedots = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setThreedot_id(event.target.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handledetailpage = (row) => {
    
    if (location.pathname === "/artist") {
      navigate(`/artist/${row._id}`);
    } else if (location.pathname === "/affiliate") {
      navigate(`/affiliate/${row._id}`);
    } else if (location.pathname === "/auction") {
      navigate(`/auction/${row._id}`);
    } else if (location.pathname === "/exhibitions") {
      navigate(`/exhibitions/${row._id}`);
    }
    dispatch(addData(row));
  };



  const buyType = props.orderData
  ?.map((val) => ({
    ...val,
    items: val.items?.filter((item) => {
      if (orderType === "Others") {
        // Include items where the order_type is not "Print", "Rent", or "Buyer"
        return !["Print", "Rent", "Buy"].includes(item?.order_type?.name);
      } else {
        // Otherwise, include items that match the specified orderType
        return item?.order_type?.name === orderType;
      }
    }),
  }))
  ?.filter(
    (val) =>
      val.items?.length > 0 &&
      (!props.condition.search ||
        val.order_number?.toString().toLowerCase().includes(props.condition.search.toLowerCase()) ||
        val.buyer?.some((buyer) => {
          const fullName = `${buyer?.first_name || ""} ${buyer?.last_name || ""}`.trim();

          return fullName.toLowerCase().includes(props.condition.search.toLowerCase());
        }))
  );

// Apply sorting if props.condition.sorting is specified
if (props.condition.sorting === "ascending") {
  buyType?.sort((a, b) => new Date(a.order_date) - new Date(b.order_date)); // Ascending by order_date
} else if (props.condition.sorting === "descending") {
  buyType?.sort((a, b) => new Date(b.order_date) - new Date(a.order_date)); // Descending by order_date
}



  const isBuyBackground =
    orderType === "Buy" ? "rgba(28, 28, 28, 1)" : "rgba(246, 246, 246, 1)";
  const isRentBackground =
    orderType === "Rent" ? "rgba(28, 28, 28, 1)" : "rgba(246, 246, 246, 1)";
  const isPrintBackground =
    orderType === "Print" ? "rgba(28, 28, 28, 1)" : "rgba(246, 246, 246, 1)";
const isOtherBackground = orderType === "Others" ? "rgba(28, 28, 28, 1)" : "rgba(246, 246, 246, 1)";
  const isBuyColor =
    orderType === "Buy" ? "rgba(246, 246, 246, 1)" : "rgba(42, 42, 44, 1)";
  const isRentColor =
    orderType === "Rent" ? "rgba(246, 246, 246, 1)" : "rgba(42, 42, 44, 1)";
  const isPrintColor =
    orderType === "Print" ? "rgba(246, 246, 246, 1)" : "rgba(42, 42, 44, 1)";
    const isOtherColor = orderType === "Others" ? "rgba(246, 246, 246, 1)" : "rgba(42, 42, 44, 1)"; 
  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
           <div style={{ width: "100%", display: "flex" }}>
              <ButtonGroup
                variant="contained"
                sx={{ borderRadius: "none", boxShadow: "none", padding: "4px" }}
              >
                <Button
                className="action_btn"
                  sx={{
                    background: isBuyBackground,
                    color: isBuyColor,
                    height: "40px",
                  borderRadius: "5px 0px 0px 0px",
                  fontSize:"16px",
                  mt: 1,
                  }}
                  onClick={() => setOrdertype("Buy")}
                >
                  Buy
                </Button>
                <Button
                  sx={{
                    background: isRentBackground,
                    color: isRentColor,
                    height: "40px",
                  borderRadius: "5px 0px 0px 0px",
                  fontSize:"16px",
                  mt: 1,
                  }}
                  onClick={() => setOrdertype("Rent")}
                >
                  Rent
                </Button>
                <Button
                  sx={{
                    background: isPrintBackground,
                    color: isPrintColor, 
                    height: "40px",
                  borderRadius: "5px 0px 0px 0px",
                  fontSize:"16px",
                  mt: 1,
                  }}
                  onClick={() => setOrdertype("Print")}
                >
                  Print
                </Button>
                <Button
                  sx={{
                    background: isOtherBackground,
                    color: isOtherColor, 
                    height: "40px",
                  borderRadius: "5px 0px 0px 0px",
                  fontSize:"16px",
                  mt: 1,
                  }}
                  onClick={() => setOrdertype("Others")}
                >
                  Others
                </Button>
              </ButtonGroup>
            </div>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  className={styles.table_head}
                  align={column.label === "Order ID" ? "center" : "left"}
                  // align={props.head.length - 1 === i ? "left" : "left"}
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "15px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {buyType?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                buyType?.map((row) => (
                  <TableRow
                    style={{ color: "#636365", height: "30px" }}
                    className={styles.table_row}
                    key={row._id}
                  >
                 
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      style={{ cursor: "pointer",textDecoration:"underline" }}
                      onClick={() => navigate(`/orderdetail/${row?._id}`)}
                      className={styles.table_column}
                      align="center"
                    >
                     #{row?.order_number}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/orderdetail/${row?._id}`)}
                      className={styles.table_column}
                      align="left"
                    >
                     {row?.items?.[0]?.order_type?.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handledetailpage(row)}
                      className={styles.table_column}
                      align="left"
                    >
                    {row?.buyer?.map((val) => val?.first_name +  " " + val?.last_name)}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handledetailpage(row)}
                      className={styles.table_column}
                      align="left"
                    >
                     ${row?.price}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handledetailpage(row)}
                      className={styles.table_column}
                      align="left"
                    >
                   {dateFormat(row?.order_date)}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handledetailpage(row)}
                      className={styles.table_column}
                      align="center"
                    >
                     {row?.items?.length}
                    </TableCell>

                       
                    
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}
