import {
  Grid,
  Container
} from "@mui/material";
import React, {useRef } from "react";
import styles from "./Catelog.module.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri";

function Catelog() {
  const prevButtonRef = useRef(null);
    const nextButtonRef = useRef(null);
  return (
    <div className={styles.catelog}>
      <div className={styles.header}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.pageitle} ${styles.max_width}`}>
              <h1>Art Smiley catalogue: A new era in art discovery</h1>
              {/* <p>Art Smiley 2024 CATALOG: A NEW ERA IN ART DISCOVERY</p> */}
            </div>
        </Container>
        <div>
        </div>
      </div>
      <div className={styles.pdsec}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
    <div className={`${styles.text_center} ${styles.max_width1}`}>
              <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
              <h3 className={styles.sec_title}>Discover The Best Artwork With Art Smiley Catalogue</h3>
              <p className={styles.withor}>At Art Smiley, we’re redefining how art connects with collectors. Our catalogue doesn’t just showcase art-it tells the stories of our artists, highlighting their journeys, creative processes, and the cultural influences behind their work.</p>
              <p className={styles.withor}>Explore the new wave of talent and trends shaping the future of art, all brought to you by Art Smiley’s curatorial experts, you can also browse our online collection.</p>
              </div>
        <div>
        <Grid container spacing={2} >
        <Grid item xs={12} sm={3} md={2.4} lg={2.4}>
          <div className={styles.cataloglist}>
            <a href="/img/artsmiley_brochure_btb.pdf" target="_blank" rel="noopener noreferrer">
            <img src={"/img/artsmiley-marketplace.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>Art Smiley Marketplace for Art</p>
            </div>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={2.4} lg={2.4}>
          <div className={styles.cataloglist}>
            <a href="href" target="_blank" rel="noopener noreferrer">
            <img src={"/img/catalog-2.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>World Art Day</p>
            </div>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={2.4} lg={2.4}>
          <div className={styles.cataloglist}>
            <a href="href" target="_blank" rel="noopener noreferrer">
            <img src={"/img/catalog-3.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>World Art Day</p>
            </div>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={2.4} lg={2.4}>
          <div className={styles.cataloglist}>
            <a href="href" target="_blank" rel="noopener noreferrer">
            <img src={"/img/catalog-4.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>World Art Day</p>
            </div>
            </a>
          </div>
        </Grid>
        
        <Grid item xs={12} sm={3} md={2.4} lg={2.4}>
          <div className={styles.cataloglist}>
            <a href="href" target="_blank" rel="noopener noreferrer">
            <img src={"/img/catalog-2.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>World Art Day</p>
            </div>
            </a>
          </div>
        </Grid>
        </Grid>
        </div>
        </Container>
      </div>
      <section className={styles.sec_scexcl}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
    <div className={`${styles.text_center} ${styles.max_width}`}>
              <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
              <h3 className={styles.sec_title}>Catalogue Highlights</h3>
              </div>
              <div className={styles.mt2}>
              <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <div>
                                    <ul className={styles.timeline}>
                                      <li>
                                      <div className={styles.stepli}>
                                    <h4>Emerging Artists to Watch</h4>
                                    <p>Explore fresh talent redefining contemporary art.</p>
                                    </div>
                                      </li>
                                      <li>
                                      <div className={styles.stepli}>
                                    <h4>Top Picks in Abstract Art</h4>
                                    <p>Bold, imaginative abstracts that ignite your space.</p>
                                    </div>
                                      </li>
                                      <li>
                                      <div className={styles.stepli}>
                                    <h4>Renowned Artists Worth the Investment</h4>
                                    <p>Timeless works from renowned artists with lasting value.</p>
                                    </div>
                                      </li>
                                      <li>
                                      <div className={styles.stepli}>
                                    <h4>Highlighting Bold Statement Pieces</h4>
                                    <p>Standout artworks that transform and elevate any room.</p>
                                    </div>
                                      </li>
                                    </ul>
                                    
                                  </div>
              </Grid>
              <Grid item xs={12} sm={8} md={7} lg={7}>
              <section className={`testmo-slider ${styles.sections}`}>
          <Swiper
        modules={[Navigation, Autoplay]}
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 3000, // Set autoplay delay in milliseconds (3 seconds here)
          disableOnInteraction: false, // Allows autoplay to continue after user interactions
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevButtonRef.current;
          swiper.params.navigation.nextEl = nextButtonRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
      >
<SwiperSlide>
<div className={styles.cataloglist}>
            <a href="href" target="_blank" rel="noopener noreferrer">
            <img src={"/img/catalog-1.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>World Art Day</p>
            </div>
            </a>
          </div>
                    </SwiperSlide>
                    <SwiperSlide>
<div className={styles.cataloglist}>
            <a href="href" target="_blank" rel="noopener noreferrer">
            <img src={"/img/catalog-2.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>World Art Day</p>
            </div>
            </a>
          </div>
                    </SwiperSlide>
                    <SwiperSlide>
<div className={styles.cataloglist}>
            <a href="href" target="_blank" rel="noopener noreferrer">
            <img src={"/img/catalog-4.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>World Art Day</p>
            </div>
            </a>
          </div>
                    </SwiperSlide>
              </Swiper>
              <div className="tesarrow">
              <div ref={prevButtonRef} className="swiper-button-prev">
          <RiArrowLeftLine />
        </div>
        <div ref={nextButtonRef} className="swiper-button-next">
          <RiArrowRightLine />
        </div>
      </div>
        </section>
              </Grid>
      </Grid>
              </div>
              
  </Container>
      </section>
      <div className={styles.pdsec}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
    <div className={`${styles.text_center} ${styles.max_width1}`}>
              <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
              <h3 className={styles.sec_title}>Art Catalogues</h3>
              <p className={styles.withor}>Explore our extensive catalogue, offering a wide variety of exceptional artworks. From best-selling pieces to exclusive creations, each section is thoughtfully organized to help you find the perfect art for your collection.</p>
              </div>
        <div>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={2.4} lg={2.4}>
          <div className={styles.cataloglist}>
            <a href="href" target="_blank" rel="noopener noreferrer">
            <img src={"/img/catalog-1.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>World Art Day</p>
            </div>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={2.4} lg={2.4}>
          <div className={styles.cataloglist}>
            <a href="href" target="_blank" rel="noopener noreferrer">
            <img src={"/img/catalog-2.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>World Art Day</p>
            </div>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={2.4} lg={2.4}>
          <div className={styles.cataloglist}>
            <a href="href" target="_blank" rel="noopener noreferrer">
            <img src={"/img/catalog-3.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>World Art Day</p>
            </div>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={2.4} lg={2.4}>
          <div className={styles.cataloglist}>
            <a href="href" target="_blank" rel="noopener noreferrer">
            <img src={"/img/catalog-4.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>World Art Day</p>
            </div>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={2.4} lg={2.4}>
          <div className={styles.cataloglist}>
            <a href="href" target="_blank" rel="noopener noreferrer">
            <img src={"/img/catalog-1.png"} alt="" className="img-fluid" />
            <div>
              <p className={styles.catlogname}>World Art Day</p>
            </div>
            </a>
          </div>
        </Grid>
        </Grid>
        </div>
        </Container>
      </div>
    </div>
  );
}
export default Catelog;