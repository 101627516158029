import * as React from "react";
import styles from "./Artable.module.css";
import Tableheader from "../Tableheader/Tableheader";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  IconButton,
  Pagination,
  PaginationItem,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Popup from "../../Popupdelete/Popup";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import { isValidElement } from "react";
import { style } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Enterdetails from "../../Popupdelete/enterDetails/Enterdetails";
import homeImage from "../../../../../constant";
import { useDispatch, useSelector } from "react-redux";
import CircularLoader from "../../../../../common/loader/Loader";
import serverimage from "../../../../../serverConstant";
import { setArtsdata } from "../../../../../State/Features/superadmin/artsSlice";
import { fetchArtsData } from "../../../../../State/api/superadminApi";
// import Pagination from "../../Pagination/Pagination";
import Csvfile from "../../../../../downloadFiles/Csvfile";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const sortdata = [
  { label: "Price Low To High", value: "low" },
  { label: "Price High to Low", value: "high" },
];
const filterdata = [
  { label: "Buy Price", value: "buy" },
  { label: "Rent Price", value: "rent" },
  { label: "Print Price", value: "print" },
];
export default function Artable(props) {

  console.log("artstableProps-->",props)
  const [activetab, setActivetab] = useState("pending");
  const [condition, setCondition] = useState({ sorting: "", filter: "" });

  const [search, setSearch] = useState("");
  const [ids, setIds] = useState();
  const [open, setOpen] = useState(false);
  const [declineopen, setDeclineopen] = useState(false);
  const [header, setHeader] = useState({
    approvedhead: props.approvedhead,
    pendinghead: props.pendinghead,
    sellerhead: props.sellerhead,
    popularhead: props.popularhead,
  });
 
  let ExcelDownloadData = {
    approvedData: props.approvedData,
    pendingData: props.pendingData,
    declineData: props.declineData,
  };

  const [artlist, setArtlist] = useState({
    pending: true,
    approved: false,
    seller: false,
    popular: false,
    deleted: false,
    decline: false,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const threedots = Boolean(anchorEl);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const loader = useSelector((state) => state.loader);
  const allData = useSelector((state) => state.artsdata.data);

  console.log("allData",allData)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIds(event.target.id);
  };

  const handleClickapprove = (event, row) => {
    setAnchorEl(event.currentTarget);
    setIds(event.target.id);
    props.setOfferId(row.Offers.length > 0 ? row.Offers[0]._id : "");
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };
  const handleMorecategories = (e) => {
    let value = e.target.value;
    if (value === "pending") {
      setActivetab("pending");
      setArtlist({
        pending: true,
        approved: false,
        seller: false,
        popular: false,
        deleted: false,
      });
      props.setData({ is_accepted: "Pending" });
    } else if (value === "approved") {
      setActivetab("approved");
      setArtlist({
        pending: false,
        approved: true,
        seller: false,
        popular: false,
        deleted: false,
      });
      props.setData({ is_accepted: "Approved", offset: 1 });
    } else if (value === "seller") {
      setActivetab("seller");
      props.setData({ is_best_seller: true, offset: 1 });
      setArtlist({
        pending: false,
        approved: false,
        seller: true,
        popular: true,
        deleted: false,
      });
    } else if (value === "popular") {
      setActivetab("popular");
      props.setData({ is_most_popular: true, offset: 1 });
      setArtlist({
        pending: false,
        approved: false,
        seller: false,
        popular: true,
        deleted: false,
      });
    } else if (value === "deleted") {
      setActivetab("deleted");
      props.setData({ is_deleted: true });
      setArtlist({
        pending: false,
        approved: false,
        seller: false,
        popular: false,
        deleted: true,
      });
    } else if (value === "decline") {
      setActivetab("decline");
      props.setData({ is_accepted: "Rejected", offset: 1 });
      setArtlist({
        pending: false,
        approved: false,
        seller: false,
        popular: false,
        deleted: false,
        decline: true,
      });
    }
  };
  const handleProfile = (id) => {
    navigate(`/art/${id}`);
  };
  const handleArtsAcceptDecline = () => {
    setDeclineopen(true);
  };
  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
  };
  const handleFilter = (event) => {
    const value = event.target.value;
    if (value === "") {
      setCondition({ ...condition, filter: '' });
    } else {
      setCondition({ ...condition, filter: value });
    }
  };


  const handleSorting = (event) => {
    const value = event.target.value;
  
    let sortedData = allData?.data ? [...allData.data] : [];
  
    if (condition.filter === "buy") {
      sortedData.sort((a, b) =>
        value === "low" ? a.sale?.price - b.sale?.price : b.sale?.price - a.sale?.price
      );
    } else if (condition.filter === "rent") {
      sortedData.sort((a, b) =>
        value === "low" ? a.rent?.rent_price - b.rent?.rent_price : b.rent?.rent_price - a.rent?.rent_price
      );
    } else if (condition.filter === "print") {
      sortedData.sort((a, b) =>
        value === "low" ? a.print[0]?.print_price - b.print[0]?.print_price : b.print[0]?.print_price - a.print[0]?.print_price
      );
    } else {
      sortedData.sort((a, b) =>
        value === "low"
          ? (a.sale?.price || a.rent?.rent_price || a.print?.[0]?.print_price || 0) - 
            (b.sale?.price || b.rent?.rent_price || b.print?.[0]?.print_price || 0)
          : (b.sale?.price || b.rent?.rent_price || b.print?.[0]?.print_price || 0) - 
            (a.sale?.price || a.rent?.rent_price || a.print?.[0]?.print_price || 0)
      );
    }
  
    dispatch(fetchArtsData({ data: sortedData, totalCount: allData?.totalCount }));
    setCondition({ ...condition, sorting: value });
  };


   const [currentPage, setCurrentPage] = React.useState(1);
      const itemsPerPage = 10; // Adjust how many items you want per page
    
      // Function to handle page changes
      const handlePageChange = (event, page) => {
        setCurrentPage(page);
      };
    
      // Slice the data based on current page
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const currentItems = allData?.data?.slice(startIndex, endIndex);
  

  return (
    <>
      {open ? <Popup open={open} setOpen={setOpen} /> : ""}
      {declineopen ? (
        <Enterdetails
          text="Reason for Rejection"
          poptype="curator_art_cancel"
          open={declineopen}
          setOpen={setDeclineopen}
        />
      ) : (
        ""
      )}
      <div className={styles.buyer_main}>
        <div className={styles.table_head}>
          <Tableheader
            handleFilter={handleFilter}
            handleSorting={handleSorting}
            filterdata={filterdata}
            sortdata={sortdata}
            condition={condition}
            button=""
            search={search}
            handleSearch={handleSearch}
          />
        </div>
        
        <div className={styles.more_categories}>
          <div>
          {props.artOptions.map((val, index) => (
            <Button
              onClick={handleMorecategories}
              key={val.id}
              className={styles.more_categories_btn}
              sx={
                index === 0
                  ? {
                      width: "130px",
                      height: "40px",
                      borderRadius: "5px 0px 0px 0px",
                      mt: 1,
                    }
                  : index === props.artOptions.length - 1
                  ? {
                      width: "130px",
                      height: "40px",
                      borderRadius: "0px 5px 0px 0px",
                      mt: 1,
                    }
                  : {
                      width: "130px",
                      height: "40px",
                      borderRadius: "0px",
                      mt: 1,
                    }
              }
              value={val.value}
              variant={activetab === val.value ? "contained" : ""}
              style={
                activetab === val.value ? {} : { backgroundColor: "white" }
              }
            >
              {val.label}
            </Button>
          ))}
          </div>
           <div className={styles.dowcsv}>
          {props.approvedData && (
           
              <Csvfile exshibitiondata={ExcelDownloadData} type="arts" />
           
          
        )}
         </div>
        </div>
        <TableContainer
          component={Paper}
          sx={{ backgroundColor: "#F6F6F6", color: "black" }}
        >
          <Table aria-label="customized table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
                {artlist.pending ? (
                  <>
                    {header.pendinghead.map((column) => (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "15px",
                          lineHeight: "21px",
                          color: "##1C1C1C",
                          whiteSpace: "nowrap",
                          textTransform:"capitalize"
                        }}
                        className={styles.table_head}
                        align="start"
                        key={column.id}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </>
                ) : artlist.approved ? (
                  <>
                    {header.approvedhead.map((column) => (
                      <TableCell
                        className={styles.table_head}
                        align="start"
                        sx={{
                          whiteSpace: "nowrap",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "15px",
                          height: "21px",
                          color: "#1C1C1C",
                        }}
                        key={column.id}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </>
                ) : artlist.seller ? (
                  <>
                    {header.sellerhead.map((column) => (
                      <TableCell
                        className={styles.table_head}
                        align="start"
                        sx={{
                          whiteSpace: "nowrap",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "15px",
                          height: "21px",
                          color: "#1C1C1C",
                        }}
                        key={column.id}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </>
                ) : artlist.popular ? (
                  <>
                    {header.popularhead.map((column) => (
                      <TableCell
                        className={styles.table_head}
                        align="start"
                        sx={{
                          whiteSpace: "nowrap",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "15px",
                          height: "21px",
                          color: "#1C1C1C",
                        }}
                        key={column.id}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </>
                ) : artlist.deleted ? (
                  <>
                    {header.popularhead.map((column) => (
                      <TableCell
                        className={styles.table_head}
                        align="start"
                        sx={{
                          whiteSpace: "nowrap",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "15px",
                          height: "21px",
                          color: "#1C1C1C",
                        }}
                        key={column.id}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </>
                ) : (
                  <>
                    {header.popularhead.map((column) => (
                      <TableCell
                        className={styles.table_head}
                        align="start"
                        sx={{
                          whiteSpace: "nowrap",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "15px",
                          height: "21px",
                          color: "#1C1C1C",
                        }}
                        key={column.id}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </>
                )}
              </TableRow>
            </TableHead>
            {loader.loader ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}>
                      {" "}
                      <CircularLoader size={60} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {artlist.pending ? (
                  <>
                    {props.pendingData && props.pendingData.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Box className={styles.loader_box}>
                            {" "}
                            No Data Found
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      currentItems &&
                      currentItems
                        .sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a.createdAt)
                        )
                        .filter((flt) => {

              

                          if (condition.filter === "buy") {
                            return flt.sale?.price;
                          } else if (condition.filter === "rent") {
                            return flt.rent?.rent_price;
                          } else if (condition.filter === "print") {
                            return flt?.print?.length > 0;
                          }
                          return true;
                        })
                        .filter((val) => {
                          if (search === "") {
                            return val;
                          }
                          {
                            const nameMatch =
                              val.name &&
                              val.name
                                .toLowerCase()
                                .includes(search.toLowerCase());
                            const artistMatch =
                              val.artist_name &&
                              val.artist_name[0] &&
                              val.artist_name[0]
                                .toLowerCase()
                                .includes(search.toLowerCase());

                            const curatorMatch =
                              val.curator_details &&
                              val.curator_details.length > 0 &&
                              val.curator_details.some(
                                (cur) =>
                                  cur.curator_name &&
                                  cur.curator_name
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                              );

                            return nameMatch || artistMatch || curatorMatch;
                          }
                        })

                        .sort((a, b) => {
                          if (condition.filter === "buy") {
                            if (condition.sorting === "low") {
                              return (a.sale?.price || 0) - (b.sale?.price || 0); // Low to high for buy
                            } else if (condition.sorting === "high") {
                              return (b.sale?.price || 0) - (a.sale?.price || 0); // High to low for buy
                            }
                          } else if (condition.filter === "rent") {
                            if (condition.sorting === "low") {
                              return (a.rent?.rent_price || 0) - (b.rent?.rent_price || 0); // Low to high for rent
                            } else if (condition.sorting === "high") {
                              return (b.rent?.rent_price || 0) - (a.rent?.rent_price || 0); // High to low for rent
                            }
                          } else if (condition.filter === "print") {
                            if (condition.sorting === "low") {
                              return (a.print[0]?.print_price || 0) - (b.print[0]?.print_price || 0); // Low to high for rent
                            } else if (condition.sorting === "high") {
                              return (b.print[0]?.print_price || 0) - (a.print[0]?.print_price || 0); // High to low for rent
                            }
                          }
                          return 0; // Default: no sorting
                        })
                        .map((row) => {
                          return (
                            <TableRow
                              style={{ color: "#636365" }}
                              key={row._id}
                            >
                              <TableCell
                                onClick={() => handleProfile(row._id)}
                                sx={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  color: "#636365",
                                  whiteSpace: "nowrap",
                                }}
                                className={styles.table_column}
                                align="left"
                              >
                                <img
                                  src={row.image}
                                  border="none"
                                  height="42px"
                                  width="60px"
                                  alt="img"
                                />{" "}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  color: "#636365",
                                  whiteSpace: "nowrap",
                                }}
                                className={styles.table_column}
                                align="left"
                              >
                                {row.art_name}
                                {row.mobile_no}
                                {row.name}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  color: "#636365",
                                  whiteSpace: "nowrap",
                                }}
                                className={styles.table_column}
                                align="left"
                              >
                                {row.artist_name}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  color: "#636365",
                                  whiteSpace: "nowrap",
                                }}
                                className={styles.table_column}
                                align="left"
                              >
                                {row.curator_details.length > 0
                                  ? row.curator_details?.map(
                                      (cur, i) => cur.curator_name
                                    )
                                  : "----"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  color: "#636365",
                                  whiteSpace: "nowrap",
                                }}
                                className={styles.table_column}
                                align="left"
                              >
                                {row.sale?.price
                                  ? "$" + row.sale?.price
                                  : "---"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  color: "#636365",
                                  whiteSpace: "nowrap",
                                }}
                                className={styles.table_column}
                                align="left"
                              >
                                {row.rent?.rent_price
                                  ? "$" + row.rent?.rent_price
                                  : "---"}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  color: "#636365",
                                  whiteSpace: "nowrap",
                                }}
                                className={styles.table_column}
                                align="left"
                              >
                                {row?.print?.length === 0
                                  ? "---"
                                  : row?.print
                                      ?.map((pr, i) => "$" + pr.print_price)
                                      .join(",")}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  color: "#636365",
                                  whiteSpace: "nowrap",
                                }}
                                className={styles.table_columns}
                                align="left"
                              >
                                <Button
                                  onClick={() =>
                                    props.handleArtsAcceptDecline(
                                      row._id,
                                      "Approved"
                                    )
                                  }
                                  color="primary"
                                  variant="contained"
                                  sx={{ width: "100px", mr: 2 }}
                                >
                                  Accept
                                </Button>
                                <Button
                                  onClick={() => props.handleReject(row._id)}
                                  color="primary"
                                  variant="outlined"
                                  sx={{ width: "100px", mr: 2 }}
                                >
                                  Decline
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    )}
                  </>
                ) : artlist.approved ? (
                  <>
                    {props.approvedData && props.approvedData.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Box className={styles.loader_box}>
                            {" "}
                            No Data Found
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      currentItems &&
                      currentItems
                        .sort(
                          (a, b) =>
                            new Date(b.updatedAt) - new Date(a.updatedAt)
                        )
                        .filter((flt) => {
                          if (condition.filter === "buy") {
                            return flt.sale?.price;
                          } else if (condition.filter === "rent") {
                            return flt.rent?.rent_price;
                          } else if (condition.filter === "print") {
                            return flt?.print?.length > 0;
                          }
                          return true;
                        })
                        .filter((val) => {
                          if (search === "") {
                            return val;
                          } else {
                            const nameMatches = (val.name || "")
                              .toLowerCase()
                              .includes(search.toLowerCase());
                            const artistNameMatches =
                              Array.isArray(val.artist_name) &&
                              val.artist_name.some((artist) =>
                                artist
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              );

                            return nameMatches || artistNameMatches;
                          }
                        })
                        .sort((a, b) => {
                          if (condition.filter === "buy") {
                            if (condition.sorting === "low") {
                              return (a.sale?.price || 0) - (b.sale?.price || 0); // Low to high for buy
                            } else if (condition.sorting === "high") {
                              return (b.sale?.price || 0) - (a.sale?.price || 0); // High to low for buy
                            }
                          } else if (condition.filter === "rent") {
                            if (condition.sorting === "low") {
                              return (a.rent?.rent_price || 0) - (b.rent?.rent_price || 0); // Low to high for rent
                            } else if (condition.sorting === "high") {
                              return (b.rent?.rent_price || 0) - (a.rent?.rent_price || 0); // High to low for rent
                            }
                          } else if (condition.filter === "print") {
                            if (condition.sorting === "low") {
                              return (a.print[0]?.print_price || 0) - (b.print[0]?.print_price || 0); // Low to high for rent
                            } else if (condition.sorting === "high") {
                              return (b.print[0]?.print_price || 0) - (a.print[0]?.print_price || 0); // High to low for rent
                            }
                          }
                          return 0; // Default: no sorting
                        })
                        .map((row) => {
                          return (
                            <TableRow
                              style={{ color: "#636365" }}
                              key={row.customer_id}
                            >
                              <TableCell
                                onClick={() => handleProfile(row._id)}
                                className={styles.table_column}
                                align="left"
                              >
                                <img
                                  src={row.image}
                                  height="42px"
                                  width="60px"
                                  alt="img"
                                />
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.name}
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.artist_name}
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.sale?.price
                                  ? "$" + row.sale?.price
                                  : "---"}
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.rent?.rent_price
                                  ? "$" + row.rent?.rent_price
                                  : "---"}
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.print.length > 0
                                  ? row?.print?.map((print, i) =>
                                      print?.print_price
                                        ? "$" + print?.print_price
                                        : "---"
                                    )
                                  : "----"}
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  {/* <Box sx={{width:'51px',fontSize:'16px'}}>{row.print}</Box> */}
                                  <Box>
                                    <Tooltip title="Account settings">
                                      <IconButton
                                        onClick={(event) =>
                                          handleClickapprove(event, row)
                                        }
                                        id={row._id}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={
                                          threedots ? "account-menu" : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                          threedots ? "true" : undefined
                                        }
                                      >
                                        <img
                                          src={"/img/Menu Icon.png"}
                                          height="21.62px"
                                          width="4.63px"
                                          alt="img"
                                          id={row._id}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                  <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={threedots}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                      elevation: 0,
                                      sx: {
                                        width: "120px",
                                        overflow: "visible",
                                        boxShadow:
                                          "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                        filter:
                                          "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                        mt: 2.5,
                                        "& .MuiAvatar-root": {
                                          width: "120px",
                                          height: "132px",
                                          ml: 0.5,
                                          mr: 1,
                                          // mt:5
                                        },
                                        "&:before": {
                                          content: '""',
                                          display: "block",
                                          position: "absolute",
                                          top: 0,
                                          // horizontal: 'center',
                                          right: 48,
                                          width: 10,
                                          height: 10,
                                          bgcolor: "background.paper",
                                          transform:
                                            "translateY(-50%) rotate(45deg)",
                                          zIndex: 0,
                                        },
                                      },
                                    }}
                                    transformOrigin={{
                                      horizontal: "center",
                                      vertical: "right",
                                    }}
                                    anchorOrigin={{
                                      horizontal: "left",
                                      vertical: "center",
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() =>
                                        props.handleBestSeller(ids, true)
                                      }
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: "16px",
                                        lineHeight: "12px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Best Seller
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                      onClick={() =>
                                        props.handleMostPopular(ids, true)
                                      }
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: "16px",
                                        lineHeight: "12px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Most Popular
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                      // onClick={() => navigate("/applyoffer")}
                                      onClick={() => {
                                        props.handleOpen();
                                        props.setArtId(ids);
                                      }}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: "16px",
                                        lineHeight: "12px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Apply Offer
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                      onClick={() => props.handleDelete(ids)}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        lineHeight: "12px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Delete
                                    </MenuItem>
                                  </Menu>
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    )}
                  </>
                ) : artlist.seller ? (
                  <>
                    {props.sellerData && props.sellerData.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Box className={styles.loader_box}>
                            {" "}
                            No Data Found
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      props.sellerData &&
                      props.sellerData
                        .sort(
                          (a, b) =>
                            new Date(b.updatedAt) - new Date(a.updatedAt)
                        )
                        .filter((flt) => {
                          if (condition.filter === "buy") {
                            return flt.sale?.price;
                          } else if (condition.filter === "rent") {
                            return flt.rent?.rent_price;
                          } else if (condition.filter === "print") {
                            return flt?.print?.length > 0;
                          }
                          return true;
                        })
                        .filter((val) => {
                          if (search === "") {
                            return val;
                          }
                          {
                            const nameMatch =
                              val.name &&
                              val.name
                                .toLowerCase()
                                .includes(search.toLowerCase());
                            const artistMatch =
                              val.artist_name &&
                              val.artist_name[0] &&
                              val.artist_name[0]
                                .toLowerCase()
                                .includes(search.toLowerCase());

                            const curatorMatch =
                              val.curator_details &&
                              val.curator_details.length > 0 &&
                              val.curator_details.some(
                                (cur) =>
                                  cur.curator_name &&
                                  cur.curator_name
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                              );

                            return nameMatch || artistMatch || curatorMatch;
                          }
                        })
                        .sort((a, b) => {
                          if (condition.filter === "buy") {
                            if (condition.sorting === "low") {
                              return (a.sale?.price || 0) - (b.sale?.price || 0); // Low to high for buy
                            } else if (condition.sorting === "high") {
                              return (b.sale?.price || 0) - (a.sale?.price || 0); // High to low for buy
                            }
                          } else if (condition.filter === "rent") {
                            if (condition.sorting === "low") {
                              return (a.rent?.rent_price || 0) - (b.rent?.rent_price || 0); // Low to high for rent
                            } else if (condition.sorting === "high") {
                              return (b.rent?.rent_price || 0) - (a.rent?.rent_price || 0); // High to low for rent
                            }
                          } else if (condition.filter === "print") {
                            if (condition.sorting === "low") {
                              return (a.print[0]?.print_price || 0) - (b.print[0]?.print_price || 0); // Low to high for rent
                            } else if (condition.sorting === "high") {
                              return (b.print[0]?.print_price || 0) - (a.print[0]?.print_price || 0); // High to low for rent
                            }
                          }
                          return 0; // Default: no sorting
                        })
                        .map((row) => {
                          return (
                            <TableRow
                              style={{ color: "#636365" }}
                              key={row.customer_id}
                            >
                              <TableCell
                                onClick={() => handleProfile(row._id)}
                                className={styles.table_column}
                                align="left"
                              >
                                <img
                                  src={row.image}
                                  height="42px"
                                  width="60px"
                                  alt="img"
                                />
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.name}
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.artist_name}
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.sale?.price
                                  ? "$" + row.sale?.price
                                  : "---"}
                              </TableCell>
                              {/* <TableCell className={styles.table_column} align="left">{row.demo}</TableCell> */}
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.rent?.rent_price
                                  ? "$" + row.rent?.rent_price
                                  : "---"}
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.print.length > 0
                                  ? row?.print?.map((print, i) =>
                                      print?.print_price
                                        ? "$" + print?.print_price
                                        : "---"
                                    )
                                  : "----"}
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  {/* <Box sx={{width:'51px',fontSize:'16px'}}>{row.print}</Box> */}
                                  <Box>
                                    <Tooltip title="Account settings">
                                      <IconButton
                                        onClick={handleClick}
                                        id={row._id}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={
                                          threedots ? "account-menu" : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                          threedots ? "true" : undefined
                                        }
                                      >
                                        <img
                                          src={"/img/Menu Icon.png"}
                                          height="21.62px"
                                          width="4.63px"
                                          alt="img"
                                          id={row._id}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                  <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={threedots}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                      elevation: 0,
                                      sx: {
                                        width: "140px",
                                        overflow: "visible",
                                        boxShadow:
                                          "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                        filter:
                                          "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                        mt: 2.5,
                                        "& .MuiAvatar-root": {
                                          width: "120px",
                                          height: "132px",
                                          ml: 0.5,
                                          mr: 1,
                                          // mt:5
                                        },
                                        "&:before": {
                                          content: '""',
                                          display: "block",
                                          position: "absolute",
                                          top: 0,
                                          // horizontal: 'center',
                                          right: 48,
                                          width: 10,
                                          height: 10,
                                          bgcolor: "background.paper",
                                          transform:
                                            "translateY(-50%) rotate(45deg)",
                                          zIndex: 0,
                                        },
                                      },
                                    }}
                                    transformOrigin={{
                                      horizontal: "center",
                                      vertical: "right",
                                    }}
                                    anchorOrigin={{
                                      horizontal: "left",
                                      vertical: "center",
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() =>
                                        props.handleBestSeller(ids, false)
                                      }
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: "16px",
                                        lineHeight: "12px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Remove from here
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                      onClick={() =>
                                        props.handleMostPopular(ids, true)
                                      }
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: "16px",
                                        lineHeight: "12px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Most Popular
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                      onClick={() => props.handleDelete(ids)}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        lineHeight: "12px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Delete
                                    </MenuItem>
                                  </Menu>
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    )}
                  </>
                ) : artlist.popular ? (
                  <>
                    {props.popularData && props.popularData.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Box className={styles.loader_box}>
                            {" "}
                            No Data Found
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      props.popularData &&
                      props.popularData
                        .sort(
                          (a, b) =>
                            new Date(b.updatedAt) - new Date(a.updatedAt)
                        )
                        .filter((flt) => {
                          if (condition.filter === "buy") {
                            return flt.sale?.price;
                          } else if (condition.filter === "rent") {
                            return flt.rent?.rent_price;
                          } else if (condition.filter === "print") {
                            return flt?.print?.length > 0;
                          }
                          return true;
                        })
                        .filter((val) => {
                          if (search === "") {
                            return val;
                          }
                          {
                            const nameMatch =
                              val.name &&
                              val.name
                                .toLowerCase()
                                .includes(search.toLowerCase());
                            const artistMatch =
                              val.artist_name &&
                              val.artist_name[0] &&
                              val.artist_name[0]
                                .toLowerCase()
                                .includes(search.toLowerCase());

                            return nameMatch || artistMatch;
                          }
                        })
                        .sort((a, b) => {
                          if (condition.filter === "buy") {
                            if (condition.sorting === "low") {
                              return (a.sale?.price || 0) - (b.sale?.price || 0); // Low to high for buy
                            } else if (condition.sorting === "high") {
                              return (b.sale?.price || 0) - (a.sale?.price || 0); // High to low for buy
                            }
                          } else if (condition.filter === "rent") {
                            if (condition.sorting === "low") {
                              return (a.rent?.rent_price || 0) - (b.rent?.rent_price || 0); // Low to high for rent
                            } else if (condition.sorting === "high") {
                              return (b.rent?.rent_price || 0) - (a.rent?.rent_price || 0); // High to low for rent
                            }
                          } else if (condition.filter === "print") {
                            if (condition.sorting === "low") {
                              return (a.print[0]?.print_price || 0) - (b.print[0]?.print_price || 0); // Low to high for rent
                            } else if (condition.sorting === "high") {
                              return (b.print[0]?.print_price || 0) - (a.print[0]?.print_price || 0); // High to low for rent
                            }
                          }
                          return 0; // Default: no sorting
                        })
                        .map((row) => {
                          return (
                            <TableRow
                              style={{ color: "#636365" }}
                              key={row.customer_id}
                            >
                              <TableCell
                                onClick={() => handleProfile(row._id)}
                                className={styles.table_column}
                                align="left"
                              >
                                <img
                                  src={row.image}
                                  height="42px"
                                  width="60px"
                                  alt="img"
                                />
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.name}
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.artist_name}
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.sale?.price
                                  ? "$" + row.sale?.price
                                  : "---"}
                              </TableCell>
                              {/* <TableCell className={styles.table_column} align="left">{row.demo}</TableCell> */}
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.rent?.rent_price
                                  ? "$" + row.rent?.rent_price
                                  : "---"}
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.print.length > 0
                                  ? row?.print?.map((print, i) =>
                                      print?.print_price
                                        ? "$" + print?.print_price
                                        : "---"
                                    )
                                  : "----"}
                              </TableCell>

                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  {/* <Box sx={{width:'51px',fontSize:'16px'}}>{row.print}</Box> */}
                                  <Box>
                                    <Tooltip title="Account settings">
                                      <IconButton
                                        onClick={handleClick}
                                        id={row._id}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={
                                          threedots ? "account-menu" : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                          threedots ? "true" : undefined
                                        }
                                      >
                                        <img
                                          src={"/img/Menu Icon.png"}
                                          height="21.62px"
                                          width="4.63px"
                                          alt="img"
                                          id={row._id}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                  <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={threedots}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                      elevation: 0,
                                      sx: {
                                        width: "140px",
                                        overflow: "visible",
                                        boxShadow:
                                          "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                        filter:
                                          "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                        mt: 2.5,
                                        "& .MuiAvatar-root": {
                                          width: "120px",
                                          height: "132px",
                                          ml: 0.5,
                                          mr: 1,
                                          // mt:5
                                        },
                                        "&:before": {
                                          content: '""',
                                          display: "block",
                                          position: "absolute",
                                          top: 0,
                                          // horizontal: 'center',
                                          right: 48,
                                          width: 10,
                                          height: 10,
                                          bgcolor: "background.paper",
                                          transform:
                                            "translateY(-50%) rotate(45deg)",
                                          zIndex: 0,
                                        },
                                      },
                                    }}
                                    transformOrigin={{
                                      horizontal: "center",
                                      vertical: "right",
                                    }}
                                    anchorOrigin={{
                                      horizontal: "left",
                                      vertical: "center",
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() =>
                                        props.handleMostPopular(ids, false)
                                      }
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: "16px",
                                        lineHeight: "12px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Remove from here
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                      onClick={() =>
                                        props.handleBestSeller(ids, true)
                                      }
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: "16px",
                                        lineHeight: "12px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Best Seller
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                      onClick={() => props.handleDelete(ids)}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        lineHeight: "12px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Delete
                                    </MenuItem>
                                  </Menu>
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    )}
                  </>
                ) : artlist.deleted ? (
                  <>
                    {props.deletedData && props.deletedData.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Box className={styles.loader_box}>
                            {" "}
                            No Data Found
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      props.deletedData &&
                      props.deletedData
                        .sort(
                          (a, b) =>
                            new Date(b.updatedAt) - new Date(a.updatedAt)
                        )
                        .filter((flt) => {
                          if (condition.filter === "buy") {
                            return flt.sale?.price;
                          } else if (condition.filter === "rent") {
                            return flt.rent?.rent_price;
                          } else if (condition.filter === "print") {
                            return flt?.print?.length > 0;
                          }
                          return true;
                        })

                        .filter((val) => {
                          if (search === "") {
                            return val;
                          }
                          {
                            const nameMatch =
                              val.name &&
                              val.name
                                .toLowerCase()
                                .includes(search.toLowerCase());
                            const artistMatch =
                              val.artist_name &&
                              val.artist_name[0] &&
                              val.artist_name[0]
                                .toLowerCase()
                                .includes(search.toLowerCase());

                            return nameMatch || artistMatch;
                          }
                        })

                       
                        .map((row) => {
                          return (
                            <TableRow
                              style={{ color: "#636365" }}
                              key={row.customer_id}
                            >
                              <TableCell
                                onClick={() => handleProfile(row._id)}
                                className={styles.table_column}
                                align="left"
                              >
                                <img
                                  src={row.image}
                                  height="42px"
                                  width="60px"
                                  alt="img"
                                />
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.name}
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.artist_name}
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.sale?.price
                                  ? "$" + row.sale?.price
                                  : "---"}
                                {/* {row.demo} */}
                              </TableCell>
                              {/* <TableCell className={styles.table_column} align="left">{row.demo}</TableCell> */}
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.rent?.rent_price
                                  ? "$" + row.rent?.rent_price
                                  : "---"}
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.print.length > 0
                                  ? row?.print?.map((print, i) =>
                                      print?.print_price
                                        ? "$" + print?.print_price
                                        : "---"
                                    )
                                  : "----"}
                              </TableCell>
                            </TableRow>
                          );
                        })
                    )}
                  </>
                ) : (
                  <>
                    {currentItems &&
                      currentItems
                        .sort(
                          (a, b) =>
                            new Date(b.updatedAt) - new Date(a.updatedAt)
                        )
                        .filter((flt) => {
                          if (condition.filter === "buy") {
                            return flt.sale?.price;
                          } else if (condition.filter === "rent") {
                            return flt.rent?.rent_price;
                          } else if (condition.filter === "print") {
                            return flt?.print?.length > 0;
                          }
                          return true;
                        })
                        .filter((val) => {
                          if (search === "") {
                            return val;
                          }
                          {
                            const nameMatch =
                              val.name &&
                              val.name
                                .toLowerCase()
                                .includes(search.toLowerCase());
                            const artistMatch =
                              val.artist_name &&
                              val.artist_name[0] &&
                              val.artist_name[0]
                                .toLowerCase()
                                .includes(search.toLowerCase());

                            return nameMatch || artistMatch;
                          }
                        })
                        .sort((a, b) => {
                          if (condition.filter === "buy") {
                            if (condition.sorting === "low") {
                              return (a.sale?.price || 0) - (b.sale?.price || 0); // Low to high for buy
                            } else if (condition.sorting === "high") {
                              return (b.sale?.price || 0) - (a.sale?.price || 0); // High to low for buy
                            }
                          } else if (condition.filter === "rent") {
                            if (condition.sorting === "low") {
                              return (a.rent?.rent_price || 0) - (b.rent?.rent_price || 0); // Low to high for rent
                            } else if (condition.sorting === "high") {
                              return (b.rent?.rent_price || 0) - (a.rent?.rent_price || 0); // High to low for rent
                            }
                          } else if (condition.filter === "print") {
                            if (condition.sorting === "low") {
                              return (a.print[0]?.print_price || 0) - (b.print[0]?.print_price || 0); // Low to high for rent
                            } else if (condition.sorting === "high") {
                              return (b.print[0]?.print_price || 0) - (a.print[0]?.print_price || 0); // High to low for rent
                            }
                          }
                          return 0; // Default: no sorting
                        })
                        .map((row) => {
                          return (
                            <TableRow
                              style={{ color: "#636365" }}
                              key={row._id}
                            >
                              <TableCell
                                onClick={() => handleProfile(row._id)}
                                className={styles.table_column}
                                align="left"
                              >
                                <img
                                  src={row.image}
                                  height="42px"
                                  alt="img"
                                  width="60px"
                                />
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.name}
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.artist_name}
                              </TableCell>
                              {/* <TableCell className={styles.table_column} align="left">{row.email}{row.demo}</TableCell> */}
                              {/* <TableCell className={styles.table_column} align="left">{row.demo}</TableCell> */}
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.sale?.price}
                              </TableCell>
                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                {row.rent?.rent_price
                                  ? "$" + row.rent?.rent_price
                                  : "---"}
                              </TableCell>

                              <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Box sx={{ width: "51px", fontSize: "16px" }}>
                                    {row.print.length > 0
                                      ? row?.print?.map((print, i) =>
                                          print?.print_price
                                            ? "$" + print?.print_price
                                            : "---"
                                        )
                                      : "----"}
                                  </Box>
                                  </Box>
                                  </TableCell>
                
                                  <TableCell
                                className={styles.table_column}
                                align="left"
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Box>
                                    <Tooltip title="Account settings">
                                      <IconButton
                                        onClick={handleClick}
                                        id={row._id}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={
                                          threedots ? "account-menu" : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                          threedots ? "true" : undefined
                                        }
                                      >
                                        <img
                                          src={"/img/Menu Icon.png"}
                                          height="21.62px"
                                          width="4.63px"
                                          alt="img"
                                          id={row._id}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                  <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={threedots}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                      elevation: 0,
                                      sx: {
                                        width: "120px",
                                        overflow: "visible",
                                        boxShadow:
                                          "0px 0px 10px rgba(0, 0, 0, 0.1)",
                                        filter:
                                          "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                        mt: 2.5,
                                        "& .MuiAvatar-root": {
                                          width: "120px",
                                          height: "132px",
                                          ml: 0.5,
                                          mr: 1,
                                          // mt:5
                                        },
                                        "&:before": {
                                          content: '""',
                                          display: "block",
                                          position: "absolute",
                                          top: 0,
                                          // horizontal: 'center',
                                          right: 48,
                                          width: 10,
                                          height: 10,
                                          bgcolor: "background.paper",
                                          transform:
                                            "translateY(-50%) rotate(45deg)",
                                          zIndex: 0,
                                        },
                                      },
                                    }}
                                    transformOrigin={{
                                      horizontal: "center",
                                      vertical: "right",
                                    }}
                                    anchorOrigin={{
                                      horizontal: "left",
                                      vertical: "center",
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() => props.handleDelete(ids)}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        lineHeight: "12px",
                                        fontWeight: "400",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Delete
                                    </MenuItem>
                                  </Menu>
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {
          activetab ==='approved' || activetab==='pending' || activetab==='decline'?
          <>
           <Box className={styles.pagination_box} sx={{}}>
          <Pagination   count={Math.ceil(allData?.data?.length / itemsPerPage)} // Total pages
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        renderItem={(item) => (
          <PaginationItem
          sx={{
            "&.Mui-selected": {
              backgroundColor: "black", // Set the selected background to black
              color: "white", // Ensure the text color is visible
              "&:hover": {
                backgroundColor: "black", // Maintain black on hover
              },
            },
          }}
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )} />
          </Box>
          </>
          :''
        }
        {/* {allData && Array.isArray(allData.data) && allData.data.length > 0 && (
          <Pagination
            totaldata={
              activetab === "pending"
                ? allData.totalPendingCount
                : activetab === "approved"
                ? allData.totalApprovedCount
                : allData.totalCount
            }
            pagination={props.data}
            setPagination={props.setData}
          />
        )} */}
      </div>
    </>
  );
}
