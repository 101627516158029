import React from "react"
import styles from "./PopupRequest.module.css"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import { Fade, Grid, Link } from "@mui/material"
import dayjs from "dayjs";

import { RiCloseCircleFill } from "react-icons/ri";
import serverimage from "../../../../serverConstant"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height:'700px',
  backgroundColor: "#FFFFFF",
  width: 600,
  bgcolor: "background.paper",
  // border: "0px solid #000",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  pl: 3,
  pr: 3,
  pt: 3,
  pb: 3,
}

const data = [
  {
    id: 1,
    user_title: "Username",
    user_val: "Artsmiley",
  },
  {
    id: 2,
    user_title: "Customer ID",
    user_val: "h3jh45",
  },
  {
    id: 3,
    user_title: "Buyer Name",
    user_val: "Smiley",
  },
  {
    id: 4,
    user_title: "Number of items",
    user_val: "878",
  },
  {
    id: 5,
    user_title: "Email Address",
    user_val: "jhe63hkj@gmail.com",
  },
]

export default function PopupQuote(props) {
  console.log(props.popupdataQuote, "data--popupdataQuote")

  // const handleOpen = () => props.setOpen(true);
  const handleClose = () => props.setOpenRequestQuote(false)

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.openRequestQuote}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openRequestQuote}>
          <Box sx={style} className={styles.modal_del}>
            <div className={styles.cancel_img}>
            <Link onClick={() => props.setOpenRequestQuote(false)}><RiCloseCircleFill className={styles.closicon}/></Link>
            </div>
            {/* grid */}
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={12} sm={4} md={4}>
                <div  className={styles.inputs_img}>
                <img
                  alt="img"
                  src={` ${props.popupdataQuote?.item_id?.image}`}
                  className="img-fluid"
                />
                </div>
              </Grid>
              <Grid item xs={12} sm={8} md={8}>
                <div className={styles.data_div}>
                  {/* {data.map((val) => ( */}
                  <p className={styles.odlist}>User Name : <span>{props.popupdataQuote?.first_name}
                  {props.popupdataQuote?.last_name}</span></p>
                  <p className={styles.odlist}>Sku_Id : <span> {props.popupdataQuote?.item_id?.sku_id}</span></p>
                  <p className={styles.odlist}>Art Name : <span>{props.popupdataQuote?.item_id?.name}</span></p>
                  <p className={styles.odlist}>Art Type : <span></span></p>
                  <p className={styles.odlist}>Request Date : <span>{dayjs(props.popupdataQuote?.updatedAt)
                        .format("DD-MM-YYYY")
                        .toString()}</span></p>
                  
 
                  {/* <div className={styles.user_detail}>
                    <h5 className={styles.user_title}>Art Type:</h5>
                    <p className={styles.user_value}></p>
                  </div> */}
 

                  {/* ))} */}
                </div>
              </Grid>
            </Grid>

            <div className={styles.reactext}>
              <h5 className={styles.requestpop}>Remark for Recreate:</h5>
              <p>{props.popupdataQuote?.item_id?.description}</p>
            </div>

            {/*  */}
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
