import React, { useEffect, useState } from 'react'
import styles from './CuratorNotification.module.css';
import { 
  Box, 
  TextField , 
  IconButton, 
  InputAdornment, 
  Pagination, 
  PaginationItem, 
  Card, CardContent, CardHeader, Avatar, Typography, Button, Grid } from "@mui/material";
  import ClearIcon from "@mui/icons-material/Clear";
import Notify from '../../../components/Pages/List/Common/Notification/Notification';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotificationData } from '../../../State/api/superadminApi';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";


const data = [
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: true,
   
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: true,
    btn: [],
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: true,
    btn: [],
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: false,
    btn: [],
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: false,
    btn: [
      {
        label: "Confirm File...",
      },
      {
        label: "Important ...",
      },
    ],
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: false,
    btn: [
      {
        label: "Confirm File...",
      },
      {
        label: "Important ...",
      },
    ],
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: false,
    btn: [
      {
        label: "Confirm File...",
      },
      {
        label: "Important ...",
      },
    ],
  },
]
export default function CuratorNotification() {

    const dispatch = useDispatch()
  
   const notificationdata = useSelector(
        (state) => state.notificationdata?.data
      )

       const fetchData = () => {
          dispatch(fetchNotificationData(data))
          console.log("Notification Data Fetch Called", notificationdata);
        }
        useEffect(() => {
          fetchData()
        }, [data])
        const [searchQuery, setSearchQuery] = useState("");
        // Filter notifications based on searchQuery
  const filteredNotifications = notificationdata?.notifications?.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  ) || [];
        const [currentPage, setCurrentPage] = useState(1);
              const itemsPerPage = 10;
              const handlePageChange = (event, page) => {
                setCurrentPage(page);
              };
            const pageSize = 10;  
            const startIndex = (currentPage - 1) * pageSize;
            const endIndex = startIndex + pageSize;
            const notificationsPaginatedData = filteredNotifications?.slice(startIndex, endIndex)
  return (
    <>
  <div className={styles.mains}>
      <TextField
    label="Search by ..."
    variant="outlined"
    fullWidth
    margin="normal"
    value={searchQuery}
    onChange={(e) => {
      setSearchQuery(e.target.value);
      setCurrentPage(1); // Reset to first page on new search
    }}
    InputProps={{
      endAdornment: searchQuery && (
        <InputAdornment position="end">
          <IconButton
            onClick={() => {
              setSearchQuery("");
              setCurrentPage(1); // Reset to first page on clear
            }}
            edge="end"
          >
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
  {filteredNotifications.length > 0 ? (
    <ul className={styles.notif_list}>
      {notificationsPaginatedData.map((item, index) => (
        <li key={index}>
          <div className={styles.notimeta}>
            <span className={styles.notiftl}>{item.title}</span> 
            <span className={styles.notifm}>
              {new Date(item.createdAt).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}&nbsp;&nbsp;&nbsp;
              {new Date(item.createdAt).toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              }).toUpperCase()}
            </span>
          </div>
          <p className={styles.descript_text}>{item.description}</p>
        </li>
      ))}
    </ul>
  ) : (
    <p className={styles.no_result_text}>No Notifications Found.</p>
  )}
  <Box className={styles.pagination_box} sx={{}}>
          <Pagination
            count={Math.ceil(filteredNotifications?.length / itemsPerPage
            )} // Total pages
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            renderItem={(item) => (
              <PaginationItem
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "black", // Set the selected background to black
                    color: "white", // Ensure the text color is visible
                    "&:hover": {
                      backgroundColor: "black", // Maintain black on hover
                    },
                  },
                }}
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
              />
            )}
          />
        </Box>
</div>


{/* <div className={styles.mains}>
<Notify data={data} />
</div> */}
</>
  )
}
