import React, { useState, useEffect } from "react"
import styles from "./heading.module.css"
import { useParams, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  editHeadingmenu,
  deleteHeadingMenu,
  fetchHeadingByID,
} from "../../../../State/api/superadminApi.js"
import { Button, IconButton, TextField, Link } from "@mui/material"
import { AlertsSuccess } from "../../../../common/alert/Alert"
import CircularLoader from "../../../../common/loader/Loader"
import { useNavigate } from "react-router-dom"
import { IoIosArrowBack } from "react-icons/io";
import { FiTrash2, FiEdit3 } from "react-icons/fi";

const HeadingMenu = () => {
  const location = useLocation()
  const { heading } = location.state || {}
  console.log(heading, "heading--meu")
  const { id } = useParams()
  const dispatch = useDispatch()
  const [name, setName] = useState({ name: "", _id: "" })
  const [menu, setMenu] = useState({ add: false, edit: false })
  const headingByMenu = useSelector((state) => state.headingByMenu.data)
  console.log(headingByMenu, "heading by menu000--")
  const loader = useSelector((state) => state.loader)

  const handleEdits = (type, menuName, _id) => {
    if (type === "edit") {
      setMenu({ ...menu, edit: true })
      setName({ name: menuName, _id })
    } else {
      setMenu({ ...menu, add: true })
    }
  }

  const handleSubmit = (id) => {
    dispatch(editHeadingmenu(id, name))
  }

  const handleDelete = (id) => {
    dispatch(deleteHeadingMenu(id)).then(() => {
      fetchHeading()
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setName((prevName) => ({ ...prevName, [name]: value }))
  }

  const subMenuByMenuIdData = useSelector(
    (state) => state.MenuBySubMenuData.data
  )

  // const fetchMenuData = () => {
  //   dispatch(fetchSubmenuData(id))
  // }

  // useEffect(() => {
  //   fetchMenuData()
  // }, [])
  const fetchHeading = () => {
    dispatch(fetchHeadingByID(id))
  }

  React.useEffect(() => {
    fetchHeading()
  }, [id])

   const navigate = useNavigate()

  const handleBack = () => {
    navigate("/menu")
  }

  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      <div className={styles.back_button}>
    <Link onClick={handleBack} className={styles.backbn}> <IoIosArrowBack /> <span> Go Back</span></Link>
      </div>
      <h3 className={styles.hrdngofv}>Menu Name : {heading ? heading?.name : ""}</h3>
      <div className={styles.detail_main}>
        <div className={styles.detail}>
          <h3 className={styles.title}>Heading</h3>
          {loader.loader ? (
            <CircularLoader size={60} />
          ) : headingByMenu && headingByMenu.headings.length === 0 ? (
            "------"
          ) : (
            headingByMenu &&
            headingByMenu?.headings?.map((val) => (
              <p className={styles.detail_para} key={val._id}>
                {menu.edit && val._id === name._id ? (
                  <>
                    <input
                    className="form-control"
                      value={name.name}
                      name="name"
                      onChange={handleChange}
                      variant="outlined"
                      type="text"
                    />
        {" "}
                    <button
                    className="fill-button-menu"
                      onClick={() => handleSubmit(val._id)}
                    >
                      Submit
                    </button>
                  </>
                ) : (
                  <>
                    <span className={styles.menpr}>{val.name}</span>
                    <div>
                    <IconButton
                      onClick={() => handleEdits("edit", val.name, val._id)}
                      
                    >
                      <FiEdit3 className={styles.editicon} />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(val._id)}>
                      <FiTrash2 className={styles.deleicon}/>
                    </IconButton>
                    </div>
                    
                  </>
                )}
              </p>
            ))
          )}
        </div>
      </div>
    </div>
  )
}

export default HeadingMenu
