import React, {useEffect, useState} from 'react';
import {PayPalScriptProvider, PayPalButtons} from "@paypal/react-paypal-js";

import axios from 'axios';
import { Errornotify, Successnotify } from '../common/alert/Alert';
import { placeOrderApi } from '../State/api/orderApi';
import { useDispatch } from 'react-redux';
import { formatNewCurrency } from '../utils/currencyFormat';
import api from '../State/api/Api';
const dt ={
    userid:'',
    totoalamount:'',
    item_ids:[{_id:''}]
}
const data = {
    "intent": "sale",
    "payer": {
      "payment_method": "paypal"
    },
    "transactions": [{
      "amount": {
        "total": "30.11",
        "currency": "USD",
        "details": {
          "subtotal": "30.00",
          "tax": "0.07",
          "shipping": "0.03",
          "handling_fee": "1.00",
          "shipping_discount": "-1.00",
          "insurance": "0.01"
        }
      },
      "description": "This is the payment transaction description.",
      "custom": "EBAY_EMS_90048630024435",
      "invoice_number": "48787589673",
      "payment_options": {
        "allowed_payment_method": "INSTANT_FUNDING_SOURCE"
      },
      "soft_descriptor": "ECHI5786786",
      "item_list": {
        "items": [{
          "name": "hat",
          "description": "Brown color hat",
          "quantity": "5",
          "price": "3",
          "tax": "0.01",
          "sku": "1",
          "currency": "USD"
        }, {
          "name": "handbag",
          "description": "Black color hand bag",
          "quantity": "1",
          "price": "15",
          "tax": "0.02",
          "sku": "product34",
          "currency": "USD"
        }],
        "shipping_address": {
          "recipient_name": "Hello World",
          "line1": "4thFloor",
          "line2": "unit#34",
          "city": "SAn Jose",
          "country_code": "US",
          "postal_code": "95131",
          "phone": "011862212345678",
          "state": "CA"
        }
      }
    }],
    "note_to_payer": "Contact us for any questions on your order.",
    "redirect_urls": {
      "return_url": "https://example.com",
      "cancel_url": "https://example.com"
    }
  }
const PaypalCheckoutButton = ({address,setAddress,couponData,giftCardAmount}) => {
    const product = {
     description: "Learn how to build a website with React JS",
     price: 1,
    };

useEffect(() => {
localStorage.setItem("payment_method", JSON.stringify("Paypal"))
},[])


  const dispatch = useDispatch()
    const [paidFor, setPaidFor] = useState(false);
    const [error, setError] = useState(null);
    const totalDiscountPercentage = JSON.parse(localStorage.getItem("discount_percentage")) || 0;

    const giftAmount = giftCardAmount || 0
  
    const totalAmount = parseFloat(localStorage.getItem('total_amount')?.replace(/[^0-9.]/g, '')) || 0;
    const discountPercentage = couponData?.map(val => val?.discount)?.reduce((acc, discount) => acc + discount, 0) || 0;
   
    // const amount = (totalAmount * (1 - (discountPercentage / 100)) - giftAmount).toFixed(2) 
    const amount = Number(JSON.parse(localStorage.getItem("payment_amount"))).toFixed(2)  || 0
    const discountedPrice = amount * (1 - discountPercentage / 100);

  //   const handleApprove = async (orderID) => {
  
  //     const userid = localStorage.getItem('login_token');
  //     const secretKey = "EB_wRVr0klRPuLD-DJ6awgjeBfN4POFLC1U9-U5Igsmn9EXo8fcDRP4FsO67nw0ByjPu7LlSWfhT-l0C";
  //     try {
  //         // const response = await axios({
  //         //   url: 'http://192.168.29.154:8000/api/user/payment',
  //         //     headers: {
  //         //         'Authorization': `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NjQ3MDlhZTQxODJhZDJiN2Q4MDc5MzUiLCJpYXQiOjE3MTU5MzE2NDYsImV4cCI6MTcxNjUzNjQ0Nn0.C2Uq_hLUppFggDrWkxCfLx53_ypMZy9FU3AWLm3JctA`,
  //         //         'Content-Type': 'application/json'
  //         //     },
  //         //     method: 'post',
  //         //     data: {
  //         //         order_id: '664459f6c48d0bbeabebf702',
  //         //         amount: amount,
  //         //         paymentType: "paypal",
  //         //         // detail: {
  //         //         //     user_id: userid,
  //         //         //     item_id: props.cart_item_id,
  //         //         // },
  //         //     },
  //         // });
  //         
  //         
  //         if (orderID) {
  //             Successnotify("Payment is Successful");
  //             
  //         }
  //     } catch (error) {
  
  //     }
  // };

    if(paidFor){
        alert("Thank You for purchasing from Art Smiley");
    }
    
    if(error){
        alert(error);
    }

    const initialOptions = {
        // "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        "client-id": "AYWjsJ-Q2mNg_Pvjnslq_eUlu6xCfDP5-GXb-77XFykzgZlJS4TyaSw6Wl4cQj9k4B40twLP8SEcN_1B",
        currency: "USD",
        intent: "Rent",
        "data-client-token": "abc123xyz==",
       };
       const createPayment = () => {
        axios.post('http://localhost:3000/user/artsmiley/pay',data)
          .then((response) => {
            const paymentId = response.data.paymentId;
            const approvalUrl = response.data.approvalUrl;
            window.location.href = approvalUrl;
          })
          .catch((error) => {
          });
      }

    //   app.get('http://localhost:3000/user/artsmiley/success', (req, res) => {
    //     const payerId = req.query.PayerID;
    //     const paymentId = req.query.paymentId;
      
    //     const execute_payment = {
    //       payer_id: payerId,
    //       transactions: [{
    //         amount: {
    //           currency: 'USD',
    //           total: '10.00'
    //         }
    //       }]
    //     };
      
    //     paypal.payment.execute(paymentId, execute_payment, (error, payment) => {
    //       if (error) {
    
    //         res.status(500).send('Internal server error');
    //       } else {
    
    //         res.send('Payment successful');
    //       }
    //     });
    //   });

  return (
   
    <div>
     
        <PayPalButtons 
          fundingSource="paypal"
          style={{
     
  
        
      
          height: 36, // Adjust height if necessary
           position:"relative"
        }}
     
            createOrder = {(data, actions) => {
                return actions.order.create({
                    purchase_units: [
                        {
                            description: localStorage.getItem('user_id'),
                            user_id:localStorage.getItem('user_id'),
                            amount: {
                                value: amount,
                            },
                        },
                    ],
                });
            }}
            // onApprove={(data, actions) => {
            //     return actions.order.capture().then((details) => {
            //         const name = details.payer.name.given_name;
            //         alert(`Transaction completed by ${name}`);
            //     });
            // }}
            onApprove = { async (data, action) => {
                const order = await action.order.capture();
                // const name = action.order.payer.name.given_name;
                if(order) {
                setAddress({...address, payment_method: "Paypal"})
                 dispatch(placeOrderApi(address))
                
                }else {
                  Errornotify("Payment Failed");
                }
                // alert(`Transaction completed by ${name}`);
                // handleApprove(data.orderID);
            }}
            // onCancel={() => {}}
            // onError={(err) => {
            //     setError(err);
            
            // }}
        />
      
    </div>
  )
}

export default PaypalCheckoutButton


