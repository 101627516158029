import validator from "validator";
import isEmpty from "./isEmpty";
const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]*[a-zA-Z]+[a-zA-Z0-9-]*\.(com|org|net|in|edu|gov|co|io|info|biz|me|ir|pk|ae|ue)(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;
const youtubeRegex = /^(https?:\/\/)?(www\.)?youtube\.com\/.+$/;

const linkedinRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|company)\/[a-zA-Z0-9_-]+(\/[a-zA-Z0-9_-]+)*\/?(\/\?.*)?$/;


const instagramRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_.]+(\/?.*)?$/;

const twitterRegex = /^(https?:\/\/)?(www\.)?x\.com\/[a-zA-Z0-9_]+(\/?.*)?$/;


const facebookRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.-]+(\/?.*)?$/;
export const validationForArtistApplyform = (
  promoting_by_reason,
  is_fulltime_artist,
  exclusive_work,
  exp,
  category_id,
  art_price_range,
  arts_solds_in_past_12_months,
  produce_art_each_month,
  artist_type_id,
  portfolio,
  online_art_gallary_URL,
  social_links,
 award_prize,
 about,
 art_work,
 othersReason,
 othersReasonCategory
) => {
    
  let applyForm = {};

  const isFile = file => file instanceof File;


  // Validate promoting_by_reason (ensure it has at least one reason)
  if (!promoting_by_reason || promoting_by_reason.length === 0) {
    applyForm.promoting_by_reason = "Reason to promote your work is required";
  }else if(promoting_by_reason.includes("Others")) {
    if(isEmpty(othersReason)) {
      applyForm.promoting_by_reason = "Please specify the other reason to promote your work.";
    }
  }

  // Validate is_fulltime_artist (should be a boolean)
  if (isEmpty(is_fulltime_artist)) {
    applyForm.is_fulltime_artist = "Full-time artist status is required";
  }

  if(isEmpty(award_prize) || award_prize === "<p><br></p>") {
    applyForm.award_prize = "Exhibitions , awards/prizes are required";
  }

  if(isEmpty(about) || about === "<p><br></p>") {
    applyForm.about = "About yourself is required";
  }
  // Validate exclusive_work (should be a boolean)
  if (isEmpty(exclusive_work)) {
    applyForm.exclusive_work = "Exclusive work status is required";
  }

  // Validate exp (should be a positive number)
  if (validator.isEmpty(exp)) {
    applyForm.exp = "Experience is required";
  } 

  // Validate category_id (ensure there is at least one category selected)
  if (!category_id || category_id.length === 0) {
    applyForm.category_id = "At least one category is required";
  } else if(category_id.some(
    (cat) => cat._id === "67334c2511d9938babd097f3"
  )) {
      if(isEmpty(othersReasonCategory)) {
        applyForm.category_id = "Other category is required";
      }
  }

  // Validate art_price_range (should not be empty)
  if (!art_price_range || art_price_range.length === 0) {
    applyForm.art_price_range = "Art price range is required";
  }

  // Validate arts_solds_in_past_12_months (should be a positive number)
  if (validator.isEmpty(arts_solds_in_past_12_months)) {
    applyForm.arts_solds_in_past_12_months = "Number of arts sold in the past 12 months is required ";
  } else if (isNaN(arts_solds_in_past_12_months) || arts_solds_in_past_12_months <= 0) {
    applyForm.arts_solds_in_past_12_months = "Number of arts sold in the past 12 months should be greater than 0";
  }

  // Validate produce_art_each_month (should be a positive number)
  if (validator.isEmpty(produce_art_each_month)) {
    applyForm.produce_art_each_month = "Number of artworks you can produce each month is required";
  } 

  // Validate artistTypes (should not be empty)
  if (!artist_type_id || artist_type_id.length === 0) {
    applyForm.artist_type_id = "Artist types are required";
  }

    // Validate portfolio URL (if provided)
    if (portfolio && !portfolio.match(regex)) {
        applyForm.portfolio = "Please enter a valid URL";
      }
    
      // Validate online_art_gallary_URL (if provided)
      if (online_art_gallary_URL && !online_art_gallary_URL.match(regex)) {
        applyForm.online_art_gallary_URL = "Please enter a valid URL";
      }
    
      // Validate LinkedIn URL (if provided)
      if (social_links.linked_in && !social_links.linked_in.match(linkedinRegex)) {
        applyForm.linked_in = "LinkedIn URL is invalid";
      }
    
      // Validate Instagram URL (if provided)
      if (social_links.instagram && !social_links.instagram.match(instagramRegex)) {
        applyForm.instagram = "Instagram URL is invalid";
      }
    
      // Validate Twitter URL (if provided)
      if (social_links.twitter && !social_links.twitter.match(twitterRegex)) {
        applyForm.twitter = "X URL is invalid";
      }
    
      // Validate Facebook URL (if provided)
      if (social_links.facebook && !social_links.facebook.match(facebookRegex)) {
        applyForm.facebook = "Facebook URL is invalid";
      }

         // Validate Facebook URL (if provided)
         if (social_links.youtube && !social_links.youtube.match(youtubeRegex)) {
            applyForm.youtube = "Youtube URL is invalid";
          }


         
  if (!Array.isArray(art_work) || art_work.length === 0) {
  
    applyForm.art_work = "At least 2 artworks are required.";
  } else if (art_work.length < 2 || art_work.length > 10) {
   
    applyForm.art_work = "Artwork should be between 2 and 10.";
  } else {
    
    const invalidFiles = art_work.some(
      (file) => !isFile(file) || !file.name
    );
    if (invalidFiles) {
      applyForm.art_work = "All artworks must be valid files.";
    }
  }
  
  return {
    applyForm,
    isValidArtistApplyform: isEmpty(applyForm),
  };
};
