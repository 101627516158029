import React, { useEffect, useState } from "react"
import styles from "./ReportsModal.module.css"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import Link from "@mui/material/Link"
import { Grid, MenuItem, Select } from "@mui/material"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import homeImage from "../../../../../constant"
import { useDispatch, useSelector } from "react-redux"
import { handleAddReports } from "../../../../../State/api/superadminApi"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import TextField from "@mui/material/TextField"
import dayjs from "dayjs"
import { RiCloseCircleFill} from "react-icons/ri";
import { FiDownload } from "react-icons/fi";
const state = {
  option: [
    { name: "Option 1️⃣", id: 1 },
    { name: "Option 2️⃣", id: 2 },
  ],
}

export default function ReportsModal(props) {
  const [radio, setRadio] = useState("")
  const [hidesubs, setHideSubs] = useState(false)
  const [adminReport, setAdminReport] = useState({
    report_type: "",
    startDate: "",
    endDate: "",
    status: "",
    plan_name: "",
  })
  const handleChangeReport = (e) => {
    const { name, value } = e.target
    setAdminReport({ ...adminReport, [name]: value })
    if (name === "report_type") {
      setHideSubs(value === "artist")
    }
  }

  const getProductOptions = (report) => {
    switch (report) {
      case "buyer":
        return [
          { value: "Approved", label: "Approved" },
          {
            value: "Pending",
            label: "Pending",
          },
          {
            value: "Decline",
            label: "Decline",
          },
        ]
      case "artist":
        return [
          { value: "Approved", label: "Approved" },
          {
            value: "Pending",
            label: "Pending",
          },
          {
            value: "Decline",
            label: "Decline",
          },
        ]
      case "curator":
        return [
          { value: "Approved", label: "Approved" },
          {
            value: "Pending",
            label: "Pending",
          },
          {
            value: "Decline",
            label: "Decline",
          },
        ]
      case "gallery":
        return [
          { value: "Approved", label: "Approved" },
          {
            value: "Pending",
            label: "Pending",
          },
          {
            value: "Decline",
            label: "Decline",
          },
        ]
      case "order":
        return [
          { value: "Placed", label: "Placed" },
          {
            value: "Delivered",
            label: "Delivered",
          },
          {
            value: "Completed",
            label: "Completed",
          },
        ]
      case "arts":
        return [
          { value: "Approved", label: "Approved" },
          {
            value: "Pending",
            label: "Pending",
          },
          {
            value: "Decline",
            label: "Decline",
          },
        ]
      case "affiliate":
        return [
          { value: "Approved", label: "Approved" },
          {
            value: "Pending",
            label: "Pending",
          },
          {
            value: "Decline",
            label: "Decline",
          },
        ]

      default:
        return []
    }
  }

  const dispatch = useDispatch()

  const handleClose = () => props.setOpen(false)

  const handleStartDate = (start) => {
    setAdminReport({
      ...adminReport,
      startDate: dayjs(start).format("DD-MM-YYYY").toString(),
    })
  }
  const handleEndDate = (end) => {
    setAdminReport({
      ...adminReport,
      endDate: dayjs(end).format("DD-MM-YYYY").toString(),
    })
  }

  const handleClick = async () => {
    const response = await dispatch(handleAddReports(adminReport))
    if (response?.filePath) {
      const fileUrl = `http://3.6.185.87:8000${response.filePath}`
      window.open(fileUrl, "_blank")
    }
  }
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className={styles.modal_exhibition}>
          <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  Create Report
              </p>
            <div className="form-group">
            <label>Report type</label>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={adminReport.report_type}
                label="Report type"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiInputBase-input":{
                    padding:".45rem .75rem",
                    height:"auto",
                    borderRadius:".25rem",
                    fontSize:"16px",
                    fontWeight:"400"
                  },
                  border:"1px solid #ced4da",
                  borderRadius:".25rem",
                  width:"100%"
                }}
                placeholder="select type"
                onChange={handleChangeReport}
                name="report_type"
                // name="blog_type"
              >
                <MenuItem value="buyer">Buyer</MenuItem>
                <MenuItem value="artist">Artist</MenuItem>
                <MenuItem value="curator">Curator</MenuItem>
                <MenuItem value="gallery">Gallery</MenuItem>
                <MenuItem value="order">Order</MenuItem>
                <MenuItem value="arts">Arts</MenuItem>
                <MenuItem value="affiliate">Affiliate</MenuItem>
              </Select>
            </div>
            <Grid
                                container
                                columnSpacing={2}
                              >
                                <Grid item xs={12} sm={6} md={6}>
									<div className="form-group">
                  <label>From Date</label>
                {/* <input onChange={handleChange} name='start_date' value={addData.start_date} placeholder={`Enter Start Date`} type='text' /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="MM-DD-YYYY"
                    value={adminReport.startDate}
                    onChange={handleStartDate}
                    renderInput={(params) => (
                      <TextField
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiInputBase-input":{
                          padding:".45rem .75rem",
                          height:"auto",
                          borderRadius:".25rem",
                          fontSize:"16px",
                          fontWeight:"400"
                        },
                        border:"1px solid #ced4da",
                        borderRadius:".25rem",
                        width:"100%"
                      }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "Enter date",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
									<div className="form-group">
                  <label>To Date</label>
                {/* <input onChange={handleChange} name='end_date' value={addData.end_date} placeholder={`Enter End Date`} type='text' /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="MM-DD-YYYY"
                    value={adminReport.endDate}
                    onChange={handleEndDate}
                    minDate={adminReport.startDate}
                    renderInput={(params) => (
                      <TextField
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiInputBase-input":{
                          padding:".45rem .75rem",
                          height:"auto",
                          borderRadius:".25rem",
                          fontSize:"16px",
                          fontWeight:"400"
                        },
                        border:"1px solid #ced4da",
                        borderRadius:".25rem",
                        width:"100%"
                      }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "Enter date",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                  </div>
                                </Grid>
                                </Grid>
            <div className="form-group">
            <label>Status</label>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={adminReport.status}
                  label="Report type"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiInputBase-input":{
                      padding:".45rem .75rem",
                      height:"auto",
                      borderRadius:".25rem",
                      fontSize:"16px",
                      fontWeight:"400"
                    },
                    border:"1px solid #ced4da",
                    borderRadius:".25rem",
                    width:"100%"
                  }}
                  placeholder="select type"
                  onChange={handleChangeReport}
                  name="status"
                  // name="blog_type"
                >
                  {getProductOptions(adminReport.report_type).map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
            </div>
            {hidesubs && (
                <div className="form-group">
                  <label>Subscription</label>
                  <input
                  className="form-control"
                    onChange={handleChangeReport}
                    name="Subscription"
                    value={adminReport.plan_name}
                    placeholder={`Enter subscription `}
                    type="text"
                  />
                </div>
              )}
              <div className={styles.downloadcv_btn}>
              <button
                className={styles.download_btn}
               
              >
                 <FiDownload />{" "}
                Download CSV{" "}
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
