import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertsSuccess } from "../../../../common/alert/Alert";
import {
  deleteAuction,
  fetchAuctionData,
  handleAcceptRejectAuction,
  handleAddAuction,
} from "../../../../State/api/superadminApi";
import { setAuctionData } from "../../../../State/Features/superadmin/auctionSlice";
import Add from "../Popupdelete/addPopup/Addpopup";
import Tablesfivecol from "../Table/Fivecolumn/Tablefivecol";
import Moreoption from "../Table/Moreoption/Moreoption";
import Tables from "../Table/sixcolumn/Tables";
import Tableheader from "../Table/Tableheader/Tableheader";
import Tablethreecol from "../Table/Threecolmn/Tablethreecol";
import styles from "./Auction.module.css";
import AdminFiveColumn from "../Table/Fivecolumn/AdminfiveColumn/AdminFiveColumn";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import dayjs from "dayjs";
import { Box, Pagination, PaginationItem } from "@mui/material";

const moreOptions = [
  // {
  //   id: "request",
  //   label: "Request",
  //   value: "request",
  // },
  {
    id: "AdminAuctionlive",
    label: "Live",
    value: "AdminAuctionlive",
  },
  {
    id: "AdminAuctionUpcoming",
    label: "Upcoming",
    value: "AdminAuctionUpcoming",
  },
  {
    id: "AdminAuctionPast",
    label: "Past",
    value: "AdminAuctionPast",
  },
  // {
  //   id: "decline",
  //   label: "Decline",
  //   value: "decline",
  // },
];
const requesthead = [
  {
    id: "like",
    label: "Start Date",
  },
  {
    id: "followe",
    label: "End Date",
  },
  {
    id: "location",
    label: "Auction Name",
  },
  {
    id: "location",
    label: "Artist Name",
  },
  {
    id: "like",
    label: "Action",
  },
];
const upcominghead = [
  {
    id: "like",
    label: "Auction Name",
  },
  {
    id: "followe",
    label: "Start Date",
  },
  {
    id: "location",
    label: "End Date",
  },
];
const livehead = [
  {
    id: "sl_no",
    label: "Sl. No",
  },
  {
    id: "auction_title",
    label: "Auction's Title",
  },
  {
    id: "created_date",
    label: "Created Date",
  },
  {
    id: "start_date",
    label: "Start Date",
  },
  {
    id: "end_date",
    label: "End Date",
  },
  {
    id: "application_end_date",
    label: "Application End Date",
  },
  {
    id:'delete_action',
    label:'Action'
  }
];
const exhibitionhead = [
  {
    id: "artist_name ",
    label: "Auction name",
  },
  {
    id: "mobile_number",
    label: "Start Date",
  },
  {
    id: "email",
    label: "End Date",
  },
  {
    id: "assign_to",
    label: "Win Bid",
  },
  {
    id: "type",
    label: "Total Bid",
  },
  {
    id: "action",
    label: "Art Sold to",
  },
];

const sortdata = [
  { label: "By Start Date ↓", value: "low" },
  { label: "By Start Date ↑", value: "high" },
  { label: "By End Date ↓", value: "endlow" },
  { label: "By End Date ↑", value: "endhigh" },
];
const filterdata = [
  { label: "A to Z", value: "AZ" },
  { label: "Z to A", value: "ZA" },
  //  { label: "Rejected", value: "rejected" },
];
export default function Auction() {
  const [activetab, setAcitvetab] = useState("AdminAuctionlive");
  const [button, setButton] = useState({ type: "add", text: "Create" });
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ is_accepted: "Pending", offset: 1 });
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  });

  const dispatch = useDispatch();
  const auctiondata = useSelector((state) => state.auctionData.data);

  //  const [currentPage, setCurrentPage] = React.useState(1);
  //     const itemsPerPage = 10; // Adjust how many items you want per page

  //     // Function to handle page changes

  console.log("auctionsdata", auctiondata);
  const handleMorecategories = (e) => {
    setAcitvetab(e);
  };
  const fetchData = () => {
    // const payload = { offset: data.offset } // ensure only offset is being sent
    dispatch(fetchAuctionData(data));
  };
  useEffect(() => {
    fetchData();
  }, [data]);
  const handleSubmitAuction = (dt) => {
    dispatch(handleAddAuction(dt, setOpen, fetchData));
  };
  const handleAcceptReject = (id, dt) => {
    dispatch(handleAcceptRejectAuction(id, { is_accepted: dt }, fetchData));
  };
  const handleDeleteAuction = (id) => {
    dispatch(deleteAuction(id, fetchData));
  };
  const hadleNavigate = () => {};

  const handleFilter = (event) => {
    const value = event.target.value;
    setCondition({ ...condition, filter: value });
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    setCondition({ ...condition, search: value });
  };

  const filterAuctions = (data, currentDate, condition, tab) => {
    return data?.filter((val) => {
      const startDate = new Date(val.start_date);
      const endDate = new Date(val.end_date);
      const isSameDayEvent =
        startDate.getTime() === endDate.getTime() &&
        startDate.toDateString() === currentDate.toDateString();

      const matchesSearch = [
        dayjs(val.start_date)
          .format("DD-MM-YYYY")
          .includes(condition.search.toLowerCase()),
        dayjs(val.application_end_date)
          .format("DD-MM-YYYY")
          .includes(condition.search.toLowerCase()),
        dayjs(val.end_date)
          .format("DD-MM-YYYY")
          .includes(condition.search.toLowerCase()),
        dayjs(val.createdAt)
          .format("DD-MM-YYYY")
          .includes(condition.search.toLowerCase()),
        val.name.toLowerCase().includes(condition.search.toLowerCase()),
      ].some(Boolean);

      if (tab === "AdminAuctionlive") {
        return (
          (isSameDayEvent ||
            (startDate <= currentDate && endDate >= currentDate)) &&
          matchesSearch
        );
      } else if (tab === "AdminAuctionUpcoming") {
        return startDate > currentDate && matchesSearch;
      } else {
        const yesterday = new Date();
        yesterday.setDate(currentDate.getDate() - 1);
        yesterday.setHours(23, 59, 59, 999);
        return endDate < yesterday && matchesSearch;
      }
    });
  };

  const sortAuctions = (data, sorting, filter) => {
    return data?.sort((a, b) => {
      // Alphabetical sorting based on filter
      if (filter === "AZ") {
        return a.name.localeCompare(b.name); // Sort A to Z
      }
      if (filter === "ZA") {
        return b.name.localeCompare(a.name); // Sort Z to A
      }
      const dateA = new Date(a.start_date);
      const dateB = new Date(b.start_date);
      switch (sorting) {
        case "low":
          return dateA - dateB;
        case "high":
          return dateB - dateA;
        case "endlow":
          return new Date(a.end_date) - new Date(b.end_date);
        case "endhigh":
          return new Date(b.end_date) - new Date(a.end_date);
        default:
          return dateB - dateA; // Default descending start_date
      }
    });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust how many items you want per page

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const currentDate = new Date();
  const filteredData = filterAuctions(
    auctiondata?.data,
    currentDate,
    condition,
    activetab
  );
  const sortedData = sortAuctions(
    filteredData,
    condition.sorting,
    condition.filter
  );
  useEffect(() => {
    setCurrentPage(1);
  }, [activetab]);
  const paginatedData = sortedData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const renderTabContent = () => {
    switch (activetab) {
      case "AdminAuctionlive":
        return (
          <Tablesfivecol
            activetab={activetab}
            head={livehead}
            data={sortedData}
            handleDeleteAuction={handleDeleteAuction}
          />
        );
      case "AdminAuctionUpcoming":
        return (
          <Tablethreecol
            activetab={activetab}
            head={livehead}
            data={paginatedData}
            handleDeleteAuction={handleDeleteAuction}
          />
        );
      default:
        return (
          <Tables activetab={activetab} head={livehead} data={sortedData}  handleDeleteAuction={handleDeleteAuction}/>
        );
    }
  };

  const handleSorting = (e) => {
    const value = e.target.value;
    setCondition((prev) => ({ ...prev, sorting: value })); // Ensure condition updates correctly.

    if (auctiondata?.data) {
      let sortedData;

      switch (value) {
        case "low":
          sortedData = [...auctiondata.data].sort(
            (a, b) => new Date(a.start_date) - new Date(b.start_date)
          );
          break;
        case "high":
          sortedData = [...auctiondata.data].sort(
            (a, b) => new Date(b.start_date) - new Date(a.start_date)
          );
          break;
        case "endlow":
          sortedData = [...auctiondata.data].sort(
            (a, b) => new Date(a.end_date) - new Date(b.end_date)
          );
          break;
        case "endhigh":
          sortedData = [...auctiondata.data].sort(
            (a, b) => new Date(b.end_date) - new Date(a.end_date)
          );
          break;
        default:
          sortedData = [...auctiondata.data]; // Default to unsorted data.
      }

      dispatch(
        setAuctionData({
          data: sortedData,
          totalCount: auctiondata.totalCount,
        })
      );
    }
  };

  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Add
          poptype="auction"
          handleSubmitAuction={handleSubmitAuction}
          open={open}
          setOpen={setOpen}
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          setOpen={setOpen}
          button={button}
        />
        <Moreoption
          activetab={activetab}
          setAcitvetab={setAcitvetab}
          moreOptions={moreOptions}
          handleMorecategories={handleMorecategories}
          setData={setData}
        />
        {/* {
          activetab === "request" ? (
            <AdminFiveColumn
              handleDeleteAuction={handleDeleteAuction}
              handleAcceptReject={handleAcceptReject}
              activetab={activetab}
              head={requesthead}
              data={
                auctionsdata &&
                auctionsdata.data.filter((val) => {
                  return (
                    val &&
                    val.name
                      .toLowerCase()
                      .includes(condition.search.toLowerCase())
                  )
                })
              }
            />
          )
          :
           activetab === "decline" ? (
            <Tablethreecol
              activetab={activetab}
              handleDeleteAuction={handleDeleteAuction}
              head={upcominghead}
              data={
                auctionsdata &&
                auctionsdata.data.filter((val) => {
                  return (
                    val.is_accepted === "Rejected" &&
                    val.name
                      .toLowerCase()
                      .includes(condition.search.toLowerCase())
                  )
                })
              }
            />
          )
          activetab === "auctionlive" ? (
            <Tablesfivecol
              activetab={activetab}
              head={livehead}
              data={
                auctiondata &&
                auctiondata.data.filter((val) => {
                  const startDate = new Date(val.start_date.substring(0, 10));
                  const endDate = new Date(val.end_date.substring(0, 10));
                  const currentDate = new Date();

                  return (
                    startDate <= currentDate &&
                    endDate >= currentDate &&
                    val.name
                      .toLowerCase()
                      .includes(condition.search.toLowerCase())
                  );
                })
              }
            />
          ) : activetab === "upcoming" ? (
            <Tablethreecol
              activetab={activetab}
              head={livehead}
              data={
                auctiondata &&
                auctiondata.data.filter((val) => {
                  return (
                    val &&
                    new Date(val.end_date.substring(0, 10)) > new Date() > 0 &&
                    val.name
                      .toLowerCase()
                      .includes(condition.search.toLowerCase())
                  );
                })
              }
            />
          ) : (
            <Tables
              activetab={activetab}
              head={livehead}
              data={
                auctiondata &&
                auctiondata.data.filter((val) => {
                  return (
                    val &&
                    new Date(val.end_date.substring(0, 10)) < new Date() > 0 &&
                    val.name
                      .toLowerCase()
                      .includes(condition.search.toLowerCase())
                  );
                })
              }
            />
          )
        } */}

        {/* {auctionsdata &&
          Array.isArray(auctionsdata.data) &&
          auctionsdata.data.length > 0 && (
            <Pagination
              totaldata={auctionsdata && auctionsdata.totalCount}
              pagination={data}
              setPagination={setData}
            />
          )} */}

        {renderTabContent()}
        {activetab === "AdminAuctionUpcoming" && (
          <Box className={styles.pagination_box} sx={{}}>
            <Pagination
              count={Math.ceil(filteredData?.length / itemsPerPage)} // Total pages
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              renderItem={(item) => (
                <PaginationItem
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "black", // Set the selected background to black
                      color: "white", // Ensure the text color is visible
                      "&:hover": {
                        backgroundColor: "black", // Maintain black on hover
                      },
                    },
                  }}
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                />
              )}
            />
          </Box>
        )}
      </div>
    </div>
  );
}
