import {Grid, Link, Container } from "@mui/material";
import React, { useState, useRef, useEffect } from 'react';
import styles from "./TradeProgramApplication.module.css";
import { useNavigate } from "react-router-dom"  
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CssBaseline from "@mui/material/CssBaseline";
import { RiCheckFill } from "react-icons/ri";
import PhoneInput from "react-phone-input-2"; 
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import striptags from "striptags";
import { position } from "stylis";
function ArtSmileyBusiness() {
    const prevButtonRef = useRef(null);
    const nextButtonRef = useRef(null);

  const navigate = useNavigate()
  const handleNavigate = (route) => {
    navigate(route)
  }

  const featurebeni = [
    {
      content: <p>Professional curation and consulting expertise tailored to hospitality, commercial, healthcare, and residential projects.</p>,
    },
    {
      content: <p>Access to a diverse array of artists and materials suited for your specific business needs.</p>,
    },
    {
      content: <p>Exclusive invitations to private art events and exhibitions for industry professionals.</p>,
    },
    {
      content: <p>Personalized portfolio development and management for cohesive design solutions.</p>,
    },
    {
      content: <p>Priority access to limited edition pieces and new releases before they are available to the public.</p>,
    },
    {
      content: <p>Special rates on bulk purchases for large-scale hospitality, commercial, or healthcare projects.</p>,
    },
    {
      content: <p>High-quality framing options tailored to complement your chosen artwork and space design.</p>,
    },
    {
      content: <p>Tailored sizes and exclusive commissioned pieces to fit your project's unique requirements.</p>,
    },
    {
      content: <p>Customer support with extensive experience in managing trade transactions across diverse sectors.</p>,
    },
    {
      content: <p>Exceptional shipping and delivery service ensuring timely and secure delivery for all projects.</p>,
    },
  ];
  const exclusive_data = [
    {
      id: 1,
      src: "/img/artcollections",
      heading: "Expert Collaboration",
      des: "Leveraging years of experience in collaborating with artists, we specialize in creating bespoke art pieces and tailored commissions that align with the distinct needs of your project, ensuring each piece enhances your space and vision.",
      
    },
    {
      id: 2,
      src: "/img/artcollections",
      heading: "Diverse Art Forms",
      des: "Our curated collection spans a wide array of artistic mediums, from paintings and prints to resin artworks, woven wall hangings, metal sculptures, 3D installations, wood works, acrylic signage, and more.",
      
    },
    {
      id: 3,
      src: "/img/artcollections",
      heading: "Trend-Focused Approach",
      des: "Staying ahead of the curve in the art and design world, we offer cutting-edge, contemporary works that reflect the latest trends and innovations, ensuring our clients receive captivating, fresh art solutions perfectly suited to their specific industry and project requirements.",
    },
  ];

  const ourArtistCard = [
    {
      id: 1,
      imgUrl: "/img/ourartist1.png",
      artistName: "Maria Cooper",
      position: "Interior Designer",
      des:"Our curators can assist you in uncovering art that you cherish."
    },
    {
      id: 2,
      imgUrl: "/img/ourartist2.png",
      artistName: "Titus Archibald",
      position:"Interior Designer, Lifestylist",
      des:"Our curators can assist you in uncovering art that you cherish."
    },
    {
      id: 3,
      imgUrl: "/img/ourartist3.png",
      artistName: "Winslow Homer",
      position: "Designer, Mississippi Native",
      des:"Our curators can assist you in uncovering art that you cherish."
    },
  ];

  const [activeTab, setActiveTab] = useState(0);
  
    const tabs = [
      {
        label: 'Hospitality',
        content: <p>In the hospitality industry, art is essential to creating memorable guest experiences. Our team specializes in curating art collections that transform hotel lobbies, restaurants, and guest rooms into sophisticated, inviting spaces. We blend local culture, contemporary design, and timeless pieces to enhance the ambiance and leave a lasting impression on guests. With a focus on brand identity and atmosphere, we help create environments that reflect the unique character of each property while delivering a refined and welcoming aesthetic.</p>,
      },
      {
        label: 'Commercial',
        content: <p>Art plays a critical role in shaping the environment of commercial spaces, elevating your brand, and enhancing the workplace atmosphere. Corporate offices and retail spaces benefit from our commercial <Link onClick={() => handleNavigate('/user/art-consultancy')}>art consulting</Link> services. We work with you to curate collections that reflect your company's values, inspire creativity, and make a lasting impression on clients and employees. Our goal is to create spaces that are functional, vibrant, inspiring, and in line with your corporate identity.</p>,
      },
      {
        label: 'Healthcare',
        content: <p>Art has the power to promote healing, reduce stress, and improve patient well-being in healthcare environments. Our healthcare art consulting services focus on creating art collections that foster a calm, supportive, and positive atmosphere for patients, families, and staff. By selecting artwork that incorporates soothing colors, natural imagery, and uplifting themes, we help hospitals, clinics, and wellness centers create environments that enhance the healing process and support the overall well-being of everyone who walks through the door.</p>,
      },
      {
        label: 'Residential',
        content: <p>Our residential art consulting services help transform your home into a fun filled place with art that reflects your style and personality. We provide expert guidance on selecting pieces that complement your design vision and create a cohesive, inviting atmosphere in a single room or your entire home.</p>,
      },
      {
        label: 'Art Collector',
        content: <p>Our Art Collector services cater to seasoned collectors and those just beginning their journey into the art world. We offer expert advice on building and curating your collection, providing access to exclusive works and market insights that help you make informed decisions. Our team works closely to source rare and exceptional artworks that align with your collecting goals, ensuring each piece adds value and meaning to your collection.</p>,
      },
    ];


    const service_data = [
      {
        id: 1,
        src: "/img/curatorsignup",
        heading: "Concept Development",
        des: `<p>Every successful art installation begins with a strong, well-defined concept. At Art Smiley, we work closely with you to understand your brand, story, and space. Our concept development phase is all about translating your vision into a creative direction that aligns with your goals and enhances the overall aesthetic of your environment.</p>
        <h5>Services</h5>
        <ul>
        <li>Collaborative brainstorming sessions</li>
        <li> Visual storytelling through art</li>
        <li>Aligning art with brand identity</li>
        <li>Developing a cohesive theme for your space</li>
        </ul>`,
      },
      {
        id: 2,
        src: "/img/curatorsignup",
        heading: "Design",
        des: `<p>Once the concept is established, our expert designers begin crafting the visual elements tailored to your needs. We offer fully customized designs to ensure each artwork fits perfectly into your space.</p>
        <h5>Services</h5>
        <ul>
        <li>Custom art designs for any medium</li>
        <li>Collaboration with local artists</li>
        <li>Tailored designs for specific spaces</li>
        <li>Integration of art with architecture and interiors</li>
        </ul>`,
      },
      {
        id: 3,
        src: "/img/curatorsignup",
        heading: "Implementation",
        des: `<p>Our implementation strategy ensures a smooth transition from concept to completion. We create a detailed action plan that includes timelines, milestones, and resource management. Our experienced team coordinates all the logistical elements, ensuring that every stage of the project is executed without disruption.</p>
        <h5>Services</h5>
        <ul>
        <li>Detailed project timelines and milestone tracking</li>
        <li>Resource allocation and management</li>
        <li>Coordination of third-party vendors (artists, fabricators, etc.)</li>
        <li>Risk management and troubleshooting</li>
        </ul>`,
      },
      {
        id: 4,
        src: "/img/curatorsignup",
        heading: "Budget Formulation",
        des: `<p>We understand that staying within budget is crucial to project success. Our team provides detailed budget formulation and cost analysis, ensuring transparency at every stage. We work with you to establish a realistic budget that reflects your needs and desires, without compromising quality or vision.</p>
        <h5>Services</h5>
        <ul>
        <li>Accurate project budgeting</li>
        <li>Cost breakdowns for each phase of the project</li>
        <li>Transparent reporting and ongoing budget updates</li>
        <li>Value-driven solutions for every budget</li>
        </ul>`,
      },
      {
        id: 5,
        src: "/img/curatorsignup",
        heading: "Expert Art Curation & Sourcing",
        des: `<p>Art Smiley offers expert art curation services that are personalized to your space. We help you choose the perfect pieces of art that reflect your vision, style, and environment. Our team of curators source artworks from around the world, ensuring quality, diversity, and exclusivity. If the curated artworks do not meet your expectations, Art Smiley offers customized artwork services, that  align with your specific theme and unique requirements.</p>
        <h5>Services</h5>
        <ul>
        <li>Curated art collections from global artists</li>
        <li>Exclusive sourcing for rare or custom pieces</li>
        <li>Artworks in a variety of styles and mediums</li>
        <li>Detailed artist and artwork portfolios</li>
        </ul>`,
      },
      {
        id: 6,
        src: "/img/curatorsignup",
        heading: "Custom Manufacturing / Production",
        des: `<p>Once the designs are approved, we oversee the production and creation of all artworks. Our skilled team works as per the approved designs with high-quality materials to create stunning large-scale and small-scale sculptures, artworks, and prints. We ensure that every piece is produced with care, attention to detail, and precision.</p>
        <h5>Services</h5>
        <ul>
        <li>High-quality material selection</li>
        <li>Skilled fabrication in all mediums</li>
        <li>Sustainable and eco-friendly production options</li>
        <li>Quality control checks throughout production</li>
        </ul>`,
      },
      {
        id: 7,
        src: "/img/curatorsignup",
        heading: "On-Demand Printing & Framing",
        des: `<p>Art Smiley takes pride in offering a diverse range of high-quality printing services, including acrylic, wood printing, canvas printing, and metal-on-print options. Our team of experts ensures that every print is produced to the highest standards, delivering vibrant colors, fine details, and premium finishes. Additionally, we provide custom framing solutions that not only protect the artwork but also complement both the piece and the surrounding environment seamlessly. We offer tailored solutions to suit the unique needs of your spaces, whether you are looking to create small prints for cozy corners or large installations for expansive walls,</p>
        <h5>Services</h5>
        <ul>
        <li>Custom framing that matches your interior design</li>
        <li>Preservation and protection of fine art</li>
        <li>Expert handling and preparation for display</li>
        </ul>`,
      },
      {
        id: 8,
        src: "/img/curatorsignup",
        heading: "Delivery & Installation",
        des: `<p>With our experienced team of installers, we ensure that your artwork is delivered and set up with care. Our installation team handles all logistics, from transport to precise placement. We ensure that every piece is installed securely and in alignment with your vision, with minimal disruption to your space. We take care of everything from mounting to final adjustments. At Art Smiley, we understand the value and intricacy of sculptures and other delicate artworks. Our experienced team of installers is equipped to handle sculptures safely and professionally, ensuring that every piece is delivered and set up with the utmost care. We pride ourselves on minimizing disruption during installation, taking care of all mounting, adjustments, and final touches to leave your space looking flawless. With Art Smiley, you can trust that your sculptures are in capable hands, handled with the respect and attention they deserve.</p>
        <h5>Services</h5>
        <ul>
        <li>Secure packaging and handling</li>
        <li>Professional installation for all art types</li>
        <li>On-site coordination to match the client’s vision</li>
        <li>Adjustments and fine-tuning during installation</li>
        </ul>`,
      },
      {
        id: 9,
        src: "/img/curatorsignup",
        heading: "Project Management & Ongoing Support",
        des: `<p>From the initial consultation to the final installation, Art Smiley manages your project every step of the way. Our project management team ensures deadlines are met, budgets are respected, and quality standards are upheld. We offer ongoing support and maintenance services to ensure your artwork remains in pristine condition for years to come.</p>
        <h5>Services</h5>
        <ul>
        <li>Full-service project management</li>
        <li>Post-installation care</li>
        <li>Long-term  maintenance support and follow-up</li>
        </ul>`,
      },
    ];
    const vrvice_data = [
      {
        id: 1,
        heading: "Tailored Art for Your Business Identity",
        des: `<p>We create art that reflects your brand’s culture and values, ensuring a unique visual identity that resonates with your audience.</p>`,
      },
      {
        id: 2,
        heading: "Exceptional Craftsmanship and Durability",
        des: `<p>Using high-quality materials and advanced techniques, our artworks maintain their vibrancy and integrity, offering lasting impact.</p>`,
      },
      {
        id: 3,
        heading: "Creative Collaboration and Guidance",
        des: `<p>Our expert designers work with you to develop styles and placements that enhance your space, ensuring your vision is realized.</p>`,
      },
      {
        id: 4,
        heading: "Comprehensive Project Management",
        des: `<p>Art Smiley handles everything from design to installation, allowing you to focus on your business while we ensure timely delivery.</p>`,
      },
      {
        id: 5,
        heading: "Customer Experience",
        des: `<p>Our strategically designed art enhances your environment, creating an inviting atmosphere that boosts customer engagement and satisfaction.</p>`,
      },
    ];

    

      const [subscriptionExpanded, setSubscriptionExpanded] = React.useState("excpanel0"); // Default to the first panel open
        const handleSubscriptionChange = (panel) => (event, isExpanded) => {
          setSubscriptionExpanded(isExpanded ? panel : false);
        };

        const scrollToSection = () => {
          const section = document.getElementById('target-section');
          if (section) {
            // Calculate offset to account for the fixed header
            const headerOffset = 100; // Height of your fixed header
            const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = sectionPosition - headerOffset;
      
            // Scroll to the calculated position
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            });
          }
        };
  
        
        const [expandedIndex, setExpandedIndex] = useState(null);
        const [isOverflowing, setIsOverflowing] = useState(false);
        
        const contentRef = useRef(null);
        
        useEffect(() => {
          if (contentRef.current) {
            const isContentOverflowing =
              contentRef.current.scrollHeight > contentRef.current.clientHeight;
            setIsOverflowing(isContentOverflowing);
          }
        }, []);
        
        const toggleReadMore = (index) => {
          setExpandedIndex(expandedIndex === index ? null : index); // Toggle state
        };

  return (
    <>
   
      <CssBaseline />
      <section className={styles.hero_main}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.pageitle} ${styles.max_width}`}>
              <h1>Art Smiley for Business</h1>
              <p className={`${styles.withor} ${styles.whitext}`}>Delight your clients with exclusive art, prints, and personalized installations from the skilled artists.</p>
              <button 
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                scrollToSection(); // Call scroll function
              }}
              className="apply_ltbtn"
            >
              Connect with Us
            </button>
            </div>
</Container>
        </section>
        <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title}`}>Why Art Smiley for Your Business</h3>
          </div>
        <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
        <Grid item xs={12} sm={6} md={5}>
         
         <div className={styles.vintageframeDiv}>
           <img
             className="img-fluid"
             src={ "/img/vintage-wood-frame.png"} alt=""
           />
         </div>
   </Grid>
   <Grid item md={6} sm={6} xs={12}>
      <div className={` ${styles.accordsc}`}>
      {vrvice_data.map((val, index) => {
      const panelId = `excpanel${index}`; // Dynamic panel ID
      return (
        <Accordion
          key={panelId}
          expanded={subscriptionExpanded === panelId} // Check if the panel is expanded
          onChange={handleSubscriptionChange(panelId)}
          sx={{
            "& .MuiAccordionSummary-contentGutters": {
              margin: "10px 0", // Adjust as needed
            },
            "&.MuiPaper-root": {
              boxShadow: "none",
              borderBottom: "1px solid #D9DBE9",
              marginBottom:"0",
              borderBottomLeftRadius:"0",
              borderBottomRightRadius:"0",
            },
            "&.MuiAccordion-root::before":{
              display:"none",
            },
            "& .Mui-expanded": {
              minHeight:"auto !important",
              margin:"0 0 10px 0 !important"
            },
            "& .MuiAccordionSummary-expandIconWrapper":{
              background:"#B7B7B7",
              opacity:".3",
              textAlign: "center",
              borderRadius:"8px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
              background:"#1C1C1C",
              opacity:"1",
              color:"#fff",
            },
            "& .MuiAccordionDetails-root":{
              paddingTop:"0"
            },
            
          }}// Handle expansion state
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${panelId}-content`}
            id={`${panelId}-header`}
          >
            <h4 className={styles.benfhdgn}>{val.heading}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.textContent} dangerouslySetInnerHTML={{ __html: val.des }}></div>
          </AccordionDetails>
        </Accordion>
      );
    })}
      </div>
          </Grid>
        
        </Grid>
        </Container>
      </section>
      <section className={styles.sec_shgn}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Art Consulting Services</h3>
          <p className={styles.withor}>Our Art Consulting Services provide expert guidance for enhancing their spaces with meaningful, high-quality artwork. We assist clients in selecting, acquiring, and installing art that aligns with their vision and objectives, whether building a private collection or curating for a corporate office. We ensure every piece complements your unique style and creates an impactful environment that resonates with viewers.</p>
          </div>
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{justifyContent:"space-between"}}>
          <Grid item md={5} sm={6} xs={12}>
          <div>
              <img src={"/img/apply-atrist.jpeg"} alt="mockup" className="img-fluid" />
              </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
                  <div>
                    <ul className={styles.timeline}>
                      <li>
                      <div className={styles.stepli}>
                      <h4>Hospitality</h4>
                      <p>In the hospitality industry, art is essential to creating memorable guest experiences. Our team specializes in curating art collections that transform hotel lobbies, restaurants, and guest rooms into sophisticated, inviting spaces. We blend local culture, contemporary design, and timeless pieces to enhance the ambiance and leave a lasting impression on guests. With a focus on brand identity and atmosphere, we help create environments that reflect the unique character of each property while delivering a refined and welcoming aesthetic.
</p>
                    </div>
                      </li>
                      <li>
                      <div className={styles.stepli}>
                      <h4>Commercial</h4>
                      <p>Art plays a critical role in shaping the environment of commercial spaces, elevating your brand, and enhancing the workplace atmosphere. Corporate offices and retail spaces benefit from our commercial art consulting services. We work with you to curate collections that reflect your company's values, inspire creativity, and make a lasting impression on clients and employees. Our goal is to create spaces that are functional, vibrant, inspiring, and in line with your corporate identity.</p>
                      </div>
                      </li>
                      <li>
                      <div className={styles.stepli}>
                      <h4>Healthcare</h4>
                      <p>Art has the power to promote healing, reduce stress, and improve patient well-being in healthcare environments. Our healthcare art consulting services focus on creating art collections that foster a calm, supportive, and positive atmosphere for patients, families, and staff. By selecting artwork that incorporates soothing colors, natural imagery, and uplifting themes, we help hospitals, clinics, and wellness centers create environments that enhance the healing process and support the overall well-being of everyone who walks through the door.</p>
                      </div>
                      </li>
                      <li>
                      <div className={styles.stepli}>
                      <h4>Residential</h4>
                      <p>Our residential art consulting services help transform your home into a fun filled place with art that reflects your style and personality. We provide expert guidance on selecting pieces that complement your design vision and create a cohesive, inviting atmosphere in a single room or your entire home.</p>
                      </div>
                      </li>
                      <li>
                      <div className={styles.stepli}>
                      <h4>Art Collector</h4>
                      <p>Our Art Collector services cater to seasoned collectors and those just beginning their journey into the art world. We offer expert advice on building and curating your collection, providing access to exclusive works and market insights that help you make informed decisions. Our team works closely to source rare and exceptional artworks that align with your collecting goals, ensuring each piece adds value and meaning to your collection.</p>
                    </div>
                      </li>
                    </ul>
                    
                  </div>
            </Grid>
            </Grid>
            <div className={styles.btnmt2}><button onClick={() => handleNavigate('/user/art-consultancy/questionnaire')} className="apply_btn">Fill your Questionnaire</button></div>
            </div>
        </Container>
      </section>
      <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title}`}>With Art Smiley Business Services, you can enjoy</h3>
          </div>
      {/* Tab Content */}
      <div className={styles.agtd}>
      <Grid container spacing={2} sx={{justifyContent:"space-between",alignItems:"center"}}>
         
        {featurebeni.map((val) => (
          <Grid item xs={12} sm={6} md={6}>
                <div  className={styles.feabox}>
                <div className={styles.feabc}>
                  <div className={styles.iconst}>
                  <RiCheckFill className={styles.usicon} />
                  </div>
                  <div>{val.content}
                  </div>
                </div>
                </div>
                </Grid>
                  ))} 
          
        
        </Grid>
      </div>
      
        </Container>
      </section>
      <section className={styles.sec_shgn}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
        
        <Grid item xs={12} sm={6} md={6} order={{ xs: 2, sm: 1 }}>
         
              <div className={styles.vintageframeDiv}>
                <img
                  className="img-fluid"
                  src={ "/img/vintage-wood-frame.png"} alt=""
                />
              </div>
        </Grid>
        <Grid item xs={12} sm={6} md={5} order={{ xs: 1, sm: 2 }}>
        <div className={styles.leftText}>
              <h4 className={styles.abttitle}>Art Smiley Trade Program</h4>
              <p className={styles.prtra}>The Art Smiley Trade Program is crafted specifically for interior designers, architects, art consultants, and other industry professionals who want to elevate their projects with high-quality, original artwork. This exclusive program offers access to a carefully curated selection of art, providing tailored solutions for both residential and commercial spaces.</p>
              <p className={styles.prtra}>Enjoy personalized service, member exclusive 10% discount on all orders above 1500 USD, and a seamless process for sourcing and acquiring artwork that perfectly complements your design vision.</p>
                 <a className="apply_btn" href="/user/art-consultancy/trade-program-application">Unlock Trade Program Benefits</a>
              </div>
        </Grid>
        </Grid>
      

        </Container>
      </section>
      <section className={styles.sec_sc}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Our Approach</h3>
          <p className={styles.withor}>We manage every aspect of your project, from concept, design, implementation strategy and installation, ensuring a smooth execution.</p>
          </div>
          </Container>
        </section>
      <section className={styles.sec_shgn}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.max_width} ${styles.text_center}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title}`}>Art Smiley Project Development Process</h3>
          <p className={styles.withor}>At Art Smiley, we manage every aspect of your project from start to finish. Our goal is to provide a seamless experience, transforming your vision into reality through strategic planning, expert design, and flawless execution.</p>
          </div>
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{justifyContent:"center",}}>
          
          {service_data.map((val, index) => (
          <Grid item xs={12} sm={6} md={4} key={val.id}>
            <div key={index} className={styles.stboxh}>
              <div className={styles.rechhbox}>
              <div className={styles.sticon}>
                           <img
                              className={styles.img_fluid}
                              src={`${val.src}.svg`}
                              alt="img"
                              />  
                        </div>
                <h4>{val.heading}</h4>
                <div className={styles.stcont}>
                    {/* <p>{val.des}</p> */}
                    <div
      className={`${styles.textContent} ${
        expandedIndex === index ? styles.expanded : ""
      }`}
      ref={contentRef}
      style={{
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: expandedIndex === index ? "unset" : 5, // Show 3 lines or full content
        overflow: "hidden",
      }}
      dangerouslySetInnerHTML={{ __html: val.des }}
    />
    
    {isOverflowing && (
      <button
        className={styles.readMoreBtn}
        onClick={() => toggleReadMore(index)}
      >
        {expandedIndex === index ? "Read Less" : "Read More"}
      </button>
    )}
    </div>
                
              </div>
            </div>
          </Grid>
           ))}
          </Grid>
          
          </div>
          </Container>
          </section>
      <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.max_width1} ${styles.text_center}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title}`}>Art Smiley Delivers Exceptional Art Solutions for Hospitality, Commercial, Healthcare, and Residential Projects</h3>
          <p className={styles.withor}>With a diverse network of talented artists from over 50 countries, Art Smiley is uniquely equipped to provide customized art solutions for a range of industries, including hospitality, commercial, healthcare, and residential. Here’s how we stand out:</p>
            
          </div>
        <div className={styles.taldartist}>
            <Grid container spacing={2} sx={{justifyContent:"center"}}>
            {exclusive_data.map((val) => (
            <Grid item xs={12} sm={6} md={4} key={val.id}>
              <div className={styles.talhg}>
                <div className={styles.talgbx}>
                <div className={styles.talenimg}>
                    <img
                    alt="img"
                    src={`${val.src}.png`}
                    className="img-fluid"
                  />
                    </div>
                    <div className={styles.talentcont}>
                      <h4>{val.heading}</h4>
                      <p>{val.des}</p>
                      
                    </div>
                    {val.link && (
              <button
                className={`${styles.mt_2} apply_btn`}
                onClick={() => window.location.href = val.link}
              >
                {val.btname}
              </button>
            )}
                </div>
              </div> 
            </Grid>
            ))}
            </Grid>
            <div className={styles.text_center}>
            <button  onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                scrollToSection(); // Call scroll function
              }}
                  className={`${styles.mt_2} apply_btn`}
                > Get Started
                </button>
            </div>

            </div>
        </Container>
      </section>
      {/* <section className={styles.sec_shgn}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Our Valued Clients</h3>
          </div>
          </Container>
        </section> */}
        <section className={styles.arconsec}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <Grid container spacing={2} sx={{alignItems:"center"}}>
              <Grid item xs={12} md={8}>
                <h3>Are You Looking for Personalized Art Consultancy services?</h3>
              </Grid>
              <Grid item xs={12} md={4}>
              <div className={styles.text_center}>
                <button  onClick={() => handleNavigate('/user/art-consultancy/questionnaire')} className="apply_ltbtn">Submit the Art Requirements</button>
              </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        {/* <section className={styles.sec_shgn}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Meet Art Smiley Curators</h3>
          </div>
          <div>
          <Swiper
        modules={[Navigation, Autoplay]}
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 3000, 
          disableOnInteraction: false,  
        }}
        navigation={{
          prevEl: prevButtonRef.current,
          nextEl: nextButtonRef.current,
        }}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
      >
        {ourArtistCard.map((item) => (
        <SwiperSlide>
          <div className={styles.ourartistcard}>
          <img className={styles.ourartistimg} src={  item.imgUrl} alt=""/>
          <div className={styles.ourartistinfo}>
            <h4>{item.artistName}</h4>
            <p className={styles.tdr}>{item.position}</p>
            <p>{item.des}</p>
          </div>
        </div>
        </SwiperSlide>
        ))}
      </Swiper>
       
          </div>
          
          </Container>
        </section> */}
        <section className={styles.sec_sc} id="target-section">
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Reach out to us</h3>
          </div>
        <Grid container spacing={2} sx={{justifyContent:"center"}}>
        <Grid item xs={12} sm={10} md={8}>
        <div className={styles.stcgbox}>
                  <Grid container spacing={2} sx={{justifyContent:"center"}}>
                    <Grid item xs={12} sm={12} md={12}>
                                      <div className="form-group1">
                                    <label style={{marginBottom:"0"}}>Fill in the required fields below</label>
                                    </div>
                                    </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>First Name *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="first_name"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Last Name *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="last_name"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Phone *</label>
                <PhoneInput
                  inputStyle={{ background: "transparent" }}
                  containerStyle={{
                    background: "transparent",
                    width: "100%",
                  }}
                  placeholder="Enter your phone number"
                  country={"in"}
                  name="phone"
                
                />
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Email *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"

                  name="email"
                />
                </div>
                  </Grid>
                  
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Designation *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="designation"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Please specify the project category *</label>
                <select className="form-control">
                  <option>Select the Option</option>
                  <option>Commercial</option>
                  <option>Retail</option>
                  <option>Education</option>
                  <option>Healthcare</option>
                  <option>Hospitality</option>
                  <option>Individual Collector</option>
                  <option>Private Home</option>
                  <option>Residential</option>
                  <option>Other</option>
                </select>
                 
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  <div className="form-group1">
                <label>Can you tell us more details about your project requirements ( no. of works needed, style, timeline etc.,) *</label>
                <textarea
                  maxRows={5}
                  minRows={5}
                 className="form-control"
                  // onChange={props.handleChangedata}
                  // value={props.uploadArt.description}
                  name="message"
                  fullWidth
                  placeholder=""
                />
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                                      <div className="form-group1">
                                    <label style={{marginBottom:"0",marginTop:"15px"}}>Choose the project location</label>
                                    </div>
                                    </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>City *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Country *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                
                </div>
                  </Grid>
                  
                  
                  <Grid item xs={12} sm={12} md={12}>
                  <div className={styles.text_right}>
               <button
                className="apply_btn"
              >
                Submit
              </button>
               </div>
               <div>
                <p className={styles.sbychsbu}>By completing this form, you consent to receive promotional emails regarding the Art Smiley Trade Program. You may opt out at any time. <Link href="/user/privacypolicy" target="_blank">Privacy Policy</Link> <Link href="/user/termsconditions" target="_blank">Terms and Conditions</Link>.</p>
               </div>
                  </Grid>
                  </Grid>
</div>
          </Grid>
        </Grid>
        
        </Container>
      </section>
    </>
  );
}

export default ArtSmileyBusiness;
