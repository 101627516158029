import { Grid, Typography, Container, Link } from "@mui/material"
import { createTheme } from "@mui/system"
import React, { useEffect, useState } from "react"
import styles from "./BlogDetailsPage.module.css"
import homeImage from "../../../constant"
import api from "../../../State/api/Api"
import { setLoader } from "../../../State/Features/common/loaderSlice"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import serverimage from "../../../serverConstant"
import { useNavigate } from "react-router-dom"

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
  },
})
const PressReleaseDetailsCard = [
  {
    id: 1,
    imgUrl: "/img/Rectangle prd.png",
  },
  {
    id: 2,
    imgUrl: "/img/Rectangle 5267.png",
  },
  {
    id: 3,
    imgUrl: "/img/Rectangle 5268.png",
  },
]
function BlogDetailsPage() {
   const navigate = useNavigate()
  const handleNavigate = (route) => {
    navigate(route)
  }
  const dispatch = useDispatch()
  const { id } = useParams()
  const [pressDetail, setPressDetail] = useState()
  console.log(pressDetail, "pressDetail--")

  const handleFetchPessDetail = async function () {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(`api/blog/fetchBlogBySlug/${id}`)
      if (result && result.status === 200) {
        setPressDetail(result.data)
        dispatch(setLoader(false))
      } else if (result.status === 401) {
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    handleFetchPessDetail()
    // dispatch(fetchUsertypes());
  }, [])
  return (
    <div className={styles.pressreleasesdetails}>
      <div className={styles.headerCategory}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
      <div className={styles.pageitle}>
              <h1>Blog</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li><Link onClick={() => handleNavigate("/user/blogPage")}>Blog </Link></li>
                
              </ul>
            </div>
      </Container>
        
      </div>
      <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <Grid container spacing={2} sx={{justifyContent:"center"}}>
        {pressDetail && (
          <Grid item xs={12} sm={12} md={9}>
            <div className={styles.content__main}>
              <img
                className={styles.content__image}
                src={ pressDetail?.data?.media} alt=""
              />
              <div className={styles.blgcont}>
                <h3> {pressDetail?.data?.title}</h3>
                <p
                    dangerouslySetInnerHTML={{
                      __html:
                        pressDetail &&
                        pressDetail?.data?.description &&
                        pressDetail?.data?.description.length > 200
                          ? pressDetail?.data?.description.substring(0) + " "
                          : pressDetail?.data && pressDetail?.data?.description
                          ? pressDetail?.data?.description
                          : "",
                    }}
                  ></p>
              </div>
              
            </div>
          </Grid>
        )}
      </Grid>
        </Container>
      </section>
      
    </div>
  )
}

export default BlogDetailsPage
