import React, { useState } from "react";
import styles from "./Dashboard.module.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import Commondashboard from "../List/Common/dashboard/Commondashboard";
import { AlertsSuccess } from "../../../common/alert/Alert";
import homeImage from "../../../constant";
import api from "../../../State/api/Api";
import { useDispatch } from "react-redux";
import { removeUser } from "../../../State/Features/common/userSlice";
import { useEffect } from "react";

export default function Dashboard(props) {
  const [age, setAge] = React.useState("");
  const [total, setTotal] = useState([]);
  const dispatch = useDispatch();
  const data = [
    {
      src: "/img/Buyer.png",
      field: "Total Buyer",
      total: total.total_buyer,
    },
    {
      src: "/img/Total Artists Icon.png",
      field: "Total Artist",
      total: total.total_artist,
    },
    {
      src: "/img/Total Curators.png",
      field: "Total Curator",
      total: total.total_curator,
    },
    {
      src: "/img/Total Orders.png",
      field: "Total Orders",
      total: total.total_order,
    },
    {
      src: "/img/Total Auctions.png",
      field: "Total Auctions",
      total: total.total_auction,
    },
    {
      src: "/img/Total Exhibition.png",
      field: "Total Exibition",
      total: total.total_exhibition,
    },
    {
      src: "/img/Total Earnings.png",
      field: "Total Earning",
      total: "0",
    },
    {
      src: "/img/Total Inquery.png",
      field: "Total Enquiry",
      total: total.total_enquiries,
    },
    {
      src: "/img/Total Sub Admins.png",
      field: "Total Sub Admins",
      total: total.total_subadmin,
    },
    {
      src: "/img/Total Number of Affiliates.png",
      field: "Total Number of Affilates",
      total: total.total_affiliate,
    },
    {
      src: "/img/Total Affiliate Earnings.png",
      field: "Total Affiliate Earnings",
      total: total.total_affiliate_earning,
    },
    {
      src: "/img/Total Visiters.png",
      field: "Total Galleries",
      total: total.total_gallery,
    },
    {
      src: "/img/Total Visiters.png",
      field: "Total Visitors",
      total: "0",
    },
    {
      src: "/img/Followers.png",
      field: "Followers",
      total: total.total_followers,
    },
    {
      src: "/img/Likes.png",
      field: "Likes",
      total: total.total_item_likes,
    },
    {
      src: "/img/Number of Artwokrs.png",
      field: "No. Of Artworks",
      total: total.total_item,
    },
    {
      src: "/img/Total Subscribers.png",
      field: "Total Subscribers",
      total: total.total_subscriber,
    },
  ];
  const handleTotal = async () => {
    const response = await api.applicationApi(
      "api/superAdmin/superAdmindashboard",
      "GET"
    );
    try {
      if (response.status === 200) {
        setTotal(response.data.data);
      } else {
        if (response.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          );
          return 401;
        }
        return false;
      }
    } catch (err) {}
  };

  useEffect(() => {
    handleTotal();
  }, []);

  const navigate = useNavigate();

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div className={styles.dashboard_main}>
      <AlertsSuccess />
      <div className={styles.dashboard_header}>
        <h3 className={styles.paneltitle}>Dashboard</h3>
        {/* <div>
          <FormControl sx={{ minWidth: 120, color: "red" }}>
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className={styles.select}
              IconComponent={() => (
                <img
                  alt="img"
                  src={ "/img/dropdownicon.png"}
                  width="13px"
                  height="10px"
                  style={{ marginRight: "20px" }}
                />
              )}
              sx={{
                color: "#636365",
                border: "0.6px solid #665D6F",
                borderRadius: "6px",
              }}
            >
              <MenuItem value="" sx={{ color: "#636365" }}>
                Filter
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </div> */}
      </div>
      <Commondashboard data={data} />
    </div>
  );
}
