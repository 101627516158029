// import validator from "validator"
// import isEmpty from "./isEmpty"

// export const validateAdminMenu = (menu) => {
//   let menuErrors = {}

//   if (validator.isEmpty(menu.name)) {
//     menuErrors.name = "Name is required"
//   }

//   if (validator.isEmpty(menu.link)) {
//     menuErrors.link = "Link is required";
//   } else {
//     // Auto-add "http://" if missing
//     let formattedLink = menu.link.startsWith("http://") || menu.link.startsWith("https://")
//       ? menu.link
//       : `http://${menu.link}`;

//     // Ensure domain has at least one letter and a valid TLD (e.g., .com, .net, .org)
//     const domainPattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]*[a-zA-Z]+[a-zA-Z0-9-]*\.[a-zA-Z]{2,}$/;

//     if (!domainPattern.test(formattedLink) || !validator.isURL(formattedLink)) {
//       menuErrors.link = "Please enter a valid URL (e.g., www.google.com or https://example.com)";
//     }
//   }

//   if (!menu.image || menu.image === null) {
//     menuErrors.image = "Image is required"
//   }

//   return {
//     menuErrors,
//     isValid: isEmpty(menuErrors),
//   }
// }

import validator from "validator";
import isEmpty from "./isEmpty";

export const validateAdminMenu = (menu) => {
  let menuErrors = {};

  if (validator.isEmpty(menu.name)) {
    menuErrors.name = "Name is required";
  }

  if (validator.isEmpty(menu.link)) {
    menuErrors.link = "Link is required";
  } else {
    let formattedLink =
      menu.link.startsWith("http://") || menu.link.startsWith("https://")
        ? menu.link
        : `http://${menu.link}`;

    // const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]*[a-zA-Z]+[a-zA-Z0-9-]*\.(com|org|net|in|edu|gov|co|io|info|biz|me)(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;
    // const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]*[a-zA-Z]+[a-zA-Z0-9-]*\.(com|org|net|in|edu|gov|co|io|info|biz|me)(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;

    if (!validator.isURL(formattedLink, { require_protocol: true })) {
      menuErrors.link = "Please enter a valid URL starting with http:// or https://";
    }
    
  }

  if (!menu.image || menu.image === null) {
    menuErrors.image = "Image is required";
  }

  return {
    menuErrors,
    isValid: isEmpty(menuErrors),
  };
};
