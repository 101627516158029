import React, { useEffect, useState } from "react";
import styles from "./Gallerytestimonial.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  editTestimonial,
  fetchGalleryProfileData,
  fetchTestimonialData,
  handleAddTestimonial,
} from "../../../State/api/superadminApi";
import { handleFetchArtistProfile } from "../../../State/api/artistapi";
import { handleFetchGalleryProfile } from "../../../State/api/galleryApi";
function GalleryTestimonial() {
  const [data, setData] = useState({ offset: 0 });
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");
  const [mode, setMode] = useState("add"); // "add" or "edit"
  const [isActive, setIsActive] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [position, setPosition] = useState("");
  const [error, setError] = useState("");
  const [positionerror, setPositionerror] = useState("");
  const validateDescription = (value) => {
    if (!value.trim()) {
      setError("Message is required.");
      return false;
    }
    if (value.length < 10) {
      setError("Message must be at least 10 characters long.");
      return false;
    }
    setError(""); // No errors
    return true;
  };

  const validatePosition = (value) => {
    if (!value.trim()) {
      setPositionerror("Position is required.");
      return false;
    }
    if (value.length < 3) {
      setPositionerror("Position must be at least 3 characters long.");
      return false;
    }
    setPositionerror(""); // No errors
    return true;
  };
  const fetchData = () => {
    dispatch(fetchTestimonialData(data));
  };
  useEffect(() => {
    fetchData();
  }, [data]);

  useEffect(() => {
    dispatch(fetchGalleryProfileData(localStorage.getItem("user_id")));
  }, []);

  const testdata = useSelector((state) => state.testmonialdata.data);
  const GalleryDetails = useSelector((state) => state.galleryprofiledata.data);

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value);
    validateDescription(value); // Trigger validation on each change
  };

  const handlePositionChange = (e) => {
    const value = e.target.value;
    setPosition(value);
    validatePosition(value);
  };

  useEffect(() => {
    if (testdata?.data && testdata.data.length > 0) {
      const firstTestimonial = testdata.data[0];
      setDescription(firstTestimonial.description || "");
      setPosition(firstTestimonial.position || "");
      setIsActive(firstTestimonial.is_active === "Active");
      setMode("edit"); // Switch to edit mode if data exists
      setCurrentId(firstTestimonial._id); // Capture the ID for editing
    } else {
      setMode("add"); // Default to add mode if no data exists
    }
  }, [testdata]);

  const handleSubmit = () => {
    const isDescriptionValid = validateDescription(description);
    const isPositionValid = validatePosition(position);

    if (isDescriptionValid && isPositionValid) {
      const data = {
        customer_name:
          `${GalleryDetails?.data?.first_name} ${GalleryDetails?.data?.last_name}` ||
          "",
        description,
        position,
        ...(mode === "add" && {
          image: GalleryDetails?.data?.profile_image || null,
        }),
        ...(mode === "edit" && { is_active: isActive ? "Active" : "Inactive" }),
      };

      if (mode === "add") {
        dispatch(handleAddTestimonial(data, () => {}, fetchData));
      } else if (mode === "edit") {
        dispatch(editTestimonial(currentId, data, () => {}, fetchData));
      }
    }
  };

  return (
    <div className={styles.mains}>
      {" "}
      <div className={styles.components}>
        <div>
          <div className="form-group">
            <label>Position</label>
            <input
              className="form-control"
              placeholder="Enter your position"
              variant="outlined"
              fullWidth
              value={position}
              onChange={handlePositionChange}
              InputLabelProps={{
                shrink: !!position, // Ensures the label shrinks when there's a default value
              }}
              error={!!positionerror} // Highlights the field in red if there's an error
              helperText={positionerror}
            />
          </div>
          <div className="form-group">
            <label>Your Message</label>
            <textarea
              multiline
              rows={8}
              placeholder="Type your message here..."
              variant="outlined"
              fullWidth
              value={description}
              onChange={handleDescriptionChange}
              InputLabelProps={{
                shrink: !!description, // Ensures the label shrinks when there's a default value
              }}
              error={!!error} // Highlights the field in red if there's an error
              helperText={error}
              className="form-control"
            />
          </div>

          <button className="action_btn" onClick={handleSubmit}>
            {mode === "add" ? "Add Testimonial" : "Update Testimonial"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default GalleryTestimonial;
