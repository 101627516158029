import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React from "react";
import styles from "./Fivecoltable.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CircularLoader from "../../../common/loader/Loader";
import { addData } from "../../../State/Features/Artist/profileSlice";
import homeImage from "../../../constant";
import dateFormat from "../../../utils/dateFormat";
import TruncatedTooltipText from "../../../utils/truncateTooltip";

export default function ExhibitionTablePart(props) {
  console.log("exibition table props", props);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const loader = useSelector((state) => state.loader);
  const [threedot_id, setThreedot_id] = React.useState("");
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const threedots = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setThreedot_id(event.target.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handledetailpage = (row) => {

    if (location.pathname === "/artist") {
      navigate(`/artist/${row._id}`);
    } else if (location.pathname === "/affiliate") {
      navigate(`/affiliate/${row._id}`);
    } else if (location.pathname === "/auction") {
      navigate(`/auction/${row._id}`);
    } else if (location.pathname === "/exhibitions") {
      navigate(`/exhibitions/${row._id}`);
    } else if (location.pathname === "/exhibitionsgallery") {
      navigate(`/exhibitionsgallery/${row._id}`);
    } else if (location.pathname === "/orders") {
      navigate(`/orders/${row._id}`);
    }
    dispatch(addData(row));
  };
  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{
          tableLayout: "auto",
          width: "100%"
        }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  className={styles.table_head}
                  align={props.head.length - 1 === i ? "left" : "left"}
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "15px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props.data &&
                props.data?.map((row) => (
                  <TableRow
                    style={{ color: "#636365", height: "30px" }}
                    className={styles.table_row}
                    key={row._id}
                  >
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                        textDecoration: "underline"
                      }}
                      style={{ cursor: "pointer" }}
                      // onClick={() => handledetailpage(row)}
                      className={styles.table_column}
                      align="left"
                    >
                      {props.activetab === "gallery_enquiry" ? row?.enquiry_id : ""}
                      {props.activetab === "gallery_order" ? row._id : ""}
                      {/* {props.activetab === "live_gallery" || props.activetab === "upcoming_gallery" || props.activetab === "past_gallery" ? row?.title?.length > 10 ? row.title.slice(0, 10) + "..." : row.title : null} */}
                      {props.activetab === "live_gallery" || props.activetab === "upcoming_gallery" || props.activetab === "past_gallery" ? <TruncatedTooltipText
                        text={row.title}
                        maxLength={20}
                        href={`/${window.location.pathname === "exhibitionartist" ? "exhibitionartist" : "exhibitionsgallery"}/${row._id}`}
                        onClick={() => {
                          // Replace this with your navigation logic
                          window.location.href = `/${window.location.pathname === "/exhibitionartist" ? "exhibitionartist" : "exhibitionsgallery"}/${row._id}`;
                        }}
                        style={{ color: "black" }}
                      /> : ''}

                    </TableCell>
                    {!props.activetab === "live_gallery" || !props.activetab === "upcoming_gallery" || !props.activetab === "past_gallery" ? (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {props.activetab === "gallery_enquiry" ? row?.customer_name : ""}

                        {row.buyer && row.buyer.map(val => (val.first_name + " " + val.last_name))}

                      </TableCell>
                    ) : null}
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {props.activetab === "gallery_enquiry" ? row?.customer_name : ""}
                      {/* {props.activetab === "live_gallery" ? (row.is_paid ? "Paid" : "Free") : ""} */}
                      {props.activetab === "upcoming_gallery"
                        ? row.is_paid && row.is_paid === true
                          ? "Paid"
                          : "Free"
                        : " "}
                      {props.activetab === "past_gallery"
                        ? row.is_paid && row.is_paid === true
                          ? "Paid"
                          : "Free"
                        : " "}
                      {props.activetab === "live_gallery"
                        ? row.is_paid && row.is_paid === true
                          ? "Paid"
                          : "Free"
                        : " "}

                      {/* this is for gallery order */}
                      {props.activetab === "gallery_order" ? "$" + row.price : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"

                    >
                      {props.activetab === "gallery_enquiry" ? row?.enquiry_type : ""}
                      {props.activetab === "live_gallery" || 
                      props.activetab === "upcoming_gallery" || 
                      props.activetab === "past_gallery" ? 
                    dateFormat(row.start_date && row.start_date.substring(0, 10))
                      : null}
                      {/* this is for gallery order  */}
                      {/* {dateFormat(row?.order_date)} */}
                    </TableCell>
                    {props.activetab === "gallery_enquiry" ? (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",

                        }}
                        className={styles.table_columns}
                        align="left"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {props.activetab === "gallery_enquiry" ? <TruncatedTooltipText text={row?.description} maxLength={20} href={{}} onClick={() => { }} style={{ color: "#636365", textDecoration: "none" }} /> : ""}
                          {/* {row.status ? (
                            "open"
                          ) : (
                            // <span className={styles.closed}>closed</span>
                            ''
                          )} */}
                          {!props.activetab === "gallery_enquiry" ? <>
                            <Tooltip title="Account settings">
                              <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={
                                  threedots ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={threedots ? "true" : undefined}
                              >
                                <img
                                  src={"/img/Menu Icon.png"}
                                  height="21.62px"
                                  width="4.63px"
                                  alt="img"
                                />
                              </IconButton>
                            </Tooltip>
                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={threedots}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  width: "150px",
                                  overflow: "visible",
                                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                  filter:
                                    "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                  mt: 2.5,
                                  "& .MuiAvatar-root": {
                                    width: "120px",
                                    height: "132px",
                                    ml: 0.5,
                                    mr: 1,
                                    // mt:5
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    // horizontal: 'center',
                                    right: 62,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "center",
                                vertical: "right",
                              }}
                              anchorOrigin={{
                                horizontal: "left",
                                vertical: "center",
                              }}
                            >
                              <MenuItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Featured Artist
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Delete
                              </MenuItem>
                            </Menu></> : null}

                        </Box>
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {dateFormat(row.end_date && row.end_date.substring(0, 10))}
                        {/* this is for gallery order  */}
                        {row.items && row.items.length}
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"

                    >
                      {dateFormat(row.end_date_registration && row.end_date_registration.substring(0, 10))}
                      {/* this is for gallery order  */}
                      {/* {dateFormat(row?.order_date)} */}
                    </TableCell>

                    {props.activetab !== 'gallery_enquiry' &&
                    props.activetab !== 'past_gallery'
                    ? (<>
                      <TableCell sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                        className={styles.table_column}
                        align="left">
                        {props.activetab === "live_gallery" && (
                          new Date(row.end_date_registration) > new Date() ? (
                            <Button
                              onClick={() => props.handleInterested(row._id)}
                              disabled={row.artist_ids?.includes(localStorage.getItem("user_id"))}
                              variant={row.artist_ids?.includes(localStorage.getItem("user_id")) ? "text" : "contained"}
                              sx={{ width: "106px", height: "34px", borderRadius: "4px" }}
                            >
                              Interested
                            </Button>
                          ) : (
                            <Button
                              onClick={() => { }}
                              disabled
                              variant="contained"
                              sx={{ width: "106px", height: "34px", borderRadius: "4px" }}
                            >
                              Interested
                            </Button>
                          )
                        )}

                      </TableCell></>) : null}
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}
