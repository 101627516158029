import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";




i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    debugger: true,
    fallbackLng: "en",
    returnObjects: true,
    ns: ["translation"], 
    defaultNS: "translation", 
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    detection: {
      order: ["localStorage", "cookie", "navigator"], // Check saved language first
      caches: ["localStorage", "cookie"], // Store the selected language
    },
  });
  if (!localStorage.getItem("i18nextLng")) {
    i18n.changeLanguage("en");
  }


  export default i18n;