import React, { useCallback, useEffect } from "react"
import styles from "./CuratorExhibitionDetails.module.css"

import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import dayjs from "dayjs"
import { acceptDeclineExshibitionArt, fetchcuratorExhibitionDetails, fetchExhibitionArtData } from "../../../../../State/api/superadminApi"
import { Box } from "@mui/material"
import CuratorExhibitionDetailsCard from "./CuratorExhibitionDetailsCard"

const approvedhead = [
  {
    id: "artist_name",
    label: "Artist Name",
  },
  {
    id: "number",
    label: "Mobile Number",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "type",
    label: "Type",
  },
]

export default function CuratorExhibitiondetails() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loader = useSelector((state) => state.loader)
  const curatorDetailsData = useSelector(
    (state) => state.curatorExhibitionDetailsData.data
  )
  console.log(curatorDetailsData, "curatorExhibitionDetailsData-------")
  // useEffect(() => {
  //   dispatch(fetchcuratorExhibitionDetails(id))
  // }, [dispatch, id])

  const handleBack = () => {
    navigate("/exhibitions")
  }


   const exhibitionAllArt = useSelector((state) => state.exhibitionAllArt.data);
  
  
   const fetchData = () => {
    if(id){
    dispatch(fetchExhibitionArtData(id));
    }
  }

   useEffect(() => {
    fetchData();
   }, []);

  

  return (
    
    <div className={styles.mains}>
    <CuratorExhibitionDetailsCard
     type="curator_exhibition"
     data={exhibitionAllArt}
    id={id}
    />
    
   </div>
  )
}
