import React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getCareerdetailsByid } from "../../../../State/api/superadminApi";
import { Box, Table } from "@mui/material";
import styles from "./Careerdetail.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import serverimage from "../../../../../serverConstant"
import { useNavigate } from "react-router-dom";
//
import serverimage from "../../../../serverConstant";
import moment from "moment";
export default function Careerdetail(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleProfile = (id) => {
    navigate(`/earnings/${id}`);
  };
  const loader = useSelector((state) => state.loader);
  const careerDetailsdata = useSelector(
    (state) => state.careerDetailsdata.data
  );
  console.log(careerDetailsdata, "careerDetailsdata--");

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const head = [
    {
      id: 1,
      label: "Name",
    },
    {
      id: 2,
      label: "Email",
    },
    {
      id: 3,
      label: "Resume",
    },
    {
      id: 4,
      label: "Applied Date",
    },
  ];

  const fetchCareerdetails = () => {
    dispatch(getCareerdetailsByid(id));
  };
  React.useEffect(() => {
    fetchCareerdetails();
  }, [id]);
  return (
    <div>
      <div className={styles.jobDetailsContainer}>
        <div>
        <h3 className={styles.sethng}>Job Details</h3>
      <div>
        <p className={styles.odlist}><span>Job Title :</span> <span>{careerDetailsdata?.career?.title}</span></p>
        <p className={styles.odlist}><span>Post Date :</span> <span>{moment(careerDetailsdata?.career?.createdAt)?.format("DD-MM-YYYY")}</span></p>
        <p className={styles.odlist}><span>Department :</span> <span>{careerDetailsdata?.career?.department}</span></p>
        <p className={styles.odlist}><span>Location :</span> <span>{careerDetailsdata?.career?.location}</span></p>
        <p className={styles.odlist}><span>City :</span> <span>{careerDetailsdata?.career?.city}</span></p>
        <p className={styles.odlist}><span>Country :</span> <span>{careerDetailsdata?.career?.country}</span></p>
        <p className={styles.odlist}><span>State :</span> <span>{careerDetailsdata?.career?.state}</span></p>
        <p className={styles.odlist}><span>Experience:</span> <span>{careerDetailsdata?.career?.min_exp}-
        {careerDetailsdata?.career?.max_exp} yrs</span></p>
        <p className={styles.odlist}><span>Position:</span> <span>{careerDetailsdata?.career?.type_of_position}</span></p>
        <p className={styles.odlist}><span>Description:</span> <span
            className={styles.jobDetailValue}
            dangerouslySetInnerHTML={{
              __html: careerDetailsdata?.career?.description || "",
            }}
          ></span></p>
        <p className={styles.odlist}><span></span> <span></span></p>
        <p className={styles.odlist}><span></span> <span></span></p>
      </div>
        </div>
        <h3 className={styles.sethng}>Applied candidate list</h3>
        <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table
          sx={{ minWidth: 700, borderRadius: "0px" }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3", borderRadius: "none" }}>
              {head.map((column, index) => (
                <TableCell
                  align={index === 0 ? "center" : "start"}
                  key={column.id}
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "15px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* {loader.loader ? (
      <TableBody>
       <TableRow>
        <TableCell colSpan={8}>
         <Box className={styles.loader_box}>
          {" "}
          <CircularLoader size={60} />
         </Box>
        </TableCell>
       </TableRow>
      </TableBody>
     ) : ( */}
          <TableBody>
            {careerDetailsdata &&
            careerDetailsdata?.joinOurTeamEntries?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}> No Data Found</Box>
                </TableCell>
              </TableRow>
            ) : (
              careerDetailsdata?.joinOurTeamEntries &&
              careerDetailsdata?.joinOurTeamEntries.map((row) => (
                <TableRow
                  className={styles.table_row}
                  key={row.id}
                  style={{
                    color: "#636365",
                    backgroundColor:
                      props.poptype === "add" ? "white" : "#F6F6F6",
                  }}
                >
                  <TableCell
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="center"
                  >
                    {`${row?.first_name}  ${row?.last_name}`}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="left"
                  >
                    {row?.email}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="center"
                  >
                    {/* {row?.attachement} */}
                    <a
                      href={` /${row?.attachement}`} // Replace with your server path
                      download={row?.attachement}
                      style={{ color: "#636365", textDecoration: "none" }}
                    >
                      {row?.attachement}
                    </a>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="left"
                  >
                    {moment(row?.createdAt).format('DD-MM-YYYY')}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          {/* )} */}
        </Table>
      </TableContainer>
      </div>

      
      
    </div>
  );
}
