import validator from "validator"
import isEmpty from "./isEmpty"

export const validateAdminCategory = (cat) => {
  let catErrors = {}

  if (validator.isEmpty(cat?.style || "")) {
    catErrors.style = "Style is required";
  }
  if (validator.isEmpty(cat?.medium || "")) {
    catErrors.medium = "Medium is required";
  }
  if (validator.isEmpty(cat?.material || "")) {
    catErrors.material = "Material is required";
  }
  if (validator.isEmpty(cat?.subject || "")) {
    catErrors.subject = "Subject is required";
  }

  if (!cat.image || cat.image === null) {
    catErrors.image = "Image is required"
  }

  return {
    catErrors,
    isValid: isEmpty(catErrors),
  }
}
