import React, { useState,useEffect } from "react";
import { Modal, Box, Button, TextField, Select, MenuItem, InputLabel, FormControl, Link } from "@mui/material";

import styles from "./CuratorEnquiry.module.css"
import { useDispatch, useSelector } from "react-redux"
import CuratorEnquiryTable from "./CuratorEnquiryTable"
import Tableheader from "../../../components/Pages/List/Table/Tableheader/Tableheader"
import { fetchEnquiryById } from "../../../State/api/superadminApi";
import dayjs from "dayjs";
import { RiCloseCircleFill } from "react-icons/ri";
import { AlertsSuccess, Errornotify, Successnotify } from "../../../common/alert/Alert";
import { Pagination, PaginationItem } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const buyhead = [
  {
    id: "Sl No",
    label: "Sl. No",
  },
  {
    id: "Inquiry Type",
    label: "Inquiry Type",
  },
  {
    id: "inquirysubtype",
    label: "Inquiry SubType",
  },
  {
    id: "subject",
    label: "Subject",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "Created_date",
    label: "Created Date",
  },
  {
    id: "Action",
    label: "Action",
  },
 
]
const sortdata = [
  { label: "By Date ↓", value: "low" },
  { label: "By Date ↑", value: "high" },
]
const filterdata = [
  { label: "Approved", value: "approved" },
  { label: "Pending", value: "pending" },
  { label: "Cancel", value: "cancel" },
]
export default function CuratorEnquiry() {
  const [data, setData] = useState({ offset: 0 })
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })
  const dispatch = useDispatch()
  
  
  
  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })
  }

  const [open, setOpen] = useState(false);
  const [enquiryType, setEnquiryType] = useState("");
  const [enquirySubtype, setEnquirySubtype] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState("");

  const [enquiryTypes, setEnquiryTypes] = useState([]);
  const [enquirySubtypes, setEnquirySubtypes] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    resetForm(); // Reset the form on close
  };

   const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (!file) return;
    
      const validTypes = ["image/jpeg", "image/png", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
      const maxSize = 10 * 1024 * 1024; // 10MB
    
      // Check if the file type is valid
      if (!validTypes.includes(file.type)) {
        Errornotify("Please upload a valid image (.jpg, .jpeg, .png) or document (.doc, .docx) file.");
        event.target.value = ""; // Reset input field
        return;
      }
    
      // Check if the file size exceeds the limit
      if (file.size > maxSize) {
        Errornotify("Please upload a file less than 10MB.");
        event.target.value = ""; // Reset input field
        return;
      }
    
      setAttachment(file);
    };

  const usertype = localStorage.getItem("user_id");

  const userid = localStorage.getItem("usertype");


  const enquirydata = useSelector((state) => state.userenquirydata.data)
    console.log("enquirydata",enquirydata)
    const fetchData = () => {
      dispatch(fetchEnquiryById(usertype))
    }
    useEffect(() => {
      fetchData()
    }, [usertype])

  const [errors, setErrors] = useState({
    enquiryType: "",
    enquirySubtype: "",
    subject: "",
    message: "",
    attachment: "",
  });
  useEffect(() => {
    fetch("http://3.6.185.87:8000/api/artsmileyenquirytype/getAllArtsmileyEnquiryTypes")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const filteredTypes = data.data.filter((item) => item.usertype === "curator");
          setEnquiryTypes(filteredTypes);
        }
      })
      .catch((error) => console.error("Error fetching enquiry types:", error));
  }, []);

  useEffect(() => {
    if (enquiryType) {
      console.log("Fetching for enquiryType:", enquiryType);
      fetch(`http://3.6.185.87:8000/api/artsmileyenquirysubtype/getArtsmileyEnquirySubTypeByTypeId/${enquiryType}`)
        .then((res) => res.json())
        .then((data) => {
          console.log("API Response:", data);
          setEnquirySubtypes(data.data);
        })
        .catch((error) => console.error("Error fetching enquiry subtypes:", error));
    } else {
      setEnquirySubtypes([]);
    }
  }, [enquiryType]);

  const validateForm = () => {
    let valid = true;
    let newErrors = { enquiryType: "", enquirySubtype: "", subject: "", message: "" };

    if (!enquiryType) {
      newErrors.enquiryType = "Inquiry Type is required";
      valid = false;
    }
    if (!enquirySubtype) {
      newErrors.enquirySubtype = "Inquiry Subtype is required";
      valid = false;
    }
    if (!subject.trim()) {
      newErrors.subject = "Subject is required";
      valid = false;
    }
    if (!message.trim()) {
      newErrors.message = "Message is required";
      valid = false;
    }
    if (!attachment) {
      newErrors.attachment = "Attachment is required";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (loading) return; // Prevent multiple clicks
    if (!validateForm()) return;
    // Get selected Enquiry Type and Subtype
    setLoading(true); // Disable button after first click
    const selectedEnquiryType = enquiryTypes.find(type => type._id === enquiryType);
    const selectedEnquirySubtype = enquirySubtypes.find(subtype => subtype._id === enquirySubtype);
  
    const formData = new FormData();
    formData.append("enquirytype", selectedEnquiryType ? selectedEnquiryType.enquirytype : ""); // Send Name instead of ID
    formData.append("enquirysubtype", selectedEnquirySubtype ? selectedEnquirySubtype.enquirysubtype : ""); // Send Name instead of ID
    formData.append("subject", subject);
    formData.append("message", message);
    formData.append("attachment", attachment ); // Send only the file name
    formData.append("userid", localStorage.getItem("user_id"));
    formData.append("usertype", localStorage.getItem("usertype"));
    formData.append("status", "open"); // Default status
  
    // Debugging: Print FormData
    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}:`, value);
    // }
  
    try {
      const response = await fetch("http://3.6.185.87:8000/api/artsmileyenquiry/addArtsmileyEnquiry", {
        method: "POST",
        body: formData,
      });
  
      const result = await response.json();
  
      if (result.success) {
       
      
              Successnotify("Thank you for submitting your inquiry. We'll review and respond to you as soon as possible.");
        
       
        setTimeout(() => {
          handleClose();
          dispatch(fetchEnquiryById(usertype));
          setLoading(false); // Re-enable button after process completes
      }, 2000);
      } else {
       // alert("Failed to submit enquiry: " + result.message);
       setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting inquiry:", error);
      setLoading(false);
    }
  };
  const filteredInquiries = enquirydata?.filter((val) => {
    if (condition.search === "") {
      return val
    } else  {
      const searchString = [
        val.enquirytype?.toLowerCase(),
        val.enquirysubtype?.toLowerCase(),
        val.subject?.toLowerCase(),
        val.status?.toLowerCase(),

       
        dayjs(val.createdAt).format("DD-MM-YYYY"),
      ]
        .filter(Boolean) 
        .join(" "); 
  
      return searchString.includes(condition.search.toLowerCase());
    } 
  })
  const [currentPage, setCurrentPage] = useState(1);
      const itemsPerPage = 10;
      const handlePageChange = (event, page) => {
        setCurrentPage(page);
      };
      useEffect(() => {
        setCurrentPage(1);
      }, []);
    const pageSize = 10;  
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedInquiries = filteredInquiries?.slice(startIndex, endIndex);
  const resetForm = () => {
    setEnquiryType("");
    setEnquirySubtype("");
    setSubject("");
    setMessage("");
    setAttachment("");
    setErrors({ enquiryType: "", enquirySubtype: "", subject: "", message: "" });
  };
  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
    setCurrentPage(1)
  }
  return (
    <div className={styles.mains}>
        <AlertsSuccess />
      <div className={styles.components}>
         <Tableheader
       handleFilter={handleFilter}
      
       filterdata={filterdata}
       sortdata={sortdata}
       condition={condition}
       search={condition.search}
       handleSearch={handleSearch}
       button=""
        /> 
         <div className={styles.invite_btn}>
         <button className="action_btn" variant="contained" onClick={handleOpen}>
        Add Inquiry
      </button>
            </div>
            <Modal open={open} onClose={handleClose}>
        <Box  className={styles.modalBox}>
        <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  Add Inquiry
              </p>
           
          <div className="form-group">
  <label>Inquiry Type *</label>
  <select className="form-control" required value={enquiryType} onChange={(e) => setEnquiryType(e.target.value)}>
    <option value="" disabled hidden>Select Inquiry Type</option>  
    {enquiryTypes.length > 0 ? (
      enquiryTypes.map((type) => <option key={type._id} value={type._id}>{type.enquirytype}</option>)
    ) : (
      <option disabled>No result found</option>
    )}
  </select>
  {errors.enquiryType && <p className="error_enquiry">{errors.enquiryType}</p>}
</div>


<div className="form-group">
  <label>Inquiry Subtype *</label>
  <select className="form-control" required value={enquirySubtype} onChange={(e) => setEnquirySubtype(e.target.value)}>
    <option value="" disabled hidden>Select Inquiry Subtype</option>
    {enquirySubtypes.length > 0 ? (
      enquirySubtypes.map((subtype) => (
        <option key={subtype._id} value={subtype._id}>
          {subtype.enquirysubtype}
        </option>
      ))
    ) : (
      <option disabled>No result found</option>
    )}
  </select>
  {errors.enquirySubtype && <p className="error_enquiry">{errors.enquirySubtype}</p>}
</div>


          <div className="form-group">
            <label>Subject *</label>
            <input className="form-control" required value={subject} placeholder="Enter Subject" type="text" onChange={(e) => setSubject(e.target.value)} />
            {errors.subject && <p className="error_enquiry">{errors.subject}</p>}
          </div>

          <div className="form-group">
            <label>Message *</label>
            <textarea className="form-control" required rows={4} value={message} placeholder="Enter Message" onChange={(e) => setMessage(e.target.value)} />
            {errors.message && <p className="error_enquiry">{errors.message}</p>}
          </div>

          <div className="form-group">
            <label>Attachment *</label>
            <input type="file" className="form-control" required
              accept=".jpg,.jpeg,.png,.doc,.docx"
            onChange={handleFileChange} />
            {errors.attachment && <p className="error_enquiry">{errors.attachment}</p>}
          </div>
    <div className={styles.actionc}>
    <button className="action_btn" onClick={handleSubmit} disabled={loading}>{loading ? "Submitting..." : "Submit"}</button>
    </div>
          {/* <Box mt={2} display="flex" justifyContent="space-between">
          
          
            <Button variant="outlined" onClick={handleClose}>Cancel</Button>
          </Box> */}
        </Box>
      </Modal>
         <CuratorEnquiryTable
          activetab="curatorenquirytable"
          head={buyhead}
         data={paginatedInquiries}
        /> 
     <Box className={styles.pagination_box} sx={{}}>
          <Pagination
            count={Math.ceil(filteredInquiries?.length / itemsPerPage)
            } // Total pages
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            renderItem={(item) => (
              <PaginationItem
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "black", // Set the selected background to black
                    color: "white", // Ensure the text color is visible
                    "&:hover": {
                      backgroundColor: "black", // Maintain black on hover
                    },
                  },
                }}
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
              />
            )}
          />
        </Box>
      </div>
    </div>
  )
}
