import { createTheme, ThemeProvider, Link, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./TandC.module.css"; 
import { useNavigate } from "react-router-dom";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import api from "../../../State/api/Api";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TranslateWrapper from "../../../languageTranslator/TranslateWrapper";

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },  
  },
});
function ShippingPolicy() {
  const [termsData, setTermsData] = useState();

  const dispatch = useDispatch();
  const { t } = useTranslation("shippingPolicy");

  const handleFetchTermsCondition = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi('api/user/termsCondition'
      )
      if (result && result.status === 200) {
        setTermsData(result.data.response);
        dispatch(setLoader(false));
      }
      else if (result.status === 401) {
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handleFetchTermsCondition();
  }, [])
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route)
  }
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.tandc}>
        <div className={styles.allcategory}>
          <div className={styles.headerCategory}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={styles.pageitle}>
              <h1>{t("shipping_policy")}</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>{t("home")} /{" "} </Link></li>
                <li>{t("shipping_policy")}</li>
              </ul>
            </div>
          </Container>
          </div>
        </div>
        {termsData && termsData.map((val) => (
          <div className={styles.tandc__content}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div>
              <h3>
              {t("shipping_policy_artists")}
              </h3>
              {/* <p>{val.description}</p>
              <h4>
                {val.title}
              </h4> */}
              <p>{t("shipping_policy_artists_desc")}
              </p>
              <h4>{t("shipping_insurance_policy")}</h4>
              <p>{t("shipping_insurance_policy_desc")}</p>
              <h4>{t("packaging_guidelines")}</h4>
              <p>{t("packaging_guidelines_desc")}</p>
              <h5>1. {t("quick_tips")}</h5>
              <ul>
                <li><b>{t("assess")}:</b>{" "} {t("assess_desc")}</li>
                <li><b>{t("pack")}:</b>{" "} {t("pack_desc")}</li>
                <li><b>{t("seal")}:</b>{" "} {t("seal_desc")}</li>
                <li><b>{t("label")}:</b>{" "} {t("label_desc")}</li>
              </ul>
              <h5>2. {t("assess_packaging_needs")}</h5>
              <ul>
                <li><b>{t("weight")}:</b>{" "} {t("weight_desc")}</li>
                <li><b>{t("size_shape")}:</b>{" "} {t("size_shape_desc")}</li>
                <li><b>{t("value")}:</b>{" "} {t("value_desc")}</li>
                <li><b>{t("fragility")}:</b>{" "} {t("fragility_desc")}</li>
                <li><b>{t("cartons_boxes")}:</b>{" "} {t("cartons_boxes_desc")}</li>
                <li><b>{t("tubes")}:</b>{" "} {t("tubes_desc")}</li>
                <li><b>{t("crates")}:</b>{" "} {t("crates_desc")}</li>
              </ul>
              <h5>3. {t("pack_with_care")}</h5>
              <h6>{t("for_framed_glass")}:</h6>
              <p>{t("for_framed_glass_desc")}:</p>
              <ul>
                <li>{t("for_framed_glass_step1")}</li>
                <li>{t("for_framed_glass_step2")}</li>
                <li>{t("for_framed_glass_step3")}</li>
              </ul>
              <h6>{t("for_unframed_works")}:</h6>
              <p>{t("for_unframed_works_desc")}:</p>
              <ul>
                <li>{t("for_unframed_works_step1")}</li>
                <li>{t("for_unframed_works_step2")}</li>
                <li>{t("for_unframed_works_step3")}</li>
            </ul>
              <h5>4. {t("securing_artwork_box")}</h5>
              <ul>
              <li>{t("securing_artwork_box_step1")}</li>
              <li>{t("securing_artwork_box_step2")}</li>
              <li>{t("securing_artwork_box_step3")}</li>
              <li>{t("securing_artwork_box_step4")}</li>
              </ul>
              <h5>5. {t("seal_securely")}</h5>
              <ul>
                <li><b>{t("tape_application")}:</b>{" "} {t("tape_application_desc")}:
                <ol>
                  <li>{t("tape_application_step1")}</li>
                  <li>{t("tape_application_step2")}</li>
                  <li>{t("tape_application_step3")}</li>
                </ol>
                </li>
                <li><b>{t("avoid_strapping")}:</b>{" "} {t("avoid_strapping_desc")}</li>
              </ul>
              <h5>6. {t("label_clearly")}</h5>
              <ul>
                <li><b>{t("shipment_label")}:</b>{" "} {t("shipment_label_desc")}</li>
                <li><b>{t("special_handling_labels")}:</b>{" "} {t("special_handling_labels_desc")}</li>
              </ul>
              <h4>{t("order_tracking")}</h4>
              <p>{t("order_tracking_desc")}</p>
              <h4>{t("shipping_costs")}</h4>
              <p>{t("shipping_costs_desc")}</p>
              <h5>{t("custom_duties")}</h5>
              <p>{t("custom_duties_desc")}</p>
              <h5>{t("address_change")}</h5>
              <p>{t("address_change_desc")}</p>
              <h5>{t("key_points_to_remember")}:</h5>
              <p><b>{t("before_schedule_pickup")}:</b>{" "} {t("before_schedule_pickup_desc")}</p>
              <p><b>{t("after_schedule_pickup")}:</b>{" "} {t("after_schedule_pickup_desc")}</p>
              <h4>{t("damaged_artwork_policy")}</h4>
              <p>{t("damaged_artwork_policy_desc")}:</p>
              <h5>1. {t("damaged_artwork_policy_point1")}</h5>
              <p>{t("damaged_artwork_policy_point1_desc")}</p>
              <h5>2. {t("damaged_artwork_policy_point2")}</h5>
              <p>{t("damaged_artwork_policy_point2_desc")}</p>
              <h5>3. {t("damaged_artwork_policy_point3")}</h5>
              <p>{t("damaged_artwork_policy_point3_desc")}</p>
              <h5>4. {t("damaged_artwork_policy_point4")}</h5>
              <p>{t("damaged_artwork_policy_point4_desc")}</p>
              <h5>5. {t("damaged_artwork_policy_point5")}</h5>
              <p>{t("damaged_artwork_policy_point5_desc1")}</p>
              <p>{t("damaged_artwork_policy_point5_desc2")}</p>
              
              
            </div>
            </Container>
            <div className={styles.privacy__buyer}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          <div >
            <h3>
            {t("artsmiley_shipping_buyer")}
            </h3>
            <p>{t("artsmiley_shipping_buyer_desc")}</p>
            <h4>1. {t("local_international_shipping")}</h4>
            <p>{t("local_international_shipping_desc")}</p>
            <h5>{t("local_art_delivery")}</h5>
            <p>{t("local_art_delivery_desc")}:</p>
            <ul>
              <li><b>{t("sulptures_Framed")}:</b>{" "} {t("sulptures_Framed_desc1")}</li>
              <li><b>{t("rolled_prints")}:</b>{" "} {t("rolled_prints_desc1")}</li>
              <li>{t("local_art_delivery_point1")}</li>
            </ul>
            <h5>{t("international_shipping")}</h5>
            <p>{t("international_shipping_desc")}:</p>
            <ul>
              <li><b>{t("sulptures_Framed")}:</b>{" "} {t("sulptures_Framed_desc2")}</li>
              <li><b>{t("rolled_prints")}:</b>{" "} {t("rolled_prints_desc1")}</li>
              <li>{t("international_shipping_point1")}</li>
              <li>{t("international_shipping_point2")}</li>
              <li>{t("international_shipping_point3")}</li>
            </ul>
            <h5>{t("shipping_costs")}</h5>
            <p>{t("shipping_costs_desc1")}</p>
            <h4>2. {t("order_tracking")}</h4>
            <p>{t("order_tracking_desc1")}</p>
            <h4>3. {t("customs_duties_taxes")}</h4>
            <p>{t("customs_duties_taxes_desc")} </p>
            <h4>4. {t("address_change")}</h4>
            <p>{t("address_change_desc1")}</p>
            <h4>5. {t("packaging_shipping_insurance")}</h4>
            <p>{t("packaging_shipping_insurance_desc")}</p>
            <h4>6. {t("damaged_artwork")}</h4>
            <p>{t("damaged_artwork_desc")} </p>
            <h4>7. {t("return_shipping_resp")}</h4>
            <p>{t("return_shipping_resp_desc")}</p>
            <h4>8. {t("shipping_restrictions")}</h4>
            <p>{t("shipping_restrictions_desc1")} </p>
            <p>{t("shipping_restrictions_desc2")}</p>
            <h4>9. {t("order_status_customer")}</h4>
            <p>{t("order_status_customer_desc")}</p>
            
            </div>
            </Container>
            </div>
          </div>
          
        ))}
      </div>
    </ThemeProvider>
  );
}

export default ShippingPolicy;
