import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../State/api/Api";
import { setLoader } from "../../../../State/Features/common/loaderSlice";
import styles from "./CuratorAuctionDetails.module.css";
import CommondetailAuctionaArtist from "../../../../gallery/pages/exshibition/commonExshibitiongallery/details/CommondetailAuctionArtist";
import { Button } from "@mui/material";
import CommondetailAuctionaCurator from "./CommonDetailAuctionCurator";
import { handleAcceptRejectAuction } from "../../../../State/api/superadminApi";

const data1 = [
    {
     id: 1,
     title: "Auction name",
     content: "Art of Space",
    },
    {
     id: 2,
     title: "No. of items",
     content: "06",
    },
   ];
const data = [
 {
  main_title: "Art Of Space",
  content: "0",
  img: "/img/Image 1.png",
  more_detail: [
   {
    title: "Price",
    content: "$45,000",
   },
   {
    title: "Materials",
    content: "Bronze, Cardboard, Carbon FIber,Ceramic",
   },
   {
    title: "Category",
    content: "Photography",
   },
   {
    title: "Dimensions",
    content: "20 W * 20 H * 50 D in",
   },
  ],
  remark: "",
 },
 {
  main_title: "The Wonder of India",
  content: "1",
  img: "/img/Image 2.png",
  more_detail: [
   {
    title: "Price",
    content: "$45,000",
   },
   {
    title: "Materials",
    content: "Bronze, Cardboard, Carbon FIber,Ceramic",
   },
   {
    title: "Category",
    content: "Photography",
   },
   {
    title: "Dimensions",
    content: "20 W * 20 H * 50 D in",
   },
  ],
  remark: "Remarks*  Lorem ip dolor sit amet corcresdac",
 },
 {
  main_title: "Way Of Seeing",
  content: "0",
  img: "/img/Image 3.png",
  more_detail: [
   {
    title: "Price",
    content: "$45,000",
   },
   {
    title: "Materials",
    content: "Bronze, Cardboard, Carbon FIber,Ceramic",
   },
   {
    title: "Category",
    content: "Photography",
   },
   {
    title: "Dimensions",
    content: "20 W * 20 H * 50 D in",
   },
  ],
  remark: "",
 },
];
export default function CuratorAuctionDetails() {
 const [auctionProfile, setAuctionProfile] = useState(null);
 console.log(auctionProfile,'auctionProfile')
 const { id } = useParams();
 const dispatch = useDispatch();

 const handleFetchAuctionDetail = async function () {
  dispatch(setLoader(true));
  try {
   const result = await api.applicationApi(`api/auction/fetchAuctionArtDetails/${id}`,'POST');
   if (result && result.status === 200) {
    setAuctionProfile(result.data);
    dispatch(setLoader(false));
   } else if (result.status === 401) {
   }
  } catch (err) {
  }
 };
   const handleAccept = (id) => {
     dispatch(
        handleAcceptRejectAuction(id, { is_accepted: "Approved" }, () => dispatch(handleFetchAuctionDetail(id)))
     );
   };
   
   const handleReject = (id) => {
     dispatch(
        handleAcceptRejectAuction(id, { is_accepted: "Rejected" }, () => dispatch(handleFetchAuctionDetail(id)))
     );
   };
 useEffect(() => {
  handleFetchAuctionDetail();
 }, []);

    
 return (
  <div className={styles.mains}>
    
   <CommondetailAuctionaCurator type="auction_gallery" data1={data1} data={auctionProfile} 
   handleAccept={handleAccept} handleReject={handleReject} />
   
     
  </div>
  
 );
}
