import validator from "validator";
import isEmpty from "./isEmpty";

export const firstFormArtValidation = (
name,
image,
artist_name
) => {
  const isFile = file => file instanceof File;
  
  let firstFormErrors = {};
   artist_name = artist_name ? artist_name : "";
   if(validator.isEmpty(name)) {
    firstFormErrors.title = "Title is required";
   }
  if(localStorage.getItem("usertype") === "gallery") {
    if(validator.isEmpty(artist_name)) {
      firstFormErrors.artist_name = "Artist name is required";
     }
  }
  // Validate ID proof file

  // Validate ID proof file
        if(window.location.pathname === "/edit/stepfirst") {
          if(typeof image !== "string") {
            if (!isFile(image) || !image.name) {
              firstFormErrors.image = "image is required";
            }
          }
        }else {
          if (!isFile(image) || !image.name) {
            firstFormErrors.image = "image is required";
          }
        }
  


  return {
    firstFormErrors,
    isValidFirstForm: isEmpty(firstFormErrors),
  };
};
