import React, { useEffect, useState } from "react"
import styles from "./Print.module.css"
import Moreoption from "../Table/Moreoption/Moreoption"
import Tableheader from "../Table/Tableheader/Tableheader"

import Pagination from "../Pagination/Pagination"
import { FormControl, MenuItem, Select } from "@mui/material"
import { useParams } from "react-router-dom"
import { AlertsSuccess, Errornotify } from "../../../../common/alert/Alert"
import { useDispatch } from "react-redux"
import api from "../../../../State/api/Api"
import { useCallback } from "react"
import TwoColTable from "../Table/Twocol/TwoColTable"
import Enterdetails from "../Popupdelete/enterDetails/Enterdetails"
import {
  deletePrintMaterial,
  deletePrintProduct,
  editedPrintMaterial,
  editedPrintProduct,
  fetchPrintMaterialData,
  fetchPrintProductData,
  handleAddPrintMaterial,
  handleAddPrintProduct,
} from "../../../../State/api/superadminApi"

const moreOptions = [
  {
    id: "material",
    label: "Material",
    value: "material",
  },
  {
    id: "product",
    label: "Product",
    value: "product",
  },
  {
    id: "productprice",
    label: "Product Price",
    value: "productprice",
  },
]

const materialhead = [
  {
    id: "id ",
    label: "Material ID",
  },
  {
    id: "date ",
    label: "Created Date",
  },
  {
    id: "name",
    label: "Name",
  },
]
const producthead = [
  {
    id: "id ",
    label: "Product ID",
  },
  {
    id: "name",
    label: "Name",
  },
]

const sortdata = [
  { label: "Oldest to Newest", value: "low" },
  { label: "Newest to Oldest", value: "high" },
]
const filterdata = [
  { label: "Name (A-Z)", value: "name-asc" },
  { label: "Name (Z-A)", value: "name-desc" },
]
export default function Print() {
  const [activetab, setAcitvetab] = useState("material")
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [button, setButton] = useState({ type: "add", text: "Add" })
  const [editdata, setEditdata] = useState()
  const [materialData, setMaterialData] = useState(null)
  console.log(materialData, "materialdata--print")
  const [productData, setProductData] = useState(null)

  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })
  const dispatch = useDispatch()

  const handleMorecategories = (e) => {
    setAcitvetab(e)
  }

  const fetchMaterialData = () => {
    dispatch(fetchPrintMaterialData(setMaterialData))
  }
  useEffect(() => {
    fetchMaterialData()
  }, [])

  const handleEditdata = (dt) => {
    setEdit(true)
    setEditdata(dt)
  }
  const handleAdd = (dt) => {
    dispatch(handleAddPrintMaterial(dt, setOpen, fetchMaterialData))
  }
  const handleEdit = (dt) => {
    dispatch(editedPrintMaterial(dt._id, dt, setEdit, fetchMaterialData))
  }
  const handleDelete = (id) => {
    dispatch(deletePrintMaterial(id, fetchMaterialData))
  }
  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })   
  }

  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }
  const handleSorting = (e) => {
    let value = e.target.value
   setCondition({ ...condition, sorting: value })
  }
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Enterdetails
          handleAdd={handleAdd}
          poptype="print"
          open={open}
          setOpen={setOpen}
        />
      ) : (
        ""
      )}
      {edit ? (
        <Enterdetails
          handleEditMaterial={handleEdit}
          poptype="print"
          open={edit}
          editdata={editdata}
          setOpen={setEdit}
        />
      ) : (
        ""
      )}
      <div className={styles.component}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          setOpen={setOpen}
          handleSearch={handleSearch}
          button={button}
        />
        {/* {activetab === "print" ? (
          <> */}
        {/* <div className={styles.moreoption_div}>
          <Moreoption
            activetab={activetab}
            moreOptions={moreOptions}
            setButton={setButton}
            handleMorecategories={handleMorecategories}
          />
        </div> */}
        {/* {activetab === "material" ? ( */}
        <TwoColTable
          handleEditdata={handleEditdata}
          activetab={activetab}
          handleDelete={handleDelete}
          head={materialhead}
          data={
            materialData &&
            [...materialData.data]
              .filter((val) =>
                val.name.toLowerCase().includes(condition.search.toLowerCase())
              )
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .sort((a,b)=>{
                if(condition.sorting === "high"){
                  return new Date(b.createdAt) - new Date(a.createdAt);
                }else if (condition.sorting === "low"){
                  return new Date(a.createdAt) - new Date(b.createdAt); // Sort by oldest
                }
                return 0; // Default case: no sorting
              })
              .sort((a,b)=>{
                if(condition.filter === "name-asc"){
                  return a.name.localeCompare(b.name, undefined, {
                    sensitivity: "base",
                  });
                }else if(condition.filter === "name-desc"){
                  return b.name.localeCompare(a.name, undefined, {
                    sensitivity: "base",
                  });
                }
                return 0;
              })
          }
        />
        {/* ) 
        : activetab === "product" ? (
          <TwoColTable
            handleEditdata={handleEditdata}
            activetab={activetab}
            handleDelete={handleDelete}
            head={producthead}
            data={productData && productData.data}
          />
        ) : (
          ""
        )} */}
        {/* <Pagination /> */}
      </div>
    </div>
  )
}
