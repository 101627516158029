import React, { useState, useRef, useEffect } from "react";
// import Navbar from "../Navbar/Navbar"
// import Header from "../Header/Header"
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
// import Footer from "../Footer/Footer";
import styles from "./Partners.module.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';

const partners = [
  {
    id: 1,
    src: "/img/elements_interior.jpg",
  },
  {
    id: 2,
    src: "/img/alps_star_electromechanical.jpg",
  },
  {
    id: 3,
    src: "/img/ck_architecture_Interiors.jpg",
  },
  {
    id: 4,
    src: "/img/couture_luxury_interior.jpg",
  },
  {
    id: 5,
    src: "/img/elenora_interior_design_works.jpg",
  },
  {
    id: 6,
    src: "/img/emkay-group.jpg",
  },
  {
    id: 7,
    src: "/img/evalai_design.jpg",
  },
  {
    id:8,
    src:"/img/exceptional.jpg",
  },
  {
    id:9,
    src:"/img/fdc_interiors.jpg",
  },
  {
    id:10,
    src:"/img/hemlock-technical.jpg",
  },
  {
    id:11,
    src:"/img/ida_interior_design.jpg",
  },
  {
    id:12,
    src:"/img/ideal-fitout-decoration.jpg",
  },
  {
    id:13,
    src:"/img/luxland-landscaping.jpg",
  },
  {
    id:14,
    src:"/img/lxa_dubai.jpg",
  },
  {
    id:15,
    src:"/img/nova_luxury.jpg",
  },
  {
    id:16,
    src:"/img/ready_room.jpg",
  },
  {
    id:17,
    src:"/img/slate_interiors.jpg",
  },
  {
    id:18,
    src:"/img/smh-interior.jpg",
  },
  {
    id:19,
    src:"/img/spacewell_interior.jpg",
  },
  {
    id:20,
    src:"/img/spazio.jpg",
  },
  {
    id:21,
    src:"/img/yana-svetlova.jpg",
  },
  {
    id:22,
    src:"/img/ainasr_contracting.jpg",
  },
  {
    id:23,
    src:"/img/ali_sons_sontracting.jpg",
  },
  {
    id:24,
    src:"/img/beama_contractin.jpg",
  },
  {
    id:25,
    src:"/img/gif_building_maintenance.jpg",
  },
  {
    id:26,
    src:"/img/grankraft_fito_out_construction.jpg",
  },
  {
    id:27,
    src:"/img/maison-dien.jpg",
  },
  {
    id:28,
    src:"/img/sobha_constructions.jpg",
  },
  {
    id:29,
    src:"/img/tornado_total_landscape.jpg",
  },
  {
    id:30,
    src:"/img/ckp_middle.jpg",
  },
  {
    id:31,
    src:"/img/double_tree.jpg",
  },
  {
    id:32,
    src:"/img/dubai_marine_beach_resort.jpg",
  },
  {
    id:33,
    src:"/img/dubai_opera.jpg",
  },
  {
    id:34,
    src:"/img/grand-hyatt.jpg",
  },
  {
    id:35,
    src:"/img/josette-restaurant.jpg",
  },
  {
    id:36,
    src:"/img/khalidia_palace_hotel.jpg",
  },
  {
    id:37,
    src:"/img/marriott-resort-palm-jumeriah.jpg",
  },
  {
    id:38,
    src:"/img/miral_destinations.jpg",
  },
  {
    id:39,
    src:"/img/rixos_bab.jpg",
  },
  {
    id:40,
    src:"/img/rsi_restaurants.jpg",
  },
  {
    id:41,
    src:"/img/soluna_beach.jpg",
  },
  {
    id:42,
    src:"/img/ai_bawardy.jpg",
  },
  {
    id:43,
    src:"/img/conatural_beauty.jpg",
  },
  {
    id:44,
    src:"/img/east_coast_furnitur.jpg",
  },
  {
    id:45,
    src:"/img/hameem-technologies.jpg",
  },
  {
    id:46,
    src:"/img/la-soiree-perfum.jpg",
  },
  {
    id:47,
    src:"/img/moet.jpg",
  },
  {
    id:48,
    src:"/img/urban_space.jpg",
  },
  {
    id:49,
    src:"/img/nmc_hospital.jpg",
  },
  {
    id:50,
    src:"/img/azizi_development.jpg",
  },
  {
    id:51,
    src:"/img/chesterton_international.jpg",
  },
  {
    id:52,
    src:"/img/alpago_properties.jpg",
  },
  {
    id:53,
    src:"/img/island_falcon_property_management.jpg",
  },
  {
    id:54,
    src:"/img/dubai_municipality.jpg",
  },
  {
    id:55,
    src:"/img/rafael_defense_systems.jpg",
  },
  {
    id:56,
    src:"/img/emirates_schools.jpg",
  },
  {
    id:57,
    src:"/img/healthmark_training.jpg",
  },
  {
    id:58,
    src:"/img/yeoworkshop.jpg",
  },
  {
    id:59,
    src:"/img/aircarbon.jpg",
  },
  {
    id:60,
    src:"/img/ai-tayer.jpg",
  },
  {
    id:61,
    src:"/img/apu_investment.jpg",
  },
  {
    id:62,
    src:"/img/assent_steel.jpg",
  },
  {
    id:63,
    src:"/img/carzilla.jpg",
  },
  {
    id:64,
    src:"/img/clifford_chance.jpg",
  },
  {
    id:65,
    src:"/img/diamond_developers.jpg",
  },
  {
    id:66,
    src:"/img/drawdeck.jpg",
  },
  {
    id:67,
    src:"/img/first_motors.jpg",
  },
  {
    id:68,
    src:"/img/inspiratus_consulting.jpg",
  },
  {
    id:69,
    src:"/img/priory_healthcare.jpg",
  },
  {
    id:70,
    src:"/img/silverwell_energy.jpg",
  },
  {
    id:71,
    src:"/img/sunreef_yachts.jpg",
  },
  {
    id:72,
    src:"/img/synergy_capital.jpg",
  },
  {
    id:73,
    src:"/img/vip-motos.jpg",
  },
  {
    id:74,
    src:"/img/zidou.jpg",
  },
  {
    id:75,
    src:"/img/beehive.jpg",
  },
  {
    id:76,
    src:"/img/wonderfulgroupof-companie.jpg",
  }
]

function Partners() {
  
  return (
    <>
       
      {/* <Header />
            <Navbar /> */}
      <React.Fragment>
        <CssBaseline />
        <section className={styles.hero_main}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.pageitle} ${styles.max_width}`}>
              <h1>Our Clients</h1>
            </div>
</Container>
        </section>
        <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Interior fit-out</h3>
          </div>
          <div className={styles.trending_content_div}>
          <Swiper
  modules={[Navigation, Pagination, Autoplay]} // Include Autoplay module
  slidesPerView="auto" // Enable auto width for continuous scrolling
  spaceBetween={15}
  loop={true} // Number of slides to loop seamlessly
  speed={1000} // Adjust the speed for the marquee effect
  autoplay={{
    delay: 0, // Continuous autoplay with no delay
    disableOnInteraction: false, // Allow autoplay after interaction
  }}
  breakpoints={{
    480: {
      slidesPerView: '2',
      spaceBetween: 10,
    },
    768: {
      slidesPerView: '3',
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: '5',
      spaceBetween: 15,
    },
  }}
>
  {/* Example Swiper slides */}
  {partners.slice(0, 21).map((val, index) => (
    <SwiperSlide key={index} style={{ width: 'auto' }}>
      <div className={styles.partners_img_div}>
        <img src={val.src} className={styles.img_fluid} alt="partner logo" />
      </div>
    </SwiperSlide>
  ))}
</Swiper>
          </div>
          </Container>
          </section>
          <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}> Construction</h3>
          </div>
          <div className={styles.trending_content_div}>
          <Swiper
  modules={[Navigation, Pagination, Autoplay]} // Include Autoplay module
  slidesPerView="auto" // Enable auto width for continuous scrolling
  spaceBetween={15}
  loop={true} // Number of slides to loop seamlessly
  speed={1000} // Adjust the speed for the marquee effect
  autoplay={{
    delay: 0, // Continuous autoplay with no delay
    disableOnInteraction: false, // Allow autoplay after interaction
  }}
  breakpoints={{
    480: {
      slidesPerView: '2',
      spaceBetween: 10,
    },
    768: {
      slidesPerView: '3',
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: '5',
      spaceBetween: 15,
    },
  }}
>
  {/* Example Swiper slides */}
  {partners.slice(21, 29).map((val, index) => (
    <SwiperSlide key={index} style={{ width: 'auto' }}>
      <div className={styles.partners_img_div}>
        <img src={val.src} className={styles.img_fluid} alt="partner logo" />
      </div>
    </SwiperSlide>
  ))}
</Swiper>
          </div>
          </Container>
          </section>
          <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}> Hospitalty </h3>
          </div>
          <div className={styles.trending_content_div}>
          <Swiper
  modules={[Navigation, Pagination, Autoplay]} // Include Autoplay module
  slidesPerView="auto" // Enable auto width for continuous scrolling
  spaceBetween={15}
  loop={true} // Number of slides to loop seamlessly
  speed={1000} // Adjust the speed for the marquee effect
  autoplay={{
    delay: 0, // Continuous autoplay with no delay
    disableOnInteraction: false, // Allow autoplay after interaction
  }}
  breakpoints={{
    480: {
      slidesPerView: '2',
      spaceBetween: 10,
    },
    768: {
      slidesPerView: '3',
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: '5',
      spaceBetween: 15,
    },
  }}
>
  {/* Example Swiper slides */}
  {partners.slice(29, 41).map((val, index) => (
    <SwiperSlide key={index} style={{ width: 'auto' }}>
      <div className={styles.partners_img_div}>
        <img src={val.src} className={styles.img_fluid} alt="partner logo" />
      </div>
    </SwiperSlide>
  ))}
</Swiper>
          </div>
          </Container>
          </section>
          <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}> Retail </h3>
          </div>
          <div className={styles.trending_content_div}>
          <Swiper
  modules={[Navigation, Pagination, Autoplay]} // Include Autoplay module
  slidesPerView="auto" // Enable auto width for continuous scrolling
  spaceBetween={15}
  loop={true} // Number of slides to loop seamlessly
  speed={1000} // Adjust the speed for the marquee effect
  autoplay={{
    delay: 0, // Continuous autoplay with no delay
    disableOnInteraction: false, // Allow autoplay after interaction
  }}
  breakpoints={{
    480: {
      slidesPerView: '2',
      spaceBetween: 10,
    },
    768: {
      slidesPerView: '3',
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: '5',
      spaceBetween: 15,
    },
  }}
>
  {/* Example Swiper slides */}
  {partners.slice(40, 48).map((val, index) => (
    <SwiperSlide key={index} style={{ width: 'auto' }}>
      <div className={styles.partners_img_div}>
        <img src={val.src} className={styles.img_fluid} alt="partner logo" />
      </div>
    </SwiperSlide>
  ))}
</Swiper>
          </div>
          </Container>
          </section>
          <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}> Hospital </h3>
          </div>
          <div className={`ourclient ${styles.trending_content_div}`}>
          <Swiper
  modules={[Navigation, Pagination, Autoplay]} // Include Autoplay module
  slidesPerView="auto" // Enable auto width for continuous scrolling
  spaceBetween={15}
  loop={false} // Number of slides to loop seamlessly
  speed={1000} // Adjust the speed for the marquee effect
  autoplay={{
    delay: 0, // Continuous autoplay with no delay
    disableOnInteraction: false, // Allow autoplay after interaction
  }}
  breakpoints={{
    480: {
      slidesPerView: '2',
      spaceBetween: 10,
    },
    768: {
      slidesPerView: '3',
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: '5',
      spaceBetween: 15,
    },
  }}
>
  {/* Example Swiper slides */}
  {partners.slice(48, 49).map((val, index) => (
    <SwiperSlide key={index} style={{ width: 'auto' }}>
      <div className={styles.partners_img_div}>
        <img src={val.src} className={styles.img_fluid} alt="partner logo" />
      </div>
    </SwiperSlide>
  ))}
</Swiper>
          </div>
          </Container>
          </section>
          <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}> Real Estate </h3>
          </div>
          <div className={`ourclient ${styles.trending_content_div}`}>
          <Swiper
  modules={[Navigation, Pagination, Autoplay]} // Include Autoplay module
  slidesPerView="auto" // Enable auto width for continuous scrolling
  spaceBetween={15}
  loop={false} // Number of slides to loop seamlessly
  speed={1000} // Adjust the speed for the marquee effect
  autoplay={{
    delay: 0, // Continuous autoplay with no delay
    disableOnInteraction: false, // Allow autoplay after interaction
  }}
  breakpoints={{
    480: {
      slidesPerView: '2',
      spaceBetween: 10,
    },
    768: {
      slidesPerView: '3',
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: '5',
      spaceBetween: 15,
    },
  }}
>
  {/* Example Swiper slides */}
  {partners.slice(49, 51).map((val, index) => (
    <SwiperSlide key={index} style={{ width: 'auto' }}>
      <div className={styles.partners_img_div}>
        <img src={val.src} className={styles.img_fluid} alt="partner logo" />
      </div>
    </SwiperSlide>
  ))}
</Swiper>
          </div>
          </Container>
          </section>
          <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}> Property Management </h3>
          </div>
          <div className={`ourclient ${styles.trending_content_div}`}>
          <Swiper
  modules={[Navigation, Pagination, Autoplay]} // Include Autoplay module
  slidesPerView="auto" // Enable auto width for continuous scrolling
  spaceBetween={15}
  loop={false} // Number of slides to loop seamlessly
  speed={1000} // Adjust the speed for the marquee effect
  autoplay={{
    delay: 0, // Continuous autoplay with no delay
    disableOnInteraction: false, // Allow autoplay after interaction
  }}
  breakpoints={{
    480: {
      slidesPerView: '2',
      spaceBetween: 10,
    },
    768: {
      slidesPerView: '3',
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: '5',
      spaceBetween: 15,
    },
  }}
>
  {/* Example Swiper slides */}
  {partners.slice(51, 53).map((val, index) => (
    <SwiperSlide key={index} style={{ width: 'auto' }}>
      <div className={styles.partners_img_div}>
        <img src={val.src} className={styles.img_fluid} alt="partner logo" />
      </div>
    </SwiperSlide>
  ))}
</Swiper>
          </div>
          </Container>
          </section>
          <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}> Goverment  </h3>
          </div>
          <div className={`ourclient ${styles.trending_content_div}`}>
          <Swiper
  modules={[Navigation, Pagination, Autoplay]} // Include Autoplay module
  slidesPerView="auto" // Enable auto width for continuous scrolling
  spaceBetween={15}
  loop={false} // Number of slides to loop seamlessly
  speed={1000} // Adjust the speed for the marquee effect
  autoplay={{
    delay: 0, // Continuous autoplay with no delay
    disableOnInteraction: false, // Allow autoplay after interaction
  }}
  breakpoints={{
    480: {
      slidesPerView: '2',
      spaceBetween: 10,
    },
    768: {
      slidesPerView: '3',
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: '5',
      spaceBetween: 15,
    },
  }}
>
  {/* Example Swiper slides */}
  {partners.slice(53, 55).map((val, index) => (
    <SwiperSlide key={index} style={{ width: 'auto' }}>
      <div className={styles.partners_img_div}>
        <img src={val.src} className={styles.img_fluid} alt="partner logo" />
      </div>
    </SwiperSlide>
  ))}
</Swiper>
          </div>
          </Container>
          </section>
          <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}> Institutions  </h3>
          </div>
          <div className={`ourclient ${styles.trending_content_div}`}>
          <Swiper
  modules={[Navigation, Pagination, Autoplay]} // Include Autoplay module
  slidesPerView="auto" // Enable auto width for continuous scrolling
  spaceBetween={15}
  loop={false} // Number of slides to loop seamlessly
  speed={1000} // Adjust the speed for the marquee effect
  autoplay={{
    delay: 0, // Continuous autoplay with no delay
    disableOnInteraction: false, // Allow autoplay after interaction
  }}
  breakpoints={{
    480: {
      slidesPerView: '2',
      spaceBetween: 10,
    },
    768: {
      slidesPerView: '3',
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: '5',
      spaceBetween: 15,
    },
  }}
>
  {/* Example Swiper slides */}
  {partners.slice(55, 57).map((val, index) => (
    <SwiperSlide key={index} style={{ width: 'auto' }}>
      <div className={styles.partners_img_div}>
        <img src={val.src} className={styles.img_fluid} alt="partner logo" />
      </div>
    </SwiperSlide>
  ))}
</Swiper>
          </div>
          </Container>
          </section>
          <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}> Art Company   </h3>
          </div>
          <div className={`ourclient ${styles.trending_content_div}`}>
          <Swiper
  modules={[Navigation, Pagination, Autoplay]} // Include Autoplay module
  slidesPerView="auto" // Enable auto width for continuous scrolling
  spaceBetween={15}
  loop={false} // Number of slides to loop seamlessly
  speed={1000} // Adjust the speed for the marquee effect
  autoplay={{
    delay: 0, // Continuous autoplay with no delay
    disableOnInteraction: false, // Allow autoplay after interaction
  }}
  breakpoints={{
    480: {
      slidesPerView: '2',
      spaceBetween: 10,
    },
    768: {
      slidesPerView: '3',
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: '5',
      spaceBetween: 15,
    },
  }}
>
  {/* Example Swiper slides */}
  {partners.slice(57, 58).map((val, index) => (
    <SwiperSlide key={index} style={{ width: 'auto' }}>
      <div className={styles.partners_img_div}>
        <img src={val.src} className={styles.img_fluid} alt="partner logo" />
      </div>
    </SwiperSlide>
  ))}
</Swiper>
          </div>
          </Container>
          </section>
          <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}> Others  </h3>
          </div>
          <div className={styles.trending_content_div}>
          <Swiper
  modules={[Navigation, Pagination, Autoplay]} // Include Autoplay module
  slidesPerView="auto" // Enable auto width for continuous scrolling
  spaceBetween={15}
  loop={true} // Number of slides to loop seamlessly
  speed={1000} // Adjust the speed for the marquee effect
  autoplay={{
    delay: 0, // Continuous autoplay with no delay
    disableOnInteraction: false, // Allow autoplay after interaction
  }}
  breakpoints={{
    480: {
      slidesPerView: '2',
      spaceBetween: 10,
    },
    768: {
      slidesPerView: '3',
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: '5',
      spaceBetween: 15,
    },
  }}
>
  {/* Example Swiper slides */}
  {partners.slice(58, 74).map((val, index) => (
    <SwiperSlide key={index} style={{ width: 'auto' }}>
      <div className={styles.partners_img_div}>
        <img src={val.src} className={styles.img_fluid} alt="partner logo" />
      </div>
    </SwiperSlide>
  ))}
</Swiper>
          </div>
          </Container>
          </section>
          <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}> Fintech  </h3>
          </div>
          <div className={`ourclient ${styles.trending_content_div}`}>
          <Swiper
  modules={[Navigation, Pagination, Autoplay]} // Include Autoplay module
  slidesPerView="auto" // Enable auto width for continuous scrolling
  spaceBetween={15}
  loop={false} // Number of slides to loop seamlessly
  speed={1000} // Adjust the speed for the marquee effect
  autoplay={{
    delay: 0, // Continuous autoplay with no delay
    disableOnInteraction: false, // Allow autoplay after interaction
  }}
  breakpoints={{
    480: {
      slidesPerView: '2',
      spaceBetween: 10,
    },
    768: {
      slidesPerView: '3',
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: '5',
      spaceBetween: 15,
    },
  }}
>
  {/* Example Swiper slides */}
  {partners.slice(74, 76).map((val, index) => (
    <SwiperSlide key={index} style={{ width: 'auto' }}>
      <div className={styles.partners_img_div}>
        <img src={val.src} className={styles.img_fluid} alt="partner logo" />
      </div>
    </SwiperSlide>
  ))}
</Swiper>
          </div>
          </Container>
          </section>
      </React.Fragment>
      {/* <Footer /> */}
    </>
  );
}
export default Partners;
