import {
  Autocomplete,
  Box,
  Button,
  Container,
  createTheme,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  Menu,
  MenuItem,
  Modal,
  Select,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import "fontsource-marcellus";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ProdDetsOther from "./ProdDetsOther";
import ProdDetsqna from "./ProdDetsqna";
import PolicySidebar from "./PolicySidebar";
import {  RiCloseCircleFill} from "react-icons/ri";

import styles from "./PrintPage.module.css";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import homeImage from "../../../constant";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../State/api/Api";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import { useNavigate, useParams } from "react-router-dom";
import CircularLoader from "../../../common/loader/Loader";
import serverimage from "../../../serverConstant";
import {
  handelAddtocart,
  handelAddWishlist,
  handleLikesViews,
  handleRequestInquiry,
  handleRequestRecreate,
  handleUpdateLikesViewsFav,
} from "../../../State/api/userApi";
import { AlertsSuccess, Errornotify } from "../../../common/alert/Alert";
import { ConstructionOutlined } from "@mui/icons-material";
import ChooseFrameModal from "./ChooseFrameModal";
import { Helmet } from "react-helmet";
import { RiStarFill} from "react-icons/ri";
import {FiHeart, FiShare2, FiEye, FiThumbsUp } from "react-icons/fi";
import {
  fetchFrameCountry,
  fetchPrintMaterialData,
  handleFetchMaterial,
} from "../../../State/api/superadminApi";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import country from "../../../Country";
import {
  formatNewCurrency,
  initializeCurrencyFormatter,
} from "../../../utils/currencyFormat";
import classNames from "classnames";
import { validationForRecreate } from "../../../validation/validationForRecreate";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon } from "react-share";
import { validationForInquiry } from "../../../validation/validationForInquiry";

function PrintPage() {
  const [productdetails, setProductdetails] = useState(null);
  const [availablity, setAvailablity] = useState(null);
  const [material, setMaterial] = useState("");
  const [metal_thikness, setMetalThikness] = useState("");
  const [product, setProduct] = useState("");
  console.log(product,'product')
  const [size, setSize] = useState({ height: 1, width: 1 });
  const [printOption, setPrintOption] = useState({
    material: null,
    print_product: null,
  });
  // const [materials, setMaterials] = useState()
  const [optionSelected, setOptionSelected] = useState({
    material: "none",
    size: "none",
    price: { print_product: "none", price: 1 },
  });
  const allitemdt = useSelector((state) => state.allitemData);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [frame, setFrame] = React.useState("frame");

  const userty = useSelector((state) => state.user.data);
  const myCart = useSelector((state) => state.mycartData.data);
  const userartists = useSelector((state) => state.userartistData.data);
  const wishlist = useSelector((state) => state.wishlistData);
  const orderTypeInCart = myCart && myCart?.result?.map((item) => item?.orderType_id?.name);
  const printId = productdetails?.orderTypes?.filter(
    (val) => val.name === "Print"
  )[0]?._id;

  const artist_id =
    productdetails &&
    productdetails.artwork.map((vl) => {
      return vl.artist_id && vl.artist_id._id;
    });
  var priceObject =
    optionSelected.price.price !== 1 &&
    printOption.print_product &&
    printOption.print_product.data
      .filter((val) => val._id === optionSelected.price.price)
      .map((val) => val);

  // const calculated_price_print =
  //   (priceObject && priceObject.length !== 0 && priceObject[0].price) *
  //   (optionSelected.size &&
  //     (optionSelected.size.height * optionSelected.size.width) / 10000);

  const getProductOptions = () => {
    switch (material) {
      case "canvas":
        return [
          { value: "canvas print", label: "Canvas Print" },
          {
            value: "canvas print and stretch",
            label: "Stretch Print & Stretched",
          },
          {
            value: "canvas print with stretching and framing",
            label: "Canvas Print With Stretching & Framing",
          },
        ];
      case "fine art paper":
        return [
          { value: "fine art print", label: "Fine Art Print" },
          // { value: "mounting", label: "Mounting" },
          {
            value: "fine art print with framing",
            label: "Fine Art Print With Framing",
          },
        ];
      case "photo paper":
        return [
          { value: "photo paper print", label: "Photo Paper Print" },
          // { value: "mounting", label: "Mounting" },
          {
            value: "photo paper print with framing",
            label: "Photo Paper Print With Framing",
          },
        ];
      case "poster":
        return [
          { value: "poster", label: "Poster" },
          // { value: "mounting", label: "Mounting" },
          { value: "poster with framing", label: "Poster With Framing" },
        ];
      case "metal print 1.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 2.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 3.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 2 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 4 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 6 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 10 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];

      default:
        return [];
    }
  };

  const getProductOptionsMetalPrint = () => {
    switch (metal_thikness) {
      case "metal print 1.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 2.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 3.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 2 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 4 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 6 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];
      case "metal print 10 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ];

      default:
        return [];
    }
  };
  const calculated_price_others =
    (optionSelected.size &&
      (optionSelected.size.height + optionSelected.size.width) / 100) *
    2 *
    optionSelected.price.price;
  // *
  // (priceObject && priceObject.length !== 0 && priceObject[0].price);

  const handleChange = (event) => {
    const value = event.target.value;

    if (value === "none") {
      setOptionSelected({ ...optionSelected, material: value });
    } else {
      const handleFetchItemPrice = async function () {
        try {
          const result = await api.applicationApi(
            `api/user/fetchPrintProductPrices`,
            "POST",
            {
              print_material_id: value,
            }
          );
          if (result && result.status === 200) {
            setPrintOption({ ...printOption, print_product: result.data.data });
            setOptionSelected({
              ...optionSelected,
              material: value,
            });
            dispatch(setLoader(false));
          } else if (result.status === 401) {
          }
        } catch (err) {
        }
      };
      handleFetchItemPrice();
    }
  };
  const handleChangeProduct = (e) => {
    const value = e.target.value;
    if (value === 1) {
      setOptionSelected({ ...optionSelected, price: value, size: "none" });
    } else {
      setOptionSelected({ ...optionSelected, price: value });
    }
  };
  const handleChangeSize = (e) => {
    const value = e.target.value;
    setOptionSelected({ ...optionSelected, size: value });
  };
  const [isFrameSelected, setIsFrameSelected] = useState(false);
  const [frameModal, setFrameModal] = useState(false);
  const [printPricingResponse, setPrintPricingResponse] = useState({});
  const [frameCountry, setFrameCountry] = useState(null);
  const [frameModalsecond, setFrameModalsecond] = useState(false);
  const [frameData, setFrameData] = useState({
    thickness_id: "",
    finishtouch_id: "",
    framing_id: "",
  });
  const handleClickChooseFrame = () => {
    if (frameData.finishtouch_id === "") {
      Errornotify("Please select any finishing touch");
    } else {
      setFrameModal(false);
      setFrameModalsecond(true);
    }
  };
  const handleBackbutton = () => {
    setFrameModal(true);
    setFrameModalsecond(false);
    setFrameData({ thickness_id: "", finishtouch_id: "", framing_id: "" });
  };
  const handleChangeFrame = (event) => {
    setFrame(event.target.value);
  };
  const theme = createTheme({
    palette: {
      primary: {
        light: "#C1C1C1",
        main: "#000000",
        dark: "#C1C1C1",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ffffff",
        main: "#C1C1C1",
        dark: "#ffffff",
        contrastText: "#000",
      },
    },
    typography: {
      prodheading: {
        fontFamily: "Marcellus",
        fontSize: "2.5rem",
      },
    },
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseShareIcons = () => {
    setAnchorEl(null);
  };
  const handleFetchItem = async function () {
    dispatch(setLoader(true));
    try {
      // const result = await api.applicationApi(`api/itemMaterial/fetchItemMaterials`, 'POST', data);
      const result = await api.applicationApi(
        `api/user/fetchPrintMaterials`,
        "GET"
      );

      //  const response = await api.applicationApi("api/user/fetchItemSizes", "POST");
      if (result && result.status === 200) {
        setPrintOption({
          ...printOption,
          material: result && result.data.data,
          //  size: response && response.data.result,
        });
        // setOptionSelected({ ...optionSelected, size: response && response.data.result[0]._id });
        // setPrintOption({ ...printOption, size: response && response.data.data });
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };
  const handleFetchAllitem = async function () {
    dispatch(setLoader(true));
    try {
      // const result = await api.applicationApi('api/user/getoffers')
      const result = await api.applicationApi(`api/user/allitems/${id}`);
      if (result && result.status === 200) {
        setProductdetails(result.data);
        result.data.artwork.forEach((element) => {
          // dispatch(handleFetchMaterial({ category_id: element.category_id._id,setPrintOption }));
          // handleFetchItem({ category_id: element.category_id._id })
        });
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };
  const checkFrameCountry = (e, newValue) => {
    const availabl = frameCountry && frameCountry.includes(newValue);
    setAvailablity(availabl);
  };
  useEffect(() => {
    handleFetchAllitem();
    dispatch(fetchFrameCountry(setFrameCountry));
    handleFetchItem();
    initializeCurrencyFormatter();
    // dispatch(fetchPrintMaterialData(setMaterials))
    // handleFetchItem();

    // dispatch(handleFetchMaterial({ "category_id": productdetails.artwork.category_id._id }));
    // handleFetchItemMaterials();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [id]);
  const handleClickOriginal = () => {
    window.location.href = `/user/newestpaintings/${id}`;
  };
  // const handleAddCart = async (id,name) => {
  //   const val = id._id;
  //   const index = myCart && myCart.result.map((item) => item.item_id._id);

  //   if (userty.signed === false) {
  //     // const ress = await axios.get('https://geolocation-db.com/json/')
  //     dispatch(
  //       handelAddtocart(
  //         {
  //           item_id: val,
  //           orderType_id: printId,
  //           user_id: localStorage.getItem("user_id"),
  //           quantity: 1,
  //           IP_address: localStorage.getItem("device_id"),
  //         },
  //         { offset: 1, IP_address: localStorage.getItem("device_id") }
  //       )
  //     );
  //     // Errornotify("Please login ");
  //     // navigate("/artsmiley/login");
  //     // localStorage.setItem("buyer_back_url", location.pathname);
  //   } else {
  //    if(orderTypeInCart.includes("Buy") || orderTypeInCart.includes("Rent")) {
  //      Errornotify("You can't add both Print and Buy to the cart at the same time.");
  //    }else {
  //     if (index.includes(val) && orderTypeInCart.includes(name)) {
  //       Errornotify("Already in your basket")
  //     } else { 
  //       dispatch(
  //         handelAddtocart(
  //           {
  //             item_id: val,
  //             orderType_id: printId,
  //             user_id: localStorage.getItem("user_id"),
  //             quantity: 1,
  //             IP_address: localStorage.getItem("device_id"),
  //           },
  //           { offset: 1, IP_address: localStorage.getItem("device_id") }
  //         )
  //       );
  //     }
  //    }
   
  //   }
  // };
  const handleAddCart = async (id, name) => {
    const val = id._id;
    const index = myCart && myCart.result.map((item) => item.item_id._id);
   
    if (userty.signed === false) {
      // const ress = await axios.get('https://geolocation-db.com/json/')
      dispatch(
        handelAddtocart(
          {
            item_id: val,
            orderType_id: printId,
            user_id: localStorage.getItem("user_id"),
            quantity: 1,
            IP_address: localStorage.getItem("device_id"),
          },
          { offset: 1, IP_address: localStorage.getItem("device_id") }
        )
      );
      // Errornotify("Please login ");
      // navigate("/artsmiley/login");
      // localStorage.setItem("buyer_back_url", location.pathname);
    } else {
      const typesInCart = ["Buy", "Rent"];
      const typeInCart = typesInCart.find(type => orderTypeInCart.includes(type));
      if (typeInCart) {
        Errornotify(`You can't add both Print and ${typeInCart} to the cart at the same time.`);
      } else {
        if (index.includes(val) && orderTypeInCart.includes(name)) {
          Errornotify("Already in your basket");
        } else {
          dispatch(
            handelAddtocart(
              {
                item_id: val,
                orderType_id: printId,
                user_id: localStorage.getItem("user_id"),
                quantity: 1,
                IP_address: localStorage.getItem("device_id"),
              },
              { offset: 1, IP_address: localStorage.getItem("device_id") }
            )
          );
        }
      }
    }
  };
  const handleShare = () => {
    const data = {
      title: "Artsmilly codes",
      text: "Hello Artsmilly",
      url: "http://localhost:3000",
    };
    if (navigator.canShare && navigator.canShare(data)) {
      navigator.share(data);
    } else {
    }
  };
  const handleProducts = (ids) => {
    const path = window.location.pathname;
    if (path.includes("user/print/newestpaintings")) {
      navigate(`user/print/newestpaintings/${ids}`);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleWishlist = (id) => {
    if (userty.signed === false) {
      Errornotify("Please login ");
    } else {
      dispatch(handelAddWishlist({ item_id: id }));
    }
  };
  const handleLikeUnlike = (id, type) => {
    if (userty.signed === false) {
      Errornotify("Please login ");
    } else if (type === "like") {
      dispatch(
        handleLikesViews({ item_id: id, string: type }, handleFetchAllitem)
      );
    } else if (type === "unlike") {
      dispatch(
        handleUpdateLikesViewsFav(
          { item_id: id, string: "like" },
          handleFetchAllitem
        )
      );
    }
  };
  // const handleArtistProfile = (id) => {
  //   id.forEach((vl) => {
  //     navigate(`/user/artist/${vl._id}`);
  //   });
  // };
  const handleArtistProfile = (id) => {
    const url = `/user/artist/${id}`;
    window.location.href = url;
  }
  function reloadPage() {
    // The last "domLoading" Time //
    var currentDocumentTimestamp = new Date(
      performance.timing.domLoading
    ).getTime();
    // Current Time //
    var now = Date.now();
    // Ten Seconds //
    var tenSec = 10 * 1000;
    // Plus Ten Seconds //
    var plusTenSec = currentDocumentTimestamp + tenSec;
    if (now > plusTenSec) {
      window.location.reload();
    } else {
    }
  }

  var itmImage = "";
  productdetails &&
    productdetails.artwork.map((val) => {
      itmImage = val.image;
    });
  useEffect(() => {
    if (productdetails?.artwork) {
      // Extract and filter metal thickness values
      const metalThicknessValues = productdetails.artwork
        .flatMap((val) =>
          val?.print_by_artsmiley?.map((print) => print.metal_thikness)
        )
        .filter((thickness) => thickness !== undefined);

      // Extract and filter material values
      const materialValues = productdetails.artwork
        .flatMap((val) =>
          val?.print_by_artsmiley?.map((print) => print.material)
        )
        .filter((material) => material !== undefined);
      const materialPrintValues = productdetails.artwork
        .flatMap((val) => val?.print?.map((print) => print.material))
        .filter((material) => material !== undefined);
      // Extract and filter product prices
      const productPrices = productdetails.artwork
        .flatMap((art) =>
          art?.print_by_artsmiley?.flatMap((print) =>
            print.product_cost.map((pro) => pro.product_price)
          )
        )
        .filter((price) => price !== undefined);

      const productHeight = productdetails.artwork.flatMap(
        (art) => art.height
      )[0];
      const productWidth = productdetails.artwork.flatMap(
        (art) => art.width
      )[0];
      // Set state with the first valid values
      setMetalThikness(metalThicknessValues[0]);
      if (materialValues.length > 0) {
        setMaterial(materialValues[0]);
      }
      if (materialPrintValues.length > 0) {
        setMaterial(materialPrintValues[0]);
      }
      setSize({ ...size, height: productHeight, width: productWidth });
      // setProduct(productPrices[0]);
    }
  }, [productdetails?.artwork]);

  const handleFetchPriceByMaterialAndProduct = async function () {
    try {
      const productOptions = getProductOptions();
      const metalPrintOptions = getProductOptionsMetalPrint();
      // Step 2: Extract only the value properties
      const valuesToSend = productOptions.map((option) => option.value);
      const metalPrintValues = metalPrintOptions.map((option) => option.value);
      const result = await api.applicationApi(
        `api/printprice/getPrintPriceByMaterialAndProduct`,
        "POST",
        {
          material: material !== "metal" ? material : metal_thikness,
          product: material === "metal" ? metalPrintValues : valuesToSend,
        }
      );
      if (result && result.status === 200) {
        setPrintPricingResponse(result.data);
        // dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };
  useEffect(() => {
    if (material) {
      handleFetchPriceByMaterialAndProduct();
    }
  }, [material, metal_thikness]);

  useEffect(() => {
    const printPrice = productdetails?.artwork?.reduce((total, val) => {
      return total + (val?.print_by_artsmiley?.[0]?.print_price || 0);
    }, 0);
  
    const totalPrice = (product || 0) + printPrice;
  
    localStorage.setItem("product_prices", JSON.stringify(totalPrice));
  }, [product, productdetails]);
  
  // Use effect to listen to storage changes
  const [isFramed, setIsFramed] = useState(() => {
    const saved = localStorage.getItem("is_framed");
    return saved ? JSON.parse(saved) : null;
  });
  useEffect(() => {
    const handleStorageChange = () => {
      const saved = localStorage.getItem("is_framed");
      setIsFramed(saved ? JSON.parse(saved) : null);
    };

    // Listen to storage events
    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const calculatePrice = (height, width, price, product, basePrice) => {
    let printPrice;
    switch (product) {
      case "canvas print":
        printPrice = (((height / 100) * width) / 100) * price + basePrice;
        localStorage.setItem("canvas_print", JSON.stringify(printPrice));
        localStorage.setItem("is_framed", JSON.stringify("is_not_framed"));
        break;
      case "canvas print and stretch":
        printPrice =
          Number(JSON.parse(localStorage.getItem("canvas_print")) || 0) +
          (((height / 100) * width) / 100) * price +
          basePrice;
        localStorage.setItem("canvas_stretch", JSON.stringify(printPrice));
        sessionStorage.setItem("canvas_stretch", JSON.stringify(printPrice));
        localStorage.setItem("is_framed", JSON.stringify("is_not_framed"));
        break;
      case "canvas print with stretching and framing":
        printPrice =
          Number(JSON.parse(localStorage.getItem("canvas_stretch")) || 0) +
          (((height / 100) * width) / 100) * price +
          basePrice;
        localStorage.setItem(
          "canvas_stretch_framing",
          JSON.stringify(printPrice)
        );
        localStorage.setItem("is_framed", JSON.stringify("is_framed"));
        break;
      case "fine art print":
        printPrice = (((height / 100) * width) / 100) * price + basePrice;
        localStorage.setItem("fine_art_print", JSON.stringify(printPrice));
        localStorage.setItem("is_framed", JSON.stringify("is_not_framed"));
        break;
      case "fine art print with framing":
        printPrice =
          Number(JSON.parse(localStorage.getItem("fine_art_print")) * 2) +
          (((height / 100) * width) / 100) * price +
          basePrice;
        localStorage.setItem(
          "fine_art_print_with_framing",
          JSON.stringify(printPrice)
        );
        localStorage.setItem("is_framed", JSON.stringify("is_framed"));
        break;
      case "photo paper print":
        printPrice = (((height / 100) * width) / 100) * price + basePrice;
        localStorage.setItem("photo_paper_print", JSON.stringify(printPrice));
        localStorage.setItem("is_framed", JSON.stringify("is_not_framed"));
        break;
      case "photo paper print with framing":
        printPrice =
          Number(JSON.parse(localStorage.getItem("photo_paper_print")) * 2) +
          (((height / 100) * width) / 100) * price +
          basePrice;
        localStorage.setItem(
          "photo_paper_print_with_framing",
          JSON.stringify(printPrice)
        );
        localStorage.setItem("is_framed", JSON.stringify("is_framed"));

        break;
      case "poster":
        printPrice = (((height / 100) * width) / 100) * price + basePrice;
        localStorage.setItem("poster", JSON.stringify(printPrice));
        localStorage.setItem("is_framed", JSON.stringify("is_not_framed"));
        break;
      case "poster with framing":
        printPrice =
          Number(JSON.parse(localStorage.getItem("poster")) * 2) +
          (((height / 100) * width) / 100) * price +
          basePrice;
        localStorage.setItem("poster_with_framing", JSON.stringify(printPrice));
        localStorage.setItem("is_framed", JSON.stringify("is_framed"));
        break;
      case "metal print":
        printPrice = (((height / 100) * width) / 100) * price + basePrice;
        localStorage.setItem("metal_print", JSON.stringify(printPrice));
        localStorage.setItem("is_framed", JSON.stringify("is_not_framed"));
        break;
      case "aluminium hanging":
        printPrice =
          Number(JSON.parse(localStorage.getItem("metal_print")) * 2) +
          (((height / 100) * width) / 100) * price +
          basePrice;
        localStorage.setItem("aluminium_hangin", JSON.stringify(printPrice));
        localStorage.setItem("is_framed", JSON.stringify("is_not_framed"));
        break;
      default:
        printPrice = (((height / 100) * width) / 100) * price + basePrice;
        break;
    }

    // Ensure printPrice is a number and round to 2 decimal places
    printPrice = Number(printPrice) || 0;
    return parseFloat(printPrice.toFixed(2));
  };

  const calculationPercentage = (productType) => {
    switch (productType) {
      case "print":
        return Number(JSON.parse(localStorage.getItem("print_price")));
      case "outer frame":
        return Number(JSON.parse(localStorage.getItem("outerframe_price")));
      case "stretch":
        return Number(JSON.parse(localStorage.getItem("stretch")));
      case "mounting":
        return Number(JSON.parse(localStorage.getItem("mounting_price")));
      default:
        return 0;
    }
  };

  function generateSizes(height, width) {
    return [
      { _id: 1, width: width * 0.5 || 0, height: height * 0.5 || 0 },
      { _id: 2, width: width * 0.75 || 0, height: height * 0.75 || 0 },
      { _id: 3, width: Number(width) || 0, height: Number(height) || 0 },
      { _id: 4, width: width * 2 || 0, height: height * 2 || 0 },
      { _id: 5, width: width * 3 || 0, height: height * 3 || 0 }
    ];
  }
  const [materialName, setMaterialName] = useState("");
  const imageClassNames = `${styles.main__img} ${
    materialName === "whitecanvas" ? styles.whitecanvas : ""
  } ${materialName === "blackcanvas" ? styles.blackcanvas : ""}
 ${materialName === "noframe" ? styles.noframe : ""}
 ${materialName === "artpaperblack" ? styles.artpaperblack : ""}
 ${materialName === "artpaperwhite" ? styles.artpaperwhite : ""}
 ${materialName === "artpapernaturalwood" ? styles.artpapernaturalwood : ""}
 ${
   materialName === "artpapermetllightpewter"
     ? styles.artpapermetllightpewter
     : ""
 }
 ${
   materialName === "artpapermetldarkpewter"
     ? styles.artpapermetldarkpewter
     : ""
 }`;

  const [imageHeight, setImageHeight] = useState(null);
  const [imageWidth, setImageWidth] = useState(null);
  const [orientation, setOrientation] = useState(""); // 'vertical' or 'horizontal'
  const handleImageLoad = (event) => {
    const height = event.target.height;
    const width = event.target.width;

    setImageHeight(height);
    setImageWidth(width);

    if (height > width) {
      setOrientation("vertical");
    } else {
      setOrientation("horizontal");
    }
  }; 
  const [recreateError, setRecreateErrors] = useState({})

  const [openInquiry, setOpenInquiry] = React.useState(false);

  const handleOpenInquiry = () => setOpenInquiry(true);
  const handleCloseInquiry = () => setOpenInquiry(false);
const [inquiryRequest, setInquiryRequest] = useState({
  email: localStorage.getItem("user_email") || "",

  description: "",
  });

    const [inquiryError, setInquiryErrors] = useState({});
  
     const handleSubmitInquiry = () => {
      const { inquiryErrors, isValidInquiry } =
      validationForInquiry(inquiryRequest);
      if (isValidInquiry) {
      dispatch(handleRequestInquiry(id, inquiryRequest, setOpenInquiry));
      handleFetchAllitem();
      } else {
      setInquiryErrors(inquiryErrors);
      }
      };
  const handleChangeInquiry = (e) => {
    setInquiryRequest({ ...inquiryRequest, [e.target.name]: e.target.value });
    };

  const [recreateRequest, setRecreateRequest] = useState({
    item_id: "",
    email: "",
    message: "",
  })  
  const [openRecreate, setOpenRecreate] = React.useState(false)
  const handleSubmitRecreate = (id) => {
    const { recreateErrors, isValidRecreate } =
      validationForRecreate(recreateRequest)
    if (isValidRecreate) {
      dispatch(
        handleRequestRecreate(
          { ...recreateRequest, item_id: id },
          setOpenRecreate
        )
      )
      handleFetchAllitem()
    } else {
      setRecreateErrors(recreateErrors)
    }
  }
  const btnClass = classNames({
    [styles.verticalImage]: orientation === "vertical",
    [styles.horizontalImage]: orientation === "horizontal",
  });

  // Combine CSS module styles with dynamic class names
  const combinedClassName = classNames(styles.main__img, btnClass);
  const handleChangeRecreate = (e) => {
    setRecreateRequest({ ...recreateRequest, [e.target.name]: e.target.value })
  }
  return (
    <ThemeProvider theme={theme}>
      <AlertsSuccess />
      <Modal
           open={openInquiry}
           onClose={handleCloseInquiry}
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description"
           >
        <div className={styles.makeofferModal}>
        <div className={styles.clobtn}>
        <Link onClick={handleCloseInquiry}><RiCloseCircleFill className={styles.iconru} /></Link>
        </div>
        <p className={styles.mpophndg}
           >
        Do you have any question?
        </p>
        <div className={styles.moblform}>
        <div className="form-group">
        <label>Email</label>
        <input
           id="outlined-basic"
           variant="outlined"
           className="form-control"
           type="mail"
           name="email"
           value={inquiryRequest.email}
           onChange={handleChangeInquiry}
           />
        {inquiryError.email && (
        <small style={{ color: "red" }}>
        {inquiryError.email}
        </small>
        )}
        </div>
        <div className="form-group">
        <label>Description</label>
        <textarea
           id="outlined-basic"
           variant="outlined"
           multiline
           className="form-control"
           name="description"
           value={inquiryRequest.description}
           onChange={handleChangeInquiry}
           />
        {inquiryError.description && (
        <small
        style={{ color: "red"}}
        >
        {inquiryError.description}
        </small>
        )}
        </div>
        <div className="form-group1 text-center">
        <button
           className="action_btn"
           onClick={handleSubmitInquiry}
           >
        Submit
        </button>
        </div>
        </div>
        </div>
        </Modal>
      {frameModal ? (
        <ChooseFrameModal
          handleClickChooseFrame={handleClickChooseFrame}
          frameModal={frameModal}
          setFrameModal={setFrameModal}
          setFrameData={setFrameData}
          frameData={frameData}
        />
      ) : (
        ""
      )}
      {frameModalsecond ? (
        <ChooseFrameModal
          setFrameData={setFrameData}
          frameData={frameData}
          setFrameModalsecond={setFrameModalsecond}
          frameModalsecond={frameModalsecond}
          handleClickChooseFrame={handleClickChooseFrame}
          handleBackbutton={handleBackbutton}
        />
      ) : (
        ""
      )}
      <div className={styles.productdetails}>
        <div className={styles.proddets__top}>
          {loader.loader ? (
            <div className={styles.loader}>
              <CircularLoader />
            </div>
          ) : (
            productdetails &&
            productdetails?.artwork?.map((val) => (
              <>
                <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
                  <Grid container spacing={{xs:2, sm:2, md:5}}>
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                      <div className={styles.prod__img}>
                        <img
                          
                          className={imageClassNames}
                          src={ val.image}
                          alt="img"
                          onLoad={handleImageLoad}
                        />
                        {orientation === "vertical" &&
                        materialName === "spacers" ? (
                          <div className={styles.verticalImage}></div>
                        ) : null}

                        {orientation === "horizontal" &&
                        materialName === "spacers" ? (
                          <div
                            style={{
                              height: imageHeight,
                              width: imageWidth,
                              overflow: "hidden",
                            }}
                            className={styles.horizontalImage}
                          ></div>
                        ) : null}
                        <br />
                        <div className={styles.previewimg__div}>
                          {/* <img
                            className={styles.small_img}
                            src={ val.image}
                            alt="img"
                          /> */}
                          {val.images?.split(',')
                          .map(imgSrc => imgSrc.trim()) // Trim spaces
                          .filter(Boolean) // Remove empty values
                          .map((imgSrc, index) => (
                            <img
                              key={index}
                              className={styles.small_img}
                              src={imgSrc}
                              style={{ width: "80px", height: "50px" }}
                              alt={`img-${index}`}
                            />
                        ))}
                          <div className={styles.artplacing__div}>
                            <Helmet>
                              <script
                                src="https://widget.artplacer.com/js/script.js"
                                crossorigin="anonymous"
                                async
                                type="text/javascript"
                              ></script>
                            </Helmet>
                            <artplacer
                              gallery="42581"
                              resizable="false"
                              frames="true"
                              rotate="false"
                              dimensions_standard="hxw"
                              catalog="false"
                              type="2"
                              thumb="https://d7mntklkfre1v.cloudfront.net/thumbs/58x58/04258116680971572846048.png"
                              space="65785"
                              artwork_url={ itmImage}
                              title="View In A Room"
                              size="dynamic size here"
                              price="dynamic price here"
                              artist="dynamic artist name here"
                            ></artplacer>
                            <artplacer
                              gallery="42581"
                              resizable="false"
                              frames="true"
                              rotate="false"
                              dimensions_standard="hxw"
                              catalog="false"
                              type="1"
                              thumb="https://d7mntklkfre1v.cloudfront.net/thumbs/58x58/0425815225614657042477.png"
                              artwork_url={ itmImage}
                              title="Try In Your Room"
                              size="dynamic size here"
                              price="dynamic price here"
                              artist="dynamic artist name here"
                            ></artplacer>
                            <artplacer
                              gallery="42581"
                              dimensions_standard="hxw"
                              type="3"
                              thumb="https://d7mntklkfre1v.cloudfront.net/thumbs/58x58/0425814222593640176562.png"
                              resizable="false"
                              frames="true"
                              rotate="false"
                              catalog="false"
                              artwork_url={ itmImage}
                              title="View In AR"
                              size="dynamic size here"
                              price="dynamic price here"
                              artist="dynamic artist name here"
                            ></artplacer>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                      <div className={styles.prodDetails}>
                        <h1>
                          {val.name}
                        </h1>
                        <Link
                        className={styles.authname}
                          // onClick={() =>
                          //   handleArtistProfile(
                          //     userartists &&
                          //       userartists.filter(
                          //         (vl) => vl?.user_id === val?.artist_id?._id
                          //       )
                          //   )?.map((v) => v._id)
                          // }
                          onClick={() => {
                            handleArtistProfile(val?.artist_id?._id);
                        }}
                        >
                             By {val.artist_id && val.artist_id.first_name ? val.artist_id.first_name.slice(0,1).toUpperCase() + val.artist_id.first_name.slice(1) : ""}{" "} 
                             {val.artist_id && val.artist_id?.last_name ? val.artist_id.last_name.trim().charAt(0).toUpperCase() + val.artist_id.last_name.trim().slice(1): ""}{", "}                    
                             {productdetails?.artistDetail?.addresses?.map((val) => val?.country)}{" - "}
                             {val.year}
                        </Link>


                     
                        <div className={styles.pricing__section}>
                          <div className={styles.pricediv}>
                          <p className={styles.mprice}
                              mt={1}
                              ml={0.5}
                              fontWeight="500"
                              fontSize="2rem"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                             
                              {val?.print_by_artsmiley.length ? "$" : ""}
                              {val.print_by_artsmiley.length
                                ? Number(val?.print_by_artsmiley[0]?.print_price + product)
                                : null}
                              {val?.print?.length ? (
                                <>
                                  {" "}
                                  {formatNewCurrency(
                                    // val?.print[0]?.print_price 
                                    (
                                      val.print?.find((pro) => pro.material === material)?.print_price ||
                                      val.print_by_artsmiley?.find((pro) => pro.material === material)
                                        ?.print_price ||
                                      0 // Default to 0 if no price is found for the selected material
                                    )
                                    *
                                      (1 -
                                        val?.offer_id
                                          ?.map((value) =>
                                            parseFloat(value?._id?.discount)
                                          )
                                          ?.reduce(
                                            (acc, discount) => acc + discount,
                                            0
                                          ) /
                                          100)
                                  )}{" "}
                                  {val?.offer_id?.length ? (
                                    <>
                                      <span
                                        style={{
                                          marginLeft: "10px",
                                          color: "gray",
                                          fontWeight: "500",
                                          textDecoration: "line-through",
                                          fontSize: "25px",
                                        }}
                                      >
                                        {formatNewCurrency(
                                          val?.print[0]?.print_price || 0
                                        )}
                                      </span>
                                      <span
                                        style={{
                                          marginLeft: "10px",
                                          color: "#2FAC4B",
                                          fontWeight: "bold",
                                          fontSize: "18px",
                                        }}
                                      >
                                        {val?.offer_id?.map((value) =>
                                          parseFloat(value?._id?.discount)
                                        )}
                                        % off
                                      </span>
                                    </>
                                  ) : null}
                                </>
                              ) : null}
                            </p>
                            {val?.print?.[0]?.qty === 0 ? (
                              <p className={styles.stock}
                              style={{
                                color: "#CE1616", // Use "color" for text
                              }}
                              >
                                Out of Stock
                              </p>
                            ) : (
                              <p  className={styles.stock}
                              style={{color:"#2FAC4B",}}
                              >
                                In Stock
                              </p>
                            )}
                          </div>
                          <div className={styles.sharediv}>
                            <div className={styles.ellipseshare}
                              onClick={handleClick}
                            >
                            <FiShare2 className={styles.shricn}/>
                            </div>
                            <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseShareIcons}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
  <MenuItem onClick={handleCloseShareIcons}>

    <FacebookShareButton  url={window.location.href}   style={{
    display: "flex",
    alignItems: "center",
    gap: "5px",
    border: "none",
    outline: "none",
    background: "transparent",
    padding: 0,
  }}>
      <FacebookIcon size={30} />
      <span style={{ marginLeft: 8 }}>Share on Facebook</span>
    </FacebookShareButton>

</MenuItem>
<MenuItem onClick={handleCloseShareIcons}>

<LinkedinShareButton url={window.location.href} style={{display:'flex',alignItems:"center",gap:'5px'}}>
  <LinkedinIcon size={30} />
  <span style={{ marginLeft: 8 }}>Share on Linkedin</span>
</LinkedinShareButton>

</MenuItem>
<MenuItem onClick={handleCloseShareIcons}>

<WhatsappShareButton url={window.location.href} style={{display:'flex',alignItems:"center",gap:'5px'}}>
  <WhatsappIcon size={30} />
  <span style={{ marginLeft: 8 }}>Share on Whatsapp</span>
</WhatsappShareButton>

</MenuItem>
<MenuItem onClick={handleCloseShareIcons}>

<TwitterShareButton url={window.location.href} style={{display:'flex',alignItems:"center",gap:'5px'}}>
  <XIcon size={30} />
  <span style={{ marginLeft: 8 }}>Share on X</span>
</TwitterShareButton>

</MenuItem>
   
      </Menu>
                            <div className={styles.ellipseheart}>
                             

                              {wishlist &&
                              wishlist.data &&
                              wishlist.data
                                .map((val) => val.item_id && val.item_id._id)
                                .includes(id) ? (
                                <FiHeart
                                  onClick={() => handleWishlist(val._id)}
                                  className={styles.likeIcon}
                                  fontSize="small"
                                  sx={{ cursor: "pointer" }}
                                />
                              ) : (
                                <FiHeart
                                  onClick={() => handleWishlist(val._id)}
                                  className={styles.likeIcon}
                                  fontSize="small"
                                  sx={{ cursor: "pointer" }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={styles.ratings__reactions}>
                        <div>
                            <ul className={styles.stratinglst}>
                              <li><RiStarFill className={styles.sticon} style={{color:"#FFD51A",opacity:"1"}}/></li>
                              <li><RiStarFill className={styles.sticon}  style={{color:"#FFD51A",opacity:"1"}}/></li>
                              <li><RiStarFill className={styles.sticon}  style={{color:"#FFD51A",opacity:"1"}}/></li>
                              <li><RiStarFill className={styles.sticon} /></li>
                              <li><RiStarFill  className={styles.sticon}/></li>
                            </ul>
                          </div>
                          <div className={styles.productrating}>
                            <div className={styles.like}>
                              {productdetails &&
                              productdetails.likesview &&
                              productdetails.likesview.likes &&
                              productdetails.likesview.likes
                                .map((val) => val._id)
                                .includes(localStorage.getItem("user_id")) ? (
                                <FiThumbsUp
                                  onClick={() =>
                                    handleLikeUnlike(val._id, "unlike")
                                  }
                                  className={styles.likeIcon}
                                  fontSize="small"
                                  sx={{ cursor: "pointer" }}
                                />
                              ) : (
                                <FiThumbsUp
                                  onClick={() =>
                                    handleLikeUnlike(val._id, "like")
                                  }
                                  className={styles.likeIcon}
                                  fontSize="small"
                                  sx={{ cursor: "pointer" }}
                                />
                              )}
                              <span
                                mt={1}
                                ml={0.5}
                                fontWeight="400"
                                fontSize="0.85rem"
                              >
                                {productdetails.likesview === null
                                  ? 0
                                  : productdetails.likesview.likes &&
                                    productdetails.likesview?.likes.length}
                              </span>
                              
                            </div>
                            <div className={styles.like}>
                              <FiEye
                                className={styles.viewIcon}
                              />
                              <span
                                mt={0.95}
                                ml={0.5}
                                fontWeight="400"
                                fontSize="0.85rem"
                              >
                                {productdetails.likesview === null
                                  ? 0
                                  : productdetails.likesview.views &&
                                    productdetails.likesview.views.length}
                              </span>
                            </div>
                          </div>
                        </div>
                        <Typography mt={2} fontSize="1.25rem">
                        {val?.print?.length  ? "Limited" : ""} {val?.print_by_artsmiley.length ? "Open" : ""} Edition Prints Available :
                        </Typography>
                        <div
                          className={styles.proddets_dets}
                          style={{ paddingTop: "20px" }}
                        >
                          <div>
                          <p className={styles.catgr}
                            >
                              Art Category
                            </p>
                          </div>
                          <div>
                            <p className={styles.reslte}
                            >
                              {val.category_id?.name}
                            </p>
                          </div>
                        </div>
                        <div className={styles.proddets_dets}>
                          <div>
                          <p className={styles.catgr}
                            >
                              Dimension (W * H) Cm
                            </p>
                          </div>
                          <div className={styles.greydescr_rent}>
                            <FormControl
                              variant="standard"
                              sx={{ minWidth: 80 }}
                            >
                              <Select
                                sx={{
                                  color: "#665d6f",
                                  "&:before": {
                                    // This targets the border before the select is focused
                                    borderBottom: "none",
                                  },
                                  "&:after": {
                                    // This targets the border after the select is focused
                                    borderBottom: "none",
                                  },
                                  "&:hover:not(.Mui-disabled):before": {
                                    // This targets the border on hover
                                    borderBottom: "none",
                                  },
                                }}
                                renderValue={(selected) =>
                                  `${selected.width} * ${selected.height}`
                                }
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={size}
                                // onChange={handleChange}
                                onChange={(e) => setSize(e.target.value)}
                                name="size"
                              >
                                {generateSizes(val.width,val.height).map(
                                  (val) => (
                                    <MenuItem
                                      value={{
                                        width: val.width,
                                        height: val.height,
                                      }}
                                      key={val._id}
                                    >
                                      {val.width} * {val.height}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div className={styles.proddets_dets}>
                          <div>
                            <p className={styles.catgr}
                            >
                              Material
                            </p>
                          </div>
                          <div className={styles.greydescr_rent}>
                            <FormControl
                              variant="standard"
                              sx={{ minWidth: 80 }}
                            >
                              <Select
                                sx={{
                                  color: "#665d6f",
                                  "&:before": {
                                    // This targets the border before the select is focused
                                    borderBottom: "none",
                                  },
                                  "&:after": {
                                    // This targets the border after the select is focused
                                    borderBottom: "none",
                                  },
                                  "&:hover:not(.Mui-disabled):before": {
                                    // This targets the border on hover
                                    borderBottom: "none",
                                  },
                                }}
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={material}
                                // onChange={handleChange}
                                onChange={(e) => setMaterial(e.target.value)}
                                name="material"
                              >
                                {val?.print?.length > 0 &&
                                  val.print.map((pro) => (
                                    <MenuItem
                                      sx={{ color: "#665d6f" }}
                                      value={pro.material}
                                      key={pro.material}
                                    >
                                      {pro.material}
                                    </MenuItem>
                                  ))}
                                {val?.print_by_artsmiley?.length > 0 &&
                                  val.print_by_artsmiley.map((pro) => (
                                    <MenuItem
                                      sx={{ color: "#665d6f" }}
                                      value={pro.material}
                                      key={pro.material}
                                    >
                                      {pro.material}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>
                        </div>

                        {material === "metal" && (
                          <>
                            <div className={styles.proddets_dets}>
                              <div>
                                <Typography
                                  fontWeight="600"
                                  fontSize="0.85rem"
                                  color="#1C1C1C"
                                  mt={-1}
                                >
                                  Metal Thickness
                                </Typography>
                              </div>
                              <div className={styles.greydescr_rent}>
                                <FormControl
                                  variant="standard"
                                  sx={{ minWidth: 80 }}
                                >
                                  <Select
                                    sx={{
                                      color: "#665d6f",
                                      "&:before": {
                                        // This targets the border before the select is focused
                                        borderBottom: "none",
                                      },
                                      "&:after": {
                                        // This targets the border after the select is focused
                                        borderBottom: "none",
                                      },
                                      "&:hover:not(.Mui-disabled):before": {
                                        // This targets the border on hover
                                        borderBottom: "none",
                                      },
                                    }}
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={metal_thikness}
                                    // onChange={handleChange}
                                    onChange={(e) =>
                                      setMetalThikness(e.target.value)
                                    }
                                    name="metal_thikness"
                                  >
                                    <MenuItem value="metal print 1.8 mm">
                                      1.8 mm
                                    </MenuItem>
                                    <MenuItem value="metal print 2.8 mm">
                                      2.8 mm
                                    </MenuItem>
                                    <MenuItem value="metal print 3.8 mm">
                                      3.8 mm
                                    </MenuItem>
                                    <MenuItem value="metal print 2 mm">
                                      2 mm
                                    </MenuItem>
                                    <MenuItem value="metal print 4 mm">
                                      4 mm
                                    </MenuItem>
                                    <MenuItem value="metal print 6 mm">
                                      6 mm
                                    </MenuItem>
                                    <MenuItem value="metal print 8 mm">
                                      8 mm
                                    </MenuItem>
                                    <MenuItem value="metal print 10 mm">
                                      10 mm
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          </>
                        )}
                        {val.print_by_artsmiley.length ? (
                          <>
                            {" "}
                            <div className={styles.proddets_dets}>
                              <div>
                                <Typography
                                  fontWeight="600"
                                  fontSize="0.85rem"
                                  color="#1C1C1C"
                                  mt={-1}
                                >
                                  Product
                                </Typography>
                              </div>
                              <div className={styles.greydescr_rent}>
                                <FormControl
                                  variant="standard"
                                  sx={{ minWidth: 80 }}
                                >
                                  <Select
                                    sx={{
                                      color: "#665d6f",
                                      "&:before": {
                                        // This targets the border before the select is focused
                                        borderBottom: "none",
                                      },
                                      "&:after": {
                                        // This targets the border after the select is focused
                                        borderBottom: "none",
                                      },
                                      "&:hover:not(.Mui-disabled):before": {
                                        // This targets the border on hover
                                        borderBottom: "none",
                                      },
                                    }}
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={product !== undefined ? product : ""}
                                    onChange={(e) => setProduct(e.target.value)}
                                  >
                                    {printPricingResponse?.found?.map((pro) => (
                                      <MenuItem
                                        sx={{ color: "#665d6f" }}
                                        value={calculatePrice(
                                          size.height,
                                          size.width,
                                          pro.price,
                                          pro.product,
                                          0
                                        )}
                                      >
                                        {pro.product} $
                                        {calculatePrice(
                                          size.height,
                                          size.width,
                                          pro.price,
                                          pro.product,
                                          0
                                        )}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          </>
                        ) : null}
                        {val?.print_by_artsmiley?.length ? (
                          <>
                            {" "}
                            <div className={styles.proddets_dets}>
                              <div>
                                <Typography
                                  fontWeight="600"
                                  fontSize="0.85rem"
                                  color="#1C1C1C"
                                  mt={-1}
                                >
                                  Select Frame
                                </Typography>
                              </div>
                              <div className={styles.greydescr_rent}>
                                <FormControl
                                  variant="standard"
                                  sx={{ minWidth: 80 }}
                                >
                                  <Select
                                    sx={{
                                      color: "#665d6f",
                                      "&:before": {
                                        // This targets the border before the select is focused
                                        borderBottom: "none",
                                      },
                                      "&:after": {
                                        // This targets the border after the select is focused
                                        borderBottom: "none",
                                      },
                                      "&:hover:not(.Mui-disabled):before": {
                                        // This targets the border on hover
                                        borderBottom: "none",
                                      },
                                    }}
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={materialName}
                                    onChange={(e) =>
                                      setMaterialName(e.target.value)
                                    }
                                  >
                                    <MenuItem
                                      sx={{ color: "#665d6f" }}
                                      value="blackcanvas"
                                    >
                                      Black Canvas
                                    </MenuItem>
                                    <MenuItem
                                      sx={{ color: "#665d6f" }}
                                      value="whitecanvas"
                                    >
                                      White Canvas
                                    </MenuItem>
                                    <MenuItem
                                      sx={{ color: "#665d6f" }}
                                      value="artpaperblack"
                                    >
                                      Art Paper Black
                                    </MenuItem>
                                    <MenuItem
                                      sx={{ color: "#665d6f" }}
                                      value="artpaperwhite"
                                    >
                                      Art Paper White
                                    </MenuItem>
                                    <MenuItem
                                      sx={{ color: "#665d6f" }}
                                      value="artpapernaturalwood"
                                    >
                                      Natural Wood
                                    </MenuItem>

                                    <MenuItem
                                      sx={{ color: "#665d6f" }}
                                      value="artpapermetllightpewter"
                                    >
                                      Metal: Light Pewter
                                    </MenuItem>
                                    <MenuItem
                                      sx={{ color: "#665d6f" }}
                                      value="artpapermetldarkpewter"
                                    >
                                      Metal: Dark Pewter
                                    </MenuItem>
                                    <MenuItem
                                      sx={{ color: "#665d6f" }}
                                      value="spacers"
                                    >
                                      Spacers
                                    </MenuItem>
                                    <MenuItem
                                      sx={{ color: "#665d6f" }}
                                      value="noframe"
                                    >
                                      No Frame
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          </>
                        ) : null}

                        {/* <div className={styles.proddets_dets}>
                          <div>
                            <p className={styles.catgr}
                            >
                              Delivery Charges
                            </p>
                          </div>
                          <div>
                            <p className={styles.reslte}
                            >
                              $0
                            </p>
                          </div>
                        </div> */}

                        <div className={styles.prints__customization}>
                          <button className="apply_btn"
                            onClick={handleClickOriginal}
                            
                          >
                            Original
                          </button>
                        </div>
                        <div className={styles.offerSection}>
                          
                          </div>

                       {val?.print?.[0]?.qty !== 0 ? 
                          <button
                          className={`apply_btn ${styles.widthfull} ${styles.mb_2}`}
                          onClick={() => {
                            if (val?.print?.[0]?.qty === 0) {
                              Errornotify("Out of Stock");
                            } else {
                              handleAddCart(val,"Print");
                            }
                          }}
                         
                        >
                          Add to Basket
                        </button>
                        : (
                         null
                       )
                       }

                        {val?.print?.[0]?.qty === 0 ? (
                          <>
                          <Tooltip  
         title={<span style={{ color: "white", fontSize: '11px' }}>Click here to initiate a request to recreate the artwork.</span>} 
         sx={{ "& .MuiTooltip-tooltip": { backgroundColor: "blue" } }}
         arrow>
                            <button
                              onClick={() => setOpenRecreate(true)}
                              className={`apply_ltbtn ${styles.widthfull} ${styles.mb_2}`}
                            >
                              Recreate
                            </button>
                            </Tooltip>
                          </>
                        ) : null}
                             <Modal
                          open={openRecreate}
                          onClose={() => setOpenRecreate(false)}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box className={styles.makeofferModal}>
                          <div className={styles.clobtn}>
                <Link onClick={() => setOpenRecreate(false)}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  Recreate
              </p>
                              
                            <div className="form-group">
                              <label>Email</label>
                              <input
                              type="email"
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="Enter Email"
                                size="small"
                                className="form-control"
                                name="email"
                                value={recreateRequest.email}
                                onChange={handleChangeRecreate}
                              />
                              {recreateError.email && (
                                <small style={{ color: "red" }}>
                                  {recreateError.email}
                                </small>
                              )}
                            </div>
                            <div className="form-group">
                            <label>
                              Message
                            </label>
                            <textarea
                              id="outlined-basic"
                              variant="outlined"
                              multiline
                              placeholder="Enter Message"
                              className="form-control"
                              name="message"
                              value={recreateRequest.message}
                              onChange={handleChangeRecreate}
                            />
                            {recreateError.message && (
                              <small
                                style={{ color: "red",}}
                              >
                                {recreateError.message}
                              </small>
                            )}
                            </div>
                            <div className="text-center">
                            <button
                              className="action_btn"
                              onClick={() => handleSubmitRecreate(val?._id)}
                            >
                              Send
                            </button>
                            </div>
                           
                          </Box>
                        </Modal>
                      </div>
                    </Grid>
                  </Grid>
                </Container>
                <div  className={styles.descriptiongrid}>
                <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={8}>
                      <div className={styles.description}>
                        <h3>Description</h3>
                        <p> {val.description}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                    <div className={styles.sidePanel}>
                    {val?.print_by_artsmiley?.length === 0 &&
                        <div className={styles.general__information}>
                          <div className={styles.att__color}>
                            <div className={styles.attributeflex}>
                            <ul className={styles.specicationlis}>
                            <li><span>Color:</span> <span>{val.color_id && val.color_id?.[0]._id?.name}</span></li>
                            <li><span>Weight:</span> <span>
                              {/* {val.print?.[0]?.print_weight} Kg */}
                              {
      val.print?.find((pro) => pro.material === material)?.print_weight ||
      val.print_by_artsmiley?.find((pro) => pro.material === material)
        ?.print_weight ||
      "N/A"
    }{" "}
    Kg
                              </span></li>
                            <li><span>Orientation:</span> <span className={styles.oraimg}>
                              {val?.orientation_id?.name}
                            {/* {val?.orientation_id?.name.toLowerCase() ===
                              "landscape" ? (
                                <img
                                  className={styles.orientation1}
                                  src={"/img/Rectangle 320.png"} alt=""
                                />
                              ) : val?.orientation_id?.name.toLowerCase() ===
                                "square" ? (
                                <img
                                  className={styles.orientation2}
                                  src={"/img/Orientation square.png"} alt=""
                                />
                              ) : (
                                <img
                                  className={styles.orientation3}
                                  src={"/img/orientation rect.png"} alt=""
                                />
                              )} */}
                              </span></li>
                            <li><span>SKU Number:</span> <span>{val.sku_id}</span></li>
                            <li><span>Can be wall mounted?</span> <span>{val.is_hanged}</span></li> 
                            </ul>
                              {/* <div className={styles.verticalLine}></div>
                              <Typography
                                fontSize="0.9rem"
                                fontWeight="600"
                                ml={1}
                              >
                                Color:
                              </Typography>
                            </div>
                            <Typography>
                              {val.color_id && val.color_id?.[0]._id?.name}
                            </Typography> */}
                          </div>
                          </div>
                          {/* <div className={styles.att__color}>
                            <div className={styles.attributeflex}>
                              <div className={styles.verticalLine}></div>
                              <Typography
                                fontSize="0.9rem"
                                fontWeight="600"
                                ml={1}
                              >
                                Weight:
                              </Typography>
                            </div>
                            <Typography>{val.print?.[0]?.print_weight} Kg</Typography>
                          </div>
                          <div className={styles.att__color}>
                            <div className={styles.attributeflex}>
                              <div className={styles.verticalLine}></div>
                              <Typography
                                fontSize="0.9rem"
                                fontWeight="600"
                                ml={1}
                              >
                                Orientation:
                              </Typography>
                            </div>
                            <div className={styles.orientationmain}>
                              {val.orientation_id.name.toLowerCase() ===
                              "landscape" ? (
                                <img
                                  className={styles.orientation1}
                                  src={"/img/Rectangle 320.png"} alt=""
                                />
                              ) : val.orientation_id.name.toLowerCase() ===
                                "square" ? (
                                <img
                                  className={styles.orientation2}
                                  src={"/img/Orientation square.png"} alt=""
                                />
                              ) : (
                                <img
                                  className={styles.orientation3}
                                  src={"/img/orientation rect.png"} alt=""
                                />
                              )}
                            </div>
                          </div>
                          <div className={styles.att__color}>
                            <div className={styles.attributeflex}>
                              <div className={styles.verticalLine}></div>
                              <Typography
                                fontSize="0.9rem"
                                fontWeight="600"
                                ml={1}
                              >
                                SKU Number:
                              </Typography>
                            </div>
                            <Typography>{val.sku_id}</Typography>
                          </div>
                          <div className={styles.att__color}>
                            <div className={styles.attributeflex}>
                              <div className={styles.verticalLine}></div>
                              <Typography
                                fontSize="0.9rem"
                                fontWeight="600"
                                ml={1}
                              >
                                Can be wall mounted?
                              </Typography>
                            </div>
                            <Typography>
                              {val.is_hanged}
                            </Typography>
                          </div> */}
                          {/* <div className={styles.att__color}>
                            <div className={styles.attributeflex}>
                              <div className={styles.verticalLine}></div>
                              <Typography
                                fontSize="0.9rem"
                                fontWeight="600"
                                ml={1}
                              >
                                Category:
                              </Typography>
                            </div>
                            <Typography>{val.category_id?.name}</Typography>
                          </div> */}
                          {/* <div className={styles.att__color}>
                            <div className={styles.attributeflex}>
                              <div className={styles.verticalLine}></div>
                              <Typography
                                fontSize="0.9rem"
                                fontWeight="600"
                                ml={1}
                              >
                                Tags:
                              </Typography>
                            </div>
                            <Typography>{val.tags}</Typography>
                          </div> */}
                        </div>
}
                      </div>
                    </Grid>
                    </Grid>
                </Container>
                </div>
              </>
            ))
          )}

          <div className={styles.features}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={styles.safe__shipping}>
                  <Grid container spacing={2} sx={{alignItems:"start"}}>
                  <Grid item xs={12} sm={3} md={3}>
                  <div className={styles.fericon}>
                    <img
                      className="img-fluid"
                      src={"/img/safe-shipping.svg"}
                      alt="img"
                    />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9}>
                  <div className={styles.feturcont}>
                      <h4>
                        Safe Shipping Globally
                      </h4>
                      <p>
                        We ensure that your artwork is shipped securely
                        worldwide. Our reliable shipping partners guarantee safe
                        handling and timely delivery.
                      </p>
                      {/* <Typography fontSize="0.9rem" ml={1.75}>
                        Our reliable shipping partners guarantee safe handling
                        and timely delivery.
                      </Typography> */}
                    </div>
                  </Grid>
                  </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={styles.safe__shipping}>
                  <Grid container spacing={2} sx={{alignItems:"start"}}>
                  <Grid item xs={12} sm={3} md={3}>
                  <div className={styles.fericon}>
                  <img
                      className="img-fluid"
                      src={"/img/free-return.svg"}
                      alt="img"
                    />
                  </div>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9}>
                  <div className={styles.feturcont}>
                      <h4>
                        Free Return
                      </h4>
                      <p>
                        If your purchase doesn't meet your expectations,
                        returning it is simple and free. We offer a hassle-free
                        return policy with no hidden fees, allowing you to
                        return the artwork within 14 days.
                      </p>
                      {/* <Typography fontSize="0.9rem" ml={1.75}>
                        We offer a hassle-free return policy with no hidden
                        fees, allowing you to return the artwork within 14 days.
                      </Typography> */}
                    </div>
                  </Grid>
                  </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={styles.safe__shipping}>
                  <Grid container spacing={2} sx={{alignItems:"start"}}>
                  <Grid item xs={12} sm={3} md={3}>
                  <div className={styles.fericon}>
                  <img
                      className="img-fluid"
                      src={"/img/secure.svg"}
                      alt="img"
                    />
                  </div>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9}>
                  <div className={styles.feturcont}>
                      <h4>
                        Secure
                      </h4>
                      <p>
                        All transactions on Art Smiley are encrypted and
                        processed securely. We collaborate with trusted
                        financial partners to provide a safe and professional
                        experience, ensuring your personal information remains
                        protected.
                      </p>
                      {/* <Typography fontSize="0.9rem" ml={1.75}>
                        We collaborate with trusted financial partners to
                        provide a safe and professional experience, ensuring
                        your personal information remains protected.
                      </Typography> */}
                    </div>
                  </Grid>
                  </Grid>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        {/* middle section */}
        <div className={styles.proddets__middle}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          <PolicySidebar />
          </Container>
        </div>
        {/* bottom section */}
        <ProdDetsOther artist_id={artist_id} allitemdt={allitemdt}  productdetails={productdetails}
      
         handleOpenInquiry={handleOpenInquiry}
        />
        {/* qna section */}
        <ProdDetsqna />
      </div>
    </ThemeProvider>
  );
}

export default PrintPage;
