import * as React from "react";
import styles from "./Galleryadminofferpopup.module.css";
import Backdrop from "@mui/material/Backdrop";
import serverimage from "../../../../serverConstant";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import homeImage from "../../../../constant";
import {
  offerAssignToArt,
  offerAssignToArtAll,
  offerRemoveToArt,
} from "../../../../State/api/artistapi";
import { useDispatch } from "react-redux";
import { Errornotify } from "../../../../common/alert/Alert";
import { FiSearch } from "react-icons/fi";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  borderRadius: "6px",
  p: 2,
};

export default function Galleryadminofferpopup(props) {
  console.log("Art Product Drtail Model ",props);
  
  const [age, setAge] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [artIds, setArtIds] = React.useState([]);
  console.log(artIds, "artIds");
  // Handle individual checkbox clickcosn
  const handleCheckboxChange = (artId, offers, isChecked) => {
    // Check if the artId is part of the offers in val.Offers
    const isOfferIncluded = offers?.map((offer) => offer?._id).includes(props.offerId);
  
    if (isChecked) {
      // If the checkbox is checked, artId is not already in artIds, and offer is not applied, add artId
      if (!artIds.includes(artId) && !isOfferIncluded) {
        setArtIds((prevIds) => [...prevIds, artId]);
      }
    } else {
      // If the checkbox is unchecked, remove the artId from artIds
      setArtIds((prevIds) => prevIds.filter((id) => id !== artId));
    }
  };
  
  

  // Handle "Select All" checkbox click
  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      // Add all art IDs to artIds, excluding those that are already in offers and ensuring qty > 0
      const allIds = props.artdata?.data
        ?.map((val) => {
          // Check if qty > 0 before adding the ID
          if (val?.sale?.qty > 0) {
            // Skip adding IDs if they are already part of the offers
            return val?.Offers?.map((offer) => offer?._id).includes(props.offerId)
              ? null
              : val._id;
          }
          return null; // Skip adding if qty <= 0
        })
        .filter((_id) => _id); // Filter out null values
  
      setArtIds(allIds); // Set artIds with only valid items
    } else {
      // Remove all art IDs from artIds
      setArtIds([]);
    }
  };
  
  const dispatch = useDispatch();
  const filteredOffers = props.artdata?.data?.filter(
    (val) => val?.Offers && val?.Offers?.length > 0
  );
  const handleClose = () => props.setOpen(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleOfferAssign = (id) => {
    if (id) {
      dispatch(offerAssignToArt(id, props.offerId, props.setOpen));
    }
  };
  const handleOfferAssignAll = () => {
      if(artIds.length > 0) {
        dispatch(offerAssignToArtAll(artIds, props.offerId, props.setOpen));
      } else {
        Errornotify("Please select at least one art")
      }
    
   
  };

  const handleOfferRemove = (id) => {
    if (id) {
      dispatch(offerRemoveToArt(id, props.offerId, props.setOpen));
    }
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const OfferNotAccept=()=>{
    Errornotify("Offer not accepted because the art amount is less than the offer price")

  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className={styles.modal_box}>
            <div className={styles.input_action_div}>
              <div className={styles.aofvsrch}>
                <input
                  name="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  
                  variant="outlined"
                  placeholder="Search"
                  type="text"
                  className="form-control"
                />
                {/* <input className={styles.search_input} placeholder='Morgan l' type='text' /> */}
                <div className={styles.sericon}><FiSearch /></div>
              </div>
              <button
                onClick={handleClose}
                variant="contained"
                className="action_libtn"
              >
                Close
              </button>
            </div>
            <div className={styles.main_content}>
              <div className={styles.selector}>
                <Checkbox {...label} onChange={handleSelectAllChange} />
                <p className={styles.title}>Select All</p>
              </div>
              {props.artdata?.data?.filter((value) => value?.name.toLowerCase().includes(search.toLowerCase())).map((val) => (
                <div key={val.id}>
                  <div className={styles.details_div}>
                    <div className={styles.img_detail_div}>
                      {val?.sale?.qty > 0 && !val?.Offers?.map((val) => val?._id).includes(
                              props.offerId
                            )  ? (
                        <Checkbox
                          {...label}
                          checked={artIds.includes(val._id)}
                          onChange={(e) => handleCheckboxChange(val._id, val.Offers, e.target.checked)}
                        />
                      ) :    <Checkbox
                      {...label}
                      sx={{visibility:"hidden"}}
                      checked={artIds.includes(val._id)}
                      onChange={() =>
                        handleCheckboxChange(val._id, val.Offers)
                      }
                    />}

                      <img src={` ${val?.image}`} width="120px" height="85px" alt="" />
                      <div className={styles.details_img_div}></div>
                      <div className={styles.detail}>
                        <h6 className={styles.title}>Name</h6>
                        <p className={styles.content}>{val.name.length > 10 ? val.name.slice(0, 10) + "..." : val.name}</p>
                      </div>
                      <div className={styles.detail}>
                        <h6 className={styles.title}>Price</h6>
                        <p className={styles.content}>{val.sale?.price}$</p>
                      </div>
                    </div>
                    <hr className={styles.hrverticle} />
                    <div className={styles.detail}>
                      <h6 className={styles.title}>Views</h6>
                      <p className={styles.content}>{val.views}</p>
                    </div>
                    <hr className={styles.hrverticle} />
                    <div className={styles.detail}>
                      <h6 className={styles.title}>Likes</h6>
                      <p className={styles.content}>{val.likes}</p>
                    </div>
                    <hr className={styles.hrverticle} />
                    <div className={styles.button_detail_div}>
                      <div className={styles.detail}>
                        <h6 className={styles.title}>Stock in Qty.</h6>
                        <p className={styles.content}>{val?.sale?.qty}</p>
                        {}
                      </div>
                      {
  props?.FilterOfferDetails?.discountType === "Price" ? (
    val?.sale?.price > props?.FilterOfferDetails?.discount ? (
      <div className={styles.add_btn_div}>
        {val?.sale?.qty > 0 ? (
          val?.Offers?.map((offer) => offer?._id).includes(props.offerId) ? (
            <button
            className="action_libtn"
              onClick={() => handleOfferRemove(val?._id)}
            >
              Remove
            </button>
          ) : (
            <button
              className="action_btn"
              onClick={() => handleOfferAssign(val?._id)}
            >
              Add
            </button>
          )
        ) : (
          <button
            disabled
              className="action_btn"
            onClick={() => handleOfferAssign(val?._id)}
          >
            Add
          </button>
        )}
      </div>
    ) : (
      <button
              className="action_btn"
      onClick={() => OfferNotAccept()}
    >
      Add
    </button>
    )
  ) : !props?.FilterOfferDetails?.discountType === "Percentage" ? null : (
    <div className={styles.add_btn_div}>
      {val?.sale?.qty > 0 ? (
        val?.Offers?.map((offer) => offer?._id).includes(props.offerId) ? (
          <button
            className="action_libtn"
            onClick={() => handleOfferRemove(val?._id)}
          >
            Remove
          </button>
        ) : (
          <button
              className="action_btn"
            onClick={() => handleOfferAssign(val?._id)}
          >
            Add
          </button>
        )
      ) : (
        <button
          disabled
              className="action_btn"
          onClick={() => handleOfferAssign(val?._id)}
        >
          Add
        </button>
      )}
    </div>
  )
}
                      
                    </div>
                  </div>
                  <hr className={styles.hori_hrs} />
                </div>
              ))}
              <Box
                zIndex={1}
                style={{ zIndex: 1 }}
                sx={{
                  py: 0,
                  mx: "auto",
                  display: "flex",
                  zIndex: 1300,
                  justifyContent: "center",
                  backgroundColor: "white",
                  mt: "0px",
                }}
              >
                <button
                onClick={handleOfferAssignAll}
                  className="action_btn"
                >
                  Save
                </button>
              </Box>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
