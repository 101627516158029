import { Link, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import serverimage from "../../../../../serverConstant";
import styles from "./Commondetailexshibitiongallery.module.css";
import api from "../../../../../State/api/Api";
import { useNavigate, useParams } from "react-router-dom";
import { Errornotify, Successnotify } from "../../../../../common/alert/Alert";
import { IoIosArrowBack } from "react-icons/io";
import { ToastContainer } from "react-toastify";

export default function BuyerDisputeDetail(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const {id} = useParams()
  const navigate = useNavigate()
  const [orderData, setOrderData] = useState(null);
  const handlePopup = () => {
    props.setOpen && props.setOpen({ open: true });
  };
  const handleDatepopup = () => {
    props.setOpen({ dateopen: true });
  };

  const handleFetchOrders = async function () {
    // dispatch(setLoader(true));
    setLoading(true)
    try {
      const result = await api.applicationApi(`api/dispute/getDisputeById/${id}`, "GET");
    
      if (result && result.status === 200) {
        setOrderData(result.data.data);
        // dispatch(setLoader(false));
        setLoading(false)
      } else if (result.status === 401) {
        Errornotify("Error with code 401");
      }
    } catch (err) {
      Errornotify("Network Error");
      setLoading(false)
    }
  };
useEffect(() => {
    handleFetchOrders();
  }, []);

  const handleAcceptOrderItem = async (orderItemId) => {
try {
  const response = await api.applicationApi(`api/order/updateOrderItemStatus/${orderItemId}`,"PATCH",{
    "order_status":"Approved" //Approved and Cancelled
    // "cancellation_reason":"This Is testing" // Not Optional
})
if(response.status === 200 || response.status === 201) {
  Successnotify(response.data.message)
}else {
Errornotify(response.data.message)
}
}catch(error) {
}
  };

  const handleRejectOrderItem = async (orderItemId) => {
    try {
      const response = await api.applicationApi(`api/order/updateOrderItemStatus/${orderItemId}`,"PATCH",{
        "order_status":"Rejected" //Approved and Cancelled
        // "cancellation_reason":"This Is testing" // Not Optional
    })
    if(response.status === 200 || response.status === 201) {
      Successnotify("Order Item Declined Successfully!")
    }
    }catch(error) {
    }
  }
  return (
    <div className={styles.mains}>
      <div className={styles.rbent}>
      <Link onClick={() => navigate("/userdispute")} className={styles.backbn}> <IoIosArrowBack /> <span> Go Back</span></Link>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={8}>
        {orderData?.map((data) => (
                    <div className={styles.more_detail}>
                    <div>
                     
                        <div>
                          <h1 className={styles.art_name}>{data.title}</h1>
                          <div style={{padding:"10px 0px"}}>
                            <img src={` ${data?.images}`} height="85px" width="121px" alt='img' />{" "}
                          </div>
                         
                         
                          <div className={styles.more_detail_child}>
                            <p className={styles.odlist}>Dispute Id : <span>{data?.dispute_id}</span></p>
                            <p className={styles.odlist}>Order Id : <span>{data?.order[0]?.order_number}</span></p>
                            <p className={styles.odlist}>Description : <span>{data.description}</span></p>
                            <p className={styles.odlist}>Payment : <span>{data.refund_status}</span></p>
                             
                          </div> 
                           
                        </div>
                
                    </div>
                   
                  </div>
                 ))}
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
        <div className={styles.item_acc_dec_div}>
    <h5 className={styles.main_heading}>
            {/* {props.type==="auction_gallery" ? props.data1 && props.data.auction: props.data && props.data.data && props.data.data.exhibition} */}
            {/* {props.data && props.data.data.data && props.data.data.data[0].artist} */}
            <span style={{color:'black',marginRight:"6px",}}>Status:</span> <span style={{color:'red',fontSize:"16px"}}>Open</span>
          </h5>
    </div>
        </Grid>
      </Grid>
         <ToastContainer />
       
    </div>
  );
}
  