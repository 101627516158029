import {
  Button,
  Container,
  createTheme,
  Grid,
  Menu,
  MenuItem,
  Modal,
  Snackbar,
  ThemeProvider,
  Link,
  Tooltip,
  } from "@mui/material";
  import React, { useEffect, useLayoutEffect, useState } from "react";
  import styles from "./ProductDetails.module.css";
  import classNames from "classnames";
  import { Helmet } from "react-helmet";
  // import "fontsource-marcellus";
  import ProdDetsOther from "./ProdDetsOther";
  import ProdDetsqna from "./ProdDetsqna";
  import PolicySidebar from "./PolicySidebar";
  import ChooseFrameModal from "./ChooseFrameModal";
  import homeImage from "../../../constant";
  import api from "../../../State/api/Api";
  import { useDispatch, useSelector } from "react-redux";
  import { setLoader } from "../../../State/Features/common/loaderSlice";
  import { useLocation, useNavigate, useParams } from "react-router-dom";
  import {
  fetchAllItemData,
  fetchCategoryData,
  fetchPrivacyPolicy,
  fetchUserArtist,
  handelAddRecentlyView,
  handelAddtocart,
  handelAddWishlist,
  handelMakeOffer,
  handelRequestQuote,
  handleFetchBuyerDetail,
  handleLikesViews,
  handleRequestInquiry,
  handleRequestRecreate,
  handleUpdateLikesViewsFav,
  } from "../../../State/api/userApi";
  import { setPolicy } from "../../../State/Features/user/policySlice";
  import {
  AlertsSuccess,
  Errornotify,
  Successnotify,
  } from "../../../common/alert/Alert";
  import CircularLoader from "../../../common/loader/Loader";
  import { setWishlistdata } from "../../../State/Features/user/wishlistSlice";
  import { setMycartdata } from "../../../State/Features/user/mycartSlice";
  import serverimage from "../../../serverConstant";
  import { Landscape } from "@mui/icons-material";
  import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
  import {
  fetchFrameCountry,
  generateAffiliateLink,
  getAffilateLink,
  } from "../../../State/api/superadminApi";
  import country from "../../../Country"; 
  import axios from "axios";
  import { useRef } from "react";
  import {
  formatNewCurrency,
  initializeCurrencyFormatter,
  } from "../../../utils/currencyFormat";
  import { validationForMakeOffer } from "../../../validation/validationForMakeOffer";
  import { validationForQuote } from "../../../validation/validationForQuote";
  import { validationForInquiry } from "../../../validation/validationForInquiry";
  import { validationForRecreate } from "../../../validation/validationForRecreate";
  import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
  } from "react-share";
  import PhoneInput from "react-phone-input-2";
  import { RiStarFill, RiCloseCircleFill} from "react-icons/ri";
  import {FiHeart, FiShare2, FiEye, FiThumbsUp } from "react-icons/fi";
  import { fetchQuateRequestData } from "../../../State/api/artistapi";
  import TranslateWrapper from "../../../languageTranslator/TranslateWrapper";
  import { useTranslation } from "react-i18next";
  const theme = createTheme({
  palette: {
  primary: {
  light: "#C1C1C1",
  main: "#000000",
  dark: "#C1C1C1",
  contrastText: "#fff",
  },
  secondary: {
  light: "#ffffff",
  main: "#C1C1C1",
  dark: "#ffffff",
  contrastText: "#000",
  },
  },
  typography: {
  prodheading: {
  fontFamily: "'Marcellus', serif",
  fontSize: "2.5rem",
  },
  },
  });
  const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  borderRadius: "3px",
  boxShadow: 24,
  };
  const cardDetails = [
  {
  id: 1,
  imgUrl: "/img/Rectangle 368.png",
  },
  {
  id: 2,
  imgUrl: "/img/Rectangle 369.png",
  },
  {
  id: 3,
  imgUrl: "/img/Rectangle 370.png",
  },
  {
  id: 4,
  imgUrl: "/img/Rectangle 5199.png",
  },
  ];
  function ProductDetails() {

   const navigateToArtworkDetail = (ids) => {
      navigate(`/user/productdetails/${ids}`);
    };

  const userDetail = useSelector((state) => state.buyerdetail.data);
  const usertypes = localStorage.getItem("usertype");
  const [inquiryRequest, setInquiryRequest] = useState({
   email: localStorage.getItem("user_email") || "",
  description: "",
  });
  const [recreateRequest, setRecreateRequest] = useState({
  item_id: "",
  email: "",
  message: "",
  });
  const [openquestion, setOpenQuestion] = React.useState(false);
  const [openQuote, setOpenQuotes] = React.useState(false);
  const [openInquiry, setOpenInquiry] = React.useState(false);
  const [openRecreate, setOpenRecreate] = React.useState(false);
  const [openLink, setOpenLink] = React.useState(false);
  const [orderTypesId, setOrderTypesId] = useState("");
  const [makeOffer, setMakeOffer] = useState({
  first_name: userDetail?.data?.first_name || "",
  email: userDetail?.data?.email || "",
  last_name: userDetail?.data?.last_name || "",
  phone: userDetail?.data?.phone || "",
  price: "",
  message: "",
  item_id: "",
  });
  const [quoteRequest, setquoteRequest] = useState({
  item_id: "",
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
  message: "",
  });
  const [makeOfferErrors, setMakeOfferErrors] = useState({});
  const [quoteError, setQuoteErrors] = useState({});
  const [inquiryError, setInquiryErrors] = useState({});
  const [recreateError, setRecreateErrors] = useState({});
  const [frameModal, setFrameModal] = useState(false);
  const [frameCountry, setFrameCountry] = useState(null);
  const [frameModalsecond, setFrameModalsecond] = useState(false);
  const [frameData, setFrameData] = useState({
  thickness_id: "",
  finishtouch_id: "",
  framing_id: "",
  });
  const user_id = localStorage.getItem("user_id");
  const [addTocart, setAddTocart] = useState({
  userId: user_id,
  items: [],
  });
  const [preview_img, setPreview_img] = useState("");
  const handleClickChooseFrame = () => {
  if (frameData.finishtouch_id === "") {
  Errornotify("Please select any finishing touch");
  } else {
  setFrameModal(false);
  setFrameModalsecond(true);
  }
  };
  const handleBackbutton = () => {
  setFrameModal(true);
  setFrameModalsecond(false);
  setFrameData({ thickness_id: "", finishtouch_id: "", framing_id: "" });
  };
  const [requestRecreateData, setRequestRecreateData] = useState(null)
  console.log(requestRecreateData,'requestRecreateData')
  const quatedt = useSelector((state) => state.quateRequestData.data)
  console.log(quatedt,'quatedt')
  const wishlist = useSelector((state) => state.wishlistData);
  const allitemdt = useSelector((state) => state.allitemData);
  const [openquote, setOpenQuote] = React.useState(false);
  const [productdetails, setProductdetails] = useState();
  const buyId = productdetails?.orderTypes?.filter(
  (val) => val.name === "Buy"
  )[0]?._id;
  const [availablity, setAvailablity] = useState(null);
  const artist_id =
  productdetails &&
  productdetails?.artwork.map((vl) => {
  return vl.artist_id && vl.artist_id._id;
  });
  var itmImage = "";
  productdetails &&
  productdetails.artwork.map((val) => {
  itmImage = val.image;
  });
  console.log("itmImage", itmImage)
  const handleOpenQuestion = () => setOpenQuestion(true);
  const handleOpenInquiry = () => setOpenInquiry(true);
  const handleOpenQuotes = () => setOpenQuotes(true);
  const handleClose = () => setOpenQuestion(false);
  const handleCloseInquiry = () => setOpenInquiry(false);
  const handleCloseQuotes = () => setOpenQuotes(false);
  const handleClickRent = () => {
  window.location.href = `/user/rent/${url[2]}/${id}`;
  };
  const handleOpenQuote = () => navigate("/");
  const handleCloseQuote = () => setOpenQuote(false);
  const { id } = useParams();
  const url = window.location.pathname.split("/");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [affiliateLink, setAffiliateLink] = useState([]);
  const userty = useSelector((state) => state.user.data);
  const loader = useSelector((state) => state.loader);
  const myCart = useSelector((state) => state.mycartData.data);
  const index = myCart && myCart.result.map((item) => item.item_id._id);
  const mac_Address = localStorage.getItem("device_id");
  const orderTypeInCart =
  myCart && myCart?.result?.map((item) => item?.orderType_id?.name);
  const userartists = useSelector((state) => state.userartistData.data);
  const location = useLocation();
  const handleFetchAllitem = async function () {
  dispatch(setLoader(true));
  try {
  // const result = await api.applicationApi('api/user/getoffers')
  const result = await api.applicationApi(`api/user/allitems/${id}`);
  if (result && result.status === 200) {
  setProductdetails(result.data);
  result.data.artwork.forEach((element) => {
  setMakeOffer({ ...makeOffer, item_id: element._id });
  });
  dispatch(setLoader(false));
  } else if (result.status === 401) {
  }
  } catch (err) {}
  };
  const handleAffiliateLink = async (a, b) => {
  try {
  await dispatch(generateAffiliateLink({ item_id: a, sku_id: b }));
  await getLink();
  setOpenLink(true);
  } catch (error) {
  console.error(
  "Error while generating the affiliate link or fetching it:",
  error
  );
  }
  };
  const getLink = async () => {
  try {
  await dispatch(getAffilateLink(setAffiliateLink));
  } catch (error) {
  console.error("Error while fetching affiliate link:", error);
  }
  };
  const fetchRecreateData = async function (data) {
  dispatch(setLoader(true))
  try {
  const result = await api.applicationApi(
  `api/recreateRequest/fetchRecreateRequests`,
  "POST",
  data
  )
  if (result && result.status === 200) {
  setRequestRecreateData(result.data.data.recreateRequests)
  dispatch(setLoader(false))
  }
  } catch (err) {
  }
  }
  const fetchData = () => {
  dispatch(fetchQuateRequestData({offset: 0}))
  }
  const fetchMakeOfferData = async function (data) {
  dispatch(setLoader(true));
  try {
  const result = await api.applicationApi(
  `api/makeOffer/fetchMakeOffers`,
  "POST",
  data
  );
  if (result && result.status === 200) {
  setMakeAnOfferData(result.data.data);
  dispatch(setLoader(false));
  }
  } catch (err) {}
  };
  const hasExecuted = useRef(false); // Initialize the ref outside the useEffect
  useEffect(() => {
  if (!hasExecuted.current) {
  dispatch(handleFetchBuyerDetail()); // First dispatch
  fetchMakeOfferData(); // Call your fetch logic
  fetchRecreateData({offset: 0})
  fetchData()
  // Delay handleLikesViews by 5 seconds
  dispatch(
  handleLikesViews({ item_id: id, string: "view" }, handleFetchAllitem)
  ); // Second dispatch
  hasExecuted.current = true; // Mark as executed
  }
  }, [dispatch, id, handleFetchAllitem]); // Dependencies as needed
  useEffect(() => {
  handleFetchAllitem();
  dispatch(fetchFrameCountry(setFrameCountry));
  dispatch(fetchAllItemData());
  initializeCurrencyFormatter();
  // handleRecentlyView(); // by localStorage
  dispatch(handelAddRecentlyView({ item_id: id }));
  // dispatch(handleLikesViews({ "item_id": id, "likes": likes + 1,"views":views+1 })),
  window.scrollTo({ top: 0, behavior: "smooth" });
  }, [id]);
  useEffect(() => {
  // Check if userDetail data is available before updating
  if (userDetail?.data) {
  const { first_name, last_name, email, phone } = userDetail.data;
  // Update makeOffer state
  setMakeOffer((prevOffer) => ({
  ...prevOffer,
  first_name,
  email,
  last_name,
  phone,
  }));
  // Update quoteRequest state
  setquoteRequest((prevQuote) => ({
  ...prevQuote,
  email,
  first_name,
  last_name,
  phone,
  }));
  }
  }, [userDetail?.data, openquestion]); // Remove makeOffer and quoteRequest from dependencies
  const textFieldRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
  if (textFieldRef.current) {
  textFieldRef.current.select();
  document.execCommand("copy");
  setCopied(true);
  }
  };
  const handleCloseLink = () => {
  setOpenLink(false);
  };
  const handleChangeOffer = (e) => {
  const name = e.target.name;
  const value = e.target.value;
  setMakeOffer({ ...makeOffer, [name]: value });
  };
  const handleSubmitOffer = () => {
  const { offerErrors, isValidMakeOffers } =
  validationForMakeOffer(makeOffer);
  if (isValidMakeOffers) {
  dispatch(
  handelMakeOffer(makeOffer, setOpenQuestion, setMakeOffer, navigate)
  );
  setTimeout(() => {
  window.location.reload();
  }, 500);
  setMakeOfferErrors({});
  } else {
  setMakeOfferErrors(offerErrors);
  }
  };
  const handleShare = () => {
  const data = {
  title: "Artsmilly codes",
  text: "Hello Artsmilly",
  url: window.location.href,
  };
  if (navigator.canShare && navigator.canShare(data)) {
  navigator.share(data);
  } else {
  }
  };
  const handleWishlist = (id) => {
  if (userty.signed === false) {
  Errornotify("Please login ");
  localStorage.setItem("buyer_back_url", location.pathname);
  } else {
  dispatch(handelAddWishlist({ item_id: id }));
  }
  };
  const [affId, setAffId] = useState("");
  useEffect(() => {
  // Get the current URL
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);
  // Extract affId
  const affIdFromUrl = params.get("affId");
  if (affIdFromUrl) {
  setAffId(affIdFromUrl);
  }
  }, []);
//   const handleAddCart = async (id, name) => {
//   const val = id;
//   if (userty.signed === false) {
//   // const ress = await axios.get('https://geolocation-db.com/json/')
//   dispatch(
//   handelAddtocart(
//   {
//   item_id: val,
//   orderType_id: buyId,
//   user_id: user_id,
//   quantity: 1,
//   IP_address: mac_Address,
//   },
//   { offset: 1, user_id: localStorage.getItem("user_id") }
//   )
//   );
//   // Errornotify("Please login ");
//   // navigate("/artsmiley/login");
//   // localStorage.setItem("buyer_back_url", location.pathname);
//   } else {
//   if (orderTypeInCart.includes("Rent")) {
//   Errornotify(
//   "You can't add both Rent and Buy to the cart at the same time."
//   );
//   } else {
//   if (index.includes(val) && orderTypeInCart.includes(name)) {
//   Errornotify("Already in your basket");
//   } else {
//   if (affId) {
//   dispatch(
//   handelAddtocart(
//   {
//   item_id: val,
//   orderType_id: buyId,
//   user_id: user_id,
//   affiliate_id: affId,
//   quantity: 1,
//   IP_address: mac_Address,
//   },
//   { offset: 1, user_id: localStorage.getItem("user_id") }
//   )
//   );
//   } else {
//   dispatch(
//   handelAddtocart(
//   {
//   item_id: val,
//   orderType_id: buyId,
//   user_id: user_id,
//   quantity: 1,
//   IP_address: mac_Address,
//   },
//   { offset: 1, user_id: localStorage.getItem("user_id") }
//   )
//   );
//   }
//   }
//   }
//   }
//   };
const handleAddCart = async (id, name) => {
   const val = id;
   if (userty.signed === false) {
     // const ress = await axios.get('https://geolocation-db.com/json/')
     dispatch(
       handelAddtocart(
         {
           item_id: val,
           orderType_id: buyId,
           user_id: user_id,
           quantity: 1,
           IP_address: mac_Address,
         },
         { offset: 1, user_id: localStorage.getItem("user_id") }
       )
     );
     // Errornotify("Please login ");
     // navigate("/artsmiley/login");
     // localStorage.setItem("buyer_back_url", location.pathname);
   } else {
     const typesInCart = ["Rent", "Print"];
     const typeInCart = typesInCart.find(type => orderTypeInCart.includes(type));
 
     if (typeInCart) {
       Errornotify(`You can't add both Buy and ${typeInCart} to the cart at the same time.`);
     } else {
       if (index.includes(val) && orderTypeInCart.includes(name)) {
         Errornotify("Already in your basket");
       } else {
         if (affId) {
           dispatch(
             handelAddtocart(
               {
                 item_id: val,
                 orderType_id: buyId,
                 user_id: user_id,
                 affiliate_id: affId,
                 quantity: 1,
                 IP_address: mac_Address,
               },
               { offset: 1, user_id: localStorage.getItem("user_id") }
             )
           );
         } else {
           dispatch(
             handelAddtocart(
               {
                 item_id: val,
                 orderType_id: buyId,
                 user_id: user_id,
                 quantity: 1,
                 IP_address: mac_Address,
               },
               { offset: 1, user_id: localStorage.getItem("user_id") }
             )
           );
         }
       }
     }
   }
 };
   
const handelSubmitRequest = () => {
  const { quoteErrors, isValidQuote } = validationForQuote(quoteRequest);
  if (isValidQuote) {
  dispatch(handelRequestQuote(quoteRequest, setOpenQuotes));
  handleFetchAllitem();
  } else {
  setQuoteErrors(quoteErrors);
  }
  };
  const handleSubmitInquiry = () => {
  const { inquiryErrors, isValidInquiry } =
  validationForInquiry(inquiryRequest);
  if (isValidInquiry) {
  dispatch(handleRequestInquiry(id, inquiryRequest, setOpenInquiry));
  handleFetchAllitem();
  } else {
  setInquiryErrors(inquiryErrors);
  }
  };
  const handleSubmitRecreate = (id) => {
  const { recreateErrors, isValidRecreate } =
  validationForRecreate(recreateRequest);
  if (isValidRecreate) {
  dispatch(
  handleRequestRecreate(
  { ...recreateRequest, item_id: id },
  setOpenRecreate
  )
  );
  handleFetchAllitem();
  } else {
  setRecreateErrors(recreateErrors);
  }
  };
  const handleChangeQuote = (e) => {
  const { name, value } = e.target;
  setquoteRequest({ ...quoteRequest, [name]: value });
  };
  const handleChangePhone = (value) => {
  setquoteRequest({ ...quoteRequest, phone: value });
  };
  const handleChangeInquiry = (e) => {
  setInquiryRequest({ ...inquiryRequest, [e.target.name]: e.target.value });
  };
  const handleChangeRecreate = (e) => {
  setRecreateRequest({ ...recreateRequest, [e.target.name]: e.target.value });
  };
//   const handleArtistProfile = (id) => {
//   id.forEach((vl) => {
//   navigate(`/user/artist/${vl._id}`);
//   });
//   };
 const handleArtistProfile = (id) => {
   const url = `/user/artist/${id}`;
   window.location.href = url;
 }
  const handleProducts = (ids) => {
  const path = window.location.pathname;
  if (path.includes("user/trending")) {
  navigate(`/user/trending/${ids}`);
  } else if (path.includes("user/bestseller")) {
  navigate(`/user/bestseller/${ids}`);
  } else if (path.includes("mostpopular")) {
  navigate(`/user/mostpopular/${ids}`);
  } else if (path.includes("user/newestpaintings")) {
  navigate(`/user/newestpaintings/${ids}`);
  } else if (path.includes("user/allcategory/")) {
  navigate(`/user/allcategory/:id/${ids}`);
  }
  window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleClickPrints = () => {
  window.location.href = `/user/print/${url[2]}/${id}`;
  };
  const handleLikeUnlike = (id, type) => {
  if (userty.signed === false) {
  Errornotify("Please login ");
  } else if (type === "like") {
  dispatch(
  handleLikesViews({ item_id: id, string: type }, handleFetchAllitem)
  );
  } else if (type === "unlike") {
  dispatch(
  handleUpdateLikesViewsFav(
  { item_id: id, string: "like" },
  handleFetchAllitem
  )
  );
  }
  };
  function reloadPage() {
  // The last "domLoading" Time //
  var currentDocumentTimestamp = new Date(
  performance.timing.domLoading
  ).getTime();
  // Current Time //
  var now = Date.now();
  // Ten Seconds //
  var tenSec = 10 * 1000;
  // Plus Ten Seconds //
  var plusTenSec = currentDocumentTimestamp + tenSec;
  if (now > plusTenSec) {
  window.location.reload();
  } else {
  }
  }
  // const getData = async () => {
  //   const res = await axios.get("https://api.ipify.org/?format=json");
  //   const ress = await axios.get('https://geolocation-db.com/json/')
  // };
  useEffect(() => {
  reloadPage();
  // getData();
  }, []);
  const handlePreviewChangeImage = (e) => {
  const { name } = e.target;
  setPreview_img(name);
  };
  const checkFrameCountry = (e, newValue) => {
  const availabl = frameCountry && frameCountry.includes(newValue);
  setAvailablity(availabl);
  };
  useEffect(() => {
  productdetails?.artwork?.map((val) => {
  if (val?.offer_id?.length) {
  const discountPercentage = val?.offer_id
  ?.map((dis) => dis?._id?.discount)
  .join("");
  localStorage.setItem(
  "discount_percentage",
  JSON.stringify(discountPercentage)
  );
  } else {
  localStorage.removeItem("discount_percentage");
  }
  });
  }, [productdetails]);
  // const imageClassNames = `${styles.main__img} ${true ? styles.whitecanvas : ''} `;
  const [imageHeight, setImageHeight] = useState(null);
  const [makeAnOfferData, setMakeAnOfferData] = useState([]); // [setMakeAnOfferData]
  console.log(makeAnOfferData, "makeAnOfferData");
  const [imageWidth, setImageWidth] = useState(null);
  const [orientation, setOrientation] = useState(""); // 'vertical' or 'horizontal'
  const handleImageLoad = (event) => {
  const height = event.target.height;
  const width = event.target.width;
  setImageHeight(height);
  setImageWidth(width);
  if (height > width) {
  setOrientation("vertical");
  } else {
  setOrientation("horizontal");
  }
  };
  const btnClass = classNames({
  [styles.verticalImage]: orientation === "vertical",
  [styles.horizontalImage]: orientation === "horizontal",
  });
  // Combine CSS module styles with dynamic class names
  const combinedClassName = classNames(styles.main__img, btnClass);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
  };
  const handleCloseShareIcons = () => {
  setAnchorEl(null);
  };
  const images =  productdetails?.artwork?.map((val) => val?.images.split(","));
//   console.log(images,'images')
  const { t } = useTranslation('productDetails');
  return (
  <ThemeProvider theme={theme}>
     <AlertsSuccess />
     {frameModal ? (
     <ChooseFrameModal
        handleClickChooseFrame={handleClickChooseFrame}
        frameModal={frameModal}
        setFrameModal={setFrameModal}
        setFrameData={setFrameData}
        frameData={frameData}
        />
     ) : (
     ""
     )}
     {frameModalsecond ? (
     <ChooseFrameModal
        setFrameData={setFrameData}
        frameData={frameData}
        setFrameModalsecond={setFrameModalsecond}
        frameModalsecond={frameModalsecond}
        handleClickChooseFrame={handleClickChooseFrame}
        handleBackbutton={handleBackbutton}
        />
     ) : (
     ""
     )}
     <div className={styles.productdetails}>
        <div className={styles.proddets__top}>
           {loader.loader ? (
           <div className={styles.loader}>
              <CircularLoader />
           </div>
           ) : (
           productdetails &&
           productdetails.artwork.map((val) => (
           <>
           <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
              <Grid container spacing={{xs:2, sm:2, md:5}}>
              <Grid item xs={12} md={6} sm={6} lg={6}>
                 <div className={styles.prod__img}>
                    <img
                    className={combinedClassName}
                    onLoad={handleImageLoad}
                    src={
                    preview_img.length !== 0 ? preview_img : val.image
                    }
                    alt="img"
                    />
                    <div className={styles.previewimg__div}>
                       {/* <img
                       className={styles.small_img}
                       onClick={handlePreviewChangeImage}
                       src={ "/img/aboutusimg.png"}
                       name="/img/aboutusimg.png"
                       alt="img"
                       />
                       <img
                       className={styles.small_img}
                       onClick={handlePreviewChangeImage}
                       src={ "/img/Rectangle 5203.png"}
                       alt="/img/Rectangle 5330.png"
                       name="/img/Rectangle 5203.png"
                       />
                       <img
                       className={styles.small_img}
                       onClick={handlePreviewChangeImage}
                       src={ "/img/Rectangle 5331.png"}
                       name="/img/Rectangle 5330.png"
                       alt="img"
                       /> */}
                       {/* {(images[0]?.length > 0) && images?.[0].map((imageUrl, index) => (
                       <img
                       key={index}
                       className={styles.small_img}
                       onClick={handlePreviewChangeImage}
                       src={imageUrl}
                       style={{ width: "80px",height:"50px"}} // Optional styling
                       alt="img"
                       />
                       ))} */}
                       {Array.isArray(images[0]) && images[0].filter(Boolean).length > 0 && 
                        images[0].filter(Boolean).map((imageUrl, index) => (
                           <img
                              key={index}
                              className={styles.small_img}
                              onClick={handlePreviewChangeImage}
                              src={imageUrl}
                              style={{ width: "80px", height: "50px" }} // Optional styling
                              alt="img"
                           />
                        ))
                        }
                       <div className={styles.artplacing__div}>
                          <Helmet>
                             <script
                                src="https://widget.artplacer.com/js/script.js"
                                crossorigin="anonymous"
                                async
                                type="text/javascript"
                                ></script>
                          </Helmet>
                          <artplacer
                             gallery="42581"
                             resizable="false"
                             frames="true"
                             rotate="false"
                             dimensions_standard="hxw"
                             catalog="false"
                             type="2"
                             thumb="https://d7mntklkfre1v.cloudfront.net/thumbs/58x58/04258116680971572846048.png"
                             space="65785"
                             artwork_url={itmImage}
                             title="View In A Room"
                             size="dynamic size here"
                             price="dynamic price here"
                             artist="dynamic artist name here"
                             ></artplacer>
                          <artplacer
                             gallery="42581"
                             resizable="false"
                             frames="true"
                             rotate="false"
                             dimensions_standard="hxw"
                             catalog="false"
                             type="1"
                             thumb="https://d7mntklkfre1v.cloudfront.net/thumbs/58x58/0425815225614657042477.png"
                             artwork_url={itmImage}
                             title="Try In Your Room"
                             size="dynamic size here"
                             price="dynamic price here"
                             artist="dynamic artist name here"
                             ></artplacer>
                          <artplacer
                             gallery="42581"
                             dimensions_standard="hxw"
                             type="3"
                             thumb="https://d7mntklkfre1v.cloudfront.net/thumbs/58x58/0425814222593640176562.png"
                             resizable="false"
                             frames="true"
                             rotate="false"
                             catalog="false"
                             artwork_url={itmImage}
                             title="View In AR"
                             size="dynamic size here"
                             price="dynamic price here"
                             artist="dynamic artist name here"
                             ></artplacer>
                       </div>
                    </div>
                 </div>
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6}>
                 <div className={styles.prodDetails}>
                    <h1><TranslateWrapper>{val.name}</TranslateWrapper></h1>
                    <Link
                       className={styles.authname}
                  //      onClick={() =>
                  //   handleArtistProfile(
                  //   userartists &&
                  //   userartists.filter(
                  //   (vl) => vl?.user_id === val?.artist_id?._id
                  //   )
                  //   )?.map((v) => v._id)
                  //   }
                  onClick={() => {
                     handleArtistProfile(val?.artist_id?._id);
                 }}
                    >
                    <TranslateWrapper>By{" "}
                    {val.artist_id && val.artist_id.first_name
                    ? val.artist_id.first_name
                    .slice(0, 1)
                    .toUpperCase() +
                    val.artist_id.first_name.slice(1)
                    : ""}{" "}
                    {val.artist_id?.last_name
                     ? val.artist_id.last_name.trim().charAt(0).toUpperCase() + val.artist_id.last_name.trim().slice(1)
                     : ""}{", "}
                    {productdetails?.artistDetail?.addresses?.map((val) => val?.country)}{" - "}
                    {val.year}</TranslateWrapper>
                    </Link>
                    <div className={styles.pricing__section}>
                       <div className={styles.pricediv}>
                          <p className={styles.mprice}>
                             {formatNewCurrency(
                             Math.max(
                             val?.offer_id?.[0]?._id?.discountType ===
                             "Percentage"
                             ? val?.sale?.price *
                             (1 -
                             parseFloat(
                             val?.offer_id?.[0]?._id?.discount ||
                             0
                             ) /
                             100)
                             : val?.sale?.price -
                             parseFloat(
                             val?.offer_id?.[0]?._id?.discount || 0
                             ),
                             0
                             )
                             )}
                             {val?.offer_id?.[0]?._id ? (
                             <>
                             <span
                             style={{
                             marginLeft: "10px",
                             color: "gray",
                             fontWeight: "500",
                             textDecoration: "line-through",
                             fontSize: "25px",
                             }}
                             >
                             {formatNewCurrency(val.sale.price)}
                             </span>
                             <span
                             style={{
                             marginLeft: "10px",
                             color: "#2FAC4B",
                             fontWeight: "bold",
                             fontSize: "18px",
                             }}
                             >
                             {val?.offer_id?.map((offer) => {
                             const discountValue =
                             offer._id.discountType === "Percentage"
                             ? `${offer._id.discount}%`
                             : `$${offer._id.discount}`;
                             return (
                             <span key={offer._id._id}>
                             {discountValue}
                             </span>
                             );
                             })}
                             </span>
                             </>
                             ) : null}
                          </p>
                          {val?.sale?.qty === 0 ? (
                          <p className={styles.stock}
                          style={{
                          color: "#CE1616", // Use "color" for text
                          }}
                          >
                          {t("out_of_stock")}
                          </p>
                          ) : (
                          <p  className={styles.stock}
                             style={{color:"#2FAC4B",}}
                             >
                             {t("in_stock")}
                          </p>
                          )}
                       </div>
                       <div className={styles.sharediv}>
                          <div
                             onClick={handleClick}
                             className={styles.ellipseshare}
                             >
                             <FiShare2 className={styles.shricn}/>
                          </div>
                          <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleCloseShareIcons}
                          MenuListProps={{
                          "aria-labelledby": "basic-button",
                          }}
                          >
                          <MenuItem onClick={handleCloseShareIcons}>
                             <FacebookShareButton
                             url={window.location.href}
                             style={{
                             display: "flex",
                             alignItems: "center",
                             gap: "5px",
                             border: "none",
                             outline: "none",
                             background: "transparent",
                             padding: 0,
                             }}
                             >
                             <FacebookIcon size={30} />
                             <span style={{ marginLeft: 8 }}>
                             {t("share_on_facebook")}
                             </span>
                             </FacebookShareButton>
                          </MenuItem>
                          <MenuItem onClick={handleCloseShareIcons}>
                             <LinkedinShareButton
                             url={window.location.href}
                             style={{
                             display: "flex",
                             alignItems: "center",
                             gap: "5px",
                             }}
                             >
                             <LinkedinIcon size={30} />
                             <span style={{ marginLeft: 8 }}>
                             {t("share_on_linkedin")}
                             </span>
                             </LinkedinShareButton>
                          </MenuItem>
                          <MenuItem onClick={handleCloseShareIcons}>
                             <WhatsappShareButton
                             url={window.location.href}
                             style={{
                             display: "flex",
                             alignItems: "center",
                             gap: "5px",
                             }}
                             >
                             <WhatsappIcon size={30} />
                             <span style={{ marginLeft: 8 }}>
                             {t("share_on_whatsapp")}
                             </span>
                             </WhatsappShareButton>
                          </MenuItem>
                          <MenuItem onClick={handleCloseShareIcons}>
                             <TwitterShareButton
                             url={window.location.href}
                             style={{
                             display: "flex",
                             alignItems: "center",
                             gap: "5px",
                             }}
                             >
                             <XIcon size={30} />
                             <span style={{ marginLeft: 8 }}>
                             {t("share_on_X")}
                             </span>
                             </TwitterShareButton>
                          </MenuItem>
                          </Menu>
                          <div
                          // onClick={() => handleWishlist(val._id)}
                          className={styles.ellipseheart}
                          >
                          {/* <img
                          className={styles.vectorheart}
                          src={ "/img/Vector-heart.png"}
                          alt="img"
                          /> */}
                          {wishlist &&
                          wishlist.data &&
                          wishlist.data
                          .map((val) => val.item_id && val.item_id._id)
                          .includes(id) ? (
                          <FiHeart
                             onClick={() =>
                          handleWishlist(val._id)}
                          className={styles.likeIcon}
                          fontSize="small"
                          sx={{ cursor: "pointer" }}
                          />
                          ) : (
                          <FiHeart
                             onClick={() =>
                          handleWishlist(val._id)}
                          className={styles.likeIcon}
                          fontSize="small"
                          sx={{ cursor: "pointer" }}
                          />
                          )}
                       </div>
                    </div>
                 </div>
                 <div className={styles.ratings__reactions}>
                    <div>
                       <ul className={styles.stratinglst}>
                          <li>
                             <RiStarFill className={styles.sticon} style={{color:"#FFD51A",opacity:"1"}}/>
                          </li>
                          <li>
                             <RiStarFill className={styles.sticon}  style={{color:"#FFD51A",opacity:"1"}}/>
                          </li>
                          <li>
                             <RiStarFill className={styles.sticon}  style={{color:"#FFD51A",opacity:"1"}}/>
                          </li>
                          <li>
                             <RiStarFill className={styles.sticon} />
                          </li>
                          <li>
                             <RiStarFill  className={styles.sticon}/>
                          </li>
                       </ul>
                    </div>
                    <div>
                       <div  className={styles.productrating}>
                          <div className={styles.like}>
                             {productdetails &&
                             productdetails.likesview &&
                             productdetails.likesview.likes &&
                             productdetails.likesview.likes
                             .map((val) => val._id)
                             .includes(user_id) ? (
                             <FiThumbsUp
                             style={{cursor: "pointer"}}
                                onClick={() =>
                             handleLikeUnlike(val._id, "unlike")
                             }
                             className={styles.likeIcon}
                             fontSize="small"
                             sx={{ cursor: "pointer" }}
                             />
                             ) : (
                             <FiThumbsUp
                             style={{cursor: "pointer"}}
                                onClick={() =>
                             handleLikeUnlike(val._id, "like")
                             }
                             className={styles.likeIcon}
                             fontSize="small"
                             sx={{ cursor: "pointer" }}
                             />
                             )}
                             <span>{productdetails.likesview === null
                             ? 0
                             : productdetails.likesview.likes &&
                             productdetails.likesview.likes.length}</span>
                          </div>
                          <div className={styles.like}>
                             <FiEye
                                className={styles.viewIcon}
                                />
                             <span> {productdetails.likesview === null
                             ? 0
                             : productdetails.likesview.views &&
                             productdetails.likesview.views.length}</span>
                          </div>
                       </div>
                    </div>
                    {/* ))} */}
                 </div>
                 <div
                 className={styles.proddets_dets}
                 style={{ paddingTop: "20px" }}
                 >
                 <div>
                    <p className={styles.catgr}>
                    {t("art_category")}
                    </p>
                 </div>
                 <div>
                    <p className={styles.reslte}>
                       <TranslateWrapper>{val.category_id?.name}</TranslateWrapper>
                    </p>
                 </div>
        </div>
        <div className={styles.proddets_dets}>
        <div>
        <p className={styles.catgr}>
        {t("dimension")} (W * H * D) CM
        </p>
        </div>
        <div>
        <p className={styles.reslte}>
        {val?.width} x {val?.height} x {val?.depth} CM
        </p>
        </div>
        </div>
        {/* <hr className={styles.dets__divider} /> */}
        {/* <div className={styles.proddets_frame}> */}
        {/* <div>
        <Typography
           fontWeight="600"
           fontSize="0.85rem"
           color="#1C1C1C"
           >
        Framing
        </Typography>
        <Typography
        fontWeight="600"
        fontSize="0.85rem"
        fontFamily="DM Sans"
        color={
        availablity && availablity ? "green" : "red"
        }
        >
        {availablity === null ? (
        ""
        ) : availablity ? (
        <div>
        <Typography>
        Framing is available in Your Location
        </Typography>
        {/* <Button
        sx={{
        whiteSpace:"nowrap",
        textTransform: "none",
        color: "white",
        backgroundColor: "black",
        "&.MuiButtonBase-root:hover": {
        bgcolor: "black",
        },
        borderRadius: "8px",
        width: "100px",
        padding: "2%",
        }}
        onClick={() => {
        setFrameModal(true);
        setFrameData({
        thickness_id: "",
        finishtouch_id: "",
        framing_id: "",
        });
        }}
        >
        <Typography fontSize="0.65rem">
        Choose frame
        </Typography>
        </Button> */}
        {/* </div>
        ) : (
        "Framing is not available in Your Location"
        )}
        </Typography>
        </div>
        <div className={styles.greydescr}>
        <Autocomplete
           id="combo-box-demo"
           disableClearable
           options={country.map((vl) => vl.label)}
        name="country"
        size="small"
        // value={frameCountry.countries}
        // onClick={checkFrameCountry}
        onChange={checkFrameCountry}
        sx={{
        mt: 1,
        padding: "0px",
        height: "25px",
        border: "none",
        width: "100px",
        }}
        className={styles.search_select}
        renderInput={(params) => (
        // <TextField
        // size="small"
        //   {...params}
        //   sx={{
        //     fontSize:"20px",
        //     padding: "0px",
        //     border: "none",
        //     color: "#636365",
        //     height: "25px",
        //     width: "100px"
        //   }}
        //   placeholder="Location"
        // />
        <div
           ref={params.InputProps.ref}
           className={styles.search_select}
           >
        <input
        type="text"
        {...params.inputProps}
        style={{
        fontSize: "14px",
        paddingLeft: "5px",
        color: "#636365",
        height: "15px",
        width: "90px",
        }}
        />
        {/* <TextField
        size="small"
        // {...params}
        {...params.inputProps}
        className={styles.search_select}
        sx={{
        fontSize:"20px",
        padding: "0px",
        border: "none",
        color: "#636365",
        height: "25px",
        width: "100px"
        }}
        placeholder="Location"
        /> */}
        {/* </div>
        )}
        />
        {availablity === null ? (
        ""
        ) : availablity ? (
        <Button
        sx={{
        whiteSpace: "nowrap",
        textTransform: "none",
        color: "white",
        backgroundColor: "black",
        "&.MuiButtonBase-root:hover": {
        bgcolor: "black",
        },
        borderRadius: "8px",
        width: "100px",
        padding: "2%",
        mt: 1,
        }}
        onClick={() => {
        setFrameModal(true)
        setFrameData({
        thickness_id: "",
        finishtouch_id: "",
        framing_id: "",
        })
        }}
        >
        <Typography fontSize="0.65rem">
        Choose frame
        </Typography>
        </Button>
        ) : (
        ""
        )}
        </div>  */}
        {/* </div> */}
        {/* <div className={styles.proddets_dets}>
        <div>
        <p className={styles.catgr}>
        {t("delivery_charges")}
        </p>
        </div>
        <div className={styles.greydescr}>
        <p className={styles.reslte}>
        {formatNewCurrency(
        val.delivery_charges ? val.delivery_charges : 0
        )}
        </p>
        </div>
        </div> */}
        <div className={styles.prints__customization}>
        {val?.print.length ||
        val?.print_by_artsmiley?.[0]?.product_cost.length ? (
        <button className="apply_btn"
           onClick={handleClickPrints}
           disabled={val.print ? false : true}
           >{t("prints")}
        </button>
        ) : null}
       
        </div>
        <div className={styles.offers}>
        <div className={styles.offerSection}>
        {/* <div className={styles.offserhdng}>
        <img
        className={`img-fluid ${styles.offerIcon}`}
        src={"/img/offer 1.png"}
        alt="img"
        />
        </div> */}
        {/* <p>Save Extra</p> */}
        {/* <div className={styles.ofrresn}>
        <p>with 3 offers</p>
        </div> */}
        </div>
        {/* For Affiliate Link */}
        {usertypes === "affiliate" ? (
        <div className={styles.affilate_btn}>
        <Button
           onClick={() =>
        handleAffiliateLink(val._id, val.sku_id)
        }
        disabled={val.print ? false : true}
        sx={{
        mt: 1,
        textTransform: "none",
        color: "white",
        backgroundColor: "black",
        "&.MuiButtonBase-root:hover": {
        bgcolor: "black",
        },
        borderRadius: "8px",
        height: "2rem",
        }}
        >
        {t("get_affiliate_link")}
        </Button>
        </div>
        ) : (
        ""
        )}
        </div>
        {val?.sale?.qty !== 0 ? (
        <>
        <button
        className={`apply_btn  ${styles.widthfull} ${styles.mb_2}`}
        onClick={() => {
        if (val?.sale.qty === 0) {
        Errornotify("Out of Stock");
        } else {
        if (!localStorage.getItem("user_id")) {
        Errornotify("Please Login");
        } else {
        if (
        localStorage.getItem("usertype") ===
        "buyer"
        ) {
        handleAddCart(val._id, "Buy");
        } else {
        Errornotify("Only Buyer Can Add To Cart");
        }
        }
        }
        }}
        >
        {t("add_to_basket")}
        </button>
        {/* <button
        className={`apply_ltbtn ${styles.widthfull} ${styles.mb_2}`}
        onClick={handleClickRent}
        disabled={val.rent ? false : true}
        >
        {t("rent")} {t("from")} ${val.rent?.rent_price}/{t("month")}
        </button> */}
        {val.rent && val.rent.rent_price && (
         <button
            className={`apply_ltbtn ${styles.widthfull} ${styles.mb_2}`}
            onClick={handleClickRent}
            disabled={val.rent ? false : true}
         >
            {t("rent")} {t("from")} ${val.rent.rent_price}/{t("month")}
         </button>
         )}
        </>
        ) : null}
        <Grid container spacing={2} sx={{
        justifyContent: "center",
        alignItems: "center",
        }}>
        <Grid item xs={12} sm={12} md={6} >
        {val?.sale?.qty !== 0 ? (
        <button
        className={`apply_ltbtn ${styles.widthfull} `}
        onClick={handleOpenInquiry}
        >
       {t("ask_a_question")}
        </button>
        ) : null}
        </Grid>
        {val?.sale?.qty === 0 &&
        requestRecreateData?.filter(
        (offer) => offer?.item_id?._id === val?._id
        ).length < 1 && (
        <Grid item xs={12} sm={12}>
         <Tooltip  
         title={<span style={{ color: "white", fontSize: '11px' }}>Click here to initiate a request to recreate the artwork.</span>} 
         sx={{ "& .MuiTooltip-tooltip": { backgroundColor: "blue" } }}
         arrow>
        <button
        className={`apply_ltbtn ${styles.widthfull} `}
        onClick={() => setOpenRecreate(true)}
        >
        {t("recreate")}
        </button>
        </Tooltip>
        </Grid>
        )}
        <Grid item  xs={12} sm={12} md={6}>
        {val?.sale?.qty !== 0 ? (
        <>
        {makeAnOfferData?.makeOffers?.filter(
        (offer) => offer?.item_id?._id === val?._id
        ).length < 1 ? (
        <button
        className={`apply_ltbtn ${styles.widthfull}  `}
        onClick={handleOpenQuestion}
        >
        {t("make_an_offer")}
        </button>
        ) : null}
        </>
        ) : null}
        </Grid>
        <Grid item xs={12}>
        {val?.sale?.qty !== 0 &&
        quatedt?.data?.filter(
        (offer) => offer?.item_id?._id === val?._id
        ).length < 1 && (
        <button
        className={`apply_ltbtn ${styles.widthfull} `}
        onClick={() => {
        setquoteRequest({
        ...quoteRequest,
        item_id: val?._id,
        });
        handleOpenQuotes();
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = "black")}
        onMouseLeave={(e) => (e.target.style.backgroundColor = "white")}
        >
        {t("request_for_quote")}
        </button>
        )}
        </Grid>
        </Grid>
        <Modal
           open={openInquiry}
           onClose={handleCloseInquiry}
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description"
           >
        <div className={styles.makeofferModal}>
        <div className={styles.clobtn}>
        <Link onClick={handleCloseInquiry}><RiCloseCircleFill className={styles.iconru} /></Link>
        </div>
        <p className={styles.mpophndg}
           >
        {t("do_you_have_any_question")}?
        </p>
        <div className={styles.moblform}>
        <div className="form-group">
        <label>{t("email")}</label>
        <input
           id="outlined-basic"
           variant="outlined"
           className="form-control"
           type="mail"
           name="email"
           value={inquiryRequest.email}
           onChange={handleChangeInquiry}
           />
        {inquiryError.email && (
        <small style={{ color: "red" }}>
        <TranslateWrapper>{inquiryError.email}</TranslateWrapper>
        </small>
        )}
        </div>
        <div className="form-group">
        <label>{t("description")}</label>
        <textarea
           id="outlined-basic"
           variant="outlined"
           multiline
           className="form-control"
           name="description"
           value={inquiryRequest.description}
           onChange={handleChangeInquiry}
           />
        {inquiryError.description && (
        <small
        style={{ color: "red"}}
        >
        <TranslateWrapper>{inquiryError.description}</TranslateWrapper>
        </small>
        )}
        </div>
        <div className="text-center">
        <button
           className="action_btn"
           onClick={handleSubmitInquiry}
           >
        {t("Submit")}
        </button>
        </div>
        </div>
        </div>
        </Modal>
        <Modal
           open={openLink}
           onClose={handleCloseLink}
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description"
           >
        <div className={styles.makeofferModal}>
        <div className={styles.clobtn}>
        <Link onClick={handleCloseLink}><RiCloseCircleFill className={styles.iconru} /></Link>
        </div>
        <p className={styles.mpophndg}>{t("generate_link")}</p>
        <div className={styles.moblform}>
        <div className="form-group">
        <label>{t("text_link")}</label>
        <input
        id="outlined-basic"
        variant="outlined"
        inputRef={textFieldRef}
        className="form-control"
        type="text"
        value={affiliateLink ? affiliateLink : ""}
        name="link"
        />
        </div>
        <div className="form-group text-center">
        <button
           className="action_btn"
           onClick={handleCopy}
           >
        {t("copy_link")}
        </button>
        </div>
        <div className="form-group1">
        <Snackbar
        open={copied}
        anchorOrigin={{
        vertical: "top",
        horizontal: "right",
        }}
        onClose={() => setCopied(false)}
        sx={{ width: "20px" }}
        autoHideDuration={2000}
        message={t("link_copied")}
        />
        </div>
        </div>
        </div>
        </Modal>
        <Modal
           open={openquestion}
           onClose={handleClose}
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description"
           >
        <div className={styles.makeofferModal}>
        <div className={styles.clobtn}>
                              <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
                            </div>
                            <p className={styles.mpophndg}>{t("make_an_offer")}</p>
                            <div className={styles.moblform}>
                            <div className="form-group">
                                <label>{t("email")}</label>
                                <input
        id="outlined-basic"
        variant="outlined"
        className="form-control"
        type="mail"
        value={makeOffer.email}
        name="email"
        onChange={handleChangeOffer}
        />
        {makeOfferErrors.email && (
        <small style={{ color: "red" }}>
        <TranslateWrapper>{makeOfferErrors.email}</TranslateWrapper>
        </small>
        )}
                              </div>
                              <div className="form-group">
                                <label>{t("first_name")}</label>
                                <input
        id="outlined-basic"
        variant="outlined"
        className="form-control"
        type="text"
        value={makeOffer.first_name}
        name="first_name"
        onChange={handleChangeOffer}
        />
        {makeOfferErrors.first_name && (
        <small style={{ color: "red" }}>
        <TranslateWrapper>{makeOfferErrors.first_name}</TranslateWrapper>
        </small>
        )}
                              </div>
                              <div className="form-group">
                                <label>{t("last_name")}</label>
                                <input type="text"
                                className="form-control"
        id="outlined-basic"
        variant="outlined"
        value={makeOffer.last_name}
        name="last_name"
        onChange={handleChangeOffer}
        />
        {makeOfferErrors.last_name && (
        <small style={{ color: "red" }}>
        <TranslateWrapper>{makeOfferErrors.last_name}</TranslateWrapper>
        </small>
        )}
                              </div>
                              <div className="form-group">
                                <label>{t("phone_number")}</label>
                                <input type="text"
                                className="form-control"
        id="outlined-basic"
        variant="outlined"
        value={makeOffer.phone}
        name="phone"
        onChange={handleChangeOffer}
        />
        {makeOfferErrors.phone && (
        <small style={{ color: "red" }}>
        <TranslateWrapper>{makeOfferErrors.phone}</TranslateWrapper>
        </small>
        )}
                              </div>
                              <div className="form-group">
                                <label>{t("offer_price")}</label>
                                <input type="text"
                                className="form-control"
        id="outlined-basic"
        variant="outlined"
        value={makeOffer.price}
        name="price"
        onChange={handleChangeOffer}
        />
        {makeOfferErrors.offer_price && (
        <small style={{ color: "red" }}>
        <TranslateWrapper>{makeOfferErrors.offer_price}</TranslateWrapper>
        </small>
        )}
                              </div>
                              <div className="form-group">
                                <label>{t("message")}</label>
                                <textarea
                                className="form-control"
        id="outlined-basic"
        variant="outlined"
        value={makeOffer.message}
        name="message"
        onChange={handleChangeOffer}
        />
        {makeOfferErrors.message && (
        <small
        style={{ color: "red"}}
        >
        <TranslateWrapper>{makeOfferErrors.message}</TranslateWrapper>
        </small>
        )}
                              </div>
                              <div className="text-center">
                              <button
        className="action_btn"
        onClick={() => {
        handleSubmitOffer();
        }}
        >
        {t("make_offer")}
        </button>
                              </div>
                            </div>
        </div>
        </Modal>
        <Modal
           open={openQuote}
           onClose={handleCloseQuotes}
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description"
           >
        <div className={styles.makeofferModal}>
        <div className={styles.clobtn}>
                              <Link onClick={handleCloseQuotes}><RiCloseCircleFill className={styles.iconru} /></Link>
                            </div>
                            <p className={styles.mpophndg}>{t("request_for_quote")}</p>
                            <div className={styles.moblform}>
                            <div className="form-group">
                                <label>{t("email")}</label>
                                <input type="mail"
                                className="form-control"
        id="outlined-basic"
        variant="outlined"
        name="email"
        value={quoteRequest.email}
        onChange={handleChangeQuote}
        />
        {quoteError.email && (
        <small style={{ color: "red" }}>
        <TranslateWrapper>{quoteError.email}</TranslateWrapper>
        </small>
        )}
                              </div>
                              <div className="form-group">
                                <label>{t("first_name")}</label>
                                <input type="text"
                                className="form-control"
        id="outlined-basic"
        variant="outlined"
        name="first_name"
        value={quoteRequest.first_name}
        onChange={handleChangeQuote}
        />
        {quoteError.first_name && (
        <small style={{ color: "red" }}>
        <TranslateWrapper>{quoteError.first_name}</TranslateWrapper>
        </small>
        )}
                              </div>
                              <div className="form-group">
                                <label>{t("last_name")}</label>
                                <input type="text"
                                className="form-control"
        id="outlined-basic"
        variant="outlined"
        name="last_name"
        value={quoteRequest.last_name}
        onChange={handleChangeQuote}
        />
        {quoteError.last_name && (
        <small style={{ color: "red" }}>
        <TranslateWrapper>{quoteError.last_name}</TranslateWrapper>
        </small>
        )}
                              </div>
                              <div className="form-group">
                                <label>{t("phone_number")}</label>
                                <PhoneInput
        inputStyle={{ height: "20px" }}
        name="phone"
        value={quoteRequest.phone}
        onChange={handleChangePhone}
        />
        {/* <TextField
        id="outlined-basic"
        variant="outlined"
        placeholder="Enter number"
        size="small"
        sx={{
        "& fieldset": { border: "none" },
        backgroundColor: "#F3F3F3",
        width: "100%",
        border: "none",
        }}
        name="phone"
        value={quoteRequest.phone}
        onChange={handleChangeQuote}
        /> */}
        {quoteError.phone && (
        <small style={{ color: "red" }}>
        <TranslateWrapper>{quoteError.phone}</TranslateWrapper>
        </small>
        )}
                              </div>
                              <div className="form-group">
                                <label>{t("message")}</label>
                                <textarea
                                className="form-control"
        id="outlined-basic"
        variant="outlined"
       
        name="message"
        value={quoteRequest.message}
        onChange={handleChangeQuote}
        />
        {quoteError.message && (
        <small
        style={{ color: "red"}}
        >
        <TranslateWrapper>{quoteError.message}</TranslateWrapper>
        </small>
        )}
                              </div>
                              <div className="form-group1 text-center">
                              <button
        className="action_btn"
        onClick={handelSubmitRequest}
        >
        {t("send_request")}
        </button>
                              </div>
                            </div>
        </div>
        </Modal>
        <Modal
           open={openInquiry}
           onClose={handleCloseInquiry}
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description"
           >
        <div className={styles.makeofferModal}>
        <div className={styles.clobtn}>
                              <Link onClick={handleCloseInquiry}><RiCloseCircleFill className={styles.iconru} /></Link>
                            </div>
                            <p className={styles.mpophndg}>{t("do_you_have_any_question")}?</p>
                            <div className={styles.moblform}>
                            <div className="form-group">
                                <label>{t("email")}</label>
                                <input type="mail"
                                className="form-control"
        id="outlined-basic"
        variant="outlined"
        
        name="email"
        value={inquiryRequest.email}
        onChange={handleChangeInquiry}
        />
        {inquiryError.email && (
        <small style={{ color: "red" }}>
        <TranslateWrapper>{inquiryError.email}</TranslateWrapper>
        </small>
        )}
                              </div>
                              <div className="form-group">
                                <label>{t("description")}</label>
                                <textarea className="form-control"
        id="outlined-basic"
        variant="outlined"
        name="description"
        value={inquiryRequest.description}
        onChange={handleChangeInquiry}
        />
        {inquiryError.description && (
        <small
        style={{ color: "red" }}
        >
        <TranslateWrapper>{inquiryError.description}</TranslateWrapper>
        </small>
        )}
                              </div>
                              <div className="form-group1 text-center">
                              <button
        className="action_btn"
        onClick={handleSubmitInquiry}
        >
        {t("submit")}
        </button>
                              </div>
                            </div>
        </div>
        </Modal>
        <Modal
           open={openRecreate}
           onClose={() => setOpenRecreate(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <div className={styles.makeofferModal}>
        <div className={styles.clobtn}>
                              <Link onClick={() => setOpenRecreate(false)}><RiCloseCircleFill className={styles.iconru} /></Link>
                            </div>
                            <p className={styles.mpophndg}>{t("recreate")}</p>
                            <div className={styles.moblform}>
                            <div className="form-group">
                                <label>{t("email")}</label>
                                <input type="mail"
                                className="form-control"
        id="outlined-basic"
        variant="outlined"
        name="email"
        value={recreateRequest.email}
        onChange={handleChangeRecreate}
        />
        {recreateError.email && (
        <small style={{ color: "red" }}>
        <TranslateWrapper>{recreateError.email}</TranslateWrapper>
        </small>
        )}
                              </div>
                              <div className="form-group">
                                <label>{t("message")}</label>
                                <textarea className="form-control"
        id="outlined-basic"
        variant="outlined"
       
        name="message"
        value={recreateRequest.message}
        onChange={handleChangeRecreate}
        />
        {recreateError.message && (
        <small
        style={{ color: "red"}}
        >
        <TranslateWrapper>{recreateError.message}</TranslateWrapper>
        </small>
        )}
                              </div>
                              <div className="form-group1 text-center">
                              <button
        className="action_btn"
        onClick={() => handleSubmitRecreate(val?._id)}
        >
        {t("send")}
        </button>
                              </div>
                            </div>
        </div>
        </Modal>
     </div>
     </Grid>
     </Grid>
     </Container>
     <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }} className={styles.descriptiongrid}>
        <Grid container spacing={2}>
           <Grid item xs={12} sm={6} md={8}>
              <div className={styles.description}>
                 <h3>{t("description")}</h3>
                 <p> <TranslateWrapper>{val.description}</TranslateWrapper>
                 </p>
              </div>
           </Grid>
           <Grid item xs={12} sm={6} md={4}>
              <div className={styles.sidePanel}>
                 {val?.is_hanged === "Yes" && (
                 <div className={styles.choose__frame}>
                    <ul className={styles.specicationlis}>
                       <li><span>{t("choose_frame")}</span> <span>
                          <button
                             className="action_btn"
                             onClick={() => {
                          setFrameModal(true);
                          setFrameData({
                          thickness_id: "",
                          finishtouch_id: "",
                          framing_id: "",
                          });
                          }}
                          > 
                          {t("choose_a_frame")} 
                          </button>
                          </span>
                       </li>
                    </ul>
                 </div>
                 )}
                 {val?.print_by_artsmiley?.length === 0 &&
                 <div className={styles.general__information}>
                    <div className={styles.att__color}>
                       <div className={styles.attributeflex}>
                          <ul className={styles.specicationlis}>
                             <li><span>{t("color")}:</span> <span><TranslateWrapper>{val.color_id && val.color_id?.[0]._id?.name}</TranslateWrapper></span></li>
                             <li><span>{t("weight")}:</span> <span>{val?.sale?.sale_weight} Kg</span></li>
                             <li><span>{t("orientation")}:</span> <span className={styles.oraimg}>
                             <TranslateWrapper>{val?.orientation_id?.name}</TranslateWrapper>
                              
                                </span>
                             </li>
                             <li><span>{t("sku_number")}:</span> <span>{val.sku_id}</span></li>
                             <li><span>{t("can_be_wall_mounted")}?:</span> <span> {val.is_hanged === "Yes" ||
                                val.is_hanged === "yes"
                                ? "Yes"
                                : "No"}</span>
                             </li>
                          </ul>
                         
                       </div>
                    </div>
                    {/* 
                    <div className={styles.att__color}>
                       <div className={styles.attributeflex}>
                          <div className={styles.verticalLine}></div>
                          <Typography
                             fontSize="0.85rem"
                             fontWeight="600"
                             ml={1}
                             >
                             Category:
                          </Typography>
                       </div>
                       <Typography>{val.category_id?.name}</Typography>
                    </div>
                    */}
                    {/* 
                    <div className={styles.att__color}>
                       <div className={styles.attributeflex}>
                          <div className={styles.verticalLine}></div>
                          <Typography
                             fontSize="0.85rem"
                             fontWeight="600"
                             ml={1}
                             >
                             Tags:
                          </Typography>
                       </div>
                       <Typography>{val.tags}</Typography>
                    </div>
                    */}
                 </div>
           }
              </div>
           </Grid>
        </Grid>
     </Container>
     </>
     ))
     )}
     <div className={styles.features}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
           <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                 <div className={styles.safe__shipping}>
                    <Grid container spacing={2} sx={{alignItems:"start"}}>
                       <Grid item xs={12} sm={3} md={3}>
                          <div className={styles.fericon}>
                             <img
                                className="img-fluid"
                                src={"/img/safe-shipping.svg"}
                                alt="img"
                                />
                          </div>
                       </Grid>
                       <Grid item xs={12} sm={9} md={9}>
                          <div className={styles.feturcont}>
                             <h4>
                                {t("safe_shipping_globally")}
                             </h4>
                             <p>
                                {t("safe_shipping_globally_desc")}
                             </p>
                             {/* 
                             <Typography fontSize="0.9rem" ml={1.75}>
                                Our reliable shipping partners guarantee safe handling
                                and timely delivery.
                             </Typography>
                             */}
                          </div>
                       </Grid>
                    </Grid>
                 </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                 <div className={styles.safe__shipping}>
                    <Grid container spacing={2} sx={{alignItems:"start"}}>
                       <Grid item xs={12} sm={3} md={3}>
                          <div className={styles.fericon}>
                             <img
                                className="img-fluid"
                                src={"/img/free-return.svg"}
                                alt="img"
                                />
                          </div>
                       </Grid>
                       <Grid item xs={12} sm={9} md={9}>
                          <div className={styles.feturcont}>
                             <h4>
                                {t("free_return")}
                             </h4>
                             <p>
                                {t("free_return_desc")}
                             </p>
                             {/* 
                             <Typography fontSize="0.9rem" ml={1.75}>
                                We offer a hassle-free return policy with no hidden
                                fees, allowing you to return the artwork within 14 days.
                             </Typography>
                             */}
                          </div>
                       </Grid>
                    </Grid>
                 </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                 <div className={styles.safe__shipping}>
                    <Grid container spacing={2} sx={{alignItems:"start"}}>
                       <Grid item xs={12} sm={3} md={3}>
                          <div className={styles.fericon}>
                             <img
                                className="img-fluid"
                                src={"/img/secure.svg"}
                                alt="img"
                                />
                          </div>
                       </Grid>
                       <Grid item xs={12} sm={9} md={9}>
                          <div className={styles.feturcont}>
                             <h4>
                                {t("secure")}
                             </h4>
                             <p>
                                {t("secure_desc")}
                             </p>
                             {/* 
                             <Typography fontSize="0.9rem" ml={1.75}>
                                We collaborate with trusted financial partners to
                                provide a safe and professional experience, ensuring
                                your personal information remains protected.
                             </Typography>
                             */}
                          </div>
                       </Grid>
                    </Grid>
                 </div>
              </Grid>
           </Grid>
        </Container>
     </div>
     </div>
     {/* middle section */}
     <div className={styles.latesprosec}>
     <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        {productdetails &&
        productdetails.artwork.map((val) => (
        <div className={styles.text_center}>
           <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
           <h3 className={styles.sec_title}><TranslateWrapper>More art works from{" "}
              {val.artist_id && val.artist_id.first_name}{" "}
              {val.artist_id && val.artist_id.last_name}</TranslateWrapper>
           </h3>
        </div>
        ))}
        <div className={styles.ProductCards}>
           <Grid container spacing={2}>
              {allitemdt.data &&
              allitemdt.data
              .filter((val) => {
              return (
              artist_id &&
              artist_id.includes(val.artist_id && val.artist_id._id) &&
              id !== val?._id
              );
              })
              .map((item, i) =>
              i >= 4 ? (
              ""
              ) : (
              <Grid item xs={12} sm={6} md={3}>
                 <div
                    className={styles.card__main}
                    onClick={() =>
                     navigateToArtworkDetail(item._id)
                   }
                    >
                    <div className={styles.card__top}>
                       <img
                          className={styles.cardImage}
                          src={item.image}
                          alt="img"
                          />
                    </div>
                    <div className={styles.pic_info}>
                       <div className={styles.card__middle}>
                          <span className={styles.titles}><TranslateWrapper>{item.name}</TranslateWrapper></span>
                       </div>
                       <div className={styles.info_like}>
                          <div className={styles.wishlst}>
                             <FiHeart />
                          </div>
                          <span className={styles.like_count}>
                          {item.likesview?.likes.length || 0}
                          </span>
                       </div>
                    </div>
                    <p className={styles.names}>
                       <TranslateWrapper>{item.artist_id && item.artist_id.first_name}{" "}
                       {item.artist_id && item.artist_id.last_name}</TranslateWrapper>
                    </p>
                    <div className={styles.price_sec}>
                       <div className={styles.card__bottom}>
                          <span className={styles.buys}>{t("buy")}</span>
                          <span className={styles.prices}>
                          {/* ${item?.sale?.price} */}
                          {item?.sale?.price ? `$ ${item?.sale?.price}` : "---"}
                          </span>
                       </div>
                       {/* {item?.rent?.rent_price > 0 ? ( */}
                       <>
                       {" "}
                       <div className={styles.hrs} />
                          <div>
                             <span className={styles.buys}>{t("rent")}/{t("month")}</span>
                             <span className={styles.prices}>
                             {/* ${item?.rent?.rent_price} */}
                             {item?.rent?.rent_price > 0 ? `$ ${item.rent.rent_price}` : "---"}
                             </span>
                          </div>
                          </>
                          {/* ) : null} */}
                          {/* {item?.print?.[0]?.print_price > 0 ? ( */}
                          <>
                          {" "}
                          <div className={styles.hrs} />
                             <div>
                                <span className={styles.buys}>{t("print")}</span>
                                <span className={styles.prices}>
                                {/* ${item?.print[0]?.print_price} */}
                                {item?.print?.[0]?.print_price > 0 ? 
                              `$ ${item?.print[0]?.print_price}`
                             : "---"}
                                </span>
                             </div>
                             </>
                           {/*   ) : null} */}
                          </div>
                          {/* 
                          <div className={styles.pic_info}>
                             <div className={styles.card__middle}>
                                <div className={styles.card__middleLeft}>
                                   <Typography
                                      fontSize="0.8rem"
                                      fontWeight="700"
                                      mt={1}
                                      color="#ccc"
                                      >
                                   </Typography>
                                </div>
                             </div>
                             <div className={styles.card__middleRight}>
                                <FavoriteBorderIcon fontSize="small" />
                                <Typography fontWeight="400" ml={0.5}>
                                </Typography>
                             </div>
                          </div>
                          <hr />
                          <div className={styles.card__bottom}>
                             <div className={styles.bottom__text}>
                                <Typography
                                   fontWeight="600"
                                   color="#ccc"
                                   fontSize="0.65rem"
                                   >
                                   Buy
                                </Typography>
                                <Typography
                                   color="black"
                                   fontWeight="500"
                                   fontSize="0.85rem"
                                   >
                                   <b>${item.sale.price}</b>
                                </Typography>
                             </div>
                             {item?.rent?.rent_price > 0 ? (
                             <>
                             <div className={styles.verticalLine}></div>
                             <div className={styles.bottom__text}>
                                <Typography
                                   fontWeight="600"
                                   color="#ccc"
                                   fontSize="0.65rem"
                                   >
                                   Rent/Month
                                </Typography>
                                <Typography
                                   color="black"
                                   fontWeight="500"
                                   fontSize="0.85rem"
                                   >
                                   <b>
                                   $
                                   {item.rent
                                   ? item.rent.rent_price
                                   : "not available"}
                                   </b>
                                </Typography>
                             </div>
                             </>
                             ) : null}
                             {item?.print?.[0]?.print_price > 0 ? (
                             <>
                             {" "}
                             <div className={styles.verticalLine}></div>
                             <div className={styles.bottom__text}>
                                <Typography
                                   fontWeight="600"
                                   color="#ccc"
                                   fontSize="0.65rem"
                                   >
                                   Print
                                </Typography>
                                <Typography
                                   color="black"
                                   fontWeight="500"
                                   fontSize="0.85rem"
                                   >
                                   <span className={styles.prices}>
                                   ${item.print[0].print_price}
                                   </span>
                                </Typography>
                             </div>
                             </>
                             ) : null}
                          </div>
                          */}
                       </div>
              </Grid>
              )
              )}
           </Grid>
           </div>
     </Container>
     </div>
     <div className={styles.proddets__middle}>
     <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
     <PolicySidebar />
     </Container>
     </div>
     {/* bottom section */}
     <ProdDetsOther
        artist_id={artist_id}
        allitemdt={allitemdt}
        productdetails={productdetails}
        handleOpenInquiry={handleOpenInquiry}
        />
     
     <ProdDetsqna />
     </div>
  </ThemeProvider>
  );
  }
  export default React.memo(ProductDetails);