import { Container, Link, Grid } from "@mui/material";
import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from "react-router-dom"
import styles from "./CommisionedArt.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import homeImage from "../../../constant";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RiCheckFill, RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import PhoneInput from "react-phone-input-2";
import { RiSettings6Line, RiSettings2Line  } from "react-icons/ri";
import 'swiper/swiper-bundle.min.css';
function CommisionedArt() {
  const navigate = useNavigate()

  const handleNavigate = (route) => {
    navigate(route)
  }
   

const exclusive_data = [
  {
    id: 1,
    src: "/img/artcollections",
    heading: "Create Your Perfect Piece",
    des: "We carefully listen to your preferences and work closely with the artist to ensure the commissioned artwork is tailored to meet your precise specifications and expectations. Our goal is for you to be delighted with your new piece when it arrives.",
  },
  {
    id: 2,
    src: "/img/artcollections",
    heading: "Personalized Guidance Through Every Step",
    des: "Our dedicated team is here to guide you through the entire journey, ensuring that every detail is perfect. If you have any questions or need help, we’re always ready to assist.",
  },
  {
    id: 3,
    src: "/img/artcollections",
    heading: "Effortless Experience",
    des: "We’ll take care of everything from keeping you in the loop with regular updates on the progress of your artwork to delivery of the artwork. You can relax while we handle everything.",
  },
];
const FAQs = [
  {
    id: 1,
    question: "How do I sign up as a gallery on Art Smiley?",
    answer:
      "Begin your journey by registering your gallery on the Art Smiley platform. Make sure to fill all the mandatory fields with the right information. Once your application has been reviewed and approved, you will receive a confirmation email to set up your password, granting you access to all available features and services.",
  },
  {
    id: 2,
    question: "How can I upload the artwork?",
    answer:
      "Begin by logging into your dashboard, navigating to the 'Upload Arts' section, and submitting your artwork with all the necessary details.",
  },
  {
    id: 3,
    question: "How long does the application review process take?",
    answer:
      "The review process for your gallery account typically takes 3 to 4 weeks. We will notify you once the review is complete and guide you through the subsequent steps.",
  },
  {
    id: 4,
    question: "Can I explore the platform while waiting for my account to be approved?",
    answer:
      "Yes, you can explore the features of Art Smiley while your application is under review. Take this time to familiarize yourself with the platform.",
  },
  {
    id: 5,
    question: "What should I do if my application is not approved?",
    answer:
      "If your application is not approved, we encourage you to enhance your profile and consider reapplying after six months. Our team is happy to offer feedback and assistance to help you improve your chances of approval in the future.",
  },
];
const remarableevents =[
  {
    id:1,
    heading: "Send an Inquiry",
    des: "The process begins when you reach out with your vision. If you have a well-defined concept or need guidance in shaping your idea, our team will work closely with you to gather all the necessary details including artwork dimensions, style, subject, materials, location, branding requirements, color preferences, and reference pictures etc., This initial step ensures that the artwork we create is tailored to your unique style and space.",
  },
  {
    id:2,
    heading: "Concept Approval",
    des: "Our team specializes in digital artwork commissions, hand painted artworks, personalized sculptures, collaborating with you to develop initial design concepts tailored to your needs. These concepts are presented for your feedback, and we make revisions until you're completely satisfied with the final idea.",
  },
  {
    id:3,
    heading: "Custom Artwork Proposal",
    des: "Once the concept is approved, we prepare a comprehensive proposal tailored to your art commissions for businesses. This proposal includes all essential details, such as the project scope, total cost for design, project execution, delivery, and installation, duration, payment terms ensuring clarity and transparency about what you can expect. Upon your approval of the proposal and first payment, we proceed to the next stage, bringing your ideas closer to realization.",
  },
  {
    id:4,
    heading: "Design Proposal",
    des: "According to the finalized concept, we create detailed designs to bring your vision to life. For large sculptures, we produce 3D designs that allow you to visualize the final structure from every angle, ensuring precision and clarity. For artworks, we develop detailed sketches to capture the essence of the concept. Revisions to the design are offered up to three times to refine and perfect the details. This ensures that the final artwork aligns with your expectations. Additionally, we provide regular updates during this stage, keeping you informed and involved throughout the design process.",
  },
  {
    id:5,
    heading: "Artist Selection",
    des: "Once you finalize the concept and design, we match you with the perfect artist or artists specializing in your desired style and medium. Our network includes a diverse range of talented professionals, each bringing their unique approach to the creative process. We ensure that the selected artist not only meets your aesthetic preferences but also has experience in similar projects, guaranteeing high-quality results. Furthermore, we facilitate communication between you and the artist when needed, fostering collaboration and alignment on creative decisions.",
  },
  {
    id:6,
    heading: "Creation of Artwork",
    des: "Once the design is finalized, the artist begins creating your personalized artwork for which we require a 50% deposit upfront. The remaining 50% balance is due upon delivery of the completed custom art pieces. This straightforward payment structure ensures both parties remain aligned as the project progresses. Throughout the creation process, we provide regular updates and progress shots, so you can follow along and make sure the piece is evolving just as you envisioned. ",
  },
  {
    id:7,
    heading: "Finalization and Approval ",
    des: "Once the artwork is complete, we will send pictures for your approval. If there are any revisions you'd like us to make, we’ll incorporate them before delivering the final piece. Once the artwork is shipped, we won’t be able to accommodate any changes or modifications on the artwork. Our goal is to ensure your complete satisfaction with the final artwork. ",
  },
  {
    id:8,
    heading: "Delivery & Installation",
    des: "After your artwork is completed, we take care of everything from delivery to installation. Our team ensures safe delivery and professional installation, bringing your commissioned piece to life in your space. The delivery and installation charges will be informed to you during the artwork proposal phase. These charges will vary according to the site location.",
  },
  {
    id:9,
    heading: "Final Touches",
    des: "We provide post-installation and maintenance support within the UAE at additional costs. We do offer specialized post installation services for sculptures. Once the piece is installed, we ensure everything is perfectly positioned, allowing the artwork to enhance your space as intended. Our dedicated post-installation services are designed to ensure your complete satisfaction with the final result.",
  },
]; 
    const tabs = [
      {
        id:1,
        src: "/img/artcollections",
        label: 'Browse',
        content:`<p>Buyers can either browse <a href="/user/search">our website</a> to explore available artwork options using the filters or approach our team directly with your specific requirements. Whether you’re looking for a ready-made piece or a completely customized design, we are here to help you find the perfect fit for your space.</p>`, 
      },
      {
        id:2,
        src: "/img/artcollections",
        label: 'Art Consultation',
        content:`<p>Not sure which piece is right for your space? Our team of art consultants is here to guide you. We'll help you explore options, consider your space's aesthetic, and choose the artwork that aligns with your vision. Whether you're looking for affordable commissioned art online or customizing a piece to suit your needs, Art Smiley is here to guide you every step of the way, ensuring the artwork you select is the perfect fit for your environment. Fill the questionnaire to match your requirements by clicking on  the link <a href="/user/art-consultancy/questionnaire">Art consultancy Questionnaire</a>. After the completion of the form, our art consultancy team will get in touch with you.</p>`, 
      },
      {
        id:3,
        src: "/img/artcollections",
        label: 'Recreate Sold Artwork',
        content:`<p>Fell in love with an artwork that’s no longer available? No worries. With our recreate request, you can ask us to recreate any piece that has been sold, adjusting it to your specific preferences. Whether you want to alter the size, color, or style, our artists will stay true to the original design’s essence while making it uniquely yours.</p>`, 
      },
      {
        id:4,
        src: "/img/artcollections",
        label: 'Customization',
        content:`<p>If you have selected an artwork on our website but you wish to make a few changes, we offer comprehensive customization options to suit your needs. Whether it’s resizing, adjusting the color palette, or switching the medium, our services are designed to help you create a piece that perfectly complements your space. Customizing is simple—just visit the product page of the artwork you want to modify and click the 'Customization' button. This will direct you to a form where you can provide details about the changes you’d like, such as size adjustments, color modifications, or specific preferences. Once you submit the form, our team will review your request and get back to you promptly with a tailored proposal or further clarifications.</p>`, 
      },
      {
        id:5,
        src: "/img/artcollections",
        label: 'Ask A Question',
        content:`<p>If you have any queries regarding artwork, you must go to the product page. Below the artwork, you will find the 'Ask a Question' button. By selecting this button, a pop-up form will appear, allowing you to submit your message or query.. Once you submit the form, our team will promptly review your message and get back to you with the necessary information or a tailored proposal.</p>`,
      },
      {
        id:6,
        src: "/img/artcollections",
        label: 'Make an offer',
        content:`<p>If you’re interested in acquiring an artwork but wish to offer a different price, take advantage of the 'Make an Offer' feature. The Make Offer button lets buyers propose their desired amount directly on the product page. Once your offer is submitted, we’ll coordinate with the artist to facilitate a negotiation, aiming to find a price that works for both parties. This approach ensures an open and flexible transaction, giving you the chance to purchase the artwork within your budget.</p> <p>For those who admire a piece but find it outside their current budget, submitting an alternative offer to the artist is a great solution. The artist will review and respond to your offer within 48 hours. However, please note that during this negotiation period, the artwork will remain available for purchase by other interested buyers.</p> <p>Discount codes and promotional offers are applicable only to artworks sold at full price and cannot be combined with negotiated offers.</p> <p>To calculate the total estimated price, including applicable customs duties and taxes, simply proceed to the checkout process and provide your shipping details.</p>`, 
      },
       
    ];
    const featurebeni =[
      {
        id:1,
        heading: "Expert Consultation",
        des: "Personalized recommendations and curated selections tailored to your specific project needs.",
      },
      {
        id:2,
        heading: "Exclusive Discounts",
        des: "Save 10% on all purchases over $1,500.",
      },
      {
        id:3,
        heading: "Comprehensive Support",
        des: "Dedicated assistance at every step, from choosing the artwork to final installation.",
      },
      {
        id:4,
        heading: "Complete Art Solutions",
        des: "Full-service offerings, including design integration and flawless delivery.",
      },
      {
        id:5,
        heading: "Priority Access",
        des: "Early previews of our latest collections and exclusive updates.",
      },
      {
        id:6,
        heading: "Timely Delivery",
        des: "Efficient and reliable delivery that aligns perfectly with project timelines.",
      },
    ];
    const service_data = [
      {
        id: 1,
        heading: "Personalized Service",
        des: "We work closely with you from start to finish to bring your unique vision to life.",
      },
      {
        id: 2,
        heading: "Global Network of Artists",
        des: "Our curated network of talented artists allows us to offer a wide range of styles, mediums, and techniques.",
      },
      {
        id: 3,
        heading: "Seamless Process",
        des: "Our team manages the entire process, ensuring that commissioning artwork is easy and enjoyable.",
      },
      {
        id: 4,
        heading: "Guaranteed Satisfaction",
        des: "We’re committed to ensuring your commissioned piece is perfect for your space, with satisfaction guaranteed.",
      },
      {
        id: 5,
        heading: "On-time Project Delivery",
        des: "Timely delivery is at the heart of our service. We adhere to agreed timelines and keep you informed at every stage of the project, ensuring your commissioned artwork arrives on schedule and in perfect condition.",
      },
    ];
    const testimoniald = [
      {
        id:1,
        src: "/img/event3",
        name: "Emily",
        designation: "New York",
        des: "The experience of commissioning art with Art Smiley was amazing. The process was smooth, and the artist perfectly captured what I envisioned. I couldn't be happier with my custom piece!",
      },
      {
        id:2,
        src: "/img/event3",
        name: "John",
        designation: "Los Angeles",
        des: "I commissioned a piece for my office and it completely transformed the space. The team at Art Smiley made the whole process enjoyable, and the artist was incredibly professional.",
      },
      {
        id:3,
        src: "/img/event3",
        name: "Sophia",
        designation: "Chicago",
        des: "This piece was the perfect anniversary gift for my partner. The artwork is stunning, and it means so much to us. I highly recommend Art Smiley!",
      },
    ];

    const faq_data = [
      {
        id: 1,
        question: "What is the process for commissioning custom artwork with Art Smiley?",
        answer: `<p>The process is simple and collaborative. Here's how it works:</p>
        <ul>
          <li><span>Initial Consultation:</span> We discuss your vision, style, and the purpose of the artwork.</li>
          <li><span>Concept Development:</span> We create a mood board or design brief to capture the direction.</li>
          <li><span>Artist Selection:</span> We match you with the perfect artist who aligns with your desired style.</li>
          <li><span>Approval & Finalization:</span> You provide feedback on the initial concepts, ensuring it's exactly what you envision.</li>
          <li><span>Creation of Artwork:</span> The artist creates your custom piece, and we keep you updated with progress shots.</li>
          <li><span>Delivery & Installation:</span> We manage the delivery and professional installation of the artwork.</li>
          <li><span>Final Touches:</span> We ensure your piece is perfectly placed, and offer post-installation support.</li>
        </ul>
        `
      },
      {
        id: 2,
        question: "What types of artwork can I commission?",
        answer: "<p>You can commission any type of artwork that fits your style and space. This includes residential, corporate, or public art in a variety of categories such as paintings, sculptures, photography, or digital art.</p>"
      },
      {
        id: 3,
        question: "How do you match me with an artist?",
        answer: "<p>We have a curated network of talented artists with diverse styles and mediums. Based on your vision, we’ll match you with an artist who specializes in the look and feel which you expect for your artwork.</p>"
      },
      {
        id: 4,
        question: "How much does it cost to commission a piece of artwork?",
        answer: "<p>The cost of commissioning artwork depends on factors like the size, complexity, and medium of the piece, as well as the artist’s experience. We will provide you with a customized quote after our initial consultation.</p>"
      },
      {
        id: 5,
        question: "How long does it take to complete my commissioned artwork?",
        answer: "<p>The timeline depends on the complexity of the project and the artist's schedule. On average, the process takes between 4-8 weeks. We will provide you with an estimated completion time during the consultation phase.</p>"
      },
      {
        id: 6,
        question: "Can I request revisions during the process?",
        answer: "<p>Yes! We want you to be 100% satisfied with your custom piece. After we present initial concepts, you’ll have the chance to provide feedback and request any changes before the final artwork is created.</p>"
      },
      {
        id: 7,
        question: "Do you provide installation services?",
        answer: "<p>Yes, we handle the delivery and professional installation of your artwork to ensure it’s perfectly placed and secure in your space across UAE.</p>"
      },
      {
        id: 8,
        question: "Can I commission a piece as a gift?",
        answer: "<p>Absolutely! Custom artwork makes a unique and meaningful gift. We can help create something that will be cherished for years to come whether it's for a birthday, anniversary, or special occasion.</p>"
      },
      {
        id: 9,
        question: "What if I need help deciding on the style or type of art?",
        answer: "<p>Our dedicated team is here to assist you throughout the process. We’ll offer guidance and suggestions based on your space, preferences, and the purpose of the artwork.</p>"
      },
      {
        id: 10,
        question: "What happens after my artwork is installed?",
        answer: "<p>After your artwork is installed, we’ll ensure everything is in place and looks perfect. We offer post-installation support to make sure you are completely satisfied with how the piece fits into your space.</p>"
      },
    ];

const [exclusiveExpanded, setExclusiveExpanded] = React.useState('excpanel0'); // Default to the first panel

const handleExclusiveChange = (panel) => (event, isExpanded) => {
  setExclusiveExpanded(isExpanded ? panel : false);
};

const prevButtonRef = useRef(null);
        const nextButtonRef = useRef(null);

  const [expandedIndex, setExpandedIndex] = useState(null);
          const [isOverflowing, setIsOverflowing] = useState(false);
          
          const contentRef = useRef(null);
          
          useEffect(() => {
            if (contentRef.current) {
              const isContentOverflowing =
                contentRef.current.scrollHeight > contentRef.current.clientHeight;
              setIsOverflowing(isContentOverflowing);
            }
          }, []);
          
          const toggleReadMore = (index) => {
            setExpandedIndex(expandedIndex === index ? null : index); // Toggle state
          };

          const [expanded, setExpanded] = React.useState(false);
          const handleChange = (panel) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
          };

          const scrollToSection = () => {
            const section = document.getElementById('target-section');
            if (section) {
              // Calculate offset to account for the fixed header
              const headerOffset = 100; // Height of your fixed header
              const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
              const offsetPosition = sectionPosition - headerOffset;
        
              // Scroll to the calculated position
              window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
              });
            }
          };
  return (
    <>
    <React.Fragment>
       <CssBaseline />
    <section className={styles.hero_main}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.pageitle} ${styles.max_width}`}>
              <h1>Commission Your Dream Artwork with Art Smiley</h1>
              <p className={`${styles.withor} ${styles.whitext}`}>Your Vision, Our Art — Create Custom Art That’s Just for You.</p>
              <button 
             onClick={(e) => {
              e.preventDefault(); // Prevent default link behavior
              scrollToSection(); // Call scroll function
            }}
              className="apply_ltbtn"
            >
              Custom Art Inquiry
            </button>
            </div>
</Container>
        </section>
        <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width1}`}>
          <p className={styles.withor}>
          At Art Smiley, we specialize in custom art commissions, helping you bring your dream piece to life. Whether you're looking for a statement artwork for your home or a unique gift for a loved one, we’ll work with a talented artist to create a one-of-a-kind masterpiece tailored specifically to your space and vision.</p>
            <p className={styles.withor}>
            Creating a commissioned artwork is a personalized process where we partner with you and the artist to craft a piece that aligns seamlessly with your vision, enhances your space, and expresses your unique personality.</p>
          </div>
          <div className={styles.taldartist}>
            <Grid container spacing={2} sx={{justifyContent:"center"}}>
            {exclusive_data.map((val) => (
            <Grid item xs={12} sm={6} md={4} key={val.id}>
              <div className={styles.talhg}>
                <div className={styles.talgbx}>
                <div className={styles.talenimg}>
                    <img
                    alt="img"
                    src={`${val.src}.png`}
                    className="img-fluid"
                  />
                    </div>
                    <div className={styles.talentcont}>
                      <h4>{val.heading}</h4>
                      <p>{val.des}</p>
                    </div>
                </div>
              </div> 
            </Grid>
            ))}
            </Grid>
            </div>
          </Container>
          </section>
          <section className={styles.sec_scexcl}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
        
        <Grid item xs={12} sm={6} md={6} order={{ xs: 2, sm: 1 }}>
         
              <div className={styles.vintageframeDiv}>
                <img
                  className="img-fluid"
                  src={ "/img/vintage-wood-frame.png"} alt=""
                />
              </div>
        </Grid>
        <Grid item xs={12} sm={5} md={5} order={{ xs: 1, sm: 2 }}>
        <div className={styles.leftText}>
              <h4 className={styles.abttitle}>Payment Terms for Commissioned Artwork</h4>
              <p className={styles.prtra}>A 50% advance payment is required to initiate the creation of a commissioned artwork. This initial deposit reserves the artist’s time and resources to work on your personalized piece. The remaining 50% must be paid after you have approved the final artwork and then the artwork will be shipped to you.</p>
              <p className={styles.prtra}>These terms are designed to streamline the process, enabling our team to focus on delivering a high-quality, custom creation that aligns perfectly with your vision.</p>
              </div>
        </Grid>
        </Grid>
        </Container>
      </section>
      <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.max_width} ${styles.text_center}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title}`}>Cancellation Policy</h3>
          <p className={styles.withor}>We understand that circumstances may change, and we aim to accommodate your needs. However, because commissioned artworks are tailored to your specifications, cancellations are only permitted during the production. If the product is approved and shipped then artwork cancellation is not possible.</p>
            
          </div>
        <div className={styles.taldartist}>
            <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
            <Grid item md={5} sm={6} xs={12}>
                      <div>
                          <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
                          </div>
                      </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6}>
              <div className={styles.stboxbn}>
                              <div className={styles.hbox1}>
                                <div className={styles.idnumbr}><RiSettings6Line /> </div>
                                <h4>Prior to Production</h4>
                        <p>If you cancel before work starts, the initial deposit will be refunded after deducting administrative fees.</p>
                   
                              </div>
                              </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
              <div className={styles.stboxbn}>
                              <div className={styles.hbox1}>
                                <div className={styles.idnumbr}><RiSettings2Line /> </div>
                                <h4>Once Production Starts</h4>
                      <p>If you cancel the order after the production has already commenced, the initial deposit will be refunded excluding the  administration charges  and production costs incurred so far, as it compensates for the time, effort, and materials invested.  This policy is designed to balance flexibility for clients with fairness to our artists, ensuring a professional and transparent process</p>
                   </div>
                              </div>
              </Grid>
              </Grid>
            
                              
            </Grid>
            </Grid>
            </div>
        </Container>
      </section>
          <section className={styles.sec_scexcl}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width1}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Steps to Order Commissioned Art</h3>
          <p className={styles.withor}>At Art Smiley, we specialize in commissioned oil paintings, sculptures, acrylic paintings, watercolor paintings, creating unique works of art that align perfectly with your vision and space. Whether for a corporate environment, a residential area, or a public space, we take a personalized approach to design commissioned artwork that truly reflects your style and needs.</p>
          </div>
          <Grid container spacing={2}>
          
          {remarableevents.map((val, index) => (
                <Grid item xs={12} sm={6} md={4} key={val.id} >
                  <div  key={index} className={styles.stboxh}>
                <div className={styles.rechhbox}>
                  <div className={styles.sticon}>
                    <p className={styles.hgrd}>{val.id}</p>
                  </div>
                  <h4>{val.heading}</h4>
                  <div className={styles.stcont}>
                     <p>{val.des}</p>
    </div>
                </div>
                </div> 
                </Grid>
          ))}
            </Grid>
          </Container>
          </section>
          <section className={styles.sec_sc}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.max_width1} ${styles.text_center}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title}`}>Personalized Artwork</h3>
          </div>
          <div className={styles.mt_2}>
          <Grid container spacing={2} sx={{justifyContent:"center"}}>
            {tabs.map((val, index) => (
            <Grid item xs={12} sm={6} md={6} key={val.id}>
              <div key={index}  className={styles.talhg}>
                <div className={styles.talgbx}>
                <div className={styles.talenimg}>
                    <img
                    alt="img"
                    src={`${val.src}.png`}
                    className="img-fluid"
                  />
                    </div>
                    <div className={styles.talentcont}>
                      <h4>{val.label}</h4>
                      <div className={styles.stcont}>
                    {/* <p>{val.des}</p> */}
                    <div
      className={`${styles.textContent} ${
        expandedIndex === index ? styles.expanded : ""
      }`}
      ref={contentRef}
      style={{
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: expandedIndex === index ? "unset" : 3, // Show 3 lines or full content
        overflow: "hidden",
      }}
      dangerouslySetInnerHTML={{ __html: val.content }}
    />
    
    {isOverflowing && (
      <button
        className={styles.readMoreBtn}
        onClick={() => toggleReadMore(index)}
      >
        {expandedIndex === index ? "Read Less" : "Read More"}
      </button>
    )}
    </div>
                      
                    </div>
                </div>
              </div> 
            </Grid>
            ))}
            </Grid>
       
      </div>
        </Container>
      </section>

      <section className={styles.sec_scexcl}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width1}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Art Smiley Trade Program: Tailored Art Solutions for Professionals</h3>
          <p className={styles.withor}>The Art Smiley Trade Program is designed exclusively for interior designers, architects, art consultants, and other professionals seeking to enrich their projects with unique, high-quality artwork. By simplifying the art acquisition process, the program provides tailored selections, expert consultation, and end-to-end support to help transform residential and commercial spaces. Participants gain access to a diverse collection of original artworks, ensuring each piece enhances the aesthetic and ambiance of their designs. With a focus on quality, creativity, and seamless service, Art Smiley empowers professionals to create inspiring environments effortlessly.</p>
          </div>
          <div className={styles.spcebtnm}>
          <h4 className={styles.tekd}>Exclusive Benefits of the Art Smiley Trade Program</h4>
          <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
          <Grid item md={6} xs={12}>
         
            <div>
            <Grid container spacing={2}>
            {featurebeni.map((val, index) => (
                <Grid item xs={12} sm={6} md={6}  key={index} >
                  <div  className={styles.feabox}>
                    <div className={styles.feabc}>
                      <div className={styles.iconst}>
                      <RiCheckFill className={styles.usicon} />
                      </div>
                      <div>
                        <h4>{val.heading}</h4>
                        <p>{val.des}</p>
                      </div>
                    </div>
                    </div>
                  </Grid>
                  ))}
            </Grid>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
          <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
          </Grid>
          </Grid>
          <div className={`${styles.mt_5} ${styles.text_center}`}>
      <a href="/user/art-consultancy/trade-program-application"
                  className={`${styles.mt_2} apply_btn`}
                > Join our Trade Program
                </a> 
      </div>
          </div>
          
          </Container>
          </section>
          <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Why Choose Art Smiley for Your Commissioned Artwork?</h3>
          </div>
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
          <Grid item md={6} sm={6} xs={12}>
          <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
          </Grid>
          <Grid item md={5} sm={6} xs={12}>
      <div className={`${styles.accordsc}`}>
      {service_data.map((val, index) => {
      const panelId = `excpanel${index}`; // Dynamic panel ID
      return (
        <Accordion
          key={panelId}
          expanded={exclusiveExpanded === panelId} // Check if the panel is expanded
          onChange={handleExclusiveChange(panelId)}
          sx={{
            "& .MuiAccordionSummary-contentGutters": {
              margin: "10px 0", // Adjust as needed
            },
            "&.MuiPaper-root": {
              boxShadow: "none",
              borderBottom: "1px solid #D9DBE9",
              marginBottom:"0",
              borderBottomLeftRadius:"0",
              borderBottomRightRadius:"0",
            },
            "&.MuiAccordion-root::before":{
              display:"none",
            },
            "& .Mui-expanded": {
              minHeight:"auto !important",
              margin:"0 0 10px 0 !important"
            },
            "& .MuiAccordionSummary-expandIconWrapper":{
              background:"#B7B7B7",
              opacity:".3",
              textAlign: "center",
              borderRadius:"8px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
              background:"#1C1C1C",
              opacity:"1",
              color:"#fff",
            },
            "& .MuiAccordionDetails-root":{
              paddingTop:"0"
            },
            
          }}// Handle expansion state
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${panelId}-content`}
            id={`${panelId}-header`}
          >
            <h4 className={styles.benfhdgn}>{val.heading}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={styles.bendesc}>{val.des}</p>
          </AccordionDetails>
        </Accordion>
      );
    })}

      </div>
          </Grid>
          </Grid>
          </div>
          </Container>
          </section>
          <section className={styles.sec_scexcl}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>What Our Clients Are Saying?</h3>
          </div>
          <section className={`testmo-slider ${styles.sections}`}>
          <Swiper
        modules={[Navigation, Autoplay]}
                slidesPerView={1}
                spaceBetween={10}
                loop={true}
                autoplay={{
                  delay: 3000, // Set autoplay delay in milliseconds (3 seconds here)
                  disableOnInteraction: false, // Allows autoplay to continue after user interactions
                }}
                onInit={(swiper) => {
                  swiper.params.navigation.prevEl = prevButtonRef.current;
                  swiper.params.navigation.nextEl = nextButtonRef.current;
                  swiper.navigation.init();
                  swiper.navigation.update();
                }}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        }}
      >
{testimoniald.map((val, index) => (
                    <SwiperSlide key={index}>
                      <div className={styles.tehgt}>
                       <div className={styles.testmibox}>
                      <div className={styles.tecont}>
                        <div className={styles.hders}>
                        <div className={styles.testimonial_img_div}>
                          <img
                            src={`${val.src}.png`}
                            width="80px"
                            height="80px"
                            alt=""
                          />
                        </div>
                        <div className={styles.test_metainfo}>
                        <h5 className={styles.test_card_heading}>
                        {val.name}
                          </h5>
                          <h6 className={styles.test_card_subhead}>
                          {val.designation}
                          </h6>
                        </div>
                        </div>
                        <div className={styles.test_cont}>
                          <h4>{val.hnd}</h4>
                          <p className={styles.test_card_des}>{val.des}</p>
                        </div>
                        </div>
                      </div>
                      </div>
                    </SwiperSlide>
                 ))}
              </Swiper>
              <div className="tesarrow">
          <div ref={prevButtonRef} className="swiper-button-prev">
                    <RiArrowLeftLine />
                  </div>
                  <div ref={nextButtonRef} className="swiper-button-next">
                    <RiArrowRightLine />
                  </div>
      </div>
        </section>
       
        </Container>
        </section>
        <section className={styles.arconsec}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <Grid container spacing={2} sx={{alignItems:"center"}}>
              <Grid item xs={12} md={8}>
                <h3>Get Started Today</h3>
                <p  className={`${styles.pytrg}`}>Ready to bring your vision to life with custom artwork? Get in touch with us today and let’s start the creative process!</p>
              </Grid>
              <Grid item xs={12} md={4}>
              <div className={styles.text_center}>
              <button onClick={() => handleNavigate('/user/art-consultancy')} className="apply_ltbtn">Your Personalized Artwork</button>
              </div>
                
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className={styles.sec_sc} id="target-section">
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Inquiry Form</h3>
          </div>
        <Grid container spacing={2} sx={{justifyContent:"center"}}>
        <Grid item xs={12} sm={8} md={8}>
        <div className={styles.stcgbox}>
                  <Grid container spacing={2}>
                   
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>First Name *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="first_name"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Last Name *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="last_name"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Phone *</label>
                <PhoneInput
                  inputStyle={{ background: "transparent" }}
                  containerStyle={{
                    background: "transparent",
                    width: "100%",
                  }}
                  placeholder="Enter your phone number"
                  country={"in"}
                  name="phone"
                
                />
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Email *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"

                  name="email"
                />
                </div>
                  </Grid>
                  
                  <Grid item xs={12} sm={12} md={12}>
                  <div className="form-group1">
                <label>Designation *</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name="designation"
                />
                
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  <div className="form-group1">
                <label>Description of the requirement</label>
                <textarea
                  maxRows={5}
                  minRows={5}
                 className="form-control"
                  // onChange={props.handleChangedata}
                  // value={props.uploadArt.description}
                  name="message"
                  fullWidth
                  placeholder=""
                />
                <small>Upload Reference Images</small>
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Size (width and height)</label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Budget </label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Timeline  </label>
                <input
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  className="form-control"
                  name=""
                />
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Category </label>
                <select className="form-control">
                  <option>Select the Option</option>
                  <option></option>
                </select>
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Medium </label>
                <select className="form-control">
                  <option>Select the Option</option>
                  <option></option>
                </select>
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Painting </label>
                <select className="form-control">
                  <option>Select the Option</option>
                  <option></option>
                </select>
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Sculpture </label>
                <select className="form-control">
                  <option>Select the Option</option>
                  <option></option>
                </select>
                </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group1">
                <label>Prints </label>
                <select className="form-control">
                  <option>Select the Option</option>
                  <option></option>
                </select>
                </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  <div className={styles.text_right}>
               <button
                className="apply_btn"
              >
                Submit
              </button>
               </div>
                  </Grid>
                  </Grid>
</div>
          </Grid>
        </Grid>
        
        </Container>
      </section>
      <section className={styles.sec_scexcl}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Terms and Conditions for Commissioned Artwork</h3>
          </div>
          <ul className={styles.termlist}>
            <li><span>Customization and Concept Approval:</span> Buyers can submit their vision using the "Custom Art Inquiry" form. We collaborate on concept finalization, providing up to three design revisions. During the design phase, 3D designs will be created for large sculptures,  and detailed sketches will be provided for artworks.</li>
            <li><span>Payment Terms:</span> A 50% deposit is required to initiate the process. The remaining 50% is due after final approval before delivery. These terms may vary according to the project proposal.</li>
            <li><span>Cancellation Policy:</span> 
                  <ol>
                    <li><span>Before Production:</span> Cancellation before work begins will result in a refund minus administrative fees.</li>
                    <li><span>After Production Starts:</span> Cancellations will incur deductions for administrative charges and production costs already incurred.</li>
                    <li><span>No Cancellation Post-Approval:</span> Once the artwork is approved and shipped, cancellation is not possible.</li>
                    
                  </ol>
            </li>
            <li><span>Refund:</span> If the artwork is damaged or the wrong product is delivered, refund / replace is applicable.</li>
            <li><span>Artwork Creation and Updates:</span>  The selected artist will create the commissioned piece based on the approved design. Regular updates and progress shots are provided during the process.</li>
            <li><span>Delivery and Installation:</span> Completed artworks will be delivered safely. Our installation services ensure the artwork is perfectly positioned. Depending on the project and location, these charges may vary and will be mentioned in the project proposal. Artwork shipping process will be initiated, once the payment is cleared as per the proposal.</li>
            <li><span>Modifications:</span> Buyers can request changes to their commissioned project before shipping. Once the artwork is shipped, we won’t be able to accommodate any changes or modifications on the artwork.</li>
            <li><span>Copyright:</span> The copyrights for all artwork created will be retained by the artist and the gallery. Reproduction rights for the artwork will be managed and attributed by both the artist and the gallery. When the artwork is posted on social media platforms, the credits for the artwork must be given to the gallery and the artist. Any use of the artwork beyond personal enjoyment must be authorized by the copyright holders.</li>
          </ul>
          </Container>
          </section>
          <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.max_width1} ${styles.text_center}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title}`}>FAQ</h3> 
          </div>
             <div className={`${styles.accordsc} ${styles.faqx}`}>
             {faq_data.map((item) => (
      <Accordion
        key={item.id}
        expanded={expanded === `panel${item.id}`}
        onChange={handleChange(`panel${item.id}`)}
        sx={{
          "& .MuiAccordionSummary-contentGutters": {
            margin: "10px 0", // Adjust as needed
          },
          "&.MuiPaper-root": {
            boxShadow: "none",
            borderBottom: "1px solid #D9DBE9",
            marginBottom:"0",
            borderBottomLeftRadius:"0",
            borderBottomRightRadius:"0",
          },
          "&.MuiAccordion-root::before":{
            display:"none",
          },
          "& .Mui-expanded": {
            minHeight:"auto !important",
            margin:"0 0 10px 0 !important"
          },
          "& .MuiAccordionSummary-expandIconWrapper":{
            background:"#B7B7B7",
            opacity:".3",
            textAlign: "center",
            borderRadius:"8px",
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
            background:"#1C1C1C",
            opacity:"1",
            color:"#fff",
          },
          "& .MuiAccordionDetails-root":{
            paddingTop:"0"
          },
          
        }}// Handle expansion state
      >
        <AccordionSummary
          // expandIcon={
          //   expanded === `panel${item.id}` ? (
          //     <RemoveIcon  />
          //   ) : (
          //     <AddIcon/>
          //   )
          // }
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${item.id}bh-content`}
          id={`panel${item.id}bh-header`}
          className={styles.accordian_show}
        >
           
          <h3 className={styles.faqhdr}>
            {expanded === `panel${item.id}` }
            {/* && <div className={styles.accodian_no}>{item.id}</div> */}
            {item.question}
          </h3>
        </AccordionSummary>
        <AccordionDetails >
        <div dangerouslySetInnerHTML={{ __html: item.answer }} />
        </AccordionDetails>
      </Accordion>
    ))}
             </div>
          </Container>
          </section>
          

          </React.Fragment>
    </>
  );
}

export default CommisionedArt;
