import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import Threedots from "../../Table/Threedots/Threedots";
import styles from "./Notification.module.css";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import homeImage from "../../../../../constant";
import { FaSearch } from "react-icons/fa";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";

export default function Notify(props) {
  console.log("props notification", props);

  const [searchText, setSearchText] = useState("");
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div className={styles.mains}>
      <div>
        {/* <InputGroup className="mb-3">
          <Form.Control
            placeholder="Search notifications..."
            aria-label="Search"
            value={searchText}
            onChange={handleSearchChange}
          />
          
        </InputGroup> */}
      </div>
      <div className={styles.components}>
        {props?.data?.length > 0 ? (
          <>           
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="notifications table">
                <TableHead className={styles.notification_table}>
                  <TableRow>
                    <TableCell sx={{
                      fontSize:"15px",
                      fontWeight:"500",
                      fontFamily:"DM Sans",
                      textTransform:"capitalize",
                      color:"#1C1C1C"
                    }}>Sl.No</TableCell>
                    <TableCell sx={{
                      fontSize:"15px",
                      fontWeight:"500",
                      fontFamily:"DM Sans",
                      textTransform:"capitalize",
                      color:"#1C1C1C"
                    }} align="left">Title</TableCell>
                    <TableCell sx={{
                      fontSize:"15px",
                      fontWeight:"500",
                      fontFamily:"DM Sans",
                      textTransform:"capitalize",
                      color:"#1C1C1C"
                    }} align="left">Description</TableCell>
                    <TableCell sx={{
                      fontSize:"15px",
                      fontWeight:"500",
                      fontFamily:"DM Sans",
                      textTransform:"capitalize",
                      color:"#1C1C1C"
                    }} align="center">Date / Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props?.data?.map((item, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={{
                        fontSize:"14px",
                        fontWeight:"400",
                        fontFamily:"DM Sans",
                        textTransform:"capitalize",
                        color:"#636365"
                      }}>{i + 1}</TableCell>
                      <TableCell align="left" sx={{
                        fontSize:"14px",
                        fontWeight:"400",
                        fontFamily:"DM Sans",
                        textTransform:"capitalize",
                        color:"#636365"
                      }}>
                        <div>
                          <span>{item?.title}</span>
                        </div>
                      </TableCell>
                      <TableCell align="left" sx={{
                        fontSize:"14px",
                        fontWeight:"400",
                        fontFamily:"DM Sans",
                        textTransform:"capitalize",
                        color:"#636365"
                      }}>
                        <div>
                          <span>{item?.description}</span>
                        </div>
                      </TableCell>
                      <TableCell align="center" sx={{
                        fontSize:"14px",
                        fontWeight:"400",
                        fontFamily:"DM Sans",
                        textTransform:"capitalize",
                        color:"#636365"
                      }}>{moment(item?.createdAt).format("DD-MM-YYYY / HH:mm")}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <h5 style={{ textAlign: "center" }}>No Notification Found !</h5>
        )}
      </div>
    </div>
  );
}
