import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  TextField,
  Container,
  Grid
} from "@mui/material";
import React, { useState } from "react";
import styles from "./Applysecondaffiliate.module.css";
import { useDispatch } from "react-redux";
import { handleApplyAffiliates } from "../../../../../State/api/artistapi";
import { AlertsSuccess, Errornotify } from "../../../../../common/alert/Alert";
import { useNavigate } from "react-router-dom";
import { set } from "date-fns";
import { be } from "date-fns/locale";
import ReactQuill from "react-quill";
import { validationForSecondFormAffiliate } from "../../../../../validation/validationForSecondAffiliate";

export default function Applysecondaffileate({
  apply,
  setApply,
  handleChangeApplyData,
  handleChangeTextEditorAffiliate
}) {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  console.log(errors,'errors')
  const [validUrls, setValidUrls] = useState({ isValidWebsiteUrl: true, isValidAppUrl: true });
  const [otherPromotionMethod, setOtherPromotionMethod] = useState({ value: "", errorMessage: "" });
  const [traffic_drivation_others, setTraffic_Drivation_Others] = useState({ value: "", errorMessage: "" });


  const [otherSocialMethod, setOtherSocialMethod] = useState({ value: "", errorMessage: "" });

  console.log(otherPromotionMethod.value,'otherPromotionMethod.value')
 // const [hearAboutUsOther, setHearAboutUsOther] = useState({ value: "", errorMessage: "" });
  //const [bestDescripbeOther, setBestDescripeOther] = useState({ value: "", errorMessage: "" });
  const [trafficDriveOther, setTrafficDriveOthers] = useState({ value: "", errorMessage: "" });
  const [mobileAppWebsiteChecked, setMobileAppWebsiteChecked] =
    React.useState(false);
  const [promoteMethod, setPromoteMethod] = useState("");
  const [descriptionOthers, setDescriptionOthers] = useState(false);
  const [trafficOthers, setTrafficOthers] = useState(false);
  const regex =
    /^(https?:\/\/)?(www\.)?[a-zA-Z][a-zA-Z0-9-]*(\.[a-zA-Z]{2,})(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;

  const dispatch = useDispatch();
  const handleApplyAffiliate = () => {
    const isWebsiteUrlValid = !apply.website_url || (apply.website_url.trim() && apply.website_url.match(regex));
    const isAppUrlValid = !apply.app_url || (apply.app_url.trim() && apply.app_url.match(regex));

   
    setValidUrls({
      ...validUrls,
      isValidWebsiteUrl: isWebsiteUrlValid,
      isValidAppUrl: isAppUrlValid
    });
    const { applyForm, isValidSecondForm } = validationForSecondFormAffiliate(
      apply.promote_art_smiley,
      apply.audience_interest,
      apply.build_links,
      apply.generate_income,
      apply.unique_visitor_count,
      apply.primary_reason_to_join,
      apply.hear_about_us,
      apply.traffic_drivation,
      apply.description,
      apply.app_url,
      apply.website_url,
      apply.otherPromotionMethod,
      apply.otherSocialMethod,
      apply.hear_about_us_other,
      apply.description_others,
      apply.traffic_drivation_others,
      apply.completion,
      apply.platform_influence_marketing,
      apply.other_social_influence,
      mobileAppWebsiteChecked 
    )
    
     if (
       isValidSecondForm
     ) {
      setErrors({});
      dispatch(handleApplyAffiliates(apply, navigate));
     } else {
       setErrors(applyForm)
      
     }
  };


  const handleCheckboxChange = (event) => {
    setMobileAppWebsiteChecked(event.target.checked);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPromoteMethod(value);
    handleChangeApplyData(event);
  };



  const handleMultiSelectBuilds = (event) => {
    const {
      target: { value },
    } = event;
    setApply((prevApply) => ({
      ...prevApply,
      build_links: typeof value === 'string' ? value.split(',') : value,
    }));
  };
  const handleChangeMultiselectDescription = (event) => {
    const {
      target: { value },
    } = event;
    setApply((prevApply) => ({
      ...prevApply,
      promote_art_smiley: typeof value === 'string' ? value.split(',') : value,
    }));
  };
  const handleChangeMultiselectDescriptionDescription = (event) => {
    const {
      target: { value },
    } = event;
    setApply((prevApply) => ({
      ...prevApply,
      description: typeof value === 'string' ? value.split(',') : value,
    }));
  };
  // Handle description select change
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setApply({
      ...apply,
      description: typeof value === "string" ? value.split(",") : value,
    });

    // Check if "Others" is selected in description
    setDescriptionOthers(value.includes("Others"));
  };

  // Handle traffic_drivation select change
  const handleTrafficChange = (e) => {
    const value = e.target.value;
    setApply({
      ...apply,
      traffic_drivation: typeof value === "string" ? value.split(",") : value,
    });

    // Check if "Others" is selected in traffic_drivation
    setTrafficOthers(value.includes("Others"));
  };
  const options = [
    "Social media",
    "Website",
    "Networking",
    "Influencer Marketing",
    "Mobile App",
    "Offline",
    "Direct Linking",
    "Others"
  ];

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",

  ];
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      <Container maxWidth="xl" sx={{
            paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
            paddingRight: { xs: '16px', sm: '24px', md: '100px' },
          }}>
            <Grid container spacing={2} sx={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                       <Grid item xs={12} sm={10} md={8}>
                                      <div className={styles.components}>
        <div className={styles.mainHead}>
          <span>Join as Affiliate</span>
          <span>
            <div className={styles.access_roles}>
              <div className={styles.main_head_toggle}>Mobile App/Website</div>
              <div className={styles.toggle_btn}>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    name="mobileAppWebsite"
                    checked={mobileAppWebsiteChecked}
                    onChange={handleCheckboxChange}
                  />
                  <span className={`${styles.slider} ${styles.round}`}></span>
                </label>
              </div>
            </div>
          </span>
        </div>
          <div>
            <div className="form-group">
            <label>
            How do you plan to promote Art Smiley services? *
            </label>
            <Select
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
              },
              "& .MuiInputBase-input":{
              padding:".375rem .75rem",
              height:"auto",
              borderRadius:".25rem",
              fontSize:"14px",
              fontWeight:"400"
              },
              border:"1px solid #ced4da",
              borderRadius:".25rem",
              width:"100%"
            }}
              fullWidth
              multiple
              name="promote_art_smiley"
              value={apply?.promote_art_smiley || []}
              onChange={handleChangeMultiselectDescription}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
              {errors?.promote_art_smiley &&  <small style={{color:"red",fontWeight:'500'}}>{errors.promote_art_smiley}</small>}
            </div>
            {apply.promote_art_smiley.includes("Others") && (
              <div className="form-group">
                <input
                  className="form-control"
                  name="otherPromotionMethod"
                  value={apply.otherPromotionMethod }
  onChange={(e) =>
    setApply((prevApply) => ({
      ...prevApply,
      otherPromotionMethod: e.target.value,
    }))
  }
                  placeholder="Please specify"
                
                />
                  {errors?.promote_art_smiley_other &&  <span style={{color:"red",fontWeight:'500',fontSize:"14"}}>{errors.promote_art_smiley_other}</span>}
              </div>
            )}
            {apply.promote_art_smiley.includes("Social media") && (
              <div className="form-group">
                <label>Please provide us the links of your social media platform</label>
                <input
                  name="otherSocialMethod"
                  value={apply.otherSocialMethod}
                 placeholder="www.example.com"
                  variant="outlined"
                  className="form-control"
                  onChange={(e) =>
                    setApply((prevApply) => ({
                      ...prevApply,
                      otherSocialMethod: e.target.value,
                    }))
                  }
                 
                />
                  {errors?.promote_art_smiley_other_social &&  <span style={{color:"red",fontWeight:'500',fontSize:"14"}}>{errors.promote_art_smiley_other_social}</span>}
              </div>
            )}
            {apply.promote_art_smiley.includes("Influencer Marketing") && (
  <>
             <div className="form-group">
                <label> 
 	
   What is your best strategy for influencer marketing?</label>
   <ReactQuill
              className="reactQuilStle"
              
              name="strategy_influence_marketing"
              value={apply.strategy_influence_marketing || ""}
            onChange={handleChangeTextEditorAffiliate("strategy_influence_marketing")}
            
              theme="snow"
              modules={modules}
              formats={formats}
            />
               
                 
              </div>

              <div className="form-group">
<label> 

What platform do you use for influencer marketing?*</label>
<Select
                
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  },
                  "& .MuiInputBase-input":{
                  padding:".375rem .75rem",
                  height:"auto",
                  borderRadius:".25rem",
                  fontSize:"14px",
                  fontWeight:"400"
                  },
                  border:"1px solid #ced4da",
                  borderRadius:".25rem",
                  width:"100%"
                }}
                name="platform_influence_marketing"
                 value={apply.platform_influence_marketing}
                 onChange={handleChangeApplyData}
                input={<OutlinedInput />}
              >
                <MenuItem value="YouTube">YouTube</MenuItem>
                <MenuItem value="Instagram">Instagram</MenuItem>
                <MenuItem value="Facebook">Facebook</MenuItem>
                <MenuItem value="TikTok">TikTok</MenuItem>
                <MenuItem value="Reddit">Reddit</MenuItem>

              
                <MenuItem value="Others">Others</MenuItem>
              </Select> 
                {errors?.platform_influence_marketing &&  <span style={{color:"red",fontWeight:'500',fontSize:"14"}}>{errors.platform_influence_marketing}</span>} 
             
               {apply.platform_influence_marketing.includes("Others") && (
                <div className="form-group">
                  <input
                    fullWidth
                    name="other_social_influence"
                    placeholder="Please specify"
                    variant="outlined"
                    value={apply.other_social_influence || ""} 
      onChange={handleChangeApplyData}
                    className="form-control"
                 
                  />
                    {errors?.other_social_influence &&  <span style={{color:"red",fontWeight:'500',fontSize:"14"}}>{errors.other_social_influence}</span>} 
                </div>
               )}
              
 
</div>
</>
            )}
            <div className="form-group">
            <label>
            Tell us about your audience interests *
            </label>
            <ReactQuill
              className="reactQuilStle"
              
              name="audience_interest"

              value={apply.audience_interest}
              onChange={handleChangeTextEditorAffiliate("audience_interest")}
              theme="snow"
              modules={modules}
              formats={formats}
            />
                {errors?.audience_interest &&  <span style={{color:"red",fontWeight:'500',fontSize:"14"}}>{errors.audience_interest}</span>}
           
            </div>
            {mobileAppWebsiteChecked && (
          <>
          <div className="form-group">
            <label>Enter your website URL</label>
            <input
            className="form-control"
              placeholder="www.example.com"
              name="website_url"
              variant="outlined"
              value={apply.website_url}
              onChange={handleChangeApplyData}
            />
            {errors?.website_url &&  <span style={{color:"red",fontWeight:'500',fontSize:"14"}}>{errors.website_url}</span>}
          </div>

          <div className="form-group">
            <label>
              Enter your mobile app URL
            </label>
            <input
            className="form-control"
              placeholder="www.example.com"
              name="app_url"
              value={apply.app_url}
              variant="outlined"
              onChange={handleChangeApplyData}
            />
          {errors?.app_url &&  <span style={{color:"red",fontWeight:'500',fontSize:"14"}}>{errors.app_url}</span>}
          </div>
          </>
          )}
          {mobileAppWebsiteChecked && (
            <div className="form-group">
              <label>
                Which of the following best describes your website or mobile
                apps?
              </label>
              <Select
              sx={{
							  "& .MuiOutlinedInput-notchedOutline": {
								border: "none",
							  },
							  "& .MuiInputBase-input":{
								padding:".375rem .75rem",
								height:"auto",
								borderRadius:".25rem",
								fontSize:"14px",
								fontWeight:"400"
							  },
							  border:"1px solid #ced4da",
							  borderRadius:".25rem",
							  width:"100%"
							}}
                fullWidth
                multiple
                name="description"
                value={apply.description || []}
                onChange={handleChangeMultiselectDescriptionDescription}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                <MenuItem value="Interior Decor">Interior Decor</MenuItem>
                <MenuItem value="Architect">Architect</MenuItem>
                <MenuItem value="Art blogs">Art blogs</MenuItem>
                <MenuItem value="Art Forums">Art Forums</MenuItem>
                <MenuItem value="B2B">B2B</MenuItem>
                <MenuItem value="B2C">B2C</MenuItem>
                <MenuItem value="Art & Craft Stores">
                  Art & Craft Stores
                </MenuItem>
                <MenuItem value="Mobile Search">Mobile Search</MenuItem>
                <MenuItem value="Social Search">Social Search</MenuItem>
                <MenuItem value="Content creators">Content creators</MenuItem>
                <MenuItem value="Media">Media</MenuItem>
                <MenuItem value="Gift">Gift</MenuItem>
                <MenuItem value="Photos and Print Services">
                  Photos and Print Services
                </MenuItem>
                <MenuItem value="Editorial publications">
                  Editorial publications
                </MenuItem>
                <MenuItem value="Publisher">Publisher</MenuItem>
                <MenuItem value="Coupon Code Websites">
                  Coupon Code Websites
                </MenuItem>
                <MenuItem value="Shopping Directory">
                  Shopping Directory
                </MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </Select>
            
           
             
              {apply.description?.includes("Others") && (
                <div className="form-group">
                  <input
                    fullWidth
                    name="description_others"
                    placeholder="Please specify"
                    variant="outlined"
                    value={apply.description_others || ""}
                    className="form-control"
                    onChange={handleChangeApplyData}

                  />
                                  {errors?.description_others &&  <span style={{color:"red",fontWeight:'500',fontSize:"14"}}>{errors.description_others}</span>}
                </div>
              )}
            </div>
          )}
          {mobileAppWebsiteChecked && (
            <div className="form-group">
              <label>
                How do you drive traffic to your website?
              </label>
              <Select
              sx={{
							  "& .MuiOutlinedInput-notchedOutline": {
								border: "none",
							  },
							  "& .MuiInputBase-input":{
								padding:".375rem .75rem",
								height:"auto",
								borderRadius:".25rem",
								fontSize:"14px",
								fontWeight:"400"
							  },
							  border:"1px solid #ced4da",
							  borderRadius:".25rem",
							  width:"100%"
							}}
                multiple
                fullWidth
                name="traffic_drivation"
                value={apply.traffic_drivation}
                onChange={handleTrafficChange}
                input={<OutlinedInput />}
              >
                <MenuItem value="Organic">Organic</MenuItem>
                <MenuItem value="Paid ads">Paid ads</MenuItem>
                <MenuItem value="Social media">Social media</MenuItem>
                <MenuItem value="Email marketing">Email marketing</MenuItem>
                <MenuItem value="Whatsapp marketing">
                  Whatsapp marketing
                </MenuItem>
                <MenuItem value="Retargeting">Retargeting</MenuItem>
                <MenuItem value="Lead Generation">Lead Generation</MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </Select>


              {apply.traffic_drivation.includes("Others") && (
             
             
                <div className="form-group">
                  <input
                    fullWidth
                    name="traffic_drivation_others"
                    placeholder="Please specify"
                    // value={trafficDriveOther.value}
                    value={apply.traffic_drivation_others || ""}
                    className="form-control"
                    onChange={(e) =>
                      setApply((prevApply) => ({
                        ...prevApply,
                        traffic_drivation_others: e.target.value,
                      }))
                    }
                  
                  />
                    {errors?.traffic_drivation_others &&  <span style={{color:"red",fontWeight:'500',fontSize:"14"}}>{errors.traffic_drivation_others}</span>}
                </div>
              )}
            </div>
          )}
            <div className="form-group">
            <label>
              How do you usually build links? *
            </label>
            <Select
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
              },
              "& .MuiInputBase-input":{
              padding:".375rem .75rem",
              height:"auto",
              borderRadius:".25rem",
              fontSize:"14px",
              fontWeight:"400"
              },
              border:"1px solid #ced4da",
              borderRadius:".25rem",
              width:"100%"
            }}
              fullWidth
              multiple
              name="build_links"
              value={apply.build_links || []}
              onChange={handleMultiSelectBuilds}
            >
              <MenuItem value="Linking via Landing Pages">
                Linking via Landing Pages
              </MenuItem>
              <MenuItem value="Direct Linking">Direct Linking</MenuItem>
              <MenuItem value="Guest Posting">Guest Posting</MenuItem>
              <MenuItem value="Co marketing">Co marketing</MenuItem>
            </Select>
            {errors?.build_links &&  <span style={{color:"red",fontWeight:'500',fontSize:"14"}}>{errors.build_links}</span>}
          
            </div>
            {mobileAppWebsiteChecked && (
            <>
          <div className="form-group">
            <label>
              How do you utilize your websites and apps to generate income? *
            </label>
            <ReactQuill
              className="reactQuilStle"
              
              name="generate_income"
              value={apply.generate_income}
              onChange={handleChangeTextEditorAffiliate("generate_income")}
              theme="snow"
              modules={modules}
              formats={formats}
            />
                {errors?.generate_income &&  <span style={{color:"red",fontWeight:'500'}}>{errors.generate_income}</span>}
          
          </div>
          <div className="form-group">
            <label>
              How many total unique visitors do your websites and apps get per
              month? *
            </label>
            <input
            className="form-control"
              type="number"
              fullWidth
              value={apply.unique_visitor_count}
              placeholder="Please Enter visitors count"
              name="unique_visitor_count"
              variant="outlined"
              onChange={handleChangeApplyData}
            />
                {errors?.unique_visitor_count &&  <span style={{color:"red",fontWeight:'500',fontSize:"14"}}>{errors.unique_visitor_count}</span>}
          </div>
          </>
          )}
            <div className="form-group">
            <label>
              What is your primary reason for joining the Art Smiley associates? *
            </label>

            <ReactQuill
              className="reactQuilStle"
              
              name="primary_reason_to_join"
              value={apply.primary_reason_to_join}
              onChange={handleChangeTextEditorAffiliate("primary_reason_to_join")}
              theme="snow"
              modules={modules}
              formats={formats}
            />
                {errors?.primary_reason_to_join &&  <span style={{color:"red",fontWeight:'500',fontSize:"14"}}>{errors.primary_reason_to_join}</span>}
           
            </div>
            <div className="form-group">
            <label>
              How did you hear about us? *
            </label>
            <Select
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
              },
              "& .MuiInputBase-input":{
              padding:".375rem .75rem",
              height:"auto",
              borderRadius:".25rem",
              fontSize:"14px",
              fontWeight:"400"
              },
              border:"1px solid #ced4da",
              borderRadius:".25rem",
              width:"100%"
            }}
              fullWidth
              name="hear_about_us"
              onChange={handleChangeApplyData}
            >
              <MenuItem value="Social media">Social media</MenuItem>
              <MenuItem value="Google search">Google search</MenuItem>
              <MenuItem value="Friend">Friend</MenuItem>
              <MenuItem value="Existing Customer">Existing Customer</MenuItem>
              <MenuItem value="Existing Artist">Existing Artist</MenuItem>
              <MenuItem value="Existing Curator">Existing Curator</MenuItem>
              <MenuItem value="TV">TV</MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
            {errors?.hear_about_us &&  <span style={{color:"red",fontWeight:'500',fontSize:"14"}}>{errors.hear_about_us}</span>}
          
            </div>
            {apply.hear_about_us === "Others" && (

<div className="form-group">
<>
  <input
    fullWidth
    name="hear_about_us_other"
    value={apply.hear_about_us_other}
    placeholder="Please specify"
    variant="outlined"
    className="form-control"
    onChange={handleChangeApplyData}
  />
   {errors?.hear_about_us_other &&  <span style={{color:"red",fontWeight:'500',fontSize:"14"}}>{errors.hear_about_us_other}</span>}</>
</div>
)}
            <div className="form-group">
            <label>
              I confirm that the information given in this form is true,
              complete and accurate. *
            </label>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={apply.completion}
              onClick={handleChangeApplyData}
              name="completion"
              sx={{ display: "flex", flexDirection: "row", mt: 0.8 }}
              className={styles.radio_button}
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
            {errors?.completion ? (
    <span style={{color:"red",fontWeight:'500',fontSize:"14" }}>
      {errors?.completion}
    </span>
  ) : null}
            </div>
            <div className={styles.btn_div}>
            <button
              onClick={() => {

             
                  handleApplyAffiliate();
               
              }}
             className="apply_btn"
            >
              Submit
            </button>
          </div>
          </div>
         
          {/* <div className={styles.child} style={{ padding: '10px 0px' }}>
            <label className={styles.input_label}>
              What are your websites or mobile apps about?*
            </label>
            <ReactQuill
              className="reactQuilStle"
              style={{
                height: "120px",
                textAlign: "left",
                marginBottom: "45px",
              }}
              name="website_about"
              value={apply.website_about}
              onChange={handleChangeTextEditorAffiliate("website_about")}
              theme="snow"
              modules={modules}
              formats={formats}
            />
           
         
          {errors?.website_about &&  <span style={{color:"red",fontWeight:'500',fontSize:"14"}}>{errors.website_about}</span>}
          </div> */}

          {/* Add other input fields similarly */}
            
      </div>
                                       </Grid>
                                      </Grid>
          </Container>
      
    </div>
  );
}
