import { createTheme, Grid, ThemeProvider, Typography, Container, Link } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchBlogData } from "../../../State/api/superadminApi"
import { useNavigate } from "react-router-dom"
import homeImage from "../../../constant"
import serverimage from "../../../serverConstant"
import api from "../../../State/api/Api"
import { setLoader } from "../../../State/Features/common/loaderSlice"
import styles from "./blogDetails.module.css"
import moment from "moment"

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
  },
})
function BlogDetails() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [pressData, setPressData] = useState()
  const [data, setData] = useState({ offset: 1 })

  const blogdt = useSelector((state) => state.blogdata.data)
  const fetchData = () => {
    dispatch(fetchBlogData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])
  // const d = Date.now()
  // const dates = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(d);
  
  const navigatetopressdetails = (id) => {
    navigate(`/user/blog-details/${id}`)
  }

  const handleNavigate = (route) => {
    navigate(route)
  }
  // const handleFetchPress = async function () {
  //   dispatch(setLoader(true))
  //   try {
  //     const result = await api.applicationApi("api/user/pressrelease")
  //     if (result && result.status === 200) {
  //       setPressData(result.data)
  //       dispatch(setLoader(false))
  //     } else if (result.status === 401) {
  //     }
  //   } catch (err) {
  
  //   }
  // }
  // useEffect(() => {
  //   handleFetchPress()
  // }, [])
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.pressreleases}>
      <div className={styles.headerCategory}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
      <div className={styles.pageitle}>
              <h1>Blog</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li>Blog</li>
              </ul>
            </div>
      </Container>
        </div>
        <section className={styles.sec_sc}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          <Grid container spacing={2}>
          {blogdt &&
  blogdt.data
    .filter((item) => new Date(item.date) <= new Date()) // Filter blogs with date <= today
    .map((item) => (

                <Grid item xs={12} sm={6} md={3}>
                  <div
                    className={styles.card__main}
                    onClick={() => navigatetopressdetails(item.title_slug)}
                  >
                    <img
                      className={styles.card__image}
                      src={ item.media}
                      width="100px"
                      height="200px"
                      alt="img"
                    />
                    <div className={styles.blocnt}>
                    <h3>{item?.title}</h3>
                    <p className={styles.metap}>{moment(item.date).format("DD/MM/YYYY")}</p>
                    <p className={styles.blocntpra}
                        dangerouslySetInnerHTML={{
                          __html:
                            item &&
                            item.description &&
                            item.description.length > 200
                              ? item.description.substring(0, 200) + "..."
                              : item && item.description
                              ? item.description
                              : "",
                        }}
                      ></p>
                    </div>
                      
                  </div>
                </Grid>
              ))}
          </Grid>
          </Container>
        </section> 
      </div>
    </ThemeProvider>
  )
}

export default BlogDetails
