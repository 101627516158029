import moment from "moment";

const dateFormat = (date) => {
    // Check if the input is a valid date
    if (!moment(date, moment.ISO_8601, true).isValid()) {
        return; // Return nothing if the input is not a valid date
    }
    return moment(date).format("DD-MM-YYYY");
};

export default dateFormat;
