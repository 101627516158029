import {
  Box,
  Button,
  Grid,
  Container,
  Tab,
  TextField,
  Typography,
  withStyles,
  Link
} from "@mui/material"
import React, { useEffect, useState } from "react"
import styles from "./WishList.module.css"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"
import homeImage from "../../../constant"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import serverimage from "../../../serverConstant"
import { setMycartdata } from "../../../State/Features/user/mycartSlice"
import { AlertsSuccess, Errornotify } from "../../../common/alert/Alert"
import { setWishlistdata } from "../../../State/Features/user/wishlistSlice"
import { setLoader } from "../../../State/Features/common/loaderSlice"
import api from "../../../State/api/Api"
import CircularLoader from "../../../common/loader/Loader"
import {
  fetchAllItemData,
  fetchFetchAllcart,
  handelAddtocart,
  handelFetchWishlist,
  handleDeleteWishlist,
  handleUpdataeCart,
} from "../../../State/api/userApi"
import { RowingRounded } from "@mui/icons-material"
import { fetchMaterial } from "../../../State/api/artistapi"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import axios from "axios"
import { FiX, FiShoppingCart, FiHeart } from "react-icons/fi";
function WishList() {
  const [counter, setCounter] = useState(0)
  const [data, setData] = useState(1)
  const [material, setMaterial] = useState(null)
  const usertype = useSelector((state) => state.user.data)
  const wishlist = useSelector((state) => state.wishlistData)
  const wishlistForOrderType = useSelector((state) => state.wishlistData.orderType)
  console.log(wishlistForOrderType, "wishlistForOrderType")
  const allitemdt = useSelector((state) => state.allitemData)
  const myCart = useSelector((state) => state.mycartData.data)
  const printId = wishlistForOrderType?.orderTypes?.filter(
    (val) => val.name === "Print"
  )[0]?._id;
  console.log(myCart, "myCart--")
  const userty = useSelector((state) => state.user.data)
  const loader = useSelector((state) => state.loader)
  const mac_Address = localStorage.getItem("device_id")
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const increase = () => {
    setCounter((count) => count + 1)
  }
  const handleNavigate = (route) => {
    navigate(route)
  }
  const decrease = () => {
    if (counter > 0) {
      setCounter((count) => count - 1)
    }
  }
  const handleClickDecrease = (event) => {
    decrease()
  }
  const handleClickIncrease = (event) => {
    increase()
  }
  const cardDetails = [
    {
      id: 1,
      imgUrl: "/img/Rectangle 5198.png",
    },
    {
      id: 2,
      imgUrl: "/img/Rectangle 5199.png",
    },
    {
      id: 3,
      imgUrl: "/img/Rectangle 5197.png",
    },
    {
      id: 4,
      imgUrl: "/img/Rectangle 5200.png",
    },
  ]
  function createData(product, productname, quantity, stock_status, price) {
    return {
      product,
      productname,
      quantity,
      stock_status,
      price,
    }
  }
  useEffect(() => {
    dispatch(handelFetchWishlist({ offset: data }))
  }, [data])

  // const getData = async () => {
  //   const res = await axios.get('https://geolocation-db.com/json/')
  //   dispatch(fetchFetchAllcart({ offset: 1, IP_address: res.data.IPv4 }))
  // }
  // useEffect(() => {
  //   // usertype.signed === false && getData()
  // }, [])

  useEffect(() => {
    dispatch(fetchMaterial(setMaterial))
    usertype.signed === true &&
      dispatch(
        fetchFetchAllcart({
          offset: 1,
          user_id: localStorage.getItem("user_id"),
        })
      )
    usertype.signed === false &&
      dispatch(fetchFetchAllcart({ offset: 1, IP_address: mac_Address }))
    dispatch(fetchAllItemData())
  }, [])
  const handleNavigateCart = (dt) => {
    dispatch(setMycartdata(dt))
  }
  const handleDeleteWishlists = (id) => {
    dispatch(handleDeleteWishlist(id, { offset: data }))
  }
  const handleAddCart = (id) => {
    const val = id
    const index = myCart && myCart.result.map((item) => item.item_id._id)
    if (userty.signed === false) {
      Errornotify("Please login ")
    } else if (index.includes(val)) {
      Errornotify("Already in your basket")
    } else {
      dispatch(
        handelAddtocart(
          {
            item_id: val,
            orderType_id: printId,
            user_id: localStorage.getItem("user_id"),
            quantity: 1,
            IP_address: localStorage.getItem("device_id"),
          },
          { offset: 1, IP_address: localStorage.getItem("device_id") }
        )
      );
    }
  }
  const quantity = myCart && myCart.result.map((val) => val.quantity)
  const cart_id = myCart && myCart.result.map((val) => val._id)
  const handleUpdateCarts = (id, qty) => {
    dispatch(handleUpdataeCart(cart_id, { quantity: quantity[0] + 1 }, data))
  }
  const handleUpdateCartRemove = (id, qty) => {
    dispatch(handleUpdataeCart(cart_id, { quantity: quantity[0] - 1 }, data))
  }
  // const materials = material && material.filter(val => val._id === (wishlist.data && wishlist.data.map(val => val.item_id.material_id)))

  console.log(wishlist.data, "wishlst--00")

  return (
    <div className={styles.wishlist}>
      <AlertsSuccess />
      <div className={styles.headerCategory}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
      <div className={styles.pageitle}>
              <h1>My Wishlist</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li>My Wishlist</li>
              </ul>
            </div>
      </Container>
        </div>
      
      <div className={styles.wishlist__content}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          <h3> Your Wishlist ({wishlist.data && wishlist.data.length} items)</h3>
          <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    borderTop: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                  align="center"
                >
                  <Typography mr={10}>PRODUCT</Typography>
                </TableCell>

                <TableCell
                  sx={{
                    borderTop: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                  align="right"
                >
                  STOCK STATUS
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                  align="right"
                >
                  PRICE
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                  align="right"
                ></TableCell>
                <TableCell
                  sx={{
                    borderTop: "0.5px solid black",
                    borderBottom: "0.5px solid black",
                  }}
                  align="right"
                ></TableCell>
              </TableRow>
            </TableHead>
            {loader.loader ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}>
                      {" "}
                      <CircularLoader size={60} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {wishlist.data && wishlist.data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Box className={styles.loader_box}> No Data Found</Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  wishlist.data &&
                  wishlist.data.map((row) => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        sx={{ borderBottom: "none" }}
                        component="th"
                        scope="row"
                      >
                        <Grid container spacing={2}>
                        <Grid item xs={4} sm={6} md={4}>
                          <div className={styles.productimg}>
                          <img
                              serc=""
                              src={
                                row.item_id
                                  ? row.item_id.image
                                  : "/img/Artist.png"
                              }
                              style={{ borderRadius: "10px" }}
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                            
                          </Grid>
                          <Grid item xs={8} sm={6} md={8}>
                            <div className={styles.product__info}>
                              <Typography>
                              <p className={styles.product_nme}>
                                  {row.item_id === null ? "" : row.item_id.name}
                                </p>
                              </Typography>
                              {material &&
                                material.map((val) => {
                                  if (
                                    val._id === row.item_id &&
                                    row.item_id.material_id
                                  ) {
                                    return (
                                      <p className={styles.product_nme}>
                                        {val.name}
                                      </p>
                                    )
                                  }
                                })}
                              <p className={styles.frdimns}
                                      >
                                {row.item_id === null
                                  ? ""
                                  : `${row.item_id.width}x${row.item_id.height}x${row.item_id.depth}`}
                                cm
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      </TableCell>
                      {/* <TableCell sx={{ borderBottom: "none" }} align="right">
                        <div className={styles.counterDiv}>
                          <div
                            onClick={() =>
                              handleUpdateCartRemove(
                                row.item_id === null ? "" : row.item_id._id
                              )
                            }
                            className={styles.decrease}
                          >
                            -
                          </div>
                          <div className={styles.counterValue}>
                            {myCart &&
                              myCart?.result?.map(
                                (val) => val?.item_id?.quantity
                              )}
                            {quantity[0]}
                          </div>
                          <div
                            onClick={() =>
                              handleUpdateCarts(
                                row.item_id === null ? "" : row.item_id._id,
                                row.quantity
                              )
                            }
                            className={styles.increase}
                          >
                            +
                          </div>
                        </div>
                      </TableCell> */}
                      <TableCell sx={{ borderBottom: "none" }} align="right">
                        {row.item_id === null ? (
                          ""
                        ) : row.item_id.sale.qty === 0 ? (
                          <Typography color="red">Out of Stock</Typography>
                        ) : (
                          <Typography color="#2D812D">In Stock</Typography>
                        )}
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }} align="right">
                        <Typography fontSize="1rem">
                          $
                          {row.item_id === null
                            ? ""
                            : row.item_id && row.item_id.sale.price}
                        </Typography>
                      </TableCell>
                      <TableCell
                        
                        sx={{ borderBottom: "none" }}
                        align="right"
                      >
                        <Link sx={{cursor:"pointer"}} onClick={() => {
                          if(row.item_id.sale.qty > 0) {
                            handleAddCart(
                              row.item_id === null
                                ? ""
                                : row.item_id && row.item_id._id
                            )
                          }else {
                            Errornotify("Out of Stock")
                          }
                        }}>
                          {
  myCart?.result?.some((item) => item?.item_id?._id === row?.item_id?._id) ? (
    <FiShoppingCart className={styles.wisliic} />
  ) : (
    <FiShoppingCart className={styles.wisliic} />
  )
}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }} align="right">
                        <Link sx={{cursor:"pointer"}} onClick={() => handleDeleteWishlists(row._id)} >
                        <FiX className={styles.remic}/>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        </Container>
      </div>
      <div className={styles.features}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4}>
                  <div className={styles.safe__shipping}>
                  <Grid container spacing={2} sx={{alignItems:"start"}}>
                  <Grid item xs={12} sm={3} md={3}>
                  <div className={styles.fericon}>
                    <img
                      className="img-fluid"
                      src={"/img/safe-shipping.svg"}
                      alt="img"
                    />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9}>
                  <div className={styles.feturcont}>
                      <h4>
                        Safe Shipping Globally
                      </h4>
                      <p>
                        We ensure that your artwork is shipped securely
                        worldwide. Our reliable shipping partners guarantee safe
                        handling and timely delivery.
                      </p>
                      {/* <Typography fontSize="0.9rem" ml={1.75}>
                        Our reliable shipping partners guarantee safe handling
                        and timely delivery.
                      </Typography> */}
                    </div>
                  </Grid>
                  </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div className={styles.safe__shipping}>
                  <Grid container spacing={2} sx={{alignItems:"start"}}>
                  <Grid item xs={12} sm={3} md={3}>
                  <div className={styles.fericon}>
                  <img
                      className="img-fluid"
                      src={"/img/free-return.svg"}
                      alt="img"
                    />
                  </div>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9}>
                  <div className={styles.feturcont}>
                      <h4>
                        Free Return
                      </h4>
                      <p>
                        If your purchase doesn't meet your expectations,
                        returning it is simple and free. We offer a hassle-free
                        return policy with no hidden fees, allowing you to
                        return the artwork within 14 days.
                      </p>
                      {/* <Typography fontSize="0.9rem" ml={1.75}>
                        We offer a hassle-free return policy with no hidden
                        fees, allowing you to return the artwork within 14 days.
                      </Typography> */}
                    </div>
                  </Grid>
                  </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div className={styles.safe__shipping}>
                  <Grid container spacing={2} sx={{alignItems:"start"}}>
                  <Grid item xs={12} sm={3} md={3}>
                  <div className={styles.fericon}>
                  <img
                      className="img-fluid"
                      src={"/img/secure.svg"}
                      alt="img"
                    />
                  </div>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9}>
                  <div className={styles.feturcont}>
                      <h4>
                        Secure
                      </h4>
                      <p>
                        All transactions on Art Smiley are encrypted and
                        processed securely. We collaborate with trusted
                        financial partners to provide a safe and professional
                        experience, ensuring your personal information remains
                        protected.
                      </p>
                      {/* <Typography fontSize="0.9rem" ml={1.75}>
                        We collaborate with trusted financial partners to
                        provide a safe and professional experience, ensuring
                        your personal information remains protected.
                      </Typography> */}
                    </div>
                  </Grid>
                  </Grid>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
          <div className={styles.newpaintngs}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Newest Paintings</h3>
          </div>
          <div>
          <Grid container spacing={2}>
              {allitemdt.data &&
                allitemdt.data
                  .filter((val) => val.is_accepted === "Approved")
                  .map((item, i) =>
                    i >= 4 ? (
                      ""
                    ) : (
                      <Grid item xs={12} sm={6} md={3}>
                        <div className={styles.card__main}>
                        <div className={styles.card__top}>
                      <img
                        className={styles.card__Image}
                        src={item.image}
                        width="100%"
                        alt="img"
                      />
                    </div>
                    <div className={styles.pic_info}>
                      <div className={styles.card__middle}>
                        <span className={styles.titles}>{item.name}</span>
                      </div>
                      <div className={styles.info_like}>
                        <div className={styles.wishlst}>
                          <FiHeart />
                        </div>
                        <span className={styles.like_count}>
                          {item.likesview?.likes.length || 0}
                        </span>
                      </div>
                    </div>
                    <p className={styles.names}>
                    {item.artist_id && item.artist_id.first_name}{" "}
                    {item.artist_id && item.artist_id.last_name}
                        </p>
                        <div className={styles.price_sec}>
                      <div className={styles.card__bottom}>
                        <span className={styles.buys}>Buy</span>
                        <span className={styles.prices}>
                          ${item?.sale?.price}
                        </span>
                      </div>
                      {item?.rent?.rent_price > 0 ? (
                        <>
                          {" "}
                          <div className={styles.hrs} />
                          <div>
                            <span className={styles.buys}>Rent/Month</span>
                            <span className={styles.prices}>
                              ${item?.rent?.rent_price}
                            </span>
                          </div>
                        </>
                      ) : null}
                      {item?.print?.[0]?.print_price > 0 ? (
                        <>
                          {" "}
                          <div className={styles.hrs} />
                          <div>
                            <span className={styles.buys}>Print</span>
                            <span className={styles.prices}>
                              ${item?.print[0]?.print_price}
                            </span>
                          </div>
                        </>
                      ) : null}
                    </div>
                        </div>
                      </Grid>
                    )
                  )}
            </Grid>
          </div>
          </Container>
          </div>
      
      
    </div>
  )
}

export default WishList
