import React, { useEffect } from "react"
import styles from "./Artistprofile.module.css"
import Tables from "../../Table/Table/Tables"
import Artistdetail from "../Common/Artistdetail"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { fetchArtistProfileData } from "../../../../../State/api/superadminApi"

const dt = {
  img: "",
  name: "Emily Cooper",
  more_details: [
    {
      title: "Views",
      content: "10,456",
    },
    {
      title: "Likes",
      content: "8,456",
    },
    {
      title: "Favorites",
      content: "1,456",
    },
  ],
}
const data = [
  {
    heading: "Description",
    description_details: [
      {
        title: "Mobile Number",
        content: "+91 9998738792",
      },
      {
        title: "Email Adress",
        content: "Artsmiley",
      },
      {
        title: "CV",
        content: "Artsmiley",
      },
      {
        title: "ID Proof ",
        content: "Artsmiley",
      },
      {
        title: "Social Media Link ",
        content: "http:art.com",
      },
      {
        title: "Art Qualification University ",
        content: "20 W * 20 H * 50 D in",
      },
      {
        title: "Degree Year",
        content: "2012",
      },
      {
        title: "Artist Type ",
        content: "Emerging Artist ",
      },
      {
        title: "Assign to",
        content: "Joe Doe",
      },
      {
        title: "Exibition 1",
        content: "Artsmiley",
      },
      {
        title: "Exibition 2 ",
        content: "Art of Space",
      },
      {
        title: "Subscription Plan ",
        content: "Art of Space",
      },
      {
        title: "Porfolio ",
        content:
          "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and mgffdg gfdhfdg",
      },
      {
        title: "Biography ",
        content:
          "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and m",
      },
    ],
  },
  {
    heading: "Bank Details",
    description_details: [
      {
        title: "Bank Name",
        content: "Axis Bank",
      },
      {
        title: "Account Holder Name",
        content: "Emily cooper",
      },
      {
        title: "Account Number",
        content: "238787438493",
      },
      {
        title: "Routing/ABA Number",
        content: "4325324543",
      },
      {
        title: "Bank Location",
        content: "India",
      },
      {
        title: "Account Type",
        content: "Saving Account",
      },
    ],
  },
  // {
  //     heading: "Art Detail",
  // }
]
const headData = [
  {
    id: "image",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "user_name",
    label: "User Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "sku_id",
    label: "Sku_Id",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "art_name",
    label: "Art Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buy",
    label: "Buy",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "rent",
    label: "Rent",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "print",
    label: "Print",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "action",
    label: "",
    format: (value) => value.toLocaleString(),
  },
]
const rows = [
  {
    image: "/img/art_list_image.png",
    user_name: "Galexy On Earth",
    sku_id: "Emily cooper",
    art_name: "Gabrial Zac",
    buy: "$1545",
    rent: "$1545",
    print: "---",
    action: "Block",
  },
  {
    image: "/img/Image 2.png",
    user_name: "Galexy On Earth",
    sku_id: "Emily cooper",
    art_name: "Gabrial Zac",
    buy: "$1545",
    rent: "$1545",
    print: "---",
    action: "Block",
  },
  {
    image: "/img/Image 3.png",
    user_name: "Galexy On Earth",
    sku_id: "Emily cooper",
    art_name: "Gabrial Zac",
    buy: "$1545",
    rent: "$1545",
    print: "---",
    action: "Block",
  },
  {
    image: "/img/Image 4.png",
    user_name: "Galexy On Earth",
    sku_id: "Emily cooper",
    art_name: "Gabrial Zac",
    buy: "$1545",
    rent: "$1545",
    print: "---",
    action: "Block",
  },
]
export default function Artistprofile() {
  var { id } = useParams()
  const dispatch = useDispatch()
  const artistprofileData = useSelector((state) => state.artistprofiledata.data)
  console.log("artistProfileData",artistprofileData)
  useEffect(() => {
    dispatch(fetchArtistProfileData(id))
  }, [])
  return (
    <>
      <div className={styles.mains}>
        <Artistdetail
          type="artist"
          data={artistprofileData}
          dt={dt}
          datas={data}
        />
        {/* <div className={styles.kh}>
                    <h2 className={styles.table_heading}>All Arts</h2>
                    <Tables headData={headData} data={rows} />
                </div> */}
      </div>
    </>
  )
}
