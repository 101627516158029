import React, { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import Commonenquirygallery from "../../../gallery/pages/totalEnquiry/common/Commonenquirygallery";
import { fetchEnquiryData } from "../../../State/api/superadminApi";
import styles from "./Totalenquiryartist.module.css";

const upcominghead = [
  {
    id: "artist_name ",
    label: "Inquiry ID",
  },
  {
    id: "location",
    label: "Buyer Name",
  },
  // {
  //   id: "like",
  //   label: "Inquiry Type",
  // },
  {
    id: "like",
    label: "Inquiry Date",
  },
  // {
  //   id: "like",
  //   label: "Description",
  // },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "action",
    label: "Action",
  },
];
export default function Totalenquiryartist() {

  const dispatch = useDispatch();
  const inquirydt = useSelector((state) => state.enquirydata.data);
  useEffect(() => {
    dispatch(fetchEnquiryData());
  }, []);
  return (
    <div className={styles.mains}>
      <div className={styles.components}>
        <Commonenquirygallery
          head={upcominghead}
          data={inquirydt && inquirydt}
          button=""
        />
      </div>
    </div>
  );
}
