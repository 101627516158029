import { CalendarMonthOutlined } from "@mui/icons-material";
import { createTheme, ThemeProvider, Link, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./TandC.module.css";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useNavigate } from "react-router-dom";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import api from "../../../State/api/Api";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import TranslateWrapper from "../../../languageTranslator/TranslateWrapper";

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },  
  },
});
function TandC() {
  const [termsData, setTermsData] = useState();

  const dispatch = useDispatch();
  const { t } = useTranslation("termsCondition");

  const handleFetchTermsCondition = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi('api/user/termsCondition'
      )
      if (result && result.status === 200) {
        setTermsData(result.data.response);
        dispatch(setLoader(false));
      }
      else if (result.status === 401) {
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handleFetchTermsCondition();
  }, [])
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route)
  }
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.tandc}>
        <div className={styles.allcategory}>
          <div className={styles.headerCategory}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={styles.pageitle}>
              <h1>{t("termsAndCondition")}</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>{t("home")} /{" "} </Link></li>
                <li>{t("termsAndCondition")}</li>
              </ul>
            </div>
          </Container>
          </div>
        </div>
        {termsData && termsData.map((val) => (
          <div className={styles.tandc__content}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div>
              
              <h3>
              <CalendarMonthOutlinedIcon /> {t("effective_date")}: <TranslateWrapper>{moment(val.updatedAt).format('Do MMMM YYYY')}</TranslateWrapper>
              </h3>
              <p><TranslateWrapper>{val.description}</TranslateWrapper></p>
              <h4>
                <TranslateWrapper>{val.title}</TranslateWrapper>
              </h4>
              <p>
                  {t("policy_main_desc")}
              </p>
              <p>
                  <i>
                    <b>{t("when_faq1")}:</b>
                  </i>{" "}
                  {t("when_faq1_ans")}
              </p>
              <p>
                  <i>
                    <b>{t("when_faq2")}: </b>
                  </i>
                  {t("when_faq2_ans")}
              </p>
              <p>
                  <i>
                    <b>{t("when_faq3")}: </b>
                  </i>
                  {t("when_faq3_ans")}
              </p>
              <p>
                  <i>
                    <b>{t("when_faq4")}: </b>
                  </i>
                  {t("when_faq4_ans")}
              </p>
              <p>
                  <i>
                    <b>{t("when_faq5")}: </b>
                  </i>
                  {t("when_faq5_ans")}
              </p>
              <h4>
              {t("how_artsmiley_use_your_info")}?
              </h4>
              <p >
                {t("how_artsmiley_use_your_info_desc")}:
              </p>
               <ul>
                  <li>{t("how_artsmiley_use_your_info_point1")};</li>
                  <li>
                  {t("how_artsmiley_use_your_info_point2")};
                  </li>
                  <li>{t("how_artsmiley_use_your_info_point3")};</li>
                  <li>
                  {t("how_artsmiley_use_your_info_point4")};
                  </li>
                  <li>
                  {t("how_artsmiley_use_your_info_point5")};
                  </li>
                  <li>{t("how_artsmiley_use_your_info_point6")};</li>
                  <li>{t("how_artsmiley_use_your_info_point7")};</li>
                  <li>
                  {t("how_artsmiley_use_your_info_point8")};
                  </li>
                  <li>
                  {t("how_artsmiley_use_your_info_point9")}
                  </li>
                  <li>
                  {t("how_artsmiley_use_your_info_point10")}
                  </li>
                  <li>
                  {t("how_artsmiley_use_your_info_point11")}
                  </li>
                </ul>
              <h4>
             {t("how_does_artsmiley_share_your_info")}?
              </h4>
              <p>
                <i>
                    <b>{t("advertisers")}: </b>
                  </i>
                  {t("advertisers_desc")}
                 </p>
              <p>
                  <i>
                    <b>{t("third_party_websites")}: </b>
                  </i>
                  {t("third_party_websites_desc")}
              </p>
              <p>
                  <i>
                    <b>{t("agents")}: </b>
                  </i>
                  {t("agents_desc")}
              </p>
              <p>
                  <i>
                    <b>{t("business_transfers")}: </b>
                  </i>
                  {t("business_transfers_desc")}
              </p>
              <p>
                  <i>
                    <b>{t("enforcement")}: </b>
                  </i>
                  {t("enforcement_desc")}
              </p>
              <p>
                  <i>
                    <b>{t("protection")}: </b>
                  </i>
                  {t("protection_desc")}
              </p>
              <p>
                  <i>
                    <b>{t("user_profiles")}: </b>
                  </i>
                  {t("user_profiles_desc")}
              </p>
              <p>
                  <i>
                    <b>{t("consent")}: </b>
                  </i>
                  {t("consent_desc")}
              </p>
              <p>
                  <i>
                    <b>{t("compliance")}: </b>
                  </i>
                  {t("compliance_desc")}
             
              </p>
              <h4>
              {t("how_artsmiley_communicate")}?
              </h4>
              <p>
                  {t("how_artsmiley_communicate_desc")}
              </p>
              <h4>
              {t("how_artsmiley_use_cookies")}?
              </h4>
              <p> {t("how_artsmiley_use_cookies_desc1")} </p>
                  <p>{t("how_artsmiley_use_cookies_desc2")}</p>
              <h4>
              {t("how_artsmiley_use_social_plugins")}?
              </h4>
              <p>{t("how_artsmiley_use_social_plugins_desc")}
              </p>
              <h4>
              {t("how_artsmiley_use_thirdparty")}?
              </h4>
              <p>{t("how_artsmiley_use_thirdparty_desc1")}
                  </p> <p>{t("how_artsmiley_use_thirdparty_desc2")}
                  </p> <p>{t("how_artsmiley_use_thirdparty_desc3")}
              </p>
            </div>
            </Container>
           
          </div>
        ))}
      </div>
    </ThemeProvider>
  );
}

export default TandC;
