import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
// import Divider from '@mui/material/Divider';
import React from "react";
import styles from "./Sevencoltable.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import homeImage from "../../../constant";
import CircularLoader from "../../../common/loader/Loader";
import moment from "moment";

export default function TableArtistDispute(props) {
 const navigate = useNavigate();
 const [anchorEl, setAnchorEl] = React.useState(null);

 const dispatch = useDispatch();
 const loader = useSelector((state) => state.loader);

 const threedots = Boolean(anchorEl);
 const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
 };
 const handleClose = () => {
  setAnchorEl(null);
 };
 const handlePopup = (e) => {
  props.data.setOpen(true);
  props.data.setPoptype("addfeature");
 };
 const handleNavigate = (id) => {
  const path = window.location.pathname;
  if (path === "/dispute") {
   navigate(`/dispute/${id}`);
  }
  props.data.setPage("profile");
 };
 return (
  <div className={styles.mains}>
   <TableContainer component={Paper} sx={{ backgroundColor: "#F6F6F6", color: "black" }}>
    <Table sx={{ minWidth: 700 }} aria-label="customized table">
     <TableHead>
      <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
       {props.head?.map((column, i) => (
        <TableCell
         align="left"
         sx={{
          whiteSpace: "nowrap",
          fontFamily: "Roboto",
          fontWeight: "500",
          fontSize: "15px",
          height: "21px",
          color: "#1C1C1C",
         }}
         key={i}>
         {column.label}
        </TableCell>
       ))}
      </TableRow>
     </TableHead>
     {loader.loader ? (
      <TableBody>
       <TableRow>
        <TableCell colSpan={8}>
         <Box className={styles.loader_box}>
          {" "}
          <CircularLoader size={60} />
         </Box>
        </TableCell>
       </TableRow>
      </TableBody>
     ) : (
      <TableBody>
       {props.data && props.data?.length === 0 ? (
        <TableRow>
         <TableCell colSpan={8}>
          <Box className={styles.loader_box}> No Data Found</Box>
         </TableCell>
        </TableRow>
       ) : (
        props.data &&
        props.data?.map((row, i) => (
         <TableRow value={row.artist_name} style={{ color: "#636365", cursor: "pointer" }} key={i}>
          <TableCell
           onClick={() => handleNavigate(row._id, row)}
           sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#636365",
           }}
           className={styles.table_column}
           align="left">
           #{i}
          </TableCell>
          <TableCell
           sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#636365",
           }}
           className={styles.table_column}
           align="left">
           {row.order?.[0]?.order_number}
          </TableCell>
          <TableCell
           sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#636365",
           }}
           className={styles.table_column}
           align="left">
           {moment(row?.createdAt).format("DD-MM-YYYY")}
          </TableCell>
          <TableCell
           sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#636365",
            whiteSpace: "nowrap",
           }}
           className={styles.table_column}
           align="left">
           {row.title}
          </TableCell>
          <TableCell
           sx={{
            // width:props.activetab==='enquiry'?'30%':'',
            // width:'200',
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#636365",
           }}
           className={styles.table_column}
           align="left">
           <div>{row.description}</div>
          </TableCell>
          <TableCell
           sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#636365",
           }}
           className={styles.table_column}
           align="left">
           <div
            className={
             row.refund_status === "Rejected"
              ? styles.dispute_status_open
              : row.refund_status === "Approved"
              ? styles.disput_status
              : styles.refund_pending
            }>
            {row.refund_status === "Rejected"
             ? "Rejected"
             : row.refund_status === "Approved"
             ? "Approved"
             : "Pending"}
           </div>
          </TableCell>
          <TableCell
           sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "21px",
            color: "#636365",
            whiteSpace: "nowrap",
           }}
           className={styles.table_column}
           align="left">
           <div className={styles.dispute_flex}>
            <span
             className={
              row.dispute_status === "Resolved" ? styles.disput_status : styles.dispute_status_open
             }>
             {" "}
             {row.dispute_status}
            </span>
            {/* <Tooltip title="Account settings">
             <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={threedots ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={threedots ? "true" : undefined}>
              <img src={ "/img/Menu Icon.png"} height="21.62px" width="4.63px" />
             </IconButton>
            </Tooltip>
            <Menu
             anchorEl={anchorEl}
             id="account-menu"
             open={threedots}
             onClose={handleClose}
             onClick={handleClose}
             PaperProps={{
              elevation: 0,
              sx: {
               width: "150px",
               overflow: "visible",
               boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
               filter: "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
               mt: 2.5,
               "& .MuiAvatar-root": {
                width: "120px",
                height: "132px",
                ml: 0.5,
                mr: 1,
                // mt:5
               },
               "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                // horizontal: 'center',
                right: 62,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
               },
              },
             }}
             transformOrigin={{ horizontal: "center", vertical: "right" }}
             anchorOrigin={{ horizontal: "left", vertical: "center" }}>
             <MenuItem
              sx={{
               display: "flex",
               justifyContent: "center",
               fontSize: "16px",
               lineHeight: "12px",
               fontWeight: "400",
               fontFamily: "Roboto",
              }}
              value="addfeature"
              onClick={
               props.ApprovedRefundStatus && props.ApprovedRefundStatus(row._id, "Approved")
              }>
              Refund Initiated
             </MenuItem>
            </Menu> */}
           </div>
          </TableCell>
         </TableRow>
        ))
       )}
      </TableBody>
     )}
    </Table>
   </TableContainer>
  </div>
 );
}
