import { CalendarMonthOutlined } from "@mui/icons-material";
import { createTheme, ThemeProvider, Link, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./TandC.module.css";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useNavigate } from "react-router-dom";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import api from "../../../State/api/Api";
import { useDispatch } from "react-redux";
import moment from "moment/moment";

 
function ReturnPolicy() {
  const [termsData, setTermsData] = useState();

  const dispatch = useDispatch();

  const handleFetchTermsCondition = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi('api/user/termsCondition'
      )
      if (result && result.status === 200) {
        setTermsData(result.data.response);
        dispatch(setLoader(false));
      }
      else if (result.status === 401) {
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handleFetchTermsCondition();
  }, [])
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route)
  }
  return (
   <>
   <div className={styles.tandc}>
        <div className={styles.allcategory}>
          <div className={styles.headerCategory}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={styles.pageitle}>
              <h1>Return Policy</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li>Return Policy</li>
              </ul>
            </div>
          </Container>
          </div>
        </div>
        {termsData && termsData.map((val) => (
          <div className={styles.tandc__content}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div>
              
              <h3>
              <CalendarMonthOutlinedIcon /> Effective Date: {moment(val.updatedAt).format('Do MMMM YYYY')}
              </h3>
              <p>At Art Smiley, we prioritize your satisfaction and aim to ensure a seamless experience with every purchase. Here’s our comprehensive return policy to help you understand your options and enjoy hassle-free art returns:</p>
             <h4>Returns for Sculptures, Original Artworks, and Prints</h4>
             <p>If your artwork arrives damaged, you must notify us within 48 hours of receiving the order. To start the resolution process, please email clear photos of the damaged artwork, the inner and outer packaging, and the shipping label to <Link href="mailto:contactus@artsmiley.com">contactus@artsmiley.com.</Link> Our team will address the issue promptly.</p>
             <p>As part of our return policy, sculptures, original artworks, and prints can be returned within 14 days of delivery for a full refund, provided they are in their original condition and packaging, along with any included certificates. However, customized or commissioned items, including framed artworks, are non-returnable. Return shipping charges are the customer’s responsibility unless the item is damaged upon delivery.</p>
             <h4>Return Policy for Custom Artworks</h4>
             <p>We offer full customization to match your desired style, preferences, and specifications in sculptures, artworks, and prints. Before shipment, we ensure that you are fully satisfied with the final product, confirming all details before delivery. Please note that customized sculptures, artworks, and prints, once created or altered to meet your specific requirements, are considered final sale and non-returnable. After delivery, these items cannot be returned or exchanged.</p>
             <h4>Damaged or Incorrect Items</h4>
             <p>If your artwork arrives damaged or does not match its description, you are eligible for a full refund to your original payment method. When returning damaged art pieces, please notify us within 48 hours of receipt. To initiate the resolution process, kindly email clear photos of the damaged artwork, both the inner and outer packaging, and the shipping label to <Link href="mailto:contactus@artsmiley.com">contactus@artsmiley.com.</Link></p>
             <p>Our team will promptly assist you in resolving the issue. This must be reported and returned within 14 days of delivery. Please retain the shipping materials, as they may be required for investigation. Art Smiley will cover the return shipping costs for damaged or incorrect items.</p>
             <h4>Cancellation Policy</h4>
             <p>Orders can be canceled before they are shipped. Once shipped, cancellations are not permitted, and the standard return policy will apply.</p>
             <h4>Shipping Costs</h4>
             <ul>
              <li>For returns due to a change of mind, customers bear the return shipping costs.</li>
              <li>If the artwork was damaged during delivery, Art Smiley will cover the return shipping costs.</li>
             </ul>
             <h4>Refund Options</h4>
             <ul>
              <li> <i>
                    <b>Store Credit:</b>
                  </i>{" "} Receive the full purchase amount as store credit.</li>
                  <li> <i>
                    <b>Refund:</b>
                  </i>{" "} Get a refund to your original payment method, minus a 20% processing fee. Refunds will be processed in USD and are subject to the exchange rate at the time of processing.</li>
             </ul>
             <h4>Final Sale Policy</h4>
             <p>Custom prints, special commissions, and any artwork that has been altered are considered final sale. They cannot be returned or refunded.</p>
             <h4>Our Commitment to Your Satisfaction</h4>
             <p>We’re dedicated to ensuring you are delighted with your artwork! If you decide to return an eligible item within 14 days, you can opt for store credit or a refund (minus a 20% processing fee). Our knowledgeable art advisors are available to assist you in selecting a replacement at no additional cost. As a leading online art destination, we’re confident we can help you discover the perfect piece from our diverse collection.</p>
             <p>For any issues or questions, please contact our customer service team, and we’ll be happy to assist you further.</p>
              
            </div>
            </Container>
           
          </div>
        ))}
      </div>
   </>
  );
}

export default ReturnPolicy;
