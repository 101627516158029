import { Typography, Grid, Link  } from "@mui/material";
import React, {  useState } from "react";
import styles from "./CuratorExhiitionCard.module.css";
import dateFormat from "../../../../../utils/dateFormat";
import { acceptDeclineExshibitionArt, fetchExhibitionArtData } from "../../../../../State/api/superadminApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";



export default function CuratorExhibitionDetailsCard(props) {
 
  const dispatch = useDispatch()
  const handleAccept = (id)=> {
    dispatch(
      acceptDeclineExshibitionArt(id, { is_accepted: "Approved" }, () => dispatch(fetchExhibitionArtData(props.id)))
    );
  };
  
  const handleReject = (id)=> {
    dispatch(
      acceptDeclineExshibitionArt(id, { is_accepted: "Rejected" }, () => dispatch(fetchExhibitionArtData(props.id)))
    );
  };
     const navigate = useNavigate()
   
   const handlePages = () => {
  
      navigate("/exhibitions")
    }
    const usertype = useSelector((state) => state.user.data);
  return (
    <div>
     <div className={styles.main_artistprofile}>
     <div className={styles.back_button}>
    <Link onClick={handlePages} className={styles.backbn}> <IoIosArrowBack /> <span> Go Back</span></Link>
      </div>
      
        
              </div>
      {props.type === "curator_exhibition" ? (
        ""
      ) : (
        <>
        <div className={styles.bsrd}>
        <h1 className={styles.arhdng}></h1>
        </div>
        </>
      )}
      <div className={styles.detail_id}>
      <div className={styles.bdngr}>
      <h3 className={styles.sethng}>Exhibition Title : {props?.data?.data?.exhibition?.title}</h3>
      <p className={styles.odlist}>Concept : <span>{props?.data?.data?.exhibition?.concept}</span></p>
      <p className={styles.odlist}>Created Date : <span>{dateFormat(props.data?.data?.exhibition?.createdAt)}</span></p>
      <p className={styles.odlist}>Start Date : <span>{dateFormat(props.data?.data?.exhibition?.start_date)}</span></p>
      <p className={styles.odlist}>End Date : <span>{ dateFormat(props.data?.data?.exhibition?.end_date || "")}</span></p>
      <p className={styles.odlist}>Application End Date : <span>{ dateFormat(props.data?.data?.exhibition?.end_date_registration)}</span></p>
      <p className={styles.odlist}>No. of Items : <span>{props.data === null ? 0 : props.data?.data?.data.length === 0 ? "0" : props.data?.data?.data.length}</span></p>
                 
                
               </div>
               </div>
    
      {/* {/ <hr /> /} */}
      {props.data === null ?
        <h2 align="center">No Data Found</h2>
        : props.data.data && props.data.data.data.length === 0 ? (
          <h2 align="center">No Data Found</h2>
        )
          : (
            props.data &&
            props.data?.data?.data &&
            props.data?.data?.data?.map((item, i) => (
              <div className={styles.bsrd}>
                <div key={i}>
                <div className={styles.card_main_content}>
                <Grid
                                container
                                spacing={2}
                                sx={{justifyContent:"space-between"}}
                              >
                                <Grid item xs={12} sm={5} md={4}>
                                {item.item.map((val, i) => (
                        <div>
                          <h1 className={styles.art_namms}>
                          <a className={styles.productLink_text} href={`/user/newestpaintings/${val._id}`}>{val.name}</a></h1>
                          <div style={{ padding: "10px 0px" }}>
                           <a href={`/user/newestpaintings/${val._id}`}> <img src={ val.image} className="img-fluid" alt='img' />{" "} </a>
                          </div>
                          {localStorage.getItem('usertype') === 'gallery' ?
                          <div>
                            <p className={styles.odlist}>Artist Name: <span>{item?.artist}</span></p>
                          </div> : ""}
                          
                      
                        </div>
                      ))}
                                </Grid>
                                {usertype.type === "curator" ? "" : (
                                  <Grid item xs={12} sm={5} md={4}>
                                  <div className={styles.quoterequest}>
                   <>
                                           {item?.is_accepted === "Pending" &&
                                           <>
                                                <button
                                                onClick={() => handleAccept(item._id)}
                                               
                                                  className="action_btn"
                                                >
                                                  Accept
                                                </button>
                                                <button
                                                   onClick={() => handleReject(item._id)}
                                                  variant="outlined"
                                                  className="action_libtn"
                                                >
                                                  Decline
                                                </button>
                                                </>
}

{item?.is_accepted === "Approved" && (
  <Typography
    variant="h6"
    sx={{
      color: "green",
      width: "80px",
      height: "34px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "4px",
      fontWeight: 'bold',
      fontSize:'15px'
    }}
  >
    Approved
  </Typography>
)}

{item?.is_accepted === "Rejected" && (
  <Typography
    variant="h6"
    sx={{
      color: "red",
      width: "80px",
      height: "34px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "4px",
      fontWeight: 'bold',
      fontSize:'15px'
    }}
  >
    Rejected
  </Typography>
)}
                                              </>
                  </div>
                                </Grid>
                                )}
                  </Grid>
                  {item.item.map((val, i) => (
                  <div>
                          <p className={styles.odlist}>Price : <span>${item.price}</span></p>
                            <p className={styles.odlist}>Materials : <span>{val.material && val.material.map((c, index) => <>{c.name}{index !== val.material.length - 1 ? ", " : ""} </>)}</span></p>
                            <p className={styles.odlist}>Category : <span>{val.category}</span></p>
                            <p className={styles.odlist}>Dimensions : <span>{val.width} W x {val.height} H x {val.depth} D in cm</span></p>
                          </div>
                  ))}
                  <div className={styles.action}>
                      <p className={styles.remark_text}>{item.remark}</p>
                    </div>
                </div>
              </div>
                     
              </div>
              
            ))
          )}
    </div>
  );
}