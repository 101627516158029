import React, { useCallback, useEffect, useState } from "react";
import styles from "./Commonuploadart.module.css";
import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AlertsSuccess, Errornotify } from "../../../../common/alert/Alert";
import { firstFormArtValidation } from "../../../../validation/firstFormArtValidation";
import { FiUpload } from "react-icons/fi";
import { getMyArtistData } from "../../../../State/api/galleryApi";
export default function Commonuploadart(props) {
  const [firstFormError, setFirstFormError] = useState({});

  const MIN_FILE_SIZE = 100; // Minimum file size in KB
  const MAX_FILE_SIZE = 5000; // Maximum file size in KB (5 MB)

  // const handleImageChange = useCallback(
  //   (e) => {
  //     if (e.target && e.target.files && e.target.files.length > 0) {
  //       const filesToAdd = Array.from(e.target.files); // Convert to array
  //       const existingImages = props.uploadArt.images || []; // Ensure it's an array
  //       const totalImages = existingImages.length + filesToAdd.length;
  //       // Check the total number of images
  //       if (totalImages > 5) {
  //         Errornotify("You can't upload more than 5 images.");
  //         return; // Exit the function without processing further
  //       }
  //       // Validate file sizes (100 KB to 5000 KB)
  //       const validFiles = [];
  //       const allowedTypes = ["image/jpeg", "image/jpg", "image/png"]; // Allowed MIME types
  //       for (let file of filesToAdd) {
  //         const fileSizeInKB = file.size / 1024; // Convert to KB


  //       // Duplicate image validation (based on file name & size)
  //       const isDuplicate = existingImages.some(
  //         (img) => img.name === file.name && img.size === file.size
  //       );
  //       if (isDuplicate) {
  //         Errornotify(`The image "${file.name}" is already uploaded.`);
  //         continue; // Skip duplicate images
  //       }

  //         // File type validation
  //         if (!allowedTypes.includes(file.type)) {
  //           Errornotify("Only .jpg, .jpeg, or .png files are allowed.");
  //           continue; // Skip invalid file types
  //         }

  //         // File size validation
  //         if (fileSizeInKB < MIN_FILE_SIZE) {
  //           Errornotify("File size should be greater than 100KB");
  //         } else if (fileSizeInKB > MAX_FILE_SIZE) {
  //           Errornotify("File size should be less than 5MB");
  //         } else {
  //           validFiles.push(file); // Only valid files are added
  //         }
  //       }

  //       // Exit if no valid files
  //       if (validFiles.length === 0) return;
  //       // Update the props.uploadArt.images
  //       const images = props.uploadArt.images;
  //       images.push(...validFiles);
  //       props.setUploadArt({
  //         ...props.uploadArt,
  //         images: images,
  //       });
  //     }
  //   },
  //   [props.uploadArt]
  // );

  const handleImageChange = useCallback((e) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      const filesToAdd = Array.from(e.target.files); // Convert FileList to array
      const existingImages = props.uploadArt.images || []; // Ensure it's an array
      const totalImages = existingImages.length + filesToAdd.length;

      // Check the total number of images
      if (totalImages > 5) {
        Errornotify("You can't upload more than 5 images.");
        return;
      }

      // Allowed MIME types
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      const validFiles = [];

      for (let file of filesToAdd) {
        const fileSizeInKB = file.size / 1024;

        // **Duplicate Image Validation**
        const isDuplicate = existingImages.some((img) => {
          if (typeof img === "string") {
            // Compare filename by extracting the part after the last `/`
            const existingFileName = img.split("/").pop();
            console.log("Comparing:", existingFileName, "with", file.name); // Debugging
            return existingFileName === file.name;
          } else if (img instanceof File) {
            // Compare file properties for newly uploaded images
            console.log("Comparing File:", img.name, "with", file.name); // Debugging
            return img.name === file.name && img.size === file.size;
          }
          return false;
        });

        if (isDuplicate) {
          Errornotify(`The image "${file.name}" is already uploaded.`);
          continue;
        }

        // File type validation
        if (!allowedTypes.includes(file.type)) {
          Errornotify("Only .jpg, .jpeg, or .png files are allowed.");
          continue;
        }

        // File size validation
        if (fileSizeInKB < MIN_FILE_SIZE) {
          Errornotify("File size should be greater than 100KB");
          continue;
        } else if (fileSizeInKB > MAX_FILE_SIZE) {
          Errornotify("File size should be less than 5MB");
          continue;
        }

        validFiles.push(file);
      }

      // Exit if no valid files
      if (validFiles.length === 0) return;

      // Update images without mutating state directly
      props.setUploadArt((prevState) => ({
        ...prevState,
        images: [...(prevState.images || []), ...validFiles],
      }));
    }
  }, [props.uploadArt]);

  const handleImageRemove = useCallback(
    (index) => {
      // Remove the selected image from the preview array
      props.setSelectedFiles((prevImages) =>
        prevImages.filter((_, i) => i !== index)
      );

      // Remove the corresponding file from the props.uploadArt.images array
      const updatedImages = props.uploadArt.images.filter(
        (_, i) => i !== index
      );

      // Update the parent component's state
      props.setUploadArt({
        ...props.uploadArt,
        images: updatedImages,
      });
    },
    [props.uploadArt]
  );

  // const renderPhotos = (source, handleRemoveImage) => {
  //   return source?.map((photo, index) => (
  //     <div key={photo} className={styles.imageContainer}>
  //       <div className={styles.deleteButton}>
  //         <IconButton onClick={() => handleImageRemove(index)}>
  //           <img
  //             src={"/img/delete.png"}
  //             height="15px"
  //             width="14px"
  //             alt="delete"
  //           />
  //         </IconButton>
  //       </div>
  //       <img className={styles.imgs} src={photo} alt="uploaded" />
  //     </div>
  //   ));
  // };


  const renderPhotos = (source = [], handleImageRemove) => {
    return source?.map((photo, index) => {
      const imageUrl = typeof photo === "string" ? photo : URL.createObjectURL(photo);

      return (
        <div key={`${imageUrl}-${index}`} className={styles.imageContainer}>
          <div className={styles.deleteButton}>
            <IconButton onClick={() => handleImageRemove(index)}>
              <img src="/img/delete.png" height="15px" width="14px" alt="delete" />
            </IconButton>
          </div>
          <img className={styles.imgs} src={imageUrl} alt="uploaded" />
        </div>
      );
    });
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uploadarts = useSelector((state) => state.uploadArt);

  const handleChangeMainImage = (e) => {
    if (e.target.files[0]) {
      const img = e.target.files[0];
      const imgs = URL.createObjectURL(e.target.files[0]);
      const MIN_FILE_SIZE = 100; // kb
      const MAX_FILE_SIZE = 5000; // kb
      const ALLOWED_TYPES = ["image/jpeg", "image/jpg", "image/png"]; // Allowed file types

      const fileSizeKiloBytes = img.size / 1024;

      // File type validation
      if (!ALLOWED_TYPES.includes(img.type)) {
        Errornotify("Only .jpg, .jpeg, or .png files are allowed.");
        return; // Exit if file type is invalid
      }

      if (fileSizeKiloBytes < MIN_FILE_SIZE) {
        Errornotify("File size should be greater than 100kb");
      } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        Errornotify("File size should be less than 5mb");
      } else {
        props.setUploadArt({ ...props.uploadArt, image: img });
        props.setSrc({ ...props.src, main: imgs });
      }
    }
  };

  const handleRemoveimage = () => {
    props.setSrc({ ...props.src, main: "" });
    props.setUploadArt({ ...props.uploadArt, image: "" });
  };
  const location = useLocation();

  const handlePopup = () => {
    const { firstFormErrors, isValidFirstForm } = firstFormArtValidation(
      props.uploadArt.name,
      props.uploadArt.image,
      props.uploadArt.artist_name
    );
    if (isValidFirstForm) {
      // props.setOpen && props.setOpen(true); // Accept Decline popup
      if (location.pathname === "/upload/stepfirst") {
        props.setUploadArt((prevState) => ({
          ...prevState,
          is_showcased: "Yes",
        }));
        navigate("/upload/stepsecond");
      } else {
        navigate("/edit/stepsecond");
      }
      setFirstFormError({});
    } else {
      setFirstFormError(firstFormErrors);
      window.scrollTo({
        top: 150,
        behavior: "smooth",
      });
    }
  };


  const myartistlist = useSelector((state) => state.galleryMyArtistData.data)

  useEffect(() => {
    dispatch(getMyArtistData(localStorage.getItem("user_id")))
  }, [])

  return (
    <div className={styles.mains} sx={{ background: "blue" }}>
      <AlertsSuccess />
      <div className={styles.atwork_div}>
        <h2 className={styles.main_heading}>ArtWork</h2>
        {localStorage.getItem("usertype") === "gallery" && (

          <div className="form-group">
            <label>Artist *</label>
            <Autocomplete
              id="artist-autocomplete"
              disableClearable
              options={myartistlist?.filter((artist) => artist.is_deleted === false) || []}  // Ensure list is not undefined
              getOptionLabel={(option) =>
                option ? `${option.first_name} ${option.last_name}` : ""
              } // Display full name in dropdown
              name="artist_name"
              value={
                myartistlist.find((artist) => artist._id === props.uploadArt?.artist_name) || null
              } // Find selected artist
              onChange={(_, newValue) => {
                props.handleChangedata({
                  target: { name: "artist_name", value: newValue ? newValue._id : "" },
                });
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0px",
                  paddingLeft: ".75rem",
                  fontSize: "14px",
                },
                "& .MuiAutocomplete-input": {
                  paddingLeft: "0 !important",
                },
                width: "100%",
                border: "none",
              }}
              className={styles.search_select}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ border: "none", color: "#636365" }}
                  placeholder="Search Artist"
                />
              )}
            />
          </div>
        )}
        {firstFormError?.artist_name ? (
          <span style={{ color: "red", fontWeight: "500" }}>
            {firstFormError?.artist_name}
          </span>
        ) : null}
        <div className="form-group">
          <label>Title *</label>
          <input
            className="form-control"
            placeholder="Enter your title"
            onChange={props.handleChangedata}
            name="name"
            value={
              props.uploadArt && props.uploadArt.name
                ? props.uploadArt.name
                : ""
            }
          />{" "}
          {firstFormError?.title ? (
            <small
              style={{ color: "red", fontWeight: "500" }}
            >
              {firstFormError?.title}
            </small>
          ) : null}
        </div>
        <div className="form-group">
          <label>Image *</label>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.upload_div_main}>
                <div className={styles.preview_img}>
                  {props.uploadArt?.image ? (
                    <>
                      <input
                        name="image"
                        onChange={handleChangeMainImage}
                        accept="image/*"
                        type="file"
                        id="input"
                        hidden
                      />
                      <label htmlFor="input">
                        <img
                          src={
                            props.uploadArt.image instanceof File
                              ? URL.createObjectURL(props.uploadArt.image)
                              : props.uploadArt.image // Use existing URL if available
                          }
                          alt="Primary"
                          id="img"
                          name="image"
                          className={styles.img_view}
                        />
                      </label>
                    </>
                  ) : (
                    <>
                      <div className={styles.img_box}>
                        <div htmlFor="input" name="image" style={{ textAlign: "center" }}>
                          <input
                            onChange={handleChangeMainImage}
                            accept="image/*"
                            type="file"
                            name="image"
                            id="input"
                            hidden
                          />
                          <label htmlFor="input" className={styles.labelv}>
                            <FiUpload />
                            <p>Upload Your Primary Image</p>
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {props.uploadArt?.image && (
                  <div className={styles.del_img_div}>
                    <IconButton onClick={handleRemoveimage}>
                      <img src={"/img/delete.png"} height="15px" width="14px" alt="delete" />
                    </IconButton>
                  </div>
                )}
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.img_box}>
                <div htmlFor="file" name="image" style={{ textAlign: "center" }} className={styles.preview_imgs}>
                  <input
                    type="file"
                    id="file"
                    multiple
                    accept="image/*"
                    onChange={handleImageChange}
                    hidden
                  />
                  <label htmlFor="file" className={styles.labelv}>
                    <FiUpload />
                    <p>Upload Your Secondary Image</p>
                  </label>
                </div>
              </div>
            </Grid>
          </Grid>

          {firstFormError?.image && (
            <small style={{ color: "red", fontWeight: "500" }}>
              {firstFormError.image}
            </small>
          )}

          <div className={styles.result}>
            {renderPhotos(props?.uploadArt?.images, handleImageRemove)}
          </div>

          <p>
            Please select a size between 100KB to 5000KB <br />
            You can upload a maximum of 5 secondary images.<br />
            <small className={styles.des}>
              Upload an image of your artwork (.JPG/.JPEG/.PNG) with an RGB color format.
            </small>
          </p>
        </div>
        <div className="form-group">
          <label>Copyright</label>
          <label>Are you the copyright owner of this artwork?</label>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              onChange={props.handleChangedata}
              value={
                props.uploadArt && props.uploadArt.copyright
                  ? props.uploadArt.copyright
                  : ""
              }
              name="copyright"
            >
              <FormControlLabel
                className={styles.radio_label}
                control={<Radio />}
                label="Yes"
                value="Yes"
                name="copyright"
              />
              <FormControlLabel
                className={styles.radio_label}
                value="No"
                control={<Radio />}
                label="No"
                name="copyright"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={styles.btn_div}>
          <button className="action_btn" onClick={handlePopup}>
            Continue
          </button>
        </div>
        <div className="form-group"></div>
      </div>
    </div>
  );
}
