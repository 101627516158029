import React, { useEffect, useMemo } from "react";
import Header from "../Pages/Header/Header";
import Footer from "../Pages/Footer/Footer";
import Navbar from "../Pages/Navbar/Navbar";
import Sidebar from "../Pages/Sidebar/Sidebar";
import styles from "./Layout.module.css";
import Popupfeatured from "../Pages/List/Artist/Popups/Popupfeatured";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import CuratorSidebar from "../../curator/sideBar/CuratorSidebar";
import Gallerysidebar from "../../gallery/sidebar/Gallerysidebar";
import Sidebarartist from "../../artist/pages/sidebarArtist/Sidebarartist";
import Sidebaraffiliate from "../../affiliate/sidebar/SIdebaraffiliate";
import UserMain from "../../UserMain";
import { useDispatch, useSelector } from "react-redux";
import { removeUser, setUser } from "../../State/Features/common/userSlice";
import UserSidebar from "../Pages/User/User Profile/User sidebar/UserSidebar";
import { Container, Grid } from "@mui/material";
import Pagenotfound from "../pagenotfound/Pagenotfound";
const authPaths = [
  "/login",
  "/signup",
  "artist/applynow",
  "artist/fillquestion",
  "artist/applyform",
  "artist/personaldetail",
  "/affiliate/applyform",
  "/affiliate/applyformsecond",
  "/affiliate/fillquestion",
  "/affiliate/applynow",
  "gallery/applynow",
  "/gallery/fillquestion",
  "/gallery/applyform",
  "/curator/applyform",
  "/curator/applynow",
  "/curator/fillquestion",
  "/artist/form-submission",
  "/curator/form-submission",
  "/affiliate/form-submission",
  "/gallery/form-submission", 
  "/gallery/personaldetails",
];

const Layout = ({ children, usertype }) => {
  const location = useLocation();
  const usertypes = localStorage.getItem("usertype");
  const is_accepted = localStorage.getItem("is_accepted");
  const userty = useSelector((state) => state.user.data);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);
  const path = authPaths.some((route) => location.pathname.includes(route));
  const userpath = location.pathname.includes("/user/");
  const userpath_ = location.pathname.includes("");
  const userpathUploadArt =
    location.pathname.includes("/upload/") ||
    location.pathname.includes("/edit")

  const userOrderDetailsPath = location.pathname.includes("/userorder/details");
  const homepath = location.pathname;


  const validPage = children ? children : <Pagenotfound />;


  return (
    <>
      {path ? (
      
        children      
      ) : (
        <>
          <Header />
          {userty.signed ? (
            userty.signed && (userpath || userpath_) ? (
              <Navbar />
            ) : usertypes === "buyer" ? (
              <Navbar />
            ) : (
              ""
            )
          ) : (
            <Navbar />
          )}
          {userpath || homepath === "/" ? (
            children
          ) : (
            <section className={styles.headrtop}>
              <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
                <Grid
                  container
                  spacing={2}
                  className={
                    userty.signed
                      ? usertype === "buyer" && !userpath
                        ? styles.curator_children
                        : is_accepted === "Pending"
                          ? styles.curator_childrens
                          : usertype === ""
                            ? ""
                            : styles.curator_children
                      : ""
                  }
                >
                  <Grid item xs={12} sm={3} md={3} lg={2}>
                    {userty.signed && usertype === "buyer" ? (
                      <UserSidebar />
                    ) : is_accepted === "Pending" ? (
                      ""
                    ) : usertype === "curator" ? (
                      <CuratorSidebar />
                    ) : usertype === "gallery" ? (
                      userpathUploadArt ? (
                        ""
                      ) : (
                        <Gallerysidebar />
                      )
                    ) : usertype === "superadmin" ? (
                      <Sidebar />
                    ) : usertype === "artist" ? (
                      userpathUploadArt ? (
                        ""
                      ) : (
                        <Sidebarartist />
                      )
                    ) : usertype === "affiliate" ? (
                      <Sidebaraffiliate />
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={9} md={9} lg={10}>
                  {children}
                  </Grid>
                </Grid>
              </Container>
            </section>
          )}
          <Footer />
        </>
      )}
    </>

   
  );
};
export default Layout;
