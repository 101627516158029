import React, { useState, useEffect } from "react"
import styles from "./MenuDetail.module.css"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchSubmenuData,
  editSubmenudata,
  deleteSubmenu,
} from "../../../../../State/api/superadminApi"
import { Button, IconButton, TextField, Link } from "@mui/material"
import Editcategory from "../../Popupdelete/editCategory/Editcategory"
import { AlertsSuccess } from "../../../../../common/alert/Alert"
import CircularLoader from "../../../../../common/loader/Loader"
import { useNavigate } from "react-router-dom"
import { IoIosArrowBack } from "react-icons/io";
import { FiTrash2, FiEdit3 } from "react-icons/fi";

const MenuDetail = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [name, setName] = useState({ name: "", link: "", _id: "" })
  const [menu, setMenu] = useState({ add: false, edit: false })

  const loader = useSelector((state) => state.loader)

  const handleEdits = (type, menuName, link, _id) => {
    if (type === "edit") {
      setMenu({ ...menu, edit: true })
      setName({ name: menuName, link, _id })
    } else {
      setMenu({ ...menu, add: true })
    }
  }

  const handleSubmit = (id) => {
    dispatch(editSubmenudata(id, name))
  }

  const handleDelete = (id) => {
    dispatch(deleteSubmenu(id)).then(() => {
      fetchMenuData()
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setName((prevName) => ({ ...prevName, [name]: value }))
  }

  const subMenuByMenuIdData = useSelector(
    (state) => state.MenuBySubMenuData.data
  )

  const fetchMenuData = () => {
    dispatch(fetchSubmenuData(id))
  }

  useEffect(() => {
    fetchMenuData()
  }, [])

const navigate = useNavigate()
  const handleBack = () => {
    navigate("/menu")
  }

  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      <div className={styles.back_button}>
    <Link onClick={handleBack} className={styles.backbn}> <IoIosArrowBack /> <span> Go Back</span></Link>
      </div>
      <h3 className={styles.hrdngofv}>Menu Name: {subMenuByMenuIdData ? subMenuByMenuIdData?.name : ""}</h3>
      <div className={styles.detail_main}>
        <div className={styles.detail}>
          <h3 className={styles.title}>Sub menu</h3>
          {loader.loader ? (
            <CircularLoader size={60} />
          ) : subMenuByMenuIdData &&
            subMenuByMenuIdData.submenus.length === 0 ? (
            "------"
          ) : (
            subMenuByMenuIdData &&
            subMenuByMenuIdData?.submenus?.map((val) => (
              <p className={styles.detail_para} key={val._id}>
                {menu.edit && val._id === name._id ? (
                  <>
                    <input
                    className="form-control"
                      value={name.name}
                      name="name"
                      onChange={handleChange}
                      variant="outlined"
                      type="text"
                    />
                    <input
                    className="form-control"
                      value={name.link}
                      name="link"
                      onChange={handleChange}
                      variant="outlined"
                      type="text"
                    />
                    <button
                      className="fill-button-menu"
                      onClick={() => handleSubmit(val._id)}
                    >
                      Submit
                    </button>
                  </>
                ) : (
                  <>
                    <span className={styles.menpr}>{val.name} - {val.link}</span>
                    <div>
                    <IconButton
                      onClick={() =>
                        handleEdits("edit", val.name, val.link, val._id)
                      }
                    >
                      <FiEdit3 className={styles.editicon} />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(val._id)}>
                    <FiTrash2 className={styles.deleicon}/>
                    </IconButton>
                    </div>
                    
                  </>
                )}
              </p>
            ))
          )}
        </div>
      </div>
    </div>
  )
}

export default MenuDetail
