import React, { useState } from "react";
import styles from "./CareerSuperadminTable.module.css";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CircularLoader from "../../../../common/loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { addData } from "../../../../State/Features/Artist/profileSlice";
import moment from "moment";

import { FiTrash2, FiEdit3 } from "react-icons/fi";
function CareerSuperadminTable(props) {
  console.log("Career Table Props", props);

  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const handledetailpage = (row) => {
    if (location.pathname === "/career") {
      navigate(`/career-detail/${row._id}`);
    }
    dispatch(addData(row));
  };

  const [expandedRows, setExpandedRows] = useState({}); // Track expanded state for each row

  const toggleReadMore = (rowIndex) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex], // Toggle the state for the specific row
    }));
  };

  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props?.head?.map((column, i) => (
                <TableCell
                  className={styles.table_head}
                  align={props.head.length - 1 === i ? "left" : "left"}
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "15px",
                    textTransform:"capitalize",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props?.careerdata?.data &&
              props?.careerdata?.data?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props?.careerdata?.data &&
                props?.careerdata?.data?.map((row, index) => (
                  <TableRow
                    style={{ color: "#636365", height: "30px" }}
                    className={styles.table_row}
                    key={row._id}
                  >
                    <TableCell sx={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        textTransform:"capitalize",
                      }} className={styles.table_column} align="left">
                      <a
                        className={styles.hyperlink_title}
                        onClick={(e) => {
                          e.preventDefault(); // Prevent default navigation for controlled behavior
                          handledetailpage(row); // Pass the exhibition title flag
                        }}
                      >
                        {row?.title}
                      </a>
                    </TableCell>
                    <TableCell className={styles.table_column} sx={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }} align="left">
                      {moment(row?.createdAt)?.format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell className={styles.table_column} align="left" sx={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}>
                      {row?.location}
                    </TableCell>
                    <TableCell className={styles.table_column} align="left" sx={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}>
                      {row?.department}
                    </TableCell>
                    <TableCell className={styles.table_column} align="left" sx={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}>
                      <div>
                        <b>Exp : </b>
                        {row?.min_exp}-{row?.max_exp} yrs
                      </div>
                    </TableCell>
                    <TableCell className={styles.table_column} align="left" sx={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}>
                      <div
                        style={{
                          maxWidth: "500px", // Set a consistent maximum width for the container
                          wordWrap: "break-word", // Ensure long words break to the next line
                          overflow: expandedRows[index] ? "visible" : "hidden", // Allow overflow when expanded
                          whiteSpace: expandedRows[index] ? "normal" : "nowrap", // Adjust whitespace based on expanded state
                          textOverflow: expandedRows[index]
                            ? "clip"
                            : "ellipsis", // Handle text overflow for collapsed state
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: expandedRows[index]
                              ? row?.description
                              : row?.description?.length > 50
                              ? `${row?.description
                                  ?.substring(0, 50)
                                  ?.trim()}...`
                              : row?.description,
                          }}
                        ></div>
                        {(row?.description?.split(" ").length > 10 ||
                          row?.description?.includes("<img")) && (
                          <a
                            onClick={() => toggleReadMore(index)} // Pass the row index to toggle
                            className={styles.read_more_button}
                            style={{
                              cursor: "pointer",
                              color: "#007BFF",
                              textDecoration: "underline",
                            }}
                          >
                            {expandedRows[index] ? "Read Less" : "Read More"}
                          </a>
                        )}

                       
                      </div>
                    </TableCell>
                    <TableCell className={styles.table_column} align="left">
                        <div className={styles.action}>
                          <IconButton onClick={() => props.handleEditpopup(row)}><FiEdit3 className={styles.editicon} /></IconButton>
                          <IconButton onClick={() => props.handleDeletecareeer(row._id)}><FiTrash2 className={styles.deleicon}/></IconButton>
                          
                        </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      
    </div>
  );
}

export default CareerSuperadminTable;
