import {
  Autocomplete,
  Box,
  Button,
  Container,
  createTheme,
  FormHelperText,
  Grid,
  Modal,
  Link,
  Snackbar,
  TextField,
  ThemeProvider,
  Typography,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "./AskAQuestion.module.css";

import classNames from "classnames";
import { Helmet } from "react-helmet";
// import "fontsource-marcellus";
import ProdDetsOther from "./ProdDetsOther";
import ProdDetsqna from "./ProdDetsqna";
import PolicySidebar from "./PolicySidebar";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ChooseFrameModal from "./ChooseFrameModal";
import homeImage from "../../../constant";
import api from "../../../State/api/Api";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  fetchAllItemData,
  fetchCategoryData,
  fetchPrivacyPolicy,
  fetchUserArtist,
  handelAddRecentlyView,
  handelAddtocart,
  handelAddWishlist,
  handelMakeOffer,
  handelRequestQuote,
  handleLikesViews,
  handleRequestInquiry,
  handleRequestRecreate,
  handleUpdateLikesViewsFav,
} from "../../../State/api/userApi";
import { setPolicy } from "../../../State/Features/user/policySlice";
import {
  AlertsSuccess,
  Errornotify,
  Successnotify,
} from "../../../common/alert/Alert";
import CircularLoader from "../../../common/loader/Loader";
import { setWishlistdata } from "../../../State/Features/user/wishlistSlice";
import { setMycartdata } from "../../../State/Features/user/mycartSlice";
import serverimage from "../../../serverConstant";
import { Landscape } from "@mui/icons-material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  fetchFrameCountry,
  generateAffiliateLink,
  getAffilateLink,
} from "../../../State/api/superadminApi";
import country from "../../../Country";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import FavoriteIcon from "@mui/icons-material/Favorite";
import axios from "axios";
import { useRef } from "react";
import {
  formatNewCurrency,
  initializeCurrencyFormatter,
} from "../../../utils/currencyFormat";
import { validationForMakeOffer } from "../../../validation/validationForMakeOffer";
import { validationForQuote } from "../../../validation/validationForQuote";
import { validationForInquiry } from "../../../validation/validationForInquiry";
import { validationForRecreate } from "../../../validation/validationForRecreate";
import { RiStarFill, RiCloseCircleFill} from "react-icons/ri";
import {FiHeart, FiShare2, FiEye, FiThumbsUp } from "react-icons/fi";

const theme = createTheme({
  palette: {
    primary: {
      light: "#C1C1C1",
      main: "#000000",
      dark: "#C1C1C1",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffffff",
      main: "#C1C1C1",
      dark: "#ffffff",
      contrastText: "#000",
    },
  },
  typography: {
    prodheading: {
      fontFamily: "'Marcellus', serif",
      fontSize: "2.5rem",
    },
  },
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  borderRadius: "3px",
  boxShadow: 24,
};
const cardDetails = [
  {
    id: 1,
    imgUrl: "/img/Rectangle 368.png",
  },
  {
    id: 2,
    imgUrl: "/img/Rectangle 369.png",
  },
  {
    id: 3,
    imgUrl: "/img/Rectangle 370.png",
  },
  {
    id: 4,
    imgUrl: "/img/Rectangle 5199.png",
  },
];

function AskAQuestion() {
  const [inquiryRequest, setInquiryRequest] = useState({
    email: localStorage.getItem("user_email") || "",

    description: "",
  });
  const [recreateRequest, setRecreateRequest] = useState({
    item_id: "",
    email: "",
    message: "",
  });
  const [openquestion, setOpenQuestion] = React.useState(false);
  const [openQuote, setOpenQuotes] = React.useState(false);
  const [openInquiry, setOpenInquiry] = React.useState(false);
  const [openRecreate, setOpenRecreate] = React.useState(false);
  const [openLink, setOpenLink] = React.useState(false);
  const [orderTypesId, setOrderTypesId] = useState("");
  const [makeOffer, setMakeOffer] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    price: "",
    message: "",
    item_id: "",
  });
  const [quoteRequest, setquoteRequest] = useState({
    item_id: "",
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    message: "",
  });
  const [makeOfferErrors, setMakeOfferErrors] = useState({});
  const [quoteError, setQuoteErrors] = useState({});
  const [inquiryError, setInquiryErrors] = useState({});
  const [recreateError, setRecreateErrors] = useState({});

  const [frameModal, setFrameModal] = useState(false);
  const [frameCountry, setFrameCountry] = useState(null);
  const [frameModalsecond, setFrameModalsecond] = useState(false);
  const [frameData, setFrameData] = useState({
    thickness_id: "",
    finishtouch_id: "",
    framing_id: "",
  });
  const user_id = localStorage.getItem("user_id");
  const [addTocart, setAddTocart] = useState({
    userId: user_id,
    items: [],
  });
  const [preview_img, setPreview_img] = useState("");
  const handleClickChooseFrame = () => {
    if (frameData.finishtouch_id === "") {
      Errornotify("Please select any finishing touch");
    } else {
      setFrameModal(false);
      setFrameModalsecond(true);
    }
  };
  const handleBackbutton = () => {
    setFrameModal(true);
    setFrameModalsecond(false);
    setFrameData({ thickness_id: "", finishtouch_id: "", framing_id: "" });
  };
  const wishlist = useSelector((state) => state.wishlistData);
  const allitemdt = useSelector((state) => state.allitemData);
  const [openquote, setOpenQuote] = React.useState(false);
  const [productdetails, setProductdetails] = useState();

  const buyId = productdetails?.orderTypes?.filter(
    (val) => val.name === "Buy"
  )[0]?._id;
  const [availablity, setAvailablity] = useState(null);
  const artist_id =
    productdetails &&
    productdetails?.artwork.map((vl) => {
      return vl.artist_id && vl.artist_id._id;
    });
  var itmImage = "";
  productdetails &&
    productdetails.artwork.map((val) => {
      itmImage = val.image;
    });
  const handleOpenQuestion = () => setOpenQuestion(true);
  const handleOpenInquiry = () => setOpenInquiry(true);
  const handleOpenQuotes = () => setOpenQuotes(true);
  const handleClose = () => setOpenQuestion(false);
  const handleCloseInquiry = () => setOpenInquiry(false);
  const handleCloseQuotes = () => setOpenQuotes(false);
  const handleClickRent = () => {
    window.location.href = `/user/rent/${url[2]}/${id}`;
  };
  const handleOpenQuote = () => navigate("/");
  const handleCloseQuote = () => setOpenQuote(false);
  const { id } = useParams();
  const url = window.location.pathname.split("/");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [affiliateLink, setAffiliateLink] = useState([]);
  const userty = useSelector((state) => state.user.data);
  const loader = useSelector((state) => state.loader);
  const myCart = useSelector((state) => state.mycartData.data);
  const mac_Address = localStorage.getItem("device_id");

  const userartists = useSelector((state) => state.userartistData.data);
  const location = useLocation();
  const handleFetchAllitem = async function () {
    dispatch(setLoader(true));
    try {
      // const result = await api.applicationApi('api/user/getoffers')
      const result = await api.applicationApi(`api/user/allitems/${id}`);
      if (result && result.status === 200) {
        setProductdetails(result.data);
        result.data.artwork.forEach((element) => {
          setMakeOffer({ ...makeOffer, item_id: element._id });
        });
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };
  const handleAffiliateLink = (a, b) => {
    dispatch(generateAffiliateLink({ item_id: a, sku_id: b }));
    setOpenLink(true);
    getLink();
  };
  const getLink = () => {
    dispatch(getAffilateLink(setAffiliateLink));
  };
  function artplacers() {
    <Helmet>
      <script
        src="https://widget.artplacer.com/js/script.js"
        crossorigin="anonymous"
        async
      ></script>
    </Helmet>;
  }

  //  useEffect(() => {
  
  //   const apScript = document.createElement("script");
  //   apScript.src = "https://widget.artplacer.com/js/script.js";
  //   apScript.addEventListener("load", () => {
  //    ArtPlacer.insert({
  //     gallery: "42581",
  //     default_style: "true",
  //     resizable: "false",
  //     frames: "true",
  //     rotate: "false",
  //     dimensions_standard: "hxw",
  //     catalog: "false",
  //     type: "2",
  //     space: "2481",
  //     artwork_url:  itmImage,
  //     title: "dynamic title here",
  //     size: "dynamic size here",
  //     price: "dynamic price here",
  //     artist: "dynamic artist name here",
  //     after: "css selector for element after which button should be placed",
  //    });
  //   });
  //   document.body.appendChild(apScript);
  //   // return () => ArtPlacer.clear();
  //  }, []);

  
  //  useEffect(() => {
  
  //   const script = document.createElement("script")
  //   script.src = "https://widget.artplacer.com/js/script.js"
  //   // script.async = true
  //   script.addEventListener("load", () => {
  //     ArtPlacer.insert({
  //      gallery: "42581",
  //      default_style: "true",
  //      resizable: "false",
  //      frames: "true",
  //      rotate: "false",
  //      dimensions_standard: "hxw",
  //      catalog: "false",
  //      type: "2",
  //      space: "2481",
  //      artwork_url:  itmImage,
  //      title: "dynamic title here",
  //      size: "dynamic size here",
  //      price: "dynamic price here",
  //      artist: "dynamic artist name here",
  //      after: "css selector for element after which button should be placed",
  //     });
  //    });
  //   // script.crossOrigin = "anonymous"
  //   document.body.appendChild(script)
  //   return () => {
  //     // clean up the script when the component in unmounted
  //     document.body.removeChild(script)
  //   }
  // }, [])

  // const handleRecentlyView = () => {
  //   const recent = localStorage.getItem("recently_view");
  //   if (recent) {
  //     if (!recent.includes(id)) {
  //       const r = JSON.parse(recent) || [];
  //       r.push(id);
  //       localStorage.setItem("recently_view", JSON.stringify(r));
  //     } else {
  //       const include = JSON.parse(recent) || [];
  //       const index = include.indexOf(id);
  
  //       include.splice(index, 1);
  //       include.push(id);
  //       localStorage.setItem("recently_view", JSON.stringify(include));
  //     }
  //   } else {
  //     const recently = [id];
  //     localStorage.setItem("recently_view", JSON.stringify(recently));
  //   }
  // };
  useEffect(() => {
    handleFetchAllitem();
    dispatch(fetchFrameCountry(setFrameCountry));
    dispatch(fetchAllItemData());
    initializeCurrencyFormatter();
    dispatch(
      handleLikesViews({ item_id: id, string: "view" }, handleFetchAllitem)
    );

    // handleRecentlyView(); // by localStorage

    dispatch(handelAddRecentlyView({ item_id: id }));
    // dispatch(handleLikesViews({ "item_id": id, "likes": likes + 1,"views":views+1 })),
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [id]);

  const textFieldRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    if (textFieldRef.current) {
      textFieldRef.current.select();
      document.execCommand("copy");
      setCopied(true);
    }
  };
  const handleCloseLink = () => {
    setOpenLink(false);
  };
  const handleChangeOffer = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setMakeOffer({ ...makeOffer, [name]: value });
  };
  const handleSubmitOffer = () => {
    const { offerErrors, isValidMakeOffers } =
      validationForMakeOffer(makeOffer);
    if (isValidMakeOffers) {
      dispatch(
        handelMakeOffer(makeOffer, setOpenQuestion, setMakeOffer, navigate)
      );
      setMakeOfferErrors({});
    } else {
      setMakeOfferErrors(offerErrors);
    }
  };
  const handleShare = () => {
    const data = {
      title: "Artsmilly codes",
      text: "Hello Artsmilly",
      url: "http://localhost:3000",
    };
    if (navigator.canShare && navigator.canShare(data)) {
      navigator.share(data);
    } else {
    }
  };
  const handleWishlist = (id) => {
    if (userty.signed === false) {
      Errornotify("Please login ");
      navigate("/login");
      localStorage.setItem("buyer_back_url", location.pathname);
    } else {
      dispatch(handelAddWishlist({ item_id: id }));
    }
  };
  const handleAddCart = async (id) => {
    const val = id;
    const index = myCart && myCart.result.map((item) => item.item_id._id);

    if (userty.signed === false) {
      // const ress = await axios.get('https://geolocation-db.com/json/')
      dispatch(
        handelAddtocart(
          {
            item_id: val,
            orderType_id: buyId,
            user_id: user_id,
            quantity: 1,
            IP_address: mac_Address,
          },
          { offset: 1, user_id: localStorage.getItem("user_id") }
        )
      );
      // Errornotify("Please login ");
      // navigate("/artsmiley/login");
      // localStorage.setItem("buyer_back_url", location.pathname);
    } else {
      dispatch(
        handelAddtocart(
          {
            item_id: val,
            orderType_id: buyId,
            user_id: user_id,
            quantity: 1,
            IP_address: mac_Address,
          },
          { offset: 1, user_id: localStorage.getItem("user_id") }
        )
      );
    }
  };
  const handelSubmitRequest = () => {
    const { quoteErrors, isValidQuote } = validationForQuote(quoteRequest);
    if (isValidQuote) {
      dispatch(handelRequestQuote(quoteRequest, setOpenQuotes));
      handleFetchAllitem();
    } else {
      setQuoteErrors(quoteErrors);
    }
  };

  const handleSubmitInquiry = () => {
    const { inquiryErrors, isValidInquiry } =
      validationForInquiry(inquiryRequest);
    if (isValidInquiry) {
      dispatch(handleRequestInquiry(id, inquiryRequest, setOpenInquiry));
      handleFetchAllitem();
    } else {
      setInquiryErrors(inquiryErrors);
    }
  };
  const handleSubmitRecreate = (id) => {
    const { recreateErrors, isValidRecreate } =
      validationForRecreate(recreateRequest);
    if (isValidRecreate) {
      dispatch(
        handleRequestRecreate(
          { ...recreateRequest, item_id: id },
          setOpenRecreate
        )
      );
      handleFetchAllitem();
    } else {
      setRecreateErrors(recreateErrors);
    }
  };
  const handleChangeQuote = (e) => {
    const { name, value } = e.target;
    setquoteRequest({ ...quoteRequest, [name]: value });
  };
  const handleChangeInquiry = (e) => {
    setInquiryRequest({ ...inquiryRequest, [e.target.name]: e.target.value });
  };
  const handleChangeRecreate = (e) => {
    setRecreateRequest({ ...recreateRequest, [e.target.name]: e.target.value });
  };
  const handleArtistProfile = (id) => {
    id.forEach((vl) => {
      navigate(`/user/artist/${vl._id}`);
    });
  };
  const handleProducts = (ids) => {
    const path = window.location.pathname;
    if (path.includes("user/trending")) {
      navigate(`/user/trending/${ids}`);
    } else if (path.includes("user/bestseller")) {
      navigate(`/user/bestseller/${ids}`);
    } else if (path.includes("mostpopular")) {
      navigate(`/user/mostpopular/${ids}`);
    } else if (path.includes("user/newestpaintings")) {
      navigate(`/user/newestpaintings/${ids}`);
    } else if (path.includes("user/allcategory/")) {
      navigate(`/user/allcategory/:id/${ids}`);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleClickPrints = () => {
    window.location.href = `/user/print/${url[2]}/${id}`;
  };
  const handleLikeUnlike = (id, type) => {
    if (userty.signed === false) {
      Errornotify("Please login ");
    } else if (type === "like") {
      dispatch(
        handleLikesViews({ item_id: id, string: type }, handleFetchAllitem)
      );
    } else if (type === "unlike") {
      dispatch(
        handleUpdateLikesViewsFav(
          { item_id: id, string: "like" },
          handleFetchAllitem
        )
      );
    }
  };
  function reloadPage() {
    // The last "domLoading" Time //
    var currentDocumentTimestamp = new Date(
      performance.timing.domLoading
    ).getTime();
    // Current Time //
    var now = Date.now();
    // Ten Seconds //
    var tenSec = 10 * 1000;
    // Plus Ten Seconds //
    var plusTenSec = currentDocumentTimestamp + tenSec;
    if (now > plusTenSec) {
      window.location.reload();
    } else {
    }
  }
  // const getData = async () => {
  //   const res = await axios.get("https://api.ipify.org/?format=json");
  //   const ress = await axios.get('https://geolocation-db.com/json/')
  
  
  // };

  useEffect(() => {
    reloadPage();
    // getData();
  }, []);
  const handlePreviewChangeImage = (e) => {
    const { name } = e.target;
    setPreview_img(name);
  };
  const checkFrameCountry = (e, newValue) => {
    const availabl = frameCountry && frameCountry.includes(newValue);
    setAvailablity(availabl);
  };

  useEffect(() => {
    productdetails?.artwork?.map((val) => {
      if (val?.offer_id?.length) {
        const discountPercentage = val?.offer_id
          ?.map((dis) => dis?._id?.discount)
          .join("");
        localStorage.setItem(
          "discount_percentage",
          JSON.stringify(discountPercentage)
        );
      } else {
        localStorage.removeItem("discount_percentage");
      }
    });
  }, [productdetails]);
  // const imageClassNames = `${styles.main__img} ${true ? styles.whitecanvas : ''} `;
  const [imageHeight, setImageHeight] = useState(null);
  const [imageWidth, setImageWidth] = useState(null);
  const [orientation, setOrientation] = useState(""); // 'vertical' or 'horizontal'
  const handleImageLoad = (event) => {
    const height = event.target.height;
    const width = event.target.width;

    setImageHeight(height);
    setImageWidth(width);

    if (height > width) {
      setOrientation("vertical");
    } else {
      setOrientation("horizontal");
    }
  };

  const btnClass = classNames({
    [styles.verticalImage]: orientation === "vertical",
    [styles.horizontalImage]: orientation === "horizontal",
  });

  // Combine CSS module styles with dynamic class names
  const combinedClassName = classNames(styles.main__img, btnClass);
  return (
    <ThemeProvider theme={theme}>
      <AlertsSuccess />
      {frameModal ? (
        <ChooseFrameModal
          handleClickChooseFrame={handleClickChooseFrame}
          frameModal={frameModal}
          setFrameModal={setFrameModal}
          setFrameData={setFrameData}
          frameData={frameData}
        />
      ) : (
        ""
      )}
      {frameModalsecond ? (
        <ChooseFrameModal
          setFrameData={setFrameData}
          frameData={frameData}
          setFrameModalsecond={setFrameModalsecond}
          frameModalsecond={frameModalsecond}
          handleClickChooseFrame={handleClickChooseFrame}
          handleBackbutton={handleBackbutton}
        />
      ) : (
        ""
      )}
      <div className={styles.productdetails}>
        <div className={styles.proddets__top}>
          {loader.loader ? (
            <div className={styles.loader}>
              <CircularLoader />
            </div>
          ) : (
            productdetails &&
            productdetails.artwork.map((val) => (
              <>
                <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
                  <Grid container  spacing={{xs:2, sm:2, md:5}}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <div className={styles.prod__img}>
                        <img
                          className={combinedClassName}
                          onLoad={handleImageLoad}
                          src={
                            preview_img.length !== 0
                              ? preview_img
                              :  val.image
                          }
                          alt="img"
                        />
                        <br />
                        <div className={styles.previewimg__div}>
                          {/* <img
                            className={styles.small_img}
                            onClick={handlePreviewChangeImage}
                            src={ "/img/aboutusimg.png"}
                            name="/img/aboutusimg.png"
                            alt="img"
                          />
                          <img
                            className={styles.small_img}
                            onClick={handlePreviewChangeImage}
                            src={ "/img/Rectangle 5203.png"}
                            alt="/img/Rectangle 5330.png"
                            name="/img/Rectangle 5203.png"
                          />
                          <img
                            className={styles.small_img}
                            onClick={handlePreviewChangeImage}
                            src={ "/img/Rectangle 5331.png"}
                            name="/img/Rectangle 5330.png"
                            alt="img"
                          /> */}
                          <img
                            className={styles.small_img}
                            onClick={handlePreviewChangeImage}
                            src={ val.image}
                            name=""
                            alt="img"
                          />
                          <div>
                            <Helmet>
                              <script
                                src="https://widget.artplacer.com/js/script.js"
                                crossorigin="anonymous"
                                async
                                type="text/javascript"
                              ></script>
                            </Helmet>
                            <artplacer
                              gallery="42581"
                              resizable="false"
                              frames="true"
                              rotate="false"
                              dimensions_standard="hxw"
                              catalog="false"
                              type="2"
                              thumb="https://d7mntklkfre1v.cloudfront.net/thumbs/58x58/04258116680971572846048.png"
                              space="65785"
                              artwork_url={ itmImage}
                              title="View In A Room"
                              size="dynamic size here"
                              price="dynamic price here"
                              artist="dynamic artist name here"
                            ></artplacer>
                            <artplacer
                              gallery="42581"
                              resizable="false"
                              frames="true"
                              rotate="false"
                              dimensions_standard="hxw"
                              catalog="false"
                              type="1"
                              thumb="https://d7mntklkfre1v.cloudfront.net/thumbs/58x58/0425815225614657042477.png"
                              artwork_url={ itmImage}
                              title="Try In Your Room"
                              size="dynamic size here"
                              price="dynamic price here"
                              artist="dynamic artist name here"
                            ></artplacer>
                            <artplacer
                              gallery="42581"
                              dimensions_standard="hxw"
                              type="3"
                              thumb="https://d7mntklkfre1v.cloudfront.net/thumbs/58x58/0425814222593640176562.png"
                              resizable="false"
                              frames="true"
                              rotate="false"
                              catalog="false"
                              artwork_url={ itmImage}
                              title="View In AR"
                              size="dynamic size here"
                              price="dynamic price here"
                              artist="dynamic artist name here"
                            ></artplacer>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <div className={styles.prodDetails}>
                        <h1>{val.name}</h1>
                        <Link className={styles.authname}
                          onClick={() =>
                            handleArtistProfile(
                              userartists &&
                                userartists.filter(
                                  (vl) => vl.user_id === val.artist_id._id
                                )
                            ).map((v) => v._id)
                          }
                        >
                          by {val.artist_id && val.artist_id.first_name}{" "}
                          {val.updatedAt.substring(0, 4)}
                        </Link>

                        <div className={styles.pricing__section}>
                          <div className={styles.pricediv}>
                          <p className={styles.mprice}>
                              {formatNewCurrency(
                                val?.sale?.price *
                                  (1 -
                                    val?.offer_id
                                      ?.map((value) =>
                                        parseFloat(value?._id?.discount)
                                      )
                                      ?.reduce(
                                        (acc, discount) => acc + discount,
                                        0
                                      ) /
                                      100)
                              )}{" "}
                              {val?.offer_id?.length ? (
                                <>
                                  <span
                                    style={{
                                      marginLeft: "10px",
                                      color: "gray",
                                      fontWeight: "500",
                                      textDecoration: "line-through",
                                      fontSize: "25px",
                                    }}
                                  >
                                    {formatNewCurrency(val.sale.price)}
                                  </span>
                                  <span
                                    style={{
                                      marginLeft: "10px",
                                      color: "#2FAC4B",
                                      fontWeight: "bold",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {val?.offer_id?.map((value) =>
                                      parseFloat(value?._id?.discount)
                                    )}
                                    % off
                                  </span>
                                </>
                              ) : null}
                            </p>

                            {val?.sale?.qty === 0 ? (
                             <p className={styles.stock}
                             style={{
                               color: "#CE1616", // Use "color" for text
                             }}
                             >
                                Out Of Stock
                              </p>
                            ) : (
                              <p  className={styles.stock}
                              style={{color:"#2FAC4B",}}
                              >
                                In Stock
                              </p>
                            )}
                          </div>
                          <div className={styles.sharediv}>
                            <div
                              onClick={handleShare}
                              className={styles.ellipseshare}
                            >
                              <FiShare2 className={styles.shricn}/>
                            </div>
                            <div
                              onClick={() => handleWishlist(val._id)}
                              className={styles.ellipseheart}
                            >
                              {/* <img
                                className={styles.vectorheart}
                                src={ "/img/Vector-heart.png"}
                                alt="img"
                              /> */}
                              {wishlist &&
                              wishlist.data &&
                              wishlist.data
                                .map((val) => val.item_id && val.item_id._id)
                                .includes(id) ? (
                                <FiHeart
                                  onClick={() => handleWishlist(val._id)}
                                  className={styles.likeIcon}
                                  fontSize="small"
                                  sx={{ cursor: "pointer" }}
                                />
                              ) : (
                                <FiHeart
                                  onClick={() => handleWishlist(val._id)}
                                  className={styles.likeIcon}
                                  fontSize="small"
                                  sx={{ cursor: "pointer" }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={styles.ratings__reactions}>
                        <div>
                            <ul className={styles.stratinglst}>
                              <li><RiStarFill className={styles.sticon} style={{color:"#FFD51A",opacity:"1"}}/></li>
                              <li><RiStarFill className={styles.sticon}  style={{color:"#FFD51A",opacity:"1"}}/></li>
                              <li><RiStarFill className={styles.sticon}  style={{color:"#FFD51A",opacity:"1"}}/></li>
                              <li><RiStarFill className={styles.sticon} /></li>
                              <li><RiStarFill  className={styles.sticon}/></li>
                            </ul>
                          </div>
                          <div className={styles.productrating}>
                            <div className={styles.like}>
                              {productdetails &&
                              productdetails.likesview &&
                              productdetails.likesview.likes &&
                              productdetails.likesview.likes
                                .map((val) => val._id)
                                .includes(user_id) ? (
                                <FiThumbsUp
                                  onClick={() =>
                                    handleLikeUnlike(val._id, "unlike")
                                  }
                                  className={styles.likeIcon}
                                  fontSize="small"
                                  sx={{ cursor: "pointer" }}
                                />
                              ) : (
                                <FiThumbsUp
                                  onClick={() =>
                                    handleLikeUnlike(val._id, "like")
                                  }
                                  className={styles.likeIcon}
                                  fontSize="small"
                                  sx={{ cursor: "pointer" }}
                                />
                              )}
                              <span>
                                {productdetails.likesview === null
                                  ? 0
                                  : productdetails.likesview.likes &&
                                    productdetails.likesview.likes.length}
                              </span>
                            </div>
                            <div className={styles.like}>
                              <FiEye
                                className={styles.viewIcon}
                              />
                              <span>
                                {productdetails.likesview === null
                                  ? 0
                                  : productdetails.likesview.views &&
                                    productdetails.likesview.views.length}
                              </span>
                            </div>
                          </div>
                          {/* ))} */}
                        </div>
                        <div
                          className={styles.proddets_dets}
                          style={{ paddingTop: "20px" }}
                        >
                          <div>
                            <p className={styles.catgr}
                            >
                              Original Artwork
                            </p>
                          </div>
                          <div className={styles.greydescr}>
                            <p className={styles.reslte}
                            >
                              {val.category_id?.name}
                            </p>
                          </div>
                        </div>
                        <div className={styles.proddets_dets}>
                          <div>
                            <p  className={styles.catgr}
                            >
                              Dimension
                            </p>
                          </div>
                          <div className={styles.greydescr}>
                            <p className={styles.reslte}
                            >
                              {val?.width} * {val?.height} * {val?.depth} CM
                            </p>
                          </div>
                        </div>
                        <div className={styles.proddets_frame}>
                          <div>
                            <p  className={styles.catgr}
                            >
                              Framing
                            </p>
                            <Typography
                              fontSize="0.85rem"
                              fontFamily="DM Sans"
                              color={
                                availablity && availablity ? "green" : "red"
                              }
                            >
                              {availablity === null ? (
                                ""
                              ) : availablity ? (
                                <div>  Framing is available in Your Location
                                  {/* <Button
                                      sx={{
                                        whiteSpace:"nowrap",
                                        textTransform: "none",
                                        color: "white",
                                        backgroundColor: "black",
                                        "&.MuiButtonBase-root:hover": {
                                          bgcolor: "black",
                                        },
                                        borderRadius: "8px",
                                        width: "100px",
                                        padding: "2%",
                                      }}
                                      onClick={() => {
                                        setFrameModal(true);
                                        setFrameData({
                                          thickness_id: "",
                                          finishtouch_id: "",
                                          framing_id: "",
                                        });
                                      }}
                                    >
                                      <Typography fontSize="0.65rem">
                                        Choose frame
                                      </Typography>
                                    </Button> */}
                                </div>
                              ) : (
                                "Framing is not available in Your Location"
                              )}
                            </Typography>
                          </div>
                          <div className={styles.greydescr}>
                            <Autocomplete
                              id="combo-box-demo"
                              disableClearable
                              options={country.map((vl) => vl.label)}
                              name="country"
                              size="small"
                              // value={frameCountry.countries}
                              // onClick={checkFrameCountry}
                              onChange={checkFrameCountry}
                              sx={{
                                mt: 1,
                                mb:1,
                                padding: "0px",
                                border: "none",
                              }}
                              className={styles.search_select}
                              renderInput={(params) => (
                               
                                <div
                                  ref={params.InputProps.ref}
                                  className={styles.search_select}
                                >
                                  <input 
                                    type="text"
                                    {...params.inputProps}
                                    style={{
                                      fontSize: "14px",
                                      color: "#212529",
                                      fontWeight:"500",
                                      height: "27px",
                                      width: "160px",
                                      padding:".375rem .75rem",
                                      border:"1px solid #ced4da",
                                      borderRadius:".25rem",
                                    }}
                                  />
                                  {/* <TextField
                                size="small"
                                  // {...params}
                                  {...params.inputProps}
                                  className={styles.search_select}
                                  sx={{
                                    fontSize:"20px",
                                    padding: "0px",
                                    border: "none",
                                    color: "#636365",
                                    height: "25px",
                                    width: "100px"
                                  }}
                                  placeholder="Location"
                                /> */}
                                </div>
                              )}
                            />
                            {availablity === null ? (
                              ""
                            ) : availablity ? (
                              <button
                              className="action_btn"
                                onClick={() => {
                                  setFrameModal(true);
                                  setFrameData({
                                    thickness_id: "",
                                    finishtouch_id: "",
                                    framing_id: "",
                                  });
                                }}
                              >
                                 
                                  Choose frame
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className={styles.proddets_dets}>
                          <div>
                            <p className={styles.catgr}
                            >
                              Delivery Charges
                            </p>
                          </div>
                          <div className={styles.greydescr}>
                            <p className={styles.reslte}
                            >
                              {formatNewCurrency(
                                val.delivery_charges ? val.delivery_charges : 0
                              )}
                            </p>
                          </div>
                        </div>
                        <div className={styles.prints__customization}>
                          <button
                            onClick={handleClickPrints}
                            disabled={val.print ? false : true}
                            className="apply_btn"
                          >Prints
                          </button>
                          {/* <p href="#" className={styles.custLink}>
                            <Typography
                              fontWeight="600"
                              fontSize="0.9rem"
                              onClick={() =>
                                navigate(`/user/askaquestion/${id}`)
                              }
                              sx={{ cursor: "pointer" }}
                            >
                              Customization
                            </Typography>
                          </p> */}
                        </div>
                        <div className={styles.offers}>
                          <div className={styles.offerSection}>
                           <div className={styles.offserhdng}>
                           <img
                              className={`img-fluid ${styles.offerIcon}`}
                              src={"/img/offer-1.svg"}
                              alt="img"
                            />
                            <p>Save Extra</p>
                           </div>
                           <div className={styles.ofrresn}>
                           <p>with 3 offers</p>
                           </div>
                          </div>
                          {/* For Affiliate Link */}
                          {url[1] === "arts" ? (
                            <div className={styles.affilate_btn}>
                              <Button
                                onClick={() =>
                                  handleAffiliateLink(val._id, val.sku_id)
                                }
                                disabled={val.print ? false : true}
                                sx={{
                                  mt: 1,
                                  textTransform: "none",
                                  color: "white",
                                  backgroundColor: "black",
                                  "&.MuiButtonBase-root:hover": {
                                    bgcolor: "black",
                                  },
                                  borderRadius: "8px",
                                  height: "2rem",
                                }}
                              >
                                <Typography fontSize="0.75rem">
                                  Get Affiliate Link
                                </Typography>
                              </Button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <button className={`apply_btn ${styles.widthfull} `}
                          onClick={handleOpenInquiry}
                        >
                          Ask a question
                        </button>
                        {val?.sale?.qty === 0 ? (
                           <Tooltip  
                                   title={<span style={{ color: "white", fontSize: '11px' }}>Click here to initiate a request to recreate the artwork.</span>} 
                                   sx={{ "& .MuiTooltip-tooltip": { backgroundColor: "blue" } }}
                                   arrow>
                          <button
                          className={`apply_btn ${styles.widthfull} `}
                            onClick={() => setOpenRecreate(true)}
                          >
                            Recreate
                          </button>
                          </Tooltip>
                        ) : null}

                        <Modal
                          open={openLink}
                          onClose={handleCloseLink}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <div className={styles.makeofferModal}>
                          <div className={styles.clobtn}>
                              <Link onClick={handleCloseLink}><RiCloseCircleFill className={styles.iconru} /></Link>
                            </div>
                            <p className={styles.mpophndg}
                            >Generate Link</p>
                             <div className={styles.moblform}>
                             <div className="form-group">
                                <label>Text Link</label>
                                <input type="text"
                                className="form-control"
                                id="outlined-basic"
                                variant="outlined"
                                inputRef={textFieldRef}
                                value={affiliateLink ? affiliateLink : ""}
                                name="link"
                              />
                              </div>
                              <div className="text-center">
                              <button
                              className="action_btn"
                              onClick={handleCopy}
                            >
                              Copy Link
                            </button>
                              </div>
                              <div className="form-group1">
                              <Snackbar
                              open={copied}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              onClose={() => setCopied(false)}
                              sx={{ width: "20px" }}
                              autoHideDuration={2000}
                              message="Link Copied"
                            />
                              </div>
                             </div>
                          </div>
                        </Modal>
                        <Modal
                          open={openquestion}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <div className={styles.makeofferModal}>
                          <div className={styles.clobtn}>
                              <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
                            </div>
                            <p className={styles.mpophndg}
                            >Make an Offer</p>
                             <div className={styles.moblform}>
                             <div className="form-group">
                                <label>Email</label>
                                <input
                                className="form-control"
                                id="outlined-basic"
                                variant="outlined"
                               
                                type="mail"
                                value={makeOffer.email}
                                name="email"
                                onChange={handleChangeOffer}
                              />
                              {makeOfferErrors.email && (
                                <small style={{ color: "red" }}>
                                  {makeOfferErrors.email}
                                </small>
                              )}
                              </div>
                              <div className="form-group">
                                <label>First Name</label>
                                <input type="text"
                                className="form-control"
                                  id="outlined-basic"
                                  variant="outlined"
                                  
                                  value={makeOffer.first_name}
                                  name="first_name"
                                  onChange={handleChangeOffer}
                                />
                                {makeOfferErrors.first_name && (
                                  <small style={{ color: "red" }}>
                                    {makeOfferErrors.first_name}
                                  </small>
                                )}
                              </div>
                              <div className="form-group">
                                <label>Last Name</label>
                                <input type="text"
                                className="form-control"
                                  id="outlined-basic"
                                  variant="outlined"
                                   
                                  value={makeOffer.last_name}
                                  name="last_name"
                                  onChange={handleChangeOffer}
                                />
                                {makeOfferErrors.last_name && (
                                  <small style={{ color: "red" }}>
                                    {makeOfferErrors.last_name}
                                  </small>
                                )}
                              </div>
                              <div className="form-group">
                                <label>Phone number</label>
                                <input type="text"
                                className="form-control"
                                  id="outlined-basic"
                                  variant="outlined"
                                  
                                  value={makeOffer.phone}
                                  name="phone"
                                  onChange={handleChangeOffer}
                                />
                                {makeOfferErrors.phone && (
                                  <small style={{ color: "red" }}>
                                    {makeOfferErrors.phone}
                                  </small>
                                )}
                              </div>
                              <div className="form-group">
                                <label>Offer Price</label>
                                <input type="text"
                                className="form-control"
                                  id="outlined-basic"
                                  variant="outlined"
                                  
                                  value={makeOffer.price}
                                  name="price"
                                  onChange={handleChangeOffer}
                                />
                                {makeOfferErrors.offer_price && (
                                  <small style={{ color: "red" }}>
                                    {makeOfferErrors.offer_price}
                                  </small>
                                )}
                              </div>
                              <div className="form-group">
                                <label>Message</label>
                                <textarea 
                                className="form-control"
                              id="outlined-basic"
                              variant="outlined"
                              value={makeOffer.message}
                              name="message"
                              onChange={handleChangeOffer}
                            />
                            {makeOfferErrors.message && (
                              <small
                                style={{ color: "red", marginLeft: "5%" }}
                              >
                                {makeOfferErrors.message}
                              </small>
                            )}
                              </div>
                              <div className="form-group1 text-center">
                              <button className="action_btn"
                              
                              onClick={handleSubmitOffer}
                            >
                              Make Offer
                            </button>
                              </div>
                              
                             </div>
                            
                          </div>
                        </Modal>
                        <Modal
                          open={openQuote}
                          onClose={handleCloseQuotes}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <div className={styles.makeofferModal}>
                          <div className={styles.clobtn}>
                              <Link onClick={handleCloseQuotes}><RiCloseCircleFill className={styles.iconru} /></Link>
                            </div>
                            <p className={styles.mpophndg}
                            >Request for Quote</p>
                             <div className={styles.moblform}>
                              <div className="form-group">
                                <label>Email</label>
                                <input
                                className="form-control"
                                id="outlined-basic"
                                variant="outlined"
                                type="email"
                                name="email"
                                value={quoteRequest.email}
                                onChange={handleChangeQuote}
                              />
                              {quoteError.email && (
                                <small style={{ color: "red" }}>
                                  {quoteError.email}
                                </small>
                              )}
                              </div>
                              <div className="form-group">
                                <label> First Name</label>
                                <input type="text"
                                className="form-control"
                                  id="outlined-basic"
                                  variant="outlined"
                                  name="first_name"
                                  value={quoteRequest.first_name}
                                  onChange={handleChangeQuote}
                                />
                                {quoteError.first_name && (
                                  <small style={{ color: "red" }}>
                                    {quoteError.first_name}
                                  </small>
                                )}
                              </div>
                              <div className="form-group">
                                <label>Last Name</label>
                                <input type="text" className="form-control"
                                  id="outlined-basic"
                                  variant="outlined"
                                  name="last_name"
                                  value={quoteRequest.last_name}
                                  onChange={handleChangeQuote}
                                />
                                {quoteError.last_name && (
                                  <small style={{ color: "red" }}>
                                    {quoteError.last_name}
                                  </small>
                                )}
                              </div>
                              <div className="form-group">
                                <label>Phone number</label>
                                <input type="text"
                                className="form-control"
                                id="outlined-basic"
                                variant="outlined"
                                name="phone"
                                value={quoteRequest.phone}
                                onChange={handleChangeQuote}
                              />
                              {quoteError.phone && (
                                <small style={{ color: "red" }}>
                                  {quoteError.phone}
                                </small>
                              )}
                              </div>
                              <div className="form-group">
                                <label>Message</label>
                                <textarea
                                className="form-control"
                              id="outlined-basic"
                              variant="outlined"
                              
                              name="message"
                              value={quoteRequest.message}
                              onChange={handleChangeQuote}
                            />
                            {quoteError.message && (
                              <small
                                style={{ color: "red", marginLeft: "5%" }}
                              >
                                {quoteError.message}
                              </small>
                            )}
                              </div>
                              <div className="form-group1 text-center">
                              <button
                               className="action_btn"
                              onClick={handelSubmitRequest}
                            >
                              Send Request
                            </button>
                              </div>
                              
                            </div>
                             
                             
                          </div>
                        </Modal>
                        <Modal
                          open={openInquiry}
                          onClose={handleCloseInquiry}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <div className={styles.makeofferModal}>
                            <div className={styles.clobtn}>
                              <Link onClick={handleCloseInquiry}><RiCloseCircleFill className={styles.iconru} /></Link>
                            </div>
                            <p className={styles.mpophndg}
                            >
                              Do you have any question?
                            </p>
                            <div className={styles.moblform}>
                              <div className="form-group">
                                <label>Email</label>
                                <input type="text"
                                id="outlined-basic"
                               className="form-control"
                                name="email"
                                value={inquiryRequest.email}
                                onChange={handleChangeInquiry}
                              />
                              {inquiryError.email && (
                                <small style={{ color: "red" }}>
                                  {inquiryError.email}
                                </small>
                              )}
                              </div>
                              <div className="form-group">
                                <label>Description</label>
                                <textarea className="form-control"
                              id="outlined-basic"
                              variant="outlined"
                              multiline 
                              
                              name="description"
                              value={inquiryRequest.description}
                              onChange={handleChangeInquiry}
                            />
                            {inquiryError.description && (
                              <small
                                style={{ color: "red"}}
                              >
                                {inquiryError.description}
                              </small>
                            )}
                              </div>
                              <div className="form-group1 text-center">
                              <button
                              className="action_btn"
                              onClick={handleSubmitInquiry}
                            >
                              Submit
                            </button>
                              </div>
                            </div>
                          </div>
                        </Modal>
                        <Modal
                          open={openRecreate}
                          onClose={() => setOpenRecreate(false)}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <div className={styles.makeofferModal}>
                          <div className={styles.clobtn}>
                              <Link onClick={() => setOpenRecreate(false)}><RiCloseCircleFill className={styles.iconru} /></Link>
                            </div>
                            <p className={styles.mpophndg}
                            >
                              Recreate
                            </p>
                            <div className={styles.moblform}>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="mail"
                                className="form-control"
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="Enter Email"
                                
                                name="email"
                                value={recreateRequest.email}
                                onChange={handleChangeRecreate}
                              />
                              {recreateError.email && (
                                <small style={{ color: "red" }}>
                                  {recreateError.email}
                                </small>
                              )}
                              </div>
                              <div className="form-group">
                                <label>Message</label>
                                <textarea
                                className="form-control"
                              id="outlined-basic"
                              variant="outlined"
                              
                              name="message"
                              value={recreateRequest.message}
                              onChange={handleChangeRecreate}
                            />
                            {recreateError.message && (
                              <small
                                style={{ color: "red", marginLeft: "5%" }}
                              >
                                {recreateError.message}
                              </small>
                            )}
                              </div>
                              <div className="form-group1 text-center">
                              <button
                               className="action_btn"
                              onClick={() => handleSubmitRecreate(val?._id)}
                            >
                              Send
                            </button>
                              </div>
                            </div>
                          </div>
                        </Modal>
                      </div>
                    </Grid>
                  </Grid>
                </Container>
                <div className={styles.descriptiongrid}>
                  <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
                  <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={8}>
                  <div className={styles.description}>
                        <h3>Description</h3>
                        <p> {val.description}
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={styles.sidePanel}>
                        <div className={styles.choose__frame}>
                        <ul className={styles.specicationlis}>
                          <li><span>Choose Frame</span> <span>
                          <button
                            className="action_btn"
                            onClick={() => {
                              setFrameModal(true);
                              setFrameData({
                                thickness_id: "",
                                finishtouch_id: "",
                                framing_id: "",
                              });
                            }}
                          > 
                              Choose a frame 
                          </button>
                            </span></li>
                        </ul>
                          {/* <div className={styles.cfflex}>
                            <div className={styles.verticalLine}></div>
                            <Typography
                              fontSize="0.9rem"
                              fontWeight="600"
                              ml={1}
                            >
                              Choose Frame
                            </Typography>
                          </div>
                          <Button
                            sx={{
                              textTransform: "none",
                              color: "white",
                              backgroundColor: "black",
                              "&.MuiButtonBase-root:hover": {
                                bgcolor: "black",
                              },
                              borderRadius: "8px",
                              width: "35%",
                              padding: "2%",
                            }}
                            onClick={() => {
                              setFrameModal(true);
                              setFrameData({
                                thickness_id: "",
                                finishtouch_id: "",
                                framing_id: "",
                              });
                            }}
                          >
                            <Typography fontSize="0.65rem">
                              Choose a frame
                            </Typography>
                          </Button> */}
                        </div>
                        <div className={styles.general__information}>
                          <div className={styles.att__color}>
                            <div className={styles.attributeflex}>
                            <ul className={styles.specicationlis}>
                                <li><span>Color:</span> <span> {val.color_id && val.color_id.name}</span></li>
                                <li><span>Weight:</span> <span>{val.weight} Kg</span></li>
                                <li><span>Orientation:</span> <span className={styles.oraimg}>
                                {val.orientation_id?.name.toLowerCase() ===
                              "landscape" ? (
                                <img
                                  className={styles.orientation1}
                                  src={"/img/Rectangle 320.png"} alt=""
                                />
                              ) : val.orientation_id?.name.toLowerCase() ===
                                "squre" ? (
                                <img
                                  className={styles.orientation2}
                                  src={"/img/Orientation square.png"} alt=""
                                />
                              ) : (
                                <img
                                  className={styles.orientation3}
                                  src={"/img/orientation rect.png"} alt=""
                                />
                              )}</span></li>
                                <li><span>SKU Number:</span> <span>{val.sku_id}</span></li>
                                <li><span> Wall Mounted:</span> <span> {val.wall_mounted ? "yes" : "No"}</span></li>
                                <li><span>Category:</span> <span>{val.category_id?.name}</span></li>
                                <li><span>Tags:</span> <span>{val.tags}</span></li>
                              </ul>
                              {/* <div className={styles.verticalLine}></div>
                              <Typography
                                fontSize="0.85rem"
                                fontWeight="600"
                                ml={1}
                              >
                                Color:
                              </Typography> */}
                            </div>
                            {/* <Typography>
                              {val.color_id && val.color_id.name}
                            </Typography> */}
                          </div>
                          
                              {/* <Typography>{val?.orientation_id?.name}</Typography> */}
                          {/* <div className={styles.att__color}>
                            <div className={styles.attributeflex}>
                              <div className={styles.verticalLine}></div>
                              <Typography
                                fontSize="0.85rem"
                                fontWeight="600"
                                ml={1}
                              >
                                Weight:
                              </Typography>
                            </div>

                            <Typography>{val.weight} Kg</Typography>
                          </div>
                          <div className={styles.att__color}>
                            <div className={styles.attributeflex}>
                              <div className={styles.verticalLine}></div>
                              <Typography
                                fontSize="0.85rem"
                                fontWeight="600"
                                ml={1}
                              >
                                Orientation:
                              </Typography>
                            </div>
                            <div className={styles.orientationmain}>
                              {val.orientation_id?.name.toLowerCase() ===
                              "landscape" ? (
                                <img
                                  className={styles.orientation1}
                                  src={"/img/Rectangle 320.png"} alt=""
                                />
                              ) : val.orientation_id?.name.toLowerCase() ===
                                "squre" ? (
                                <img
                                  className={styles.orientation2}
                                  src={"/img/Orientation square.png"} alt=""
                                />
                              ) : (
                                <img
                                  className={styles.orientation3}
                                  src={"/img/orientation rect.png"} alt=""
                                />
                              )}
                            </div>
                          </div>
                          <div className={styles.att__color}>
                            <div className={styles.attributeflex}>
                              <div className={styles.verticalLine}></div>
                              <Typography
                                fontSize="0.85rem"
                                fontWeight="600"
                                ml={1}
                              >
                                SKU Number:
                              </Typography>
                            </div>

                            <Typography>{val.sku_id}</Typography>
                          </div>
                          <div className={styles.att__color}>
                            <div className={styles.attributeflex}>
                              <div className={styles.verticalLine}></div>
                              <Typography
                                fontSize="0.85rem"
                                fontWeight="600"
                                ml={1}
                              >
                                Wall Mounted:
                              </Typography>
                            </div>

                            <Typography>
                              
                            </Typography>
                          </div>
                          <div className={styles.att__color}>
                            <div className={styles.attributeflex}>
                              <div className={styles.verticalLine}></div>
                              <Typography
                                fontSize="0.85rem"
                                fontWeight="600"
                                ml={1}
                              >
                                Category:
                              </Typography>
                            </div>

                            <Typography>{val.category_id?.name}</Typography>
                          </div>
                          <div className={styles.att__color}>
                            <div className={styles.attributeflex}>
                              <div className={styles.verticalLine}></div>
                              <Typography
                                fontSize="0.85rem"
                                fontWeight="600"
                                ml={1}
                              >
                                Tags:
                              </Typography>
                            </div>
                            <Typography>{val.tags}</Typography>
                          </div> */}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  </Container>
                  
                </div>
              </>
            ))
          )}
          <div className={styles.features}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={styles.safe__shipping}>
                  <Grid container spacing={2} sx={{alignItems:"start"}}>
                  <Grid item xs={12} sm={3} md={3}>
                  <div className={styles.fericon}>
                    <img
                      className="img-fluid"
                      src={"/img/safe-shipping.svg"}
                      alt="img"
                    />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9}>
                  <div className={styles.feturcont}>
                      <h4>
                        Safe Shipping Globally
                      </h4>
                      <p>
                        We ensure that your artwork is shipped securely
                        worldwide. Our reliable shipping partners guarantee safe
                        handling and timely delivery.
                      </p>
                      {/* <Typography fontSize="0.9rem" ml={1.75}>
                        Our reliable shipping partners guarantee safe handling
                        and timely delivery.
                      </Typography> */}
                    </div>
                  </Grid>
                  </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={styles.safe__shipping}>
                  <Grid container spacing={2} sx={{alignItems:"start"}}>
                  <Grid item xs={12} sm={3} md={3}>
                  <div className={styles.fericon}>
                  <img
                      className="img-fluid"
                      src={"/img/free-return.svg"}
                      alt="img"
                    />
                  </div>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9}>
                  <div className={styles.feturcont}>
                      <h4>
                        Free Return
                      </h4>
                      <p>
                        If your purchase doesn't meet your expectations,
                        returning it is simple and free. We offer a hassle-free
                        return policy with no hidden fees, allowing you to
                        return the artwork within 14 days.
                      </p>
                      {/* <Typography fontSize="0.9rem" ml={1.75}>
                        We offer a hassle-free return policy with no hidden
                        fees, allowing you to return the artwork within 14 days.
                      </Typography> */}
                    </div>
                  </Grid>
                  </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <div className={styles.safe__shipping}>
                  <Grid container spacing={2} sx={{alignItems:"start"}}>
                  <Grid item xs={12} sm={3} md={3}>
                  <div className={styles.fericon}>
                  <img
                      className="img-fluid"
                      src={"/img/secure.svg"}
                      alt="img"
                    />
                  </div>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9}>
                  <div className={styles.feturcont}>
                      <h4>
                        Secure
                      </h4>
                      <p>
                        All transactions on Art Smiley are encrypted and
                        processed securely. We collaborate with trusted
                        financial partners to provide a safe and professional
                        experience, ensuring your personal information remains
                        protected.
                      </p>
                      {/* <Typography fontSize="0.9rem" ml={1.75}>
                        We collaborate with trusted financial partners to
                        provide a safe and professional experience, ensuring
                        your personal information remains protected.
                      </Typography> */}
                    </div>
                  </Grid>
                  </Grid>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        {/* middle section */}
        <div className={styles.latesprosec}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          {productdetails &&
            productdetails.artwork.map((val) => (
              <div className={styles.text_center}>
              <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
              <h3 className={styles.sec_title}>More art works from{" "}
                      {val.artist_id && val.artist_id.first_name}{" "}
                      {val.artist_id && val.artist_id.last_name}</h3>
              </div>
            ))}
            <div className={styles.ProductCards}>
            <Grid container spacing={2}>
              {allitemdt.data &&
                allitemdt.data
                  .filter((val) => {
                    return (
                      artist_id &&
                      artist_id.includes(val.artist_id && val.artist_id._id) &&
                      id !== val?._id
                    );
                  })
                  .map((item, i) =>
                    i >= 4 ? (
                      ""
                    ) : (
                      <Grid item xs={12} sm={6} md={3}>
                        <div
                          className={styles.card__main}
                          onClick={() => handleProducts(item._id)}
                        >
                          <div className={styles.card__top}>
                            <img
                              className={styles.cardImage}
                              src={item.image}
                              alt="img"
                            />
                          </div>
                          <div className={styles.pic_info}>
                      <div className={styles.card__middle}>
                        <span className={styles.titles}>{item.name}</span>
                      </div>
                      <div className={styles.info_like}>
                        <div className={styles.wishlst}>
                          <FiHeart />
                        </div>
                        <span className={styles.like_count}>
                        {item.likesview?.likes.length || 0}
                        </span>
                      </div>
                    </div>
                    <p className={styles.names}>
                    {item.artist_id && item.artist_id.first_name}{" "}
                    {item.artist_id && item.artist_id.last_name}
                        </p>

                        <div className={styles.price_sec}>
                      <div className={styles.card__bottom}>
                        <span className={styles.buys}>Buy</span>
                        <span className={styles.prices}>
                          ${item?.sale?.price}
                        </span>
                      </div>
                      {item?.rent?.rent_price > 0 ? (
                        <>
                          {" "}
                          <div className={styles.hrs} />
                          <div>
                            <span className={styles.buys}>Rent/Month</span>
                            <span className={styles.prices}>
                              ${item?.rent?.rent_price}
                            </span>
                          </div>
                        </>
                      ) : null}
                      {item?.print?.[0]?.print_price > 0 ? (
                        <>
                          {" "}
                          <div className={styles.hrs} />
                          <div>
                            <span className={styles.buys}>Print</span>
                            <span className={styles.prices}>
                              ${item?.print[0]?.print_price}
                            </span>
                          </div>
                        </>
                      ) : null}
                    </div>
                          {/* <div className={styles.pic_info}>
                            <div className={styles.card__middle}>
                              <div className={styles.card__middleLeft}>
                               
                                <Typography
                                  fontSize="0.8rem"
                                  fontWeight="700"
                                  mt={1}
                                  color="#ccc"
                                >
                                  
                                </Typography>
                              </div>
                            </div>
                            <div className={styles.card__middleRight}>
                              <FavoriteBorderIcon fontSize="small" />
                              <Typography fontWeight="400" ml={0.5}>
                               
                              </Typography>
                            </div>
                          </div>
                          <hr />
                          <div className={styles.card__bottom}>
                            <div className={styles.bottom__text}>
                              <Typography
                                fontWeight="600"
                                color="#ccc"
                                fontSize="0.65rem"
                              >
                                Buy
                              </Typography>
                              <Typography
                                color="black"
                                fontWeight="500"
                                fontSize="0.85rem"
                              >
                                <b>${item.sale.price}</b>
                              </Typography>
                            </div>
                            {item?.rent?.rent_price > 0 ? (
                              <>
                                <div className={styles.verticalLine}></div>
                                <div className={styles.bottom__text}>
                                  <Typography
                                    fontWeight="600"
                                    color="#ccc"
                                    fontSize="0.65rem"
                                  >
                                    Rent/Month
                                  </Typography>
                                  <Typography
                                    color="black"
                                    fontWeight="500"
                                    fontSize="0.85rem"
                                  >
                                    <b>
                                      $
                                      {item.rent
                                        ? item.rent.rent_price
                                        : "not available"}
                                    </b>
                                  </Typography>
                                </div>
                              </>
                            ) : null}
                            {item?.print?.[0]?.print_price > 0 ? (
                              <>
                                {" "}
                                <div className={styles.verticalLine}></div>
                                <div className={styles.bottom__text}>
                                  <Typography
                                    fontWeight="600"
                                    color="#ccc"
                                    fontSize="0.65rem"
                                  >
                                    Print
                                  </Typography>
                                  <Typography
                                    color="black"
                                    fontWeight="500"
                                    fontSize="0.85rem"
                                  >
                                   

                                    <span className={styles.prices}>
                                      ${item.print[0].print_price}
                                    </span>
                                  </Typography>
                                </div>
                              </>
                            ) : null}
                          </div> */}
                        </div>
                      </Grid>
                    )
                  )}
            </Grid>
            </div>
          </Container>
        </div>
        <div className={styles.proddets__middle}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          <PolicySidebar />
          </Container>
        </div>
        {/* bottom section */}
        <ProdDetsOther artist_id={artist_id} allitemdt={allitemdt} />
        {/* qna section */}
        <ProdDetsqna />
      </div>
    </ThemeProvider>
  );
}

export default React.memo(AskAQuestion);
