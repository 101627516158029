import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

const  CuratorGallerySlice = createSlice({
  name: "curatorGalleryData",
  initialState,
  reducers: {
    setCuratorGalleryData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setCuratorGalleryData } =  CuratorGallerySlice.actions;

export const CuratorGalleryData = (state) => state.CuratorGallerydata;

export default CuratorGallerySlice.reducer;
