import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
// import Divider from '@mui/material/Divider';
import React from "react";
import styles from "./SevencolumnTable.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import profileSlice, { addData } from "../../../../../State/Features/Artist/profileSlice";
import homeImage from "../../../../../constant";
import CircularLoader from "../../../../../common/loader/Loader";

export default function SevencolumnTable(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.artistdata);
  const loader = useSelector((state) => state.loader);

  const threedots = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePopup = (e) => {
    props.setOpen(true);
    props.setPoptype("addfeature");
  };
  const handleNavigate = (id) => {
    const path = window.location.pathname;
    if (path === "/dispute") {
      navigate(`/dispute/${id}`);
    }
    props.setPage("profile");
  };
  return (
    <div className={styles.mains}>
      <TableContainer component={Paper} sx={{ backgroundColor: "#F6F6F6", color: "black" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  align="left"
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "15px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : ( */}
            <TableBody>
              {props.data &&
                props.data.map((row, i) => (
                  <TableRow value={row.artist_name} style={{ color: "#636365", cursor: "pointer" }} key={i}>
                    <TableCell                      
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left">
                      {row.sku_id}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left">
                      {row.title}
                    </TableCell>                   
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      className={styles.table_column}
                      align="left">
                      {row.start_date}
                    </TableCell>
                    <TableCell
                      sx={{
                        // width:props.activetab==='enquiry'?'30%':'',
                        // width:'200',
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left">
                      {row.end_date}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left">
                      {row.bid}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      className={styles.table_column}
                      align="left">
                      {row.result ? "Win" : "Lost"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      className={styles.table_column}
                      align="left">
                      <a>{row.result? row.action: ""}</a>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          
        </Table>
      </TableContainer>
    </div>
  );
}
