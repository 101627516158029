import React, {useState } from "react"
import styles from "./PopupCuratorList.module.css"
import Backdrop from "@mui/material/Backdrop"
import { useParams } from "react-router-dom"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import {
  addmarketingdata
} from "../../../../State/api/superadminApi"
import { Link, MenuItem, Select } from "@mui/material"
import { useDispatch } from "react-redux"
import "react-quill/dist/quill.snow.css"
import ReactQuill from "react-quill"
import { RiCloseCircleFill} from "react-icons/ri";
 
export default function PopupModalSubscription(props) {
  const dispatch = useDispatch()
  const { id } = useParams()
  console.log(id, "id----")
  const handleClose = () => props.setOpen(false)
  const [subinput, setSubinput] = useState({
    feature: "",
    description: "",
  })
  const handleChangeSub = (e) => {
    const { name, value } = e.target
    setSubinput({ ...subinput, [name]: value })
  }
  const curatorSubmit = () => {
    dispatch(
      addmarketingdata(
        id,
        subinput.feature,
        subinput.description,
        props.setOpen
      )
    )
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box   className={styles.modal_del}>
          <div className={styles.clobtn}>
                <Link onClick={() => props.setOpen(false)}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
                Artist Marketing Information
              </p>
             
            <div className="form-group">
              <label>Select</label>

              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={subinput.feature}
                label="Feature"
                fullWidth
                sx={{
                  "& .MuiSelect-outlined": { border:"1px solid #ced4da",
                    padding:".375rem .75rem",
                    fontSize:"16px",
                    borderRadius:".25rem",
                   },
                }}
                placeholder="select type"
                onChange={handleChangeSub}
                name="feature"
              >
                <MenuItem value="feature artists in home page">
                  feature artists in home page
                </MenuItem>
                <MenuItem value="exclusive promotion of artworks on social media">
                  exclusive promotion of artworks on social media
                </MenuItem>
                <MenuItem value="Video promotion on YT and social networking">
                  Video promotion on YT and social networking
                </MenuItem>
                <MenuItem value="promotion artist on Art Smiley blogs">
                  promotion artist on Art Smiley blogs
                </MenuItem>
                <MenuItem value="solo online exhibitions">
                  solo online exhibitions
                </MenuItem>
                <MenuItem value="discount on first refusal opportunity on exhibitions">
                  discount on first refusal opportunity on exhibitions
                </MenuItem>
              </Select>

              
            </div>
            <div className="form-group">
                <label>Description</label>
                 
                <ReactQuill
                  theme="snow"
                  placeholder={`Enter description`}
                  className={`${styles.textarea_blog}  ${styles.customEditor}`}
                  value={subinput.description}
                  onChange={(content) =>
                    handleChangeSub({
                      target: { name: "description", value: content },
                    })
                  }
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                />
              </div>
            <div className={styles.action}>
              <button
                variant="contained"
                className="action_btn"
                onClick={curatorSubmit}
              >
                Add
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
