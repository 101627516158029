import React from "react";
import { Modal, Backdrop, Fade, Box, Button, Link } from "@mui/material";
import styles from "./DeleteArt.module.css";
import { useNavigate } from "react-router-dom";
import { setEditArtId } from "../../../../State/Features/common/editArtIdSlice";
import { useDispatch } from "react-redux";
import { RiCloseCircleFill } from "react-icons/ri";

export default function PopupForDeleteArt(props) {
  const navigate = useNavigate();
  const handleClose = () => props.setOpen(false);
const dispatch = useDispatch()
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={styles.modal}
    >
      <Fade in={props.open}>
        <Box className={styles.modalContent}>
        <div className={styles.clobtn}>
                <Link onClick={() => props.setOpen(false)}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  Manage Your Artwork
              </p>
        
          <div className={styles.description}>
            <div className={styles.buttonGroup}>
              <button
                onClick={() =>
               {
                navigate("/edit/stepfirst", { state: { id: props.artId } })
                  
                  dispatch(setEditArtId({id: props.artId}))
               }

 
                }
               className="action_btn"
              >
                Edit Artwork
              </button>
              <button
                className="action_libtn"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
            <p className={styles.warningText}>
              Wait! Are you sure you want to delete this artwork?
            </p>
            <p>
              We have noticed that deleting art on Art Smiley Art creates a lot
              of frustration:
            </p>
            <ul>
              <li>
                Collectors are not able to find what they are looking for when
                they click on links found online.
              </li>
              <li>
                Artists miss the opportunity of sales because people don't find
                their art on Art Smiley Art.
              </li>
            </ul>
            <p>By editing your artwork instead you can:</p>
            <ul>
              <li>Upload a different image</li>
              <li>Change the name</li>
              <li>Change the price</li>
              <li>Start/Stop making this original work available for sale</li>
              <li>Start/Stop offering prints of this</li>
            </ul>
          </div>
          <div className={styles.buttonGroup}>
            <button
              variant="contained"
              className="action_btn"
              onClick={() => {
                props.handleDelete(props.artId);
                props.setOpen(false);
              }}
            >
              Delete My Artwork Anyway
            </button>
            <button
              className="action_libtn"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
