import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    InputAdornment,
    Step,
    StepLabel,
    Stepper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import styles from "./MyCart.module.css";
  import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
  import { AccountCircle } from "@mui/icons-material";
  import homeImage from "../../../constant";
  import { useLocation, useNavigate, useParams } from "react-router-dom";
  import { useDispatch, useSelector } from "react-redux";
  import serverimage from "../../../serverConstant";
  import {
 
    fetchSubscriptionById,
  } from "../../../State/api/userApi";

  import {
    AlertsSuccess,
    Errornotify,
    Successnotify,
  } from "../../../common/alert/Alert";
  import CircularLoader from "../../../common/loader/Loader";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import { setAllSubscriptionData } from "../../../State/Features/user/subscriptionSlice";
import api from "../../../State/api/Api";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PaypalSubscription from "../../../payment/PaypalSubscription";
import StripeForSubscription from "../../../payment/stripe/StripeForSubscription";
import CustomTabby from "../../../payment/stripe/CustomTabby";

  
  function MyCartSubscription() {
    const {id} = useParams();
    const {state} = useLocation();
    const plan_name = state?.planName;
    console.log(plan_name,'plan_name')
    const amount = state?.amount;
 const navigate = useNavigate()

 const [subscriptionId, setSubscriptionId] = useState("")

 const [makePayment, setMakePayment] = useState(true)
 const [subscriptionData, setSubscriptionData] = useState({})
    // const subscriptionData = useSelector((state) => state.subscriptionData?.data);

   
  

    const loader = useSelector((state) => state.loader);

    const dispatch = useDispatch();
 
 
    // useEffect(() => {
    //   setSubscriptionId(id)
    //   dispatch(fetchSubscriptionById(subscriptionId));
    
    // }, [dispatch,subscriptionId]);
  


    
   async function fetchSubscriptionByIdThunk() {
      dispatch(setLoader(true));
      try {
        const apiResponse = await api.applicationApi(
          `api/subscription/getSubscription/${id}`,
          "GET",
        );
      setSubscriptionData(apiResponse.data.subscription)
        if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.status === 304) {
     
          dispatch(setLoader(false));
        } else {
        
          return false;
        }
      } catch (err) {
      }
    };
      
    useEffect(() => {
fetchSubscriptionByIdThunk()
    },[])
    
    
    return (
      <div className={styles.mycart} style={{paddingBottom:"10px"}}>
        <AlertsSuccess />
        <div className={styles.headerCategory}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
      <div className={styles.pageitle}>
              <h1>Subscription Cart</h1>
               
            </div>
      </Container>
        </div>
        <div className={styles.formsec}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
    <Stepper>
          <Step>
            <StepLabel>Subscription Cart</StepLabel>
          </Step>
        
          <Step>
            <StepLabel>Completed</StepLabel>
          </Step>
        </Stepper>
        <div className={styles.cart__grid}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={8}>
              <div className={styles.wishlist__content}>
              <p  className={styles.carhndg}>
                Your Selection
              </p>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                                                sx={{
                                                  borderBottom: "1px solid #111111",
                                                }}
                                               
                                              >
                          Subscription Name
                        </TableCell>
                        <TableCell
                                                sx={{
                                                  borderBottom: "1px solid #111111",
                                                }}
                                                align="center"
                                              >
                          Duration
                        </TableCell>
  
                        <TableCell
                                                sx={{
                                                  borderBottom: "1px solid #111111",
                                                }}
                                                align="center"
                                              >
                          Price
                        </TableCell>
                        
                    
                      </TableRow>
                    </TableHead>
                    {loader.loader ? (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={8}>
                            <Box className={styles.loader_box}>
                              {" "}
                              <CircularLoader size={60} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        {subscriptionData ? (
                        
                          
                              <TableRow
                          
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  sx={{ borderBottom: "none", paddingBottom: 0 }}
                                  component="th"
                                  scope="row"
                                  
                                >
                              
                                      <div className={styles.product__info}>
                                      <Typography fontSize="1rem" sx={{paddingBottom:"20px"}}>
                                          {subscriptionData?.plan_name}
                                        </Typography>
                                       
                                       
                                      </div>
                                   
                                </TableCell>
                                <TableCell
                                  sx={{ borderBottom: "none", paddingBottom: 0 }}
                                 align="center"
                                >
                              
                              {plan_name}
                                </TableCell>
  
                                <TableCell
                                  sx={{ borderBottom: "none", paddingBottom: 0 }}
                                  align="center"
                                >
                               ${plan_name === "yearly" ? Math.round((subscriptionData.plan_price * 12) * (1 - 0.20)).toFixed(0) : subscriptionData.plan_price}
                                </TableCell>
                            
                              </TableRow>
                            )
                          
                         : (
                          <TableRow>
                            <TableCell colSpan={8}>
                              <Box className={styles.loader_box}>
                                {" "}
                                No Data Found
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
             
               
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
            <div className={styles.total__div}>
            <p className={styles.carhndg}>Cart Total</p>
            <div className={styles.totalsub__div}>
                  <span className={styles.itemnm}>
                  Total
                  </span>
                  <span className={styles.iprisubt}>
                    {/* {subtotalarray.reduce((a,b) => a+b,0)} */}
                    ${plan_name === "yearly" ? Math.round((subscriptionData.plan_price * 12) * (1 - 0.20)).toFixed(0) : subscriptionData.plan_price}
                  </span>
                </div>
                {/* <div className={styles.totalvalue__div}>
                  <div>
                    <Typography fontSize="0.9rem">Total</Typography>
                    <Typography fontSize="0.75rem" color="#636365">
                      Free Basic Packaging
                    </Typography>
                  </div>
                  <Typography fontSize="1.05rem" fontWeight="600">
                    {formatNewCurrency(subtotal + vat)}
                  </Typography>
                </div> */}
                {makePayment ?  <button
                onClick={() => setMakePayment(false)}
                className={`apply_btn ${styles.fillbtn}`}
                >
                  Pay     ${plan_name === "yearly" ? Math.round((subscriptionData.plan_price * 12) * (1 - 0.20)).toFixed(0) : subscriptionData.plan_price}
                </button> : null}

                {!makePayment && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      padding: "10px 5px",
                    }}
                  >
                   

                    
                    <div style={{ width: "48%",marginRight:'5px' }}>
                    <PayPalScriptProvider
                        options={{
                          "client-id":
                            "AYWjsJ-Q2mNg_Pvjnslq_eUlu6xCfDP5-GXb-77XFykzgZlJS4TyaSw6Wl4cQj9k4B40twLP8SEcN_1B",
                          components: "buttons",
                          currency: "USD",
                        }}
                      >
                         <PaypalSubscription   subscriptionName={subscriptionData?.plan_name}
 
  subscriptionId={subscriptionData?._id}
  subscriptionTenure={plan_name}
  paymentType="Paypal"
  amount={subscriptionData?.plan_price} />
                      </PayPalScriptProvider>
                   
                    </div>
                    <div style={{ width: "48%" }}>
                      <StripeForSubscription />
                    </div>
                    <div style={{ width: "48%" }}>
                      <CustomTabby />
                    </div>
                  </div>
                )}
                {/* <Button
                  onClick={() => navigate("/user/")}
                  sx={{
                    backgroundColor: "white",
                    color: "black",
                    width: "98%",
                    padding: "2%",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "black",
                    },
                    marginTop: "2%",
                    border: "1px solid black",
                  }}
                >
                  Continue Shopping
                </Button> */}
              </div>
            </Grid>
          </Grid>
        </div>
  </Container>
  </div>
         
        {/* <div className={styles.proddets__middle}>
          <div className={styles.design__headingImage}>
            <img
              className={styles.design__image}
              src={ "/img/Design.png"}
              alt="img"
            />
          </div>
          <div className={styles.design__heading}>
            <Typography mb={2} fontSize="2rem" fontFamily="marcellus">
              Newest Paintings
            </Typography>
          </div>
          <div className={styles.ProductCards}>
            <Container>
              <Grid container spacing={2}>
                {allitemdt.data &&
                  allitemdt.data
                    .filter((val) => val.is_accepted === "Approved")
                    .map((item, i) =>
                      i >= 4 ? (
                        ""
                      ) : (
                        <Grid item xs={12} sm={6} md={3}>
                          <div className={styles.card__main}>
                            <div className={styles.card__top}>
                              <img
                                className={styles.cardImage}
                                src={ item.image}
                                alt="imgs"
                              />
                            </div>
                            <div className={styles.card__middle}>
                              <div className={styles.card__middleLeft}>
                                <Typography
                                  fontSize="0.95rem"
                                  fontWeight="700"
                                  mt={1}
                                >
                                  {item.name}
                                </Typography>
                                <Typography
                                  fontSize="0.8rem"
                                  fontWeight="700"
                                  mt={1}
                                  color="#ccc"
                                >
                                  {item.artist_id && item.artist_id.first_name}{" "}
                                  {item.artist_id && item.artist_id.last_name}
                                </Typography>
                              </div>
                              <div className={styles.card__middleRight}>
                                <FavoriteBorderIcon fontSize="small" />
                                <Typography fontWeight="400" ml={0.5}>
                                  522
                                </Typography>
                              </div>
                            </div>
                            <hr />
                            <div className={styles.card__bottom}>
                              <div className={styles.buy__text}>
                                <Button
                                  sx={{
                                    marginTop: "15px",
                                    maxHeight: "5vh",
                                    color: "white",
                                    backgroundColor: "white",
                                    "&:hover": { backgroundColor: "white" },
                                  }}
                                >
                                  <Typography
                                    fontWeight="600"
                                    color="#ccc"
                                    fontSize="0.65rem"
                                  >
                                    Buy
                                  </Typography>
                                  <Typography
                                    color="black"
                                    fontWeight="500"
                                    fontSize="0.85rem"
                                    mt={4}
                                    ml={-3}
                                  >
                                    <b>$ {item.sale.price}</b>
                                  </Typography>
                                </Button>
                              </div>
                              <div className={styles.verticalLine}></div>
                              <div className={styles.rent__text}>
                                <Button
                                  sx={{
                                    marginTop: "15px",
                                    maxHeight: "5vh",
                                    color: "white",
                                    backgroundColor: "white",
                                    "&:hover": { backgroundColor: "white" },
                                  }}
                                >
                                  <Typography
                                    fontWeight="600"
                                    color="#ccc"
                                    fontSize="0.65rem"
                                  >
                                    Rent/Month
                                  </Typography>
                                  <Typography
                                    color="black"
                                    fontWeight="500"
                                    fontSize="0.85rem"
                                    mt={4}
                                    ml={-6.5}
                                    mr={3}
                                  >
                                    <b>$ {item?.price}</b>
                                  </Typography>
                                </Button>
                              </div>
                              <div className={styles.verticalLine}></div>
                              <div className={styles.print__text}>
                                <Button
                                  sx={{
                                    marginTop: "15px",
                                    maxHeight: "5vh",
                                    color: "white",
                                    backgroundColor: "white",
                                    "&:hover": { backgroundColor: "white" },
                                  }}
                                >
                                  <Typography
                                    fontWeight="600"
                                    color="#ccc"
                                    fontSize="0.65rem"
                                  >
                                    Print
                                  </Typography>
                                  <Typography
                                    color="black"
                                    fontWeight="500"
                                    fontSize="0.85rem"
                                    mt={4}
                                    ml={-3.75}
                                  >
                                    <b>$ {item.print.price}</b>
                                  </Typography>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      )
                    )}
              </Grid>
            </Container>
          </div>
        </div> */}
      </div>
    );
  }
  
  export default MyCartSubscription;
  