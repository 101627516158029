import React, { useEffect, useState } from "react";
import Tableheader from "../Table/Tableheader/Tableheader";
import styles from "./Career.module.css";
import Tablesfivecol from "../Table/Fivecolumn/Tablefivecol";
import Pagination from "../Pagination/Pagination";
import Addpopup from "../Popupdelete/addPopup/Addpopup";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCareer,
  editCareer,
  fetchCareerData,
  handleAddCareers,
} from "../../../../State/api/superadminApi";
import { AlertsSuccess } from "../../../../common/alert/Alert";
import { setCareerdata } from "../../../../State/Features/superadmin/careerSlice";
import CareerSuperadminTable from "./CareerSuperadminTable";

const upcominghead = [
  {
    id: "artist_name ",
    label: "Title",
  },
  {
    id: "created date ",
    label: "Created Date",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "like",
    label: "Department",
  },
  {
    id: "like",
    label: "Experience(yr)",
  },
  {
    id: "followe",
    label: "Description",
  },
  {
    id:'action',
    label:'Action'
  }
];
const sortdata = [
  { label: "Oldest to Newest", value: "low" },
  { label: "Newest to Oldest", value: "high" },
];
const filterdata = [
  { label: "A to Z", value: "name-asc" },
  { label: "Z to A", value: "name-desc" },
];

export default function Career() {
  const [button, setButton] = useState({ type: "add", text: "Add" });
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({ offset: 1 });
  const [editinput, setEditinput] = useState({
    title: "",
    location: "",
    experience: "",
    department: "",
    description: "",
  });
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  });
  const dispatch = useDispatch();
  const careerdata = useSelector((state) => state.careerdata.data);
  const fetchData = () => {
    dispatch(fetchCareerData(data));
  };

  useEffect(() => {
    fetchData();
  }, [data]);

  const handleAddcareer = (data) => {
    dispatch(handleAddCareers(data, setOpen, fetchData));
  };
  const handleEditpopup = (data) => {
    setEdit(data);
  };
  const handleEditcareer = (id, edit) => {
    dispatch(editCareer(id, edit, setEdit, fetchData));
  };
  const handleDeletecareeer = (id) => {
    dispatch(deleteCareer(id, fetchData));
  };
 

  const handleFilter = (event) => {
    const value = event.target.value;
    setCondition({ ...condition, filter: value });
    if (value === "name-asc") {
      dispatch(
        setCareerdata({
          data:
            careerdata.data &&
            [...careerdata.data].sort((a, b) =>
              a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1
            ),
          totalCount: careerdata.totalCount,
        })
      );
    } else if (value === "name-desc") {
      dispatch(
        setCareerdata({
          data:
            careerdata.data &&
            [...careerdata.data].sort((a, b) =>
              a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1
            ),
          totalCount: careerdata.totalCount,
        })
      );
    } else {
      fetchData();
      setCondition({ ...condition, sorting: value });
    }
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    setCondition({ ...condition, search: value });
  };

  const filteredCareerData = Array.isArray(careerdata?.data)
  ? careerdata.data.filter((val) => {
      const search = condition.search.toLowerCase();
      const nameMatch = val.title?.toLowerCase().includes(search);
      const locationMatch = val.location?.toLowerCase().includes(search);
      const departmentMatch = val.department?.toLowerCase().includes(search);
      const experienceMatch = val.experience?.toLowerCase().includes(search);

      return nameMatch || locationMatch || departmentMatch || experienceMatch;
    })
  : [];

  const handleSorting = (e) => {
    let value = e.target.value;
    if (value === "low") {
      setCondition({ ...condition, sorting: value });
      dispatch(
        setCareerdata({
          data:
            careerdata.data &&
            [...careerdata.data].sort((a, b) => {
              return new Date(a.createdAt) - new Date(b.createdAt);
            }),
          totalCount: careerdata.totalCount,
        })
      );
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value });
      dispatch(
        setCareerdata({
          data:
            careerdata.data &&
            [...careerdata.data].sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            }),
          totalCount: careerdata.totalCount,
        })
      );
    } else {
      fetchData();
      setCondition({ ...condition, sorting: value });
    }
  };


  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Addpopup
          handleAddcareer={handleAddcareer}
          poptype="career"
          open={open}
          setOpen={setOpen}
        />
      ) : (
        ""
      )}
      {edit ? (
        <Addpopup
          poptype="career_edit"
          handleEditcareer={handleEditcareer}
          edit={edit}
          open={edit}
          setOpen={setEdit}
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          setOpen={setOpen}
          button={button}
        />
        <CareerSuperadminTable
          activetab="career"
          careerdata={{ data: filteredCareerData }}
          head={upcominghead}        
          handleDeletecareeer={handleDeletecareeer}
          handleEditpopup={handleEditpopup}
        />        
        <Pagination
          totaldata={careerdata && careerdata.totalCount}
          pagination={data}
          setPagination={setData}
        />
      </div>
    </div>
  );
}
