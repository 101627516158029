import "./App.css";
import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Curatormain from "./Curatormain";
import Gallerymain from "./Gallerymain";
import Superadminmain from "./Superadminmain";
import Artist from "./Artist";
import Affiliate from "./Affiliate";
import { useDispatch, useSelector } from "react-redux";
import UserMain from "./UserMain";
import { removeUser, setUser } from "./State/Features/common/userSlice";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CookieConsent from "react-cookie-consent";
import { Typography } from "@mui/material";

function App() {
  const dispatch = useDispatch();
  const usertype = useSelector((state) => state.user.data);

  useEffect(() => {
    if (localStorage.getItem("login_token")) {
      const userty = localStorage.getItem("usertype");
      const is_accepted = localStorage.getItem("is_accepted");
      dispatch(
        setUser({
          signed: true,
          type: userty,
          // data: "",
          is_accepted: is_accepted,
        })
      );
    } else {
      removeUser({
        signed: false,
        type: "",
        data: "",
        is_accepted: "",
      });
    }
  }, []);
  return (
    <>
      {usertype.signed ? (<>
        <Layout usertype={usertype.type} className="main_layout">
          {usertype.type === "curator" ? (
            <Suspense fallback={<div className="loading_div">loading...</div>}>
              <Curatormain />
            </Suspense>
          ) : usertype.type === "gallery" ? (
            <Suspense fallback={<div className="loading_div">loading...</div>}>
              <Gallerymain />
            </Suspense>
          ) : usertype.type === "superadmin" ? (
            <Suspense fallback={<div className="loading_div">loading...</div>}>
              <Superadminmain />
            </Suspense>
          ) : usertype.type === "artist" ? (
            <Suspense fallback={<div className="loading_div">loading...</div>}>
              <Artist />
            </Suspense>
          ) : usertype.type === "affiliate" ? (
            <Suspense fallback={<div className="loading_div">loading...</div>}>
              <Affiliate />
            </Suspense>
          ) : (
            <Suspense fallback={<div className="loading_div">loading...</div>}>
              <UserMain />
            </Suspense>
          )}
        </Layout>
      </>) : (<>
        <Layout usertype={usertype.type} className="main_layout">
          <Suspense fallback={<div className="loading_div">loading...</div>}>
            <UserMain />
          </Suspense>
        </Layout>
       
      </>)}
      <CookieConsent
        enableDeclineButton
        flipButtons={false}
        onDecline={() => {
          alert("nay!");
        }}
        location="bottom"
        buttonText="Accept"
        cookieName="myArtsmileyCookie"
        style={{
          display: "flex",
          columnGap: "30px",
          rowGap: "10px",
          background: "#54555c",
          color: "#fff",
          paddingLeft: "10px",
          marginBottom: "0px",
        }}
        buttonStyle={{
          color: "#fff",
          background: "black",
          height: "48px",
          width: "140px",
          borderRadius: "10px",
          fontSize: "18px",
          fontFamily: "Roboto",
        }}
        declineButtonStyle={{
          color: "black",
          background: "white",
          height: "48px",
          width: "140px",
          borderRadius: "10px",
          fontSize: "18px",
          fontFamily: "Roboto",
        }}
        // contentStyle ={{height:'50px', }}
        expires={150}
        className="cookies_consent"
      >
        <Typography>
          Your privacy By clicking “Accept all cookies”, you agree Stack
          Exchange can store cookies on your device and disclose information in
          accordance with our{" "}
          <Link to="/privacypolicy">Cookie Policy</Link>.
        </Typography>
      </CookieConsent>
    </>
  );
}

export default App;
