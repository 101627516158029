import * as React from "react"
import styles from "./Category.module.css"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { Button, TextField, Grid } from "@mui/material"
import { useState } from "react"
import Tabss from "../Common/Tabs/Tabs"
import homeImage from "../../../../constant"
import {
  deleteCategory,
  fetchCategories,
  handleAddStyle,
} from "../../../../State/api/superadminApi"
import { useDispatch, useSelector } from "react-redux"
import Addpaintingexshibition from "../../../../artist/pages/exshibition/popup/Addpaintingexshibition"
import Editcategory from "../Popupdelete/editCategory/Editcategory"
import { AlertsSuccess } from "../../../../common/alert/Alert"
import Categorylist from "./categoryList/Categorylist"
import { FiPlus } from "react-icons/fi";
import { validateAdminCategory } from "../../../../validation/validationAdminCategory"
// import TextField from '@mui/material/TextField';

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  }
}

export default function Category() {
  const dispatch = useDispatch()

  const [open, setOpen] = React.useState(false)
  const [category_id, setCategory_id] = useState("")
  const [categoryErrors,setCategoryErrors] = useState({})
  const [category, setCategory] = useState({ add: false, edit: false })
  const [name, setName] = useState({ name: "", _id: "", image: "" })
  const [subcatData, setSubcateData] = useState({
    style: "",
    medium: "",
    material: "",
    subject: "",
  })

  const handleChangeSubData = (e) => {
    const { name, value } = e.target
    setSubcateData((prev) => ({ ...prev, [name]: value || "" })); 
    // setSubcateData({ ...subcatData, [name]: value })
  }
  // const handleSubmit = (type) => {
  //   const { catErrors,isValid} = validateAdminCategory(subcatData)

  //   if(!isValid){
  //     setCategoryErrors(catErrors)
  //   }else{
  //     setCategoryErrors({})
    
  //   switch (type) {
  //     case "style":
  //       setSubcateData({ ...subcatData, style: "" })
  //       dispatch(
  //         handleAddStyle("api/itemStyle/addItemStyle", {
  //           category_id: category_id,
  //           name: subcatData.style,
  //         })
  //       )
  //       break
  //     case "medium":
  //       setSubcateData({ ...subcatData, medium: "" })
  //       dispatch(
  //         handleAddStyle("api/itemMedium/addItemMedium", {
  //           category_id: category_id,
  //           name: subcatData.medium,
  //         })
  //       )
  //       break
  //     case "material":
  //       setSubcateData({ ...subcatData, material: "" })
  //       dispatch(
  //         handleAddStyle("api/itemMaterial/addItemMaterial", {
  //           category_id: category_id,
  //           name: subcatData.material,
  //         })
  //       )
  //       break
  //     case "subject":
  //       setSubcateData({ ...subcatData, subject: "" })
  //       dispatch(
  //         handleAddStyle("api/itemSubject/addItemSubject", {
  //           category_id: category_id,
  //           name: subcatData.subject,
  //         })
  //       )
  //       break
  //     default:
  //   }
  // }
  // }

  const handleSubmit = (type) => {
    // Get all validation errors
    const { catErrors, isValid } = validateAdminCategory(subcatData);
  
    // Filter errors to only the relevant field based on the button clicked
    let fieldError = {};
    if (type in catErrors) {
      fieldError[type] = catErrors[type];
    }
  
    // If there's an error for the clicked field, set the error state
    if (Object.keys(fieldError).length > 0) {
      setCategoryErrors((prevErrors) => ({ ...prevErrors, ...fieldError }));
      return; // Stop execution if there's an error
    }
  
    // Clear error for the clicked field if valid
    setCategoryErrors((prevErrors) => ({ ...prevErrors, [type]: "" }));
  
    // Proceed with API call based on the type
    switch (type) {
      case "style":
        dispatch(
          handleAddStyle("api/itemStyle/addItemStyle", {
            category_id: category_id,
            name: subcatData.style,
          })
        );
        setSubcateData((prev) => ({ ...prev, style: "" }));
        break;
  
      case "medium":
        dispatch(
          handleAddStyle("api/itemMedium/addItemMedium", {
            category_id: category_id,
            name: subcatData.medium,
          })
        );
        setSubcateData((prev) => ({ ...prev, medium: "" }));
        break;
  
      case "material":
        dispatch(
          handleAddStyle("api/itemMaterial/addItemMaterial", {
            category_id: category_id,
            name: subcatData.material,
          })
        );
        setSubcateData((prev) => ({ ...prev, material: "" }));
        break;
  
      case "subject":
        dispatch(
          handleAddStyle("api/itemSubject/addItemSubject", {
            category_id: category_id,
            name: subcatData.subject,
          })
        );
        setSubcateData((prev) => ({ ...prev, subject: "" }));
        break;
  
      default:
        break;
    }
  };
  

  const categoriesData = useSelector((state) => state.categoryData.data)
  const fetchData = () => {
    dispatch(fetchCategories(setCategory_id))
  }
  React.useEffect(() => {
    fetchData()
  }, [])
  const handleAddCategoryPopup = (type, value) => {
    if (type === "add") {
      setCategory({ ...category, add: true })
    } else {
      setCategory({ ...category, edit: true })
      setName({
        ...name,
        name: value.name,
        _id: value._id,
        image: value.image,
      })
    }
  }
  const handleNewChange = (e) => {
    setName({ ...name, name: e.target.value })
  }
  const handleDelete = (id) => {
    dispatch(deleteCategory(id, fetchData))
  }
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Addpaintingexshibition open={open} setOpen={setOpen} type="category" />
      ) : (
        ""
      )}
      {category.add ? (
        <Editcategory
          handleNewChange={handleNewChange}
          name={name}
          type="add"
          open={category.add}
          fetchData={fetchData}
          setOpen={setCategory}
        />
      ) : (
        ""
      )}

      {category.edit ? (
        <Editcategory
          type="edit"
          handleNewChange={handleNewChange}
          name={name}
          open={category.edit}
          fetchData={fetchData}
          setOpen={setCategory}
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <div className={styles.category_btn}>
          <button
            onClick={() => handleAddCategoryPopup("add")}
            variant="contained"
            className="action_btn"
          >
            Add Category
          </button>
        </div>
        {/* <Box
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
                >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        <Tab label="Item One" {...a11yProps(0)} />
                        <Tab label="Item Two" {...a11yProps(1)} />
                        <Tab label="Item Three" {...a11yProps(2)} />
                        <Tab label="Item Four" {...a11yProps(3)} />
                        <Tab label="Item Five" {...a11yProps(4)} />
                        <Tab label="Item Six" {...a11yProps(5)} />
                        <Tab label="Item Seven" {...a11yProps(6)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        Item One
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        Item Two
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        Item Three
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        Item Four
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        Item Five
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        Item Six
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        Item Seven
                    </TabPanel>
                </Box> */}
        <div className={styles.card_main}>
        <Grid  container spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
        <div>
            <Tabss
              category_id={category_id}
              setCategory_id={setCategory_id}
              label={categoriesData && categoriesData}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
        <div className={styles.card}>
            <div className={styles.heading_main}>
              <div>Category</div>
              <div className={styles.hrline}></div>
            </div>
            <div className={styles.detail_inputs}>
              <input
                value={
                  categoriesData &&
                  categoriesData.filter((val) => val._id === category_id)
                    .length !== 0
                    ? categoriesData &&
                      categoriesData.filter((val) => val._id === category_id)[0]
                        .name
                    : ""
                }
                type="text"
                id="outlined-basic"
                placeholder="Category Name"
                className="form-control"
                fullWidth
                variant="outlined"
              />
            </div>
            {/* <div className={styles.upload_img}>
                            <div className={styles.img_heading}>Images</div>
                            <div className={styles.preview_img}>
                                {src === '' ?
                                    <div className={styles.img_box}>
                                        <div htmlFor='input' style={{ textAlign: 'center' }}
                                            onClick={handleChangeImage}>
                                            <input
                                                onChange={handleChangeImage}
                                                type="file"
                                                id='input'
                                                hidden
                                            /><label htmlFor='input' className={styles.label}>
                                                <img src={ '/img/uploadimg.png'} width='46px' height='43px' alt='img' />
                                                <p>  Upload your image
                                                </p></label>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <input
                                            onChange={handleChangeImage}
                                            type="file"
                                            id='input'
                                            hidden
                                        />
                                        <label htmlFor='input'>
                                            <img src={src} alt='img' id='img' className={image ? styles.img_view : styles.img_view_hide} />
                                        </label>
                                    </>
                                }
                            </div>
                           
                            <div className={styles.file_div}></div>
                            <p>Upload an image of your artwork.Your file must be least 1200*1500 px , a JPG or PNG file ,width a color format of rgb,and less than 50MB</p>
                        </div> */}

            <div className={styles.heading_main}>
              <div className={styles.subheading}>Sub Category</div>
              {/* <Button variant="contained">Show List</Button> */}
            </div>
            {/* {data.map((val,i)=>( */}

            <div className={styles.detail_inputs}>
              <div className="form-group">
                <div className={styles.labtn}>
                  <label>Style</label>
                  <div className={styles.adbtn}>
                <Button onClick={() => handleSubmit("style")}><FiPlus /> Add Style </Button>
                </div>
                </div>
                <input
                  onChange={handleChangeSubData}
                  name="style"
                  value={subcatData.style}
                  type="text"
                  id="outlined-basic"
                  placeholder="Enter Style"
                  className="form-control"
                  fullWidth
                  variant="outlined"
                />
                {categoryErrors?.style && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {categoryErrors?.style}
                    </span>
                )}
              </div>
            </div>
            {/* ))} */}
            <div className={styles.detail_inputs}>
              <div className="form-group">
                <div className={styles.labtn}>
                  <label>Medium</label>
                  <div className={styles.adbtn}>
                <Button onClick={() => handleSubmit("medium")}><FiPlus /> Add Medium </Button>
                </div>
                </div>
                <input
                  onChange={handleChangeSubData}
                  name="medium"
                  value={subcatData.medium}
                  
                  id="outlined-basic"
                  placeholder="Enter Medium"
                  className="form-control"
                  fullWidth
                  variant="outlined"
                />
                   {categoryErrors?.medium && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {categoryErrors?.medium}
                    </span>
                )}
              </div>
            </div>
            <div className={styles.detail_inputs}>
              <div className="form-group">
              <div className={styles.labtn}>
                  <label>Material</label>
                  <div className={styles.adbtn}>
                <Button onClick={() => handleSubmit("material")}><FiPlus /> Add Material </Button>
                </div>
                </div>
                <input
                  onChange={handleChangeSubData}
                  name="material"
                  value={subcatData.material}
                  
                  id="outlined-basic"
                  placeholder="Enter menu"
                  className="form-control"
                  fullWidth
                  variant="outlined"
                />
                   {categoryErrors?.material && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {categoryErrors?.material}
                    </span>
                )}
              </div> 
            </div>
            <div className={styles.detail_inputs}>
              <div className="form-group">
                <div className={styles.labtn}>
                  <label>Subject</label>
                  <div className={styles.adbtn}>
                <Button onClick={() => handleSubmit("subject")}><FiPlus /> Add Subject </Button>
                </div>
                </div> 
                <input
                  onChange={handleChangeSubData}
                  name="subject"
                  value={subcatData.subject}
                  
                  id="outlined-basic"
                  placeholder="Enter Subject"
                  className="form-control"
                  fullWidth
                  variant="outlined"
                />
                    {categoryErrors?.subject && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {categoryErrors?.subject}
                    </span>
                )}
              </div> 
            </div>
            {/* <div className={styles.save_btn}>
                            <Button variant='contained' sx={{ width: '120px', height: '54px', borderRadius: '10px' }}>Save</Button>
                        </div> */}
          </div>
        </Grid>
        </Grid>
          
          
        </div>
        <Categorylist
          open={open}
          handleDelete={handleDelete}
          setOpen={setCategory}
          handleAddCategoryPopup={handleAddCategoryPopup}
          categoriesData={categoriesData && categoriesData}
        />
      </div>
    </div>
  )
}
