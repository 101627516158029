import React, { useState } from "react";
import Commonsidebar from "../../../List/Common/sideBar/Commonsidebar";

const sidebarRoutes = [
  {
    name: "my profile",
    icon: "fa fa-dashboard",
    path: "/dashboard",
    hasSubmenu: false,
  },
  {
    name: "My Address",
    icon: "fa fa-truck",
    path: "/useraddress",
    hasSubmenu: false,
  },
  {
    name: "My Inquiry",
    icon: "fa fa-cube",
    path: "/userinquiry",
    hasSubmenu: false,
  },
  {
    name: "My orders",
    icon: "fa fa-users",
    path: "/userorder",
    hasSubmenu: false,
  },
  {
    name: "followed artist",
    icon: "fa fa-cube",
    path: "/followedartist",
    hasSubmenu: false,
  },
  // {
  //   name: "REQUEST FOR QOUTE ",
  //   icon: "fa fa-th-large",
  //   path: "/userquotes",
  //   hasSubmenu: false,
  // },
  {
    name: "Art Smiley News",
    icon: "fa fa-cube",
    path: "/news",
    hasSubmenu: false,
  },
  {
    name: "request made",
    icon: "fa fa-cube",
    path: "/requestmade",
    hasSubmenu: false,
  },
  {
    name: "giftcard",
    icon: "fa fa-cube",
    path: "/giftcard",
    hasSubmenu: false,
  },
  {
    name: "campaigns",
    icon: "fa fa-cube",
    path: "/campaigns",
    hasSubmenu: false,
  },
  {
    name: "auctions",
    icon: "fa fa-cube",
    path: "/auction",
    hasSubmenu: false,
  },
  {
    name: "exhibitions",
    icon: "fa fa-cube",
    path: "/exhibition",
    hasSubmenu: false,
  },
  {
    name: "dispute",
    icon: "fa fa-cube",
    path: "/userdispute",
    hasSubmenu: false,
  },
];
export default function UserSidebar() {
  const [open, setOpen] = useState(true);

  return (
    <>
      {/* <Webpopup open={open} setOpen={setOpen} /> */}
      {/* <Artistgallery open={open} setOpen={setOpen} /> */}
      <Commonsidebar sidebarRoutes={sidebarRoutes} />
    </>
  );
}
