import {
  createTheme,
  ThemeProvider,
  Modal,
  Container,
  Link,
  Grid
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { RiCloseCircleFill } from "react-icons/ri";
import CircularLoader from "../../../common/loader/Loader"
import api from "../../../State/api/Api"
import { setLoader } from "../../../State/Features/common/loaderSlice"
import styles from "./CareerUserDetails.module.css"
import homeImage from "../../../constant"
import { handleJoinTeam, handleSubmitForm } from "../../../State/api/userApi"
import { useNavigate } from "react-router-dom"
import { Errornotify, Successnotify } from "../../../common/alert/Alert"
import { validateAdminJoinTeam } from "../../../validation/validationAdminJoinTeam"
import striptags from "striptags"
const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
  },
})
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
}

function CareerUserDetails() {
  const navigate = useNavigate()
  const [careerDetail, setCareerDetail] = useState(null)
  console.log(careerDetail, "careerDetail--")
  const dispatch = useDispatch()
  const [careerErrors, setCareerErrors] = useState({})
  const { id } = useParams()

  const [jointeam, setJointeam] = useState({
    first_name: "",
    last_name: "",
    email: "",
    message: "",
    attachement: null,
  })

  const loader = useSelector((state) => state.loader)
  const [openquote, setOpenQuote] = useState(false)
  const handleOpenQuote = () => setOpenQuote(true)
  const handleCloseQuote = () => setOpenQuote(false)

  const handleChangeTeam = (e) => {
    const name = e.target.name
    const value = e.target.value
    setJointeam({ ...jointeam, [name]: value })
  }

  const handleChangeFile = (e) => {
    const value = e.target.files[0]
    setJointeam({ ...jointeam, attachement: value })
  }
  // const handleSubmitTeam = () => {
  //   dispatch(handleJoinTeam(id, jointeam, setOpenQuote, setJointeam))
  //   navigate("/user/career")
  // }
  const fetchCareerDetail = async function () {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(`api/user/getcareers/${id}`)
      if (result && result.status === 200) {
        setCareerDetail(result.data)
        dispatch(setLoader(false))
      } else if (result.status === 401) {
      }
    } catch (err) {}
  }
  useEffect(() => {
    fetchCareerDetail()
  }, [])
  const handleSubmitTeam = () => {
    const { careerErrors, isValid } = validateAdminJoinTeam(jointeam)

    if (!isValid) {
      setCareerErrors(careerErrors)
    } else {
      setCareerErrors({})
      dispatch(handleJoinTeam(id, jointeam, setOpenQuote, setJointeam))
      navigate("/user/career")
    }
  }
  const handleNavigate = (route) => {
    navigate(route)
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        {loader.loader ? (
          <div className={styles.loader}>
            <CircularLoader />
          </div>
        ) : (
          <div className={styles.careeruserdetails}>
            {careerDetail &&
              careerDetail.career.map((val) => (
                <>
                  <div className={styles.headerCategory}>
                  <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
                  <div className={styles.pageitle}>
              <h1>{val.title}</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li>Career</li>
              </ul>
            </div>
                  </Container>
                   
                  </div>
                  <div className={styles.careerdetailCard}>
                  <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
                  <Grid container spacing={2} sx={{
    justifyContent: "center",
    alignItems: "center",
  }}>
    <Grid item md={9} xs={12}>
    <Grid container spacing={2} sx={{
    alignItems: "baseline",
  }}>
    <Grid item md={4} xs={12}><h4>Job Title :</h4></Grid>
    <Grid item md={8} xs={12}><p>{val.title}</p></Grid>
    <Grid item md={4} xs={12}><h4>Min Experience required :</h4></Grid>
    <Grid item md={8} xs={12}><p>{val.min_exp} Years</p></Grid>
    <Grid item md={4} xs={12}><h4>Max Experience required :</h4></Grid>
    <Grid item md={8} xs={12}><p>{val.max_exp} Years</p></Grid>
    <Grid item md={4} xs={12}><h4>Salary :</h4></Grid>
    <Grid item md={8} xs={12}><p>{val?.salary}</p></Grid>
    <Grid item md={4} xs={12}><h4>Department :</h4></Grid>
    <Grid item md={8} xs={12}><p>{val.department}</p></Grid>
    <Grid item md={4} xs={12}><h4>Description:</h4></Grid>
    <Grid item md={8} xs={12}><p>{striptags(val.description)}</p></Grid>
    <Grid item md={4} xs={12}></Grid>
    <Grid item md={8} xs={12}>
    <button className="apply_btn" onClick={handleOpenQuote}>
                      Apply Now 
                    </button>
    </Grid> 
  </Grid>
    </Grid>
                    
                  </Grid>
                  </Container>
                    <Modal
                      open={openquote}
                      // onClose={handleCloseQuote}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      
                      <div className={styles.jointeamModal}>
                        <div className={styles.textright}>
                          <Link  className={styles.modalclosebutton}
                          onClick={handleCloseQuote}><RiCloseCircleFill/></Link>
                        </div>
                        <h4 className={styles.mdltitle}>Join our Team</h4>
                        <div className="form-group">
                          <label>
                            First Name *
                          </label>
                          <div>
                          <input
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter Name"
                            size="small"
                            className="form-control"
                            name="first_name"
                            value={jointeam.first_name}
                            onChange={handleChangeTeam}
                          />
                          {careerErrors?.first_name && (
                            <span
                              style={{
                                color: "red",
                                fontWeight: "bold",
                                fontSize: "12px",
                              }}
                            >
                              {careerErrors?.first_name}
                            </span>
                          )}
                          </div>
                        </div>
                        <div className="form-group">
                          <label fontSize="0.9rem" fontWeight={600}>
                            Last Name *
                          </label>
                          <div>
                          <input
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter Name"
                            size="small"
                            className="form-control"
                            name="last_name"
                            value={jointeam.last_name}
                            onChange={handleChangeTeam}
                          />
                          {careerErrors?.last_name && (
                            <span
                              style={{
                                color: "red",
                                fontWeight: "bold",
                                fontSize: "12px",
                              }}
                            >
                              {careerErrors?.last_name}
                            </span>
                          )}
                          </div>
                        </div>
                        <div className="form-group">
                          <label fontSize="0.9rem" fontWeight={600}>
                            Email *
                          </label>
                          <div>
                          <input
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Enter Email"
                            size="small"
                            className="form-control"
                            name="email"
                            value={jointeam.email}
                            onChange={handleChangeTeam}
                          />
                          {careerErrors?.email && (
                            <span
                              style={{
                                color: "red",
                                fontWeight: "bold",
                                fontSize: "12px",
                              }}
                            >
                              {careerErrors?.email}
                            </span>
                          )}
                          </div>
                        </div>
                        <div className="form-group">
                          <label fontSize="0.9rem" fontWeight={600}>
                            Message *
                          </label>
                          <div>
                          <textarea
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Type Here..."
                            multiline
                            className="form-control"
                            name="message"
                            value={jointeam.message}
                            onChange={handleChangeTeam}
                          />
                          {careerErrors?.message && (
                            <span
                              style={{
                                color: "red",
                                fontWeight: "bold",
                                fontSize: "12px",
                              }}
                            >
                              {careerErrors?.message}
                            </span>
                          )}
                          </div>
                        </div>
                        <div className="form-group">
                          <label fontSize="0.9rem" fontWeight={600}>
                            Attachment *
                          </label>
                          <div>
                          <input
                            id="outlined-basic"
                            variant="outlined"
                            type="file"
                            // accept="application/pdf, image/png, image/jpeg"
                            
                            className="form-control"
                            name="attachement"
                            // value={jointeam.attachement}
                            onChange={handleChangeFile}
                          />
                          {careerErrors?.attachement && (
                            <span
                              style={{
                                color: "red",
                                fontWeight: "bold",
                                fontSize: "12px",
                              }}
                            >
                              {careerErrors?.attachement}
                            </span>
                          )}
                          </div>
                        </div>
                        <button
                          onClick={handleSubmitTeam}
                          className="apply_btn"
                        >
                          Submit
                        </button>
                      </div>
                    </Modal>
                  </div>
                </>
              ))}
          </div>
        )}
      </ThemeProvider>
    </>
  )
}

export default CareerUserDetails
