import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./ArtistSidebar.module.css";
import {
  setArtistTypeFilter,
  setCategoryFilter,
  setCountryFilter,
} from "../../../State/Features/user/filterationSlice";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import api from "../../../State/api/Api";
import {
  fetchArtistTypes,
  fetchCategoryData,
} from "../../../State/api/userApi";
import CircularLoader from "../../../common/loader/Loader";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";

function ArtistSidebar({
  onCategorySelect,
  selectedCategories,
  onCountrySelect,
  selectedCountries,
  onArtistTypeSelect,
  selectedArtistTypes
}) {
  const dispatch = useDispatch();
  const theme = createTheme({
    palette: {
      secondary: {
        main: "#ccc",
      },
    },
    typography: {
      accordionheading: {
        fontSize: "14px",
        fontWeight: 500,
      },
      radioname: {
        fontSize: "0.95vw",
        fontWeight: "400",
      },
    },
  });
  const loader = useSelector((state) => state.loader);
  const artistTypes = useSelector((state) => state.artistTypedata);
  const allcategory = useSelector((state) => state.allcategoryData);
  const [countrydt, setCountrydt] = useState();

  const handleFetchCountry = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(`api/user/getcountry`);
      if (result && result.status === 200) {
        setCountrydt(result.data.response);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {}
  };
  const handleFilterCategory = (category) => {
    // const val = e.target.id;
    dispatch(setCategoryFilter(category));
    onCategorySelect(category);
  };
  const handleCountry = (e) => {
    const val = e.target.value;
    dispatch(setCountryFilter(val));
  };
  const handleArtistType = (e) => {
    const val = e.target.id;
    dispatch(setArtistTypeFilter(val));
  };
  useEffect(() => {
    handleFetchCountry();
    dispatch(fetchArtistTypes());
    dispatch(fetchCategoryData({}));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={`filtersidebar ${styles.ProductSidebar}`}>
        <Accordion
          elevation={0}
          sx={{
            "& .MuiAccordionSummary-contentGutters": {
              margin: "10px 0", // Adjust as needed
            },
            "&.MuiPaper-root": {
              boxShadow: "none",
              borderBottom: "1px solid #D9DBE9",
              marginBottom: "0",
              borderBottomLeftRadius: "0",
              borderBottomRightRadius: "0",
            },
            "& .MuiAccordionSummary-gutters": {
              padding: "0",
            },
            "&.MuiAccordion-root::before": {
              display: "none",
            },
            "& .Mui-expanded": {
              minHeight: "auto !important",
              margin: "0 0 10px 0 !important",
            },
            "& .MuiAccordionSummary-expandIconWrapper": {
              background: "#B7B7B7",
              opacity: ".3",
              textAlign: "center",
              borderRadius: "8px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              background: "#1C1C1C",
              opacity: "1",
              color: "#fff",
            },
            "& .MuiAccordionDetails-root": {
              paddingTop: "0",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h3>Category</h3>
          </AccordionSummary>
          <AccordionDetails>
            {loader.loader ? (
              <div className={styles.loader}>
                <CircularLoader />
              </div>
            ) : (
              <div className={styles.radio__div}>
                <ul className={styles.ctfilist}>
                  {allcategory.data &&
                    allcategory.data.allcategories.map((val, index) => (
                      <li key={index}>
                        <label
                          className={styles.radio__label}
                          htmlFor={val._id}
                        >
                          <input
                            className={styles.checkbox_animated}
                            type="checkbox"
                            name={val.name}
                            value={val.name}
                            id={val._id}
                            onChange={() => onCategorySelect(val.name)}
                            size="small"
                            color="primary"
                            // checked={props.filter?.category?.includes(val.name)}
                            checked={
                              selectedCategories &&
                              selectedCategories.includes(val.name)
                            }
                          />

                          {val.name}
                        </label>
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          sx={{
            "& .MuiAccordionSummary-contentGutters": {
              margin: "10px 0", // Adjust as needed
            },
            "&.MuiPaper-root": {
              boxShadow: "none",
              borderBottom: "1px solid #D9DBE9",
              marginBottom: "0",
              borderBottomLeftRadius: "0",
              borderBottomRightRadius: "0",
            },
            "& .MuiAccordionSummary-gutters": {
              padding: "0",
            },
            "&.MuiAccordion-root::before": {
              display: "none",
            },
            "& .Mui-expanded": {
              minHeight: "auto !important",
              margin: "0 0 10px 0 !important",
            },
            "& .MuiAccordionSummary-expandIconWrapper": {
              background: "#B7B7B7",
              opacity: ".3",
              textAlign: "center",
              borderRadius: "8px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              background: "#1C1C1C",
              opacity: "1",
              color: "#fff",
            },
            "& .MuiAccordionDetails-root": {
              paddingTop: "0",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h3>Filters</h3>
          </AccordionSummary>
          <AccordionDetails>
            {loader.loader ? (
              <div className={styles.loader}>
                <CircularLoader />
              </div>
            ) : (
              <div className={styles.radio__div}>
                <ul className={styles.ctfilist}>
                  {artistTypes.data &&
                    artistTypes.data.map((val, index) => (
                      <li key={index}>
                        <label
                          className={styles.radio__label}
                          htmlFor={val._id}
                        >
                          <input
                            className={styles.checkbox_animated}
                            name={val.name}
                            value={val._id}
                            id={val._id}
                            type="checkbox"
                            onChange={() => onArtistTypeSelect(val.name)}
                            checked={selectedArtistTypes.includes(val.name)}
                          />

                          {val.name}
                        </label>
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          elevation={0}
          sx={{
            "& .MuiAccordionSummary-contentGutters": {
              margin: "10px 0", // Adjust as needed
            },
            "&.MuiPaper-root": {
              boxShadow: "none",
              borderBottom: "1px solid #D9DBE9",
              marginBottom: "0",
              borderBottomLeftRadius: "0",
              borderBottomRightRadius: "0",
            },
            "& .MuiAccordionSummary-gutters": {
              padding: "0",
            },
            "&.MuiAccordion-root::before": {
              display: "none",
            },
            "& .Mui-expanded": {
              minHeight: "auto !important",
              margin: "0 0 10px 0 !important",
            },
            "& .MuiAccordionSummary-expandIconWrapper": {
              background: "#B7B7B7",
              opacity: ".3",
              textAlign: "center",
              borderRadius: "8px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              background: "#1C1C1C",
              opacity: "1",
              color: "#fff",
            },
            "& .MuiAccordionDetails-root": {
              paddingTop: "0",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h3>Nationalities</h3>
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.radio__div}>
              <ul className={styles.ctfilist}>
                {countrydt &&
                  countrydt.map((val, index) => (
                    <li key={index}>
                      <label className={styles.radio__label} htmlFor={val._id}>
                        <input
                          className={styles.checkbox_animated}
                          name={val.name}
                          value={val.name}
                          id={val._id}
                          type="checkbox"
                          onChange={() => onCountrySelect(val.name)} // Call parent function to update country
                          checked={selectedCountries.includes(val.name)} // Check if the country is selected
                        />
                        {val.name}
                      </label>
                    </li>
                  ))}
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </ThemeProvider>
  );
}

export default ArtistSidebar;
