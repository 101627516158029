import * as React from "react";
import styles from "./PopupCuratorList.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import { assignCurator } from "../../../../State/api/superadminApi";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch } from "react-redux";
import { RiCloseCircleFill} from "react-icons/ri";
import { prop } from "dom7";
 
export default function PopupCuratorList(props) {
  console.log("props.curatorData-----", props);
  const dispatch = useDispatch();
  const handleClose = () => props.setOpen(false);

  const handleChange = (event) => {
    const newValue = event.target.value;
    // Update the parent's state through the passed setCuratorUser function
    props.setCuratorUser(newValue);
  };
  const curatorSubmit = () => {
    dispatch(
      assignCurator(props.curatorUser, props.popupdata, props.fetchData)
    );
    props.setOpen(false);

  };

  

  const [selectedCurator, setSelectedCurator] = React.useState("");
  const curatorsData = [
    { id: 1, name: "John Doe" },
    { id: 2, name: "Jane Smith" },
    { id: 3, name: "Alice Brown" },
  ];
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box  className={styles.modal_del}>
          <div className={styles.clobtn}>
                <Link onClick={() => props.setOpen(false)}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  Assign To
              </p>
         
            <div className="form-group">
              {/* <label className={styles.search_label}>Select Curator</label> */}
              <label>Select Curator</label>
              <Select
              labelId="curator-select-label"
                value={props.curName || ""}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiInputBase-input":{
                    padding:".45rem .75rem",
                    height:"auto",
                    borderRadius:".25rem",
                    fontSize:"16px",
                    fontWeight:"400"
                  },
                  border:"1px solid #ced4da",
                  borderRadius:".25rem",
                  width:"100%"
                }}
                placeholder="Select curator"
                onChange={handleChange}
                name="curator_id"
              >
                <MenuItem value="">
                  Select Curator
                </MenuItem>
                {props.curatorsData &&
                  props.curatorsData.data.map((val) => (
                    <MenuItem key={val._id} value={val._id}>
                      {val.first_name} {val.last_name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
            <div className={styles.actionc}>
              <button
                className="action_btn"
                onClick={curatorSubmit}
              >
                Add
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
