import React from "react";
import styles from "./Popup.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import homeImage from "../../../../constant";
import { RiCloseCircleFill} from "react-icons/ri";

const data = [
  {
    id: 1,
    user_title: "Username",
    user_val: "Artsmiley",
  },
  {
    id: 2,
    user_title: "Customer ID",
    user_val: "h3jh45",
  },
  {
    id: 3,
    user_title: "Buyer Name",
    user_val: "Smiley",
  },
  {
    id: 4,
    user_title: "Number of items",
    user_val: "878",
  },
  {
    id: 5,
    user_title: "Email Address",
    user_val: "jhe63hkj@gmail.com",
  },
];

export default function Popup(props) {
  console.log(props.popupdata);
  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => props.setOpen(true);
  const handleClose = () => props.setOpen(false);
  const handleBlockunblock = () => {
    const is_blocked = props.popupdata.is_blocked;
    console.log(is_blocked);
    props.handleBlockunblock &&
      props.handleBlockunblock(props.popupdata._id, !is_blocked);
  };
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box  className={styles.modal_del}>
          <div className={styles.clobtn}>
                <Link onClick={() => props.setOpen(false)}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
                 {props.popupdata?.name}
              </p>
            {/* <div className={styles.cancel_img}>
              <img
                
                src="/img/cancel_cross.png"
                height="20px"
                width="20px"
              />
            </div> */}
            
            <div className={styles.data_div}>
            <p className={styles.odlist}><span>Username :</span> <span>{props.popupdata?.first_name} {props.popupdata?.last_name}</span></p>
            <p className={styles.odlist}><span>Customer ID :</span> <span>{props.popupdata?._id}</span></p>
            <p className={styles.odlist}><span>Buyer name :</span> <span>{props.popupdata?.first_name} {props.popupdata?.last_name}</span></p>
              <p className={styles.odlist}><span>No.of Items :</span> <span>{props.popupdata?.orders_count}</span></p>
              <p className={styles.odlist}><span>Email Address :</span> <span>{props.popupdata?.email}</span></p> 
              {/* {data.map((val) => ( */}
                

              {/* ))} */}
            </div>
            <div className={styles.action}>
              <button
              className="action_btn"
                variant="contained"
                sx={{ mr: 1, width: "100px" }}
                onClick={handleBlockunblock}
                color={props.popupdata?.is_blocked ? "secondary" : "primary"}
              >
                {props.popupdata?.is_blocked ? "Unblock" : "Block"}
              </button> 
              <button
                variant="outlined"
                onClick={() => props.handleDeletebuyer(props.popupdata._id)}
                className="action_libtn"
              >
                Delete
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}