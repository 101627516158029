import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import React from "react"
import homeImage from "../../../../../constant"
import { useLocation } from "react-router-dom"
import { buyerAddressDelete, deleteAddressBuyer, handleFetchBuyerDetail } from "../../../../../State/api/userApi"
import { useDispatch } from "react-redux"
import api from "../../../../../State/api/Api"
import { Successnotify } from "../../../../../common/alert/Alert"
import { ToastContainer } from "react-toastify"

export default function Threedots(props) {
  // console.log(props)
  const [anchorEl, setAnchorEl] = React.useState(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const threedots = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

 
  return (
    <div>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          // aria-controls={threedots ? 'account-menu' : undefined}
          aria-haspopup="true"
          // aria-expanded={threedots ? 'true' : undefined}
        >
          <img src={"/img/Menu Icon.png"} height="21.62px" width="4.63px" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            // width: '150px',
            overflow: "visible",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
            filter: "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
            mt: 2.5,
            "& .MuiAvatar-root": {
              width: "120px",
              height: "132px",
              ml: 0.5,
              mr: 1,
              // mt:5
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              // horizontal: 'center',
              right: 30,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "right" }}
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
      >
        {/* {props.data && props.data.map((val,i)=>( */}
        <MenuItem
          sx={{
            display: "flex",
            justifyContent: "center",
            fontSize: "16px",
            lineHeight: "12px",
            fontWeight: "400",
            fontFamily: "Roboto",
          }}
          value="addfeature"
          onClick={() => props.handleMakeDefaultAddress(props.val?._id)}
        >
          Make it Default
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.setEdit(true)
            props.setEditAddressData(props.val)
          }}
          sx={{
            display: "flex",
            justifyContent: "center",
            fontSize: "16px",
            lineHeight: "12px",
            fontWeight: "400",
            fontFamily: "Roboto",
          }}
          value="addfeature"
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => dispatch(deleteAddressBuyer(props.id))}
          sx={{
            display: "flex",
            justifyContent: "center",
            fontSize: "16px",
            lineHeight: "12px",
            fontWeight: "400",
            fontFamily: "Roboto",
          }}
          value="addfeature"
        >
          Delete
        </MenuItem>
        {/* <MenuItem
          onClick={() => props.handleArtistDetails(props._id)}
          sx={{
            display: "flex",
            justifyContent: "center",
            fontSize: "16px",
            lineHeight: "12px",
            fontWeight: "400",
            fontFamily: "Roboto",
          }}
          value="addfeature"
        >
          Artist Subscription List
        </MenuItem> */}
        {/* )) } */}
      </Menu>
      <ToastContainer />
    </div>
  )
}
