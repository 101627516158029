import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./AtristInquirtDetails.module.css";
import capitalizeWords from "../../../utils/capitalizeContent";
import { IoIosArrowBack } from "react-icons/io";
import dateFormat from "../../../utils/dateFormat";
import { Box, Button, Link } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEnquiryByEnquiryId,
  fetchEnquiryReplyByEnquiryId,
} from "../../../State/api/superadminApi";
import InquiryChatpopup from "./popup/InquiryChatpopup";
import dayjs from "dayjs";
import api from "../../../State/api/Api";
function AtristInquirtDetails() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = location.state || {};
  console.log("details page description:",id);

    const [inquiries, setInquiries] = useState([])  
  
      const handleFetchAllInquiries = async () => {
        try {
          const {data} = await api.applicationApi(
            "api/enquiry/fetchEnquiries",
            "POST",            
             )
          setInquiries(data.data.enquiries?.filter((ele)=>ele?._id === id)?.[0])
        }catch(error) {
           console.log(error)
        }
      }
      useEffect(() => {
       handleFetchAllInquiries()
      },[])
  console.log("inquiries",inquiries);
  
  const enquiryId = useSelector((state) => state.enquirybyenquiryid.data);
  const enquiryreply = useSelector((state) => state.enquiryreplybyiddata.data);
  
  console.log("enquiryreply",enquiryreply);
  console.log("enquiryId",enquiryId);
  
  const fetchData = () => {
    dispatch(fetchEnquiryByEnquiryId(id));
    dispatch(fetchEnquiryReplyByEnquiryId(id));
  };
  useEffect(() => {
    fetchData();
  }, []);
  const [Open, setOpen] = useState(false);
  const handleAddchatbtn = (id) => {
    setOpen(true);
  };
  return (
    <div className={styles.mains}>
      {Open ? <InquiryChatpopup Open={Open} id={id} setOpen={setOpen} /> : ""}
      <div>
        <div className={styles.main_artistprofile}>
          <div className={styles.back_button}>
            <Box  display="flex" justifyContent="space-between">
              <Link
                onClick={() => navigate(-1)}
                className={styles.backbn}
              >
                {" "}
                <IoIosArrowBack /> <span> Go Back</span>
              </Link>
              <button
                className="action_btn"
                variant="contained"
                onClick={handleAddchatbtn}
              >
                Add Reply
              </button>
            </Box>
          </div>
        </div>

        <div className={styles.more_detail}>
          {enquiryId ?   <div className={styles.bdngr}>
            <h3 className={styles.sethng}>
              Subject : {capitalizeWords(enquiryId?.subject)}{" "}
            </h3>
            <p className={styles.odlist}>
              Message : <span>{capitalizeWords(enquiryId?.message)}</span>
            </p>
            <p className={styles.odlist}>
              Enquiry Type :{" "}
              <span>{capitalizeWords(enquiryId?.enquirytype)}</span>
            </p>
            <p className={styles.odlist}>
              Enquiry SubType :{" "}
              <span>{capitalizeWords(enquiryId?.enquirysubtype)}</span>
            </p>
            <p className={styles.odlist}>
              Attachment :{" "}
              <span>
                {enquiryId?.attachment === null
                  ? "----"
                  : enquiryId?.attachment}
              </span>
            </p>
            <p className={styles.odlist}>
              Created At : <span>{dateFormat(enquiryId?.createdAt)}</span>
            </p>
          </div>:null}
                  
          {inquiries ?   <div className={styles.bdngr}>
          
            <p className={styles.odlist}>
              Status : <span>{capitalizeWords(inquiries?.is_open)}</span>
            </p>
            <p className={styles.odlist}>
            Inquiry Id : <span>{capitalizeWords(inquiries?.enquiry_id)}</span>
            </p>
            <p className={styles.odlist}>
            Customer Name : <span>{capitalizeWords(inquiries?.customer_name)}</span>
            </p>
            <p className={styles.odlist}>
            Customer Email : <span>{capitalizeWords(inquiries?.email)}</span>
            </p>
            <p className={styles.odlist}>
            Customer Phone No. : <span>{(inquiries?.phone)}</span>
            </p>
            <p className={styles.odlist}>
              Message : <span>{capitalizeWords(inquiries?.description)}</span>
            </p>
            
            <p className={styles.odlist}>
              Attachment :{" "}
              <span>
                {enquiryId?.attachment === null
                  ? "----"
                  : enquiryId?.attachment}
              </span>
            </p>
            <p className={styles.odlist}>
              Inquiry Date : <span>{(enquiryId?.date)}</span>
            </p>
          </div> : null}
        
        </div>
      </div>
      <div className={styles.more_detail}>
        <h3 className={styles.sethng}>Enquiry Replies</h3>
        {enquiryreply && enquiryreply?.length > 0 ? (
          enquiryreply?.map((val) => (
            <div key={val._id}>
              <p className={styles.odlist}>
                Reply Message: <span>{val.reply}</span>
              </p>
              <p className={styles.odlist}>
                Created at:{" "}
                <span>
                  {dayjs(val?.createdAt).format("DD-MM-YYYY").toString()}
                </span>
              </p>
              <p className={styles.odlist}>
                File Name:{" "}
                <span>
                  {val.attachment === null ? (
                    "----"
                  ) : (
                    <a
                      href={val.attachment}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                    >
                      {val.attachment}
                    </a>
                  )}
                </span>
              </p>
              <hr />
            </div>
          ))
        ) : (
          <div className={styles.no_reply}>No Reply found</div>
        )}
      </div>
    </div>
  );
}

export default AtristInquirtDetails;
