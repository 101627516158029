import React from "react"
import styles from "./Tables.module.css"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Button, IconButton } from "@mui/material"
import Popup from "../../Popupdelete/Popup"
import Tableheader from "../Tableheader/Tableheader"
import Moreoption from "../Moreoption/Moreoption"
import homeImage from "../../../../../constant"
import serverimage from "../../../../../serverConstant";
import { useSelector } from "react-redux";
const headData = [
  {
    id: "image",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "art_name",
    label: "Art Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "sku_id",
    label: "Sku_Id",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "buy",
    label: "Buy",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "rent",
    label: "Rent",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "print",
    label: "Print",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "action",
    label: "",
    format: (value) => value.toLocaleString(),
  },
]
const curatorHeadData = [
  {
    id: "image",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "art_name",
    label: "Art Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "category",
    label: "Category",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "sku_id",
    label: "Sku_Id",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "buy",
    label: "Buy",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "rent",
    label: "Rent",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "print",
    label: "Print",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "action",
    label: "",
    format: (value) => value.toLocaleString(),
  },
]
const rows = [
  {
    image: "/img/art_list_image.png",
    user_name: "Galexy On Earth",
    sku_id: "Emily cooper",
    art_name: "Gabrial Zac",
    buy: "$1545",
    rent: "$1545",
    print: "---",
    action: "Block",
  },
  {
    image: "/img/Image 2.png",
    user_name: "Galexy On Earth",
    sku_id: "Emily cooper",
    art_name: "Gabrial Zac",
    buy: "$1545",
    rent: "$1545",
    print: "---",
    action: "Block",
  },
  {
    image: "/img/Image 3.png",
    user_name: "Galexy On Earth",
    sku_id: "Emily cooper",
    art_name: "Gabrial Zac",
    buy: "$1545",
    rent: "$1545",
    print: "---",
    action: "Block",
  },
  {
    image: "/img/Image 4.png",
    user_name: "Galexy On Earth",
    sku_id: "Emily cooper",
    art_name: "Gabrial Zac",
    buy: "$1545",
    rent: "$1545",
    print: "---",
    action: "Block",
  },
]
const artistOptions = [
  {
    id: "pending",
    label: "Pending",
    value: "pending",
  },
  {
    id: "approved",
    label: "Approved",
    value: "approved",
  },
  {
    id: "seller",
    label: "Best Seller",
    value: "seller",
  },
  {
    id: "popular",
    label: "Most Popular",
    value: "popular",
  },
  {
    id: "deleted",
    label: "Deleted Arts",
    value: "deleted",
  },
]
export default function Tables(props) {
  const [open, setOpen] = React.useState(false)
  const [selectedRow, setSelectedRow] = React.useState(null)
  const handleDeleteClick = (sku_id) => {
    const selectedData = props.data.find((row) => row.sku_id === sku_id)
    console.log("selectedData", selectedData)
    if (selectedData) {
      setSelectedRow(selectedData)
      setOpen(true)
    }
  }
  const usertype = useSelector((state) => state.user.data);
  const artsHeadData = usertype.type === "curator" ? curatorHeadData : headData;
  // console.log("props.data", props.data.map((row) => row.itemcategories_details))
  return (
    <>
      {open ? <Popup open={open} setOpen={setOpen} popupdata={selectedRow}/> : ""}
      <div className={styles.shadow}>
        {/* <Tableheader /> */}
        {/* <Moreoption  artistOptions={artistOptions} /> */}
        <TableContainer
          component={Paper}
          sx={{ backgroundColor: "#F6F6F6", color: "black", boxShadow: "none" }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
                {artsHeadData.map((column, i) => (
                  <TableCell
                    align="left"
                    sx={{
                      whiteSpace: "nowrap",
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "16px",
                      height: "21px",
                      color: "#1C1C1C",
                    }}
                    key={i}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data &&
                props.data?.map((row, i) => (
                  <TableRow style={{ color: "#636365" }} key={i}>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      <img
                        src={ row.image}
                        height="30px"
                        width="40px"
                        alt="img"
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    {usertype.type === "curator" && 
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      component="th"
                      scope="row"
                    >
                      {row?.itemcategories_details?.map((item) => item.name)}
                    </TableCell>}
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.sku_id ? row.sku_id : "----"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row?.sale?.price ? `$${row?.sale?.price}` : "----"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row?.rent?.rent_price ? `$${row?.rent?.rent_price}` : "----"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row?.print?.[0]?.print_price ? `$${row?.print?.[0]?.print_price}` : "----"}
                    </TableCell>
                    {usertype.type !== "curator" &&
                    <TableCell
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="left"
                  >
                    <Button
                      color={row.status ? "primary" : "primary"}
                      variant={row.status ? "contained" : "contained"}
                      sx={{ width: "100px", mr: 2 }}
                    >
                      Block
                    </Button>
                    <IconButton onClick={() => handleDeleteClick(row.sku_id)}>
                      <img
                        src={"/img/delete.png"}
                        width="20px"
                        height="23px"
                        alt="img"
                      />
                    </IconButton>
                  </TableCell>}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
}
