import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material"
// import Divider from '@mui/material/Divider';
import React, { useState } from "react"
import styles from "./Tableseven.module.css"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import profileSlice, {
  addData,
} from "../../../../../State/Features/Artist/profileSlice"
import homeImage from "../../../../../constant"
import CircularLoader from "../../../../../common/loader/Loader"
import moment from "moment"

export default function Tableseven(props) {
  console.log("props.data.data",props)
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const dispatch = useDispatch()
  const data = useSelector((state) => state.artistdata)
  const loader = useSelector((state) => state.loader)

  const threedots = Boolean(anchorEl)
  const [SetId, setSetId] = useState('')
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setSetId(event.target.id)
  }
  console.log("SetId",SetId);
  
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handlePopup = (e) => {
    props.setOpen(true)
    props.setPoptype("addfeature")
  }
  const handleNavigate = (id) => {
    const path = window.location.pathname
    if (path === "/dispute") {
      navigate(`/dispute/${id}`)
    }
    // props.setPage("profile");
  }
  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  align="left"
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "15px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data &&
              props.data
                // (props.activetab === "dispute_superadmin"
                //   ? props.data
                //   : ([...props.data].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)))
                // )
                ?.map((row, i) => (
                  <TableRow
                    value={row.artist_name}
                    style={{ color: "#636365", cursor: "pointer" }}
                    key={i}
                  >
                    <TableCell
                      onClick={() => handleNavigate(row._id, row)}
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                         <span className={styles.hover_text}>
                         {row.dispute_id}
                                            </span>
                      
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.order_id}
                    </TableCell>
                    {props.activetab === "dispute_superadmin" ? (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {/* {row.generated_by} */}
                        {moment(row?.createdAt)?.format("DD-MM-YYYY")}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.name}
                    </TableCell>
                  
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        display:"flex",
                        gap:"5px"
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      <div
                        className={
                          row.refund_status === "Rejected"
                            ? styles.dispute_status_open
                            : row.refund_status === "Approved"
                            ? styles.disput_status
                            : styles.refund_pending
                        }
                      >
                        {row.refund_status === "Rejected"
                          ? "Rejected"
                          : row.refund_status === "Approved"
                          ? "Approved"
                          : "Pending"}
                      </div>
                      <Tooltip title="Account settings">
                          <IconButton
                            onClick={handleClick}
                            size="small"
                            id={row?._id}
                            sx={{ ml: 2 }}
                            aria-controls={
                              threedots ? "account-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={threedots ? "true" : undefined}
                          >
                            <img
                            alt=""
                              src={"/img/Menu Icon.png"}
                              height="21.62px"
                              width="4.63px"
                              id={row._id}
                            />
                          </IconButton>
                        </Tooltip>
                        <Menu
                          anchorEl={anchorEl}
                          id="account-menu"
                          open={threedots}
                          onClose={handleClose}
                          onClick={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              width: "150px",
                              overflow: "visible",
                              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                              filter:
                                "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                              mt: 2.5,
                              "& .MuiAvatar-root": {
                                width: "120px",
                                height: "132px",
                                ml: 0.5,
                                mr: 1,
                                // mt:5
                              },
                              "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                // horizontal: 'center',
                                right: 62,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "center",
                            vertical: "right",
                          }}
                          anchorOrigin={{
                            horizontal: "left",
                            vertical: "center",
                          }}
                        >
                          <MenuItem
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "16px",
                              lineHeight: "12px",
                              fontWeight: "400",
                              fontFamily: "Roboto",
                            }}
                            value="addfeature"
                            onClick={()=>{
                              props.ApprovedRefundStatus &&
                              props.ApprovedRefundStatus(SetId, {refund_status:"Approved"})
                            }
                            }
                          >
                            Refund Approved
                          </MenuItem>
                          <Divider/>
                          <MenuItem
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "16px",
                              lineHeight: "12px",
                              fontWeight: "400",
                              fontFamily: "Roboto",
                            }}
                            value="addfeature"
                            onClick={()=>{
                              props.ApprovedRefundStatus &&
                              props.ApprovedRefundStatus(SetId,  {refund_status:"Rejected"})
                            }}
                          >
                            Refund Rejected
                          </MenuItem>
                        </Menu>
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      <div className={styles.dispute_flex}>
                        <span
                          className={
                            row.dispute_status === "Resolved"
                              ? styles.disput_status
                              : styles.dispute_status_open
                          }
                        >
                          {" "}
                          {row.dispute_status}
                        </span>
                       
                      </div>
                    </TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  )
}
