import * as React from "react";
import styles from "./Commonsidebar.module.css";
import { Button, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

export default function Commonsidebar(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const rawPath = location.pathname;
  const path = rawPath.replace(/\/[a-f0-9]{24}$/, ""); // Adjust regex for 24-character MongoDB ObjectIDs
  const usertypes = localStorage.getItem("usertype");

  const [isOpen, setIsOpen] = React.useState(false);
  const [openSubmenu, setOpenSubmenu] = React.useState(null);

  // Function to get the current page title based on the route
  const getPageTitle = () => {
    const currentRoute = props.sidebarRoutes.find((route) =>
      path.includes(route.path)
    );
    return currentRoute ? currentRoute.name : "Menu";
  };

  const handleDashboard = () => {
    navigate("/dashboard");
  };
  const handleClick = (val) => {
    if (val.hasSubmenu) {
      setOpenSubmenu(openSubmenu === val.path ? null : val.path);
    } else {
      setOpenSubmenu(null);
      navigate(val.path);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleSubmenuClick = (sub) => {
    navigate(sub.path);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // const handleClick = (val) => {
  //   if(val.hasSubmenu===true){
  //     setOpenSubmenu(!openSubmenu)
  //     navigate(val?.submenu[0]?.path);
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth",
  //     });
  //     setIsOpen(false);
  //   }else{
  //   navigate(val.path);
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  //   setIsOpen(false);
  //  } // Close dropdown after selection on mobile
  // };

  // const handleClick = (val) => {
  //   if (val.hasSubmenu) {
  //     setOpenSubmenu(openSubmenu === val.path ? null : val.path); // Toggle submenu
  //   } else {
  //     setOpenSubmenu(null); // Close any open submenu when navigating
  //     navigate(val.path);
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //     setIsOpen(false);
  //   }
  // };

  return (
    <div className={styles.demo}>
      {/* Desktop View - Always Visible */}
      {usertypes === "buyer" ||
      usertypes === "affiliate" ||
      usertypes === "subadmin" ? null : (
        <h2 onClick={handleDashboard} style={{ cursor: "pointer" }}>
          Dashboard
        </h2>
      )}

      {/* Mobile View - Dropdown Button with Dynamic Title */}
      <div className={styles.mobileDropdown}>
        <button
          className="action_btn"
          onClick={() => setIsOpen(!isOpen)}
          fullWidth
          variant="contained"
        >
          {getPageTitle()} {isOpen ? <FiChevronUp /> : <FiChevronDown />}
        </button>
      </div>

      {/* Sidebar Links */}
      <div
        className={`${styles.sidebarContent} ${
          isOpen ? styles.open : styles.closed
        }`}
      >
        {props.sidebarRoutes &&
          props.sidebarRoutes.map((val, i) => (
            <div className={styles.demo_child_div} key={i}>
              <div
                className={
                  path === val.path || path.includes(`${val.path}/`)
                    ? styles.flex_start
                    : ""
                }
              ></div>
              <Button
                onClick={() => handleClick(val)}
                variant={
                  path === val.path || path.includes(`${val.path}/`)
                    ? "contained"
                    : ""
                }
                fullWidth
                sx={{ 
                  pl: 2,
                  mr: 1,

                  textAlign: "left",
                  justifyContent: "start",
                  textTransform: "capitalize !important",
                  fontWeight: "500",
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                  fontSize: "16px",
                  lineHeight: "20px",
                  marginBottom: "1.8px",
                  marginTop: "1.8px",
                }}
              >
                {val.name}
              </Button>
              {val.hasSubmenu && (
                <div
                  className={` ${
                    openSubmenu === val.path
                      ? styles.submenuOpen
                      : styles.submenuContainer
                  }`}
                >
                  {val.submenu.map((sub, j) => (
                    <Button
                      key={j}
                      onClick={() => handleSubmenuClick(sub)}
                      variant={path === sub.path ? "contained" : ""}
                      fullWidth
                      sx={{ 
                        pl: 4,
                        mr: 1,
                        textAlign: "left",
                        justifyContent: "start",
                        textTransform: "capitalize !important",
                        fontSize: "14px",
                        lineHeight: "18px",
                        marginBottom: "1.5px",
                        marginTop: "1.5px",
                        borderTopRightRadius: "0",
                        borderBottomRightRadius: "0",
                        backgroundColor:
                          path === sub.path ? "black" : "transparent",
                      }}
                    >
                      {sub.name}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}
