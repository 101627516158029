import React, { useState } from "react";
import Commonsidebar from "../../components/Pages/List/Common/sideBar/Commonsidebar";
import Artistgallery from "../components/popups/artistGallery/Artistgallery";
import Webpopup from "../components/popups/galleryWeb/Webpopup";

const sidebarRoutes = [
  // {
  //     name: 'GALLERY PROFILE',
  //     icon: 'fa fa-dashboard',
  //     path: '/galleryprofile',
  //     hasSubmenu: false,
  // },
  {
    name: "profile",
    icon: "fa fa-truck",
    path: "/profile",
    hasSubmenu: false,
  },

  {
    name: "arts",
    icon: "fa fa-users",
    path: "/arts",
    hasSubmenu: false,
  },
  {
    name: 'my artist',
    icon: 'fa fa-users',
    path: '/myartist',
    hasSubmenu: true,
    submenu: [
        {
            name: 'Artist List',
            path: '/myartist',
        },
        {
            name: 'Registration Form',
            path: '/myartist/registrationform',
        },
      ]
  },
  {
    name: "upload Arts",
    icon: "fa fa-cube",
    path: "/upload/stepfirst",
    hasSubmenu: false,
  },
  {
    name: "exhibitions",
    icon: "fa fa-th-large",
    path: "/exhibitionsgallery",
    hasSubmenu: false,
  },
  {
    name: "auctions",
    icon: "fa fa-cube",
    path: "/auctions",
    hasSubmenu: false,
  },
  {
    name: "orders",
    icon: "fa fa-cube",
    path: "/orders",
    hasSubmenu: false,
  },
  {
    name: "subscriptions",
    icon: "fa fa-cube",
    path: "/subscriptions",
    hasSubmenu: false,
  },
  {
    name: "earnings",
    icon: "fa fa-cube",
    path: "/earnings",
    hasSubmenu: false,
  },
  {
    name: "buyer's offers",
    icon: "fa fa-cube",
    path: "/buyeroffer",
    hasSubmenu: false,
  },
  {
    name: "Art Smiley Offers",
    icon: "fa fa-cube",
    path: "/adminoffer",
    hasSubmenu: false,
  },
  {
    name: "recreate",
    icon: "fa fa-cube",
    path: "/recreate",
    hasSubmenu: false,
  },
  {
    name: "quote request",
    icon: "fa fa-cube",
    path: "/quoterequest",
    hasSubmenu: false,
  },
  {
    name: " Buyer's inquiry",
    icon: "fa fa-cube",
    path: "/inquiry",
    hasSubmenu: false,
  }, 
  {
    name: "My enquiry",
    icon: "fa fa-cube",
    path: "/enquirygallery",
    hasSubmenu: true,
    submenu: [
        {
            name: 'Gallery Inquiry',
            path: '/enquirygallery',
        },
        // {
        //     name: 'Inquiry Details',
        //     path: '/enquirygallery/atristinquirtdetails',
        // }
    ]
  },
  {
    name: "testimonial",
    icon: "fa fa-cube",
    path: "/gallerytestimonial",
    hasSubmenu: false,
  },
 
  {
    name: "dispute Management",
    icon: "fa fa-cube",
    path: "/dispute",
    hasSubmenu: false,
  },
  {
    name: "notifications",
    icon: "fa fa-cube",
    path: "/notification",
    hasSubmenu: false,
  },
  {
    name: "Art Smiley News",
    icon: "fa fa-cube",
    path: "/news",
    hasSubmenu: false,
  },
];
export default function Gallerysidebar() {
  const [open, setOpen] = useState(true);

  return (
    <>
      {/* <Webpopup open={open} setOpen={setOpen} /> */}
      {/* <Artistgallery open={open} setOpen={setOpen} /> */}
      <Commonsidebar sidebarRoutes={sidebarRoutes} />
    </>
  );
}
