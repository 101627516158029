import validator from "validator"
import isEmpty from "./isEmpty"

export const validateAdminEditCamp = (camp) => {
  let campEditErrors = {}

  if (validator.isEmpty(camp.name)) {
    campEditErrors.name = "Name is required"
  }

  if (validator.isEmpty(camp.campaign_code)) {
    campEditErrors.campaign_code = "Code is required"
  }

  if (validator.isEmpty(camp.discount)) {
    campEditErrors.discount = "Discount is required"
  }
  if (validator.isEmpty(camp.validity)) {
    campEditErrors.validity = "Validity is required"
  }
  if(validator.isEmpty(camp.description) || camp.description === "<p><br></p>") {
     campEditErrors.description = "Description is required";
  }
  // if (validator.isEmpty(camp.description)) {
  //   campEditErrors.description = "Description is required"
  // }

  return {
    campEditErrors,
    isValid: isEmpty(campEditErrors),
  }
}
