import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Tooltip,
  Pagination,
  PaginationItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./Tables.module.css";
import { useSelector } from "react-redux";
import CircularLoader from "../../../../../common/loader/Loader";
import homeImage from "../../../../../constant";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function TablesApproved(props) {
  console.log("Approve Table ----->", props);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ids, setIds] = useState("");
  const [threedot_id, setThreedot_id] = React.useState("");
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState({ id: "", country: "" });
  console.log(selectedData, "selectedData-asas-");
  const threedots = Boolean(anchorEl);
  const loader = useSelector((state) => state.loader);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setIds(event.target.id);
    const country = row?.artist[0]?.country || "Unknown";
    setSelectedData({
      id: row._id,
      country: country,
    });
  };

  const handleGalleryDelete = (event) => {
    setAnchorEl(event.currentTarget);
    setThreedot_id(event.target.id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePopup = () => {
    props.setOpen(true);
  };
  const handleProfilepage = (id) => {
    const path = window.location.pathname;
    if (path === "/artist") {
      navigate(`/artist/${id}`, { state: { active: props.activetab } });
    } else if (path === "/curators") {
      navigate(`/curators/${id}`);
    } else if (path === "/exhibitions") {
      navigate(`/exhibitions/${id}`);
    } else if (path === "/gallery") {
      navigate(`/gallery/${id}`, { state: { active: props.activetab } });
    }
  };
  const navigateToArtistDetail = (id) => {
    navigate(`/user/artist/${id}`);
  };

  // Curator
  const [ApprovesCuraterdata, setApprovesCuraterdata] = useState([]);
  const GetCuratorData = async () => {
    try {
      const res = await axios.post(
        `http://3.6.185.87:8000/api/curator/fetchCurators`
      );
      if (res.status === 200) {
        setApprovesCuraterdata(res.data.data);
      }
    } catch (error) {}
  };
  console.log("ApprovesCuraterdata", ApprovesCuraterdata);

  useEffect(() => {
    GetCuratorData();
  }, []);

  // Updated handleDelete function
  const AssignCurator = (data) => {
    console.log("check function arg", data);
    props.handlePopup(selectedData);
    if (props.setOpen) {
      props.setOpen(true);
    }
  };

  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10; // Adjust how many items you want per page

  // Function to handle page changes
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  // Slice the data based on current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = props?.data?.slice(startIndex, endIndex);

  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#f6f6f6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head &&
                props.head.map((column, i) => (
                  <TableCell
                    align={props.head.length - 1 === i ? "left" : "left"}
                    sx={{
                      whiteSpace: "nowrap",
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "15px",
                      height: "21px",
                      color: "#1C1C1C",
                    textTransform:"capitalize"
                    }}
                    key={i}
                  >
                    {column.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                currentItems &&
                currentItems.map((row, i) => (
                  <TableRow
                    style={{ color: "#636365", height: "30px" }}
                    className={styles.table_row}
                    key={row._id}
                  >
                    {props.activetab === "gallery_approved" ? (
                      <>
                        <TableCell
                          sx={{
                            fontFamily: "Roboto", 
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                    textTransform:"capitalize"
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          {row?.gallery[0]?.gallery_name}
                        </TableCell>
                      </>
                    ) : (
                      ""
                    )}
                    <TableCell
                      sx={{
                        fontFamily: "Roboto", 
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                    textTransform:"capitalize"
                      }}
                      style={{ cursor: "pointer" }}
                      className={styles.table_column}
                      align="left"
                      onClick={() => handleProfilepage(row._id)}
                    >
                      {props.activetab === "gallery_approved" ||
                      props.activetab === "approved" ? (
                        <>
                          <span className={styles.hover_text}>
                            {row.first_name} {row.last_name}
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {/* {props.activetab === "gallery_approved" ? (
                          <>
                            {row?.gallery[0]?.addresses[0]?.country}
                          </>
                        ) : (
                          ""
                        )} */}
                      {props.activetab === "gallery_approved"
                        ? row?.gallery?.[0]?.addresses?.[0]?.country ?? "----"
                        : ""}

                      {props.activetab === "approved" ? (
                        <>{row?.artist?.[0]?.addresses?.[0]?.country}</>
                      ) : (
                        ""
                      )}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {props.activetab === "approved" ||
                      props.activetab === "gallery_approved" ? (
                        <>
                          <div>{row?.email}</div>
                        </>
                      ) : (
                        ""
                      )}
                    </TableCell>

                    {props.activetab === "approved" ||
                    props.activetab === "gallery_approved" ? (
                      <>
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <div>{row?.phone}</div>
                        </TableCell>
                      </>
                    ) : (
                      ""
                    )}

                    {props.activetab === "approved" ? (
                      <>
                        <TableCell
                          sx={{
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          {props.activetab === "approved"
                            ? row.createdAt
                              ? dayjs(row.createdAt)
                                  .format("DD-MM-YYYY")
                                  .toString()
                              : "----"
                            : ""}
                        </TableCell>
                      </>
                    ) : (
                      ""
                    )}

                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {/* {row.artist[0]?.approved_date
                        ? row.artist?.map((cur, i) => cur.approved_date)
                        : "---"} */}

                      {props.activetab === "gallery_approved" ? (
                        <>
                          {row.gallery &&
                          row.gallery.length > 0 &&
                          row.gallery[0].approved_date
                            ? dayjs(row.gallery[0].approved_date)
                                .format("DD-MM-YYYY")
                                .toString()
                            : "----"}
                        </>
                      ) : (
                        ""
                      )}

                      {props.activetab === "approved" ? (
                        <>
                          {row.artist[0]
                            ? row.artist[0].approved_date
                              ? dayjs(row.artist[0].approved_date)
                                  .format("DD-MM-YYYY")
                                  .toString()
                              : "----"
                            : ""}
                        </>
                      ) : (
                        ""
                      )}
                      {/*                       
                      {row.artist[0]
                        ? row.artist[0].approved_date
                          ? dayjs(row.artist[0].approved_date)
                              .format("DD-MM-YYYY")
                              .toString()
                          : "----"
                        : ""} */}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      <>
                        {props.activetab === "gallery_approved" ? (
                          <>
                            {row.subscriptions && row.subscriptions.length > 0
                              ? row.subscriptions[0]?.name
                              : "_____"}
                          </>
                        ) : (
                          ""
                        )}

                        {props.activetab === "approved" ? (
                          <>
                            {row.artist[0]?.curator?.length > 0
                              ? row.artist?.map((cur, i) =>
                                  cur.curator.map(
                                    (curname, i) => curname.curator_name
                                  )
                                )
                              : "Super Admin"}
                          </>
                        ) : (
                          ""
                        )}
                        {/* 
                        {row.artist[0]?.curator?.length > 0
                          ? row.artist?.map((cur, i) =>
                              cur.curator.map(
                                (curname, i) => curname.curator_name
                              )
                            )
                          : "---"} */}
                      </>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {/* {props.activetab === "gallery_approved" ? (
                        <>
                          <div> {row?.gallery[0]?.gallery_name}</div>
                        </>
                      ) : (
                        ""
                      )} */}

                      {props.activetab === "approved" ? (
                        <>
                          <div> {row?.artist[0]?.artist_type[0]?.name}</div>
                        </>
                      ) : (
                        ""
                      )}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      <>
                        {props.activetab === "gallery_approved" ? (
                          <>
                            {row.gallery &&
                              row.gallery.map((vl) => {
                                const lastSubscription =
                                  vl?.subscriptions?.slice(-1)[0];
                                return lastSubscription ? (
                                  <>{lastSubscription?.name}</>
                                ) : (
                                  <>----</>
                                );
                              })}
                          </>
                        ) : (
                          ""
                        )}

                        {props.activetab === "approved" ? (
                          <>
                            {row.artist &&
                              row.artist.map((vl) => {
                                const lastSubscription =
                                  vl?.subscriptions?.slice(-1)[0];
                                return lastSubscription ? (
                                  <>{lastSubscription?.name}</>
                                ) : (
                                  <>----</>
                                );
                              })}
                          </>
                        ) : (
                          "----"
                        )}
                      </>
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {props.activetab === "gallery_approved" ? (
                        <>{row?.likes}</>
                      ) : (
                        ""
                      )}

                      {props.activetab === "approved" ? (
                        <>
                          {row.artist?.map((lik, i) => (
                            <>{lik.likesview?.likes}</>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                    </TableCell>

                    {props.activetab === "approved" ? (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                          height: "auto",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          {row.followers ? row.followers.length : "0"}
                        </Box>
                      </TableCell>
                    ) : (
                      ""
                    )}

                    {props.activetab === "approved" ? (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                          height: "auto",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          <>
                            <Tooltip title="Account settings">
                              <IconButton
                                onClick={(event) => handleClick(event, row)}
                                size="small"
                                id={row._id}
                                sx={{ ml: 2 }}
                                aria-controls={
                                  threedots ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={threedots ? "true" : undefined}
                              >
                                <img
                                  src={"/img/Menu Icon.png"}
                                  id={row._id}
                                  height="21.62px"
                                  alt="img"
                                  width="4.63px"
                                />
                              </IconButton>
                            </Tooltip>
                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={threedots}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  width: "150px",
                                  overflow: "visible",
                                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                  filter:
                                    "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                  mt: 2.5,
                                  "& .MuiAvatar-root": {
                                    width: "120px",
                                    height: "132px",
                                    ml: 0.5,
                                    mr: 1,
                                    // mt:5
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    // horizontal: 'center',
                                    right: 62,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "center",
                                vertical: "right",
                              }}
                              anchorOrigin={{
                                horizontal: "left",
                                vertical: "center",
                              }}
                            >
                              <MenuItem
                                onClick={() => props.handleSetArtist(ids, true)}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Add Featured Artist
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                onClick={() =>
                                  props.handleRecommended(ids, true)
                                }
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Recommended
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                onClick={() =>
                                  props.handleExclusive(ids, true)
                                }
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Exclusive
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                onClick={() =>
                                  // props.handleRecommended(ids, true)
                                  navigateToArtistDetail(ids)
                                }
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                View Artist Profile
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                onClick={() => AssignCurator(row)}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Assign Curator
                              </MenuItem>
                              {/* <MenuItem
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    lineHeight: "12px",
                                    fontWeight: "400",
                                    fontFamily: "Roboto",
                                  }}
                                  onClick={() => props.handleArtistDelete(ids)}
                                >
                                  Delete
                                </MenuItem> */}
                            </Menu>
                          </>
                        </Box>
                      </TableCell>
                    ) : (
                      ""
                    )}

                    {props.activetab === "gallery_approved" ? (
                      <>
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                            height: "auto",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                            }}
                          >
                            {row?.followers}

                            <>
                              <Tooltip title="Account settings">
                                <IconButton
                                  onClick={handleGalleryDelete}
                                  size="small"
                                  id={row._id}
                                  sx={{ ml: 2 }}
                                  aria-controls={
                                    threedots ? "account-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={threedots ? "true" : undefined}
                                >
                                  <img
                                    src={"/img/Menu Icon.png"}
                                    id={row._id}
                                    height="21.62px"
                                    alt="img"
                                    width="4.63px"
                                  />
                                </IconButton>
                              </Tooltip>
                              <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={threedots}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    width: "150px",
                                    overflow: "visible",
                                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                    filter:
                                      "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                    mt: 2.5,
                                    "& .MuiAvatar-root": {
                                      width: "120px",
                                      height: "132px",
                                      ml: 0.5,
                                      mr: 1,
                                      // mt:5
                                    },
                                    "&:before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      top: 0,
                                      // horizontal: 'center',
                                      right: 62,
                                      width: 10,
                                      height: 10,
                                      bgcolor: "background.paper",
                                      transform:
                                        "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "center",
                                  vertical: "right",
                                }}
                                anchorOrigin={{
                                  horizontal: "left",
                                  vertical: "center",
                                }}
                              >
                                <MenuItem
                                  onClick={() =>
                                    props.handleDelete(threedot_id)
                                  }
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    lineHeight: "12px",
                                    fontWeight: "400",
                                    fontFamily: "Roboto",
                                  }}
                                >
                                  Delete
                                </MenuItem>
                              </Menu>
                            </>
                          </Box>
                        </TableCell>
                      </>
                    ) : (
                      ""
                    )}
                    {/*  */}
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <>
        <Box className={styles.pagination_box} sx={{}}>
          <Pagination
            count={Math.ceil(props?.data?.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            renderItem={(item) => (
              <PaginationItem
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "black",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  },
                }}
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
              />
            )}
          />
        </Box>
      </>
    </div>
  );
}
