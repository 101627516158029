import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
  IconButton,
  Container,
} from "@mui/material";
import React from "react";
import styles from "./ProdDetsqna.module.css";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import homeImage from "../../../constant";
import { FiUser } from "react-icons/fi";
import { RiStarFill} from "react-icons/ri";
import { useTranslation } from "react-i18next";
function ProdDetsqna() {
  const qnadata = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
  ];
  const reviewData = [
    {
      id: 1,
    },
  
  ];
  const { t } = useTranslation("productDetails");
  return (
    <div className={styles.qnasection}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={12} md={6}>
          <div className={styles.cqna}>
            <Typography fontFamily="marcellus" fontSize="1.5rem">
              Customer Questions & Answers
            </Typography>

            <TextField
              label="Search by..."
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "85%",
                marginTop: "3%",
                height: "30px",
                marginBottom: "8%",
              }}
            />
            {qnadata.map((item) => (
              <>
                <div className={styles.questiondiv}>
                  <div className={styles.verticalLine3}></div>
                  <Typography fontSize="0.9rem" ml={1}>
                    QUESTION:
                  </Typography>
                  <Typography fontSize="0.7rem" ml={4}>
                    Please let me know urgently about packing, do you fold it
                    simply or send in good
                  </Typography>
                </div>
                <div className={styles.answerdiv}>
                  <div className={styles.verticalLine3}></div>
                  <Typography fontSize="0.9rem" ml={1}>
                    ANSWER:
                  </Typography>
                  <Typography fontSize="0.7rem" ml={5.5}>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </Typography>
                </div>
                <div className={styles.authorinfo}>
                  <Typography fontSize="0.65rem">
                    By honeypriya on 12 June 2021
                  </Typography>
                </div>
              </>
            ))}
          </div>
        </Grid> */}
        <Grid item xs={12} sm={12} md={6}>
          <h3>{t("top_reviews")}</h3>
          {reviewData.map((item) => (
            <ul className={styles.revilist}>
              <li>
              <div className={styles.top__reviews}>
              <div className={styles.review__user}>
                <div className={styles.uservector}>
                  <FiUser className={styles.revicon}/>
                </div>
                <p>
                {t("reviewer_name")}
                </p>
              </div>
              <div className={styles.rating__review}>
                <div className={styles.ratingflex}>
                <ul className={styles.stratinglst}>
                              <li><RiStarFill className={styles.sticon} style={{color:"#FFD51A",opacity:"1"}}/></li>
                              <li><RiStarFill className={styles.sticon}  style={{color:"#FFD51A",opacity:"1"}}/></li>
                              <li><RiStarFill className={styles.sticon}  style={{color:"#FFD51A",opacity:"1"}}/></li>
                              <li><RiStarFill className={styles.sticon} /></li>
                              <li><RiStarFill  className={styles.sticon}/></li>
                            </ul>
                </div>

                <p>
                {t("comment1")}
                </p>
              </div>
              <div>
                <p className={styles.redate}>
                {t("review_details")}
                </p>
              </div>
              <div>
                <p>
                {t("comment2")}
                </p>
              </div>
              <div className={styles.like__dislike}>
                <p >
                {t("comment3")}
                </p>
                {/* <div className={styles.reactions}>
                  <div className={styles.reac__flex}>
                    <img
                      className={styles.reacimg}
                      src={ "/img/LikeIcon.png"}
                    />
                    <Typography fontSize="0.8rem">48</Typography>
                  </div>
                  <div className={styles.reac__flex}>
                    <img
                      className={styles.reacimg}
                      src={ "/img/DislikeIcon.png"}
                    />
                    <Typography fontSize="0.8rem">08</Typography>
                  </div>
                </div> */}
              </div>
            </div>
              </li>
            </ul>
           
          ))}
        </Grid>
      </Grid>
      </Container>
    </div>
  );
}

export default ProdDetsqna;
