import React, { useEffect, useState } from "react"
import styles from "./Addauctionpopup.module.css"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import "react-quill/dist/quill.snow.css"
import ReactQuill from "react-quill"
import Typography from "@mui/material/Typography"
import {
  Autocomplete,
  Grid,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Link
} from "@mui/material"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import Uploadimage from "../../../../../curator/components/Common/uploadImage/Uploadimage"
import {
  fetchArtistData,
  handleAddBuyer,
} from "../../../../../State/api/superadminApi"
import { useDispatch, useSelector } from "react-redux"
import homeImage from "../../../../../constant"
import { VpnLockRounded } from "@mui/icons-material"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { fetchUserArtist } from "../../../../../State/api/userApi"
import { validateAdminCamp } from "../../../../../validation/validationAdminCamp"
import { validateAdminEditCamp } from "../../../../../validation/validationAdminEditCamp"
import { validateAdminCareer } from "../../../../../validation/validationAdminCareer"
import { validateAdminEditCareer } from "../../../../../validation/validationAdminEditCareer"
import { validateAdminAuction } from "../../../../../validation/validationAdminAuction"
import styled from "styled-components"
import { RiCloseCircleFill} from "react-icons/ri";

const buyerinputs = [
  {
    id: 1,
    label: "First Name",
    placeholder: "Enter first name",
    name: "first_name",
    type: "text",
  },
  {
    id: 2,
    label: "Last Name",
    placeholder: "Enter last name",
    name: "last_name",
    type: "text",
  },
  {
    id: 3,
    label: "Email",
    placeholder: "Enter email",
    name: "email",
    type: "email",
  },
  {
    id: 4,
    label: "Phone",
    placeholder: "Enter phone ",
    name: "phone",
    type: "number",
  },
  {
    id: 5,
    label: "Password",
    placeholder: "Enter password",
    name: "password",
    type: "password",
  },
]
const top100Films = [
  "Billing",
  "Account",
  "Dashboard Management",
  "Subscription",
  "Technical",
  "Exhibition",
  "Auction",
  "Events",
  "products",
]
export default function Add(props) {
  const [errors, setErrors] = useState({})
  const [AuctionErrors, setAuctionErrors] = useState({})
  const [CareerErrors, setCareerErrors] = useState({})
  const [editCamperrors, setEditCampErrors] = useState({})
  const [editCareererrors, setEditCareerErrors] = useState({})
  const [generateDisputeValues, setGenerateDisputeValues] = useState({
    title: "",
    description: "",
    images: [],
    order_id: "",
    artist_id: "",
  })
  const [radio, setRadio] = useState("")
  const [cominput, setCominput] = useState({
    name: "",
    campaign_code: "",
    discount: "",
    validity: "",
    description: "",
  })
  const [editCamp, setEditCamp] = useState(
    props.editdataCamp
      ? {
          name: props.editdataCamp.name,
          campaign_code: props.editdataCamp.campaign_code,
          discount: props.editdataCamp.discount,
          validity: props.editdataCamp.validity,
          description: props.editdataCamp.description,
        }
      : {
          name: "",
          campaign_code: "",
          discount: "",
          validity: "",
          description: "",
        }
  )
  const [carinput, setCarinput] = useState({
    title: "",
    location: "",
    experience: "",
    department: "",
    description: "",
    min_exp: "",
    max_exp: "",
    type_of_position: "",
    city: "",
    state: "",
    country: "",
    salary: "",
  })
  const [editcarinput, setEditcarinput] = useState(
    props.edit && {
      title: props.edit.title,
      location: props.edit.location,
      experience: props.edit.experience,
      department: props.edit.department,
      description: props.edit.description,
      min_exp: props.edit.min_exp,
      max_exp: props.edit.max_exp,
      type_of_position: props.edit.type_of_position,
      city: props.edit.city,
      state: props.edit.state,
      country: props.edit.country,
      salary: props.edit.salary,
    }
  )
  const [addauction, setAddauction] = useState({
    name: "",
    theme: "",
    start_date: "",
    end_date: "",
    application_end_date: "",
    description: "",
    artist_emails:'',
  })

  const [printPricing, setPrintPricing] = useState({
    material: "",
    product: "",
    price: "",
  })

  const [editPrintPricing, setEditPrintPricing] = useState({
    material: "",
    product: "",
    price: "",
  })

  useEffect(() => {
    if (props.edit) {
      setEditPrintPricing({
        material: props.edit.material,
        product: props.edit.product,
        price: props.edit.price,
      })
    }
  }, [props.edit])

  const [personName, setPersonName] = React.useState([])
  // const artistdatas = useSelector((state) => state.artistdata.data);
  const artistdatas = useSelector((state) => state.userartistData)
  console.log("artistDatats-->",artistdatas)
  // const handleOpen = () => props.setOpen(true);
  const handleClose = () => props.setOpen(false)

  const [image, setImage] = useState("")
  const [src, setSrc] = useState("")
  const dispatch = useDispatch()
  const handleChangeImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0]
      const imgs = URL.createObjectURL(e.target.files[0])
      setImage(e.target.files[0])
      setSrc(imgs)
    }
  }

  const handleChangeDispute = (e) => {
    const name = e.target.name
    const value = e.target.value
    setGenerateDisputeValues({ ...generateDisputeValues, [name]: value })
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    let validValue = value
    if (name === "name") {
      validValue = value.replace(/[^a-zA-Z\s]/g, "")
    }

    setCominput({ ...cominput, [name]: validValue })
  }
  const handleChangeEditCamp = (e) => {
    const { name, value } = e.target
    let validValue = value
    if (name === "name") {
      // Allow only alphabets and spaces for the name field
      validValue = value.replace(/[^a-zA-Z\s]/g, "")
    }
    setEditCamp({ ...editCamp, [name]: validValue })
  }
  const handleCampDate = (date) => {
    setCominput({
      ...cominput,
      validity: dayjs(date).format("YYYY-MM-DD").toString(),
    })
  }
  const handleEditCampDate = (date) => {
    setEditCamp({
      ...editCamp,
      validity: dayjs(date).format("YYYY-MM-DD").toString(),
    })
  }
  const handleChangeCareer = (e) => {
    const { name, value } = e.target
    setCarinput({ ...carinput, [name]: value })
  }
  const handleChangeEditCareer = (e) => {
    const { name, value } = e.target
    setEditcarinput({ ...editcarinput, [name]: value })
  }
  const handleChangetheme = (e) => {
    const { name, value } = e.target
    setAddauction({ ...addauction, [name]: value })
  }

  //printpricing
  const handleChangePrintpricing = (e) => {
    const { name, value } = e.target
    setPrintPricing({ ...printPricing, [name]: value })
  }

  const handleChangeEditPrintPricing = (e) => {
    const { name, value } = e.target
    setEditPrintPricing({ ...editPrintPricing, [name]: value })
  }

  const getProductOptions = (material) => {
    switch (material) {
      case "canvas":
        return [
          { value: "canvas print", label: "Canvas Print" },
          {
            value: "canvas print and stretch",
            label: "Canvas Print & Stretched",
          },
          {
            value: "canvas print with stretching and framing",
            label: "Canvas Print With Stretching & Framing",
          },
        ]
      case "fine art paper":
        return [
          { value: "fine art print", label: "Fine Art Print" },
          { value: "mounting", label: "Mounting" },
          {
            value: "fine art print with framing",
            label: "Fine Art Print With Framing",
          },
        ]
      case "photo paper":
        return [
          { value: "photo paper print", label: "Photo Paper Print" },
          { value: "mounting", label: "Mounting" },
          {
            value: "photo paper print with framing",
            label: "Photo Paper Print With Framing",
          },
        ]
      case "poster":
        return [
          { value: "poster", label: "Poster" },
          { value: "mounting", label: "Mounting" },
          { value: "poster with framing", label: "Poster With Framing" },
        ]
      case "metal print 1.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ]
      case "metal print 2.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ]
      case "metal print 3.8 mm":
        return [
          { value: "metal print", label: "Metal Print" },
          {
            value: "metal print with aluminium hanging",
            label: "Metal Print With Aluminium Hanging",
          },
        ]
      case "acrylic print 2 mm":
        return [
          { value: "acrylic printing", label: "Acrylic printing " },
          {
            value: "acrylic print with aluminium hanging",
            label: "Acrylic print with Aluminium hanging",
          },
          {
            value: "acrylic print with spacers",
            label: "Acrylic print with Spacers",
          },
        ]
      case "acrylic print 4 mm":
        return [
          { value: "acrylic printing", label: "Acrylic printing " },
          {
            value: "acrylic print with aluminium hanging",
            label: "Acrylic print with Aluminium hanging",
          },
          {
            value: "acrylic print with spacers",
            label: "Acrylic print with Spacers",
          },
        ]
      case "acrylic print 6 mm":
        return [
          { value: "acrylic printing", label: "Acrylic printing " },
          {
            value: "acrylic print with aluminium hanging",
            label: "Acrylic print with Aluminium hanging",
          },
          {
            value: "acrylic print with spacers",
            label: "Acrylic print with Spacers",
          },
        ]
      case "acrylic print 8 mm":
        return [
          { value: "acrylic printing", label: "Acrylic printing " },
          {
            value: "acrylic print with aluminium hanging",
            label: "Acrylic print with Aluminium hanging",
          },
          {
            value: "acrylic print with spacers",
            label: "Acrylic print with Spacers",
          },
        ]
      case "acrylic print 10 mm":
        return [
          { value: "acrylic printing", label: "Acrylic printing " },
          {
            value: "acrylic print with aluminium hanging",
            label: "Acrylic print with Aluminium hanging",
          },
          {
            value: "acrylic print with spacers",
            label: "Acrylic print with Spacers",
          },
        ]
      case "acrylic sandwich":
        return [
          { value: "acrylic sandwich 4+2 mm", label: "Acrylic Sandwich 4+2 mm " },
          {
            value: "acrylic sandwich 6+2 mm",
            label: "Acrylic Sandwich 6+2 mm",
          },
          
        ]

      default:
        return []
    }
  }
  const handleSelectArtist = (e) => {
    const vl = e.target.value
    vl.forEach((element) => {
      var invite_artists = [...addauction.invite_artists]
      const dt = addauction.invite_artists.map((val) => val._id)
      const index = dt.indexOf(element)
      if (index === -1) {
        invite_artists.push({ _id: element })
        setAddauction({ ...addauction, invite_artists: invite_artists })
      } else {
        invite_artists.slice(index, 1)
        setAddauction({ ...addauction, invite_artists: invite_artists })
      }
    })
  }
  const handleStartDate = (start) => {
    setAddauction({
      ...addauction,
      start_date: dayjs(start).format("YYYY-MM-DD").toString(),
    })
  }
  const handleEndDate = (end) => {
    setAddauction({
      ...addauction,
      end_date: dayjs(end).format("YYYY-MM-DD").toString(),
    })
  }
  const handleEndAppDate = (endApp) => {
    setAddauction({
      ...addauction,
      application_end_date: dayjs(endApp).format("YYYY-MM-DD").toString(),
    })
  }
  // const handleChanges = (event) => {
  //     const {
  //       target: { value },
  //     } = event;
  //     setAuction(
  //       // On autofill we get a stringified value.
  //       typeof value === 'string' ? value.split(',') : value,
  //     );
  //   };
  useEffect(() => {
    // dispatch(fetchArtistData());
    dispatch(fetchUserArtist({}))
  }, [])
  //campaingn
  const handleCampSubmit = () => {
    const { campErrors, isValid } = validateAdminCamp(cominput)

    if (!isValid) {
      setErrors(campErrors)
    } else {
      setErrors({})
      props.handleAddCompaingn(cominput)
    }
  }

  const handleEditCampSubmit = () => {
    const { campEditErrors, isValid } = validateAdminEditCamp(editCamp)
    if (!isValid) {
      setEditCampErrors(campEditErrors)
    } else {
      setEditCampErrors({})
      props.handleEdit(props.editdataCamp._id, editCamp)
    }
  }
  const handleCareerSubmit = () => {
    const { careerErrors, isValid } = validateAdminCareer(carinput)
    if (!isValid) {
      setCareerErrors(careerErrors)
    } else {
      setCareerErrors({})
      props.handleAddcareer(carinput)
    }
  }

  const handleEditCarrerSubmit = () => {
    const { careerEditErrors, isValid } = validateAdminEditCareer(editcarinput)

    if (!isValid) {
      setEditCareerErrors(careerEditErrors)
    } else {
      setEditCareerErrors({})
      props.handleEditcareer(props.edit._id, editcarinput)
    }
  }

  const handleAuctionSubmit = () => {
    const { aucErrors, isValid } = validateAdminAuction(addauction)

    if (!isValid) {
      setAuctionErrors(aucErrors)
    } else {
      setAuctionErrors({})
      props.handleSubmitAuction(addauction)
    }
  }

  return (
    <div>
      {props.poptype === "auction" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_exhibition}>
            <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
                 Add Auction
              </p>
              
              
              <div className="form-group">
                <label>Auction Name <span style={{ color: "red" }}>*</span></label>
              
                <input
                  className="form-control"
                  placeholder="Enter Name"
                  name="name"
                  value={addauction.name}
                  type="text"
                  onChange={handleChangetheme}
                />
                {AuctionErrors?.name && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {AuctionErrors?.name}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Auction Theme <span style={{ color: "red" }}>*</span></label>
                <input
                  className="form-control"
                  placeholder="Enter Theme"
                  name="theme"
                  value={addauction.theme}
                  type="text"
                  onChange={handleChangetheme}
                />
                {AuctionErrors?.theme && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {AuctionErrors?.theme}
                  </span>
                )}
                {/* <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={addauction.theme}
                  fullWidth
                  //  multiple
                  sx={{
                    background: "#f6f6f6",
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    "& .MuiInputBase-root": {
                      borderRadius: "6px",
                    },
                    height: "40px",
                  }}
                  placeholder="select type"
                  onChange={handleChangetheme}
                  name="theme"
                >
                  {top100Films &&
                    top100Films.map((val) => (
                      <MenuItem value={val}>{val}</MenuItem>
                    ))}
                </Select> */}
              </div>
              
             <Grid
                container
                direction="row"
                justifyContent="space-between"
                sx={{ display: "flex", justifyContent: "space-between" }}
                alignItems="center"
                item
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                colgap={1.5}
                columns={{ xs: 12, sm: 12, md: 22 }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={11}
                >
                  <div className="form-group">
                  <label>Start Date <span style={{ color: "red" }}>*</span></label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disablePast
                      inputFormat="DD-MM-YYYY"
                      value={addauction.start_date}
                      onChange={handleStartDate}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "& .MuiInputBase-input":{
                              padding:".45rem .75rem",
                              height:"auto",
                              borderRadius:".25rem",
                              fontSize:"16px",
                              fontWeight:"400"
                            },
                            border:"1px solid #ced4da",
                            borderRadius:".25rem",
                            width:"100%"
                          }}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Enter date",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {AuctionErrors?.start_date && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {AuctionErrors?.start_date}
                    </span>
                  )}
                  </div>
                  
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={11}
                   >
                    <div className="form-group">
                    <label>End Date <span style={{ color: "red" }}>*</span></label>
                                   <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat="DD-MM-YYYY"
                      value={addauction.end_date}
                      disabled={addauction.start_date === ""}
                      onChange={handleEndDate}
                      minDate={addauction.start_date}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },"& .MuiInputBase-input":{
                              padding:".45rem .75rem",
                              height:"auto",
                              borderRadius:".25rem",
                              fontSize:"16px",
                              fontWeight:"400"
                            },
                            border:"1px solid #ced4da",
                            borderRadius:".25rem",
                            width:"100%"
                          }}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Enter date",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {AuctionErrors?.end_date && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {AuctionErrors?.end_date}
                    </span>
                  )}
                  {/* <TextField
                        sx={{
                            backgroundColor: "#f6f6f6",
                            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                            borderRadius: "6px",
                        }}
                        size="small"
                        fullWidth
                        placeholder={`Enter End Date `}
                        value={addauction.end_date}
                        type="text"
                        name="end_date"
                        onChange={handleChangetheme}
                    /> */}
                    </div>
                  
                </Grid>
              </Grid> 
              
              <div className="form-group">
                <label>End Date Receving Application <span style={{ color: "red" }}>*</span></label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disablePast
                    inputFormat="DD-MM-YYYY"
                    minDate={addauction.start_date}
                    maxDate={addauction.end_date}
                    value={addauction.application_end_date}
                    onChange={handleEndAppDate}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },"& .MuiInputBase-input":{
                              padding:".375rem .75rem",
                              height:"auto",
                              borderRadius:".25rem",
                              fontSize:"16px",
                              fontWeight:"400"
                            }, 
                            border:"1px solid #ced4da",
                            borderRadius:".25rem",
                            width:"100%"
                        }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "Enter date",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                {AuctionErrors?.application_end_date && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {AuctionErrors?.application_end_date}
                  </span>
                )}

            <div className="form-group">
              {/* <FormControl fullWidth>
        <label id="select-mutli-artist">Invite Artist</label>
        {/* <FormControl sx={{ background: '#f6f6f6', height: '40px', }} fullWidth>
                                <InputLabel id="demo-multiple-name-label">Name</InputLabel> */}
              {/* <input onChange={handleChange} name='artist_emails' value={addData.artist_emails} placeholder='Enter Email id' type='email' /> */}
              <label>
                Invite Artist<span style={{ color: "red" }}>*</span>
              </label>
              <input
                className="form-control"
                onChange={handleChange}
                name="artist_emails"
                value={addauction.artist_emails}
                placeholder="Enter Email"
                type="text"
              />
              
              {AuctionErrors?.artist_emails && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {AuctionErrors?.artist_emails}
                  </span>
                )}
            </div>
                {/* <TextField
                    sx={{
                        backgroundColor: "#f6f6f6",
                        "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                        borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder="Enter Amount"
                    type="text"
                    name="application_end_date"
                    value={addauction.application_end_date}
                    onChange={handleChangetheme}
                /> */}
              </div>
              {/* <div className="form-group">
                <label className={styles.search_label}>
                  Invite Specific Artist
                </label>
                <Select
                  labelId="select-mutli-artist"
                  id="demo-select-artist"
                  value={
                    addauction.invite_artists.length === 0
                      ? []
                      : addauction.invite_artists.map((val) => val._id)
                  }
                  fullWidth
                  multiple
                  sx={{
                   
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    "& .MuiInputBase-root": {
                      borderRadius: "6px",
                      
                    }, 
                    "& .MuiSelect-multiple":{
                      padding:".375rem .75rem",
                    },
                    border:"1px solid #ced4da",
                    borderRadius:".25rem", 
                  }}
                  placeholder="select type"
                  onChange={handleSelectArtist}
                  name="invite_artists"
                   // input={<OutlinedInput label="Aritst Name" />}
                  // MenuProps={MenuProps}
                >
                  {artistdatas &&
                    artistdatas?.data 
                      ?.filter((fl) => {
                        return fl.is_accepted === "Approved"
                      })
                      ?.map((val) => (
                        <MenuItem value={val.user_id} key={val.user_id}>
                          {val.artist_name}
                        </MenuItem>
                      ))}
                  {/* {artistdatas &&
                    artistdatas.data
                      .filter((fl) => {
                        return fl.is_accepted === "Approved";
                      })
                      .map((val) => (
                        <MenuItem value={val._id} key={val._id}>
                          {val.first_name} {val.last_name}
                        </MenuItem>
                      ))} */}
                {/* </Select>
              </div> */}
              <div className="form-group">
                <label>Description <span style={{ color: "red" }}>*</span></label>
                <textarea
                className="form-control"
                  placeholder="Enter description"
                  name="description"
                  value={addauction.description}
                  onChange={handleChangetheme}
                  fullWidth
                  minRows={5}
                />
                {AuctionErrors?.description && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {AuctionErrors?.description}
                  </span>
                )}
              </div>
              <div className={styles.action}>
                <button
                  variant="contained"
                  className="action_btn"
                  onClick={handleAuctionSubmit}
                >
                  Save
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "campaigns" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_campaigns}>
            <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
                 Add Campaigns
              </p>
               
              <div className="form-group">
                <label>Campaigns Name <span className={styles.asterisk}>*</span></label>
                <input
                  onChange={handleChange}
                 className="form-control"
                  placeholder="Enter Name"
                  type="text"
                  name="name"
                  value={cominput.name}
                />
                {errors?.name && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {errors?.name}
                  </span>
                )}
              </div>
              <Grid
                                container
                                columnSpacing={2}
                              >
                                <Grid item xs={12} sm={6} md={6}>
                                <div className="form-group">
                <label>Campaigns Code <span className={styles.asterisk}>*</span></label>
                
                <input
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Enter Code"
                  type="text"
                  name="campaign_code"
                  value={cominput.campaign_code}
                />
                {errors?.campaign_code && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {errors?.campaign_code}
                  </span>
                )}
              </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                <div className="form-group">
                  <label>Discount(%) <span className={styles.asterisk}>*</span></label>
                  <input
                  className="form-control"
                    onChange={handleChange}
                    
                    placeholder={"Enter discount"}
                    name="discount"
                    value={cominput.discount}
                    type="number"
                  />
                  {errors?.discount && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {errors?.discount}
                    </span>
                  )}
                  </div>
                                </Grid>
                                </Grid>
              
              
                  <div className="form-group">
                  <label>Validity <span className={styles.asterisk}>*</span></label>
                  {/* <TextField
                    onChange={handleChange}
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder={"Enter validity"}
                    name="validity"
                    type="text"
                    value={cominput.validity}
                  /> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disablePast
                      inputFormat="DD-MM-YYYY"
                      value={cominput.validity}
                      onChange={handleCampDate}
                      renderInput={(params) => (
                        <TextField
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input":{
                            padding:".45rem .75rem",
                            height:"auto",
                            borderRadius:".25rem",
                            fontSize:"16px",
                            fontWeight:"400"
                          },
                          border:"1px solid #ced4da",
                          borderRadius:".25rem",
                          width:"100%"
                        }}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Enter date",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {errors?.validity && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {errors?.validity}
                    </span>
                  )}
                  </div>
              <div className="form-group">
              <label className={styles.textarea_label}>Description <span className={styles.asterisk}>*</span></label>
                <ReactQuill
                  theme="snow"
                  placeholder={`Enter description`}
                  className={`${styles.textarea_blog}  ${styles.customEditor}`}
                  value={cominput.description}
                  onChange={(content) =>{
                    handleChange({
                      target: { name: "description", value: content },
                    })
                   }}
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                />
                {errors?.description && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginTop:"70px",
                      position:"absolute"
                    }}
                  >
                    {errors?.description}
                  </span>
                )}
              </div>
              <div className={styles.action_career}>
                <button
                  variant="contained"
                  className="action_btn"
                  onClick={handleCampSubmit}
                >
                  Save
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "editCamp" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_campaigns}>
            <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
                 Edit Campaigns
                 
              </p>
              
              <div className="form-group">
                <label>Campaigns Name <span className={styles.asterisk}>*</span></label>
                <input
                 onChange={handleChangeEditCamp}
                className="form-control"
                  placeholder="Enter Name"
                  type="text"
                  name="name"
                  value={editCamp.name}
                />
                {editCamperrors?.name && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {editCamperrors?.name}
                  </span>
                )}
              </div>
              <Grid
                                container
                                columnSpacing={2}
                              >
                                <Grid item xs={12} sm={6} md={6}>
									<div className="form-group">
                  <label>Campaigns Code <span className={styles.asterisk}>*</span></label>
                <input
                 onChange={handleChangeEditCamp}
                className="form-control"
                  placeholder="Enter Code"
                  type="text"
                  name="campaign_code"
                  value={editCamp.campaign_code}
                />
                {editCamperrors?.campaign_code && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {editCamperrors?.campaign_code}
                  </span>
                )}
                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                <div className="form-group">
              <label>Discount(%) <span className={styles.asterisk}>*</span></label>
                  <input
                    onChange={handleChangeEditCamp}
                    className="form-control"
                    placeholder={"Enter discount"}
                    name="discount"
                    value={editCamp.discount}
                    type="number"
                  />
                  {editCamperrors?.discount && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {editCamperrors?.discount}
                    </span>
                  )}
              </div>
                                </Grid>
                                </Grid>
             
              
              <div className="form-group">
              <label>Validity <span className={styles.asterisk}>*</span></label>
                  {/* <TextField
                    onChange={handleChange}
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder={"Enter validity"}
                    name="validity"
                    type="text"
                    value={cominput.validity}
                  /> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disablePast
                      inputFormat="DD-MM-YYYY"
                      value={editCamp.validity}
                      onChange={handleEditCampDate}
                      renderInput={(params) => (
                        <TextField
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input":{
                            padding:".45rem .75rem",
                            height:"auto",
                            borderRadius:".25rem",
                            fontSize:"16px",
                            fontWeight:"400"
                          },
                          border:"1px solid #ced4da",
                          borderRadius:".25rem",
                          width:"100%"
                        }}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Enter date",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {editCamperrors?.validity && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {editCamperrors?.validity}
                    </span>
                  )}
              </div>
              <div className="form-group">
              <label className={styles.textarea_label}>Description  <span className={styles.asterisk}>*</span></label>
                <ReactQuill
                  theme="snow"
                  placeholder={`Enter description`}
                  className={`${styles.textarea_blog}  ${styles.customEditor}`}
                  value={editCamp.description}
                  onChange={(content) =>
                    handleChangeEditCamp({
                      target: { name: "description", value: content },
                    })
                  }
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                />
                {editCamperrors?.description && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                       marginTop:"70px",
                      position:"absolute"
                    }}
                  >
                    {editCamperrors?.description}
                  </span>
                )}
              </div>
              <div className={styles.action_career}>
                <button
                  variant="contained"
                  className="action_btn"
                  onClick={handleEditCampSubmit}
                >
                  Save
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "printpricing" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_campaigns}>
            <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  Add Print Pricing
              </p>
              <div className="form-group">
              <label>Material <span style={{ color: "red" }}>*</span></label>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={printPricing.material}
                    label="Material"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input":{
                        padding:".45rem .75rem",
                        height:"auto",
                        borderRadius:".25rem",
                        fontSize:"16px",
                        fontWeight:"400"
                      },
                      border:"1px solid #ced4da",
                      borderRadius:".25rem",
                      width:"100%"
                    }}
                    placeholder="select type"
                    onChange={handleChangePrintpricing}
                    name="material"
                  >
                    <MenuItem value="canvas">Canvas</MenuItem>
                    <MenuItem value="fine art paper">Fine Art Paper</MenuItem>
                    <MenuItem value="photo paper">Photo Paper</MenuItem>
                    <MenuItem value="poster">Poster </MenuItem>
                    <MenuItem value="metal print 1.8 mm">
                      Metal print 1.8 mm
                    </MenuItem>
                    <MenuItem value="metal print 2.8 mm">
                      Metal print 2.8 mm
                    </MenuItem>
                    <MenuItem value="metal print 3.8 mm">
                      Metal print 3.8 mm
                    </MenuItem>
                    <MenuItem value="acrylic print 2 mm">
                    Acrylic print 2 mm
                    </MenuItem>
                    <MenuItem value="acrylic print 4 mm">
                    Acrylic print 4 mm
                    </MenuItem>
                    <MenuItem value="acrylic print 6 mm">
                    Acrylic print 6 mm
                    </MenuItem>
                    <MenuItem value="acrylic print 8 mm">
                    Acrylic print 8 mm
                    </MenuItem>
                    <MenuItem value="acrylic print 10 mm">
                    Acrylic print 10 mm
                    </MenuItem>
                    {/* <MenuItem value="acrylic">
                      Acrylic 
                    </MenuItem> */}
                    <MenuItem value="acrylic sandwich">
                      Acrylic Sandwich
                    </MenuItem>
                  </Select>
                  {props.errorPrintPricing?.material && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {props.errorPrintPricing?.material}
                  </span>
                )}
              </div>
              <div className="form-group">
              <label>Product <span style={{ color: "red" }}>*</span></label>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={printPricing.product}
                    label="Product"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input":{
                        padding:".45rem .75rem",
                        height:"auto",
                        borderRadius:".25rem",
                        fontSize:"16px",
                        fontWeight:"400"
                      },
                      border:"1px solid #ced4da",
                      borderRadius:".25rem",
                      width:"100%"
                    }}
                    placeholder="Select product"
                    onChange={handleChangePrintpricing}
                    name="product"
                  >
                    {getProductOptions(printPricing.material).map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {props.errorPrintPricing?.product && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {props.errorPrintPricing?.product}
                  </span>
                )}
              </div>
              <div className="form-group">
              <label>Price <span style={{ color: "red" }}>*</span></label>
                <input
                className="form-control"
                  placeholder="Enter Price In USD"
                  type="text"
                  name="price"
                  value={printPricing.price}
                  onChange={handleChangePrintpricing}
                />
                {props.errorPrintPricing?.price && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {props.errorPrintPricing?.price}
                  </span>
                )}
              </div>
              <div className={styles.actionc}>
                <button
                  className="action_btn"
                  onClick={() => props.handleAddPrintPricing(printPricing)}
                >
                  Save
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "printpricing_edit" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_campaigns}>
            <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  Edit Print Pricing
              </p>
              <div className="form-group">
              <label>Material <span style={{ color: "red" }}>*</span></label>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={editPrintPricing.material}
                    label="Material"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input":{
                        padding:".45rem .75rem",
                        height:"auto",
                        borderRadius:".25rem",
                        fontSize:"16px",
                        fontWeight:"400"
                      },
                      border:"1px solid #ced4da",
                      borderRadius:".25rem",
                      width:"100%"
                    }}
                    placeholder="select type"
                    onChange={handleChangeEditPrintPricing}
                    name="material"
                  >
                    <MenuItem value="canvas">Canvas</MenuItem>
                    <MenuItem value="fine art paper">Fine Art Paper</MenuItem>
                    <MenuItem value="photo paper">Photo Paper</MenuItem>
                    <MenuItem value="poster">Poster </MenuItem>
                    <MenuItem value="metal print 1.8 mm">
                      Metal print 1.8 mm
                    </MenuItem>
                    <MenuItem value="metal print 2.8 mm">
                      Metal print 2.8 mm
                    </MenuItem>
                    <MenuItem value="metal print 3.8 mm">
                      Metal print 3.8 mm
                    </MenuItem>
                    <MenuItem value="metal print 2 mm">
                      Metal print 2 mm
                    </MenuItem>
                    <MenuItem value="metal print 4 mm">
                      Metal print 4 mm
                    </MenuItem>
                    <MenuItem value="metal print 6 mm">
                      Metal print 6 mm
                    </MenuItem>
                    <MenuItem value="metal print 8 mm">
                      Metal print 8 mm
                    </MenuItem>
                    <MenuItem value="metal print 10 mm">
                      Metal print 10 mm
                    </MenuItem>
                    <MenuItem value="acrylic sandwich">
                      Acrylic Sandwich
                    </MenuItem>
                  </Select>
                  {props?.errorPrintPricing?.material && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {props?.errorPrintPricing?.material}
                  </span>
                )}
              </div>
              <div className="form-group">
              <label>Product <span style={{ color: "red" }}>*</span></label>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={editPrintPricing.product}
                    label="Product"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input":{
                        padding:".45rem .75rem",
                        height:"auto",
                        borderRadius:".25rem",
                        fontSize:"16px",
                        fontWeight:"400"
                      },
                      border:"1px solid #ced4da",
                      borderRadius:".25rem",
                      width:"100%"
                    }}
                    placeholder="Select product"
                    onChange={handleChangeEditPrintPricing}
                    name="product"
                  >
                    {getProductOptions(editPrintPricing.material).map(
                      (option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  {props?.errorPrintPricing?.product && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {props?.errorPrintPricing?.product}
                  </span>
                )}
              </div>
              <div className="form-group">
              <label>Price <span style={{ color: "red" }}>*</span></label>
                <input
                  className="form-control"
                  placeholder="Enter Price"
                  type="text"
                  name="price"
                  value={editPrintPricing.price}
                  onChange={handleChangeEditPrintPricing}
                />
                 {props?.errorPrintPricing?.price && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {props?.errorPrintPricing?.price}
                  </span>
                )}
              </div>
              
              <div className={styles.actionc}>
                <button
                  variant="contained"
                  className="action_btn"
                  onClick={() =>
                    props.handleEditPrintPricing(
                      props.edit._id,
                      editPrintPricing
                    )
                  }
                >
                  Update
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "buyer" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_campaigns}>
              <div className={styles.cancel_img}>
                <img
                  onClick={handleClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Add Buyer
              </p>
              {/* <div className={styles.input_title}>
                                    <label>First Name</label><br />
                                    <TextField sx={{ mt: 0.5, backgroundColor: '#f6f6f6', "& .MuiOutlinedInput-notchedOutline": { border: "none" }, borderRadius: '6px' }} size='small' fullWidth placeholder='Enter First name' type='text' />
                                </div> */}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                sx={{
                  my: 1.5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
                alignItems="center"
                item
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                colgap={1.5}
                columns={{ xs: 12, sm: 12, md: 22 }}
              >
                {buyerinputs.map((val) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={11}
                    key={val.id}
                    className={styles.inputs_gird}
                  >
                    <label>{val.label}</label>
                    <br />
                    <TextField
                      onChange={props.handleChange}
                      name={val.name}
                      sx={{
                        mt: 0.5,
                        mb: 1,
                        backgroundColor: "#f6f6f6",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        borderRadius: "6px",
                      }}
                      size="small"
                      fullWidth
                      placeholder={val.placeholder}
                      type={val.type}
                    />
                  </Grid>
                ))}
              </Grid>
              <div className={styles.textarea_div}>
                <label className={styles.textarea_label}>Description</label>
                <br />
                <TextareaAutosize
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    "&&:after": {
                      borderBottom: "none",
                    },
                  }}
                  placeholder="Enter description"
                  className={styles.textarea}
                  fullWidth
                  minRows={5}
                />
              </div>
              <div className={styles.action}>
                <Button
                  variant="contained"
                  onClick={() => props.handleAddnew()}
                  className="action_btn"
                >
                  Add
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "gallery_dispute" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_exhibition}>
              <div className={styles.cancel_img}>
                <img
                  onClick={handleClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Generate Dispute
              </p>
              <div className={styles.input_title}>
                <label>Title</label>
                <br />
                <TextField
                  sx={{
                    mt: 0.5,
                    backgroundColor: "#f6f6f6",
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    borderRadius: "6px",
                  }}
                  size="small"
                  fullWidth
                  placeholder="Enter Title"
                  type="text"
                  name="title"
                  value={generateDisputeValues.title}
                  onChange={handleChangeDispute}
                  inputProps={{
                    style: {
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      lineHeight: "21px",
                    },
                  }}
                />
              </div>
              <div className={styles.input_title}>
                <label className={styles.search_label}>Select Reason</label>
                <Autocomplete
                  // disablePortal
                  id="combo-box-demo"
                  disableClearable
                  size="small"
                  options={top100Films}
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    color: "#636365",
                    lineHeight: "21px",
                    mt: 0.5,
                    backgroundColor: "#F6F6F6",
                    border: "none",
                    borderRadius: "6px",
                  }}
                  className={styles.search_select}
                  fullwidth
                  // value={generateDisputeValues.description}
                  onChange={handleChangeDispute}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        backgroundColor: "#f6f6f6",
                        borderRadius: "6px",
                        color: "#636365",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiInputBase-root": {
                          // height: 40
                        },
                      }}
                      {...params}
                      fullwidth
                      placeholder="Select Theme"
                    />
                  )}
                />
              </div>
              <div className={styles.textarea_div}>
                <label className={styles.lebless}>Description</label>
                <br />
                <TextareaAutosize
                  maxRows={5}
                  minRows={5}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  }}
                  className={styles.textarea}
                  fullWidth
                  name="description"
                  placeholder="Enter Description"
                  value={generateDisputeValues.description}
                  onChange={handleChangeDispute}
                />
              </div>
              <div className={styles.img_main}>
                <label className={styles.lebless}>Upload Proof</label>
                <br />

                <div className={styles.preview_img}>
                  {src === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        onClick={handleChangeImage}
                      >
                        <input
                          onChange={handleChangeImage}
                          type="file"
                          id="input"
                          hidden
                        />
                        <label htmlFor="input" className={styles.label}>
                          <div className={styles.image_div}>
                            <img
                              src={"/img/uploadimg.png"}
                              width="100%"
                              height="100%"
                              alt="img"
                            />
                          </div>
                          <div className={styles.img_text}>
                            <p>Upload Your Proof</p>
                          </div>
                          {/* <p>  {props.val.placeholder}
                            </p> */}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        onChange={handleChangeImage}
                        type="file"
                        id="input"
                        hidden
                      />
                      <label htmlFor="input">
                        <img
                          src={src}
                          alt="img"
                          id="img"
                          className={
                            image ? styles.img_view : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                  <div className={styles.file_div}></div>
                </div>
              </div>
              <div className={styles.action}>
                <Button
                  variant="contained"
                  className="action_btn"
                  sx={{ borderRadius: "10px" }}
                >
                  Submit
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "career_edit" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_campaigns}>
            <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  Edit Career
              </p>
               
              <div className="form-group">
                <label>Title <span className={styles.asterisk}>*</span></label>
                <input
                className="form-control"
                  placeholder="Enter Title"
                  type="text"
                  name="title"
                  value={editcarinput.title}
                  onChange={handleChangeEditCareer}
                />
                {editCareererrors?.title && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {editCareererrors?.title}
                  </span>
                )}
              </div>
              <div className="form-group">
              <label>Location <span className={styles.asterisk}>*</span></label>
                  <input
                    className="form-control"
                    placeholder={`Enter location`}
                    type="text"
                    onChange={handleChangeEditCareer}
                    name="location"
                    value={editcarinput.location}
                  />
                  {editCareererrors?.location && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {editCareererrors?.location}
                    </span>
                  )}
              </div>
              <div className="form-group">
              <label>Type Of Position <span className={styles.asterisk}>*</span></label>
                  {/* <TextField
                    sx={{
                      mt: 1,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder={`Enter experience`}
                    type="number"
                    onChange={handleChangeCareer}
                    name="experience"
                    value={carinput.experience}
                  /> */}
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={editcarinput.type_of_position}
                    label="Type of position"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input":{
                        padding:".45rem .75rem",
                        height:"auto",
                        borderRadius:".25rem",
                        fontSize:"16px",
                        fontWeight:"400"
                      },
                      border:"1px solid #ced4da",
                      borderRadius:".25rem",
                      width:"100%"
                    }}
                    placeholder="select type"
                    onChange={handleChangeEditCareer}
                    name="type_of_position"
                    // name="blog_type"
                  >
                    <MenuItem value="Offsite">Offsite</MenuItem>
                    <MenuItem value="Onsite">Onsite</MenuItem>
                  </Select>
                  {editCareererrors?.type_of_position && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {editCareererrors?.type_of_position}
                    </span>
                  )}
              </div>
              <Grid
                container columnSpacing={2}>
                  <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}>
                     <div className="form-group">
                     <label>Min Experience <span className={styles.asterisk}>*</span></label>
                    <input
                      className="form-control"
                      placeholder={`Enter min exp`}
                      type='number'
                      onChange={handleChangeEditCareer}
                      name="min_exp"
                      value={editcarinput.min_exp}
                    />
                    {editCareererrors?.min_exp && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {editCareererrors?.min_exp}
                      </span>
                    )}
                     </div>
                         </Grid>
                         <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}>
                     <div className="form-group">
                     <label>Max Experience (yr) <span className={styles.asterisk}>*</span></label>
                    <input
                      className="form-control"
                      placeholder={`Enter Max exp`}
                      type='number'
                      onChange={handleChangeEditCareer}
                      name="max_exp"
                      value={editcarinput.max_exp}
                    />
                    {editCareererrors?.max_exp && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {editCareererrors?.max_exp}
                      </span>
                    )}
                     </div>
                         </Grid>
                         <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}>
                     <div className="form-group">
                     <label>Salary <span className={styles.asterisk}>*</span></label>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={editcarinput.salary}
                      label="Salary"
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiInputBase-input":{
                          padding:".45rem .75rem",
                          height:"auto",
                          borderRadius:".25rem",
                          fontSize:"16px",
                          fontWeight:"400"
                        },
                        border:"1px solid #ced4da",
                        borderRadius:".25rem",
                        width:"100%"
                      }}
                      placeholder="Select Salary"
                      onChange={handleChangeEditCareer}
                      name="salary"
                      // name="blog_type"
                    >
                      <MenuItem value="$500 to $1000">$500 to $1000</MenuItem>
                      <MenuItem value="$1000 to $2000">$1000 to $2000</MenuItem>
                      <MenuItem value="$2000 to $5000">$2000 to $5000</MenuItem>
                      <MenuItem value="$5000 to $10000">
                        $5000 to $10000
                      </MenuItem>
                    </Select>
                    {editCareererrors?.salary && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {editCareererrors?.salary}
                      </span>
                    )}
                     </div>
                         </Grid>
                         <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}>
                     <div className="form-group">
                     <label>City <span className={styles.asterisk}>*</span></label>
                    <input
                     className="form-control"
                      placeholder={`Enter City`}
                      type="text"
                      onChange={handleChangeEditCareer}
                      name="city"
                      value={editcarinput.city}
                    />
                    {editCareererrors?.city && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {editCareererrors?.city}
                      </span>
                    )}
                     </div>
                         </Grid>
                         <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}>
                     <div className="form-group">
                     <label>State <span className={styles.asterisk}>*</span></label>
                    <input
                      className="form-control"
                      placeholder={`Enter State`}
                      type="text"
                      onChange={handleChangeEditCareer}
                      name="state"
                      value={editcarinput.state}
                    />
                    {editCareererrors?.state && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {editCareererrors?.state}
                      </span>
                    )}
                     </div>
                         </Grid>
                         <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}>
                     <div className="form-group">
                     <label>Country <span className={styles.asterisk}>*</span></label>
                    <input
                      className="form-control"
                      placeholder={`Enter Country`}
                      type="text"
                      onChange={handleChangeEditCareer}
                      name="country"
                      value={editcarinput.country}
                    />
                    {editCareererrors?.country && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {editCareererrors?.country}
                      </span>
                    )}
                     </div>
                         </Grid>
                </Grid>
              <div className="form-group">
              <label>Department <span className={styles.asterisk}>*</span></label>
                <input
                className="form-control"
                  placeholder="Enter de[partment"
                  type="text"
                  onChange={handleChangeEditCareer}
                  name="department"
                  value={editcarinput.department}
                />
                {editCareererrors?.department && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {editCareererrors?.department}
                  </span>
                )}
              </div>
              <div className="form-group">
              <label className={styles.textarea_label}>Description <span className={styles.asterisk}>*</span></label>
                <ReactQuill
                  theme="snow"
                  placeholder={`Enter description`}
                  className={`${styles.textarea_blog}  ${styles.customEditor}`}
                  value={editcarinput.description}
                  onChange={(content) =>
                    handleChangeEditCareer({
                      target: { name: "description", value: content },
                    })
                  }
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                />
                {editCareererrors?.description && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginTop:"70px",
                      position:"absolute"
                    }}
                  >
                    {editCareererrors?.description}
                  </span>
                )}
              </div>
               
              <div className={styles.action_career}>
                <button
                  variant="contained"
                  onClick={handleEditCarrerSubmit}
                  className="action_btn"
                >
                  Add
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "curator" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_campaigns}>
              <div className={styles.cancel_img}>
                <img
                  onClick={handleClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                {window.location.pathname === "/affiliate"
                  ? "Add Affiliate"
                  : window.location.pathname === "/gallerys"
                  ? "Add Gallery"
                  : "Add Curator"}
              </p>
              {/* <div className={styles.input_title}>
                                    <label>First Name</label><br />
                                    <TextField sx={{ mt: 0.5, backgroundColor: '#f6f6f6', "& .MuiOutlinedInput-notchedOutline": { border: "none" }, borderRadius: '6px' }} size='small' fullWidth placeholder='Enter First name' type='text' />
                                </div> */}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                sx={{
                  my: 1.5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
                alignItems="center"
                item
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                colgap={1.5}
                columns={{ xs: 12, sm: 12, md: 22 }}
              >
                {buyerinputs.map((val) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={11}
                    key={val.id}
                    className={styles.inputs_gird}
                  >
                    <label>{val.label}</label>
                    <br />
                    <TextField
                      onChange={props.handleChange}
                      name={val.name}
                      sx={{
                        mt: 0.5,
                        mb: 1,
                        backgroundColor: "#f6f6f6",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        borderRadius: "6px",
                      }}
                      size="small"
                      fullWidth
                      placeholder={val.placeholder}
                      type={val.type}
                    />
                  </Grid>
                ))}
              </Grid>
              {/* <div className={styles.textarea_div}>
        <label className={styles.textarea_label}>Description</label>
        <br />
        <TextareaAutosize
         sx={{
          "& .MuiOutlinedInput-notchedOutline": { border: "none" },
          "&&:after": {
           borderBottom: "none",
          },
         }}
         placeholder="Enter description"
         className={styles.textarea}
         fullWidth
         minRows={5}
        />
       </div> */}
              <div className={styles.action}>
                <Button
                  variant="contained"
                  onClick={() => props.handleAddnew()}
                  className="action_btn"
                >
                  Add
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_campaigns}>
            <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  Add Career
              </p>
              
              <div className="form-group">
                <label>Title <span className={styles.asterisk}>*</span></label>
                <input
                  className="form-control"
                  placeholder="Enter Title"
                  type="text"
                  name="title"
                  value={carinput.title}
                  onChange={handleChangeCareer}
                />
                {CareerErrors?.title && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {CareerErrors?.title}
                  </span>
                )}
              </div>
              <div className="form-group">
              <label>Location <span className={styles.asterisk}>*</span></label>
                  <input
                    className="form-control"
                    placeholder={`Enter location`}
                    type="text"
                    onChange={handleChangeCareer}
                    name="location"
                    value={carinput.location}
                  />
                  {CareerErrors?.location && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {CareerErrors?.location}
                    </span>
                  )}
              </div>
              <div className="form-group">
              <label>Type Of Position <span className={styles.asterisk}>*</span></label>
                  {/* <TextField
                    sx={{
                      mt: 1,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder={`Enter experience`}
                    type="number"
                    onChange={handleChangeCareer}
                    name="experience"
                    value={carinput.experience}
                  /> */}
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={carinput.type_of_position}
                    label="Type of position"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input":{
                        padding:".45rem .75rem",
                        height:"auto",
                        borderRadius:".25rem",
                        fontSize:"16px",
                        fontWeight:"400"
                      },
                      border:"1px solid #ced4da",
                      borderRadius:".25rem",
                      width:"100%"
                    }}
                    placeholder="select type"
                    onChange={handleChangeCareer}
                    name="type_of_position"
                    // name="blog_type"
                  >
                    <MenuItem value="Offsite">Offsite</MenuItem>
                    <MenuItem value="Onsite">Onsite</MenuItem>
                  </Select>
                  {CareerErrors?.type_of_position && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {CareerErrors?.type_of_position}
                    </span>
                  )}
              </div>
              <Grid
                container 
                columnSpacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <div className="form-group">
                  <label>Min Experience <span className={styles.asterisk}>*</span></label>
                  <input
                    className="form-control"
                    placeholder={`Enter min exp`}
                    type='number'
                    onChange={handleChangeCareer}
                    name="min_exp"
                    value={carinput.min_exp}
                  />
                  {CareerErrors?.min_exp && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {CareerErrors?.min_exp}
                    </span>
                  )}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6} 
                >
                  <div className="form-group">
                  <label>Max Experience (yr) <span className={styles.asterisk}>*</span></label>
                  <input
                    className="form-control"
                    placeholder={`Enter Max exp`}
                    type='number'
                    onChange={handleChangeCareer}
                    name="max_exp"
                    value={carinput.max_exp}
                  />
                  {CareerErrors?.max_exp && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {CareerErrors?.max_exp}
                    </span>
                  )}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6} 
                >
                  <div className="form-group">
                  <label>Salary <span className={styles.asterisk}>*</span></label>
                   
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={carinput.salary}
                    label="Salary"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input":{
                        padding:".45rem .75rem",
                        height:"auto",
                        borderRadius:".25rem",
                        fontSize:"16px",
                        fontWeight:"400"
                      },
                      border:"1px solid #ced4da",
                      borderRadius:".25rem",
                      width:"100%"
                    }}
                    placeholder="Select Salary"
                    onChange={handleChangeCareer}
                    name="salary"
                    // name="blog_type"
                  >
                    <MenuItem value="$500 to $1000">$500 to $1000</MenuItem>
                    <MenuItem value="$1000 to $2000">$1000 to $2000</MenuItem>
                    <MenuItem value="$2000 to $5000">$2000 to $5000</MenuItem>
                    <MenuItem value="$5000 to $10000">$5000 to $10000</MenuItem>
                  </Select>
                  {CareerErrors?.salary && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {CareerErrors?.salary}
                    </span>
                  )}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6} 
                >
                  <div className="form-group">
                  <label>City <span className={styles.asterisk}>*</span></label>
                  
                  <input
                  className="form-control"
                    placeholder={`Enter City`}
                    type="text"
                    onChange={handleChangeCareer}
                    name="city"
                    value={carinput.city}
                  />
                  {CareerErrors?.city && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {CareerErrors?.city}
                    </span>
                  )}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6} 
                >
                  <div className="form-group">
                  <label>State <span className={styles.asterisk}>*</span></label>
                  
                  <input
                  className="form-control"
                    placeholder={`Enter State`}
                    type="text"
                    onChange={handleChangeCareer}
                    name="state"
                    value={carinput.state}
                  />
                  {CareerErrors?.state && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {CareerErrors?.state}
                    </span>
                  )}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6} 
                >
                  <div className="form-group">
                  <label>Country <span className={styles.asterisk}>*</span></label>
                
                  <input
                  className="form-control"
                    placeholder={`Enter Country`}
                    type="text"
                    onChange={handleChangeCareer}
                    name="country"
                    value={carinput.country}
                  />
                  {CareerErrors?.country && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {CareerErrors?.country}
                    </span>
                  )}
                  </div>
                </Grid>
              </Grid>
              <div className="form-group">
              <label>Department <span className={styles.asterisk}>*</span></label>
                
                <input
                  className="form-control"
                  placeholder="Enter department"
                  type="text"
                  onChange={handleChangeCareer}
                  name="department"
                  value={carinput.department}
                />
                {CareerErrors?.department && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {CareerErrors?.department}
                  </span>
                )}
              </div>
              <div className="form-group">
              <label className={styles.textarea_label}>Description <span className={styles.asterisk}>*</span></label>
                 
                {/* <TextareaAutosize
                  sx={{
                    mt: 3,
                  }}
                  placeholder="Enter description"
                  className={styles.textarea}
                  fullWidth
                  minRows={5}
                  name="description"
                  onChange={handleChangeCareer}
                  value={carinput.description}
                /> */}

                <ReactQuill
                  theme="snow"
                  placeholder={`Enter description`}
                  className={`${styles.textarea_blog}  ${styles.customEditor}`}
                  value={carinput.description}
                  onChange={(content) =>
                    handleChangeCareer({
                      target: { name: "description", value: content },
                    })
                  }
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                />
                {CareerErrors?.description && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                       marginTop:"70px",
                      position:"absolute"
                    }}
                  >
                    {CareerErrors?.description}
                  </span>
                )}
              </div> 
              <div className={styles.action_career}>
                <button
                  variant="contained"
                  onClick={handleCareerSubmit}
                  className="action_btn"
                >
                  Add
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </div>
  )
}
