import React, { useEffect, useState, useRef } from 'react';
import i18n from '../i18n';

const TranslateWrapper = ({ children }) => {
  const [translatedText, setTranslatedText] = useState('');
  const elementRef = useRef(null); 

  const apiKey = process.env.REACT_APP_GOOGLE_TRANSLATION_API_KEY;
  const baseurl = process.env.REACT_APP_TRANSLATION_BASE_URL;


  const targetLanguage = i18n.language

  const fetchTranslation = async () => {
    const urlWithKey = `${baseurl}?key=${apiKey}`;
    
    try {
      if (!targetLanguage) {
        return children;
      }
      const response = await fetch(urlWithKey, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          q: children,
          target: targetLanguage,
        }),
      });
      const data = await response.json();
      const translation = data.data.translations.map(
        (translation) => translation.translatedText
      );
      setTranslatedText(translation);
    } catch (e) {
      console.log(e.message);
    }
  };


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            fetchTranslation();
            observer.unobserve(entry.target); 
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.2, 
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [children,targetLanguage]);

  return(<span  ref={elementRef}>{translatedText || children}</span>);
};

export default TranslateWrapper;