import {
  Box,
  Modal,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./AddInquarypopup.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ArtstEnquiryList,
  EnquirySubType,
  EnquiryType,
  handleArtAddInquity,
} from "../../../../State/api/artistapi";
import { RiCloseCircleFill } from "react-icons/ri";
function AddInquirypopup(props) {
console.log("popup props",props);

  const inquirytype = useSelector((state) => state.inquirylist);
  const handleClose = () => {
    props.setOpen(false);
  };

  const [formData, setFormData] = useState({
    enquirytype: "",
    enquirysubtype: "",
    subject: "",
    message: "",
    attachment: null,
    userid: props?.id,
    usertype: props?.type,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    enquirytype: "",
    enquirysubtype:"",
    subject: "",
    message: "",
  });
  const handleSubmit = () => {
    // Validate fields
    let formErrors = {};
    if (!formData.enquirytype) {
      formErrors.enquirytype = "Inquiry Type is required.";
    }
    if (!formData.enquirysubtype) {
      formErrors.enquirysubtype = "Inquiry Sub -Type is required.";
    }
    if (!formData.subject) {
      formErrors.subject = "Subject is required.";
    }
    if (!formData.message) {
      formErrors.message = "Message is required.";
    }

    // If there are errors, update state and stop form submission
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    // Dispatch the API call only if validation passes
    dispatch(handleArtAddInquity(formData, navigate));
    // Reset form
    setFormData({
      enquirytype: "",
      enquirysubtype: "",
      subject: "",
      message: "",
      attachment: null,
      userid: props?.id,
      usertype: props?.type,
    });
    dispatch(ArtstEnquiryList(props?.type));

    // Close the modal
    handleClose();
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setFormData({
      ...formData,
      enquirytype: selectedCategory,
      enquirysubtype:
        selectedCategory === "Other" ? "" : formData.enquirysubtype, // Reset subcategory if "Other" is selected
    });
    setErrors({ ...errors, enquirytype: "" }); // Reset error for inquiry type
  };

  const handleSubcategoryChange = (e) => {
    setFormData({
      ...formData,
      enquirysubtype: e.target.value,
    });
  };
  const [InquirySubType, setInquirySubType] = useState([]);

  useEffect(() => {
    if (formData.enquirytype) {
      const findenquirytype = inquirytype?.inquirytype?.find(
        (ele) => ele.enquirytype === formData.enquirytype
      );

      if (findenquirytype) {
        const filteredSubtypes = inquirytype?.inquirysubtype?.filter(
          (ele) => ele.typeid === findenquirytype._id
        );
        setInquirySubType(filteredSubtypes);
      } else {
        setInquirySubType([]);
      }
    } else {
      setInquirySubType([]);
    }
  }, [formData.enquirytype, inquirytype]);

  useEffect(() => {
    dispatch(ArtstEnquiryList(props?.type));
    dispatch(EnquirySubType());
    dispatch(EnquiryType(props?.type));
  }, []);

  return (
    <div>
      <Modal
        open={props.Open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modalBox}>
          <div className={styles.clobtn}>
            <Link onClick={handleClose}>
              <RiCloseCircleFill className={styles.iconru} />
            </Link>
          </div>
          <p className={styles.mpophndg} id="transition-modal-title">
            Inquiry Form
          </p>

          {/* Inquiry Type Dropdown */}
          <div className="form-group">
            <label id="inquiry-type-label">Inquiry Type *</label>
            <FormControl fullWidth error={!!errors.enquirytype}>
              <Select
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiInputBase-input": {
                    padding: ".375rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "14px",
                    fontWeight: "400",
                    color:"#636365",
                    opacity:".9"
                  },
                  border: "1px solid #ced4da",
                  borderRadius: ".25rem",
                  width: "100%",
                }}
                labelId="inquiry-type-label"
                id="inquiry-type"
                value={formData.enquirytype}
                onChange={handleCategoryChange}
                label="Inquiry Type *"
              >
                {inquirytype?.inquirytype
                  ?.filter((ele) => ele.usertype === props.type)
                  ?.map((inquiry, index) => (
                    <MenuItem key={index} value={inquiry.enquirytype}>
                      {inquiry.enquirytype}
                    </MenuItem>
                  ))}
              </Select>
              {errors.enquirytype && (
                <small style={{color:"red"}}>{errors.enquirytype}</small>
              )}
            </FormControl>
          </div>
          <div className="form-group">
            <label id="inquiry-subcategory-label">Select Subcategory *</label>
            <FormControl fullWidth error={!!errors.enquirysubtype}>
              <Select
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiInputBase-input": {
                    padding: ".375rem .75rem",
                    height: "auto",
                    borderRadius: ".25rem",
                    fontSize: "14px",
                    fontWeight: "400",
                    color:"#636365",
                    opacity:".9"
                  },
                  border: "1px solid #ced4da",
                  borderRadius: ".25rem",
                  width: "100%",
                }}
                labelId="inquiry-subcategory-label"
                id="inquiry-subcategory"
                value={formData.enquirysubtype}
                onChange={handleSubcategoryChange}
                label="Select Subcategory"
              >
                {InquirySubType?.map((subcategory, index) => (
                  <MenuItem key={index} value={subcategory?.enquirysubtype}>
                    {subcategory?.enquirysubtype}
                  </MenuItem>
                ))}
              </Select>
              {errors.enquirysubtype && (
                <small style={{color:"red"}}>{errors.enquirysubtype}</small>
              )}
            </FormControl>
          </div>
          <div className="form-group">
            <label>Subject *</label>
            <input
              className="form-control"
              value={formData.subject}
              onChange={(e) =>
                setFormData({ ...formData, subject: e.target.value })
              }
              sx={{ mt: 2 }}
              error={!!errors.subject}
            />
              {errors.subject && (
                <small style={{color:"red"}}>{errors.subject}</small>
              )}
          </div>
          <div className="form-group">
            <label>Message *</label>
            <textarea
              className="form-control"
              fullWidth
              label="Message *"
              value={formData.message}
              onChange={(e) =>
                setFormData({ ...formData, message: e.target.value })
              }
              multiline
              rows={4}
              sx={{ mt: 2 }}
              error={!!errors.message}
            />
              {errors.message && (
                <small style={{color:"red"}}>{errors.message}</small>
              )}
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="file"
              accept="image/*, .pdf"
              onChange={(e) =>
                setFormData({ ...formData, attachment: e.target.files[0] })
              }
            />
          </div>


          {/* Submit Button */}
          <div className={styles.actionc}>
            <button className="action_btn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default AddInquirypopup;
