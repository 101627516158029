import React, { useState } from "react"
import styles from "./Editcategory.module.css"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import { TextField, Link } from "@mui/material"
import serverimage from "../../../../../serverConstant"
import { DataObjectOutlined } from "@mui/icons-material"
import {
  editCategorydata,
  handleAddCategory,
} from "../../../../../State/api/superadminApi"
import { useDispatch, useSelector } from "react-redux"
import CircularLoader from "../../../../../common/loader/Loader"
import { validateAdminCategory } from "../../../../../validation/validationAdminCategory"
import { validateAdminEditCategory } from "../../../../../validation/validationAdminEditCat"
import { RiCloseCircleFill } from "react-icons/ri"
import { FiUpload } from "react-icons/fi"
import { Errornotify } from "../../../../../common/alert/Alert"

export default function Editcategory(props) {
  console.log(props, "props-edit cat")

  const [data, setData] = useState(
    props.name
      ? {
          name: props.name && props.name.name,
          image: props.name && props.name.image,
        }
      : {
          name: "",
          image: "",
        }
  )
  const [src, setSrc] = useState("")
  const [editsrc, setEditsrc] = useState({
    category: props.name && props.name.image,
  })
  const loader = useSelector((state) => state.loader)
  const [categoryAddErrors, setcategoryAddErrors] = useState({})
  const [categoryEditErrors, setcategoryEditErrors] = useState({})
  const [newdata, setNewdata] = useState({ image: "", name: "" })
  const [newsrc, setNewsrc] = useState("")
  const [image, setImage] = useState("")
  const handleClose = () => props.setOpen(false)
  const dispatch = useDispatch()

  const handleChangeImage = (e) => {
    // if (e.target.files && e.target.files[0]) {
    //   const img = e.target.files[0]
    //   const imgs = URL.createObjectURL(e.target.files[0])
     if (e.target.files[0]) {
            const file = e.target.files[0];
            const fileURL = URL.createObjectURL(e.target.files[0]);
            const MIN_FILE_SIZE = 100; 
            const MAX_FILE_SIZE = 50000; 
            const ALLOWED_TYPES = ["image/jpeg", "image/jpg", "image/png"]; 
      
            const fileSizeKiloBytes = file.size / 1024;
      
          
            if (!ALLOWED_TYPES.includes(file.type)) {
              Errornotify("Only .jpg, .jpeg, or .png files are allowed.");
              return; // Exit if file type is invalid
            }
      
            if (fileSizeKiloBytes < MIN_FILE_SIZE) {
              Errornotify("File size should be greater than 100kb");
            } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
              Errornotify("File size should be less than or equal to  50mb");
            } else {

      setImage(file)
      setSrc(fileURL)
      setNewdata((prev) => ({ ...prev, image: file }))
      setData((prev) => ({ ...prev, image: file }))
      setEditsrc({ category: fileURL })
            }
    }
  }

  const handleChange = (e) => {
    setData({ name: e.target.value, image: data.image })
  }

  const handleNewImage = (e) => {
    // console.log('trigger', e.target)
    // if (e.target.files && e.target.files[0]) {
    //   const img = e.target.files[0]
    //   const imgs = URL.createObjectURL(e.target.files[0])
      // setImage(e.target.files[0]);


      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const fileURL = URL.createObjectURL(e.target.files[0]);
        const MIN_FILE_SIZE = 100; // kb
        const MAX_FILE_SIZE = 50000; // kb
        const ALLOWED_TYPES = ["image/jpeg", "image/jpg", "image/png"]; // Allowed file types
  
        const fileSizeKiloBytes = file.size / 1024;
  
        // File type validation
        if (!ALLOWED_TYPES.includes(file.type)) {
          Errornotify("Only .jpg, .jpeg, or .png files are allowed.");
          return; // Exit if file type is invalid
        }
  
        if (fileSizeKiloBytes < MIN_FILE_SIZE) {
          Errornotify("File size should be greater than 100kb");
        } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
          Errornotify("File size should be less than or equal to  50mb");
        } else {
      setNewdata((prev) => ({ ...prev, image: file }))
      setNewsrc(fileURL)
        }
    }
  }

  const handleNewChange = (e) => {
    const { value } = e.target
    const validValue = value.replace(/[^a-zA-Z\s]/g, "")

    setNewdata({ name: validValue, image: newdata.image })
  }

  const handleSubmit = () => {
    const { catErrors, isValid } = validateAdminCategory(newdata)

    if (!isValid) {
      setcategoryAddErrors(catErrors)
    } else {
      setcategoryAddErrors({})
      dispatch(handleAddCategory(newdata, props.setOpen, props.fetchData))
    }
  }
  const handleEdit = (id) => {
    const { catErrors, isValid } = validateAdminEditCategory(data)

    if (!isValid) {
      setcategoryEditErrors(catErrors)
    } else {
      setcategoryEditErrors({})
      dispatch(editCategorydata(id, data, props.setOpen, props.fetchData))
    }
  }

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}

      {props.type === "add" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_del}>
            <div className={styles.clobtn}>
                <Link onClick={() => props.setOpen(false)}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  Add Category
              </p> 

              <div className="form-group">
                <label>Images <span className={styles.astricksymb}>*</span></label>
                <div className={styles.preview_img}>
                  {newsrc === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        onClick={handleNewImage}
                      >
                        <input
                          onChange={handleNewImage}
                          type="file"
                          id="input"
                          hidden
                          accept="image/*"
                        />
                        <label htmlFor="input" className={styles.labelv}>
                    <FiUpload />
                      <p>Upload Your Category Image</p>
                    </label>
                        {/* <label htmlFor="input" className={styles.label}>
                          <img
                            src={"/img/uploadimg.png"}
                            width="46px"
                            height="43px"
                            alt="img"
                          />
                          <p> Upload your category image</p>
                        </label> */}
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        onChange={handleNewImage}
                        type="file"
                        id="input"
                        hidden
                        accept="image/*"
                      />
                      <label htmlFor="input">
                        <img
                          src={newsrc}
                          alt="img"
                          id="img"
                          className={
                            newdata.image
                              ? styles.img_view
                              : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                </div>
                <div className={styles.file_div}></div>
                <small>
                  Upload an image of your Category.Your file must be least
                  1200*1500 px , a JPG or PNG file ,width a color format of
                  rgb,and less than 50MB
                </small>
                <br />
                {categoryAddErrors?.image && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {categoryAddErrors?.image}
                  </span>
                )}
              </div>
              <div className="form-group">
              <label>Name <span className={styles.astricksymb}>*</span></label>
              <input
              className="form-control"
                onChange={handleNewChange}
                value={newdata.name}
                variant="outlined"
                placeholder="Enter Name"
                fullWidth
              />
              {categoryAddErrors?.name && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {categoryAddErrors?.name}
                </span>
              )}
              </div>
              
              
              <div className={styles.action}>
                <button
                  variant="contained"
                  onClick={handleSubmit}
                  className="action_btn"
                >
                  {loader.btnloader ? (
                    <CircularLoader color="white" type="small" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_del}>
            <div className={styles.clobtn}>
                <Link onClick={() => props.setOpen(false)}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  Edit Category
              </p>
              

              <div className="form-group">
                <label>Images</label>
                <div className={styles.preview_img}>
                  {data.image === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        onClick={handleChangeImage}
                      >
                        <input
                          onChange={handleChangeImage}
                          type="file"
                          id="input"
                          hidden
                          accept="image/*"
                        />
                        <label htmlFor="input" className={styles.labelv}>
                    <FiUpload />
                      <p>Upload Your Category Image</p>
                    </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        onChange={handleChangeImage}
                        type="file"
                        id="input"
                        hidden
                        accept="image/*"
                      />
                      <label htmlFor="input">
                        <img
                          src={editsrc.category}
                          alt="img"
                          id="img"
                          className={
                            data.image ? styles.img_view : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                </div>
                <div className={styles.file_div}></div>
                <small>
                  Upload an image of your Category.Your file must be least
                  1200*1500 px , a JPG or PNG file ,width a color format of
                  rgb,and less than 50MB
                </small>
                {categoryEditErrors?.image && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {categoryEditErrors?.image}
                  </span>
                )}
              </div>
              <div className="form-group">
              <label>Name <span className={styles.astricksymb}></span></label>
              <input
              className="form-control"
                onChange={handleChange}
                value={data.name}
                variant="outlined"
                placeholder="Enter Name"
                fullWidth
                sx={{ mb: 2 }}
              />
              {categoryEditErrors?.name && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {categoryEditErrors?.name}
                </span>
              )}
              </div>
              
              <div className={styles.action}>
                <button
                  variant="contained"
                  onClick={() => handleEdit(props.name._id)}
                  className="action_btn"
                >
                  Save
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </div>
  )
}
