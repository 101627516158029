import {
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  Modal,
  Link,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import styles from "./Subadminpopup.module.css";
import Backdrop from "@mui/material/Backdrop";
import homeImage from "../../../../../constant";
import { useDispatch, useSelector } from "react-redux";
import { fetchPermissionData } from "../../../../../State/api/superadminApi";
import CircularLoader from "../../../../../common/loader/Loader";
import Entermobile from "../../../../../curator/pages/applyForm/mobileNumber/Entermobile";
import { Dashboard } from "@mui/icons-material";
import { RiCloseCircleFill} from "react-icons/ri";
import { value } from "dom7";

const permissionsubadminLeft = [
  {
    name: "Dashboard",
    value: 1,
  },
  {
    name: "Art",
    value: 2,
  },
  {
    name: "artist",
    value: 3,
  },
  {
    name: "curators",
    value: 4,
  },
  {
    name: "buyer",
    value: 5,
  },
  {
    name: "orders",
    value: 6,
  },
  {
    name: "exhibitions",
    value: 7,
  },
  {
    name: "auction",
    value: 8,
  },
  {
    name: "sub admin",
    value: 9,
  },
  // {
  //   name: "Chat",
  //   value: 10,
  // },
  {
    name: "affiliate",
    value: 11,
  },
  {
    name: "notification",
    value: 12,
  },
  {
    name: "inquiry",
    value: 13,
  },
];
const permissionsubadminMid = [
  {
    name: "offers",
    value: 14,
  },
  {
    name: "testimonials",
    value: 15,
  },
  {
    name: "gallery",
    value: 16,
  },
  {
    name: "menu",
    value: 17,
  },
  {
    name: "campaigns",
    value: 18,
  },
  {
    name: "career",
    value: 19,
  },
  {
    name: "subscription",
    value: 20,
  },
  {
    name: "press release",
    value: 21,
  },
  {
    name: "blog",
    value: 22,
  },
  {
    name: "gift card",
    value: 23,
  },
  {
    name: "activity Page",
    value: 24,
  },
  {
    name: "hero section",
    value: 25,
  },
  {
    name: "category",
    value: 26,
  },
];
const permissionsubadminRight = [
  {
    name: "print",
    value: 27,
  },
  {
    name: "print pricing",
    value: 28,
  },
  {
    name: "reports",
    value: 29,
  },
  {
    name: "policies",
    value: 30,
  },
  {
    name: "all account",
    value: 31,
  },
  {
    name: "faq",
    value: 32,
  },
  {
    name: "recreate Request",
    value: 33,
  },
  {
    name: "dispute",
    value: 34,
  },
  {
    name: "rating and review",
    value: 35,
  },
  {
    name: "settings",
    value: 36,
  },
  {
    name: "frame country",
    value: 37,
  },
  {
    name: "contact-us",
    value: 38,
  },
  {
    name:"buyer's offers",
    value:39
  },
  {
    name:'quote request',
    value:40
  }
];

export default function Subadminpopup(props) {
  console.log("checking props ", props);

  const [errors, setErrors] = useState({});

  const [addSubadmin, setAddSubadmin] = useState(
    props.editSubadmin
      ? {
          first_name: props.editSubadmin.first_name,
          last_name: props.editSubadmin.last_name,
          email: props.editSubadmin.email,
          phone: props.editSubadmin.phone,
          _id: props.editSubadmin._id,
          admin_role: props.editSubadmin.admin_role,
          role:props.editSubadmin.role,
          password:props.editSubadmin.password,
          permission_ids: props.editSubadmin.access_role.map((val) => {
            return { _id: val._id };
          }),
        }
      : {
          first_name: "",
          last_name: "",
          role: "",
          email: "",
          phone: "",
          password: "",
          permission_ids: [],
          admin_role: "",
        }
  );

  

  
  // const [permission_ids, setPermission_ids] = useState([])
  const handleClose = () => props.setOpen(false);
  const loader = useSelector((state) => state.loader);

  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.permissionData);

  useEffect(() => {
    dispatch(fetchPermissionData());
  }, [props.type === "edit" ? false : true]);
  const handleChangeData = (e) => {
    const val = e.target.value;
    const name = e.target.name;
    let validValue = val;
    if (name === "first_name" || name === "last_name") {
      // Allow only alphabets and spaces for the name field
      validValue = val.replace(/[^a-zA-Z\s]/g, "");
    }
    setAddSubadmin({ ...addSubadmin, [name]: validValue });
  };
  const handleChangeNumber = (e) => {
    setAddSubadmin({ ...addSubadmin, phone: e });
  };
  // const handleAccessRole = (e) => {
  //   const val = e.target.value
  //   const permission_ids = [...addSubadmin.permission_ids]
  //   const check_ids = addSubadmin.permission_ids.findIndex(
  //     (item) => item._id === val
  //   )
  //   if (check_ids === -1) {
  //     permission_ids.push({ _id: val })
  //     setAddSubadmin({ ...addSubadmin, permission_ids: permission_ids })
  //   } else {
  //     permission_ids.splice(check_ids, 1)
  //     setAddSubadmin({ ...addSubadmin, permission_ids: permission_ids })
  //   }
  // }

  const handleAccessRole = (e) => {
    const val = e.target.value;
    let roles = addSubadmin.admin_role ? addSubadmin.admin_role.split(",") : [];

    const roleIndex = roles.indexOf(val);
    if (roleIndex === -1) {
      // If the value is not found in the array, add it
      roles.push(val);
    } else {
      // If the value is found in the array, remove it
      roles.splice(roleIndex, 1);
    }

    // Convert the roles array back to a comma-separated string
    const updatedAdminRole = roles.join(",");

    setAddSubadmin({ ...addSubadmin, admin_role: updatedAdminRole });
  };
  

  const validateForm = () => {
    let newErrors = {};
    if (!addSubadmin.first_name.trim()) newErrors.first_name = "First Name is required";
    if (!addSubadmin.last_name.trim()) newErrors.last_name = "Last Name is required";
    if (!/\S+@\S+\.\S+/.test(addSubadmin.email)) newErrors.email = "email is required";
    if (!/^\d{12}$/.test(addSubadmin.phone)) newErrors.phone = "Phone must be 10 digits";
    if (!addSubadmin.password) newErrors.password = "Password is required";
    if(!addSubadmin.role) newErrors.role = "role is required" 

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) return; 

    props.type === "edit"
      ? props.handleSubmitEdited(addSubadmin._id, addSubadmin)
      : props.handleAdd(addSubadmin);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className={styles.modal_subadmin}>
          <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
                 {props.type === "edit" ? "Edit" : "Add"} Sub Admin
              </p>
             
            <Grid
              container
              columnSpacing={2}
            >
              <Grid item xs={12} sm={6} md={6}>
                <div className="form-group">
                <label>
                  First Name <span style={{ color: "red" }}>*</span>
                </label>
              
                <input
                  className="form-control"
                  placeholder={`Enter First Name`}
                  name="first_name"
                  value={addSubadmin.first_name}
                  onChange={handleChangeData}
                  type="text"
                />
                {errors.first_name && <span style={{ color: "red" ,fontSize:'10px',fontWeight:'500' }} className="error-text">{errors.first_name}</span>}
                </div>
                
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
              <div className="form-group">
              <label>
                  Last Name <span style={{ color: "red" }}>*</span>
                </label> 
                <input
                  className="form-control"
                  placeholder={`Enter Last Name`}
                  name="last_name"
                  value={addSubadmin.last_name}
                  onChange={handleChangeData}
                  type="text"
                />
                {errors.last_name && <span style={{ color: "red",fontSize:'10px',fontWeight:'500' }} className="error-text">{errors.last_name}</span>}
              </div>
                
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
              <div className="form-group">
              <label>
                  Email <span style={{ color: "red" }}>*</span>
                </label> 
                <input
                  className="form-control"
                  placeholder={`Enter Email`}
                  name="email"
                  value={addSubadmin.email}
                  onChange={handleChangeData}
                  type="email"
                />
                {errors.email && <span style={{ color: "red",fontSize:'10px',fontWeight:'500'}} className="error-text">{errors.email}</span>}
              </div>
                
              </Grid>
              <Grid item xs={12} sm={6} md={6} className={styles.inputs_gird}>
              <div className="form-group">
              <label>
                  Role <span style={{ color: "red" }}>*</span>
                </label> 
                <input
                 className="form-control"
                  placeholder={`Enter Role`}
                  name="role"
                  value={addSubadmin.role}
                  onChange={handleChangeData}
                  type="text"
                />
                {errors.role && <span style={{ color: "red",fontSize:'10px',fontWeight:'500'}} className="error-text">{errors.role}</span>}
              </div>
                
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
              <div className="form-group">
              <label>
                  Phone No. <span style={{ color: "red" }}>*</span>
                </label> 
                <Entermobile
                  handleChangeNumber={handleChangeNumber}
                  type="subadmin"
                  addSubadmin={addSubadmin}
                />
                {errors.phone && <span style={{ color: "red",fontSize:'10px',fontWeight:'500' }} className="error-text">{errors.phone}</span>}
              </div>
                
              
              </Grid>
            
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                   
                >
                {/* <div className="form-group">
                <label>
                    Password <span style={{ color: "red" }}>*</span>
                  </label> 
                  <input
                  className="form-control"
                    placeholder={`Enter Password`}
                    value={addSubadmin.password}
                    onChange={handleChangeData}
                    name="password"
                    type="text"
                  />
                  {errors.password && <span style={{ color: "red" ,fontSize:'10px',fontWeight:'500'}} className="error-text">{errors.password}</span>}
                </div> */}
                  
                </Grid>
          
            </Grid>
            
            <Grid
              container
              spacing={2}
             
            >
              <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                  ><p className={styles.role_heading}>Access Role</p></Grid>
              {props.type === "edit" ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                  >
                    {permissionsubadminLeft &&
                      permissionsubadminLeft.map((val) => (
                        <div className={styles.access_roles} key={val._id}>
                          <div style={{textTransform:'capitalize'}}>{val.name}</div>
                          <div className={styles.toggle_btn}>
                            <label className={styles.switch}>
                              <input
                                type="checkbox"
                                name="admin_role"
                                value={val.name}
                                checked={addSubadmin?.admin_role?.includes(
                                  val?.name
                                )}
                                onChange={handleAccessRole}
                              />
                              <span
                                className={`${styles.slider} ${styles.round}`}
                              ></span>
                             
                            </label>
                          </div>
                        </div>
                      ))}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className={styles.inputs_gird}
                  >
                    {permissionsubadminMid &&
                      permissionsubadminMid.map((val) => (
                        <div className={styles.access_roles} key={val._id}>
                          <div style={{textTransform:'capitalize'}}>{val.name}</div>
                          <div className={styles.toggle_btn}>
                            <label className={styles.switch}>
                              <input
                                type="checkbox"
                                name="admin_role"
                                value={val.name}
                                checked={addSubadmin?.admin_role?.includes(
                                  val.name
                                )}
                                onChange={handleAccessRole}
                              />
                              <span
                                className={`${styles.slider} ${styles.round}`}
                              ></span>
                            </label>
                          </div>
                        </div>
                      ))}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className={styles.inputs_gird}
                  >
                    {permissionsubadminRight &&
                      permissionsubadminRight.map((val) => (
                        <div className={styles.access_roles} key={val._id}>
                          <div style={{textTransform:'capitalize'}}>{val.name}</div>
                          <div className={styles.toggle_btn}>
                            <label className={styles.switch}>
                              <input
                                type="checkbox"
                                name="admin_role"
                                value={val.name}
                                checked={addSubadmin?.admin_role?.includes(
                                  val.name
                                )}
                                onChange={handleAccessRole}
                              />
                              <span
                                className={`${styles.slider} ${styles.round}`}
                              ></span>
                            </label>
                          </div>
                        </div>
                      ))}
                  </Grid>
                </>
              ) : loader.loader ? (
                <Box className={styles.loader_box}>
                  {" "}
                  <CircularLoader size={60} />
                </Box>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className={styles.inputs_gird}
                  >
                    {permissionsubadminLeft &&
                      permissionsubadminLeft.map((val) => (
                        <div className={styles.access_roles} key={val._id}>
                          <div style={{textTransform:'capitalize'}}>{val.name}</div>
                          <div className={styles.toggle_btn}>
                            <label className={styles.switch}>
                              <input
                                
                                type="checkbox"
                                name="admin_role"
                                value={val.name}
                                checked={addSubadmin?.admin_role?.includes(
                                  val?.name
                                )}
                                onChange={handleAccessRole}
                              />
                              <span
                                className={`${styles.slider} ${styles.round}`}
                              ></span>
                            </label>
                          </div>
                        </div>
                      ))}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className={styles.inputs_gird}
                  >
                    {permissionsubadminMid &&
                      permissionsubadminMid.map((val) => (
                        <div className={styles.access_roles} key={val._id}>
                          <div style={{textTransform:'capitalize'}}>{val.name}</div>
                          <div className={styles.toggle_btn}>
                            <label className={styles.switch}>
                              <input
                                type="checkbox"
                                name="admin_role"
                                value={val.name}
                                checked={addSubadmin?.admin_role?.includes(
                                  val?.name
                                )}
                                onChange={handleAccessRole}
                              />
                              <span
                                className={`${styles.slider} ${styles.round}`}
                              ></span>
                            </label>
                          </div>
                        </div>
                      ))}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className={styles.inputs_gird}
                  >
                    {permissionsubadminRight &&
                      permissionsubadminRight.map((val) => (
                        <div className={styles.access_roles} key={val._id}>
                          <div style={{textTransform:'capitalize'}}>{val.name}</div>
                          <div className={styles.toggle_btn}>
                            <label className={styles.switch}>
                              <input
                                type="checkbox"
                                name="admin_role"
                                value={val.name}
                                checked={addSubadmin?.admin_role?.includes(
                                  val?.name
                                )}
                                onChange={handleAccessRole}
                              />
                              <span
                                className={`${styles.slider} ${styles.round}`}
                              ></span>
                            </label>
                          </div>
                        </div>
                      ))}
                  </Grid>
                </>
              )}
            </Grid>
            <div className={styles.action}>
              <button 
                className="action_btn"
                onClick={handleSubmit}
              >
                {props.type === "edit" ? "Submit" : "Add"}
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
