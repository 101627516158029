import React, { lazy, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Applyformartist from "./artist/pages/apply/applyForm/Applyformartist";
import Personaldetail from "./artist/pages/apply/personalDetail/Personaldetailartist";
import Digitalart from "./artist/pages/uploadArt/thirdUpload/thirdSecond/digitalart/Digitalart";
import Home from "./common/home/Home";
import UserMain from "./UserMain";
import { useDispatch, useSelector } from "react-redux";
import Questionhomeartist from "./artist/pages/apply/quationHome/Quationhomeartist";
import { Errornotify } from "./common/alert/Alert";
import { useEffect } from "react";
import api from "./State/api/Api";
import { setEditArtId } from "./State/Features/common/editArtIdSlice";
import BuyerDisputeDetails from "./artist/pages/order/details/BuyerDisputeDetails";
import { fetchArtsDataForSubscription } from "./State/api/superadminApi";
import MandatoryModal from "./components/MandatoryModal";
import { updateArtistProfile, updateArtistProfileShippingAddress } from "./State/api/artistapi";
import ArtistTestimonial from "./artist/pages/Testimonials/ArtistTestimonial";
import AtristInquirtDetails from "./artist/pages/enquiry/AtristInquirtDetails";
import CuratorNotification from "./curator/pages/notification/CuratorNotification";

const Adminoffer = lazy(() => import("./artist/pages/adminOffer/Adminoffer"));
const Fillquestionartist = lazy(() =>
  import("./artist/pages/apply/fillQuestion/Fillquestion")
);
const Auctionartist = lazy(() =>
  import("./artist/pages/auction/Auctionartist")
);
const Auctionartistdetail = lazy(() =>
  import("./artist/pages/auction/details/Auctionartistdetail")
);
const Buyerartist = lazy(() => import("./artist/pages/buyer/Buyerartist"));
const Detailartistdispute = lazy(() =>
  import("./artist/pages/disputeManagement/details/Detailartistdisput")
);
const Disputartist = lazy(() =>
  import("./artist/pages/disputeManagement/Disputeartist")
);
const Earningartist = lazy(() =>
  import("./artist/pages/earnings/Earningartist")
);
const Editprofileartist = lazy(() =>
  import("./artist/pages/editProfile/Editprofileartist")
);
const Editprofilesecondartist = lazy(() =>
  import("./artist/pages/editProfile/stepTwo/Editprofilesecondartist")
);
const Totalenquiryartist = lazy(() =>
  import("./artist/pages/enquiry/Totalenquiryartist")
);
const Artistenquiryartist = lazy(() =>
  import("./artist/pages/enquiry/ArtistEnquirylst")
);
const Exshibitiondetailartist = lazy(() =>
  import("./artist/pages/exshibition/details/Exshibitionartistdetail")
);
const Exshibitionartist = lazy(() =>
  import("./artist/pages/exshibition/Exshibitionartist")
);
const Artistnews = lazy(() => import("./artist/pages/news/Artistnews"));
// const Notificationartist = lazy(() =>
//   import("./artist/pages/notification/Notificationartist")
// );
const Orderartistdetail = lazy(() =>
  import("./artist/pages/order/details/Orderartistdetail")
);
const Orderartist = lazy(() => import("./artist/pages/order/Orderartist"));
const Artistquoterequest = lazy(() =>
  import("./artist/pages/quoteRequest/Artistquoterequest")
);
const Recreateartist = lazy(() =>
  import("./artist/pages/recreate/Recreateartist")
);
const Detailuploadartist = lazy(() =>
  import("./artist/pages/uploadArt/details/Detailuploadartist")
);
const Firstuploadartist = lazy(() =>
  import("./artist/pages/uploadArt/firstUpload/Firstuploadartist")
);
const Seconduploadartist = lazy(() =>
  import("./artist/pages/uploadArt/secondUpload/Seconduploadartist")
);
const Thirdsecond = lazy(() =>
  import("./artist/pages/uploadArt/thirdUpload/thirdSecond/Thirdsecond")
);
const Thirduploadartist = lazy(() =>
  import("./artist/pages/uploadArt/thirdUpload/Thirduploadartist")
);
const Artistsubscription = lazy(() =>
  import("./artist/pages/subscription/Artistsubscription")
);
const Artartist = lazy(() => import("./artist/pages/allArts/Artartist"));
const Artsitartshipping = lazy(() =>
  import("./artist/pages/allArts/shippingAddress/Artsitartshipping")
);
const Bankdetailartist = lazy(() =>
  import("./artist/pages/dashboard/bankDetail/Bankdetailartist")
);
const Dashboardartist = lazy(() =>
  import("./artist/pages/dashboard/Dashboardartist")
);

export default function Artist() {
  const [artsData, setArtsData] = useState(null);
  const [category, setCategory] = useState("");
  const dispatch = useDispatch();
  const editArtId = useSelector((state) => state.editArtId?.id);
console.log("editArtId",editArtId);
console.log("artsData",artsData);

  const location = useLocation();
  const [materialId, setMaterialId] = useState(null);
  const [stylesId, setStylesId] = useState(null);
  const [mediumId, setMediumId] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [uploadArt, setUploadArt] = useState({
    name: "",
    tube_size: "",
    frame_color: "",
    package_type: "Tube",
    sale: {
      qty: "1",
      price: "",
      rolled: [{ rolled_size: "", rolled_price: "" }],
      artsmiley_commission: "",
      artist_profit: "",
      price_of_collector: "",
      sale_weight: "",
      curator_percentage: "",
      package_type:"Tube",
      isframe: false,
      tube_size:"",
      is_hanged:"No",
      height:"",
      width:"",
      depth:"",
    },
    rent: {
      time: "1 Month",
      rent_price: "",
      artsmiley_commission_rent: "",
      artist_profit_rent: "",
      curator_percentage_rent: "",
      price_of_collector_rent: "",
      rent_weight: "",
      package_type:"Tube",
      isframe:false,
      tube_size:"",
      is_hanged:"No",
      height:"",
      width:"",
      depth:"",
    },
    print: [
      {
        edition: "Limited",
        qty: "1",
        size: {},
        print_price: "",
        material: "",
        artsmiley_commission_print: "",
        artist_profit_print: "",
        price_of_collector_print: "",
        curator_percentage_print: "",
        print_weight: "",
        package_type:"Tube",
        is_print:false,
        is_hanged:"No",
        is_frame:false,
        frame_color:"",
        tube_size:"",
        height:"",
        width:"",
        depth:"",
      },
    ],
    print_by_artsmiley: [
      {
        edition: "Open",
        qty: "1",
        size: {},
        print_price: "",
        material: "",
        metal_thikness: "",
        product: "",
        product_price: "",
        product_commission: "",
        package_type:"Tube",
      },
    ],
    print_by_artsmiley_material: [],
    medium_id: [],
    image: "",
    printImage: "",
    copyright: "Yes",
    is_showcased: "No",
    subject_id: "",
    style_id: [],
    orientation: "",
    colors: [],
    category_id: "",
    material_id: [],
    width: "",
    height: "",
    depth: "",
    is_hanged: "No",
    is_signed: "Yes",
    keywords: [],
    tags: "",
    description: "",
    images: [],
    year: null,
    item_type: "",
  });
  const [src, setSrc] = useState({ main: "", multi: [] });
  const user = useSelector((state) => state.user.data);

  const handleChangeOrientation = (selectedValue) => {
    setUploadArt({ ...uploadArt, orientation: selectedValue.value });
  };
  console.log("uploadArt for editing",uploadArt);
  
  const handleChangedata = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (["width", "height", "depth"].includes(name)) {
      if (!/^\d+$/.test(value) && value.length <= 4) {
        // Ensure only numeric values are allowed
        setUploadArt({ ...uploadArt, [name]: "" }); // Replace with an empty string
      } else if (value.length > 4) {
        setUploadArt({ ...uploadArt, [name]: value.slice(0, 4) });
      } else {
        setUploadArt({ ...uploadArt, [name]: value });
      }
    }
    // else if(name === "is_hanged" && value ==="No"){
    //   setUploadArt({...})
    //   }
    
    
    else {
      setUploadArt({ ...uploadArt, [name]: value });
    }
  };
  const handleChangedataCategories = (selectedOptions, name) => {
    selectedOptions.forEach((selectedOption) => {
      const { value } = selectedOption;
      if (name === "material_id") {
        const material = [...uploadArt.material_id];
        const index = material.findIndex((val) => val._id === value);
        if (index === -1) {
          material.push({ _id: value });
          setUploadArt({ ...uploadArt, material_id: material });
        }
      } else if (name === "style_id") {
        const style_id = [...uploadArt.style_id];
        const index = style_id.findIndex((val) => val._id === value);
        if (index === -1) {
          style_id.push({ _id: value });
          setUploadArt({ ...uploadArt, style_id: style_id });
        }
      } else {
        const medium_id = [...uploadArt.medium_id];
        const index = medium_id.findIndex((val) => val._id === value);
        if (index === -1) {
          medium_id.push({ _id: value });
          setUploadArt({ ...uploadArt, medium_id: medium_id });
        }
      }
    });
  };

  const fetchArtById = async () => {
    try {
      const response = await api.applicationApi(
        `api/user/allitems/${editArtId?.id}`,
        "GET"
      );
      const artData = response.data.artwork[0];
       setArtsData(artData);
    } catch (error) {
      console.log(error);
    }
  };
  console.log("ArtsData",artsData);
  
  // const [artistSubscriptions, setArtistSubscriptions] = useState([]);

  // async function fetchsubscriptionDataArtist() {
  //   try {
  //     const apiResponse = await api.applicationApi(
  //       `api/subscription/getArtistSubscriptions/${localStorage.getItem(
  //         "user_id"
  //       )}`,
  //       "GET"
  //     );

  //     if (apiResponse.status === 200 || apiResponse.status === 201) {
  //       setArtistSubscriptions(apiResponse.data.data);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  // useEffect(() => {
  //   if (!artistSubscriptions || artistSubscriptions.length === 0) return;
  //   // Step 1: Get the most recent subscription (last item in the array)
  //   const mostRecentSubscription =
  //     artistSubscriptions[artistSubscriptions.length - 1];

  //   if (
  //     !mostRecentSubscription ||
  //     !mostRecentSubscription.subscription_id ||
  //     !Array.isArray(mostRecentSubscription.subscription_id.feature_ids)
  //   ) {
  //     console.error("Invalid subscription data");
  //     return;
  //   }

  //   // Step 2: Extract feature_ids array from the most recent subscription
  //   const featureIds = mostRecentSubscription.subscription_id.feature_ids;

  //   // Step 3: Find the count for "no. of Arts"
  //   const artsFeature = featureIds.find(
  //     (feature) => feature._id?.name === "no. of Arts"
  //   );
  //   const count = artsFeature ? artsFeature._id?.count : 0; // Default to 0 if not found

  //   // Step 4: Store the count in localStorage
  //   if (count) {
  //     localStorage.setItem(
  //       "current_subscription_count",
  //       JSON.stringify(parseInt(count, 10))
  //     );
  //   }
  // }, [artistSubscriptions]);

  useEffect(() => {
    dispatch(fetchArtsDataForSubscription());
    // fetchsubscriptionDataArtist();
  }, []);
  useEffect(() => {
    setUploadArt({
      name: artsData?.name || "",
      tube_size: artsData?.tube_size || "",
      frame_color: "",
      package_type: artsData?.package_type || "Tube",
      sale: {
        qty: artsData?.sale?.qty || "1",
        price: artsData?.sale?.price || "",
        rolled:
          artsData?.sale?.rolled?.map((rolledItem) => ({
            rolled_size: rolledItem?.rolled_size || "",
            rolled_price: rolledItem?.rolled_price || "",
          })) || [],
        artsmiley_commission: artsData?.sale?.artsmiley_commission || "",
        artist_profit: artsData?.sale?.artist_profit || "",
        curator_percentage: artsData?.sale?.curator_percentage || "",
        price_of_collector: artsData?.sale?.price_of_collector || "",
        sale_weight: artsData?.sale?.sale_weight || "",
        package_type: artsData?.sale?.package_type || "Tube",
        isframe: artsData?.sale?.isframe || false,
        tube_size: artsData?.sale?.tube_size || "",
        is_hanged: artsData?.sale?.is_hanged || "No",
        height: artsData?.sale?.height || "",
        width: artsData?.sale?.width || "",
        depth: artsData?.sale?.depth || "",
      },
      rent: {
        time: artsData?.rent?.time || "1 Month",
        rent_price: artsData?.rent?.rent_price || "",
        artsmiley_commission_rent:
          artsData?.rent?.artsmiley_commission_rent || "",
        artist_profit_rent: artsData?.rent?.artist_profit_rent || "",
        price_of_collector_rent: artsData?.rent?.price_of_collector_rent || "",
        curator_percentage_rent: artsData?.rent?.curator_percentage_rent || "",
        rent_weight: artsData?.rent?.rent_weight || "",
        package_type: artsData?.rent?.package_type || "Tube",
        isframe: artsData?.rent?.isframe || false,
        tube_size: artsData?.rent?.tube_size || "",
        is_hanged: artsData?.rent?.is_hanged || "No",
        height: artsData?.rent?.height || "",
        width: artsData?.rent?.width || "",
        depth: artsData?.rent?.depth || "",
      },
      print:
        Array.isArray(artsData?.print) && artsData.print.length > 0
          ? artsData.print.map((printItem) => ({
              edition: printItem?.edition || "Limited",
              qty: printItem?.qty || "1",
              size: printItem?.size || {},
              print_price: printItem?.print_price || "",
              material: printItem?.material || "Fine Art Paper",
              artsmiley_commission_print:
                printItem?.artsmiley_commission_print || "",
              artist_profit_print: printItem?.artist_profit_print || "",
              price_of_collector_print:
                printItem?.price_of_collector_print || "",
              print_weight: printItem?.print_weight || "",
              package_type: printItem?.package_type || "Tube",
              is_print: !!printItem?.print_price,
              tube_size: printItem?.tube_size || "",
              is_hanged: printItem?.is_hanged || "No",
              is_frame: printItem?.is_frame || false,
              frame_color: printItem?.frame_color || "",
              height: printItem?.height || "",
              width: printItem?.width || "",
              depth: printItem?.depth || "",            
            }))
          : [
              {
                edition: "Limited",
                qty: "1",
                size: {},
                print_price: "",
                material: "",
                artsmiley_commission_print: "",
                artist_profit_print: "",
                price_of_collector_print: "",
                print_weight: "",
                package_type: "Tube",
                is_print: false,
                tube_size: "",
                height:  "",
                width: "",
                depth:  "",  
              },
            ],
      // print_by_artsmiley:
      //   artsData?.print_by_artsmiley?.map((printItem) => ({
      //     edition: printItem?.edition || "OpenLimited",
      //     qty: printItem?.qty || "1",
      //     size: printItem?.size || {},
      //     print_price: printItem?.print_price || "",
      //     material: printItem?.material || "",
      //     metal_thikness: printItem?.metal_thikness || "",
      //     product: printItem?.product || "",
      //     product_price: printItem?.product_price || "",
      //     product_commission: printItem?.product_commission || "",
      //     package_type: printItem?.package_type || "Tube",
        
      //   })) || [],

      print_by_artsmiley:
  Array.isArray(artsData?.print_by_artsmiley) && artsData.print_by_artsmiley.length > 0
    ? artsData.print_by_artsmiley.map((printItem) => ({
        edition: printItem?.edition || "OpenLimited",
        qty: printItem?.qty || "1",
        size: printItem?.size || {},
        print_price: printItem?.print_price || "",
        material: printItem?.material || "",
        metal_thikness: printItem?.metal_thikness || "",
        product: printItem?.product || "",
        product_price: printItem?.product_price || "",
        product_commission: printItem?.product_commission || "",
        package_type: printItem?.package_type || "Tube",
      }))
    : [
        {
          edition: "OpenLimited",
          qty: "",
          size: {},
          print_price: "",
          material: "",
          metal_thikness: "",
          product: "",
          product_price: "",
          product_commission: "",
          package_type: "",
        },
      ],
      print_by_artsmiley_material: artsData?.print_by_artsmiley_material || [],

      image:
        window.location.pathname !== "/upload/stepfirst" ? artsData?.image : "",
      printImage: artsData?.printImage || "",
      copyright: artsData?.copyright || "Yes",
      is_showcased: artsData?.is_showcased || "No",
      subject_id: artsData?.subject_id?._id || "",

      orientation: artsData?.orientation_id?._id || "",
      colors: artsData?.color_id?.map((color) => color?._id?._id) || [],
      category_id: artsData?.category_id?._id || "",

      medium_id:
        artsData?.medium_id
          .map((medium) => ({
            _id: medium?._id?._id || "",
          }))
          .filter((item) => item._id) || [],
      material_id:
        artsData?.material_id
          .map((material) => ({
            _id: material?._id?._id || "",
          }))
          .filter((item) => item._id) || [],
      style_id:
        artsData?.style_id
          .map((style) => ({
            _id: style?._id?._id || "",
          }))
          .filter((item) => item._id) || [],

      width: artsData?.width || "",
      height: artsData?.height || "",
      depth: artsData?.depth || "",
      is_hanged: artsData?.is_hanged || "No",
      is_signed: artsData?.is_signed || "Yes",
      keywords: artsData?.keywords
        ? artsData.keywords
            .split(",")
            .map((keyword) => keyword.trim())
            .filter((keyword) => keyword !== "")
        : [],
      tags: artsData?.tags || "",
      description: artsData?.description || "",
      // images: artsData?.images || [],
      images: artsData?.images ? artsData.images.split(",") : [],
      year: artsData?.year || null,
      item_type: artsData?.item_type || "",
    });
    const material = artsData?.material_id
      .map((material) => material?._id?._id)
      .join("");
  }, [artsData]);

  useEffect(() => {
    if (materialId || mediumId || stylesId) {
      setUploadArt((prevState) => ({
        ...prevState,
        material_id: materialId,
        medium_id: mediumId,
        style_id: stylesId,
      }));
    }
  }, [materialId, mediumId, stylesId]);
  useEffect(() => {
    if (window.location.pathname === "/upload/stepfirst") {
      setSrc({ ...src, main: "" });
      dispatch(setEditArtId({ id: null }));
    }
  }, [window.location.pathname]);
  useEffect(() => {
    if (editArtId?.id) {
      fetchArtById();
    } else {
      setUploadArt({
        name: "",
        tube_size: "",
        frame_color: "",
        package_type: "Tube",
        sale: {
          qty: "1",
          price: "",
          rolled: [{ rolled_size: "", rolled_price: "" }],
          artsmiley_commission: "",
          artist_profit: "",
          price_of_collector: "",
          sale_weight: "",
          tube_size:"",
          is_hanged:"No",
        },
        rent: {
          time: "1 Month",
          rent_price: "",
          artsmiley_commission_rent: "",
          artist_profit_rent: "",
          price_of_collector_rent: "",
          rent_weight: "",
          is_hanged:"No",
          tube_size:"", 
          isframe: false,  
          package_type:"Tube",       
        },
        print: [
          {
            edition: "Limited",
            qty: "1",
            size: {},
            print_price: "",
            material: "Fine Art Paper",
            artsmiley_commission_print: "",
            artist_profit_print: "",
            price_of_collector_print: "",
            print_weight: "",
            package_type: "Tube",
            is_print:false,
            is_hanged:"No",
            is_frame:false,
            frame_color:"",
            tube_size:""
          },
        ],
        print_by_artsmiley: [
          {
            edition: "OpenLimited",
            qty: "1",
            size: {},
            print_price: "",
            material: "",
            metal_thikness: "",
            product: "",
            product_price: "",
            product_commission: "",
          },
        ],
        print_by_artsmiley_material: [],
        medium_id: [],
        image: "",
        printImage: "",
        copyright: "Yes",
        is_showcased: "No",
        subject_id: "",
        style_id: [],
        orientation: "",
        colors: [],
        category_id: "",
        material_id: [],
        width: "",
        height: "",
        depth: "",
        is_hanged: "No",
        is_signed: "Yes",
        keywords: [],
        tags: "",
        description: "",
        images: [],
        year: null,
        item_type: "",
      });
    }
  }, [editArtId?.id]);
  return (
    <>
      {/* <MandatoryModal
        open={open}
        handleClose={handleClose}
        apply={apply}
        setApply={setApply}
        handleChange={handleChange}
        handleSubmitProfile={handleSubmitProfile}
      /> */}
      <Routes>
        {user.is_accepted === "Pending" ? (
          <>
            <>
              <Route path="*" element={<UserMain />} />

              <Route path="/home" element={<Home user="Curator" />} />
            </>
          </>
        ) : (
          <>
            <Route path="/dashboard" element={<Dashboardartist />} />
            <Route path="/bankdetail" element={<Bankdetailartist />} />
            <Route path="/arts" element={<Artartist />} />
            <Route path="/artshipping" element={<Artsitartshipping />} />
            <Route path="/subscriptions" element={<Artistsubscription />} />
            <Route path="/news" element={<Artistnews />} />
            <Route path="/quoterequest" element={<Artistquoterequest />} />
            <Route path="/recreate" element={<Recreateartist />} />
            <Route path="/buyeroffer" element={<Buyerartist />} />
            <Route path="/adminoffer" element={<Adminoffer />} />
            <Route path="/inquiry" element={<Totalenquiryartist />} />
            <Route path="/myinquiry" element={<Artistenquiryartist />} />
            <Route path="/userdispute/:id" element={<BuyerDisputeDetails />} />
            <Route path="/earnings" element={<Earningartist />} />
            <Route path="/notification" element={<CuratorNotification/>} />
            <Route path="/exhibitionartist" element={<Exshibitionartist />} />
            <Route path="/auctions" element={<Auctionartist />} />
            <Route path="/orders" element={<Orderartist />} />
            {/* <Route path="/orders/:id" element={<Orderartist />} /> */}
            <Route path="/orderdetail/:id" element={<Orderartistdetail />} />
            <Route path="/dispute" element={<Disputartist />} />
            <Route path="/disputedetail" element={<Detailartistdispute />} />
            <Route path="/artisttestimonial" element={<ArtistTestimonial />} />
            <Route path="/myinquiry/atristinquirtdetails" element={<AtristInquirtDetails />} />
            <Route
              path="/upload/stepfirst"
              element={
                <Firstuploadartist
                  uploadArt={uploadArt}
                  setUploadArt={setUploadArt}
                  handleChangedata={handleChangedata}
                  src={src}
                  setSrc={setSrc}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                />
              }
            />
            <Route
              path="/upload/stepsecond"
              element={
                <Seconduploadartist
                  uploadArt={uploadArt}
                  setUploadArt={setUploadArt}
                  handleChangedata={handleChangedata}
                  handleChangedataCategories={handleChangedataCategories}
                  src={src.main}
                  handleChangeOrientation={handleChangeOrientation}
                  category={category}
                  setCategory={setCategory}
                />
              }
            />
            <Route
              path="/upload/stepthird"
              element={
                <Digitalart
                  uploadArt={uploadArt}
                  setUploadArt={setUploadArt}
                  handleChangedata={handleChangedata}
                  category={category}
                  // src={src.main}
                />
              }
            />
            <Route
              path="/edit/stepfirst"
              element={
                <Firstuploadartist
                  uploadArt={uploadArt}
                  setUploadArt={setUploadArt}
                  handleChangedata={handleChangedata}
                  src={src}
                  setSrc={setSrc}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                />
              }
            />
            <Route
              path="/edit/stepsecond"
              element={
                <Seconduploadartist
                  uploadArt={uploadArt}
                  setUploadArt={setUploadArt}
                  handleChangedata={handleChangedata}
                  handleChangedataCategories={handleChangedataCategories}
                  src={src.main}
                  handleChangeOrientation={handleChangeOrientation}
                 category={category}
                  setCategory={setCategory}
                />
              }
            />
            <Route
              path="/edit/stepthird"
              element={
                <Digitalart
                  uploadArt={uploadArt}
                  setUploadArt={setUploadArt}
                  handleChangedata={handleChangedata}
                  category={category}
                  // src={src.main}
                />
              }
            />
            {/* <Route
    path="/upload/stepthird"
    element={
     <Digitalart
      uploadArt={uploadArt}
      setUploadArt={setUploadArt}
      handleChangedata={handleChangedata}
     /> */}
            {/* }
   /> */}
            <Route
              path="/upload/digitalart"
              element={
                <Thirduploadartist
                  uploadArt={uploadArt}
                  setUploadArt={setUploadArt}
                  handleChangedata={handleChangedata}
                />
              }
            />
            <Route
              path="/upload/details"
              element={
                <Detailuploadartist
                  uploadArt={uploadArt}
                  setUploadArt={setUploadArt}
                  handleChangedata={handleChangedata}
                />
              }
            />
            <Route path="/auctions/:id" element={<Auctionartistdetail />} />
            <Route
              path="/exhibitionartist/:id"
              element={<Exshibitiondetailartist />}
            />
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/thirdsecond" element={<Thirdsecond />} />
            <Route path="/profile" element={<Editprofileartist />} />
            <Route path="*" element={<UserMain />} />
          </>
        )}
      </Routes>
    </>
  );
}
