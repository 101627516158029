import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
// import Divider from '@mui/material/Divider';
import React from "react";
import styles from "./Artartisttable.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import serverimage from "../../../serverConstant";
import homeImage from "../../../constant";
import CircularLoader from "../../../common/loader/Loader";
import dateFormat from "../../../utils/dateFormat";
// import profileSlice, { addData } from '../../../../../State/Features/Artist/profileSlice';

export default function ArtartistTable(props) {
 
  
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [threedot_id, setThreedot_id] = React.useState("");
  // const dispatch = useDispatch();
  // const data = useSelector((state)=>state.artistdata);

  const loader = useSelector((state) => state.loader);
  const threedots = Boolean(anchorEl);
  const handleClick = (event, id) => {
    props.setArtId(id);
    setAnchorEl(event.currentTarget);
    setThreedot_id(event.target.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const handlePopup = (e) => {
  //
  //   props.setOpen && props.setOpen(true);
  //   props.setPoptype && props.setPoptype("addfeature");
  // };
  const handleProfilepage = (id, name, row) => {
    // const path = window.location.pathname;
    // if (path === "/arts") {
    // navigate(`/art/${id}`);
    navigate(`/art/${id}`);
    // }
    // props.setPage('profile')
    // props.setPendingProfileData({ artist_name: name });
    // dispatch(addData(row));
  };
  console.log("Props data===>Artist", props);

  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ width: "100%" }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  align="center"
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "15px",
                    textTransform: "capitalize",
                    height: "18px",
                    color: "#1C1C1C",
                    textAlign: column.align || "center",
                  }}
                  
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props.data &&
                props.data.map((row, i) => (
                  <TableRow
                    value={row.artist_name}
                    style={{ color: "#636365", cursor: "pointer" }}
                    key={i}
                  >
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                    // onClick={() => handleProfilepage(row._id)}
                    >
                      {props?.activetab === "artist_art_pending" ? (
                        <img
                          src={`${row?.image}`}
                          height="42px"
                          width="60px"
                          alt="img"
                        />
                      ) : (
                        <img
                          src={`${row?.image}`}
                          height="42px"
                          width="60px"
                          alt="img"

                        />
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        textTransform:"capitalize"
                      }}
                      className={styles.table_column}
                      align="left"

                    >
                      <span onClick={() =>
                        navigate(`/user/newestpaintings/${row?._id}`)
                      }>
                        {row.name}
                      </span>
                      {props.activetab === "quoterequest"
                        ? row.first_name + " " + row.last_name
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                      {row?.sale?.price ? "$" + row.sale?.price : "----"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                      {row?.rent?.rent_price
                        ? "$" + row.rent.rent_price
                        : "----"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                      {row?.print?.[0]?.print_price
                        ? "$" + row.print[0].print_price
                        : "----"}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                      {dateFormat(row?.createdAt)}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                      }}
                      className={styles.column_gallery_artist}
                      align="right"
                    >
                      <div className={styles.column_gallery_artist_true}>
                        {row?.sale?.qty || "----"}

                        {props.activetab !== "quoterequest" && (
                          <>
                            <Tooltip title="Account settings">
                              <IconButton
                                onClick={(event) => handleClick(event, row._id)}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={
                                  threedots ? "account-menu" : undefined
                                }
                                // id={row._id}
                                aria-haspopup="true"
                                aria-expanded={threedots ? "true" : undefined}
                              >
                                <img
                                  src={"/img/Menu Icon.png"}
                                  height="21.62px"
                                  width="4.63px"
                                  alt="img"
                                  id={row._id}
                                />
                              </IconButton>
                            </Tooltip>
                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={threedots}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  width: "auto",
                                  overflow: "visible",
                                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                  filter:
                                    "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                  mt: 2.5,
                                  "& .MuiAvatar-root": {
                                    width: "120px",
                                    height: "132px",
                                    ml: 0.5,
                                    mr: 1,
                                    // mt:5
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    // horizontal: 'center',
                                    right: 40,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "center",
                                vertical: "right",
                              }}
                              anchorOrigin={{
                                horizontal: "left",
                                vertical: "center",
                              }}
                            >
                              {/* <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                              }}
                            >
                              Add in Art Smiley Offer
                            </MenuItem> */}
                              {/* <Divider /> */}
                              { }
                              {props.activetab !== "artist_art_rejected" && (
                                <>
                                  <MenuItem
                                    onClick={() => {
                                      props.setOpen(true);
                                    }}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      fontSize: "16px",
                                      lineHeight: "12px",
                                      fontWeight: "400",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    Edit Art
                                  </MenuItem>

                                  <Divider />
                                </>
                              )}

                              <MenuItem
                                onClick={() => props.setOpen(row?._id)}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Delete Art
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </div>
                    </TableCell>
                    {/* {props.activetab === "quoterequest" ? (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#35BE43",
                        }}
                        className={styles.table_column}
                        // align="left"
                      >
                        <div className={styles.quoterequest}>
                          {row.is_accepted && row.is_accepted === "Approved" ? (
                            <span> Accepted</span>
                          ) : row.action === "Rejected" ? (
                            <span className={styles.rejected}> Rejected</span>
                          ) : (
                            <>
                              <Button
                                onClick={() => props.handlePopupData(row)}
                                variant="contained"
                                sx={{
                                  width: "80px",
                                  height: "34px",
                                  borderRadius: "4px",
                                }}
                              >
                                Accept
                              </Button>
                              <Button
                                onClick={() => props.handlePopupData(row)}
                                variant="outlined"
                                sx={{
                                  width: "80px",
                                  height: "34px",
                                  borderRadius: "4px",
                                }}
                              >
                                Decline
                              </Button>
                            </>
                          )}
                        </div>
                      </TableCell>
                    ) : (
                    <>
                    </>
                    )} */}
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}
