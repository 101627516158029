import { Button } from "@mui/material"
import { CSVLink } from "react-csv"
import serverimage from "../serverConstant"

export default function CsvfileFAQ(props) {
  console.log(props, "props-=-")
  const exshibitionData = []
  //  props.exshibitiondata.forEach((item) => {
  //   exshibitionData.push({
  //    _id: item._id,
  //    is_accepted: item.is_accepted,
  //    start_date: item.start_date,
  //    end_date: item.end_date,
  //    is_paid: item.is_paid,
  //    artist_name: Object.assign({}, item.artist), // {0:"a", 1:"b", 2:"c"}
  //    artist: item.artist.reduce((obj, cur) => ({ ...obj, ["name"]: cur.first_name+" " + cur.last_name }), {}),
  //    artists: item.artist.reduce((a, b) => Object.assign(a, b), {}),
  //   });
  //   // item.artist.forEach(val => (val.first_name +" "+ val.last_name))
  //   //     for (let i = 0; i < item.artist.length ===0? 0: item.artist.length; i++) {
  //   //         // const role = item.suggestedRoles[i];
  //   //         // data.push({
  //   //         //     name: '',
  //   //         //     description: '',
  //   //         //     suggestedRoles: role.name
  //   //         // });
  //   //         const index = item.artist[i]
  //   //         exshibitionData.push({
  //   //             _id: item._id,
  //   //             is_accepted: item.is_accepted,
  //   //             start_date:item.start_date,
  //   //             end_date:item.end_date,
  //   //             is_paid:item.is_paid,
  //   //             artist_name: {first_name:index.first_name}+" "+{last_name:index.last_name}
  //   //         });
  //   //     }
  //  });
  props.FAQ_data.forEach((item) => {
    exshibitionData.push({
      _id: item._id,
      question: item.question,
      answer: item.answer,
      faq_type: item.faq_type,
    })
  })
  console.log(
    exshibitionData.map((val) => val),
    "00909"
  )
  //  console.log(exshibitionData.map((val) => val.artist_name && val.artist_name));
  const header = [
    { label: "Question", key: "question" },
    { label: "Answer", key: "answer" },
    { label: "user_type", key: "user_type" },
    { label: "faq_type", key: "faq_type" },
  ]
  const head = [
    { label: "Id", key: "_id" },
    { label: "Image", key: "image" },
    { label: "Buy Price", key: "buy_price" },
    { label: "Name", key: "name" },
    { label: "Rent Price", key: "rent_price" },
    { label: "Print Price", key: "print_price" },
    { label: "Most Popular", key: "is_most_popular" },
    { label: "Best Seller", key: "is_best_seller" },
    { label: "Print Price", key: "print_price" },
    { label: "Artist Name", key: "artist_name" },
  ]
  //  artist && artist.map(val => val.first_name)
  const exshibitionDatas = exshibitionData
  const csvLink = {
    filename: "file.csv",
    headers: header,
    data: exshibitionDatas,
  }
  return (
    <>
      <CSVLink
        {...csvLink}
        className="download_csv"
        target="_blank" // Optional to open in a new tab
        filename="file.csv"
      >
        <button
          className="action_btn"
        >
          Upload FAQ
        </button>
      </CSVLink>
    </>

    //   <CSVLink data={data} headers={headers}>
    //    Download me
    //   </CSVLink>
  )
}
