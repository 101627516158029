import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './Userinquiry.module.css';
import api from '../../../../../State/api/Api';
import TableForInquiry from '../Tables/TableForInquiry';


const exhibitionhead = [
   {
      id: 'artist_name ',
      label: 'Inquiry ID',
   },
   {
      id: 'mobile_number',
      label: 'Customer Name',
   },
   {
      id: 'product',
      label: 'Product Details',
   },
   {
      id: 'date',
      label: 'Inquiry Date',
   },
   // {
   //    id: 'assign_to',
   //    label: 'Description',
   // },   
   {
      id: 'status',
      label: 'Status',
   },
   {
      id: 'action',
      label: 'Action',
   },

]
export default function UserInquiry() {
   const[sortedValue, setSortedValue] = useState(10)  
  const [inquiries, setInquiries] = useState([])  

    const handleFetchAllInquiries = async () => {
      try {
        const {data} = await api.applicationApi("api/enquiry/fetchEnquiries","POST",{
         // "is_open":"Open", //Optional
         // "limit":sortedValue
     }
     )
        setInquiries(data.data.enquiries)
      }catch(error) {
         console.log(error)
      }
    }
    useEffect(() => {
     handleFetchAllInquiries()
    },[sortedValue])
   return (
      <div className={styles.mains}>
         <div className={styles.components}>
            <div className={styles.table_header}>
               <FormControl>
                  <Select
                     value={sortedValue}
                     onChange={(e) => setSortedValue(e.target.value)}
                     displayEmpty
                     inputProps={{ 'aria-label': 'Without label' }}
                     sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                         border: "none",
                        },
                        "& .MuiInputBase-input":{
                         padding:".45rem .75rem",
                         height:"auto",
                         borderRadius:".25rem",
                         fontSize:"16px",
                         fontWeight:"400"
                        },
                        border:"1px solid #ced4da",
                        borderRadius:".25rem",
                        background:"#fff"
                      }}
                  >
                     <MenuItem value="">
                        Sort by
                     </MenuItem>
                     <MenuItem value={10}>10</MenuItem>
                     <MenuItem value={20}>20</MenuItem>
                     <MenuItem value={30}>30</MenuItem>
                     <MenuItem value={40}>40</MenuItem>
                     <MenuItem value={50}>50</MenuItem>
                     <MenuItem value={60}>60</MenuItem>
                     <MenuItem value={70}>70</MenuItem>
                     <MenuItem value={80}>80</MenuItem>
                     <MenuItem value={90}>90</MenuItem>
                     <MenuItem value={100}>100</MenuItem>
                  </Select>
               </FormControl>
            </div>
            <TableForInquiry 
            activetab={"buyer_enquiry"}
            head={exhibitionhead} 
            data={inquiries} />
         </div>
      </ div >
   )
}
