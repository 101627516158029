import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import { removeUser } from "../../../State/Features/common/userSlice";
import styles from "./Header.module.css";
import homeImage from "../../../constant";
import {
  Badge,
  Menu,
  MenuItem,
  Select,
  Container,
} from "@mui/material";
import FavoriteBorderSharpIcon from "@mui/icons-material/FavoriteBorderSharp";
import { fetchCountry } from "../../../State/api/artistapi";
import country from "../../../Country";
import { setDeliveryCountry } from "../../../State/Features/common/countrySlice";
import availableCurrencies from "../../../DeliveryCountry";
import Webpopup from "../../../gallery/components/popups/galleryWeb/Webpopup";
import WebpopupArtistAndGallery from "../../../gallery/components/popups/galleryWeb/WebpopupArtistAndGallery";
import WebpopupCuratorAndAffiliate from "../../../gallery/components/popups/galleryWeb/WebpopupCuratorAndAffiliate";
import { useTranslation } from 'react-i18next';
import TranslateWrapper from "../../../languageTranslator/TranslateWrapper";
import GoogleTranslate from "../../../GoogleTranslate";
import { FiMail, FiPhoneCall, FiMapPin, FiUser, FiHeart } from "react-icons/fi";
export default function Header() {
  //Select the languages for entire web
  // const [language, setLanguage] = React.useState("");

  // const handleChangelanguage = (event) => {
  //   setLanguage(event.target.value);
  // };

  const languages = [
    { code: "en", lang: "English" },
    { code: "hi", lang: "Hindi" },
    { code: "fr", lang: "French" },
    { code: "es", lang: "Spanish" },
    { code: "zh", lang: "Chinese" },
    { code: "ar", lang: "Arabic" },
  ];

  const {t,i18n} = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("selectedLanguage") || "en"
  );

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage, i18n]);

  const handleChangeLanguageOption = (event) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
  };

  const [delivery_cntry, setDelivery_cntry] = useState(
    "india"
    //   {
    //   countryCode: "IN",
    //   currencyCode: "INR",
    //   displayName: "India",
    //   exchangeRate: 81.64,
    //   locale: "hi-IN"
    // }
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [signPop, setSignPop] = useState(false);
  const [artistPop, setArtistPop] = useState(false);
  const [artistPopSign, setArtistPopSign] = useState(false);
  const [curatorPop, setCuratorPop] = useState(false);
  const [curatorPopSign, setCuratorPopSign] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChange = (event) => {
    setDelivery_cntry(event.target.value);
    const value = event.target.value;
    dispatch(
      setDeliveryCountry({
        country_code: value.locale,
        currency: value.currencyCode,
      })
    );
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usertype = useSelector((state) => state.user.data);

  useEffect(() => {
    // dispatch(fetchCountry(setCountry));
  }, []);

  const user = useSelector((state) => state.user.data);
  const userwishlist = useSelector((state) => state.wishlistData);
  const handleSignupbtn = () => {
    setSignPop(true);
    // navigate("/artsmiley/signup");
    // window.location.reload();
  };
  const handleHoverOnProfile = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleHoverOutOnProfile = (event) => {
    setAnchorEl(null);
  };
  const handleOnclickProfile = (val) => {
    setAnchorEl(null);
    if (val === "apply") {
      navigate("/applynow");
    } else {
      navigate("/dashboard");
    }
  };
  const handleSignoutbtn = () => {
    setAnchorEl(null);
    localStorage.removeItem("login_token");
    localStorage.removeItem("usertype");
    localStorage.removeItem("user_id");
    localStorage.removeItem("password");
    localStorage.removeItem("is_accepted");
    localStorage.removeItem("user_name");
    localStorage.removeItem("__paypal_storage__");
    localStorage.removeItem("buyer_back_url");
    dispatch(
      removeUser(
        {
          signed: false,
          type: "",
          data: "",
        },
        navigate("/")
      )
    );
    window.location.reload();
  };

  // const { setLanguage: changeLanguage } = useTranslation(); // Renaming to avoid conflict
  // const [language, setLanguage] = useState("english"); // Default language set to English
  // const languageOptions = {
  //   english: { label: "English", code: "en" },
  //   arabic: { label: "Arabic", code: "ar" },
  //   french: { label: "French", code: "fr" },
  //   spanish: { label: "Spanish", code: "es" },
  //   chinese: { label: "Chinese", code: "zh" },
  //   hindi: { label: "Hindi", code: "hi" },
  // };
  // useEffect(() => {
  //   const savedLanguage = localStorage.getItem("selectedLanguage");
  //   if (savedLanguage && languageOptions[savedLanguage]) {
  //     setLanguage(savedLanguage);
  //     changeLanguage(languageOptions[savedLanguage].code); // Set the language
  //   } else {
  //     // Default to English
  //     changeLanguage(languageOptions.english.code);
  //   }
  // }, [changeLanguage]);

//  const handleLanguageChange = (event) => {
//     const selectedLanguage = event.target.value;
//     setLanguage(selectedLanguage);
//     changeLanguage(languageOptions[selectedLanguage].code); // Update the translation language
//     localStorage.setItem("selectedLanguage", selectedLanguage); // Save language to localStorage
//   };

  

  return (
    <div className={styles.mains}>
      {signPop ? (
        <Webpopup
          open={signPop}
          setOpen={setSignPop}
          setArtistPop={setArtistPop}
          setCuratorPop={setCuratorPop}
          setArtistPopSign={setArtistPopSign}
          setCuratorPopSign={setCuratorPopSign}
        />
      ) : (
        ""
      )}
      {artistPop ? (
        <WebpopupArtistAndGallery
          open={artistPop}
          setOpen={setArtistPop}
          setArtistPopSign={setArtistPopSign}
        />
      ) : (
        ""
      )}
      {artistPopSign === "ArtistAndGallerySignIn" ? (
        <WebpopupArtistAndGallery
          open={artistPopSign}
          setOpen={setArtistPopSign}
          setArtistPopSign={setArtistPopSign}
        />
      ) : (
        ""
      )}
      {curatorPop ? (
        <WebpopupCuratorAndAffiliate
          open={curatorPop}
          setOpen={setCuratorPop}
          setSignPop={setSignPop}
          setCuratorPopSign={setCuratorPopSign}
        />
      ) : (
        ""
      )}
      {curatorPopSign === "AffiliateAndCuratorSignIn" ? (
        <WebpopupCuratorAndAffiliate
          open={curatorPopSign}
          setOpen={setCuratorPopSign}
          setSignPop={setSignPop}
        />
      ) : (
        ""
      )}
      <div className={styles.header_main}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={styles.tophercont}>
        <div className={styles.flex_first}>
          <ul>
            <li><a href="mailto:contactus@artsmiley.com"> <FiMail className={styles.iconse} /> contactus@artsmiley.com</a></li>
            <li><a href="tel:0506165331"><FiPhoneCall className={styles.iconse} /> 0506165331</a></li>
          </ul>
         
          
        </div>
        <div className={styles.flex_second}>
          <ul>
            <li>
              <Link><FiMapPin className={styles.iconse} />
              {t('delivery_to')} :
              <Select
  labelId="demo-select-small-label"
  id="demo-select-small"
  value={delivery_cntry}
  onChange={handleChange}
  size="small"
  sx={{
    color: "white",
    "& .MuiSelect-select": { // Target the internal select input
      padding: "0 2px!important",  // Remove padding
    },
    "& .MuiSelect-icon":{
      display:"none"
    },
  }}
>
  <MenuItem
    disabled
    value="india"
  >
    {t('india')}
  </MenuItem>
  {availableCurrencies.map((v) => (
    <MenuItem
      key={v.value}
      value={v}
    >
      <TranslateWrapper>{v.displayName}</TranslateWrapper>
    </MenuItem>
  ))}
</Select>
</Link>
            </li>
            {user.signed && localStorage.getItem("login_token") ? (
              <>
              <li>
              <Link
                  className={styles.signup_btn}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleHoverOnProfile}
                  // onMouseEnter={handleHoverOnProfile}
                  // onMouseOut={handleHoverOutOnProfile}
                  // onMouseLeave={handleHoverOutOnProfile}
                  // onMouseDown={handleHoverOutOnProfile}

                  sx={{ color: "white" }}
                ><FiUser className={styles.iconse} />
                  <TranslateWrapper>{localStorage.getItem("user_name")}</TranslateWrapper>
                </Link>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{ left: "35px", top: "5px" }}
                >
                  {localStorage.getItem("usertype") === "buyer" ? (
                    <MenuItem onClick={() => handleOnclickProfile("profile")}>
                      {t('profile')}
                    </MenuItem>
                  ) : localStorage.getItem("is_accepted") === "Pending" ? (
                    <MenuItem onClick={() => handleOnclickProfile("apply")}>
                      {t('apply')}
                    </MenuItem>
                  ) : (
                    <MenuItem onClick={() => handleOnclickProfile("profile")}>
                      {t('profile')}
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleSignoutbtn}>{t('log_out')}</MenuItem>
                </Menu>
              </li>
              </>
            ) : (
              <>
              <li>
              <Link to="/login"
      state={{ type: "buyer" }} className={styles.signup_btn}>
              <FiUser className={styles.iconse} /> {t('sign_In')}
              </Link>
              </li>
              <li>
              <Link onClick={handleSignupbtn} className={styles.signup_btn}>
              {t('sign_up')}
              </Link>
              </li>
              </>
            )}
            <li>
            <Link
            to={"/user/wishlist"}
            className={styles.wishic}
          >
            <Badge
              badgeContent={userwishlist.data && userwishlist.data.length}
              color="secondary"
              sx={{ ml: "-2", cursor: "pointer" }}
            >
              <FiHeart  className={styles.iconse} />
            
            </Badge>
            {/* <img src={ "/img/Heart.png"} width="20px" height="18px" /> */}
          </Link>
            </li>
            <li>
            <div className={styles.langslect}>
            <img
              src={"/img/Emerican Language.png"}
              width="20px"
              height="18px"
              alt="img"
            />
            {/* <p>English</p> */}
            <Select
      labelId="language-select-label"
      id="language-select"
      value={selectedLanguage}
      onChange={handleChangeLanguageOption}
      size="small"
      sx={{
        color: "white",
        "& .MuiSelect-select": { // Target the internal select input
          padding: "0 2px 0 5px!important",
          lineheight: "21px", // Remove padding
        },
        "& .MuiSelect-icon":{
      display:"none"
    },
      }}
    >
      {languages.map((item) => (
                        <MenuItem key={item.code} value={item.code}>
                          {item.lang}
                        </MenuItem>
          ))}
    </Select>
            <div>{/* <GoogleTranslate /> */}</div>
          </div>
            </li>
          </ul>
         
          
        </div>
        </div>
        </Container>
      </div>
    </div>
  );
}
