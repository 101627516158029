import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  
} from "@mui/material"
import {FiEye } from "react-icons/fi";

// import Divider from '@mui/material/Divider';
import React, { useState } from "react"
import styles from "./CuratorEnquiryTable.module.css"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import CircularLoader from "../../../common/loader/Loader"
import dayjs from "dayjs"


export default function CuratorEnquiryTable(props) {
 
   const loader = useSelector((state) => state.loader)
 

  
  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ width: "100%" }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  align="center"
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "15px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props.data &&
                props.data.map((row, i) => (
                  <TableRow
                  
                    style={{ color: "#636365", cursor: "pointer" }}
                    key={i}
                  >
                   

                    <TableCell
                    
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                      {i + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                         cursor:'default'
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                      {row?.enquirytype}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                          cursor:'default'
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                        {row?.enquirysubtype}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        cursor:'default'
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                     {row?.subject}
                    </TableCell>

                  

                    <TableCell
                      sx={{
                        width: "25%",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                         cursor:'default'
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                      {row?.status}
                    </TableCell>

                    <TableCell
                      sx={{
                        width: "25%",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                         cursor:'default'
                      }}
                      className={styles.table_column}
                      align="center"
                    
                    >
                        <span style={{ marginRight: "18px" }}>
                           {dayjs(row?.createdAt).format("DD-MM-YYYY").toString()}
                          
                        </span>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "25%",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                         cursor:'default'
                      }}
                      className={styles.table_column}
                      align="center"
                    
                    >
                      <div className={styles.curator_order}>
                      <Link
                              className={styles.hyperlink_title}
                              to={`/enquiry/${row._id}`}
                            >
                      <FiEye

                                className={styles.viewIcon}
                              />
                     </Link>
                      </div>
                    </TableCell>
                   
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  )
}
