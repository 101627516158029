import React, { useState } from "react"
import styles from "./Entermobile.module.css"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

export default function EntermobileArtist({value, handleChangeNumber, error}) {
  const [mobile, setMobile] = useState()

  const handleChangeCode = (e) => {
    setMobile(e)
    console.log(e)
  }

    return (
      <div>
      <PhoneInput
        country={"in"} // Default country as India (can be changed)
        value={value}
        autoFormat={true}
        disableSearchIcon={true}
        countryCodeEditable={false}
        inputProps={{
          name: "phone",
          required: true,
        }}
        buttonStyle={
            // props.border && props.border === 'applyform' ?
            // {}
            // :
            {
              paddingLeft: "0px",
            
         
              color: "#636365",
       
            }
          }
          containerStyle={{
            width: "100%", 
            borderRadius: "1px",
            direction: "ltr", // Ensure proper left-to-right alignment
          }}
          dropdownStyle={{
            width: "250px",
            textAlign:"left"
            // marginLeft: "-10px", // Adjust this as needed to align correctly
          }}
          // dropdownStyle={{width:'200px',padding:'30px',borderColor:'red'}}
          inputStyle={
            //     props.border && props.border === 'applyform' ? {
            //     // paddingLeft:'78px',
            //     width: '100%',
            //     backgroundColor: '#f6f6f6',
            //     height: '34px',
            //     outlined: 'true',
            //     borderRadius: '0px',
            //     '&:focus': {
            //         border: '1px solid black'
            //     },
            // } :
            {
              // paddingLeft:'78px',
              width: "100%",
           
              color: "#636365",
              outlined: "true", 
              "&:focus": {
                border: "1px solid black",
              },
            }
          }
        placeholder="1234567890"
        onChange={handleChangeNumber}
      />

    </div>
    )

}
