import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./Tablefivecol.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addData } from "../../../../../State/Features/Artist/profileSlice";
import CircularLoader from "../../../../../common/loader/Loader";
import moment from "moment";
import dayjs from "dayjs";
import { FiTrash2, FiEdit3 } from "react-icons/fi";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { PropaneSharp } from "@mui/icons-material";
import { handleArtistTypeChange } from "../../../../../State/api/superadminApi";
export default function Tablesfivecol(props) {
  console.log("props--SuperAdmin TableFivecol ", props);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const loader = useSelector((state) => state.loader);
  const [threedot_id, setThreedot_id] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const threedots = Boolean(anchorEl);

  console.warn("anchorEl", anchorEl)
  const handleClick = (event) => {
    console.log("anchorEvent", event)
    setAnchorEl(event.currentTarget);
    setThreedot_id(event.target.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handledetailpage = (row, isExhibitionTitle = false) => {
    if (isExhibitionTitle && location.pathname === "/exhibitions") {

      navigate(`/exhibitions/${row._id}`);
      return;
    }

    // Default row navigation
    if (location.pathname === "/artist") {
      navigate(`/artist/${row._id}`);
    } else if (location.pathname === "/affiliate") {
      navigate(`/affiliate/${row._id}`);
    } else if (location.pathname === "/exhibitions") {
      navigate(`/exhibitions/${row._id}`);
    } else if (location.pathname === "/career") {
      navigate(`/career-detail/${row._id}`);
    }
    dispatch(addData(row));
  };

  const usertype = useSelector((state) => state.user.data);

  const [selectedArtistTypes, setSelectedArtistTypes] = useState({});
  // useEffect(() => {
  //   if (Array.isArray(props.data)) {
  //     const initialArtistTypes = {};
  //     props.data.forEach((artist) => {
  //       // Extract the first name from the artist_type array or set as an empty string
  //       initialArtistTypes[artist._id] = artist.artist_type?.[0]?.name || "";
  //     });
  //     setSelectedArtistTypes(initialArtistTypes);
  //   }
  // }, []);
  useEffect(() => {
    if (Array.isArray(props.data) && props.data.length > 0) {
      const initialArtistTypes = {};  
      props.data.forEach((artist) => {
  
        // Check if artist has a nested artist array
        if (Array.isArray(artist.artist) && artist.artist.length > 0) {
          const firstArtist = artist.artist[0];  // Assuming you want the first artist in the array
  
          // Now access the artist_type from the first artist in the array
          if (Array.isArray(firstArtist.artist_type) && firstArtist.artist_type.length > 0) {
            const artistTypeName = firstArtist.artist_type[0].name;
            initialArtistTypes[artist._id] = artistTypeName;  // Use the name property here
          } else {
            initialArtistTypes[artist._id] = "Not Available";  // Default value
          }
        } else {
          initialArtistTypes[artist._id] = "Not Available";  // Default value
        }
      });
  
      console.log('initialArtistTypes:', initialArtistTypes);
      setSelectedArtistTypes(initialArtistTypes);
    } else {
      console.log('props.data is not an array');
    }
  }, [props.data]);
  

  // Handle change of the dropdown value for each artist
  // const handleArtistTypeChange = (event, artistId) => {
  //   const newValue = event.target.value;

  //   // Update the state with the new value for the specific artist
  //   setSelectedArtistTypes((prev) => ({
  //     ...prev,
  //     [artistId]: newValue,
  //   }));
  // };
const handleArtistTypeChanged = (event, artistId) => {
    const newValue = event.target.value;

    // Update the state with the new value for the specific artist
    setSelectedArtistTypes((prev) => {
      const updatedState = { ...prev, [artistId]: newValue };
      return updatedState;
    });

    dispatch(handleArtistTypeChange(artistId,newValue))
    
  };
  
  
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;


  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [props.activetab]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = props.data?.slice(startIndex, endIndex);

  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  className={styles.table_head}
                  align={props.head.length - 1 === i ? "left" : "left"}
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "15px",
                    textTransform: "capitalize",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                currentItems &&
                // currentItems
                //   ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                (props.activetab === "upcoming" ||props.activetab=== "pending" || props.activetab === "auctionlive" || "past" || props.activetab==='exhibitionupcoming' || props.activetab==='exhibitionpast'||  props.activetab==='AdminAuctionlive'
                  ? currentItems
                  : ([...currentItems].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
                )
                  ?.map((row, i) => (
                    <TableRow
                      style={{ color: "#636365", height: "30px" }}
                      className={styles.table_row}
                      key={row._id}
                    >
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                          textTransform: "capitalize",
                        }}
                        style={{ cursor: "pointer" }}
                        className={styles.table_column}
                        align="left"
                      >
                        {/* Name fields */}
                        {props.activetab === "gallery_approved" ||
                          props.activetab === "gallery_decline" ? (
                          <>
                            {row.first_name} {row.last_name}
                          </>
                        ) : (
                          ""
                        )}

                        {/* Gallery name */}
                        {row.gallery_name}

                        {/* Hyperlink for exhibition titles */}
                        {props.activetab === "upcoming" ||props.activetab === "past" || props.activetab ==='exhibitionpast' || props.activetab==='exhibitionupcoming' ? (
                          <a
                            href={`/exhibitions/${row._id}`} // Dynamic href for the title link
                            onClick={(e) => {
                              e.preventDefault(); // Prevent default navigation for controlled behavior
                              handledetailpage(row, true); // Pass the exhibition title flag
                            }}
                            className={styles.hyperlink_title}
                          >
                            {row?.title}
                          </a>
                        ) : (
                          ""
                        )}
                           {props.activetab === "AdminAuctionlive"
                         
                         ? i + 1
                         : ''}

                        {/* Auction name */}
                        {props.activetab === "auctionlive" &&
                          usertype.type === "curator"
                          ? i + 1
                          : ''}

                     

                        {/* Additional fields */}
                        {props.activetab === "pending" ? (
                          row.first_name && row.last_name ? (
                            <Link
                              className={styles.hyperlink_title}
                              to={`/artists/${row._id}`}
                            >
                              {row.first_name + " " + row.last_name}
                            </Link>
                          ) : row.first_name || row.last_name ? (
                            <a href={`/artists/${row._id}`}>
                              {row.first_name || row.last_name}
                            </a>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        {props.activetab === "request"
                          ? row.start_date &&
                          moment(row.start_date).format("DD-MM-YYYY")
                          : ""}
                        {props.activetab === "decline" ? (
                          <Link to={`#`} className={styles.hyperlink_title}>
                            {row.first_name + " " + row.last_name}
                          </Link>
                        ) : (
                          ""
                        )}
                        {props.activetab === "affiliatedecline" ? (
                          <Link
                            to={`/affiliate/${row?._id}`}
                            className={styles.hyperlink_title}
                          >
                            {row.first_name + " " + row.last_name}
                          </Link>
                        ) : (
                          ""
                        )}
                        {props.activetab === "affiliatepending" ? (
                          <Link
                            to={`/affiliate/${row?._id}`}
                            className={styles.hyperlink_title}
                          >
                            {row.first_name + " " + row.last_name}
                          </Link>
                        ) : (
                          ""
                        )}
                        {props.activetab === "affiliateapproved" ? (
                          <Link
                            to={`/affiliate/${row?._id}`}
                            className={styles.hyperlink_title}
                          >
                            {row.first_name + " " + row.last_name}
                          </Link>
                        ) : (
                          ""
                        )}
                        {props.activetab === "contact-us"
                          ? row.first_name + " " + row.last_name
                          : ""}
                        {props.activetab === "printpricing" ? row.material : ""}
                      </TableCell>

                      {props.activetab === "career" ? (
                        <>
                          <TableCell
                            sx={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "21px",
                              color: "#636365",
                            }}
                            className={styles.table_column}
                            align="left"
                          >
                            {moment(row?.createdAt)?.format("DD-MM-YYYY")}
                          </TableCell>
                        </>
                      ) : (
                        ""
                      )}

                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                          textTransform:"capitalize"
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {/* {props.activetab === "gallery_approved" ||
                        "gallery_decline"
                          ? row.country
                          : row.phone} */}

                        {props.activetab === "gallery_approved"
                          ? row.first_name + " " + row.last_name
                          : ""}
                        {props.activetab === "gallery_decline"
                          ? row.first_name + " " + row.last_name
                          : ""}
                        {props.activetab === "decline" ? row.phone : ""}
                        {props.activetab === "auctionlive" &&
                          usertype.type === "curator" ? (
                          <Link
                            className={styles.hyperlink_title}
                            to={`/auction/${row._id}`}
                          >
                            {row.name}
                          </Link>
                        ) : row.time_left ? (
                          row.time_left
                        ) : (
                          ""
                        )}
                       {props.activetab === "AdminAuctionlive"
                           ? (
                          <Link
                            className={styles.hyperlink_title}
                            to={`/auction/${row._id}`}
                          >
                            {row.name}
                          </Link>
                        ) : row.time_left ? (
                          row.time_left
                        ) : (
                          ""
                        )}
                        {props.activetab === "affiliatedecline"
                          ? row.phone
                          : ""}
                        {props.activetab === "affiliatepending"
                          ? row.phone
                          : ""}
                        {props.activetab === "affiliateapproved"
                          ? row.phone
                          : ""}
                        {props.activetab === "contact-us" ? row.phone : ""}
                        {props.activetab === "request"
                          ? row.end_date &&
                          moment(row.end_date).format("DD-MM-YYYY")
                          : ""}
                        {props.activetab === "career" && " " + row.location}
                        {props.activetab === "printpricing" ? row.product : ""}
                        {props.activetab === "pending" ? row.phone : ""}
                        {props.activetab === "decline" ? (
                          <div>
                            {row.invite_artists &&
                              row.invite_artists.map((vl) => (
                                <>
                                  {" "}
                                  {vl.first_name} {vl.last_name}
                                </>
                              ))}
                          </div>
                        ) : (
                          ""
                        )}
                        {/* {props.activetab === "exshibitionpast" ? (
                          <Select
                            value=""
                            // onChange={handleChange}
                            displayEmpty
                            // fullWidth
                            sx={{
                              background: "#f6f6f6",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-root": {
                                borderRadius: "6px",
                              },
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "21px",
                              color: "#636365",
                              height: "40px",
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Artists Name</MenuItem>
                            {row.invite_artists &&
                              row.invite_artists.map((val) => (
                                <MenuItem disabled key={val._id}>
                                  {val.first_name} {val.last_name}
                                </MenuItem>
                              ))}
                          </Select>
                        ) : (
                          ""
                        )} */}
                        {props.activetab === "exshibitiondecline" ? (
                          <Select
                            value=""
                            // onChange={handleChange}
                            displayEmpty
                            // fullWidth
                            sx={{
                              background: "#f6f6f6",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-root": {
                                borderRadius: "6px",
                              },
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "21px",
                              color: "#636365",
                              height: "40px",
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Artists Name</MenuItem>
                            {row.invite_artists &&
                              row.invite_artists.map((val) => (
                                <MenuItem disabled key={val._id}>
                                  {val.first_name} {val.last_name}
                                </MenuItem>
                              ))}
                          </Select>
                        ) : (
                          ""
                        )}
                        {/* {props.activetab === "exhibitionupcoming" ? (
                          <Select
                            value=""
                            // onChange={handleChange}
                            displayEmpty
                            // fullWidth
                            sx={{
                              background: "#f6f6f6",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-root": {
                                borderRadius: "6px",
                              },
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "21px",
                              color: "#636365",
                              height: "40px",
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Artists Name</MenuItem>
                            {row.invite_artists &&
                              row.invite_artists.map((val) => (
                                <MenuItem disabled key={val._id}>
                                  {val.first_name} {val.last_name}
                                </MenuItem>
                              ))}
                          </Select>
                        ) : (
                          ""
                        )} */}
                        {props.activetab === "exhibitionlive" ? (
                          <Select
                            value=""
                            // onChange={handleChange}
                            displayEmpty
                            // fullWidth
                            sx={{
                              background: "#f6f6f6",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-root": {
                                borderRadius: "6px",
                              },
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "21px",
                              color: "#636365",
                              height: "40px",
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Artists Name</MenuItem>
                            {row.invite_artists &&
                              row.invite_artists.map((val) => (
                                <MenuItem disabled key={val._id}>
                                  {val.first_name} {val.last_name}
                                </MenuItem>
                              ))}
                          </Select>
                        ) : (
                          ""
                        )}
                        {/* this is for curator panel exhibition tab */}
                        {props.activetab === "upcoming" || props.activetab ==='exhibitionupcoming'? (
                          // <Select
                          //   value=""
                          //   // onChange={handleChange}
                          //   displayEmpty
                          //   // fullWidth
                          //   sx={{
                          //     background: "#f6f6f6",
                          //     ".MuiOutlinedInput-notchedOutline": {
                          //       border: "none",
                          //     },
                          //     "& .MuiInputBase-root": {
                          //       borderRadius: "6px",
                          //     },
                          //     fontFamily: "Roboto",
                          //     fontStyle: "normal",
                          //     fontWeight: "400",
                          //     fontSize: "16px",
                          //     lineHeight: "21px",
                          //     color: "#636365",
                          //     height: "40px",
                          //   }}
                          //   inputProps={{ "aria-label": "Without label" }}
                          // >
                          //   <MenuItem value="">Artists Invited</MenuItem>
                          //   {row.invite_artists &&
                          //     row.invite_artists.map((val) => (
                          //       <MenuItem key={val._id}>
                          //         {val.first_name} {val.last_name}
                          //       </MenuItem>
                          //     ))}
                          // </Select>
                          <>
                            {row.is_paid && row.is_paid === true
                              ? "Paid"
                              : "Free"}
                          </>
                        ) : (
                          ""
                        )}
                        {props.activetab === "past" || props.activetab === "exhibitionpast"? (
                          // <Select
                          //   value=""
                          //   // onChange={handleChange}
                          //   displayEmpty
                          //   // fullWidth
                          //   sx={{
                          //     background: "#f6f6f6",
                          //     ".MuiOutlinedInput-notchedOutline": {
                          //       border: "none",
                          //     },
                          //     "& .MuiInputBase-root": {
                          //       borderRadius: "6px",
                          //     },
                          //     fontFamily: "Roboto",
                          //     fontStyle: "normal",
                          //     fontWeight: "400",
                          //     fontSize: "16px",
                          //     lineHeight: "21px",
                          //     color: "#636365",
                          //     height: "40px",
                          //   }}
                          //   inputProps={{ "aria-label": "Without label" }}
                          // >
                          //   <MenuItem value="">Artists Invited</MenuItem>
                          //   {row.invite_artists &&
                          //     row.invite_artists.map((val) => (
                          //       <MenuItem key={val._id}>
                          //         {val.first_name} {val.last_name}
                          //       </MenuItem>
                          //     ))}
                          // </Select>
                          <>
                            {row.is_paid && row.is_paid === true
                              ? "Paid"
                              : "Free"}
                          </>
                        ) : (
                          ""
                        )}
                      </TableCell>

                      
                      {props.activetab === "printpricing" ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.column}
                          align="center"
                        >
                          <div className={styles.career_lastcol}>
                            {row?.createdAt ? dayjs(row.createdAt).format('DD-MM-YYYY') : "---"}
                          </div>
                        </TableCell>
):''}

                      {props.activetab === "printpricing" ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.column}
                          align="center"
                        >
                          <div className={styles.career_lastcol}>
                            {row?.price ? row.price : "---"}
                            {/* <div className={styles.action}>
                              <IconButton onClick={() => props.handleEditpopup(row)}><FiEdit3 className={styles.editicon} /></IconButton>
                              <IconButton onClick={() =>
                                  props.handleDeletePrintPricing(row._id)
                                }><FiTrash2 className={styles.deleicon}/></IconButton>
                             
                            </div> */}
                          </div>
                        </TableCell>
                      ) : (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          {props.activetab === "decline" ? row.email : ""}
                          {props.activetab === "pending" ? row.email : ""}
                          {props.activetab === "affiliatedecline"
                            ? row.email
                            : ""}
                          {props.activetab === "affiliatepending"
                            ? row.email
                            : ""}
                          {props.activetab === "affiliateapproved"
                            ? row.email
                            : ""}
                          {props.activetab === "contact-us" ? row.email : ""}

                          {props.activetab === "gallery_approved" || props.activetab === "gallery_decline"
                            ? row?.gallery[0]?.addresses[0]?.country
                            : ""}
                          {row.department}
                          {/* {props.activetab === "auctionlive" ? row.current_bid : row.name} */}
                          {props.activetab === "auctionlive" &&
                            usertype.type === "curator"
                            ? moment(row.createdAt).format("DD-MM-YYYY")
                            : row.current_bid
                              ? row.current_bid
                              : ""}
                          {props.activetab === "AdminAuctionlive"
                            
                            ? moment(row.createdAt).format("DD-MM-YYYY")
                            : row.current_bid
                              ? row.current_bid
                              : ""}
                          {props.activetab === "live" ? row.current_bid : ""}
                          {props.activetab === "career" ? "" : row.location}
                          {props.activetab === "request" ? row.name : ""}
                          {row.country}
                          {/* {props.activetab === "gallery_approved" ||
                          "gallery_decline"
                            ? ""
                            : ""} */}
{/* 
                          {props.activetab === "exshibitionpast"
                            ? row.is_paid
                              ? "Paid"
                              : "Free"
                            : ""} */}
                          {props.activetab === "exshibitiondecline"
                            ? row.is_paid
                              ? "Paid"
                              : "Free"
                            : ""}
                          {props.activetab === "exhibitionlive"
                            ? row.is_paid
                              ? "Paid"
                              : "Free"
                            : ""}
                          {/* {props.activetab === "exhibitionupcoming"
                            ? row.is_paid
                              ? "Paid"
                              : "Free"
                            : ""} */}
                          {/* this is for curator panel exhibition tab (upcoming and past) */}

                          {props.activetab === "upcoming" || props.activetab==='exhibitionupcoming'
                            ? row.createdAt &&
                            moment(row.createdAt).format(
                              "DD-MM-YYYY"
                            )
                            : ""}
                          {props.activetab === "past" || props.activetab ==='exhibitionpast'
                            ? row.createdAt &&
                            moment(row.createdAt).format(
                              "DD-MM-YYYY"
                            )
                            : ""}
                        </TableCell>
                      )}
                      {props.activetab === "affiliatepending" || "affiliateapproved" || "affiliatedecline" || "pending" || "upcoming" || "auctionlive" || 'exhibitionupcoming'|| props.activetab==='AdminAuctionlive'? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={
                            props.activetab === "pending"
                              ? ""
                              : styles.table_columnss
                          }
                          align="left"

                        >
                          {props.activetab === "affiliatepending"
                            ? moment(row?.createdAt).format("DD-MM-YYYY")
                            : ""}
                          {props.activetab === "affiliateapproved"
                            ? moment(row?.createdAt).format("DD-MM-YYYY")
                            : ""}
                          {props.activetab === "affiliatedecline"
                            ? moment(row?.createdAt).format("DD-MM-YYYY")
                            : ""}
                          {props.activetab === "gallery_approved" || props.activetab === "gallery_decline"
                            ? row?.likes
                            : ""}
                          {props.activetab === 'printpricing' ?
                            <div className={styles.action}>
                              <IconButton onClick={() => props.handleEditpopup(row)}><FiEdit3 className={styles.editicon} /></IconButton>
                              <IconButton onClick={() =>
                                props.handleDeletePrintPricing(row._id)
                              }><FiTrash2 className={styles.deleicon} /></IconButton>

                            </div> : ''}
                          {props.activetab === "pending" ? (
                            <Select
                              value={selectedArtistTypes[row._id] || ""}
                              onChange={(event) =>
                                handleArtistTypeChanged(event, row._id)
                              }
                              displayEmpty
                              sx={{
                                background: "#f6f6f6",
                                ".MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "& .MuiInputBase-root": {
                                  borderRadius: "6px",
                                },
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "21px",
                                color: "#636365",
                                height: "40px",
                              }}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value="">Artist Type</MenuItem>
                              {props.artistTypes &&
                                props.artistTypes.data.map((val) => (
                                  <MenuItem key={val._id} value={val.name}>
                                    {val.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          ) : (
                            ""
                          )}
                          {props.activetab === "upcoming" || props.activetab ==='exhibitionupcoming'|| props.activetab ==='exhibitionpast'|| props.activetab ==='past'
                            ? row.start_date &&
                            moment(row.start_date).format("DD-MM-YYYY")
                            : ""}
                            {props.activetab === "auctionlive" ||props.activetab === "AdminAuctionlive"
                            ? row.start_date &&
                            moment(row.start_date).format("DD-MM-YYYY")
                            : ""}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {props.activetab === "request" ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <Select
                            value=""
                            // onChange={handleChange}
                            displayEmpty
                            // fullWidth
                            sx={{
                              background: "#f6f6f6",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-root": {
                                borderRadius: "6px",
                              },
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "21px",
                              color: "#636365",
                              height: "40px",
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Artists Name</MenuItem>
                            {row.invite_artists &&
                              row.invite_artists.map((val) => (
                                <MenuItem disabled key={val._id}>
                                  {val.first_name} {val.last_name}
                                </MenuItem>
                              ))}
                          </Select>
                        </TableCell>
                      ) : props.activetab === "decline" ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <p>
                            {row.start_date &&
                              moment(row.start_date).format("DD-MM-YYYY")}
                          </p>
                        </TableCell>
                      ) : props.activetab === "gallery_decline" ? ( // this is for superadmin gallery decline
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_columns}
                          align="left"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span>{row?.followers}</span>
                            <Tooltip title="Account settings">
                              <IconButton
                                onClick={handleClick}
                                id={row._id}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={
                                  threedots ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={threedots ? "true" : undefined}
                              >
                                <img
                                  src={"/img/Menu Icon.png"}
                                  id={row._id}
                                  alt="img"
                                  height="21.62px"
                                  width="4.63px"
                                />
                              </IconButton>
                            </Tooltip>
                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={threedots}
                              onClose={handleClose}
                              onClick={handleClose}
                              // onClick={props.handleDelete}
                              value={row._id}
                              name={row._id}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  width: "150px",
                                  overflow: "visible",
                                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                  filter:
                                    "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                  mt: 2.5,
                                  "& .MuiAvatar-root": {
                                    width: "120px",
                                    height: "132px",
                                    ml: 0.5,
                                    mr: 1,
                                    // mt:5
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    // horizontal: 'center',
                                    right: 62,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform:
                                      "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "center",
                                vertical: "right",
                              }}
                              anchorOrigin={{
                                horizontal: "left",
                                vertical: "center",
                              }}
                            >
                              <MenuItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Featured Artist
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                onClick={() =>
                                  props.handleDelete(threedot_id)
                                }
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Delete
                                {/* {row._id} */}
                              </MenuItem>
                            </Menu>
                          </Box>
                        </TableCell>
                      ) : props.activetab === "gallery_approved" ? ( // this is for superadmin gallery approved
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_columns}
                          align="left"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span>{row?.followers}</span>
                            <Tooltip title="Account settings">
                              <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={
                                  threedots ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={threedots ? "true" : undefined}
                              >
                                <img
                                  src={"/img/Menu Icon.png"}
                                  height="21.62px"
                                  id={row._id}
                                  width="4.63px"
                                  alt="img"
                                />
                              </IconButton>
                            </Tooltip>
                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={threedots}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  width: "150px",
                                  overflow: "visible",
                                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                  filter:
                                    "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                  mt: 2.5,
                                  "& .MuiAvatar-root": {
                                    width: "120px",
                                    height: "132px",
                                    ml: 0.5,
                                    mr: 1,
                                    // mt:5
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    // horizontal: 'center',
                                    right: 62,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "center",
                                vertical: "right",
                              }}
                              anchorOrigin={{
                                horizontal: "left",
                                vertical: "center",
                              }}
                            >

                              <MenuItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Featured Artist
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                onClick={() =>
                                  props.handleDelete(threedot_id)
                                }
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </Box>
                        </TableCell>
                      ) : props.activetab === "pending" ||
                        props.activetab === "affiliatepending" ||
                        props.activetab === "career" ||
                        props.activetab === "auctionlive" ||
                        props.activetab === "AdminAuctionlive" ||
                        props.activetab === "exshibitiondecline"
                        || props.activetab === "affiliateapproved"
                        || props.activetab === "affiliatedecline"
                        
                        ? (
                          <TableCell
                            sx={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "21px",
                              color: "#636365",
                              width: "15%",
                            }}
                            className={
                              props.activetab === "pending"
                                ? styles.table_columns
                                : styles.table_columnss
                            }
                            align="left"
                          // style={props.activetab === "gallery_enquiry" ? { width: "30%" } : {}}
                          >
                            {props.activetab === "career" && (
                              <>
                                <div>
                                  <b>Exp : </b>
                                  {row?.min_exp}-{row?.max_exp} yrs
                                </div>
                              </>
                            )}

                            {row.experience}
                            {/* {props.activetab === "gallery_approved" ||
                          "gallery_decline"
                            ? ""
                            : row.followers} */}
                          {props.activetab === "auctionlive"
                                ? usertype.type === "curator"
                                  ? row.end_date && moment(row.end_date).format("DD-MM-YYYY") // Ensures end_date is displayed
                                  : row.total_bid
                                : ''}
                          {row.type}
                          {props.activetab === "AdminAuctionlive"
                               
                                  ? row.end_date && moment(row.end_date).format("DD-MM-YYYY") // Ensures end_date is displayed
                                  : ''
                          }
                          {/* {props.activetab === "gallery_approved"
                            ? row.likes
                            : ""}
                          {props.activetab === "gallery_approved"
                            ? row.likes
                            : ""} */}
                            {props.activetab === "pending" ? (
                              // <Select
                              //   value={selectedArtistTypes[row._id] || ""}
                              //   onChange={(event) =>
                              //     handleArtistTypeChange(event, row._id)
                              //   }
                              //   displayEmpty
                              //   sx={{
                              //     background: "#f6f6f6",
                              //     ".MuiOutlinedInput-notchedOutline": {
                              //       border: "none",
                              //     },
                              //     "& .MuiInputBase-root": {
                              //       borderRadius: "6px",
                              //     },
                              //     fontFamily: "Roboto",
                              //     fontStyle: "normal",
                              //     fontWeight: "400",
                              //     fontSize: "14px",
                              //     lineHeight: "21px",
                              //     color: "#636365",
                              //     height: "40px",
                              //   }}
                              //   inputProps={{ "aria-label": "Without label" }}
                              // >
                              //   <MenuItem value="">Artist Type</MenuItem>
                              //   {props.artistTypes &&
                              //     props.artistTypes.data.map((val) => (
                              //       <MenuItem key={val._id} value={val.name}>
                              //         {val.name}
                              //       </MenuItem>
                              //     ))}
                              // </Select>
                              <>{dayjs(row?.createdAt).format("DD-MM-YYYY").toString()}</>
                            ) : (
                              ""
                            )}
                            {/* {props.activetab === "exshibitionpast"
                              ? row.start_date &&
                              moment(row.start_date).format("DD-MM-YYYY")
                              : ""} */}
                            {props.activetab === "exshibitiondecline"
                              ? row.start_date &&
                              moment(row.start_date).format("DD-MM-YYYY")
                              : ""}
                            {/* {props.activetab === "exhibitionupcoming"
                              ? row.start_date &&
                              moment(row.start_date).format("DD-MM-YYYY")
                              : ""} */}
                            {/* this is for curator panel exhibition tab (upcoming and past) */}
                            {/* {props.activetab === "upcoming" || props.activetab ==='exhibitionupcoming'
                              ? row.end_date &&
                              moment(row.end_date).format("DD-MM-YYYY")
                              : ""} */}
                            {/* {props.activetab === "past"
                              ? row.start_date
                                ? moment(row.start_date).format("DD-MM-YYYY")
                                : ""
                              : ""} */}
                            {props.activetab === "contact-us"
                              ? row.createdAt &&
                              moment(row.createdAt).format("DD-MM-YYYY")
                              : ""}
                            {props.activetab === "affiliatepending"
                              ? row.affiliates[0]?.accountType[0]?.name
                              : ""}
                            {props.activetab === "affiliateapproved"
                              ? row.affiliates[0]?.accountType[0]?.name
                              : ""}
                            {props.activetab === "affiliatedecline"
                              ? row.affiliates[0]?.accountType[0]?.name
                              : ""}
                          </TableCell>
                        ) : ''}

                      {props.activetab === "contact-us" ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: 4,
                            }}
                          >
                            {row.message}
                            <IconButton onClick={() => props.handleDelete(row._id)}><FiTrash2 className={styles.deleicon} /></IconButton>

                          </Box>
                        </TableCell>
                      ) : props.activetab === "affiliateapproved" ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_columns}
                          align="center"
                        >
                          <Tooltip title="Account settings">
                            <IconButton
                              onClick={handleClick}
                              size="small"
                              sx={{ ml: 2 }}
                              id={row._id}
                              aria-controls={
                                threedots ? "account-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={threedots ? "true" : undefined}
                            >
                              <img
                                src={"/img/Menu Icon.png"}
                                height="21.62px"
                                width="4.63px"
                                alt="img"
                              />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={threedots}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                width: "150px",
                                overflow: "visible",
                                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                filter:
                                  "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                mt: 2.5,
                                "& .MuiAvatar-root": {
                                  width: "120px",
                                  height: "132px",
                                  ml: 0.5,
                                  mr: 1,
                                  // mt:5
                                },
                                "&:before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  // horizontal: 'center',
                                  right: 62,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "center",
                              vertical: "right",
                            }}
                            anchorOrigin={{
                              horizontal: "left",
                              vertical: "center",
                            }}
                          >
                            <MenuItem
                              onClick={() => props.handleDelete(threedot_id)}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                              }}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      ) : props.activetab === "decline" ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <p>
                            {row.end_date &&
                              moment(row.end_date).format("DD-MM-YYYY")}
                          </p>
                          <IconButton onClick={() => props.handleDelete(row._id)}><FiTrash2 className={styles.deleicon} /></IconButton>

                        </TableCell>
                      ) : props.activetab === "affiliatedecline" ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <p>
                            {row.end_date &&
                              moment(row.end_date).format("DD-MM-YYYY")}
                          </p>
                          <IconButton onClick={() => props.handleDelete(row._id)}><FiTrash2 className={styles.deleicon} /></IconButton>

                        </TableCell>
                      ) : props.activetab === "affiliatepending" ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                            whiteSpace: "nowrap",
                          }}
                          className={styles.table_columns}
                          align="left"
                        >
                          <Button
                            onClick={() =>
                              props.handleAcceptDecline(row._id, "Approved")
                            }
                            color={row.status ? "primary" : "primary"}
                            variant="contained"
                            sx={{ width: "100px", mr: 2 }}
                          >
                            Accept
                          </Button>
                          <Button
                            onClick={() =>
                              props.handleAcceptDecline(row._id, "Rejected")
                            }
                            color={row.status ? "primary" : "primary"}
                            variant="outlined"
                            sx={{ width: "100px", mr: 2 }}
                          >
                            Decline
                          </Button>
                        </TableCell>
                      )
                        // ) : props.activetab === "gallery_approved" ? (
                        //   <TableCell
                        //     sx={{
                        //       fontFamily: "Roboto",
                        //       fontStyle: "normal",
                        //       fontWeight: "400",
                        //       fontSize: "14px",
                        //       lineHeight: "21px",
                        //       color: "#636365",
                        //     }}
                        //     className={styles.table_columns}
                        //     align="left"
                        //   >
                        //     <Box
                        //       sx={{
                        //         display: "flex",
                        //         justifyContent: "space-between",
                        //         alignItems: "center",
                        //       }}
                        //     >
                        //       <span>{row.followers}</span>
                        //       <Tooltip title="Account settings">
                        //         <IconButton
                        //           onClick={handleClick}
                        //           size="small"
                        //           sx={{ ml: 2 }}
                        //           aria-controls={
                        //             threedots ? "account-menu" : undefined
                        //           }
                        //           aria-haspopup="true"
                        //           aria-expanded={threedots ? "true" : undefined}
                        //         >
                        //           <img
                        //             src={"/img/Menu Icon.png"}
                        //             height="21.62px"
                        //             width="4.63px"
                        //             alt="img"
                        //           />
                        //         </IconButton>
                        //       </Tooltip>
                        //       <Menu
                        //         anchorEl={anchorEl}
                        //         id="account-menu"
                        //         open={threedots}
                        //         onClose={handleClose}
                        //         onClick={handleClose}
                        //         PaperProps={{
                        //           elevation: 0,
                        //           sx: {
                        //             width: "150px",
                        //             overflow: "visible",
                        //             boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                        //             filter:
                        //               "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                        //             mt: 2.5,
                        //             "& .MuiAvatar-root": {
                        //               width: "120px",
                        //               height: "132px",
                        //               ml: 0.5,
                        //               mr: 1,
                        //               // mt:5
                        //             },
                        //             "&:before": {
                        //               content: '""',
                        //               display: "block",
                        //               position: "absolute",
                        //               top: 0,
                        //               // horizontal: 'center',
                        //               right: 62,
                        //               width: 10,
                        //               height: 10,
                        //               bgcolor: "background.paper",
                        //               transform: "translateY(-50%) rotate(45deg)",
                        //               zIndex: 0,
                        //             },
                        //           },
                        //         }}
                        //         transformOrigin={{
                        //           horizontal: "center",
                        //           vertical: "right",
                        //         }}
                        //         anchorOrigin={{
                        //           horizontal: "left",
                        //           vertical: "center",
                        //         }}
                        //       >
                        //         <MenuItem
                        //           sx={{
                        //             display: "flex",
                        //             justifyContent: "center",
                        //             fontSize: "16px",
                        //             lineHeight: "12px",
                        //             fontWeight: "400",
                        //             fontFamily: "Roboto",
                        //           }}
                        //         >
                        //           Delete
                        //         </MenuItem>
                        //       </Menu>
                        //     </Box>
                        //   </TableCell>
                        // )
                        // ) : props.activetab === "gallery_decline" ? (
                        //   <TableCell
                        //     sx={{
                        //       fontFamily: "Roboto",
                        //       fontStyle: "normal",
                        //       fontWeight: "400",
                        //       fontSize: "14px",
                        //       lineHeight: "21px",
                        //       color: "#636365",
                        //     }}
                        //     className={styles.table_columns}
                        //     align="left"
                        //   >
                        //     <Box
                        //       sx={{
                        //         display: "flex",
                        //         justifyContent: "space-between",
                        //         alignItems: "center",
                        //       }}
                        //     >
                        //       <span>{row.followers}</span>
                        //       <Box
                        //         sx={{
                        //           display: "flex",
                        //           justifyContent: "space-between",
                        //         }}
                        //       >
                        //         <Tooltip title="Account settings">
                        //           <IconButton
                        //             onClick={handleClick}
                        //             id={row._id}
                        //             size="small"
                        //             sx={{ ml: 2 }}
                        //             aria-controls={
                        //               threedots ? "account-menu" : undefined
                        //             }
                        //             aria-haspopup="true"
                        //             aria-expanded={threedots ? "true" : undefined}
                        //           >
                        //             <img
                        //               src={"/img/Menu Icon.png"}
                        //               id={row._id}
                        //               alt="img"
                        //               height="21.62px"
                        //               width="4.63px"
                        //             />
                        //           </IconButton>
                        //         </Tooltip>
                        //         <Menu
                        //           anchorEl={anchorEl}
                        //           id="account-menu"
                        //           open={threedots}
                        //           onClose={handleClose}
                        //           onClick={handleClose}
                        //           // onClick={props.handleDelete}
                        //           value={row._id}
                        //           name={row._id}
                        //           PaperProps={{
                        //             elevation: 0,
                        //             sx: {
                        //               width: "150px",
                        //               overflow: "visible",
                        //               boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                        //               filter:
                        //                 "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                        //               mt: 2.5,
                        //               "& .MuiAvatar-root": {
                        //                 width: "120px",
                        //                 height: "132px",
                        //                 ml: 0.5,
                        //                 mr: 1,
                        //                 // mt:5
                        //               },
                        //               "&:before": {
                        //                 content: '""',
                        //                 display: "block",
                        //                 position: "absolute",
                        //                 top: 0,
                        //                 // horizontal: 'center',
                        //                 right: 62,
                        //                 width: 10,
                        //                 height: 10,
                        //                 bgcolor: "background.paper",
                        //                 transform:
                        //                   "translateY(-50%) rotate(45deg)",
                        //                 zIndex: 0,
                        //               },
                        //             },
                        //           }}
                        //           transformOrigin={{
                        //             horizontal: "center",
                        //             vertical: "right",
                        //           }}
                        //           anchorOrigin={{
                        //             horizontal: "left",
                        //             vertical: "center",
                        //           }}
                        //         >
                        //           <MenuItem
                        //             sx={{
                        //               display: "flex",
                        //               justifyContent: "center",
                        //               fontSize: "16px",
                        //               lineHeight: "12px",
                        //               fontWeight: "400",
                        //               fontFamily: "Roboto",
                        //             }}
                        //           >
                        //             Featured Artist
                        //           </MenuItem>
                        //           <Divider />
                        //           <MenuItem
                        //             onClick={() =>
                        //               props.handleDelete(threedot_id)
                        //             }
                        //             sx={{
                        //               display: "flex",
                        //               justifyContent: "center",
                        //               textAlign: "center",
                        //               fontSize: "16px",
                        //               lineHeight: "12px",
                        //               fontWeight: "400",
                        //               fontFamily: "Roboto",
                        //             }}
                        //           >
                        //             Delete
                        //             {/* {row._id} */}
                        //           </MenuItem>
                        //         </Menu>
                        //       </Box>
                        //     </Box>
                        //   </TableCell>
                        : props.activetab === "request" ? (
                          <TableCell
                            sx={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "21px",
                              color: "#636365",
                              whiteSpace: "nowrap",
                            }}
                            className={styles.column}
                            align="center"
                          >
                            <Button
                              color={row.status ? "primary" : "primary"}
                              variant="contained"
                              sx={{ width: "80px", mr: 2 }}
                              onClick={() =>
                                props.handleAcceptReject(row._id, "Approved")
                              }
                            >
                              Accept
                            </Button>
                            <Button
                              color={row.status ? "primary" : "primary"}
                              variant="outlined"
                              sx={{ width: "80px", mr: 0 }}
                              onClick={() =>
                                props.handleAcceptReject(row._id, "Rejected")
                              }
                            >
                              Decline
                            </Button>
                            <IconButton onClick={() => props.handleDeleteAuction(row._id)}>
                              <FiTrash2 className={styles.deleicon} />

                            </IconButton>
                          </TableCell>
                        ) : props.activetab === "career" ? (
                          <TableCell
                            sx={{
                              width: "32%",
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "21px",
                              color: "#636365",
                            }}
                            className={styles.column}
                            align="center"
                          >
                            <div className={styles.career_lastcol}>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    row &&
                                      row.description &&
                                      row.description.length > 100
                                      ? row.description.substring(0, 100) + "..."
                                      : row && row.description
                                        ? row.description
                                        : "",
                                }}
                              ></p>
                              <div className={styles.action}>
                                <IconButton onClick={() => props.handleEditpopup(row)}><FiEdit3 className={styles.editicon} /></IconButton>
                                <IconButton onClick={() =>
                                  props.handleDeletecareeer(row._id)
                                }><FiTrash2 className={styles.deleicon} /></IconButton>

                              </div>
                            </div>
                          </TableCell>
                        ) : props.activetab === "pending" ? (
                          <>
                            {/* <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                        }}
                        className={
                          props.activetab === "pending"
                            ? ""
                            : styles.table_columnss
                        }
                        align="left"
                        
                      >
                        {dayjs(row?.createdAt).format("DD-MM-YYYY").toString()}
                        </TableCell> */}
                            <TableCell
                              sx={{
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "21px",
                                color: "#636365",
                                whiteSpace: "nowrap",
                              }}
                              className={styles.table_column}
                              align="left"
                            >
                              <Button
                                color={row.status ? "primary" : "primary"}
                                variant="contained"
                                disabled={row.is_blocked}
                                sx={{ width: "80px", mr: 2 }}
                                onClick={() =>
                                  props.handleAcceptDecline(row._id, "Approved", {
                                    name: "Basic Subscription",
                                    artist_id: row._id,
                                    subscriptionId: "66a24113b46eb6ba162ec273",
                                    subscription_tenure: "monthly",
                                    payment_mode: "From_admin",
                                    transactionId: "TXN123456789",
                                    amount: "0",
                                  })
                                }
                              >
                                Accept
                              </Button>
                              <Button
                                color={row.status ? "primary" : "primary"}
                                variant="outlined"
                                disabled={row.is_blocked}
                                sx={{ width: "80px", mr: 0 }}
                                onClick={() =>
                                  props.handleAcceptDecline(row._id, "Rejected")
                                }
                              >
                                Decline
                              </Button>


                            </TableCell>
                          </>
                        ) : props.activetab === "galleryadmin" ? (
                          <TableCell
                            sx={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "21px",
                              color: "#636365",
                              whiteSpace: "nowrap",
                            }}
                            className={styles.table_column}
                            align="left"
                          >
                            <Button>
                            Interested
                          </Button>
                        </TableCell>
                      ) : 
                       props.activetab === "exshibitiondecline" ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            width :"130px"
                          }}
                          align="left"
                        >
                          {/* {props.activetab === "auctionlive"
                            ? usertype.type === "curator"
                              ? moment(row.end_date).format("DD-MM-YYYY")
                              : row.total_bid
                            : row.end_date &&
                              moment(row.end_date).format("DD-MM-YYYY")} */}
                              {props.activetab === "auctionlive"
                                ? usertype.type === "curator"
                                  ? row.end_date && moment(row.end_date).format("DD-MM-YYYY") // Ensures end_date is displayed
                                  : row.total_bid
                                : row.end_date && moment(row.end_date).format("DD-MM-YYYY")}

                              {props.activetab === "AdminAuctionlive"
                                
                                  ? row.end_date && moment(row.end_date).format("DD-MM-YYYY") // Ensures end_date is displayed
                                  : row.total_bid
                              }
                        </TableCell>
                      ) : props.activetab === "upcoming" || props.activetab === "past" || props.activetab==='exhibitionupcoming'||props.activetab==='exhibitionpast'  ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left" 
                        >
                          {props.activetab === "upcoming" || props.activetab ==='exhibitionupcoming' ||props.activetab === "past" || props.activetab==='exhibitionpast'
                            ? row.end_date &&
                              moment(row.end_date).format("DD-MM-YYYY")
                            : ""}
                          <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {row.followers}
                              {/* {props.activetab === "gallery_decline" ? (
                              <IconButton  onClick={() => props.handleDelete(row._id)}><FiTrash2 className={styles.deleicon}/></IconButton>
                             
                            ) : (
                              ""
                            )} */}
                            </Box>
                          
                          </TableCell>
                        ) : (
                          <></>
                        )}

                      {props.activetab === "past"  &&
                        usertype.type === "curator" ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                          }}
                          align="left"
                        >
                          {row.end_date_registration &&
                            moment(row.end_date_registration).format(
                              "DD-MM-YYYY"
                            )}
                        </TableCell>
                      ) : (
                        ""
                      )}

                    {props.activetab === "exhibitionupcoming"  || props.activetab==='exhibitionpast'
                       ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                          }}
                          align="center"
                        >
                          {row.end_date_registration &&
                            moment(row.end_date_registration).format(
                              "DD-MM-YYYY"
                            )}
                        </TableCell>
                      ) : (
                        ""
                      )}

                     {/* { props.activetab === "exshibitionpast"
                         ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                          }}
                          align="center"
                        >
                          {row.end_date_registration &&
                            moment(row.end_date_registration).format(
                              "DD-MM-YYYY"
                            )}
                        </TableCell>
                      ) : (
                        ""

                      )} */}
                      {props.activetab === "auctionlive" &&
                        usertype.type === "curator" ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                          }}
                          align="left"
                        >
                          {row.application_end_date &&
                            moment(row.application_end_date).format(
                              "DD-MM-YYYY"
                            )}
                        </TableCell>
                      ) : (
                        ""
                      )}

                      
                    

                      {props.activetab === "AdminAuctionlive" 
                        ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                          }}
                          align="left"
                        >
                          {row.application_end_date &&
                            moment(row.application_end_date).format(
                              "DD-MM-YYYY"
                            )}
                        </TableCell>
                      ) : (
                        ""
                      )}

                      {props.activetab === "AdminAuctionlive"  
                        ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                          }}
                          align="left"
                        >
                          <IconButton onClick={() => props.handleDeleteAuction(row._id)}><FiTrash2 className={styles.deleicon}/></IconButton>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {props.activetab === "upcoming" &&
                      usertype.type === "curator" ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                          }}
                          align="left"
                        >
                          {row.end_date_registration &&
                            moment(row.end_date_registration).format(
                              "DD-MM-YYYY"
                            )}
                        </TableCell>
                      ) : (
                        ""
                      )}

                     {/* {props.activetab === "exhibitionupcoming" || props.activetab ==='exhibitionpast'
                     ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                          }}
                          align="center"
                        >
                          {row.end_date_registration &&
                            moment(row.end_date_registration).format(
                              "DD-MM-YYYY"
                            )}
                        </TableCell>
                      ) : (
                        ""
                      )} */}
                    </TableRow>

                  ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <>
        <Box className={styles.pagination_box} sx={{}}>
          <Pagination count={Math.ceil(props.data?.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            renderItem={(item) => (
              <PaginationItem
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "black",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  },
                }}
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
              />
            )} />
        </Box>
      </>
    </div>
  );
}
