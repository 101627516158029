import validator from "validator";
import isEmpty from "./isEmpty";
const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]*[a-zA-Z]+[a-zA-Z0-9-]*\.(com|org|net|in|edu|gov|co|io|info|biz|me|ir|pk|ae|ue)(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;
const youtubeRegex = /^(https?:\/\/)?(www\.)?youtube\.com\/.+$/;

const linkedinRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|company)\/[a-zA-Z0-9_-]+(\/[a-zA-Z0-9_-]+)*\/?(\/\?.*)?$/;


const instagramRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_.]+(\/?.*)?$/;

const twitterRegex = /^(https?:\/\/)?(www\.)?x\.com\/[a-zA-Z0-9_]+(\/?.*)?$/;


const facebookRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.-]+(\/?.*)?$/;
export const validationForGalleryApplyform = (
    portfolio_file,
    description,
    category_id,
    art_price_range,
    arts_solds_in_past_12_months,
 

    portfolio,
    other_URL,
    online_art_gallary_URL,
    social_links,
   award,
  //  degree_year,
   best_work,
   othersReasonCategory,
   selectedFilesGallery
) => {
    const isFile = file => file instanceof File;
  let applyForm = {};


  if (!best_work || best_work.length === 0) {
    console.log("best_work==>",best_work.length);
    
    applyForm.best_work = "Best artist works is required";
  } else if (best_work.length > 0) {
    if (selectedFilesGallery.length < 2 || selectedFilesGallery.length >10) {
      applyForm.best_work = "Please upload between 2 and 10 best artist works.";
    }
  }
  if (!isFile(portfolio_file) || !portfolio_file.name) {
    applyForm.portfolio_file = "Portfolio file is required";
  }
  // if(isEmpty(degree_year) || degree_year === "<p><br></p>") {
  //   applyForm.degree_year = "Please provide a detailed description, including the name, year, and place of each experience.";
  // }

  if(isEmpty(award) || award === "<p><br></p>") {
    applyForm.award = "Exhibitions , awards/prizes are required ";
  }

  if(isEmpty(description) || description === "<p><br></p>") {
    applyForm.description = "About yourself is required";
  }



  // Validate category_id (ensure there is at least one category selected)
  if (!category_id || category_id.length === 0) {
    applyForm.category_id = "At least one category is required";
  } else if(category_id.some(
    (cat) => cat._id === "67334c2511d9938babd097f3"
  )) {
      if(isEmpty(othersReasonCategory)) {
        applyForm.category_id = "Other category is required";
      }
  }

  // Validate art_price_range (should not be empty)
  if (!art_price_range || art_price_range.length === 0) {
    applyForm.art_price_range = "Art price range is required";
  }

  // Validate arts_solds_in_past_12_months (should be a positive number)
  if (validator.isEmpty(arts_solds_in_past_12_months)) {
    applyForm.arts_solds_in_past_12_months = "Number of arts sold in the past 12 months is required ";
  } else if (isNaN(arts_solds_in_past_12_months) || arts_solds_in_past_12_months < 0) {
    applyForm.arts_solds_in_past_12_months = "numbeN of arts sold in the past 12 months should be a positive number";
  }





    // Validate portfolio URL (if provided)
    if (portfolio && !portfolio.match(regex)) {
        applyForm.portfolio = "Please enter a valid URL";
      }
         // Validate portfolio URL (if provided)
    if (other_URL && !other_URL.match(regex)) {
        applyForm.other_URL = "Please enter a valid URL";
      }
    
      // Validate online_art_gallary_URL (if provided)
      if (online_art_gallary_URL && !online_art_gallary_URL.match(regex)) {
        applyForm.online_art_gallary_URL = "Please enter a valid online art gallary URL";
      }
    
      // Validate LinkedIn URL (if provided)
      if (social_links.linked_in && !social_links.linked_in.match(linkedinRegex)) {
        applyForm.linked_in = "LinkedIn URL is invalid";
      }
    
      // Validate Instagram URL (if provided)
      if (social_links.instagram && !social_links.instagram.match(instagramRegex)) {
        applyForm.instagram = "Instagram URL is invalid";
      }
    
      // Validate Twitter URL (if provided)
      if (social_links.twitter && !social_links.twitter.match(twitterRegex)) {
        applyForm.twitter = "X URL is invalid";
      }
    
      // Validate Facebook URL (if provided)
      if (social_links.facebook && !social_links.facebook.match(facebookRegex)) {
        applyForm.facebook = "Facebook URL is invalid";
      }

         // Validate Facebook URL (if provided)
         if (social_links.youtube && !social_links.youtube.match(youtubeRegex)) {
            applyForm.youtube = "Youtube URL is invalid";
          }


        //   if (art_work.length) {
        //     if (art_work.length < 2 || art_work.length > 10) {
        //         applyForm.art_work = "Artwork should be between 2 and 10"
        //     }
        //   }
  return {
    applyForm,
    isValidGalleryApplyform: isEmpty(applyForm),
  };
};
