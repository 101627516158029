import React, { useEffect, useState } from 'react';
import styles from './Emailverify.module.css';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../State/api/Api';

const Emailverify = () => {
    const [open, setOpen] = useState(true)
    const [signupdt, setSignupdt] = useState(null);
    const [is_verify, setIs_verify] = useState(true);
    const [notify_msg, setNotify_msg] = useState("");

    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    // const search = useLocation().search
    // const id = search.includes('?') ? search.split('?')[1].split('=')[1] : '';
    const searchParams = useLocation();
    const a = searchParams.search && searchParams.search.split('?')[1].split('&')[0].split('&').map(param => param.split('='))
    const b = searchParams.search && searchParams.search.split('?')[1].split('&')[2].split('&').map(param => param.split('='))
    const c = searchParams.search && searchParams.search.split('?')[1].split('&')[1].split('&').map(param => param.split('='))
    useEffect(() => {
        if (searchParams.search.length > 0) {
            setSignupdt({ email: a[0][1], key: c[0][1], role: b[0][1] });
            // handleVeryfied()
        }
    }, [])
    const handleVeryfied = async function () {
        // dispatch(setLoade(true));
        try {
            const result = await api.applicationApi(
                `api/user/userVerification`,
                "POST", signupdt
            );
            if (result && result.status === 200) {
                setIs_verify(true)
                setNotify_msg(result.data.message)
                // setAffiliateType(result.data.data.data);
                // dispatch(setLoader(false));
            } else if (result.status === 400) {
                setIs_verify(true)
                setNotify_msg(result.data.message)
            }
            else {
                setIs_verify(false)
            }
        } catch (err) {
        }
    };
    useEffect(() => {
        signupdt && handleVeryfied();
    }, [signupdt])
    return (
        <>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                // onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    {is_verify ?
                        <Box className={styles.modal_del}>
                            <h3 className={styles.success_txt}>{notify_msg}</h3>
                            <h3 className={styles.login_text} onClick={() => navigate("/login")}>Please login to your account</h3>
                        </Box>
                        :
                        <Box className={styles.modal_del}>
                            <h2 className={styles.success_txt}>Some Error occured</h2>
                            <p className={styles.login_tex}>Please try again after some time</p>
                        </Box>
                    }
                </Fade>
            </Modal>
        </>
    )
}

export default Emailverify;