import { Container, Link, Grid } from "@mui/material";
import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from "react-router-dom"
import styles from "./CommisionedArt.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import homeImage from "../../../constant";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RiCheckFill, RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import PhoneInput from "react-phone-input-2";
import 'swiper/swiper-bundle.min.css';
function ArtCurationProcess() {
  const navigate = useNavigate()

  const handleNavigate = (route) => {
    navigate(route)
  }
   

const exclusive_data = [
  {
    id: 1,
    src: "/img/artcollections",
    heading: "Artwork Submission and Comprehensive Review",
    des: "Once an artist’s application is approved, they are invited to upload their artwork to our platform. Our curators then engage in a meticulous review process, assessing the piece from multiple angles. This includes evaluating key factors such as the price, theme, artistic style, subject matter, technique, and medium used. We also examine the overall quality of the artwork, ensuring that it meets the highest standards of craftsmanship. We consider the detailed description of the artwork provided by the artist, offering context and insight into the piece. Each individual artwork will be selected with careful attention given to its unique qualities and potential fit within our curated artwork collections.",
  },
  {
    id: 2,
    src: "/img/artcollections",
    heading: "Prioritizing Authenticity and Originality",
    des: "Every piece we include in our collection undergoes a rigorous artwork verification process to ensure authenticity and originality. We prioritize artworks that reflect the artist’s unique perspective and genuine passion, ensuring our customers receive creations with integrity and depth. This verification process guarantees that each piece aligns with our standards of quality and credibility. By focusing on originality, we celebrate the individuality of each artist and uphold the trust of collectors who seek unique and meaningful additions to their art collection. Our dedication to authenticity underscores the value and significance of the pieces showcased on our platform.",
  },
  {
    id: 3,
    src: "/img/artcollections",
    heading: "A Collaborative Process",
    des: "Our team of curators collaborates with cultural experts, interior designers, and industry professionals to identify pieces that align with current trends and timeless appeal. This collaborative approach ensures our collection caters to both contemporary and classic preferences, offering something for every art enthusiast. These partnerships enhance our ability to present pieces that connect with audiences, balancing innovation with traditional artistry. By combining expertise from various disciplines, we create collections that reflect a holistic understanding of the art market, ensuring they resonate across different tastes and cultures.",
  },
  {
    id: 4,
    src: "/img/artcollections",
    heading: "Tailored for Every Space",
    des:
    (
      <>
      <p>We curate collections that enhance the ambiance and purpose of any space, ensuring that art becomes an integral part of the environment it inhabits. Our art complements various settings from bold abstracts to serene landscapes, whether residential, commercial, or gallery spaces. Each piece is chosen with consideration of how it interacts with its surroundings, elevating the aesthetic and emotional impact of the space. By tailoring our collections to suit diverse needs, we empower our clients to transform their spaces into expressions of personality and creativity. This tailored approach reinforces our commitment to making art accessible and meaningful for everyone.</p>
     <div className={styles.mt_2}>
     <a href="#" className="apply_btn">Explore Our Curated Collection</a>
     </div>
      </>),
  },
];
const FAQs = [
  {
    id: 1,
    question: "How do I sign up as a gallery on Art Smiley?",
    answer:
      "Begin your journey by registering your gallery on the Art Smiley platform. Make sure to fill all the mandatory fields with the right information. Once your application has been reviewed and approved, you will receive a confirmation email to set up your password, granting you access to all available features and services.",
  },
  {
    id: 2,
    question: "How can I upload the artwork?",
    answer:
      "Begin by logging into your dashboard, navigating to the 'Upload Arts' section, and submitting your artwork with all the necessary details.",
  },
  {
    id: 3,
    question: "How long does the application review process take?",
    answer:
      "The review process for your gallery account typically takes 3 to 4 weeks. We will notify you once the review is complete and guide you through the subsequent steps.",
  },
  {
    id: 4,
    question: "Can I explore the platform while waiting for my account to be approved?",
    answer:
      "Yes, you can explore the features of Art Smiley while your application is under review. Take this time to familiarize yourself with the platform.",
  },
  {
    id: 5,
    question: "What should I do if my application is not approved?",
    answer:
      "If your application is not approved, we encourage you to enhance your profile and consider reapplying after six months. Our team is happy to offer feedback and assistance to help you improve your chances of approval in the future.",
  },
];
const remarableevents =[
  {
    id:1,
    heading: "Send an Inquiry",
    des: "The process begins when you reach out with your vision. If you have a well-defined concept or need guidance in shaping your idea, our team will work closely with you to gather all the necessary details including artwork dimensions, style, subject, materials, location, branding requirements, color preferences, and reference pictures etc., This initial step ensures that the artwork we create is tailored to your unique style and space.",
  },
  {
    id:2,
    heading: "Concept Approval",
    des: "Our team specializes in digital artwork commissions, hand painted artworks, personalized sculptures, collaborating with you to develop initial design concepts tailored to your needs. These concepts are presented for your feedback, and we make revisions until you're completely satisfied with the final idea.",
  },
  {
    id:3,
    heading: "Custom Artwork Proposal",
    des: "Once the concept is approved, we prepare a comprehensive proposal tailored to your art commissions for businesses. This proposal includes all essential details, such as the project scope, total cost for design, project execution, delivery, and installation, duration, payment terms ensuring clarity and transparency about what you can expect. Upon your approval of the proposal and first payment, we proceed to the next stage, bringing your ideas closer to realization.",
  },
  {
    id:4,
    heading: "Design Proposal",
    des: "According to the finalized concept, we create detailed designs to bring your vision to life. For large sculptures, we produce 3D designs that allow you to visualize the final structure from every angle, ensuring precision and clarity. For artworks, we develop detailed sketches to capture the essence of the concept. Revisions to the design are offered up to three times to refine and perfect the details. This ensures that the final artwork aligns with your expectations. Additionally, we provide regular updates during this stage, keeping you informed and involved throughout the design process.",
  },
  {
    id:5,
    heading: "Artist Selection",
    des: "Once you finalize the concept and design, we match you with the perfect artist or artists specializing in your desired style and medium. Our network includes a diverse range of talented professionals, each bringing their unique approach to the creative process. We ensure that the selected artist not only meets your aesthetic preferences but also has experience in similar projects, guaranteeing high-quality results. Furthermore, we facilitate communication between you and the artist when needed, fostering collaboration and alignment on creative decisions.",
  },
  {
    id:6,
    heading: "Creation of Artwork",
    des: "Once the design is finalized, the artist begins creating your personalized artwork for which we require a 50% deposit upfront. The remaining 50% balance is due upon delivery of the completed custom art pieces. This straightforward payment structure ensures both parties remain aligned as the project progresses. Throughout the creation process, we provide regular updates and progress shots, so you can follow along and make sure the piece is evolving just as you envisioned. ",
  },
  {
    id:7,
    heading: "Finalization and Approval ",
    des: "Once the artwork is complete, we will send pictures for your approval. If there are any revisions you'd like us to make, we’ll incorporate them before delivering the final piece. Once the artwork is shipped, we won’t be able to accommodate any changes or modifications on the artwork. Our goal is to ensure your complete satisfaction with the final artwork. ",
  },
  {
    id:8,
    heading: "Delivery & Installation",
    des: "After your artwork is completed, we take care of everything from delivery to installation. Our team ensures safe delivery and professional installation, bringing your commissioned piece to life in your space. The delivery and installation charges will be informed to you during the artwork proposal phase. These charges will vary according to the site location.",
  },
  {
    id:9,
    heading: "Final Touches",
    des: "We provide post-installation and maintenance support within the UAE at additional costs. We do offer specialized post installation services for sculptures. Once the piece is installed, we ensure everything is perfectly positioned, allowing the artwork to enhance your space as intended. Our dedicated post-installation services are designed to ensure your complete satisfaction with the final result.",
  },
]; 
const tabs = [ 
  {
    label: 'Artist Application',
    content: (
    <>
    <p>At Art Smiley, we believe in inclusivity and accessibility, and we enthusiastically welcome artist applications to join our platform. Our commitment to fostering talent is reflected in our streamlined and open submission process, which offers artists the opportunity to showcase their work in front of a global audience. By joining our curated collections, artists gain exposure to a diverse range of art lovers, collectors, and enthusiasts from around the world. We empower artists to expand their reach and elevate their careers through virtual exhibitions, online auctions, and collaborative events.</p>,
    <div className={styles.mt_2}>
    <a href="artist-application" className="apply_btn">Apply Now</a>
    </div>
    
    </>
    ),
  },
  {
    label: 'Artist Verification',
    content: <p>Maintaining authenticity and integrity is a top priority for Art Smiley. Every artist undergoes a thorough verification process. This includes verifying the originality of their artwork to ensure that each piece is genuinely created by the submitting artist. Additionally, artists are required to provide valid identification documents, such as a passport or an identification card, to confirm their identity. This process safeguards the trust of buyers and collectors on our platform.</p>,
  },
  {
    label: 'Artist Categorization',
    content: (
      <>
        <p>Once an artist is verified, their profile and portfolio undergo a meticulous artist curation process based on several criteria. Our curators assess the quality of their artwork, technical skills, and the mediums or styles they use. We also consider the artist's talent, education, participation in exhibitions, international reputation, and their unique artistic perspective. Thematic and historical relevance, as well as the originality of concepts and themes, play a significant role in our evaluation. This thorough curation ensures that every artist we represent meets the highest standards of creativity and professionalism.</p>
        <p>Artists are then categorized into one of four tiers:</p>
        <ul className={styles.faqx}>
          <li><span>Emerging:</span> Artists in the early stages of their career, showcasing potential and creativity.</li>
          <li><span>Experienced:</span> Artists with an established portfolio and growing recognition.</li>
          <li><span>Acclaimed:</span> Well-regarded artists with notable achievements and significant influence.</li>
          <li><span>Masters:</span> Distinguished artists with exceptional talent and a lasting legacy.</li>
        </ul>
        <p>We also consider whether the artist is working full-time or part-time and adjust their categorization accordingly. This tiered approach helps buyers and collectors identify artists who match their preferences and interests, while also providing artists with tailored opportunities to grow and succeed on the platform.</p>
        <div className={styles.mt_2}>
    <a href="artist-application" className="apply_btn">Explore our Artists</a>
    </div>
      </>
    ),
  },
];
    const featurebeni =[
      {
        id:1,
        heading: "Expert Consultation",
        des: "Personalized recommendations and curated selections tailored to your specific project needs.",
      },
      {
        id:2,
        heading: "Exclusive Discounts",
        des: "Save 10% on all purchases over $1,500.",
      },
      {
        id:3,
        heading: "Comprehensive Support",
        des: "Dedicated assistance at every step, from choosing the artwork to final installation.",
      },
      {
        id:4,
        heading: "Complete Art Solutions",
        des: "Full-service offerings, including design integration and flawless delivery.",
      },
      {
        id:5,
        heading: "Priority Access",
        des: "Early previews of our latest collections and exclusive updates.",
      },
      {
        id:6,
        heading: "Timely Delivery",
        des: "Efficient and reliable delivery that aligns perfectly with project timelines.",
      },
    ];
    const service_data = [
      {
        id: 1,
        heading: "Exploring Global Artistic Horizons",
        des: "We go beyond traditional boundaries to explore art from every corner of the world. Our team seeks out talent that represents a wide spectrum of styles, techniques, and cultural perspectives from bustling art fairs and renowned exhibitions to hidden studios and local galleries. This global approach allows us to curate diverse collections that cater to varied tastes and spaces.",
      },
      {
        id: 2,
        heading: "Navigating the World of Art Fairs and Art Exhibitions",
        des: "Art fairs and exhibitions serve as vibrant platforms for artist discovery. Our team attends prestigious events around the world, from renowned fairs in cultural capitals to emerging art exhibitions in smaller cities. These events give us access to a diverse range of artists, helping us identify creators with unique visions and exceptional skills.",
      },
      {
        id: 3,
        heading: "Engaging with Local Art Communities",
        des: "Some of the most inspiring art comes from grassroots movements and local communities. We engage directly with local art hubs, attending workshops, open studios, and cultural festivals to discover artists whose works reflect the authenticity and traditions of their regions.",
      },
      {
        id: 4,
        heading: "Leveraging Digital Platforms",
        des: "In today’s connected world, digital platforms have become invaluable tools for sourcing talent. We explore online galleries, artist portfolios, and social media to identify creators who demonstrate originality and innovation. This approach allows us to connect with artists who might otherwise remain undiscovered.",
      },
      {
        id: 5,
        heading: "Collaborating with Art Institutions",
        des: "Our partnerships with art schools, cultural organizations, and museums help us tap into a rich talent pool. These collaborations not only allow us to discover exceptional artists but also support the development of future generations of creatives.",
      },
    ];
    const testimoniald = [
      {
        id:1,
        src: "/img/event3",
        name: "Olivia Thompson",
        designation: " New York",
        hnd:"Honoring Heritage Through Digital Space",
        des: "Art Smiley beautifully showcases Indigenous art, offering virtual storytelling and curated collections. It’s an excellent platform for supporting contemporary artists while appreciating the cultural significance behind their works, all in a modern, digital format.",
      },
      {
        id:2,
        src: "/img/event3",
        name: "Amina Patel",
        designation: "Mumbai",
        hnd:"Empowering Artists with Global Reach",
        des: "As an artist, Art Smiley has truly revolutionized the way I approach showcasing my work. The platform’s combination of virtual and physical exhibitions has expanded my reach beyond traditional gallery spaces, connecting me with a global audience. It feels like a seamless fusion of traditional art presentation with modern technology, giving me a unique opportunity to showcase my creativity in new ways.",
      },
      {
        id:3,
        src: "/img/event3",
        name: "Luca Ferraro",
        designation: "Rome",
        hnd:"An Innovative Space for Artistic Expression",
        des: "As an artist, Art Smiley provides an innovative platform that perfectly blends technology with creativity. The virtual exhibitions have opened up a world of possibilities for me, allowing my work to be experienced and appreciated by people from different corners of the globe. Whether virtual or physical, the ability to present my work across different mediums has been invaluable to my growth as an artist, and has helped me expand my presence in the art market.",
      },
      {
        id:4,
        src: "/img/event3",
        name: "William Carter",
        designation: "Edinburgh",
        hnd:"Seamlessly Blending Tradition with Technology",
        des: "As an artist working in traditional mediums, I was initially hesitant about entering the digital space. However, Art Smiley has proven that technology can enhance, rather than overshadow, traditional art. Their virtual exhibitions beautifully present my work while maintaining the integrity of the physical details. The feedback from collectors who discover my work online and then view it in physical exhibitions has been overwhelmingly positive. It’s a perfect synergy.",
      },
    ];
    const testimoniacols = [
      {
        id:1,
        src: "/img/event3",
        name: "Jason Miller",
        designation: "Los Angeles",
        hnd:"A Bold, Interactive Experience",
        des: "Abstract art has always been a personal journey for me, and the Art Smiley platform offers a truly engaging experience for collectors of this genre. The platform allows me to explore not only static images of abstract works but also immersive experiences, where the art itself responds to user interaction. I’ve been particularly impressed with the ‘emotion-driven’ features, where abstract pieces change dynamically based on the preferences I select. This interactivity creates a much deeper connection to the art. For collectors who appreciate abstract expressionism and the freedom it offers, Art Smiley is a great tool to discover new, experimental artists in the field and expand one’s art collection.",
      },
      {
        id:2,
        src: "/img/event3",
        name: "Evelyn Grant",
        designation: "Paris",
        hnd:"A Game Changer for Modern Collecting",
        des: "The Art Smiley platform has transformed my experience with contemporary art. It offers a fantastic range of innovative, emerging artists, and modern artwork collections. The virtual exhibitions bring cutting-edge works right to my screen, making art collecting more accessible and engaging.",
      },
      {
        id:3,
        src: "/img/event3",
        name: "Marcus Wang",
        designation: "New York",
        hnd:"Revolutionizing Art Discovery",
        des: "As a long-time collector, I’ve never experienced anything quite like the Art Smiley platform. The ability to discover and acquire artworks from around the globe, coupled with the high-quality curation of pieces, has been a game changer for my collection. The platform’s intuitive design makes it easy to navigate, and the direct interaction with artists is a unique feature that adds incredible value. It’s not just a marketplace; it’s a community.",
      },
      {
        id:4,
        src: "/img/event3",
        name: "Isabella Rossi",
        designation: "Milan",
        hnd:"An Unparalleled Curatorial Experience",
        des: "The Art Smiley platform has elevated my art collecting to a whole new level. The curated selections showcase a perfect blend of classic and innovative works, and the attention to detail in the presentation of each piece makes the entire process feel personal. I especially appreciate the opportunity to explore behind-the-scenes insights into the artists' creative processes. For collectors who want more than just a art, Art Smiley offers an unparalleled curatorial experience.",
      },
    ];

    const faq_data = [
      {
        id: 1,
        question: "1. What is the process for commissioning custom artwork with Art Smiley?",
        answer: `<p>The process is simple and collaborative. Here's how it works:</p>
        <ul>
          <li><span>Initial Consultation:</span> We discuss your vision, style, and the purpose of the artwork.</li>
          <li><span>Concept Development:</span> We create a mood board or design brief to capture the direction.</li>
          <li><span>Artist Selection:</span> We match you with the perfect artist who aligns with your desired style.</li>
          <li><span>Approval & Finalization:</span> You provide feedback on the initial concepts, ensuring it's exactly what you envision.</li>
          <li><span>Creation of Artwork:</span> The artist creates your custom piece, and we keep you updated with progress shots.</li>
          <li><span>Delivery & Installation:</span> We manage the delivery and professional installation of the artwork.</li>
          <li><span>Final Touches:</span> We ensure your piece is perfectly placed, and offer post-installation support.</li>
        </ul>
        `
      },
      {
        id: 2,
        question: "2. What types of artwork can I commission?",
        answer: "<p>You can commission any type of artwork that fits your style and space. This includes residential, corporate, or public art in a variety of categories such as paintings, sculptures, photography, or digital art.</p>"
      },
      {
        id: 3,
        question: "3. How do you match me with an artist?",
        answer: "<p>We have a curated network of talented artists with diverse styles and mediums. Based on your vision, we’ll match you with an artist who specializes in the look and feel which you expect for your artwork.</p>"
      },
      {
        id: 4,
        question: "4. How much does it cost to commission a piece of artwork?",
        answer: "<p>The cost of commissioning artwork depends on factors like the size, complexity, and medium of the piece, as well as the artist’s experience. We will provide you with a customized quote after our initial consultation.</p>"
      },
      {
        id: 5,
        question: "5. How long does it take to complete my commissioned artwork?",
        answer: "<p>The timeline depends on the complexity of the project and the artist's schedule. On average, the process takes between 4-8 weeks. We will provide you with an estimated completion time during the consultation phase.</p>"
      },
      {
        id: 6,
        question: "6. Can I request revisions during the process?",
        answer: "<p>Yes! We want you to be 100% satisfied with your custom piece. After we present initial concepts, you’ll have the chance to provide feedback and request any changes before the final artwork is created.</p>"
      },
      {
        id: 7,
        question: "7. Do you provide installation services?",
        answer: "<p>Yes, we handle the delivery and professional installation of your artwork to ensure it’s perfectly placed and secure in your space across UAE.</p>"
      },
      {
        id: 8,
        question: "8. Can I commission a piece as a gift?",
        answer: "<p>Absolutely! Custom artwork makes a unique and meaningful gift. We can help create something that will be cherished for years to come whether it's for a birthday, anniversary, or special occasion.</p>"
      },
      {
        id: 9,
        question: "9. What if I need help deciding on the style or type of art?",
        answer: "<p>Our dedicated team is here to assist you throughout the process. We’ll offer guidance and suggestions based on your space, preferences, and the purpose of the artwork.</p>"
      },
      {
        id: 10,
        question: "10. What happens after my artwork is installed?",
        answer: "<p>After your artwork is installed, we’ll ensure everything is in place and looks perfect. We offer post-installation support to make sure you are completely satisfied with how the piece fits into your space.</p>"
      },
    ];
    const tpoics =[
      {
        id:1,
        heading: "Flexible Return Options",
        des:"Pick the most convenient time for a courier to retrieve the artwork.",
         
      },
      {
        id:2,
        heading: "Best Choices",
        des:"Make your decision with confidence, backed by our 14-day free return policy.",
         
      },
      {
        id:3,
        heading: "Prompt Refunds",
        des:"Receive your refund within 4 to 6 weeks, once when the artwork is returned.",
         
      },
    ];
    const ourArtistCard = [
      {
        id: 1,
        imgUrl: "/img/ourartist1.png",
        artistName: "Maria Cooper",
        position: "Interior Designer",
        des:"Our curators can assist you in uncovering art that you cherish."
      },
      {
        id: 2,
        imgUrl: "/img/ourartist2.png",
        artistName: "Titus Archibald",
        position:"Interior Designer, Lifestylist",
        des:"Our curators can assist you in uncovering art that you cherish."
      },
      {
        id: 3,
        imgUrl: "/img/ourartist3.png",
        artistName: "Winslow Homer",
        position: "Designer, Mississippi Native",
        des:"Our curators can assist you in uncovering art that you cherish."
      },
    ];

const [exclusiveExpanded, setExclusiveExpanded] = React.useState('excpanel0'); // Default to the first panel

const handleExclusiveChange = (panel) => (event, isExpanded) => {
  setExclusiveExpanded(isExpanded ? panel : false);
};

const prevButtonRef = useRef(null);
        const nextButtonRef = useRef(null);

  const [expandedIndex, setExpandedIndex] = useState(null);
          const [isOverflowing, setIsOverflowing] = useState(false);
          
          const contentRef = useRef(null);
          
          useEffect(() => {
            if (contentRef.current) {
              const isContentOverflowing =
                contentRef.current.scrollHeight > contentRef.current.clientHeight;
              setIsOverflowing(isContentOverflowing);
            }
          }, []);
          
          const toggleReadMore = (index) => {
            setExpandedIndex(expandedIndex === index ? null : index); // Toggle state
          };

          const [expanded, setExpanded] = React.useState(false);
          const handleChange = (panel) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
          };

          const scrollToSection = () => {
            const section = document.getElementById('target-section');
            if (section) {
              // Calculate offset to account for the fixed header
              const headerOffset = 100; // Height of your fixed header
              const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
              const offsetPosition = sectionPosition - headerOffset;
        
              // Scroll to the calculated position
              window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
              });
            }
          };
           const [activeTab, setActiveTab] = useState(0);
  return (
    <>
    <React.Fragment>
       <CssBaseline />
    <section className={styles.hero_main}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.pageitle} ${styles.max_width}`}>
              <h1>Curation Process </h1>
               
            </div>
</Container>
        </section>
        <section className={styles.sec_scexcl}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
        
        <Grid item xs={12} sm={6} md={6} order={{ xs: 2, sm: 1 }}>
         
              <div className={styles.vintageframeDiv}>
                <img
                  className="img-fluid"
                  src={ "/img/vintage-wood-frame.png"} alt=""
                />
              </div>
        </Grid>
        <Grid item xs={12} sm={5} md={5} order={{ xs: 1, sm: 2 }}>
        <div className={styles.leftText}>
              <h4 className={styles.abttitle}>How Do We Curate Art?</h4>
              <p className={styles.prtra}>
              At Art Smiley, we are passionate about creating a seamless bridge between exceptional artists and discerning art lovers. Art is a medium that conveys stories, evokes emotions, and sparks connections. Our art curation process ensures that every piece in our collection stands out for its uniqueness, quality, and relevance, making it a valuable addition to your home, workspace, or gallery. This comprehensive process celebrates artistic diversity and empowers artists by providing them with a global art platform to showcase their talent.
                </p>
                </div>
        </Grid>
        </Grid>
        </Container>
      </section>
      <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width1}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>How Do We Approach the Artists?</h3>
          <h5>Discovering Hidden Artistic Skills and Promoting Talent</h5>
          <p className={styles.withor}>At Art Smiley, sourcing artists is as important as curating their work. As an art platform for artists, we believe in nurturing talent, supporting creativity, and giving artists the recognition they deserve. Here’s how we discover and connect with artists who bring life to our collections:</p>
          </div>
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
          <Grid item md={6} sm={6} xs={12}>
          <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
          </Grid>
          <Grid item md={5} sm={6} xs={12}>
      <div className={`${styles.accordsc}`}>
      {service_data.map((val, index) => {
      const panelId = `excpanel${index}`; // Dynamic panel ID
      return (
        <Accordion
          key={panelId}
          expanded={exclusiveExpanded === panelId} // Check if the panel is expanded
          onChange={handleExclusiveChange(panelId)}
          sx={{
            "& .MuiAccordionSummary-contentGutters": {
              margin: "10px 0", // Adjust as needed
            },
            "&.MuiPaper-root": {
              boxShadow: "none",
              borderBottom: "1px solid #D9DBE9",
              marginBottom:"0",
              borderBottomLeftRadius:"0",
              borderBottomRightRadius:"0",
            },
            "&.MuiAccordion-root::before":{
              display:"none",
            },
            "& .Mui-expanded": {
              minHeight:"auto !important",
              margin:"0 0 10px 0 !important"
            },
            "& .MuiAccordionSummary-expandIconWrapper":{
              background:"#B7B7B7",
              opacity:".3",
              textAlign: "center",
              borderRadius:"8px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
              background:"#1C1C1C",
              opacity:"1",
              color:"#fff",
            },
            "& .MuiAccordionDetails-root":{
              paddingTop:"0"
            },
            
          }}// Handle expansion state
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${panelId}-content`}
            id={`${panelId}-header`}
          >
            <h4 className={styles.benfhdgn}>{val.heading}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={styles.bendesc}>{val.des}</p>
          </AccordionDetails>
        </Accordion>
      );
    })}

      </div>
          </Grid>
          </Grid>
          </div>
          </Container>
          </section>
          <section className={`${styles.sec_scexcl} ${styles.sec_shgn}`}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          <div className={`${styles.text_center} ${styles.max_width1}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>How do We Curate Artists?</h3> 
          <p className={styles.withor}>At Art Smiley, we are committed to nurturing talent and providing a platform for artists to showcase their artwork. The curation process begins when an artist submits their application through our platform. Each application is carefully reviewed by our team of expert curators. Once approved, the artist can upload their online art submissions to the platform, making them available for sale and connecting with collectors worldwide.</p>
          </div>
          <div className={styles.mtb_2}>
                    <Grid container spacing={2} sx={{justifyContent:"space-between"}}>
                    <Grid item md={5} sm={6} xs={12}>
                    <div>
                        <img src={"/img/apply-atrist.jpeg"} alt="mockup" className="img-fluid" />
                        </div>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                            <div>
                              <ul className={styles.timeline}>
                                <li>
                                <div className={styles.stepli}>
                              <h4>Artist Application</h4>
                              <p>At Art Smiley, we believe in inclusivity and accessibility, and we enthusiastically welcome artist applications to join our platform. Our commitment to fostering talent is reflected in our streamlined and open submission process, which offers artists the opportunity to showcase their work in front of a global audience. By joining our curated collections, artists gain exposure to a diverse range of art lovers, collectors, and enthusiasts from around the world. We empower artists to expand their reach and elevate their careers through virtual exhibitions, online auctions, and collaborative events.</p>
    <div className={styles.mt_1}>
    <a href="artist-application" className="apply_btn">Apply Now</a>
    </div>
                              </div>
                                </li>
                                <li>
                                <div className={styles.stepli}>
                              <h4>Artist Verification</h4>
                                <p>Maintaining authenticity and integrity is a top priority for Art Smiley. Every artist undergoes a thorough verification process. This includes verifying the originality of their artwork to ensure that each piece is genuinely created by the submitting artist. Additionally, artists are required to provide valid identification documents, such as a passport or an identification card, to confirm their identity. This process safeguards the trust of buyers and collectors on our platform.</p>
                             </div>
                                </li>
                                <li>
                                <div className={styles.stepli}>
                              <h4>Artist Categorization</h4>
                              <p>Once an artist is verified, their profile and portfolio undergo a meticulous artist curation process based on several criteria. Our curators assess the quality of their artwork, technical skills, and the mediums or styles they use. We also consider the artist's talent, education, participation in exhibitions, international reputation, and their unique artistic perspective. Thematic and historical relevance, as well as the originality of concepts and themes, play a significant role in our evaluation. This thorough curation ensures that every artist we represent meets the highest standards of creativity and professionalism.</p>
        <p>Artists are then categorized into one of four tiers:</p>
        <ul className={styles.faqx}>
          <li><span>Emerging:</span> Artists in the early stages of their career, showcasing potential and creativity.</li>
          <li><span>Experienced:</span> Artists with an established portfolio and growing recognition.</li>
          <li><span>Acclaimed:</span> Well-regarded artists with notable achievements and significant influence.</li>
          <li><span>Masters:</span> Distinguished artists with exceptional talent and a lasting legacy.</li>
        </ul>
        <p>We also consider whether the artist is working full-time or part-time and adjust their categorization accordingly. This tiered approach helps buyers and collectors identify artists who match their preferences and interests, while also providing artists with tailored opportunities to grow and succeed on the platform.</p>
        <div className={styles.mt_1}>
    <a href="artist-application" className="apply_btn">Explore our Artists</a>
    </div>
                           </div>
                                </li>
                              </ul>
                            </div>
                      </Grid>
                      </Grid>
                      </div>
        {/* <div className={styles.mt_2}>
      <div className="tab-headers">
          <ul className={styles.tablist}>
          {tabs.map((tab, index) => (
            <li>
              <Link
            key={index}
            className={`tab-button ${activeTab === index ? 'active' : ''}`}
            onClick={() => setActiveTab(index)}
            style={{
              background: activeTab === index ? '#1c1c1c' : '#fff',
              color: activeTab === index ? '#fff' : '#1c1c1c',
            }}
          >
            {tab.label}
          </Link>
            </li>
        ))}
          </ul>
      </div>
         <div className={`tab-content ${styles.tab_content}`}>
        {tabs[activeTab] && <div>{tabs[activeTab].content}</div>}
      </div>
      </div> */}

        </Container>
      </section>
        <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}><div className={`${styles.text_center} ${styles.max_width1}`}>
  <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
  <h3 className={styles.sec_title}>How We Curate Artworks?</h3>
  <p className={styles.withor}>Curation is crafting a collection that inspires and resonates. At Art Smiley, our curatorial approach reflects the principles of global art curation, combining creativity, strategy, and a deep understanding of international art trends. This ensures that our collections not only captivate diverse audiences but also showcase a wide spectrum of artistic talent from around the world.</p>
  </div>
            
          <div className={styles.taldartist}>
            <Grid container spacing={2} sx={{justifyContent:"center"}}>
            {exclusive_data.map((val) => (
            <Grid item xs={12} sm={6} md={6} key={val.id}>
              <div className={styles.talhg}>
                <div className={styles.talgbx}>
                <div className={styles.talenimg}>
                    <img
                    alt="img"
                    src={`${val.src}.png`}
                    className="img-fluid"
                  />
                    </div>
                    <div className={styles.talentcont}>
                      <h4>{val.heading}</h4>
                      <p>{val.des}</p>
                    </div>
                </div>
              </div> 
            </Grid>
            ))}
            </Grid>
            </div>
          </Container>
          </section> 
      <section className={styles.sec_scexcl}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.max_width} ${styles.text_center}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title}`}>How Do We Curate Art For Exhibitions and Auctions?</h3>
          </div>
        <div className={styles.taldartist}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.talhg}>
                <div className={styles.talgbx}>
                    <div className={styles.talentcont}>
                      <h4>Exhibitions</h4>
                      <p>At Art Smiley, we specialize in art curation for exhibitions and auctions, adopting a theme-driven approach to selecting artworks for both online and physical events. Only pieces published on our platform and chosen by artists for promotion are considered. Our curators meticulously review each submission to ensure it aligns with the exhibition or auction theme while resonating with the intended audience. This process highlights the artist’s vision and appeals to collectors, creating a meaningful and engaging experience. Explore our upcoming and current exhibitions.</p>
                      <div className={styles.mt_2}>
                      <a href="#" className="apply_btn">Exhibitions</a>
                      </div>
                    </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.talhg}>
                <div className={styles.talgbx}>
                    <div className={styles.talentcont}>
                      <h4>Auctions</h4>
                    <p>Our expertise extends to online auctions, where we curate artworks based on the auction theme and their readiness for immediate sale. Through collaboration with artists, we ensure that their most compelling works are included, providing increased visibility and enhancing their market reach. By focusing on curation for exhibitions and online auctions, we strive to offer an engaging platform that benefits artists and collectors, delivering outstanding experiences for all. Check out the upcoming online auctions.</p>
                    <div className={styles.mt_2}>
                    <a href="#" className="apply_btn">Auctions</a>
                    </div>
                    </div>
                </div>
              </div>
            </Grid>
            </Grid>
            </div>
        </Container>
      </section>
      <section className={styles.arconsec}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <Grid container spacing={2} sx={{alignItems:"center"}}>
              <Grid item xs={12} md={8}>
                <h3>Looking For Expert Art Guidance?</h3>
                <p  className={`${styles.pytrg}`}>Discover the perfect pieces with help from our professional curators. We’re here to make the art curation process seamless and enjoyable from selecting art to framing and hanging.</p>
              </Grid>
              <Grid item xs={12} md={4}>
              <div className={styles.text_center}>
              <button onClick={() => handleNavigate('/user/art-consultancy/questionnaire')} className="apply_ltbtn">Consult Now!</button>
              </div>
                
              </Grid>
            </Grid>
          </Container>
        </section>
        {/* <section className={styles.sec_scexcl}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Curators Team</h3>
          </div>
          <div>
          <Swiper
        modules={[Navigation, Autoplay]}
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 3000, 
          disableOnInteraction: false, 
        }}
        navigation={{
          prevEl: prevButtonRef.current,
          nextEl: nextButtonRef.current,
        }}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
      >
        {ourArtistCard.map((item) => (
        <SwiperSlide>
          <div className={styles.ourartistcard}>
          <img className={styles.ourartistimg} src={  item.imgUrl} alt=""/>
          <div className={styles.ourartistinfo}>
            <h4>{item.artistName}</h4>
            <p className={styles.tdr}>{item.position}</p>
            <p>{item.des}</p>
          </div>
        </div>
        </SwiperSlide>
        ))}
      </Swiper>
     <div
        ref={prevButtonRef}
        className="swiper-button-prev"
      />
      <div
        ref={nextButtonRef}
        className="swiper-button-next"
      />   
          </div>
          </Container>
        </section> */}
        <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Artist Reviews</h3>
          </div>
          <section className={`testmo-slider ${styles.sections}`}>
          <Swiper
        modules={[Navigation, Autoplay]}
                slidesPerView={1}
                spaceBetween={10}
                loop={true}
                autoplay={{
                  delay: 3000, // Set autoplay delay in milliseconds (3 seconds here)
                  disableOnInteraction: false, // Allows autoplay to continue after user interactions
                }}
                onInit={(swiper) => {
                  swiper.params.navigation.prevEl = prevButtonRef.current;
                  swiper.params.navigation.nextEl = nextButtonRef.current;
                  swiper.navigation.init();
                  swiper.navigation.update();
                }}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        }}
      >
{testimoniald.map((val, index) => (
                    <SwiperSlide key={index}>
                      <div className={styles.tehgt}>
                       <div className={styles.testmibox}>
                      <div className={styles.tecont}>
                        <div className={styles.hders}>
                        <div className={styles.testimonial_img_div}>
                          <img
                            src={`${val.src}.png`}
                            width="80px"
                            height="80px"
                            alt=""
                          />
                        </div>
                        <div className={styles.test_metainfo}>
                        <h5 className={styles.test_card_heading}>
                        {val.name}
                          </h5>
                          <h6 className={styles.test_card_subhead}>
                          {val.designation}
                          </h6>
                        </div>
                        </div>
                        <div className={styles.test_cont}>
                          <h4>{val.hnd}</h4>
                          <p className={styles.test_card_des}>{val.des}</p>
                        </div>
                        </div>
                      </div>
                      </div>
                    </SwiperSlide>
                 ))}
              </Swiper>
              <div className="tesarrow">
          <div ref={prevButtonRef} className="swiper-button-prev">
                    <RiArrowLeftLine />
                  </div>
                  <div ref={nextButtonRef} className="swiper-button-next">
                    <RiArrowRightLine />
                  </div>
      </div>
        </section>
       
        </Container>
        </section>
        <section className={styles.sec_scexcl}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Collectors Review</h3>
          </div>
          <section className={`testmo-slider ${styles.sections}`}>
          <Swiper
        modules={[Navigation, Autoplay]}
                slidesPerView={1}
                spaceBetween={10}
                loop={true}
                autoplay={{
                  delay: 3000, // Set autoplay delay in milliseconds (3 seconds here)
                  disableOnInteraction: false, // Allows autoplay to continue after user interactions
                }}
                onInit={(swiper) => {
                  swiper.params.navigation.prevEl = prevButtonRef.current;
                  swiper.params.navigation.nextEl = nextButtonRef.current;
                  swiper.navigation.init();
                  swiper.navigation.update();
                }}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
        }}
      >
{testimoniacols.map((val, index) => (
                    <SwiperSlide key={index}>
                      <div className={styles.tehgt}>
                       <div className={styles.testmibox}>
                      <div className={styles.tecont}>
                        <div className={styles.hders}>
                        <div className={styles.testimonial_img_div}>
                          <img
                            src={`${val.src}.png`}
                            width="80px"
                            height="80px"
                            alt=""
                          />
                        </div>
                        <div className={styles.test_metainfo}>
                        <h5 className={styles.test_card_heading}>
                        {val.name}
                          </h5>
                          <h6 className={styles.test_card_subhead}>
                          {val.designation}
                          </h6>
                        </div>
                        </div>
                        <div className={styles.test_cont}>
                          <h4>{val.hnd}</h4>
                          <p className={styles.test_card_des}>{val.des}</p>
                        </div>
                        </div>
                      </div>
                      </div>
                    </SwiperSlide>
                 ))}
              </Swiper>
              <div className="tesarrow">
          <div ref={prevButtonRef} className="swiper-button-prev">
                    <RiArrowLeftLine />
                  </div>
                  <div ref={nextButtonRef} className="swiper-button-next">
                    <RiArrowRightLine />
                  </div>
      </div>
        </section>
       
        </Container>
        </section>
        <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Collect Art With Assurance</h3>
          </div>
          <Grid container spacing={2}>
          
          {tpoics.map((val, index) => (
                <Grid item xs={12} sm={6} md={4} key={val.id} >
                  <div  key={index} className={styles.stboxh}>
                <div className={styles.rechhbox2}>
                  <div className={styles.sticon}>
                    <p className={styles.hgrd}>{val.id}</p>
                  </div>
                  <div className={styles.hdrng}>
                  <h4>{val.heading}</h4>
                  <div className={styles.stcont}>
                    
                     <p>{val.des}</p>
    </div>
                  </div>
                </div>
                </div> 
                </Grid>
          ))}
            </Grid>
            <div className={styles.nedhlp}> 
               <h4>Need Help? </h4>
               <button onClick={() => handleNavigate('/user/contactus')} className="apply_btn">Contact Art Smiley Today!</button>
            </div>
          </Container>
          </section>
    
          
          </React.Fragment>
    </>
  );
}

export default ArtCurationProcess;
