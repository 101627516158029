import validator from "validator"
import isEmpty from "./isEmpty"





export const ValidationAdminPrintPricing =(print)=>{
    let ErrorsPrintPricing ={};

    if(validator.isEmpty(print.material)){
        ErrorsPrintPricing.material = 'Material is required'
    }

    if(validator.isEmpty(print.product)){
        ErrorsPrintPricing.product = "Product is required"
    }
    if(validator.isEmpty(print.price)){
        ErrorsPrintPricing.price = 'Price is required'
    }


    return{
        ErrorsPrintPricing,
        isValid: isEmpty(ErrorsPrintPricing),

    }
}

