import validator from "validator"
import isEmpty from "./isEmpty"

export const validateAdminEditHero = (hero) => {
  let editHeroErrors = {}

  if (validator.isEmpty(hero.title)) {
    editHeroErrors.title = "Title is required"
  }

  // if (
  //   !press.item_type_id ||
  //   press.item_type_id === null ||
  //   press.item_type_id === undefined
  // ) {
  //   pressErrors.item_type_id = "Press Type is required"
  // }

  if (validator.isEmpty(hero.sub_title)) {
    editHeroErrors.sub_title = "Subtitle is required"
  }
  if (validator.isEmpty(hero.button_name)) {
    editHeroErrors.button_name = "Button name is required"
  }
  if (validator.isEmpty(hero.position)) {
    editHeroErrors.position = "Position is required"
  }
   if (validator.isEmpty(hero.link)) {
    editHeroErrors.link = "Link is required";
     } else {
       let formattedLink = hero.link.startsWith("http://") || hero.link.startsWith("https://")
         ? hero.link
         : `http://${hero.link}`;
   
       const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]*[a-zA-Z]+[a-zA-Z0-9-]*\.(com|org|net|in|edu|gov|co|io|info|biz|me)(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;
   
       if (!regex.test(formattedLink) || !validator.isURL(formattedLink)) {
        editHeroErrors.link = "Please enter a valid URL (e.g., www.google.com or https://example.com)";
       }
     }

  if (!hero.image || hero.image === null) {
    editHeroErrors.image = "Image is required"
  }

  return {
    editHeroErrors,
    isValid: isEmpty(editHeroErrors),
  }
}
