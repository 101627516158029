import { Button, Fade, Modal } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./Formsubmission.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import homeImage from "../../../../constant";
import Navbar from "../../../../components/Pages/Navbar/Navbar";
import Header from "../../../../components/Pages/Header/Header";
import Footer from "../../../../components/Pages/Footer/Footer"
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { RiCheckboxCircleLine } from "react-icons/ri";
export default function Formsubmission(props) {
  const handleClose = () => {
    props.setOpen(false);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, [])

  const path = window.location.pathname.split("/")[1];

  const dynamicText = {
    artist: `Thank you for registering as an artist with Art Smiley! You’re one step closer to joining a vibrant community of artists and art enthusiasts. Our team is currently reviewing applications to showcase the diverse and inspiring talented artists. This process takes approximately 3 to 4 weeks.

Once your registration is approved, you’ll receive access to your personalized dashboard, where you can complete your profile and prepare to showcase your art. We’ll notify you as soon as your profile is approved and connect with art lovers worldwide.`,
    curator: `Thank you for registering as a curator with Art Smiley! Our team is currently reviewing applications to bring together a distinguished group of curators to enrich our platform. This review process typically takes about 3 to 4 weeks. 

Once your registration is approved, you’ll gain access to your personalized dashboard, where you can complete your profile and begin engaging with our community. We’ll notify you as soon as your profile is approved and ready to connect with artists and collectors.`,
    affiliate: `Thank you for signing up as an affiliate with Art Smiley! You’re on the path to partnering with a thriving community dedicated to promoting and celebrating art. Our team is currently reviewing affiliate applications to build a strong network that supports our artists and collectors. This process typically takes about 3 to 4 weeks. 

Once approved, you’ll gain access to your affiliate dashboard, where you can track your referrals, access marketing resources, and start earning commissions. We’ll notify you as soon as your profile is approved.`,
    gallery: `Thank you for registering as a gallery with Art Smiley! You’re about to join a global network of artists, collectors, and galleries dedicated to promoting exceptional art. Our team is reviewing your application to ensure it aligns with our community’s diverse artistic standards. This review process generally takes 3 to 4 weeks. 

Once approved, you’ll have access to your gallery dashboard, where you can showcase your collections, engage with art lovers, and connect with other galleries. We’ll reach out as soon as your profile is approved and ready to showcase your gallery’s offerings.`,
  };

  return (
    <>


      <Header />
      <Navbar />
      <div className={styles.hero_main_curator}>
        <Container
          maxWidth="xl"
          sx={{
            paddingLeft: { xs: '16px', sm: '24px', md: '60px', lg: '70px', xl: '100px' },
            paddingRight: { xs: '16px', sm: '24px', md: '60px', lg: '70px', xl: '100px' },
          }}
        >
          <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid item md={8} sm={8} xs={12}>
              <div
                className={
                  path === "artist"
                    ? styles.Formsubmission
                    : path === "curator"
                      ? styles.FormsubmissionCurator
                      : path === "affiliate"
                        ? styles.FormsubmissionAffiliate
                        : path === "gallery"
                          ? styles.FormsubmissionGallery
                          : null
                }
              >
                <div className={styles.done_icon}>
                  {/* <div className={styles.sucicon}>
                    <RiCheckboxCircleLine />
                  </div> */}
                  <div className={styles.thankimg}>
            <img
                  className="img-fluid"
                  src={ "/img/thankyou.png"} alt=""
                />
            </div>
                  <h1>Your submission has been received.</h1>
                  <p style={{ whiteSpace: 'pre-line' }}>
                    {dynamicText[path] || "Thank you for your submission. Our team will get back to you soon."}
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
      {/* <Header />
      <Navbar />
      <div className={styles.hero_main_curator}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
    <Grid container spacing={2} sx={{justifyContent:"center"}}>
    <Grid item md={8} sm={8} xs={12}>
    <div
        className={
          window.location.pathname.split("/")[2] === "artist"
            ? styles.Formsubmission
            : window.location.pathname.split("/")[2] === "curator"
            ? styles.FormsubmissionCurator
            : window.location.pathname.split("/")[2] === "affiliate"
            ? styles.FormsubmissionAffiliate
            : window.location.pathname.split("/")[2] === "gallery" ? styles.FormsubmissionGallery : null
        }
      >
          <div className={styles.done_icon}>
              <div className={styles.sucicon}>
              <RiCheckboxCircleLine />
              </div>
              <h1>Thank you for submitting the Application. Our curator will review and get back to you as soon as possible.</h1>
            </div>
      </div>
    </Grid>
    </Grid>

  </Container>
  </div>
      
      <Footer /> */}
    </>
  );
}
