import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import dayjs from "dayjs"
import { Box, IconButton } from "@mui/material"
// import Divider from '@mui/material/Divider';
import React from "react"
import styles from "./AdminRatingReview.module.css"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { FiTrash2 } from "react-icons/fi"
import CircularLoader from "../../../../../../common/loader/Loader"

export default function AdminratingReview(props) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null)

  // const dispatch = useDispatch();
  // const data = useSelector((state)=>state.artistdata);
  
  const loader = useSelector((state) => state.loader)

  const threedots = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handlePopup = (e) => {
    
    props.setOpen(true)
    props.setPoptype("addfeature")
  }
  const handleProfilepage = (id, name, row) => {
    // props.setPage('profile')
    navigate(`/user/newestpaintings/${id}`)
    props.setPendingProfileData &&
      props.setPendingProfileData({ artist_name: name })
    // dispatch(addData(row));
  }
  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  align="left"
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "15px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props.data &&
                props.data.map((row, i) => (
                  <TableRow
                    style={{ color: "#636365", cursor: "pointer" }}
                    key={i}
                  >
                    {/* <Link to={`/artist/${row.id}`}> */}
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {/* <img alt="img" src={`/img/${row.src}`} height="42px" width="60px" /> */}
                      {row.item_image && (
                        <img
                          // onClick={() => handleClickImg()}
                          alt="img"
                          src={` ${row.item_image}`}
                          height="42px"
                          width="60px"
                        />
                      )}
                      {/* this is for gallery >>> admin offer tab */}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                      value={row.buyer_name}
                    >
                      {row?.user ? row?.user : "----"}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.item_rating ? row.item_rating : "----"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.star_rating ? row.star_rating : "----"}
                    </TableCell>
                    {props.activetab === "RatingReview" && (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.item_review ? row.item_review : "----"}
                      </TableCell>
                    )}

                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {dayjs(row.createdAt).format("DD-MM-YYYY").toString()}
                    </TableCell>

                    <TableCell
                      sx={{
                        width: "20%",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.column}
                      align="center"
                    >
                      <div className={styles.career_lastcol}>
                        {row.item_type ? row.item_type : "----"}
                        {/* <img src={ /img/Edit.png'} height='15px' width='14px' /> */}
                        
                        <IconButton onClick={() => props.handleDeleOrder(row._id)}>
                        <FiTrash2 className={styles.deleicon}/>
                        </IconButton>
                        
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  )
}
