import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
// import Divider from '@mui/material/Divider';
import React, { useState } from "react";
import styles from "./Sixcolumnimg.module.css"
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import serverimage from "../../../../../serverConstant";
import homeImage from "../../../../../constant";
import { Successnotify } from "../../../../../common/alert/Alert";
import { ToastContainer } from "react-toastify";
import moment from "moment/moment";

export default function Sixcolumnimg(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ids, setIds] = useState('');
  const loader = useSelector((state) => state.loader);
  const threedots = Boolean(anchorEl);
  const handleClick = (event) => {
    const id = event.target.id;
    const value = event.target;
    setAnchorEl(event.currentTarget);
    setIds(event.target.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePopup = (e) => {
    props.setOpen && props.setOpen(true);
    props.setPoptype && props.setPoptype("addfeature");
  };
  const handleProfilepage = (id, name, row) => {
    const path = window.location.pathname;
    if (path === "/pressrelease") {
      navigate(`/pressrelease/${id}`);
    } else if (path === "/orders") {
      navigate(`/orders/${id}`);
    } else if (path === "/blog") {
      navigate(`/blog/${id}`);
    } else if (path === "/art") {
      navigate(`/art/${id}`);
    }
    // props.setPage('profile')
    // props.setPendingProfileData({ artist_name: name });
    // dispatch(addData(row));
  };
  return (
    <div className={styles.mains}>
      <TableContainer component={Paper} sx={{ backgroundColor: "#F6F6F6", color: "black" }}>
        <Table sx={{ width: "100%" }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  align="left"
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "15px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : ( */}
          <TableBody>
            {props.data?.data && props.data?.data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}> No Data Found</Box>
                </TableCell>
              </TableRow>
            ) : (
              props.data?.data &&
              props.data?.data?.map((row, i) => (
                <TableRow value={row.artist_name} style={{ color: "#636365", cursor: "pointer" }} key={i}>
                  {/* <Link to={`/artist/${row.id}`}> */}
                  {/* <TableCell
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="left"
                  >
               
                  
                      <img src={` ${row.offer_image}`} height="42px" width="60px" />
                    
                  </TableCell> */}
                  <TableCell
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="left">
                    {row.name}
                  </TableCell>
                  <TableCell
                  onClick={() => navigator.clipboard.writeText(row.campaign_code).then(() => {
      Successnotify('Offer code copied to clipboard!');
      // setTimeout(() => setCopyMessage(''), 2000); // Clear message after 2 seconds
    })}
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#636365",
                      cursor: 'pointer', // Change cursor to indicate clickability
        // boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.3)', // Inset box shadow
     
        transition: 'box-shadow 0.3s ease-in-out', // Smooth transition for hover effect
        '&:hover': {
          boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)', // Darker shadow on hover
        },
                    }}
                    className={styles.table_column}
                    align="left">
                    {row.campaign_code}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="left">
                    {row.discount}%
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="left">
                    {row.is_active ? "Active" : "Inactive"}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="left">
                    {moment(row.createdAt).format("DD-MM-YYYY")}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>

        </Table>
      </TableContainer>
      <ToastContainer />
    </div>
  );
}
