import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./CuratorEnquiryDetail.module.css";
import {  Box, Link, Button, Modal} from "@mui/material";

import { IoIosArrowBack } from "react-icons/io";
import { fetchEnquiryByEnquiryId, fetchEnquiryReplyByEnquiryId } from "../../../State/api/superadminApi";
import capitalizeWords from "../../../utils/capitalizeContent";
import dateFormat from "../../../utils/dateFormat";
import dayjs from "dayjs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RiCloseCircleFill } from "react-icons/ri";
import { Errornotify, Successnotify } from "../../../common/alert/Alert";


export default function CuratorEnquiryDetail() {

 //console.log(auctionProfile,'auctionProfile')
 const { id } = useParams();
 const dispatch = useDispatch();
 const navigate = useNavigate()
 
   const [attachment, setAttachment] = useState(null);
   const [reply, setReply] = useState("")
   const enquiryId = useSelector((state) => state.enquirybyenquiryid.data)
 
   const fetchData = () => {
     dispatch(fetchEnquiryByEnquiryId(id))
   }
   useEffect(() => {
     fetchData()
   }, [])
 
   

   const enquiryReplyId = useSelector((state) => state.enquiryreplybyiddata.data)
   
 
   const fetchReplyData = () => {
     dispatch(fetchEnquiryReplyByEnquiryId(id))
   }
   useEffect(() => {
    fetchReplyData()
   }, [])
 
 const handlePages = () => {
   
    navigate("/enquiry")
  }

    const [modalopen, setModalOpen] = useState(false);
  
    const [error, setError] = useState("");
    const [attachmentError, setAttachmentError] = useState("");

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => {
    setModalOpen(false);
    
  };  

  //  const handleSubmit = async (event) => {
  //     event.preventDefault();
  //     console.log("Attachment before validation:", attachment);
    
  //     if (!reply.trim()) {
  //       setError("Reply message is required.");
  //       return;
  //     }
  //     if (!attachment) {
  //       setAttachmentError("Attachment is required.");
  //       return;
  //     }
  //     setError(""); 
  //     setAttachmentError("");
  //     const formData = new FormData();
     
  //     formData.append("enquiryid", id);
  //     formData.append("reply", reply);
  //     formData.append("attachment", attachment ); // Send only the file name
   
  //     formData.append("usertype", localStorage.getItem("usertype"));
    
    
  //     try {
  //       const response = await fetch("http://3.6.185.87:8000/api/artsmileyenquiryreply/addArtsmileyEnquiryReply", {
  //         method: "POST",
  //         body: formData,
  //       });
    
  //       const result = await response.json();
    
  //       if (result.success) {
  //        // alert("Enquiry submitted successfully!");
  //         handleClose();
  //         dispatch(fetchEnquiryReplyByEnquiryId(id))
  //       } else {
  //        // alert("Failed to submit enquiry: " + result.message);
  //       }
  //     } catch (error) {
  //       console.error("Error submitting enquiry:", error);
  //     }
  //   };

const validateReply = (value) => {
  if (!value.trim()) {
    setError("Reply message is required.");
  } else {
    setError("");
  }
};

const validateAttachment = (file) => {
  if (!file) {
    setAttachmentError("Attachment is required.");
  } else {
    setAttachmentError("");
  }
};
const handleReplyChange = (event) => {
  const value = event.target.value;
  setReply(value);
  validateReply(value);
};

const handleAttachmentChange = (event) => {
  const file = event.target.files[0];
  if (!file) return;
      
        const validTypes = ["image/jpeg", "image/png", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
        const maxSize = 10 * 1024 * 1024; // 10MB
        // Check if the file type is valid
        if (!validTypes.includes(file.type)) {
          Errornotify("Please upload a valid image (.jpg, .jpeg, .png) or document (.doc, .docx) file.");
          event.target.value = ""; // Reset input field
          return;
        }
      
        // Check if the file size exceeds the limit
        if (file.size > maxSize) {
          Errornotify("Please upload a file less than 10MB.");
          event.target.value = ""; // Reset input field
          return;
        }
  setAttachment(file);
  validateAttachment(file);
};

    const handleSubmit = async (event) => {
      event.preventDefault();  
      
      validateReply(reply);
      validateAttachment(attachment);
      // if (!reply.trim() || !attachment) {
      //   return; // Stop submission if validation fails
      // }       
      const formData = new FormData();
      formData.append("enquiryid", id);
      formData.append("reply", reply);
      formData.append("attachment", attachment);
      formData.append("usertype", localStorage.getItem("usertype"));
    
      try {
        const response = await fetch("http://3.6.185.87:8000/api/artsmileyenquiryreply/addArtsmileyEnquiryReply", {
          method: "POST",
          body: formData,
        });
    
        const result = await response.json();
    
        if (result.success) {
          Successnotify("Thank you for submitting your reply. We'll review and respond to you as soon as possible.");
          setTimeout(() => {
                    handleClose();
                    dispatch(fetchEnquiryReplyByEnquiryId(id));
                }, 2000);
        } else {
          // Errornotify("Failed to submit enquiry: " + result.message);
        }
      } catch (error) {
        console.error("Error submitting enquiry:", error);
      }
    };
    
    
 return (
  <div className={styles.mains}>
    <Errornotify/>
    <div>
         <div className={styles.main_artistprofile}>
         <Box display="flex" justifyContent="space-between" alignItems="center">
        
    <Link onClick={handlePages} className={styles.backbn}> <IoIosArrowBack /> <span> Go Back</span></Link>
    <Button className="action_btn" variant="contained" onClick={handleOpen} >Add Reply</Button>
     
   
      </Box>
      <hr/>

      <Modal open={modalopen} onClose={handleClose}>
        <Box className={styles.modal_del}>
        <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
                 Add Reply
              </p>
         



          <div className="form-group">
  <label>Enter your reply *</label>
  <textarea
    className="form-control"
    value={reply}
    placeholder="Enter your reply"
    onChange={handleReplyChange}
    rows="4"
    required
  />
  {error && <small style={{ color: "red"}}>{error}</small>}
</div>


        
          <div className="form-group">
            <label>Attachment *</label>
            <input type="file" className="form-control" required
            accept=".jpg,.jpeg,.png,.doc,.docx"
            onChange={handleAttachmentChange}/>
            {attachmentError && <small style={{ color: "red"}}>{attachmentError}</small>}
          </div>
         

          <Box mt={2} display="flex" justifyContent="space-between">
          <button variant="contained" color="primary"  onClick={handleSubmit} className="action_btn">Submit</button>
           
          </Box>
        </Box>
      </Modal>
              </div>
   
         <div className={styles.more_detail}>
         <div className={styles.bdngr}>
            
          <h3 className={styles.sethng}>Subject : {capitalizeWords(enquiryId?.subject)} </h3>
          <p className={styles.odlist}>Message : <span>{capitalizeWords(enquiryId?.message)}</span></p>
          <p className={styles.odlist}>Inquiry Type : <span>{capitalizeWords(enquiryId?.enquirytype)}</span></p>
          <p className={styles.odlist}>Inquiry SubType : <span>{capitalizeWords(enquiryId?.enquirysubtype)}</span></p>
          <p className={styles.odlist}>Attachment : <span>{enquiryId?.attachment === null ? ("----") : enquiryId?.attachment }</span></p>
          <p className={styles.odlist}>Created Date : <span>{dateFormat(enquiryId?.createdAt)}</span></p>
         
         
          
                        </div>
                  </div>

                

     
    
    </div>
    
    <div className={styles.more_detail}>
    <h3 className={styles.sethng}>Inquiry Replies</h3>
    {enquiryReplyId && enquiryReplyId.length > 0 ? (
        enquiryReplyId.map((val) => (
            <div key={val._id}>
                <p className={styles.odlist}>Reply Message : <span>{val.reply}</span></p>
                <p className={styles.odlist}>Created Date : <span>{dayjs(val?.createdAt).format("DD-MM-YYYY").toString()}</span></p>
                <p className={styles.odlist}>File Name : <span>{val.attachment === null ? ("----") : (
                  <a
                  href={val.attachment}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                >
                  {val.attachment}
                </a>
                )}</span></p>
                <hr />
            </div>
        ))
    ) : (
        <div className={styles.no_reply}>No Reply found</div>
    )}
</div>

<ToastContainer />
  </div>
  
 );
}
