import React, { useState } from "react"
import styles from "./EditMenu.module.css"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import { TextField, Link } from "@mui/material"
import serverimage from "../../../../../serverConstant"
import {
  handleAddMenu,
  fetchMenuSubMenu,
  getAllMenuSubMenu,
  editMenudata,
} from "../../../../../State/api/superadminApi"
import { useDispatch, useSelector } from "react-redux"
import CircularLoader from "../../../../../common/loader/Loader"
import { validateAdminMenu } from "../../../../../validation/validationAdminmenu"
import { validateAdminEditMenu } from "../../../../../validation/validationadminEditMenu"
import { RiCloseCircleFill} from "react-icons/ri";
import { FiUpload } from "react-icons/fi";
import { Errornotify } from "../../../../../common/alert/Alert"

export default function EditMenu(props) {
  console.log(props.name, "props.name--menu")
  const [data, setData] = useState(
    props.name
      ? {
          name: props.name && props.name.name,
          link: props.name && props.name.link,
          image: props.name && props.name.image,
        }
      : {
          name: "",
          link: "",
          image: "",
        }
  )
  const [errors, setErrors] = useState({})
  const [editerrors, setEditErrors] = useState({})
  const loader = useSelector((state) => state.loader)
  const [newdata, setNewdata] = useState({ name: "", link: "", image: "" })
  console.log(newdata, "newdata-menu")
  const [src, setSrc] = useState("")
  const [image, setImage] = useState("")
  const handleClose = () => props.setMenuOpen(false)
  const dispatch = useDispatch()

  const handleChange = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }

  const handleEdit = (id) => {
    const { menuEditErrors, isValid } = validateAdminEditMenu(data)

    if (!isValid) {
      setEditErrors(menuEditErrors)
    } else {
      setEditErrors({})
      dispatch(
        editMenudata(
          id,
          data,
          props.setMenuOpen,
          props.fetchMenuData,
          getAllMenuSubMenu
        )
      )
    }
  }

  const handleNewChange = (e) => {
    const { name, value } = e.target
    setNewdata({ ...newdata, [name]: value })
  }

  const handleChangeImage = (e) => {

    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileURL = URL.createObjectURL(e.target.files[0]);
      const MIN_FILE_SIZE = 100; 
      const MAX_FILE_SIZE = 50000; 
      const ALLOWED_TYPES = ["image/jpeg", "image/jpg", "image/png"]; 

      const fileSizeKiloBytes = file.size / 1024;

    
      if (!ALLOWED_TYPES.includes(file.type)) {
        Errornotify("Only .jpg, .jpeg, or .png files are allowed.");
        return; // Exit if file type is invalid
      }

      if (fileSizeKiloBytes < MIN_FILE_SIZE) {
        Errornotify("File size should be greater than 100kb");
      } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        Errornotify("File size should be less than or equal to  50mb");
      } else{
        setImage(file)
        setSrc(fileURL)
        setNewdata((prev) => ({ ...prev, image: file }))
        setData((prev) => ({ ...prev, image: file }))
        
      }
    }
    // if (e.target.files && e.target.files[0]) {
    //   const img = e.target.files[0]
    //   const imgs = URL.createObjectURL(e.target.files[0])
    //   setImage(e.target.files[0])
    //   setSrc(imgs)
    //   setNewdata({ ...newdata, image: img })
    //   setData({ ...data, image: img })
    // }
  }

  const handleSubmit = () => {
    const { menuErrors, isValid } = validateAdminMenu(newdata)

    if (!isValid) {
      setErrors(menuErrors)
    } else {
      setErrors({})
      const formData = new FormData()
      formData.append("name", newdata.name)
      formData.append("link", newdata.link)
      if (newdata.image) formData.append("image", newdata.image)

      dispatch(
        handleAddMenu(
          formData,
          props.setMenuOpen,
          props.fetchMenuData,
          getAllMenuSubMenu
        )
      )
    }
  }

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}

      {props.type === "add" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.menuopen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.menuopen}>
            <Box className={styles.modal_del}>
            <div className={styles.clobtn}>
                <Link onClick={() => props.setMenuOpen(false)}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  Add Menu
              </p>
             

              {/* <div className={styles.textarea}>
                        <textarea className={styles.textareas} placeholder='Write a Remark' rows='10' />
                    </div> */}
              <div className="form-group">
                <label>Images <span className={styles.asterisk}>*</span></label>
                <div className={styles.preview_img}>
                  {/* {newdata === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        onClick={handleChangeImage}
                      >
                        <input
                          onChange={handleChangeImage}
                          type="file"
                          id="input"
                          name="image"
                          hidden
                          accept="image/*"
                        />
                        <label htmlFor="input" className={styles.labelv}>
                    <FiUpload />
                      <p>Upload Your Image</p>
                    </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        onChange={handleChangeImage}
                        type="file"
                        id="input"
                        hidden
                        //  name="image"
                        accept="image/*"
                      />
                      <label htmlFor="input">
                        <img
                          alt="img"
                          src={src}
                          className={
                            newdata.image
                              ? styles.img_view
                              : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                </div>

                <div className={styles.file_div}></div>
                <small>
                  Upload an image of your artwork.Your file must be least
                  1200*1500 px , a JPG or PNG file ,width a color format of
                  rgb,and less than 50MB
                </small>
                <br />
                {errors?.image && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {errors?.image}
                  </span>
                )} */}
                 {src === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        // onChange={handleChangeImage}
                      >
                        <input
                           onChange={handleChangeImage}
                          type="file"
                          id="input"
                          hidden
                          accept="image/*"
                        />
                        <label htmlFor="input" className={styles.labelv}>
                    <FiUpload />
                      <p>Upload Your Menu Image</p>
                    </label>
                        {/* <label htmlFor="input" className={styles.label}>
                          <img
                            src={"/img/uploadimg.png"}
                            width="46px"
                            height="43px"
                            alt="img"
                          />
                          <p> Upload your category image</p>
                        </label> */}
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                         onChange={handleChangeImage}
                        type="file"
                        id="input"
                        hidden
                        accept="image/*"
                      />
                      <label htmlFor="input">
                        <img
                          src={src}
                          alt="img"
                          id="img"
                          className={
                            newdata.image
                              ? styles.img_view
                              : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                </div>
                <div className={styles.file_div}></div>
                <small>
                  Upload an image of your Category.Your file must be least
                  1200*1500 px , a JPG or PNG file ,width a color format of
                  rgb,and less than 50MB
                </small>
                <br />
                {errors?.image && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {errors?.image}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>Main Menu  <span className={styles.asterisk}>*</span></label>
                <input
                className="form-control"
                onChange={handleNewChange}
                value={newdata.name}
                name="name"
                variant="outlined"
                placeholder="Enter Menu"
                fullWidth
                sx={{ mb: 1 }}
              />
              {errors?.name && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {errors?.name}
                </span>
              )}
              </div>
              <div className="form-group">
                <label>Link  <span className={styles.asterisk}>*</span></label>
                <input
                className="form-control"
                onChange={handleNewChange}
                value={newdata.link}
                name="link"
                variant="outlined"
                placeholder="Enter Link"
                fullWidth
                sx={{ mb: 1 }}
              />
              {errors?.link && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {errors?.link}
                </span>
              )}
              </div>
              
              <div className={styles.action}>
                <button
                  variant="contained"
                  onClick={handleSubmit}
                  className="action_btn"
                >
                  {loader.btnloader ? (
                    <CircularLoader color="white" type="small" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.menuopen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.menuopen}>
            <Box className={styles.modal_del}>
            <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  Edit Menu
              </p>
               
              <div className="form-group">
                <label>Images  <span className={styles.asterisk}>*</span></label>
                <div className={styles.preview_img}>
                  {data.image === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        onClick={handleChangeImage}
                      >
                        <input
                          onChange={handleChangeImage}
                          type="file"
                          id="input"
                          hidden
                          accept="image/*"
                        />
                         <label htmlFor="input" className={styles.labelv}>
                    <FiUpload />
                      <p>Upload Your Image</p>
                    </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        onChange={handleChangeImage}
                        type="file"
                        id="input"
                        hidden
                        accept="image/*"
                      />
                      <label htmlFor="input">
                        <img
                          alt="img"
                          src={data.image}
                          // alt="img"
                          className={
                            data.image ? styles.img_view : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                </div>
                <div className={styles.file_div}></div>
                <small>
                  Upload an image of your artwork.Your file must be least
                  1200*1500 px , a JPG or PNG file ,width a color format of
                  rgb,and less than 50MB
                </small>
                <br />
                {errors?.image && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {errors?.image}
                  </span>
                )}
              </div>
                  <div className="form-group">
                  <label>Name  <span className={styles.asterisk}>*</span></label>
              <input
              className="form-control"
                onChange={handleChange}
                name="name"
                value={data.name}
                variant="outlined"
                placeholder="Enter Name"
                fullWidth
                sx={{ mb: 1 }}
              />
              {editerrors?.name && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {editerrors?.name}
                </span>
              )}
                  </div>
                  <div className="form-group">
                  <label>Link  <span className={styles.asterisk}>*</span></label>
              <input
              className="form-control"
                onChange={handleChange}
                name="link"
                value={data.link}
                variant="outlined"
                placeholder="Enter Link"
                fullWidth
                sx={{ mb: 1 }}
              />
              {editerrors?.link && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {editerrors?.link}
                </span>
              )}
                  </div>
              
              
              <div className={styles.action}>
                <button
                  variant="contained"
                  onClick={() => handleEdit(props.name._id)}
                  className="action_btn"
                >
                  Save
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </div>
  )
}
