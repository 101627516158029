import { Button, Fade, Modal, TextField, Link } from '@mui/material';
import styles from './Datetime.module.css';
import React from 'react';
import { Box } from '@mui/system';
import Backdrop from '@mui/material/Backdrop';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import homeImage from '../../../../../constant';
import { RiCloseCircleFill } from 'react-icons/ri';

const datecolor = '#636365'
const icon = <img src={ '/img/Edit.png'} />
export default function Datetime(props) {
    const [value, setValue] = React.useState(dayjs(''));

    const handleChange = (newValue) => {
        setValue(newValue);
    };
    const handleClose = () => props.setOpen(false);
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <Box className={styles.modal_details}>
                    <div className={styles.clobtn}>
                <Link onClick={handleClose}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  {props.text}
              </p>
                         
                        {props.type === 'order_detail' ?

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack>
                                    <label>Date</label>
                                    <DesktopDatePicker
                                        // label="Date desktop"
                                        inputFormat="DD/MM/YYYY"
                                        minDate={new Date()}
                                        value={value}
                                        onChange={handleChange}
                                        components={{
                                            OpenPickerIcon: EventNoteOutlinedIcon
                                            // <img src={ /img/Calander.png'} height='16px' width='15px' />
                                        }}
                                        renderInput={(params) => <TextField className={styles.input_date}
                                        sx={{
                                            "& .MuiOutlinedInput-notchedOutline": {
                                              border: "none",
                                            },
                                            "& .MuiInputBase-input":{
                                              padding:".45rem .75rem",
                                              height:"auto",
                                              borderRadius:".25rem",
                                              fontSize:"16px",
                                              fontWeight:"400"
                                            },
                                            border:"1px solid #ced4da",
                                            borderRadius:".25rem",
                                            width:"100%"
                                          }}    
                                         {...params} />}
                                    />
                                    {/* <label className={styles.input_label}>Date</label> */}

                                    {/* <TimePicker
                                        // label="Time"
                                        // inputFormat="Enter Time"
                                        value={value}
                                        onChange={handleChange}
                                        className={styles.date_picker}
                                        components={{
                                            // OpenPickerIcon: icon
                                        }}
                                        renderInput={(params) => <TextField inputProps={{
                                            ...params.inputProps,
                                            placeholder: "Enter Time"
                                        }}
                                            sx={{
                                                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                                                input: { datecolor },
                                                label: {}, borderRadius: '6px', mt: 0
                                            }} {...params} />}
                                    /> */}
                                </Stack>
                            </LocalizationProvider>
                            :
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack>
                                    <label className={styles.input_label}>Date</label>
                                    <DesktopDatePicker
                                        // label="Date desktop"
                                        inputFormat="DD/MM/YYYY"
                                        value={value}
                                        onChange={handleChange} components={{
                                            OpenPickerIcon: EventNoteOutlinedIcon
                                            // <img src={ /img/Calander.png'} height='16px' width='15px' />
                                        }}
                                        className={styles.date_picker}
                                        renderInput={(params) => <TextField className={styles.input_date}
                                            sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" }, borderRadius: '6px', mt: 0, mb: 2 }} {...params} />}
                                    />
                                    <label className={styles.input_label}>Time</label>

                                    <TimePicker
                                        // label="Time"
                                        // inputFormat="Enter Time"
                                        value={value}
                                        onChange={handleChange}
                                        className={styles.date_picker}
                                        components={{
                                            // OpenPickerIcon: icon
                                        }}
                                        renderInput={(params) => <TextField inputProps={{
                                            ...params.inputProps,
                                            placeholder: "Enter Time"
                                        }}
                                            sx={{
                                                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                                                input: { datecolor },
                                                label: {}, borderRadius: '6px', mt: 0
                                            }} {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        }
                        <div className={styles.action}>
                            <button variant='contained' className="action_btn" >Save</button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}
