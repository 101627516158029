import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
// export function notify(msg, type) {
//   toast.success('🦄 Wow so easy!', {
//     position: "top-right",
//     autoClose: 5000,
//     hideProgressBar: true,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//   });
// };

export function Successnotify(msg) { 
  toast.success(msg, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  
  });
}

export function Warnnotify(msg) {
  toast.warning(msg, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  
  });
}


export function Warnnoti(message, onConfirm) {
  toast.warning(
    <div>
      <p>{message}</p>
      <button 
        onClick={() => {
          onConfirm(); 
          toast.dismiss(); 
        }}
        style={{ marginRight: "10px", padding: "5px", background: "red", color: "white", border: "none", cursor: "pointer" }}
      >
        Yes
      </button>
      <button 
        onClick={() => toast.dismiss()} 
        style={{ padding: "5px", background: "gray", color: "white", border: "none", cursor: "pointer" }}
      >
        No
      </button>
    </div>,
    {
      position: "top-center",
      autoClose: false, 
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      closeButton: false
    }
  );
}



export function Errornotify(msg) {
  toast.error(msg, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  
  });
};

export function AlertsSuccess() {
  return (
    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
 
    />
  );
}
