import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Tooltip,
} from "@mui/material";
// import Divider from '@mui/material/Divider';
import React, { useEffect, useState } from "react";
import styles from "./Sixcolimg.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import homeImage from "../../../../../../constant";
import CircularLoader from "../../../../../../common/loader/Loader";
import serverimage from "../../../../../../serverConstant";
import moment from "moment/moment";
import { FiTrash2, FiEdit3 } from "react-icons/fi";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// import profileSlice, { addData } from '../../../../../State/Features/Artist/profileSlice';

export default function Sixcolimg(props) {
  console.log("props-curator--", props);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ids, setIds] = useState("");
  const [collection_id, setCollection_id] = useState("");

  console.log(ids, "ids--cur");
  const [cids, setCids] = useState("");
  const loader = useSelector((state) => state.loader);
  const threedots = Boolean(anchorEl);
  const handleClick = (event) => {
    const id = event.target.id;
    const value = event.target;
    setAnchorEl(event.currentTarget);
    setIds(event.target.id);
    setCollection_id(event.target.id);
  };
  const handleClickCurator = (event) => {
    const id = event.target.id;
    const value = event.target;
    setAnchorEl(event.currentTarget);
    setCids(event.target.id._id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePopup = (e) => {
    props.setOpen && props.setOpen(true);
    props.setPoptype && props.setPoptype("addfeature");
  };
  const handleProfilepage = (id, curator_coll_id) => {
    const path = window.location.pathname;

    if (path === "/art" && props.activetab === "curated_collection") {
      navigate(`/art/${curator_coll_id}`);
    } else if (path === "/orders") {
      navigate(`/orders/${id}`);
    } else if (path === "/art") {
      navigate(`/art/${id}`);
    }
  };
  const handleProfile = (id) => {
    navigate(`/arts/${id}`);
  };


    const [expandedRows, setExpandedRows] = useState({}); 
    
    const toggleReadMore = (rowIndex) => {
        setExpandedRows((prevState) => ({
          ...prevState,
          [rowIndex]: !prevState[rowIndex], 
        }));
    };



     const [currentPage, setCurrentPage] = React.useState(1);
          const itemsPerPage = 10; // Adjust how many items you want per page
        
          // Function to handle page changes
          const handlePageChange = (event, page) => {
            setCurrentPage(page);
          };
        
          // Slice the data based on current page
          const startIndex = (currentPage - 1) * itemsPerPage;
          const endIndex = startIndex + itemsPerPage;
          const currentItems = props?.data?.slice(startIndex, endIndex);
          useEffect(() => {
                setCurrentPage(1);
              }, [props.activetab]);
    
  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ width: "100%" }} aria-label="customized table" bordered>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  align={column.label === "Action" ? "center" : "left"}
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "15px",
                    textTransform: "capitalize",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                    {console.log("props.data && props.data", props.data && props.data)}
                  </TableCell>
                </TableRow>
              ) : (
                currentItems &&
                (props.activetab === "press" 
                  ? currentItems
                  : ([...currentItems].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
                )
                ?.map((row, index) => (
                  <TableRow
                    value={row.artist_name}
                    style={{ color: "#636365", cursor: "pointer" }}
                    key={index}
                  >
                    {/* <Link to={`/artist/${row.id}`}> */}
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                      onClick={() => handleProfilepage(row._id, row._id._id)}
                    >
                      {/* <img alt='img' src={ `/img/${row.src}`} height='42px' width='60px' /> */}
                      {props.activetab === "hero" ? (
                        <img
                          alt="img"
                          src={` ${row.image}`}
                          height="42px"
                          width="60px"
                        />
                      ) : // ) : props.activetab === "buy" ? (
                      //   <img
                      //     alt="img"
                      //     //  src={` ${row.src ? row.src : row.media}`}
                      //     src={` ${row.items.map(
                      //       (val) => val.image
                      //     )}`}
                      //     height="42px"
                      //     width="60px"
                      //   />
                      // ) : props.activetab === "rent" ? (
                      //   <img
                      //     alt="img"
                      //     //  src={` ${row.src ? row.src : row.media}`}
                      //     src={` ${row.items.map(
                      //       (val) => val.image
                      //     )}`}
                      //     height="42px"
                      //     width="60px"
                      //   />
                      // ) : props.activetab === "print" ? (
                      //   <img
                      //     alt="img"
                      //     //  src={` ${row.src ? row.src : row.media}`}
                      //     src={` ${row.items.map(
                      //       (val) => val.image
                      //     )}`}
                      //     height="42px"
                      //     width="60px"
                      //   />
                      // ) : props.activetab === "curatororder" ? (
                      //   <img
                      //     alt="img"
                      //     src={` ${row.items.map(
                      //       (val) => val.image
                      //     )}`}
                      //     height="42px"
                      //     width="60px"
                      //   />
                      props.activetab === "blogs" ? (
                        <img
                          alt="img"
                          src={` ${row.media}`}
                          height="42px"
                          width="60px"
                        />
                      ) : // this is for curator panel (art)
                      props.activetab === "pending" ? (
                        <img
                          alt="img"
                          src={` ${row.image}`}
                          height="42px"
                          width="60px"
                        />
                      ) : // this is for curator panel (art)
                      props.activetab === "is_best_seller" ? (
                        <img
                          alt="img"
                          src={` ${row.image}`}
                          height="42px"
                          width="60px"
                        />
                      ) : // this is for curator panel (art)
                      props.activetab === "most_popular" ? (
                        <img
                          alt="img"
                          src={` ${row.image}`}
                          height="42px"
                          width="60px"
                        />
                      ) : props.activetab === "curated_collection" ? (
                        <img
                          alt="img"
                          src={` ${row._id.image}`}
                          height="42px"
                          width="60px"
                        />
                      ) : props.activetab === "approved" ? (
                        <img
                          alt="img"
                          src={` ${row.image}`}
                          height="42px"
                          width="60px"
                        />
                      ) : props.activetab === "decline" ? (
                        <img
                          alt="img"
                          src={` ${row.image}`}
                          height="42px"
                          width="60px"
                        />
                      ) : props.activetab === "print" ? (

                         <span className={styles.hover_text}>
                                                 {row._id}
                                                </span>
                      ) : props.activetab === "affiliateart" ? (
                        <img
                          alt="img"
                          src={` ${row.image}`}
                          height="42px"
                          width="60px"
                          onClick={() => handleProfile(row._id)}
                        />
                      ) : props.activetab === "press" ? (
                        <img
                          alt="img"
                          src={
                            row.media ? row.media : "/img/artist_profile.png"
                          }
                          height="42px"
                          width="60px"
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell
                      onClick={
                        props.activetab === "curatororder"
                          ? () => navigate(`/orders/${row._id}`)
                          : undefined
                      }
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        textTransform:"capitalize"
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row?.title}
                      {props.activetab === "curatororder"
                        ? row.order_number
                          ? row.order_number
                          : "---"
                        : ""}
                      {props.activetab === "hero" ? row.name : ""}
                      {props.activetab === "pending" ? row.name : ""}
                      {props.activetab === "is_best_seller"
                        ? row.name
                          ? row.name
                          : "----"
                        : ""}
                      {props.activetab === "most_popular"
                        ? row.name
                          ? row.name
                          : "----"
                        : ""}
                      {props.activetab === "approved" ? row.name : ""}
                      {props.activetab === "decline" ? row.name : ""}
                      {/* {row._id} */}
                      {/* curator panel (arts) */}{" "}
                      {props.activetab === "curated_collection"
                        ? row._id.name
                        : ""}
                      {props.activetab === "print"
                        ? row.items.map((val, i) => val.createdAt)
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        textTransform:"capitalize"
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.date ? row.date.split("-").reverse().join("-") : ""}
                      {row.sub_title}
                      {props.activetab === "curatororder"
                        ? row.buyer.map(
                            (val) => val.first_name + " " + val?.last_name
                          )
                        : ""}
                      {props.activetab === "buy" ? row.order_date : ""}
                      {props.activetab === "rent" ? row.order_date : ""}
                      {props.activetab === "print"
                        ? row.items.map((val, i) => val.quantity)
                        : ""}
                      {props.activetab === "affiliateart"
                        ? row.artist_id.first_name +
                          "" +
                          row.artist_id.last_name
                        : ""}
                      {/* this is for curator panel (arts) */}{" "}
                      {props.activetab === "approved" ? row.artist_name : ""}
                      {props.activetab === "is_best_seller"
                        ? row.artist_name
                          ? row.artist_name
                          : "----"
                        : ""}
                      {props.activetab === "most_popular"
                        ? row.artist_name
                          ? row.artist_name
                          : "----"
                        : ""}
                      {props.activetab === "decline" ? row.artist_name : ""}
                      {props.activetab === "pending" ? row.artist_name : ""}
                      {props.activetab === "hero" ? row.artist_name : ""}
                      {props.activetab === "curated_collection"
                        ? row._id.artist_id.first_name +
                          " " +
                          row._id.artist_id.last_name
                        : ""}
                    </TableCell>
                    {/* {props.activetab === "curatororder" && (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {props.activetab === "curatororder"
                          ? row.buyer.map(
                              (val) => val.first_name + " " + val?.last_name
                            )
                          : ""}
                      </TableCell>
                    )} */}
                    {props.activetab === "hero" ? (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.button_name}
                        {/* {"$" + row?.sale?.price} */}
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.assign_by}
                        {row.button_name}
                        {/* curator panel (arts) */}{" "}
                        {/* {props.activetab === "pending"
                        ? row.artist_type
                        : props.activetab === "curated_collection"
                          ? "$" + row._id?.sale?.price
                          : "$" + row.sale?.price} */}
                        {props.activetab === "approved"
                          ? row.sale?.price
                            ? "$" + row.sale?.price
                            : "---"
                          : ""}
                        {props.activetab === "is_best_seller"
                          ? row.sale?.price
                            ? "$" + row.sale?.price
                            : "---"
                          : ""}
                        {props.activetab === "most_popular"
                          ? row.sale?.price
                            ? "$" + row.sale?.price
                            : "---"
                          : ""}
                        {props.activetab === "decline"
                          ? row.sale?.price
                            ? "$" + row.sale?.price
                            : "---"
                          : ""}
                        {props.activetab === "pending"
                          ? row?.category?.map((artist, i) => artist?.name)
                          : ""}
                        {props.activetab === "buy" ? row.noOfItems : ""}
                        {props.activetab === "rent" ? row.noOfItems : ""}
                        {props.activetab === "print"
                          ? row.items.map((val, i) => val.item.order_type.name)
                          : ""}
                        {props.activetab === "curated_collection"
                          ? "$" + row._id.sale.price
                          : ""}
                        {props.activetab === "curatororder"
                          ? row.noOfItems
                            ? row.noOfItems
                            : ""
                          : ""}
                      </TableCell>
                    )}
                    {props.activetab === "hero" ? (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.position}
                        {/* {"$" + row?.rent?.rent_price} */}
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.blog_type}
                        {row.press_type}
                        {row.position}
                        {row?.items?.length}
                        {/* {row.item_type} because of curator apnel >> order section */}
                        {/* for curator panel order section */}
                        {props.activetab === "buy" ? row.items?.length : ""}
                        {props.activetab === "rent" ? row.items?.length : ""}
                        {props.activetab === "print"
                          ? row.items.map((val, i) => val.item.sum_amount)
                          : ""}
                        {/* curator panel (arts) */}{" "}
                        {props.activetab === "pending"
                          ? row.sale?.price
                            ? "$" + row.sale?.price
                            : "---"
                          : ""}
                        {props.activetab === "approved"
                          ? row.rent?.rent_price
                            ? "$" + row.rent?.rent_price
                            : "---"
                          : ""}
                        {props.activetab === "most_popular"
                          ? row.rent?.rent_price
                            ? "$" + row.rent?.rent_price
                            : "---"
                          : ""}
                        {props.activetab === "is_best_seller"
                          ? row.rent?.rent_price
                            ? "$" + row.rent?.rent_price
                            : "---"
                          : ""}
                        {props.activetab === "decline"
                          ? row.rent?.rent_price
                            ? "$" + row.rent?.rent_price
                            : "---"
                          : ""}
                        {props.activetab === "curated_collection"
                          ? row._id?.rent?.rent_price
                            ? "$" + row._id?.rent?.rent_price
                            : "----"
                          : ""}
                      </TableCell>
                    )}
                    {props.activetab === "hero" && (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {dayjs(row.createdAt).format("DD-MM-YYYY").toString()}
                      </TableCell>
                    )}

                    {props.activetab === "approved" ? (
                      <>
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                            height: "auto",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <span>
                            {row.link}
                            {/* this is curator panel  */}{" "}
                            {row?.print?.[0]?.print_price
                              ? "$" + row.print[0].print_price
                              : "---"}
                          </span>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                            height: "auto",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <span>
                            {row.createdAt &&
                              moment.utc(row.createdAt).format("DD-MM-YYYY")}
                          </span>
                          <Tooltip title="Account settings">
                            <IconButton
                              onClick={handleClick}
                              size="small"
                              id={row._id}
                              name={row._id}
                              sx={{ ml: 2 }}
                              aria-controls={
                                threedots ? "account-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={threedots ? "true" : undefined}
                            >
                              <img
                                alt="img"
                                src={"/img/Menu Icon.png"}
                                id={row._id}
                                height="21.62px"
                                width="4.63px"
                              />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={threedots}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                width: "200px",
                                overflow: "visible",
                                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                filter:
                                  "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                mt: 2.5,
                                "& .MuiAvatar-root": {
                                  width: "120px",
                                  height: "132px",
                                  ml: 0.5,
                                  mr: 1,
                                  // mt:5
                                },
                                "&:before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  // horizontal: 'center',
                                  right: 85,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "center",
                              vertical: "right",
                            }}
                            anchorOrigin={{
                              horizontal: "left",
                              vertical: "center",
                            }}
                          >
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                              }}
                              value="addfeature"
                              onClick={() => props.handleCuratorCollection(ids)}
                            >
                              Add to Curator Collection
                            </MenuItem>

                            <Divider />
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                              }}
                              onClick={() => props.handleDelete(ids)}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </>
                    ) : props.activetab === "is_best_seller" ? (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <div className={styles.career_lastcol}>
                          <span>
                            {/* this is curator panel  */}{" "}
                            {row?.print?.map((print, i) =>
                              print?.print_price
                                ? "$" + print?.print_price
                                : "---"
                            )}
                          </span>
                          <Tooltip title="Account settings">
                            <IconButton
                              onClick={handleClick}
                              size="small"
                              id={row._id}
                              name={row._id}
                              sx={{ ml: 2 }}
                              aria-controls={
                                threedots ? "account-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={threedots ? "true" : undefined}
                            >
                              <img
                                alt="img"
                                src={"/img/Menu Icon.png"}
                                id={row._id}
                                height="21.62px"
                                width="4.63px"
                              />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={threedots}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                width: "200px",
                                overflow: "visible",
                                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                filter:
                                  "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                mt: 2.5,
                                "& .MuiAvatar-root": {
                                  width: "120px",
                                  height: "132px",
                                  ml: 0.5,
                                  mr: 1,
                                  // mt:5
                                },
                                "&:before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  // horizontal: 'center',
                                  right: 85,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "center",
                              vertical: "right",
                            }}
                            anchorOrigin={{
                              horizontal: "left",
                              vertical: "center",
                            }}
                          >
                            <MenuItem
                              onClick={() => props.handleBestSeller(ids, false)}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                              }}
                            >
                              Remove from here
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={() => props.handleMostPopular(ids, true)}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                              }}
                            >
                              Most Popular
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                              }}
                              onClick={() => props.handleDelete(ids)}
                            >
                              Delete
                            </MenuItem>
                            <Divider />
                          </Menu>
                        </div>
                      </TableCell>
                    ) : props.activetab === "most_popular" ? (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <div className={styles.career_lastcol}>
                          <span>
                            {/* this is curator panel  */}{" "}
                            {row?.print?.map((print, i) =>
                              print?.print_price
                                ? "$" + print?.print_price
                                : "---"
                            )}
                          </span>
                          <Tooltip title="Account settings">
                            <IconButton
                              onClick={handleClick}
                              size="small"
                              id={row._id}
                              name={row._id}
                              sx={{ ml: 2 }}
                              aria-controls={
                                threedots ? "account-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={threedots ? "true" : undefined}
                            >
                              <img
                                alt="img"
                                src={"/img/Menu Icon.png"}
                                id={row._id}
                                height="21.62px"
                                width="4.63px"
                              />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={threedots}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                width: "200px",
                                overflow: "visible",
                                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                filter:
                                  "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                mt: 2.5,
                                "& .MuiAvatar-root": {
                                  width: "120px",
                                  height: "132px",
                                  ml: 0.5,
                                  mr: 1,
                                  // mt:5
                                },
                                "&:before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,

                                  right: 85,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "center",
                              vertical: "right",
                            }}
                            anchorOrigin={{
                              horizontal: "left",
                              vertical: "center",
                            }}
                          >
                            <MenuItem
                              onClick={() =>
                                props.handleMostPopular(ids, false)
                              }
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                              }}
                            >
                              Remove from here
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={() => props.handleBestSeller(ids, true)}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                              }}
                            >
                              Best Seller
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                              }}
                              onClick={() => props.handleDelete(ids)}
                            >
                              Delete
                            </MenuItem>
                            <Divider />
                          </Menu>
                        </div>
                      </TableCell>
                    ) : props.activetab === "decline" ? (
                      <>
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <span>
                            {row.link}
                            {/* this is curator panel  */}{" "}
                            {row?.print?.length > 0
                              ? row.print[0]?.print_price
                                ? `$${row.print[0].print_price}`
                                : "---"
                              : "---"}
                          </span>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                            height: "auto",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <span>
                            {row.createdAt &&
                              moment.utc(row.createdAt).format("DD-MM-YYYY")}
                          </span>
                        </TableCell>
                      </>
                    ) : props.activetab === "curated_collection" ? (
                      <>
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <div className={styles.career_lastcol}>
                            <span>
                              {row._id?.link}{" "}
                              {row._id?.print && row._id.print.length > 0
                                ? row._id.print[0]?.print_price
                                  ? "$" + row._id.print[0]?.print_price
                                  : "NA"
                                : "----"}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                            height: "auto",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <span>
                            {row._id.createdAt &&
                              moment
                                .utc(row._id.createdAt)
                                .format("DD-MM-YYYY")}
                          </span>

                          <Tooltip title="Account settings">
                            <IconButton
                              onClick={handleClick}
                              id={row._id._id}
                              size="small"
                              sx={{ ml: 2 }}
                              aria-controls={threedots ? "account-menu" : ""}
                              aria-haspopup="true"
                              aria-expanded={threedots ? "true" : ""}
                            >
                              <img
                                alt="img"
                                src={"/img/Menu Icon.png"}
                                id={row._id._id}
                                height="21.62px"
                                width="4.63px"
                              />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={threedots}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                overflow: "visible",
                                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                filter:
                                  "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                mt: 2.5,
                                "& .MuiAvatar-root": {
                                  width: "130px",
                                  height: "132px",
                                  ml: 0.5,
                                  mr: 1,
                                  // mt:5
                                },
                                "&:before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  // horizontal: 'center',
                                  right: 62,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "center",
                              vertical: "right",
                            }}
                            anchorOrigin={{
                              horizontal: "left",
                              vertical: "center",
                            }}
                          >
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                              }}
                              onClick={() =>
                                props.handleDeleteCuratorcollection(
                                  collection_id
                                )
                              }
                            >
                              Remove Curated Collection
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </>
                    ) : props.activetab === "blogs" ? (
                      <TableCell
                        sx={{
                          width: "40%",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.column}
                        align="center"
                      >
                        <div className={styles.career_lastcol}>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                row &&
                                row.description &&
                                row.description.length > 100
                                  ? row.description.substring(0, 100) + "..."
                                  : row && row.description
                                  ? row.description
                                  : "",
                            }}
                          ></p>

                          {/* <div className={styles.action}>
                            <IconButton
                              onClick={() => props.handleEditPopup(row)}
                            >
                              <FiEdit3 className={styles.editicon} />
                            </IconButton>
                            <IconButton
                              onClick={() => props.handleDelete(row._id)}
                            >
                              <FiTrash2 className={styles.deleicon} />
                            </IconButton>
                          </div> */}
                        </div>
                      </TableCell>
                    ) : props.activetab === "press" ? (
                      <TableCell
                        sx={{
                          // width: "40%",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.column}
                        align="left"
                      >
                         <div className={styles.auction_last_col}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: expandedRows[index]
                              ? row?.description
                              : row?.description?.length > 100
                              ? `${row?.description?.substring(0, 100)}...`
                              : row?.description,
                          }}
                        ></span>
                        {row?.description?.length > 100 && (
                          <a
                          onClick={() => toggleReadMore(index)}
                            className={styles.read_more_button}
                          >
                            {expandedRows[index] ? "Read Less" : "Read More"}
                          </a>
                        )}
                      </div>
                        {/* <div className={styles.career_lastcol}>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                row &&
                                row.description &&
                                row.description.length > 100
                                  ? row.description.substring(0, 100) + "..."
                                  : row && row.description
                                  ? row.description
                                  : "",
                            }}
                          ></p> */}
                          {/* <div className={styles.action}>
                            <IconButton
                              onClick={() => props.handleEditpopup(row)}
                            >
                              <FiEdit3 className={styles.editicon} />
                            </IconButton>
                            <IconButton
                              onClick={() => props.handleDelete(row._id)}
                            >
                              <FiTrash2 className={styles.deleicon} />
                            </IconButton>
                          </div> */}
                        {/* </div> */}
                      </TableCell>
                    ) : props.activetab === "gallery_artist_pending" ? (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#35BE43",
                        }}
                        className={styles.column_gallery_artist}
                        align="left"
                      >
                        {row.status ? (
                          <div className={styles.column_gallery_artist_true}>
                            Pending
                            <Tooltip title="Account settings">
                              <IconButton
                                onClick={handleClick}
                                id={row._id}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={
                                  threedots ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={threedots ? "true" : undefined}
                              >
                                <img
                                  alt="img"
                                  src={"/img/Menu Icon.png"}
                                  id={row._id}
                                  height="21.62px"
                                  width="4.63px"
                                />
                              </IconButton>
                            </Tooltip>
                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={threedots}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  width: "150px",
                                  overflow: "visible",
                                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                  filter:
                                    "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                  mt: 2.5,
                                  "& .MuiAvatar-root": {
                                    width: "120px",
                                    height: "132px",
                                    ml: 0.5,
                                    mr: 1,
                                    // mt:5
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    // horizontal: 'center',
                                    right: 62,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "center",
                                vertical: "right",
                              }}
                              anchorOrigin={{
                                horizontal: "left",
                                vertical: "center",
                              }}
                            >
                              <MenuItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                                value="addfeature"
                                onClick={handlePopup}
                              >
                                Add Featured Artist
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Reconmmended
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Block
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Unblock
                              </MenuItem>
                            </Menu>
                          </div>
                        ) : (
                          <div className={styles.column_gallery_artist}>
                            Rejected
                            <Tooltip title="Account settings">
                              <IconButton
                                onClick={handleClick}
                                id={row._id}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={
                                  threedots ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={threedots ? "true" : undefined}
                              >
                                <img
                                  alt="img"
                                  src={"/img/Menu Icon.png"}
                                  id={row._id}
                                  height="21.62px"
                                  width="4.63px"
                                />
                              </IconButton>
                            </Tooltip>
                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={threedots}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  width: "150px",
                                  overflow: "visible",
                                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                  filter:
                                    "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                  mt: 2.5,
                                  "& .MuiAvatar-root": {
                                    width: "120px",
                                    height: "132px",
                                    ml: 0.5,
                                    mr: 1,
                                    // mt:5
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    // horizontal: 'center',
                                    right: 62,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "center",
                                vertical: "right",
                              }}
                              anchorOrigin={{
                                horizontal: "left",
                                vertical: "center",
                              }}
                            >
                              <MenuItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                                value="addfeature"
                                onClick={handlePopup}
                              >
                                Add Featured Artist
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Reconmmended
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Block
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Unblock
                              </MenuItem>
                            </Menu>
                          </div>
                        )}
                      </TableCell>
                    ) : props.activetab === "quoterequest" ? (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#35BE43",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <div className={styles.quoterequest}>
                          {row.action && row.action === "accept" ? (
                            <span> Pending</span>
                          ) : row.action === "rejected" ? (
                            <span className={styles.rejected}> Rejected</span>
                          ) : (
                            <>
                              <Button
                                onClick={() => props.setOpen(true)}
                                variant="contained"
                                sx={{
                                  width: "80px",
                                  height: "34px",
                                  borderRadius: "4px",
                                }}
                              >
                                Accept
                              </Button>
                              <Button
                                onClick={() => props.setOpen(true)}
                                variant="outlined"
                                sx={{
                                  width: "80px",
                                  height: "34px",
                                  borderRadius: "4px",
                                }}
                              >
                                Decline
                              </Button>
                            </>
                          )}
                        </div>
                      </TableCell>
                    ) : props.activetab === "curatororder" ? (
                      <TableCell
                        sx={{
                          width: "25%",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.order_type}
                        <span>${row.price}</span>
                      </TableCell>
                    ) : props.activetab === "hero" ? (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <div className={styles.career_lastcol}>
                          {row?.print?.map((pr, i) => "$" + pr.print_price)}
                          <span>{row.link}</span>
                          <div className={styles.action}>
                            <IconButton
                              onClick={() => props.handleEditPopup(row)}
                            >
                              <FiEdit3 className={styles.editicon} />
                            </IconButton>
                            <IconButton
                              onClick={() => props.handleDelete(row._id)}
                            >
                              <FiTrash2 className={styles.deleicon} />
                            </IconButton>
                          </div>

                          {/* <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={threedots}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    width: "200px",
                                    overflow: "visible",
                                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                    filter:
                                      "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                    mt: 2.5,
                                    "& .MuiAvatar-root": {
                                      width: "120px",
                                      height: "132px",
                                      ml: 0.5,
                                      mr: 1,
                                      // mt:5
                                    },
                                    "&:before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      top: 0,
                                      // horizontal: 'center',
                                      right: 85,
                                      width: 10,
                                      height: 10,
                                      bgcolor: "background.paper",
                                      transform: "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "center",
                                  vertical: "right",
                                }}
                                anchorOrigin={{
                                  horizontal: "left",
                                  vertical: "center",
                                }}
                              >
                                <MenuItem
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    lineHeight: "12px",
                                    fontWeight: "400",
                                    fontFamily: "Roboto",
                                  }}
                                  value="addfeature"
                                  onClick={() => props.handleCuratorCollection(ids)}
                                >
                                  Add to Curator Collection
                                </MenuItem>
                                <Divider />
                                <MenuItem
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    lineHeight: "12px",
                                    fontWeight: "400",
                                    fontFamily: "Roboto",
                                  }}
                                  onClick={() => props.handleDelete(ids)}
                                >
                                  Delete
                                </MenuItem>
                                <Divider />
                              </Menu> */}
                        </div>
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.action}
                        {row.price}
                        {/* curator panel (arts) */}
                        {props.activetab === "pending"
                          ? row.rent?.rent_price
                            ? "$" + row.rent?.rent_price
                            : "---"
                          : ""}
                      </TableCell>
                    )}
                    {props.activetab === "buy" ? (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.order_status === "Cancelled" ? (
                          <span className={styles.cancel}>Cancelled</span>
                        ) : (
                          <label className={styles.pending}>Pending</label>
                        )}
                        <Tooltip title="Account settings">
                          <IconButton
                            onClick={handleClick}
                            size="small"
                            id={row._id}
                            sx={{ ml: 2 }}
                            aria-controls={
                              threedots ? "account-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={threedots ? "true" : undefined}
                          >
                            <img
                              alt="img"
                              src={"/img/Menu Icon.png"}
                              id={row._id}
                              height="21.62px"
                              width="4.63px"
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    ) : props.activetab === "curatororder" ? (
                      <TableCell
                        sx={{
                          width: "25%",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <div className={styles.curator_order}>
                          <span style={{ marginRight: "10px" }}>
                            ${row.price}
                          </span>
                          <Button
                            variant="contained"
                            sx={{ width: "106px", height: "34px" }}
                          >
                            Track Order
                          </Button>
                        </div>
                      </TableCell>
                    ) : props.activetab === "pending" ? (
                      <>
                        <TableCell
                          sx={{
                            width: "40%",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                            whiteSpace: "nowrap",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <div className={styles.curator_order}>
                            <span style={{ width: "60px" }}>
                              {row.status}
                              {props.activetab === "pending" &&
                                (row?.print?.length > 0
                                  ? row.print[0]?.print_price
                                    ? `$${row.print[0]?.print_price}`
                                    : "---"
                                  : "---")}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                            height: "auto",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <span>
                            {row.createdAt &&
                              moment.utc(row.createdAt).format("DD-MM-YYYY")}
                          </span>
                        </TableCell>
                      </>
                    ) : props.activetab === "blogs" ? (
                      <>
                        <TableCell
                          sx={{
                            // width: "40%",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.column}
                          align="center"
                        >
                          <div className={styles.action}>
                            <IconButton
                              onClick={() => props.handleEditPopup(row)}
                            >
                              <FiEdit3 className={styles.editicon} />
                            </IconButton>
                            <IconButton
                              onClick={() => props.handleDelete(row._id)}
                            >
                              <FiTrash2 className={styles.deleicon} />
                            </IconButton>
                          </div>
                        </TableCell>
                      </>
                    ):''}
                    {props.activetab === "pending" && (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                          height: "auto",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <Button
                          onClick={() =>
                            props.handleAcceptRejectArts(row._id, "Approved")
                          }
                          variant="contained"
                          sx={{ mr: 2, width: "80px", height: "34px" }}
                        >
                          Accept
                        </Button>
                        <Button
                          onClick={() => props.handleReject(row._id)}
                          variant="outlined"
                          sx={{ width: "80px", height: "34px" }}
                        >
                          Decline
                        </Button>
                      </TableCell>
                    )}
                    {
                       props.activetab === "press" ? (
                        <TableCell
                          sx={{
                            // width: "25%",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                            whiteSpace: "nowrap",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                            <div className={styles.action}>
                            <IconButton
                              onClick={() => props.handleEditpopup(row)}
                            >
                              <FiEdit3 className={styles.editicon} />
                            </IconButton>
                            <IconButton
                              onClick={() => props.handleDelete(row._id)}
                            >
                              <FiTrash2 className={styles.deleicon} />
                            </IconButton>
                          </div>
                        </TableCell>):''
                    }
                    

                    {/* </Link> */}
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <> 
      <Box className={styles.pagination_box} sx={{}}>
          <Pagination   
          count={Math.ceil(
            (props?.activetab === "pending" ? props?.datalength : props?.data?.length) / itemsPerPage
          )}
          // count={Math.ceil(props?.data?.length / itemsPerPage)} // Total pages
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        renderItem={(item) => (
          <PaginationItem
          sx={{
            "&.Mui-selected": {
              backgroundColor: "black", // Set the selected background to black
              color: "white", // Ensure the text color is visible
              "&:hover": {
                backgroundColor: "black", // Maintain black on hover
              },
            },
          }}
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )} />
          </Box>
          
      </>
    </div>
  );
}
