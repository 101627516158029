import * as React from "react"
import { useState, useEffect } from "react"
import styles from "./Sidebar.module.css"
import { useLocation, useNavigate } from "react-router-dom"
import Commonsidebar from "../List/Common/sideBar/Commonsidebar"

const sidebarRoutes = [
  { name: "Art", icon: "fa fa-dashboard", path: "/art", hasSubmenu: false },
  { name: "artist", icon: "fa fa-truck", path: "/artist", hasSubmenu: false },
  {
    name: "curators",
    icon: "fa fa-users",
    path: "/curators",
    hasSubmenu: false,
  },
  {
    name: "buyer",
    icon: "fa fa-th-large",
    path: "/buyerlist",
    hasSubmenu: false,
  },
  { name: "orders", icon: "fa fa-cube", path: "/orders", hasSubmenu: false },
  {
    name: "exhibitions",
    icon: "fa fa-cube",
    path: "/exhibitions",
    hasSubmenu: false,
  },
  { name: "auction", icon: "fa fa-cube", path: "/auction", hasSubmenu: false },
  {
    name: "sub admin",
    icon: "fa fa-exchange",
    path: "/subadmin",
    hasSubmenu: false,
  },
  // {
  //   name: "CHAT",
  //   icon: "fa fa-exchange",
  //   path: "/livechat",
  //   hasSubmenu: false,
  // },
  {
    name: "affiliate",
    icon: "fa fa-file-invoice",
    path: "/affiliate",
    hasSubmenu: false,
  },
  {
    name: "notification",
    icon: "fa fa-home",
    path: "/notification",
    hasSubmenu: false,
  },
  { name: "inquiry", icon: "fa fa-gear", path: "/inquiry", hasSubmenu: false },
  {
    name: "offers",
    icon: "fa-solid fa-comments",
    path: "/offers",
    hasSubmenu: false,
  },
  {
    name: "buyer's offers",
    icon: "fa-solid fa-comments",
    path: "/buyeroffer",
    hasSubmenu: false,
  },
  { name: "recreate Request", path: "/recreate", hasSubmenu: false },
  {
    name: "quote request",
    icon: "fa-solid fa-comments",
    path: "/quoterequest",
    hasSubmenu: false,
  },
  {
    name: "testimonials",
    icon: "fa fa-home",
    path: "/testimonials",
    hasSubmenu: false,
  },
  { name: "gallery", 
    icon: "fa fa-gear",
     path: "/gallery", 
    hasSubmenu: false },
  {
    name: "menu",
    icon: "fa-solid fa-comments",
    path: "/menu",
    hasSubmenu: false,
  },
  {
    name: "campaigns",
    icon: "fa fa-home",
    path: "/campaigns",
    hasSubmenu: false,
  },
  { name: "career", icon: "fa fa-gear", path: "/career", hasSubmenu: false },
  {
    name: "subscription",
    icon: "fa-solid fa-comments",
    path: "/subscription",
    hasSubmenu: false,
  },
  {
    name: "press release",
    icon: "fa fa-gear",
    path: "/pressrelease",
    hasSubmenu: false,
  },
  {
    name: "blog",
    icon: "fa-solid fa-comments",
    path: "/blog",
    hasSubmenu: false,
  },
  {
    name: "gift card",
    icon: "fa-solid fa-comments",
    path: "/giftcard",
    hasSubmenu: false,
  },
  { name: "activity Page", path: "/activity", hasSubmenu: false },
  { name: "hero section", path: "/herosection", hasSubmenu: false },
  { name: "category", path: "/category", hasSubmenu: false },
  { name: "print", path: "/print", hasSubmenu: false },
  { name: "print pricing", path: "/print-pricing", hasSubmenu: false },
  { name: "reports", path: "/reports", hasSubmenu: false },
  { name: "policies", path: "/policies", hasSubmenu: false },
  { name: "all account", path: "/allaccount", hasSubmenu: false },
  { name: "faq", path: "/faq", hasSubmenu: false },

  { name: "dispute", path: "/dispute", hasSubmenu: false },
  { name: "rating and review", path: "/ratingreview", hasSubmenu: false },
  // { name: "Art Consultancy", path: "/artconsultancy", hasSubmenu: false },
  {
    name: "Submission Forms",
    path: "/submissionforms",
    hasSubmenu: true,  
    submenu: [
      { name: "Art for interior", path: "/submissionforms/art-for-interior-designers" },
      { name: "Art for business", path: "/submissionforms/art-smiley-for-business" },
      { name: "questionnaire", path: "/submissionforms/questionnaire" }
    ]
  },
  { name: "settings", path: "/settings", hasSubmenu: false },
  { name: "frame country", path: "/framecountry", hasSubmenu: false },
  { name: "contact-us", path: "/contactus", hasSubmenu: false },
]

export default function Sidebar() {
  const location = useLocation()
  const navigate = useNavigate()
  const path = location.pathname

  const [filteredRoutes, setFilteredRoutes] = useState(sidebarRoutes)

  useEffect(() => {
    // Fetch the admin role and is_subadmin status from localStorage
    const adminRole = localStorage.getItem("admin_role")
    const isSubAdmin = localStorage.getItem("is_subadmin") // Convert to boolean

    if (isSubAdmin && adminRole) {
      // Parse the adminRole string into an array of route names
      const allowedRoles = adminRole.split(",").map((role) => role.trim())

      console.log("allowedRoles",allowedRoles)
      // Filter the sidebar routes based on allowed roles
      const allowedRoutes = sidebarRoutes.filter((route) =>
        allowedRoles.includes(route.name)
      )
      console.log("allowedRoutes",allowedRoutes)

      setFilteredRoutes(allowedRoutes)
    } else {
      // If not subadmin, show all routes
      setFilteredRoutes(sidebarRoutes)
    }
  }, [])

  const handleClick = (val) => {
    navigate(val)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }



  return (
    <>
      <Commonsidebar sidebarRoutes={filteredRoutes} />
    </>
  )
}
