import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  price: [0, 50000],
  height: [0, 1500],
  width: [0, 1500],
  type: [],
  category: '',
  style: [],
  artist_type: [],
  artist: [],
  country: [],
  orientation: [],
  color: [],
  search: "",
  allFilterValues: []
};

const filterationSlice = createSlice({
  name: "filteration",
  initialState,
  reducers: {
    setNoFilter(state, action) {
      state = initialState;
    },
    setStyleFilter(state, action) {
      if (action.payload.length === 0) {
        state.style = action.payload
      }
      else {
        const index = state.style.findIndex((item) => item === action.payload);
        if (index === -1) {
          state.style.push(action.payload);
        } else {
          state.style.splice(index, 1);
        }
      }
    },
    setTypeFilter(state, action) {
      const index = state.type.findIndex((item) => item === action.payload);
      if (index === -1) {
        state.type.push(action.payload);
      } else {
        state.type.splice(index, 1);
      }
    },
    setCategoryFilter(state, action) {
      state.category = action.payload
      //  const index = state.category.findIndex((item) => item === action.payload);
      //  if (index === -1) {
      //   state.category.push(action.payload);
      //  } else {
      //   state.category.splice(index, 1);
      //  }
    },
    setArtistTypeFilter(state, action) {
      const index = state.artist_type.findIndex((item) => item === action.payload);
      if (index === -1) {
        state.artist_type.push(action.payload);
      } else {
        state.artist_type.splice(index, 1);
      }
    },
    setArtistFilter(state, action) {
      const index = state.artist.findIndex((item) => item === action.payload);
      console.log(action.payload)
      if (index === -1) {
        state.artist.push(action.payload);
      } else {
        state.artist.splice(index, 1);
      }
    },
    setCountryFilter(state, action) {
      const index = state.country.findIndex((item) => item === action.payload);
      if (index === -1) {
        state.country.push(action.payload);
      } else {
        state.country.splice(index, 1);
      }
    },
    setOrientationFilter(state, action) {
      const index = state.orientation.findIndex((item) => item === action.payload);
      if (index === -1) {
        state.orientation.push(action.payload);
      } else {
        state.orientation.splice(index, 1);
      }
    },
    setColorFilter(state, action) {
      const index = state.color.findIndex((item) => item === action.payload);
      if (index === -1) {
        state.color.push(action.payload);
      } else {
        state.color.splice(index, 1);
      }
    },
    setSearchFilter(state, action) {
      state.search = action.payload;
      state.price = state.price;
      state.height = [0, 50000];
      state.width = [0, 50000];
      state.type = state.type;
      state.category = state.category;
      state.style = state.style;
      state.artist_type = state.artist_type;
      state.artist = state.artist;
      state.country = state.country;
      state.orientation = state.orientation;
      state.color = state.color;
    },

    // LEFT  NAV BUTTONS

    setStyleFilterNav(state, action) {
      state.style = [action.payload];
      state.price = [0, 50000];
      state.height = [0, 50000];
      state.width = [0, 50000];
      state.type = [];
      state.category = [];
      state.artist_type = [];
      state.artist = [];
      state.country = [];
      state.orientation = [];
      state.color = [];
      state.search = "";
      //    state = {state.style :action.payload}
    },

    // RIGHT BUTTONS

    setArtistFilterNav(state, action) {
      state.country = [action.payload];
      state.price = [0, 50000];
      state.height = [0, 50000];
      state.width = [0, 50000];
      state.type = [];
      state.category = [];
      state.style = [];
      state.artist_type = [];
      state.artist = [];
      state.orientation = [];
      state.color = [];
      state.search = "";
    },

    // IN HOME PAGE FILTER BY PRICE

    setFilterByPriceNav(state, action) {
      state.price = action.payload;
      state.height = [0, 50000];
      state.width = [0, 50000];
      state.type = state.type;
      state.category = state.category;
      state.style = state.style;
      state.artist_type = state.artist_type;
      state.artist = state.artist;
      state.country = state.country;
      state.orientation = state.orientation;
      state.color = state.color;
      state.search = state.search;
      // state = { ...state, price: action.payload }
      // console.log(action.payload, state.price)
    },
    setFilterByPrice(state, action) {
      state.price = [0, action.payload];
      state.height = [0, 50000];
      state.width = [0, 50000];
      state.type = [];
      state.category = [];
      state.style = [];
      state.artist_type = [];
      state.artist = [];
      state.country = [];
      state.orientation = [];
      state.color = [];
      state.search = "";
    },
    setAllFilterValues: (state, action) => {
      state.allFilterValues = action.payload           
    }
  },
});

export const { setStyleFilter } = filterationSlice.actions;

export const { setTypeFilter, setSearchFilter } = filterationSlice.actions;
export const { setCategoryFilter } = filterationSlice.actions;
export const { setArtistTypeFilter } = filterationSlice.actions;
export const { setArtistFilter } = filterationSlice.actions;
export const { setCountryFilter } = filterationSlice.actions;
export const { setOrientationFilter } = filterationSlice.actions;
export const { setColorFilter } = filterationSlice.actions;
export const { setStyleFilterNav } = filterationSlice.actions;
export const { setNoFilter } = filterationSlice.actions;
export const { setArtistFilterNav } = filterationSlice.actions;

export const { setFilterByPriceNav } = filterationSlice.actions;
export const { setFilterByPrice } = filterationSlice.actions;
export const {setAllFilterValues} = filterationSlice.actions;
export default filterationSlice.reducer;
