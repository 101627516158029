import React from "react";
import Moreoption from "../../../../components/Pages/List/Table/Moreoption/Moreoption";
import Tableheader from "../../../../components/Pages/List/Table/Tableheader/Tableheader";
import styles from "./Commonauctiongallery.module.css";

export default function Commonauctiongallery(props) {
 return (
  <div className={styles.mains}>
   <Tableheader
    button={props.button}
    handleFilter={props.handleFilter}
    handleSorting={props.handleSorting}
    filterdata={props.filterdata}
    sortdata={props.sortdata}
    condition={props.condition}
    search={props.condition.search}
    handleSearch={props.handleSearch}
   />
   <Moreoption
    activetab={props.activetab}
    setActivetab={props.setActivetab}
    moreOptions={props.moreOptions}
    handleMorecategories={props.handleMorecategories}
   />
  </div>
 );
}
