import React, { useState } from "react"
import styles from "./Fillquestion.module.css"
import Questionsui from "../../../components/Common/questionUi/Question"
import { useNavigate } from "react-router-dom"
import { ToastContainer } from "react-toastify"

const inputs = [
  {
    number: 1,
    label: "Enter youre first name",
    type: "text",
    name: "first_name",
    placeholder: "Enter first name",
  },
  {
    number: 2,
    label: "Enter youre last name",
    type: "text",
    name: "last_name",
    placeholder: "Enter last name",
  },
  {
    number: 3,
    label: "Enter youre mobile number",
    type: "number",
    name: "number",
    placeholder: "Enter first name",
  },
  {
    number: 4,
    label: "Email Address",
    type: "email",
    name: "email",
    placeholder: " Enter Email",
  },
  {
    number: 5,
    label: "Enter Password",
    type: "password",
    name: "password",
    placeholder: "Enter password",
  },
  {
    number: 6,
    label: "Professional Qualifications",
    type: "text",
    name: "qualification",
    placeholder: "Enter text",
  },
  {
    number: 7,
    label: "Echievements",
    type: "text",
    placeholder: "Enter text",
  },
  {
    number: 8,
    label: "Shows Cutrated",
    type: "text",
    name: "curated",
    placeholder: "Enter text",
  },
  {
    number: 9,
    label: "Upload your profile detailing your experience?",
    label2: "Upload your ID proof *",
    type: "file",
    name: "experience_image",
    placeholder: "Upload your images",
  },
  {
    number: 10,
    label: "ID Proof",
    label2: "Upload your ID proof *",
    type: "file",
    name: "id_image",
    placeholder: "Upload your images",
  },
]
const input = [
  {
    number: "11",
    type: "text",
    placeholder: "Enter address",
    name: "location",
    label: "Location",
  },
]
export default function Fillquestion({
  handleChangeApplyData,
  apply,
  setApply,
  handleChangeImage,
  setAchivement,
  handleChangeShowsCurated
}) {
  const [steps, setSteps] = useState({
    step: "1",
    stepper: Math.floor((100 / 13) * 1),
  })
  const navigate = useNavigate()

  const handleStepsBack = (num) => {
    if (num === "1") {
      setSteps({ step: "1", stepper: (100 / 13) * 1 })
    } else if (num === "2") {
      setSteps({ step: "2", stepper: (100 / 13) * 2 })
    } else if (num === "3") {
      setSteps({ step: "3", stepper: (100 / 13) * 3 })
    } else if (num === "4") {
      setSteps({ step: "4", stepper: (100 / 13) * 4 })
    } else if (num === "5") {
      setSteps({ step: "5", stepper: (100 / 13) * 5 })
    } else if (num === "6") {
      setSteps({ step: "6", stepper: (100 / 13) * 6 })
    } else if (num === "7") {
      setSteps({ step: "7", stepper: (100 / 13) * 7 })
    } else if (num === "8") {
      setSteps({ step: "8", stepper: (100 / 13) * 8 })
    } else if (num === "9") {
      setSteps({ step: "9", stepper: (100 / 13) * 9 })
    } else if (num === "10") {
      setSteps({ step: "10", stepper: (100 / 13) * 10 })
      } 
      else if (num === "11") {
      setSteps({ step: "11", stepper: (100 / 13) * 11 })
    } 
    else if (num === "12") {
      setSteps({ step: "12", stepper: (100 / 13) * 12 })
    } 
    else if (num === "13") {
      setSteps({ step: "13", stepper: (100 / 13) * 13 })
    } 
    else {
      setSteps({ step: "14", stepper: (100 / 13) * 14 })
    }
  }
  const handleSteps = () => {
    if (steps.step === "1" && apply.first_name.length !== 0) {
      setSteps({ step: "2", stepper: (100 / 13) * 2 })
    } else if (steps.step === "2" && apply.last_name.length !== 0) {
      setSteps({ step: "3", stepper: (100 / 13) * 3 })
    } else if (steps.step === "3" && apply.curator_email.length !== 0) {
      setSteps({ step: "4", stepper: (100 / 13) * 4 })
    } else if (steps.step === "4" && apply.phone.length !== 0) {
      setSteps({ step: "5", stepper: (100 / 13) * 5 })
    } else if (steps.step === "5") {
    
      setSteps({ step: "6", stepper: (100 / 13) * 6 })
    } else if (steps.step === "6") {
      setSteps({ step: "7", stepper: (100 / 13) * 7 })
    } else if (steps.step === "7") {
      setSteps({ step: "8", stepper: (100 / 13) * 8 })
    } else if (steps.step === "8" && apply.CV.name.length) {
      setSteps({ step: "9", stepper: (100 / 13) * 9 })
    } else if (steps.step === "9" && apply.ID_proof.name.length) {
      setSteps({ step: "10", stepper: (100 / 13) * 10 })
    } else if (steps.step === "10" && apply.profile_image.name.length) {
      setSteps({ step: "11", stepper: (100 / 13) * 11 })
    }
    else if (steps.step === "11" && apply.cover_image.name.length) {
      setSteps({ step: "12", stepper: (100 / 13) * 12 })
    }
    else if (steps.step === "12" && apply.nationality.length !== 0) {
      setSteps({ step: "13", stepper: (100 / 13) * 13 })
    }
    else if (steps.step === "13" && apply.country.length !== 0) {
      navigate("/curator/applyform");
    } 
  };


  return (
    <>
      <Questionsui
        steps={steps}
        apply={apply}
        setApply={setApply}
        handleSteps={handleSteps}
        inputs={inputs}
        handleChangeImage={handleChangeImage}
        handleChangeApplyData={handleChangeApplyData}
        type="curator"
        handleStepsBack={handleStepsBack}
        setSteps={setSteps}
        setAchivement={setAchivement}
        handleChangeShowsCurated={handleChangeShowsCurated}
      />
      <ToastContainer />
    </>
  )
}
