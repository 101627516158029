import React, { useEffect, useState } from "react"
import Tableheader from "../Table/Tableheader/Tableheader"
import styles from "./PrintPricing.module.css"
import Tablesfivecol from "../Table/Fivecolumn/Tablefivecol"
import Pagination from "../Pagination/Pagination"
import Addpopup from "../Popupdelete/addPopup/Addpopup"
import { useDispatch, useSelector } from "react-redux"
import {
  deletePrintPricing,
  editprintPricing,
  fetchPrintPricing,
  handleAddPrintPricings,
} from "../../../../State/api/superadminApi"
import { AlertsSuccess } from "../../../../common/alert/Alert"
import { setPrintPricingdata } from "../../../../State/Features/superadmin/careerSlice"
import { ValidationAdminPrintPricing } from "../../../../validation/ValidationAdminPrintPricing"


const upcominghead = [
  {
    id: "material ",
    label: "Material",
  },
  {
    id: "product",
    label: "Product",
  },
  {
    id:'createdDate',
    label:'Created Date'
  },
  {
    id: "price",
    label: "Price",
  },
  {
    id:'action',
    label:'Action',
  }
]

export default function PrintPricing() {
  const [button, setButton] = useState({ type: "add", text: "Add" })
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [data, setData] = useState({ offset: 0 })
  const [errorPrintPricing,setErrorPrintPricing] = useState({})
  // const [editErrorPrintPricing,setEditErrorPrintPricing] = useState({})

  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })
  const dispatch = useDispatch()
  const printPricedata = useSelector((state) => state.printPricingdata.data)
  console.log(printPricedata, "printPricedata--")
  const fetchData = () => {
    dispatch(fetchPrintPricing(data))
  }

  useEffect(() => {
    fetchData()
  }, [data])

  const handleAddPrintPricing = (data) => {
    const {isValid,ErrorsPrintPricing} = ValidationAdminPrintPricing(data)

    if(!isValid){
      setErrorPrintPricing(ErrorsPrintPricing)
    }else{
      setErrorPrintPricing({})
      dispatch(handleAddPrintPricings(data, setOpen, fetchData))
    }
    
   
  }

  const handleEditpopup = (data) => {
    console.log(data, "data-=-")
    setEdit(data)
  }
  const handleEditPrintPricing = (id, edit) => {
    const {isValid,ErrorsPrintPricing} = ValidationAdminPrintPricing(edit)

    if(!isValid){
      setErrorPrintPricing(ErrorsPrintPricing)
    }else{
    dispatch(editprintPricing(id, edit, setEdit, fetchData))
    }
  }
  const handleDeletePrintPricing = (id) => {
    dispatch(deletePrintPricing(id, fetchData))
  }
  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })
  }

  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }

  // const handleSorting = (e) => {
  //   let value = e.target.value
  //   if (value === "low") {
  //     setCondition({ ...condition, sorting: value })
  //     dispatch(
  //       setCareerdata({
  //         data:
  //           careerdata.data &&
  //           [...careerdata.data].sort((a, b) => {
  //             return a.experience - b.experience
  //           }),
  //         totalCount: careerdata.totalCount,
  //       })
  //     )
  //   } else if (value === "high") {
  //     setCondition({ ...condition, sorting: value })
  //     dispatch(
  //       setCareerdata({
  //         data:
  //           careerdata.data &&
  //           [...careerdata.data].sort((a, b) => {
  //             return b.experience - a.experience
  //           }),
  //         totalCount: careerdata.totalCount,
  //       })
  //     )
  //   } else {
  //     fetchData()
  //     setCondition({ ...condition, sorting: value })
  //   }
  // }

  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Addpopup
          handleAddPrintPricing={handleAddPrintPricing}
          poptype="printpricing"
          open={open}
          setOpen={setOpen}
          errorPrintPricing={errorPrintPricing}
        />
      ) : (
        ""
      )}
      {edit ? (
        <Addpopup
          poptype="printpricing_edit"
          handleEditPrintPricing={handleEditPrintPricing}
          edit={edit}
          open={edit}
          setOpen={setEdit}
          errorPrintPricing={errorPrintPricing}
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <Tableheader
          handleFilter={handleFilter}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          setOpen={setOpen}
          button={button}
        />
        <Tablesfivecol
          activetab="printpricing"
          handleDeletePrintPricing={handleDeletePrintPricing}
          handleEditpopup={handleEditpopup}
          head={upcominghead}
          data={
            printPricedata &&
            printPricedata
            ?.filter((val) =>
              val.material
                .toLowerCase()
                .includes(condition.search.toLowerCase())
            )
            ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          }
        />
        {/* <Pagination
          totaldata={printPricedata && printPricedata.totalCount}
          pagination={data}
          setPagination={setData}
        /> */}
      </div>
    </div>
  )
}
