import { Grid, TextField } from "@mui/material"
import React, { useState } from "react"
import styles from "./Card.module.css"
import { Button, Select, MenuItem } from "@mui/material"
import { useDispatch } from "react-redux"
import {
  handleAddSubMenu,
  handleAddHeading,
} from "../../../../../State/api/superadminApi"
import { validateAdminSubMenu } from "../../../../../validation/validationAdminSubmenu"
import {ValidateAdminMenuHeading}  from '../../../../../validation/ValidateAdminMenuHeading'
import { FiPlus } from "react-icons/fi";

export default function Card(props) {
  console.log(props?.headingByMenu?.headings, "props.headingByMenu--")
  const dispatch = useDispatch()
  const [errors, setErrors] = useState({})
  const [newdata, setNewdata] = useState({ name: "", link: "" })
  const [heading, setHeading] = useState("")
  const [error, setError] = useState(false)
  const handleNewChange = (e) => {
    const { name, value } = e.target
    setNewdata({ ...newdata, [name]: value })
  }

  const handleHeading = (e) => {
    const head = e.target.value
    setHeading(head)
    setError(false)
  }

  const handleSubmit = () => {
    const { submenuErrors, isValid } = validateAdminSubMenu(newdata)
    if (!isValid) {
      setErrors(submenuErrors)
    } else {
      setErrors({})
      dispatch(handleAddSubMenu(props.menu_id, newdata, props.fetchData))

      setNewdata({ name: "", link: "" })
    }
  }
  const handleSubmitHead = () => {     

    // if (heading.trim() === "") {
    //   setError(true)
    //   return
    // }

    const {HeadingErrors,isValid} = ValidateAdminMenuHeading(heading)


    if(!isValid){
      setErrors(HeadingErrors)
    }else{
      setErrors({})
      dispatch(handleAddHeading(props.menu_id, heading, props.fetchHeading))
    setHeading("")
    }
    
  }
  return (
    <div className={styles.mains}>
      <div className={styles.components}>
        <div className={styles.card}>
          <div className={styles.heading_main}>
            <div className={styles.subheadig}>Menu</div>
            <div className={styles.hrline}></div>
          </div>
          <div className={styles.inputs_div}>
            <label>Main menu</label>
            <Grid
                                container
                                columnSpacing={2}
                              >
                                <Grid item xs={12} sm={6} md={6}>
									<div className="form-group">
                  <input
                  value={
                    props.menuesData &&
                    props.menuesData.filter((val) => val._id === props.menu_id)
                      .length !== 0
                      ? props.menuesData.filter(
                          (val) => val._id === props.menu_id
                        )[0].name
                      : ""
                  }
                  type="text"
                  id="outlined-basic"
                  placeholder="Enter main menu"
                  className="form-control"
                  fullWidth
                  variant="outlined"
                />
                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
									<div className="form-group">
                  <input
                  value={
                    props.menuesData &&
                    props.menuesData.filter((val) => val._id === props.menu_id)
                      .length !== 0
                      ? props.menuesData.filter(
                          (val) => val._id === props.menu_id
                        )[0].link
                      : ""
                  }
                  type="text"
                  id="outlined-basic"
                  placeholder="Enter Redirect Link"
                  className="form-control"
                  fullWidth
                  variant="outlined"
                />
                  </div>
                                </Grid>
                                </Grid>
            
          </div>
          {/* heading */}
          <div className={styles.heading_main}>
            <div className={styles.subheading}>Heading</div>
            <div className={styles.hrline}></div>
          </div>
          <div className={styles.inputs_div}>
          <Grid
                                container
                                columnSpacing={2}
                              >
              <Grid item xs={14} sm={6} md={6}>
                
              <div className="form-group">
              <input
                  onChange={handleHeading}
                  value={heading}
                  name="name"
                  error={error}
                  helperText={error ? "Heading is required." : ""}
                  type="text"
                  id="outlined-basic"
                  placeholder="Enter heading"
                  className="form-control"
                  fullWidth
                  variant="outlined"
                />
              </div>
              {errors?.head && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {errors?.head}
                </span>
              )}
                
              </Grid>
            </Grid>
            <Grid
                                container
                                columnSpacing={2}
                              >
              <Grid item xs={14} sm={6} md={6}>
                <div className={styles.input_detail_div}>
                 
                  <Button onClick={handleSubmitHead}><FiPlus /> Add Heading</Button>
                </div>
              </Grid>
            </Grid>
          </div>
          {/* heading */}
          <div className={styles.heading_main}>
            <div className={styles.subheading}>Sub Menu</div>
            <div className={styles.hrline}></div>
          </div>
          <div className={styles.inputs_div}>
          <Grid
                                container
                                columnSpacing={2}
                              >
              <Grid item xs={12} sm={6} md={6}>
                <div className="form-group">
                <input
                type="text"
                  onChange={handleNewChange}
                  value={newdata.name}
                  name="name"
                  
                  id="outlined-basic"
                  placeholder="Enter submenu"
                  className="form-control"
                  fullWidth
                  variant="outlined"
                />
                 {errors?.name && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {errors?.name}
                </span>
              )}
                </div>
                
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
              <div className="form-group">
              <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  displayEmpty
                  value={newdata.heading_id ?? ""}
                  label="Select heading"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiInputBase-input":{
                      padding:".45rem .75rem",
                      height:"auto",
                      borderRadius:".25rem",
                      fontSize:"16px",
                      fontWeight:"400"
                    },
                    border:"1px solid #ced4da",
                    borderRadius:".25rem",
                    width:"100%"
                  }}
                  placeholder="select type"
                  onChange={handleNewChange}
                  name="heading_id"
                  // name="blog_type"
                >
                  {/* {props.headingByMenu?.headings &&
                    props.headingByMenu?.headings?.map((val) => (
                      <MenuItem value={val?._id}>{val?.name}</MenuItem>
                    ))} */}
                  <MenuItem value="" disabled>
                    Select Heading
                  </MenuItem>
                  {props.headingByMenu?.headings &&
                    props.headingByMenu?.headings?.map((val) => (
                      <MenuItem key={val?._id} value={val?._id}>
                        {val?.name}
                      </MenuItem>
                    ))}
                </Select>
                {errors?.heading_id && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {errors?.heading_id}
                </span>
              )}
              </div>
                
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
              <div className="form-group">
              <input
                  onChange={handleNewChange}
                  value={newdata.link}
                  name="link"
                  
                  id="outlined-basic"
                  placeholder="Enter Redirect Link"
                  className="form-control"
                  fullWidth
                  variant="outlined"
                />
                {errors?.link && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {errors?.link}
                  </span>
                )}
              </div>
                
              </Grid>
            </Grid>

           
              
              {/*
               <Grid
                                container
                                columnSpacing={2}
                              > <Grid item xs={14} sm={2} md={2}>
                <div className={styles.toggle_btn}>
                  <span>Inactive</span>
                  <label className={styles.switch}>
                    <input type="checkbox" />
                    <span className={`${styles.slider} ${styles.round}`}></span>
                  </label>
                </div>
              </Grid> 
              </Grid>*/}
            
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 14, sm: 14, md: 14 }}
            >
              <Grid item xs={14} sm={6} md={6}>
                <div className={styles.input_detail_div}>
                  
                  <Button onClick={handleSubmit}><FiPlus /> Add sub menu</Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  )
}
