import { Box, Button } from "@mui/material";
import React from "react";
import styles from "./webPopupArtistAndGallery.module.css";
import homeImage from "../../../../../constant";
import { useNavigate } from "react-router-dom";

export default function WebpopupArtist({open,setOpen}) {
  const navigate = useNavigate();
  return (
    <>
      {/* {
            props.type === 'web' ?
                // <Box sx={{ mt: { xs: '-20px', sm: '-64px' } }} className={props.data.length === props.i + 1 ? styles.cancel_imgweb : styles.cancel_img_hide_web} >
                //     {props.data.length === props.i + 1 ?
                //         <img alt='img' onClick={() => props.setOpen(false)} src={ '/img/cancel_cross.png'} height='20px' width='20px' />
                //         : ''}
                // </Box> 
                "" :
                <div className={props.data.length === props.i + 1 ? styles.cancel_img : styles.cancel_img_hide} >
                    {props.data.length === props.i + 1 ?
                        <img alt='img' onClick={() => props.setOpen(false)} src={ '/img/cancel_cross.png'} height='20px' width='20px' />
                        : ''}
                </div>
        } */}

<div className={styles.icdvn}><img alt="img" src="/img/artist-popup.svg" width="50px" height="50px" /></div>
      <div className={styles.card_content}>
       
      <div>
      <h3> 
        {open === "ArtistAndGallerySignIn" ? "Artist" : "I Am An Artist"}
          </h3>
          <h5>{open === "ArtistAndGallerySignIn" ? "Create & Connect" : ""}</h5>
        <h6>{open === "ArtistAndGallerySignIn" ? "Sign in to manage your portfolio, upload arts, sell, track orders, connect with collectors, and participate in exhibitions & auctions." : "You are a Painter / Calligrapher / Illustrator / Mixed Media Artist / Sculptor / Digital Artist /  Photographer / Print Maker"}</h6>
        
      </div>
       <button
         className={`apply_btn ${styles.ml_auto}`}
          onClick={() => {
            if(open === "ArtistAndGallerySignIn") {
              navigate("/login",{state: {type: "artist"}})
            }else {
             navigate("/artist/applynow")
             setOpen(false)
            }
          }}
        >
          {open === "ArtistAndGallerySignIn" ? "Sign In" : "Apply Now"}
        </button>
      </div>
    </>
  );
}
