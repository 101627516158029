import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,

}

const CareerSlice = createSlice({
    name: 'careerData',
    initialState,
    reducers: {
        setCareerdata: (state, action) => {
            state.data = action.payload;
                        
        }
    }
});

export const { setCareerdata } = CareerSlice.actions;

// export const compaingnsData = (state) => state.compaingnsData;


export default CareerSlice.reducer;