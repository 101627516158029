import React, { useState } from "react"
import styles from "./RequestMade.module.css"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import Moreoption from "../../../List/Table/Moreoption/Moreoption"
import EightcolumnTable from "../Tables/EightcolumnTable"
import Pagination from "../../../List/Pagination/Pagination"
import { setLoader } from "../../../../../State/Features/common/loaderSlice"
import api from "../../../../../State/api/Api"
import TenColumnTable from "../Tables/TenColumnTable"
import NineColumnTable from "../Tables/NineColumnTable"
import { fetchQuateRequestData } from "../../../../../State/api/artistapi"
import { AlertsSuccess } from "../../../../../common/alert/Alert"
import PopupRequest from "../../../List/Popupdelete/PopupRequest"
import PopupMakeOffer from "../../../List/Popupdelete/PopupMakeOffer"
import PopupQuote from "../../../List/Popupdelete/PopupQuote"
import { RiCloseCircleFill } from "react-icons/ri";
const recreateheadData = [
  {
    id: "customer_id",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "Art Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "sku_id",
    label: "Category",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "name",
    label: "Subject",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "Request Date",
    label: "Request Date",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "date",
    label: "Approved Date",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "status",
    label: "Status",
    format: (value) => value.toLocaleString(),
  },
  // {
  //   id: "action",
  //   label: "Action",
  //   format: (value) => value.toLocaleString(),
  // },
]
const offerheadData = [
  {
    id: "customer_id",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "User Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "sku_id",
    label: "Sku_Id",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "name",
    label: "Art Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "art_tpe",
    label: "Art Type",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "date",
    label: "Offer Date",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "price",
    label: "Price($)",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "offerprice",
    label: "Offer Price($)",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "status",
    label: "Status",
    format: (value) => value.toLocaleString(),
  },
  // {
  //   id: "action",
  //   label: "Action",
  //   format: (value) => value.toLocaleString(),
  // },
]
const qouteheadData = [
  {
    id: "customer_id",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "User Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "sku_id",
    label: "Sku_Id",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "name",
    label: "Art Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "art_tpe",
    label: "Remark",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "date",
    label: "Request Date",
    format: (value) => value.toLocaleString(),
  },
  // {
  //   id: "remark",
  //   label: "Remark",
  //   format: (value) => value.toLocaleString(),
  // },
  {
    id: "status",
    label: "Status",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "action",
    label: "Action",
    format: (value) => value.toLocaleString(),
  },
]
const moreOptions = [
  {
    id: "reuest_made",
    label: "Recreate Request",
    value: "recreate_request",
  },
  {
    id: "make_offer",
    label: "Make An Offer",
    value: "make_offer",
  },
  {
    id: "qoute_request",
    label: "Request For Quote",
    value: "qoute_request",
  },
]
export default function RequestMade() {
  const [makeOfferData, setMakeOfferData] = useState()
  const [data, setData] = useState({ offset: 0 })
  const [activetab, setAcitvetab] = useState("recreate_request")
  const [requestRecreateData, setRequestRecreateData] = useState(null)
  const quatedt = useSelector((state) => state.quateRequestData.data)
  const [open, setOpen] = useState(false)
  const [openMakeOffer, setOpenMakeOffer] = useState(false)
  const [openRequestQuote, setOpenRequestQuote] = useState(false)
  const [popupdata, setPopupdata] = useState(null)
  const [popupdataOffer, setPopupdataOffer] = useState(null)
  const [popupdataQuote, setPopupdataQuote] = useState(null)
  const dispatch = useDispatch()

  const fetchRecreateData = async function (data) {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(
        `api/recreateRequest/fetchRecreateRequests`,
        "POST",
        data
      )
      if (result && result.status === 200) {
        setRequestRecreateData(result.data.data)
        dispatch(setLoader(false))
      }
    } catch (err) {
    }
  }
  const fetchMakeOfferData = async function (data) {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(
        `api/makeOffer/fetchMakeOffers`,
        "POST",
        data
      )
      if (result && result.status === 200) {
        setMakeOfferData(result.data.data)
        dispatch(setLoader(false))
      }
    } catch (err) {
    }
  }
  
  useEffect(() => {
    fetchRecreateData(data)
    fetchMakeOfferData(data)
  }, [data])

  const handlePopup = (data) => {
    setOpen(true)
    setPopupdata(data)
  }
  const handlePopupOffer = (data) => {
    setOpenMakeOffer(true)
    setPopupdataOffer(data)
  }
  const handlePopupQuote = (data) => {
    setOpenRequestQuote(true)
    setPopupdataQuote(data)
  }
  const handleMorecategories = (e) => {
    setAcitvetab(e)
    // if (e === "recreate_request") {
    //   setRequestRecreate({ offset: 1 });
    // } else if (e === "make_offer") {
    //   // props.setButton && props.setButton({ type: '', text: '' })
    //   setMakeOfferData({ offset: 1 });
    // } else if (e === "qoute_request") {
    //   // props.setButton && props.setButton({ type: '', text: '' })
    //   setMakeOfferData({ offset: 1 });
    // }
  }

  const fetchData = () => {
    dispatch(fetchQuateRequestData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <PopupRequest
          popupdata={popupdata}
          fetchData={fetchData}
          open={open}
          setOpen={setOpen}
        />
      ) : (
        ""
      )}
      {openMakeOffer ? (
        <PopupMakeOffer
          popupdataOffer={popupdataOffer}
          openMakeOffer={openMakeOffer}
          setOpenMakeOffer={setOpenMakeOffer}
        />
      ) : (
        ""
      )}
      {openRequestQuote ? (
        <PopupQuote
          popupdataQuote={popupdataQuote}
          openRequestQuote={openRequestQuote}
          setOpenRequestQuote={setOpenRequestQuote}
        />
      ) : (
        ""
      )}
      <Moreoption
        activetab={activetab}
        handleMorecategories={handleMorecategories}
        moreOptions={moreOptions}
        setData={setData}
        data={data}
      />
      <div className={styles.components}>
        {activetab && activetab === "recreate_request" ? (
          <EightcolumnTable
            activetab={activetab}
            headData={recreateheadData}
            data={requestRecreateData && requestRecreateData.recreateRequests}
            handlePopup={handlePopup}
            orderTypes={requestRecreateData  && requestRecreateData?.orderTypes}
          />
        ) : activetab && activetab === "make_offer" ? (
          <TenColumnTable
            activetab={activetab}
            headData={offerheadData}
            data={makeOfferData && makeOfferData.makeOffers}
            orderTypes={makeOfferData  && makeOfferData?.orderTypes}
            handlePopupOffer={handlePopupOffer}
          />
        ) : (
          <NineColumnTable
            activetab="qoute_request"
            headData={qouteheadData}
            data={quatedt && quatedt.data}
            orderTypes={quatedt  && quatedt?.orderType}
            handlePopupQuote={handlePopupQuote}
          />
        )}
        {activetab && activetab === "recreate_request" ? (
          <Pagination
            totaldata={requestRecreateData && requestRecreateData.totalCount}
            pagination={data}
            setPagination={setData}
          />
        ) : activetab && activetab === "make_offer" ? (
          <Pagination
            totaldata={makeOfferData && makeOfferData.totalCount}
            pagination={data}
            setPagination={setData}
          />
        ) : (
          <Pagination
            totaldata={requestRecreateData && requestRecreateData.totalCount}
            pagination={data}
            setPagination={setData}
          />
        )}
      </div>
    </div>
  )
}
