import * as React from "react";
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

export default function TableForBuyerBid({bids}) {
  console.log("biding price",bids);
  
  return (
    <TableContainer
      component={Paper}
      sx={{ backgroundColor: "#F6F6F6", color: "black" }}
    >
      <Table sx={{ minWidth: 700 }} aria-label="static table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
            <TableCell
              align="left"
              sx={{
                whiteSpace: "nowrap",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "16px",
                height: "21px",
                color: "#1C1C1C",
              }}
            >
              Sl. No
            </TableCell>
            <TableCell
              align="left"
              sx={{
                whiteSpace: "nowrap",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "16px",
                height: "21px",
                color: "#1C1C1C",
              }}
            >
              Buyer Name
            </TableCell>
            <TableCell
              align="center"
              sx={{
                whiteSpace: "nowrap",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "16px",
                height: "21px",
                color: "#1C1C1C",
              }}
            
            >
              Bidding Price
            </TableCell>
            <TableCell
              align="left"
              sx={{
                whiteSpace: "nowrap",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "16px",
                height: "21px",
                color: "#1C1C1C",
              }}
            >
              Date
            </TableCell>
            <TableCell
              align="center"
              sx={{
                whiteSpace: "nowrap",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "16px",
                height: "21px",
                color: "#1C1C1C",
              }}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bids?.bids?.map((row, index) => (
           <>         
                   <TableRow key={index} style={{ color: "#636365" }}>
                   <TableCell>{index + 1}</TableCell>
                   <TableCell>{row?.buyer?.[0]?.first_name + " " + row?.buyer?.[0]?.last_name}</TableCell>
                   <TableCell align="center">{"$" + row.amount}</TableCell>
                   <TableCell>{row?.createdAt || "-----"}</TableCell>
                   <TableCell align="center">
                     {row.is_accepted.toLowerCase() === "pending" ? (
                       <Box
                         sx={{ display: "flex", gap: "8px", justifyContent: "center" }}
                       >
                         <Button
                           variant="contained"
                           sx={{ width: "80px", height: "34px", borderRadius: "4px" }}
                         >
                           Accept
                         </Button>
                         <Button
                           variant="outlined"
                           sx={{ width: "80px", height: "34px", borderRadius: "4px" }}
                         >
                           Decline
                         </Button>
                       </Box>
                     ) : (
                       <span
                         style={{
                           color: row.is_accepted.toLowerCase() === "approved" ? "#35BE43" : "#FF0000",
                           fontWeight: "500",
                         }}
                       >
                         {row.is_accepted}
                       </span>
                     )}
                   </TableCell>
                 </TableRow>
        
           </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
