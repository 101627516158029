import validator from "validator"
import isEmpty from "./isEmpty"

export const ValidateAdminMenuHeading = (head) => {
  console.log("headd",head)
  let HeadingErrors = {}

  if (validator.isEmpty(String(head))) {
    HeadingErrors.head = "Heading is required"
  }

  return {
    HeadingErrors,
    isValid: isEmpty(HeadingErrors),
  }
}
