import { Button, FormControl, MenuItem, Select } from "@mui/material"
import styles from "./Tableheader.module.css"
import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { FiSearch } from "react-icons/fi"
export default function TableHeaderFollowedArtist(props) {
  console.log(props, "props-tableheader--")
  // const [search, setSearch] = useState("");
  // const [age, setAge] = React.useState("");

  const location = useLocation()
  const navigate = useNavigate()
  // const handleOnSearch = (e) => {
  //   if (location.pathname === "/artist") {
  //     props.setSearch && props.setSearch(true);
  //     props.setPoptype && props.setPoptype(e.target.name); //
  //   }
  // };
  const handleAddbtn = () => {
    props.setOpen && props.setOpen(true)
    // navigate('/upload/stepfirst')
    // props.setPoptype("add")
    // props.handleAddnew()
  }
  console.log(window.location.href, "href")
  let Pathlocation = window.location.href.split("/")[3]

  return (
    <div>
      <div className={styles.buyer_header}>
        <div className={styles.input_div}>
          <input
            onChange={(e) => props.setSearchInput(e.target.value)}
            name="search"
            // onClick={handleOnSearch}
            value={props.searchInput}
            className="form-control"
            type="text"
            placeholder={props.type === "dispute" ? "Search by title" : "Search by name"}
          />
          <div className={styles.sericon}><FiSearch /></div>
        </div>
        <div className={styles.header_action}>
          {!window.location.href.includes("print-pricing") && (
            <>
              <FormControl>
                <Select
                  value={props.sortedValue}
                  onChange={(e) => props.setSortedValue(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiInputBase-input":{
                      padding:".45rem .75rem",
                      height:"auto",
                      borderRadius:".25rem",
                      fontSize:"16px",
                      fontWeight:"400"
                    },
                    border:"1px solid #ced4da",
                    borderRadius:".25rem",
                    background:"#fff"
                  }}
                >
                <MenuItem value="">
                        Sort by
                     </MenuItem>
                     <MenuItem value={10}>10</MenuItem>
                     <MenuItem value={20}>20</MenuItem>
                     <MenuItem value={30}>30</MenuItem>
                     <MenuItem value={40}>40</MenuItem>
                     <MenuItem value={50}>50</MenuItem>
                     <MenuItem value={60}>60</MenuItem>
                     <MenuItem value={70}>70</MenuItem>
                     <MenuItem value={80}>80</MenuItem>
                     <MenuItem value={90}>90</MenuItem>
                     <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
              {!window.location.href.includes("campaigns") && !window.location.href.includes("followedartist")&& (
                <FormControl>
                  <Select
                    value={props.condition && props.condition.filter}
                    onChange={props.handleFilter && props.handleFilter}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input":{
                        padding:".45rem .75rem",
                        height:"auto",
                        borderRadius:".25rem",
                        fontSize:"16px",
                        fontWeight:"400"
                      },
                      border:"1px solid #ced4da",
                      borderRadius:".25rem",
                      background:"#fff"
                    }}
                     
                  >
                    <MenuItem value="">Filter</MenuItem>
                    {props.filterdata &&
                      props.filterdata.map((val) => (
                        <MenuItem value={val.value}>{val.label}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
          {!window.location.href.includes("artist") &&
          !window.location.href.includes("curator") &&
          !window.location.href.includes("buyerlist") &&
          !window.location.href.includes("affiliate") &&
          !window.location.href.includes("affiliate") &&
          props.button &&
          props.button.type === "add" ? (
            <Button
              variant="contained"
              fullWidth
              onClick={handleAddbtn}
              className={styles.add_btn}
              sx={{
                width: { xs: "120", sm: "100px" },
                mt: { xs: 1, sm: 0 },
                mb: { xs: 1, sm: 0 },
                minWidth: 100,
                height: "44px",
                mr: 0,
                ml: 0,
                // fontFamily: "Poppins",
                // fontStyle: "normal",
                // fontWeight: "600",
                // fontSize: "14px",
                // lineHeight: "21px",
              }}
            >
              {props.button && props.button.text}
            </Button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}
