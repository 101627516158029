import { Grid } from "@mui/material";
import React from "react";
import Commonuploadartsecond from "../../../../gallery/pages/uploadArt/commonUploadartsecond/Commonuploadartsecond";
import Steppergallery from "../../../../gallery/pages/uploadArt/stepper/Steppergallery";
import styles from "./Seconduploadartist.module.css";
import { useNavigate } from "react-router-dom";

const stage = 1;
function Seconduploadartist(props) {  
   const navigate = useNavigate()
  return (
    <div className={styles.mains}>
     <Grid container> 
        <Grid item xs={12} sm={12} md={12} >
          <Steppergallery stage={stage} />
        </Grid>
      </Grid>
      <Grid container spacing={2} display="flex">
         <span className={styles.back_img2} onClick={() => navigate("/upload/stepfirst")}>
              {"<"} Back To Home
            </span> </Grid>
            <Grid item xs={12} position="relative">
      <Commonuploadartsecond
        uploadArt={props.uploadArt}
        setUploadArt={props.setUploadArt}
        handleChangedata={props.handleChangedata}
        handleChangedataCategories={props.handleChangedataCategories}
        src={props.src}
        handleChangeOrientation={props.handleChangeOrientation}
        setCategory={props.setCategory}
        category={props.category}
      />
      </Grid>
    </div>
  );
}

export default React.memo(Seconduploadartist);
