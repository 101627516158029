import {
  Box,
  Button,
  Container,
  createTheme,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./AuctionDetailsOther.module.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PolicySidebar from "./PolicySidebar";
import ProdDetsOther from "./ProdDetsOther";
import ProdDetsqna from "./ProdDetsqna";
import homeImage from "../../../constant";
import { useSelector } from "react-redux";

function AuctionDetailsOther() {
  const theme = createTheme({
    palette: {
      primary: {
        light: "#C1C1C1",
        main: "#000000",
        dark: "#C1C1C1",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ffffff",
        main: "#C1C1C1",
        dark: "#ffffff",
        contrastText: "#000",
      },
    },
    typography: {
      prodheading: {
        fontFamily: "Marcellus",
        fontSize: "2.5rem",
      },
    },
  });
  const [age, setAge] = React.useState("");
  const [productdetails, setProductdetails] = useState();
  const allitemdt = useSelector((state) => state.allitemData);
  const artist_id =
    productdetails &&
    productdetails.artwork.map((vl) => {
      return vl.artist_id._id;
    });

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const cardDetails = [
    {
      id: 1,
      imgUrl: "/img/Rectangle 368.png",
    },
    {
      id: 2,
      imgUrl: "/img/Rectangle 369.png",
    },
    {
      id: 3,
      imgUrl: "/img/Rectangle 370.png",
    },
    {
      id: 4,
      imgUrl: "/img/Rectangle 5199.png",
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.productdetails}>
        <div className={styles.proddets__top}>
          <Container>
            <Grid container>
              <Grid item xs={12} md={6} lg={6}>
                <div className={styles.prod__img}>
                  <div className={styles.morepictures}>
                    <div className={styles.morepicsDiv}>
                      <img
                        className={styles.frameimg}
                        src={"/img/frame traced.png"}
                      />
                      <Typography
                        ml={4}
                        fontFamily="marcellus"
                        fontSize="1.5rem"
                      >
                        Do you want to see
                      </Typography>
                      <Typography fontFamily="marcellus" fontSize="1.5rem">
                        more pictures of this piece?
                      </Typography>
                      <Typography>
                        Let us know and we will send them to you as
                      </Typography>
                      <Typography ml={10}>quickly as possible!</Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Please describe what details you would like to see."
                        size="small"
                        sx={{
                          "& .MuiInputBase-root": {
                            height: 120,
                          },
                          width: "100%",
                          backgroundColor: "white",
                          marginLeft: "-6%",
                          marginTop: "4%",
                        }}
                      />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Your Email Address"
                        size="small"
                        sx={{
                          width: "100%",
                          marginTop: "4%",
                          backgroundColor: "white",
                          marginLeft: "-6%",
                        }}
                      />
                      <Button
                        sx={{
                          backgroundColor: "black",
                          color: "white",
                          width: "100%",
                          "&:hover": {
                            backgroundColor: "black",
                            color: "white",
                          },
                          marginTop: "4%",
                          marginLeft: "-6%",
                        }}
                      >
                        Send My Request
                      </Button>
                    </div>
                  </div>
                  <br />
                  <div className={styles.previewimg__div}>
                    <img
                      className={styles.small_img}
                      src={"/img/Rectangle 5329.png"}
                    />
                    <img
                      className={styles.small_img}
                      src={"/img/Rectangle 5330.png"}
                    />
                    <img
                      className={styles.small_img}
                      src={"/img/Rectangle 5331.png"}
                    />
                    <img
                      className={styles.small_img}
                      src={"/img/Rectangle 5332.png"}
                    />
                    <img
                      className={styles.outlineimg}
                      src={"/img/interiorsofa1.png"}
                    />
                    <Typography ml={2} fontWeight="600" fontSize="0.8rem">
                      View in a room
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className={styles.prodDetails}>
                  <Typography variant="prodheading">
                    See The Light - Special Merit Award
                  </Typography>
                  <p>
                    <Typography fontSize="0.88rem" color="#636365">
                      3D multiple with glass fibre light line and PMMA mirror in
                      the acrylic display case 19.69 x 19.69 x 9.84 in. <br />{" "}
                      (50 x 50 x 25 cm.) Incised with signature and title;
                      signed and numbered in black marker on the Certificate of
                      Authenticity affixed to the reverse
                    </Typography>
                  </p>
                  <Typography fontSize="0.88rem">
                    19.69 x 19.69 x 9.84 in. (50 x 50 x 25 cm.)
                  </Typography>
                  <div className={styles.proddets_dets}>
                    <div>
                      <Typography
                        fontWeight="600"
                        fontSize="0.85rem"
                        color="#1C1C1C"
                      >
                        Starting Date
                      </Typography>
                    </div>
                    <div className={styles.greydescr}>
                      <Typography
                        color="#636365"
                        fontWeight="400"
                        fontSize="0.85rem"
                      >
                        April 20 at 12:45 p.m. (EDT)
                      </Typography>
                    </div>
                  </div>
                  <hr className={styles.dets__divider} />
                  <div className={styles.proddets_dets}>
                    <div>
                      <Typography
                        fontWeight="600"
                        fontSize="0.85rem"
                        color="#1C1C1C"
                      >
                        End Date
                      </Typography>
                    </div>
                    <div className={styles.greydescr}>
                      <Typography ml={4.5} fontWeight="400" fontSize="0.85rem">
                        1 day, 5 hours, 6 mins
                      </Typography>
                      <Typography
                        color="#636365"
                        fontWeight="400"
                        fontSize="0.85rem"
                      >
                        April 27 at 12:45 p.m. (EDT)
                      </Typography>
                    </div>
                  </div>
                  <hr className={styles.dets__divider} />
                  <div className={styles.proddets_dets}>
                    <div>
                      <Typography
                        fontWeight="600"
                        fontSize="0.85rem"
                        color="#1C1C1C"
                      >
                        Estimate
                      </Typography>
                    </div>
                    <div className={styles.greydescr}>
                      <Typography
                        color="#636365"
                        fontWeight="400"
                        fontSize="0.85rem"
                      >
                        5,000—7,000 USD
                      </Typography>
                    </div>
                  </div>
                  <hr className={styles.dets__divider} />
                  <div className={styles.proddets_dets}>
                    <div>
                      <Typography
                        fontWeight="600"
                        fontSize="0.85rem"
                        color="#1C1C1C"
                      >
                        Base Bid
                      </Typography>
                    </div>
                    <div className={styles.greydescr}>
                      <Typography ml={5.25} fontWeight="400" fontSize="0.85rem">
                        4,600 USD
                      </Typography>
                      <Typography
                        color="#636365"
                        fontWeight="400"
                        fontSize="0.85rem"
                      >
                        1 bid, reserve met
                      </Typography>
                    </div>
                  </div>
                  <hr className={styles.dets__divider} />
                  <Typography
                    color="#1c1c1c"
                    fontWeight="600"
                    fontSize="0.85rem"
                  >
                    Choose your maximum bid
                  </Typography>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <TextField
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        select
                        value={age}
                        label="Select Amount"
                        onChange={handleChange}
                        size="small"
                        sx={{
                          "& .MuiFormLabel-root": {
                            marginTop: "-0.15%",
                          },
                          marginTop: "2%",
                        }}
                      >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </TextField>
                    </FormControl>
                  </Box>
                  <Typography fontSize="0.85rem" color="#636365" mt={1}>
                    *This amount excludes shipping fees, applicable taxes, and
                    will have a Buyer's Premium based on the hammer price of the
                    lot: Buyer's Premium
                  </Typography>
                  <Button
                    sx={{
                      textTransform: "none",
                      color: "white",
                      backgroundColor: "black",
                      width: "100%",
                      marginTop: 1,
                      "&.MuiButtonBase-root:hover": { bgcolor: "black" },
                      borderRadius: "8px",
                      height: "3rem",
                    }}
                  >
                    Place Bid
                  </Button>
                  <hr className={styles.divider} />
                  <Typography fontSize="1rem" fontWeight="500">
                    Recent Bidding
                  </Typography>
                  <div className={styles.bidders}>
                    <div className={styles.bidders__flex}>
                      <Typography fontSize="0.85rem">Emily Cooper</Typography>
                      <Typography fontSize="0.85rem">$5000</Typography>
                    </div>
                    <div className={styles.bidders__flex}>
                      <Typography fontSize="0.85rem">John Doe</Typography>
                      <Typography fontSize="0.85rem">$8000</Typography>
                    </div>
                    <div className={styles.bidders__flex}>
                      <Typography fontSize="0.85rem">Gabrial Cam</Typography>
                      <Typography fontSize="0.85rem">$9000</Typography>
                    </div>
                    <div className={styles.bidders__flex}>
                      <Typography fontSize="0.85rem">Emily Cooper</Typography>
                      <Typography fontSize="0.85rem">$5000</Typography>
                    </div>
                    <div className={styles.bidders__flex}>
                      <Typography fontSize="0.85rem">John Doe</Typography>
                      <Typography fontSize="0.85rem">$8000</Typography>
                    </div>
                  </div>
                  <hr className={styles.divider} />
                </div>
              </Grid>
            </Grid>
          </Container>
          <Grid container>
            <Grid item xs={12} sm={7} md={7}>
              <div className={styles.description}>
                <Typography fontWeight="600">Description</Typography>
                <p>
                  <Typography fontWeight="400" fontSize="0.95rem">
                    As an artist currently i have realistic approach to
                    formation, perspectives and impressionist to colors, their
                    harmony and combination. In my artworks i try to catch
                    feelings impressively from today and past. Especially themes
                    of love, compassion, beauty, vintage and different cultures.
                  </Typography>
                </p>
                <p>
                  <Typography fontWeight="400" fontSize="0.95rem">
                    Free of all thoughts and protocol, his macro photographic
                    approach covers both the making of images and the taking of
                    photos focusing on matter and light. This technique allows
                    him to create palettes of patterns and colors which then
                    serve as primary elements for the realization of my
                    photographic or scanographic compositions.
                  </Typography>
                </p>
                <p>
                  <Typography fontWeight="400" fontSize="0.95rem">
                    He is mainly interested in abstraction, whether geometric or
                    lyrical. To the sensitivity that the play of shapes and
                    colors can produce on the individual. These different
                    abstractions (movements or artistic schools of the 20th
                    century) are well established in painting or sculpture but
                    are still only emerging in contemporary photography.
                    Photography has already turned the world of painting upside
                    down, upon its arrival, taking over the representation of
                    reality.
                  </Typography>
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <div className={styles.sidePanel}>
                <div className={styles.choose__frame}>
                  <div className={styles.verticalLine}></div>
                  <Typography
                    fontSize="0.9rem"
                    fontWeight="600"
                    ml={1}
                    className={styles.choose__frameDiv}
                  >
                    Choose Frame
                  </Typography>
                  <Button
                    sx={{
                      textTransform: "none",
                      color: "white",
                      backgroundColor: "black",
                      "&.MuiButtonBase-root:hover": { bgcolor: "black" },
                      borderRadius: "8px",
                      width: "35%",
                      marginLeft: "33%",
                      padding: "3%",
                    }}
                  >
                    <Typography fontSize="0.65rem">Choose a frame</Typography>
                  </Button>
                </div>
                <div className={styles.general__information}>
                  <div className={styles.att__color}>
                    <div className={styles.verticalLine}></div>
                    <Typography fontSize="0.9rem" fontWeight="600" ml={1}>
                      Color:
                    </Typography>
                    <Typography ml={27}>Blue</Typography>
                  </div>
                  <div className={styles.att__color}>
                    <div className={styles.verticalLine}></div>
                    <Typography fontSize="0.9rem" fontWeight="600" ml={1}>
                      Weight:
                    </Typography>
                    <Typography ml={24}>2.5 Kg</Typography>
                  </div>
                  <div className={styles.att__color}>
                    <div className={styles.verticalLine}></div>
                    <Typography fontSize="0.9rem" fontWeight="600" ml={1}>
                      Orientation:
                    </Typography>
                    <img
                      className={styles.orientation1}
                      src={"/img/Rectangle 320.png"}
                    />
                    <img
                      className={styles.orientation2}
                      src={"/img/orientation square.png"}
                    />
                    <img
                      className={styles.orientation3}
                      src={"/img/orientation rect.png"}
                    />
                  </div>
                  <div className={styles.att__color}>
                    <div className={styles.verticalLine}></div>
                    <Typography fontSize="0.9rem" fontWeight="600" ml={1}>
                      SKU Number:
                    </Typography>
                    <Typography ml={18}>200451</Typography>
                  </div>
                  <div className={styles.att__color}>
                    <div className={styles.verticalLine}></div>
                    <Typography fontSize="0.9rem" fontWeight="600" ml={1}>
                      Wall Mounted:
                    </Typography>
                    <Typography ml={20.5}>Yes</Typography>
                  </div>
                  <div className={styles.att__color}>
                    <div className={styles.verticalLine}></div>
                    <Typography fontSize="0.9rem" fontWeight="600" ml={1}>
                      Category:
                    </Typography>
                    <Typography ml={11.7}>Figurative, Animal</Typography>
                  </div>
                  <div className={styles.att__color}>
                    <div className={styles.verticalLine}></div>
                    <Typography fontSize="0.9rem" fontWeight="600" ml={1}>
                      Tags:
                    </Typography>
                    <Typography ml={17.6}>Oil Painting, Art</Typography>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <div className={styles.features}>
          <Container>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4} md={4}>
                  <div className={styles.safe__shipping}>
                    <img
                      className={styles.mini__truck}
                      src={"/img/Mini Truck.png"}
                      alt="img"
                    />
                    <div>
                      <Typography fontWeight="600" fontSize="0.9rem" ml={2}>
                        Safe Shipping Globally
                      </Typography>
                      <Typography fontSize="0.9rem" ml={1.75}>
                        We ensure that your artwork is shipped securely
                        worldwide.
                      </Typography>
                      <Typography fontSize="0.9rem" ml={1.75}>
                        Our reliable shipping partners guarantee safe handling
                        and timely delivery.
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div className={styles.safe__shipping}>
                    <img
                      className={styles.package}
                      src={"/img/Package.png"}
                      alt="img"
                    />
                    <div>
                      <Typography fontWeight="600" fontSize="0.9rem" ml={2}>
                        Free Return
                      </Typography>
                      <Typography fontSize="0.9rem" ml={1.75}>
                        If your purchase doesn't meet your expectations,
                        returning it is simple and free.
                      </Typography>
                      <Typography fontSize="0.9rem" ml={1.75}>
                        We offer a hassle-free return policy with no hidden
                        fees, allowing you to return the artwork within 14 days.
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div className={styles.safe__shipping}>
                    <img
                      className={styles.secure}
                      src={"/img/Secure.png"}
                      alt="img"
                    />
                    <div>
                      <Typography fontWeight="600" fontSize="0.9rem" ml={2}>
                        Secure
                      </Typography>
                      <Typography fontSize="0.9rem" ml={1.75}>
                        All transactions on Art Smiley are encrypted and
                        processed securely.
                      </Typography>
                      <Typography fontSize="0.9rem" ml={1.75}>
                        We collaborate with trusted financial partners to
                        provide a safe and professional experience, ensuring
                        your personal information remains protected.
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        {/* middle section */}
        <div className={styles.proddets__middle}>
          <div className={styles.design__headingImage}>
            <img className={styles.design__image} src={"/img/Design.png"} />
          </div>
          <div className={styles.design__heading}>
            <Typography mt={1} fontSize="2rem" fontFamily="marcellus">
              More art WORKS FROM EMILY
            </Typography>
          </div>

          <div className={styles.ProductCards}>
            <Container>
              <Grid container>
                {cardDetails.map((item) => (
                  <Grid item xs={12} sm={6} md={3}>
                    <div className={styles.card__main}>
                      <div className={styles.card__top}>
                        <img className={styles.cardImage} src={item.imgUrl} />
                      </div>
                      <div className={styles.card__middle}>
                        <div className={styles.card__middleLeft}>
                          <Typography
                            fontSize="0.95rem"
                            fontWeight="700"
                            mt={1}
                          >
                            Galexy On Earth
                          </Typography>
                          <Typography
                            fontSize="0.8rem"
                            fontWeight="700"
                            mt={1}
                            color="#ccc"
                          >
                            John Deo
                          </Typography>
                        </div>
                        <div className={styles.card__middleRight}>
                          <FavoriteBorderIcon fontSize="small" />
                          <Typography fontWeight="400" ml={0.5}>
                            522
                          </Typography>
                        </div>
                      </div>
                      <hr />
                      <div className={styles.card__bottom}>
                        <div className={styles.buy__text}>
                          <Button>
                            <Typography
                              fontWeight="600"
                              color="#ccc"
                              fontSize="0.65rem"
                            >
                              Buy
                            </Typography>
                            <Typography
                              color="black"
                              fontWeight="500"
                              fontSize="0.85rem"
                              mt={4}
                              ml={-3}
                            >
                              <b>$ 3254</b>
                            </Typography>
                          </Button>
                        </div>
                        <div className={styles.verticalLine}></div>
                        <div className={styles.rent__text}>
                          <Button>
                            <Typography
                              fontWeight="600"
                              color="#ccc"
                              fontSize="0.65rem"
                            >
                              Rent/Month
                            </Typography>
                            <Typography
                              color="black"
                              fontWeight="500"
                              fontSize="0.85rem"
                              mt={4}
                              ml={-6.5}
                              mr={3}
                            >
                              <b>$ 680</b>
                            </Typography>
                          </Button>
                        </div>
                        <div className={styles.verticalLine}></div>
                        <div className={styles.print__text}>
                          <Button>
                            <Typography
                              fontWeight="600"
                              color="#ccc"
                              fontSize="0.65rem"
                            >
                              Print
                            </Typography>
                            <Typography
                              color="black"
                              fontWeight="500"
                              fontSize="0.85rem"
                              mt={4}
                              ml={-3.75}
                            >
                              <b>$ 356</b>
                            </Typography>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </div>
          <PolicySidebar />
        </div>
        {/* bottom section */}
        <ProdDetsOther artist_id={artist_id} allitemdt={allitemdt} />
        {/* qna section */}
        <ProdDetsqna />
      </div>
    </ThemeProvider>
  );
}

export default AuctionDetailsOther;
