import { createTheme, Grid, ThemeProvider, Typography,  Container,
  Link } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import homeImage from "../../../constant"
import serverimage from "../../../serverConstant"
import api from "../../../State/api/Api"
import { setLoader } from "../../../State/Features/common/loaderSlice"
import styles from "./PressReleases.module.css"
import moment from "moment"

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
  },
})
function PressReleases() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [pressData, setPressData] = useState()
  console.log(pressData, "pressData--")
  // const d = Date.now()
  // const dates = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(d);
  // console.log(d, dates)
  const navigatetopressdetails = (id) => {
    navigate(`/user/press-releases/${id}`)
  }

  const handleNavigate = (route) => {
    navigate(route)
  }
  const handleFetchPress = async function () {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi("api/user/pressrelease")
      if (result && result.status === 200) {
        setPressData(result.data)
        dispatch(setLoader(false))
      } else if (result.status === 401) {
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    handleFetchPress()
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.pressreleases}>
        <div className={styles.headerCategory}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
      <div className={styles.pageitle}>
              <h1>Press Releases</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li>Press Releases</li>
              </ul>
            </div>
      </Container>
        </div>
        <div className={styles.press_list}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          <Grid container  spacing={2}>
            {pressData &&
              pressData.responses.map((item) => (
                <Grid item xs={12} sm={6} md={3}>
                  <div
                    className={styles.press_box}
                    onClick={() => navigatetopressdetails(item._id)}
                  >
                    <div className={styles.press_img}>
                    <img
                      className={styles.img_fluid}
                      src={ item.media}
                      alt="img"
                    />
                    </div>
                   
                    <div className={styles.press_cont}>
                      <h4>{item.title}</h4>
                      <div className={styles.press_meta}>
                        <div className={styles.prsmto}><h5>{item.press_type}</h5></div>
                        <div className={styles.prsmtt}><h5>{moment(item.date).format("DD/MM/YYYY")}</h5></div>
                    </div>
                    <div className={styles.press_descpr}>
                    <span
                        dangerouslySetInnerHTML={{
                          __html:
                            item &&
                            item.description &&
                            item.description.length > 100
                              ? item.description.substring(0, 100) + "..."
                              : item && item.description
                              ? item.description
                              : "",
                        }}
                      ></span>
                    </div>
                    </div>
                  </div>
                </Grid>
              ))}
          </Grid>
          </Container>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default PressReleases
