import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pages/List/Pagination/Pagination";
import Add from "../../../components/Pages/List/Popupdelete/addPopup/Addpopup";
import Tableseven from "../../../components/Pages/List/Table/Sevencol/Tableseven";
import Commonadmindisputegallery from "../../../gallery/pages/adminDispute/common/Commonadmindisputegallery";
import { fetchDisputeData } from "../../../State/api/superadminApi";
import { setDisputedata } from "../../../State/Features/superadmin/disputeSlice";
import Sevencoltable from "../../commonTables/sevencol/Sevencoltable";
import styles from "./Disputeartist.module.css";
import TableArtistDispute from "../../commonTables/sevencol/TableDisputeArtist";

const moreOptions = [
 {
  id: "created_by_me",
  label: "Created By Me",
  value: "gallery",
 },
 {
  id: "created_by_other",
  label: "Created By Other",
  value: "buyer",
 },
];
const exhibitionhead = [
 {
  id: "artist_name ",
  label: "Dispute ID",
 },
 {
  id: "mobile_number",
  label: "Order ID",
 },
 {
  id: "mobile_number",
  label: "Created Date",
 },
 {
  id: "type",
  label: "Title",
 },
 {
  id: "assign_to",
  label: "Descritpion",
 },
 {
  id: "actiont",
  label: "Refund",
 },
 {
  id: "action",
  label: "Status",
 },
];
const exhibitionData = [
 {
  id: 1,
  disp_id: "#37527",
  order_id: "#35732 ",

  name: "Emily Cooper",
  title: "Emerging artist dkuiu",
  refund: {
   status: 1,
  },
  status: true,
 },
 {
  id: 2,
  disp_id: "#37527",
  order_id: "#35732 ",

  name: "Emily Cooper",
  title: "Emerging artist dkuiu",
  refund: {
   status: 1,
  },
  status: false,
 },
 {
  id: 3,
  disp_id: "#37527",
  order_id: "#35732 ",

  name: "Emily Cooper",
  title: "Emerging artist dkuiu",
  refund: {
   status: 0,
  },
  status: true,
 },
 {
  id: 4,
  disp_id: "#37527",
  order_id: "#35732 ",

  name: "Emily Cooper",
  title: "Emerging artist dkuiu",
  refund: {
   status: 2,
  },
  status: true,
 },
 {
  id: 5,
  disp_id: "#37527",
  order_id: "#35732 ",

  name: "Emily Cooper",
  title: "Emerging artist dkuiu",
  refund: {
   status: 1,
  },
  status: false,
 },
 {
  id: 6,
  disp_id: "#37527",
  order_id: "#35732 ",

  name: "Emily Cooper",
  title: "Emerging artist dkuiu",
  refund: {
   status: 0,
  },
  status: true,
 },
 {
  id: 7,
  disp_id: "#37527",
  order_id: "#35732 ",

  name: "Emily Cooper",
  title: "Emerging artist dkuiu",
  refund: {
   status: 0,
  },
  status: false,
 },
];
const placeholder = {
 placeholder: "upload new pic",
};
const sortdata = [
 { label: "Order ID ↓", value: "low" },
 { label: "Order ID ↑", value: "high" },
];
const filterdata = [
 { label: "Resolved", value: "resolved" },
 { label: "Open", value: "open" },
];
export default function Disputartist() {
 const [activetab, setActivetab] = useState("created_by_me");
 const [open, setOpen] = useState(true);
 const [createdby, setCreatedby] = useState({value: "gallery"});
 console.log(createdby,'createdby')
 const [data, setData] = useState({ createdBy: "", offset: 1 });
 const [condition, setCondition] = useState({ search: "", filter: "", sorting: "" });
 console.log(condition,'condition')
 const dispatch = useDispatch();
 const disputedata = useSelector((state) => state.disputeData.data);

 useEffect(() => {
  dispatch(fetchDisputeData(data));
 }, [data]);

 const handleMorecategories = (e) => {
  setActivetab(e);
  if (e === "created_by_me") {
   setData({ createdBy: "1", offset: 1 });
   setCreatedby({value: "gallery"})
  } else {
   setData({ createdBy: "2", offset: 1 });
   setCreatedby({value: "buyer"})
  }
 };

 const handleSearch = (e) => {
  let value = e.target.value;
  setCondition({ ...condition, search: value });
 };

 const handleFilter = (event) => {
  const value = event.target.value;
  setCondition({ ...condition, filter: value });
 };
 const handleSorting = (e) => {
  let value = e.target.value;
  if (value === "low") {
   setCondition({ ...condition, sorting: value });
   dispatch(
    setDisputedata({
     data:
      disputedata.data &&
      [...disputedata.data].sort((a, b) => {
       if (a.order_id.toLowerCase() < b.order_id.toLowerCase()) return -1;
       // a.name.toLowerCase() < b.name.toLowerCase()) return -1
      }),
     totalCount: disputedata.totalCount,
    })
   );
  } else if (value === "high") {
   setCondition({ ...condition, sorting: value });
   dispatch(
    setDisputedata({
     data:
      disputedata.data &&
      [...disputedata.data].sort((a, b) => {
       if (a.order_id.toLowerCase() > b.order_id.toLowerCase()) return -1;
      }),
     totalCount: disputedata.totalCount,
    })
   );
  } else {
    dispatch(fetchDisputeData(data));
   setCondition({ ...condition, sorting: value });
  }
 };
 return (
  <div className={styles.mains}>
   {/* {open ? <Add poptype="gallery_dispute" val={placeholder} open={open} setOpen={setOpen} /> : ""} */}
   <div className={styles.components}>
    <Commonadmindisputegallery
     handleFilter={handleFilter}
     handleSorting={handleSorting}
     filterdata={filterdata}
     sortdata={sortdata}
     condition={condition}
     search={condition.search}
     handleSearch={handleSearch}
     handleMorecategories={handleMorecategories}
     moreOptions={moreOptions}
     activetab={createdby.value}
     setActivetab={setActivetab}
     setData={setCreatedby}
    />
    <TableArtistDispute
     head={exhibitionhead}
     data={
      disputedata &&
      disputedata.data
       .filter((val) => {
        if (condition.filter === "") {
         return val;
        } else if (condition.filter === "resolved") {
         return val.dispute_status === "Resolved";
        } else {
         return val.dispute_status === "Open";
        }
       })
       .filter((val) => {
        if (condition.search === "") {
         return val;
        } else {
         return (
          val.title.toLowerCase().includes(condition.search.toLowerCase()) ||
          val.description.toLowerCase().includes(condition.search.toLowerCase()) ||
          val.order?.[0]?.order_number?.toString().toLowerCase().includes(condition.search.toLowerCase()) ||
          (val.refund_status &&
            ["Rejected", "Approved", "Pending"]
              .find((status) => status.toLowerCase().includes(condition.search.toLowerCase())) === val.refund_status) ||
              val.dispute_status.toLowerCase().includes(condition.search.toLowerCase())
         );
        }
       }) 
     }
    />
    <Pagination 
     totaldata={disputedata && disputedata.totalCount}
     pagination={data}
     setPagination={setData}/>
   </div>
  </div>
 );
}
