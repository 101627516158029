import React, { useEffect } from "react"
import styles from "./CuratorGalleryDetails.module.css"

import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { IoIosArrowBack } from "react-icons/io";
import { Button, Link, Grid } from "@mui/material";

import Artistdetail from "../../../components/Pages/List/Artist/Common/Artistdetail"
import { fetchGalleryProfileData } from "../../../State/api/superadminApi"


const dt = {
  img: "",
  name: "Emily Cooper",
  more_details: [
    {
      title: "Views",
      content: "10,456",
    },
    {
      title: "Likes",
      content: "8,456",
    },
    {
      title: "Favorites",
      content: "1,456",
    },
  ],
}
const data = [
  {
    heading: "Description",
    description_details: [
      {
        title: "Mobile Number",
        content: "+91 9998738792",
      },
      {
        title: "Email Adress",
        content: "Artsmiley",
      },
      {
        title: "CV",
        content: "Artsmiley",
      },
      {
        title: "ID Proof ",
        content: "Artsmiley",
      },
      {
        title: "Social Media Link ",
        content: "http:art.com",
      },
      {
        title: "Art Qualification University ",
        content: "20 W * 20 H * 50 D in",
      },
      {
        title: "Degree Year",
        content: "2012",
      },
      {
        title: "Artist Type ",
        content: "Emerging Artist ",
      },
      {
        title: "Assign to",
        content: "Joe Doe",
      },
      {
        title: "Exibition 1",
        content: "Artsmiley",
      },
      {
        title: "Exibition 2 ",
        content: "Art of Space",
      },
      {
        title: "Subscription Plan ",
        content: "Art of Space",
      },
      {
        title: "Porfolio ",
        content:
          "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and mgffdg gfdhfdg",
      },
      {
        title: "Biography ",
        content:
          "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and m",
      },
    ],
  },
  {
    heading: "Bank Details",
    description_details: [
      {
        title: "Bank Name",
        content: "Axis Bank",
      },
      {
        title: "Account Holder Name",
        content: "Emily cooper",
      },
      {
        title: "Account Number",
        content: "238787438493",
      },
      {
        title: "Routing/ABA Number",
        content: "4325324543",
      },
      {
        title: "Bank Location",
        content: "India",
      },
      {
        title: "Account Type",
        content: "Saving Account",
      },
    ],
  },
 
]
export default function CuratorGalleryDetails() {
  var { id } = useParams()
   const dispatch = useDispatch()
   const galleryprofileData = useSelector((state) => state.galleryprofiledata)
  
   useEffect(() => {
     dispatch(fetchGalleryProfileData(id))
   }, [id])

     const navigate = useNavigate();
   
   const handlePages = () => {   
      navigate("/gallery");
  };

  return (
    <div className={styles.mains}>
     


            <div className={styles.main_artistprofile}>
             <div className={styles.back_button}>
    <Link onClick={handlePages}  className={styles.backbn}> <IoIosArrowBack /> <span> Go Back</span></Link>
      
      </div>
              
      <div className={styles.profile_detail}>
                <div>
                <div className={styles.bsrd}>
          <Grid  container spacing={2} sx={{justifyContent:"space-between"}}>
           <Grid item xs={12} sm={5} md={4}>
        
                    <>
                    
                        <img
                          className="img-fluid"
                          src={galleryprofileData?.data?.profile_image ? galleryprofileData.data.profile_image : "/img/profile_alt.jpg"}
                          alt="img"
                        />
                      
                    </>
                  
                <div className={styles.arf_in}>
                <h2>
                {galleryprofileData?.data?.first_name} {galleryprofileData?.data?.last_name}
                  
                  </h2>
                  <div className={styles.more_details}>
                   
                    <div>
                      <h4>Views</h4>
                      <p>0</p>
                    </div>{" "}
                    <div>
                      <h4>Likes</h4>
                      <p>0</p>
                    </div>{" "}
                    <div>
                      <h4>Favorites</h4>
                      <p>0</p>
                    </div>
                   
                  </div>
                </div>
           </Grid>
         
           </Grid>
           </div>
                  
                
                  
                </div>
              </div> 
             
           
                
                 <div className={styles.all_details}>
                  <div className={styles.bdngr}>
                   <h3 className={styles.sethng}>Description</h3>
                   <p className={styles.odlist}><span>Name :</span> <span> {galleryprofileData?.data?.first_name}
                   {galleryprofileData?.data?.last_name}</span></p>
                   <p className={styles.odlist}><span>Mobile Number :</span> <span>{galleryprofileData?.data?.phone}</span></p>
                   <p className={styles.odlist}><span>Email Address :</span> <span>{galleryprofileData?.data?.email}</span></p>
                   <p className={styles.odlist}><span>Gallery established :</span> <span>{galleryprofileData?.data?.gallery_established}</span></p>
                   <p className={styles.odlist}><span>About Gallery :</span> <span>{galleryprofileData?.data?.about_gallery !== "" ? galleryprofileData?.data?.about_gallery : "----" }</span></p>
                   <p className={styles.odlist}><span>Price Range :</span> <span>
                        {galleryprofileData?.data?.selling_price_range &&  galleryprofileData?.data?.selling_price_range[0] ? galleryprofileData?.data?.selling_price_range[0] : "----"}
                        </span></p>

                        <p className={styles.odlist}><span>Portfolio :</span> <span>
                        {galleryprofileData?.data?.portfolio !== "" ? galleryprofileData?.data?.portfolio : "----" }
                        </span></p>
                   
                        <p className={styles.odlist}><span>Country :</span> <span>
                        {galleryprofileData?.data?.addresses[0]?.country?.trim() 
      ? galleryprofileData?.data?.addresses[0]?.country 
      : "----"}
                        </span></p>
                        <p className={styles.odlist}><span>Social Instagram Link :</span> <span>
                        {galleryprofileData?.data?.instagram !== "" ? galleryprofileData?.data?.instagram : "----" }
                          </span></p>

                          <p className={styles.odlist}><span>Linkedin :</span> <span>
                        {galleryprofileData?.data?.linkedin !== "undefined" ? galleryprofileData?.data?.linkedin : "----" }
                          </span></p>

                          <p className={styles.odlist}><span>Language :</span> <span>
                        {galleryprofileData?.data?.preferred_language !== "" ? galleryprofileData?.data?.preferred_language : "----" }
                          </span></p>

                          <p className={styles.odlist}><span>Facebook :</span> <span>
                        {galleryprofileData?.data?.facebook !== "" ? galleryprofileData?.data?.facebook : "----" }
                          </span></p>

                          <p className={styles.odlist}><span>Experience :</span> <span>
                        {galleryprofileData?.data?.experience !== "" ? galleryprofileData?.data?.experience : "----" }
                          </span></p>
                   </div> 
                  
                   
                   
            
                 
                </div>
            
           
            
             
            </div>
        
    </div>
  )
}
