import { Link, Grid } from "@mui/material"
import { Box } from "@mui/system"
import React, {useState} from "react"
import styles from "./AffiliateDetailsPage.module.css"
import { useParams, useNavigate } from "react-router-dom"
import { IoIosArrowBack } from "react-icons/io";
// import homeImage from "../../../../../constant"
// import serverimage from "../../../../../serverConstant"

const data = {
  description: [
    {
      title: "Title",
      content: "Art of Space",
    },
    {
      title: "Copyright",
      content: "yes",
    },
    {
      title: "Category",
      content: "Oil Painting",
    },
    {
      title: "Subject",
      content: "Lorem this is Subject",
    },
    {
      title: "Year",
      content: "2022",
    },
    {
      title: "Material",
      content: "Canvas",
    },
    {
      title: "Styles",
      content: "Art",
    },
    {
      title: "Dimenstions",
      content: "Cm H25 X  W215 X D14",
    },
    {
      title: "Keywords",
      content: "Art, Painting, Space",
    },
    {
      title: "Description",
      content:
        "ver since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently wi",
    },
    {
      title: "Exibition 3",
      content: "Art of Space",
    },
    {
      title: "Subscription Plan",
      content: "Premium",
    },
    {
      title: "Exxibition 2",
      content: "Art of space",
    },
    {
      title: "Biography",
      content:
        "k. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and m",
    },
  ],
  detailPrice: {
    heading: "Price &  Detail",
    content: [
      {
        details_subheading: "Sale",
        detailsubcontent: [
          {
            title: "Availablity",
            content: "sale",
          },
          {
            title: "Price",
            content: "$250,25",
          },
        ],
      },
      {
        details_subheading: "Rent",
        detailsubcontent: [
          {
            title: "Availablity",
            content: "Rent",
          },
          {
            title: "Price",
            content: "$250,25",
          },
          {
            title: "Time",
            content: "1 Month",
          },
        ],
      },
    ],
  },
}

export default function AffiliateDetailsPage(props) {

  console.log("Affiliate Props",props);
  
  const navigate = useNavigate()

  const handleBack = () => {
    navigate("/affiliate")
  }
  const [activeTab, setActiveTab] = useState(0);
  return (
    <>
   <div className={styles.mains}>
    <div className={styles.back_button}>
    <Link onClick={handleBack} className={styles.backbn}> <IoIosArrowBack /> <span> Go Back</span></Link>
      </div>
      {props.data &&
        props.data.map((val) => (
          <>

            <div className={styles.bsrd2}>
            <Grid
                                          container
                                          spacing={{ xs: 2, md: 1, lg:2 }}
                                        >
              <Grid item xs={12} sm={12} md={3} lg={3}>
              <div className={`${styles.upload_pic_div} ${styles.prfimg}`}>
              <div className={styles.profilemg}>
              <div className={styles.preview_img}>
              <img
                src={
                  val.affiliates && val.affiliates[0].image
                    ? val.affiliates[0].image
                    : "/img/dummy-profile.png"
                }
                className="img-fluid"
                alt="img"
              />
              </div>
              </div>
              </div>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9}>
              <div
              className={
                props.page === "superadmin"
                  ? styles.details_main
                  : styles.details_main_curator
              }
            >
              <div>
                <div className="tab-headers">
                                                <ul className={styles.tablist}>
                                                  <li>
                                                    <Link
                                                      className={`tab-button ${activeTab === 0 ? "active" : ""}`}
                                                      onClick={() => setActiveTab(0)}
                                                      style={{
                                                        background: activeTab === 0 ? "#1c1c1c" : "#fff",
                                                        color: activeTab === 0 ? "#fff" : "#1c1c1c",
                                                      }}
                                                    >
                                                      Personal Details
                                                    </Link>
                                                  </li>
                                                  <li>
                                                    <Link
                                                      className={`tab-button ${activeTab === 1 ? "active" : ""}`}
                                                      onClick={() => setActiveTab(1)}
                                                      style={{
                                                        background: activeTab === 1 ? "#1c1c1c" : "#fff",
                                                        color: activeTab === 1 ? "#fff" : "#1c1c1c",
                                                      }}
                                                    >
                                                      Professional Details
                                                    </Link>
                                                  </li>
                                                  <li>
                                                    <Link
                                                      className={`tab-button ${activeTab === 2 ? "active" : ""}`}
                                                      onClick={() => setActiveTab(2)}
                                                      style={{
                                                        background: activeTab === 2 ? "#1c1c1c" : "#fff",
                                                        color: activeTab === 2 ? "#fff" : "#1c1c1c",
                                                      }}
                                                    >
                                                      Bank Details
                                                    </Link>
                                                  </li>
                                                </ul>
                                              </div>
                                              <div className={`tab-content ${styles.tab_content}`}>
                                              {activeTab === 0 && (
                                                <>
                                                <p className={styles.odlist}><span>Name</span> <span>: {val.affiliates && val.affiliates[0].affiliate_name}</span></p>
            <p className={styles.odlist}><span>Mobile Number</span> <span>: {val.affiliates && val.affiliates[0].phone}</span></p>
            <p className={styles.odlist}><span>Email Id</span> <span>: {val.affiliates && val.affiliates[0].email}</span></p>
            <p className={styles.odlist}><span>Account Type</span> <span>: {val.affiliates &&
                    val.affiliates[0].accountType &&
                    val.affiliates[0].accountType[0].name}</span></p>
                                                </>
                                              )}
                                              {activeTab === 1 && (
                                                <>
                                                <h3 className={styles.sethng}>{val.affiliates && val.affiliates[0].affiliate_name}</h3>
            <p className={styles.odlist}><span>Account Type</span> <span>: {val.affiliates &&
                    val.affiliates[0].accountType &&
                    val.affiliates[0].accountType[0].name}</span></p>
            <p className={styles.odlist}><span>Company Name</span> <span>: {val.affiliates && val.affiliates[0].company_name
                    ? val.affiliates && val.affiliates[0].company_name
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Name</span> <span>: {val.affiliates && val.affiliates[0].affiliate_name}</span></p>
            <p className={styles.odlist}><span>Email</span> <span>: {val.affiliates && val.affiliates[0].email}</span></p>
            <p className={styles.odlist}><span>Country</span> <span>: {val.affiliates && val.affiliates[0].country
                    ? val.affiliates && val.affiliates[0].country
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Language</span> <span>: {val.affiliates && val.affiliates[0].language
                    ? val.affiliates && val.affiliates[0].language
                    : "----"}</span></p>
            <p className={styles.odlist}><span> Phone Number</span> <span>: {val.affiliates && val.affiliates[0].phone
                    ? val.affiliates && val.affiliates[0].phone
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Promote Art Smiley Services</span> <span>: {val.affiliates && val.affiliates[0].promote_art_smiley
                    ? val.affiliates && val.affiliates[0].promote_art_smiley
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Audience Interests</span> <span>: {val.affiliates && val.affiliates[0].audience_interest
                    ? val.affiliates && val.affiliates[0].audience_interest
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Website Url</span> <span>: {val.affiliates && val.affiliates[0].website_url
                    ? val.affiliates && val.affiliates[0].website_url
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Mobile App Url</span> <span>: {val.affiliates && val.affiliates[0].app_url
                    ? val.affiliates && val.affiliates[0].app_url
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Describes your website or mobile apps</span> <span>: {val.affiliates && val.affiliates[0].description
                    ? val.affiliates &&
                      val.affiliates[0].description.map((a, i) => a)
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Drive traffic to your website</span> <span>: {val.affiliates && val.affiliates[0].traffic_drivation
                    ? val.affiliates &&
                      val.affiliates[0].traffic_drivation.map((a, i) => a)
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Build Links</span> <span>: {val.affiliates && val.affiliates[0].build_links
                    ? val.affiliates &&
                      val.affiliates[0].build_links.map((a, i) => a)
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Generate Income</span> <span>: {val.affiliates && val.affiliates[0].generate_income
                    ? val.affiliates && val.affiliates[0].generate_income
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Unique visitors do your websites and apps get per month</span> <span>: {val.affiliates && val.affiliates[0].unique_visitor_count
                    ? val.affiliates && val.affiliates[0].unique_visitor_count
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Primary reason for joining the artsmiley associates</span> <span>: {val.affiliates && val.affiliates[0].primary_reason_to_join
                    ? val.affiliates && val.affiliates[0].primary_reason_to_join
                    : "----"}</span></p>
            <p className={styles.odlist}><span>About Us</span> <span>: {val.affiliates && val.affiliates[0].hear_about_us
                    ? val.affiliates && val.affiliates[0].hear_about_us
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Website or mobile apps about</span> <span>: {val.affiliates && val.affiliates[0].website_about
                    ? val.affiliates && val.affiliates[0].website_about
                    : "----"}</span></p>
                                                </>
                                              )}
                                              {activeTab === 2 && (
                                                <>
                                                <p className={styles.odlist}><span>Bank Name</span> <span>: {val.affiliates && val.bankdetails[0]?.bank_name
                    ? val.affiliates && val.bankdetails[0]?.bank_name
                    : "----"}</span></p>
            <p className={styles.odlist}><span> Bank Location</span> <span>: {val.affiliates && val.bankdetails[0]?.bank_location
                    ? val.affiliates && val.bankdetails[0]?.bank_location
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Account Holder Name</span> <span>: {val.affiliates && val.bankdetails[0]?.account_holder_name
                    ? val.affiliates && val.bankdetails[0]?.account_holder_name
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Account Type</span> <span>: {val.affiliates && val.bankdetails[0]?.account_type
                    ? val.affiliates && val.bankdetails[0]?.account_type
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Account Number</span> <span>: {val.affiliates && val.bankdetails[0]?.account_number
                    ? val.affiliates && val.bankdetails[0]?.account_number
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Bank Address</span> <span>: {val.affiliates && val.bankdetails[0]?.bank_address
                    ? val.affiliates && val.bankdetails[0]?.bank_address
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Swift Code</span> <span>: {val.affiliates && val.bankdetails[0]?.swift_code
                    ? val.affiliates && val.bankdetails[0]?.swift_code
                    : "----"}</span></p>
            <p className={styles.odlist}><span>Routing / ABA Number</span> <span>: {val.affiliates && val.bankdetails[0]?.routing_ABA_number
                    ? val.affiliates && val.bankdetails[0]?.routing_ABA_number
                    : "----"}</span></p>
                                                </>
                                              )}
                                              </div>
                </div>
            
            </div>
              </Grid>
            </Grid>
            </div>
            
          </>
        ))}
    </div>
       
      
    </>
  )
}
