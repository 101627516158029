import { Api } from "@mui/icons-material"
import { Errornotify, Successnotify } from "../../common/alert/Alert"
import { setLoader } from "../Features/common/loaderSlice"
import { setAllcategory } from "../Features/user/allcategorySlice"
import { setAllitemdata, setOrderType } from "../Features/user/allitemsSlice"
import { setAllcartdata } from "../Features/user/mycartSlice"
import { setPolicydata } from "../Features/user/policySlice"
import { setAllType } from "../Features/user/producttypeSlice"
import { setUserArtistdata } from "../Features/user/userartistSlice"
import { setAllArtitType } from "../Features/user/userartisttypeSlice"
import { setFaqdata } from "../Features/user/userfaqSlice"
import { setUserAllOffer } from "../Features/user/useroffersSlice"
import { setWishlistdata, setWishlistdataOrderType } from "../Features/user/wishlistSlice"
import { setAllFilterValues } from "../Features/user/filterationSlice"
import api from "./Api"
import { removeUser } from "../Features/common/userSlice"
import { setBuyerDetail } from "../Features/user/buyerdetailSlice"
import axios from "axios"
import { setAllSubscriptionData } from "../Features/user/subscriptionSlice"

// SUBSCRIPTION API'S
export function AddMailSubscription(data, setOpen, setEmail) {
  return async function fetchCategoryDataThunk(dispatch) {
    // dispatch(setLoader(true));
    try {
      const apiResponse = await api.applicationApi(
        "api/user/addsubscribenewsletter",
        "POST",
        data
      )
      if (apiResponse.status === 200 || apiResponse.status === 201) {
        Successnotify("Subscribed Successfully")
        setOpen(false)
        setEmail("")
        // dispatch(setLoader(false));
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          return 401
        }
 
        dispatch(setLoader(false))
        return false
      }
    } catch (err) {
    
    }
  }
}
// BUYER DETAIL'S API'S

export function handleFetchBuyerDetail() {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/buyer/fetchBuyerDetails",
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setLoader(false))
        dispatch(setBuyerDetail(apiResponse.data.data))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
// MAKEOFFERSUBMIT Api'S
export function handelMakeOffer(data, setOpen, setMakeOffer) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/createMakeOffer",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify("Offer Send")
        setOpen(false)
        setMakeOffer({
          email: "",
          first_name: "",
          last_name: "",
          phone: "",
          price: 4555,
          message: "",
        })
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        } else {
   
          dispatch(setLoader(false))
          return false
        }
      }
    } catch (err) {
  
    }
  }
}

export function ApplyOfferCoupon(offerCode, setCouponData) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/getOfferByCode",
        "POST",
        {
          offer_code: offerCode,
        }
      )
      if (apiResponse.status === 200) {
        Successnotify("Coupon Code Applied")
        setCouponData(apiResponse.data?.response)
      } else {
     
      }
    } catch (err) {
  
    }
  }
}

export function ApplyGiftCard(giftCard, setGiftCardData, setOpen) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/user/getPurchasedGiftCard/${giftCard}`,
        "GET",
        {}
      )
      if (apiResponse.status === 200 || apiResponse.status === 201) {
        Successnotify("Gift Card Applied")
        setGiftCardData(apiResponse.data?.data)
        setOpen(false)
      } else {
        Errornotify("Invalid Gift Card")
      }
    } catch (err) {
  
    }
  }
}

export function ApplyGirfCard(
  gift_card_id,
  paymentType,
  session_id,
  amount,
  email
) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/purchaseGiftCard",
        "POST",
        {
          gift_card_id,
          paymentType,
          session_id,
          amount,
          email,
        }
      )
      if (apiResponse.status === 200 || apiResponse.status === 201) {
        Successnotify("Gift Card Purchased Successfully!")
      } else {
        Errornotify("Invalid Coupon Code")
      }
    } catch (err) {
  
    }
  }
}
// CONTACT API'S //
export function handelContactDetails(data, setContactDetail) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/contactus",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify("Contact Detail Submited")
        setContactDetail({
          first_name: "",
          last_name: "",
          phone: "",
          email: "",
          message: "",
        })
        // dispatch(setLoader(false));
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          return 401
        }
 
        dispatch(setLoader(false))
        return false
      }
    } catch (err) {
      Errornotify(err)
  
    }
  }
}
// ASK QUESTION API
export function handelQuestionDetails(data, setContactDetail, setOpen) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/askquestions",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify("Your Question Submit")
        setOpen(false)
        setContactDetail({
          email: "",
          message: "",
        })
        // dispatch(setLoader(false));
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          return 401
        }
 
        dispatch(setLoader(false))
        return false
      }
    } catch (err) {
      Errornotify(err)
  
    }
  }
}
export function handleRecreateDetails(data, setContactDetail, setOpen) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/addRecreateRequest",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify("Your Recreate Submit")
        setOpen(false)
        setContactDetail({
          email: "",
          message: "",
          item_id: "",
        })
        // dispatch(setLoader(false));
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          return 401
        }
 
        dispatch(setLoader(false))
        return false
      }
    } catch (err) {
      Errornotify(err)
  
    }
  }
}
// CATEGORY API'S
export function fetchCategoryData(data) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/getcategories",
        "GET",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setAllcategory(apiResponse.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
export function fetchDataByCategory(id, data) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/user/getcategories/${id}`,
        "PATCH",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setAllcategory(apiResponse.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
// ALL ITEM API'S
export function fetchAllItemData() {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/allitems",
        "GET",
        {}
      )
      if (apiResponse.status === 200) {
        dispatch(setAllitemdata(apiResponse.data.artworks))
        dispatch(setOrderType(apiResponse.data?.oredrTypeList?.filter(
          (val) => val.name === "Buy"
        )[0]?._id))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
// POLICY API'S
export function fetchPrivacyPolicy(url, data) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(url, "GET", data)
      if (apiResponse.status === 200) {
        dispatch(setPolicydata(apiResponse.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          // dispatch(setLoader(false));
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
// FAQ API'S
export function fetchFaq(data) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/getfaq",
        "GET",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setFaqdata(apiResponse.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
// ARTIST API'S
export function fetchUserArtist(data) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/artist/fetchArtists",
        // "api/user/fetchallartists",
        "GET",
        data
      )
      if (apiResponse.status === 200) {
        console.log("apiResponseUserList",apiResponse)
        dispatch(setUserArtistdata(apiResponse.data.responses))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
// TESTIMONIAL API'S

export function fetchUseTestimonial(data, setTestimonial) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/testimonial",
        "GET",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setTestimonial(apiResponse.data.responses))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
// TYPE API

export function fetchProductType() {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/getitemtype",
        "GET",
        {}
      )
      if (apiResponse.status === 200) {
        dispatch(setAllType(apiResponse.data.response))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
// ARTIST TYPE API

export function fetchArtistTypes() {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/artist-type",
        "GET",
        {}
      )
      if (apiResponse.status === 200) {
        dispatch(setAllArtitType(apiResponse.data.response))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
// REQUEST QUOTE API

export function handelRequestQuote(data, setOpen) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/addRequestQoute",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify("Request Sent")
        setOpen(false)
     setTimeout(() => {
      window.location.reload()
     },1000)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          return 401
        }
 
        dispatch(setLoader(false))
        return false
      }
    } catch (err) {
      Errornotify(err)
  
    }
  }
}
// OFFER GET API

export function handleRequestInquiry(id, inquiryRequest, setOpen) {
  console.log("inquireyRequest",inquiryRequest)
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/user/askInquiry/${id}`,
        "POST",
        inquiryRequest
      )
      console.log(apiResponse,'apiResponse')
      
      if (apiResponse.status === 200 || apiResponse.status === 201) {
        Successnotify("Request sent")
        setOpen(false)
      } else if(apiResponse.status === 500) {
           Errornotify("Email already exist")
           setOpen(true)
      }
    } catch (err) {
      console.log(err,'err')
      Errornotify(err)
  
    }
  }
}
export function handleRequestRecreate(recreateRequest, setOpen) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/user/addRecreateRequest`,
        "POST",
        recreateRequest
      )
      if (apiResponse.status === 200 || apiResponse.status === 201) {
        Successnotify("Recreate Request Sent ")
        setOpen(false)
        setTimeout(() => {
          window.location.reload()
         },1000)
      }
    } catch (err) {
      Errornotify(err)
  
    }
  }
}
export function fetchFetchOffers() {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/getoffers",
        "GET",
        {}
      )
      if (apiResponse.status === 200) {
        dispatch(setUserAllOffer(apiResponse.data.response))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}

// FRAME SUBMIT

export function handelSubmitFrame(data, setOpen) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/addchoseframe",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify("Frame Selected Successfully")
        setOpen(false)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          setOpen(false)
          return 401
        }
 
        dispatch(setLoader(false))
        setOpen(false)
        return false
      }
    } catch (err) {
      Errornotify(err)
  
    }
  }
}
// CUSTOM FRAME API
export function handelCustomFrame(data, setOpen) {
  return async function fetchCategoryDataThunk(dispatch) {
    // dispatch(setLoader(true));
    try {
      const apiResponse = await api.applicationApi(
        "api/user/addframe",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify("Frame Request Sent Successfully")
        setOpen(false)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          return 401
        }
 
        dispatch(setLoader(false))
        return false
      }
    } catch (err) {
  
    }
  }
}
// CART GET API

// export function fetchFetchCareer() {
//   return async function fetchCategoryDataThunk(dispatch) {
//     dispatch(setLoader(true))
//     try {
//       const apiResponse = await api.applicationApi(
//         "api/user/getcareers",
//         "GET",
//         {}
//       )
//       if (apiResponse.status === 200) {

//         // dispatch(setUserAllCareer(apiResponse.data.response))
//         dispatch(setLoader(false))
//       } else {
//         if (apiResponse.status === 401) {
//           dispatch(
//             removeUser({
//               signed: false,
//               type: "",
//               data: "",
//             })
//           )
//           return 401
//         }
//         return false
//       }
//     } catch (err) {

//     }
//   }
// }
export function fetchFetchAllcart(data) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/getCartItems",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setAllcartdata(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}

export function fetchSubscriptionById(id) {
  return async function fetchSubscriptionByIdThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/subscription/getSubscription/${id}`,
        "GET"
      )
      dispatch(setAllSubscriptionData(apiResponse.data.subscription))
      if (
        apiResponse.status === 200 ||
        apiResponse.status === 201 ||
        apiResponse.status === 304
      ) {
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
// ADD TO CART API

export function handelAddtocart(data, getData) {
  
  return async function fetchCategoryDataThunk(dispatch) {
    // dispatch(setLoader(true));
    try {
      const apiResponse = await api.applicationApi(
        "api/user/addToCart",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        dispatch(fetchFetchAllcart(getData))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          return 401
        }
        return false
      }
    } catch (err) {
  
    }
  }
}
export function handleUpdataeCart(id, data, dt) {
  return async function fetchCategoryDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        `api/user/updateCartItem/${id}`,
        "PUT",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(fetchFetchAllcart(dt))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
// ADD WISHLIST API

export function handelFetchWishlist(data) {
  return async function fetchCategoryDataThunk(dispatch) {
    // dispatch(setLoader(true));
    try {
      const apiResponse = await api.applicationApi(
        "api/user/getWishListItems",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setWishlistdata(apiResponse.data.data.result))
        dispatch(setWishlistdataOrderType(apiResponse.data.data))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
export function handelAddWishlist(data) {
  return async function fetchCategoryDataThunk(dispatch) {
    // dispatch(setLoader(true));
    try {
      const apiResponse = await api.applicationApi(
        "api/user/addToWishList",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify("Item Added to Wishlist Successfully")
        dispatch(handelFetchWishlist())
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
   
          return 401
        }
        Errornotify(`User ${apiResponse.data.message}`)
        dispatch(setLoader(false))
        return false
      }
    } catch (err) {
  
    }
  }
}

export function handleDeleteWishlist(id, data) {
  return async function fetchCategoryDataThunk(dispatch) {
    // dispatch(setLoader(true));z
    try {
      const apiResponse = await api.applicationApi(
        `api/user/removeFromWishList/${id}`,
        "DELETE",
        {}
      )
      if (apiResponse.status === 200) {
        dispatch(handelFetchWishlist(data))
        Successnotify(apiResponse.data.message)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
 
        return false
      }
    } catch (err) {
    }
  }
}
// CAREER PAGE JOIN TEAM API

export function handleJoinTeam(id, data, setOpen, setJointeam) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      var bodyformData = new FormData()
      bodyformData.set("first_name", data.first_name)
      bodyformData.set("last_name", data.last_name)
      bodyformData.set("email", data.email)
      bodyformData.set("message", data.message)
      bodyformData.append("attachement", data.attachement)
      bodyformData.set("career_id", id)
      const apiResponse = await api.multipartApi(
        "api/user/joinourteam/",
        "POST",
        bodyformData
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setOpen(false)
        setJointeam({
          first_name: "",
          last_name: "",
          email: "",
          message: "",
          attachement: "",
        })
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          dispatch(setLoader(false))
          return 401
        }
 
        dispatch(setLoader(false))
        return false
      }
    } catch (err) {
      Errornotify(err)
  
    }
  }
}

// LIKE AND UNLIKE

export function handleLikesViews(data, handleFetchAllitem) {
  return async function fetchCategoryDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        // "api/likeView/likeAndViews",
        "api/like/addLikeViewFavourite",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        data.string !== "view" && Successnotify(apiResponse.data.message)
        handleFetchAllitem()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
   
          return 401
        }
        data.string !== "view" && Errornotify(apiResponse.data.message)
        return false
      }
    } catch (err) {
  
    }
  }
}
export function handleUpdateLikesViewsFav(data, handleFetchAllitem) {
  return async function fetchCategoryDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/like/updatelikeViewFavourite",
        "PUT",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        handleFetchAllitem()
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
   
          return 401
        }
 
        return false
      }
    } catch (err) {
  
    }
  }
}

// RECENTLY VIEWED API

export function handelAddRecentlyView(data) {
  return async function fetchCategoryDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/user/addToRecentlyViewed",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        // dispatch(fetchFetchAllcart());
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
export function handelFetchRecentlyView(data, setRecently) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/fetchRecentlyViewed",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        setRecently(apiResponse.data.data.result)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}

export function buyerProfileUpdate(data) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const formData = new FormData()

      formData.append("image", data.image)

      formData.append("first_name", data.first_name)
      formData.set("last_name", data.last_name)
      formData.append("phone", data.phone)
      formData.append("language", data.language)
      let dt = {
        address: data.address,
      }
      for (let dataKey in dt) {
        if (dataKey === "address") {
          for (let previewKey in dt[dataKey]) {
            formData.append(`addresses[${previewKey}]`, dt[dataKey][previewKey])
          }
        }
      }

      const apiResponse = await api.multipartApi(
        "api/buyer/updateBuyerProfile",
        "POST",
        formData
      )
      if (apiResponse.status === 200) {
        Successnotify("Profile Updated")
        localStorage.setItem(
          "user_name",
          data.first_name + " " + data.last_name
        )
        dispatch(handleFetchBuyerDetail())
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
// UPDATE ADDRESS

export function buyerAddressDelete(addressId) {
  return async function deleteBuyerAddressThunk(dispatch) {
    try {
      const formData = new FormData()
      formData.append("address_id", addressId)
      formData.append("action", "delete")
      const apiResponse = await api.multipartApi(
        "api/buyer/updateBuyerProfile",
        "POST",
        formData
      )
      if (apiResponse.status === 200 || apiResponse.status === 201) {
        Successnotify("Address Deleted")
        dispatch(handleFetchBuyerDetail())
      }
    } catch (error) {
    }
  }
}
export function buyerProfileAddressAdd(data) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const formData = new FormData()
      let dt = {
        address: data.address,
      }
      for (let dataKey in dt) {
        if (dataKey === "address") {
          for (let previewKey in dt[dataKey]) {
            formData.append(`addresses[${previewKey}]`, dt[dataKey][previewKey])
          }
        }
      }
      formData.append("image", data.image)

      formData.append("first_name", data.first_name)
      formData.set("last_name", data.last_name)
      formData.append("phone", data.phone)
      formData.append("language", data.language)
      // formData.append("address_id", data.address_id)
      // data.address.forEach((address, index) => {
      //   Object.entries(address).forEach(([key, value]) => {
      //     formData.append(`address[${index}][${key}]`, value);
      //   });
      // });

      // data.address.forEach(function (element, index) {
      //   formData.append(`address[${index}]`, element);
      // });

      const apiResponse = await api.multipartApi(
        "api/buyer/updateBuyerProfile",
        "POST",
        formData
      )
      if (apiResponse.status === 200) {
        Successnotify("Profile Updated")
        localStorage.setItem(
          "user_name",
          data.first_name + " " + data.last_name
        )
        dispatch(handleFetchBuyerDetail())
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}

export function buyerProfileAddressUpdate(data) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const formData = new FormData()
      let dt = {
        address: data.address,
      }
      for (let dataKey in dt) {
        if (dataKey === "address") {
          for (let previewKey in dt[dataKey]) {
            formData.append(`addresses[${previewKey}]`, dt[dataKey][previewKey])
          }
        }
      }
      formData.append("image", data.image)

      formData.append("first_name", data.first_name)
      formData.set("last_name", data.last_name)
      formData.append("phone", data.phone)
      formData.append("language", data.language)
      formData.append("address_id", data.address_id)
      // data.address.forEach((address, index) => {
      //   Object.entries(address).forEach(([key, value]) => {
      //     formData.append(`address[${index}][${key}]`, value);
      //   });
      // });

      // data.address.forEach(function (element, index) {
      //   formData.append(`address[${index}]`, element);
      // });

      const apiResponse = await api.multipartApi(
        "api/buyer/updateBuyerProfile",
        "POST",
        formData
      )
      if (apiResponse.status === 200) {
        Successnotify("Profile Updated")
        localStorage.setItem(
          "user_name",
          data.first_name + " " + data.last_name
        )
        dispatch(handleFetchBuyerDetail())
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
export function addAddressBuyer(data) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/buyer/addNewAddress`,
        "POST",

        {
          address_type:
            data?.address?.address_type_dropdown || data.address.address_type,
          flat_no: data.address.flat_no,
          address_line1: data.address.address_line1,
          address_line2: data.address.address_line2,
          apartment: data.address.apartment,
          city: data.address.city,
          state: data.address.state,
          country: data.address.country,
        }
      )
      if (apiResponse.status === 200) {
        Successnotify("Profile Updated")

        dispatch(handleFetchBuyerDetail())
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
export function deleteAddressBuyer(id) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/buyer/deleteAddressById/${id}`,
        "DELETE"
      )
      if (apiResponse.status === 200 || apiResponse.status === 201) {
        Successnotify("Address deleted")

        dispatch(handleFetchBuyerDetail())
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
export function updateAddressBuyer(id, data) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/buyer/updateAddress/${id}`,
        "PATCH",

        {
          address_type: data.address.address_type,
          flat_no: data.address.flat_no,
          address_line1: data.address.address_line1,
          address_line2: data.address.address_line2,
          apartment: data.address.apartment,
          city: data.address.city,
          state: data.address.state,
          country: data.address.country,
        }
      )
      if (apiResponse.status === 200) {
        Successnotify("Profile Updated")

        dispatch(handleFetchBuyerDetail())
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
export function buyerProfileAddressUpdateCheckout(data) {
  return async function fetchCategoryDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const formData = new FormData()
      // let dt = {
      //   address: data.address,
      // };
      // for (let dataKey in dt) {
      //   if (dataKey === "address") {
      //     for (let previewKey in dt[dataKey]) {
      //       formData.append(`addresses[${previewKey}]`, dt[dataKey][previewKey]);
      //     }
      //   }
      // }
      formData.append(`addresses[country]`, data.address.country)
      formData.append(`addresses[city]`, data.address.city)
      formData.append(`addresses[state]`, data.address.state)
      formData.append(`addresses[flat_no]`, "382")
      formData.append(`addresses[nationality]`, data.address.country)
      formData.append("first_name", data.address.fullName)
      formData.append("last_name", data.address.fullName)
      formData.append("phone", data.address.contact)
      // data.address.forEach((address, index) => {
      //   Object.entries(address).forEach(([key, value]) => {
      //     formData.append(`address[${index}][${key}]`, value);
      //   });
      // });

      // data.address.forEach(function (element, index) {
      //   formData.append(`address[${index}]`, element);
      // });

      const apiResponse = await api.multipartApi(
        "api/buyer/updateBuyerProfile",
        "POST",
        formData
      )
      if (apiResponse.status === 200) {
        Successnotify("Profile Updated")
        localStorage.setItem("user_name", data.address.fullName)
       
        dispatch(handleFetchBuyerDetail())
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
// FETCH TAX API

export function fetchSettingTax(setInputs) {
  return async function fetchsubscriptionDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/superAdmin/fetchSettingDetails",
        "GET"
      )
      if (apiResponse.status === 200) {
        apiResponse.data.data.forEach((element) => {
          setInputs(element)
        })
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
        } else {
        }
      }
    } catch (err) {
    }
  }
}

// FOLLOW ARTIST

export function handelAddFollow(data, first_name) {
  return async function fetchCategoryDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/buyer/followUser",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(`You successfully followed ${first_name}`)
        dispatch(handleFetchBuyerDetail())
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}
export function handelUnFollow(data, first_name) {
  return async function fetchCategoryDataThunk(dispatch) {
    try {
      const apiResponse = await api.applicationApi(
        "api/buyer/updateFollow",
        "PUT",
        data
      )
      if (apiResponse.status === 200) {
        Successnotify(`You successfully unfollowed ${first_name}`)
        dispatch(handleFetchBuyerDetail())
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )

          return 401
        }
        return false
      }
    } catch (err) {
    }
  }
}

export function handleAddDisputeBuyer(data, setOpen) {
  return async function addsubscriptionFeatureThunk(dispatch) {
    try {
      const form = new FormData()
      form.append("artist_id", data.artist_id)
      form.append("order_item_id", data.artist_id)
      form.append("order_id", data.order_id)
      form.append("title", data.title)
      form.append("description", data.description)
      form.append("images", data.images)
      const apiResponse = await api.multipartApi(
        "api/dispute/addDispute",
        "POST",
        form
      )
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)

        setOpen(false)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
   
          // return 401;
        } else {
   
          // return false;
        }
      }
    } catch (err) {
    }
  }
}

export function subscriptionPurchase({
  subscriptionName,
  artistId,
  subscriptionId,
  subscriptionTenure,
  paymentType,
  transactionId,
  amount,
}) {
  return async function subscriptionPurchaseDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/subscription/purchaseSubscription`,
        "POST",
        {
          name: subscriptionName,
          artist_id: artistId,
          subscription_id: subscriptionId,
          subscription_tenure: subscriptionTenure, //yearly
          payment_mode: paymentType,
          transaction_id: transactionId,
          amount: amount,
        }
      )
      if (apiResponse.status === 200 || apiResponse.status === 201) {
        Successnotify("Subscription Purchased Successfully!")
        setTimeout(() => {
          window.location.href = "/user/subscription-placed"
        }, 400)
      } else {
        Errornotify("Subscription Purchased Failed!")
      }
    } catch (err) {
  
    }
  }
}

export const filterAllArts =

  (
    {
      categoryName = [],
      styleName = "",
      subjectName = "",
      mediumName = "",
      colorName = "",
      artistTypeName = "",
      artistName = "",
      orientationName = "",
      startPrice = "",
      endPrice = "",
      minHeight = "",
      maxHeight = "",
      minWidth = "",
      maxWidth = "",
      countryName = "",
      offername = "",
      curated_arts = "",
      artistNationality = [],
      isFeaturedArtist = "",
      isExclusiveArtist = "",
      isRecommendedArtist = "",
      artistCountry = [],
      galleryCountry = [],
      galleryNationality = [],
      galleryName = "",
      materialName = "",
      edition = "",
    },
    setLoading
  ) =>
  async (dispatch) => {
    setLoading(true)

    try {
      const queryParams = {
        categoryName,
        styleName,
        subjectName,
        mediumName,
        colorName,
        artistTypeName,
        artistName,
        orientationName,
        startPrice,
        endPrice,
        minHeight,
        maxHeight,
        minWidth,
        maxWidth,
        countryName,
        offername,
        artistNationality,
        isFeaturedArtist,
        isExclusiveArtist,
        isRecommendedArtist,
        artistCountry,
        galleryCountry,
        galleryNationality,
        galleryName,
        curated_arts,
        materialName,
        edition
      }
      const filteredParams = Object.fromEntries(
        Object.entries(queryParams).filter(
          ([_, value]) => value !== "" && value != null
        )
      )
      const queryString = new URLSearchParams(filteredParams).toString()
      const { data } = await api.applicationApi(
        `api/art/filterArt?${queryString}`,
        "GET"
      )
      dispatch(setAllFilterValues(data?.Arts))
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
