import React from 'react'
import Moreoption from '../../../../components/Pages/List/Table/Moreoption/Moreoption'
import Tableheader from '../../../../components/Pages/List/Table/Tableheader/Tableheader'

export default function Commonordergallery(props) {
    console.log(props)
    return (
        <div>
            <Tableheader button=''
                setOpen={props.setOpen}
                // button={props.button}
                handleFilter={props.handleFilter}
                handleSorting={props.handleSorting}
     
                filterdata={props.filterdata}
                sortdata={props.sortdata}
                condition={props.condition}
                search={props.condition.search}
                setCondition={props.setCondition}
                handleSearch={props.handleSearch}
                
                />
               
            {/* <Moreoption activetab={props.activetab} setActivetab={props.setActivetab} moreOptions={props.moreOptions} handleMorecategories={props.handleMorecategories} /> */}
        </div>
    )
}
