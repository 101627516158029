import { createSlice } from "@reduxjs/toolkit";

const initialState = {  
    inquirytype:[],
    inquirysubtype:[],
    data: [],
    inquirymessage:[]
}

const InquiryeSlice = createSlice({
    name: 'inquirylist',
    initialState,
    reducers: {
        setInquiryType:(state,action)=>{
            state.inquirytype = action.payload;
        },
        setInquirySubtype:(state,action)=>{
            state.inquirysubtype = action.payload;
        },
        setInquirydetails: (state, action) => {
            state.data = action.payload;
        },
        setInquirymessage: (state, action) => {
            state.inquirymessage = action.payload;
        },
     
     
    }
});

export const { setInquirydetails,setInquiryType,setInquirySubtype,setInquirymessage } = InquiryeSlice.actions;

export default InquiryeSlice.reducer;