import { CalendarMonthOutlined } from "@mui/icons-material";
import { createTheme, ThemeProvider, Link, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./TandC.module.css";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useNavigate } from "react-router-dom";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import api from "../../../State/api/Api";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import TranslateWrapper from "../../../languageTranslator/TranslateWrapper";

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },  
  },
});
function TandCG() {
  const [termsData, setTermsData] = useState();

  const dispatch = useDispatch();
  const { t } = useTranslation("termsCondition");

  const handleFetchTermsCondition = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi('api/user/termsCondition'
      )
      if (result && result.status === 200) {
        setTermsData(result.data.response);
        dispatch(setLoader(false));
      }
      else if (result.status === 401) {
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handleFetchTermsCondition();
  }, [])
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route)
  }
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.tandc}>
        <div className={styles.allcategory}>
          <div className={styles.headerCategory}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={styles.pageitle}>
              <h1>Terms and Conditions for Gallery</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>{t("home")} /{" "} </Link></li>
                <li>Terms and Conditions for Gallery</li>
              </ul>
            </div>
          </Container>
          </div>
        </div>
        <div className={styles.tandc__content}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div>
            <Grid container spacing={2} sx={{justifyContent:"center"}}>
    <Grid item xs={12} md={6} className={styles.staticLinks}>
    <div>
    <img
                  className="img-fluid"
                  src={ "/img/coming-soon.png"} alt=""
                /> 
    </div>
    </Grid>
    </Grid>
            </div>
            </Container>
           
          </div>
        {/* {termsData && termsData.map((val) => (
          
        ))} */}
      </div>
    </ThemeProvider>
  );
}

export default TandCG;
