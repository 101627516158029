import {
  Button,
  Link,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import styles from "./Addimgpopup.module.css";
import Backdrop from "@mui/material/Backdrop";
import Autocomplete from "@mui/material/Autocomplete";
import homeImage from "../../../../../../constant";
import { fetchItemType } from "../../../../../../State/api/superadminApi";
import { useDispatch, useSelector } from "react-redux";
import { EightKRounded } from "@mui/icons-material";
import serverimage from "../../../../../../serverConstant";
import {
  DesktopDatePicker,
  LocalizationProvider,
  DatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import dayjs from "dayjs";
import { validateOfferDetails } from "../../../../../../validation/validationForAdminOffer";
import { validateEditOfferad } from "../../../../../../validation/validationEditOfferad";
import { validateAdminTestimonials } from "../../../../../../validation/validationAdminTestimonials";
import { validatePrssrelease } from "../../../../../../validation/validationPressRelease";
import { validateAdminBlog } from "../../../../../../validation/validationAdminBlog";
import { validateAdminEditBlog } from "../../../../../../validation/validationAdminEditBlog";
import { validateAdminGift } from "../../../../../../validation/validationAdminGift";
import { validateAdminHero } from "../../../../../../validation/validationAdminHero";
import { validateAdminEditHero } from "../../../../../../validation/validationAdminEditHero";
import { RiCloseCircleFill } from "react-icons/ri";
import { FiUpload } from "react-icons/fi";
import { Errornotify } from "../../../../../../common/alert/Alert";
export default function Addimgpopup(props) {
  console.log("addpopup props", props);

  var d = new Date();
  const [value, setValue] = React.useState([null, null]);
  const [image, setImage] = useState("");
  const [src, setSrc] = useState("");
  const [errors, setErrors] = useState({});
  const [pressErrors, setPressErrors] = useState({});
  const [giftErrors, setGiftErrors] = useState({});
  const [heroErrors, setHeroErrors] = useState({});
  const [heroEditErrors, setHeroEditErrors] = useState({});
  const [blogErrors, setBlogErrors] = useState({});
  const [blogEditErrors, setEditBlogErrors] = useState({});
  const [editsrc, setEditsrc] = useState({
    blog: props.editdata && props.editdata.media,
    pressrelease: props.editpress && props.editpress.image,
    hero: props.editdataHero && props.editdataHero.image,
    offer: props.editdataOffer && props.editdataOffer.offer_image,
  });
  const [testimonial, setTestimonial] = useState({
    image: "",
    customer_name: "",
    position: "",
    description: "",
  });
  const [blogdata, setBlogdata] = useState({
    title: "",
    item_type_id: "",
    image: "",
    // blog_type: "",
    description: "",
    date: "",
  });

  const [editdata, setEditdata] = useState(
    props.editdata
      ? {
          image: props.editdata.media,
          title: props.editdata?.title,
          date: props.editdata.date,
          assign_by: props.editdata.assign_by,
          blog_type: props.editdata.blog_type,
          description: props.editdata.description,
          item_type_id: props.editdata.item_type_id,
        }
      : {
          image: "",
          title: "",
          date: "",
          assign_by: "",
          blog_type: "",
          description: "",
          item_type_id: "",
        }
  );

  const [editOffer, setEditOffer] = useState(
    props.editdataOffer
      ? {
          image: props.editdataOffer.offer_image,
          name: props.editdataOffer?.name,
          discountType: props.editdataOffer.discountType,
          discount: props.editdataOffer.discount,
          start_date: props.editdataOffer.start_date,
          end_date: props.editdataOffer.end_date,
          description: props.editdataOffer.description,
        }
      : {
          image: "",
          name: "",
          discountType: "",
          discount: "",
          start_date: "",
          end_date: "",
          description: "",
        }
  );
  const [editHerodata, setEditHerodata] = useState(
    props.editdataHero
      ? {
          image: props.editdataHero.image,
          title: props.editdataHero?.title,
          sub_title: props.editdataHero.sub_title,
          button_name: props.editdataHero.button_name,
          position: props.editdataHero.position,
          link: props.editdataHero.link,
        }
      : {
          image: "",
          title: "",
          sub_title: "",
          button_name: "",
          position: "",
          link: "",
        }
  );

  const [press, setPress] = useState(
    props.editpress
      ? props.editpress
      : {
          title: "",
          press_type_id: props.editpress?.press_type_id || "",
          image: "",
          description: "",
          link: "",
        }
  );

  const [offer, setOffer] = useState(
    props.offer
      ? props.offer
      : {
          name: "",
          offer_code: "",
          discount: "",
          discountType: "",
          image: "",
          description: "",
          start_date: "",
          end_date: "",
        }
  );

  const [giftcard, setGiftcard] = useState(
    props.giftcard
      ? props.giftcard
      : { name: "", min_price: "", image: "", description: "" }
  );

  const [hero, setHero] = useState(
    props.hero
      ? props.hero
      : {
          title: "",
          sub_title: "",
          button_name: "",
          image: "",
          position: "",
          link: "",
        }
  );

  const itemtype = useSelector((state) => state.itemtype);
  console.log("itemtype", itemtype);

  console.log("blogdata", blogdata);

  const dispatch = useDispatch();
  const handleClose = () => props.setOpen(false);
  // const handleChangeImage = (e) => {
  //   const files = e.target.files;
  //   if (!files || files.length === 0) {
  //     return;
  //   }

  //   const file = files[0];
  //   const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

  //   // Validate file type
  //   if (!allowedTypes.includes(file.type)) {
  //     setImage(null);
  //     setSrc(null);
  //     return;
  //   }
  //   const fileURL = URL.createObjectURL(file);
  //   setImage(file);
  //   setSrc(fileURL);
  //   setBlogdata((prev) => ({ ...prev, image: file }));
  //   setEditdata((prev) => ({ ...prev, image: file }));
  //   setEditHerodata((prev) => ({ ...prev, image: file }));
  //   setEditOffer((prev) => ({ ...prev, image: file }));
  //   setTestimonial((prev) => ({ ...prev, image: file }));
  //   setPress((prev) => ({ ...prev, image: file }));
  //   setOffer((prev) => ({ ...prev, image: file }));
  //   setGiftcard((prev) => ({ ...prev, image: file }));
  //   setHero((prev) => ({ ...prev, image: file }));

  //   setEditsrc((prev) => ({
  //     ...prev,
  //     blog: fileURL,
  //     pressrelease: fileURL,
  //     hero: fileURL,
  //     offer: fileURL,
  //   }));
  // };

  const handleChangeImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileURL = URL.createObjectURL(e.target.files[0]);
      const MIN_FILE_SIZE = 100; // kb
      const MAX_FILE_SIZE = 50000; // kb
      const ALLOWED_TYPES = ["image/jpeg", "image/jpg", "image/png"]; // Allowed file types

      const fileSizeKiloBytes = file.size / 1024;

      // File type validation
      if (!ALLOWED_TYPES.includes(file.type)) {
        Errornotify("Only .jpg, .jpeg, or .png files are allowed.");
        return; // Exit if file type is invalid
      }

      if (fileSizeKiloBytes < MIN_FILE_SIZE) {
        Errornotify("File size should be greater than 100kb");
      } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        Errornotify("File size should be less than or equal to  50mb");
      } else {
        setImage(file);
        setSrc(fileURL);
        setBlogdata((prev) => ({ ...prev, image: file }));
        setEditdata((prev) => ({ ...prev, image: file }));
        setEditHerodata((prev) => ({ ...prev, image: file }));
        setEditOffer((prev) => ({ ...prev, image: file }));
        setTestimonial((prev) => ({ ...prev, image: file }));
        setPress((prev) => ({ ...prev, image: file }));
        setOffer((prev) => ({ ...prev, image: file }));
        setGiftcard((prev) => ({ ...prev, image: file }));
        setHero((prev) => ({ ...prev, image: file }));

        setEditsrc((prev) => ({
          ...prev,
          blog: fileURL,
          pressrelease: fileURL,
          hero: fileURL,
          offer: fileURL,
        }));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      const imgs = URL.createObjectURL(e.target.files[0]);
      setImage(e.target.files[0]);
      setSrc(imgs);
    }
    setTestimonial({ ...testimonial, [name]: value });
  };
  const handleChangeblog = (e) => {
    const { name, value } = e.target;
    setBlogdata({ ...blogdata, [name]: value });
  };

  const handleChangeEditblog = (e) => {
    const { name, value } = e.target;
    setEditdata({ ...editdata, [name]: value });
  };
  console.log("editdata", editdata);

  const handleChangeEditOffer = (e) => {
    const { name, value } = e.target;
    const normalizedValue =
      name === "description" && value === "<p><br></p>" ? "" : value;
    setEditOffer({ ...editOffer, [name]: normalizedValue });
  };

  const handleChangeEditHero = (e) => {
    const { name, value } = e.target;
    setEditHerodata({ ...editHerodata, [name]: value });
  };
  const handleChangeEditpress = (e) => {
    const { name, value } = e.target;
    props.setEditdata({ ...props.editdata, [name]: value });
  };

  const handlefetchItemType = () => {
    dispatch(fetchItemType());
  };
  useEffect(() => {
    handlefetchItemType();
  }, []);
  // const handleChangepress = (e) => {
  //   const { name, value } = e.target;
  //   setPress({ ...press, [name]: value });
  // };

  const handleChangepress = (event) => {
    const { name, value } = event.target;
    setPress((prevPress) => ({ ...prevPress, [name]: value }));
  };

  const handleChangeOffer = (e) => {
    const { name, value } = e.target;
    setOffer({ ...offer, [name]: value });
  };
  const handleChangeGiftcard = (e) => {
    const { name, value } = e.target;
    setGiftcard({ ...giftcard, [name]: value });
  };
  const handleChangeHero = (e) => {
    const { name, value } = e.target;
    setHero({ ...hero, [name]: value });
  };
  const handleChangeDate = (date) => {
    setBlogdata({
      ...blogdata,
      date: dayjs(date).format("YYYY-MM-DD").toString(),
    });
  };
  const handleEditChangeDate = (date) => {
    setEditdata({
      ...editdata,
      date: dayjs(date).format("YYYY-MM-DD").toString(),
    });
  };

  const handleStartDate = (start) => {
    setOffer({
      ...offer,
      start_date: dayjs(start).format("YYYY-MM-DD").toString(),
    });
  };
  const handleEndDate = (end) => {
    setOffer({
      ...offer,
      end_date: dayjs(end).format("YYYY-MM-DD").toString(),
    });
  };

  const handleEditStartDate = (start) => {
    setEditOffer({
      ...editOffer,
      start_date: dayjs(start).format("YYYY-MM-DD").toString(),
    });
  };
  const handleEditEndDate = (end) => {
    setEditOffer({
      ...editOffer,
      end_date: dayjs(end).format("YYYY-MM-DD").toString(),
    });
  };

  const handleSubmit = () => {
    const { offerErrors, isValid } = validateOfferDetails(offer);

    if (!isValid) {
      setErrors(offerErrors);
    } else {
      setErrors({});
      props.handleAddoffer(offer);
    }
  };

  //testimonials
  const handleTestimonialsSubmit = () => {
    const { testimonialsErrors, isValid } =
      validateAdminTestimonials(testimonial);

    if (!isValid) {
      setErrors(testimonialsErrors);
    } else {
      setErrors({});
      props.handleAdd(testimonial);
    }
  };

  const handleUpdate = () => {
    const { offerErrors, isValid } = validateEditOfferad(editOffer);
    if (!isValid) {
      setErrors(offerErrors);
    } else {
      setErrors({});
      props.handleEdit(props.editdataOffer._id, editOffer);
    }
  };

  const handleBlogAdd = () => {
    const { blogErrors, isValid } = validateAdminBlog(blogdata);

    if (!isValid) {
      setBlogErrors(blogErrors);
    } else {
      setBlogErrors({});
      props.handleAddblog(blogdata);
    }
  };

  const handleBlogUpdate = () => {
    const { blogErrors, isValid } = validateAdminEditBlog(editdata);
    // Normalize the description value
    const normalizedDescription =
      editdata.description?.trim() === "<p><br></p>" ||
      editdata.description?.trim() === ""
        ? ""
        : editdata.description;

    // Update the editdata object with the normalized description
    const updatedEditdata = {
      ...editdata,
      description: normalizedDescription,
    };

    // Re-validate with the updated description
    const { blogErrors: updatedBlogErrors, isValid: updatedIsValid } =
      validateAdminEditBlog(updatedEditdata);

    if (!updatedIsValid) {
      setEditBlogErrors(updatedBlogErrors);
    } else {
      setEditBlogErrors({});
      props.handleEdit(props.editdata._id, updatedEditdata);
    }
  };

  console.log("Selected Blog Type:", editdata.blog_type);
  console.log("Item Types:", itemtype.data);

  const handlePressAdd = () => {
    const { pressErrors, isValid } = validatePrssrelease(press);

    if (!isValid) {
      setPressErrors(pressErrors);
    } else {
      setPressErrors({});
      props.add
        ? props.handleAdd(press)
        : props.handleEdit(props.editpress._id, press);
    }
  };
  const handleGiftAdd = () => {
    const { giftErrors, isValid } = validateAdminGift(giftcard);

    if (!isValid) {
      setGiftErrors(giftErrors);
    } else {
      setGiftErrors({});
      props.handleAdd(giftcard);
    }
  };
  const handleHeroAdd = () => {
    const { heroErrors, isValid } = validateAdminHero(hero);

    if (!isValid) {
      setHeroErrors(heroErrors);
    } else {
      setHeroErrors({});
      props.handleAdd(hero);
    }
  };
  const handleHeroUpdate = () => {
    const { editHeroErrors, isValid } = validateAdminEditHero(editHerodata);

    if (!isValid) {
      setHeroEditErrors(editHeroErrors);
    } else {
      setHeroEditErrors({});
      props.handleEdit(props.editdataHero._id, editHerodata);
    }
  };

  console.log("presstype", press);
  return (
    <div>
      {props.poptype === "pressrelease" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_campaigns}>
              <div className={styles.clobtn}>
                <Link onClick={handleClose}>
                  <RiCloseCircleFill className={styles.iconru} />
                </Link>
              </div>
              <p className={styles.mpophndg} id="transition-modal-title">
                {props.add ? "Add" : "Edit"} Press Release
              </p>
              <div className="form-group">
                <label>
                  Images <span className={styles.astricksymb}>*</span>
                </label>
                <div className={styles.preview_img}>
                  {press.image === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        // onClick={handleChangeImage}
                      >
                        <input
                          onChange={handleChangeImage}
                          type="file"
                          id="input"
                          accept="image/*"
                          hidden
                        />
                        <label htmlFor="input" className={styles.labelv}>
                          <FiUpload />
                          <p>Upload Your Image</p>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        onChange={handleChangeImage}
                        type="file"
                        id="input"
                        hidden
                      />
                      <label htmlFor="input">
                        <img
                          alt="img"
                          src={props.add ? src : editsrc.pressrelease}
                          id="img"
                          className={
                            editsrc.pressrelease
                              ? styles.img_view
                              : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                </div>
                <div className={styles.file_div}></div>
                <small>
                  Upload an image of Press Release.Your file must be least
                  1200*1500 px , a JPG or PNG file ,width a color format of
                  rgb,and less than 50MB
                </small>
                <br />
                {pressErrors?.image && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {pressErrors?.image}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>
                  Title <span className={styles.astricksymb}>*</span>
                </label>
                <input
                  className="form-control"
                  placeholder={`Enter title`}
                  type="text"
                  onChange={handleChangepress}
                  name="title"
                  value={press.title}
                />
                {pressErrors?.title && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {pressErrors?.title}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>
                  Press Type <span className={styles.astricksymb}>*</span>
                </label>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={press.press_type_id || ""}
                  label="Type"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiInputBase-input": {
                      padding: ".45rem .75rem",
                      height: "auto",
                      borderRadius: ".25rem",
                      fontSize: "16px",
                      fontWeight: "400",
                    },
                    border: "1px solid #ced4da",
                    borderRadius: ".25rem",
                    width: "100%",
                  }}
                  placeholder="select type"
                  onChange={handleChangepress}
                  name="press_type_id"
                >
                  <MenuItem value={press.press_type}>
                    {press.press_type}
                  </MenuItem>
                  {itemtype.data &&
                    itemtype.data.map((val) => (
                      <MenuItem value={val._id}>{val.name}</MenuItem>
                    ))}
                </Select>
                {pressErrors?.press_type_id && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {pressErrors?.press_type_id}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>
                  Link <span className={styles.astricksymb}>*</span>
                </label>
                <input
                  className="form-control"
                  placeholder="https://artsmiley.to/2hdfkjh"
                  type="text"
                  onChange={handleChangepress}
                  name="link"
                  value={press.link}
                />
                {pressErrors?.link && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {pressErrors?.link}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label className={styles.textarea_label}>
                  Description <span className={styles.astricksymb}>*</span>
                </label>
                <ReactQuill
                  theme="snow"
                  placeholder={`Enter description`}
                  className={`${styles.textarea_blog}  ${styles.customEditor}`}
                  value={press.description}
                  onChange={(content) =>
                    handleChangepress({
                      target: { name: "description", value: content },
                    })
                  }
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                />
                {pressErrors?.description && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginTop: "70px",
                      position: "absolute",
                    }}
                  >
                    {pressErrors?.description}
                  </span>
                )}
              </div>

              <div className={styles.action}>
                <button className="action_btn" onClick={handlePressAdd}>
                  {props.add ? "Add" : "Submit"}
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "addhero" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_campaigns}>
              <div className={styles.clobtn}>
                <Link onClick={handleClose}>
                  <RiCloseCircleFill className={styles.iconru} />
                </Link>
              </div>
              <p className={styles.mpophndg} id="transition-modal-title">
                Add Header
              </p>

              <div className="form-group">
                <label>
                  Images <span className={styles.astricksymb}>*</span>
                </label>
                <div className={styles.preview_img}>
                  {src === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        // onClick={handleChangeImage}
                      >
                        <input
                          onChange={handleChangeImage}
                          type="file"
                          id="input"
                          hidden
                          accept="image/*"
                        />
                        <label htmlFor="input" className={styles.labelv}>
                          <FiUpload />
                          <p>Upload Your Image</p>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        onChange={handleChangeImage}
                        type="file"
                        id="input"
                        hidden
                      />
                      <label htmlFor="input">
                        <img
                          alt="img"
                          src={src}
                          className={
                            image ? styles.img_view : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                </div>
                <div className={styles.file_div}></div>
                <small>
                  Upload an image of your header.Your file must be least
                  1200*1500 px , a JPG or PNG file ,width a color format of
                  rgb,and less than 50MB
                </small>
                <br />
                {heroErrors?.image && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {heroErrors?.image}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label>
                  Title <span className={styles.astricksymb}>*</span>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter title "
                  type="text"
                  name="title"
                  value={hero.title}
                  onChange={handleChangeHero}
                />
                {heroErrors?.title && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {heroErrors?.title}
                  </span>
                )}
              </div>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label>
                      Subtitle <span className={styles.astricksymb}>*</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder={`Enter Subtitle`}
                      type="text"
                      name="sub_title"
                      value={hero.sub_title}
                      onChange={handleChangeHero}
                    />
                    {heroErrors?.sub_title && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {heroErrors?.sub_title}
                      </span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label>
                      Button Name <span className={styles.astricksymb}>*</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder={`Enter Button name `}
                      type="text"
                      name="button_name"
                      value={hero.button_name}
                      onChange={handleChangeHero}
                    />
                    {heroErrors?.button_name && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {heroErrors?.button_name}
                      </span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label>
                      Position <span className={styles.astricksymb}>*</span>
                    </label>

                    <FormControl fullWidth>
                      <Select
                        size="small"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        placeholder="Enter Position"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            padding: ".45rem .75rem",
                            height: "auto",
                            borderRadius: ".25rem",
                            fontSize: "16px",
                            fontWeight: "400",
                          },
                          border: "1px solid #ced4da",
                          borderRadius: ".25rem",
                          width: "100%",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={hero.position}
                        name="position"
                        onChange={handleChangeHero}
                      >
                        <MenuItem value=""> select position</MenuItem>
                        <MenuItem value="Left">Left</MenuItem>
                        <MenuItem value="Right">Right</MenuItem>
                      </Select>
                    </FormControl>
                    {heroErrors?.position && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {heroErrors?.position}
                      </span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label>
                      Link <span className={styles.astricksymb}>*</span>
                    </label>

                    <input
                      className="form-control"
                      placeholder="Enter Link"
                      type="text"
                      name="link"
                      value={hero.link}
                      onChange={handleChangeHero}
                    />
                    {heroErrors?.link && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {heroErrors?.link}
                      </span>
                    )}
                  </div>
                </Grid>
              </Grid>

              <div className={styles.actionv}>
                <button
                  variant="contained"
                  sx={{ borderRadius: "10px" }}
                  className="action_btn"
                  onClick={handleHeroAdd}
                >
                  Add
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "edithero" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_campaigns}>
              <div className={styles.clobtn}>
                <Link onClick={handleClose}>
                  <RiCloseCircleFill className={styles.iconru} />
                </Link>
              </div>
              <p className={styles.mpophndg} id="transition-modal-title">
                Edit Header
              </p>

              <div className="form-group">
                <label>
                  Images <span className={styles.astricksymb}>*</span>{" "}
                </label>
                <div className={styles.preview_img}>
                  {editHerodata.image === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        onClick={handleChangeImage}
                      >
                        <input
                          onChange={handleChangeImage}
                          type="file"
                          id="input"
                          hidden
                          accept="image/*"
                        />
                        <label htmlFor="input" className={styles.labelv}>
                          <FiUpload />
                          <p>Upload Your Image</p>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        onChange={handleChangeImage}
                        type="file"
                        id="input"
                        hidden
                        accept="image/*"
                      />
                      <label htmlFor="input">
                        <img
                          alt="img"
                          src={editsrc.hero}
                          className={
                            editHerodata?.image
                              ? styles.img_view
                              : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                </div>
                <div className={styles.file_div}></div>
                <small>
                  Upload an image of your header.Your file must be least
                  1200*1500 px , a JPG or PNG file ,width a color format of
                  rgb,and less than 50MB
                </small>
                {heroEditErrors?.image && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {heroEditErrors?.image}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label>
                  Title <span className={styles.astricksymb}>*</span>
                </label>

                <input
                  className="form-control"
                  placeholder="Enter title "
                  type="text"
                  name="title"
                  value={editHerodata.title}
                  onChange={handleChangeEditHero}
                />
                {heroEditErrors?.title && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {heroEditErrors?.title}
                  </span>
                )}
              </div>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label>
                      Subtitle <span className={styles.astricksymb}>*</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder={`Enter Subtitle`}
                      type="text"
                      name="sub_title"
                      value={editHerodata.sub_title}
                      onChange={handleChangeEditHero}
                    />
                    {heroEditErrors?.sub_title && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {heroEditErrors?.sub_title}
                      </span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label>
                      Button Name <span className={styles.astricksymb}>*</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder={`Enter Button name `}
                      type="text"
                      name="button_name"
                      value={editHerodata.button_name}
                      onChange={handleChangeEditHero}
                    />
                    {heroEditErrors?.button_name && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {heroEditErrors?.button_name}
                      </span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label>
                      Position <span className={styles.astricksymb}>*</span>
                    </label>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        placeholder="Enter Position"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            padding: ".45rem .75rem",
                            height: "auto",
                            borderRadius: ".25rem",
                            fontSize: "16px",
                            fontWeight: "400",
                          },
                          border: "1px solid #ced4da",
                          borderRadius: ".25rem",
                          width: "100%",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={editHerodata.position}
                        name="position"
                        onChange={handleChangeEditHero}
                      >
                        <MenuItem value="">Position</MenuItem>
                        <MenuItem value="Left">Left</MenuItem>
                        <MenuItem value="Right">Right</MenuItem>
                      </Select>
                    </FormControl>
                    {heroEditErrors?.position && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {heroEditErrors?.position}
                      </span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label>
                      Link <span className={styles.astricksymb}>*</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder="Enter Link"
                      type="text"
                      name="link"
                      value={editHerodata.link}
                      onChange={handleChangeEditHero}
                    />
                    {heroEditErrors?.link && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {heroEditErrors?.link}
                      </span>
                    )}
                  </div>
                </Grid>
              </Grid>

              <div className={styles.actionv}>
                <button className="action_btn" onClick={handleHeroUpdate}>
                  Add
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "testimonials" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_campaigns}>
              <div className={styles.clobtn}>
                <Link onClick={handleClose}>
                  <RiCloseCircleFill className={styles.iconru} />
                </Link>
              </div>
              <p className={styles.mpophndg} id="transition-modal-title">
                Add Testimonials
              </p>

              <div className="form-group">
                <label>
                  Images <span className={styles.astricksymb}>*</span>
                </label>
                <div className={styles.preview_img}>
                  {testimonial.image === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        // onClick={handleChangeImage}
                      >
                        <input
                          onChange={handleChangeImage}
                          type="file"
                          id="input"
                          accept="image/*"
                          hidden
                        />
                        <label htmlFor="input" className={styles.labelv}>
                          <FiUpload />
                          <p>Upload Your Image</p>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        onChange={handleChangeImage}
                        type="file"
                        id="input"
                        hidden
                      />
                      <label htmlFor="input">
                        <img
                          alt="img"
                          src={src}
                          id="img"
                          className={
                            image ? styles.img_view : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                </div>

                <div className={styles.file_div}></div>
                <small>
                  Upload an image of your artwork.Your file must be least
                  1200*1500 px , a JPG or PNG file ,width a color format of
                  rgb,and less than 50MB
                </small>
                <br />
                {errors?.image && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {errors?.image}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>
                  Customer Name <span className={styles.astricksymb}>*</span>
                </label>
                <input
                  className="form-control"
                  placeholder={`Enter customer name`}
                  type="text"
                  value={testimonial.customer_name}
                  name="customer_name"
                  onChange={handleChange}
                />
                {errors?.customer_name && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {errors?.customer_name}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>
                  Position <span className={styles.astricksymb}>*</span>
                </label>
                <input
                  className="form-control"
                  placeholder={`Enter position`}
                  type="text"
                  value={testimonial.position}
                  name="position"
                  onChange={handleChange}
                />
                {errors?.position && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {errors?.position}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>
                  Description <span className={styles.astricksymb}>*</span>
                </label>
                <ReactQuill
                  theme="snow"
                  placeholder={`Enter description`}
                  className={`${styles.textarea_blog}  ${styles.customEditor}`}
                  value={testimonial.description}
                  onChange={(content) =>
                    handleChange({
                      target: { name: "description", value: content },
                    })
                  }
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                />
                {errors?.description && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginTop: "75px",
                      display: "block",
                    }}
                  >
                    {errors?.description}
                  </span>
                )}
              </div>
              <div className={styles.action}>
                <button
                  className="action_btn"
                  onClick={handleTestimonialsSubmit}
                >
                  Add
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "blogs" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_campaigns}>
              <div className={styles.clobtn}>
                <Link onClick={handleClose}>
                  <RiCloseCircleFill className={styles.iconru} />
                </Link>
              </div>
              <p className={styles.mpophndg} id="transition-modal-title">
                Add Blog
              </p>
              <div className="form-group">
                <label>
                  Images <span className={styles.astricksymb}>*</span>
                </label>
                <div className={styles.upload_img}>
                  <div className={styles.preview_img}>
                    {src === "" ? (
                      <div className={styles.img_box}>
                        <div
                          htmlFor="input"
                          style={{ textAlign: "center" }}
                          // onClick={handleChangeImage}
                        >
                          <input
                            onChange={handleChangeImage}
                            type="file"
                            id="input"
                            hidden
                            name="image"
                            accept="image/*"
                          />
                          <label htmlFor="input" className={styles.labelv}>
                            <FiUpload />
                            <p>Upload Your Image</p>
                          </label>
                        </div>
                      </div>
                    ) : (
                      <>
                        <input
                          onChange={handleChangeImage}
                          type="file"
                          id="input"
                          hidden
                          name="image"
                          accept="image/*"
                        />
                        <label htmlFor="input">
                          <img
                            alt="img"
                            src={src}
                            className={
                              image ? styles.img_view : styles.img_view_hide
                            }
                          />
                        </label>
                      </>
                    )}
                  </div>
                  <div className={styles.file_div}></div>
                  <small>
                    Upload an image of your Blog.Your file must be least
                    1200*1500 px , a JPG or PNG file ,width a color format of
                    rgb,and less than 50MB
                  </small>
                  <br />
                  {blogErrors?.image && (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {blogErrors?.image}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>
                  Title <span className={styles.astricksymb}>*</span>
                </label>
                <input
                  className="form-control"
                  placeholder={`Enter title`}
                  type="text"
                  onChange={handleChangeblog}
                  name="title"
                  value={blogdata.title}
                />
                {blogErrors?.title && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {blogErrors?.title}
                  </span>
                )}
              </div>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack>
                        <label>
                          Date <span className={styles.astricksymb}>*</span>
                        </label>
                        <DesktopDatePicker
                          // label="Date desktop"
                          // disableFuture
                          components={{
                            OpenPickerIcon: EventNoteOutlinedIcon,
                            //{ <img alt='img' src={ /img/Calander.png'} height='16px' width='15px' />}
                          }}
                          inputFormat="DD-MM-YYYY"
                          // value={value}
                          value={blogdata.date}
                          onChange={handleChangeDate}
                          className={styles.date_picker}
                          disablePast
                          renderInput={(params) => (
                            <TextField
                              className={styles.input_date}
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "& .MuiInputBase-input": {
                                  padding: ".45rem .75rem",
                                  height: "auto",
                                  borderRadius: ".25rem",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                },
                                border: "1px solid #ced4da",
                                borderRadius: ".25rem",
                                width: "100%",
                              }}
                              {...params}
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                    {blogErrors?.date && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {blogErrors?.date}
                      </span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label>
                      Type <span className={styles.astricksymb}>*</span>
                    </label>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={blogdata.item_type_id || ""}
                      label="Age"
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiInputBase-input": {
                          padding: ".45rem .75rem",
                          height: "auto",
                          borderRadius: ".25rem",
                          fontSize: "16px",
                          fontWeight: "400",
                        },
                        border: "1px solid #ced4da",
                        borderRadius: ".25rem",
                        width: "100%",
                      }}
                      onChange={handleChangeblog}
                      name="item_type_id"
                    >
                      <MenuItem value="" disabled>
                        Select type
                      </MenuItem>
                      {itemtype.data &&
                        itemtype.data.map((val) => (
                          <MenuItem value={val._id}>{val.name}</MenuItem>
                        ))}
                    </Select>
                    {blogErrors?.blog_type && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {blogErrors?.blog_type}
                      </span>
                    )}
                  </div>
                </Grid>
              </Grid>

              <div className="form-group">
                <label className={styles.textarea_label}>
                  Description <span className={styles.astricksymb}>*</span>
                </label>
                <ReactQuill
                  theme="snow"
                  placeholder={`Enter description`}
                  className={`${styles.textarea_blog}  ${styles.customEditor}`}
                  value={blogdata.description}
                  onChange={(content) =>
                    handleChangeblog({
                      target: { name: "description", value: content },
                    })
                  }
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                />
                {blogErrors?.description && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginTop: "70px",
                      position: "absolute",
                    }}
                  >
                    {blogErrors?.description}
                  </span>
                )}
              </div>

              {/* <div className={styles.input_title}>
                                            <label>Title</label><br />
                                            <TextField sx={{ mt: 0.5, backgroundColor: '#f6f6f6', "& .MuiOutlinedInput-notchedOutline": { border: "none" }, borderRadius: '6px' }} size='small' fullWidth placeholder='Enter title ' type='text' />
                                        </div> */}

              <div className={styles.action}>
                <button className="action_btn" onClick={handleBlogAdd}>
                  Add
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "editblogs" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_campaigns}>
              <div className={styles.clobtn}>
                <Link onClick={handleClose}>
                  <RiCloseCircleFill className={styles.iconru} />
                </Link>
              </div>
              <p className={styles.mpophndg} id="transition-modal-title">
                Edit Blog
              </p>

              <div className="form-group">
                <lavel>
                  Images <span className={styles.astricksymb}>*</span>
                </lavel>
                <div className={styles.preview_img}>
                  {editdata.image === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        // onClick={handleChangeImage}
                      >
                        <input
                          onChange={handleChangeImage}
                          type="file"
                          id="input"
                          hidden
                          accept="image/*"
                        />
                        <label htmlFor="input" className={styles.label}>
                          <img
                            alt="img"
                            src={"/img/uploadimg.png"}
                            width="46px"
                            height="43px"
                          />
                          <p> Upload your image</p>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        onChange={handleChangeImage}
                        type="file"
                        id="input"
                        hidden
                      />
                      <label htmlFor="input">
                        <img
                          alt="img"
                          src={editsrc.blog}
                          id="img"
                          className={
                            editdata.image
                              ? styles.img_view
                              : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                </div>
                <div className={styles.file_div}></div>
                <small>
                  Upload an image of your Blog.Your file must be least 1200*1500
                  px , a JPG or PNG file ,width a color format of rgb,and less
                  than 50MB
                </small>
              </div>
              <div className="form-group">
                <label>
                  Title <span className={styles.astricksymb}>*</span>
                </label>
                <input
                  className="form-control"
                  placeholder={`Enter title`}
                  type="text"
                  value={editdata.title}
                  name="title"
                  onChange={handleChangeEditblog}
                />
                {blogEditErrors?.title && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {blogEditErrors?.title}
                  </span>
                )}
              </div>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack>
                        <label className={styles.input_label}>
                          Date <span className={styles.astricksymb}>*</span>
                        </label>
                        <DesktopDatePicker
                          // label="Date desktop"
                          // disableFuture
                          components={{
                            OpenPickerIcon: EventNoteOutlinedIcon,
                            //{ <img alt='img' src={ /img/Calander.png'} height='16px' width='15px' />}
                          }}
                          inputFormat="DD-MM-YYYY"
                          // value={value}
                          value={editdata.date}
                          onChange={handleEditChangeDate}
                          className={styles.date_picker}
                          disablePast
                          renderInput={(params) => (
                            <TextField
                              className={styles.input_date}
                              sx={{
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "& .MuiInputBase-input": {
                                  padding: ".45rem .75rem",
                                  height: "auto",
                                  borderRadius: ".25rem",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                },
                                border: "1px solid #ced4da",
                                borderRadius: ".25rem",
                                width: "100%",
                              }}
                              {...params}
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                    {blogEditErrors?.date && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {blogEditErrors?.date}
                      </span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label>
                      Type <span className={styles.astricksymb}>*</span>
                    </label>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={String(editdata.blog_type || "")} // Ensure a default value
                      label="Type"
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiInputBase-input": {
                          padding: ".45rem .75rem",
                          height: "auto",
                          borderRadius: ".25rem",
                          fontSize: "16px",
                          fontWeight: "400",
                        },
                        border: "1px solid #ced4da",
                        borderRadius: ".25rem",
                        width: "100%",
                      }}
                      placeholder="select type"
                      onChange={handleChangeEditblog}
                      name="blog_type"
                    >
                      <MenuItem value={editdata.blog_type}>
                        {editdata.blog_type}
                      </MenuItem>
                      {itemtype.data &&
                        itemtype.data.map((val) => (
                          <MenuItem key={val._id} value={String(val._id)}>
                            {val.name}
                          </MenuItem>
                        ))}
                    </Select>
                    {blogEditErrors?.blog_type && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {blogEditErrors?.blog_type}
                      </span>
                    )}
                  </div>
                </Grid>
              </Grid>

              <div className="form-group">
                <label>
                  Description <span className={styles.astricksymb}>*</span>
                </label>
                <ReactQuill
                  theme="snow"
                  placeholder={`Enter description`}
                  className={`${styles.textarea_blog}  ${styles.customEditor}`}
                  value={editdata.description}
                  onChange={(content) =>
                    handleChangeEditblog({
                      target: { name: "description", value: content },
                    })
                  }
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                />
                {blogEditErrors?.description && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginTop: "70px",
                      position: "absolute",
                    }}
                  >
                    {blogEditErrors?.description}
                  </span>
                )}
              </div>
              <div className={styles.action}>
                <button className="action_btn" onClick={handleBlogUpdate}>
                  Add
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "giftcard" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_campaigns}>
              <div className={styles.clobtn}>
                <Link onClick={handleClose}>
                  <RiCloseCircleFill className={styles.iconru} />
                </Link>
              </div>
              <p className={styles.mpophndg} id="transition-modal-title">
                {props.heading}
              </p>

              <div className="form-group">
                <label>
                  Gift Images <span className={styles.astricksymb}>*</span>
                </label>
                <div className={styles.preview_img}>
                  {giftcard.image === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        // onClick={handleChangeImage}
                      >
                        <input
                          onChange={handleChangeImage}
                          type="file"
                          id="input"
                          hidden
                          accept="image/*"
                        />
                        <label htmlFor="input" className={styles.labelv}>
                          <FiUpload />
                          <p>Upload Your Image</p>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        onChange={handleChangeImage}
                        type="file"
                        id="input"
                        hidden
                      />
                      <label htmlFor="input">
                        <img
                          alt="img"
                          src={src}
                          className={
                            offer.image ? styles.img_view : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                </div>
                <div className={styles.file_div}></div>
                <small>
                  Upload an image of your Gift Image.Your file must be least
                  1200*1500 px , a JPG or PNG file ,width a color format of
                  rgb,and less than 50MB
                </small>
                <br />
                {giftErrors?.image && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {giftErrors?.image}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>
                  Title <span className={styles.astricksymb}>*</span>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Title"
                  onChange={handleChangeGiftcard}
                  type="text"
                  value={giftcard.name}
                  name="name"
                />
                {giftErrors?.name && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {giftErrors?.name}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>
                  Minimum Price (in $){" "}
                  <span className={styles.astricksymb}>*</span>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter discount"
                  type="number"
                  onChange={handleChangeGiftcard}
                  value={giftcard.min_price}
                  name="min_price"
                />
                {giftErrors?.min_price && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {giftErrors?.min_price}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label className={styles.textarea_label}>
                  Description <span className={styles.astricksymb}>*</span>
                </label>
                <ReactQuill
                  theme="snow"
                  placeholder={`Enter description`}
                  className={`${styles.textarea_blog}  ${styles.customEditor}`}
                  value={giftcard.description}
                  onChange={(content) =>
                    handleChangeGiftcard({
                      target: { name: "description", value: content },
                    })
                  }
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                />
                {giftErrors?.description && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginTop: "70px",
                      position: "absolute",
                    }}
                  >
                    {giftErrors?.description}
                  </span>
                )}
              </div>
              <div className={styles.action}>
                <button className="action_btn" onClick={handleGiftAdd}>
                  Add
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "offer" ? (
        // this is for offer
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_campaigns}>
              <div className={styles.clobtn}>
                <Link onClick={handleClose}>
                  <RiCloseCircleFill className={styles.iconru} />
                </Link>
              </div>
              <p className={styles.mpophndg} id="transition-modal-title">
                {props.heading}
              </p>
              <div className="form-group">
                <label>
                  {" "}
                  Images<span style={{ color: "red" }}>*</span>
                </label>
                <div className={styles.preview_img}>
                  {offer.image === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        // onClick={handleChangeImage}
                      >
                        <input
                          onChange={handleChangeImage}
                          type="file"
                          id="input"
                          accept="image/*"
                          hidden
                        />
                        <label htmlFor="input" className={styles.labelv}>
                          <FiUpload />
                          <p>Upload Your Image</p>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        onChange={handleChangeImage}
                        type="file"
                        id="input"
                        hidden
                      />
                      <label htmlFor="input">
                        <img
                          alt="img"
                          src={src}
                          // alt="img"
                          className={
                            offer.image ? styles.img_view : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                </div>
                <div className={styles.file_div}></div>
                <small>
                  Upload an image of your artwork.Your file must be least
                  1200*1500 px , a JPG or PNG file ,width a color format of
                  rgb,and less than 50MB
                </small>
                <br />
                {errors?.image && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {errors?.image}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>
                  Offer Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Offer Name"
                  onChange={handleChangeOffer}
                  type="text"
                  value={offer.name}
                  name="name"
                />

                {errors?.name && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "",
                      fontSize: "12px",
                    }}
                  >
                    {errors?.name}
                  </span>
                )}
              </div>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label>
                      Discount type <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={offer.discountType}
                      label="Discount type"
                      fullWidth
                      sx={{
                        "& .MuiSelect-select": {
                          padding: ".375rem .75rem", // Apply custom padding here
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiInputBase-root": {
                          borderRadius: "6px",
                        },
                        border: "1px solid #ced4da",
                        borderRadius: ".25rem",
                      }}
                      placeholder="select type"
                      onChange={handleChangeOffer}
                      name="discountType"
                      // name="blog_type"
                    >
                      <MenuItem value="Percentage">Percentage</MenuItem>
                      <MenuItem value="Price">Price</MenuItem>
                    </Select>
                    {errors?.discountType && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {errors?.discountType}
                      </span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <div className="form-group">
                      <label>
                        Discount value <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className="form-control"
                        placeholder="Enter discount"
                        type="number"
                        onChange={handleChangeOffer}
                        value={offer.discount}
                        name="discount"
                      />
                      {errors?.discount && (
                        <span
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {errors?.discount}
                        </span>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label>
                      Start Date <span style={{ color: "red" }}>*</span>
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disablePast
                        inputFormat="DD-MM-YYYY"
                        value={offer.start_date}
                        onChange={handleStartDate}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-input": {
                                padding: ".45rem .75rem",
                                height: "auto",
                                borderRadius: ".25rem",
                                fontSize: "16px",
                                fontWeight: "400",
                              },
                              border: "1px solid #ced4da",
                              borderRadius: ".25rem",
                              width: "100%",
                            }}
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "Enter date",
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <div>
                      {errors?.start_date && (
                        <span
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {errors?.start_date}
                        </span>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label>
                      End Date <span style={{ color: "red" }}>*</span>
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disablePast
                        inputFormat="DD-MM-YYYY"
                        value={offer.end_date}
                        onChange={handleEndDate}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-input": {
                                padding: ".45rem .75rem",
                                height: "auto",
                                borderRadius: ".25rem",
                                fontSize: "16px",
                                fontWeight: "400",
                              },
                              border: "1px solid #ced4da",
                              borderRadius: ".25rem",
                              width: "100%",
                            }}
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "Enter date",
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <div>
                      {errors?.end_date && (
                        <span
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {errors?.end_date}
                        </span>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div className="form-group">
                <label className={styles.textarea_label}>
                  Description <span style={{ color: "red" }}>*</span>
                </label>
                <ReactQuill
                  theme="snow"
                  placeholder={`Enter description`}
                  className={`${styles.textarea_blog}  ${styles.customEditor}`}
                  value={offer.description}
                  onChange={(content) =>
                    handleChangeOffer({
                      target: { name: "description", value: content },
                    })
                  }
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                />
                {errors?.description && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginTop: "70px",
                      display: "block",
                    }}
                  >
                    {errors?.description}
                  </span>
                )}
              </div>

              <div className={styles.action}>
                <button className="action_btn" onClick={handleSubmit}>
                  Add
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : (
        // this is for offer
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_campaigns}>
              <div className={styles.clobtn}>
                <Link onClick={handleClose}>
                  <RiCloseCircleFill className={styles.iconru} />
                </Link>
              </div>
              <p className={styles.mpophndg} id="transition-modal-title">
                {props.heading}
              </p>

              <div className="form-group">
                <label>
                  Images <span style={{ color: "red" }}>*</span>
                </label>
                <div className={styles.preview_img}>
                  {editOffer.image === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        // onClick={handleChangeImage}
                      >
                        <input
                          onChange={handleChangeImage}
                          type="file"
                          id="input"
                          accept="image/*"
                          hidden
                        />
                        <label htmlFor="input" className={styles.label}>
                          <img
                            alt="img"
                            src={"/img/uploadimg.png"}
                            width="46px"
                            height="43px"
                          />
                          <p> Upload your image</p>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        onChange={handleChangeImage}
                        type="file"
                        id="input"
                        hidden
                        accept="image/*"
                      />
                      <label htmlFor="input">
                        <img
                          alt="img"
                          src={editsrc.offer}
                          // alt="img"
                          className={
                            editsrc.offer
                              ? styles.img_view
                              : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                </div>
                <div className={styles.file_div}></div>
                <small>
                  Upload an image of your artwork.Your file must be least
                  1200*1500 px , a JPG or PNG file ,width a color format of
                  rgb,and less than 50MB
                </small>
                {errors?.image && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {errors?.image}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label>
                  Offer Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="form-control"
                  placeholder="Enter offername"
                  onChange={handleChangeEditOffer}
                  type="text"
                  value={editOffer.name}
                  name="name"
                />
                {errors?.name && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {errors?.name}
                  </span>
                )}
              </div>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <div className="form-group">
                      <label>
                        Discount type <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={editOffer.discountType}
                        label="Discount type"
                        fullWidth
                        sx={{
                          "& .MuiSelect-select": {
                            padding: ".375rem .75rem", // Apply custom padding here
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-root": {
                            borderRadius: "6px",
                          },
                          border: "1px solid #ced4da",
                          borderRadius: ".25rem",
                        }}
                        placeholder="select type"
                        onChange={handleChangeEditOffer}
                        name="discountType"
                        // name="blog_type"
                      >
                        <MenuItem value="Percentage">Percentage</MenuItem>
                        <MenuItem value="Price">Price</MenuItem>
                      </Select>
                      {errors?.discountType && (
                        <span
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          {errors?.discountType}
                        </span>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label>
                      Discount value <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder="Enter discount"
                      type="number"
                      onChange={handleChangeEditOffer}
                      value={editOffer.discount}
                      name="discount"
                    />
                    {errors?.discount && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {errors?.discount}
                      </span>
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label>
                      Start Date <span style={{ color: "red" }}>*</span>
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disablePast
                        inputFormat="DD-MM-YYYY"
                        value={editOffer.start_date}
                        onChange={handleEditStartDate}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-input": {
                                padding: ".45rem .75rem",
                                height: "auto",
                                borderRadius: ".25rem",
                                fontSize: "16px",
                                fontWeight: "400",
                              },
                              border: "1px solid #ced4da",
                              borderRadius: ".25rem",
                              width: "100%",
                            }}
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "Enter date",
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {errors?.start_date && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {errors?.start_date}
                      </span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className="form-group">
                    <label>
                      End Date <span style={{ color: "red" }}>*</span>
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disablePast
                        inputFormat="DD-MM-YYYY"
                        value={editOffer.end_date}
                        onChange={handleEditEndDate}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-input": {
                                padding: ".45rem .75rem",
                                height: "auto",
                                borderRadius: ".25rem",
                                fontSize: "16px",
                                fontWeight: "400",
                              },
                              border: "1px solid #ced4da",
                              borderRadius: ".25rem",
                              width: "100%",
                            }}
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "Enter date",
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {errors?.end_date && (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                        }}
                      >
                        {errors?.end_date}
                      </span>
                    )}
                  </div>
                </Grid>
              </Grid>
              <div className="form-group">
                <label>
                  Description <span style={{ color: "red" }}>*</span>
                </label>
                <ReactQuill
                  theme="snow"
                  placeholder={`Enter description`}
                  className={`${styles.textarea_blog}  ${styles.customEditor}`}
                  value={editOffer.description}
                  onChange={(content) =>
                    handleChangeEditOffer({
                      target: { name: "description", value: content },
                    })
                  }
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                />
                {errors?.description && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginTop: "45px",
                      display: "block",
                    }}
                  >
                    {errors?.description}
                  </span>
                )}
              </div>

              <div className={styles.action}>
                <button className="action_btn" onClick={handleUpdate}>
                  Add
                </button>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </div>
  );
}
