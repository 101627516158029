import React, { useEffect, useState } from "react";
import styles from "./GalleryArtistPopup.module.css";
import {
  Modal,
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  OutlinedInput,
  Chip,
  Grid,
  IconButton,
} from "@mui/material";
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from "react-router-dom";
import Tableheader from "../../../components/Pages/List/Table/Tableheader/Tableheader";
import { useDispatch, useSelector } from "react-redux";
import { SubmitMyArtistData, UpdateGalleryDetails } from "../../../State/api/galleryApi";
import api from "../../../State/api/Api";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import { fetchArtistTypes, fetchCategories } from "../../../State/api/superadminApi";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CloudUpload, Edit } from "@mui/icons-material";
const nationalities = ["American", "Canadian", "French", "Indian", "Japanese"];

const GalleryArtistPopup = () => {

  const location = useLocation()
  const artistId = location.state?.id;
  console.log("Editing artist with ID:", artistId);
  const [GalleryProfile, setGalleryProfile] = useState({})
  const handleFetchArtistProfile = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(
        `api/user/getGallery/${localStorage.getItem("user_id")}`,
        "GET"
      );
      if (result && result.status === 200) {
        const res = result.data.data;
        setGalleryProfile(res)
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };
  const [ArtistDetailsById, setArtistDetailsById] = useState(null)
  const getArtistdataById = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(
        `api/galleryartist/galleryArtist/${artistId}`,
        "GET"
      );
      if (result && result.status === 200) {
        const res = result.data.data;
        setArtistDetailsById(res?.[0])
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };



  const categorydt = useSelector((state) => state.categoryData.data);
  const artistType = useSelector((state) => state.artistTypedata.data);


  useEffect(() => {
    if (GalleryProfile?.user_id) {
      setFormData((prevData) => ({
        ...prevData,
        gallery_id: GalleryProfile.user_id, // Update gallery_id when GalleryProfile is set
      }));
    }
  }, [GalleryProfile]);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    profilePhoto: null,
    coverPhoto: null,
    aboutArtist: "",
    professionalDetails: "",
    artistType: "",
    nationality: "",
    artCategory: [],
    gallery_id: GalleryProfile?.user_id,
  });

  useEffect(() => {
    if (ArtistDetailsById) {
      setFormData({
        firstName: ArtistDetailsById?.first_name || "",
        lastName: ArtistDetailsById?.last_name || "",
        email: ArtistDetailsById?.email || "",
        mobile: ArtistDetailsById?.phone || "",
        profilePhoto: ArtistDetailsById?.latest_profile || null,
        coverPhoto: ArtistDetailsById?.backgroundImage || null,
        aboutArtist: ArtistDetailsById?.about || "",
        professionalDetails: ArtistDetailsById?.professional_details || "",
        artistType: ArtistDetailsById?.artist_type_id || "",
        nationality: ArtistDetailsById?.nationality || "",
        // artCategory: ArtistDetailsById?.categories || [],
        artCategory: ArtistDetailsById?.categories?.map(cat => cat._id) || [],
      });
    }
  }, [ArtistDetailsById]);


  console.log("formData", formData);
  console.log("ArtistDetailsById", ArtistDetailsById);

  const dispatch = useDispatch()
  const [openCategory, setOpenCategory] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate("/myartist")
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  const handleMultiSelect = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      artCategory: typeof value === "string" ? value.split(",") : value,
    });
    setOpenCategory(false); // Close the dropdown after selection
  };


  const handleDelete = (categoryId) => {
    setFormData({
      ...formData,
      artCategory: formData.artCategory.filter((id) => id !== categoryId),
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required.";
    if (!formData.lastName) newErrors.lastName = "Last name is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.mobile) newErrors.mobile = "Mobile number is required.";
    if (!formData.profilePhoto)
      newErrors.profilePhoto = "Profile photo is required.";
    if (!formData.coverPhoto) newErrors.coverPhoto = "Cover photo is required.";
    if (!formData.aboutArtist)
      newErrors.aboutArtist = "About Artist is required.";
    if (!formData.professionalDetails)
      newErrors.professionalDetails = "Professional details are required.";
    if (!formData.artistType) newErrors.artistType = "Artist type is required.";
    if (!formData.nationality)
      newErrors.nationality = "Nationality is required.";
    if (formData.artCategory.length === 0)
      newErrors.artCategory = "At least one art category is required.";

    return newErrors;
  };

  const handleQuillChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      dispatch(SubmitMyArtistData(formData, navigate))
    }
  };
  const update = () => {
    dispatch(UpdateGalleryDetails(formData, artistId, navigate))
  };

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
  ];

  console.log("ArtistDetailsById", ArtistDetailsById);


  useEffect(() => {
    if (artistId) {
      getArtistdataById()
    } else {
      console.log("id is not coming..");

    }
  }, [])


  useEffect(() => {
    handleFetchArtistProfile()
    dispatch(fetchCategories());
    dispatch(fetchArtistTypes());
  }, [])

  return (
    <div>
      <ToastContainer />
      <div>

        <Box
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 2,
            overflowY: "auto",
          }}
        >
          <div className={styles.back_img2} onClick={() => navigate("/myartist")}>
            {"<"} Back
          </div>
          <div
            style={{
              textAlign: "center",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            {artistId ? "EDIT ARTIST " : "ARTIST REGISTRATION"}
          </div>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField fullWidth label="First Name *" name="firstName" onChange={handleChange} margin="dense" error={!!errors.firstName} helperText={errors.firstName} value={formData.firstName} />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Last Name *" name="lastName" onChange={handleChange} margin="dense" error={!!errors.lastName} helperText={errors.lastName} value={formData.lastName} />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Email *" name="email" onChange={handleChange} margin="dense" error={!!errors.email} helperText={errors.email} value={formData.email} />
              </Grid>
              <Grid item xs={6}>
                <TextField fullWidth label="Mobile *" name="mobile" onChange={handleChange} margin="dense" error={!!errors.mobile} helperText={errors.mobile} value={formData.mobile} />
              </Grid>
              <Grid item xs={6}>
                <label>Profile Photo *</label>
                {formData.profilePhoto ? (
                  <div>
                    <img src={typeof formData.profilePhoto === "string" ? formData.profilePhoto : URL.createObjectURL(formData.profilePhoto)} alt="Profile" style={{ width: 200, height: 150, borderRadius: "5px" }} />
                    <IconButton component="label">
                      <Edit />
                      <input type="file" name="profilePhoto" onChange={handleFileChange} accept="image/*" hidden />
                    </IconButton>
                  </div>
                ) : (
                  <IconButton component="label">
                    <CloudUpload />
                    <input type="file" name="profilePhoto" onChange={handleFileChange} accept="image/*" hidden />
                  </IconButton>
                )}
                {errors.profilePhoto && <p style={{ color: "red" }}>{errors.profilePhoto}</p>}
              </Grid>
              <Grid item xs={6}>
                <label>Cover Photo *</label>
                {formData.coverPhoto ? (
                  <div>
                    <img src={typeof formData.coverPhoto === "string" ? formData.coverPhoto : URL.createObjectURL(formData.coverPhoto)} alt="Cover" style={{ width: "100%", height: 150, objectFit: "cover", borderRadius: "8px" }} />
                    <IconButton component="label">
                      <Edit />
                      <input type="file" name="coverPhoto" onChange={handleFileChange} accept="image/*" hidden />
                    </IconButton>
                  </div>
                ) : (
                  <IconButton component="label">
                    <CloudUpload />
                    <input type="file" name="coverPhoto" onChange={handleFileChange} accept="image/*" hidden />
                  </IconButton>
                )}
                {errors.coverPhoto && <p style={{ color: "red" }}>{errors.coverPhoto}</p>}
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="dense">
                  <InputLabel>Artist Type *</InputLabel>
                  <Select name="artistType" value={formData.artistType} onChange={handleChange} error={!!errors.artistType}>
                    {artistType?.map((type) => (
                      <MenuItem key={type._id} value={type._id}>{type.name}</MenuItem>
                    ))}
                  </Select>
                  {errors.artistType && <p style={{ color: "red" }}>{errors.artistType}</p>}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="dense">
                  <InputLabel>Nationality *</InputLabel>
                  <Select name="nationality" value={formData.nationality} onChange={handleChange} error={!!errors.nationality}>
                    {nationalities?.map((nation) => (
                      <MenuItem key={nation} value={nation}>{nation}</MenuItem>
                    ))}
                  </Select>
                  {errors.nationality && <p style={{ color: "red" }}>{errors.nationality}</p>}
                </FormControl>
              </Grid>
            </Grid>
            <div className="form-group">
              <label>About Artist</label>
              <ReactQuill
                style={{
                  height: "120px",
                  textAlign: "left",
                  marginBottom: "48px",
                }}
                className="reactQuilStle"
                value={formData.aboutArtist}
                onChange={(value) => handleQuillChange(value, 'aboutArtist')}
                theme="snow"
                modules={modules}
                formats={formats}
              />
            </div>
            <div className="form-group">
              <label>Professional  Details</label>
              <ReactQuill
                style={{
                  height: "120px",
                  textAlign: "left",
                  marginBottom: "48px",
                }}
                className="reactQuilStle"
                value={formData.professionalDetails}
                onChange={(value) => handleQuillChange(value, 'professionalDetails')}
                theme="snow"
                modules={modules}
                formats={formats}
              />

            </div>

            <FormControl fullWidth margin="dense">
              <InputLabel>Art Category</InputLabel>
              <Select
                multiple
                value={formData.artCategory}
                onChange={handleMultiSelect}
                input={<OutlinedInput label="Art Category" />}
                open={openCategory}
                onOpen={() => setOpenCategory(true)}
                onClose={() => setOpenCategory(false)}
                error={!!errors.artCategory}
              >
                {categorydt?.map((category) => (
                  <MenuItem key={category} value={category?._id}>
                    {category?.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.artCategory && (
                <p style={{ color: "red" }}>{errors.artCategory}</p>
              )}
            </FormControl>

            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 1 }}>
              {formData.artCategory.map((categoryId) => {
                // Find the category object based on the _id
                const category = categorydt?.find((cat) => cat._id === categoryId);
                return (
                  <Chip
                    key={categoryId}
                    label={category ? category.name : "Unknown"} // Show name instead of _id
                    onDelete={() => handleDelete(categoryId)}
                  />
                );
              })}
            </Box>

          </div>
          {artistId ? <Button
            fullWidth
            variant="contained"
            onClick={update}
            sx={{ mt: 2 }}
          >
            Update
          </Button> : <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            sx={{ mt: 2 }}
          >
            Submit
          </Button>}

        </Box>

      </div>
    </div>
  );
};

export default GalleryArtistPopup;