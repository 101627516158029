import React from "react"
import styles from "./TwoColTable.module.css"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import dayjs from "dayjs"
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material"
import Popup from "../../Popupdelete/Popup"
import Tableheader from "../Tableheader/Tableheader"
import Moreoption from "../Moreoption/Moreoption"
import { useSelector } from "react-redux"
import CircularLoader from "../../../../../common/loader/Loader"
import { useLocation, useNavigate } from "react-router-dom"
import homeImage from "../../../../../constant"
import { FiTrash2, FiEdit3 } from "react-icons/fi";

export default function TwoColTable(props) {
  // console.log(props);
  const navigate = useNavigate()
  const location = useLocation()
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const threedots = Boolean(anchorEl)
  const loader = useSelector((state) => state.loader)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handlePopup = (e) => {
    props.setOpen(true)
    props.setPoptype("addfeature")
  }
  const handleNavitgateDetailPage = (id) => {
    const path = location.pathname
    if (path.includes("/category")) {
      navigate(`/category/${id}`)
    } else if (path.includes("/print")) {
      navigate(`/print/${id}`)
    }
  }
  return (
    <>
      {open ? <Popup open={open} setOpen={setOpen} /> : ""}
      <div className={styles.shadow}>
        {/* <Tableheader /> */}
        {/* <Moreoption  artistOptions={artistOptions} /> */}
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "#F6F6F6",
            color: "black",
            boxShadow: "none",
          }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
                {props.head.map((column, i) => (
                  <TableCell
                    align="left"
                    sx={{
                      whiteSpace: "nowrap",
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      textTransform:"capitalize",
                      fontSize: "15px",
                      height: "21px",
                      color: "#1C1C1C",
                    }}
                    key={i}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loader.loader ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}>
                      {" "}
                      <CircularLoader size={60} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {props.data && props.data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Box className={styles.loader_box}> No Data Found</Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  props.data &&
                  props.data.map((row, i) => (
                    <TableRow style={{ color: "#636365" }} key={i}>
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                          cursor: "pointer",
                        }}
                        className={styles.table_column}
                        align="left"
                        onClick={() => handleNavitgateDetailPage(row._id)}
                      >
                        {row._id}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                          cursor: "pointer",
                        }}
                        className={styles.table_column}
                        align="left"
                        onClick={() => handleNavitgateDetailPage(row._id)}
                      >
                        {dayjs(row.createdAt).format("DD-MM-YYYY").toString()}
                      </TableCell>

                      <TableCell
                        sx={{
                          // width: '30%',
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <div className={styles.career_lastcol}>
                          <p>{row.name}</p>
                          <div className={styles.action}>
                            <IconButton onClick={() => props.handleEditdata(row)}><FiEdit3 className={styles.editicon} /></IconButton>
                            <IconButton onClick={() => props.handleDelete(row._id)}><FiTrash2 className={styles.deleicon}/></IconButton>
                            
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
    </>
  )
}
