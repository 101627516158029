import React, { useState } from "react";
import styles from "./Recretepopup.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Button, TextField , Grid} from "@mui/material";
import homeImage from "../../../../constant";
import dateFormat from "../../../../utils/dateFormat";
import serverimage from "../../../../serverConstant";

export default function Recretepopup(props) {
 console.log(props,'props')
  const [amount, setAmount] = useState();
  const handleClose = () => props.setOpen(false);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={props.open}>
          <Box className={styles.modal_del}>
          <Grid
                                container
                                spacing={2}
                              >
                                <Grid item xs={12} sm={4} md={4}>
                                <div className={styles.img_div}>
                <img alt='img' src={ props.acceptPopupData?.item_id?.image} className="img-fluid" />
              </div>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8}>
                          <div>
                            <p className={styles.odlist}>Buyer Name : <span>{props.acceptPopupData.userName}</span></p>
                            <p className={styles.odlist}>Sku_Id : <span>{props.acceptPopupData?.item_id?.sku_id}</span></p>
                            <p className={styles.odlist}>Art Name : <span>{props.acceptPopupData?.item_id?.name}</span></p>
                            <p className={styles.odlist}>Art Type : <span>{props.acceptPopupData.item_id?.sale ? "Sale" : "Print"}</span></p>
                            <p className={styles.odlist}>Requested Date : <span>{dateFormat(props.acceptPopupData.createdAt)}</span></p>
                          </div>
                                </Grid>
                                </Grid>
           
            <div className={styles.description_div}>
            <p className={styles.odlist}>Remark For Recreate <br/> <span>{props.acceptPopupData.message}</span></p>
            
            </div>
            {props.activetab === "galleryrecrete" || "buyeroffer" ? "" : (
              <div className="form-group">
                <label>Quatation</label>
                <input
                className="form-control"
                  fullWidth
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  variant="outlined"
                  placeholder="Enter amount"
                />
              </div>
            )}
            <div className={styles.btn_div}>
              {props.activetab === "galleryrecrete" ? (
                <>
                  <button className="action_btn"
                    onClick={() =>
                    {
                      props.handleAcceptRejectRecreate(props.acceptPopupData._id, { is_accepted: "Approved" })
                      handleClose()
                    }
                    }
                    >
                    Accept
                  </button>
                  <button className="action_libtn"
                    onClick={() =>
                   {
                    props.handleAcceptRejectRecreate(props.acceptPopupData._id, { is_accepted: "Rejected" })
                    handleClose()
                   }
                    }>
                    Decline
                  </button>
                </>
              ) : props.activetab === 'qouterequest' ? (
                <>
                  <button className="action_btn"
                    onClick={() =>
                     {
                      
                      props.handleAcceptRejectQuate(props.acceptPopupData._id, { is_accepted: "Approved" })
                      handleClose()
                     }
                    }>
                    Accept
                  </button>
                  <button className="action_libtn"
                    onClick={() =>
                    {
                      props.handleAcceptRejectQuate(props.acceptPopupData._id, { is_accepted: "Rejected" })
                      handleClose()
                    }
                    }>
                    Decline
                  </button>
                </>
              )
                : (
                  <>
                    <button className="action_btn"
                      onClick={() =>
                     {
                      props.handleAcceptRejectOffer(props.acceptPopupData._id, { is_accepted: "Approved" })
                      handleClose()
                     }
                      }>
                      Accept
                    </button>
                    <button className="action_libtn"
                      onClick={() =>
                       {
                        props.handleAcceptRejectOffer(props.acceptPopupData._id, { is_accepted: "Rejected" })
                        handleClose()
                       }
                      }>
                      Decline
                    </button>
                  </>
                )
              }

            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
