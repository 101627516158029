import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import {
  Box,
  Button,
} from "@mui/material";
// import Divider from '@mui/material/Divider';
import React from "react";
import styles from "./Fivecolimg.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import profileSlice, { addData } from '../../../../../State/Features/Artist/profileSlice';
import serverimage from "../../../../../../serverConstant";
import homeImage from "../../../../../../constant";
import CircularLoader from "../../../../../../common/loader/Loader";
import dateFormat from "../../../../../../utils/dateFormat";
import TruncatedTooltipText from "../../../../../../utils/truncateTooltip";

export default function TableForAdminOffer(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // const dispatch = useDispatch();
  // const data = useSelector((state)=>state.artistdata);

  const loader = useSelector((state) => state.loader);

  const threedots = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePopup = (e) => {
    props.setOpen(true);
    props.setPoptype("addfeature");
  };
  const handleProfilepage = (id, name, row) => {
    // props.setPage('profile')
    navigate(`/user/newestpaintings/${id}`);
    props.setPendingProfileData &&
      props.setPendingProfileData({ artist_name: name });
    // dispatch(addData(row));
  };
  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  align="left"
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "15px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props.data &&
                props.data.map((row, i) => (
                  <TableRow
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate("/user/offers");
                    }}
                    value={row.artist_name}
                    style={{ color: "#636365", cursor: "pointer" }}
                    key={i}
                  >
                    {/* <Link to={`/artist/${row.id}`}> */}
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {/* <img alt="img" src={`/img/${row.src}`} height="42px" width="60px" /> */}
                      {row.item_image && (
                        <img
                          // onClick={() => handleClickImg()}
                          alt="img"
                          src={` ${row.item_image}`}
                          height="42px"
                          width="60px"
                        />
                      )}
                      {/* this is for gallery >>> admin offer tab */}
                      {props.activetab === "galleryadmin" ? (
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate("/user/offers");
                          }}
                          alt="img"
                          src={` ${row.offer_image}`}
                          height="42px"
                          width="60px"
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                      value={row.buyer_name}
                    >
                      <TruncatedTooltipText
                        text={row.name}
                        maxLength={20}
                        href={{}}
                        onClick={() => { }}
                        style={{ color: "inherit", textDecoration: "none" }}
                      />
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                      value={row.buyer_name}
                    >
                      {props.activetab === "galleryadmin" ? (
                        <div dangerouslySetInnerHTML={{ __html: row?.description || '' }} />
                      ) : null}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                      value={row.buyer_name}
                    >
                      {props.activetab === "galleryadmin"
                        ? `${row?.discountType}`
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                      value={row.buyer_name}
                    >
                      {props.activetab === "galleryadmin"
                        ? `${row?.discountType === "Percentage"
                          ? row.discount + "%"
                          : row.discount + "$"
                        }`
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.rating}
                      {row.item_rating}
                      {props.activetab === "galleryadmin"
                        ? dateFormat(row.start_date)
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {props.activetab === "galleryadmin"
                        ? ""
                        : row.description}
                      {row.star_rating}
                      {props.activetab === "galleryadmin"
                        ? dateFormat(row.end_date)
                        : ""}
                    </TableCell>
                    {props.activetab === "RatingReview" && (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.item_review}
                      </TableCell>
                    )}
                    {props.activetab === "RatingReview" && (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {dayjs(row.createdAt).format("DD-MM-YYYY").toString()}
                      </TableCell>
                    )}

                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.column}
                      align="left"
                    >
                      <Button
                        variant="contained"
                        disabled={(() => {
                          const today = new Date();
                          today.setHours(0, 0, 0, 0); // midnight today

                          const endDate = new Date(row?.end_date);
                          endDate.setHours(0, 0, 0, 0); // midnight of end date

                          return endDate <= today;
                        })()}
                        onClick={(e) => {
                          e.stopPropagation();
                          props.handleOpen();
                          props.setOfferId(row._id);
                        }}
                      >
                        Interested
                      </Button>
                    </TableCell>

                    {/* </Link> */}
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}
