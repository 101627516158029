import {
  Button,
  Fade,
  Link,
  Modal,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import homeImage from "../../../../constant";
import React, { useEffect, useState } from "react";
import styles from "./Fillexshibition.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Pagination, PaginationItem } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { fetchAllItemData } from "../../../../State/api/userApi";
import { useDispatch, useSelector } from "react-redux";
import { fetchArtsData } from "../../../../State/api/superadminApi";
import { PropaneTwoTone } from "@mui/icons-material";
import serverimage from "../../../../serverConstant";
import { RiCloseCircleFill } from "react-icons/ri";
import { FiPlus } from "react-icons/fi";

const datecolor = "#636365";
export default function Fillexshibition(props) {
  console.log("exshivisonpopup", props);

  const [selectOpen, setSelectOpen] = React.useState(false);
  const dispatch = useDispatch();
  const allitems = useSelector((state) => state.artsdata.data);

  // const handleChangeAddData = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   props.setAddArtinExhibition({ ...props.addArtinExhibition, [name]: value });
  //   setSelectOpen(false);
  //   console.log(name, value);
  // };


  const handleChangeAddData = (e, index) => {
    const { name, value } = e.target;

    // Clone the existing array
    const updatedData = [...props.addArtinExhibition];

    // Update the specific index
    updatedData[index] = { ...updatedData[index], [name]: value };

    // Set state only if there's an actual change to prevent unnecessary re-renders
    if (props.addArtinExhibition[index][name] !== value) {
      props.setAddArtinExhibition(updatedData); // ✅ Corrected state update
    }
    setTimeout(() => {
      setSelectOpen(false);
    }, 0);
  };
  const [ArrayError, setArrayError] = useState({});

  const handleAddAnotherPainting = () => {
    const lastPainting = props.addArtinExhibition?.slice(-1)[0]; // Get the last added painting
    let errors = {};

    // Validation checks
    if (!lastPainting?.item_id) {
      errors.item_id = "Please select an art";
    }
    if (!lastPainting?.price) {
      errors.price = "Please enter a price";
    }
if(props.text ==="Exhibition"){
  if (!lastPainting?.description) {
    errors.description = "Please enter a description";
  }
}else{
  if (!lastPainting?.description) {
    errors.item_description = "Please enter a description";
  }
}
  

    // If there are validation errors, update state and stop execution
    if (Object.keys(errors).length > 0) {
      setArrayError(errors);
      return;
    }

    // If no errors, clear previous errors
    setArrayError({});

    // Prepare the new painting object based on the type
    let newPainting;
    if (props.text === "Exhibition") {
      newPainting = {
        price: "",
        description: "",
        exhibition_id: props.ExshibitionId,
        item_id: "",
        type: props.text,
      };
    } else {
      newPainting = {
        item_price: "",
        item_description: "",
        auction_id: props.ExshibitionId,
        item_id: "",
        type: props.text,
      };
    }

    // Update the state with the new painting
    props.setAddArtinExhibition([
      ...props.addArtinExhibition, // Spread existing array
      newPainting, // Add new painting entry
    ]);
  };

  const handleRemovePainting = (index) => {
    const updatedData = props.addArtinExhibition.filter((_, i) => i !== index);

    props.setAddArtinExhibition(updatedData); // ✅ Correct way to update state with an array
  };
  // const handleSelectPainting = (e) => {
  //     const vl = e;
  //     const a = allitems && allitems.data.filter(v => props.addArtinExhibition.item_id.includes(v._id)).map(c => (c.name))
  //     console.log(a, allitems.data)
  //     // const a = vl.forEach((element) => {
  //     var item_id = [...props.addArtinExhibition.item_id];
  //     const dt = props.addArtinExhibition.item_id.map((val) => val);
  //     const index = dt.indexOf(vl);
  //     if (index === -1) {
  //         item_id.push(vl);
  //         props.setAddArtinExhibition({ ...props.addArtinExhibition, item_id: item_id });
  //     } else {
  //         // item_id.slice(index, 1);
  //         item_id.splice(index, 1);
  //         props.setAddArtinExhibition({ ...props.addArtinExhibition, item_id: item_id });
  //     }
  //     // });
  // };

  const handleClose = () => {
    props.setOpen(false);
  };
  useEffect(() => {
    dispatch(fetchArtsData({ is_accepted: "Approved", offset: 1 }));
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust how many items you want per page

  // Function to handle page changes
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    setSelectOpen(true);
  };

  // Slice the data based on current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = allitems && allitems.data?.slice(startIndex, endIndex);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className={styles.modal_uploaded}>
            <div className={styles.clobtn}>
              <Link onClick={handleClose}>
                <RiCloseCircleFill className={styles.iconru} />
              </Link>
            </div>
            <p className={styles.mpophndg} id="transition-modal-title">
              Fill {props.text} Details
            </p>

            <div className={styles.mains}>
              <p className={styles.mpophndg}>
                {sessionStorage.getItem("exhibition_name") || ""}
              </p>

              {props?.addArtinExhibition?.map((art, index) => {
                return (
                  <>
                    {props?.addArtinExhibition?.length > 1 && (
                      <button
                        style={{
                          background: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                          float: "right",
                        }}
                        onClick={() => handleRemovePainting(index)}
                      >
                        ✖
                      </button>
                    )}

                    <div className="form-group">
                      <label>Painting *</label>
                      <FormControl fullWidth>
                        <Select
                          open={selectOpen}
                          fullWidth
                          value={art.item_id} // Ensure value is bound to selected item_id
                          onClose={() => setSelectOpen(false)} // Keep this to close the dropdown when the user manually clicks outside
                          onOpen={() => setSelectOpen(true)}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          renderValue={
                            art.item_id.length !== 0
                              ? undefined
                              : () => <span>Select Painting</span>
                          }
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            color: "#636365",
                            lineHeight: "21px",
                            backgroundColor: "#f3f3f3",
                            border: "none",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(228, 219, 233, 0.25)",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(228, 219, 233, 0.25)",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "rgba(228, 219, 233, 0.25)",
                            },
                          }}
                        >
                          {currentItems?.map((val) => (
                            <MenuItem
                              value={val._id}
                              key={val._id}
                              className={styles.menu_item}
                            >
                              <div style={{ width: "100%", cursor: "default" }}>
                                <div className={styles.details_div}>
                                  <div className={styles.img_detail_div}>
                                    <div className={styles.details_img_div}>
                                      <img
                                        src={val.image}
                                        width="120px"
                                        height="85px"
                                        alt="img"
                                      />
                                    </div>
                                    <div className={styles.detail}>
                                      <h6 className={styles.title} title={val.name}>
                                        {val.name.length > 15
                                          ? val.name.substring(0, 15) + "..."
                                          : val.name}
                                      </h6>
                                      <p className={styles.content} title={val.artist_name}>
                                        {val.artist_name}
                                      </p>
                                    </div>
                                  </div>
                                  <hr className={styles.hrverticle} />
                                  <div className={styles.detail}>
                                    <h6 className={styles.title}>Views</h6>
                                    <p className={styles.content}>{val.views}</p>
                                  </div>
                                  <hr className={styles.hrverticle} />
                                  <div className={styles.detail}>
                                    <h6 className={styles.title}>Likes</h6>
                                    <p className={styles.content}>{val.likes}</p>
                                  </div>
                                  <hr className={styles.hrverticle} />
                                  <div className={styles.button_detail_div}>
                                    <div className={styles.detail}>
                                      <h6 className={styles.title}>Stock in Qty.</h6>
                                      <p className={styles.content}>
                                        {val.sale?.qty}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      val.added
                                        ? styles.add_btn_div
                                        : styles.add_btn_div
                                    }
                                  >
                                    {props.addArtinExhibition.item_id === val._id ? (
                                      ""
                                    ) : val?.sale?.qty > 0 ? (
                                      <button
                                        variant="contained"
                                        name="item_id"
                                        className="action_btn"
                                        onClick={() => {
                                          const newPrints = [...props.addArtinExhibition]; // ✅ Clone the array
                                          newPrints[index].item_id = val._id; // ✅ Update the specific index
                                          props.setAddArtinExhibition(newPrints); // ✅ Set the updated array
                                        }}
                                      >
                                        Add
                                      </button>
                                    ) : (
                                      <Button
                                        variant="contained"
                                        disabled={
                                          !(
                                            props.addArtinExhibition.item_id !==
                                            val._id && val?.sale?.qty > 0
                                          )
                                        }
                                        name="item_id"
                                        sx={{
                                          width: "80px",
                                          height: "44px",
                                          borderRadius: "6px",
                                        }}
                                      >
                                        Add
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </MenuItem>
                          ))}
                          <Box className={styles.pagination_box} sx={{}}>
                            <Pagination
                              count={Math.ceil(allitems?.data?.length / itemsPerPage)} // Total pages
                              page={currentPage}
                              onChange={handlePageChange}
                              variant="outlined"
                              shape="rounded"
                              renderItem={(item) => (
                                <PaginationItem
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: "black", // Set the selected background to black
                                      color: "white", // Ensure the text color is visible
                                      "&:hover": {
                                        backgroundColor: "black", // Maintain black on hover
                                      },
                                    },
                                  }}
                                  slots={{
                                    previous: ArrowBackIcon,
                                    next: ArrowForwardIcon,
                                  }}
                                  {...item}
                                />
                              )}
                            />
                          </Box>
                        </Select>
                      </FormControl>

                      {props.errors[index]?.item_id && (
                        <div
                          style={{
                            color: "red",
                            fontWeight: "500",
                            fontSize: "14px",
                            marginTop: "5px",
                          }}
                        >
                          {props.errors[index].item_id}
                        </div>
                      )}
                      {ArrayError.item_id && (
                        <div
                          style={{
                            color: "red",
                            fontWeight: "500",
                            fontSize: "14px",
                            marginTop: "5px",
                          }}
                        >
                          {ArrayError.item_id}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Base Price *</label>
                      <input
                        className="form-control"
                        onChange={(e) => handleChangeAddData(e, index)}
                        name={props.text === "Auction" ? "item_price" : "price"}
                        type="number"
                        placeholder="Enter Price"
                      />
                      {props.errors[index]?.price && (
                        <div
                          style={{
                            color: "red",
                            fontWeight: "500",
                            fontSize: "14px",
                            marginTop: "5px",
                          }}
                        >
                          {props.errors[index].price}
                        </div>
                      )}
                      {ArrayError.price && (
                        <div
                          style={{
                            color: "red",
                            fontWeight: "500",
                            fontSize: "14px",
                            marginTop: "5px",
                          }}
                        >
                          {ArrayError.price}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label>Description *</label>
                      <textarea
                        maxRows={5}
                        minRows={5}
                        onChange={(e) => handleChangeAddData(e, index)}
                        name={props.text === "Auction" ? "item_description" : "description"}
                        className="form-control"
                        fullWidth
                        placeholder="Enter your description"
                      />

                      {/* Dynamic error message based on type */}
                      {props.errors[index]?.[props.text === "Auction" ? "item_description" : "description"] && (
                        <div
                          style={{
                            color: "red",
                            fontWeight: "500",
                            fontSize: "14px",
                            marginTop: "5px",
                          }}
                        >
                          {props.errors[index][props.text === "Auction" ? "item_description" : "description"]}
                        </div>
                      )}

                      {/* Fix for ArrayError dynamic key */}
                      {ArrayError?.[props.text === "Auction" ? "item_description" : "description"] && (
                        <div
                          style={{
                            color: "red",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          {ArrayError[props.text === "Auction" ? "item_description" : "description"]}
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <Button
                        s={{
                          display: "flex",
                          justiyContent: "center",
                          alignItems: "center",
                          columnGap: "10px",

                        }}
                        onClick={(e) => handleAddAnotherPainting(e, index)}
                      >
                        <FiPlus />
                        Add Another Painting
                      </Button>
                    </div>
                  </>
                );
              })}
              <div className={styles.btn_div}>
                <button className="action_btn" onClick={props.handleAdd}>
                  Save
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
