import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const enquiryReplyByIdSlice = createSlice({
    name: "enquiryreplybyiddata",
    initialState,
    reducers: {
        setenquiryreplybyiddata: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setenquiryreplybyiddata } = enquiryReplyByIdSlice.actions;

export const enquiryreplybyiddata = (state) => state.enquiryreplybyiddata;

export default enquiryReplyByIdSlice.reducer;
