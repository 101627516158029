import { Box, Button } from "@mui/material";
import React from "react";
import styles from "./Commoncard.module.css";
import homeImage from "../../../../../constant";
import { useNavigate } from "react-router-dom";
import { RiPaletteLine } from "react-icons/ri";
export default function CommoncardArtist({setArtistPop, setOpen}) {
  const navigate = useNavigate();
  return (
    <>
      {/* {
            props.type === 'web' ?
                // <Box sx={{ mt: { xs: '-20px', sm: '-64px' } }} className={props.data.length === props.i + 1 ? styles.cancel_imgweb : styles.cancel_img_hide_web} >
                //     {props.data.length === props.i + 1 ?
                //         <img alt='img' onClick={() => props.setOpen(false)} src={ '/img/cancel_cross.png'} height='20px' width='20px' />
                //         : ''}
                // </Box> 
                "" :
                <div className={props.data.length === props.i + 1 ? styles.cancel_img : styles.cancel_img_hide} >
                    {props.data.length === props.i + 1 ?
                        <img alt='img' onClick={() => props.setOpen(false)} src={ '/img/cancel_cross.png'} height='20px' width='20px' />
                        : ''}
                </div>
        } */}
<div className={styles.icdvn}><RiPaletteLine className={styles.icvn}/></div>
<div className={styles.signboc}>
          <div className={styles.tconvr}>
          <h3 className={styles.sucrhndg}>Artist / Gallery</h3>
            <p className={styles.orpra}>Join our art marketplace to sell your art, connect with art collectors, and join auctions & exhibitions worldwide.</p>
            
          </div>
           <button  className={`apply_btn ${styles.ml_auto}`} onClick={() => {
            setArtistPop(true);
            setOpen(false)
          }}>Apply Now</button>
          </div>
          
    
    </>
  );
}
