import validator from "validator"
import isEmpty from "./isEmpty"

export const validateAdminSubMenu = (menu) => {
  let submenuErrors = {}

  if (validator.isEmpty(menu.name)) {
    submenuErrors.name = "subMenu is required"
  }

  // if (validator.isEmpty(menu.heading_id) || menu.heading_id==='Select Heading') {
  //   submenuErrors.heading_id = "select the Heading"
  // }

  // if (validator.isEmpty(menu.link)) {
  //   submenuErrors.link = "Link is required"
  // } else if (!validator.isURL(menu.link)) {
  //   submenuErrors.link = "Please enter a valid URL"
  // }

    if (validator.isEmpty(menu.link)) {
      submenuErrors.link = "Link is required";
       } else {
         let formattedLink = menu.link.startsWith("http://") || menu.link.startsWith("https://")
           ? menu.link
           : `http://${menu.link}`;
     
        //  const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]*[a-zA-Z]+[a-zA-Z0-9-]*\.(com|org|net|in|edu|gov|co|io|info|biz|me)(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;
     
         if (!validator.isURL(formattedLink, { require_protocol: true })) {
          submenuErrors.link = "Please enter a valid URL starting with http:// or https://";
            }
       }
  

  return {
    submenuErrors,
    isValid: isEmpty(submenuErrors),
  }
}
