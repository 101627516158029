import axios from 'axios';

const currencyRates = {
    USD: 1, // Base currency
    GBP: 0.7967, // Pound Sterling rate against USD
    AED: 3.6725 // Dirham rate against USD (example value, replace with actual rate)
    // Add more currency rates as needed
};

let userCurrency = "USD"; // Default to USD in case of error or if not set

const getCurrency = async (ip) => {
    try {
        const currencyResponse = await axios.get(`https://ipapi.co/${ip}/currency/`);
        const currencyData = currencyResponse.data;
        userCurrency = currencyData;
        console.log(userCurrency, 'userCurrency');
    } catch (error) {
        console.error("Error fetching currency data:", error);
        userCurrency = "USD"; // Default to USD in case of error
    }
};

const getIpAddress = async () => {
    try {
        const ipResponse = await axios.get("https://api.ipify.org/?format=json");
        return ipResponse.data.ip;
    } catch (error) {
        console.error("Error fetching IP address:", error);
        return null;
    }
};

const formatCurrency = (amount) => {
    let convertedAmount;
    if (userCurrency === 'USD') {
        convertedAmount = amount; // No conversion needed if currency is USD
    } else {
        const rateAgainstUSD = currencyRates[userCurrency];
        if (rateAgainstUSD) {
            convertedAmount = amount * rateAgainstUSD; // Convert amount to user's currency
        } else {
            // console.warn(`No rate found for currency: ${userCurrency}. Defaulting to USD.`);
            convertedAmount = amount; // Default to USD if currency rate not found
        }
    }
    
    // Format the amount to remove trailing zeros
    const formattedAmount = Number(convertedAmount.toFixed(2)).toLocaleString();

    return userCurrency === 'AED' ? `${userCurrency} ${formattedAmount}` : `${formattedAmount}`;
};

const formatNewCurrency = (amount) => {
    if (userCurrency === "GBP") {
        return "£" + formatCurrency(amount);
    } else if (userCurrency === "AED") {
        return formatCurrency(amount);
    } else {
        return "$" + formatCurrency(amount);
    }
};

const initializeCurrencyFormatter = async () => {
    const ip = await getIpAddress();
    if (ip) {
        await getCurrency(ip);
    }
};

// Initialize currency formatter
initializeCurrencyFormatter();

export { formatNewCurrency, initializeCurrencyFormatter, userCurrency };
