import { configureStore } from "@reduxjs/toolkit"
import paginationSlice from "../Features/common/paginationSlice"
import tableheaderbuttonSlice from "../Features/common/tableheaderbuttonSlice"
// import registrationApi from "../api/commonapi";
import subscriptionData from "../Features/superadmin/subscriptionSlice"
import buyerData from "../Features/superadmin/buyerSlice"
import buyerSlice from "../Features/superadmin/buyerSlice"
import usertypeSlice from "../Features/common/usertypeSlice"
import loaderSlice from "../Features/common/loaderSlice"
import userSlice from "../Features/common/userSlice"
import categorySlice from "../Features/superadmin/categorySlice"
import menuSlice from "../Features/superadmin/menuSlice"
import subMenuSlice from "../Features/superadmin/subMenuSlice"
import artisttypeSlice from "../Features/superadmin/artisttypeSlice"
import curatorEarningSlice from "../Features/superadmin/curatorEarningSlice"
import mainMenuSubMenuSlice from "../Features/superadmin/mainMenuSubMenuSlice"
import getAllMenuSubMenuSlice from "../Features/superadmin/getallmenusubmenuSlice"
import HeadingByMenuSlice from "../Features/superadmin/headingByMenuSlice"
import ArtistSlice from "../Features/superadmin/ArtistSlice"
import CuratorGallerySlice from "../Features/superadmin/CuratorGallerySlice"
import NotificationSlice from "../Features/superadmin/notificationsSlice"


import AffiliatEarningSlice from "../Features/superadmin/affiliateearningSlice"
import curatorSlice from "../Features/superadmin/curatorSlice"
import subscriptionfeatureSlice from "../Features/superadmin/subscriptionfeatureSlice"
import careerdetailsSlice from "../Features/superadmin/careerdetailsSlice"
import subscriptionArtistSlice from "../Features/superadmin/subscriptionArtistSlice"
import artistprofileSlice from "../Features/superadmin/artistprofileSlice"
import galleryprofileSlice from '../Features/superadmin/galleryprofileSlice'
import userenquirySlice from '../Features/superadmin/userenquirySlice'
import enquiryReplyByIdSlice from '../Features/superadmin/enquiryReplyByIdSlice'
import EnquiryByEnquiryIdSlice from '../Features/superadmin/EnquiryByEnquiryIdSlice'
import artsSlice from "../Features/superadmin/artsSlice"
import affiliateSlice from "../Features/superadmin/affiliateSlice"
import exshibitionSlice from "../Features/superadmin/exshibitionSlice"
import addMarketingSlice from "../Features/superadmin/addMarketingDataSlice"
import exhibitionAdminSlice from "../Features/superadmin/exhibitionAdminSlice"
import compaingnSlice from "../Features/superadmin/compaingnSlice"
import careerSlice from "../Features/superadmin/careerSlice"
import printPricingSlice from "../Features/superadmin/printPricingSlice"
import enquirySlice from "../Features/superadmin/enquirySlice"
import testimonialSlice, {
  testimonial,
} from "../Features/superadmin/testimonialSlice"
import blogSlice from "../Features/superadmin/blogSlice"
import blogSliceSlug from "../Features/superadmin/blogSliceSlug"
import usercareerSlice from "../Features/user/usercareerSlice"
import itemtypeSlice from "../Features/superadmin/itemtypeSlice"
import pressreleaseSlice from "../Features/superadmin/pressreleaseSlice"
import faqlistSlice from "../Features/superadmin/faqlistSlice"
import affiliateprofileSlice from "../Features/superadmin/affiliateprofileSlice"
import getAllaffiliateLinkSlice from "../Features/superadmin/getAllaffiliateLinkSlice"
import MenuBySubMenuSlice from "../Features/superadmin/submenuByMenuidSlice"
import artsproflieSlice from "../Features/superadmin/artsproflieSlice"
import offerSlice from "../Features/superadmin/offerSlice"
import giftcardSlice from "../Features/superadmin/giftcardSlice"
import herosectionSlice from "../Features/superadmin/herosectionSlice"
import policySlice from "../Features/superadmin/policySlice"
import disputeSlice from "../Features/superadmin/disputeSlice"
import gallerySlice from "../Features/superadmin/gallerySlice"
import permissionSlice from "../Features/superadmin/permissionSlice"
import auctionSlice from "../Features/superadmin/auctionSlice"
import subadminSlice from "../Features/superadmin/subadminSlice"
import allcategorySlice from "../Features/user/allcategorySlice"
import userpolicySlice from "../Features/user/policySlice"
import userfaqSlice from "../Features/user/userfaqSlice"
import userartistSlice from "../Features/user/userartistSlice"
import wishlistSlice from "../Features/user/wishlistSlice"
import mycartSlice from "../Features/user/mycartSlice"
import subscriptionSlice from "../Features/user/subscriptionSlice"
import producttypeSlice from "../Features/user/producttypeSlice"
import userartisttypeSlice from "../Features/user/userartisttypeSlice"
import useroffersSlice from "../Features/user/useroffersSlice"
import allitemsSlice from "../Features/user/allitemsSlice"
import activetabSlice from "../Features/user/activetabSlice"
import exhibitionartdetailSlice from "../Features/common/exhibitionartdetailSlice"
import deliveryCountrySlice from "../Features/common/countrySlice"
import orderSlice from "../Features/superadmin/orderSlice"
import filterationSlice from "../Features/user/filterationSlice"
import quateDataSlice from "../Features/Artist/quateDataSlice"
import uploadartSlice from "../Features/Artist/uploadartSlice"
import recreateDataSlice from "../Features/Artist/recreateSlice"
import buyerOfferDataSlice from "../Features/Artist/buyerOfferSlice"
import buyerdetailSlice from "../Features/user/buyerdetailSlice"
import adminOfferDataSlice from "../Features/Artist/adminOfferSlice"
import curatorDetailsSlice from "../Features/superadmin/curatorDetailsSlice"
import curatorExhibitionDetailsSlice from "../Features/superadmin/curatorExhibitionDetailsSlice"
import editArtIdSlice from "../Features/common/editArtIdSlice"
import { setCuratorGalleryData } from "../Features/superadmin/CuratorGallerySlice"
import CuratorGallery from "../../curator/pages/gallery/CuratorGallery"
import  setInquirydetails  from "../Features/Artist/inquiryList"
import profileSlice  from "../Features/Artist/profileSlice"
import GalleryMyArtistSlice  from "../Features/Gallery/GalleryMyArtistSlice"
export const store = configureStore({
  reducer: {
    // registration: registrationApi,
    // common slice
    tableheaderbtn: tableheaderbuttonSlice,
    pagination: paginationSlice,
    usertype: usertypeSlice,
    loader: loaderSlice,
    user: userSlice,

    exhibitionAllArt: exhibitionartdetailSlice,
    deliveryCountry: deliveryCountrySlice,
    // subscription slice
    // USER
    allcategoryData: allcategorySlice,
    policyData: userpolicySlice,
    userFaqData: userfaqSlice,
    userartistData: userartistSlice,
    wishlistData: wishlistSlice,
    mycartData: mycartSlice,
    subscriptionData: subscriptionSlice,
    productType: producttypeSlice,
    artistTypedata: userartisttypeSlice,
    offersData: useroffersSlice,
    allitemData: allitemsSlice,
    activetab: activetabSlice,
    filteration: filterationSlice,
    buyerdetail: buyerdetailSlice,

    // superadmin

    artsdata: artsSlice,
    artsprofiledata: artsproflieSlice,
    subscriptionfeatureData: subscriptionfeatureSlice,
    careerDetailsdata: careerdetailsSlice,
    subscriptionArtistData: subscriptionArtistSlice,
    subscriptionData: subscriptionData,
    buyerData: buyerSlice,
    categoryData: categorySlice,
    menuData: menuSlice,
    CuratorGalleryData: CuratorGallerySlice,
    submenuData: subMenuSlice,
    artisttypedata: artisttypeSlice,
    curatorEarningdata: curatorEarningSlice,
    menuSubMenudata: mainMenuSubMenuSlice,
    getAllMenuSubMenudata: getAllMenuSubMenuSlice,
    headingByMenu: HeadingByMenuSlice,
    artistdata: ArtistSlice,
    AffiliatEarningData: AffiliatEarningSlice,
    artistprofiledata: artistprofileSlice,
    galleryprofiledata : galleryprofileSlice,
    userenquirydata: userenquirySlice,
    enquiryreplybyiddata : enquiryReplyByIdSlice,
    notificationdata : NotificationSlice,
    enquirybyenquiryid :EnquiryByEnquiryIdSlice,
    curatorData: curatorSlice,
    affiliatedata: affiliateSlice,
    affiliateprofiledata: affiliateprofileSlice,
    getAllaffiliateLinkData: getAllaffiliateLinkSlice,
    MenuBySubMenuData: MenuBySubMenuSlice,
    exshibitionData: exshibitionSlice,
    addMarketingData: addMarketingSlice,
    exhibitionAdminData: exhibitionAdminSlice,
    compaingnsdata: compaingnSlice,
    careerdata: careerSlice,
    printPricingdata: printPricingSlice,
    enquirydata: enquirySlice,
    testmonialdata: testimonialSlice,
    blogdata: blogSlice,
    itemtype: itemtypeSlice,
    pressreleasedata: pressreleaseSlice,
    faqlistdata: faqlistSlice,
    offerdata: offerSlice,
    giftcardData: giftcardSlice,
    heroData: herosectionSlice,
    policyData: policySlice,
    disputeData: disputeSlice,
    galleryData: gallerySlice,
    permissionData: permissionSlice,
    auctionData: auctionSlice,
    subadminData: subadminSlice,
    orderData: orderSlice,
    curatorDetailsData: curatorDetailsSlice,
    curatorExhibitionDetailsData: curatorExhibitionDetailsSlice,
    // buyerData: buyerData
    // blog
    careersData: usercareerSlice,
    blogSlugdata: blogSliceSlug,
    // ARTIST STATE

    inquirylist:setInquirydetails,
    quateRequestData: quateDataSlice,
    uploadArt: uploadartSlice,
    recreateData: recreateDataSlice,
    buyerOfferData: buyerOfferDataSlice,
    adminOfferData: adminOfferDataSlice,
    editArtId: editArtIdSlice,
    artistdetails:profileSlice,

    //gallery
    galleryMyArtistData:GalleryMyArtistSlice
  },
})
