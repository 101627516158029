import React, { useEffect, useState } from "react";
import styles from "./UserOrder.module.css";
import moment from "moment";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Link,
  Modal,
  Rating,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { Errornotify, Successnotify } from "../../../../../common/alert/Alert";
import serverimage from "../../../../../serverConstant";
import api from "../../../../../State/api/Api";
import { useNavigate } from "react-router-dom";
import CircularLoader from "../../../../../common/loader/Loader";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { handleAddDisputeBuyer } from "../../../../../State/api/userApi";

const UserOrder = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState(
    JSON.parse(localStorage.getItem("rating")) || 0
  );
  const storedRatingId = JSON.parse(localStorage.getItem("rating_id")) || null;
  const storedRating = JSON.parse(localStorage.getItem("rating")) || 0;

  const dispatch = useDispatch();
  const [orderType, setOrdertype] = useState("Buy");
  const [orderData, setOrderData] = useState(null);


  const handleFetchOrders = async function () {
    // dispatch(setLoader(true));
    setLoading(true);
    try {
      const result = await api.applicationApi("api/order/fetchOrders", "POST");
      console.log(result.data.data, "result from orders");
      if (result && result.status === 200) {
        setOrderData(result.data.data);
        // dispatch(setLoader(false));
        setLoading(false);
      } else if (result.status === 401) {
        Errornotify("Error with code 401");
      }
    } catch (err) {
      Errornotify("Network Error");
      setLoading(false);
    }
  };
  useEffect(() => {
    handleFetchOrders();
  }, []);

  const buyType = orderData?.data
  ?.map((val) => ({
    ...val,
    items: val.items?.filter((item) => {
      if (orderType === "Others") {
        // Include items where the order_type is not "Print", "Rent", or "Buyer"
        return !["Print", "Rent", "Buyer"].includes(item?.order_type?.name);
      } else {
        // Otherwise, include items that match the specified orderType
        return item?.order_type?.name === orderType;
      }
    }),
  }))
  ?.filter((val) => val.items?.length > 0);

  console.log(buyType, "buyType");
  // setOrderData(buyType)

  const isBuyBackground =
    orderType === "Buy" ? "rgba(28, 28, 28, 1)" : "rgba(246, 246, 246, 1)";
  const isRentBackground =
    orderType === "Rent" ? "rgba(28, 28, 28, 1)" : "rgba(246, 246, 246, 1)";
  const isPrintBackground =
    orderType === "Print" ? "rgba(28, 28, 28, 1)" : "rgba(246, 246, 246, 1)";
const isOtherBackground = orderType === "Others" ? "rgba(28, 28, 28, 1)" : "rgba(246, 246, 246, 1)";
  const isBuyColor =
    orderType === "Buy" ? "rgba(246, 246, 246, 1)" : "rgba(42, 42, 44, 1)";
  const isRentColor =
    orderType === "Rent" ? "rgba(246, 246, 246, 1)" : "rgba(42, 42, 44, 1)";
  const isPrintColor =
    orderType === "Print" ? "rgba(246, 246, 246, 1)" : "rgba(42, 42, 44, 1)";
    const isOtherColor = orderType === "Others" ? "rgba(246, 246, 246, 1)" : "rgba(42, 42, 44, 1)"; 
  return (
    <>
      {loading ? (
        <div
          style={{
            background: "transparent",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "71%",
            height: "50vh",
          }}
        >
          <CircularLoader />
        </div>
      ) : (
        <>
          <div className={styles.order_main}>
            <div className={styles.buttonlist}>
              <ButtonGroup
                variant="contained"
                sx={{ borderRadius: "none", boxShadow: "none", padding: "4px" }}
              >
                <Button
                  sx={{
                    background: isBuyBackground,
                    color: isBuyColor,
                  }}
                  onClick={() => setOrdertype("Buy")}
                >
                  Buy
                </Button>
                <Button
                  sx={{
                    background: isRentBackground,
                    color: isRentColor, 
                  }}
                  onClick={() => setOrdertype("Rent")}
                >
                  Rent
                </Button>
                <Button
                  sx={{
                    background: isPrintBackground,
                    color: isPrintColor, 
                  }}
                  onClick={() => setOrdertype("Print")}
                >
                  Print
                </Button>
                <Button
                  sx={{
                    background: isOtherBackground,
                    color: isOtherColor, 
                  }}
                  onClick={() => setOrdertype("Others")}
                >
                  Others
                </Button>
              </ButtonGroup>
            </div>
            {buyType
              ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((val) => (
                <div className={styles.order_cart}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                  >
                    <Grid sx={{ height: "240px" }} item xs={12} sm={6} md={3}>
                      <div className={styles.img_div}>
                        <img
                          alt="img"
                          src={` ${
                            val?.items?.map((val) =>
                              String(val?.item?.image)
                            )[0]
                          }`}
                          className={styles.order_img}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <p className={styles.odlist}>Order ID : <span>{val?.order_number}</span></p>
                      <p className={styles.odlist}>Order Date : <span>{moment(val?.order_date).format("DD-MM-YYYY")}</span></p>
                      <p className={styles.odlist}>No. of Items : <span>{val?.items.length}</span></p>
                      <p className={styles.odlist}>Category : <span>Artworks</span></p>
                      <p className={styles.odlist}>Type : <span>{
                          
                          val?.items?.map(
                            (item) => item?.order_type?.name
                          
                        )
                      }</span></p>
                      <p className={styles.odlist}>Order Amount : <span>${val?.price}</span></p>
                        
            {false ?   <Rating
              name="customized-10"
             defaultValue={storedRatingId === val._id ? storedRating : 0}
              max={5}
              onChange={(event, newValue) => {
                localStorage.setItem("rating", JSON.stringify(newValue));
                localStorage.setItem("rating_id", JSON.stringify(val._id));
                setRating(newValue); // Update the rating state
              }}
            /> : null}
        

                      
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <div className={styles.transit_div}>
                        <div className={styles.trasit_dot}></div>
                        <Typography
                          variant="h6"
                          className={styles.transit_text}
                        >
                          In-transit
                        </Typography>
                      </div>
                      <div className={styles.otiobtn}>
                      <button
                      className="action_btn"
                        onClick={() =>
                          navigate(`/userorder/details/${val?._id}`)
                        }
                        variant="contained"
                         
                      >
                        View Order{" "}
                      </button>
                      <button
                         className="action_libtn"
                      >
                        Track Order{" "}
                      </button>
                    
                        <>
                          {" "}
                          <button
                          className="action_libtn"
                              onClick={() =>
                          navigate(`/userorder/details/${val?._id}`)
                        }
                           
                            
                          >
                            Cancel Order{" "}
                          </button>
                        </>
                      </div>
                      
                    
                    </Grid>
                  </Grid>
                </div>
              ))}
          </div>
        </>
      )}
    </>
  );
};

export default UserOrder;
