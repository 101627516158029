import PhoneInput from "react-phone-input-2";
import { Autocomplete, Link, FormHelperText, TextField, Grid } from "@mui/material";
import styles from "./EditUserProfile.module.css";
import Uploadpic from "../../../../../../gallery/pages/editProfile/commonEditprofile/uploadPic/Uploadpic";

import {
  buyerProfileUpdate,
  handleFetchBuyerDetail,
} from "../../../../../../State/api/userApi";
import { useDispatch } from "react-redux";
import {
  AlertsSuccess,
  Errornotify,
} from "../../../../../../common/alert/Alert";
import { handlechangePassword } from "../../../../../../State/api/superadminApi";
import Enterdetails from "../../../../List/Popupdelete/enterDetails/Enterdetails";
import serverimage from "../../../../../../serverConstant";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import { RiCloseCircleFill } from "react-icons/ri";
const placeholder = {
  placeholder: "upload new pic",
};

const languages = [
  { code: "en", name: "English" },
  { code: "hi", name: "Hindi" },
  { code: "ar", name: "Arabic" },
  { code: "es", name: "Spanish" },
  { code: "fr", name: "French" },
  { code: "de", name: "German" },
  { code: "zh", name: "Chinese" },
  { code: "ja", name: "Japanese" },
  { code: "ru", name: "Russian" },
  { code: "pt", name: "Portuguese" },
  { code: "it", name: "Italian" },
  { code: "ko", name: "Korean" },
  { code: "tr", name: "Turkish" },
  { code: "vi", name: "Vietnamese" },
  { code: "nl", name: "Dutch" },
  { code: "sv", name: "Swedish" },
  { code: "pl", name: "Polish" },
  { code: "uk", name: "Ukrainian" },
  { code: "el", name: "Greek" },
  { code: "he", name: "Hebrew" },
  { code: "th", name: "Thai" },
  { code: "cs", name: "Czech" },
  { code: "fi", name: "Finnish" },
  { code: "no", name: "Norwegian" },
  { code: "da", name: "Danish" },
  { code: "hu", name: "Hungarian" },
  { code: "ro", name: "Romanian" },
  { code: "sk", name: "Slovak" },
  { code: "bg", name: "Bulgarian" },
  { code: "hr", name: "Croatian" },
  { code: "sr", name: "Serbian" },
  { code: "lt", name: "Lithuanian" },
  { code: "lv", name: "Latvian" },
  { code: "et", name: "Estonian" },
  { code: "sl", name: "Slovenian" },
  { code: "is", name: "Icelandic" },
  { code: "ga", name: "Irish" },
  { code: "mt", name: "Maltese" },
  { code: "cy", name: "Welsh" },
  { code: "sq", name: "Albanian" },
  { code: "mk", name: "Macedonian" },
  { code: "bs", name: "Bosnian" },
  { code: "ms", name: "Malay" },
  { code: "sw", name: "Swahili" },
  { code: "am", name: "Amharic" },
  { code: "fa", name: "Persian" },
  { code: "ur", name: "Urdu" },
  { code: "bn", name: "Bengali" },
  { code: "ta", name: "Tamil" },
  { code: "te", name: "Telugu" },
  { code: "mr", name: "Marathi" },
  { code: "gu", name: "Gujarati" },
  { code: "kn", name: "Kannada" },
  { code: "ml", name: "Malayalam" },
  { code: "pa", name: "Punjabi" },
  { code: "si", name: "Sinhala" },
  { code: "ne", name: "Nepali" },
  { code: "km", name: "Khmer" },
  { code: "my", name: "Burmese" },
  { code: "lo", name: "Lao" },
  { code: "mn", name: "Mongolian" },
  { code: "kk", name: "Kazakh" },
  { code: "uz", name: "Uzbek" },
  { code: "hy", name: "Armenian" },
  { code: "az", name: "Azerbaijani" },
  { code: "ka", name: "Georgian" },
  // Add more languages as needed
];

export default function EditUserProfile(props) {
  console.log(props.userDetail);
  const [open, setOpen] = useState(false);
  const [pass, setPass] = useState({ oldPassword: "", newPassword: "", confirm_new_password: "" });
  const [phoneNumberError, setPhoneNumberError] = useState("");
  // const [updateAddress, setUpdateAddress] = useState({
  //   flat_no: "",
  //   city: "",
  //   state: "",
  //   country: "",
  //   nationality: "",
  // });

  const dispatch = useDispatch();

  // const handleAddress = (e) => {
  //   const { name, value } = e.target;
  //   setUpdateAddress({ ...updateAddress, [name]: value });
  //   setUpdateProfile({
  //     ...updateProfile,
  //     address: [updateAddress],
  //   });
  // };
  const validatePhoneNumber = (phoneNumber) => {
    const minDigits = 10;
    const maxDigits = 18;
    if (phoneNumber.length < minDigits || phoneNumber.length > maxDigits) {
      return `Phone number must be between 7 and 15 digits`;
    }
  };
  const handleChangeNumber = (e) => {
    props.setUpdateProfile((prevState) => ({
      ...prevState,
      phone: e,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const validTextPattern = /^[a-zA-Z0-9]*$/;
    const filteredValue = value.replace(/[^a-zA-Z]/g, "");
    const isNumeric = /^\d*$/;
    if (name === "first_name") {
      // Filter out invalid characters from the input value

      // Update the state with the filtered value
      if (validTextPattern.test(filteredValue)) {
        props.setUpdateProfile((prevState) => ({
          ...prevState,
          [name]: filteredValue,
        }));
      }
    } else if (name === "last_name") {
      if (validTextPattern.test(filteredValue)) {
        props.setUpdateProfile((prevState) => ({
          ...prevState,
          [name]: filteredValue,
        }));
      }
    } else {
      // Handle other fields
      props.setUpdateProfile((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleSubmitProfile = () => {
    const validationError = validatePhoneNumber(props.updateProfile.phone);
    if (validationError) {
      setPhoneNumberError(validationError);
    } else {
      dispatch(buyerProfileUpdate(props.updateProfile));
    }
  };

  const onChangePass = (e) => {
    const { name, value } = e.target;
    setPass({ ...pass, [name]: value });
  };
  const handleSubmitChangePassword = () => {
    if(pass.newPassword !== pass.confirm_new_password){
      Errornotify("Please fill same password both inputs");
    }else {
      dispatch(handlechangePassword(pass));
    }    
  }
  return (
    <div className={styles.mains}>
      <AlertsSuccess />

      <div className={styles.components}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12 }}
        >
          <Grid item xs={12} sm={4} md={3}>
            <div className={styles.upload_pic_div}>
              <Uploadpic
                val={placeholder}
                src={props.src}
                name="image"
                handleButtonClick={props.handleButtonClick}
                handleChangeImage={props.handleChangeImage}
                fileInputRef={props.fileInputRef}
                type="buyer"
              />
            </div>
            <div className={styles.btn_divb}>
              <input
                accept="image/*"
                type="file"
                hidden
                ref={props.fileInputRef}
                onChange={props.handleChangeImage}
              />
              <button
                onClick={props.handleButtonClick}
                className={styles.choose_btn}
              >
                Change Image
              </button>
            </div>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <div>
              {/* <Commoninputs
                input={input}
                handleChange={handleChange}
                detail={updateProfile}
              /> */}

              {!open ? (
                <>
                <div  className={styles.inputs}>
                    <div className="form-group">
                      <label>First Name *</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="first_name"
                        value={props.updateProfile.first_name}
                        placeholder="New Delhi"
                        onChange={handleChange}
                       
                      />
                    </div>
                     <div className="form-group">
                      <label>Last Name *</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="last_name"
                        value={props.updateProfile.last_name}
                        placeholder="Delhi"
                        onChange={handleChange}
                        
                      />
                    </div>
                     <div className="form-group">
                      <label>Phone *</label>
                      {/* <TextField
                    className={styles.inputs}
                    variant="outlined"
                    type="text"
                    name="phone"
                    value={props.updateProfile.phone}
                    placeholder="+91 999999999"
                    onChange={handleChange}
                    sx={{
                      backgroundColor: "white",
                      height: 50,
                      borderRadius: "6px",
                      mt: 0.5,
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                          // opacity: 0.5
                        },
                        // '&:hover fieldset': {
                        //     borderColor: 'yellow',
                        // },
                        // '&.Mui-focused fieldset': {
                        //     borderColor: 'green',
                        // },
                      },
                    }}
                    fullWidth
                  /> */}
                      <PhoneInput
                        className={`${styles.phoneinpu} ${
                          props.phone?.length ? styles.disabled : ""
                        }`}
                        country={"in"}
                        value={props.updateProfile.phone}
                        disable={true}
                        autoFormat={true}
                        disableSearchIcon={true}
                        variant="contained"
                        countryCodeEditable={false}
                        inputProps={{
                          name: "phone",
                          required: true,
                          // autoFocus: true
                        }}
                        // searchStyle={{ borderColor: 'black', bakgrondColor: 'green' }}
                        // {props.border && props.border === 'applyform' ? : }
                        buttonStyle={
                          // props.border && props.border === 'applyform' ?
                          // {}
                          // :
                          {
                            paddingLeft: "0px",
                            backgroundColor: "white",
                            borderRightHeight: "4px",
                            borderRightWidth: "20px",
                            color: "#636365",
                            border: "1px solid #D0D0D0",
                            borderTopLeftRadius: "8px",
                            borderBottomLeftRadius: "8px",
                          }
                        }
                        containerStyle={{
                          width: "100%", 
                          // border: '1px solid #ababab',
                          borderRadius: "1px",
                        }}
                        // dropdownStyle={{width:'200px',padding:'30px',borderColor:'red'}}
                        inputStyle={
                          //     props.border && props.border === 'applyform' ? {
                          //     // paddingLeft:'78px',
                          //     width: '100%',
                          //     backgroundColor: '#f6f6f6',
                          //     height: '34px',
                          //     outlined: 'true',
                          //     borderRadius: '0px',
                          //     '&:focus': {
                          //         border: '1px solid black'
                          //     },
                          // } :
                          {
                            // paddingLeft:'78px',
                            width: "100%",
                            backgroundColor: "white",
                            height: "52px",
                            color: "#636365",
                            outlined: "true",
                            border: "1px solid #D0D0D0",
                            "&:focus": {
                              border: "1px solid black",
                            },
                          }
                        }
                        {...props}
                        onChange={handleChangeNumber}
                      />
                      {phoneNumberError && (
                        <FormHelperText sx={{ color: "red" }}>
                          {phoneNumberError}
                        </FormHelperText>
                      )}
                    </div> 

                  <div className="form-group">
                     <label>Language? *</label>
                    
                    <Autocomplete
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        padding: "0px", // Adjust padding as needed
                        paddingLeft:".75rem",
                      },
                    }}
                      options={languages}
                      getOptionLabel={(option) => option.name}
                      // getOptionLabel={(key) =>
                      //   `${key.substring(0, 2)}-${key.substring(2, 4)}`
                      // }
                      name="language"
                      value={languages.find(
                        (lang) => lang.name === props.updateProfile.language
                      )}
                      disableClearablex
                      onChange={(event, newValue) => {
                        if (newValue) {
                          props.setUpdateProfile({
                            ...props.updateProfile,
                            language: newValue.name,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          sx={{ backgroundColor: "white" }}
                        />
                      )}
                    />
                  </div>
                  <div className={styles.textright}>
                      <a className={styles.forgot}
                        onClick={() => setOpen(true)}
                      >
                        Change Password?
                      </a>
                </div>
                  {!open ? (
          <>
            {" "}
            <div className={styles.btn_div}>
              <button
              className="action_btn"
                onClick={handleSubmitProfile}
                
              >
                Save
              </button> {" "} &nbsp;
              <button
                onClick={() => props.setEdit(false)}
                className="action_libtn"
              >
                Cancel
              </button>
            </div>
          </>
        ) : (
          <div className={styles.btn_div}>
            <button
              onClick={handleSubmitProfile}
              className={styles.aply_btn}
            >
              Update Password
            </button>
          </div>
        )}
        </div>
                </>
              ) : (
                <>
                  <div className={styles.editicon}>
                     <Link onClick={() => setOpen(false)}><RiCloseCircleFill className={styles.closicon}/></Link>
                     
                  </div>
                  <div  className={styles.inputs}>
                  <div className="form-group">
                      <label>Current Password</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="oldPassword"
                        value={pass.oldPassword}
                        placeholder="Enter current password"
                        onChange={onChangePass}
                        
                      />
                    </div>
                  <div className="form-group">
                      <label>New Password</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="newPassword"
                        value={pass.newPassword}
                        placeholder="Enter new password"
                        onChange={onChangePass}
                        
                      />
                    </div>
                    <div className="form-group">
                      <label>Confirm Password</label>
                      <input
                        className="form-control"
                        variant="outlined"
                        type="text"
                        name="confirm_new_password"
                        value={pass.confirm_new_password}
                        placeholder="Enter confirm password"
                        onChange={onChangePass}
                        
                      />
                    </div> 
                  <div className={styles.btn_div}>
            <button
              onClick={handleSubmitChangePassword}
              className="action_btn"
            >
              Update
            </button>
          </div>
                  </div>
                   
                </>
              )}
            </div>
          </Grid>
        </Grid>
      <ToastContainer />
      </div>
    </div>
  );
}
