import { Button, Link, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./CommonDetailAuctionCurator.module.css";
import dateFormat from "../../../../utils/dateFormat";
import capitalizeWords from "../../../../utils/capitalizeContent";
import TableForBuyerBid from "../../../../artist/commonTables/EightcolimgArtist/TableForBuyerBid";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";

export default function CommondetailAuctionaCurator(props) {
  const navigate = useNavigate();

  const handlePages = () => {
    navigate("/auction");
  };
  const usertype = useSelector((state) => state.user.data);
  return (
    <div>
      <div className={styles.main_artistprofile}>
        <div className={styles.back_button}>
          <Link onClick={handlePages} className={styles.backbn}>
            {" "}
            <IoIosArrowBack /> <span> Go Back</span>
          </Link>
        </div>
      </div>
      {props.type === "order_detail_gallery" ? (
        ""
      ) : (
        <>
          <div className={styles.bsrd}>
            <h1 className={styles.arhdng}></h1>
          </div>
        </>
      )}
      <div className={styles.more_detail}>
        <div className={styles.bdngr}>
          <h3 className={styles.sethng}>
            Auction Title :{" "}
            {capitalizeWords(props.data && props.data.data.auction?.name)}
          </h3>
          <p className={styles.odlist}>
            Description :{" "}
            <span>
              {capitalizeWords(
                props.data && props.data.data.auction?.description
              )}
            </span>
          </p>
          <p className={styles.odlist}>
            Created Date :{" "}
            <span>
              {dateFormat(props.data && props.data.data.auction?.createdAt)}
            </span>
          </p>
          <p className={styles.odlist}>
            Start Date :{" "}
            <span>
              {dateFormat(props.data && props.data.data.auction?.start_date)}
            </span>
          </p>
          <p className={styles.odlist}>
            End Date :{" "}
            <span>
              {dateFormat(props.data && props.data.data.auction?.end_date)}
            </span>
          </p>
          <p className={styles.odlist}>
            Application End Date :{" "}
            <span>
              {dateFormat(
                props.data && props.data.data.auction?.application_end_date
              )}
            </span>
          </p>
          <p className={styles.odlist}>
            No. of Items :{" "}
            <span>
              {props.data === null
                ? 0
                : props.data?.data.data.length === 0
                ? "0"
                : props.data.data.data.length}
            </span>
          </p>
        </div>
      </div>

      <div className={styles.more_detail}>
        {Array.isArray(props?.data?.data?.data) &&
        props.data.data.data.length > 0 ? (
          props.data.data.data.map((auctionItem) =>
            auctionItem.item && auctionItem.item.length > 0 ? (
              auctionItem.item.map((val) => (
                <div key={val._id}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ justifyContent: "space-between" }}
                  >
                    <Grid item xs={12} sm={5} md={4}>
                      <h1 className={styles.art_namms}>
                        <a
                          className={styles.productLink_text}
                          href={`/user/newestpaintings/${val._id}`}
                        >
                          {val.name}
                        </a>
                      </h1>
                      <div style={{ padding: "10px 0px" }}>
                        <a href={`/user/newestpaintings/${val._id}`}>
                          <img
                            src={val.image}
                            className="img-fluid"
                            alt="img"
                          />
                        </a>
                      </div>
                    </Grid>
                    {usertype.type === "curator" ? "" : (
                      <Grid item xs={12} sm={5} md={4}>
                      <div className={styles.quoterequest}>
                        <>
                          {val?.is_accepted === "Pending" && (
                            <>
                              <button
                                onClick={() => props.handleAccept(val._id)}
                                className="action_btn"
                              >
                                Accept
                              </button>
                              <button
                                onClick={() => props.handleReject(val._id)}
                                variant="outlined"
                                className="action_libtn"
                              >
                                Decline
                              </button>
                            </>
                          )}

                          {val?.is_accepted === "Approved" && (
                            <Typography
                              variant="h6"
                              sx={{
                                color: "green",
                                width: "80px",
                                height: "34px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "4px",
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                            >
                              Approved
                            </Typography>
                          )}

                          {val?.is_accepted === "Rejected" && (
                            <Typography
                              variant="h6"
                              sx={{
                                color: "red",
                                width: "80px",
                                height: "34px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "4px",
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                            >
                              Rejected
                            </Typography>
                          )}
                        </>
                      </div>
                    </Grid>
                    )}
                  </Grid>
                  <div>
                    {/* <p className={styles.odlist}>
                      Price : <span>${auctionItem.item_price}</span>
                    </p> */}
                    <p className={styles.odlist}>
                      Materials :{" "}
                      <span>
                        {val.material.map((c, index) => (
                          <span key={index}>
                            {c.name}
                            {index !== val.material.length - 1 ? ", " : ""}
                          </span>
                        ))}
                      </span>
                    </p>
                    <p className={styles.odlist}>
                      Category : <span>{val.category}</span>
                    </p>
                    <p className={styles.odlist}>
                      Dimensions :{" "}
                      <span>
                        {val.width} W x {val.height} H x {val.depth} D in cm
                      </span>
                    </p>
                  </div>
                  {/* <div>
              <strong>Name:</strong> {val.name}
            </div>
            <div>
              <strong>Category:</strong> {val.category}
            </div>
            <div>
              <strong>Dimensions:</strong> {val.height} * {val.width} * {val.depth}
            </div>
            <div>
              <strong>Materials:</strong>{" "}
              {val.material.map((c, index) => (
                <span key={index}>{c.name}{index !== val.material.length - 1 ? ", " : ""}</span>
              ))}
            </div> */}
                </div>
              ))
            ) : (
              <div key={auctionItem._id}>No item found.</div>
            )
          )
        ) : (
          <div>No data found.</div>
        )}
      </div>
    </div>
  );
}
