import { Box, Button } from "@mui/material";
import React from "react";
import styles from "./webPopupArtistAndGallery.module.css";
import homeImage from "../../../../../constant";
import { useNavigate } from "react-router-dom";

export default function WebpopupGallery({open,setOpen}) {
  const navigate = useNavigate();
  return (
    <>
      {/* {
            props.type === 'web' ?
                // <Box sx={{ mt: { xs: '-20px', sm: '-64px' } }} className={props.data.length === props.i + 1 ? styles.cancel_imgweb : styles.cancel_img_hide_web} >
                //     {props.data.length === props.i + 1 ?
                //         <img alt='img' onClick={() => props.setOpen(false)} src={ '/img/cancel_cross.png'} height='20px' width='20px' />
                //         : ''}
                // </Box> 
                "" :
                <div className={props.data.length === props.i + 1 ? styles.cancel_img : styles.cancel_img_hide} >
                    {props.data.length === props.i + 1 ?
                        <img alt='img' onClick={() => props.setOpen(false)} src={ '/img/cancel_cross.png'} height='20px' width='20px' />
                        : ''}
                </div>
        } */}
<div className={styles.icdvn}><img alt="img" src="/img/gallery-popup.svg" width="50px" height="50px" /></div>
      <div className={styles.card_content}>
      
       
      <div><h3>  
        {open === "ArtistAndGallerySignIn" ? "Gallery" : "I Am A Gallery"}</h3>
        <h5>{open === "ArtistAndGallerySignIn" ? "Showcase & Sell" : ""}</h5>
        <h6>
        {open === "ArtistAndGallerySignIn" ? "Sign in to elevate your gallery, manage your collection, track sales, and engage with collectors around the world." : "You are a Contemporary Art Gallery / Artist-Run Gallery / Online Gallery / Auction House Gallery"}
          </h6></div>
        
        <button
         className={`apply_btn ${styles.ml_auto}`}
          onClick={() => {
            if(open === "ArtistAndGallerySignIn") {
              navigate("/login",{state: {type: "gallery"}})
            }else {
              navigate("/gallery/applynow")
              setOpen(false)
            }
          }}
        >
          {open === "ArtistAndGallerySignIn" ? "Sign In" : "Apply Now"}
        </button>
      </div>
    </>
  );
}
