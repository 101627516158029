import React, { useState } from "react";
import styles from "./Buyerartist.module.css";
import EightcolimgArtist from "../../../artist/commonTables/EightcolimgArtist/EightcolimgArtist";
import TableheaderArtist from "../../../artist/commonTables/TableheaderArtist/TableheaderArtist";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  acceptDeclineBuyerOffer,
  fetchBuyerOffer,
} from "../../../State/api/artistapi";
import Recretepopup from "../../../gallery/components/popups/recretepopup/Recretepopup";
import { setbuyerOfferData } from "../../../State/Features/Artist/buyerOfferSlice";
import Pagination from "../../../components/Pages/List/Pagination/Pagination";
import Buyerofferpopup from "../../../gallery/components/popups/recretepopup/buyerofferpopup";
import { ToastContainer } from "react-toastify";


const headData = [
  {
    id: "image",
    label: "Image",
  },
  {
    id: "buyer_name",
    label: "Buyer Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "email",
    label: "SKU Id",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "country",
    label: "Art Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "item",
    label: "Art Type",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "newsletter",
    label: "Requested Date",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "contact",
    label: "Price",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "actionsss",
    label: "Offer Price",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "action",
    label: "Action",
    format: (value) => value.toLocaleString(),
  },
];


const sortdata = [
  { label: "By Price ↓", value: "low" },
  { label: "By Price ↑", value: "high" },
];
const filterdata = [
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
  { label: "Pending", value: "pending" },
];
export default function Gallerybuyeroffer() {
  const [open, setOpen] = useState(false);
  const [acceptPopupData, setAcceptPopuupData] = useState(null);
  const [search, setSearch] = useState("");
  const [data, setData] = useState(null);
  const [condition, setCondition] = useState({
    sorting: "",
    filter: "",
  });

  const buyerofferdt = useSelector((state) => state.buyerOfferData.data);
  console.log("buyerofferdt-->", buyerofferdt);
  console.log("acceptPopupData-->", acceptPopupData);

  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(fetchBuyerOffer(data));
  };
  useEffect(() => {
    fetchData();
  }, [data]);
  const handlePopupData = (dt) => {
    setOpen(true);
    setAcceptPopuupData(dt);
  };
  const handleFilter = (event) => {
    const value = event.target.value;
    setCondition({ ...condition, filter: value });
  };
  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
  };
  const handleAcceptRejectOffer = (id, dt) => {
    dispatch(acceptDeclineBuyerOffer(id, dt, fetchData));
  };

  const handleSorting = (event) => {
    const value = event.target.value;
    if (value === "low") {
      setCondition({ ...condition, sorting: value });
      // dispatch(
      //   setbuyerOfferData({
      //     data: [...buyerofferdt.data].sort((a, b) => a.price - b.price),
      //     totalCount: buyerofferdt.totalCount,
      //   })
      // );
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value });
      // dispatch(
      //   setbuyerOfferData({
      //     data: [...buyerofferdt.data].sort((a, b) => b.price - a.price),
      //     totalCount: buyerofferdt.totalCount,
      //   })
      // );
    } else {
      // Default case
      dispatch(setbuyerOfferData(buyerofferdt));
      setCondition({ ...condition, sorting: value });
    }
  };


  return (
    <div className={styles.mains}>
      
      {open ? (
        <Buyerofferpopup
          handleAcceptRejectOffer={handleAcceptRejectOffer}
          acceptPopupData={acceptPopupData}
          data={buyerofferdt.data}
          open={open}
          setOpen={setOpen}
          activetab="buyeroffer"
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <div className={styles.tableheader}>
          <TableheaderArtist
            setOpen={setOpen}
            handleFilter={handleFilter}
            button=""
            search={search}
            condition={condition}
            filterdata={filterdata}
            sortdata={sortdata}
            handleSearch={handleSearch}
            handleSorting={handleSorting}
          />
        </div>
        <EightcolimgArtist
          activetab="buyeroffer"
          headData={headData}
          data={
            buyerofferdt &&
            buyerofferdt?.makeOffers?.slice()
              .reverse() // Reverse the data array
              .filter((val) =>
                (val.first_name + " " + val.last_name).toLowerCase().includes(search.toLowerCase()) ||
                val?.item_id?.sku_id?.toString().toLowerCase().includes(search.toLowerCase()) ||
                val?.item_id?.name.toString().toLowerCase().includes(search.toLowerCase())
              )
              .filter((val) => {
                if (condition.filter === "approved") {
                  return val.is_accepted && val.is_accepted === "Approved";
                } else if (condition.filter === "rejected") {
                  return val.is_accepted && val.is_accepted === "Rejected";
                } else if (condition.filter === "pending") {
                  return val.is_accepted && val.is_accepted === "Pending";
                } else {
                  return val;
                }
              }).sort((a, b) => {
                if (condition.sorting === "low") {
                  return a.item_id?.sale?.price - b.item_id?.sale?.price; // Sort price low to high
                } else if (condition.sorting === "high") {
                  return b.item_id?.sale?.price - a.item_id?.sale?.price; // Sort price high to low
                }
                return 0; // No sorting if condition.sorting is not 'low' or 'high'
              })

          }
          setOpen={setOpen}
          handlePopupData={handlePopupData}
        />
        {/* <Pagination
          totaldata={buyerofferdt && buyerofferdt.totalCount}
          pagination={data}
          setPagination={setData}
        /> */}
        <ToastContainer />
      </div>
    
    </div>
  );
}
