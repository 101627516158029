import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Moreoption from "../../../components/Pages/List/Table/Moreoption/Moreoption"
import Tableheader from "../../../components/Pages/List/Table/Tableheader/Tableheader"
import Allartpopup from "../../../gallery/components/popups/allArt/Allartpopup"
import { fetchArtsData, fetchArtsDataForSubscription } from "../../../State/api/superadminApi"
import FivecolimgArtist from "../../commonTables/FivecolimgArtist/FivecolimgArtist"
import SixcolimgArtist from "../../commonTables/SixcolimgArtist/SixcolimgArtist"
import styles from "./Artartist.module.css"
import { deleteArtistArt } from "../../../State/api/artistapi"
import { AlertsSuccess } from "../../../common/alert/Alert"
import { setArtsdata } from "../../../State/Features/superadmin/artsSlice"
// import Pagination from "../../../components/Pages/List/Pagination/Pagination"
import PopupForDeleteArt from "../../../gallery/components/popups/allArt/PopupForDeleteArt"
import ArtartistTable from "./ArtartistTable"
import { Box, Pagination, PaginationItem } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const moreOptions = [
  {
    id: "pending",
    label: "Pending",
    value: "artist_art_pending",
  },
  {
    id: "approved",
    label: "Approved",
    value: "artist_art_approved",
  },
  {
    id: "rejected",
    label: "Rejected",
    value: "artist_art_rejected",
  },
]
const headDataPending = [
  {
    id: "customer_id",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "Name",
    format: (value) => value.toLocaleString(),
    align:"left",
  },
  {
    id: "buyer_name",
    label: "Buy",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "Rent",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "Print",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "Date",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "item",
    label: "Stock in Qty.",
    format: (value) => value.toLocaleString(),
  },

]
const ratinghead = [
  {
    id: "customer_id",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "email",
    label: "Views",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "country",
    label: "Likes",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "item",
    label: "Stock in Qty.",
    format: (value) => value.toLocaleString(),
  },
]
const sortdata = [
  { label: "Price Low To High", value: "low" },
  { label: "Price High to Low", value: "high" },
]
const filterdata = [
  { label: "Buy Price", value: "buy" },
  { label: "Rent Price", value: "rent" },
  { label: "Print Price", value: "print" },
]
export default function Artartist() {
  const [button, setButton] = useState({ type: "add", text: "Add" })
  const [activetab, setAcitvetab] = useState("artist_art_pending")
  const [open, setOpen] = useState(false)
  const [openArtDelete, setOpenArtDelete] = useState(false)
  const [artId, setArtId] = useState("")
  const [data, setData] = useState({ is_accepted: "Pending", offset: 1 })
  const [search, setSearch] = useState("")
  const [condition, setCondition] = useState({ sorting: "", filter: "" })
  const dispatch = useDispatch()
  const artdata = useSelector((state) => state.artsdata.data)
console.log("artdata",artdata);

  const handleMorecategories = (e) => {
    setAcitvetab(e)
    if (e === "artist_art_pending") {
      setData({ is_accepted: "Pending", offset: 1 })
    } else if (e === "artist_art_approved") {
      // props.setButton && props.setButton({ type: '', text: '' })
      setData({ is_accepted: "Approved", offset: 1 })
    } else if (e === "artist_art_rejected") {
      // props.setButton && props.setButton({ type: '', text: '' })
      setData({ is_accepted: "Rejected", offset: 1 })
    }
  }
  const handleDelete = (id) => {
    dispatch(deleteArtistArt(id, fetchData))
  }

  const handleSearch = (e) => {
    let val = e.target.value
    setSearch(val)
  }
  const fetchData = () => {
    dispatch(fetchArtsData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])


  // const handleSorting = (event) => {
  //   const value = event.target.value;
  
  //   if (!artdata?.data || !Array.isArray(artdata.data)) return;
  
  //   const sortedData =
  //     value === "low"
  //       ? [...artdata.data].sort((a, b) => (a.sale?.price || 0) - (b.sale?.price || 0))
  //       : value === "high"
  //       ? [...artdata.data].sort((a, b) => (b.sale?.price || 0) - (a.sale?.price || 0))
  //       : artdata.data;
  
  //   if (value === "low" || value === "high") {
  //     setCondition({ ...condition, sorting: value });
  //     dispatch(
  //       setArtsdata({
  //         data: sortedData,
  //         totalCount: artdata.totalCount,
  //       })
  //     );
  //   }
  // };

  const handleSorting = (event) => {
    const value = event.target.value;
  
    let sortedData = artdata?.data ? [...artdata.data] : [];
  
    if (condition.filter === "buy") {
      sortedData.sort((a, b) =>
        value === "low" ? a.sale?.price - b.sale?.price : b.sale?.price - a.sale?.price
      );
    } else if (condition.filter === "rent") {
      sortedData.sort((a, b) =>
        value === "low" ? a.rent?.rent_price - b.rent?.rent_price : b.rent?.rent_price - a.rent?.rent_price
      );
    } else if (condition.filter === "print") {
      sortedData.sort((a, b) =>
        value === "low" ? a.print[0]?.print_price - b.print[0]?.print_price : b.print[0]?.print_price - a.print[0]?.print_price
      );
    } else {
      sortedData.sort((a, b) =>
        value === "low"
          ? (a.sale?.price || a.rent?.rent_price || a.print?.[0]?.print_price || 0) - 
            (b.sale?.price || b.rent?.rent_price || b.print?.[0]?.print_price || 0)
          : (b.sale?.price || b.rent?.rent_price || b.print?.[0]?.print_price || 0) - 
            (a.sale?.price || a.rent?.rent_price || a.print?.[0]?.print_price || 0)
      );
    }
  
    dispatch(fetchArtsData({ data: sortedData, totalCount: artdata?.totalCount }));
    setCondition({ ...condition, sorting: value });
  };


  const handleFilter = (event) => {
    const value = event.target.value;
    if (value === "") {
      setCondition({ ...condition, filter: '' });
    } else {
      setCondition({ ...condition, filter: value });
    }
  };
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; 
  const handlePageChange = (event, page) => {
    setCurrentPage(page)
;
  };

  const pageSize = 10; 
  const sortedArtData = artdata?.data
    ? [...artdata?.data].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
    : [];
 
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const currentItems = sortedArtData?.slice(startIndex, endIndex);

  return (
    <div className={styles.mains}>
      <AlertsSuccess />
    
      {openArtDelete ? <PopupForDeleteArt open={openArtDelete} setOpen={setOpenArtDelete} artId={artId} handleDelete={handleDelete} /> : ""}
      <Tableheader
        setOpen={setOpen}
        handleFilter={handleFilter}
        button={button}
        search={search}
        condition={condition}
        filterdata={filterdata}
        sortdata={sortdata}
        handleSearch={handleSearch}
        handleSorting={handleSorting}
        // activetab={activetab}
      />
      <Moreoption
        activetab={activetab}
        handleMorecategories={handleMorecategories}
        moreOptions={moreOptions}
      />
      {activetab && activetab === "artist_art_approved" ? (
        <ArtartistTable
          activetab={activetab}
          head={headDataPending}
          data={ 
            Array.isArray(currentItems)
            ? [...currentItems]
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .filter((val) => {
              if (search === "") {
                return val;
              } else if (val.name.toLowerCase().includes(search.toLowerCase())) {
                return val;
              }
            })
            .sort((a, b) => {
              if (condition.filter === "new") {
                return new Date(b.createdAt) - new Date(a.createdAt);
              } else if (condition.filter === "old") {
                return new Date(a.createdAt) - new Date(b.createdAt); 
              }
              return 0; // No sorting if no valid condition.filter
            }
      ):null}
      setArtId={setArtId}
      open={openArtDelete}
      setOpen={setOpenArtDelete}
      handleDelete={handleDelete}
        />
      ) : activetab && activetab === "artist_art_pending" ? (
        <ArtartistTable
          activetab="artist_art_pending"
          head={headDataPending}
          data={
            Array.isArray(currentItems)
            ? [...currentItems]
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .filter((val) => {
              if (search === "") {
                return val
              } else if (
                val.name.toLowerCase().includes(search.toLowerCase())
              ) {
                return val
              }
            }):null
          }
          open={openArtDelete}
          setOpen={setOpenArtDelete}
          setArtId={setArtId}
          handleDelete={handleDelete}
        />
      ) : (
        <ArtartistTable
          activetab="artist_art_rejected"
          head={headDataPending}
          data={
            Array.isArray(currentItems)
            ? [...currentItems]
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .filter((val) => {
              if (search === "") {
                return val
              } else if (
                val.name.toLowerCase().includes(search.toLowerCase())
              ) {
                return val
              }
            }):null
          }
          open={openArtDelete}
          setOpen={setOpenArtDelete}
          setArtId={setArtId}
          handleDelete={handleDelete}
        />
      )}
      {/* <Pagination
        totaldata={artdata && artdata.totalCount}
        pagination={data}
        setPagination={setData}
      /> */}
      <Box className={styles.pagination_box} sx={{}}>
          <Pagination
            count={Math.ceil(
              artdata?.data.length / itemsPerPage
            )} // Total pages
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            renderItem={(item) => (
              <PaginationItem
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "black", // Set the selected background to black
                    color: "white", // Ensure the text color is visible
                    "&:hover": {
                      backgroundColor: "black", // Maintain black on hover
                    },
                  },
                }}
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
              />
            )}
          />
        </Box>
    </div>
  )
}
