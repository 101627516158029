import React from "react";
import Pagination from "../../../../components/Pages/List/Pagination/Pagination";
import Moreoption from "../../../../components/Pages/List/Table/Moreoption/Moreoption";
import Tables from "../../../../components/Pages/List/Table/sixcolumn/Tables";
import Tableheader from "../../../../components/Pages/List/Table/Tableheader/Tableheader";
import TableheaderExhibitions from "../../../../components/Pages/List/Table/Tableheader/TableheaderExhibitions";

export default function Commonexshibitiongallery(props) {
 return (
  <div>
   <TableheaderExhibitions
    button={props.button}
    handleFilter={props.handleFilter}
    handleSorting={props.handleSorting}
    handleSearch={props.handleSearch}
    filterdata={props.filterdata}
    sortdata={props.sortdata}
    condition={props.condition}
    search={props.condition.search}
    setOpen={props.setOpen}
   />
   <Moreoption
    activetab={props.activetab}
    setActivetab={props.setActivetab}
    moreOptions={props.moreOptions}
    handleMorecategories={props.handleMorecategories}
   />
  </div>
 );
}
