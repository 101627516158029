import validator from "validator"
import isEmpty from "./isEmpty"

export const validateAdminEditMenu = (menu) => {
  let menuEditErrors = {}

  if (validator.isEmpty(menu.name)) {
    menuEditErrors.name = "Name is required"
  }

  // if (validator.isEmpty(menu.link)) {
  //   menuEditErrors.link = "Link is required"
  // } else if (!validator.isURL(menu.link)) {
  //   menuEditErrors.link = "Please enter a valid URL"
  // }

   if (validator.isEmpty(menu.link)) {
        menuEditErrors.link = "Link is required";
      } else {
        let formattedLink = menu.link.startsWith("http://") || menu.link.startsWith("https://")
          ? menu.link
          : `http://${menu.link}`;
    
        // const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]*[a-zA-Z]+[a-zA-Z0-9-]*\.(com|org|net|in|edu|gov|co|io|info|biz|me)(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;
        // const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]*[a-zA-Z]+[a-zA-Z0-9-]*\.(com|org|net|in|edu|gov|co|io|info|biz|me)(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;
    
        if (!validator.isURL(formattedLink, { require_protocol: true })) {
          menuEditErrors.link = "Please enter a valid URL starting with http:// or https://";
           }
      }

  return {
    menuEditErrors,
    isValid: isEmpty(menuEditErrors),
  }
}
