import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Container
} from "@mui/material";
import React, { useState } from "react";
import Formsubmission from "../../../../gallery/components/popups/formSubmit/Formsubmission";
import styles from "./Applyformartist.module.css";
import { useNavigate } from "react-router-dom";
import Header from "../../../../components/Pages/Header/Header";
import Navbar from "../../../../components/Pages/Navbar/Navbar";
import Footer from "../../../../components/Pages/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { fetchCategoryData } from "../../../../State/api/userApi";
import api from "../../../../State/api/Api";
import { Errornotify } from "../../../../common/alert/Alert";
import { ToastContainer } from "react-toastify";
import ReactQuill from "react-quill";
import { validationForArtistApplyform } from "../../../../validation/validationForArtistApplyform";
import { FiUpload } from "react-icons/fi";
const checkboxdt = [
  {
    id: 1,
    label: "< $250",
  },
  {
    id: 2,
    label: "$250-$1000",
  },
  {
    id: 1,
    label: "$1000-$2500",
  },
  {
    id: 1,
    label: "$2500-5000",
  },
  {
    id: 1,
    label: "$5000-10000",
  },
  {
    id: 1,
    label: ">$10000",
  },
];

const promoteWorks = [
  {
    id: 1,
    label: "I have participated in many fairs worldwide",
  },
  {
    id: 2,
    label: "My works are present in many private collections worldwide",
  },
  {
    id: 3,
    label: "I have participated to a Biennial",
  },
  {
    id: 4,
    label: "My works are present in many public collections worldwide",
  },

  {
    id: 5,
    label: "I have many followers on social media",
  },

  {
    id: 6,
    label: "I am passionate about art and want to share my works!",
  },
  {
    id: 7,
    label: "Others",
  },
];
export default function Applyformartist({
  apply,
  setApply,
  handleChangeApplyData,
  handleChangeSocialLinks,
  handleSelectCheckbox,
  handleChangeImage,
  handleImageChangeMultiple,
  handleChangeTextEditor,
  handleRemoveImage,
  selectedFiles,
}) {
  const dispatch = useDispatch();
  const [applyFormError, setApplyFormError] = useState({});
  const [artistTypes, setArtistTypes] = useState([]);
  const [othersReason, setOthersReason] = useState(
    sessionStorage.getItem("othersReason") || ""
  );
  const [othersReasonArray, setOtherReasonArray] = useState(() => {
    const storedValue = sessionStorage.getItem("othersReason");
    console.log(storedValue, "storedValue");
    return storedValue ? [storedValue] : []; // Parsing "Other" is causing the error
  });
  console.log(othersReasonArray, "othersReasonArray");
  const [othersReasonCategory, setOthersReasonCategory] = useState(
    sessionStorage.getItem("othersReasonCategory") || ""
  );
  const [othersReasonArrayCategory, setOtherReasonArrayCategory] = useState(
    () => {
      const storedValue = sessionStorage.getItem("othersReasonCategory");
      return storedValue ? [storedValue] : []; // Parse and return an empty array if null
    }
  );

  console.log(othersReasonArrayCategory, "othersReasonArrayCategory");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const renderPhotos = (source) => {
    return source.map((photo, index) => {
      return (
        <div className={styles.photoWrapper} key={index}>
          {" "}
          {/* Wrapper div with unique key */}
          <img
            className={styles.imgs}
            src={photo}
            alt=""
            style={{ cursor: "pointer" }}
          />
          <div className={styles.del_img_div}>
            <IconButton onClick={() => handleRemoveImage(index)}>
              <img
                src="/img/delete.png"
                height="15px"
                width="14px"
                alt="delete icon"
              />
            </IconButton>
          </div>
        </div>
      );
    });
  };
  const allcategory = useSelector((state) => state.allcategoryData.data);
  const apply_category_id =
    apply.category_id && apply.category_id.map((val) => val._id);
    const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]*[a-zA-Z]+[a-zA-Z0-9-]*\.(com|org|net|in|edu|gov|co|io|info|biz|me)(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;

  const youtubeRegex = /^(https?:\/\/)?(www\.)?youtube\.com\/.+$/;

  const linkedinRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|company)\/[a-zA-Z0-9_-]+(\/[a-zA-Z0-9_-]+)*\/?(\/\?.*)?$/;


  const instagramRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_.]+(\/?.*)?$/;

  const twitterRegex = /^(https?:\/\/)?(www\.)?x\.com\/[a-zA-Z0-9_]+(\/?.*)?$/;


  const facebookRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.-]+(\/?.*)?$/;

  // const art_price_range = apply.art_price_range && apply.art_price_range.map(val => val);

  useEffect(() => {
    dispatch(fetchCategoryData({}));
    if (
      !apply.promoting_by_reason.length ||
      !apply.is_fulltime_artist ||
      !apply.exclusive_work ||
      !apply.exp ||
      !apply.category_id.length ||
      !apply.art_price_range.length ||
      !apply.arts_solds_in_past_12_months ||
      !apply.produce_art_each_month
    ) {
      //  navigate("/artsmiley/artist/fillquestion")
    }
  }, []);
  const handlePopup = () => {
    //   setOpen(true);
    const { applyForm, isValidArtistApplyform } = validationForArtistApplyform(
      apply.promoting_by_reason,
  apply.is_fulltime_artist,
  apply.exclusive_work,
  apply.exp,
  apply.category_id,
  apply.art_price_range,
  apply.arts_solds_in_past_12_months,
  apply.produce_art_each_month,
  apply.artist_type_id,
  apply.portfolio,
  apply.online_art_gallary_URL,
  apply.social_links,
 apply.award_prize,
 apply.about,
 apply.art_work,
 othersReason,
 othersReasonCategory
    );
    if (isValidArtistApplyform) {
      navigate("/artist/personaldetail");
    }else {
setApplyFormError(applyForm)
    }
  };

  const fetchArtistTypes = async function () {
    try {
      const result = await api.applicationApi(
        `api/artisttype/fetchArtistTypes`,
        "GET"
      );
      if (result.status === 200 || result.status === 201) {
        const res = result.data.data.data;
        setArtistTypes(res);
      }
    } catch (err) {}
  };
  useEffect(() => {
    fetchArtistTypes();
  }, []);

  const handleKeywordChangeRemoveCategory = () => {
    setOtherReasonArrayCategory([]);
    setOthersReasonCategory("");
    sessionStorage.removeItem("othersReasonCategory");
  };
  const handleChangeKeywordCategory = (e) => {
    const { value } = e.target;
    // Uncomment this line if you want to add the value on every change

    setOthersReasonCategory(value);
    sessionStorage.setItem("othersReasonCategory", value);
  };
  // const handleKeywordChangeCategory = (event) => {
  //   if (event.key === "Enter" && othersReasonCategory.trim()) {
  //     event.preventDefault();
  //     const newKeyword = othersReasonCategory.trim();

  //     if (othersReasonArrayCategory.length > 0) {
  //       // Update the existing category with the new one
  //       setOtherReasonArrayCategory([newKeyword]);
  //     } else {
  //       // Add the new category for the first time
  //       setOtherReasonArrayCategory([newKeyword]);
  //     }

  //     // Clear the input field
  //     setOthersReasonCategory("");
  //   }
  // };

  const handleChangeKeyword = (e) => {
    const { value } = e.target;
    // Uncomment this line if you want to add the value on every change
    setOthersReason(value);
    sessionStorage.setItem("othersReason", value);
  };
  // const handleKeywordChange = (event) => {
  //   if (event.key === "Enter" && othersReason.trim()) {
  //     event.preventDefault();
  //     const newKeyword = othersReason.trim();
  //     if (!apply.promoting_by_reason.includes(newKeyword)) {
  //       setApply((prevState) => ({
  //         ...prevState,
  //         promoting_by_reason: [...prevState.promoting_by_reason, newKeyword],
  //       }));
  //       if (othersReasonArray > 0) {
  //         Errornotify("You can only enter one keyword");
  //       } else {
  //         setOtherReasonArray([newKeyword]);
  //         setOthersReason("");
  //       }
  //     }
  //   }
  // };
  const handleKeywordChangeRemove = () => {
    setOtherReasonArray([]);
    setOthersReason("");
    sessionStorage.removeItem("othersReason");
  };

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
  ];

  return (
    <>
      <Header />
      <Navbar />
      <div className={styles.mains}>
        {open ? <Formsubmission open={open} setOpen={setOpen} /> : ""}
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
     <div className={`${styles.text_center} ${styles.max_width1}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Artist Profile</h3>
          </div>
          <Grid container spacing={2} sx={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                             <Grid item xs={12} sm={10} md={8}>
                              <div className={styles.components}>
          <div>
            <div className="form-group">
            <label>Please upload your latest Portfolio in word doucment or pdf</label>
            <small className="wrtsmall">(Max Size 100MB)</small>
                <div
                  className={styles.preview_img}
                  
                >
                  {apply.CV === "" ? (
                    <div className={styles.imguplod}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        onClick={handleChangeImage}
                      >
                        <input
                          name="CV"
                          onChange={handleChangeImage}
                          type="file"
                          id="CV"
                          hidden
                          accept=".pdf, .doc, .docx" 
                        />
                        <label htmlFor="CV" className={styles.labelv}>
                    <FiUpload />
                      <p>Upload Your Latest Portfolio</p>
                    </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className={styles.imguplod}>
                        <div
                          htmlFor="input"
                          style={{ textAlign: "center" }}
                          onClick={handleChangeImage}
                        >
                          <input
                            name="CV"
                            onChange={handleChangeImage}
                            type="file"
                            id="CV"
                            hidden
                            accept=".pdf, .doc, .docx" 
                          />
                          <label htmlFor="CV" className={styles.labelv}>
                           <FiUpload />
                            <p>{apply?.CV?.name}</p>
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                </div>
            </div>
            <div className="form-group">
            <label>
            <span className="lablhdng">About Artist:</span>
            Please write about yourself, education, art qualitfications, inspiration behind your artworks etc *
                </label>
                <ReactQuill
                  className="reactQuilStle"
                   
                  value={apply.about}
                  onChange={handleChangeTextEditor("about")}
                  theme="snow"
                  modules={modules}
                  formats={formats}
                />
                  { applyFormError.about && (
      <small style={{ color: "red"}}>
       { applyFormError.about}
      </small>
    )}
                {/* {artistApplicationErrors.about && (
                      <div
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                          paddingTop: "15px",
                        }}
                      >
                        {artistApplicationErrors.about}
                      </div>
                    )} */}
            </div>
            <div className="form-group">
            <label>
                          <span className="lablhdng">Professional Details:</span>
                          What are your most important exhibitions, awards/prizes, etc? *
                          <small className="wrtsmall">Please provide us with the name, the year and the place of each experience.</small>
                          </label>
                <ReactQuill
                  className="reactQuilStle"
                  value={apply.award_prize}
                  onChange={handleChangeTextEditor("award_prize")}
                  theme="snow"
                  modules={modules}
                  formats={formats}
                />
                  { applyFormError.award_prize && (
      <small style={{ color: "red", display:"block"}}>
       { applyFormError.award_prize}
      </small>
    )}
                {/* {artistApplicationErrors.award_prize && (
                      <div
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                          paddingTop: "15px",
                        }}
                      >
                        {artistApplicationErrors.award_prize}
                      </div>
                    )} */}
            </div>
            <div className="form-group">
            <label>Upload your best artworks images *
                          <small className="wrtsmall">(Upload: Max-10 and Min-2 Artworks, File format: PNG/JPG, Max Size: 2MB)</small>
                          </label>
                <div
                  className={styles.preview_img}
                  
                >
                  <div className={styles.imguplod}>
                    <div htmlFor="input" style={{ textAlign: "center" }}>
                      <input
                        name="art_work"
                        onChange={handleImageChangeMultiple}
                        type="file"
                        id="art_work"
                        hidden
                        accept="image/*"
                      />
                      <label htmlFor="art_work" className={styles.labelv}>
                        <FiUpload />
                        <p>Upload Your Art Works</p>
                      </label>
                    </div>
                  </div>
                </div>
                { applyFormError.art_work && (
      <small style={{ color: "red", display:"block"}}>
       { applyFormError.art_work}
      </small>
    )}
                {/* {artistApplicationErrors.art_work && (
                      <div
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                          paddingTop: "15px",
                        }}
                      >
                        {artistApplicationErrors.art_work}
                      </div>
                    )} */}
            </div>
            <div className={styles.result}>{renderPhotos(selectedFiles)}</div>
            <div className="form-group">
              <label>
              Are you a full time professional artist? *
              </label>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Yes"
                name="is_fulltime_artist"
                onChange={handleChangeApplyData}
                value={apply.is_fulltime_artist}
                sx={{ display: "flex", flexDirection: "row", }}
                className={styles.radio_button}
                // onChange={handleChangeApplyData}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
              { applyFormError.is_fulltime_artist && (
      <small style={{ color: "red",}}>
       { applyFormError.is_fulltime_artist}
      </small>
    )}
            </div>
            
            <div className="form-group">
              <label>
              How many years of experience you have as an artist? *
              </label>
              <input
                value={apply.exp}
                className="form-control"
                fullWidth
                focused
                name="exp"
                placeholder="Enter Experience"
                type="text"
                error={apply.exp.trim() !== "" && isNaN(apply.exp.trim())}
                color={
                  !apply.exp
                    ? ""
                    : isNaN(apply.exp.trim())
                    ? "error"
                    : "success"
                }
                onChange={handleChangeApplyData}
              />
                  { applyFormError.exp && (
      <small style={{ color: "red"}}>
       { applyFormError.exp}
      </small>
    )}
            </div>
            <div className="form-group">
              <label>
              If you are represented by another online art gallery, Please provide URL links of your artworks on those galleries
              </label>
              <input
                className="form-control"
                fullWidth
                name="online_art_gallary_URL"
                value={apply.online_art_gallary_URL}
                focused
                error={
                  apply.online_art_gallary_URL === ""
                    ? false
                    : !apply.online_art_gallary_URL.match(regex)
                }
                color={
                  apply.online_art_gallary_URL.length === 0 ? "" : "success"
                }
                placeholder="www.example.com"
                type="text"
                onChange={handleChangeApplyData}
              />
                     { applyFormError.online_art_gallary_URL && (
      <small style={{ color: "red"}}>
       { applyFormError.online_art_gallary_URL}
      </small>
    )}
            </div>
            <div className="form-group">
              <label className={styles.input_label}>
              Please provide the link to your online portfolio (Artist Website)
              </label>
              <input
                className="form-control"
                fullWidth
                name="portfolio"
                focused
                error={
                  apply.portfolio === "" ? false : !apply.portfolio.match(regex)
                }
                color={apply.portfolio.length === 0 ? "" : "success"}
                value={apply.portfolio}
                placeholder="www.example.com"
                type="text"
                onChange={handleChangeApplyData}
              />
                     { applyFormError.portfolio && (
      <small style={{ color: "red"}}>
       { applyFormError.portfolio}
      </small>
    )}
            </div>
            <div>
              <label>
              Please provide us the links of your social media platform
              here
              </label>
              {/* {datamulti &&
        datamulti.map((item) => ( */}
              <Grid
                container
                columnSpacing={2}
              >
               <Grid item xs={12} sm={6} md={6}>
                <div className="form-group">
                <input
                    className="form-control"
                    fullWidth
                    name="linked_in"
                    focused
                    error={
                      apply.social_links.linked_in === ""
                        ? false
                        : !apply.social_links.linked_in.match(linkedinRegex)
                    }
                    color={
                      apply.social_links.linked_in.length === 0 ? "" : "success"
                    }
                    value={apply.social_links.linked_in}
                    placeholder="www.linkedin.com/in/example"
                    type="text"
                    onChange={handleChangeSocialLinks}
                  />
                         { applyFormError.linked_in && (
      <small style={{ color: "red"}}>
       { applyFormError.linked_in}
      </small>
    )}
                </div>
                  
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                <div className="form-group">
                <input
                    className="form-control"
                    fullWidth
                    focused
                    error={
                      apply.social_links.facebook === ""
                        ? false
                        : !apply.social_links.facebook.match(facebookRegex)
                    }
                    color={apply.social_links.facebook === "" ? "" : "success"}
                    name="facebook"
                    value={apply.social_links.facebook}
                    placeholder="www.facebook.com/example"
                    type="text"
                    onChange={handleChangeSocialLinks}
                  />
                         { applyFormError.facebook && (
      <small style={{ color: "red"}}>
       { applyFormError.facebook}
      </small>
    )}
                </div>
                  
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                <div className="form-group">
                <input
                    className="form-control"
                    fullWidth
                    name="instagram"
                    focused
                    error={
                      apply.social_links.instagram === ""
                        ? false
                        : !apply.social_links.instagram.match(instagramRegex)
                    }
                    color={apply.social_links.instagram === "" ? "" : "success"}
                    value={apply.social_links.instagram}
                    placeholder="www.instagram.com/example"
                    type="text"
                    onChange={handleChangeSocialLinks}
                  />
                         { applyFormError.instagram && (
      <small style={{ color: "red"}}>
       { applyFormError.instagram}
      </small>
    )}
                </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                <div className="form-group">
                <input
                    className="form-control"
                    fullWidth
                    name="twitter"
                    focused
                    error={
                      apply.social_links.twitter === ""
                        ? false
                        : !apply.social_links.twitter.match(twitterRegex)
                    }
                    color={apply.social_links.twitter === "" ? "" : "success"}
                    value={apply.social_links.twitter}
                    placeholder="www.x.com/example"
                    type="text"
                    onChange={handleChangeSocialLinks}
                  />
                         { applyFormError.twitter && (
      <small style={{ color: "red"}}>
       { applyFormError.twitter}
      </small>
    )}
                </div>
                  
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                <div className="form-group">
                <input
                    className="form-control"
                    fullWidth
                    name="youtube"
                    focused
                    error={
                      apply.social_links.youtube === ""
                        ? false
                        : !apply.social_links.youtube.match(youtubeRegex)
                    }
                    color={apply.social_links.youtube === "" ? "" : "success"}
                    value={apply.social_links.youtube}
                    placeholder="www.youtube.com/example"
                    type="text"
                    onChange={handleChangeSocialLinks}
                  />
                         { applyFormError.youtube && (
      <small style={{ color: "red"}}>
       { applyFormError.youtube}
      </small>
    )}
                </div>
                  
                </Grid>
              </Grid>

              {/* ))} */}
            </div>
            <div className="form-group">
            <label>
                 What is your main category? *
               </label>
               <div className={styles.main_divs_category}>
   
                 {allcategory &&
                  [...allcategory?.allcategories]?.sort((a, b) => (a.name === "Other Media" ? 1 : b.name === "Other Media" ? -1 : 0))?.map((item) => (
                     <RadioGroup
                       aria-labelledby="demo-radio-buttons-group-label"
                       defaultValue=""
                       name="category_id"
                       onClick={handleSelectCheckbox}
                       key={item._id}
                       sx={{ margin: "0px", padding: "0px" }}
                     >
                       <FormControlLabel
                         key={item._id}
                         sx={{
                           backgroundColor: "#f6f6f6",
                           minWidth: "180px",
                           maxWidth: "max-content",
                           borderRadius: "6px",
                           marginBottom:"5px !important",
                           marginLeft:"0 !important",
                           px: 1,
                         }}
                         value={item._id}
                         checked={apply?.category_id.some(
                           (cat) => cat._id === item._id
                         )}
                         onChange={(event) => {
                           if (!event.target.checked) {
                             handleKeywordChangeRemoveCategory();
                           }
                         }}
                         name="category_id"
                         control={<Checkbox />}
                         label={item.name}
                       />
                     </RadioGroup>
   
                     // </div>
                   ))}
               { applyFormError.category_id === "At least one category is required" && (
         <small style={{ color: "red"}}>
          { applyFormError.category_id}
         </small>
       )}
             
               </div>
               
               {apply?.category_id.some(
                 (cat) => cat._id === "67334c2511d9938babd097f3"
               ) ? (
                 // Your conditional rendering here
   
                 <input
                   className="form-control"
                   value={othersReasonCategory}
                   fullWidth
                   focused
                   startAdornment={
                     <InputAdornment position="end">↵</InputAdornment>
                   }
                   // onKeyDown={handleKeywordChangeCategory}
                   onChange={handleChangeKeywordCategory}
                   placeholder="Please specify"
                   type="text"
                 />
               ) : null}
      { applyFormError.category_id === "Other category is required" && (
         <small style={{ color: "red"}}>
          { applyFormError.category_id}
         </small>
       )}
            </div>
            <div className="form-group">
            <label>
            Please select your artwork selling price range *
            </label>
            <div className={styles.main_divs_category}>
              {checkboxdt &&
                checkboxdt?.map((item) => (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="art_price_range"
                    value={apply.art_price_range}
                    key={item.label}
                    onClick={handleSelectCheckbox}
                  >
                    <FormControlLabel
                      name="art_price_range"
                      value={item.label}
                      control={<Checkbox />}
                      label={item.label}
                      checked={apply.art_price_range.includes(item.label)}
                      sx={{ px: 2 }}
                    />
                  </RadioGroup>
                ))}
           
            </div>
            { applyFormError.art_price_range && (
      <small style={{ color: "red",display:"block"}}>
       { applyFormError.art_price_range}
      </small>
    )}
            </div>
            
            <div className="form-group">
              <label>
              How many artworks have you sold in the past 12 months? *
              </label>
              <input
                className="form-control"
                fullWidth
                name="arts_solds_in_past_12_months"
                value={apply.arts_solds_in_past_12_months}
                placeholder="Number of arts"
                type="text"
                error={
                  apply.arts_solds_in_past_12_months.trim() !== "" &&
                  isNaN(apply.arts_solds_in_past_12_months.trim())
                }
                color={
                  !apply.arts_solds_in_past_12_months
                    ? ""
                    : isNaN(apply.arts_solds_in_past_12_months.trim())
                    ? "error"
                    : "success"
                }
                onChange={handleChangeApplyData}
              />
              { applyFormError.arts_solds_in_past_12_months && (
      <small style={{ color: "red"}}>
       { applyFormError.arts_solds_in_past_12_months}
      </small>
    )}
            </div>
            
            <div className="form-group">
              <label>
              How many artworks do you produce each month? *
              </label>
              <input
                className="form-control"
                fullWidth
                name="produce_art_each_month"
                value={apply.produce_art_each_month}
                placeholder="Number of arts"
                type="text"
                onChange={handleChangeApplyData}
                error={
                  apply.produce_art_each_month?.trim() !== "" &&
                  apply.produce_art_each_month.trim() === "0" // Check if produce_art_each_month is "0"
                }
                color={
                  !apply.produce_art_each_month
                    ? ""
                    : isNaN(apply.produce_art_each_month.trim())
                    ? "error"
                    : "success"
                }
              />
              { applyFormError.produce_art_each_month && (
      <small style={{ color: "red"}}>
       { applyFormError.produce_art_each_month}
      </small>
    )}
            </div>
            
           <div className="form-group">
           <label className={styles.input_label}>
           Why do you think Art Smiley would need to select you and promote your works? *
            </label>
            <div className={styles.main_divs}>
              {promoteWorks &&
                promoteWorks?.map((item) => (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="promoting_by_reason"
                    onClick={handleSelectCheckbox}
                    key={item.id}
                  >
                    <FormControlLabel
                      key={item.id}
                      sx={{
                        backgroundColor: "#f6f6f6",
                        marginBottom:"5px !important",
                        marginLeft:"0 !important"
                      }}
                      value={item.label}
                      control={<Checkbox />}
                      onChange={(event) => {
                        if (!event.target.checked) {
                          handleKeywordChangeRemove();
                        }
                      }}
                      checked={apply.promoting_by_reason.includes(item.label)}
                      label={item.label}
                      name="promoting_by_reason"
                    />
                  </RadioGroup>
                ))}
                 { applyFormError.promoting_by_reason === "Reason to promote your work is required"&& (
      <small style={{ color: "red",}}>
       { applyFormError.promoting_by_reason}
      </small>
    )}
            </div>
            {apply.promoting_by_reason.includes("Others") ? (
              <input
                value={othersReason}
                className="form-control"
                fullWidth
                focused
                startAdornment={
                  <InputAdornment position="end">↵</InputAdornment>
                }
               
                onChange={handleChangeKeyword}
                placeholder="Please specify"
                type="text"
              />
            ) : null}
              { applyFormError.promoting_by_reason === "Please specify the other reason to promote your work."&& (
      <small style={{ color: "red"}}>
       { applyFormError.promoting_by_reason}
      </small>
    )}
           </div>
            
    <div className="form-group">
    <label className={styles.input_label}>
    Which Catergory of Artist you belong to? *
            </label>
            <div className={styles.main_divs}>
              {artistTypes?.map((val) => (
                <RadioGroup
                  key={val?._id}
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={apply.completion}
                  onClick={handleChangeApplyData}
                  name="artist_type_id"
                >
                  <FormControlLabel
                    key={val?._id}
                    value={val?._id}
                    sx={{
                      backgroundColor: "#f6f6f6",
                      width: "auto",
                      borderRadius: "6px",
                      px: 1,
                      marginLeft:"0 !important",
                      marginBottom:"5px !important"
                    }}
                    checked={apply.artist_type_id === val?._id}
                    control={<Radio />}
                    label={val?.name}
                  />
                </RadioGroup>
              ))}
            </div>
            { applyFormError.artist_type_id && (
      <small style={{ color: "red", }}>
       { applyFormError.artist_type_id}
      </small>
    )}
    
    </div>
    <div className="form-group"> 
              <label className={styles.input_label}>
              Do you prefer to work Exclusively with Art Smiley? *
              </label>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Yes"
                value={apply.exclusive_work}
                name="exclusive_work"
                onClick={handleChangeApplyData}
                sx={{ display: "flex", flexDirection: "row", marginLeft:"0 !important" }}
                className={styles.radio_button}
                // onChange={handleChangeApplyData}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup> 
            { applyFormError.exclusive_work && (
      <small style={{ color: "red", }}>
       { applyFormError.exclusive_work}
      </small>
    )}
    </div>

            {/* {othersReasonArray.length ? (
              <>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                >
                  {othersReasonArray?.map((val, index) => (
                    <FormControlLabel
                      key={index} // Use a unique key for each child
                      sx={{
                        backgroundColor: "#f6f6f6",
                        minWidth: "180px",
                        maxWidth: "max-content",
                        borderRadius: "6px",
                        px: 1,
                        pl: 2,
                      }}
                      value={val}
                      checked={true}
                      onChange={(event) => {
                        if (!event.target.checked) {
                          handleKeywordChangeRemove();
                        }
                      }}
                      control={<Checkbox sx={{ display: "none" }} />}
                      label={val} // Display the value as the label
                    />
                  ))}
                </RadioGroup>
              </>
            ) : null} */}

           
           
          </div>
         
          <div className={styles.btn_div}>
            <button
              onClick={handlePopup}
              className="apply_btn"
            >
              Next
            </button>
          </div>
        </div>
                             </Grid>
                             </Grid>

  </Container>
        
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
}
