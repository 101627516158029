import {
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  Modal,
  Stack,
  Link
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./Uploadpopup.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import homeImage from "../../../../constant";
import { useNavigate } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { subscriptionPurchase } from "../../../../State/api/userApi";
import { useDispatch } from "react-redux";
import { RiCloseCircleFill } from "react-icons/ri";
import { FiCheck } from "react-icons/fi";

export default function Uploadpopupsubscription(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
 


  const handleClose = (e) => {
    props.setOpen(false);

    navigate("/arts");
  };

  const sortedSubscriptionData = props.subscriptionData
    ? [...props.subscriptionData].sort(
        (a, b) => new Date(a.plan_price) - new Date(b.plan_price)
      )
    : [];
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className={styles.modal_del}>
            <div className={styles.main_div}>
            <div className={styles.clobtn}>
                <Link  onClick={() => {
                    props.setOpen(false);
                    navigate("/arts");
                  }}><RiCloseCircleFill className={styles.iconru} /></Link>
              </div>
              <p
                className={styles.mpophndg}
                id="transition-modal-title"
              >
			  Choose Plan
              </p>
              <Grid container spacing={2} sx={{justifyContent:"center"}}>
              {sortedSubscriptionData.map((val) => (
                 <Grid item xs={12} sm={6} md={4} key={val.id}>
                  <div
                    
                    className={
                      val.plan_name === "Standard"
                        ? styles.card_dark
                        : styles.card
                    }
                  >
                    <div
                      className={
                        val.plan_name === "Standard"
                          ? styles.card_child_dark
                          : styles.card_child
                      }
                    >
                      <h6>{val.plan_name}</h6>
                      <div className={styles.pricing}>
                        <FormControlLabel
                          required
                          control={
                            <Checkbox
                            checked={props.selectedPlans[val.plan_name]?.monthly || false}
                            onChange={props.handleCheckboxChange(val.plan_name, 'monthly')}
                            sx={{
    color: val.plan_name === "Standard" ? "white" : "black",
    '&.Mui-checked': {
      color: val.plan_name === "Standard" ? "white" : "black",
    },
  }}
                            />
                          }
                          label="monthly"
                          sx={{
    color: val.plan_name === "Standard" ? "white" : "black",
    '&.Mui-checked': {
      color: val.plan_name === "Standard" ? "white" : "black",
    },
  }}
                        />
                        <FormControlLabel
                          required
                          control={
                            <Checkbox
                            checked={props.selectedPlans[val.plan_name]?.yearly || false}
                            onChange={props.handleCheckboxChange(val.plan_name, 'yearly')}
                            sx={{
    color: val.plan_name === "Standard" ? "white" : "black",
    '&.Mui-checked': {
      color: val.plan_name === "Standard" ? "white" : "black",
    },
  }}
                            />
                          }
                          label="yearly"
                          sx={{
    color: val.plan_name === "Standard" ? "white" : "black",
    '&.Mui-checked': {
      color: val.plan_name === "Standard" ? "white" : "black",
    },
  }}
                        />
                      </div>
                      <div
                        className={
                          val.plan_name === "Standard"
                            ? styles.pricing_dark
                            : styles.prisec
                        }
                      >
                        <p className={styles.subsprice}><span className={styles.pricv}>${ props.selectedPlans[val.plan_name]?.yearly ? Math.round((val.plan_price * 12) * (1 - 0.20)).toFixed(0) : val.plan_price}</span> <span>/{props.selectedPlans[val.plan_name]?.yearly ? "yearly" : "monthly"}</span></p>
                       
                      </div>
                      <p style={{color: val.plan_name === "Standard" ? "white" : "black"}}>
                        {val?.plan_description?.length > 50
                          ? val?.plan_description?.slice(0, 30) + "..."
                          : val?.plan_description}
                      </p>
                      {val.feature_ids?.map((item) => (
                        <div
                          key={item.id}
                          className={
                            val.plan_name === "Standard"
                              ? styles.content_dark
                              : styles.content
                          }
                        >
                          {val.plan_name === "Standard" ? (
                             <div className={styles.darkd}>
                             <FiCheck className={styles.checkion} />
                           </div>
                          ) : (
                            <div className={styles.whtbx}>
                            <FiCheck className={styles.checkion} />
                          </div>
                          )}

                          <p className={styles.vpra}>
                            <span>{item._id.name}:</span> {item?._id?.count}
                          </p>
                        </div>
                      ))}
                      {val.plan_name === "Standard" ? (
                        <div className={styles.btn_div}>
                          <button 
                            className="action_libtn"
                            onClick={() =>
                              navigate(`/user/mycart-subscription/${val?._id}`,{state: {planName:props.selectedPlans[val.plan_name]?.yearly ? "yearly" : "monthly"}  })
                            }
                          >
                            Choose Plan
                          </button>
                        </div>
                      ) : (
                        <div className={styles.btn_div}>
                          <button 
                           className="action_btn"
                            onClick={() => {
                              if (val?.plan_name === "Basic") {
                                dispatch(
                                  subscriptionPurchase({
                                    subscriptionName: val?._plan_name,
                                    artistId: localStorage.getItem("user_id"),
                                    subscriptionId: val?._id,
                                    subscriptionTenure: "monthly",
                                    paymentType: "Paypal",
                                    transactionId: "",
                                    amount: 0,
                                  })
                                );
                              } else {
                                navigate(`/user/mycart-subscription/${val?._id}`, {
                                  state: { planName: props.selectedPlans[val.plan_name]?.yearly ? "yearly" : "monthly" },
                                });
                              }
                            }}
                            // onClick={() =>
                            //   navigate(`/user/mycart-subscription/${val?._id}`,{state: {planName:props.selectedPlans[val.plan_name]?.yearly ? "yearly" : "monthly"}  })
                            // }
                          >
                            Choose Plan
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                 </Grid>
                ))}
              </Grid>
               
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
