import { Box, Button } from "@mui/material";
import React from "react";
import styles from "./webPopupCuretorAndAffilate.module.css";
import homeImage from "../../../../../constant";
import { useNavigate } from "react-router-dom";

export default function WebpopupCuretor({open,setOpen}) {
  const navigate = useNavigate();
  return (
    <>
      {/* {
            props.type === 'web' ?
                // <Box sx={{ mt: { xs: '-20px', sm: '-64px' } }} className={props.data.length === props.i + 1 ? styles.cancel_imgweb : styles.cancel_img_hide_web} >
                //     {props.data.length === props.i + 1 ?
                //         <img alt='img' onClick={() => props.setOpen(false)} src={ '/img/cancel_cross.png'} height='20px' width='20px' />
                //         : ''}
                // </Box> 
                "" :
                <div className={props.data.length === props.i + 1 ? styles.cancel_img : styles.cancel_img_hide} >
                    {props.data.length === props.i + 1 ?
                        <img alt='img' onClick={() => props.setOpen(false)} src={ '/img/cancel_cross.png'} height='20px' width='20px' />
                        : ''}
                </div>
        } */}
<div className={styles.icdvn}><img alt="img" src="/img/new-curator.svg" width="50px" height="50px" /></div>
      <div className={styles.card_content}>
      <div>
      <h3>  {open === "AffiliateAndCuratorSignIn" ? "Curator" : "I Am A Curator"}</h3>
      <h5>{open === "AffiliateAndCuratorSignIn" ? "Curate & Inspire" : ""}</h5>
        <h6>{open === "AffiliateAndCuratorSignIn" ? "Sign in to connect with artists, curate outstanding pieces, and earn commissions on every sale." : "You are an Independent Curator / Contemporary Art Curator / Gallery Curator / Digital Art Curator / Photography Curator / Public Art Curator"}</h6>
       
      </div>
         <button
          className={`apply_btn ${styles.ml_auto}`}
          onClick={() => {
            if(open === "AffiliateAndCuratorSignIn" ) {
              navigate("/login",{state: {type: "curator"}})
            }else {
              navigate("/curator/applynow")
              setOpen(false)
            }
          }}
        >
      {open === "AffiliateAndCuratorSignIn" ? "Sign In" : "Apply Now"}
        </button>
      </div>
    </>
  );
}
