import validator from "validator"
import isEmpty from "./isEmpty"

export const userProfileValidation = ( { 
  address_line1,
flat_no,
city,
state,
country,
}) => {
  let errors = {}
  if (validator.isEmpty(address_line1)) {
    errors.address_line1 = "Address line is required"
  }
  if (validator.isEmpty(flat_no)) {
    errors.flat_no = "zipcode is required"
  }

  if (validator.isEmpty(city)) {
    errors.city = "City is required"
  }
  if (validator.isEmpty(state)) {
    errors.state = "State is required"
  }

  if (validator.isEmpty(country)) {
    errors.country = "Country is required"
  }

  return {
    errors,
    isValidAddress: isEmpty(errors),
  }
}
