import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Container
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import Formsubmission from '../../../../gallery/components/popups/formSubmit/Formsubmission';
import styles from "./Affiliateapplyform.module.css";
import { useNavigate } from "react-router-dom";
import country from "../../../../Country";
import Header from "../../../../components/Pages/Header/Header";
import Navbar from "../../../../components/Pages/Navbar/Navbar";
import Entermobile from "../../../../curator/pages/applyForm/mobileNumber/Entermobile";
import { fetchAffiliateType } from "../../../../State/api/superadminApi";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { Errornotify } from "../../../../common/alert/Alert";
import { ToastContainer } from "react-toastify";
import { validationForJoinAffiliate } from "../../../../validation/joinAffiliateValidation";
import EntermobileArtist from "../../../../curator/pages/applyForm/mobileNumber/EntermobileArtist";
import api from "../../../../State/api/Api";

const languages = [
  { code: "en", name: "English" },
  { code: "hi", name: "Hindi" },
  { code: "ar", name: "Arabic" },
  { code: "es", name: "Spanish" },
  { code: "fr", name: "French" },
  { code: "de", name: "German" },
  { code: "zh", name: "Chinese" },
  { code: "ja", name: "Japanese" },
  { code: "ru", name: "Russian" },
  { code: "pt", name: "Portuguese" },
  { code: "it", name: "Italian" },
  { code: "ko", name: "Korean" },
  { code: "tr", name: "Turkish" },
  { code: "vi", name: "Vietnamese" },
  { code: "nl", name: "Dutch" },
  { code: "sv", name: "Swedish" },
  { code: "pl", name: "Polish" },
  { code: "uk", name: "Ukrainian" },
  { code: "el", name: "Greek" },
  { code: "he", name: "Hebrew" },
  { code: "th", name: "Thai" },
  { code: "cs", name: "Czech" },
  { code: "fi", name: "Finnish" },
  { code: "no", name: "Norwegian" },
  { code: "da", name: "Danish" },
  { code: "hu", name: "Hungarian" },
  { code: "ro", name: "Romanian" },
  { code: "sk", name: "Slovak" },
  { code: "bg", name: "Bulgarian" },
  { code: "hr", name: "Croatian" },
  { code: "sr", name: "Serbian" },
  { code: "lt", name: "Lithuanian" },
  { code: "lv", name: "Latvian" },
  { code: "et", name: "Estonian" },
  { code: "sl", name: "Slovenian" },
  { code: "is", name: "Icelandic" },
  { code: "ga", name: "Irish" },
  { code: "mt", name: "Maltese" },
  { code: "cy", name: "Welsh" },
  { code: "sq", name: "Albanian" },
  { code: "mk", name: "Macedonian" },
  { code: "bs", name: "Bosnian" },
  { code: "ms", name: "Malay" },
  { code: "sw", name: "Swahili" },
  { code: "am", name: "Amharic" },
  { code: "fa", name: "Persian" },
  { code: "ur", name: "Urdu" },
  { code: "bn", name: "Bengali" },
  { code: "ta", name: "Tamil" },
  { code: "te", name: "Telugu" },
  { code: "mr", name: "Marathi" },
  { code: "gu", name: "Gujarati" },
  { code: "kn", name: "Kannada" },
  { code: "ml", name: "Malayalam" },
  { code: "pa", name: "Punjabi" },
  { code: "si", name: "Sinhala" },
  { code: "ne", name: "Nepali" },
  { code: "km", name: "Khmer" },
  { code: "my", name: "Burmese" },
  { code: "lo", name: "Lao" },
  { code: "mn", name: "Mongolian" },
  { code: "kk", name: "Kazakh" },
  { code: "uz", name: "Uzbek" },
  { code: "hy", name: "Armenian" },
  { code: "az", name: "Azerbaijani" },
  { code: "ka", name: "Georgian" },
  // Add more languages as needed
];

// const data = [
//   {
//     type: "select",
//     label: "Account Type",
//     placeholder: "Select type",
//     name: "account_type",
//   },
//   {
//     type: "text",
//     label: "Company name",
//     placeholder: "Enter name",
//     name: "company_name",
//   },
//   {
//     type: "text",
//     label: "First name",
//     placeholder: "Enter name",
//     name: "first_name",
//   },
//   {
//     type: "text",
//     label: "Last name",
//     placeholder: "Enter name",
//     name: "last_name",
//   },
//   {
//     type: "text",
//     label: "User name",
//     placeholder: "Enter name",
//     name: "user_name",
//   },
//   {
//     type: "text",
//     label: "Email",
//     placeholder: "Enter email",
//     name: "email",
//   },
//   {
//     type: "text",
//     label: "Password",
//     placeholder: "Enter password",
//     name: "password",
//   },
//   {
//     type: "text",
//     label: "Confirm password",
//     placeholder: "Enter confirm password",
//     name: "confirm_password",
//   },
//   {
//     type: "select",
//     label: "Country",
//     placeholder: "select",
//     name: "country",
//   },
//   {
//     type: "select",
//     label: "Language",
//     placeholder: "select",
//     name: "language",
//   },
//   {
//     type: "text",
//     label: "Phone number",
//     placeholder: "Enter number",
//     name: "mobile",
//   },
//   {
//     type: "text",
//     label: "Skype",
//     placeholder: "Enter id",
//     name: "skyp_id",
//   },
// ];

// const top100Films = [
//   { label: "The Shawshank Redemption", year: 1994 },
//   { label: "The Godfather", year: 1972 },
//   { label: "The Godfather: Part II", year: 1974 },
//   { label: "The Dark Knight", year: 2008 },
//   { label: "12 Angry Men", year: 1957 },
//   { label: "Schindler's List", year: 1993 },
//   { label: "Pulp Fiction", year: 1994 },
// ];
const phoneLengthByCountry = {
  ad: 9, // Andorra
  af: 9, // Afghanistan
  al: 9, // Albania
  dz: 9, // Algeria
  ao: 9, // Angola
  ag: 10, // Antigua and Barbuda
  ar: 10, // Argentina
  am: 8, // Armenia
  aw: 7, // Aruba
  au: 9, // Australia
  at: 10, // Austria
  az: 9, // Azerbaijan
  bh: 8, // Bahrain
  bd: 10, // Bangladesh
  bn: 7, // Brunei
  bs: 10, // Bahamas
  bb: 10, // Barbados
  by: 9, // Belarus
  bq: 7, // Caribbean Netherlands
  be: 9, // Belgium
  bz: 7, // Belize
  bj: 8, // Benin
  bt: 8, // Bhutan
  bo: 8, // Bolivia
  ba: 8, // Bosnia and Herzegovina
  bw: 7, // Botswana
  br: 11, // Brazil
  bg: 9, // Bulgaria
  bf: 8, // Burkina Faso
  bi: 8, // Burundi
  kh: 9, // Cambodia
  cm: 9, // Cameroon
  ca: 10, // Canada
  cv: 7, // Cape Verde
  ci: 8, // Ivory Coast
  cw: 7, // Curaçao
  cg: 9, // Congo - Brazzaville
  cd: 9, // Congo - Kinshasa
  cf: 8, // Central African Republic
  td: 8, // Chad
  cl: 9, // Chile
  cn: 11, // China
  co: 10, // Colombia
  km: 7, // Comoros
  cr: 8, // Costa Rica
  hr: 9, // Croatia
  cu: 8, // Cuba
  cy: 8, // Cyprus
  cz: 9, // Czech Republic
  dk: 8, // Denmark
  dj: 8, // Djibouti
  dm: 10, // Dominica
  do: 10, // Dominican Republic
  ec: 9, // Ecuador
  eg: 10, // Egypt
  sv: 8, // El Salvador
  gq: 9, // Equatorial Guinea
  er: 7, // Eritrea
  ee: 7, // Estonia
  et: 9, // Ethiopia
  fj: 7, // Fiji
  fi: 9, // Finland
  fr: 9, // France
  ga: 7, // Gabon
  gm: 8, // Gambia
  gp: 9, // Guadeloupe
  gu: 10, // Guam
  ge: 9, // Georgia
  de: 10, // Germany
  gh: 9, // Ghana
  gr: 10, // Greece
  gd: 10, // Grenada
  gf: 9, // French Guiana
  gt: 8, // Guatemala
  gn: 9, // Guinea
  gw: 9, // Guinea-Bissau
  gy: 7, // Guyana
  ht: 8, // Haiti
  hn: 8, // Honduras
  hk: 8, // Hong Kong
  hu: 9, // Hungary
  is: 7, // Iceland
  in: 10, // India
  id: 10, // Indonesia
  io: 7, // British Indian Ocean Territory
  ir: 10, // Iran
  iq: 10, // Iraq
  ie: 9, // Ireland
  il: 9, // Israel
  it: 10, // Italy
  jm: 7, // Jamaica
  jp: 10, // Japan
  jo: 9, // Jordan
  kz: 10, // Kazakhstan
  kp: 9, // North Korea
  ke: 10, // Kenya
  ki: 7, // Kiribati
  ks: 8, // Kosovo
  kn: 7, // Saint Kitts and Nevis
  kw: 8, // Kuwait
  kg: 9, // Kyrgyzstan
  la: 9, // Laos
  lv: 8, // Latvia
  lb: 8, // Lebanon
  ls: 8, // Lesotho
  lr: 7, // Liberia
  ly: 9, // Libya
  li: 7, // Liechtenstein
  lt: 8, // Lithuania
  lu: 9, // Luxembourg
  lc: 7, // Saint Lucia
  mo: 8, // Macau
  mg: 10, // Madagascar
  mw: 9, // Malawi
  my: 10, // Malaysia
  mv: 7, // Maldives
  ml: 8, // Mali
  mk: 8, // Macedonia
  mq: 9, // Martinique
  mt: 8, // Malta
  mh: 7, // Marshall Islands
  mr: 8, // Mauritania
  mu: 8, // Mauritius
  mx: 10, // Mexico
  fm: 7, // Micronesia
  md: 8, // Moldova
  mc: 9, // Monaco
  mn: 8, // Mongolia
  me: 8, // Montenegro
  ma: 9, // Morocco
  mz: 9, // Mozambique
  mm: 8, // Myanmar
  na: 9, // Namibia
  nc: 8, // New Caledonia
  nr: 7, // Nauru
  np: 10, // Nepal
  nl: 9, // Netherlands
  nz: 9, // New Zealand
  ni: 8, // Nicaragua
  ne: 8, // Niger
  ng: 10, // Nigeria
  no: 8, // Norway
  om: 8, // Oman
  pk: 10, // Pakistan
  pw: 7, // Palau
  pa: 7, // Panama
  pf: 8, // French Polynesia
  pg: 8, // Papua New Guinea
  py: 9, // Paraguay
  pe: 9, // Peru
  ph: 10, // Philippines
  pl: 9, // Poland
  pt: 9, // Portugal
  ps: 9, // Palestine
  pr: 10, // Puerto Rico
  qa: 8, // Qatar
  ro: 10, // Romania
  ru: 10, // Russia
  re: 9, // Reunion
  rw: 9, // Rwanda
  ws: 7, // Samoa
  sa: 9, // Saudi Arabia
  sn: 9, // Senegal
  sm: 9, // San Marino
  st: 7, // Sao Tome and Principe
  ss: 9, // South Sudan
  sz: 8, // Swaziland
  rs: 9, // Serbia
  sc: 7, // Seychelles
  sl: 8, // Sierra Leone
  sg: 8, // Singapore
  sk: 9, // Slovakia
  si: 8, // Slovenia
  sb: 7, // Solomon Islands
  so: 7, // Somalia
  za: 9, // South Africa
  kr: 10, // South Korea
  es: 9, // Spain
  lk: 9, // Sri Lanka
  sd: 9, // Sudan
  sr: 7, // Suriname
  se: 9, // Sweden
  ch: 9, // Switzerland
  sy: 9, // Syria
  tw: 9, // Taiwan
  tj: 9, // Tajikistan
  tz: 9, // Tanzania
  th: 9, // Thailand
  tl: 7, // Timor-Leste
  tg: 8, // Togo
  to: 7, // Tonga
  tt: 10, // Trinidad and Tobago
  tn: 8, // Tunisia
  tr: 10, // Turkey
  tm: 8, // Turkmenistan
  tv: 6, // Tuvalu
  ug: 9, // Uganda
  ua: 9, // Ukraine
  vc: 7, // Saint Vincent and the Grenadines
  va: 9, // Vatican CityS
  ae: 9, // United Arab Emirates
  gb: 10, // United Kingdom
  us: 10, // United States
  uy: 9, // Uruguay
  uz: 9, // Uzbekistan
  vu: 7, // Vanuatu
  ve: 7, // Venezuela
  vn: 10, // Vietnam
  ye: 9, // Yemen
  zm: 9, // Zambia
  zw: 9, // Zimbabwe
};
export default function Affiliateapplyform({
  apply,
  handleChangeApplyData,
  setApply,
}) {
  const [image, setImage] = useState("");
  const [joinAffiliateError, setJoinAffiliateError] = useState({});
  const [affiliatetype, setAffiliateType] = useState(null);
  const [error, setError] = useState("");
  const [src, setSrc] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleFetchAffiliateType = () => {
    dispatch(fetchAffiliateType(setAffiliateType));
  };
  const handleChangeImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      const imgs = URL.createObjectURL(e.target.files[0]);
      setImage(e.target.files[0]);
      setSrc(imgs);
    }
  };

  useEffect(() => {
    handleFetchAffiliateType();
  }, []);
  // useEffect(() => {
  //   handleFetchAffiliateType()
  //   if (
  //     apply.affiliate_account_type_id === "" ||
  //     apply.company_name === "" ||

  //     apply.first_name === "" ||
  //     apply.last_name === "" ||

  //     apply.country === "" ||
  //     apply.language === "" ||
  //     apply.phone === 0

  //   ) {
  //     navigate("/artsmiley/affiliate/fillquestion")
  //   }
  // }, [])
  const handlePopup = () => {
    setOpen(true);
  };
  const handleChangeNumber = (value, country) => {
    // Get country code and expected length
    const countryCode = country.countryCode; // e.g., 'in'
    const dialCode = country.dialCode; // e.g., '91'
    const expectedLength = phoneLengthByCountry[countryCode]; // e.g., 10 for India

    // Clean the phone number input, stripping non-numeric characters
    let phoneNumber = value.replace(/[^0-9]/g, "");

    // Remove the dial code from the phone number before validation
    if (phoneNumber.startsWith(dialCode)) {
      phoneNumber = phoneNumber.slice(dialCode.length);
    }

    // Limit the input to the expected length
    if (expectedLength && phoneNumber.length === expectedLength) {
      phoneNumber = phoneNumber.slice(0, expectedLength);
    }

    // Construct the formatted value with the country dial code
    const formattedValue = `+${dialCode}${phoneNumber}`;

    // Update the phone state with the formatted value
    setApply({ ...apply, phone: formattedValue });

    // Validate length for error display
    if (expectedLength && phoneNumber.length !== expectedLength) {
      setError(
        `Phone number must be ${expectedLength} digits long for this country code`
      );
    } else {
      setError(""); // Clear error if the length is valid
    }
  };

  const [email_errors, setEmail_Errors] = useState({});
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleCheckEmailExistOrNot = async () => {
    const {data} = await api.applicationApi("api/user/checkEmailIsExistOrNot", "POST", {email: apply.email,role : "affiliate"})
   
    if(data.success === false) {
      setEmail_Errors({...email_errors, email: "This email is already registered. Please use a different email address."})
    
      setIsEmailValid(false);
    }else {
      setEmail_Errors({...email_errors, email: ""})
      setIsEmailValid(true);
    }
  }

  const handleEmailValidation = (email) => {
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) && email.endsWith(".com");
  };
  // useEffect(() => {
  //   if ( apply.email && handleEmailValidation(apply.email)) {
  //  handleCheckEmailExistOrNot()
  //   }
  // },[apply.email])

  return (
    <>
      <Header />
      <Navbar />
      <div className={styles.mains}>
         <Container maxWidth="xl" sx={{
            paddingLeft: { xs: '16px', sm: '24px', md: '100px' },
            paddingRight: { xs: '16px', sm: '24px', md: '100px' },
          }}>
            <div className={`${styles.text_center} ${styles.max_width1}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Join as Affiliate</h3>
          </div>
          <Grid container spacing={2} sx={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                       <Grid item xs={12} sm={10} md={8}>
                                      <div className={styles.components}>
          <div>
            <div className="form-group">
              <label>
                Please select your Account Type *
              </label> 
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="affiliate_account_type_id"
                onChange={handleChangeApplyData}
                value={apply.affiliate_account_type_id}
                sx={{ display: "flex", flexDirection: "row", mt: 0.8 }}
                className={styles.radio_button}
              >
                {affiliatetype &&
                  affiliatetype.map((vl) => (
                    <FormControlLabel
                      value={vl._id}
                      control={<Radio />}
                      label={vl.name}
                    />
                  ))}
              </RadioGroup>
              {apply.affiliate_account_type_id === "66307a1f62041dbbcad0609c" && (
              <div className="form-group">
                <label>
                  What is your company name? *
                </label>
                <input
                  className="form-control"
                  name="company_name"
                  value={apply.company_name}
                  placeholder="Enter your company name"
                  onChange={handleChangeApplyData}
                 
                />
                {joinAffiliateError?.company_name ? (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {joinAffiliateError?.company_name}
                  </span>
                ) : null}
              </div>
              )}
            </div>
            <div>
            <Grid
                                container
                                columnSpacing={2}
                              >
                                <Grid item xs={12} sm={6} md={6}>
									<div className="form-group">
                  <label>
                  What is your first name? *
                </label>
                <input
                  className="form-control"
                  name="first_name"
                  value={apply.first_name}
                  placeholder="Enter your first name"
                  onChange={handleChangeApplyData}
                  
                />
                {joinAffiliateError?.first_name ? (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {joinAffiliateError?.first_name}
                  </span>
                ) : null}
                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
									<div className="form-group">
                  <label>
                  What is your last name? *
                </label>
                <input
                  className="form-control"
                  name="last_name"
                  value={apply.last_name}
                  placeholder="Enter your last name"
                  onChange={handleChangeApplyData}
                  
                />
                {joinAffiliateError?.last_name ? (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {joinAffiliateError?.last_name}
                  </span>
                ) : null}
                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
									<div className="form-group">
                  <label>
                  What is your email address? *
                </label>
                <input
                  className="form-control"
                  name="email"
                  value={apply.email}
                  onChange={handleChangeApplyData}
                  placeholder="Enter your email address"
                  
                />
                {joinAffiliateError?.email && !handleEmailValidation(apply.email)  && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {joinAffiliateError?.email}
                  </span>
               ) }
                 {!joinAffiliateError?.email &&  email_errors?.email && (
  <span
    style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
  >
    {email_errors.email}
  </span>
)}
                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
									<div className="form-group">
                  <label>
                  What is your country of residence? *
                </label> 
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  name="country"
                  value={apply.country}
                  onChange={handleChangeApplyData}
                  // variant=""
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    },
                    "& .MuiInputBase-input":{
                    padding:".45rem .75rem",
                    height:"auto",
                    borderRadius:".25rem",
                    fontSize:"16px",
                    fontWeight:"400"
                    },
                    border:"1px solid #ced4da",
                    borderRadius:".25rem",
                    width:"100%"
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {country.map((c) => (
                    <MenuItem value={c.label}>{c.label}</MenuItem>
                  ))}
                </Select>
                {joinAffiliateError?.country ? (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {joinAffiliateError?.country}
                  </span>
                ) : null}
                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
									<div className="form-group">
                  <label>
                  What is your preferred language? *
                </label> 
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  name="language"
                  value={apply.language}
                  onChange={handleChangeApplyData}
                  // variant=""
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    },
                    "& .MuiInputBase-input":{
                    padding:".45rem .75rem",
                    height:"auto",
                    borderRadius:".25rem",
                    fontSize:"16px",
                    fontWeight:"400"
                    },
                    border:"1px solid #ced4da",
                    borderRadius:".25rem",
                    width:"100%"
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {languages.map((c) => (
                    <MenuItem value={c.name}>{c.name}</MenuItem>
                  ))}
                </Select>
                {joinAffiliateError?.language ? (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {joinAffiliateError?.language}
                  </span>
                ) : null}
                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
									<div className="form-group">
                  <label>
                  What is your phone number? *
                </label>
             
                <EntermobileArtist
                  value={apply.phone}
                  handleChangeNumber={(value, country) =>
                    handleChangeNumber(value, country)
                  }
                />
              
                {joinAffiliateError?.phone ? (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {joinAffiliateError?.phone}
                  </span>
                ) : null}
                {!joinAffiliateError?.phone && error && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {error}
                  </span>
                )}
                  </div>
                                </Grid>
                                </Grid>
            </div>
            <div className={styles.btn_div}>
        <button
  onClick={() => {
    const { isValid, joinAffiliateErrors } = validationForJoinAffiliate(
      apply.first_name,
      apply.last_name,
      apply.email,
      apply.company_name,
      apply.phone,
      apply.country,
      apply.language,
      phoneLengthByCountry,
      apply.affiliate_account_type_id // Pass the selected ID
    );

    // Proceed if everything is valid
    if (isValid && error.length === 0 && isEmailValid) {
      navigate("/affiliate/applyformsecond");
    } else {
      setJoinAffiliateError(joinAffiliateErrors);
    }
  }}
  className="apply_btn"
>
  Next
</button>

        </div>

          </div>
          
        </div>

                                       </Grid>
                                       </Grid>
          </Container>
        {/* {open ? <Formsubmission open={open} setOpen={setOpen} /> : ''} */}
        
        
      </div>
      <ToastContainer />
    </>
  );
}
