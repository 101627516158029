import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function PrintTableArtsmiley({
  print_percentage,
  printPricingResponse,
  getProductOptions,
  width,
  height,
  basePrice,
  props,
  pricingData,
  setPricingData,
}) {

  const calculatePrice = (height, width, price, product, basePrice, print_percentage) => {
    let printPrice;
    const remainingPercent = (100 - print_percentage) / 100;


    const sizeKey = `${product}_${width}x${height}`;
    switch (product) {
      case "canvas print":
        const printCal = (height / 100) * (width / 100) * price;
        printPrice = (printCal + basePrice) / remainingPercent;

        // Store dimensions and price with size-specific key
        localStorage.setItem(`${sizeKey}_base`, JSON.stringify(printCal));
        localStorage.setItem(`${sizeKey}_canvas_print_total`, JSON.stringify(printPrice));

        break;

      case "canvas print and stretch":
        const stretchCal = ((height / 100) + (width / 100)) * 2 * price;
        const canvasPrintBase = Number(localStorage.getItem(`canvas print_${width}x${height}_base`)) || 0;

        printPrice = ((stretchCal + canvasPrintBase) + basePrice) / remainingPercent;

        localStorage.setItem(`${sizeKey}_base`, JSON.stringify(stretchCal));
        localStorage.setItem(`${sizeKey}_canvas_Print_strech_total`, JSON.stringify(printPrice));

        break;


      case "canvas print with stretching and framing":
        const framingCal = ((height / 100) + (width / 100)) * 2 * price;

        // Retrieve previously stored base prices for print and stretch
        const canvasPrintBase1 = Number(localStorage.getItem(`canvas print_${width}x${height}_base`)) || 0;
        const canvasStretchBase = Number(localStorage.getItem(`canvas print and stretch_${width}x${height}_base`)) || 0;

        printPrice = ((framingCal + canvasPrintBase1 + canvasStretchBase + basePrice)) / remainingPercent;

        localStorage.setItem(`${sizeKey}_base`, JSON.stringify(framingCal));
        localStorage.setItem(`${sizeKey}_canvas_print_stretch_framing`, JSON.stringify(printPrice));
        break;

      case "fine art print":
        const fineArtPrintBase = (height / 100) * (width / 100) * price;
        printPrice = (fineArtPrintBase + basePrice) / remainingPercent;

        // Store dimensions and price with size-specific key
        localStorage.setItem(`${sizeKey}_base`, JSON.stringify(fineArtPrintBase));
        localStorage.setItem(`${sizeKey}_fine_art_print_total`, JSON.stringify(printPrice));

        break;

      case "mounting":
        const fineArtmountingbase = (height / 100) * (width / 100) * price;
        printPrice = (fineArtmountingbase + basePrice) / remainingPercent;

        // Store dimensions and price with size-specific key
        // Store with unique key for mounting
        localStorage.setItem(`${sizeKey}_mount_base`, JSON.stringify(fineArtmountingbase));
        localStorage.setItem(`${sizeKey}_mount_total`, JSON.stringify(printPrice));

        break;

      case "fine art print with framing":
        const fineArtFramingBase = ((height / 100) + (width / 100)) * 2 * price;

        // Retrieve the previous values for print and mounting from localStorage using the size-specific keys
        const fineArtPrintBase1 = Number(localStorage.getItem(`fine art print_${width}x${height}_base`)) || 0;
        const fineArtmountingbase1 = Number(localStorage.getItem(`mounting_${width}x${height}_mount_base`)) || 0;

        printPrice = ((fineArtFramingBase + fineArtPrintBase1 + fineArtmountingbase1) + basePrice) / remainingPercent;

        localStorage.setItem(`${sizeKey}_framing_base`, JSON.stringify(fineArtFramingBase));
        localStorage.setItem(`${sizeKey}_print_with_framing`, JSON.stringify(printPrice));

        break;

      case "photo paper print":
        const photoPaperPrintBase = (height / 100) * (width / 100) * price;
        printPrice = (photoPaperPrintBase + basePrice) / remainingPercent;

        // Store with dimension-specific keys
        localStorage.setItem(`${sizeKey}_photo_paper_print_base`, JSON.stringify(photoPaperPrintBase));
        localStorage.setItem(`${sizeKey}_photo_paper_print_total`, JSON.stringify(printPrice));

        console.log(`Stored: ${sizeKey}_photo_paper_print_base = ${photoPaperPrintBase}`);
        break;

      case "mounting":
        const photoPaperMountingBase = (height / 100) * (width / 100) * price;
        printPrice = (photoPaperMountingBase + basePrice) / remainingPercent;

        // Store with dimension-specific keys
        localStorage.setItem(`${sizeKey}_mount_base`, JSON.stringify(photoPaperMountingBase));
        localStorage.setItem(`${sizeKey}_mount_total`, JSON.stringify(printPrice));

        console.log(`Stored: ${sizeKey}_mount_base = ${photoPaperMountingBase}`);
        break;

      case "photo paper print with framing":
        const photoPaperFramingBase = ((height / 100) + (width / 100)) * 2 * price;

        // Retrieve values using dimension-specific keys
        const photoPaperPrintStored = Number(localStorage.getItem(`photo paper print_${width}x${height}_photo_paper_print_base`)) || 0;
        const photoPaperMountingStored = Number(localStorage.getItem(`mounting_${width}x${height}_mount_base`)) || 0;

        printPrice = ((photoPaperFramingBase + photoPaperPrintStored + photoPaperMountingStored) + basePrice) / remainingPercent;

        // Store final values with dimension-specific keys
        localStorage.setItem(`${sizeKey}_photo_paper_framing_base`, JSON.stringify(photoPaperFramingBase));
        localStorage.setItem(`${sizeKey}_photo_paper_print_with_framing_total`, JSON.stringify(printPrice));
        break;

      case "poster":
        const posterBase = (height / 100) * (width / 100) * price;
        printPrice = (posterBase + basePrice) / remainingPercent;

        // Store with dimension-specific keys
        localStorage.setItem(`${sizeKey}_poster_base`, JSON.stringify(posterBase));
        localStorage.setItem(`${sizeKey}_poster_total`, JSON.stringify(printPrice));

        console.log(`Stored: ${sizeKey}_poster_base = ${posterBase}`);
        break;

      case "mounting":
        const posterMountingBase = (height / 100) * (width / 100) * price;
        printPrice = (posterMountingBase + basePrice) / remainingPercent;

        // Store with dimension-specific keys
        localStorage.setItem(`${sizeKey}_mount_base`, JSON.stringify(posterMountingBase));
        localStorage.setItem(`${sizeKey}_mount_total`, JSON.stringify(printPrice));

       
        break;

      case "poster with framing":
        const posterFramingBase = ((height / 100) + (width / 100)) * 2 * price;

        // Retrieve values using dimension-specific keys
        const posterStored = Number(localStorage.getItem(`poster_${width}x${height}_poster_base`)) || 0;
        const posterMountingStored = Number(localStorage.getItem(`mounting_${width}x${height}_mount_base`)) || 0;



        // Calculate the final price
        printPrice = ((posterFramingBase + posterStored + posterMountingStored) + basePrice) / remainingPercent;

        // Store final values with dimension-specific keys
        localStorage.setItem(`${sizeKey}_poster_framing_base`, JSON.stringify(posterFramingBase));
        localStorage.setItem(`${sizeKey}_poster_with_framing_total`, JSON.stringify(printPrice));


        break;

      case "metal print":
        const metalPrintBase = (height / 100) * (width / 100) * price;
        printPrice = (metalPrintBase + basePrice) / remainingPercent;

        // Store with dimension-specific keys
        localStorage.setItem(`${sizeKey}_metal_print_base`, JSON.stringify(metalPrintBase));
        localStorage.setItem(`${sizeKey}_metal_print_total`, JSON.stringify(printPrice));


        break;

      case "metal print with aluminium hanging":
        const metalHangingBase = ((height / 100) + (width / 100)) * 2 * price;

        // Retrieve the metal print base for this specific size
        const metalPrintStored = Number(localStorage.getItem(`metal print_${width}x${height}_metal_print_base`)) || 0;



        // Calculate final price including the hanging cost
        printPrice = ((metalHangingBase + metalPrintStored) + basePrice) / remainingPercent;

        // Store the aluminium hanging base and total with dimension-specific keys
        localStorage.setItem(`${sizeKey}_metal_aluminium_hanging_base`, JSON.stringify(metalHangingBase));
        localStorage.setItem(`${sizeKey}_metal_print_with_aluminium_hanging_total`, JSON.stringify(printPrice));


        break;

      case "acrylic printing":
        const acrylicPrintBase = (height / 100) * (width / 100) * price;
        printPrice = (acrylicPrintBase + basePrice) / remainingPercent;

        // Store with dimension-specific keys
        localStorage.setItem(`${sizeKey}_acrylic_print_base`, JSON.stringify(acrylicPrintBase));
        localStorage.setItem(`${sizeKey}_acrylic_print_total`, JSON.stringify(printPrice));


        break;

      case "acrylic print with aluminium hanging":
        const acrylicHangingBase = ((height / 100) + (width / 100)) * 2 * price;

        // Retrieve acrylic print base for this specific size
        const acrylicPrintStored = Number(localStorage.getItem(`acrylic printing_${width}x${height}_acrylic_print_base`)) || 0;


        // Calculate final price with aluminium hanging
        printPrice = ((acrylicHangingBase + acrylicPrintStored) + basePrice) / remainingPercent;

        // Store with dimension-specific keys
        localStorage.setItem(`${sizeKey}_acrylic_aluminium_hanging_base`, JSON.stringify(acrylicHangingBase));
        localStorage.setItem(`${sizeKey}_acrylic_with_aluminium_hanging_total`, JSON.stringify(printPrice));


        break;

      case "acrylic print with spacers":
        const acrylicSpacersBase = 7 ;

        // Retrieve acrylic print base for this specific size
        const acrylicprintbase1 = Number(localStorage.getItem(`acrylic printing_${width}x${height}_acrylic_print_base`)) || 0;

        // Calculate final price with spacers
        printPrice = ((acrylicSpacersBase + acrylicprintbase1) + basePrice) / remainingPercent;

        // Store with dimension-specific keys
        localStorage.setItem(`${sizeKey}_acrylic_spacers_base`, JSON.stringify(acrylicSpacersBase));
        localStorage.setItem(`${sizeKey}_acrylic_with_spacers_total`, JSON.stringify(printPrice));


        break;

      case "acrylic sandwich 4+2 mm":
        const acrylicsandwichBase = (height / 100) * (width / 100) * price;
        printPrice = (acrylicsandwichBase + basePrice) / remainingPercent;

        // Store with dimension-specific keys
        localStorage.setItem(`${sizeKey}_acrylic_sandwich_4+2_base`, JSON.stringify(acrylicsandwichBase));
        localStorage.setItem(`${sizeKey}_acrylic_sandwich_4+2_total`, JSON.stringify(printPrice));


        break;

      case "acrylic sandwich 6+2 mm":
        const acrylicsandwichBase1 = (height / 100) * (width / 100) * price;
        printPrice = (acrylicsandwichBase1 + basePrice) / remainingPercent;

        // Store with dimension-specific keys
        localStorage.setItem(`${sizeKey}_acrylic_sandwich_6+2_base`, JSON.stringify(acrylicsandwichBase1));
        localStorage.setItem(`${sizeKey}_acrylic_sandwich_6+2_total`, JSON.stringify(printPrice));


        break;

      default:
        printPrice = 0;  // Default to zero if product doesn't match
        // console.warn(`No matching case for product: ${product}`);
        break;
    }

    // Ensure printPrice is a number and round to 2 decimal places
    printPrice = Number(printPrice) || 0;
    // console.log(`Final calculated price for ${product}: $${printPrice.toFixed(2)}`);
    return parseFloat(printPrice.toFixed(2));
  };

  const calculationPercentage = (productType, print_percentage, height,
    width,) => {
    let percentage = print_percentage / 100;
    switch (productType) {
      case "canvas print":

        return parseFloat((Number(JSON.parse(localStorage.getItem(`canvas print_${width}x${height}_canvas_print_total`))) * percentage).toFixed(2));
      case "canvas print and stretch":

        return parseFloat((Number(JSON.parse(localStorage.getItem(`canvas print and stretch_${width}x${height}_canvas_Print_strech_total`))) * percentage).toFixed(2));
      case "canvas print with stretching and framing":

        return parseFloat((Number(
          JSON.parse(localStorage.getItem(`canvas print with stretching and framing_${width}x${height}_canvas_print_stretch_framing`))) * percentage).toFixed(2));
      case "fine art print":

        return parseFloat((Number(
          JSON.parse(localStorage.getItem(`fine art print_${width}x${height}_fine_art_print_total`))) * percentage).toFixed(2));
      case "fine art print with framing":

        return parseFloat((Number(
          JSON.parse(localStorage.getItem(`fine art print with framing_${width}x${height}_print_with_framing`))) * percentage).toFixed(2));
      case "photo paper print":

        return parseFloat((Number(
          JSON.parse(localStorage.getItem(`photo paper print_${width}x${height}_photo_paper_print_total`))) * percentage).toFixed(2));
      case "photo paper print with framing":

        return parseFloat((Number(
          JSON.parse(localStorage.getItem(`photo paper print with framing_${width}x${height}_photo_paper_print_with_framing_total`))) * percentage).toFixed(2));
      case "poster":

        return parseFloat((Number(
          JSON.parse(localStorage.getItem(`poster_${width}x${height}_poster_total`))) * percentage).toFixed(2));
      case "poster with framing":

        return parseFloat((Number(
          JSON.parse(localStorage.getItem(`poster with framing_${width}x${height}_poster_with_framing_total`))) * percentage).toFixed(2));
      case "metal print":

        return parseFloat((Number(
          JSON.parse(localStorage.getItem(`metal print_${width}x${height}_metal_print_total`))) * percentage).toFixed(2));
      case "metal print with aluminium hanging":

        return parseFloat((Number(
          JSON.parse(localStorage.getItem(`metal print with aluminium hanging_${width}x${height}_metal_print_with_aluminium_hanging_total`))) * percentage).toFixed(2));
      case "acrylic printing":

        return parseFloat((Number(
          JSON.parse(localStorage.getItem(`acrylic printing_${width}x${height}_acrylic_print_total`))) * percentage).toFixed(2));
      case "acrylic print with aluminium hanging":

        return parseFloat((Number(
          JSON.parse(localStorage.getItem(`acrylic print with aluminium hanging_${width}x${height}_acrylic_with_aluminium_hanging_total`))) * percentage).toFixed(2));
      case "acrylic print with spacers":

        return parseFloat((Number(
          JSON.parse(localStorage.getItem(`acrylic print with spacers_${width}x${height}_acrylic_with_spacers_total`))) * percentage).toFixed(2));
      case "acrylic sandwich 4+2 mm":

        return parseFloat((Number(
          JSON.parse(localStorage.getItem(`acrylic sandwich 4+2 mm_${width}x${height}_acrylic_sandwich_4+2_total`))) * percentage).toFixed(2));
      case "acrylic sandwich 6+2 mm":

        return parseFloat((Number(
          JSON.parse(localStorage.getItem(`acrylic sandwich 6+2 mm_${width}x${height}_acrylic_sandwich_6+2_total`))) * percentage).toFixed(2));
      default:
        return 0;
    }
  };

  useEffect(() => {
    if (printPricingResponse?.found) {
      const updatedPricing = printPricingResponse.found.map((row) => {
        const product = row.product;
        const price = calculatePrice(
          height,
          width,
          row.price,
          row.product,
          basePrice
        );
        return { product, price };
      });
      setPricingData(updatedPricing);
    }
  }, [printPricingResponse, height, width, basePrice]);

  const handleStoreProductPricing = (product, price) => {
    props.setUploadArt((prevUploadArt) => {
      const updatedPrintByArtsmiley = prevUploadArt.print_by_artsmiley.map(
        (item, index) => {
          if (index === 0) {
            return {
              ...item,
              product,
              product_price: price,
            };
          }
          return item;
        }
      );

      return {
        ...prevUploadArt,
        print_by_artsmiley: updatedPrintByArtsmiley,
      };
    });
  };

  useEffect(() => {
    pricingData.forEach(({ product, price }) => {
      handleStoreProductPricing(product, price);
    });
  }, [pricingData]);

  return (<>
    <TableContainer sx={{ mt: 4, borderRadius: 2, boxShadow: 3 }}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#5d5e5e" }}>
            <TableCell sx={{ color: "white", fontWeight: "bold" }}>Product</TableCell>
            <TableCell align="right" sx={{ color: "white", fontWeight: "bold" }}>Size</TableCell>
            <TableCell align="right" sx={{ color: "white", fontWeight: "bold" }}>Sale Price ($)</TableCell>
            <TableCell align="right" sx={{ color: "white", fontWeight: "bold" }}>Artsmily Commission ($)</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {printPricingResponse?.found
          
            // ?.filter((row) => row.product.toLowerCase() !== "mounting")
            ?.map((row, index) => {
              const isMounting = row.product.toLowerCase() === "mounting";
              const maxDimensions = {
                "canvas print": { maxWidth: Infinity, maxHeight: 145 },
                "canvas print and stretch": { maxWidth: 284, maxHeight: 145 },
                "canvas print with stretching and framing": { maxWidth: 284, maxHeight: 145 },
                "fine art print": { maxWidth: Infinity, maxHeight: 105 },
                "fine art print with framing": { maxWidth: 284, maxHeight: 105 },
                "photo paper print": { maxWidth: Infinity, maxHeight: 105 },
                "photo paper print with framing": { maxWidth: 284, maxHeight: 105 },
                "poster": { maxWidth: Infinity, maxHeight: 150 },
                "poster with framing": { maxWidth: 284, maxHeight: 150 },
                "metal print": { maxWidth: 244, maxHeight: 122 },
                "metal print with aluminium hanging": { maxWidth: 244, maxHeight: 122 },
                "acrylic printing": { maxWidth: 244, maxHeight: 122 },
                "acrylic print with aluminium hanging": { maxWidth: 244, maxHeight: 122 },
                "acrylic print with spacers": { maxWidth: 244, maxHeight: 122 },
                "acrylic sandwich 4+2 mm": { maxWidth: 244, maxHeight: 122 },
                "acrylic sandwich 6+2 mm": { maxWidth: 244, maxHeight: 122 },
              };

              const categoryColors = {
                "canvas print": "#f0f4c3",
                "canvas print and stretch": "#dcedc8",
                "canvas print with stretching and framing": "#c5e1a5",
                "fine art print": "#ffecb3",
                "fine art print with framing": "#ffe082",
                "photo paper print": "#b3e5fc",
                "photo paper print with framing": "#81d4fa",
                "poster": "#f8bbd0",
                "poster with framing": "#f48fb1",
                "metal print": "#d1c4e9",
                "metal print with aluminium hanging": "#b39ddb",
                "acrylic printing": "#ffccbc",
                "acrylic print with aluminium hanging": "#ffab91",
                "acrylic print with spacers": "#ff8a65",
                "acrylic sandwich 4+2 mm": "#f48fb1",
                "acrylic sandwich 6+2 mm": "#ff8a65",
              };

              const { maxWidth, maxHeight } = maxDimensions[row.product] || { maxWidth: Infinity, maxHeight: Infinity };
              const rowColor = categoryColors[row.product] || "#e0e0e0";

              // Size Multipliers Logic
              const sizeMultipliers = (width <= 80 && height <= 80)
                ? [0.5, 0.75, 1.25, 1.5, 1, 2]
                : [0.25, 0.5, 0.75, 1, 1.25, 1.5];
            
              return (
                <>
                  {/* Product Name Row */}
                  {/* <TableRow key={`${row.product}-header`} sx={{ backgroundColor: rowColor }}>
                    <TableCell colSpan={4} sx={{ fontWeight: "bold", fontSize: "1rem", textTransform: "capitalize", border: "2px solid #5d5e5e" }}>
                      {["acrylic sandwich 4+2 mm", "acrylic sandwich 6+2 mm"].includes(row.product)
                        ? "Acrylic Sandwich with Aluminium Hanging"
                        : row.product}

                    </TableCell>
                  </TableRow> */}

{!isMounting && (
          <TableRow key={`${row.product}-header`} sx={{ backgroundColor: rowColor }}>
            <TableCell colSpan={4} sx={{ fontWeight: "bold", fontSize: "1rem", textTransform: "capitalize", border: "2px solid #5d5e5e" }}>
              {["acrylic sandwich 4+2 mm", "acrylic sandwich 6+2 mm"].includes(row.product)
                ? "Acrylic Sandwich with Aluminium Hanging"
                : row.product}
            </TableCell>
          </TableRow>
        )}

                  {/* Size and Price Rows */}
                  {sizeMultipliers.map((multiplier, sizeIndex) => {
                    const adjustedWidth = width * multiplier;
                    const adjustedHeight = height * multiplier;

                    if (adjustedWidth > maxWidth || adjustedHeight > maxHeight) {
                      return null;
                    }

                    return (
                      <TableRow
                        key={`${row.product}-${multiplier}`}
                        sx={{
                          backgroundColor: sizeIndex % 2 === 0 ? "#ffffff" : "#f5f5f5",
                          "&:hover": { backgroundColor: "#e3f2fd" },
                          borderBottom: "1px solid #5d5e5e", // Add border for rows                          
                        display: row.product.toLowerCase() === "mounting" ? "none" : "table-row"
                        }}
                      >
                        <TableCell></TableCell> {/* Empty cell for product column */}
                        <TableCell align="right" sx={{ border: "1px solid #5d5e5e" }}>
                          {adjustedWidth} x {adjustedHeight}
                        </TableCell>
                        <TableCell align="right" sx={{ border: "1px solid #5d5e5e" }}>
                          ${calculatePrice(adjustedHeight, adjustedWidth, row.price, row.product, basePrice, print_percentage)}
                        </TableCell>
                        <TableCell align="right" sx={{ border: "1px solid #5d5e5e" }}>
                          ${calculationPercentage(row.product, print_percentage, adjustedHeight, adjustedWidth)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>


  </>);
}
