import React from 'react';
import styles from './FrameCountry.module.css'
import country from '../../../../Country';
import { Button, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { useEffect } from 'react';
import { fetchFrameCountry, handleAddFrameCountry } from '../../../../State/api/superadminApi';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertsSuccess } from '../../../../common/alert/Alert';
import CircularLoader from '../../../../common/loader/Loader';
import ClearIcon from '@mui/icons-material/Clear';

const FrameCountry = () => {
    const dispatch = useDispatch();

    const loader = useSelector((state) => state.loader);
    const [frameCountry, setFrameCountry] = useState([])

    const handleSelectFrameCountry = (e) => {
        const vl = e.target.value;
        setFrameCountry(vl);
    };
    const handleRemoveCountry = (value) => {
        var frmcnty = [...frameCountry];
        const indexs = frameCountry.findIndex((val) => val === value);
        frmcnty.splice(indexs, 1);
        setFrameCountry(frmcnty);
    }
    // deep enterpirses redition hotel shubhssh chock sohna road
    const handleUpdateFrame = () => {
        dispatch(handleAddFrameCountry({ countries: frameCountry }))
    }
    useEffect(() => {
        dispatch(fetchFrameCountry(setFrameCountry))
    }, []);
    return (
        <div className={styles.mains}>
            <AlertsSuccess />
            <div className={styles.card_div}>
                <div className="form-group">
                <label>Change Frame Country</label>
                <Select
                    labelId="select-mutli-artist"
                    id="demo-select-artist"
                    value={frameCountry === 0 ? [] : frameCountry.map((val) => val)}
                    fullWidth
                    multiple
                    sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiInputBase-input":{
                          padding:".45rem .75rem",
                          height:"auto",
                          borderRadius:".25rem",
                          fontSize:"16px",
                          fontWeight:"400"
                        },
                        border:"1px solid #ced4da",
                        borderRadius:".25rem",
                        width:"100%"
                      }}
                    placeholder="select type"
                    onChange={handleSelectFrameCountry}
                    name="country"
                // input={<OutlinedInput label="Aritst Name" />}
                // MenuProps={MenuProps}
                >
                    {country.map((val) => (
                        <MenuItem
                            value={val.label} key={val.label}>
                            {val.label}
                        </MenuItem>
                    ))}
                </Select>
                </div>
                
                <div className={styles.country_label_div}>
                    {loader.loader ?
                        <div className={styles.loader}>
                            <CircularLoader />
                        </div>
                        : frameCountry.map((val) => (
                            <>
                                <Typography variant='outlined' className={styles.labels}>{val}
                                    <IconButton onClick={() => handleRemoveCountry(val)} sx={{ p: 0, ml: 1 }} ><ClearIcon style={{fontSize:"18px"}}/></IconButton></Typography>
                            </>
                        ))}
                </div>
                <div className={styles.save_btn}>
                    <button
                        className="action_btn"
                        onClick={handleUpdateFrame}>
                        Save Frame Country
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FrameCountry;
