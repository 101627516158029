import React, { useState } from "react";
import styles from "./Recreateartist.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  acceptDeclineRecreateRequest,
  fetchRecreateRequest,
} from "../../../State/api/artistapi";

import Recretepopup from "../../../gallery/components/popups/recretepopup/Recretepopup";
import EightcolimgArtist from "../../commonTables/EightcolimgArtist/EightcolimgArtist";
import TableheaderArtist from "../../commonTables/TableheaderArtist/TableheaderArtist";
import { setRecreateData } from "../../../State/Features/Artist/recreateSlice";
import { ToastContainer } from "react-toastify";
import TableForRecreateArtist from "../../commonTables/EightcolimgArtist/TableForRecreateArtist";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Pagination, PaginationItem } from "@mui/material";
import { fetchUsertypes } from "../../../State/api/commonapi";
const headData = [
  {
    id: "customer_id",
    label: "Image",
    format: (value) => value.toLocaleString(),
    align:"center"
  },
  {
    id: "buyer_name",
    label: "Buyer Name",
    format: (value) => value.toLocaleString(),
  },
  {
    
    id: "sku_id",
    label: "SKU Id",
    align:"center"
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "name",
    label: "Art Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "art_type",
    label: "Art Type",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "Request Date",
    label: "Requested Date",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "contact",
    label: "Remark",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "action",
    label: "Action",
    format: (value) => value.toLocaleString(),
  },
];
const sortdata = [
  { label: "Oldest Date ↓", value: "low" },
  { label: "Newest Date ↑", value: "high" },
];
const filterdata = [
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
  { label: "Pending", value: "pending" },
];
export default function Galleryrecrete() {
  const [open, setOpen] = useState(false);
  const [acceptPopupData, setAcceptPopuupData] = useState(null);
  const recreatedt = useSelector((state) => state.recreateData.data);
  const [search, setSearch] = useState("");
  const [data, setData] = useState({ offset: 1 });
  const [condition, setCondition] = useState({
    sorting: "",
    filter: "",
  });

  console.log("recreatedt", recreatedt);

  const userType = localStorage.getItem('usertype')

  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(fetchRecreateRequest(data));
  };
  useEffect(() => {
    fetchData();
  }, [data]);
  const handleFilter = (event) => {
    const value = event.target.value;

    setCondition({ ...condition, filter: value });
  };
  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
  };
  const handleSorting = (event) => {
    const value = event.target.value;
    if (value === "low") {
      setCondition({ ...condition, sorting: value });
      // dispatch(
      //   setRecreateData({
      //     data:
      //       recreatedt &&
      //       [...recreatedt.data].sort((a, b) => {
      //         if (a.createdAt.toLowerCase() < b.createdAt.toLowerCase())
      //           return -1;
      //       }),
      //     totalCount: recreatedt.totalCount,
      //   })
      // );
    } else if (value === "high") {
      setCondition({ ...condition, sorting: event.target.value });
      // dispatch(
      //   setRecreateData({
      //     data:
      //       recreatedt &&
      //       [...recreatedt.data].sort((a, b) => {
      //         if (a.createdAt.toLowerCase() > b.createdAt.toLowerCase())
      //           return -1;
      //       }),
      //     totalCount: recreatedt.totalCount,
      //   })
      // );
    } else {
      dispatch(setRecreateData(recreatedt));
      setCondition({ ...condition, sorting: value });
    }
  };
  const handlePopupData = (dt) => {
    setOpen(true);
    setAcceptPopuupData(dt);
  };
  const handleAcceptRejectQuate = (id, dt) => {
    dispatch(acceptDeclineRecreateRequest(id, dt, fetchData));
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust how many items you want per page

  // Function to handle page changes
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const pageSize = 10; // Define the number of items per page

  // Sort recreateRequests by createdAt (newest first)
  const sortedRecreateRequests = recreatedt?.recreateRequests
    ? [...recreatedt.recreateRequests].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
    : [];
  // Calculate the start and end index for slicing
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  // Slice the sorted data for the current page
  const currentItems = sortedRecreateRequests?.slice(startIndex, endIndex);
  return (
    <div className={styles.mains}>
      {open ? (
        <Recretepopup
          handleAcceptRejectRecreate={handleAcceptRejectQuate}
          acceptPopupData={acceptPopupData}
          data={data}
          open={open}
          setOpen={setOpen}
          activetab="galleryrecrete"
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <TableheaderArtist
          setOpen={setOpen}
          handleFilter={handleFilter}
          button=""
          search={search}
          condition={condition}
          filterdata={filterdata}
          sortdata={sortdata}
          handleSearch={handleSearch}
          handleSorting={handleSorting}
        />
        <TableForRecreateArtist
          activetab="galleryrecrete"
          headData={headData}
          data={
            Array.isArray(currentItems)
              ? [...currentItems]
                  // Sort all items by date first (descending order)
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  // Apply optional search filter
                  .filter((val) => {
                    if (search) {
                      return val?.item_id?.name
                        ?.toLowerCase()
                        .includes(search.toLowerCase());
                    }
                    return true; // Skip filter if search is not provided
                  })
                  // Apply condition-based filters
                  .filter((val) => {
                    if (condition.filter === "approved") {
                      return val.is_accepted === "Approved";
                    } else if (condition.filter === "rejected") {
                      return val.is_accepted === "Rejected";
                    } else if (condition.filter === "pending") {
                      return val.is_accepted === "Pending";
                    } else {
                      return true; // Include all if no specific filter is set
                    }
                  })
                  // Apply sorting logic based on conditions
                  .sort((a, b) => {
                    if (condition.sorting === "low") {
                      return new Date(a.createdAt) - new Date(b.createdAt); // Sort price low to high
                    } else if (condition.sorting === "high") {
                      return new Date(b.createdAt) - new Date(a.createdAt); // Sort price high to low
                    }
                    return 0; // No sorting if condition.sorting is not 'low' or 'high'
                  })
              : [] // Return an empty array if currentItems is not an array
          }
          setOpen={setOpen}
          handlePopupData={handlePopupData}
        />
        <Box className={styles.pagination_box} sx={{}}>
          <Pagination
            count={Math.ceil(
              recreatedt?.recreateRequests?.length / itemsPerPage
            )} // Total pages
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            renderItem={(item) => (
              <PaginationItem
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "black", // Set the selected background to black
                    color: "white", // Ensure the text color is visible
                    "&:hover": {
                      backgroundColor: "black", // Maintain black on hover
                    },
                  },
                }}
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
              />
            )}
          />
        </Box>

        <ToastContainer />
      </div>
    </div>
  );
}
