import { Button, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import styles from "./Deputedetail.module.css"
import Commondisputedetails from "../common/Commondisputedetails"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import api from "../../../../../State/api/Api"
import { acceptRejectRefundStatus } from "../../../../../State/api/superadminApi"
import { ToastContainer } from "react-toastify"
import { Errornotify, Successnotify } from "../../../../../common/alert/Alert"
import DisputeDetailsAdmin from "../disputeDetailsAdmin/DisputeDetailsAdmin"
const data = [
  {
    title: "Depute ID",
    content: "#565787",
  },
  {
    title: "Order ID",
    content: "#23444",
  },
  {
    title: "Description",
    content: "Lorem this is for onlyu tedt that is true",
  },
  {
    title: "Payment",
    content: "Pending",
  },
]

export default function Deputedetail() {
  const [loading, setLoading] = useState(false)
  const [age, setAge] = React.useState("")
  const [orderData, setOrderData] = useState(null)
  const { id } = useParams()
  const dispatch = useDispatch()

  const handleChange = (event) => {
    setAge(event.target.value)
  }

  // const ApprovedRefundStatus = (dt) => {
  //   dispatch(acceptRejectRefundStatus(id, dt))
  // }

  const ApprovedRefundStatus = async (dt) => {
    await dispatch(acceptRejectRefundStatus(id, dt))
    handleFetchOrders() 
  }
  

  const handleFetchOrders = async function () {
    // dispatch(setLoader(true));
    setLoading(true)
    try {
      const result = await api.applicationApi(
        `api/dispute/getDisputeById/${id}`,
        "GET"
      )

      if (result && result.status === 200) {
        console.log("result.data.data",result.data.data)
        setOrderData(result.data.data)
        // dispatch(setLoader(false));
        setLoading(false)
      } else if (result.status === 401) {
        Errornotify("Error with code 401")
      }
    } catch (err) {
      Errornotify("Network Error")
      setLoading(false)
    }
  }
  useEffect(() => {
    handleFetchOrders()
  }, [])

  const status = orderData?.find((item) => item.refund_status === "Pending")

  return (
    <>
      <div className={styles.mains}>
        <DisputeDetailsAdmin data={data} orderData={orderData} />
        <div className={styles.action_btns}>
          {
            status?
            <>
            <p className={styles.content}>Initiate Refund</p>
          <button
            onClick={() => ApprovedRefundStatus({ refund_status: "Approved" })}
            variant="contained"
            className="action_btn"
          >
            Accept
          </button>
          <button
            onClick={() => ApprovedRefundStatus({ refund_status: "Rejected" })}
            variant="outlined"
            className="action_libtn"
          >
            Reject
          </button>
            </>:''
          }
          
        </div>
      </div>
      <ToastContainer />
    </>
  )
}
