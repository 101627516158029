import React, { useEffect } from "react";
import Questionsui from "../../../../curator/components/Common/questionUi/Question";
import { useState } from "react";
import { AlertsSuccess, Errornotify } from "../../../../common/alert/Alert";
import { useNavigate } from "react-router-dom";
import api from "../../../../State/api/Api";

const inputs = [
  {
    number: 1,
    label: "About Gallery",
    type: "text",
    name: "about_gallery",
    placeholder: "Enter text",
  },
  {
    number: 2,
    label:
      "Please provide details of prestigious exhibitions, awards/prizes, commissions, grants, recognitions etc?*",
    type: "text",
    name: "awards_etc",
    placeholder: "Enter text",
  },
  {
    number: 3,
    label:
      "Please provide us with the name, the year and the place of each experience.",
    type: "text",
    name: "experience",
    placeholder: " Enter URL",
  },
  {
    number: 4,
    label: "How many years of experience you have ?*",
    type: "text",
    name: "total_experience",
    placeholder: "Enter URL",
  },
  {
    number: 5,
    label:
      "Please provide the link to your online portfolio: (Gallery Website)",
    type: "checkbox",
    name: "checkbox_width_input",
    placeholder: "Enter text",
    data: [
      {
        id: 1,
        label: "Painting",
      },
      {
        id: 2,
        label: "Drawing",
      },
      {
        id: 3,
        label: "Mixed media",
      },
      {
        id: 4,
        label: "Sculpture",
      },
      {
        id: 5,
        label: "Photography",
      },
      {
        id: 6,
        label: "Textile",
      },
      {
        id: 7,
        label: "Installation",
      },
      {
        id: 8,
        label: "NFT",
      },
      {
        id: 9,
        label: "Digital Art",
      },
      {
        id: 10,
        label: "Craft",
      },
      {
        id: 11,
        label: "Others",
      },
    ],
  },
  {
    number: 6,
    label:
      "Please provide the link to your online portfolio: (Gallery Website)",
    type: "checkbox",
    name: "checkbox",
    placeholder: "Enter text",
    data: [
      {
        id: 1,
        label: "< $250",
      },
      {
        id: 2,
        label: "$250-$1000",
      },
      {
        id: 1,
        label: "$1000-$2500",
      },
      {
        id: 1,
        label: "$2500-5000",
      },
      {
        id: 1,
        label: "$5000-10000",
      },
      {
        id: 1,
        label: ">$10000",
      },
    ],
  },
  {
    number: 7,
    label:
      "If you are represented by another online platform, Please provide URL links:",
    type: "text",
    name: "url_link",
    placeholder: "Enter text",
  },
  {
    number: 8,
    label:
      "Please provide the link to your online portfolio: (Gallery Website)",
    type: "text",
    name: "web_link",
    placeholder: "Enter text",
  },
  {
    number: 9,
    label:
      "Please provide the link to your online portfolio: (Gallery Website)",
    type: "checkbox",
    name: "checkbox_width_input",
    placeholder: "Enter text",
    data: [
      {
        id: 1,
        label: "Painting PaintingPainting Painting Painting Painting    ",
      },
      {
        id: 2,
        label: "Drawing",
      },
      {
        id: 3,
        label: "Mixed media",
      },
      {
        id: 4,
        label: "Sculpture",
      },
      {
        id: 5,
        label: "Photography",
      },
      {
        id: 6,
        label: "Textile",
      },
      {
        id: 7,
        label: "Installation",
      },
      {
        id: 8,
        label: "NFT",
      },
      {
        id: 9,
        label: "Digital Art",
      },
      {
        id: 10,
        label: "Craft",
      },
      {
        id: 11,
        label: "Others",
      },
    ],
  },
  {
    number: 10,
    label:
      "Please provide the link to your online portfolio: (Gallery Website)",
    type: "radio",
    name: "radio",
    placeholder: "Enter text",
    press_enter: true,
    data: [
      {
        id: 1,
        label: "Yes",
      },
      {
        id: 2,
        label: "No",
      },
    ],
  },
  {
    number: 11,
    label:
      "Please provide the link to your online portfolio: (Gallery Website)",
    type: "radio",
    name: "checkbox",
    placeholder: "Enter text",
    press_enter: true,
    data: [
      {
        id: 1,
        label: "Yes",
      },
      {
        id: 2,
        label: "No",
      },
    ],
  },
];
const checkboxdt = {
  step5: [
    {
      id: 1,
      label: "Painting",
    },
    {
      id: 2,
      label: "Drawing",
    },
    {
      id: 3,
      label: "Mixed media",
    },
    {
      id: 4,
      label: "Sculpture",
    },
    {
      id: 5,
      label: "Photography",
    },
    {
      id: 6,
      label: "Textile",
    },
    {
      id: 7,
      label: "Installation",
    },
    {
      id: 8,
      label: "NFT",
    },
    {
      id: 9,
      label: "Digital Art",
    },
    {
      id: 10,
      label: "Craft",
    },
    {
      id: 11,
      label: "Others",
    },
  ],
  step6: [
    {
      id: 1,
      label: "< $250",
    },
    {
      id: 2,
      label: "$250-$1000",
    },
    {
      id: 1,
      label: "$1000-$2500",
    },
    {
      id: 1,
      label: "$2500-5000",
    },
    {
      id: 1,
      label: "$5000-10000",
    },
    {
      id: 1,
      label: ">$10000",
    },
  ],
  step9: [
    {
      id: 1,
      label: "I have participated in many fairs worldwide",
    },
    {
      id: 2,
      label: "My works are present in many private collections worldwide",
    },
    {
      id: 3,
      label: "I have participated to a Biennial",
    },
    {
      id: 4,
      label: "My works are present in many public collections worldwide",
    },
   
    {
      id: 5,
      label: "I have many followers on social media",
    },
  
    {
      id: 6,
      label: "I am passionate about art and want to share my works!",
    },
    {
      id: 7,
      label: "Others",
    },
  ]
};
export default function Fillquestionartist({
  handleChangeApplyData,
  apply,
  setApply,
  src,
  handleSelectCheckbox,
  handleChangeSocialLinks,
  handleChangeImage,
  handleImageChangeMultiple,
  handleRemoveImage,
  selectedFiles,
  handleChangeTextEditor
}) {
 const [artistTypes, setArtistTypes] = useState([])
  const fetchArtistTypes = async function () {
    try {
      const result = await api.applicationApi(
        `api/artisttype/fetchArtistTypes`,
        "GET"
      );
      if (result.status === 200 || result.status === 201) {
        const res = result.data.data.data;
       setArtistTypes(res)
     
      } 
    } catch (err) {
    }
  };
  useEffect(() => {
    fetchArtistTypes()
  },[])
  const isValidURL = (url) => {
    // Regular expression to match URLs
    const regex =
      /^(https:\/\/www\.|http:\/\/www\.|www\.|https:\/\/|)[a-zA-Z0-9\-_$]+\.[a-zA-Z]{2,3}(\/[a-zA-Z0-9\-_$]{0,}|){0,}$/g;
    // const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(url);
  };
  const [steps, setSteps] = useState({
    step: "1",
    stepper: Math.floor((100 / 9) * 1),
  });
  useEffect(() => {}, [steps]);
  const navigate = useNavigate();
  const handleStepsBack = (num) => {
    if (num === "1") {
      setSteps({ step: "1", stepper: (100 / 13) * 1 });
    } else if (num === "2") {
      setSteps({ step: "2", stepper: (100 / 13) * 2 });
    } else if (num === "3") {
      setSteps({ step: "3", stepper: (100 / 13) * 3 });
    } else if (num === "4") {
      setSteps({ step: "4", stepper: (100 / 13) * 4 });
    } else if (num === "5") {
      setSteps({ step: "5", stepper: (100 / 13) * 5 });
    } else if (num === "6") {
      setSteps({ step: "6", stepper: (100 / 13) * 6 });
    } else if (num === "7") {
      setSteps({ step: "7", stepper: (100 / 13) * 7 });
    } else if (num === "8") {
      setSteps({ step: "8", stepper: (100 / 13) * 8 });
    } else if (num === "9") {
      setSteps({ step: "9", stepper: (100 / 13) * 9 });
    } else if (num === "10") {
      setSteps({ step: "10", stepper: (100 / 13) * 10 });
    }  else if (num === "11") {
      setSteps({ step: "11", stepper: (100 / 13) * 11 });
    }
    else if (num === "12") {
      setSteps({ step: "12", stepper: (100 / 13) * 12 });
    }   else if (num === "13") {
      setSteps({ step: "13", stepper: (100 / 13) * 13 });
    }  else {
      setSteps({ step: "14", stepper: (100 / 13) * 14 });
    }
  };
  const handleSteps = () => {
     if (steps.step === "1") {
      setSteps({ step: "2", stepper: Math.floor((100 / 13) * 2) });
    } else if (steps.step === "2" ) {
      setSteps({ step: "3", stepper: Math.floor((100 / 13) * 3) });
    }  else if (steps.step === "3" && apply.exp.length !== 0) {
      setSteps({ step: "4", stepper: Math.floor((100 / 13) * 4) });
    } else if (
      steps.step === "4" 
    ) {
      setSteps({ step: "5", stepper: (100 / 13) * 5 });
    } else if (steps.step === "5") {
     
        setSteps({ step: "6", stepper: (100 / 13) * 6 });
    
    } else if (
      steps.step === "6"
    ) {
    
        setSteps({ step: "7", stepper: (100 / 13) * 7 });
    

      // apply.social_links.linked_in.match(regex) ?setSteps({ step: "5", stepper: (100 / 12) * 3 }) : Errornotify("Please Enter Valid Url")
    } else if (steps.step === "7" && apply.category_id.length !== 0) {
      setSteps({ step: "8", stepper: (100 / 13) * 8 });
    } else if (steps.step === "8" && apply.art_price_range.length !== 0) {
      setSteps({ step: "9", stepper: (100 / 13) * 9 });
    } else if (
      steps.step === "9" &&
      apply.arts_solds_in_past_12_months.length !== 0
    ) {
      if (!isNaN(apply.arts_solds_in_past_12_months)) {
        setSteps({ step: "10", stepper: (100 / 13) * 10 });
      } else {
        Errornotify("Please enter valid number");
      }
      // setSteps({ step: "8", stepper: (100 / 12) * 3 });
    } else if (
      steps.step === "10" &&
      apply.produce_art_each_month.length !== 0
    ) {
      if (!isNaN(apply.produce_art_each_month)) {
        setSteps({ step: "11", stepper: (100 / 13) * 11 });
      } else {
        Errornotify("Please enter valid number");
      }
      //  setSteps({ step: "9", stepper: (100 / 12) * 3 });
    } else if (steps.step === "11" && apply.promoting_by_reason.length !== 0) {
      setSteps({ step: "12", stepper: (100 / 13) * 12 });
    } else if (steps.step === "12") {
      setSteps({ step: "13", stepper: (100 / 13) * 13 });
    } 
   else {
      navigate("/artist/applyform");
      // Errornotify("Please fill Detail");
      //   }
    }
  };

  // const handleSteps = () => {

  // }
  return (
    <div>
      <AlertsSuccess />
      <Questionsui
        handleChangeSocialLinks={handleChangeSocialLinks}
        checkboxdt={checkboxdt}
        handleSelectCheckbox={handleSelectCheckbox}
        steps={steps}
        setSteps={setSteps}
        handleStepsBack={handleStepsBack}
        apply={apply}
        src={src}
        setApply={setApply}
        handleSteps={handleSteps}
        inputs={inputs}
        handleChangeApplyData={handleChangeApplyData}
        handleChangeImage={handleChangeImage}
        handleImageChangeMultiple={handleImageChangeMultiple}
        handleRemoveImage={handleRemoveImage}
        selectedFiles={selectedFiles}
        type="artist"
        artistTypes={artistTypes}
        handleChangeTextEditor={handleChangeTextEditor}
      />
    </div>
  );
}
