import { createTheme, ThemeProvider, Typography, Container, Link } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ShippingReturnDets.module.css";
import { useTranslation } from "react-i18next";

function ShippingReturnDets() {
  const theme = createTheme({
    typography: {
      h4: {
        fontFamily: "Marcellus",
        fontSize: "2vw",
      },
      h6: {
        fontFamily: "Marcellus",
        fontSize: "1vw",
      },
      imgText: {
        fontFamily: "Marcellus",
        fontWeight: 400,
        align: "center",
        fontSize: "1.15vw",
      },
    },
  });

  const navigate = useNavigate();
  const { t } = useTranslation("returnPolicy");
  const handleNavigate = (route) => {
    navigate(route)
  }
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.shippingandreturns}>
      <div className={styles.headerCategory}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
      <div className={styles.pageitle}>
              <h1>{t("return_policy")}</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>{t("home")} /{" "} </Link></li>
                <li>{t("return_policy")}</li>
              </ul>
            </div>
      </Container>
        </div>
        <div className={styles.main__content}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          <h3>{t("shipping")}</h3>
          <ol>
              <li className={styles.bullets}>
                {t("shipping_desc1")}
              </li>
              <li className={styles.bullets}>
              {t("shipping_desc2")}
              </li>
              <li className={styles.bullets}>
              {t("shipping_desc3")}
              </li>
              <li className={styles.bullets}>
              {t("shipping_desc4")}
              </li>
            </ol>
            <h3>{t("refunds_returns")}</h3>
            <ol>
              <li className={styles.bullets}>
                {t("refunds_returns_desc1")}
              </li>
              <li className={styles.bullets}>
              {t("refunds_returns_desc2")}
              </li>
              <li className={styles.bullets}>
              {t("refunds_returns_desc3")}
              </li>
              <li className={styles.bullets}>
              {t("refunds_returns_desc4")}
              </li>
              <li className={styles.bullets}>
              {t("refunds_returns_desc5")}
              </li>
              <li className={styles.bullets}>
              {t("refunds_returns_desc6")}
              </li>
              <li className={styles.bullets}>
              {t("refunds_returns_desc7")}
              </li>
              <li className={styles.bullets}>
              {t("refunds_returns_desc8")}
              </li>
            </ol>
        </Container>
         
        </div>
      </div>
    </ThemeProvider>
  );
}

export default ShippingReturnDets;
