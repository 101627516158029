import * as React from "react";
import styles from "./EightcolimgArtist.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, IconButton, Pagination, PaginationItem } from "@mui/material";
import homeImage from "../../../../src/constant";
import serverimage from "../../../serverConstant";
import { useSelector } from "react-redux";
import CircularLoader from "../../../common/loader/Loader";
import dateFormat from "../../../utils/dateFormat";
import { Errornotify } from "../../../common/alert/Alert";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


export default function EightcolimgArtist(props) {

console.log("props buye's offer",props);

  const [age, setAge] = React.useState("");
const navigate = useNavigate()
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const loader = useSelector((state) => state.loader);  
    const [currentPage, setCurrentPage] = React.useState(1);
    const itemsPerPage = 10; // Adjust how many items you want per page
  
    // Function to handle page changes
    const handlePageChange = (event, page) => {
      setCurrentPage(page);
    };
  
    // Slice the data based on current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = props.data?.slice(startIndex, endIndex);

    
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.headData.map((column) => (
                <TableCell
                  className={styles.table_head}
                  align={column.label === "Action" ? "center" : "left"}
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "15px",
                    height: "21px",
                    color: "#1C1C1C",

                  }}
                  
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={9}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {currentItems?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                currentItems?.map((row, i) => (
                  <TableRow style={{ color: "#636365",cursor:"pointer" }} key={i}         
                  >
                    {props.activetab === "buyeroffer" ? (
                      <TableCell
                      onClick={() =>  navigate(`/user/newestpaintings/${row?.item_id?._id}`)}
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <img
                 
                          src={` ${row.item_id?.image}`}
                          height="42px"
                          width="60px"
                          alt="img"
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <img
                          src={`${row.item_id?.image}`}
                          height="42px"
                          width="60px"
                          alt=""
                        />
                      </TableCell>
                    )}
                    <TableCell
                      className={styles.table_column}
                      component="th"
                      scope="row"
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        textTransform:"capitalize"
                      }}
                    >
                      {props.activetab === "buyeroffer"
                        ? row.first_name + " " + row.last_name
                        : row.user_id?.first_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row?.item_id?.sku_id || "---"}
                    </TableCell>
                    <TableCell
                    // onClick={() =>  navigate(`/user/newestpaintings/${row?.item_id?._id}`)}
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                        textTransform:"capitalize"
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {props.activetab === "buyeroffer"
                        ? row.country
                        : row.item_id?.name}
                        {row?.item_id?.name || "---"}
                    </TableCell>
                    { props.activetab==='buyeroffer'?
                    <>
                      <TableCell
                      // onClick={() =>  navigate(`/user/newestpaintings/${row?.item_id?._id}`)}
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                          textTransform:"capitalize"
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                       {row.item_id?.sale ? "Sale" : "Pring"}
                      </TableCell>
                      </>:''
                    }
                  
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {props.activetab1 === "gallerybuyeroffer" ? "---" : dateFormat(row?.createdAt)}
                 
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {props.activetab === "buyeroffer"
                        ?  row.item_id?.sale?.price ? "$" + row.item_id?.sale?.price : dateFormat(row?.created_at) : row.message.slice(0,30)}
                    </TableCell>
                    {props.activetab === "galleryrecrete" ? (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#35BE43",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <div className={styles.quoterequest}>
                          {row.is_accepted && row.is_accepted === "Approved" ? (
                            <span className={styles.accepted}> Accepted</span>
                          ) : row.is_accepted === "Rejected" ? (
                            <span className={styles.rejected}> Rejected</span>
                          ) : (
                            <>
                              <Button
                                onClick={(e) => {
                                 e.stopPropagation()
                                    props.handlePopupData(row)
                                
                                }}
                                variant="contained"
                                sx={{
                                  width: "80px",
                                  height: "34px",
                                  borderRadius: "4px",
                                }}
                              >
                                Accept
                              </Button>
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation()
                                   props.handlePopupData(row)
                                }}
                                variant="outlined"
                                sx={{
                                  width: "80px",
                                  height: "34px",
                                  borderRadius: "4px",
                                }}
                              >
                                Decline
                              </Button>
                            </>
                          )}
                        </div>
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                         {props.activetab === "buyeroffer"
                        ? "$" + row.price
                        : row.message}
                      </TableCell>
                    )}
                    {props.activetab1 === "gallerybuyeroffer" &&       <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                         {props.activetab === "buyeroffer"
                        ? "$" + row.price
                        : row.message}
                      </TableCell>}
                    {props.activetab === "buyeroffer" ? (
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#35BE43",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <div className={styles.quoterequest}>
                          {row.is_accepted && row?.is_accepted === "Approved" ? (
                            <span className={styles.accepted}  onClick={() => props.handlePopupData(row)}> Approved</span>
                          ) : row.is_accepted === "Rejected" ? (
                            <span className={styles.rejected}  onClick={() => props.handlePopupData(row)}> Rejected</span>
                          ) : (
                            <>
                              <Button
                                onClick={() => props.handlePopupData(row)}
                                variant="contained"
                                sx={{
                                  width: "80px",
                                  height: "34px",
                                  borderRadius: "4px",
                                }}
                              >
                                Accept
                              </Button>
                              <Button
                                onClick={() => props.handlePopupData(row)}
                                variant="outlined"
                                sx={{
                                  width: "80px",
                                  height: "34px",
                                  borderRadius: "4px",
                                }}
                              >
                                Decline
                              </Button>
                            </>
                          )}
                        </div>
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
     <Box className={styles.pagination_box} sx={{}}>
          <Pagination   count={Math.ceil(props.data?.length / itemsPerPage)} // Total pages
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        renderItem={(item) => (
          <PaginationItem
          sx={{
            "&.Mui-selected": {
              backgroundColor: "black", // Set the selected background to black
              color: "white", // Ensure the text color is visible
              "&:hover": {
                backgroundColor: "black", // Maintain black on hover
              },
            },
          }}
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )} />
          </Box>
    </>
  );
}
