// const isEmpty = (value) =>
//     value === undefined ||
//     value === null ||
//     (typeof value === "object" && Object.keys(value).length === 0) ||
//     (typeof value === "string" && value.trim().length === 0)
//   export default isEmpty
  


// const isEmpty = (value) => {
//   if (value === undefined || value === null) return true;

//   if (typeof value === "string") return value.trim().length === 0;

//   if (typeof value === "object") return Object.keys(value).length === 0;

//   if (Array.isArray(value)) return value.length === 0;

//   return false;
// };


const isEmpty = (value) => {
  if (value === undefined || value === null) return true;

  if (typeof value === "string") return value.length === 0; 

  if (typeof value === "object" && !Array.isArray(value)) return Object.keys(value).length === 0;

  if (Array.isArray(value)) return value.length === 0;

  return true; 
};


export default isEmpty;
