import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data:[]
}

export const GalleryprofileSlice = createSlice({
  name: 'gallerydetails',
  initialState,
  reducers: {
    addData: (state, action) => {
      state.data = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { addData } = GalleryprofileSlice.actions;

export default GalleryprofileSlice.reducer