import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const NotificationSlice = createSlice({
    name: "notificationdata",
    initialState,
    reducers: {
        setNotificationdata: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setNotificationdata } = NotificationSlice.actions;

export default NotificationSlice.reducer;
