import SearchOutlined from "@mui/icons-material/SearchOutlined";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import ProductSidebar from "./ProductSidebar";
import styles from "./Search.module.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import homeImage from "../../../constant";
import api from "../../../State/api/Api";
import serverimage from "../../../serverConstant";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllItemData,
  fetchArtistTypes,
  fetchCategoryData,
  fetchProductType,
  fetchUserArtist,
  filterAllArts,
} from "../../../State/api/userApi";
import {
  ArticleSharp,
  Close,
  CloseOutlined,
  Search as Searchicon,
} from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CircularLoader from "../../../common/loader/Loader";
import { setSearchFilter } from "../../../State/Features/user/filterationSlice";
import { handleFetchStyle } from "../../../State/api/superadminApi";
import { handleFetchSubject } from "../../../State/api/superadminApi";
import { handleFetchMedium, fetchGalleryData } from "../../../State/api/superadminApi";
import { FiHeart } from "react-icons/fi";
import ProductGallerySidebar from "./ProductGallerySidebar";
const sortdata = [
  { label: "Sale Price ↓", value: "low" },
  { label: "Sale Price ↑", value: "high" },
];
function GallerySearch() {
  const cardDetails = [
    {
      id: 1,
      imgUrl: "/img/Rectangle 368.png",
    },
    {
      id: 2,
      imgUrl: "/img/Rectangle 369.png",
    },
    {
      id: 3,
      imgUrl: "/img/Rectangle 370.png",
    },
    {
      id: 4,
      imgUrl: "/img/Rectangle 5199.png",
    },
    {
      id: 5,
      imgUrl: "/img/Rectangle 5197.png",
    },
    {
      id: 6,
      imgUrl: "/img/Rectangle 5200.png",
    },
    {
      id: 7,
      imgUrl: "/img/Rectangle 368.png",
    },
    {
      id: 8,
      imgUrl: "/img/Rectangle 5202.png",
    },
    {
      id: 9,
      imgUrl: "/img/Rectangle 5203.png",
    },
    {
      id: 10,
      imgUrl: "/img/Rectangle 5206.png",
    },
    {
      id: 11,
      imgUrl: "/img/Rectangle 5207.png",
    },
    {
      id: 12,
      imgUrl: "/img/Rectangle 5208.png",
    },
  ];
  const searchParams = new URLSearchParams(window.location.search);

  const categoryParams = searchParams.get("category");
  const { state } = useLocation();
  const [loadingArts, setLoadingArts] = useState(false);
  const [value_height, setValueHeight] = useState([0, 1500]);
  const [value_width, setValueWidth] = useState([0, 1500]);
  const [initialValueSet, setInitialValueSet] = useState(false);
  const [filteredValues, setFilteredValues] = useState({
    categoryName: state?.id ? state?.id : [],
    styleName: "",
    style: "",
    subjectName:"",
    mediumName:"",
    colorName: "",
    artistTypeName: "",
    artistName: "",
    startPrice: "0",
    endPrice: "50000",
    minHeight: 0,
    maxHeight: 1500,
    minWidth: 0,
    maxWidth: 1500,
    countryName:"",
    searchTitle: "",
    offername: "",
    artistNationality:"",
    artistCountry: "",
    galleryCountry: [],
    galleryNationality: [],
    galleryName: "",
    materialName: "",
    edition: "",
  });
  const [searchTitle, setSearchTitle] = useState("");
  const [selectedSort, setSelectedSort] = useState("");
  const location = useLocation();
  const [artist, setArtist] = React.useState("");
  const [artData, setArtdata] = useState();
  const [galleryListDt, setGalleryListDt] = useState();
  const [filterData, setFilterdata] = useState();
  const [priceRange, setPriceRange] = useState([0, 50000]);

  const [condition, setCondition] = useState({ sorting: "", filter: "" });
  const [style, setStyle] = useState([]);
  const [subject, setSubject] = useState([]);
  const [medium, setMedium] = useState([]);
  const [filterByArtist, setFilterByArtist] = useState("all");
  const allcategory = useSelector((state) => state.allcategoryData);

  const { option } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateToArtworkDetail = (ids) => {
    navigate(`/user/productdetails/${ids}`);
  };
  const getArt = async () => {
    const reqData = await api.applicationApi("api/user/allitems", "GET", {});
    console.log("reqData", reqData)
    setArtdata(reqData.data.artworks);
    setFilterdata(reqData);
  };
  const getGalleries = async () => {
    const requiredData = await api.applicationApi("api/user/getGallery", "GET", {});
    console.log("Galleries", requiredData);
    setGalleryListDt(requiredData.data.responses)
  };
  useEffect(() => {
    if (filteredValues.categoryName) {
      const category_id = allcategory?.data?.allcategories?.filter(
        (val) => val?.name.includes(filteredValues.categoryName)
      )[0]?._id;
      if (category_id) {
        dispatch(handleFetchStyle({ category_id: category_id }, setStyle));
        dispatch(handleFetchSubject({ category_id: category_id }, setSubject));
        dispatch(handleFetchMedium({ category_id: category_id }, setMedium));
      }
    }
  }, [filteredValues.categoryName, allcategory?.data?.allcategories]);
  const [data, setData] = useState({
      offset: 1,
      is_accepted: "Pending",
    });
    const gallerydata = useSelector((state) => state.galleryData.data);
    console.log("gallerydata", gallerydata);
  useEffect(() => {
    getArt();
    getGalleries();
    dispatch(fetchProductType());
    dispatch(fetchArtistTypes());
    dispatch(fetchUserArtist({}));
    dispatch(fetchCategoryData({}));
    dispatch(fetchGalleryData(data));
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [data]);

  const productType = useSelector((state) => state.productType);
  const artistTypes = useSelector((state) => state.artistTypedata);
  const userartists = useSelector((state) => state.userartistData);

  const loader = useSelector((state) => state.loader);
  const filter = useSelector((state) => state.filteration);
  
  const AllFilterValues = useSelector(
    (state) => state.filteration.allFilterValues
  );
  useEffect(() => {
    console.log("Filtered Artworks from Redux:", AllFilterValues);
  }, [AllFilterValues]);
  
  const sortedFilterValues = Array.isArray(AllFilterValues)
    ? [...AllFilterValues]
        .filter((item) => {
          // Filter by itemType and ensure `print` is not empty
          if (
            location?.state?.itemType === "print" &&
            (!item.print || item.print.length === 0)
          ) {
            return false; // Exclude items with an empty `print` field
          }

          // Filter by searchTitle
          if (filteredValues.searchTitle) {
            return item.name
              .toLowerCase()
              .includes(filteredValues.searchTitle.toLowerCase());
          }

          return true; // Include other items
        })
        .sort((a, b) => {
          // Sort by price based on selectedSort
          if (selectedSort === "low") {
            return a.sale.price - b.sale.price; // Low to High
          } else if (selectedSort === "high") {
            return b.sale.price - a.sale.price; // High to Low
          } else {
            return new Date(b.createdAt) - new Date(a.createdAt); // Default sort by date
          }
        })
    : [];
    console.log("sortedFilterValues",sortedFilterValues)
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTitle(value);
    dispatch(setSearchFilter(value));
  };
  const handleSubmitSearch = () => {
    setFilteredValues({ ...filteredValues, searchTitle: searchTitle });
  };
  const handleSubmitRemove = () => {
    setFilteredValues({ ...filteredValues, searchTitle: "", galleryName: "" });
    setSearchTitle("");
  };
  const handleArtistSearch = (event) => {
    setArtist(event.target.value);
  };

  const handleFilter = (event) => {
    const value = event.target.value;
    setFilteredValues({ ...filteredValues, galleryName: value });
    // setFilterByArtist(value);
    // setCondition({ ...condition, filter: value });
  };

  const handleSorting = (event) => {
    const value = event.target.value;
    setSelectedSort(value);
  };

  const filters =
    artData && artData.map((val) => val.style_id.map((c) => c._id?._id));

  const selectedFilters = [
    ...(filteredValues.categoryName.length ? filteredValues.categoryName : []),
    ...(filteredValues.galleryCountry.length ? filteredValues.galleryCountry : []),
    ...(filteredValues.galleryNationality.length ? filteredValues.galleryNationality: []),
    filteredValues.galleryName,
    filteredValues.artistTypeName,
    filteredValues.colorName,
    filteredValues.countryName,
    filteredValues.styleName,
    filteredValues.subjectName,
    filteredValues.mediumName,
    (filteredValues.startPrice && filteredValues.startPrice !== "0") ||
    (filteredValues.endPrice && filteredValues.endPrice !== "50000")
      ? "$" + filteredValues.startPrice + " - " + "$" + filteredValues.endPrice
      : null,
    (filteredValues.minHeight && filteredValues.minHeight !== 0) ||
    (filteredValues.maxHeight && filteredValues.maxHeight !== 1500)
      ? filteredValues.minHeight +
        " cm" +
        " - " +
        filteredValues.maxHeight +
        " cm" +
        " height"
      : null,
    (filteredValues.minWidth && filteredValues.minWidth !== 0) ||
    (filteredValues.maxWidth && filteredValues.maxWidth !== 1500)
      ? filteredValues.minWidth +
        " cm" +
        " - " +
        filteredValues.maxWidth +
        " cm" +
        " width"
      : null,
    filteredValues.searchTitle,
    filteredValues.offername,
    filteredValues.materialName,
    filteredValues.edition
  ].filter(
    (filterValue) =>
      filterValue !== "" && filterValue !== null && filterValue !== undefined
  );
  // useEffect(() =>  {
  //   if( (filteredValues.startPrice && filteredValues.startPrice !== "0") ||
  //   (filteredValues.endPrice && filteredValues.endPrice !== "50000")) {
  //   // setTimeout(() => {
  //   //   window.scrollTo({
  //   //     top: 100,
  //   //     behavior: "smooth"
  //   //    })
  //   // },[2000])
  //   }else {
  //     window.scrollTo({
  //       top: 100,
  //       behavior: "smooth"
  //      })
  //   }
  // },[selectedFilters])

  const clearCategoryName = (categoryName) => {
    setFilteredValues({ ...filteredValues, categoryName: "" });
    searchParams.delete("category");
  };
  // const clearCategoryName = (categoryToRemove) => {
  //   setFilteredValues((prevData) => ({
  //     ...prevData,
  //     categoryName: Array.isArray(prevData.categoryName)
  //       ? prevData.categoryName.filter((category) => category !== categoryToRemove)
  //       : [],
  //   }));
  // };
  const clearArtistTypeName = (artistTypeName) => {
    setFilteredValues({ ...filteredValues, artistTypeName: "" });
  };
  const clearGalleryName = () => {
    setFilteredValues({ ...filteredValues, galleryName: "" });
  };
  const clearColorName = (colorName) => {
    setFilteredValues({ ...filteredValues, colorName: "" });
  };
  const clearFunctions = {
    [filteredValues.categoryName]: clearCategoryName,
    [filteredValues.artistTypeName]: clearArtistTypeName,
    [filteredValues.colorName]: clearColorName,
    [filteredValues.galleryName]: clearGalleryName,
    [filteredValues.offername]: () =>
      setFilteredValues({ ...filteredValues, offername: "" }),
    [filteredValues.searchTitle]: () => {
      setFilteredValues({ ...filteredValues, searchTitle: "" });
      setSearchTitle("");
    },
    [filteredValues.countryName]: () =>
      setFilteredValues({ ...filteredValues, countryName: "" }),
    [filteredValues.artistNationality]: () =>
      setFilteredValues({ ...filteredValues, artistNationality: "" }),
    [filteredValues.artistCountry]: () =>
      setFilteredValues({ ...filteredValues, artistCountry: "" }),
    [filteredValues.materialName]: () =>
      setFilteredValues({ ...filteredValues, materialName: "" }),
    [filteredValues.edition]: () =>
      setFilteredValues({ ...filteredValues, edition: "" }),
    [filteredValues.galleryCountry]: () =>
        setFilteredValues({ ...filteredValues, galleryCountry: "" }),
    [filteredValues.galleryNationality]: () =>
        setFilteredValues({ ...filteredValues, galleryNationality: "" }),
    [filteredValues.styleName]: () =>
      setFilteredValues({ ...filteredValues, styleName: "" }),
    [filteredValues.subjectName]: () =>
      setFilteredValues({ ...filteredValues, subjectName: ""}),
    [filteredValues.mediumName]: () =>
      setFilteredValues({ ...filteredValues, mediumName: ""}),
    ["$" + filteredValues.startPrice + " - " + "$" + filteredValues.endPrice]:
      () =>
        setFilteredValues({
          ...filteredValues,
          startPrice: "0",
          endPrice: "50000",
        }),
    [filteredValues.minWidth +
    " cm" +
    " - " +
    filteredValues.maxWidth +
    " cm" +
    " width"]: () =>
      setFilteredValues({ ...filteredValues, minWidth: 0, maxWidth: 1500 }),
    [filteredValues.minHeight +
    " cm" +
    " - " +
    filteredValues.maxHeight +
    " cm" +
    " height"]: () =>
      setFilteredValues({ ...filteredValues, minHeight: 0, maxHeight: 1500 }),
    // ... map other filter values to their corresponding clear functions ...
  };
  const removeFilter = (filterValue) => {  
    setFilteredValues((prevValues) => {
      // Detect if filterValue matches a height range
      const minHeight = prevValues.minHeight;
      const maxHeight = prevValues.maxHeight;
      const heightRange = `${minHeight} cm - ${maxHeight} cm height`;
  
      if (filterValue === heightRange) {
        return {
          ...prevValues,
          minHeight: "",
          maxHeight: "",
        };
      }
      // Detect if filterValue matches a price range
      const startPrice = prevValues.startPrice;
    const endPrice = prevValues.endPrice;
    const formattedPriceRange = `$${startPrice} - $${endPrice}`; // Ensure correct formatting

    if (filterValue === formattedPriceRange) {
      return {
        ...prevValues,
        startPrice: "",
        endPrice: "",
      };
    }
      // Detect if filterValue matches a width range
      const minWidth = prevValues.minWidth;
      const maxWidth = prevValues.maxWidth;
      const widthRange = `${minWidth} cm - ${maxWidth} cm width`;
  
      if (filterValue === widthRange) {
        return {
          ...prevValues,
          minWidth: "",
          maxWidth: "",
        };
      }
      // Standard filter removal logic
      const key = Object.keys(prevValues).find((filterKey) => {
        const filter = prevValues[filterKey];
        return Array.isArray(filter) ? filter.includes(filterValue) : filter === filterValue;
      });
  
      if (!key) {
        return prevValues; // No change if value isn't found
      }
  
      if (Array.isArray(prevValues[key])) {
        return {
          ...prevValues,
          [key]: prevValues[key].filter((item) => item !== filterValue),
        };
      } else {
        return {
          ...prevValues,
          [key]: "",
        };
      }
    });
  };
  
  useEffect(() => {
    if (!initialValueSet) return;
    const searchParams = new URLSearchParams();

    if (filteredValues.categoryName.length > 0) {
      searchParams.set("category", filteredValues.categoryName);
    }
    if (filteredValues.styleName) {
      searchParams.set("style-name", filteredValues.styleName);
    }
    if (filteredValues.subjectName) {
      searchParams.set("subject-name", filteredValues.subjectName);
      }
    if (filteredValues.mediumName) {
      searchParams.set("medium-name", filteredValues.mediumName);
      }
    if (filteredValues.style) {
      searchParams.set("style", filteredValues.style);
    }
    if (filteredValues.colorName) {
      searchParams.set("color", filteredValues.colorName);
    }
    if (filteredValues.artistTypeName) {
      searchParams.set("gallery-type", filteredValues.artistTypeName);
    }
    if (filteredValues.materialName) {
      searchParams.set("materialName", filteredValues.materialName);
    }
    if (filteredValues.edition) {
      searchParams.set("edition", filteredValues.edition);
    }
    if (filteredValues.offername) {
      searchParams.set("offername", filteredValues.offername);
    }
    if (filteredValues.galleryName) {
      searchParams.set("galleryName", filteredValues.galleryName);
    }
    if (
      (filteredValues.startPrice && filteredValues.startPrice !== "0") ||
      (filteredValues.endPrice && filteredValues.endPrice !== "50000")
    ) {
      searchParams.set(
        "price-range",
        `${filteredValues.startPrice}-${filteredValues.endPrice}`
      );
    }
    if (filteredValues.minHeight || filteredValues.maxHeight) {
      searchParams.set(
        "height-range",
        `${filteredValues.minHeight}-${filteredValues.maxHeight}`
      );
    }
    if (filteredValues.minWidth || filteredValues.maxWidth) {
      searchParams.set(
        "width-range",
        `${filteredValues.minWidth}-${filteredValues.maxWidth}`
      );
    }
    if (filteredValues.countryName) {
      searchParams.set("country", filteredValues.countryName.toLowerCase());
    }
    if (filteredValues.galleryCountry.length > 0) {
        searchParams.set("galleryCountry", filteredValues.galleryCountry);
    }
    if (filteredValues.galleryNationality.length > 0) {
      searchParams.set("galleryNationality", filteredValues.galleryNationality);
    }
    const newSearch = searchParams.toString()
      ? `?${decodeURIComponent(searchParams.toString())}`
      : "";
    if (
      (filteredValues.categoryName && filteredValues.categoryName.length) > 0 ||
      filteredValues.styleName ||
      filteredValues.style ||
      filteredValues.colorName ||
      filteredValues.subjectName ||
      filteredValues.mediumName ||
      filteredValues.artistTypeName ||
      filteredValues.galleryName ||
      (filteredValues.startPrice && filteredValues.startPrice !== "0") ||
      (filteredValues.endPrice && filteredValues.endPrice !== "50000") ||
      (filteredValues.minHeight && filteredValues.minHeight !== 0) ||
      (filteredValues.maxHeight && filteredValues.maxHeight !== 1500) ||
      (filteredValues.minWidth && filteredValues.minWidth !== 0) ||
      (filteredValues.maxWidth && filteredValues.maxWidth !== 1500) ||
      filteredValues.offername ||
      filteredValues.countryName ||
      filteredValues.materialName ||
      filteredValues.edition ||
      filteredValues.artistNationality ||
      filteredValues.artistCountry ||
      (filteredValues.galleryCountry && filteredValues.galleryCountry.length > 0) ||
      (filteredValues.galleryNationality && filteredValues.galleryNationality.length > 0) 
    ) {
      navigate(`/user/gallery-search${newSearch}`, { replace: true });
    } else {
      navigate(`/user/gallery-search`);
    }
  }, [filteredValues, dispatch]);

  useEffect(() => {
    // Parse URL parameters and set initial state
    const searchParams = new URLSearchParams(window.location.search);
    const params = {
      categoryName: searchParams.get("category")
      ? searchParams.get("category").split(",")
        : [],
      styleName: searchParams.get("style-name") || "",
      subjectName: searchParams.get("subject-name") || "",
      mediumName: searchParams.get("medium-name") || "",
      style: searchParams.get("style") || "",
      colorName: searchParams.get("color") || "",
      offername: searchParams.get("offername") || "",
      materialName: searchParams.get("materialName") || "",
      edition: searchParams.get("edition") || "",
      artistTypeName: searchParams.get("gallery-type") || "",
      galleryName: searchParams.get("galleryName") || "",
      startPrice: searchParams.get("price-range")
        ? searchParams.get("price-range").split("-")[0]
        : "0",
      endPrice: searchParams.get("price-range")
        ? searchParams.get("price-range").split("-")[1]
        : "50000",
      minHeight: searchParams.get("height-range")
        ? searchParams.get("height-range").split("-")[0]
        : "",
      maxHeight: searchParams.get("height-range")
        ? searchParams.get("height-range").split("-")[1]
        : "",
      minWidth: searchParams.get("width-range")
        ? searchParams.get("width-range").split("-")[0]
        : "",
      maxWidth: searchParams.get("width-range")
        ? searchParams.get("width-range").split("-")[1]
        : "",
      countryName: searchParams.get("country") || "",
      artistNationality: searchParams.get("nationality") || "",
      artistCountry: searchParams.get("artistCountry") || "",
      galleryCountry: searchParams.get("galleryCountry")
      ? searchParams.get("galleryCountry").split(",")
        : [],
      galleryNationality: searchParams.get("galleryNationality")
      ? searchParams.get("galleryNationality").split(",") 
      : [],
    };

    setFilteredValues({ ...filteredValues, ...params });
    dispatch(filterAllArts(params, setLoadingArts)); // Ensure dispatch is done with initial params
    setInitialValueSet(true);
  }, [window.location.search, dispatch]);

  useEffect(() => {
    if (initialValueSet) {
      dispatch(filterAllArts(filteredValues, setLoadingArts));
    }
  }, [filteredValues, dispatch, initialValueSet]);
  // const handleChangeFilteredValues = (e) => {
  //   if (e.target.name === "categoryName") {
  //   }
  //   setFilteredValues({ ...filteredValues, [e.target.name]: e.target.value });
  //   window.scrollTo({
  //     top: 120,
  //     behavior: "smooth",
  //   });
  // };
  // const handleChangeFilteredValues = (e) => {
  //   const { name, value } = e.target;
  
  //   setFilteredValues((prevValues) => {
  //     let newValue;
      
  //     // If the existing value is an array, toggle selection
  //     if (Array.isArray(prevValues[name])) {
  //       newValue = prevValues[name].includes(value)
  //         ? prevValues[name].filter((item) => item !== value) // Remove if already selected
  //         : [...prevValues[name], value]; // Add if not selected
  //     } else {
  //       newValue = value; // For non-array fields, simply update the value
  //     }
  
  //     return { ...prevValues, [name]: newValue };
  //   });  
  //   window.scrollTo({
  //     top: 120,
  //     behavior: "smooth",
  //   });
  // };
  const handleChangeFilteredValues = (e) => {
    const { name, value, checked } = e.target;
  
    setFilteredValues((prevValues) => {
      const currentValue = prevValues[name];
      let newValue;
      if (Array.isArray(currentValue)) {
        // Handle array-based filters (multiple selections)
        if (checked) {
          newValue = [...currentValue, value];
        } else {
          newValue = currentValue.filter((item) => item !== value);
        }
      } else {
        // Handle single-value filters
        newValue = currentValue === value ? "" : value;
      }
  
      return { ...prevValues, [name]: newValue };
    });
    window.scrollTo({
      top: 120,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    console.log('filteredValues updated:', filteredValues);
  }, [filteredValues]);


  return (
    <>
      <div className={styles.searchbanner}>
        <Container
          maxWidth="xl"
          sx={{
            paddingLeft: { xs: "16px", sm: "24px", md: "60px", lg: "100px" },
            paddingRight: { xs: "16px", sm: "24px", md: "60px", lg: "100px" },
          }}
        >
          <div className={styles.searchbar}>
            <TextField
              id="input-with-icon-textfield"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              placeholder="Gallery"
              value={searchTitle}
              onChange={handleSearch}
              size="small"
              sx={{
                mb: { xs: 2, sm: 0 },
                width: { xs: "100%", sm: "30% " },
                bgcolor: "#fff",
              }}
            />
            <div className={styles.sractnbtn}>
      <Button
          variant="outlined"
          sx={{ height: "40px", 
            width: { xs: "100%", sm: "100px" },
            bgcolor:"#fff"
           }}
          onClick={handleSubmitSearch}
        >
          <IconButton aria-label="delete" color="primary">
            <Searchicon />
          </IconButton>{" "}
          Search
        </Button>
        <Button
          variant="outlined"
          sx={{ height: "40px",
            width: { xs: "100%", sm: "100px" }, 
            ml:{xs:1, sm:1},
            bgcolor:"#fff"
           }}
          onClick={handleSubmitRemove}
        >
          <IconButton aria-label="delete" color="primary">
            <Close />
          </IconButton>{" "}
          Close
        </Button>
        <div className={styles.filter__div}>
          <FormControl sx={{ minWidth: 120, color: "red",
            ml:{xs:1, sm:1},
            bgcolor:"#fff" }} size="small">
            <Select
              value={filteredValues.galleryName || ""}
              onChange={handleFilter}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className="select"
              sx={{
                color: "#636365",
                border: "0.6px solid #665D6F",
                borderRadius: "6px",
              }}
            >
              
              <MenuItem value="" disabled>
              Gallery
              </MenuItem>
              {galleryListDt &&
                galleryListDt.map((item) => (
                  <MenuItem value={item.gallery_name}>
                    {item.gallery_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>
          </div>
        </Container>
      </div>

      <div className={styles.search}>
        <div className={styles.searchresults__div}>
          <Container
            maxWidth="xl"
            sx={{
              paddingLeft: { xs: "16px", sm: "24px", md: "60px", lg: "100px" },
              paddingRight: { xs: "16px", sm: "24px", md: "60px", lg: "100px" },
            }}
          >
            <div className={styles.tosearch}>
              <Grid container spacing={2} sx={{ alignItems: "center" }}>
                <Grid item xs={12} md={3}>
                  <div className={styles.searchheading}>
                    <h3>Search : Gallery</h3>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexWrap: "wrap",
                    }}
                  >
                    {selectedFilters?.map((val, index) => (
                      <button
                        className={styles.filterButtonsStyles}                       
                      >                        
                        {val}
                        <CloseOutlined
                          onClick={() => removeFilter(val)}
                          sx={{ fontSize: "1rem", marginLeft: "4px" }}
                          className={styles.filterButtonsStylesIcon}
                        />
                      </button>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className={styles.filter__maindiv}>
                    <div className={styles.filter__label}>
                      <span className={styles.filter__span}>Sort By Price</span>
                    </div>
                    <div className={styles.filter__div}>
                      <FormControl
                        sx={{ minWidth: 120, color: "red" }}
                        size="small"
                      >
                        <Select
                          value={selectedSort}
                          onChange={handleSorting}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "& .MuiInputBase-input": {
                              padding: ".45rem .75rem",
                              height: "auto",
                              borderRadius: ".25rem",
                              fontSize: "16px",
                              fontWeight: "400",
                            },
                            border: "1px solid #ced4da",
                            borderRadius: ".25rem",
                            width: "100%",
                            background: "#fff",
                          }}
                        >
                          <MenuItem value="">Sort by</MenuItem>
                          {sortdata &&
                            sortdata.map((val) => (
                              <MenuItem value={val.value}>{val.label}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <ProductGallerySidebar
                  value_height={value_height}
                  value_width={value_width}
                  setValueHeight={setValueHeight}
                  style={style}
                  setStyle={setStyle}
                  subject={subject}
                  setSubject={setSubject}
                  medium={medium}
                  setMedium={setMedium}
                  priceRange={priceRange}
                  filter={filter}
                  setFilter={filter}
                  setPriceRange={setPriceRange}
                  selectedOption={option}
                  setValueWidth={setValueWidth}
                  producttypeData={productType.data}
                  artisttypeData={artistTypes.data}
                  allartistData={userartists.data}
                  allcategoryData={
                    allcategory.data && allcategory.data.allcategories
                  }
                  selectedFilters={selectedFilters}
                  setFilteredValues={setFilteredValues}
                  handleChangeFilteredValues={handleChangeFilteredValues}
                  filteredValues={filteredValues}
                  galleryListDt={galleryListDt}
                  setGalleryListDt={setGalleryListDt}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                {loadingArts ? (
                  <div className={styles.loader}>
                    <CircularLoader />
                  </div>
                ) : (
                  <>
                    <Grid container spacing={2}>
                      {sortedFilterValues.length > 0 ? (
                        <>
                          {sortedFilterValues &&
                            sortedFilterValues?.map((item) => (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                sx={{ mb: 2 }}
                                key={item._id}
                              >
                                <div
                                  className={styles.card__main}
                                  onClick={() =>
                                    navigateToArtworkDetail(item._id)
                                  }
                                >
                                  <div className={styles.card__top}>
                                    <img
                                      className={styles.cardImage}
                                      src={item.image}
                                      alt="img"
                                    />
                                  </div>
                                  <div className={styles.pic_info}>
                                    <div className={styles.card__middle}>
                                      <span className={styles.titles}>
                                        {item.name}
                                      </span>
                                    </div>
                                    <div className={styles.info_like}>
                                      <div className={styles.wishlst}>
                                        <FiHeart />
                                      </div>
                                      <span className={styles.like_count}>
                                        {item?.likesView?.[0]?.likes.length ||
                                          0}
                                      </span>
                                    </div>
                                  </div>
                                  <p className={styles.names}>
                                    {item.artist_id &&
                                      item.artist_id.first_name +
                                        " " +
                                        item.artist_id &&
                                      item.artist_id.last_name}
                                  </p>
                                  <div className={styles.price_sec}>
                                    <div className={styles.card__bottom}>
                                      <span className={styles.buys}>Buy</span>
                                      <span className={styles.prices}>
                                        ${item?.sale?.price}
                                      </span>
                                    </div>
                                    {item?.rent?.rent_price > 0 ? (
                                      <>
                                        {" "}
                                        <div className={styles.hrs} />
                                        <div>
                                          <span className={styles.buys}>
                                            Rent/Month
                                          </span>
                                          <span className={styles.prices}>
                                            ${item?.rent?.rent_price}
                                          </span>
                                        </div>
                                      </>
                                    ) : null}
                                    {item?.print?.[0]?.print_price > 0 ? (
                                      <>
                                        {" "}
                                        <div className={styles.hrs} />
                                        <div>
                                          <span className={styles.buys}>
                                            Print
                                          </span>
                                          <span className={styles.prices}>
                                            ${item?.print[0]?.print_price}
                                          </span>
                                        </div>
                                      </>
                                    ) : null}
                                  </div>                                  
                                </div>
                              </Grid>
                            ))}
                        </>
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            height: "40vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <h2>No ArtWorks Found</h2>
                        </div>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className={styles.ProductContent}>
          <div className={styles.ProductCards}>
            <Container>
              <Grid
                container
                columnSpacing={{ xs: 2, md: 5 }}
                columns={{ xs: 12, sm: 12, md: 12 }}
              ></Grid>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default GallerySearch;
