import React, { useState } from "react"
import styles from "./Tabletwocol.module.css"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import dayjs from "dayjs"
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material"
import Popup from "../../Popupdelete/Popup"
import Tableheader from "../Tableheader/Tableheader"
import Moreoption from "../Moreoption/Moreoption"
import { useSelector } from "react-redux"
import CircularLoader from "../../../../../common/loader/Loader"
import { useLocation, useNavigate } from "react-router-dom"
import { FiTrash2, FiEdit3 } from "react-icons/fi";
export default function Tabletwocol(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(false)
  const threedots = Boolean(anchorEl)
  const [rowdt, setRodt] = useState(null)
  const [showHeading, setShowHeading] = useState(false)
  const loader = useSelector((state) => state.loader)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClicks = (dt) => {
    setRodt(dt)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handlePopup = (e) => {
    props.setOpen(true)
    props.setPoptype("addfeature")
  }
  const handleNavitgateDetailPage = (id) => {
    const path = location.pathname
    setShowHeading(true)
    // switch (path) {
    //   case path.includes("/category"):
    //     navigate(`/category/${id}`);
    //     break;
    // }
    if (path.includes("/category")) {
      navigate(`/category/${id}`)
    }
    if (path.includes("/menu")) {
      navigate(`/menu/${id}`)
    }
  }
  const handleEditIconClick = (id) => {
    navigate(`/menu/heading/${id}`)
  }

  return (
    <>
      {open ? <Popup open={open} setOpen={setOpen} /> : ""}
      <div className={styles.shadow}>
        {/* <Tableheader /> */}
        {/* <Moreoption  artistOptions={artistOptions} /> */}
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "#F6F6F6",
            color: "black",
            boxShadow: "none",
          }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
                {props.head.map((column, i) => (
                  <TableCell
                    align="left"
                    sx={{
                      whiteSpace: "nowrap",
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "16px",
                      height: "21px",
                      color: "#1C1C1C",
                    }}
                    key={i}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loader.loader ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}>
                      {" "}
                      <CircularLoader size={60} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {props.data && props.data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Box className={styles.loader_box}> No Data Found</Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  props.data &&
                  props.data
                  ?.map((row, i) => (
                    <TableRow style={{ color: "#636365" }} key={i}>
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                          cursor: "pointer",
                        }}
                        className={styles.table_column}
                        align="left"
                        onClick={() => handleNavitgateDetailPage(row._id)}
                      >
                        {props.activetab && props.activetab === "category_list"
                          ? row._id
                          : ""}

                        {props.activetab && props.activetab === "menu_list"
                          ? row._id
                          : ""}
                        {props.activetab && props.activetab === "term condition"
                          ? row.title
                          : ""}
                        {props.activetab &&
                        props.activetab === "shipping policy"
                          ? row.title
                          : ""}
                        {props.activetab && props.activetab === "return policy"
                          ? row.title
                          : ""}
                        {props.activetab && props.activetab === "privacy policy"
                          ? row.title
                          : ""}
                        {props.activetab &&
                        props.activetab === "rental agreement"
                          ? row.title
                          : ""}
                      </TableCell>

                      {props.activetab === "artisttype" ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <div className={styles.end_col_auction}>
                            {" "}
                            {row.name}
                            <IconButton
                              onClick={() => {
                                props.setOpen(true)
                                props.setEditdata(row)
                              }}
                            >
                              <FiEdit3 className={styles.editicon} />
                            </IconButton>
                          </div>
                        </TableCell>
                      ) : props.activetab === "subscription_feature" ? (
                        <TableCell
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <div className={styles.end_col_auction}>
                            {" "}
                            {row.name}
                            <IconButton
                              onClick={() => {
                                props.setOpen(true)
                                props.setEditdata(row)
                              }}
                            >
                              <FiEdit3 className={styles.editicon} />
                            </IconButton>
                            <IconButton
                              onClick={() => props.handleDeletefeature(row._id)}
                            >
                              <FiTrash2 className={styles.deleicon}/>
                            </IconButton>
                          </div>
                        </TableCell>
                      ) : props.activetab === "category_list" ? (
                        <TableCell
                          sx={{
                            width: "33%",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                            whiteSpace: "nowrap",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <div className={styles.end_col_auction}>
                            {" "}
                            <span style={{ flex: 1 }}>{row.name}</span>
                            <div className={styles.actionedit}>
                              <IconButton
                                onClick={() => {
                                  props.handleAddCategoryPopup("edit", row)
                                }}
                              >
                                <FiEdit3 className={styles.editicon} />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  props.handleDelete(row._id)
                                }}
                              >
                                <FiTrash2 className={styles.deleicon}/>
                              </IconButton>
                            </div>
                          </div>
                        </TableCell>
                      ) : props.activetab === "menu_list" ? (
                        <TableCell
                          sx={{
                            width: "33%",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                            whiteSpace: "nowrap",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <div className={styles.end_col_auction}>
                            {" "}
                            {row.name}
                          </div>
                        </TableCell>
                      ) : (
                        <TableCell
                          sx={{
                            // width: '30%',
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#636365",
                          }}
                          className={styles.table_column}
                          align="left"
                        >
                          <div className={styles.buyer_flex}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  row &&
                                  row.description &&
                                  row.description.length > 100
                                    ? row.description.substring(0, 100) + "..."
                                    : row && row.description
                                    ? row.description
                                    : "",
                              }}
                            ></span>
                            <Tooltip title="Account settings">
                              <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{
                                  ml: 2,
                                  height: "21.62px",
                                  width: "2.63px",
                                }}
                                aria-controls={
                                  threedots ? "account-menu" : undefined
                                }
                                height="21.62px"
                                width="4.63px"
                                aria-haspopup="true"
                                aria-expanded={threedots ? "true" : undefined}
                              >
                                <img
                                  src="/img/Menu Icon.png"
                                  height="21.62px"
                                  width="7.63px"
                                  alt="img"
                                  onClick={() => handleClicks(row)}
                                />
                              </IconButton>
                            </Tooltip>
                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={threedots}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  width: "150px",
                                  overflow: "visible",
                                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                  filter:
                                    "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                  mt: 2.5,
                                  "& .MuiAvatar-root": {
                                    width: "120px",
                                    height: "132px",
                                    ml: 0.5,
                                    mr: 1,
                                    // mt:5
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    // horizontal: 'center',
                                    right: 62,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "center",
                                vertical: "right",
                              }}
                              anchorOrigin={{
                                horizontal: "left",
                                vertical: "center",
                              }}
                            >
                              <MenuItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                                onClick={() => props.handleEditdata(rowdt)}
                              >
                                Edit
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  lineHeight: "12px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                }}
                                onClick={() => props.handleDelete(rowdt)}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </div>
                        </TableCell>
                      )}
                      {props.activetab && props.activetab === "menu_list" && (
                        <>
                          <Tooltip title="Edit heading">
                            <IconButton
                              onClick={() => handleEditIconClick(row._id)}
                            >
                              <FiEdit3 className={styles.editicon} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit Menu">
                            <IconButton
                              onClick={() => {
                                props.handleAddCategoryPopup("edit", row)
                              }}
                            >
                              <FiEdit3 className={styles.editicon} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete Menu">
                            <IconButton
                              onClick={() => {
                                props.handleDelete(row._id)
                              }}
                            >
                              <FiTrash2 className={styles.deleicon}/>
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                      {/* <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                          cursor: "pointer",
                        }}
                        className={styles.table_column}
                        align="left"
                        onClick={() => handleNavitgateDetailPage(row._id)}
                      >
                        {props.activetab && props.activetab === "menu_list"
                          ? row._id
                          : row.name}
                      </TableCell> */}

                      {/* :
                                            <TableCell sx={{
                                                width: '33%',
                                                fontFamily: 'DM Sans',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '16px',
                                                lineHeight: '21px',
                                                color: '#636365',
                                                whiteSpace: 'nowrap',
                                            }} className={styles.table_column} align="left">
                                                <div className={styles.end_col_auction}> {row.end_date}
                                                    <IconButton onClick={() => setOpen(true)} ><img src={ /img/delete.png'} /></IconButton >
                                                </div>
                                            </TableCell> */}
                      {/* } */}
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
    </>
  )
}
