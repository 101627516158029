import React, { useEffect, useState } from "react"
import styles from "./Buyerpage.module.css"
import Buyertable from "../Table/Buyertable/Buyertable"
import Tableheader from "../Table/Tableheader/Tableheader"
import Popup from "../Popupdelete/Popup"
import { useDispatch, useSelector } from "react-redux"
import {
  blockUnblockBuyer,
  deleteBuyer,
  fetchBuyerData,
  handleAddBuyer,
} from "../../../../State/api/superadminApi"
// import { useSelect } from "@mui/base";
import Add from "../Popupdelete/addPopup/Addpopup"
import { AlertsSuccess } from "../../../../common/alert/Alert"
import { VpnLock } from "@mui/icons-material"
import { setBuyerdata } from "../../../../State/Features/superadmin/buyerSlice"
import Pagination from "../Pagination/Pagination"
const headData = [
  {
    id: "customer_id",
    label: "Customer ID",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "Buyer Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "created_date",
    label: "Created Date",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "email",
    label: "Email",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "country",
    label: "Country",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "item",
    label: "No. of items",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "newsletter",
    label: "Newsletter",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "contact",
    label: "Contact No.",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "action",
    label: "Action",
    format: (value) => value.toLocaleString(),
  },
]

const sortdata = [
  { label: "By Name ↓", value: "low" },
  { label: "By Name ↑", value: "high" },
]
const filterdata = [
  { label: "Blocked", value: "blocked" },
  { label: "Unblocked", value: "unblocked" },
  //  { label: "Rejected", value: "rejected" },
]

export default function Buyerpage() {
  const [button, setButton] = useState({ type: "add", text: "Add" })
  const [open, setOpen] = useState(false)
  const [add, setAdd] = useState(false)
  const [data, setData] = useState({
    limit: 10,
    offset: 1,
    is_blocked: "true",
  })
  const [popupdata, setPopupdata] = useState(null)

  // const pagination = useSelect((state) => state.pagination)
  const [newbuyer, setNewbuyer] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    phone_is_verified: "yes",
    email_is_verified: "yes",
    password: "",
    role: "admin",
  })
  // const [newbuyer,setNewbuyer] = useState({first_name: "Nisat",last_name: "Anjum", email:"nishat@gmail.com", phone: "1234567090", phone_is_verified: "yes", email_is_verified: "yes", password: "password123", role: "admin"})
  const buyerData = useSelector((state) => state.buyerData.data)
  console.log(buyerData, "buyerData--")
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })
  const dispatch = useDispatch()
  const handleAddnew = () => {
    dispatch(handleAddBuyer(newbuyer, setAdd, fetchData))
  }
  const handleDeletebuyer = (id) => {
    dispatch(deleteBuyer(id, setOpen, fetchData))
  }
  const handlePopup = (data) => {
    setOpen(true)
    setPopupdata(data)
  }
  const handleBlockunblock = (id, data) => {
    dispatch(blockUnblockBuyer(id, { is_blocked: data }, fetchData, setOpen))
  }
  useEffect(() => {
    fetchData()
  }, [data])

  const fetchData = () => {
    dispatch(fetchBuyerData(data))
  }
  

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setNewbuyer({ ...newbuyer, [name]: value })
  }

  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })
  }

  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }

  const handleSorting = (e) => {
    let value = e.target.value
    if (value === "low") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setBuyerdata({
          data:
            buyerData.data &&
            [...buyerData.data].sort((a, b) => {
              if (
                a.first_name.toLowerCase() + a.last_name.toLowerCase() <
                b.first_name.toLowerCase() + b.last_name.toLowerCase()
              )
                return -1
            }),
          totalCount: buyerData.totalCount,
        })
      )
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setBuyerdata({
          data:
            buyerData.data &&
            [...buyerData.data].sort((a, b) => {
              if (
                a.first_name.toLowerCase() + a.last_name.toLowerCase() >
                b.first_name.toLowerCase() + b.last_name.toLowerCase()
              )
                return -1
            }),
          totalCount: buyerData.totalCount,
        })
      )
    } else {
      fetchData()
      setCondition({ ...condition, sorting: value })
    }
  }
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Popup
          handleBlockunblock={handleBlockunblock}
          popupdata={popupdata}
          fetchData={fetchData}
          handleDeletebuyer={handleDeletebuyer}
          open={open}
          setOpen={setOpen}
        />
      ) : (
        ""
      )}
      {add ? (
        <Add
          handleChange={handleChange}
          poptype="buyer"
          fetchData={fetchData}
          handleAddnew={handleAddnew}
          open={add}
          setOpen={setAdd}
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          open={add}
          setOpen={setAdd}
          button={button}
        />
        <Buyertable
          handleBlockunblock={handleBlockunblock}
          handlePopup={handlePopup}
          headData={headData}
          data={
            buyerData &&
            buyerData.data
              .filter((val) => {
                if (condition.search === "") {
                  return val
                }
                // else if ((val.first_name.toLowerCase() + val.last_name.toLowerCase()).includes(condition.search.toLowerCase())) {
                //   return val
                // }
                {
                  const fullName = `${val.first_name || ""} ${
                    val.last_name || ""
                  }`
                    .toLowerCase()
                    .trim()
                  const search = condition.search.toLowerCase().trim()
                  const fullNameMatch = fullName.includes(search)
                  const nameMatch =
                    val.first_name &&
                    val.first_name.toLowerCase().includes(search)
                  const lastnameMatch =
                    val.last_name &&
                    val.last_name.toLowerCase().includes(search)
                  const emailMatch =
                    val.email && val.email.toLowerCase().includes(search)
                  const contactMatch =
                    val.phone && val.phone.toLowerCase().includes(search)
                  const IDMatch =
                    val._id && val._id.toLowerCase().includes(search)

                  return (
                    nameMatch ||
                    fullNameMatch ||
                    lastnameMatch ||
                    emailMatch ||
                    contactMatch ||
                    IDMatch
                  )
                }
              })
              .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
              .filter((val) => {
                if (condition.filter === "blocked") {
                  return val.is_blocked === true
                } else if (condition.filter === "unblocked") {
                  return val.is_blocked === false
                } else {
                  return val
                }
              })
          }
        />
        {/* {buyerData &&
          Array.isArray(buyerData.data) &&
          buyerData.data.length > 0 && (
            <Pagination
              totaldata={buyerData && buyerData.totalCount}
              pagination={data}
              setPagination={setData}
            />
          )} */}
      </div>
    </div>
  )
}
