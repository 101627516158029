import { Button, Link, Grid } from "@mui/material";
import React, { useState } from "react";
import serverimage from "../../../../../serverConstant";
import styles from "./Commondetailexshibitiongallery.module.css";
import capitalizeWords from "../../../../../utils/capitalizeContent";
import dateFormat from "../../../../../utils/dateFormat";
import TableForBuyerBid from "../../../../../artist/commonTables/EightcolimgArtist/TableForBuyerBid";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

export default function CommondetailAuctionaArtist(props) {
  const [open, setOpen] = useState(false);
  console.log("Act props data", props.data && props.data.data.data)
  console.log("Auction Details page",props);
  const handlePopup = () => {
    props.setOpen && props.setOpen({ open: true });
  };
  const handleDatepopup = () => {
    props.setOpen({ dateopen: true });
  };

  const navigate = useNavigate()

  const handlePages = () => {
    navigate("/auctions")
  }


  return (
    <div >
      <div className={styles.back_button}>
        <Link onClick={handlePages} className={styles.backbn}> <IoIosArrowBack /> <span> Go Back</span></Link>
      </div>
      {props.type === "order_detail_gallery" ? (
        ""
      ) : (
        <>    
        </>
      )}
      <div className={styles.more_detail}>
        <div className={styles.bdngr}>
          <h3 className={styles.sethng}>Auction Title : {capitalizeWords(props.data && props.data.data.auction?.name)}</h3>
          <p className={styles.odlist}>Description : <span>{capitalizeWords(props.data && props.data.data.auction?.description)}</span></p>
          <p className={styles.odlist}>Created Date : <span>{dateFormat(props.data && props.data.data.auction?.createdAt)}</span></p>
          <p className={styles.odlist}>Start Date : <span>{dateFormat(props.data && props.data.data.auction?.start_date)}</span></p>
          <p className={styles.odlist}>End Date : <span>{dateFormat(props.data && props.data.data.auction?.end_date)} </span></p>
          <p className={styles.odlist}>Application End Date : <span>{dateFormat(props.data && props.data.data.auction?.application_end_date)}</span></p>
          <p className={styles.odlist}>No. of Items : <span>{props.data === null ? 0 : props.data?.data.data.length === 0 ? "0" : props.data.data.data.length}</span></p>

        </div>
      </div>
      {props.data === null ?
        <h2 align="center">No Data Found</h2>
        : props.data.data && props.data.data.data.length === 0 ? (
          <h2 align="center">No Data Found</h2>
        )
          : (
            props?.data &&
            props?.data?.data?.data &&
            props?.data?.data?.data?.map((item, i) => (
              <>
                <div className={styles.bsrd}>
                  <div key={i}>
                    <div className={styles.card_main_content}>
                      <Grid
                        container
                        spacing={2}
                        sx={{ justifyContent: "space-between" }}
                      >
                        <Grid item xs={12} sm={5} md={4}>
                          <div className={styles.more_detail}>
                            <div>
                              {item.item.map((val, i) => (
                                <div>
                                  <h1 className={styles.art_name}>{val.name}</h1>
                                  <div style={{ padding: "10px 0" }}>
                                    <img src={val.image} className="img-fluid" alt='img' />{" "}
                                  </div>
                                  {localStorage.getItem('usertype') === 'gallery' ?
                                    <p className={styles.odlist}>Artist Name : <span>{item.artist}</span></p>
                                    : ""}
                                </div>
                              ))}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={5} md={4}>
                          <div className={styles.status}>
                            {props.type === "auction_gallery" ? (
                              item.item_status === "Sold" ? (
                                <Button
                                  sx={{ color: "#35BE43", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                                  Sold
                                </Button>
                              ) : item.item_status === "Unsold" ? (
                                <Button
                                  sx={{ color: "#EE0909", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                                  Unsold
                                </Button>
                              ) : (
                                <Button
                                  sx={{ color: "#FFC705", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                                  Pending
                                </Button>
                              )
                            ) : props.type === "exshibition_gallery" ? (
                              item.item_status === "Sold" ? (
                                <Button
                                  sx={{ color: "#35BE43", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                                  Sold
                                </Button>
                              ) : item.item_status === "Unsold" ? (
                                <Button
                                  sx={{ color: "#EE0909", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                                  Unsold
                                </Button>
                              ) : (
                                <Button
                                  sx={{ color: "#FFC705", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                                  Pending
                                </Button>
                              )
                            ) :
                              props.type === "order_detail_gallery" ? (
                                item.content === "1" ? (
                                  <Button
                                    sx={{ color: "#EE0909", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                                    Rejected
                                  </Button>
                                ) : item.content === "2" ? (
                                  <div className={styles.item_acc_dec_div}>
                                    <Button variant="contained" sx={{ width: "120px", height: "46px" }}>
                                      Accept
                                    </Button>
                                    <Button
                                      onClick={handlePopup}
                                      variant="outlined"
                                      sx={{ backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                                      Decline
                                    </Button>
                                  </div>
                                ) : item.order_status?.toLowerCase() === "schedule pickup pending" ? (
                                  <Button
                                    onClick={handleDatepopup}
                                    variant="contained"
                                    sx={{ width: "226px", height: "46px" }}>
                                    Pick A Shedule
                                  </Button>
                                ) : item.content === "0" ? (
                                  <Button
                                    sx={{ color: "#35BE43", backgroundColor: "#EAEAEA", width: "326px", height: "46px" }}>
                                    Product Recived at Artist’s Location
                                  </Button>
                                ) : (
                                  <p className={styles.schedule_time}>{item.order_status}</p>
                                )
                              ) : item.content === "0" ? (
                                <Button
                                  sx={{ color: "#35BE43", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                                  Accept
                                </Button>
                              ) : item.content === "1" ? (
                                <Button
                                  sx={{ color: "#EE0909", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                                  Rejected
                                </Button>
                              ) : (
                                <Button
                                  sx={{ color: "#FFC705", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                                  Pending
                                </Button>
                              )}
                          </div>
                        </Grid>
                      </Grid>
                      <div>
                        {item.item.map((val, i) => (
                          <div>
                            <p className={styles.odlist}>Materials : <span>{val.material && val.material.map(c => <>{c.name}, </>)}</span></p>
                            <p className={styles.odlist}>Category : <span>{val?.category}</span></p>
                            <p className={styles.odlist}>Dimensions : <span>{val?.width} W × {val?.height} H × {val?.depth} D in cm</span></p>
                          </div>
                        ))}
                      </div>
                      <div className={styles.action}>
                        <p className={styles.remark_text}>{item.remark}</p>
                      </div>

                    </div>
                  </div>
                </div>
                <TableForBuyerBid bids={item} />

              </>
            ))

          )}

    </div>
  );
}
