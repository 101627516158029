import validator from "validator"
import isEmpty from "./isEmpty"

export const validateAdminCareer = (career) => {
  let careerErrors = {}

  if (validator.isEmpty(career.title)) {
    careerErrors.title = "Title is required"
  }

  if (validator.isEmpty(career.location)) {
    careerErrors.location = "Location is required"
  }
  if (validator.isEmpty(career.min_exp)) {
    careerErrors.min_exp = "Min experience is required"
  }
  if (validator.isEmpty(career.max_exp)) {
    careerErrors.max_exp = "Max experience is required"
  }else if (Number(career.min_exp) > Number(career.max_exp)) {
    careerErrors.min_exp = "Min experience should be less than or equal to Max experience"
  }

  if (validator.isEmpty(career.type_of_position)) {
    careerErrors.type_of_position = "Type of position is required"
  }
  if (validator.isEmpty(career.city)) {
    careerErrors.city = "city is required"
  }
  if (validator.isEmpty(career.state)) {
    careerErrors.state = "state is required"
  }
  if (validator.isEmpty(career.country)) {
    careerErrors.country = "country is required"
  }
  if (validator.isEmpty(career.salary)) {
    careerErrors.salary = "salary is required"
  }

  if (validator.isEmpty(career.department)) {
    careerErrors.department = "Department is required"
  }
  if (validator.isEmpty(career.description) || career.description === "<p><br></p>") {
    careerErrors.description = "Description is required"
  }

  return {
    careerErrors,
    isValid: isEmpty(careerErrors),
  }
}
