import { Button } from "@mui/material"
import React, { useEffect, useState } from "react"
import Enterdetails from "../Popupdelete/enterDetails/Enterdetails"
import styles from "./Faq.module.css"
import { fetchFaq } from "../../../../State/api/userApi"
import homeImage from "../../../../constant"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchFaqData,
  handleAddFaq,
  handleAddFAQcat,
} from "../../../../State/api/superadminApi"
import { AlertsSuccess } from "../../../../common/alert/Alert"
import { fetchUsertypes } from "../../../../State/api/commonapi"
import { useNavigate } from "react-router-dom"
import Faqlist from "./faqList/Faqlist"
import CsvfileFAQ from "../../../../downloadFiles/CsvFileFAQ"
import { RiArrowRightCircleFill } from "react-icons/ri"

const datas = [
  {
    title: "FOR ARTIST",
  },
  {
    title: "FOR CURATOR",
  },
  {
    title: "FOR AFFILIATE",
  },
  {
    title: "FOR BUYER",
  },
  {
    title: "FOR GALLERY",
  },
]
export default function Faq() {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState({ offset: 0 })
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userFaqData = useSelector((state) => state.userFaqData)
  const FAQ_data = userFaqData?.data?.responses
  console.log(FAQ_data, "FAQ_data---")
  const handlePopup = () => {
    setOpen(true)
  }
  const userType = useSelector((state) => state.usertype)

  const fetchUserFaqType = () => {
    dispatch(fetchUsertypes())
  }
  useEffect(() => {
    fetchUserFaqType()
  }, [])

  useEffect(() => {
    dispatch(fetchFaq())
  }, [])

  const handleFaqlistpage = (id, name) => {
    navigate(`/faq/${id}`, { state: { name } })
    setData({ ...data, user_type_id: id })
  }
  const handleAddfaqCat = (data) => {
    dispatch(handleAddFAQcat(data, setOpen, fetchUserFaqType))
  }
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Enterdetails
          poptype="faq"
          open={open}
          setOpen={setOpen}
          handleAddfaqCat={handleAddfaqCat}
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <div className={styles.faq_header}>
          {/* <Button variant="contained" onClick={handlePopup}>
            Upload FAQ
          </Button> */}
          {FAQ_data ? <CsvfileFAQ FAQ_data={FAQ_data} /> : <></>}
        </div>
        {userType.data &&
          userType.data.map((val, i) =>
            val.name === "superadmin" ? (
              ""
            ) : (
              <div
                className={styles.card}
                onClick={() => handleFaqlistpage(val._id, val.name)}
              >
                <p className={styles.card_title}>For {val.name}</p>
                <div className={styles.open_arrow}>
                <RiArrowRightCircleFill className={styles.arico} />
                  {/* <img src={"/img/right_close.png"} height="9px" width="9px" /> */}
                </div>
              </div>
            )
          )}
      </div>
    </div>
  )
}
