import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertsSuccess, Errornotify,Warnnotify, Warnnoti } from "../../../../common/alert/Alert";
import {
  acceptRejectGallery,
  blockUnblockGallery,
  deleteGallery,
  fetchCuratorList,
  fetchGalleryData,
  handleAddGallery,
  setRecommendedGallery,
} from "../../../../State/api/superadminApi";
import { setGallery } from "../../../../State/Features/superadmin/gallerySlice";
import Pagination from "../Pagination/Pagination";
import Tablesfivecol from "../Table/Fivecolumn/Tablefivecol";
import Moreoption from "../Table/Moreoption/Moreoption";
import Tables from "../Table/sixcolumn/Tables";
import Tableheader from "../Table/Tableheader/Tableheader";
import styles from "./Gallery.module.css";
import Add from "../Popupdelete/addPopup/Addpopup";
import TablesApproved from "../Table/Fourcolumn/TablesApproved";
import Tablesfour from "../Table/Fourcolumn/Tables";
import PopupCuratorList from "../Popupdelete/PopupCuratorList";


const moreOptions = [
  {
    id: "pending",
    label: "Pending",
    value: "gallery_pending",
  },
  {
    id: "approved",
    label: "Approved",
    value: "gallery_approved",
  },
  {
    id: "decline",
    label: "Decline",
    value: "gallery_decline",
  },
];
const exhibitionhead = [
  {
    id: "company_name ",
    label: "Company Name",
  },
  {
    id: "gallery",
    label: "Gallery",
  },
  {
    id: "gallery_email",
    label: "Email",
  },
  {
    id:'gallery_phone',
    label:'Contact Number'
  },
  {
    id: "date",
    label: "Created Date",
  },
  {
    id: "assign_to",
    label: "Assign to",
  },
  // {
  //   id: "type",
  //   label: "Type",
  // },
  {
    id: "action",
    label: "Action",
  },
];
const approvedhead = [
  {
    id: "company_name ",
    label: "Company Name",
  },
  {
    id: "gallery",
    label: "Gallery",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "gallery_email",
    label: "Email",
  },
  {
    id:'gallery_phone',
    label:'Contact Number'
  },
  {
    id: "date",
    label: "Approved Date",
  },
  {
    id: "assign_to",
    label: "Approved by",
  },
  // {
  //   id: "type",
  //   label: "Type",
  // },
  {
    id: "subscription",
    label: "Subscription",
  },
  {
    id: "like",
    label: "Likes",
  },
  {
    id: "followe",
    label: "Followers",
  },
  {
    id:'action',
    label:"Action"
  }
];


const approvedheadDecline = [
  {
    id: "company_name ",
    label: "Company Name",
  },
  {
    id: "artist_name ",
    label: "Gallery Name",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "gallery_email",
    label: "Email",
  },
  {
    id:'gallery_phone',
    label:'Contact Number'
  },
  {
    id: "date",
    label: "Decline Date",
  },

  {
    id: "subscription",
    label: "Subscription",
  },
  {
    id: "like",
    label: "Likes",
  },
  {
    id: "followe",
    label: "Followers",
  },
];

const sortdata = [
  { label: "Oldest to Newest", value: "low" },
  { label: "Newest to Oldest", value: "high" },
];
const filterdata = [
  { label: "Name (A-Z)", value: "name-asc" },
  { label: "Name (Z-A)", value: "name-desc" },
];

export default function Gallery() {
  const [activetab, setAcitvetab] = useState("gallery_pending");
  const [button, setButton] = useState({ type: "add", text: "Add" });
  const [popup, setPopup] = useState(false);
   const [curName,setCurName] = useState('')
   const [curatorUser, setCuratorUser] = useState("");
   const [openCur, setOpenCur] = useState(false);
    const [popupdata, setPopupdata] = useState(null);
   const [curatorData, setCuratorData] = useState([]);
  const [data, setData] = useState({
    offset: 1,
    is_accepted: "Pending",
  });
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  });
  const [newgallery, setNewGallery] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
  });

  const dispatch = useDispatch();
  const gallerydata = useSelector((state) => state.galleryData.data);
  const handleMorecategories = (e) => {
    setAcitvetab(e);
  };

  const curatorsData = useSelector((state) => state.curatorData.data);

  console.log("curatorDatad",curatorsData)

  console.log("gallerydata", gallerydata);
  console.log("condition", condition);
  useEffect(() => {}, []);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewGallery({ ...newgallery, [name]: value });
  };
  const handleAddnew = () => {
    if (newgallery.phone.length !== 10) {
      Errornotify("Please Enter Valid Number");
    } else if (newgallery.password.length < 8) {
      Errornotify("Please fill password atleast 8 character");
    } else {
      dispatch(handleAddGallery(newgallery, setPopup, fetchData));
    }
  };
  const fetchData = () => {
    dispatch(fetchGalleryData(data));
  };
  useEffect(() => {
    fetchData();
  }, [data]);

  const handleAcceptDecline = (id, dt) => {
    dispatch(acceptRejectGallery(id, { is_accepted: dt }, fetchData));
  };
  const handleBlockUnblock = (id, dt) => {
    dispatch(blockUnblockGallery(id, { is_blocked: !dt }, fetchData));
  };
  const handleRecommended = (id, dt) => {
    dispatch(setRecommendedGallery(id, { is_recommended: !dt }, fetchData));
  };


  // const handleDelete = (id) => {
  //   console.log("getid")
  //   if(window.confirm("Are you sure?")){
  //     dispatch(deleteGallery(id, fetchData));
  //   }
   
  // };


  const handleDelete = (id) => {
    console.log("getid");
  
    Warnnoti("Are you sure?", () => {
      dispatch(deleteGallery(id, fetchData));
    });
  };
  
  
  
  const handleFilter = (event) => {
    const value = event.target.value;
    setCondition({ ...condition, filter: value });

    if (value === "name-asc") {
      dispatch(
        setGallery({
          data:
            gallerydata.data &&
            [...gallerydata.data].sort((a, b) =>
              a.first_name.toLowerCase() > b.first_name.toLowerCase() ? 1 : -1
            ),
          totalCount: gallerydata.totalCount,
        })
      );
    } else if (value === "name-desc") {
      dispatch(
        setGallery({
          data:
            gallerydata.data &&
            [...gallerydata.data].sort((a, b) =>
              a.first_name.toLowerCase() < b.first_name.toLowerCase() ? 1 : -1
            ),
          totalCount: gallerydata.totalCount,
        })
      );
    } else {
      fetchData();
    }
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    setCondition({ ...condition, search: value });
  };

  // const handleSorting = (e) => {
  //   let value = e.target.value
  //   if (value === "low") {
  //     setCondition({ ...condition, sorting: value })
  //     dispatch(
  //       setGallery({
  //         data:
  //           gallerydata.data &&
  //           [...gallerydata.data].sort((a, b) => {
  //             if (a.createdAt.toLowerCase() < b.createdAt.toLowerCase())
  //               return -1
  //           }),
  //         totalCount: gallerydata.totalCount,
  //       })
  //     )
  //   } else if (value === "high") {
  //     setCondition({ ...condition, sorting: value })
  //     dispatch(
  //       setGallery({
  //         data:
  //           gallerydata.data &&
  //           [...gallerydata.data].sort((a, b) => {
  //             if (a.createdAt.toLowerCase() > b.createdAt.toLowerCase())
  //               return -1
  //           }),
  //         totalCount: gallerydata.totalCount,
  //       })
  //     )
  //   } else {
  //     fetchData()
  //     setCondition({ ...condition, sorting: value })
  //   }
  // }

  const handleSorting = (e) => {
    const value = e.target.value;
    setCondition({ ...condition, sorting: value });

    if (value === "low") {
      dispatch(
        setGallery({
          data: gallerydata.data
            ? [...gallerydata.data].sort(
                (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
              )
            : [],
          totalCount: gallerydata.totalCount,
        })
      );
    } else if (value === "high") {
      dispatch(
        setGallery({
          data: gallerydata.data
            ? [...gallerydata.data].sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
              )
            : [],
          totalCount: gallerydata.totalCount,
        })
      );
    } else {
      fetchData();
    }
  };

  const filterIdByName = (data) => {
    const findId = curatorsData.data.find(
      (item) => item.first_name + " " + item.last_name === data
    );

    console.log('findId',findId)
  
    if (findId) {
      setCurName(findId._id); // Assuming you want to set the ID
    } else {
      console.log("Not found");
    }
  };


   const handlePopup = (data) => {
      const country = data?.country;
      console.log("countru",data)
      dispatch(fetchCuratorList(country, setCuratorData));
      setOpenCur(true);
      setPopupdata(data?._id);
      const fetchCurName = data?.gallery[0]?.curator[0]?.curator_name
      console.log('fetchCurName',fetchCurName)
      filterIdByName(fetchCurName)
      fetchData();
    };



  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {popup ? (
        <Add
          handleChange={handleChange}
          poptype="curator"
          fetchData={fetchData}
          handleAddnew={handleAddnew}
          open={popup}
          setOpen={setPopup}
        />
      ) : (
        ""
      )}

       {openCur ? (
              <PopupCuratorList
                popupdata={popupdata}
                fetchData={fetchData}
                open={openCur}
                setOpen={setOpenCur}
                curatorData={curatorData}
                setCuratorUser={setCuratorUser}
                curatorUser={curatorUser}
                curatorsData={curatorsData}
                curName={curName}
              />
            ) : (
              ""
            )}
      <div className={styles.components}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          button={button}
          setOpen={setPopup}
        />
        <Moreoption
          activetab={activetab}
          moreOptions={moreOptions}
          handleMorecategories={handleMorecategories}
          setData={setData}
          data={data}
        />
        {activetab === "gallery_pending" ? (
          <Tables
            handleDelete={handleDelete}
            handleBlockUnblock={handleBlockUnblock}
            handleRecommended={handleRecommended}
            handleAcceptDecline={handleAcceptDecline}
            handlePopup={handlePopup}
            activetab="gallery_pending"
            head={exhibitionhead}
            data={
              gallerydata &&
              gallerydata.data.filter((val) => {
                const fullName =
                  `${val.first_name} ${val.last_name}`.toLowerCase();
                return (
                  val.is_accepted === "Pending" &&
                  fullName.includes(condition.search.toLowerCase())
                  // val.first_name +
                  //   " " +
                  //   val.last_name
                  //     .toLowerCase()
                  //     .includes(condition.search.toLowerCase())
                );
              })
            }
          />
        ) : activetab === "gallery_decline" ? (

          <Tablesfour
          handleDelete={handleDelete}
          activetab={activetab}
          head={approvedheadDecline}
          data={
            gallerydata &&
            gallerydata.data.filter((val) => {
              const fullName =
                `${val.first_name} ${val.last_name}`.toLowerCase();
              return (
                val.is_accepted === "Rejected" &&
                fullName.includes(condition.search.toLowerCase())
                // val.first_name +
                //   " " +
                //   val.last_name
                //     .toLowerCase()
                //     .includes(condition.search.toLowerCase())
              );
            })
          }
          />
          // <Tablesfivecol
          //   handleDelete={handleDelete}
          //   activetab={activetab}
          //   head={approvedhead}
          //   data={
          //     gallerydata &&
          //     gallerydata.data.filter((val) => {
          //       const fullName =
          //         `${val.first_name} ${val.last_name}`.toLowerCase();
          //       return (
          //         val.is_accepted === "Rejected" &&
          //         fullName.includes(condition.search.toLowerCase())
          //         // val.first_name +
          //         //   " " +
          //         //   val.last_name
          //         //     .toLowerCase()
          //         //     .includes(condition.search.toLowerCase())
          //       );
          //     })
          //   }
          // />
        ) : (

          <TablesApproved
          handleDelete={handleDelete}
            activetab={activetab}
            head={approvedhead}
            data={ 
              gallerydata?.data // Ensure gallerydata exists
                ?.filter((val) => {
                  const fullName = `${val.first_name || ""} ${val.last_name || ""}`.toLowerCase();
                  const searchMatch = fullName.includes(condition.search.toLowerCase());
                  return val.is_accepted === "Approved" && searchMatch;
                })             
                ?.sort((a, b) => {
                  if (condition.filter === "name-asc") {
                    const result = a.first_name.localeCompare(b.first_name, undefined, {
                      sensitivity: "base",
                    });
                    return result;
                  } else if (condition.filter === "name-desc") {
                    const result = b.first_name.localeCompare(a.first_name, undefined, {
                      sensitivity: "base",
                    });                  
                    return result;
                  }
                  return 0; // Default order if no sorting condition
                })
            }
          
          />
        //   <Tablesfivecol
        //   handleDelete={handleDelete}
        //   activetab={activetab}
        //   head={approvedhead}
        //   data={ 
        //     gallerydata?.data // Ensure gallerydata exists
        //       ?.filter((val) => {
        //         const fullName = `${val.first_name || ""} ${val.last_name || ""}`.toLowerCase();
        //         const searchMatch = fullName.includes(condition.search.toLowerCase());
        //         return val.is_accepted === "Approved" && searchMatch;
        //       })             
        //       ?.sort((a, b) => {
        //         if (condition.filter === "name-asc") {
        //           const result = a.first_name.localeCompare(b.first_name, undefined, {
        //             sensitivity: "base",
        //           });
        //           return result;
        //         } else if (condition.filter === "name-desc") {
        //           const result = b.first_name.localeCompare(a.first_name, undefined, {
        //             sensitivity: "base",
        //           });                  
        //           return result;
        //         }
        //         return 0; // Default order if no sorting condition
        //       })
        //   }
        // />
        )}
        {/* {
           activetab === "gallery_decline"||activetab ==='gallery_approved'?
           <Pagination
           totaldata={gallerydata && gallerydata.totalCount}
           pagination={data}
           setPagination={setData}
         />
           :''
        } */}
       
      </div>
    </div>
  );
}
