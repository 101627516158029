import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material"
import React, { useState } from "react"
import styles from "./Tables.module.css"
import { useSelector } from "react-redux"
import CircularLoader from "../../../../../common/loader/Loader"

import { Link, useNavigate } from "react-router-dom"

import dayjs from "dayjs"

export default function CuratorGalleryFour(props) {
 
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [ids, setIds] = useState("")
  const navigate = useNavigate()
  const threedots = Boolean(anchorEl)
  const [threedot_id, setThreedot_id] = React.useState("")
  const loader = useSelector((state) => state.loader)


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setIds(event.target.id)
    setThreedot_id(event.target.id)
  }
  const handleClose = () => {
    setAnchorEl(null)
    
  }
  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#f6f6f6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head &&
                props.head.map((column, i) => (
                  <TableCell
                    align={props.head.length - 1 === i ? "center" : "center"}
                    sx={{
                      whiteSpace: "nowrap",
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "16px",
                      height: "21px",
                      color: "#1C1C1C",
                    }}
                    key={i}
                  >
                    {column.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props.data &&
                props.data.map((row, i) => (
                  <TableRow
                    style={{ color: "#636365", height: "30px" }}
                    className={styles.table_row}
                    key={row._id}
                  >
                    <TableCell
                     disabled={row.is_blocked}
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: row.is_blocked ? 'lightgray' : "#636365",
                        pointerEvents: row.is_blocked ? "none" : "visible"
                        
                      }}
                      style={{ cursor: "pointer" }}
                      className={styles.table_column}
                      align="center"
                     
                    >
                       {row.is_blocked ? (
    <span style={{color : "lightgray", pointerEvents:"none"}} className={styles.hyperlink_title}>
      {row.first_name} {row.last_name}
    </span>
  ) : (
    <Link className={styles.hyperlink_title} to={`/gallery/${row._id}`}>
      {row.first_name} {row.last_name}
    </Link>
  )}
                    </TableCell>
                    <TableCell
                    disabled={row.is_blocked}
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: row.is_blocked ? 'lightgray' : "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                   {row?.gallery?.length > 0 
  ? row.gallery
      .flatMap((artist) =>
        artist.addresses?.map((address) =>
          address?.country?.trim() ? address.country : "NA"
        ) || []
      )
      .filter(Boolean) // Removes empty or null entries
      .join(", ")
  : "NA"}

                    </TableCell>

                    <TableCell
                     disabled={row.is_blocked}
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: row.is_blocked ? 'lightgray' : "#636365",
                        pointerEvents: row.is_blocked ? "none" : "visible"
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                      <div  > {row?.email}</div>
                      <div  >{row?.phone}</div>
                    </TableCell>
                  
                    <TableCell
                    disabled={row.is_blocked}
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: row.is_blocked ? 'lightgray' : "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                    {dayjs(row?.createdAt).format("DD-MM-YYYY").toString()}
                    </TableCell>
                  

                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: row.is_blocked ? 'lightgray' : "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                      disabled={row.is_blocked}
                    >
                  {row?.likes}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: row.is_blocked ? 'lightgray' : "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                      disabled={row.is_blocked}
                    >
                  {row?.followers}
                    <>
                                        
                                            
                                             <Tooltip title="Account settings">
                                              <IconButton
                                                onClick={handleClick}
                                                size="small"
                                                id={row._id}
                                                sx={{ ml: 2 }}
                                                aria-controls={
                                                  threedots ? "account-menu" : undefined
                                                }
                                                aria-haspopup="true"
                                                aria-expanded={threedots ? "true" : undefined}
                                              >
                                                <img
                                                  src={"/img/Menu Icon.png"}
                                                  id={row._id}
                                                  height="21.62px"
                                                  alt="img"
                                                  width="4.63px"
                                                />
                                              </IconButton>
                                            </Tooltip>
                                            <Menu
                                              anchorEl={anchorEl}
                                              id="account-menu"
                                              open={threedots}
                                              onClose={handleClose}
                                              onClick={handleClose}
                                              PaperProps={{
                                                elevation: 0,
                                                sx: {
                                                  width: "150px",
                                                  overflow: "visible",
                                                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                                  filter:
                                                    "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                                  mt: 2.5,
                                                  "& .MuiAvatar-root": {
                                                    width: "120px",
                                                    height: "132px",
                                                    ml: 0.5,
                                                    mr: 1,
                                                    // mt:5
                                                  },
                                                  "&:before": {
                                                    content: '""',
                                                    display: "block",
                                                    position: "absolute",
                                                    top: 0,
                                                    // horizontal: 'center',
                                                    right: 62,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: "background.paper",
                                                    transform: "translateY(-50%) rotate(45deg)",
                                                    zIndex: 0,
                                                  },
                                                },
                                              }}
                                              transformOrigin={{
                                                horizontal: "center",
                                                vertical: "right",
                                              }}
                                              anchorOrigin={{
                                                horizontal: "left",
                                                vertical: "center",
                                              }}
                                            >
                                           {row.gallery.length > 0 &&
                      row.gallery.map((artist, index) => (
                          <>
                           
                              <MenuItem
                                
                                  sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      fontSize: "16px",
                                      lineHeight: "12px",
                                      fontWeight: "400",
                                      fontFamily: "Roboto",
                                  }}
                              >
                                  View Gallery Profile
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                  onClick={() => props.blockUnblockArtist(threedot_id, true)}
                                  sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      fontSize: "16px",
                                      lineHeight: "12px",
                                      fontWeight: "400",
                                      fontFamily: "Roboto",
                                  }}
                              >
                                  Block
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                  onClick={() => props.blockUnblockArtist(threedot_id, false)}
                                  sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      fontSize: "16px",
                                      lineHeight: "12px",
                                      fontWeight: "400",
                                      fontFamily: "Roboto",
                                  }}
                              >
                                  Unblock
                              </MenuItem>
                          </>
                      ))}
                  
                                            </Menu> 
                                           
                                          </>
                    </TableCell>

                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  )
}
