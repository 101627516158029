import React, { useState, useRef, useEffect } from "react";
// import Navbar from "../Navbar/Navbar"
// import Header from "../Header/Header"
import styles from "./Home.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
// import Footer from "../Footer/Footer";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import homeImage from "../../constant";
import { useDispatch } from "react-redux";
import { AddMailSubscription } from "../../State/api/userApi";
import { AlertsSuccess, } from "../alert/Alert";
import { RiCheckFill, RiFileList2Line, RiUserAddLine, RiImageLine, RiUserVoiceLine    } from "react-icons/ri";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import PhoneInput from "react-phone-input-2";
import 'swiper/swiper-bundle.min.css';
import { id } from "date-fns/locale";
const CuratorPrivilege_data = [
  {
    id: 1,
    heading: "Collaborate with a Global Community",
    des: "Connect with artists, collectors, and curators from around the world. Exchange ideas, inspire others, and help shape the narrative of contemporary art. Engage with a diverse network that enhances your ability to discover art for sale and promotes the buying art online experience.",
  },
  {
    id: 2,
    heading: "Access to Diverse Artworks",
    des: "Explore a wide range of artworks, from traditional to contemporary, and curate collections that reflect your unique perspective. Whether you're looking for original art for sale, fine art, or canvas wall art, you'll find an extensive selection of works that speak to your vision and interests.",
  },
  {
    id: 3,
    heading: "Curate Exhibitions to Sell Artworks",
    des: "As a curator, you have the opportunity to curate physical and virtual exhibitions specifically designed to showcase and sell artworks.",
  },
  {
    id: 4,
    heading: "Earn Commissions",
    des: "By curating and promoting exceptional artworks, you will earn commissions(2.5%) on every art original sold, adding value to both your career and the artists you support. These earnings are a testament to the success of the buy original art movement, connecting creators with buyers worldwide.",
  }, 
  {
    id: 5,
    heading: "Support and Guidance",
    des: "Our dedicated team is here to assist you every step of the way. Whether you need help navigating the platform or advice on curating, we’re just a message away.",
  },
  {
    id: 6,
    heading: "Track Your Commissions",
    des: "Use our platform to easily monitor and track your commissions, providing you with clear insights into your earnings and helping you manage your curatorial activities effectively. This streamlined approach supports your goal of promoting fine art and canvas wall art.",
  },
  {
    id: 7,
    heading: "Select Featured Artists",
    des: "As a curator, you play a pivotal role in highlighting talent. You have the authority to select and showcase featured artists, drawing attention to their original art and providing a platform for them to reach wider audiences. Your work directly impacts the visibility of art for sale, helping buyers buy original art from talented creators.",
  },
  {
    id: 8,
    heading: "Specially Curated Collections",
    des: "Manage the curated artworks that reflect your unique artistic vision. All curated collections are featured on a dedicated page, allowing you to showcase your selections in a professional, organized manner, making it easier for collectors to explore and buy art. Whether promoting canvas wall art or fine art, your curated collections help guide buyers to discover pieces that speak to their tastes and interests.",
  },
];
const joinarsmliey_data =[
  {
    id:1,
    src: "/img/curatorsignup",
    heading: "Curate Top Talent",
    des: "<p>Select and recommend artists, help them gain visibility among global collectors, galleries, and exhibitions.</p> ",
  },
  {
    id:2,
    src: "/img/curator-recommended",
    heading: "Exclusive Opportunities",
    des: "<p>Gain access to emerging talent, participate in exclusive exhibitions, and collaborate with leading industry professionals and collectors.</p> ",
   },
  {
    id:3,
    src: "/img/curator-earn",
    heading: "Effortless Curation",
    des: "<p>Navigate a seamless platform designed for curators. Evaluate, recommend, and promote artists with ease.</p> ",
  },
];


const curatbeyound =[
  {
    id:1,
    icon: <RiImageLine   />,
    heading: "Enhanced Curatorial Freedom",
    des: "<p>Curators are given the creative freedom to develop unique, thematic collections that reflect their artistic vision, without constraints or limitations.</p>",
  },
  {
    id:2,
    icon: <RiUserAddLine />,
    heading: "Streamlined Curation Process",
    des: "<p>Art Smiley offers a user-friendly platform that simplifies the artwork selection and approval process, allowing curators to focus on quality and creativity.</p>",
  },
  {
    id:3,
    icon: <RiUserVoiceLine   />,
    heading: "Global Art Marketplace",
    des: "<p>Art Smiley connects curators to a global marketplace, expanding their reach beyond local or regional boundaries and offering access to international collectors.</p>",
  },
  {
    id:4,
    icon: <RiUserVoiceLine   />,
    heading:"Work without Borders",
    des:"<p>Art Smiley curators have the ability to work remotely, which allows for the curation of artworks without locational restrictions and provides opportunities to earn additional income through the sale of curated work.</p>"
  }
];
const remarableevents =[
  {
    id:1,
    icon: <RiImageLine   />,
    heading: "Private Viewings",
    des: "<p>Enjoy exclusive events where you can see artworks up close and interact with artists and curators.</p>",
  },
  {
    id:2,
    icon: <RiUserAddLine />,
    heading: "Curator-led Tours",
    des: "<p>Join guided tours to explore the collection and appreciate the diverse styles and techniques of featured pieces.</p>",
  },
  {
    id:3,
    icon: <RiUserVoiceLine   />,
    heading: "Special Collaborations",
    des: "<p>Participate in unique events that foster connections between artists, collectors, and art enthusiasts.</p>",
  },
];

const featurebeni =[
  {
    id:1,
    heading: "Total Featured Artists",
    des: "Track the number of artists in your collections.",
  },
  {
    id:2,
    heading: "Total Arts",
    des: "Monitor the total amount of art represented.",
  },
  {
    id:3,
    heading: "Curated Collections",
    des: "Count your specially curated artwork collections.",
  },
  {
    id:4,
    heading: "Total Earnings",
    des: "Check your earnings to track financial progress.",
  },
  {
    id:5,
    heading: "Total Number of Artists",
    des: "Know how many artists you’re engaging currently.",
  },
  {
    id:6,
    heading: "Total Weekly Earnings",
    des: "Stay updated on weekly earnings.",
  },
  {
    id:7,
    heading: "Total Monthly Earnings",
    des: "Track your monthly earnings.",
  },
  {
    id:8,
    heading: "Total Yearly Earnings",
    des: "View your cumulative earnings for the year.",
  },
  {
    id:9,
    heading: "Total Curated Exhibitions",
    des: "Review the total number of the curated exhibitions by you.",
  },
];

const ourArtistCard = [
  {
    id: 1,
    imgUrl: "/img/ourartist1.png",
    artistName: "Maria Cooper",
    position: "Interior Designer",
    des:"Our curators can assist you in uncovering art that you cherish."
  },
  {
    id: 2,
    imgUrl: "/img/ourartist2.png",
    artistName: "Titus Archibald",
    position:"Interior Designer, Lifestylist",
    des:"Our curators can assist you in uncovering art that you cherish."
  },
  {
    id: 3,
    imgUrl: "/img/ourartist3.png",
    artistName: "Winslow Homer",
    position: "Designer, Mississippi Native",
    des:"Our curators can assist you in uncovering art that you cherish."
  },
];


const spcialart_data = [
  {
    id: 1,
    heading: "Curated Artist Approval",
    des: "Curators carefully select artists across four categories: Emerging, Experienced, Acclaimed, and Masters, ensuring only high-quality art.",
  },
  {
    id: 2,
    heading: "Quality Control & Compliance",
    des: "As a curator, you must ensure that the originality and creativity of the artwork adhere to Art Smiley's platform standards.",
  },
  {
    id: 3,
    heading: "Exclusive Selection for Exhibitions & Auctions",
    des: "Curators select exceptional artworks for Art Smiley’s renowned exhibitions and auctions, showcasing a diverse, premium collection.",
  },
  {
    id: 4,
    heading: "Artist Invitations for Special Events",
    des: "Curators invite top artists to prestigious exhibitions, offering unmatched opportunities for global exposure.",
  },
  {
    id: 5,
    heading: "Facilitating Artist Success",
    des: "Curators provide mentorship and guidance, helping artists refine their portfolios and enhance market recognition.",
  },
  {
    id: 6,
    heading: "First Preference for Art Smiley Projects",
    des: "Curators ensure top artists receive priority for exclusive Art Smiley collaborations and commissioned works, driving success.",
  },
];
const featre_data = [
  {
    id: 1,
    heading: "Curator Portfolio Overview",
    des: "Quickly review your art collection and earnings with the Curator Dashboard. Track the total number of artworks, curated collections, income, and artists all in one intuitive interface.",
  },
  {
    id: 2,
    heading: "Oversee and Curate Art with Ease",
    des: "Manage all artworks in one place, from pending submissions to approved pieces. Organize your curated collections and maintain high-quality standards.",
  },
  {
    id: 3,
    heading: "Discover, Manage and Curate Exhibitions",
    des: "Search for exhibitions, manage artist requests, and send invitations to artists. Curate exhibitions and expand your influence.",
  },
  {
    id: 4,
    heading: "Track, Sort, and Engage Auctions",
    des: "Effortlessly track live, upcoming, and past auctions to stay ahead in the art market. Use search, sorting, and filtering tools to identify the most valuable pieces for your curated collection.",
  },
  {
    id: 5,
    heading: "Stay on Top of Orders",
    des: "Track and manage all orders in real time. Click on any order ID to view detailed information and shipment updates. In this way, you get the updated information of your curated artworks.",
  },
  {
    id: 6,
    heading: "Monitor Your Financial Growth",
    des: "Monitor your weekly, monthly, and yearly earnings to stay on top of your revenue streams. Analyze trends, identify growth patterns, and make data-driven decisions to enhance profitability.",
  },
];



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function HomeCurator(props) {
  const [expanded, setExpanded] = useState("panel1");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSignupButton = () => {
    // const applied_profile = localStorage.getItem("applied_profile");
    // const user_type = localStorage.getItem("usertype");
    
    // if (applied_profile === "true" && user_type === "aritst") {
    //   Errornotify("You Already Apply for Artist");
    // }
    // if (applied_profile === "true" && user_type === "affiliate") {
    //   Errornotify("You Already Apply for Artist");
    // } else {
    //   navigate("/artsmiley/applynow");
    // }
    navigate("/curator/applynow")
  };
  const handleChangeSubscription = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = () => {
    dispatch(AddMailSubscription({ email: email }, "st", setEmail));
  };

  const [expandedIndex, setExpandedIndex] = useState(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  
  const contentRef = useRef(null);
  const prevButtonRef = useRef(null);
        const nextButtonRef = useRef(null);
  useEffect(() => {
    if (contentRef.current) {
      const isContentOverflowing =
        contentRef.current.scrollHeight > contentRef.current.clientHeight;
      setIsOverflowing(isContentOverflowing);
    }
  }, []);
  
  const toggleReadMore = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Toggle state
  };
const [exclusiveExpanded, setExclusiveExpanded] = React.useState('excpanel0'); // Default to the first panel

const handleExclusiveChange = (panel) => (event, isExpanded) => {
  setExclusiveExpanded(isExpanded ? panel : false);
};


const handleNavigate = (route) => {
  if (route.includes("https")) {
    window.open(route, "_blank");
  } else {
   // console.log(route,'route')
    if(route === "/search") {
      navigate(`/user${route}`, {state: {itemType: "print"}});
      window.scrollTo({ top: 0, behavior: "smooth" });
    }else {
      navigate(`/user${route}`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
   
  }
};

  return (
    <>
      <AlertsSuccess />
      {/* <Header />
            <Navbar /> */}
      <React.Fragment>
        <CssBaseline />
       <div className={styles.hero_main_curator}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.pageitle} ${styles.max_width}`}>
              <h1>Earn Commissions On Sale Of Each Curated Artwork</h1>
              <button
              onClick={handleSignupButton}
              variant="contained"
              
              className="apply_ltbtn"
            >
              Apply Now
            </button>
            </div>
        </Container>
       </div>
          
          <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Join the Art Smiley Curator’s Community</h3>
          <p className={styles.withor}>Become a curator with Art Smiley to select the best artworks and showcase them to art enthusiasts. By connecting artists with collectors, you can play a key role in shaping the future of the art world.</p>
          </div>
          <Grid container spacing={2}>
          {joinarsmliey_data.map((val, index) => (
                <Grid item xs={12} sm={4} md={4} key={val.id} >
                  <div  key={index} className={styles.stboxh}>
                <div className={styles.rechhbox} style={{background: '#F6F6F6'}}>
                  <div className={styles.sticon}>
                  <img
                className={styles.img_fluid}
                src={`${val.src}.svg`}
                alt="img"
              />  </div>
                  <h4>{val.heading}</h4>
                  <div className={styles.stcont}>
                    {/* <p>{val.des}</p> */}
                    <div
      className={`${styles.textContent} ${
        expandedIndex === index ? styles.expanded : ""
      }`}
      ref={contentRef}
      style={{
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: expandedIndex === index ? "unset" : 4, // Show 3 lines or full content
        overflow: "hidden",
      }}
      dangerouslySetInnerHTML={{ __html: val.des }}
    />
    
    {isOverflowing && (
      <button
        className={styles.readMoreBtn}
        onClick={() => toggleReadMore(index)}
      >
        {expandedIndex === index ? "Read Less" : "Read More"}
      </button>
    )}
    </div>
                </div>
                </div>
                </Grid>
          ))}
            
            </Grid>
            </Container>
          </section>
          <section className={styles.sec_scexcl}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}> Curate Beyond Boundaries</h3>
          </div>
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
          <Grid item md={5} sm={6} xs={12}>
          <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
          <Grid container spacing={3}>
          {curatbeyound.map((val, index) => (
                <Grid item xs={12} sm={6} md={6} key={val.id} >
                  <div  key={index} className={styles.stboxbn}>
                <div className={styles.hbox1}>
                  <div className={styles.idnumbr}>{val.icon}</div>
                  <h4>{val.heading}</h4>
                  <div className={styles.stcont}>
                    {/* <p>{val.des}</p> */}
                    <div
      className={`${styles.textContent1}`}
       
      dangerouslySetInnerHTML={{ __html: val.des }}
    />
    
    
    </div>
                </div>
                </div>
                </Grid>
          ))}
            </Grid>
          </Grid>
          
          </Grid>
         
          </div>
          
          </Container>
          </section>
          <section className={styles.sec_sc}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>How to Apply?</h3>
          <p className={styles.withor}>Art Smiley Curator’s Community makes a strong contribution to multifaceted art. By fostering connections with art lovers and patrons, curators can greatly improve artwork sales through the artful narration of compelling stories behind the art pieces. </p>
         </div>
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
          <Grid item md={5} sm={6} xs={12}>
          <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
                  <div>
                    <ul className={styles.timeline}>
                      <li>
                      <div className={styles.stepli}>
                    <h4>Submit Your Application</h4>
                    <p>Begin your journey by providing the necessary details for registration. This is the first step towards becoming a valued member of the Art Smiley Curator's Community.</p>
                    </div>
                      </li>
                      <li>
                      <div className={styles.stepli}>
                      <h4>Review and Approval</h4>
                    <p>Once your information is submitted, our team will review your application within 3-4 weeks. During this period, we’ll assess your profile to ensure it aligns with our curatorial standards and goals. Upon approval, you will receive a confirmation email to create a password for your account and login to your dashboard.</p></div>
                      </li>
                      <li>
                      <div className={styles.stepli}>
                      <h4>Start Curating and Earning</h4>
                    <p>Once your account is activated, you can curate collections and earn commissions by showcasing high-quality contemporary art. Invite artists to join and curate their artworks, expanding your collections. Curate exhibitions, selecting the best artworks to connect artists with buyers. Promote the exhibitions to enhance the experience and foster a deeper appreciation for the art.</p></div>
                      </li>
                    </ul>
                    
                  </div>
            </Grid>
            </Grid>
            <div className={styles.btnmt2}><button onClick={handleSignupButton} class="apply_btn">Sign Up</button></div>
            </div>
          
          </Container>
          </section>
          <section className={styles.sec_scexcl}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Special Perks For Curators at Art Smiley</h3>
          </div>
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{alignItems:"center"}} className={styles.spesc}>
          {spcialart_data.map((val) => (
             <Grid item xs={12} sm={4} md={4} key={val.id}>
              <div className={styles.whyusboox}>
                {/* <h5>{val.id}</h5> */}
              <h4>{val.heading}</h4>
              <div className={styles.whubody}>
              <p>{val.des}</p>
              </div>
              </div> 
             </Grid>
          ))} 
          
          </Grid>
            
            </div>
          
          </Container>
          </section>
          
          <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Curator Privileges</h3>
          </div>
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
          <Grid item md={6} sm={6} xs={12}>
          <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
          </Grid>
          <Grid item md={5} sm={6} xs={12}>
      <div className={`${styles.accordsc}`}>
      {CuratorPrivilege_data.map((val, index) => {
      const panelId = `excpanel${index}`; // Dynamic panel ID
      return (
        <Accordion
          key={panelId}
          expanded={exclusiveExpanded === panelId} // Check if the panel is expanded
          onChange={handleExclusiveChange(panelId)}
          sx={{
            "& .MuiAccordionSummary-contentGutters": {
              margin: "10px 0", // Adjust as needed
            },
            "&.MuiPaper-root": {
              boxShadow: "none",
              borderBottom: "1px solid #D9DBE9",
              marginBottom:"0",
              borderBottomLeftRadius:"0",
              borderBottomRightRadius:"0",
            },
            "&.MuiAccordion-root::before":{
              display:"none",
            },
            "& .Mui-expanded": {
              minHeight:"auto !important",
              margin:"0 0 10px 0 !important"
            },
            "& .MuiAccordionSummary-expandIconWrapper":{
              background:"#B7B7B7",
              opacity:".3",
              textAlign: "center",
              borderRadius:"8px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
              background:"#1C1C1C",
              opacity:"1",
              color:"#fff",
            },
            "& .MuiAccordionDetails-root":{
              paddingTop:"0"
            },
            
          }}// Handle expansion state
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${panelId}-content`}
            id={`${panelId}-header`}
          >
            <h4 className={styles.benfhdgn}>{val.heading}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={styles.bendesc}>{val.des}</p>
          </AccordionDetails>
        </Accordion>
      );
    })}
      </div>
      
          </Grid>
          
          
          </Grid>
          </div>
          </Container>
          </section>
          <section className={styles.sec_scexcl}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Why Choose Art Smiley to Sell My Art?</h3>
          </div>
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
          <Grid item md={5} sm={6} xs={12}>
          <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
                  <div>
                    <ul className={styles.timeline}>
                      <li>
                      <div className={styles.stepli}>
                        <h4>Unparalleled Artistic Diversity</h4>
                        <p>Art Smiley offers curators access to a wide range of unique, high-quality artworks from emerging, experienced, and acclaimed artists, allowing for a diverse curation process.</p>
                    </div>
                      </li>
                      <li>
                      <div className={styles.stepli}>
                      <h4>Global Platform with Exclusive Reach</h4>
                      <p>Art Smiley provides curators with an international platform to showcase artwork to a global audience of collectors, designers, and buyers, ensuring broad exposure for curated pieces.</p>
                      </div>
                       </li> 
                      <li>
                      <div className={styles.stepli}>
                      <h4>Professional Development and Mentorship</h4>
                      <p>Curators benefit from Art Smiley’s focus on artist support, offering mentorship opportunities, feedback, and resources to help guide artists toward greater recognition and success.</p>
                      </div>
                       </li> 
                      <li>
                      <div className={styles.stepli}>
                      <h4>Exclusive Art Events and Auctions</h4>
                      <p>Curators have the opportunity to handpick artworks for prestigious exhibitions, auctions, and special events, enhancing their visibility and ensuring high-quality exposure for selected pieces.</p>
                      </div>
                       </li> 
                      <li>
                      <div className={styles.stepli}>
                      <h4>Gain Commissions</h4>
                      <p>Earn a substantial commission by connecting renowned artists with international art buyers. Expand your global reach and showcase exceptional artworks to collectors.</p>
                      </div>
                       </li> 
                    </ul>
                    
                  </div>
            </Grid>
            </Grid>
            </div>
          
          </Container>
          </section>
          <section className={styles.hero_main_curator_benefits}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title} ${styles.text_white}`}>Members-Only Insights</h3>
          </div>
          <div>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>01</p>
                </div>
                <h4>Exclusive Artworks</h4>
                <div className={styles.befncont}>
                  <p>Discover a curated selection of exceptional contemporary art pieces that represent the cutting edge of contemporary art.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>02</p>
                </div>
                <h4>Curator Insights</h4>
                <div className={styles.befncont}>
                  <p>Gain deeper understanding with detailed commentary and stories from our curators about each artwork.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>03</p>
                </div>
                <h4>Early Previews</h4>
                <div className={styles.befncont}>
                  <p>Get first access to new arrivals and special releases, keeping you ahead of the latest art trends.</p>
                </div>
              </div>
            </div>
          </Grid>
          </Grid>
          </div>
            </Container>
          </section>
          <section className={styles.sec_scexcl}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Dashboard Features</h3>
          </div>
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
          <Grid item md={6} sm={6} xs={12}>
          <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
          </Grid>
          <Grid item md={5} sm={6} xs={12}>
      <div className={`${styles.accordsc}`}>
      {featre_data.map((val, index) => {
      const panelId = `excpanel${index}`; // Dynamic panel ID
      return (
        <Accordion
          key={panelId}
          expanded={exclusiveExpanded === panelId} // Check if the panel is expanded
          onChange={handleExclusiveChange(panelId)}
          sx={{
            "& .MuiAccordionSummary-contentGutters": {
              margin: "10px 0", // Adjust as needed
            },
            "&.MuiPaper-root": {
              boxShadow: "none",
              borderBottom: "1px solid #D9DBE9",
              marginBottom:"0",
              borderBottomLeftRadius:"0",
              borderBottomRightRadius:"0",
            },
            "&.MuiAccordion-root::before":{
              display:"none",
            },
            "& .Mui-expanded": {
              minHeight:"auto !important",
              margin:"0 0 10px 0 !important"
            },
            "& .MuiAccordionSummary-expandIconWrapper":{
              background:"#B7B7B7",
              opacity:".3",
              textAlign: "center",
              borderRadius:"8px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
              background:"#1C1C1C",
              opacity:"1",
              color:"#fff",
            },
            "& .MuiAccordionDetails-root":{
              paddingTop:"0"
            },
            
          }}// Handle expansion state
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${panelId}-content`}
            id={`${panelId}-header`}
          >
            <h4 className={styles.benfhdgn}>{val.heading}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <p className={styles.bendesc}>{val.des}</p>
          </AccordionDetails>
        </Accordion>
      );
    })}
      </div>
      
          </Grid>
          
          
          </Grid>
          </div>
          </Container>
          </section>
          
          
          {/* <section className={styles.sec_scexcl}>
          <div className={`${styles.text_center}`}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
            <h3 className={styles.sec_title}>Art Smiley Curators</h3>
            <p className={styles.withor}>Art Smiley’s curators carefully select a broad range of contemporary arts, spotlighting both emerging and renowned artists. Our curators expertise brings fresh, vibrant perspectives to the global art community. Check Art Smiley’s existing curator and their expertise in reviewing the classic art pieces in the link below.
              
            </p>
            <div>
          <Swiper
        modules={[Navigation, Autoplay]}
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        navigation={{
          prevEl: prevButtonRef.current,
          nextEl: nextButtonRef.current,
        }}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
      >
        {ourArtistCard.map((item) => (
        <SwiperSlide>
          <div className={styles.ourartistcard}>
          <img className={styles.ourartistimg} src={  item.imgUrl} alt=""/>
          <div className={styles.ourartistinfo}>
            <h4>{item.artistName}</h4>
            <p className={styles.tdr}>{item.position}</p>
            <p>{item.des}</p>
          </div>
        </div>
        </SwiperSlide>
        ))}
      </Swiper>
       
          </div>
            </Container>
          </div>
          </section> */}
          {/* <section className={styles.sec_sc}>
          <div className={`${styles.text_center}`}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
            <h3 className={styles.sec_title}>Upcoming Events</h3>
            <p className={styles.withor}>The Curator Community events offer exclusive access to curators to curate exhibitions and create networking opportunities with prominent artists and collectors. Engage with the art community, discover new talent, and deepen your appreciation for exceptional artworks in a collaborative environment.
              [Event List]
            </p>
            </Container>
          </div>
          </section> */}
          
          
          <section className={styles.sec_sc}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
             <div className={`${styles.text_center} ${styles.max_width}`}>
                      <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
                      <h3 className={styles.sec_title}>FAQ</h3>
                        </div>
            
            <div className={styles.faq_box}>
            <div className="acchdr">
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
      sx={{
        "& .MuiAccordionSummary-contentGutters": {
          margin: "10px 0", // Adjust as needed
        },
        "&.MuiPaper-root": {
          boxShadow: "none",
          borderBottom: "1px solid #D9DBE9",
          marginBottom:"0",
          borderBottomLeftRadius:"0",
          borderBottomRightRadius:"0",
        },
        "&.MuiAccordion-root::before":{
          display:"none",
        },
        "& .Mui-expanded": {
          minHeight:"auto !important",
          margin:"0 0 10px 0 !important"
        },
        "& .MuiAccordionSummary-expandIconWrapper":{
          background:"#B7B7B7",
          opacity:".3",
          textAlign: "center",
          borderRadius:"8px",
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
          background:"#1C1C1C",
          opacity:"1",
          color:"#fff",
        },
        "& .MuiAccordionDetails-root":{
          paddingTop:"0"
        },
        
      }}// Handle expansion state
      >
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >{expanded === "panel1" ? (
          <div className={styles.accodian_no}>1</div>
        ) : (
          ""
        )}
            <h3 className={`${styles.faqhdr} ${
      expanded === "panel1" ? styles.faqhdrExpanded : ""
    }`}>What is the Art Smiley Curator’s Community?</h3>
        </AccordionSummary>
        <AccordionDetails>
        <p className={styles.acpra}>Art Smiley Curator’s Community is a platform where curators connect with artists, collectors, and patrons to drive art sales through storytelling, exhibitions, and collaborations. It emphasizes showcasing diverse artistic expressions while fostering deeper connections between artworks and audiences.</p>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
      sx={{
        "& .MuiAccordionSummary-contentGutters": {
          margin: "10px 0", // Adjust as needed
        },
        "&.MuiPaper-root": {
          boxShadow: "none",
          borderBottom: "1px solid #D9DBE9",
          marginBottom:"0",
          borderBottomLeftRadius:"0",
          borderBottomRightRadius:"0",
        },
        "&.MuiAccordion-root::before":{
          display:"none",
        },
        "& .Mui-expanded": {
          minHeight:"auto !important",
          margin:"0 0 10px 0 !important"
        },
        "& .MuiAccordionSummary-expandIconWrapper":{
          background:"#B7B7B7",
          opacity:".3",
          textAlign: "center",
          borderRadius:"8px",
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
          background:"#1C1C1C",
          opacity:"1",
          color:"#fff",
        },
        "& .MuiAccordionDetails-root":{
          paddingTop:"0"
        },
        
      }}// Handle expansion state
      >
        <AccordionSummary className="acchdr"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >{expanded === "panel2" ? (
          <div className={styles.accodian_no}>2</div>
        ) : (
          ""
        )}
           <h3 className={`${styles.faqhdr} ${
      expanded === "panel2" ? styles.faqhdrExpanded : ""
    }`}>
           How can I join the Art Smiley Curator’s Community?
          </h3>
        </AccordionSummary>
        <AccordionDetails>
        <h6>To join:</h6>
        <ul className={styles.faq}>
          <li><strong>Submit Your Application:</strong> Fill out the registration form online. <a href="/curator/applynow">Online curator registration</a></li>
          <li><strong>Review and Approval:</strong> Your application will be reviewed within 3-4 weeks. Upon approval, you’ll receive a confirmation email to set up your account.</li>
          <li><strong>Start Curating and Earning:</strong> Once activated, you can curate collections, promote exhibitions, and earn commissions.</li>
        </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
      sx={{
        "& .MuiAccordionSummary-contentGutters": {
          margin: "10px 0", // Adjust as needed
        },
        "&.MuiPaper-root": {
          boxShadow: "none",
          borderBottom: "1px solid #D9DBE9",
          marginBottom:"0",
          borderBottomLeftRadius:"0",
          borderBottomRightRadius:"0",
        },
        "&.MuiAccordion-root::before":{
          display:"none",
        },
        "& .Mui-expanded": {
          minHeight:"auto !important",
          margin:"0 0 10px 0 !important"
        },
        "& .MuiAccordionSummary-expandIconWrapper":{
          background:"#B7B7B7",
          opacity:".3",
          textAlign: "center",
          borderRadius:"8px",
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
          background:"#1C1C1C",
          opacity:"1",
          color:"#fff",
        },
        "& .MuiAccordionDetails-root":{
          paddingTop:"0"
        },
        
      }}// Handle expansion state
      >
        <AccordionSummary className="acchdr"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          {expanded === "panel3" ? (
                    <div className={styles.accodian_no}>3</div>
                  ) : (
                    ""
                  )}
          <h3 className={`${styles.faqhdr} ${
      expanded === "panel3" ? styles.faqhdrExpanded : ""
    }`}>
          What is the curator approval process?
          </h3>
        </AccordionSummary>
        <AccordionDetails>
        <p className={styles.acpra}>After submitting your application, Art Smiley’s team will review your profile to ensure it aligns with the platform’s curatorial standards. You’ll receive an email notification upon approval.</p>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}
      sx={{
        "& .MuiAccordionSummary-contentGutters": {
          margin: "10px 0", // Adjust as needed
        },
        "&.MuiPaper-root": {
          boxShadow: "none",
          borderBottom: "1px solid #D9DBE9",
          marginBottom:"0",
          borderBottomLeftRadius:"0",
          borderBottomRightRadius:"0",
        },
        "&.MuiAccordion-root::before":{
          display:"none",
        },
        "& .Mui-expanded": {
          minHeight:"auto !important",
          margin:"0 0 10px 0 !important"
        },
        "& .MuiAccordionSummary-expandIconWrapper":{
          background:"#B7B7B7",
          opacity:".3",
          textAlign: "center",
          borderRadius:"8px",
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
          background:"#1C1C1C",
          opacity:"1",
          color:"#fff",
        },
        "& .MuiAccordionDetails-root":{
          paddingTop:"0"
        },
        
      }}// Handle expansion state
      >
        <AccordionSummary className="acchdr"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          {expanded === "panel4" ? (
                    <div className={styles.accodian_no}>4</div>
                  ) : (
                    ""
                  )}
          <h3 className={`${styles.faqhdr} ${
      expanded === "panel4" ? styles.faqhdrExpanded : ""
    }`}>
          What is the commission structure for curators in art sales?
          </h3>
        </AccordionSummary>
        <AccordionDetails>
        <p className={styles.acpra}>You will earn a 3% commission for every curated artwork sold. This is to reward your efforts in ensuring authenticity, quality, and a strong collection. It's a great way to support both artists and collectors while being rewarded for your expertise.</p>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}
      sx={{
        "& .MuiAccordionSummary-contentGutters": {
          margin: "10px 0", // Adjust as needed
        },
        "&.MuiPaper-root": {
          boxShadow: "none",
          borderBottom: "1px solid #D9DBE9",
          marginBottom:"0",
          borderBottomLeftRadius:"0",
          borderBottomRightRadius:"0",
        },
        "&.MuiAccordion-root::before":{
          display:"none",
        },
        "& .Mui-expanded": {
          minHeight:"auto !important",
          margin:"0 0 10px 0 !important"
        },
        "& .MuiAccordionSummary-expandIconWrapper":{
          background:"#B7B7B7",
          opacity:".3",
          textAlign: "center",
          borderRadius:"8px",
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
          background:"#1C1C1C",
          opacity:"1",
          color:"#fff",
        },
        "& .MuiAccordionDetails-root":{
          paddingTop:"0"
        },
        
      }}// Handle expansion state
      >
        <AccordionSummary className="acchdr"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          {expanded === "panel5" ? (
                    <div className={styles.accodian_no}>5</div>
                  ) : (
                    ""
                  )}
          <h3 className={`${styles.faqhdr} ${
      expanded === "panel5" ? styles.faqhdrExpanded : ""
    }`}>
          What are the essential skills required to become a curator?
          </h3>
        </AccordionSummary>
        <AccordionDetails>
          <ul className={styles.faq}>
            <li>To become a curator, you'll need a combination of artistic knowledge, organizational abilities, and strong communication skills.</li>
            <li>A deep understanding of art history and current trends is essential to curating meaningful exhibitions.</li>
            <li>Curators should have excellent project management skills to plan and organize events, as well as the ability to evaluate and select artworks based on their quality and relevance. </li>
            <li>Strong networking abilities are also important, as curators often work closely with artists, collectors, and galleries. </li>
            <li>Curators must have the wise knowledge in classifying the artists, techniques used, and their skill set which is essential to select and approve the artists registered in the platform.</li>
            <li>Lastly, attention to detail, creativity, and a passion for art are key traits that will help you succeed in this role.</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}
      sx={{
        "& .MuiAccordionSummary-contentGutters": {
          margin: "10px 0", // Adjust as needed
        },
        "&.MuiPaper-root": {
          boxShadow: "none",
          borderBottom: "1px solid #D9DBE9",
          marginBottom:"0",
          borderBottomLeftRadius:"0",
          borderBottomRightRadius:"0",
        },
        "&.MuiAccordion-root::before":{
          display:"none",
        },
        "& .Mui-expanded": {
          minHeight:"auto !important",
          margin:"0 0 10px 0 !important"
        },
        "& .MuiAccordionSummary-expandIconWrapper":{
          background:"#B7B7B7",
          opacity:".3",
          textAlign: "center",
          borderRadius:"8px",
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
          background:"#1C1C1C",
          opacity:"1",
          color:"#fff",
        },
        "& .MuiAccordionDetails-root":{
          paddingTop:"0"
        },
        
      }}// Handle expansion state
      >
        <AccordionSummary className="acchdr"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          {expanded === "panel6" ? (
                    <div className={styles.accodian_no}>6</div>
                  ) : (
                    ""
                  )}
          <h3 className={`${styles.faqhdr} ${
      expanded === "panel6" ? styles.faqhdrExpanded : ""
    }`}>
           What privileges do curators have?
          </h3>
        </AccordionSummary>
        <AccordionDetails>
            <h6>Curators can:</h6>
            <ul className={styles.faq}>
              <li>Collaborate globally with artists and collectors.</li>
              <li>Review and approve the artist and gallery applications.</li>
              <li>Select recommended artists, galleries and artworks</li>
              <li>Access a wide range of artworks to curate collections.</li>
              <li>Organize and promote exhibitions/auctions to sell artworks.</li>
              <li>Earn commissions on sale of each curated artwork.</li>
              <li>Receive support and guidance from the Art Smiley team.</li>
            </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}
      sx={{
        "& .MuiAccordionSummary-contentGutters": {
          margin: "10px 0", // Adjust as needed
        },
        "&.MuiPaper-root": {
          boxShadow: "none",
          borderBottom: "1px solid #D9DBE9",
          marginBottom:"0",
          borderBottomLeftRadius:"0",
          borderBottomRightRadius:"0",
        },
        "&.MuiAccordion-root::before":{
          display:"none",
        },
        "& .Mui-expanded": {
          minHeight:"auto !important",
          margin:"0 0 10px 0 !important"
        },
        "& .MuiAccordionSummary-expandIconWrapper":{
          background:"#B7B7B7",
          opacity:".3",
          textAlign: "center",
          borderRadius:"8px",
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
          background:"#1C1C1C",
          opacity:"1",
          color:"#fff",
        },
        "& .MuiAccordionDetails-root":{
          paddingTop:"0"
        },
        
      }}// Handle expansion state
      >
        <AccordionSummary className="acchdr"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          {expanded === "panel7" ? (
                    <div className={styles.accodian_no}>7</div>
                  ) : (
                    ""
                  )}
          <h3 className={`${styles.faqhdr} ${
      expanded === "panel7" ? styles.faqhdrExpanded : ""
    }`}>
           Can curators track their commissions?
          </h3>
        </AccordionSummary>
        <AccordionDetails>
        <p className={styles.acpra}>Yes, the platform includes a dashboard that tracks total earnings (weekly, monthly, and yearly).</p>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}
      sx={{
        "& .MuiAccordionSummary-contentGutters": {
          margin: "10px 0", // Adjust as needed
        },
        "&.MuiPaper-root": {
          boxShadow: "none",
          borderBottom: "1px solid #D9DBE9",
          marginBottom:"0",
          borderBottomLeftRadius:"0",
          borderBottomRightRadius:"0",
        },
        "&.MuiAccordion-root::before":{
          display:"none",
        },
        "& .Mui-expanded": {
          minHeight:"auto !important",
          margin:"0 0 10px 0 !important"
        },
        "& .MuiAccordionSummary-expandIconWrapper":{
          background:"#B7B7B7",
          opacity:".3",
          textAlign: "center",
          borderRadius:"8px",
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
          background:"#1C1C1C",
          opacity:"1",
          color:"#fff",
        },
        "& .MuiAccordionDetails-root":{
          paddingTop:"0"
        },
        
      }}// Handle expansion state
      >
        <AccordionSummary className="acchdr"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          {expanded === "panel8" ? (
                    <div className={styles.accodian_no}>8</div>
                  ) : (
                    ""
                  )}
          <h3 className={`${styles.faqhdr} ${
      expanded === "panel8" ? styles.faqhdrExpanded : ""
    }`}>
           What types of exhibitions or auctions can curators organize?
          </h3>
        </AccordionSummary>
        <AccordionDetails>
        <p className={styles.acpra}>Curators can organize both physical and virtual exhibitions or auctions to showcase and sell curated artworks. Curators must select the artworks aligning with the theme of the exhibition or auction.
        </p></AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}
      sx={{
        "& .MuiAccordionSummary-contentGutters": {
          margin: "10px 0", // Adjust as needed
        },
        "&.MuiPaper-root": {
          boxShadow: "none",
          borderBottom: "1px solid #D9DBE9",
          marginBottom:"0",
          borderBottomLeftRadius:"0",
          borderBottomRightRadius:"0",
        },
        "&.MuiAccordion-root::before":{
          display:"none",
        },
        "& .Mui-expanded": {
          minHeight:"auto !important",
          margin:"0 0 10px 0 !important"
        },
        "& .MuiAccordionSummary-expandIconWrapper":{
          background:"#B7B7B7",
          opacity:".3",
          textAlign: "center",
          borderRadius:"8px",
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
          background:"#1C1C1C",
          opacity:"1",
          color:"#fff",
        },
        "& .MuiAccordionDetails-root":{
          paddingTop:"0"
        },
        
      }}// Handle expansion state
      >
        <AccordionSummary className="acchdr"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
        >
          {expanded === "panel9" ? (
                    <div className={styles.accodian_no}>9</div>
                  ) : (
                    ""
                  )}
          <h3 className={`${styles.faqhdr} ${
      expanded === "panel9" ? styles.faqhdrExpanded : ""
    }`}>
           What is the process for curators to organize exhibitions or auctions and select artworks?
          </h3>
        </AccordionSummary>
        <AccordionDetails>
           <p>Curators play a key role in organizing exhibitions and auctions by selecting and curating artworks that match a specific theme. The process ensures a smooth experience for both curators and artists, from initial setup to final selection.</p>
        <h6>Step 1: Creating an Exhibition or Auction</h6>
        <p>Curators need to log in to their dashboard, to define the exhibition or auction theme, event details, and eligibility criteria. Once submitted, the event goes through an approval process to ensure it aligns with the platform’s standards.</p>
        <h6>Step 2: Selecting Artworks for the Exhibition or Auction</h6>
        <p>Following approval for an exhibition or auction, artists submit previously published artwork from the website. Curators review these submissions and select pieces for the exhibition or auction, ensuring they align with the theme. The selection process includes verifying the artwork's title, description, medium, quality, authenticity, relevance, and overall composition.</p>
        <h6>Step 3: Finalizing the Exhibition or Auction</h6>
        <p>Once selections are made, the exhibition or auction is finalized, and artists are notified. The curated collection is displayed for collectors to explore, bid, or purchase, offering artists exposure and potential sales.</p>
        <p>This process allows curators to maintain high curation standards, ensuring each event showcases premium artwork while giving artists a prominent platform.</p>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}
      sx={{
        "& .MuiAccordionSummary-contentGutters": {
          margin: "10px 0", // Adjust as needed
        },
        "&.MuiPaper-root": {
          boxShadow: "none",
          borderBottom: "1px solid #D9DBE9",
          marginBottom:"0",
          borderBottomLeftRadius:"0",
          borderBottomRightRadius:"0",
        },
        "&.MuiAccordion-root::before":{
          display:"none",
        },
        "& .Mui-expanded": {
          minHeight:"auto !important",
          margin:"0 0 10px 0 !important"
        },
        "& .MuiAccordionSummary-expandIconWrapper":{
          background:"#B7B7B7",
          opacity:".3",
          textAlign: "center",
          borderRadius:"8px",
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
          background:"#1C1C1C",
          opacity:"1",
          color:"#fff",
        },
        "& .MuiAccordionDetails-root":{
          paddingTop:"0"
        },
        
      }}// Handle expansion state
      >
        <AccordionSummary className="acchdr"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
        >
          {expanded === "panel10" ? (
                    <div className={styles.accodian_no}>10</div>
                  ) : (
                    ""
                  )}
          <h3 className={`${styles.faqhdr} ${
      expanded === "panel10" ? styles.faqhdrExpanded : ""
    }`}>
           Can curators invite artists to the platform?
          </h3>
        </AccordionSummary>
        <AccordionDetails>
           <p className={styles.acpra}>Yes, curators can invite artists to join Art Smiley through dashboard, expanding the variety and richness of the curated collections.</p>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}
      sx={{
        "& .MuiAccordionSummary-contentGutters": {
          margin: "10px 0", // Adjust as needed
        },
        "&.MuiPaper-root": {
          boxShadow: "none",
          borderBottom: "1px solid #D9DBE9",
          marginBottom:"0",
          borderBottomLeftRadius:"0",
          borderBottomRightRadius:"0",
        },
        "&.MuiAccordion-root::before":{
          display:"none",
        },
        "& .Mui-expanded": {
          minHeight:"auto !important",
          margin:"0 0 10px 0 !important"
        },
        "& .MuiAccordionSummary-expandIconWrapper":{
          background:"#B7B7B7",
          opacity:".3",
          textAlign: "center",
          borderRadius:"8px",
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
          background:"#1C1C1C",
          opacity:"1",
          color:"#fff",
        },
        "& .MuiAccordionDetails-root":{
          paddingTop:"0"
        },
        
      }}// Handle expansion state
      >
        <AccordionSummary className="acchdr"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel11bh-content"
          id="panel11bh-header"
        >
          {expanded === "panel11" ? (
                    <div className={styles.accodian_no}>11</div>
                  ) : (
                    ""
                  )}
          <h3 className={`${styles.faqhdr} ${
      expanded === "panel11" ? styles.faqhdrExpanded : ""
    }`}>
           What are specially curated collections?
          </h3>
        </AccordionSummary>
        <AccordionDetails>
           <p className={styles.acpra}>These are personalized art selections curated by curators to reflect their artistic vision. The collections are displayed on dedicated pages to enhance visibility for buyers.</p>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}
      sx={{
        "& .MuiAccordionSummary-contentGutters": {
          margin: "10px 0", // Adjust as needed
        },
        "&.MuiPaper-root": {
          boxShadow: "none",
          borderBottom: "1px solid #D9DBE9",
          marginBottom:"0",
          borderBottomLeftRadius:"0",
          borderBottomRightRadius:"0",
        },
        "&.MuiAccordion-root::before":{
          display:"none",
        },
        "& .Mui-expanded": {
          minHeight:"auto !important",
          margin:"0 0 10px 0 !important"
        },
        "& .MuiAccordionSummary-expandIconWrapper":{
          background:"#B7B7B7",
          opacity:".3",
          textAlign: "center",
          borderRadius:"8px",
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
          background:"#1C1C1C",
          opacity:"1",
          color:"#fff",
        },
        "& .MuiAccordionDetails-root":{
          paddingTop:"0"
        },
        
      }}// Handle expansion state
      >
        <AccordionSummary className="acchdr"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12bh-content"
          id="panel12bh-header"
        >
          {expanded === "panel12" ? (
                    <div className={styles.accodian_no}>12</div>
                  ) : (
                    ""
                  )}
          <h3 className={`${styles.faqhdr} ${
      expanded === "panel12" ? styles.faqhdrExpanded : ""
    }`}>
           What types of events can curators participate in?
          </h3>
        </AccordionSummary>
        <AccordionDetails>
           <p className={styles.acpra}>Events include physical and virtual exhibitions, auctions, and collaborative events connecting artists and art enthusiasts.</p>
        </AccordionDetails>
      </Accordion>
    </div>
            </div>
            <div className={styles.btnx_div}>
              <button
                variant="contained"
                onClick={() => handleNavigate('/faq?category=curator')}
                className="apply_btn"
              >More FAQ's
              </button>
            </div>
            </Container>
          </section>

      
      </React.Fragment>
      {/* <Footer /> */}
    </>
  );
}

export default HomeCurator;
