import { useEffect, useRef } from "react";

const TrustpilotWidget = () => {
  const widgetRef = useRef(null); // Reference to the widget container

  useEffect(() => {
    // Check if widgetRef exists and then load Trustpilot script
    if (widgetRef.current) {
      const script = document.createElement("script");
      script.src = "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
      script.async = true;
      document.body.appendChild(script);
      
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <div ref={widgetRef} className="trustpilot-widget"
         data-locale="en-US"
         data-template-id="56278e9abfbbba0bdcd568bc"
         data-businessunit-id="596c890d0000ff0005a6d4c2"
         data-style-height="52px"
         data-style-width="100%">
    </div>
  );
};

export default TrustpilotWidget;
