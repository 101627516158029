import React, { useState, useEffect, useRef, Suspense } from "react"
import styles from "./Homepage.module.css"
import { Helmet } from "react-helmet"
import TawkMessengerReact from "@tawk.to/tawk-messenger-react"
import { Container } from '@mui/material';
import {
  Box,
  Button,
  createMuiTheme,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Rating,
  TextField,
  Typography,
} from "@mui/material"
import TrustBox from "./TrustPilot"
import { ThemeProvider } from "@emotion/react"
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm"
import Slider from "./components/Slider"
// import "fontsource-marcellus";
import { useNavigate } from "react-router-dom"
import {
  fetcCompaingnsData,
  fetchHeroSectionData,
} from "../../../State/api/superadminApi"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import homeImage from "../../../constant"
// import Slider from "react-slick";

import "swiper/css/navigation"
import "swiper/css/scrollbar"
// Import Swiper styles
import "swiper/css"
import Homemodel from "./components/Homemodel"
import StarIcon from "@mui/icons-material/Star"
import { AlertsSuccess, Errornotify } from "../../../common/alert/Alert"
import api from "../../../State/api/Api"
import { setLoader } from "../../../State/Features/common/loaderSlice"
import { useDispatch, useSelector } from "react-redux"
import {
  AddMailSubscription,
  fetchAllItemData,
  fetchArtist,
  fetchCategoryData,
  fetchFetchOffers,
  fetchUserArtist,
  handelFetchRecentlyView,
  handleFetchBuyerDetail,
} from "../../../State/api/userApi"
import CircularLoader from "../../../common/loader/Loader"
import serverimage from "../../../serverConstant"

import axios from "axios"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import {
  initializeCurrencyFormatter,
  formatNewCurrency,
} from "../../../utils/currencyFormat"
import capitalizeWords from "../../../utils/capitalizeContent"
import TranslateWrapper from "../../../languageTranslator/TranslateWrapper";
import { useTranslation } from "react-i18next";
import TrustpilotWidget from "./TrustpilotWidget";


const SliderCarousel = React.lazy(() => import('./components/SliderCarousel'));
const partners = [
  {
    id: 1,
    src: "/img/elements_interior.jpg",
  },
  {
    id: 2,
    src: "/img/alps_star_electromechanical.jpg",
  },
  {
    id: 3,
    src: "/img/ck_architecture_Interiors.jpg",
  },
  {
    id: 4,
    src: "/img/couture_luxury_interior.jpg",
  },
  {
    id: 5,
    src: "/img/elenora_interior_design_works.jpg",
  },
  {
    id: 6,
    src: "/img/emkay-group.jpg",
  },
  {
    id: 7,
    src: "/img/evalai_design.jpg",
  },
  {
    id:8,
    src:"/img/exceptional.jpg",
  },
  {
    id:9,
    src:"/img/fdc_interiors.jpg",
  },
  {
    id:10,
    src:"/img/hemlock-technical.jpg",
  },
  {
    id:11,
    src:"/img/ida_interior_design.jpg",
  },
  {
    id:12,
    src:"/img/ideal-fitout-decoration.jpg",
  },
  {
    id:13,
    src:"/img/luxland-landscaping.jpg",
  },
  {
    id:14,
    src:"/img/lxa_dubai.jpg",
  },
  {
    id:15,
    src:"/img/nova_luxury.jpg",
  },
  {
    id:16,
    src:"/img/ready_room.jpg",
  },
  {
    id:17,
    src:"/img/slate_interiors.jpg",
  },
  {
    id:18,
    src:"/img/smh-interior.jpg",
  },
  {
    id:19,
    src:"/img/spacewell_interior.jpg",
  },
  {
    id:20,
    src:"/img/spazio.jpg",
  },
  {
    id:21,
    src:"/img/yana-svetlova.jpg",
  },
  {
    id:22,
    src:"/img/ainasr_contracting.jpg",
  },
  {
    id:23,
    src:"/img/ali_sons_sontracting.jpg",
  },
  {
    id:24,
    src:"/img/beama_contractin.jpg",
  },
  {
    id:25,
    src:"/img/gif_building_maintenance.jpg",
  },
  {
    id:26,
    src:"/img/grankraft_fito_out_construction.jpg",
  },
  {
    id:27,
    src:"/img/maison-dien.jpg",
  },
  {
    id:28,
    src:"/img/sobha_constructions.jpg",
  },
  {
    id:29,
    src:"/img/tornado_total_landscape.jpg",
  },
  {
    id:30,
    src:"/img/ckp_middle.jpg",
  },
  {
    id:31,
    src:"/img/double_tree.jpg",
  },
  {
    id:32,
    src:"/img/dubai_marine_beach_resort.jpg",
  },
  {
    id:33,
    src:"/img/dubai_opera.jpg",
  },
  {
    id:34,
    src:"/img/grand-hyatt.jpg",
  },
  {
    id:35,
    src:"/img/josette-restaurant.jpg",
  },
  {
    id:36,
    src:"/img/khalidia_palace_hotel.jpg",
  },
  {
    id:37,
    src:"/img/marriott-resort-palm-jumeriah.jpg",
  },
  {
    id:38,
    src:"/img/miral_destinations.jpg",
  },
  {
    id:39,
    src:"/img/rixos_bab.jpg",
  },
  {
    id:40,
    src:"/img/rsi_restaurants.jpg",
  },
  {
    id:41,
    src:"/img/soluna_beach.jpg",
  },
  {
    id:42,
    src:"/img/ai_bawardy.jpg",
  },
  {
    id:43,
    src:"/img/conatural_beauty.jpg",
  },
  {
    id:44,
    src:"/img/east_coast_furnitur.jpg",
  },
  {
    id:45,
    src:"/img/hameem-technologies.jpg",
  },
  {
    id:46,
    src:"/img/la-soiree-perfum.jpg",
  },
  {
    id:47,
    src:"/img/moet.jpg",
  },
  {
    id:48,
    src:"/img/urban_space.jpg",
  },
  {
    id:49,
    src:"/img/nmc_hospital.jpg",
  },
  {
    id:50,
    src:"/img/azizi_development.jpg",
  },
  {
    id:51,
    src:"/img/chesterton_international.jpg",
  },
  {
    id:52,
    src:"/img/alpago_properties.jpg",
  },
  {
    id:53,
    src:"/img/island_falcon_property_management.jpg",
  },
  {
    id:54,
    src:"/img/dubai_municipality.jpg",
  },
  {
    id:55,
    src:"/img/rafael_defense_systems.jpg",
  },
  {
    id:56,
    src:"/img/emirates_schools.jpg",
  },
  {
    id:57,
    src:"/img/healthmark_training.jpg",
  },
  {
    id:58,
    src:"/img/yeoworkshop.jpg",
  },
  {
    id:59,
    src:"/img/aircarbon.jpg",
  },
  {
    id:60,
    src:"/img/ai-tayer.jpg",
  },
  {
    id:61,
    src:"/img/apu_investment.jpg",
  },
  {
    id:62,
    src:"/img/assent_steel.jpg",
  },
  {
    id:63,
    src:"/img/carzilla.jpg",
  },
  {
    id:64,
    src:"/img/clifford_chance.jpg",
  },
  {
    id:65,
    src:"/img/diamond_developers.jpg",
  },
  {
    id:66,
    src:"/img/drawdeck.jpg",
  },
  {
    id:67,
    src:"/img/first_motors.jpg",
  },
  {
    id:68,
    src:"/img/inspiratus_consulting.jpg",
  },
  {
    id:69,
    src:"/img/priory_healthcare.jpg",
  },
  {
    id:70,
    src:"/img/silverwell_energy.jpg",
  },
  {
    id:71,
    src:"/img/sunreef_yachts.jpg",
  },
  {
    id:72,
    src:"/img/synergy_capital.jpg",
  },
  {
    id:73,
    src:"/img/vip-motos.jpg",
  },
  {
    id:74,
    src:"/img/zidou.jpg",
  },
  {
    id:75,
    src:"/img/beehive.jpg",
  },
  {
    id:76,
    src:"/img/wonderfulgroupof-companie.jpg",
  }
]
const pricedt = [
  {
    _id: 1,
    img: "/img/Rectangle 5230.png",
    price: "3000",
  },
  {
    _id: 2,
    img: "/img/Rectangle 5231.png",
    price: "4000",
  },
  {
    _id: 3,
    img: "/img/Rectangle 5230.png",
    price: "5000",
  },
  {
    _id: 4,
    img: "/img/Rectangle 5231.png",
    price: "5500",
  },
  {
    _id: 5,
    img: "/img/Rectangle 5230.png",
    price: "3500",
  },
]
const rating = [
  {
    id: 1,
    heading: "Very reliable and professional work",
    des: "Very reliable and professional work. They followed the design exactly as I wished. Would definitely recommend them.",
    name: "Sihem Hachicha",
    hint: "",
    duration: "Aug 11, 2023",
  },
  {
    id: 2,
    heading: "Loved working with them",
    des: "I had an opportunity to work with artsmily in a live painting event at the hotel show 2021..they were very supportive and encouraging.",
    name: "Aparna Mohan",
    hint: "",
    duration: "Jun 23, 2021",
  },
  {
    id: 3,
    heading: "Immediate response",
    des: "I was pleased with immediate response to my questions despite of late hours. Speedy and friendly service. Recommended.",
    name: "Anastasia Volkova",
    hint: "",
    duration: "Feb 03, 2021",
  },
  {
    id: 4,
    heading: "It was a wonderful experience",
    des: "It was a wonderful experience. Glad to work with such a nice Team. Very Professional and supportive. I Would love to work with them again.",
    name: "Rishu Roy",
    hint: "",
    duration: " Sep 13, 2020",
  },
  {
    id: 5,
    heading: "Very nice online help",
    des: "Absolutely very helping staff service.They are readily available as a guide to all your doubts and really really helpful.Thank you",
    name: "Sachin Jose",
    hint: "",
    duration: "Aug 02, 2020",
  },
]
const newestpaintingData = [
  {
    id: 1,
    title: "Galexy On Earth",
    artist: "John Deo",
    price: "$ 3,254",
    rentpermonth: "$ 680",
    url: "/img/Rectangle 367.png",
  },
  {
    id: 2,
    title: "Galexy On Earth",
    artist: "John Deo",
    price: "$ 3,254",
    rentpermonth: "$ 680",
    url: "/img/Rectangle 368.png",
  },
  {
    id: 3,
    title: "Galexy On Earth",
    artist: "John Deo",
    price: "$ 3,254",
    rentpermonth: "$ 680",
    url: "/img/Rectangle 369.png",
  },
  {
    id: 4,
    title: "Galexy On Earth",
    artist: "John Deo",
    price: "$ 3,254",
    rentpermonth: "$ 680",
    url: "/img/Rectangle 370.png",
  },
  {
    id: 5,
    title: "Galexy On Earth",
    artist: "John Deo",
    price: "$ 3,254",
    rentpermonth: "$ 680",
    url: "/img/Rectangle 5198.png",
  },
  {
    id: 6,
    title: "Galexy On Earth",
    artist: "John Deo",
    price: "$ 3,254",
    rentpermonth: "$ 680",
    url: "/img/Rectangle 5199.png",
  },
  {
    id: 7,
    title: "Galexy On Earth",
    artist: "John Deo",
    price: "$ 3,254",
    rentpermonth: "$ 680",
    url: "/img/Rectangle 5200.png",
  },
  {
    id: 8,
    title: "Galexy On Earth",
    artist: "John Deo",
    price: "$ 3,254",
    rentpermonth: "$ 680",
    url: "/img/Rectangle 5201.png",
  },
]
const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#C1C1C1",
      main: "#000000",
      dark: "#C1C1C1",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffffff",
      main: "#C1C1C1",
      dark: "#ffffff",
      contrastText: "#000",
    },
  },
  typography: {
    h2: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
      fontWeight: 400,
      // lineHeight: "50.16px",
    },
    h1: {
      fontFamily: "Marcellus",
      fontSize: "4vw",
      fontWeight: 400,
      paddingTop: "0%",
      paddingBottom: "0%",
      // lineHeight: "87.77px",
    },
    heading3: {
      fontFamily: "Marcellus",
      fontSize: "1.5vw",
      fontWeight: 400,
    },
    imgText: {
      fontFamily: "Marcellus",
      fontWeight: 400,
      align: "center",
      fontSize: "1.15vw",
    },
    subscribeHeading: {
      fontFamily: "Marcellus",
      color: "white",
      fontWeight: 400,
      fontSize: "2vw",
    },
  },
})

function Homepage(props) {
  const tawkMessengerRef = useRef()
  const { t } = useTranslation();
  const handleMinimize = () => {
    tawkMessengerRef.current.minimize()
  }
  const [data, setData] = useState({ offset: 1 })
  const [open, setOpen] = useState(false)
  const [value, setValue] = React.useState(2)
  const [curatorCollection, setCuratorCollection] = useState([]);
  console.log(curatorCollection,'curatorCollection')
  const [testimonial, setTestimonial] = useState()
  const [recent, setRecent] = useState()
  const current_date = new Date()
  // current_date.setDate(current_date.getDate() - 5);
  

  const categorydt = useSelector((state) => state.allcategoryData)
  const offersdt = useSelector((state) => state.offersData)
  const orderType = useSelector((state) => state.allitemData.orderType)
  console.log(orderType,'orderType')
  const allitemdt = useSelector((state) => state.allitemData)
  console.log(allitemdt,'allitemdt')
  const compaingns = useSelector((state) => state.compaingnsdata.data)
  const loader = useSelector((state) => state.loader)
  const userartists = useSelector((state) => state.userartistData)
  const userDetail = useSelector((state) => state.buyerdetail.data)
  const usertype = useSelector((state) => state.user.data)
  const fol_id =
    userDetail &&
    userDetail.data.followings &&
    userDetail.data.followings.map((val) => val._id?._id)
  const abc =
    allitemdt.data &&
    allitemdt.data.filter(
      (val) => fol_id && fol_id.includes(val?.artist_id?._id)
    )

  
  const dat = allitemdt.data && allitemdt.data[0] && allitemdt.data[0].updatedAt

  var dateFormat =
    current_date.getFullYear() +
    "-" +
    (current_date.getMonth() + 1) +
    "-" +
    (current_date.getDate() - 5) +
    "-" +
    current_date.getTime()

  const dt_date = new Date(dat)
  const today_date = new Date(dateFormat)

  const b = JSON.parse(localStorage.getItem("recently_view"))
  const allitem_id =
    allitemdt.data && allitemdt.data.filter((v) => b && b.includes(v._id))

  // const sorted = allitem_id && allitem_id.sort((a, ab) => b.indexOf(a) - b.indexOf(ab));
  const a = allitem_id && allitem_id.filter((c, i) => b[i] === c._id)

  // This code is contributed by Nitin Jangra

  function sortAccording(A1, A2) {
    // Map to store the indices of elements in the second array
    let index = new Map()
    for (let i = 0; i < A2.length; i++) {
      // Consider only first occurrence of element
      if (!index.has(A2[i])) {
        // Assign value of i+1
        index.set(A2[i], i + 1)
      }
    }

    A1?.sort((a, b) => {
      let idx1 = index.get(a._id) || 0
      let idx2 = index.get(b._id) || 0

      // If both a and b are not present in the second array, sort them in ascending order
      if (idx1 === 0 && idx2 === 0) {
        return a - b
      }

      // If only b is present in the second array, b comes before a
      if (idx1 === 0) {
        return 1
      }

      // If only a is present in the second array, a comes before b
      if (idx2 === 0) {
        return -1
      }

      // If both a and b are present in the second array, sort them according to their respective indices
      return idx1 - idx2
    })
  }

  // allitem_id && sortAccording(allitem_id, b);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      />
    )
  }
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true)
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const dispatch = useDispatch()

  const handleFetchTestimonial = async function () {
    try {
      dispatch(setLoader(true))
      const result = await api.applicationApi("api/user/testimonial", "GET", {})
      if (result && result.status === 200) {
        setTestimonial(result.data.responses)
        dispatch(setLoader(false))
      } else if (result.status === 401) {
        dispatch(setLoader(false))
      } else {
      }
    } catch (err) {
      dispatch(setLoader(false))
    }
  }

  const fetchCuratorCollections = async () => {
    try {
      dispatch(setLoader(true))
      const result = await api.applicationApi("api/art/fetchAllCuratorArts", "GET", {})
      if (result && result.status === 200) {
        setCuratorCollection(result.data)
        dispatch(setLoader(false))
      } else if (result.status === 401) {
        dispatch(setLoader(false))
      } else {
      }
    } catch (err) {
      dispatch(setLoader(false))
    }
  }
  const handleClickBestSeller = (id) => {
    navigate(`/user/bestseller/${id}`)
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  const handleClickTrending = (id) => {
    navigate(`/user/trending/${id}`)
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  useEffect(() => {
    fetchCuratorCollections()
    initializeCurrencyFormatter()
    handleFetchTestimonial()
    dispatch(fetchCategoryData({}))
    dispatch(fetchFetchOffers())
    dispatch(fetcCompaingnsData({ offset: 1 }))
    dispatch(fetchAllItemData())
    dispatch(fetchUserArtist({}))
    dispatch(handleFetchBuyerDetail())

    dispatch(handelFetchRecentlyView({ limit: 90 }, setRecent))
  }, [])
  const [email, setEmail] = useState("")
  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = () => {
    const email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(email_regex)) {
      dispatch(AddMailSubscription({ email: email }, setOpen, setEmail))
    } else {
      Errornotify("Please Enter Valid email")
    }
  }
  
  const herodata = useSelector((state) => state.heroData.data)
  const fetchData = () => {
    dispatch(fetchHeroSectionData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])

  const handlePartnerButton = () => {

    navigate("/user/our-clients")
    };

  return (
    <ThemeProvider theme={theme}>
      <AlertsSuccess />
      {open ? (
        <Homemodel
          handleSubmit={handleSubmit}
          email={email}
          setEmail={setEmail}
          handleChange={handleChange}
          open={open}
          setOpen={setOpen}
        />
      ) : (
        ""
      )}
    
      <div className={styles.homepage}>
        {/* <div className={styles.homepage__LandingPage}>
          <div className={styles.landingpage__Text}>
            <Typography variant="h2">Latest Collection</Typography>
            <br />
            <Typography variant="h1">
              Original Artworks <br />& Paintings
            </Typography>
            <br />
            <Button
              onClick={() => {
                navigate("/user/allcategory")
                window.scrollTo({ top: 0, behavior: "smooth" })
              }}
              sx={{
                width: { xs: "100px", sm: "156px" },
                height: { xs: "36px", sm: "56px" },
              }}
              className={styles.lp__button}
              variant="contained"
            >
              <Typography sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}>
                Buy Now
              </Typography>
            </Button>
          </div>
          <div className={styles.landingpage__Images}>
            <div className={styles.images && styles.image__item1}>
              <img
                className={styles.top__Image}
                src={"/img/Rectangle 5264.png"}
                alt="img"
              />
            </div>
            <div className={styles.images && styles.image__item2}>
              <img
                className={styles.top__Image2}
                src={"/img/Rectangle 5266.png"}
                alt="img"
              />
            </div>
            <div className={styles.images && styles.image__item3}>
              <img
                className={styles.top__Image3}
                src={"/img/Rectangle 365.png"}
                alt="img"
              />
            </div>
          </div>
        </div> */}
        {loader.loader ? (
          <div className={styles.loader}>
            <CircularLoader />
          </div>
        ) : (
          <Suspense fallback={<CircularLoader />}>
           <SliderCarousel data={herodata ? herodata.data : []} type="hero" />
      </Suspense>
       
        )}
        
        <div className={`prod-slider ${styles.sec_main}`}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
          <div className={`${styles.titlsec} ${styles.text_center}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>{t("newest_paintings")}</h3>
          <p className={styles.sub_title}>{t("newest_paintings_subheading")}</p>
          </div>
        
        {loader.loader ? (
          <div className={styles.loader}>
            <CircularLoader />
          </div>
        ) : (
          <Suspense fallback={<CircularLoader />}>
            {console.log(
    allitemdt.data
      ? allitemdt.data.filter((val) => val.is_accepted === "Approved")
          .filter((val) => val.category_id?.name?.toLowerCase() === "painting")
          .length
      : 0
  )}
                <SliderCarousel
            data={
              allitemdt.data
                ? allitemdt.data.filter(
                    (val) => val.is_accepted === "Approved"
                  ).filter((val) => 
                    val.category_id?.name?.toLowerCase() === "painting"
                  )
                : []
            }
            type="paintings"
            orderType={orderType}
            formatNewCurrency={formatNewCurrency}
          />
          </Suspense>
      
        )}
          </Container>
        </div>
        
        <div className={`prod-slider ${styles.sec_main}`}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.titlsec} ${styles.text_center}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>{t("shop_by_category")}</h3>
          <p className={styles.sub_title}>{t("shop_by_category_subheading")}</p>
          </div>

          {loader.loader ? (
          <div className={styles.loader}>
            <CircularLoader />
          </div>
        ) : (
          <Suspense fallback={<CircularLoader />}>
          <SliderCarousel
              data={categorydt.data ? categorydt.data.allcategories : []}
              type=""
          />
      </Suspense>
        )}
        </Container>
        </div>
        
       
        <div className={styles.best_seller_main}>
        <div className={`${styles.titlsec} ${styles.text_center}`}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="img"
            />
            <h3 className={styles.sec_title}>{t("best_sellers")}</h3>
          </div>
          {loader.loader ? (
            <div className={styles.loader}>
              <CircularLoader />
            </div>
          ) : (
            <Box sx={{ width: { sm: "70%", xs: "95%" }, m: "auto" }}>
              <ImageList
                variant="masonry"
                sx={{
                  columnCount: {
                    xs: "2 !important",
                    sm: "2 !important",
                    md: "3 !important",
                    lg: "4 !important",
                    // xl: '5 !important',
                  },
                }}
                gap={10}
              >
                <>
                  {allitemdt.data &&
                    allitemdt.data
                      .filter((val) => {
                        return val.is_best_seller === true
                      })
                      .map((item) => (
                        <ImageListItem
                          className={styles.image_list}
                          key={item._id}
                          onClick={() => handleClickBestSeller(item._id)}
                        >
                          <img
                            src={item.image}
                            // srcSet={`${item.category_id.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.artist_id && item.artist_id.first_name}
                            loading="lazy"
                          />
                          <h3 className={styles.cards_head}><TranslateWrapper>{capitalizeWords(item.name)}</TranslateWrapper></h3>
                          <p className={styles.cards_artist}>
                            <TranslateWrapper>
                            {capitalizeWords(item.artist_id && item.artist_id.first_name)}{" "}
                            {capitalizeWords(item.artist_id && item.artist_id.last_name)}
                            </TranslateWrapper>
                          </p>
                          {/* <ImageListItemBar sx={{ m: 0 }} className={styles.cards_head} position="below" title={item.painting_name} />
                <ImageListItemBar sx={{ m: 0 }} className={styles.cards_artist} position="below" title={item.artist} /> */}
                        </ImageListItem>
                      ))}
                </>
              </ImageList>
            </Box>
          )}
        </div>
        <div className={`prod-slider ${styles.sec_main}`}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.titlsec} ${styles.text_center}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>{t("most_popular")}</h3>
          </div>
          {loader.loader ? (
          <div className={styles.loader}>
            <CircularLoader />
          </div>
        ) : allitemdt.data &&
          allitemdt.data.filter((val) => {
            return val.is_most_popular === true
          }).length === 0 ? (
          <p style={{textAlign:'center'}}>{t("no_data_found")}</p>
        ) : (
         <Suspense fallback={<CircularLoader />}>
            <SliderCarousel
            data={
              allitemdt.data !== null
                ? allitemdt?.data?.filter((val) => {
                    return val.is_most_popular === true
                  })
                : []
            }
            type="most_popular"
          />
         </Suspense>
        )}
          </Container>
        </div>
        
        
        <div className={`prod-slider1 ${styles.sec_main}`}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <Grid container spacing={2}  alignItems="center">
        <Grid item xs={12} md={3}>
          <div className={`${styles.titlsec} ${styles.price_explore}`}>
       
            <div>
              <img
                src={"/img/Design.png"}
                height="21.49px"
                width="78.3px"
                alt=""
              />
            </div>
            <h3>{t("shop_by_price")}</h3>
            <p>{t("shop_by_price_subheading")}</p>
            <button className="apply_btn"
              onClick={() => navigate("/user/search")}
            >
              {t("explore")}
            </button>
          </div>
        </Grid>
  <Grid item md={9} xs={12}>
  <div className={styles.price_div}>
            <Suspense fallback={<CircularLoader />}>
            <SliderCarousel
              // data={
              //   allitemdt.data !== null
              //     ? allitemdt.data
              //     : []
              // }
              data={pricedt}
              type="price"
            />
            </Suspense>
          </div>
  </Grid>
        </Grid>
          </Container>
          </div>
        <div className={styles.offer_banner}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <Grid container spacing={2}  alignItems="center">
        <Grid item md={3}  xs={12}>
        <div className={`${styles.titlsec} ${styles.explore_side}`}>
       
            <div>
              <img
                src={"/img/Design.png"}
                height="21.49px"
                width="78.3px"
                alt=""
              />
            </div>
            <h3>{t("offers")}</h3>
            <p>{t("offers_subheading")}</p>
            <button  className="apply_btn"
              variant="contained"
              onClick={() => {
                navigate("/user/offers")
              }}
            >
              {t("explore")}
            </button>
          </div>
        </Grid>
        <Grid item md={9} xs={12}>
        <Grid container spacing={2}>
        {loader.loader ? (
            <div className={styles.loaders}>
              <CircularLoader />
            </div>
          ) : (
            offersdt.data &&
            offersdt.data.slice(0, 2).map((val) => (
              <Grid item md={6} sm={6} xs={12}>
                <div className={styles.offers_div}>
                <div className={styles.offer_card}>
                <Grid container spacing={2} alignItems="center">
                <Grid item md={6} xs={12}>
                <div>
                    <h3 className={styles.off}><TranslateWrapper>Get Up To {val.discount}% Off</TranslateWrapper></h3>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                <div className={styles.offerimgsc}>
                    <img
                      src={val.offer_image}
                       className={styles.img_fluid}
                      alt="img"
                    />
                  </div>
                </Grid>
                </Grid>
                </div>
              </div>
              </Grid>
            ))
          )}
        </Grid>
        </Grid>
        </Grid>
        </Container>
        </div>
        {localStorage.getItem("user_id") ? (
          <div className={styles.offer_banner} style={{ marginTop: "100px" }}>
            <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <Grid container spacing={2}  alignItems="center">
            <Grid item md={3} xs={12}>
            <div className={`${styles.titlsec} ${styles.explore_side}`}>
              <div>
                <img
                  src={"/img/Design.png"}
                  height="21.49px"
                  width="78.3px"
                  alt=""
                />
              </div>
              <h3>{t("campaign_offers")}</h3>
              <p>
                {t("campaign_offers_subheading")}
              </p>
              <button  className={styles.remobtn}
                variant="contained"
                onClick={() => {
                  // navigate("/user/offers");
                }}
              >
                {t("explore")}
              </button>
            </div>
            </Grid>
        <Grid item md={9} xs={12}>
        <Grid container spacing={2}>
        {loader.loader ? (
              <div className={styles.loaders}>
                <CircularLoader />
              </div>
            ) : (
              compaingns?.data &&
              compaingns?.data?.slice(0, 2).map((val) => (
                <Grid item md={6} sm={6} xs={12}>
                  <div className={styles.offers_div}>
                  <div className={styles.offer_card}>
                  <Grid container spacing={2} alignItems="center">
                  <Grid item md={6} xs={12}>
                  <div>
                      <h1 className={styles.off}><TranslateWrapper>Get Up To {val.discount}% Off</TranslateWrapper></h1>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                  <div>
                      <h3 className={styles.off} style={{ fontSize: "18px" }}>
                        <TranslateWrapper>{val.name}</TranslateWrapper>
                      </h3>
                    </div>
                  </Grid>
                  </Grid>
                  </div>
                </div>
                </Grid>
              ))
            )}
        </Grid>
        </Grid>
            </Grid>
            </Container>
          </div>
        ) : null}

<div className={`prod-slider ${styles.sec_main}`}>
        <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.titlsec} ${styles.text_center}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>{t("curator_collections")}</h3>
          </div>
          {loader.loader ? (
          <div className={styles.loader}>
            <CircularLoader />
          </div>
        ) : (
         <Suspense fallback={<CircularLoader />}>
             <SliderCarousel
            data={curatorCollection ? curatorCollection.data?.filter((val) => val?.item_ids?.length > 0) : []}
            type="curator"
          />
         </Suspense>
        )}
          </Container>
        </div>
        
        <div className={`prod-slider ${styles.sec_main}`}>
       <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.titlsec} ${styles.text_center}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>{t("featured_artists")}</h3>
          <p className={styles.sub_title}>{t("featured_artists_subheading")}</p>
          </div>
          {loader.loader ? (
          <div className={styles.loader}>
            <CircularLoader />
          </div>
        ) : (
          <Suspense fallback={<CircularLoader />}>
             <SliderCarousel
            data={
              userartists.data
                ? userartists.data.filter((val) => val.is_featured === true)
                : []
            }
            // data={userartists.data ? userartists.data : []}
            type="featured"
          /> 
          </Suspense>
        )}
          </Container>
       </div>
        
        
        {localStorage.getItem("user_id") && fol_id && fol_id.length > 0 ? (
          <>
          <div className={`prod-slider ${styles.sec_main}`}>
       <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
       <div className={`${styles.titlsec} ${styles.text_center}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>{t("from_people_you_follow")}</h3>
          <p className={styles.sub_title}>{t("from_people_you_follow_subheading")}</p>
          </div>

          {loader.loader ? (
              <div className={styles.loader}>
                <CircularLoader />
              </div>
            ) : (
           <Suspense fallback={<CircularLoader />}>
                <SliderCarousel
                data={
                  allitemdt.data &&
                  allitemdt.data
                    .filter(
                      (val) => fol_id && fol_id.includes(val?.artist_id?._id)
                    )
                    .reverse()
                }
                type="follow"
              />
           </Suspense>
            )}
          </Container>
          </div>
          </>
        ) : null}

        {/* testmonials */}
        <div className={`testmo-slider ${styles.sec_main}`}>
       <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.titlsec} ${styles.text_center}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>{t("testimonials")}</h3>
          <p className={styles.sub_title}>{t("testimonials_subheading")}</p>
          </div>
          {loader.loader ? (
            <div className={styles.loader}>
              <CircularLoader />
            </div>
          ) : (
            <Slider type="testimonial" data={testimonial ? testimonial : []} />
          )}
          </Container>
          </div>
        
        {/* trending */}
        {/* <div className={styles.hero_main7}>
           
          {loader.loader ? (
            <div className={styles.loader}>
              <CircularLoader />
            </div>
          ) : (
            <>
              <div className={styles.trending_content_div}>
                <Grid
                  container
                  sx={{ justifyContent: "center" }}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                >
                  {allitemdt.data &&
                    allitemdt.data &&
                    allitemdt.data
                      .filter((item) => {
                        return item.trending === true
                      })
                      .map((val) => (
                        <Grid
                          item
                          className={styles.trending_card}
                          xs={4}
                          sm={2}
                          md={2}
                          onClick={() => handleClickTrending(val._id)}
                        >
                          <div className={styles.trending_img_div}>
                            <img
                              src={val.category_id.image}
                              height="100%"
                              width="100%"
                              alt="img"
                            />
                          </div>
                          <div className={styles.type_div}>
                            <h4>{val.name}</h4>
                          </div>
                        </Grid>
                      ))}
                </Grid>
              </div>
              {allitemdt.data &&
              allitemdt.data &&
              allitemdt.data.filter((item) => {
                return item.trending === true
              }).length === 0 ? (
                <p>No Data Found</p>
              ) : (
                <Button
                  variant="contained"
                  sx={{ width: "136px", height: "56px", mt: 5 }}
                  onClick={() => {
                    navigate("/user/trending")
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }}
                >
                  Explore
                </Button>
              )}
            </>
          )}
        </div> */}
        {/* recently viewed */}
        {localStorage.getItem("user_id") &&
        userDetail?.data?.item_views.length > 0 ? (
          <div className={`prod-slider ${styles.sec_main}`}>
       <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.titlsec} ${styles.text_center}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>{t("recently_viewed")}</h3>
          </div>
          {loader.loader ? (
          <div className={styles.loader}>
            <CircularLoader />
          </div>
        ) : (
         <Suspense fallback={<CircularLoader />}>
             <SliderCarousel
            // data={allitem_id ? allitem_id.reverse() : []}
            type="recently"
            data={recent ? recent.reverse() : []}
          />
         </Suspense>
        )}
          </Container>
          </div>
          
        ) : (
          ""
        )}
        <div className={`prod-slider ${styles.sec_main} ${styles.partnrs}`}>
       <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
        <div className={`${styles.titlsec} ${styles.text_center}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>{t("partners")}</h3>
          <p className={styles.sub_title}>{t("partners_subheading")}</p>
          </div>
          <div className={styles.trending_content_div}>
          <Swiper
  modules={[Navigation, Pagination, Autoplay]} // Include Autoplay module
  slidesPerView="auto" // Enable auto width for continuous scrolling
  spaceBetween={15}
  loop={true} // Number of slides to loop seamlessly
  speed={1000} // Adjust the speed for the marquee effect
  autoplay={{
    delay: 0, // Continuous autoplay with no delay
    disableOnInteraction: false, // Allow autoplay after interaction
  }}
  breakpoints={{
    480: {
      slidesPerView: '2',
      spaceBetween: 10,
    },
    768: {
      slidesPerView: '5',
      spaceBetween: 15,
    },
    1024: {
      slidesPerView: '7',
      spaceBetween: 15,
    },
  }}
>
  {/* Example Swiper slides */}
  {partners &&
                partners.map((val) => (
  <SwiperSlide style={{ width: 'auto' }}>
  <div className={styles.partners_img_div}>
                      <img src={val.src} className={styles.img_fluid} alt="img" />
                    </div>
  </SwiperSlide>
   ))}
</Swiper>
            {/* <Grid
              container
              columnSpacing={0}
              columns={{ xs: 14, sm: 14, md: 14 }}
            >
              {partners &&
                partners.map((val) => (
                  <Grid className={styles.partners_card} xs={7} sm={2} md={2}>
                    <div className={styles.partners_img_div}>
                      <img src={val.src} className={styles.img_fluid} alt="img" />
                    </div>
                  </Grid>
                ))}
            </Grid> */}
          </div>
          <div className={styles.btnmt2}>
            <button  onClick={handlePartnerButton} className="apply_btn">View More</button>
          </div>
          </Container>
          </div>
        
          <div className={`prod-slider ratingsc ${styles.sec_main}`}>
       <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
       <Grid container spacing={2}  alignItems="center">
       <Grid item md={2} xs={12}>
       <div className={styles.mobcebter}>
            <h4 className={styles.extitle}>{t("excellent")}</h4>

            <div className={styles.rating_div}>
              <StarIcon className={styles.sticon}/>
              <StarIcon className={styles.sticon}/>
              <StarIcon className={styles.sticon}/>
              <StarIcon className={styles.sticon}/>
              <StarIcon className={styles.sticon}/>
            </div>
            <p className={styles.rtpra}>
              <TranslateWrapper>Based on </TranslateWrapper><u><TranslateWrapper>8,180 reviews</TranslateWrapper></u>
            </p>
            <div className={styles.img_type}>
              <StarIcon
                sx={{ width: "30px", height: "30px", color: "green" }}
              />
              <h3><TranslateWrapper>TrustPilet</TranslateWrapper></h3>
            </div>
          </div>
       </Grid>
       <Grid item md={1}></Grid>
       <Grid item md={9} xs={12}>
        
       <Suspense fallback={<CircularLoader />}>
       <SliderCarousel
            data={rating.length !== 0 ? rating : []}
            type="rating"
          /></Suspense>
          <div className={styles.mtb2}>
          <TrustpilotWidget />
          </div>
       
       </Grid>
       </Grid>
       </Container>
       </div>
      

        {/* <Box className={styles.subscribe_banner}>
          
          <div>
            <img src={"/img/email.png"} width="80px" height="63px" alt="img" />
          </div>
          <h5 className={styles.subscribe_head}>
            Subscribe for Art Smiley Newsletter to get the trending art updates,
            curated collections, and special offers.
          </h5>
          <TextField
            variant="outlined"
            placeholder="Please enter your mail address"
            sx={{
              width: { xs: "90%", sm: "400px" },
              backgroundColor: "#3c3c3c",
              "& fieldset": {
                border:
                  email.length === 0
                    ? "none"
                    : email.match(
                   /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                      )
                    ? "none"
                    : "",
              }, 
              borderRadius: "6px",
              "& .MuiOutlinedInput-notchedOutline": { border: "" }, 
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "21px",
              color: "#FFFFFF",
              input: { color: "white" }, 
            }}
            error="true"
            value={email}
            onChange={handleChange}
          />
          <Button
            variant="contained"
            sx={{
              background: "white",
              color: "black",
              height: "54px",
              width: "131px",
              borderRadius: "6px",
            }}
            onClick={handleSubmit}
          >
            Subscribe
          </Button>
        </Box> */}

        <div className="App">
          {/* <button onClick={handleMinimize}> Minimize the Chat </button> */}
          {localStorage.getItem("usertype") === "buyer" ? (
            <TawkMessengerReact
              propertyId="64b247bbcc26a871b0288827"
              widgetId="1h5c6ge6i"
              ref={tawkMessengerRef}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </ThemeProvider>
  )
}

export default Homepage
