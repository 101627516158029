import { ThemeProvider } from "@emotion/react";
import { useState, useEffect } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Link,
  createTheme,
  Grid,
  InputAdornment,
  TextField,
  Container,
  IconButton,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import styles from "./ArtistList.module.css";
import { useNavigate } from "react-router-dom";
import homeImage from "../../../constant";
import { useSelector } from "react-redux";
import ArtistSidebar from "./ArtistSidebar";
import { RiStarFill } from "react-icons/ri";
import { useLocation } from "react-router-dom";

const useQueryParams = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};
const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
    accordionheading: {
      fontSize: "14px",
      fontWeight: 500,
    },
    radioname: {
      fontSize: "0.95vw",
      fontWeight: "400",
    },
  },
});
const artists = [
  {
    id: 1,
    name: "Morgan Wave",
    country: "Dubai",
    rating: 0,
    profession: "Drawing",
    profileImg: "/img/artist_profile.png",
    backgroundImg: "/img/ourartist3.png",
    artist_type: "I am an acclaimed artist",
  },
  {
    id: 2,
    name: "Sophia Reed",
    country: "USA",
    rating: 4.5,
    profession: "Painting",
    profileImg: "/img/artist_profile.png",
    backgroundImg: "/img/ourartist3.png",
    artist_type: "I am an experienced artist",
  },
  {
    id: 3,
    name: "Liam Carter",
    country: "India",
    rating: 3.8,
    profession: "Photography",
    profileImg: "/img/artist_profile.png",
    backgroundImg: "/img/ourartist3.png",
    artist_type: "I am an experienced artist",
  },
  {
    id: 4,
    name: "Ava Kim",
    country: "Germany",
    rating: 5.0,
    profession: "Mixed Media",
    profileImg: "/img/artist_profile.png",
    backgroundImg: "/img/ourartist3.png",
    artist_type: "I am an acclaimed artist",
  },
  {
    id: 5,
    name: "Ethan Hayes",
    country: "India",
    rating: 2.7,
    profession: "Installation",
    profileImg: "/img/artist_profile.png",
    backgroundImg: "/img/ourartist3.png",
    artist_type: "I am an emerging and talented artist",
  },
  {
    id: 6,
    name: "Isabella Rossi",
    country: "Dubai",
    rating: 4.2,
    profession: "Textile",
    profileImg: "/img/artist_profile.png",
    backgroundImg: "/img/ourartist3.png",
    artist_type: "I am a Master",
  },
];
function ArtistList() {
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedArtistTypes, setSelectedArtistTypes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  // Function to create the query string based on selected filters
  const buildQueryParams = () => {
    const params = new URLSearchParams();
  
    // Add selected categories to query params
    if (selectedCategories && selectedCategories.length > 0) {
      params.set('category', selectedCategories.join(','));
    }
  
    // Add selected artist types to query params
    if (selectedArtistTypes && selectedArtistTypes.length > 0) {
      params.set('artistType', selectedArtistTypes.join(','));
    }

    // Add selected countries to query params
    if (selectedCountries && selectedCountries.length > 0) {
      params.set('country', selectedCountries.join(','));
    }
  
    return params.toString();
  };
  
  // Function to update the URL based on selected filters
  const updateUrlWithFilters = () => {
    const queryString = buildQueryParams();
    navigate(`?${queryString}`, { replace: true });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const clearSearch = () => {
    setSearchQuery("");
  };
  const handleCategorySelect = (category) => {
    setSelectedCategories((prevSelected) => {
      const newSelected = prevSelected.includes(category)
        ? prevSelected.filter((item) => item !== category)
        : [...prevSelected, category];
      return newSelected;
    });
  };
  const handleCountrySelect = (country) => {
    setSelectedCountries((prevSelected) => {
      const newSelected = prevSelected.includes(country)
        ? prevSelected.filter((item) => item !== country)
        : [...prevSelected, country];
      return newSelected;
    });
  };
  // Function to handle artist type selection
  const handleArtistTypeSelect = (type) => {
    setSelectedArtistTypes((prevSelected) => {
      const newSelected = prevSelected.includes(type)
        ? prevSelected.filter((item) => item !== type)
        : [...prevSelected, type];
      return newSelected;
    });
  };
  const handleRemoveCategory = (category) => {
    setSelectedCategories((prevCategories) =>
      prevCategories.filter((cat) => cat !== category)
    );
  };
  const handleRemoveArtistType = (type) => {
    setSelectedArtistTypes((prevArtistTypes) =>
      prevArtistTypes.filter((cnt) => cnt !== type)
    );
  };
  const handleRemoveCountry = (country) => {
    setSelectedCountries((prevCountries) =>
      prevCountries.filter((cnt) => cnt !== country)
    );
  };
  const filteredArtists = artists.filter((artist) => {
    const matchesSearch =
      artist.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      artist.country.toLowerCase().includes(searchQuery.toLowerCase()) ||
      artist.profession.toLowerCase().includes(searchQuery.toLowerCase());
      // artist.artist_type.toLowerCase().includes(searchQuery.toLowerCase()); // Consider if you want to include artist_type in the general search
  
    const categoryMatch =
      selectedCategories.length === 0 ||
      selectedCategories.some((category) =>
        artist.profession.toLowerCase().includes(category.toLowerCase())
      );
  
    const countryMatch =
      selectedCountries.length === 0 ||
      selectedCountries.some((country) =>
        artist.country.toLowerCase().includes(country.toLowerCase())
      );
  
    const artistTypeMatch =
      selectedArtistTypes.length === 0 ||
      selectedArtistTypes.some((type) =>
        artist.artist_type.toLowerCase().includes(type.toLowerCase())
      );
  
    const noFiltersSelected =
      selectedCategories.length === 0 &&
      selectedCountries.length === 0 &&
      selectedArtistTypes.length === 0;
  
    // If no specific filters are selected, only the search query matters
    if (noFiltersSelected) {
      return matchesSearch;
    }
  
    // Otherwise, an artist must match the search AND (at least one active filter type matches)
    const matchesAnyActiveFilterType =
      (selectedCategories.length > 0 && categoryMatch) ||
      (selectedCountries.length > 0 && countryMatch) ||
      (selectedArtistTypes.length > 0 && artistTypeMatch);
  
    return matchesSearch && matchesAnyActiveFilterType;
  });
  useEffect(() => {
    updateUrlWithFilters();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [selectedCategories, selectedCountries, selectedArtistTypes]);
  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={styles.artist__list}>
          <div className={styles.headerCategory}>
            <Container
              maxWidth="xl"
              sx={{
                paddingLeft: {
                  xs: "16px",
                  sm: "24px",
                  md: "60px",
                  lg: "70px",
                  xl: "100px",
                },
                paddingRight: {
                  xs: "16px",
                  sm: "24px",
                  md: "60px",
                  lg: "70px",
                  xl: "100px",
                },
              }}
            >
              <div className={styles.pageitle}>
                <h1>Artist</h1>
                <ul>
                  <li>
                    <Link onClick={() => handleNavigate("/")}>Home / </Link>
                  </li>
                  <li>Artist List</li>
                </ul>
              </div>
            </Container>
          </div>
          <div className={styles.headerMain}>
            <Container
              maxWidth="xl"
              sx={{
                paddingLeft: {
                  xs: "16px",
                  sm: "24px",
                  md: "60px",
                  lg: "70px",
                  xl: "100px",
                },
                paddingRight: {
                  xs: "16px",
                  sm: "24px",
                  md: "60px",
                  lg: "70px",
                  xl: "100px",
                },
              }}
            >
              <div className={styles.tosearch}>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item xs={12} md={3}>
                    <div className={styles.searchheading}>
                      <h3>Our Artist</h3>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      placeholder="Search Artists"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlinedIcon />
                          </InputAdornment>
                        ),
                        endAdornment: searchQuery && (
                          <InputAdornment position="end">
                            <IconButton onClick={clearSearch} edge="end">
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ borderRadius: "5px", width: "100%" }}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <ArtistSidebar
                    onCategorySelect={handleCategorySelect}
                    selectedCategories={selectedCategories}
                    onCountrySelect={handleCountrySelect}
                    selectedCountries={selectedCountries}
                    onArtistTypeSelect={handleArtistTypeSelect}
                    selectedArtistTypes={selectedArtistTypes}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className={styles.selectedFilters}>
                        {selectedCategories.length > 0 &&
                          selectedCategories.map((category, index) => (
                            <div
                              key={index}
                              className={styles.filterButtonsStyles}
                            >
                              <span>{category}</span>
                              <CloseOutlined
                                onClick={() => handleRemoveCategory(category)}
                                sx={{ fontSize: "1rem", marginLeft: "4px" }}
                                className={styles.filterButtonsStylesIcon}
                              />
                            </div>
                          ))}
                        {selectedArtistTypes.length > 0 &&
                          selectedArtistTypes.map((artist_type, index) => (
                            <div
                              key={index}
                              className={styles.filterButtonsStyles}
                            >
                              <span>{artist_type}</span>
                              <CloseOutlined
                                onClick={() =>
                                  handleRemoveArtistType(artist_type)
                                }
                                sx={{ fontSize: "1rem", marginLeft: "4px" }}
                                className={styles.filterButtonsStylesIcon}
                              />
                            </div>
                          ))}
                        {selectedCountries.length > 0 &&
                          selectedCountries.map((country, index) => (
                            <div
                              key={index}
                              className={styles.filterButtonsStyles}
                            >
                              <span>{country}</span>
                              <CloseOutlined
                                onClick={() => handleRemoveCountry(country)}
                                sx={{ fontSize: "1rem", marginLeft: "4px" }}
                                className={styles.filterButtonsStylesIcon}
                              />
                            </div>
                          ))}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {filteredArtists.length > 0 ? (
                      filteredArtists.map((artist) => (
                        <Grid item xs={12} sm={6} md={4} key={artist.id}>
                          <div className={styles.otherartistcards}>
                            <div className={styles.otherartistcardmain}>
                              <div className={styles.Other_otheim}>
                                <img
                                  className={styles.otherartimg}
                                  src={artist.backgroundImg}
                                  alt=""
                                />
                              </div>

                              <div className={styles.otherartistimaged}>
                                <img
                                  className={styles.otherartistimg}
                                  src={artist.profileImg}
                                  alt=""
                                />
                              </div>

                              <div className={styles.Other_otherartist}>
                                <div className={styles.artdtl}>
                                  <div>
                                    <h4>{artist.name}</h4>
                                    <p>{artist.country}</p>
                                    <p>{artist.artist_type}</p>
                                  </div>
                                  <div className={styles.ratingdiv}>
                                    <div>
                                      <p className={styles.rngd}>
                                        <RiStarFill className={styles.rsic} />{" "}
                                        {artist.rating}
                                      </p>
                                    </div>
                                    <p>{artist.profession}</p>
                                  </div>
                                </div>

                                <button
                                  className={`apply_ltbtn ${styles.widthfull} ${styles.mt_2}`}
                                >
                                  Explore
                                </button>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      ))
                    ) : (
                      <Grid item xs={12} sm={12} md={12}>
                        <h4 style={{ textAlign: "center", margin: "40px 0" }}>
                          No Artists found.
                        </h4>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
}
export default ArtistList;