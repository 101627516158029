import React, { useEffect, useState } from 'react';
import styles from './PrintDetailpage.module.css'
import { useParams, useNavigate } from 'react-router-dom';
import { deletePrintProduct, deletePrintProductPrice, editedPrintMaterial, editedPrintProduct, editedPrintProductPrice, fetchPrintMaterialData, fetchPrintPriceData, fetchPrintProductData, handleAddPrintProduct, handleAddPrintProductPrice } from '../../../../../State/api/superadminApi';
import { useDispatch, useSelector } from 'react-redux';
import homeImage from '../../../../../constant';
import { Button, IconButton, TextField, Link, Grid, Tooltip } from '@mui/material';
import CircularLoader from '../../../../../common/loader/Loader';
import Enterdetails from '../../Popupdelete/enterDetails/Enterdetails';
import { AlertsSuccess } from '../../../../../common/alert/Alert';
import { IoIosArrowBack } from "react-icons/io";
import { FiTrash2, FiEdit3 } from "react-icons/fi";

const PrintDetailpage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [materialDt, setMaterialDt] = useState(null);
    const [productDt, setProductData] = useState(null);
    const [printPriceDt, setPrintPriceData] = useState(null);
    const [open, setOpen] = useState(false);
    const [price, setPrice] = useState(false);

    const [name, setName] = useState({ name: '', _id: "" })
    const [category, setCategory] = useState({ add: false, edit: false });

    const loader = useSelector((state) => state.loader);

    const fetchMaterialData = () => {
        dispatch(fetchPrintMaterialData(setMaterialDt));
    };

    const fetchProductData = () => {
        dispatch(fetchPrintProductData(setProductData));
    };

    const fetchprintPriceDt = () => {
        dispatch(fetchPrintPriceData({ print_material_id: id }, setPrintPriceData));
    };
    useEffect(() => {
        fetchMaterialData();
        fetchProductData();
        fetchprintPriceDt();
    }, []);

    const handleEditdata = (type, value, id) => {
        setCategory({ ...category, edit: true })
        setName({ ...name, name: value, _id: id });
    };

    const handleDelete = (type, id) => {
        switch (type) {
            case 'product':
                dispatch(deletePrintProduct(id, fetchProductData));
                break;
            case 'product_price':
                dispatch(deletePrintProductPrice(id, fetchprintPriceDt))
                break;
            default: break;
        }
    }

    const handleChange = (e) => {
        setName({ ...name, name: e.target.value });
    }

    const hundleSubmit = (type, id) => {
        switch (type) {
            case 'editProductPrice':
                dispatch(editedPrintProductPrice(id, { price: name.name }, fetchprintPriceDt))
                break;
            case 'editProduct':
                dispatch(editedPrintProduct(id, { name: name.name }, fetchProductData));
                break;
            //     case 'editStyle':
            //         dispatch(handleEdit(`api/itemStyle/editItemStyle/${id}`, name, fetchData));
            //         setCategory({ ...category, edit: false });
            //         break;
            //     case 'editMedium':
            //         dispatch(handleEdit(`api/itemMedium/editItemMedium/${id}`, name, fetchDataMedium));
            //         setCategory({ ...category, edit: false });
            //         break;
            //     case 'editSubject':
            //         dispatch(handleEdit(`api/itemSubject/editItemSubject/${id}`, name, fetchDataSubject));
            //         setCategory({ ...category, edit: false });
            //         break;
            default:
                break;
        }
        setCategory({ ...category, edit: false });
    }
    const handleAddBtn = () => {
        setOpen(true)
    }
    const handleAddProduct = (dt) => {
        dispatch(handleAddPrintProduct(dt, setOpen, fetchProductData));
    };
    const handleAddPrice = (price, product_id) => {
        dispatch(handleAddPrintProductPrice({ print_material_id: id, price: price, print_product_id: product_id }, setPrice, fetchprintPriceDt));
    };

    const navigate = useNavigate()
      const handleBack = () => {
        navigate("/print")
      }
    return (
        <div className={styles.mains}>
            <AlertsSuccess />
            {open ? (
                <Enterdetails
                    handleAdd={handleAddProduct}
                    poptype="print"
                    open={open}
                    setOpen={setOpen}
                />
            ) : (
                ""
            )}
            {price ? (
                <Enterdetails
                    handleAdd={handleAddPrice}
                    poptype="print_prcie"
                    open={price}
                    setOpen={setPrice}
                    productDt={productDt}
                />
            ) : (
                ""
            )}
            <div className={styles.back_button}>
    <Link onClick={handleBack} className={styles.backbn}> <IoIosArrowBack /> <span> Go Back</span></Link>
      </div>
      <h3 className={styles.hrdngofv}>Material Name : {materialDt && materialDt.data.filter(v => v._id === id)[0].name} </h3>
            <div className={styles.main_details}>
            <Grid  container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
            <div className={styles.boxsc}>
                    <div className={styles.add_div}>
                        <h5>Product Name</h5>
                        <button variant='contained' onClick={handleAddBtn} className='fill-button-menu'>Add</button>
                    </div>
                    {loader.loader ? (
                        <CircularLoader size={60} />
                    ) : productDt && productDt.data.length === 0 ? "------" :
                        productDt && productDt.data.map(val => (
                            <p className={styles.detail_para}>
                                {category.edit && val._id === name._id ?
                                    <input className='form-control' value={name.name} onChange={handleChange} variant='outlined' type='text'></input>
                                    : <Tooltip title={val.name} arrow
                                                                    PopperProps={{
                                                                        modifiers: [
                                                                          {
                                                                            name: 'offset',
                                                                            options: {
                                                                              offset: [0, -13], // Adjust the vertical space (4px here)
                                                                            },
                                                                          },
                                                                        ],
                                                                      }}
                                                                    ><span className={styles.menpr}>{val.name}</span></Tooltip>}
                                {category.edit && val._id === name._id ?
                                    <button className='fill-button-menu' onClick={() => hundleSubmit('editProduct', val._id)}>Submit</button> :
                                    <>
                                    <div>
                                    <IconButton
                                            onClick={() => handleEditdata("edit", val.name, val._id)}
                                        >
                                            <FiEdit3 className={styles.editicon} />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleDelete("product", val._id)}
                                        // sx={{ ml: 1 }}
                                        >
                                            <FiTrash2 className={styles.deleicon}/>
                                        </IconButton>
                                    </div>
                                        
                                    </>
                                }


                            </p>
                        ))}
                </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
            <div className={styles.boxsc}>
                <div className={styles.add_div}>
                    <h5>Product Price</h5>
                    <button variant='contained' onClick={() => setPrice(true)} className='fill-button-menu'>Add</button>
                </div>
                    {loader.loader ? (
                        <CircularLoader size={60} />
                    ) : printPriceDt && printPriceDt.data.length === 0 ? "------" :
                        printPriceDt && printPriceDt.data.map(val => (
                            <p className={styles.detail_para}>
                                {category.edit && val._id === name._id ?
                                    <input className='form-control' value={name.name} type="number" onChange={handleChange} variant='outlined' ></input>
                                    : <Tooltip title={val.price} arrow
                                    PopperProps={{
                                        modifiers: [
                                          {
                                            name: 'offset',
                                            options: {
                                              offset: [0, -13], // Adjust the vertical space (4px here)
                                            },
                                          },
                                        ],
                                      }}
                                    ><span className={styles.menpr}>{val.price}</span></Tooltip>}
                                {category.edit && val._id === name._id ?
                                    <button className='fill-button-menu' onClick={() => hundleSubmit('editProductPrice', val._id)}>Submit</button> :
                                    <>
                                    <div>
                                    <IconButton
                                            onClick={() => handleEditdata("edit", val.price, val._id)}
                                        >
                                            <FiEdit3 className={styles.editicon} />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleDelete("product_price", val._id)}
                                        // sx={{ ml: 1 }}
                                        >
                                            <FiTrash2 className={styles.deleicon}/>
                                        </IconButton>
                                    </div>
                                        
                                    </>
                                }


                            </p>
                        ))}
                </div>
            </Grid>
            </Grid>
            </div>


        </div>
    );
}

export default PrintDetailpage;
