import React, { useEffect, useRef, useState } from "react";
import styles from "./MyAccount.module.css";
import { Button, Grid, TextField } from "@mui/material";
import Uploadpic from "../../../../../gallery/pages/editProfile/commonEditprofile/uploadPic/Uploadpic";
import Commoninputs from "../../../../../gallery/pages/editProfile/commonEditprofile/inputs/Commoninputs";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { buyerProfileUpdate, handleFetchBuyerDetail } from "../../../../../State/api/userApi";
import EditUserProfile from "./editProfile/EditUserProfile";
import { AlertsSuccess, Errornotify } from "../../../../../common/alert/Alert";
import Enterdetails from "../../../List/Popupdelete/enterDetails/Enterdetails";
import { handlechangePassword } from "../../../../../State/api/superadminApi";
import serverimage from "../../../../../serverConstant";
import PhoneInput from "react-phone-input-2";

const placeholder = {
  placeholder: "upload new pic",
};


export default function MyAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passpop, setPasspop] = useState(false);
  const userDetail = useSelector((state) => state.buyerdetail.data);
  const [edit, setEdit] = useState();
  const [updateProfile, setUpdateProfile] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    language: "",
    image: "",
    address: {
      flat_no: "",
      city: "",
      state: "",
      country: "",
      nationality: "",
    },
    address_id: "",
  });
  const [src, setSrc] = useState({
    image: userDetail ? userDetail.buyer?.image : "",
  });
  const handleChangeImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      const imgs = URL.createObjectURL(e.target.files[0]);
      setSrc({ ...src, image: imgs });
      setUpdateProfile({ ...updateProfile, image: img });
    }
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    dispatch(handleFetchBuyerDetail());
  }, []);

  const changePassword = (pass) => {
    if (pass.password !== pass.confirmpassword) {
      Errornotify("Please fill same password both inputs");
    } else if (pass.password.length < 8) {
      Errornotify("Password must be 8 characters");
    } else {
      dispatch(handlechangePassword({ password: pass.password }, setPasspop));
    }
  };
  useEffect(() => {
    // Update the state with new props if they change
    if (userDetail) {
      const address = userDetail?.buyer?.addresses ?? [];
      const reversedAddresses = [...address].reverse();
      setUpdateProfile((prevState) => ({
        ...prevState,
        first_name: userDetail.data?.first_name || "",
        last_name: userDetail.data?.last_name || "",
        phone: userDetail.data?.phone || "",
        image: userDetail.buyer?.image || "",
        language: userDetail.buyer?.language || "",
        address: {
          flat_no: reversedAddresses[0]?.flat_no || "",
          city: reversedAddresses[0]?.city || "",
          state: reversedAddresses[0]?.state || "",
          country: reversedAddresses[0]?.country || "",
          nationality: userDetail.buyer?.nationality || "", // Added nationality from userDetail
        },
        address_id: reversedAddresses[0]?.id || "",
      }));
      setSrc({
        ...src.image,
        image: userDetail.buyer?.image ? userDetail.buyer?.image : "",
      });
    }
  }, [userDetail]);

  const handleSubmitImage = () => {
    if (updateProfile.image) {
      dispatch(buyerProfileUpdate(updateProfile));
    }
  };
  


  if (edit) {
    return (
      <EditUserProfile
        userDetail={userDetail}
        setEdit={setEdit}
        updateProfile={updateProfile}
        setUpdateProfile={setUpdateProfile}
        handleButtonClick={handleButtonClick}
        handleChangeImage={handleChangeImage}
        fileInputRef={fileInputRef}
        src={src.image}
        setOpen={setPasspop}
      />
    );
  } else {
    return (
      <div className={styles.mains}>
        <AlertsSuccess />
        {passpop ? (
          <Enterdetails
            changePassword={changePassword}
            open={passpop}
            setOpen={setPasspop}
            poptype="newpassword"
          />
        ) : (
          ""
        )}
        <div className={styles.components}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 12, sm: 12, md: 12 }}
          >
            <Grid item xs={12} sm={4} md={3}>
              <div className={styles.upload_pic_div} >
                <Uploadpic
                  onClick={handleButtonClick}
                  src={src.image}
                  val={placeholder}
                  edit="edit"
                  setEdit={setEdit}
                  handleButtonClick={handleButtonClick}
                  handleChangeImage={handleChangeImage}
                  fileInputRef={fileInputRef}
                  type="buyer"
                />
              </div>
              <div className={styles.btn_div}>
                {!src.image ? 
                <Button variant='contained'
                  onClick={handleButtonClick}
                  sx={{
                    width: '200px',
                    height: '35px',
                    borderRadius: '10px',
                    mt: 3,
                    backgroundColor: "#DCDCDC",
                    color: 'black'
                  }} >
                  Upload Image
                </Button>:null}

 
      
      {src.image && (
        <Button 
          variant="contained"
          sx={{ width: '200px', height: '35px', borderRadius: '10px', mt: 3, ml: 2, backgroundColor: "#4CAF50", color: 'white' }}  
          onClick={handleSubmitImage}
        >
          Submit Image
        </Button>
      )}
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleChangeImage}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <div className={styles.editicon}>
                <FiEdit
                  style={{ cursor: "pointer", fontSize: "18px" }}
                  onClick={() => setEdit(true)}
                />
              </div>
              <div className={styles.inputs}>
                {/* <Commoninputs input={input} /> */}
                <div className="form-group">
                  <label>First Name *</label>
                  <input
                    className="form-control"
                    variant="outlined"
                    type="text"
                    name="bank_name"
                    value={userDetail && userDetail.data.first_name}
                    placeholder="Axis Bank"

                  />
                </div>
                <div className="form-group">
                  <label>Last Name *</label>
                  <input
                    className="form-control"
                    variant="outlined"
                    type="text"
                    name="bank_name"
                    value={userDetail && userDetail.data.last_name}
                    placeholder="Axis Bank"

                  />
                </div>
                <div className="form-group">
                  <label>Phone *</label>
                  <PhoneInput
                    // className={`${styles.phoneinpu} ${
                    //   props.phone?.length ? styles.disabled : ""
                    // }`}
                    country={"in"}
                    disabled={true}
                    value={userDetail && userDetail.data.phone}
                    disable={true}
                    autoFormat={true}
                    disableSearchIcon={true}
                    variant="contained"
                    countryCodeEditable={false}
                    inputProps={{
                      name: "phone",
                      required: true,
                      // autoFocus: true
                    }}
                    // searchStyle={{ borderColor: 'black', bakgrondColor: 'green' }}
                    // {props.border && props.border === 'applyform' ? : }
                    buttonStyle={
                      // props.border && props.border === 'applyform' ?
                      // {}
                      // :
                      {
                        paddingLeft: "0px",
                        backgroundColor: "white",
                        borderRightHeight: "4px",
                        borderRightWidth: "20px",
                        color: "#636365",
                        border: "1px solid #D0D0D0",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      }
                    }
                    containerStyle={{
                      width: "100%",
                      // border: '1px solid #ababab',
                      borderRadius: "1px",
                    }}
                    // dropdownStyle={{width:'200px',padding:'30px',borderColor:'red'}}
                    inputStyle={
                      //     props.border && props.border === 'applyform' ? {
                      //     // paddingLeft:'78px',
                      //     width: '100%',
                      //     backgroundColor: '#f6f6f6',
                      //     height: '34px',
                      //     outlined: 'true',
                      //     borderRadius: '0px',
                      //     '&:focus': {
                      //         border: '1px solid black'
                      //     },
                      // } :
                      {
                        // paddingLeft:'78px', 
                        width: "100%",
                        backgroundColor: "white",
                        height: "var(--custom-height) !important",
                        color: "#636365",
                        outlined: "true",
                        border: "1px solid #D0D0D0",
                        "&:focus": {
                          border: "1px solid black",
                        },
                      }
                    }
                  // {...props}
                  // onChange={handleChangeNumber}
                  />
                </div>
                <div className="form-group">
                  <label>Email *</label>
                  <input
                    className="form-control"
                    variant="outlined"
                    type="email"
                    name="bank_name"
                    value={userDetail && userDetail.data.email}
                    placeholder="Axis Bank"

                  />
                </div>
                <div className="form-group">
                  <div className={styles.password_label}>
                    <label>Password *</label>
                  </div>
                  <input
                    className="form-control"
                    variant="outlined"
                    type="password"
                    name="pass"
                    value={localStorage.getItem("password")}
                    placeholder="**********"

                  />
                </div>
                <div className="form-group">
                  <label>Language *</label>
                  <input
                    className="form-control"
                    variant="outlined"
                    type="text"
                    name="Language"
                    value={userDetail && userDetail.buyer.language}
                    placeholder="Language"

                  />
                </div>
              </div>
            </Grid>
          </Grid>
          {/* <div className={styles.btn_div1}>
          <Button variant='contained' sx={{ width: '120px', height: '54px', borderRadius: '10px', mt: 2 }}>Save</Button>
        </div> */}
        </div>
      </div>
    );
  }
}
