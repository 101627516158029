import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React from "react";
import styles from './Fivecolumntable.module.css'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

export default function TableForInquiry(props) {
  console.log(props, 'props'); 
  const navigate = useNavigate();
  const location = useLocation();
  const handleinquirylinkbutton = (id) => {
    if (location.pathname === "/userinquiry") {
      navigate("/userinquiry/inquirtdetails", {
        state: { id: id },
      })
    }
  };

  return (
    <div className={styles.mains}>
      <TableContainer component={Paper} sx={{ backgroundColor: "#F6F6F6", color: "black" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  className={styles.table_head}
                  align={props.head.length - 1 === i ? "left" : "left"}
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "15px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : ( */}
          <TableBody>
            {props.data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}> No Data Found</Box>
                </TableCell>
              </TableRow>
            ) : (
              props.data &&
              props.data.map((row) => (
                <TableRow
                  style={{ color: "#636365", height: "30px" }}
                  className={styles.table_row}
                  key={row.id}>
                  <TableCell
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#636365",
                      whiteSpace: "nowrap",
                    }}
                    style={{ cursor: "pointer" }}
                    className={styles.table_column}
                    align="left">
                    {row?.enquiry_id}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="left">
                    {row?.customer_name}
                  </TableCell>
                  
                  {props?.activetab === "buyer_enquiry" ?
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_columns}
                      align="left">
                     <img
                     alt=""
                     src={row?.item_id?.image}
                     style={{
                      height:"100px",
                      width:"100px",
                      borderRadius:"5px",
                      cursor:"pointer"
                    }}
                     />
                    </TableCell> : null
                  }

                  <TableCell
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="left"
                    style={props.activetab === "gallery_enquiry" ? { width: "30%" } : {}}>
                    {moment(row?.date)?.format("DD-MM-YYYY")}
                  </TableCell>
                  {!props?.activetab === "buyer_enquiry" ?
                    <TableCell
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_columns}
                      align="left">
                      {location.pathname === "/exhibition" ? row.start_date : null}
                    </TableCell> : null
                  }

                  <TableCell
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: row?.is_open === "Open" || "open" ? "#219653" : "#D00000",
                    }}
                    className={styles.table_column}
                    align="left">
                    {row?.is_open}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                    className={styles.table_column}
                    align="left">
                    <VisibilityIcon
                      onClick={() =>
                        handleinquirylinkbutton(row?._id)
                      }
                      style={{ cursor: "pointer" }}
                      aria-label="View Details"
                    />
                  </TableCell>

                </TableRow>
              ))
            )}
          </TableBody>

        </Table>
      </TableContainer>
    </div>
  );
}
