import { Alert, Collapse, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Tablesfour from "../../../components/Pages/List/Table/Fourcolumn/Tables";
import Fillexshibition from "../../../gallery/components/popups/exshibition/Fillexshibition";
import Commonauctiongallery from "../../../gallery/pages/auction/common/Commonauctiongallery";
import styles from "./Auctionartist.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { setAuctionData } from "../../../State/Features/superadmin/auctionSlice";
import {
  fetchAuctionData,
  fetchAuctionForArtistAndGallery,
} from "../../../State/api/superadminApi";
import { useDispatch, useSelector } from "react-redux";
import Fourcoltable from "../../commonTables/Fourcol/Fourcoltable";
import { OpenInBrowserTwoTone } from "@material-ui/icons";
import { handleAddArtAuction } from "../../../State/api/artistapi";
import { AlertsSuccess } from "../../../common/alert/Alert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Pagination, PaginationItem } from "@mui/material";
import { validationForAuctionArts } from "../../../validation/validationForAuctionArts";
const moreOptions = [
  {
    id: "live_auction_gallery",
    label: "Live",
    value: "live_auction_gallery",
  },
  {
    id: "upcoming_auction_gallery",
    label: "Upcoming",
    value: "upcoming_auction_gallery",
  },

  {
    id: "past_auction_gallery",
    label: "Past",
    value: "past_auction_gallery",
  },
];

const approvedhead = [
  {
    id: "sl_no ",
    label: "Sl. No",
    align:"center"
  },
  {
    id: "artist_name ",
    label: "Auction's Title",
    align:"left"
  },
  {
    id: "artist_name ",
    label: "Created Date",
    align:"center"
  },

  {
    id: "location",
    label: "Start Date",
    align:"center"
  },
  {
    id: "like",
    label: "End Date",
    align:"center"
  },
  {
    id: "like",
    label: "Application End Date",
    align:"center"
  },
  {
    id: "like",
    label: "Action",
    align:"center"
  },
];
const approvedheadPast = [
  {
    id: "sl_no ",
    label: "Sl. No",
    align:"center"
  },
  {
    id: "artist_name ",
    label: "Auction's Title",
  },
  {
    id: "artist_name ",
    label: "Created Date",
    align:"center"
  },

  {
    id: "location",
    label: "Start Date",
    align:"center"
  },
  {
    id: "like",
    label: "End Date",
    align:"center"
  },
  {
    id: "like",
    label: "Application End Date",
    align:"center"
  },
];
const sortdata = [
  { label: "By Start Date ↓", value: "low" },
  { label: "By Start Date ↑", value: "high" },
  { label: "By End Date ↓", value: "endlow" },
  { label: "By End Date ↑", value: "endhigh" },
];
const filterdata = [
  { label: "Free", value: "free" },
  { label: "Paid", value: "paid" },
  //  { label: "Rejected", value: "rejected" },
];
export default function Auctionartist() {
  const [errors, setErrors] = useState({});
  const [activetab, setAcitvetab] = useState("live_auction_gallery");
  console.log(activetab, "activetab");
  const [alertopen, setAlertopen] = React.useState(true);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({ is_accepted: "" });
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  });
  const [addArtinExhibition, setAddArtinExhibition] = useState([{
    auction_id: "",
    item_id: "",
    item_price: "",
    item_description: "",
  }]);
  const dispatch = useDispatch();

  const auctiondata = useSelector((state) => state.auctionData.data);
  console.log(auctiondata, "auctiondata");
  const handleMorecategories = (e) => {
    setAcitvetab(e);
  };

  useEffect(() => {
    dispatch(
      fetchAuctionForArtistAndGallery({
        event:
          activetab === "live_auction_gallery"
            ? "live"
            : activetab === "upcoming_auction_gallery"
            ? "upcoming"
            : activetab === "past_auction_gallery"
            ? "past"
            : "upcoming_auction_gallery",
      })
    );
  }, [activetab]);

  const handleFilter = (event) => {
    const value = event.target.value;
    setCondition({ ...condition, filter: value });
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    setCondition({ ...condition, search: value });
  };

  const handleSorting = (e) => {
    let value = e.target.value;
    if (value === "low") {
      setCondition({ ...condition, sorting: value });
      dispatch(
        setAuctionData({
          data:
            auctiondata.data &&
            [...auctiondata.data].sort((a, b) => {
              if (a.start_date.toLowerCase() < b.start_date.toLowerCase())
                return -1;
            }),
          totalCount: auctiondata.totalCount,
        })
      );
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value });
      dispatch(
        setAuctionData({
          data:
            auctiondata.data &&
            [...auctiondata.data].sort((a, b) => {
              if (a.start_date.toLowerCase() > b.start_date.toLowerCase())
                return -1;
            }),
          totalCount: auctiondata.totalCount,
        })
      );
    } else if (value === "endlow") {
      setCondition({ ...condition, sorting: value });
      dispatch(
        setAuctionData({
          data:
            auctiondata.data &&
            [...auctiondata.data].sort((a, b) => {
              if (a.end_date.toLowerCase() < b.end_date.toLowerCase())
                return -1;
            }),
          totalCount: auctiondata.totalCount,
        })
      );
    } else if (value === "endhigh") {
      setCondition({ ...condition, sorting: value });
      dispatch(
        setAuctionData({
          data:
            auctiondata.data &&
            [...auctiondata.data].sort((a, b) => {
              if (a.end_date.toLowerCase() > b.end_date.toLowerCase())
                return -1;
            }),
          totalCount: auctiondata.totalCount,
        })
      );
    } else {
      dispatch(fetchAuctionData(data));
      setCondition({ ...condition, sorting: value });
    }
  };

  console.log("addArtinExhibition",addArtinExhibition);
  
  const handleAdd = () => {
    let auctionErrors=[];
    addArtinExhibition.forEach((artwork,index)=>{
      const {isValid,auctionErrors:errors}=
      validationForAuctionArts(artwork)
      if(!isValid){
        auctionErrors[index] = errors;
      }
    })
    if(auctionErrors.length === 0){
      dispatch(handleAddArtAuction(addArtinExhibition,setOpen))
      setOpen(false);
    }else{
      setErrors(auctionErrors);
    }
  
    // const { isValid, auctionErrors } =
    //   validationForAuctionArts(addArtinExhibition);
    // if (isValid) {
    //   dispatch(handleAddArtAuction(addArtinExhibition, setOpen));
    //   setOpen(false);
    // } else {
    //   setErrors(auctionErrors);
    // }
  };

  const [ExshibitionId, setExshibitionId] = useState({});
  const handleInterested = (id) => {
    setAddArtinExhibition(
      addArtinExhibition.map((item)=>({
        ...item,
        auction_id:id,
      }))
    )
    setExshibitionId(id)
    setOpen(true);
    // setAddArtinExhibition({ ...addArtinExhibition, auction_id: id });
    // setOpen(true);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust how many items you want per page

  // Function to handle page changes
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    window.scrollTo({
      top: 0, // Scroll to the top of the page
      behavior: "smooth", // Smooth scrolling effect
    });
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = auctiondata?.data?.slice(startIndex, endIndex);
  console.log(currentItems, "currentItems");
  const safeItemsPerPage = Array.isArray(currentItems) ? currentItems : [];
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Fillexshibition
        ExshibitionId={ExshibitionId}
          addArtinExhibition={addArtinExhibition}
          setAddArtinExhibition={setAddArtinExhibition}
          text="Auction"
          open={open}
          setOpen={setOpen}
          handleAdd={handleAdd}
          errors={errors}
        />
      ) : (
        ""
      )}

      <Commonauctiongallery
        handleFilter={handleFilter}
        handleSorting={handleSorting}
        filterdata={filterdata}
        sortdata={sortdata}
        condition={condition}
        search={condition.search}
        handleSearch={handleSearch}
        setAcitvetab={setAcitvetab}
        activetab={activetab}
        button=""
        moreOptions={moreOptions}
        handleMorecategories={handleMorecategories}
      />
      {activetab === "upcoming_auction_gallery" ? (
        <Fourcoltable
          handleInterested={handleInterested}
          activetab={activetab}
          head={approvedhead}
          data={safeItemsPerPage
            .filter((val) =>
              val.name.toLowerCase().includes(condition.search.toLowerCase())
            )
            .filter((val) =>
              condition.filter === "free"
                ? !val.is_paid
                : condition.filter === "paid"
                ? val.is_paid
                : true
            )
            .sort((a, b) => {
              // Apply sorting based on the selected condition
              const sorting = condition.sorting || "default"; // Default condition if not set

              switch (sorting) {
                case "low":
                  return new Date(a.start_date) - new Date(b.start_date); // Start date ascending
                case "high":
                  return new Date(b.start_date) - new Date(a.start_date); // Start date descending
                case "endlow":
                  return new Date(a.end_date) - new Date(b.end_date); // End date ascending
                case "endhigh":
                  return new Date(b.end_date) - new Date(a.end_date); // End date descending
                default:
                  return new Date(b.start_date) - new Date(a.start_date); // Default: start_date descending
              }
            })}
        />
      ) : activetab === "past_auction_gallery" ? (
        <Fourcoltable
          activetab={activetab}
          head={approvedheadPast}
          data={safeItemsPerPage
            .filter((val) =>
              val.name.toLowerCase().includes(condition.search.toLowerCase())
            )
            .filter((val) =>
              condition.filter === "free"
                ? !val.is_paid
                : condition.filter === "paid"
                ? val.is_paid
                : true
            )
            .sort((a, b) => {
              // Apply sorting based on the selected condition
              const sorting = condition.sorting || "default"; // Default condition if not set

              switch (sorting) {
                case "low":
                  return new Date(a.start_date) - new Date(b.start_date); // Start date ascending
                case "high":
                  return new Date(b.start_date) - new Date(a.start_date); // Start date descending
                case "endlow":
                  return new Date(a.end_date) - new Date(b.end_date); // End date ascending
                case "endhigh":
                  return new Date(b.end_date) - new Date(a.end_date); // End date descending
                default:
                  return new Date(b.start_date) - new Date(a.start_date); // Default: start_date descending
              }
            })}
        />
      ) : activetab === "live_auction_gallery" ? (
        <Fourcoltable
          handleInterested={handleInterested}
          activetab={activetab}
          head={approvedhead}
          data={safeItemsPerPage
            .filter((val) =>
              val.name.toLowerCase().includes(condition.search.toLowerCase())
            )
            .filter((val) =>
              condition.filter === "free"
                ? !val.is_paid
                : condition.filter === "paid"
                ? val.is_paid
                : true
            )
            .sort((a, b) => {
              // Apply sorting based on the selected condition
              const sorting = condition.sorting || "default"; // Default condition if not set

              switch (sorting) {
                case "low":
                  return new Date(a.start_date) - new Date(b.start_date); // Start date ascending
                case "high":
                  return new Date(b.start_date) - new Date(a.start_date); // Start date descending
                case "endlow":
                  return new Date(a.end_date) - new Date(b.end_date); // End date ascending
                case "endhigh":
                  return new Date(b.end_date) - new Date(a.end_date); // End date descending
                default:
                  return new Date(b.start_date) - new Date(a.start_date); // Default: start_date descending
              }
            })}
        />
      ) : (
        <Fourcoltable
          handleInterested={handleInterested}
          activetab={activetab}
          head={approvedhead}
          data={auctiondata?.data
            ?.filter((val) => {
              const currentDate = new Date();
              const startDate = new Date(val.start_date);
              const endDate = new Date(val.end_date);

              // Combine both conditions using logical AND
              return (
                // Condition 1: Upcoming exhibitions
                startDate <= currentDate &&
                endDate >= currentDate &&
                // Condition 2: Start date substring and name search
                new Date(val.start_date.substring(0, 10)) < currentDate &&
                val.name.toLowerCase().includes(condition.search.toLowerCase())
              );
            })
            ?.sort((a, b) => {
              // Sort by start_date in descending order
              const dateA = new Date(a.start_date);
              const dateB = new Date(b.start_date);
              return dateB - dateA; // Descending order
            })}
        />
      )}
      <Box className={styles.pagination_box} sx={{}}>
        <Pagination
          count={Math.ceil(auctiondata?.data?.length / itemsPerPage)} // Total pages
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          renderItem={(item) => (
            <PaginationItem
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "black", // Set the selected background to black
                  color: "white", // Ensure the text color is visible
                  "&:hover": {
                    backgroundColor: "black", // Maintain black on hover
                  },
                },
              }}
              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...item}
            />
          )}
        />
      </Box>
      {/* {activetab === "live_auction_gallery" ? (
        <Collapse in={alertopen} sx={{ my: 8 }}>
          <Alert
            icon={false}
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertopen(false);
                }}>
                <CloseIcon fontSize="inherit" sx={{ color: "#EF401A" }} />
              </IconButton>
            }
            sx={{
              mb: 2,
              color: "#EF401A",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "21px",
              backgroundColor: "rgba(234, 84, 85, 0.12)",
              borderRadius: "4px",
            }}>
            You've Already Been a Part of 4 Auction This Year So Far, To Be a Part of Further Action
            Please <u>Upgrade</u> Your Plan for The Same
          </Alert>
        </Collapse>
      ) : (
        ""
      )} */}
    </div>
  );
}
