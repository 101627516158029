import validator from "validator"
import isEmpty from "./isEmpty"

// Link validation utility
const linkvalidation = (link) => {
  // Check if the link is empty or null
  if (!link || link.trim() === "") {
    return true; 
  }

 
  const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w.-]*)*\/?$/;
  return !urlRegex.test(link); 
};

export const validatePrssrelease = (press) => {

  let pressErrors = {}

    // Function to check if ReactQuill content is empty
    const isContentEmpty = (content) => {
      // Remove all HTML tags, whitespace, and non-visible characters
      const strippedContent = content.replace(/<[^>]*>?/gm, "").trim();
      return strippedContent.length === 0;
    };

  if (validator.isEmpty(press.title)) {
    pressErrors.title = "Title is required"
  }

  // if (
  //   !press.item_type_id ||
  //   press.item_type_id === null ||
  //   press.item_type_id === undefined
  // ) {
  //   pressErrors.item_type_id = "Press Type is required"
  // }


  if (isContentEmpty(press.description || "")) {
    pressErrors.description = "Description is required";
  }
  
  if (validator.isEmpty(press.press_type_id || '')) {
    pressErrors.press_type_id = "Press type is required"
  }

  if (linkvalidation(press.link)) {
    pressErrors.link = "A valid link is required";
  }
  if (!press.image || press.image === null) {
    pressErrors.image = "Image is required"
  }

  return {
    pressErrors,
    isValid: isEmpty(pressErrors),
  }
}
