import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Container
} from "@mui/material";
import React, { useState } from "react";
import Formsubmission from "../../../components/popups/formSubmit/Formsubmission";
import styles from "./Galleryapplyform.module.css";
import homeImage from "../../../../constant";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../../components/Pages/Header/Header";
import Navbar from "../../../../components/Pages/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { fetchCategoryData } from "../../../../State/api/userApi";
import { updateGalleryProfile } from "../../../../State/api/galleryApi";
import { fetchCategories } from "../../../../State/api/superadminApi";
import { Errornotify } from "../../../../common/alert/Alert";
import { ToastContainer } from "react-toastify";
import { validationForGalleryApplyform } from "../../../../validation/validationForGalleryApplyform";
import ReactQuill from "react-quill";
import { FiUpload } from "react-icons/fi";

export default function Galleryapplyform({
  apply,
  setApply,
  handleSelectCheckbox,
  handleChangeApplyData,
  handleChangeSocialLinksGallery,
  handleChangeTextEditor,
  handleImageChangeMultipleGallery,
  handleRemoveImageGallery,
  selectedFilesGallery,
}) {
  const [image, setImage] = useState("");
  const dispatch = useDispatch();
  const [src, setSrc] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const allcategory = useSelector((state) => state.allcategoryData.data);
  const apply_category_id =
    apply.category_id && apply.category_id.map((val) => val._id);

  // const handleChangeImage = (e) => {

  //   if (e.target.files && e.target.files[0]) {
  //     const img = e.target.files[0];
  //     const imgs = URL.createObjectURL(e.target.files[0]);
  //     setImage(e.target.files[0]);
  //     setSrc(imgs);

  //   }
  // };

  useEffect(() => {
    dispatch(fetchCategoryData({}));
    // if (
    //   apply.portfolio_file === "" ||
    //   apply.portfolio === "" ||
    //   apply.best_work === "" ||
    //   apply.exp === "" ||
    //   apply.degree_year === 0 ||
    //   apply.description.length === 0 ||
    //   apply.art_price_range.length === 0 ||
    //   apply.arts_solds_in_past_12_months === 0 ||
    //   apply.award === "" ||
    //   apply.other_URL === "" ||
    //   apply.category_id.length === 0
    // ) {
    //   // navigate("/artsmiley/gallery/fillquestion");
    // }
  }, []);

  const categorydt = useSelector((state) => state.categoryData.data);
  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  const checkboxdt = [
    {
      id: 1,
      label: "< $250",
    },
    {
      id: 2,
      label: "$250-$1000",
    },
    {
      id: 1,
      label: "$1000-$2500",
    },
    {
      id: 1,
      label: "$2500-5000",
    },
    {
      id: 1,
      label: "$5000-10000",
    },
    {
      id: 1,
      label: ">$10000",
    },
  ];

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
  ];
  const [galleryError, setGalleryError] = useState({});
  console.log("gallery apply",apply?.best_work);
  const [othersReasonCategory, setOthersReasonCategory] = useState(
    sessionStorage.getItem("othersReasonCategory") || ""
  );

  const youtubeRegex = /^(https?:\/\/)?(www\.)?youtube\.com\/.+$/;

  const linkedinRegex =
    /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|company)\/[a-zA-Z0-9_-]+(\/[a-zA-Z0-9_-]+)*\/?(\/\?.*)?$/;

  const instagramRegex =
    /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_.]+(\/?.*)?$/;

  const twitterRegex = /^(https?:\/\/)?(www\.)?x\.com\/[a-zA-Z0-9_]+(\/?.*)?$/;

  const facebookRegex =
    /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.-]+(\/?.*)?$/;

  const handleChangeImage = (e) => {
    const name = e.target.name;
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      const imgs = URL.createObjectURL(e.target.files[0]);

      if (name === "portfolio_file") {
        const validTypes = [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ]; // pdf and word doc types

        // Check file size (1MB = 1048576 bytes)
        // const maxSize = 2 * 1048576; // 2MB

        if (!validTypes.includes(img.type)) {
          Errornotify("Please upload a PDF or Word document.");
          e.target.value = ""; // Clear the input
        } else if (img.size > 10 * 1024 * 1024) {
          Errornotify("File size should be less than or equal to 10 MB");
          e.target.value = ""; // Clear the input
        } else {
          setApply({ ...apply, portfolio_file: img });
        }
      }

      if (name === "best_work") {
        if (img.type !== "image/png" && img.type !== "image/jpeg") {
          Errornotify("Please upload an image in png/jpeg format");
          e.target.value = ""; // Clear input field for invalid img type
        } else {
          setApply({ ...apply, best_work: img });
          // setImageBestWorks(img);
        }
      }
      setImage(e.target.files[0]);
    }
  };

  const handleCategoryChange = (event) => {
    const { value, checked } = event.target;
    const updatedCategoryId = [...apply.category_id];

    if (checked) {
      // Add the item if it is checked and not already in the list
      if (!updatedCategoryId.some((cat) => cat._id === value)) {
        updatedCategoryId.push({ _id: value });
      }
    } else {
      // Remove the item if it is unchecked
      const index = updatedCategoryId.findIndex((cat) => cat._id === value);
      if (index > -1) {
        updatedCategoryId.splice(index, 1);
      }
    }

    // Update state
    setApply((prevState) => ({
      ...prevState,
      category_id: updatedCategoryId,
    }));
  };

  const handleChangeArtPriceRange = (event) => {
    const { value, checked } = event.target;
    const updatedArtPriceRange = [...apply.art_price_range];

    if (checked) {
      // Add the price range if it is checked and not already in the list
      if (!updatedArtPriceRange.includes(value)) {
        updatedArtPriceRange.push(value);
      }
    } else {
      // Remove the price range if it is unchecked
      const index = updatedArtPriceRange.indexOf(value);
      if (index > -1) {
        updatedArtPriceRange.splice(index, 1);
      }
    }

    // Update state
    setApply((prevState) => ({
      ...prevState,
      art_price_range: updatedArtPriceRange,
    }));
  };

  const handleKeywordChangeRemoveCategory = () => {
    sessionStorage.removeItem("othersReasonCategory"); // Remove first from sessionStorage
    setOthersReasonCategory(""); // Update state after removing
  };

  const renderPhotosGallery = (source) => {
    return source.map((photo, index) => {
      return (
        <div className={styles.photoWrapper} key={index}>
          {" "}
          {/* Wrapper div with unique key */}
          <img
            className={styles.imgs}
            src={photo}
            alt=""
            style={{ cursor: "pointer" }}
          />
          <div className={styles.del_img_div}>
            <IconButton onClick={() => handleRemoveImageGallery(index)}>
              <img
                src="/img/delete.png"
                height="15px"
                width="14px"
                alt="delete icon"
              />
            </IconButton>
          </div>
        </div>
      );
    });
  };
  const handleChangeKeywordCategory = (e) => {
    const { value } = e.target;
    // Uncomment this line if you want to add the value on every change

    setOthersReasonCategory(value);
    sessionStorage.setItem("othersReasonCategory", value);
  };
  // const handleKeywordChangeCategory = (event) => {
  //   if (event.key === "Enter" && othersReasonCategory.trim()) {
  //     event.preventDefault();
  //     const newKeyword = othersReasonCategory.trim();

  //     if (othersReasonArrayCategory.length > 0) {
  //       // Update the existing category with the new one
  //       setOtherReasonArrayCategory([newKeyword]);
  //     } else {
  //       // Add the new category for the first time
  //       setOtherReasonArrayCategory([newKeyword]);
  //     }

  //     // Clear the input field
  //     setOthersReasonCategory("");
  //   }
  // };
  return (
    <>
      <Header />
      <Navbar />
      <div className={styles.mains}>
        <Container maxWidth="xl" sx={{
          paddingLeft: { xs: '16px', sm: '24px', md: '60px', lg: '70px', xl: '100px' },
          paddingRight: { xs: '16px', sm: '24px', md: '60px', lg: '70px', xl: '100px' },
        }}>
          <div className={`${styles.text_center} ${styles.max_width1}`}>
            <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
            <h3 className={styles.sec_title}>Join Art Smiley's community of Reputed Galleries</h3>
          </div>

          <Grid container spacing={2} sx={{
            justifyContent: "center",
            alignItems: "center",
          }}>
            <Grid item xs={12} sm={10} md={8}>
              <div className={styles.components}>
                <div>
                  <div className="form-group">
                    <label>
                      Please upload portfolio of your gallery in word document or pdf *
                      <small class="wrtsmall">(Max Size 10MB)</small>
                    </label>
                    <div className={styles.preview_img}>
                      {apply.portfolio_file === "" ? (
                        <div className={styles.img_box}>
                          <div
                            htmlFor="input"
                            style={{ textAlign: "center" }}
                            onClick={handleChangeImage}
                          >
                            <input
                              name="portfolio_file"
                              onChange={handleChangeImage}
                              type="file"
                              id="portfolio_file"
                              hidden
                              accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            />
                            <label htmlFor="portfolio_file" className={styles.labelv}>
                              <FiUpload />
                              <p>Upload Your Portfolio File</p>
                            </label>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className={styles.img_box}>
                            <div
                              htmlFor="input"
                              style={{ textAlign: "center" }}
                              onClick={handleChangeImage}
                            >
                              <input
                                name="portfolio_file"
                                onChange={handleChangeImage}
                                type="file"
                                id="portfolio_file"
                                hidden
                                accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              />
                              <label htmlFor="portfolio_file" className={styles.labelv}>
                                <FiUpload />
                                <p>{apply?.portfolio_file?.name}</p>
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {/* <label htmlFor='input' className='labels' onClick={handleChangeImage}>Upload</label> */}
                    {/* <input type='file' className={styles.input_file} /> */}
                    <div className={styles.file_div}></div>
                    {galleryError?.portfolio_file ? (
                      <small
                        style={{ color: "red", fontWeight: "500", display: "block" }}
                      >
                        {galleryError?.portfolio_file}
                      </small>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label>About gallery *</label>
                    <ReactQuill
                      className="reactQuilStle"

                      value={apply.description}
                      onChange={handleChangeTextEditor("description")}
                      theme="snow"
                      modules={modules}
                      formats={formats}
                    />

                    {galleryError?.description ? (
                      <small
                        style={{ color: "red", fontWeight: "500", }}
                      >
                        {galleryError?.description}
                      </small>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <span class="lablhdng">Professional Details:</span>
                    <label>Please provide details of prestigious exhibitions, awards/prizes, commissions, grants, recognitions etc? *<small class="wrtsmall">Please provide us with the name, the year and the place of each experience.</small></label>
                    <ReactQuill
                      className="reactQuilStle"

                      value={apply.award}
                      onChange={handleChangeTextEditor("award")}
                      theme="snow"
                      modules={modules}
                      formats={formats}
                    />
                    {galleryError?.award ? (
                      <small
                        style={{ color: "red", fontWeight: "500", display: "block" }}
                      >
                        {galleryError?.award}
                      </small>
                    ) : null}
                  </div>
                  {/* <div className="form-group">
                    <label>
                      Please provide us with the name, the year and the place of each
                      experience.
                    </label>
                    <ReactQuill
                      className="reactQuilStle"

                      value={apply.degree_year}
                      onChange={handleChangeTextEditor("degree_year")}
                      theme="snow"
                      modules={modules}
                      formats={formats}
                    />                   
                    {galleryError?.degree_year ? (
                      <small
                        style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
                      >
                        {galleryError?.degree_year}
                      </small>
                    ) : null}
                  </div> */}
                  <div className="form-group">
                    <label>
                      Upload your best artists works *
                      <small className="wrtsmall">(Upload: Max-10 and Min-2 Artworks, File format: PNG/JPG, Max Size: 2MB)</small>
                    </label>
                    {apply.best_work === "" ? (
                      <div className={styles.img_box}>
                        <div
                          htmlFor="input"
                          style={{ textAlign: "center" }}
                          onClick={handleChangeImage}
                        >
                          <input
                            name="best_work"
                            onChange={handleChangeImage}
                            type="file"
                            id="best_work"
                            hidden
                            accept="image/*"
                          />
                          <label htmlFor="best_work" className={styles.labelvl}>
                            <FiUpload />
                            <p>Upload your Best Work</p>
                          </label>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className={styles.img_box}>
                          <div
                            htmlFor="input"
                            style={{ textAlign: "center" }}

                          >
                            <input
                              name="best_work"
                              onChange={handleImageChangeMultipleGallery}
                              type="file"
                              id="best_work"
                              hidden
                              accept="image/*"
                            />
                            <label htmlFor="best_work" className={styles.labelv}>
                              <FiUpload />
                              <p>Upload Your Best Artists Works</p>
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                    <div className={styles.result}>
                      {renderPhotosGallery(selectedFilesGallery)}
                    </div>

                    {galleryError?.best_work ? (
                      <small
                        style={{ color: "red", fontWeight: "500", }}
                      >
                        {galleryError?.best_work}
                      </small>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label className={styles.input_label}>
                      If your gallery is represented by another online platform, Please provide URL links
                    </label>
                    <input
                      className="form-control"
                      name="other_URL"
                      value={apply.other_URL}
                      onChange={handleChangeApplyData}

                    />
                    {galleryError?.other_URL ? (
                      <small
                        style={{ color: "red", fontWeight: "500", }}
                      >
                        {galleryError?.other_URL}
                      </small>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label>
                      Please provide the link to your gallery online portfolio (Gallery Website)
                    </label>

                    <input
                      className="form-control"
                      name="portfolio"
                      value={apply.portfolio}
                      onChange={handleChangeApplyData}
                      variant="standard"

                    />
                    {galleryError?.portfolio ? (
                      <small
                        style={{ color: "red", fontWeight: "500", }}
                      >
                        {galleryError?.portfolio}
                      </small>
                    ) : null}
                  </div>
                  <div>
                    <label>
                      Please provide us the links of your gallery social media platform platform here
                    </label>
                    <Grid
                      container
                      columnSpacing={2}
                    >
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <input
                            onClick={handleChangeApplyData}
                            className="form-control"
                            placeholder="www.linkedin.com/in/example"
                            name="linked_in"
                            error={
                              apply.social_links.linked_in === ""
                                ? false
                                : !apply.social_links.linked_in.match(linkedinRegex)
                            }
                            color={
                              apply.social_links.linked_in.length === 0 ? "" : "success"
                            }
                            onChange={handleChangeSocialLinksGallery}
                            value={apply.social_links.linked_in}

                          />
                          {galleryError?.linked_in && (
                            <small
                              style={{
                                color: "red",
                                fontWeight: "500",
                              }}
                            >
                              {galleryError.linked_in}
                            </small>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <input
                            onClick={handleChangeApplyData}
                            className="form-control"
                            onChange={handleChangeSocialLinksGallery}
                            placeholder="www.facebook.com/example"
                            name="facebook"
                            error={
                              apply.social_links.facebook === ""
                                ? false
                                : !apply.social_links.facebook.match(facebookRegex)
                            }
                            color={apply.social_links.facebook === "" ? "" : "success"}
                            value={apply.social_links.facebook}

                          />
                          {galleryError?.facebook && (
                            <small
                              style={{
                                color: "red",
                                fontWeight: "500",
                              }}
                            >
                              {galleryError.facebook}
                            </small>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <input
                            onClick={handleChangeApplyData}
                            className="form-control"
                            error={
                              apply.social_links.instagram === ""
                                ? false
                                : !apply.social_links.instagram.match(instagramRegex)
                            }
                            color={apply.social_links.instagram === "" ? "" : "success"}
                            placeholder="www.instagram.com/example"
                            name="instagram"
                            onChange={handleChangeSocialLinksGallery}
                            value={apply.social_links.instagram}

                          />
                          {galleryError?.instagram && (
                            <small
                              style={{
                                color: "red",
                                fontWeight: "500",
                              }}
                            >
                              {galleryError.instagram}
                            </small>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <input
                            onClick={handleChangeApplyData}
                            className="form-control"
                            placeholder="www.x.com/example"
                            name="twitter"
                            error={
                              apply.social_links.twitter === ""
                                ? false
                                : !apply.social_links.twitter.match(twitterRegex)
                            }
                            color={apply.social_links.twitter === "" ? "" : "success"}
                            onChange={handleChangeSocialLinksGallery}
                            value={apply.social_links.twitter}

                          />
                          {galleryError?.twitter && (
                            <small
                              style={{
                                color: "red",
                                fontWeight: "500",
                              }}
                            >
                              {galleryError.twitter}
                            </small>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                          <input
                            onClick={handleChangeApplyData}
                            className="form-control"
                            placeholder="www.youtube.com/example"
                            name="youtube"
                            error={
                              apply.social_links.youtube === ""
                                ? false
                                : !apply.social_links.youtube.match(youtubeRegex)
                            }
                            color={apply.social_links.youtube === "" ? "" : "success"}
                            onChange={handleChangeSocialLinksGallery}
                            value={apply.social_links.youtube}

                          />
                          {galleryError?.youtube && (
                            <small
                              style={{
                                color: "red",
                                fontWeight: "500",
                              }}
                            >
                              {galleryError.youtube}
                            </small>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="form-group">
                    <label>Please select the main categories your artists work with? *
                    </label>
                    <div className={styles.main_divs_category}>

                      {allcategory &&
                        [...allcategory?.allcategories]?.sort((a, b) => (a.name === "Other Media" ? 1 : b.name === "Other Media" ? -1 : 0))?.map((item) => (
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="category_id"
                            key={item._id}
                            sx={{ margin: "0px", padding: "0px" }}
                          >
                            <FormControlLabel
                              key={item._id}
                              sx={{
                                backgroundColor: "#f6f6f6",
                                minWidth: "180px",
                                maxWidth: "max-content",
                                borderRadius: "6px",
                                marginBottom: "5px !important",
                                marginLeft: "0 !important",
                                px: 1,
                              }}
                              value={item._id}
                              checked={apply?.category_id.some(
                                (cat) => cat._id === item._id
                              )}
                              onChange={(event) => {
                                if (!event.target.checked) {
                                  sessionStorage.removeItem("othersReasonCategory");
                                  handleKeywordChangeRemoveCategory();
                                }
                              }}
                              name="category_id"
                              control={<Checkbox onChange={handleCategoryChange} />}
                              label={item.name}
                            />
                          </RadioGroup>

                          // </div>
                        ))}

                    </div>
                    {galleryError?.category_id === "At least one category is required" ? (
                      <small
                        style={{ color: "red", fontWeight: "500", }}
                      >
                        {galleryError?.category_id}
                      </small>
                    ) : null}
                    {apply?.category_id.some(
                      (cat) => cat._id === "67334c2511d9938babd097f3"
                    ) ? (
                      // Your conditional rendering here

                      <input
                        className="form-control"
                        value={othersReasonCategory}
                        startAdornment={<InputAdornment position="end">↵</InputAdornment>}
                        // onKeyDown={handleKeywordChangeCategory}
                        onChange={handleChangeKeywordCategory}
                        placeholder="Please specify"
                        type="text"
                      />
                    ) : null}
                    {galleryError?.category_id === "Other category is required" ? (
                      <small
                        style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
                      >
                        {galleryError?.category_id}
                      </small>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label>
                      Please select your artwork selling price range *
                    </label>
                    <div className={styles.main_divs_category}>
                      {checkboxdt &&
                        checkboxdt?.map((item) => (
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="art_price_range"
                            value={apply.art_price_range}
                            key={item.label}
                            onClick={handleSelectCheckbox}
                          >
                            <FormControlLabel
                              name="art_price_range"
                              value={item.label}
                              control={<Checkbox onChange={handleChangeArtPriceRange} />}
                              label={item.label}
                              checked={apply.art_price_range.includes(item.label)}
                              sx={{ px: 2 }}
                            />
                          </RadioGroup>
                        ))}
                    </div>

                    {galleryError?.art_price_range ? (
                      <small
                        style={{ color: "red", fontWeight: "500", }}
                      >
                        {galleryError?.art_price_range}
                      </small>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label>How many artworks have you sold in the past 12 months? *
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="arts_solds_in_past_12_months"
                      value={apply.arts_solds_in_past_12_months}
                      onChange={handleChangeApplyData}

                    />
                    {galleryError?.arts_solds_in_past_12_months ? (
                      <small
                        style={{ color: "red", fontWeight: "500", }}
                      >
                        {galleryError?.arts_solds_in_past_12_months}
                      </small>
                    ) : null}
                  </div>

                </div>




                {/* <div className={styles.checkbox_div_container}>
            <label className={styles.input_label}>
              I confirm that the information given in this form is true,
              complete and accurate.
            </label>
            <div>
           
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                sx={{ display: "flex", flexDirection: "row" }}
                className={styles.radio_button}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
           
            </div>
          </div> */}
                <div className={styles.submit_btn}>
                  <button
                    onClick={() => {
                      const { applyForm, isValidGalleryApplyform } =
                        validationForGalleryApplyform(
                          apply.portfolio_file,
                          apply.description,
                          apply.category_id,
                          apply.art_price_range,
                          apply.arts_solds_in_past_12_months,

                          apply.portfolio,
                          apply.other_URL,
                          apply.online_art_gallary_URL,
                          apply.social_links,
                          apply.award,
                          // apply.degree_year,
                          apply.best_work,
                          othersReasonCategory,
                          selectedFilesGallery
                        );
                      if (isValidGalleryApplyform) {
                        navigate("/gallery/personaldetails");
                      } else {
                        setGalleryError(applyForm);
                      }
                    }}
                    //  onClick={() => navigate("/artsmiley/gallery/personaldetails")}
                    className="apply_btn"
                  >
                    Next
                  </button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>

      </div>
      <ToastContainer />
    </>
  );
}
