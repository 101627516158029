import React from "react";
import Tooltip from "@mui/material/Tooltip";

const TruncatedTooltipText = ({ text, maxLength = 10, href, onClick, style = {} }) => {

  const isTruncated = text.length > maxLength;

  const displayText = isTruncated ? `${text.substring(0, maxLength)}...` : text;

  return (
    <a
      href={href}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      }}
      style={{
        textDecoration: "underline",
        color: "inherit",
        ...style,
      }}
    >
      <Tooltip title={text} arrow disableHoverListener={!isTruncated}>
        <span>{displayText}</span>
      </Tooltip>
    </a>
  );
};

export default TruncatedTooltipText;
