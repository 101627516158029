import React from 'react'
import Commonsidebar from '../../../components/Pages/List/Common/sideBar/Commonsidebar'


const sidebarRoutes = [
    {
        name: 'dashboard',
        icon: 'fa fa-dashboard',
        path: '/dashboard',
        hasSubmenu: false,
    },
    {
        name: 'profile',
        icon: 'fa fa-truck',
        path: '/profile',
        hasSubmenu: false,
    },
    // {
    //     name: 'MANAGE ADDRESS',
    //     icon: 'fa fa-cube',
    //     path: '/manageaddress',
    //     hasSubmenu: false,
    // },
    {
        name: 'arts',
        icon: 'fa fa-users',
        path: '/arts',
        hasSubmenu: false,
    },
    {
        name: 'Upload Arts',
        icon: 'fa fa-cube',
        path: "/upload/stepfirst",
        hasSubmenu: false,
    },
    {
        name: 'exhibition',
        icon: 'fa fa-th-large',
        path: '/exhibitionartist',
        hasSubmenu: false,
    },
    {
        name: 'auctions',
        icon: 'fa fa-cube',
        path: '/auctions',
        hasSubmenu: false,
    },
    {
        name: 'orders',
        icon: 'fa fa-cube',
        path: '/orders',
        hasSubmenu: false,
    },
    {
        name: 'subscriptions',
        icon: 'fa fa-cube',
        path: '/subscriptions',
        hasSubmenu: false,
    },
    {
        name: 'earnings',
        icon: 'fa fa-cube',
        path: '/earnings',
        hasSubmenu: false,
    },
    {
        name: "buyer's offer",
        icon: 'fa fa-cube',
        path: '/buyeroffer',
        hasSubmenu: false,
    },
    {
        name: "Art Smiley Offers",
        icon: 'fa fa-cube',
        path: '/adminoffer',
        hasSubmenu: false,
    },
    {
        name: 'recreate',
        icon: 'fa fa-cube',
        path: '/recreate',
        hasSubmenu: false,
    },
    {
        name: 'quote request',
        icon: 'fa fa-cube',
        path: '/quoterequest',
        hasSubmenu: false,
    },
    {
        name: "Buyer's inquiry",
        icon: 'fa fa-cube',
        path: '/inquiry',
        hasSubmenu: false,
    },
    {
        name: 'my inquiry',
        icon: 'fa fa-cube',
        path: '/myinquiry',
        hasSubmenu: true,
        submenu:[
            {
                name:"My Inquiry",
                path:"/myinquiry"
            },
            {
                name:"Artistinquirtdetails",
                path:"/myinquiry/atristinquirtdetails"
            },
        ]
    },
    {
        name: 'dispute Management',
        icon: 'fa fa-cube',
        path: '/dispute',
        hasSubmenu: false,
    },
    {
        name: 'notifications',
        icon: 'fa fa-cube',
        path: '/notification',
        hasSubmenu: false,
    },
    {
        name: 'testimonial',
        icon: 'fa fa-cube',
        path: '/artisttestimonial',
        hasSubmenu: false,
    },
    {
        name: 'Art Smiley News',
        icon: 'fa fa-cube',
        path: '/news',
        hasSubmenu: false,
    },
]
export default function Sidebarartist() {
    return (
        <>
            <Commonsidebar sidebarRoutes={sidebarRoutes} />
        </>
    )
}
