import validator from "validator"
import isEmpty from "./isEmpty"



export const validationForExhibitionArts = (exhibition) => {
  let exhibitionErrors = {}

  if (validator.isEmpty(exhibition.item_id)) {
    exhibitionErrors.item_id = "Artwork is required"
  }

  if (validator.isEmpty(exhibition.price)) {
    exhibitionErrors.price = "Price is required"
  }
  if (validator.isEmpty(exhibition.description)) {
    exhibitionErrors.description = "Description is required"
  }
  return {
    exhibitionErrors,
    isValid: isEmpty(exhibitionErrors),
  }
}
