import { Button, Grid, Switch } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./Commonnewsgallery.module.css";
import api from "../../../../State/api/Api";
import { Successnotify } from "../../../../common/alert/Alert";
import { ToastContainer } from "react-toastify";

const label = { inputProps: { "aria-label": "Switch demo" } };
export default function Commonnewsgallery(props) {
  const [news, setNews] = React.useState({
    usernews: {
      newsletter: false,
      notification: false,
      events: false,
      offer: false,
      customisedcampaings: false,
      blogs: false,
    },
  });
console.log(news,'news')
  const handleToggle = (field) => (event) => {
    setNews((prevState) => ({
      ...prevState,
      usernews: {
        ...prevState.usernews,
        [field]: event.target.checked, // Update the specific field
      },
    }));
  };

const handleSaveNews = async () => {
    const response = await api.applicationApi("api/user/addusernews", "POST", 
       news.usernews  
    );
     if(response.status === 200 || response.status === 201){
        Successnotify(response.data.message)
    }
}
  const handleGetNews = async () => {
    const { data } = await api.applicationApi("api/user/getusernews", "GET");
    setNews(data);
  };
  useEffect(() => {
    handleGetNews();
  }, []);
  return (
    <div className={styles.mains}>
      <div className={styles.components}>
        <div className={styles.news_heading}>
          <h3>Art Smiley News</h3>
          <hr className={styles.hrs} />
        </div>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          sx={{ my: 1.5, display: "flex", justifyContent: "space-between" }}
          alignItems="center"
          item
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          colgap={1.5}
          columns={{ xs: 12, sm: 12, md: 22 }}
        >
          <Grid item xs={12} sm={6} md={11} className={styles.inputs_gird}>
            <div className={styles.access_roles}>
              <div>News Letter</div>
              <Switch
                checked={news?.usernews?.newsletter}
                {...label}
                onChange={handleToggle("newsletter")}
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "green",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "green",
                  },
                }}
              />
            </div>
            <div className={styles.access_roles}>
              <div>Notifications</div>
              <Switch
               checked={news?.usernews?.notification}
                {...label}
                onChange={handleToggle("notification")}
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "green",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "green",
                  },
                }}
              />
            </div>
            <div className={styles.access_roles}>
              <div>Events</div>
              <Switch
                  checked={news?.usernews?.events}
                  onChange={handleToggle("events")}
                {...label}
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "green",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "green",
                  },
                }}
              />
            </div>
            <div className={styles.access_roles}>
              <div>Offers</div>
              <Switch
               checked={news?.usernews?.offer}
                {...label}
                onChange={handleToggle("offer")}
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "green",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "green",
                  },
                }}
              />
            </div>
            <div className={styles.access_roles}>
              <div>Customized campaigns</div>
              <Switch
                  checked={news?.usernews?.customisedcampaings}
                  onChange={handleToggle("customisedcampaings")}
                {...label}
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "green",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "green",
                  },
                }}
              />
            </div>
            <div className={styles.access_roles}>
              <div>Blogs</div>
              <Switch
                 checked={news?.usernews?.blogs}
                 onChange={handleToggle("blogs")}
                {...label}
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "green",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "green",
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
        <div className={styles.btn_div}>
          <button
          onClick={handleSaveNews}
            className="action_btn"
          >
            Save
          </button>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}
