import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./OrderTable.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CircularLoader from "../../../../../common/loader/Loader";
import serverimage from "../../../../../serverConstant";
import homeImage from "../../../../../constant";
// import profileSlice, { addData } from '../../../../../State/Features/Artist/profileSlice';
import dayjs from "dayjs";
import { BsThreeDotsVertical } from "react-icons/bs";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
export default function OrderTable(props) {
  console.log("rent props =>", props);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ids, setIds] = useState("");
  const [cids, setCids] = useState("");
  const loader = useSelector((state) => state.loader);
  const threedots = Boolean(anchorEl);
  const handleClick = (event) => {
    const id = event.target.id;
    const value = event.target;
    setAnchorEl(event.currentTarget);
    setIds(event.target.id);
  };
  const handleClickCurator = (event) => {
    const id = event.target.id;
    const value = event.target;
    setAnchorEl(event.currentTarget);
    setCids(event.target.id._id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePopup = (e) => {
    props.setOpen && props.setOpen(true);
    props.setPoptype && props.setPoptype("addfeature");
  };
  const handleProfilepage = (id, name, row) => {
    const path = window.location.pathname;
    navigate(`/orders/${id}`);
  };
  const handleProfile = (id) => {
    navigate(`/arts/${id}`);
  };


  
       const [currentPage, setCurrentPage] = React.useState(1);
            const itemsPerPage = 10; // Adjust how many items you want per page
          
            // Function to handle page changes
            const handlePageChange = (event, page) => {
              setCurrentPage(page);
            };
          
            // Slice the data based on current page
            const startIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            const currentItems = props?.data?.slice(startIndex, endIndex);
  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ width: "100%" }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  align="left"
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "15px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                currentItems &&
                currentItems.map((row, i) =>
                  row.items.map((item, i) => (
                    <TableRow
                      value={row.artist_name}
                      style={{ color: "#636365", cursor: "pointer" }}
                      key={i}
                    >
                      <TableCell
                        onClick={() => handleProfilepage(row._id)}
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <span className={styles.hover_text}>
                         {row._id}
                        </span>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {dayjs(item.createdAt).format("DD-MM-YYYY").toString()}
                      </TableCell>

                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="center"
                      >
                        {item.quantity}
                      </TableCell>

                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="center"
                      >
                         {dayjs(item.createdAt).format("DD-MM-YYYY").toString()}
                      </TableCell>
                      {/* 9899323262 */}
                      <TableCell
                        sx={{
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {item.order_type.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "25%",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        ${item.sum_amount}
                      </TableCell>

                      {/* <TableCell
                                            sx={{
                                                width: "25%",
                                                fontFamily: "Roboto",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                fontSize: "16px",
                                                lineHeight: "21px",
                                                color: "#636365",
                                                whiteSpace: "nowrap",
                                            }}
                                            className={styles.table_column}
                                            align="left"
                                        >
                                            <div className={styles.curator_order}>
                                                <span>${row.price}</span>
                                                <Button
                                                    variant="contained"
                                                    sx={{ width: "106px", height: "34px" }}
                                                >
                                                    Track Order
                                                </Button>
                                            </div>
                                        </TableCell> */}

                      {/* </Link> */}
                      <TableCell
                        sx={{
                          width: "25%",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row?.items[0]?.order_status}

                      </TableCell>
                      <TableCell
                        sx={{
                          width: "25%",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        

                        <BsThreeDotsVertical
                          style={{ marginLeft: "5px" }}
                          onClick={() => handleProfilepage(row._id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <> 
      <Box className={styles.pagination_box} sx={{}}>
          <Pagination   
          count={Math.ceil(
            (props?.activetab === "pending" ? props?.datalength : props?.data?.length) / itemsPerPage
          )}
          // count={Math.ceil(props?.data?.length / itemsPerPage)} // Total pages
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        renderItem={(item) => (
          <PaginationItem
          sx={{
            "&.Mui-selected": {
              backgroundColor: "black", // Set the selected background to black
              color: "white", // Ensure the text color is visible
              "&:hover": {
                backgroundColor: "black", // Maintain black on hover
              },
            },
          }}
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )} />
          </Box>
          
      </>
    </div>
  );
}
