import React, { useState } from "react";
import styles from "./Recretepopup.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Button, TextField, Grid } from "@mui/material";
import homeImage from "../../../../constant";
import serverimage from "../../../../serverConstant";
import dateFormat from "../../../../utils/dateFormat";
import { Errornotify } from "../../../../common/alert/Alert";

 

export default function Requestquotepopup(props) {
console.log(props,'props')
  const [amount, setAmount] = useState();
  const handleClose = () => props.setOpen(false);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={props.open}>
          <Box className={styles.modal_del}>
          <Grid
                                container
                                spacing={2}
                              >
                                <Grid item xs={12} sm={4} md={4}>
                                <div className={styles.img_div}>
                <img alt='img' src={ props.acceptPopupData?.item_id?.image} className="img-fluid" />
              </div>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8}>
                                  <div>
                                  <p className={styles.odlist}>Buyer Name : <span>{props.acceptPopupData.first_name} {props.acceptPopupData.last_name}</span></p>
                                  <p className={styles.odlist}>Category : <span>{props.acceptPopupData.item_id?.category_id?.name}</span></p>
                                  <p className={styles.odlist}>Subject : <span>{props.acceptPopupData.item_id?.subject_id?.name}</span></p>
                                  <p className={styles.odlist}>Requested Date : <span>{dateFormat(props.acceptPopupData.createdAt)}</span></p>
                                  
                                  </div>
                                </Grid>
                                </Grid>
            
            <div className={styles.description_div}>
            <p className={styles.odlist}>Message for quote request <br/> <span>{props.acceptPopupData.message}</span></p>
             
            </div>
        
              {localStorage.getItem("user_type") !== "superadmin" && 
              <div className="form-group">
                <label>Quotation</label>
                <input
                className="form-control"
                  fullWidth
                  value={amount}
                  type="number"
                  onChange={(e) => setAmount(e.target.value)}
                  variant="outlined"
                placeholder="Enter amount"
                />
              </div>}
          
            <div className={styles.btn_div}>
              {props.activetab === "galleryrecrete" ? (
                <>
                  <button className="action_btn"
                    onClick={() =>
                      props.handleAcceptRejectRecreate(props.acceptPopupData._id, { is_accepted: "Approved" })
                    }>
                    Accept
                  </button>
                  <button className="action_libtn"
                    onClick={() =>
                      props.handleAcceptRejectRecreate(props.acceptPopupData._id, { is_accepted: "Rejected" })
                    }>
                    Decline
                  </button>
                </>
              ) : props.activetab === 'qouterequest' ? (
                <>
                  <button className="action_btn"
                    onClick={() =>
                   {
                    props.handleAcceptRejectQuate(props.acceptPopupData._id, { is_accepted: "Approved",price: amount })
                     if(!amount) {
                      Errornotify("Please enter amount")
                     }else {
                      handleClose()
                     }
                   }
                    }>
                    Accept
                  </button>
                  <button className="action_libtn"
                    onClick={() =>
                     {
                      props.handleAcceptRejectQuate(props.acceptPopupData._id, { is_accepted: "Rejected" })
                      handleClose()
                     }
                    }>
                    Decline
                  </button>
                </>
              )
                : (
                  <>
                    <button className="action_btn"
                      onClick={() =>
                        props.handleAcceptRejectOffer(props.acceptPopupData._id, { is_accepted: "Approved" })
                      }>
                      Accept
                    </button>
                    <button className="action_libtn"
                      onClick={() =>
                        props.handleAcceptRejectOffer(props.acceptPopupData._id, { is_accepted: "Rejected" })
                      }>
                      Decline
                    </button>
                  </>
                )
              }

            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
