import React, { useState } from "react";
import Questionsui from "../../../../../curator/components/Common/questionUi/Question";
import { useNavigate } from "react-router-dom";
import { AlertsSuccess, Errornotify, Successnotify } from "../../../../../common/alert/Alert";
import { ToastContainer } from "react-toastify";

const inputs = [
  {
    number: 1,
    label: "Please upload portfolio of your gallery in word doucment or pdf.",
    type: "file",
    name: "upload_portfolio",
    placeholder: "Upload your file",
  },
  {
    number: 2,
    label: "About Gallery",
    type: "text",
    name: "about_gallery",
    placeholder: "Enter text",
  },
  {
    number: 3,
    label:
      "Please provide details of prestigious exhibitions, awards/prizes, commissions, grants, recognitions etc?*",
    type: "text",
    name: "awards_etc",
    placeholder: "Enter text",
  },
  {
    number: 4,
    label:
      "Please provide us with the name, the year and the place of each experience.",
    type: "text",
    name: "experience",
    placeholder: " Enter text",
  },
  {
    number: 5,
    label: "Upload your best artist works",
    type: "file",
    name: "artist_work",
    placeholder: "Upload your file",
  },
  {
    number: 6,
    label: "How many years of experience you have ?*",
    type: "text",
    name: "total_experience",
    placeholder: "Enter text",
  },
  {
    number: 7,
    label:
      "If you are represented by another online platform, Please provide URL links:",
    type: "text",
    name: "url_link",
    placeholder: "Enter text",
  },
  {
    number: 8,
    label:
      "Please provide the link to your online portfolio: (Gallery Website)",
    type: "text",
    name: "web_link",
    placeholder: "Enter text",
  },
  {
    number: 9,
    type: "multioptions",
    label:
      "Please provide us the links of your social media platform platform here:",
    name: "multioptions",
    placeholder: "Enter text",
    data: [
      {
        id: 1,
        placeholder: "Enter Facebook",
        type: "text",
        name: "facebook",
      },
      {
        id: 2,
        placeholder: "Enter Linkedin",
        type: "text",
        name: "facebook",
      },
      {
        id: 3,
        placeholder: "Enter Twitter",
        type: "text",
        name: "facebook",
      },
      {
        id: 4,
        placeholder: "Enter Youtube",
        type: "text",
        name: "facebook",
      },
      {
        id: 5,
        placeholder: "Enter Instagram",
        type: "text",
        name: "facebook",
      },
    ],
  },
  {
    number: 10,
    label: "Please select the main categories you work with ? *",
    type: "checkbox",
    name: "checkbox_width_input",
    placeholder: "Enter text",
    data: [
      {
        id: 1,
        label: "Painting",
      },
      {
        id: 2,
        label: "Drawing",
      },
      {
        id: 3,
        label: "Mixed media",
      },
      {
        id: 4,
        label: "Sculpture",
      },
      {
        id: 5,
        label: "Photography",
      },
      {
        id: 6,
        label: "Textile",
      },
      {
        id: 7,
        label: "Installation",
      },
      {
        id: 8,
        label: "NFT",
      },
      {
        id: 9,
        label: "Digital Art",
      },
      {
        id: 10,
        label: "Craft",
      },
      {
        id: 11,
        label: "Others",
      },
    ],
  },
  {
    number: 11,
    label: "Please select your artwork selling price range:",
    type: "checkbox",
    name: "checkbox",
    placeholder: "Enter text",
    data: [
      {
        id: 1,
        label: "< $250",
      },
      {
        id: 2,
        label: "$250-$1000",
      },
      {
        id: 1,
        label: "$1000-$2500",
      },
      {
        id: 1,
        label: "$2500-5000",
      },
      {
        id: 1,
        label: "$5000-10000",
      },
      {
        id: 1,
        label: ">$10000",
      },
    ],
  },
  {
    number: 12,
    label: "How many artworks have you sold in the past 12 months? *",
    type: "text",
    name: "web_link",
    placeholder: "Enter text",
  },
  {
    number: 12,
    label:
      "I confirm that the information given in this form is true, complete and accurate. *",
    type: "radio",
    name: "radio",
    placeholder: "Enter text",
    data: [
      {
        id: 1,
        label: "Yes",
      },
      {
        id: 2,
        label: "No",
      },
    ],
  },
];
const checkboxdt = {
  step11: [
    {
      id: 1,
      label: "< $250",
    },
    {
      id: 2,
      label: "$250-$1000",
    },
    {
      id: 1,
      label: "$1000-$2500",
    },
    {
      id: 1,
      label: "$2500-5000",
    },
    {
      id: 1,
      label: "$5000-10000",
    },
    {
      id: 1,
      label: ">$10000",
    },
  ],
};
export default function Fillquestiongallery({
  handleChangeApplyData,
  apply,
  setApply,
  handleSelectCheckbox,
  handleChangeSocialLinks,
  handleChangeImage,
  handleChangeTextEditor,
  handleImageChangeMultipleGallery,
  handleRemoveImageGallery,
  selectedFilesGallery,
}) {
  const [steps, setSteps] = useState({ step: "1",stepper: Math.floor((100 / 12) * 1) });
  const navigate = useNavigate();

  const handleStepsBack = (num) => {
    if(num === "1") {
      setSteps({ step: "1", stepper: (100 / 8) * 1 });
    }else if(num === "2") {
      setSteps({ step: "2", stepper: (100 / 8) * 2 });
    }else if(num === "3") { 
      setSteps({ step: "3", stepper: (100 / 8) * 3 });
    }else if(num === "4") {
      setSteps({ step: "4", stepper: (100 / 8) * 4 });
    }else if(num === "5") {
      setSteps({ step: "5", stepper: (100 / 8) * 5 });
    }else if(num === "6") {
      setSteps({ step: "6", stepper: (100 / 8) * 6 });
    }else if(num === "7") {
      setSteps({ step: "7", stepper: (100 / 8) * 7 });
    }else if(num === "8") {
      setSteps({ step: "8", stepper: (100 / 8) * 8 });
    }else if(num === "9") {
      setSteps({ step: "9", stepper: (100 / 8) * 9 });
    }
  }
  const handleSteps = () => {
    const regex =
      /^(https:\/\/www\.|http:\/\/www\.|www\.|https:\/\/|)[a-zA-Z0-9\-_$]+\.[a-zA-Z]{2,3}(\/[a-zA-Z0-9\-_$]{0,}|){0,}$/g;
    // const regex = (https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?\/[a-zA-Z0-9]{2,}
    // const regex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
    // const regex = (https:\/\/www\. |http:\/\/www\. |https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.
      
    if (steps.step === "1" ) {
      
        setSteps({ step: "2", stepper: (100 / 8) * 2 });
     
    } else if (steps.step === "2" ) {
      setSteps({ step: "3", stepper: (100 / 8) * 3 });
    } else if (steps.step === "3") {
      setSteps({ step: "4", stepper: (100 / 8) * 4 });
    } else if (steps.step === "4") {
      setSteps({ step: "5", stepper: (100 / 8) * 5 });
    } else if (steps.step === "5" ) {
      setSteps({ step: "6", stepper: (100 / 8) * 6 });
    } else if (steps.step === "6") {
     
      setSteps({ step: "7", stepper: (100 / 8) * 7 });
    } else if (steps.step === "7" ) {
       setSteps({ step: "8", stepper: (100 / 8) * 8 })
    }
      else {
      navigate("/gallery/applyform");
    } 
  };
  return (
    <div>
      <Questionsui
        handleChangeSocialLinks={handleChangeSocialLinks}
        checkboxdt={checkboxdt}
        handleSelectCheckbox={handleSelectCheckbox}
        steps={steps}
        apply={apply}
        setApply={setApply}
        handleSteps={handleSteps}
        // inputs={inputs}
        handleChangeApplyData={handleChangeApplyData}
        type="gallery"
      handleStepsBack={handleStepsBack}
      handleChangeTextEditor={handleChangeTextEditor}
      handleImageChangeMultipleGallery={handleImageChangeMultipleGallery}
      handleRemoveImageGallery={handleRemoveImageGallery}
      selectedFilesGallery={selectedFilesGallery}
      />
      <ToastContainer />
    </div>
  );
}
