import React, { useEffect, useState } from "react";
import styles from "./Footer.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../../State/api/superadminApi";
import {Grid, Container, Link, TextField, Button} from "@mui/material"
import { RiPinterestFill, RiInstagramLine, RiFacebookFill, RiLinkedinFill, RiTwitterXFill, RiYoutubeFill } from "react-icons/ri";
import { AlertsSuccess, Errornotify } from "../../../common/alert/Alert"
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AddMailSubscription
} from "../../../State/api/userApi"
import TranslateWrapper from "../../../languageTranslator/TranslateWrapper";
import { useTranslation } from 'react-i18next';

const data = [
  {
    heading: "For Buyers",
    first: "Art Consultancy",
    first_path: "/artworks",
    second: "Buyer FAQ",
    second_path: "/faq",
    third: "Buyer Manual",
    third_path: "/",
    fourth: "Return Policy",
    fourth_path: "/shipping-returns",
    fifth: "Testimonials",
    fifth_path: "/termsandconditions",

    sixth: "Curators Collection",
    sixth_path: "/search?category=art+prints",
    seventh: "Gift Card",
    seventh_path: "/giftcard",
    eighth: "Offers",
    eighth_path: "/offers",
    nineth: "Commisoned Art ",
    nineth_path: "/",
    tenth: "Art Rentals ",
    tenth_path: "/",
    eleventh: "Art Curation Process  ",
    eleventh_path: "/",
    twelfth: "Catalog",
    twelfth_path: "/catelog",
    // seventh: "Curators",
    // seventh_path: "/curator-circle",
    // tenth: "Refer A Friend",
    // tenth_path: "/",
  },
  {
    heading: "For Artist",
    first: "Artist Application",
    first_path: "/artist-application",
    second: "Artist Manual",
    second_path: "/",
    // second: "Artist Handbook",
    // second_path: "/artistlist",
   
   
  },
  {
    heading: "For Gallery",
    first: "Gallery Application",
    first_path: "/gallery-application",
    second: "Gallery Manual",
    second_path: "/",
  },
  {
    heading: "For Curator",
    first: "Curator Application",
    first_path: "/curator-application",
    second: "Curator Manual ",
    second_path: "/",
  },
  {
    heading: "For Affilate",
    first: "Affilate Application ",
    first_path: "/",
    second: " Affilate Manual ",
    second_path: "/",
  },
  {
    heading: "About Us",
    first: "About Us",
    first_path: "/about-us",
    second: "Press",
    second_path: "/pressrelease",
    third: "Careers",
    third_path: "/career",
    fourth: "Contact Us",
    fourth_path: "/contactus",
    fifth: "Blogs",
    fifth_path: "/blogPage",
    sixth: "Art Smiley Biz",
    sixth_path: "https://artsmileybiz.com/",
    // seventh: "FAQ",
    // seventh_path: "/faq",
    eighth: "Art Smiley NFT",
    eight_path: "https://artsmileynft.com/",
    nineth: "Art Prints",
    nineth_path: "/search?category=art+prints",
  },
  {
    heading: "Art Smiley",
    first: "Terms of Service",
    first_path: "/termsconditions",
    second: "Privacy Policy / Copyright Policy",
    second_path: "/privacypolicy",
    // third: "Copyright Policy",
    // third_path: "/privacypolicy",
    // fourth: "Affiliat Program",
    // fourth_path: "/",
    // fifth: "Gift Card Terms",
    // fifth_path: "user/giftcard",
    // sixth: "Sitemap",
    // sixth_path: "/",
  },
  // {
  //   heading: "Top Categories",
  //   first: "Sculptures",
  //   first_path: "/search?category=sculptures",
  //   second: "Artworks",
  //   second_path: "/search?category=artworks",
  //   third: "Art Prints",
  //   third_path: "/search?category=art+prints",
  //   fourth: "Photography",
  //   fourth_path: "/search?category=photography",
  //   fifth: "Painting",
  //   fifth_path: "/search?category=painting",
  //   sixth: "Drawing",
  //   sixth_path: "/search?category=drawing",
  //   seventh: "Collage",
  //   seventh_path: "/search?category=collage",
  //   eighth: "Textile",
  //   eight_path: "/search?category=textile",
  //   nineth: "Digital",
  //   nineth_path: "/search?category=digital",
  //   tenth: "Crafts",
  //   tenth_path: "/search?category=crafts",
  //   eleventh: "Installation",
  //   eleventh_path: "/search?category=installation",
  //   Twelfth: "Prints",
  //   Twelfth_path: "/search",
  // },
];

// {
//     heading: "Top Categories",
//     first: "Paintings",
//     first_path: "/",
//     second: "Phototgraphy",
//     second_path: "/",
//     third: "Sculpture",
//     third_path: "/",
//     fourth: "Drawings",
//     fourth_path: "/",
//     fifth: "collage",
//     fifth_path: "/",
//   },

export default function Footer() {

  const [exclusiveExpanded, setExclusiveExpanded] = React.useState('excpanel0'); // Default to the first panel
  
  const handleExclusiveChange = (panel) => (event, isExpanded) => {
    setExclusiveExpanded(isExpanded ? panel : false);
  };

  const navigate = useNavigate();
const dispatch = useDispatch();
const { t } = useTranslation("footer");
  const handleNavigate = (route) => {
    if (route.includes("https")) {
      window.open(route, "_blank");
    } else {
     // console.log(route,'route')
      if(route === "/search") {
        navigate(`/user${route}`, {state: {itemType: "print"}});
        window.scrollTo({ top: 0, behavior: "smooth" });
      }else {
        navigate(`/user${route}`);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
     
    }
  };

  const categorydt = useSelector((state) => state.categoryData.data);
  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  const [email, setEmail] = useState("")
    const handleChange = (e) => {
      setEmail(e.target.value)
    }
    const handleSubmit = () => {
        const email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (email.match(email_regex)) {
          dispatch(AddMailSubscription({ email: email }, setEmail))
        } else {
          Errornotify("Please Enter Valid email")
        }
      }

  return (
    <>
    <div className={styles.socln_sec}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '100px' },
  }}>
          <Grid container spacing={2} sx={{alignItems:"center",justifyContent:"space-between"}}>
              <Grid item xs={12} md={5}>
                <div className={styles.scrvtext}>
                <div>
            <img src={"/img/email.png"} width="80px" height="63px" alt="img" />
          </div>
          <h5 className={styles.subscribe_head}>
          <TranslateWrapper>Subscribe to the art smiley newsletter</TranslateWrapper>
            {/* Subscribe for ArtSmiley Newsletter to get the trending art updates,
            curated collections, and special offers. */}
          </h5>
                </div>
              </Grid>
              <Grid item xs={12} md={7}>
                <div className={styles.fomsc}>
                  <div className={styles.frsvc}>
                    <div className={styles.mte}>
                    <TextField
                    
                    fullWidth
            variant="outlined"
            placeholder={t("mail_address_text")}
            sx={{
              width: { xs: "100%", sm: "100%", md:"100%", lg:"400px" },
              backgroundColor: "#3c3c3c",
              borderRadius: "6px",
              "& fieldset": {
                border:
                  email.length === 0
                    ? "none"
                    : email.match(
                   /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                      )
                    ? "none"
                    : "",
              },  
              "& .MuiOutlinedInput-notchedOutline": { border: "0" }, 
              fontFamily: "Roboto",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "1.5",
              color: "#FFFFFF",
              input: { color: "white",
                padding:"11.5px 15px",
                fontSize:"16px",
                fontWeight:"500",
                borderRadius: "6px",
                lineHeight:"26px",
                border:"0"
               },
            }}
            error="true"
            value={email}
            onChange={handleChange}
          />
                   
                    </div>
                    <div>
                    <Button
            variant="contained"
            sx={{
              background: "#fff",
              color: "#1c1c1c",
              padding:"10px 35px",
              fontSize:"16px",
              fontWeight:"500",
              borderRadius: "6px",
              lineHeight:"26px",
            }}
            onClick={handleSubmit}
          >
            <TranslateWrapper>Subscribe</TranslateWrapper>
          </Button>
                    </div>
                  </div>
                
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      <div className={styles.footer_main}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '100px' },
  }}>
     
      <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={9}>
      <div className={styles.desktopview}>
      <Grid container spacing={2}>
        <Grid item  xs={12} sm={2.4} md={2.4}>
        <h4 className={styles.footer_heading}>
        {t("for_buyers")}
            </h4>
            <ul className={styles.ftlinks}>
  <li>
    <Link onClick={() => handleNavigate('/art-consultancy')}>
      {t("art_consultancy")}
    </Link>
  </li>
  <li>
  <Link onClick={() => handleNavigate('/faq?category=buyer')}>
  {t("buyer_faq")}
</Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/buyer-manual')}>
      {t("buyer_manual")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/return-policy')}>
      {t("return_policy")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/testimonials')}>
      {t("testimonials")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/curators-collection')}>
      {t("curators_collection")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/giftcard')}>
      {t("gift_card")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/offers')}>
      {t("offers")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/commisioned-art')}>
      {t("commisioned_art")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/art-rentals')}>
      {t("art_rentals")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/art-curation-process')}>
      {t("art_curation_process")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/catelog')}>
      {t("catalog")}
    </Link>
  </li>
</ul>
</Grid>
<Grid item  xs={12} sm={2.4} md={2.4}>
<h4 className={styles.footer_heading}>
        {t("for_artist")}
              </h4>
              <ul className={styles.ftlinks}>
                <li><Link onClick={() => handleNavigate('/artist-application')}>{t("artist_application")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/artist-manual')}>{t("artist_manual")}</Link></li>
              </ul>
              <h4 className={styles.footer_heading}>
              {t("for_gallery")}
              </h4>
              <ul className={styles.ftlinks}>
                <li><Link onClick={() => handleNavigate('/gallery-application')}>{t("gallery_application")}</Link></li>
                <li><Link onClick={() => handleNavigate('/gallery-manual')}>{t("gallery_manual")}</Link></li>
              </ul>
              <h4 className={styles.footer_heading}>
              {t("for_curator")}
              </h4>
              <ul className={styles.ftlinks}>
                <li><Link  onClick={() => handleNavigate('/curator-application')}>{t("curator_application")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/curator-manual')}>{t("curator_manual")}</Link></li>
              </ul>
              <h4 className={styles.footer_heading}>
              {t("for_affiliate")}
              </h4>
              <ul className={styles.ftlinks}>
                <li><Link  onClick={() => handleNavigate('/affiliate-application')}>{t("affiliate_application")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/affiliate-manual')}>{t("affiliate_manual")}</Link></li>
              </ul>
        </Grid>
        <Grid item  xs={12} sm={2.4} md={2.4}>
        <h4 className={styles.footer_heading}>
        {t("Art_smiley")}
              </h4>
              <ul className={styles.ftlinks}>
                <li><Link onClick={() => handleNavigate('/about-us')}> {t("about_us")}</Link></li>
                <li><Link   onClick={() => handleNavigate('/pressrelease')}> {t("press")}</Link></li>
                <li><Link   onClick={() => handleNavigate('/career')}> {t("careers")}</Link></li>
                <li><Link   onClick={() => handleNavigate('/contactus')}> {t("contact_us")}</Link></li>
                <li><Link   onClick={() => handleNavigate('/blogPage')}> {t("blogs")}</Link></li>
                <li><Link   onClick={() => handleNavigate('https://artsmileybiz.com/')}> {t("art_smiley_biz")}</Link></li>
                <li><Link   onClick={() => handleNavigate('https://artsmileynft.com/')}> {t("art_smiley_nft")}</Link></li>
                <li><Link   onClick={() => handleNavigate('/faq')}> {t("faqs")}</Link></li>
              </ul>
        </Grid>
        <Grid item  xs={12} sm={2.4} md={2.4}>
        <h4 className={styles.footer_heading}>
        {t("terms_and_conditions")} 
              </h4>
              <ul className={styles.ftlinks}>
                <li><Link onClick={() => handleNavigate('/termsconditions')}> {t("terms_of_service")}</Link></li>
                <li><Link onClick={() => handleNavigate('/privacypolicy')} > {t("privacy_policy")} </Link></li>
                <li><Link onClick={() => handleNavigate('/shipping-policy')}> {t("shipping_policy")}</Link></li>
                {/* <li><Link onClick={() => handleNavigate('/return-policy')}> Return Policy</Link></li> */}
                <li><Link  onClick={() => handleNavigate('/terms_and_conditions_for_artist')}> {t("terms_and_conditions_for_artist")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/terms_and_conditions_for_gallery')}> {t("terms_and_conditions_for_gallery")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/terms_and_conditions_for_curator')}> {t("terms_and_conditions_for_curator")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/terms_and_conditions_for_affiliate')}> {t("terms_and_conditions_for_affiliate")}</Link></li>
              </ul>
        </Grid>
        <Grid item  xs={12} sm={2.4} md={2.4}>
        <h4 className={styles.footer_heading}>
        {t("top_categories")}
              </h4>
              <ul className={styles.ftlinks}>
                <li><Link  onClick={() => handleNavigate('/search?category=Painting')}> {t("painting")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Drawing')}> {t("drawing")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Mixed+Media')}> {t("mixed_media")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Sculpture')}> {t("sculpture")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Photography')}> {t("photography")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Installation')}> {t("installation")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Digital+Art')}> {t("digital_art")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=art+prints')}> {t("art_prints")}</Link></li>
              </ul>
        </Grid>
        </Grid>
</div>
<div className={styles.mobileview}>
<Accordion
        expanded={exclusiveExpanded === "panel8"}
        onChange={handleExclusiveChange("panel8")}
        sx={{
          "& .MuiAccordionSummary-contentGutters": { margin: "7px 0" },
          "&.MuiPaper-root": {
            boxShadow: "none",
            borderBottom: ".5px solid #fff",
            background:"#1C1C1C",
            borderRadius:"0"
          },
          "& .MuiAccordionSummary-root":{
            padding:"0"
          },
          "&.Mui-expanded":{
            margin:"0",
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            background: "#1C1C1C",
            opacity: "1",
            textAlign: "center",
            borderRadius: "8px",
            color:"#fff"
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            background: "#1C1C1C",
            opacity: "1",
            color: "#fff",
          },
          "& .MuiAccordionDetails-root": { paddingTop: "0" },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel8-header">
        <h4 className={styles.footer_heading}>
        {t("top_categories")}
              </h4>
        </AccordionSummary>
        <AccordionDetails>
        
              <ul className={styles.ftlinks}>
                <li><Link  onClick={() => handleNavigate('/search?category=Painting')}> {t("painting")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Drawing')}> {t("drawing")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Mixed+Media')}> {t("mixed_media")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Sculpture')}> {t("sculpture")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Photography')}> {t("photography")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Installation')}> {t("installation")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=Digital+Art')}> {t("digital_art")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/search?category=art+prints')}> {t("art_prints")}</Link></li>
              </ul>
        </AccordionDetails>
      </Accordion>
<Accordion
        expanded={exclusiveExpanded === "panel1"}
        onChange={handleExclusiveChange("panel1")}
        sx={{
          "& .MuiAccordionSummary-contentGutters": { margin: "7px 0" },
          "&.MuiPaper-root": {
            boxShadow: "none",
            borderBottom: ".5px solid #fff",
            background:"#1C1C1C",
            borderRadius:"0"
          },
          "&.Mui-expanded":{
            margin:"0",
          },
          "& .MuiAccordionSummary-root":{
            padding:"0"
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            background: "#1C1C1C",
            opacity: "1",
            textAlign: "center",
            borderRadius: "8px",
            color:"#fff"
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            background: "#1C1C1C",
            opacity: "1",
            color: "#fff",
          },
          "& .MuiAccordionDetails-root": { paddingTop: "0" },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1-header">
        <h4 className={styles.footer_heading}>
        {t("for_buyers")}
            </h4>
        </AccordionSummary>
        <AccordionDetails>
        
            <ul className={styles.ftlinks}>
  <li>
    <Link onClick={() => handleNavigate('/art-consultancy')}>
      {t("art_consultancy")}
    </Link>
  </li>
  <li>
  <Link onClick={() => handleNavigate('/faq?category=buyer')}>
  {t("buyer_faq")}
</Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/buyer-manual')}>
      {t("buyer_manual")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/return-policy')}>
      {t("return_policy")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/testimonials')}>
      {t("testimonials")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/curators-collection')}>
      {t("curators_collection")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/giftcard')}>
      {t("gift_card")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/offers')}>
      {t("offers")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/commisioned-art')}>
      {t("commisioned_art")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/art-rentals')}>
      {t("art_rentals")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/art-curation-process')}>
      {t("art_curation_process")}
    </Link>
  </li>
  <li>
    <Link onClick={() => handleNavigate('/catelog')}>
      {t("catalog")}
    </Link>
  </li>
</ul>
        </AccordionDetails>
      </Accordion>

      {/* Accordion 2 */}
      <Accordion
        expanded={exclusiveExpanded === "panel2"}
        onChange={handleExclusiveChange("panel2")}
        sx={{
          "& .MuiAccordionSummary-contentGutters": { margin: "7px 0" },
          "&.MuiPaper-root": {
            boxShadow: "none",
            borderBottom: ".5px solid #fff",
            background:"#1C1C1C",
            borderRadius:"0"
          },
          "&.Mui-expanded":{
            margin:"0",
          },
          "& .MuiAccordionSummary-root":{
            padding:"0"
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            background: "#1C1C1C",
            opacity: "1",
            textAlign: "center",
            borderRadius: "8px",
            color:"#fff"
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            background: "#1C1C1C",
            opacity: "1",
            color: "#fff",
          },
          "& .MuiAccordionDetails-root": { paddingTop: "0" },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel2-header">
        <h4 className={styles.footer_heading}>
        {t("for_artist")}
              </h4>
        </AccordionSummary>
        <AccordionDetails>
       
              <ul className={styles.ftlinks}>
                <li><Link onClick={() => handleNavigate('/artist-application')}>{t("artist_application")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/artist-manual')}>{t("artist_manual")}</Link></li>
              </ul>
              
        </AccordionDetails>
      </Accordion>

      {/* Accordion 3 */}
      <Accordion
        expanded={exclusiveExpanded === "panel3"}
        onChange={handleExclusiveChange("panel3")}
        sx={{
          "& .MuiAccordionSummary-contentGutters": { margin: "7px 0" },
          "&.MuiPaper-root": {
            boxShadow: "none",
            borderBottom: ".5px solid #fff",
            background:"#1C1C1C",
            borderRadius:"0"
          },
          "&.Mui-expanded":{
            margin:"0",
          },
          "& .MuiAccordionSummary-root":{
            padding:"0"
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            background: "#1C1C1C",
            opacity: "1",
            textAlign: "center",
            borderRadius: "8px",
            color:"#fff"
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            background: "#1C1C1C",
            opacity: "1",
            color: "#fff",
          },
          "& .MuiAccordionDetails-root": { paddingTop: "0" },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel3-header">
        <h4 className={styles.footer_heading}>
              {t("for_gallery")}
              </h4>
        </AccordionSummary>
        <AccordionDetails>
        
              <ul className={styles.ftlinks}>
                <li><Link onClick={() => handleNavigate('/gallery-application')}>{t("gallery_application")}</Link></li>
                <li><Link onClick={() => handleNavigate('/gallery-manual')}>{t("gallery_manual")}</Link></li>
              </ul>
              
        </AccordionDetails>
      </Accordion>

      {/* Accordion 4 */}
      <Accordion
        expanded={exclusiveExpanded === "panel4"}
        onChange={handleExclusiveChange("panel4")}
        sx={{
          "& .MuiAccordionSummary-contentGutters": { margin: "7px 0" },
          "&.MuiPaper-root": {
            boxShadow: "none",
            borderBottom: ".5px solid #fff",
            background:"#1C1C1C",
            borderRadius:"0"
          },
          "&.Mui-expanded":{
            margin:"0",
          },
          "& .MuiAccordionSummary-root":{
            padding:"0"
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            background: "#1C1C1C",
            opacity: "1",
            textAlign: "center",
            borderRadius: "8px",
            color:"#fff"
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            background: "#1C1C1C",
            opacity: "1",
            color: "#fff",
          },
          "& .MuiAccordionDetails-root": { paddingTop: "0" },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel4-header">
        <h4 className={styles.footer_heading}>
              {t("for_curator")}
              </h4>
        </AccordionSummary>
        <AccordionDetails>
        
              <ul className={styles.ftlinks}>
                <li><Link  onClick={() => handleNavigate('/curator-application')}>{t("curator_application")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/curator-manual')}>{t("curator_manual")}</Link></li>
              </ul>
              
        </AccordionDetails>
      </Accordion>

      {/* Accordion 5 */}
      <Accordion
        expanded={exclusiveExpanded === "panel5"}
        onChange={handleExclusiveChange("panel5")}
        sx={{
          "& .MuiAccordionSummary-contentGutters": { margin: "7px 0" },
          "&.MuiPaper-root": {
            boxShadow: "none",
            borderBottom: ".5px solid #fff",
            background:"#1C1C1C",
            borderRadius:"0"
          },
          "&.Mui-expanded":{
            margin:"0",
          },
          "& .MuiAccordionSummary-root":{
            padding:"0"
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            background: "#1C1C1C",
            opacity: "1",
            textAlign: "center",
            borderRadius: "8px",
            color:"#fff"
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            background: "#1C1C1C",
            opacity: "1",
            color: "#fff",
          },
          "& .MuiAccordionDetails-root": { paddingTop: "0" },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel5-header">
        <h4 className={styles.footer_heading}>
              {t("for_affiliate")}
              </h4>
        </AccordionSummary>
        <AccordionDetails>
       
              <ul className={styles.ftlinks}>
                <li><Link  onClick={() => handleNavigate('/affiliate-application')}>{t("affiliate_application")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/affiliate-manual')}>{t("affiliate_manual")}</Link></li>
              </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={exclusiveExpanded === "panel6"}
        onChange={handleExclusiveChange("panel6")}
        sx={{
          "& .MuiAccordionSummary-contentGutters": { margin: "7px 0" },
          "&.MuiPaper-root": {
            boxShadow: "none",
            borderBottom: ".5px solid #fff",
            background:"#1C1C1C",
            borderRadius:"0"
          },
          "&.Mui-expanded":{
            margin:"0",
          },
          "& .MuiAccordionSummary-root":{
            padding:"0"
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            background: "#1C1C1C",
            opacity: "1",
            textAlign: "center",
            borderRadius: "8px",
            color:"#fff"
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            background: "#1C1C1C",
            opacity: "1",
            color: "#fff",
          },
          "& .MuiAccordionDetails-root": { paddingTop: "0" },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel6-header">
        <h4 className={styles.footer_heading}>
        {t("Art_smiley")}
              </h4>
        </AccordionSummary>
        <AccordionDetails>
        
              <ul className={styles.ftlinks}>
                <li><Link onClick={() => handleNavigate('/about-us')}> {t("about_us")}</Link></li>
                <li><Link   onClick={() => handleNavigate('/pressrelease')}> {t("press")}</Link></li>
                <li><Link   onClick={() => handleNavigate('/career')}> {t("careers")}</Link></li>
                <li><Link   onClick={() => handleNavigate('/contactus')}> {t("contact_us")}</Link></li>
                <li><Link   onClick={() => handleNavigate('/blogPage')}> {t("blogs")}</Link></li>
                <li><Link   onClick={() => handleNavigate('https://artsmileybiz.com/')}> {t("art_smiley_biz")}</Link></li>
                <li><Link   onClick={() => handleNavigate('https://artsmileynft.com/')}> {t("art_smiley_nft")}</Link></li>
                <li><Link   onClick={() => handleNavigate('/faq')}> {t("faqs")}</Link></li>
              </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={exclusiveExpanded === "panel7"}
        onChange={handleExclusiveChange("panel7")}
        sx={{
          "& .MuiAccordionSummary-contentGutters": { margin: "7px 0" },
          "&.MuiPaper-root": {
            boxShadow: "none",
            borderBottom: ".5px solid #fff",
            background:"#1C1C1C",
            borderRadius:"0"
          },
          "&.Mui-expanded":{
            margin:"0",
          },
          "& .MuiAccordionSummary-root":{
            padding:"0"
          },
          "& .MuiAccordionSummary-expandIconWrapper": {
            background: "#1C1C1C",
            opacity: "1",
            textAlign: "center",
            borderRadius: "8px",
            color:"#fff"
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            background: "#1C1C1C",
            opacity: "1",
            color: "#fff",
          },
          "& .MuiAccordionDetails-root": { paddingTop: "0" },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel7-header">
        <h4 className={styles.footer_heading}>
        {t("terms_and_conditions")} 
              </h4>
        </AccordionSummary>
        <AccordionDetails>
        
              <ul className={styles.ftlinks}>
                <li><Link onClick={() => handleNavigate('/termsconditions')}> {t("terms_of_service")}</Link></li>
                <li><Link onClick={() => handleNavigate('/privacypolicy')} > {t("privacy_policy")} </Link></li>
                <li><Link onClick={() => handleNavigate('/shipping-policy')}> {t("shipping_policy")}</Link></li>
                {/* <li><Link onClick={() => handleNavigate('/return-policy')}> Return Policy</Link></li> */}
                <li><Link  onClick={() => handleNavigate('/terms_and_conditions_for_artist')}> {t("terms_and_conditions_for_artist")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/terms_and_conditions_for_gallery')}> {t("terms_and_conditions_for_gallery")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/terms_and_conditions_for_curator')}> {t("terms_and_conditions_for_curator")}</Link></li>
                <li><Link  onClick={() => handleNavigate('/terms_and_conditions_for_affiliate')}> {t("terms_and_conditions_for_affiliate")}</Link></li>
              </ul>
        </AccordionDetails>
      </Accordion>
      
</div>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
      <div className={styles.mbtextcenter}>
          <div className={styles.mobnone}><img src={"/img/Copyright symbol.png"} width="30px" height="30px" alt="" /></div>
          <p className={styles.footer_links}>
            <TranslateWrapper><span className={styles.onlmob}>&copy;</span> Copyright 2025 Art Smiley Eart and Technology FZCO.</TranslateWrapper>
          </p>
          <p className={styles.footer_links}>{t("all_rights_reserved")}.</p>
          <div className={styles.footer_cards}>
            <img src={"/img/Visa Sign.png"} alt="" />
            <img src={"/img/Master Card.png"} alt="" />
            <img src={"/img/Discover.png"} alt=""/>
            <img src={"/img/American Express.png"} alt=""/>
            <img src={"/img/Paypal.png"} alt=""/>
          </div>
          <div className={styles.footer_socialmedia}>
            <ul>
              <li><Link href="https://www.instagram.com/artsmiley_arts?igsh=cGZpNG9qazRqZWR2" target="_blank"><RiInstagramLine className={styles.soclicn}/></Link></li>
              <li><Link href="https://www.linkedin.com/company/artsmiley/" target="_blank"><RiLinkedinFill className={styles.soclicn}/></Link></li>
              <li><Link href="https://www.facebook.com/artsmileyonline" target="_blank"><RiFacebookFill className={styles.soclicn}/></Link></li>
              <li><Link href="https://x.com/ArtSmileyArts" target="_blank"><RiTwitterXFill className={styles.soclicn}/></Link></li>
              <li><Link href="https://www.youtube.com/channel/UCxppAu6eSwZI0pezf1-7e4A" target="_blank"><RiYoutubeFill className={styles.soclicn}/></Link></li>
              <li><Link href="https://www.pinterest.com/artsmiley/" target="_blank"><RiPinterestFill className={styles.soclicn}/></Link></li>
            </ul>
           
          </div>
        </div>
      </Grid>
      </Grid>
      </Container>
      </div>
    </>
  );
}