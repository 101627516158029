import React from "react"
import Commonsidebar from "../../components/Pages/List/Common/sideBar/Commonsidebar"

const sidebarRoutes = [
  {
    name: "art",
    icon: "fa fa-dashboard",
    path: "/art",
    hasSubmenu: false,
  },
  {
    name: "Profile",
    icon: "fa fa-cube",
    path: "/myaccount",
    hasSubmenu: false,
  },
  {
    name: "artists",
    icon: "fa fa-truck",
    path: "/artists",
    hasSubmenu: false,
  },
  {
    name: "gallery",
    icon: "fa fa-truck",
    path: "/gallery",
    hasSubmenu: false,
  },
  {
    name: "exhibitions",
    icon: "fa fa-cube",
    path: "/exhibitions",
    hasSubmenu: false,
  },
  {
    name: "auctions",
    icon: "fa fa-users",
    path: "/auction",
    hasSubmenu: false,
  },
  {
    name: "orders",
    icon: "fa fa-cube",
    path: "/orders",
    hasSubmenu: false,
  },
  {
    name: "testimonials",
    icon: "fa fa-cube",
    path: "/curator-testimonials",
    hasSubmenu: false,
  },

  {
    name: "earnings",
    icon: "fa fa-th-large",
    path: "/earnings",
    hasSubmenu: false,
  },
  {
    name: "notifications",
    icon: "fa fa-cube",
    path: "/notification",
    hasSubmenu: false,
  },
  {
    name: "My Inquiries",
    icon: "fa fa-cube",
    path: "/enquiry",
    hasSubmenu: false,
  },
]
export default function CuratorSidebar() {
  return (
    <div>
      <Commonsidebar sidebarRoutes={sidebarRoutes} />
    </div>
  )
}
