// import { Errornotify, Successnotify } from "../../common/alert/Alert";
import { Errornotify, Successnotify, Warnnotify } from "../../common/alert/Alert";
import { setLoader } from "../Features/common/loaderSlice";
import { removeUser } from "../Features/common/userSlice";
import { setMyArtistData } from "../Features/Gallery/GalleryMyArtistSlice";
import { addData } from "../Features/Gallery/galleryPrifileSlice";
import api from "./Api";

export function updateGalleryProfile(data, navigate) {
  console.log("Gallery register", data);

  return async function fetchedirGalleryProfile(dispatch) {
    dispatch(setLoader(true));
    try {
      const formData = new FormData();

      formData.append("first_name", data.first_name);
      formData.append("last_name", data.last_name);
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("title", data.title);
      formData.append("gallery_name", data.gallery_name);
      formData.append("gallery_address", data.gallery_address);
      formData.append("gallery_based", data.gallery_based);
      formData.append("country_residence", data.country);
      formData.append("addresses[0][address_type]", "Shipping Address");
      formData.append("addresses[0][is_default]", true);
      formData.append("gallery_established", data.gallery_established);
      formData.append("language", data.language);
      formData.append("registration_document", data.registration_document);
      formData.append("profile_image", data.profile_image);
      formData.append("cover_image", data.cover_image);
      formData.append("completion", data.completion);

      data.category_id.forEach(function (element, index) {
        formData.append(`category_id[${index}][_id]`, element._id);
      });
      data.art_price_range.forEach((element, index) => {
        formData.append(`art_price_range[${index}]`, element);
      });
      formData.append("instagram", data.social_links.instagram);
      formData.append("facebook", data.social_links.facebook);
      formData.append("linkedin", data.social_links.linkedin);
      formData.append("twitter", data.social_links.twitter);
      formData.append("youtube", data.social_links.youtube);
      formData.append("description", data.description);
      formData.append("award", data.award);
      formData.append("completion", data.completion)
      formData.append("degree_year", data.degree_year);
      data.best_work.forEach((file) => {
        formData.append("best_work", file);
      });
      formData.append("exp", data.exp);
      formData.append("other_URL", data.other_URL);
      formData.append("online_portfolio", data.portfolio);
      formData.append("portfolio", data.portfolio_file);
      formData.append(
        "arts_solds_in_past_12_months",
        data.arts_solds_in_past_12_months
      );

      const apiResponse = await api.multipartApi(
        "api/gallery/updateGalleryProfile",
        "POST",
        formData
      );
      if (apiResponse.status === 200) {
        Successnotify(apiResponse.data.message);
        setTimeout(() => {
          navigate("/gallery/form-submission");
        }, 500);
        dispatch(setLoader(false));
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          );
          Errornotify(apiResponse.data.message);
        } else {
          dispatch(setLoader(false));
          Errornotify(apiResponse.data.message);
        }
      }
    } catch (error) {
      console.log("catch render:", error);
      dispatch(setLoader(false));
    }
  };
}


export function SubmitMyArtistData(data, navigate) {
  return async function fetchGalleryData(dispatch) {
    dispatch(setLoader(true));
    try {
      const formData = new FormData();
      formData.append("first_name", data.firstName);
      formData.append("last_name", data.lastName);
      formData.append("email", data.email);
      formData.append("phone", data.mobile);
      formData.append("latest_profile", data.profilePhoto);
      formData.append("backgroundImage", data.coverPhoto);
      formData.append("about", data.aboutArtist);
      formData.append("professional_details", data.professionalDetails);
      formData.append("artist_type_id", data.artistType);
      formData.append("nationality", data.nationality);
      formData.append("gallery_id", data.gallery_id);
      data.artCategory.forEach((categoryId) => {
        formData.append("category_id[]", categoryId);
      });
      const apiResponse = await api.multipartApi(
        "api/galleryartist/addGalleryArtist",
        "POST",
        formData
      );
      if (apiResponse.status === 201) {
        Successnotify(apiResponse.data.message);
        dispatch(setLoader(false));
        setTimeout(() => {
          navigate("/myartist");  // Navigate after 2 seconds
        }, 2000);
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          );
          Errornotify(apiResponse.data.message);
        } else {
          dispatch(setLoader(false));
          Errornotify(apiResponse.data.message);
        }
      }
    } catch (error) {
      console.log("catch render:", error);
      dispatch(setLoader(false));
    }
  };
}
export function UpdateGalleryDetails(data, artistId, navigate, callback) {
   
  return async function fetchGalleryData(dispatch) {
    if (!artistId) {
      Errornotify("❌ Artist ID is missing in API call!");
      return;
    }

    dispatch(setLoader(true));
    try {
      const formData = new FormData();
      
      if (data.firstName) formData.append("first_name", data.firstName);
      if (data.lastName) formData.append("last_name", data.lastName);
      if (data.email) formData.append("email", data.email);
      if (data.mobile) formData.append("phone", data.mobile);
      if (data.profilePhoto) formData.append("latest_profile", data.profilePhoto);
      if (data.coverPhoto) formData.append("backgroundImage", data.coverPhoto);
      if (data.aboutArtist) formData.append("about", data.aboutArtist);
      if (data.professionalDetails) formData.append("professional_details", data.professionalDetails);
      if (data.artistType) formData.append("artist_type_id", data.artistType);
      if (data.nationality) formData.append("nationality", data.nationality);

      data.artCategory?.forEach((categoryId) => {
        formData.append("category_id[]", categoryId);
      });

      if (data.hasOwnProperty("is_deleted")) {
        formData.append("is_deleted", data.is_deleted);
      }

      const apiResponse = await api.multipartApi(
        `api/galleryartist/editGalleryArtist/${artistId}`,
        "PUT",
        formData
      );

      if (apiResponse.status === 200) {
        // ✅ Custom success message based on update type
        if (data.hasOwnProperty("is_deleted")) {
          Successnotify("🎨 Artist status updated successfully!");
        } else {
          Successnotify(apiResponse.data.message);
        }

        dispatch(setLoader(false));

        if (callback) {
          callback(); // Refresh data instead of navigating
        } else {
          setTimeout(() => {
            navigate("/myartist");
          }, 2000);
        }
      } else {
        if (apiResponse.status === 401) {
          dispatch(removeUser({ signed: false, type: "", data: "" }));
          Errornotify(apiResponse.data.message);
        } else {
          dispatch(setLoader(false));
          Errornotify(apiResponse.data.message);
        }
      }
    } catch (error) {
      console.error("❌ API Error:", error);
      dispatch(setLoader(false));
    }
  };
}


export function getMyArtistData(id) {
  return async function fetchMyArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/galleryartist/galleryArtistsByGallery/${id}`,
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setMyArtistData(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          // Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) { }
  }
}

export function DeleteArtist(id) {
  return async function fetchMyArtistDataThunk(dispatch) {
    dispatch(setLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        `api/galleryartist/galleryArtistsByGallery/${id}`,
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setMyArtistData(apiResponse.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
          Errornotify(apiResponse.data.message)
        } else {
          // Errornotify(apiResponse.data.message)
        }
      }
    } catch (err) { }
  }
}
