import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const EnquiryByEnquiryIdSlice = createSlice({
    name: "enquirybyenquiryid",
    initialState,
    reducers: {
        setEnquiryByEnquiryId: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setEnquiryByEnquiryId } = EnquiryByEnquiryIdSlice.actions;

export const enquirybyenquiryid = (state) => state.enquirybyenquiryid;

export default EnquiryByEnquiryIdSlice.reducer;
