import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import serverimage from "../../../../../serverConstant"
import styles from "./Orderdetail.module.css"
import api from "../../../../../State/api/Api"
import { useParams } from "react-router-dom"
import { Errornotify } from "../../../../../common/alert/Alert"

export default function ArtistOrderDetails(props) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const [orderData, setOrderData] = useState(null)
  console.log("orderData Details",orderData )
  const handlePopup = () => {
    props.setOpen && props.setOpen({ open: true })
  }
  const handleDatepopup = () => {
    props.setOpen({ dateopen: true })
  }

  const handleFetchOrders = async function () {
    // dispatch(setLoader(true));
    setLoading(true)
    try {
      const result = await api.applicationApi(
        `api/order/fetchOrder/${id}`,
        "GET"
      )

      if (result && result.status === 200) {
        setOrderData(result.data.data)
        // dispatch(setLoader(false));
        setLoading(false)
      } else if (result.status === 401) {
        Errornotify("Error with code 401")
      }
    } catch (err) {
      Errornotify("Network Error")
      setLoading(false)
    }
  }
  useEffect(() => {
    handleFetchOrders()
  }, [])
  return (
    <div className={styles.mains}>
      <>
        <div className={styles.back_button}>
          <h3 className={styles.main_heading}>Order Id:<span style={{fontWeight:'500',fontSize:'18px'}}>{orderData?.map((val)=>val?._id)}</span></h3>
          {/* <span className={styles.ordersubsty}>{orderData[0]?._id}</span> */}
        </div>{" "}
        {orderData?.map((val) =>
          val?.items?.map((item) => (
            <>
            <div className={styles.bsrd}>
            <Grid  container spacing={2} sx={{justifyContent:"space-between"}}>
           <Grid item xs={12} sm={5} md={4}>
            <div>
            <h1 className={styles.art_name}>{item?.item?.name}</h1>
            <div style={{ padding: "10px 0px" }}>
                          <img
                            src={` ${item?.item?.image}`}
                             className="img-fluid"
                            alt="img"
                          />{" "}
                        </div>
            </div>
           </Grid>
           <Grid item xs={12} sm={5} md={4}></Grid>
           </Grid>
           <div>
           <p className={styles.odlist}>Order type:<span> {item?.order_type?.name}</span></p>
            <p className={styles.odlist}>Price: <span>${val?.price}</span></p>
            <p className={styles.odlist}>Materials: <span>{item?.item?.print[0]?.material}</span></p>
            <p className={styles.odlist}>Category: <span>{item?.item?.category[0]?.name}</span></p>
            <p className={styles.odlist}>Dimensions: <span>{item?.item?.width}*
            {item?.item?.height}*{item?.item?.depth} in cm</span></p>
           </div>
            </div>
              <div>  {/* <span className={styles.ordersubsty}>
                            {" "}
                            {item?.order_status}
                          </span> */}
   
              </div>
            </>
          ))
        )}
      </>
    </div>
  )
}
