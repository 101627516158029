import validator from "validator";
import isEmpty from "./isEmpty";
const isValidFile = file => file instanceof File && file.name && file.size > 0;
export const validationForPersonalDetailGallery = (
    first_name,
    last_name,
    email,
    phone,
    title,
    gallery_name,
    country,
    gallery_address,
    gallery_established,
    gallery_based,
    language,
    registration_document,
    profile_image,
    cover_image,
    completion
) => {
   language = typeof language === "string" ? language : (language?.toString() || "");
    const isFile = file => file instanceof File;
  
  let galleryErrors = {};
  language = language || "";
  gallery_based = gallery_based || "";
  if (validator.isEmpty(first_name)) {
    galleryErrors.first_name = "First name is required";
  } else if (
    !validator.isEmpty(first_name) &&
    !/^[A-Za-z\s]+$/.test(first_name)
  ) {
    galleryErrors.first_name = "First name can only contain alphabets";
  }
  if (validator.isEmpty(last_name)) {
    galleryErrors.last_name = "Last name is required";
  } else if (!validator.isEmpty(last_name) && !/^[A-Za-z\s]+$/.test(last_name)) {
    galleryErrors.last_name = "Last name can only contain alphabets";
  }
  if (validator.isEmpty(phone)) {
    galleryErrors.phone = "Phone number is required";
  } else if (phone?.slice(0, 2) === "91" && phone?.length !== 12) {
    galleryErrors.phone = "Phone number is invalid";
  }

  if (validator.isEmpty(email)) {
    galleryErrors.email = "Email is required";
  } else if (!validator.isEmpty(email) && !validator.isEmail(email)) {
    galleryErrors.email = "Email is invalid";
  }

  if (validator.isEmpty(title)) {
    galleryErrors.title = "Title is required";
  }
  if (validator.isEmpty(gallery_name)) {
    galleryErrors.gallery_name = "Gallery name is required";
  }
  if (validator.isEmpty(country)) {
    galleryErrors.country = "Country name is required";
  }
  if (validator.isEmpty(gallery_address)) {
    galleryErrors.gallery_address = "Gallery address is required";
  }
  if (validator.isEmpty(gallery_based)) {
    galleryErrors.gallery_based = "Location of the gallery is required";
  }
  if (validator.isEmpty(gallery_established)) {
    galleryErrors.gallery_established = "Gallery established is required";
  }
  if (validator.isEmpty(language)) {
    galleryErrors.language = "Language is required";
  }


  // Validate ID proof file
  if (!isFile(cover_image) || !cover_image.name) {
    galleryErrors.cover_image = "Cover image is required";
  }

    // Validate ID proof file
    if (!isFile(registration_document) || !registration_document.name) {
        galleryErrors.registration_document = "Registration document is required";
      }
    
  // Validate profile image file
  if (!isFile(profile_image) || !profile_image.name) {
    galleryErrors.profile_image = "Profile image is required";
  }

  if (validator.isEmpty(completion || "")) {
    galleryErrors.completion = "Please confirm the information.";
  } else if(completion === "No"){
    galleryErrors.completion = "Please Select Yes to confirm the information is accurate.";
  }

  return {
    galleryErrors,
    isValidGalleryDetails: isEmpty(galleryErrors),
  };
};
