import React from "react"
import styles from "./PopupRequest.module.css"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import { Fade, Grid, Link } from "@mui/material"
import dayjs from "dayjs";
import { RiCloseCircleFill } from "react-icons/ri";
import serverimage from "../../../../serverConstant"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height:'700px',
  backgroundColor: "#FFFFFF",
  width: 600,
  bgcolor: "background.paper",
  // border: "0px solid #000",
  borderRadius: "10px",
  pl: 3,
  pr: 3,
  pt: 3,
  pb: 3,
}

const data = [
  {
    id: 1,
    user_title: "Username",
    user_val: "Artsmiley",
  },
  {
    id: 2,
    user_title: "Customer ID",
    user_val: "h3jh45",
  },
  {
    id: 3,
    user_title: "Buyer Name",
    user_val: "Smiley",
  },
  {
    id: 4,
    user_title: "Number of items",
    user_val: "878",
  },
  {
    id: 5,
    user_title: "Email Address",
    user_val: "jhe63hkj@gmail.com",
  },
]

export default function PopupRequest(props) {
  console.log(props.popupdata, "data--recreate")
  const [open, setOpen] = React.useState(false)
  // const handleOpen = () => props.setOpen(true);
  const handleClose = () => props.setOpen(false)
  const handleBlockunblock = () => {
    const is_blocked = props.popupdata.is_blocked
    console.log(is_blocked)
    props.handleBlockunblock &&
      props.handleBlockunblock(props.popupdata._id, !is_blocked)
  }
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style} className={styles.modal_del}>
            <div className={styles.cancel_img}>
              <Link onClick={() => props.setOpen(false)}><RiCloseCircleFill className={styles.closicon}/></Link>
            </div>
            {/* grid */}
            <Grid
               container
               spacing={2}
            >
              <Grid item xs={12} sm={4} md={4}>
              <div  className={styles.inputs_img}>
              <img
                  alt="img"
                  src={` ${props.popupdata?.item_id?.image}`}
                  className="img-fluid"
                />
              </div>
                
              </Grid>
              <Grid item xs={12} sm={8} md={8}>
                <div className={styles.data_div}>
                <p className={styles.odlist}>Name : <span>{props.popupdata?.item_id?.name}</span></p>
                <p className={styles.odlist}>Category : <span>{props.popupdata?.item_id?.category_id?.name}</span></p>
                <p className={styles.odlist}>Subject : <span>{props.popupdata?.first_name}
                {props.popupdata?.item_id?.subject_id?.name}</span></p>
                <p className={styles.odlist}>Request Date : <span>{dayjs(props.popupdata?.updatedAt)
                        .format("DD-MM-YYYY")
                        .toString()}</span></p>
                  {/* {data.map((val) => ( */}

                  {/* ))} */}
                </div>
              </Grid>
            </Grid>

            <div className={styles.reactext}>
              <h5 className={styles.requestpop}>Message for Quote Request:</h5>
              <p>{props.popupdata?.item_id?.description}</p>
{/* 
              <h5 className={styles.requestpop}>Quatation:</h5>
              <p>  {props.popupdata?.item_id?.category_id?.name} </p> */}
            </div>

            {/*  */}
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
