import React, { useState } from "react";
// import Navbar from "../Navbar/Navbar"
// import Header from "../Header/Header"
import styles from "./Home.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Footer from "../Footer/Footer";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import homeImage from "../../constant";
import { useDispatch } from "react-redux";
import { AddMailSubscription } from "../../State/api/userApi";
import { AlertsSuccess} from "../alert/Alert";
import { RiAnticlockwise2Line, RiArrowRightUpLine, RiComputerLine, RiCalendarLine  } from "react-icons/ri";
const data = [
{
id: 1, 
icon: <RiAnticlockwise2Line  />,
heading: "Worldwide Networking Prospects",
content:
"<p>Tap into a vast network of international art collectors, and curators. We foster meaningful connections and collaborations that transcend geographical boundaries.</p>",
},
{
   id: 2,
   icon: <RiCalendarLine />,
   heading: "Curated Art Events",
   content:
   "<p>Benefit from our exclusive, physical and virtual exhibitions and auctions that spotlight unique artworks that attract art lovers, maximizing engagement and interest.</p>",
   },
   {
      id: 3, 
      icon: <RiArrowRightUpLine   />,
      heading: "Accelerate Sales",
      content:
      "<p>Boost your sales by showcasing your work to a larger, targeted audience. Our platform's expansive reach connects you with serious buyers, helping convert interest into sales.</p>",
      },
      {
         id: 4,
         icon:<RiComputerLine />,
         heading: "Seamless Digital Platform",
         content:
         "<p>A user-friendly online platform that allows galleries to list, manage, and sell artworks effortlessly. Advanced search and filtering features help buyers find the right artwork.</p>",
         },
];
const service_data = [
{
id: 1,
heading: "Exhibitions and Auctions",
des: "As a registered gallery on Art Smiley, gain access to exclusive exhibitions and auctions that elevate your artists' visibility. Connect with global collectors and art enthusiasts, enhancing exposure and growth.",
},
{
id: 2,
heading: "Highlighting Recommended Artworks",
des: "Art Smiley proudly showcases a selection of trending and top-rated artworks from our partnered galleries, recognizing outstanding talent within the community.",
},
{
id: 3,
heading: "Art Community",
des: "Showcase your artists alongside other distinguished galleries while gaining access to valuable insights on emerging trends through articles, magazines, catalogs, and artist interviews.",
},
{
id: 4,
heading: "Digital Promotion",
des: "Art Smiley offers digital galleries and virtual tours, expanding reach to a global audience. Our team promotes exhibitions and auctions, using social media to attract art enthusiasts and boost visibility.",
},
{
   id:5,
   heading:"Elevated Artwork Prestige",
   des:"Selling through Art Smiley enhances the perceived value of artwork, reinforcing its desirability among collectors. Exclusivity fosters a sense of rarity and increasing demand."
},
{
   id:6,
   heading:"Exclusive Selling Rights",
   des:"Partner galleries gain a unique advantage by offering artwork exclusively through Art Smiley, maintaining the distinctiveness of their collection."
},
{
   id:7,
   heading:"Networking & Collaboration Opportunities",
   des:"Gain access to Art Smiley’s exclusive industry events, networking sessions, and collaborations with leading collectors, curators, and galleries. "
}
];
const dashboardfeature_data = [
   {
   id: 1,
   heading: "Managing Your Gallery Profile",
   des: "Keep your gallery profile up to date effortlessly. Edit essential details, manage artist collaborations, and update your portfolio with ease. Maintain a professional online presence with full control over your gallery’s information.",
   },
   {
   id: 2,
   heading: "Artwork Management",
   des: "Easily upload, organize, and showcase artworks from various artists. Keep your collection fresh by adding new pieces and curating engaging displays. Ensure maximum visibility and engagement through a seamless digital platform.",
   },
   {
      id: 3,
      heading: "Exhibition Management",
      des: "Plan, track, and manage your gallery’s exhibitions efficiently. Stay informed about upcoming events and coordinate with artists to ensure a successful showcase. Boost visibility and attract art enthusiasts and collectors.",
   },
   {
      id: 4,
      heading: "Auction Listings",
      des: "Host and participate in exclusive art auctions directly through the platform. Manage bids, track interest from collectors, and optimize auction strategies to maximize value. Create excitement and drive higher engagement through limited-time sales.",
   },
   {
      id: 5,
      heading: "Collector’s Offers Tab",
      des: "Review and respond to purchase offers from potential buyers. Gain insights into collector preferences and negotiate deals effectively. Stay proactive in closing sales and growing your gallery’s revenue.",
   },
   {
      id: 6,
      heading: "Admin Curated Opportunities",
      des: "Access exclusive promotions, premium gallery features, and special exhibition invitations. Take advantage of curated opportunities designed to elevate your gallery’s reach. Connect with Art Smiley’s network to expand your influence.",
   },
   {
      id: 7,
      heading: "Managing Reproduction Requests",
      des: "Handle requests for reproductions or commissioned artworks seamlessly. Coordinate with artists to fulfill custom orders while ensuring quality and consistency. Strengthen relationships with collectors by offering personalized options.",
   },
   {
      id: 8,
      heading: "Custom Quote Requests",
      des: "Receive and manage inquiries for bespoke art projects. Provide tailored quotes for custom artwork commissions, bulk orders, or specialized curation services. Offer a personalized experience to high-value clients.",
   },
   {
      id: 9,
      heading: "Dispute Resolution System",
      des: "Address and resolve order-related concerns quickly with the dispute resolution feature. Maintain trust with buyers and artists by ensuring a transparent, fair, and structured process. Safeguard your gallery’s reputation with smooth conflict management.",
   },
   {
      id: 10,
      heading: "Art Smiley Updates & News",
      des: "Stay ahead with the latest industry insights, platform updates, and exclusive opportunities. Get notified about upcoming exhibitions, auctions, and new feature releases. Keep your gallery informed and engaged in the ever-evolving art world.",
   },
   
];
const gallery_subscription_data = [
{
id: 1,
src: "/img/service_artist_1",
heading: "Public Profile",
des: "Display your creations online and grab the membership of the Art Smiley community.",
},
{
id: 2,
src: "/img/service_artist_1",
heading: "Custom Dashboard",
des: "Seamlessly manage your collection and keep track of artworks, exhibitions, orders, and client inquiries.",
},
{
id: 3,
src: "/img/service_artist_1",
heading: "SSL Protection",
des: "Ensure the safety of your personal information with SSL encryption.",
},
{
id: 4,
src: "/img/service_artist_1",
heading: "24/7 Support",
des: "Access help whenever you need assistance.",
},
{
id: 5,
src: "/img/service_artist_1",
heading: "Upload Up to 20 Pieces",
des: "Start sharing your work with ease.",
},
{
id: 6,
src: "/img/service_artist_1",
heading: "Engagement Metrics",
des: "Analyze views, likes, follows, and reviews to gauge audience interaction.",
},
];
const standard_subscription = [
{
id: 1,
src: "/img/service_artist_1",
heading: "Upload Up to 200 Pieces",
des: "Expand your portfolio and connect with a broader audience.",
},
{
id: 2,
src: "/img/service_artist_1",
heading: "Feature on Homepage",
des: "Gain significant visibility with a one-time spotlight on Art Smiley's homepage.",
},
{
id: 3,
src: "/img/service_artist_1",
heading: "Virtual Event Participation",
des: "Network with other artists and collectors by showcasing up to three works at any one of the Art Smiley virtual events.",
},
{
id: 4,
src: "/img/service_artist_1",
heading: "Unique Marketing Opportunities",
des: "One-time exclusive promotion of  selected artwork across social media platforms.",
},
{
id: 5,
src: "/img/service_artist_1",
heading: "Pricing and Marketing Insights",
des: "Access essential guidance to help you make strategic pricing and marketing decisions.",
},
];
const premium_subscription = [
{
id: 1,
src: "/img/service_artist_1",
heading: "Upload Up to 500 Pieces",
des: "Present your full range of creative work.",
},
{
id: 2,
src: "/img/service_artist_1",
heading: "Highlighted Profile on Homepage",
des: "Gain enhanced visibility within the artistic community with a homepage feature.",
},
{
id: 3,
src: "/img/service_artist_1",
heading: "Expanded Virtual Event Participation",
des: "Enjoy exclusive networking opportunities with two time participation up to three works for each Art Smiley virtual event.",
},
{
id: 4,
src: "/img/service_artist_1",
heading: "Broader Marketing Support",
des: "Benefit from two time exclusive promotion of artworks across all Art Smiley social media channels.",
},
{
id: 5,
src: "/img/service_artist_1",
heading: "Video Showcase for YouTube & Social Media",
des: "Promote your artistry with a professionally produced video by our team or share your own production.",
},
{
id: 6,
src: "/img/service_artist_1",
heading: "Special Exhibition Invitations",
des: "Receive discounts and first-access opportunities for all Art Smiley-related exhibitions.",
},
{
id: 7,
src: "/img/service_artist_1",
heading: "Guest Blog Features",
des: "Share your artistic journey and insights through guest posts on Art Smiley's blog.",
},
{
id: 8,
src: "/img/service_artist_1",
heading: "Solo Online Exhibition with Annual Plan",
des: "Enjoy a dedicated virtual exhibition highlighting your artwork if you select the annual Premium Subscription.",
},
];
// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));
const FAQs = [
{
id: 1,
question: "How do I sign up as a gallery on Art Smiley?",
answer:
"Begin your journey by registering your gallery on the Art Smiley platform. Make sure to fill all the mandatory fields with the right information. Once your application has been reviewed and approved, you will receive a confirmation email to set up your password, granting you access to all available features and services.",
},
{
id: 2,
question: "How can I upload the artwork?",
answer:
"Begin by logging into your dashboard, navigating to the 'Upload Arts' section, and submitting your artwork with all the necessary details.",
},
{
id: 3,
question: "How long does the application review process take?",
answer:
"The review process for your gallery account typically takes 3 to 4 weeks. We will notify you once the review is complete and guide you through the subsequent steps.",
},
{
id: 4,
question: "Can I explore the platform while waiting for my account to be approved?",
answer:
"Yes, you can explore the features of Art Smiley while your application is under review. Take this time to familiarize yourself with the platform.",
},
{
id: 5,
question: "What should I do if my application is not approved?",
answer:
"If your application is not approved, we encourage you to enhance your profile and consider reapplying after six months. Our team is happy to offer feedback and assistance to help you improve your chances of approval in the future.",
},
{
id: 6,
question: "What information should I include in my gallery's portfolio?",
answer:
"Your portfolio should detail your gallery’s history, including artworks, exhibitions, awards, and other recognitions. Be sure to include the names, years, and locations of these experiences. You will also be asked to upload your gallery’s artist artworks.",
},
{
id: 7,
question: "How many years of experience should I have to join Art Smiley?",
answer:
"There is no minimum requirement for years of experience, but you will need to specify how many years your gallery has been operating during the sign-up process.",
},
{
id: 8,
question: "Can I join Art Smiley if my gallery is represented by other online platforms?",
answer:
"Yes, you can. During the onboarding process, you will be asked to provide the URL links to other online platforms where your gallery is represented.",
},
{
id: 9,
question: "Do I need to have an online portfolio or social media presence?",
answer:
"No, including an online portfolio and social media links such as LinkedIn, Facebook, Instagram, Twitter, and YouTube is not mandatory as part of the onboarding process. However, providing these links can help us better assess your gallery’s online visibility and engagement.",
},
{
id: 10,
question: "What benefits do I receive as a registered gallery on Art Smiley?",
answer:
"As a registered gallery, you will have access to exclusive virtual auctions and exhibitions, to promote your artwork, networking opportunities, and insights/analytics to track your artwork’s performance.",
},
{
id: 11,
question: "How can I stay updated with the latest news and opportunities on Art Smiley?",
answer:
"We invite you to subscribe to our newsletter to receive exclusive updates, special offers, and the latest news about new collections and events. You can manage your preferences or unsubscribe at any time.",
},
{
id: 12,
question: "What is the payment process for galleries?",
answer:
"As a gallery, you will receive your payment for artwork through flexible options. Payments will be processed and transferred to your account within 3 to 4 weeks following the successful delivery of the artwork. You can choose your payment either through Paypal or direct bank transfer.",
},
{
id: 13,
question: "What is the return policy for sold artwork?",
answer:
"If the buyer wishes to return the artwork, Art Smiley will manage the return process. In such cases, we will coordinate with you on how to handle the <a href='return-policy'>return</a> and refund process.",
},
{
id: 14,
question: "What Types of Artwork Can Galleries Offer on Art Smiley?",
answer:
"Art Smiley welcomes a diverse range of artwork from galleries, providing a platform for various creative expressions. Accepted works include original paintings, illustrations, photography, digital artwork, and sculptures. Galleries can also list both open and limited edition prints, as well as unique handcrafted items or mixed media pieces. This wide range of accepted formats allows galleries to showcase their unique collections and reach a broader audience through Art Smiley.",
},
{
id: 15,
question: "What is the process for shipping artwork?",
answer:
"When a piece is sold, galleries receive an email with all order details. By logging into your dashboard, accept the artwork order and arrange a pickup with Art Smiley’s designated courier service. Shipping labels are available for download within the dashboard, ready to be printed and affixed to the package. Buyers are responsible for covering shipping fees; however, galleries need to ensure the artwork is packaged according to Art Smiley’s guidelines. Detailed packaging instructions are available here[packaging guidelines link].",
},
{
id: 16,
question: "What are the best practices for packaging artwork?",
answer:
"Galleries should use packaging tailored to the specific type of artwork to ensure safe transport. Flat pack boxes are ideal for small framed pieces, such as prints and photographs. For rolled canvases, tube packaging provides solid protection against bending. Larger or fragile items, like sculptures or oversized framed artworks, should be shipped in custom wooden crates for maximum safety. Regardless of the chosen packaging method, all items should be wrapped in bubble wrap or foam to prevent scratches and damage. For more detailed instructions, please refer to our packaging guidelines here [packaging guidelines link].",
},
{
id: 17,
question: "How does Art Smiley’s commission structure work for gallery sales?",
answer:
"Art Smiley has a structured commission model tailored to different types of gallery transactions: Sales: For each artwork sold, Art Smiley retains a commission of [dynamic value%], with the gallery keeping the remaining [dynamic value%]. Rentals: In cases where artwork is rented, Art Smiley charges a [dynamic value%] commission, allowing the gallery to keep [dynamic value%] of the rental revenue. Print Sales: For any sales of art prints, Art Smiley deducts a [dynamic value%] commission, with the remaining [dynamic value%] going to the gallery.",
},
{
id: 18,
question: "How Does the Artwork Rental Program Work for Galleries on Art Smiley?",
answer:
"Art Smiley’s artwork rental service allows galleries to offer their collections on a temporary basis, providing clients with the chance to experience art in their spaces without a long-term commitment. Galleries can set a monthly rental rate for each piece, which enables clients to enjoy the artwork for a specified period. Should a client decide to purchase the piece, 50% of each rental payment they’ve made can be credited toward the final sale price. This program not only generates recurring revenue for galleries but also enhances accessibility for art lovers exploring different pieces before committing to a purchase. Art Smiley retains a [dynamic value%] commission on each rental, with the rest going to the gallery.",
},
{
id: 19,
question: "How Will I Be Notified When My Artwork is Sold?",
answer:
"When one of your artworks sells on Art Smiley, you’ll receive an email notification with all the relevant details. Simply log in to your gallery dashboard to confirm the order and arrange for shipment. After accepting the order, schedule a pickup date and prepare the artwork for safe transport. For rolled canvases, package the piece securely by wrapping it in bubble wrap and placing it in a durable tube sealed at both ends. For framed pieces, flat-pack boxes are recommended to prevent bending or creasing. You can monitor the shipment status through your dashboard. Once the buyer confirms receipt and the 14-day return period has elapsed, Art Smiley will process your payment within 3 to 4 weeks.",
},
{
id: 20,
question: "How Should I Price My Original Works?",
answer:
"When pricing original pieces on Art Smiley, it's essential to consider both your target earnings and the platform's commission rate, currently set at [dynamic value%]. Make sure your prices incorporate this commission, allowing you to reach your financial goals. Thoughtfully pricing your work will ensure competitive rates on the platform while aligning with your gallery’s revenue objectives.",
},
{
id: 21,
question: "What Are Limited Edition Prints?",
answer:
"Limited edition prints are exclusive reproductions of an artwork, produced in a set, finite quantity. Each print in the edition is typically signed and numbered, enhancing its authenticity and collectible value. The limited availability of these prints adds a sense of rarity, making them particularly appealing to collectors and art enthusiasts. This exclusivity not only increases their desirability but also often contributes to a higher long-term value compared to open editions, offering a unique opportunity to own a piece of art that holds both artistic and investment appeal.",
},
{
id: 22,
question: "What Are Open Edition Prints?",
answer:
"Open edition prints are art reproductions that are not limited in quantity, there are no limitations on the number of copies that can be produced. Unlike limited edition prints, which are released in restricted numbers, open editions are continuously available, allowing for broader accessibility. This unlimited approach makes the artwork more affordable and reachable to a wider audience, providing art lovers with a high-quality reproduction that captures the original's essence without the exclusivity tied to limited editions.",
},
];

const exclusive_data = [
   {
     id: 1,
     src: "/img/artcollections",
     heading: "Global Exposure Through Premier Exhibitions & Auctions",
     des: "Secure exclusive physical and virtual exhibition slots to place your artists in front of a worldwide audience. Gain priority access to high-profile auctions, ensuring your collection attracts serious art buyers and investors, elevating both visibility and value.",
    },
   {
     id: 2,
     src: "/img/artcollections",
     heading: "Featured & Prestige Gallery Status",
     des: "Art Smiley carefully selects and promotes standout galleries, offering premium placement, branding, and marketing to elevate your profile. We ensure targeted visibility for your collection, making your gallery a go-to destination for serious art buyers.",
    },
   {
     id: 3,
     src: "/img/artcollections",
     heading: "Showcase Best-Selling & Highly Recommended Artworks",
     des: "Leverage our data-driven insights to feature in-demand, top-performing pieces in our “Most Popular & Recommended” section. This exclusive curation increases desirability, boosts sales, and strengthens your gallery’s presence in the market.",
    },
   {
     id: 4,
     src: "/img/artcollections",
     heading: "Hassle-Free Logistics & Sales Management",
     des: "Art Smiley provides end-to-end sales support, including pricing strategy, buyer negotiations, secure transactions, and worldwide shipping logistics—allowing you to focus on expanding your collection while we handle the details.",
    },
 ];
function HomeGallery() {
const [expanded, setExpanded] = React.useState(false);
const navigate = useNavigate();
const dispatch = useDispatch();
const [email, setEmail] = useState("");
const handleChange = (panel) => (event, isExpanded) => {
setExpanded(isExpanded ? panel : false);
};
const handleSignupButton = () => {

navigate("/gallery/applynow")
};
const handleChangeSubscription = (e) => {
setEmail(e.target.value);
};
const handleSubmit = () => {
dispatch(AddMailSubscription({ email: email }, "st", setEmail));
};
const [exclusiveExpanded, setExclusiveExpanded] = React.useState('excpanel0'); // Default to the first panel
const handleExclusiveChange = (panel) => (event, isExpanded) => {
setExclusiveExpanded(isExpanded ? panel : false);
};
const [subscriptionExpanded, setSubscriptionExpanded] = React.useState("excpanel0"); // Default to the first panel open
const handleSubscriptionChange = (panel) => (event, isExpanded) => {
setSubscriptionExpanded(isExpanded ? panel : false);
};
const [substandardscriptionExpanded, setStandardsubscriptionExpanded] = React.useState("excpanel0"); // Default to the first panel open
const handleStandardsubscriptionChange = (panel) => (event, isExpanded) => {
setStandardsubscriptionExpanded(isExpanded ? panel : false);
};
const [subpremiumscriptionExpanded, setPremiumsubscriptionExpanded] = React.useState("excpanel0"); // Default to the first panel open
const handlePremiumsubscriptionChange = (panel) => (event, isExpanded) => {
setPremiumsubscriptionExpanded(isExpanded ? panel : false);
};
const [subfaqExpanded, setFaqExpanded] = React.useState("excpanel0"); // Default to the first panel open
const handleFaqChange = (panel) => (event, isExpanded) => {
setFaqExpanded(isExpanded ? panel : false);
};

const handleNavigate = (route) => {
   if (route.includes("https")) {
     window.open(route, "_blank");
   } else {
    // console.log(route,'route')
     if(route === "/search") {
       navigate(`/user${route}`, {state: {itemType: "print"}});
       window.scrollTo({ top: 0, behavior: "smooth" });
     }else {
       navigate(`/user${route}`);
       window.scrollTo({ top: 0, behavior: "smooth" });
     }
   }
 };

return (
<>
<AlertsSuccess />
{/* 
<Header />
<Navbar />
*/}
<React.Fragment>
   <CssBaseline />
   <section className={styles.hero_main_gallery}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
         <div className={`${styles.pageitle} ${styles.max_width}`}>
         <h1>Grow Your Gallery's Online Presence
         </h1>
         <button
            onClick={handleSignupButton}
            variant="contained"
            className="apply_ltbtn"
            >
        Apply Now
         </button>
         </div>
      </Container>
   </section>
   <section className={styles.sec_sc}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
         <div className={`${styles.text_center} ${styles.max_width}`}>
         <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
         <h3 className={styles.sec_title}>Be a Part of Visionary Gallery Community</h3>
         <p className={styles.withor}>Showcase your Gallery artwork in front of art enthusiasts looking to discover emerging and established talents. Experience the benefits of reaching art lovers beyond your current network.</p>
        
         </div>
         <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
               <div className={styles.stboxh}>
                  <div className={styles.stvob}>
                     <div className={styles.sticon}>
                        <img
                           className={styles.img_fluid}
                           src={"/img/curatorsignup.svg"}
                           alt="img"
                           />  
                     </div>
                     <h4>Targeted Marketing</h4>
                     <div className={styles.stcont}>
                     <p>Art Smiley collaborates with galleries to create focused campaigns that connect with buyers interested in each gallery's unique artwork styles.</p>
                     </div>
                  </div>
               </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
               <div className={styles.stboxh}>
                  <div className={styles.stvob}>
                     <div className={styles.sticon}>
                        <img
                           className={styles.img_fluid}
                           src={"/img/curatorsignup.svg"}
                           alt="img"
                           />  
                     </div>
                     <h4>Global Visibility</h4>
                     <div className={styles.stcont}>
                     <p>Art Smiley helps galleries expand their reach beyond in-gallery visits. By showcasing the artwork over a global audience, we increase the potential of art sales and open up new opportunities.</p>
                     </div>
                  </div>
               </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
               <div className={styles.stboxh}>
                  <div className={styles.stvob}>
                     <div className={styles.sticon}>
                        <img
                           className={styles.img_fluid}
                           src={"/img/curatorsignup.svg"}
                           alt="img"
                           />  
                     </div>
                     <h4>Fair Earnings</h4>
                     <div className={styles.stcont}>
                     <p>Art Smiley charges a commission of 40% on each sale. As a registered gallery, you maintain full pricing control for your artists' works, allowing you to set prices that ensure fair earnings.</p>
                     </div>
                  </div>
               </div>
            </Grid>
         </Grid>
      </Container>
   </section>
   <section className={styles.sec_scexcl}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width1}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Ascend to New Achievements</h3>
          </div>
          <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
          
          <Grid item md={6} sm={6} xs={12}>
          <Grid container spacing={3}>
          {data.map((val, index) => (
                <Grid item xs={12} sm={6} md={6} key={val.id} >
                  <div  key={index} className={styles.stboxbn}>
                <div className={styles.hbox1}>
                  <div className={styles.idnumbr}>{val.icon}</div>
                  <h4>{val.heading}</h4>
                  <div className={styles.stcont}>
                    {/* <p>{val.des}</p> */}
                    <div
      className={`${styles.textContent1}`}
       
      dangerouslySetInnerHTML={{ __html: val.content }}
    />
    
    
    </div>
                </div>
                </div>
                </Grid>
          ))}
            </Grid>
          </Grid>
          <Grid item md={5} sm={6} xs={12}>
          <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
          </Grid>
          </Grid>
          <div className={styles.btnmt2}><button onClick={handleSignupButton} class="apply_btn">Sign Up</button></div>
          </div>
           
          </Container>
          </section>
     <section className={styles.sec_sc}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
         <div className={`${styles.text_center} ${styles.max_width}`}>
         <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
         <h3 className={styles.sec_title}>Power Up Your Path to Sales Excellence Registration Process </h3>
         <p className={styles.withor}>Register your gallery on the Art Smiley platform to start promoting your artist’s works to a global audience. Once your application is reviewed and approved, you can access all the features.</p>
         </div>
         <div className={styles.spcebtnm}>
          <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
          <Grid item md={5} sm={6} xs={12}>
          <div>
              <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
              </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
                  <div className={styles.whibg}>
                    <ul className={styles.timeline}>
                      <li>
                      <div className={styles.stepli}>
                    <h4>Portfolio Upload</h4>
                     <p>From your personalized gallery dashboard, you can upload artwork of your artists, creating a virtual platform that showcases your unique selection.</p>
                    </div>
                      </li>
                      <li>
                      <div className={styles.stepli}>
                    <h4>Subscription Plans</h4>
                     <p>Start with our complimentary plan and, as your gallery’s reach expands, consider our flexible paid plans designed to meet your gallery's evolving goals and promotional needs.</p>
                    </div>
                      </li>
                      <li>
                      <div className={styles.stepli}>
                    <h4>Smart Dashboard</h4>
                     <p>The comprehensive dashboard allows you to monitor total artwork listings, orders, quote requests, inquiries, exhibitions, auctions, likes, followers, and total earnings, all in one efficient platform. This helps you focus on growth and maximizing visibility.</p>
                     </div>
                      </li> 
                    </ul>
                    
                  </div>
            </Grid>
            </Grid>
             
            </div>
         
      </Container>
   </section>
   {/* <section className={styles.sec_sc}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
         <div className={`${styles.text_center} ${styles.max_width1}`}>
         <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
         <h3 className={styles.sec_title}>Testimonials</h3>
         </div>
         <section className={styles.sections}>
            <Grid container spacing={2}  alignItems="center">
               <Grid item md={6} xs={12}>
                  <div className={styles.testmibox}>
                     <Grid container spacing={2}  alignItems="center">
                        <Grid item md={3} xs={12}>
                           <div className={styles.testimonial_img_div}>
                              <img
                                 src={"/img/event3.png"}
                                 width="120px"
                                 height="120px"
                                 alt=""
                                 />
                           </div>
                        </Grid>
                        <Grid item md={9} xs={12}>
                           <div className={styles.tecont}>
                              <div className={styles.test_metainfo}>
                                 <h5 className={styles.test_card_heading}>
                                    Albert Didier
                                 </h5>
                                 <h6 className={styles.test_card_subhead}>
                                    Euismod ipsum
                                 </h6>
                              </div>
                              <div className={styles.test_cont}>
                                 <p className={styles.test_card_des}>In purus at morbi magna in in maecenas. Nunc nulla magna
                                    elit, varius phasellus blandit convallis.
                                 </p>
                              </div>
                           </div>
                        </Grid>
                     </Grid>
                  </div>
               </Grid>
               <Grid item md={6} xs={12}>
                  <div className={styles.testmibox}>
                     <Grid container spacing={2}  alignItems="center">
                        <Grid item md={3} xs={12}>
                           <div className={styles.testimonial_img_div}>
                              <img
                                 src={"/img/event3.png"}
                                 width="120px"
                                 height="120px"
                                 alt=""
                                 />
                           </div>
                        </Grid>
                        <Grid item md={9} xs={12}>
                           <div className={styles.tecont}>
                              <div className={styles.test_metainfo}>
                                 <h5 className={styles.test_card_heading}>
                                    Albert Didier
                                 </h5>
                                 <h6 className={styles.test_card_subhead}>
                                    Euismod ipsum
                                 </h6>
                              </div>
                              <div className={styles.test_cont}>
                                 <p className={styles.test_card_des}>In purus at morbi magna in in maecenas. Nunc nulla magna
                                    elit, varius phasellus blandit convallis.
                                 </p>
                              </div>
                           </div>
                        </Grid>
                     </Grid>
                  </div>
               </Grid>
            </Grid>
         </section>
      </Container>
   </section> */}
   <section className={styles.sec_scexcl}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
         <div className={`${styles.text_center} ${styles.max_width}`}>
         <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
         <h3 className={styles.sec_title}>Transforming Galleries with Art Smiley's Seamless Services</h3>
         <p className={styles.withor}>Art Smiley specializes in empowering art galleries to streamline their curation process and attract diverse audiences. Art Smiley’s services are tailored to meet the unique needs of global clients.</p>
         </div>
         <div className={styles.spcebtnm}>
            <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
            <Grid item md={6} sm={6} xs={12}>
               <div>
                  <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
               </div>
            </Grid>
            <Grid item md={5} sm={6} xs={12}>
               <div className={` ${styles.accordsc}`}>
               {service_data.map((val, index) => {
               const panelId = `excpanel${index}`; // Dynamic panel ID
               return (
               <Accordion
               key={panelId}
               expanded={exclusiveExpanded === panelId} // Check if the panel is expanded
               onChange={handleExclusiveChange(panelId)}
               sx={{
               "& .MuiAccordionSummary-contentGutters": {
               margin: "10px 0", // Adjust as needed
               },
               "&.MuiPaper-root": {
               boxShadow: "none",
               borderBottom: "1px solid #D9DBE9",
               marginBottom:"0",
               borderBottomLeftRadius:"0",
               borderBottomRightRadius:"0",
               },
               "&.MuiAccordion-root::before":{
               display:"none",
               },
               "& .Mui-expanded": {
                  minHeight:"auto !important",
                  margin:"0 0 10px 0 !important"
               },
               "& .MuiAccordionSummary-expandIconWrapper":{
               background:"#B7B7B7",
               opacity:".3",
               textAlign: "center",
               borderRadius:"8px",
               },
               "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
               background:"#1C1C1C",
               opacity:"1",
               color:"#fff",
               },
               "& .MuiAccordionDetails-root":{
               paddingTop:"0"
               },
               }}// Handle expansion state
               >
               <AccordionSummary
               expandIcon={
               <ExpandMoreIcon />
               }
               aria-controls={`${panelId}-content`}
               id={`${panelId}-header`}
               >
               <h4 className={styles.benfhdgn}>{val.heading}</h4>
               </AccordionSummary>
               <AccordionDetails>
                  <p className={styles.bendesc}>{val.des}</p>
               </AccordionDetails>
               </Accordion>
               );
               })}
         </div>
         </Grid>
         </Grid>
         </div>
         <div className={styles.btn_div1}>
            <button
               variant="contained"
               onClick={handleSignupButton}
               className="apply_btn"
               >
            Apply Now
            </button>
         </div>
      </Container>
   </section>
   <section className={styles.sec_sc}>
          <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Special Benefits for Art Galleries</h3>
            </div>
            <div className={styles.taldartist}>
            <Grid container spacing={2}>
            {exclusive_data.map((val) => (
            <Grid item xs={12} sm={6} md={6} key={val.id}>
              <div className={styles.talhg}>
                <div className={styles.talgbx}>
                <div className={styles.talenimg}>
                    <img
                    alt="img"
                    src={`${val.src}.png`}
                    className="img-fluid"
                  />
                    </div>
                    <div className={styles.talentcont}>
                      <h4>{val.heading}</h4>
                      <p>{val.des}</p>
                    </div>
                </div>
              </div> 
            </Grid>
            ))}
            </Grid>
            </div>
             
          </Container>
          </section>
   {/* <section className={styles.subscriptionbg}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
         <div className={`${styles.text_center} ${styles.max_width}`}>
         <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
         <h3 className={`${styles.text_white} ${styles.sec_title}`}> Gallery Subscription Plans</h3>
         <p className={`${styles.text_white} ${styles.withor}`}>Advance your creative path with Art Smiley’s flexible subscription tiers, designed to support your unique artistic goals. Choose the plan that resonates with your vision and start presenting your work to a global audience today!</p>
         </div>
         <div className={styles.plansec}>
            <Grid container spacing={2} sx={{justifyContent:"center"}}>
               <Grid item md={4} sm={6} xs={12}>
                  <div className={` ${styles.accordsc}`}>
                  <h3 className={styles.sucrhndg}>Basic</h3>
                  <div className={styles.prisec}>
                     <p className={styles.subsprice}>Free </p>
                  </div>
                  {gallery_subscription_data.map((val, index) => {
                  const panelId = `excpanel${index}`;  
                  return (
                  <Accordion
                  key={panelId}
                  expanded={subscriptionExpanded === panelId}  
                  onChange={handleSubscriptionChange(panelId)}
                  sx={{
                  "& .MuiAccordionSummary-contentGutters": {
                  margin: "10px 0", 
                  },
                  "&.MuiPaper-root": {
                  boxShadow: "none",
                  borderBottom: "1px solid #D9DBE9",
                  marginBottom:"0",
                  borderBottomLeftRadius:"0",
                  borderBottomRightRadius:"0",
                  },
                  "&.MuiAccordion-root::before":{
                  display:"none",
                  },
                  "& .Mui-expanded": {
                     minHeight:"auto !important",
                     margin:"0 0 10px 0 !important"
                  },
                  "& .MuiAccordionSummary-expandIconWrapper":{
                  background:"#B7B7B7",
                  opacity:".3",
                  textAlign: "center",
                  borderRadius:"8px",
                  },
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
                  background:"#1C1C1C",
                  opacity:"1",
                  color:"#fff",
                  },
                  "& .MuiAccordionDetails-root":{
                  paddingTop:"0"
                  },
                  }}
                  >
                  <AccordionSummary
                  expandIcon={
                  <ExpandMoreIcon />
                  }
                  aria-controls={`${panelId}-content`}
                  id={`${panelId}-header`}
                  >
                  <h4 className={styles.scbhdrng}>{val.heading}</h4>
                  </AccordionSummary>
                  <AccordionDetails>
                     <p className={styles.bendesc}>{val.des}</p>
                  </AccordionDetails>
                  </Accordion>
                  );
                  })}
         </div>
         </Grid>
         <Grid item md={4} sm={6} xs={12}>
         <div className={`${styles.accordsc} ${styles.premimd}`}>
         <h3 className={`${styles.sucrhndg} ${styles.text_white}`}>Standard</h3>
         <div className={styles.prisec}>
         <p className={styles.subsprice}><span className={`${styles.pric} ${styles.text_white}`}>$29</span> <span className={`${styles.predg} ${styles.text_white}`}>/month</span> </p>
         <p className={`${styles.accorscc} ${styles.subsprice} `} ><span className={`${styles.pric} ${styles.text_white}`}>$276</span> <span className={`${styles.predg} ${styles.text_white}`}>/year</span> </p>
         </div>
         <p className={styles.incl} style={{color:"#fff"}}>Includes all Basic Features plus:</p>
         {standard_subscription.map((val, index) => {
         const panelId = `excpanel${index}`;
         return (
         <Accordion
         key={panelId}
         expanded={substandardscriptionExpanded === panelId}
         onChange={handleStandardsubscriptionChange(panelId)}
         sx={{
         "& .MuiAccordionSummary-contentGutters": {
         margin: "10px 0",
         },
         "&.MuiPaper-root": {
         boxShadow: "none",
         borderBottom: "1px solid #D9DBE9",
         marginBottom:"0",
         background:"transparent",
         borderBottomLeftRadius:"0",
         borderBottomRightRadius:"0",
         },
         "&.MuiAccordion-root::before":{
         display:"none",
         },
         "& .Mui-expanded": {
            minHeight:"auto !important",
            margin:"0 0 10px 0 !important"
         },
         "& .MuiAccordionSummary-expandIconWrapper":{
         background:"#fff",
         opacity:"1",
         textAlign: "center",
         borderRadius:"8px",
         },
         "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
         background:"#fff",
         opacity:"1",
         color:"#1C1C1C",
         },
         "& .MuiAccordionDetails-root":{
         paddingTop:"0"
         },
         }}
         >
         <AccordionSummary
         expandIcon={<ExpandMoreIcon />}
         aria-controls={`${panelId}-content`}
         id={`${panelId}-header`}
         >
         <h4 className={`${styles.scbhdrng} ${styles.text_white}`}>{val.heading}</h4>
         </AccordionSummary>
         <AccordionDetails>
         <p className={`${styles.bendesc} ${styles.text_white}`}>{val.des}</p>
         </AccordionDetails>
         </Accordion>
         );
         })}
         </div>
         </Grid>
         <Grid item md={4} sm={6} xs={12}>
         <div className={`${styles.accordsc}`}>
         <h3 className={`${styles.sucrhndg}`}>Premium</h3>
         <div className={styles.prisec}>
         <p className={styles.subsprice}><span className={styles.pric}>$59</span> <span className={styles.predg} style={{color:"#848199"}}>/month</span> </p>
         <p className={`${styles.accorscc} ${styles.subsprice}`} ><span className={styles.pric}>$564</span> <span className={styles.predg} style={{color:"#848199"}}>/year</span> </p>
         </div>
         <p className={styles.incl}>Includes all Standard Features plus:</p>
         {premium_subscription.map((val, index) => {
         const panelId = `excpanel${index}`;
         return (
         <Accordion
         key={panelId}
         expanded={subpremiumscriptionExpanded === panelId}
         onChange={handlePremiumsubscriptionChange(panelId)}
         sx={{
         "& .MuiAccordionSummary-contentGutters": {
         margin: "10px 0",
         },
         "&.MuiPaper-root": {
         boxShadow: "none",
         borderBottom: "1px solid #D9DBE9",
         marginBottom:"0",
         borderBottomLeftRadius:"0",
         borderBottomRightRadius:"0",
         },
         "&.MuiAccordion-root::before":{
         display:"none",
         },
         "& .Mui-expanded": {
            minHeight:"auto !important",
            margin:"0 0 10px 0 !important"
         },
         "& .MuiAccordionSummary-expandIconWrapper":{
         background:"#B7B7B7",
         opacity:".3",
         textAlign: "center",
         borderRadius:"8px",
         },
         "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
         background:"#1C1C1C",
         opacity:"1",
         color:"#fff",
         },
         "& .MuiAccordionDetails-root":{
         paddingTop:"0"
         },
         }}
         >
         <AccordionSummary
         expandIcon={<ExpandMoreIcon />}
         aria-controls={`${panelId}-content`}
         id={`${panelId}-header`}
         >
         <h4 className={styles.scbhdrng}>{val.heading}</h4>
         </AccordionSummary>
         <AccordionDetails>
         <p className={styles.bendesc}>{val.des}</p>
         </AccordionDetails>
         </Accordion>
         );
         })}
         </div>
         </Grid>
         </Grid>
         </div>
         <div className={styles.btn_div1}>
            <button
               variant="contained"
               onClick={handleSignupButton}
               className="apply_ltbtn"
               >
            Apply Now
            </button>
         </div>
      </Container>
   </section> */}
   <section className={styles.sec_scexcl}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
         <div className={`${styles.text_center} ${styles.max_width}`}>
         <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
         <h3 className={styles.sec_title}>Gallery Dashboard Features</h3>
         </div>
         <div className={styles.spcebtnm}>
            <Grid container spacing={2} sx={{justifyContent:"space-between", alignItems:"center"}}>
            <Grid item md={6} sm={6} xs={12}>
               <div>
                  <img src={"/img/Mockup.png"} alt="mockup" className="img-fluid" />
               </div>
            </Grid>
            <Grid item md={5} sm={6} xs={12}>
               <div className={` ${styles.accordsc}`}>
               {dashboardfeature_data.map((val, index) => {
               const panelId = `excpanel${index}`; // Dynamic panel ID
               return (
               <Accordion
               key={panelId}
               expanded={exclusiveExpanded === panelId} // Check if the panel is expanded
               onChange={handleExclusiveChange(panelId)}
               sx={{
               "& .MuiAccordionSummary-contentGutters": {
               margin: "10px 0", // Adjust as needed
               },
               "&.MuiPaper-root": {
               boxShadow: "none",
               borderBottom: "1px solid #D9DBE9",
               marginBottom:"0",
               borderBottomLeftRadius:"0",
               borderBottomRightRadius:"0",
               },
               "&.MuiAccordion-root::before":{
               display:"none",
               },
               "& .Mui-expanded": {
                  minHeight:"auto !important",
                  margin:"0 0 10px 0 !important"
               },
               "& .MuiAccordionSummary-expandIconWrapper":{
               background:"#B7B7B7",
               opacity:".3",
               textAlign: "center",
               borderRadius:"8px",
               },
               "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
               background:"#1C1C1C",
               opacity:"1",
               color:"#fff",
               },
               "& .MuiAccordionDetails-root":{
               paddingTop:"0"
               },
               }}// Handle expansion state
               >
               <AccordionSummary
               expandIcon={
               <ExpandMoreIcon />
               }
               aria-controls={`${panelId}-content`}
               id={`${panelId}-header`}
               >
               <h4 className={styles.benfhdgn}>{val.heading}</h4>
               </AccordionSummary>
               <AccordionDetails>
                  <p className={styles.bendesc}>{val.des}</p>
               </AccordionDetails>
               </Accordion>
               );
               })}
         </div>
         </Grid>
         </Grid>
         </div> 
      </Container>
   </section>
   <section className={styles.sec_sc}>
      <Container maxWidth="xl" sx={{
    paddingLeft: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
    paddingRight: { xs: '16px', sm: '24px', md:'60px', lg: '70px', xl: '100px' },
  }}>
   <div className={`${styles.text_center} ${styles.max_width}`}>
         <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
         <h3 className={` ${styles.sec_title}`}> FAQ</h3>
         </div>
         <div className={` ${styles.accordsc} ${styles.faqx}`}>
         {FAQs.map((val, index) => {
         const panelId = `excpanel${index}`;
         return ( // Explicit return statement is necessary
         <Accordion
         key={panelId}
         expanded={subfaqExpanded === panelId}
         onChange={handleFaqChange(panelId)}
         sx={{
         "& .MuiAccordionSummary-contentGutters": {
         margin: "10px 0",
         },
         "&.MuiPaper-root": {
         boxShadow: "none",
         borderBottom: "1px solid #D9DBE9",
         marginBottom: "0",
         borderBottomLeftRadius:"0",
         borderBottomRightRadius:"0",
         },
         "&.MuiAccordion-root::before": {
         display: "none",
         },
         "& .Mui-expanded": {

            minHeight:"auto !important",
            margin:"0 0 10px 0 !important"
         },
         "& .MuiAccordionSummary-expandIconWrapper": {
         background: "#B7B7B7",
         opacity: ".3",
         textAlign: "center",
         borderRadius: "8px",
         },
         "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
         background: "#1C1C1C",
         opacity: "1",
         color: "#fff",
         },
         "& .MuiAccordionDetails-root": {
         paddingTop: "0",
         },
         }}
         >
         <AccordionSummary
         expandIcon={
         <ExpandMoreIcon />
         }
         aria-controls={`${panelId}-content`}
         id={`${panelId}-header`}
         >
         <h3 className={styles.faqhdr}>{val.question}</h3>
         </AccordionSummary>
         <AccordionDetails>
         <div className={styles.acpra} dangerouslySetInnerHTML={{ __html: val.answer }} />
            {/* <p>{val.answer}</p> */}
         </AccordionDetails>
         </Accordion>
         );
         })}
         </div>
         <div className={styles.btnx_div}>
                                    <button
                                      variant="contained"
                                      onClick={() => handleNavigate('/faq?category=galleries')}
                                      className="apply_btn"
                                    >More FAQ's
                                    </button>
                                  </div>
      </Container>
   </section>
</React.Fragment>
{/* 
<Footer />
*/}
</>
);
}
export default HomeGallery;