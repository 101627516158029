import React, { useEffect, useState } from "react";
import Fivecoltable from "../../../../artist/commonTables/FivecolimgArtist/Fivecoltable";
import TableheaderArtist from "../../../../artist/commonTables/TableheaderArtist/TableheaderArtist";
import styles from "./Commonenquirygallery.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Pagination, PaginationItem } from "@mui/material";
import AddInquirypopup from "../../../../artist/pages/enquiry/popup/AddInquirypopup";
const filterdata = [
  { label: "Open", value: "Open" },
  { label: "Closed", value: "Closed" },
];
const sortdata = [
  { label: "Old to new", value: "low" },
  { label: "New to old", value: "high" },
];
export default function Commonenquirygallery(props) {
  
  const [search, setSearch] = useState("");
  const [condition, setCondition] = useState({
    sorting: "",
    filter: "",
  });
  console.log("condition", condition);
  const [button, setButton] = useState(""); // State must be declared at the top level

  useEffect(() => {
    if (props.artistinquiry === "artistinquiry") {
      setButton("add");
    }
  }, [props.artistinquiry]); // Effect runs when props.artistinquiry changes

  const handleFilter = (event) => {
    const value = event.target.value;
    setCondition({ ...condition, filter: value });
  };
  const handleSorting = (event) => {
    const value = event.target.value;
    setCondition({ ...condition, sorting: value });
  };
  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust how many items you want per page

  // Function to handle page changes
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    window.scrollTo({
      top: 0, // Scroll to the top of the page
      behavior: "smooth", // Smooth scrolling effect
    });
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = props?.data?.slice(startIndex, endIndex);

  const [Open, setOpen] = useState(false);
  
  return (
    <div>
      <div>{Open ? <AddInquirypopup Open={Open} setOpen={setOpen} /> : ""}</div>
      <TableheaderArtist
        setOpen={setOpen}
        handleFilter={handleFilter}
        button={button}
        search={search}
        condition={condition}
        filterdata={filterdata}
        sortdata={sortdata}
        handleSearch={handleSearch}
        handleSorting={handleSorting}
        enquiry="enquiry"
      />
      <Fivecoltable
        activetab="gallery_enquiry"
        artistdate="artistdate"
        head={props.head}
        data={currentItems
          ?.filter((val) => {
            if (search === "") {
              return val;
            } 
            else {
              return(
                val.customer_name.toLowerCase().includes(search.toLowerCase()) ||
                val.enquiry_id.toLowerCase().includes(search.toLowerCase())||
                val.enquiry_type.toLowerCase().includes(search.toLowerCase())
              )
            }
           
          })
          .sort((val1) => {
            // Apply "Open" filter when condition.sorting is "Open"
            if (condition.filter === "Open") {
              return val1.is_open === "Open";
            } else if (condition.filter === "Close") {
              return val1.is_open === "Close";
            }
            return true; // Include all if condition.sorting is not "Open"
          })
          .sort((a, b) => {
            if (condition.sorting === "low") {
              return new Date(b.createdAt) - new Date(a.createdAt);
            } else if (condition.sorting === "high") {
              return new Date(a.createdAt) - new Date(b.createdAt); // Sort by oldest
            }
            return 0; // Default case: no sorting
          })}
      />
      <Box className={styles.pagination_box} sx={{}}>
        <Pagination
          count={Math.ceil(props?.data?.length / itemsPerPage)} // Total pages
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          renderItem={(item) => (
            <PaginationItem
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "black", // Set the selected background to black
                  color: "white", // Ensure the text color is visible
                  "&:hover": {
                    backgroundColor: "black", // Maintain black on hover
                  },
                },
              }}
              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...item}
            />
          )}
        />
      </Box>
    </div>
  );
}
