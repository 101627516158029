import validator from "validator"
import isEmpty from "./isEmpty"
import { PhoneNumberUtil } from 'google-libphonenumber';

export const validationForContactUs = ( {   first_name,
    last_name,
    phone,
    email,
    message,
    captcha

}) => {

  const phoneUtil = PhoneNumberUtil.getInstance();
  const isPhoneValid = (phone_no) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone_no, "IN")); // Adjust region as needed
    } catch (error) {
      return false;
    }
  };
  let errors = {}

  if (validator.isEmpty(first_name)) {
    errors.first_name = "First name is required";
  } else if (!validator.isAlpha(first_name, "en-US")) {
    errors.first_name = "First name must contain only alphabetic characters";
  }

  // Validate Last Name
  if (validator.isEmpty(last_name)) {
    errors.last_name = "Last name is required";
  } else if (!validator.isAlpha(last_name, "en-US")) {
    errors.last_name = "Last name must contain only alphabetic characters";
  }
  if (captcha === false) {
    errors.captcha = "Captcha is required";
  }

 
  if (validator.isEmpty(email)) {
    errors.email = "Email is required"
  } else if(!validator.isEmail(email)){
    errors.email = "Email is invalid"
  }

  if (!phone || !isPhoneValid(phone)) {
    errors.phone = "Invalid Phone Number format";
  }


  if(validator.isEmpty(message)){
    errors.message
     = "Message is required"
  }

  return {
    errors,
    isValidAddress: isEmpty(errors),
  }
}
