import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AlertsSuccess } from "../../../../common/alert/Alert"
import {
  deleteSubadmin,
  editedSubadmin,
  editSubadmin,
  fetchSubadminData,
  handleAddSubadmin,
} from "../../../../State/api/superadminApi"
import { setSubadmin } from "../../../../State/Features/superadmin/subadminSlice"
import Pagination from "../Pagination/Pagination"
import Subadminpopup from "../Popupdelete/subAdmin/Subadminpopup"
import Tableheader from "../Table/Tableheader/Tableheader"
import Tablethreecol from "../Table/Threecolmn/Tablethreecol"
import styles from "./Subadmin.module.css"


const upcominghead = [
  {
    id: "like",
    label: "Name",
  },
  {
    id: "followe",
    label: "Email",
  },
  {
    id: "date",
    label: "Created Date",
  },
  {
    id: "location",
    label: "Access Role",
  },
  {
    id:'action',
    label:'Action'
  }
]

const sortdata = [
  { label: "Oldest to Newest", value: "low" },
  { label: "Newest to Oldest", value: "high" },
]
const filterdata = [
  { label: "Name (A-Z)", value: "name-asc" },
  { label: "Name (Z-A)", value: "name-desc" },
]

export default function Subadmin() {
  const [button, setButton] = useState({ type: "add", text: "Add" })
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false)
  const [data, setData] = useState({is_accepted: "subadmin", offset: 1 })
  const [edit, setEdit] = useState(false)
  const [editSubadmin, setEditSubadmin] = useState()
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })
  console.warn("editSubadmin-->",editSubadmin)

  const dispatch = useDispatch()

  const subadminsdata = useSelector((state) => state.subadminData.data)
  console.warn("subadminsData",subadminsdata)
  const fetchData = () => {
    dispatch(fetchSubadminData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])


  const handleAdd = (dt) => {
    console.warn("dt-->",dt)
    dispatch(handleAddSubadmin(dt, setOpen, fetchData))
  }
  const handleEdit = (dt) => {
    setEditSubadmin(dt)
    setEdit(true)
  }
  const handleSubmitEdited = (id, dt) => {
    dispatch(editedSubadmin(id, dt, setEdit, fetchData))
  }
  const handleDelete = (id) => {
    dispatch(deleteSubadmin(id, fetchData))
  }

  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })

    if (value === "name-asc") {
      dispatch(
        setSubadmin({
          data:
            subadminsdata.data &&
            [...subadminsdata.data].sort((a, b) =>
              a.first_name.toLowerCase() > b.first_name.toLowerCase() ? 1 : -1
            ),
          totalCount: subadminsdata.totalCount,
        })
      )
    } else if (value === "name-desc") {
      dispatch(
        setSubadmin({
          data:
            subadminsdata.data &&
            [...subadminsdata.data].sort((a, b) =>
              a.first_name.toLowerCase() < b.first_name.toLowerCase() ? 1 : -1
            ),
          totalCount: subadminsdata.totalCount,
        })
      )
    } else {
      fetchData()
    }
  }

  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }

  const handleSorting = (e) => {
    let value = e.target.value
    if (value === "low") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setSubadmin({
          data:
            subadminsdata.data &&
            [...subadminsdata.data].sort((a, b) => {
              if (a.createdAt.toLowerCase() < b.createdAt.toLowerCase())
                return -1
            }),
          totalCount: subadminsdata.totalCount,
        })
      )
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setSubadmin({
          data:
            subadminsdata.data &&
            [...subadminsdata.data].sort((a, b) => {
              if (a.createdAt.toLowerCase() > b.createdAt.toLowerCase())
                return -1
            }),
          totalCount: subadminsdata.totalCount,
        })
      )
    } else {
      fetchData()
      setCondition({ ...condition, sorting: value })
    }
  }
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Subadminpopup
          handleAdd={handleAdd}
          type=""
          open={open}
          setOpen={setOpen}
          errors={errors}
        />
      ) : (
        ""
      )}
      {edit ? (
        <Subadminpopup
          editSubadmin={editSubadmin}
          handleSubmitEdited={handleSubmitEdited}
          type="edit"
          open={edit}
          setOpen={setEdit}
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          setOpen={setOpen}
          button={button}
        />
        <Tablethreecol
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          handleAdd={handleAdd}
          activetab="subadmin"
          head={upcominghead}
          data={
            subadminsdata &&
            subadminsdata.data.filter((val) => {
              const fullName = `${val.first_name || ""} ${val.last_name || ""}`
                .toLowerCase()
                .trim()
              const search = condition.search.toLowerCase().trim()
              const nameMatch =
                val.first_name && val.first_name.toLowerCase().includes(search)
              const lastnameMatch =
                val.last_name && val.last_name.toLowerCase().includes(search)
              const emailMatch =
                val.email && val.email.toLowerCase().includes(search)
              const fullNameMatch = fullName.includes(search)

              return nameMatch || lastnameMatch || emailMatch || fullNameMatch
            })
          }
        />
        {/* {subadminsdata &&
          Array.isArray(subadminsdata.data) &&
          subadminsdata.data.length > 0 && (
            <Pagination
              totaldata={subadminsdata && subadminsdata.totalCount}
              pagination={data}
              setPagination={setData}
            />
          )} */}
      </div>
    </div>
  )
}
