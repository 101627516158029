import { Button, Typography,Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularLoader from "../../../common/loader/Loader";
import api from "../../../State/api/Api";
import { fetchPrivacyPolicy } from "../../../State/api/userApi";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import styles from "./PolicySidebar.module.css";
import TranslateWrapper from "../../../languageTranslator/TranslateWrapper";
import { useTranslation } from "react-i18next";

const divData = [
  {
    id: 1,
    label: "SHIPPING POLICY",
    value: "shipping__policy",
    url: "api/user/get-shipping-policy",
  },
  {
    id: 2,
    label: "RETURN POLICY",
    value: "return__policy",
    url: "api/user/get-return-policy",
  },

];
function PolicySidebar(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation("productDetails");
  const [policydata, setPolicydata] = useState();
  const [policies, setPolicies] = useState({
    url: "api/user/get-shipping-policy",
    type: "shipping__policy",
  });

  const faqdata = useSelector((state) => state.userFaqData);
  const policiesdata = useSelector((state) => state.policyData);

  const loader = useSelector((state) => state.loader);

  const handleClick = (val) => {
    // SetdivLinks(val);
    setPolicies({ url: val.url, type: val.value });
    // props.policies
  };
  useEffect(() => {
    dispatch(fetchPrivacyPolicy(policies.url, {}));
  }, [policies]);

  console.log(policiesdata.data, "demo");

  return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
      <div className={styles.demo}>
        {divData &&
          divData.map((val, i) => (
            <div className={styles.demo_child_div} key={i}>
              <div
                className={policies.type === val.value ? styles.flex_start : ""}
              ></div>
              <Button
                value={policies.type}
                onClick={() => handleClick(val)}
                variant={policies.type === val.value ? "contained" : ""}
                fullWidth
                sx={{
                  ml: 1,
                  display: "flex",
                  justifyContent: "flex-start",
                  pl: 2,
                  mr: 1,
                  borderRadius: " 2.06016px 0px 0px 2.06016px",
                }}
              >
                <TranslateWrapper>{val.label}</TranslateWrapper>
              </Button>
            </div>
          ))}
      </div>
      </Grid>
      <Grid item xs={12} md={8}>
      {loader.loader ? (
        <div className={styles.loader}>
          <CircularLoader />
        </div>
      ) : policies.type === "shipping__policy" ? (
        <div className={styles.shipping__policy}>
          {policiesdata.data &&
            policiesdata.data.response.slice(0,1).map((val) => (
              <div className={styles.information__use}>
                {/* <p>
                  <Typography fontSize="1.1rem">
                    <i>
                      <b>{val.title}: </b>
                    </i>
                    {val.description}
                  </Typography>
                </p> */}
            
                <div className={styles.calendarHeading}>
                  <h4>{t("shipping_details")}</h4>
                </div>
                <p><b>{t("delivery_timeline")}:{" "}</b>
                    {t("delivery_timeline_desc")}</p>

                <p><b>{t("shipping_fees")}:{" "}</b>
                    {t("shipping_fees_desc")}
                </p>

                <p><b>{t("packaging")}:{" "}</b>
    {t("packaging_desc")}<br />
    <a href="#"  style={{color:"black"}} rel="noopener noreferrer">
    {t("visit_here")}
    </a>.
</p>

                <p><b>{t("customs_and_duties")}:{" "}
                      </b>{t("customs_and_duties_desc")}
                </p>

          
              </div>
            ))}
        </div>
      ) : policies.type === "return__policy" ? (
        <div className={styles.return__policy}>
          {policiesdata.data &&
            policiesdata.data.response.map((val) => (
              <div className={styles.information__use}>
                <div className={styles.calendarHeading}>
                  <p><TranslateWrapper>{val.title}: {val.description}</TranslateWrapper></p>
                </div>
                <div className={styles.calendarHeading}>
                  <h4 >
                    {t("return_guidelines")}
                  </h4>
                </div>
                <p><b>
                        {/* {val.title} */}
                        {t("return_period")}:{" "}
                      </b>
                    {/* {val.description} */}
                    {t("return_period_desc")}<br />
                    <a href="#"  style={{color:"black"}} rel="noopener noreferrer">
    {t("visit_here")}
    </a>.
                </p>

                {/* faq */}
                <div className={styles.calendarHeading}>
                  <h4>
                    {t("frequency_asked_questions")}
                  </h4>
                </div>
                <p>  <b>
                        {/* {val.title} */}1. {t("faq1")}?{" "}
                      </b>
                    {/* {val.description} */}
                 
                  {t("faq1_ans")}
                </p>

                <p>  <b>
                        {/* {val.title} */}2. {t("faq2")}?{" "}
                      </b>
                  {t("faq2_ans")}
                </p>

                <p> <b>
                        {/* {val.title} */}3. {t("faq3")}?{" "}
                      </b>
                  {t("faq3_ans")}
                </p>

                <p> <b>{/* {val.title} */}4. {t("faq4")}? </b>
                    
                    {t("faq4_ans")}
                </p>

                <p>   <b>
                        {/* {val.title} */}5. {t("faq5")}?{" "}
                      </b> 
                    {/* {val.description} */} 
                    {t("faq5_ans")}
                </p>

                <p>
                <b>
                        {/* {val.title} */}6. {t("faq6")}?{" "}
                      </b>
                    {/* {val.description} */}
                  {t("faq6_ans")}
                </p>

                <p>  <b>
                        {/* {val.title} */}7. {t("faq7")}?{" "}
                      </b>
                    {/* {val.description} */}
                  {t("faq7_ans")}
                </p>

                <p>
                      <b>
                        {/* {val.title} */}8. {t("faq8")}?{" "}
                      </b>
                    {/* {val.description} */}
                  {t("faq8_ans")}
                </p>
                {/* <Typography fontSize="1.1rem">
                <ul>
                  <li>personalize and imporove the Services;</li>
                  <li>
                    customize the Services and provide advertisements, content or
                    features that match your interests;
                  </li>
                  <li>allow our users to set up a user account and profile;</li>
                  <li>
                    communicate with you about or fulfil your requests for certain
                    products, services, offers, promotions and events offered by
                    Art Smiley;
                  </li>
                  <li>
                    send you notices, updates, and other administrative messages;
                  </li>
                  <li>process transactions and send you related information;</li>
                  <li>operate sweepstakes, contests or giveaways;</li>
                  <li>
                    link or combine with information we get from others to help
                    provide you with better service;
                  </li>
                  <li>
                    detect, investigate and/or prevent fraudulent, unauthorized or
                    illegal activity
                  </li>
                  <li>
                    analyze and monitor how users utilize the websites and
                    Services; and
                  </li>
                  <li>
                    carry out other purposes described in this Privacy Policy
                  </li>
                </ul>
              </Typography> */}
              </div>
            ))}
        </div>
      ) : (
        <div className={styles.faqs}>
          {policiesdata.data &&
            policiesdata.data.response.map((val) => (
              <div className={styles.information__use}>
                <p>
                      <b><TranslateWrapper>{val.title}</TranslateWrapper>: </b>
                    <TranslateWrapper>{val.description}</TranslateWrapper>
                  </p>
                <div className={styles.calendarHeading}>
                  <h4>
                    <TranslateWrapper>{val.title}</TranslateWrapper>
                  </h4>
                </div>
                <p>
                  <TranslateWrapper>{val.description}</TranslateWrapper>
                </p>

                {/* <Typography fontSize="1.1rem">
                    <ul>
                      <li>personalize and imporove the Services;</li>
                      <li>
                        customize the Services and provide advertisements, content or
                        features that match your interests;
                      </li>
                      <li>allow our users to set up a user account and profile;</li>
                      <li>
                        communicate with you about or fulfil your requests for certain
                        products, services, offers, promotions and events offered by
                        Art Smiley;
                      </li>
                      <li>
                        send you notices, updates, and other administrative messages;
                      </li>
                      <li>process transactions and send you related information;</li>
                      <li>operate sweepstakes, contests or giveaways;</li>
                      <li>
                        link or combine with information we get from others to help
                        provide you with better service;
                      </li>
                      <li>
                        detect, investigate and/or prevent fraudulent, unauthorized or
                        illegal activity
                      </li>
                      <li>
                        analyze and monitor how users utilize the websites and
                        Services; and
                      </li>
                      <li>
                        carry out other purposes described in this Privacy Policy
                      </li>
                    </ul>
                  </Typography> */}
              </div>
            ))}
        </div>
      )}
      </Grid>
    </Grid>
    <div className={styles.sidebar_flex}>
      

      
    </div>
    </>
    
  );
}

export default PolicySidebar;
