import React from 'react';

const Pagenotfound = () => {
    return (
        <div className='text-center'>
            {/* Page Not Found */}
            <img src="/img/error.png" className='img-fluid' alt=''/>
        </div>
    );
}

export default Pagenotfound;
