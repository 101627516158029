import React from "react";
import styles from "./TestimonialPopup.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Link from "@mui/material/Link";
import { RiCloseCircleFill } from "react-icons/ri";

const data = [
  {
    id: 1,
    user_title: "Username",
    user_val: "Artsmiley",
  },
  {
    id: 2,
    user_title: "Customer ID",
    user_val: "h3jh45",
  },
  {
    id: 3,
    user_title: "Buyer Name",
    user_val: "Smiley",
  },
  {
    id: 4,
    user_title: "Number of items",
    user_val: "878",
  },
  {
    id: 5,
    user_title: "Email Address",
    user_val: "jhe63hkj@gmail.com",
  },
];

export default function TestimonialPopup(props) {
  console.log(props.popupdata);
  const handleClose = () => props.setOpenPopUp(false);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.openPopup}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openPopup}>
          <Box className={styles.modal_del}>
            <div className={styles.clobtn}>
              <Link onClick={() => props.setOpenPopUp(false)}>
                <RiCloseCircleFill className={styles.iconru} />
              </Link>
            </div>
            <p className={styles.mpophndg} id="transition-modal-title">
              {props.popupdata?.name}
            </p>

            <div className={styles.data_div}>
              <p className={styles.odlist}>
                <span></span>{" "}
                {props?.popupdata?.description.replace(/<\/?p>/g, "")}
                <span>
                  {/* {props.popupdata?.first_name} {props.popupdata?.last_name} */}
                </span>
              </p>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
