import isEmpty from "./isEmpty";
import validator from 'validator'
const validationForThirdFormArt = (printType,uploadArt, type,props) => {
  const errors = {};

  if (type.sale) {
    if (!uploadArt.sale.qty || isNaN(Number(uploadArt.sale.qty))) {
      errors.saleQty = "Quantity in sale is required and must be a number";
    }
    
    if (!uploadArt.sale.price || isNaN(Number(uploadArt.sale.price))) {
      errors.salePrice = "Sale price is required and must be a number";
    }
   
    if(!uploadArt.sale.sale_weight || isNaN(Number(uploadArt.sale.sale_weight))) {
      errors.saleWeight = "Sale weight is required and must be a number";
    }
   
    if (!["Sculpture", "Crafts", "Installation"].includes(props.category)) {
      if (uploadArt.is_hanged === "No") {
        // Error is required only if tube_size is empty
        if (validator.isEmpty(uploadArt.tube_size)) {
          errors.tubeSize = "Tube size is required";
        }
      }
    } 




    if (uploadArt.is_hanged === "Yes") {
      if(!uploadArt.sale.package_type) {
        errors.packagetype = "Packaging type is required";
      }
      // if(!uploadArt.isframe === "Yes") {
      //   errors.saleisframe = "Frame color is required";
      // }
    }
    if (!uploadArt.sale.height || isNaN(Number(uploadArt.sale.height))) {
      errors.salepackageheight = "Sale height is required and must be a number";
    }
    if (!uploadArt.sale.width || isNaN(Number(uploadArt.sale.width))) {
      errors.salepackageweight = "Sale width is required and must be a number";
    }
    if (!uploadArt.sale.depth || isNaN(Number(uploadArt.sale.depth))) {
      errors.salepackagedepth = "Sale depth is required and must be a number";
    }
    
  }


 



  // Conditionally validate rent object if `type.rent` is true
  if (type.rent) {
    if (uploadArt.rent) {
      if (!uploadArt.rent.rent_price || isNaN(Number(uploadArt.rent.rent_price))) {
        errors.rentPrice = "Rent price is required and must be a number";
      }
  
      if (!uploadArt.rent.time || uploadArt.rent.time.trim() === "") {
        errors.rentTime = "Rent time is required";
      }
      if(!uploadArt.rent.rent_weight || isNaN(Number(uploadArt.rent.rent_weight))) {
        errors.rentWeight = "Rent weight is required and must be a number";
      }
      if (!["Sculpture", "Crafts", "Installation"].includes(props.category)) {
        if (uploadArt?.rent?.is_hanged === "No") {
          // Error is required only if tube_size is empty
          if (validator.isEmpty(uploadArt?.rent?.tube_size)) {
            errors.renttubeSize = "Tube size is required";
          }
        }
      } 



      if (uploadArt.rent?.is_hanged === "Yes") {
        if(!uploadArt.rent.package_type) {
          errors.rentpackagetype = "Packaging type is required";
        }
      }
      if (!uploadArt.rent.height || isNaN(Number(uploadArt.rent.height))) {
        errors.rentpackageheight = "rent height is required and must be a number";
      }
      if (!uploadArt.rent.width || isNaN(Number(uploadArt.rent.width))) {
        errors.rentpackageweight = "rent width is required and must be a number";
      }
      if (!uploadArt.rent.depth || isNaN(Number(uploadArt.rent.depth))) {
        errors.rentpackagedepth = "rent depth is required and must be a number";
      }
  
    }
    
  }

  
   // Validate print fields
   if (printType === "printbyme" && uploadArt.print) {
    uploadArt.print.forEach((printItem, index) => {
      if (printItem.is_print) {
        if (!printItem.material) {
          errors[`printMaterial${index}`] = "Material is required";
        }
  
        if (!printItem.qty || isNaN(Number(printItem.qty))) {
          errors[`printQty${index}`] = "Quantity is required and must be a number";
        }
  
        if (!printItem.size?.width || !printItem.size?.height) {
          errors[`printSize${index}`] = "Size is required";
        }
  
        if (!printItem.print_price || isNaN(Number(printItem.print_price))) {
          errors[`printPrice${index}`] = "Price is required and must be a number";
        }
  
        if (!printItem.print_weight || isNaN(Number(printItem.print_weight))) {
          errors[`printWeight${index}`] = "Weight is required and must be a number";
        }
  
        // if (printItem.is_hanged === "Yes" && !validator.isEmpty(printItem.package_type)) {
        //   errors[`printPackageType${index}`] = "Packaging type is required";
        // }
        if (!["Sculpture", "Crafts", "Installation"].includes(props.category)) {
          if (printItem.is_hanged === "No") {
            // Error is required only if tube_size is empty
            if (validator.isEmpty(printItem.tube_size)) {
              errors[`printTubeSize${index}`] = "Tube sizee is required";
            }
          }
        } else {
          // For categories other than Sculpture, Crafts, or Installation, no error is needed
          errors[`printTubeSize${index}`] = undefined; // Optional: Ensure no residual error remains
        }
        if (!["Sculpture", "Crafts", "Installation"].includes(props.category)) {
          if (printItem.is_hanged === "Yes") {        
            if (validator.isEmpty(printItem.package_type)) {
              errors[`printPackageType${index}`] = "Packaging type is required";
            }
          }
        } else {
          // For categories other than Sculpture, Crafts, or Installation, no error is needed
          errors[`printPackageType${index}`] = undefined; // Optional: Ensure no residual error remains
        }     
  
        if (printItem.is_frame === "Yes" && !printItem.frame_color) {
          errors[`printFrameColor${index}`] = "Frame color is required";
        } 

        if (!printItem.height || isNaN(Number(printItem.height))) {
          errors[`printpackageheight${index}`] = "Print height is required and must be a number";
        }
        if (!printItem.width || isNaN(Number(printItem.width))) {
          errors[`printpackageweight${index}`] = "Print width is required and must be a number";
        }
        if (!printItem.depth || isNaN(Number(printItem.depth))) {
          errors[`printpackagedepth${index}`] = "Print depth is required and must be a number";
        }

      }
     
    });
  }
  if(printType === "printbyartsmiley"){    
    if (!props?.uploadArt.printImage) {
      errors.openeditionimg= "Image is requred";
    }
  if (!props?.uploadArt.print_by_artsmiley_material || props.uploadArt.print_by_artsmiley_material.length === 0) {
    errors.material01 = "Select at least one item";
  }
  }
  return {
    thirdFormErrors: errors,
    isValidThirdForm: isEmpty(errors),
  };
};

export default validationForThirdFormArt;
