import validator from "validator"
import isEmpty from "./isEmpty"

export const validateEditOfferad = (offer) => {
  let offerErrors = {}

  
  if (validator.isEmpty(offer.name)) {
    offerErrors.name = "Offer name is required"
  }

 
  if (validator.isEmpty(offer.discount)) {
    offerErrors.discount = "Discount is required"
  } else if (!validator.isNumeric(offer.discount.toString())) {
    offerErrors.discount = "Discount must be a number"
  }

  
  if (validator.isEmpty(offer.discountType)) {
    offerErrors.discountType = "Discount type is required"
  }

  if (!offer.image) {
    offerErrors.image = "Offer image is required"
  }

  
  if (validator.isEmpty(offer.start_date)) {
    offerErrors.start_date = "Start date is required"
  }

 
  if (validator.isEmpty(offer.end_date)) {
    offerErrors.end_date = "End date is required"
  }

 
  if (validator.isEmpty(offer.description)) {
    offerErrors.description = "Description is required"
  }

  return {
    offerErrors,
    isValid: isEmpty(offerErrors),
  }
}
