import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const GalleryprofileSlice = createSlice({
    name: "galleryprofiledata",
    initialState,
    reducers: {
        setGalleryprofiledata: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setGalleryprofiledata } = GalleryprofileSlice.actions;

export const GalleryProfileData = (state) => state.galleryprofiledata;

export default GalleryprofileSlice.reducer;
