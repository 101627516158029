import React, { useState } from "react";
import { Link, Grid } from "@mui/material";
import styles from "./DisputeDetailsAdmin.module.css";
import { useNavigate } from "react-router-dom";
import serverimage from "../../../../../serverConstant";
import { IoIosArrowBack } from "react-icons/io";

export default function DisputeDetailsAdmin(props) {
  const navigate = useNavigate();
  console.log(props, "props--dispute");
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleProfilepage = (id) => {
    navigate(`/orders/${id}`);
  };
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div className={styles.mains}>
      <div className={styles.rbent}>
      <Link onClick={() => navigate("/dispute")} className={styles.backbn}> <IoIosArrowBack /> <span> Go Back</span></Link>
      </div>
      {/* <div
        className={styles.back_btn}
        onClick={() => navigate(`/dispute`)}
        style={{ cursor: "pointer" }}
      >
        <img src={"/img/_ Back.png"} width="46px" height="15px" />
      </div>
      <hr className={styles.hrs} /> */}
      {props.orderData &&
        props.orderData.map((val, i) => (
          <>
            <div className={styles.selector}>
            <h3 className={styles.main_heading}>{val.title}</h3>
              <div className={styles.gallery_status}>
              <p className={styles.odlist}>Status: <span className={styles.ga_status}>{val.dispute_status}</span></p>
                
              </div>
              {/* {props.type === "gallery" ? (
                <div className={styles.gallery_status}>
                  <h6>Status:</h6>
                  <p>Open</p>
                </div>
              ) : (
                <FormControl
                  sx={{
                    my: 1,
                    p: 0,
                    width: "120px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Select
                    value={age}
                    size="small"
                    sx={{
                      color: "#EE0909",
                      backgroundColor: "#f6f6f6",
                      borderRadius: "10px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        borderRadius: "10px",
                      },
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        borderRadius: "10px",
                      },
                      height: "46px",
                      display: "flex",
                      justifyContent: "center",
                      // textAlign:'right'
                    }}
                    align="right"
                    onChange={handleChange}
                    displayEmpty
                    IconComponent={() => (
                      <img
                        src={"/img/dropdownicon.png"}
                        width="13px"
                        height="10px"
                        style={{ marginRight: "20px", textAlign: "left" }}
                      />
                    )}
                  >
                    <MenuItem sx={{ color: "green" }} value="">
                      Open
                    </MenuItem>
                    <MenuItem sx={{ color: "green" }} value="">
                      Close
                    </MenuItem>
                  </Select>
                </FormControl>
              )} */}
            </div>

            <>
              <div className={styles.details}>
              <p className={styles.odlist}>Dispute ID : <span>{val._id}</span></p>
              <p className={styles.odlist}>Order ID : <span className={styles.trd}  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={() => handleProfilepage(val.order_id)}>
                  {val.order_id}
                </span></p>
              <p className={styles.odlist}>Description : <span>{val.description}</span></p>
              <p className={styles.odlist}>Payment : <span>{val.refund_status}</span></p>
              </div>
              
            </>
            <div className={styles.image_div}>
              <img src={` ${val.images}`} className="img-fluid" alt="" />
            </div>
          </>
        ))}
    </div>
  );
}
