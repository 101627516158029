import { style } from "@mui/system"
import React, { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchAffiliateData,
  fetchArtistData,
  fetchArtsData,
  fetchBuyerData,
  fetchCuratorData,
  fetchGalleryData,
} from "../../../../State/api/superadminApi"
import { setAffiliatedata } from "../../../../State/Features/superadmin/affiliateSlice"
import { setArtistdata } from "../../../../State/Features/superadmin/ArtistSlice"
import { setBuyerdata } from "../../../../State/Features/superadmin/buyerSlice"
import { setCuratordata } from "../../../../State/Features/superadmin/curatorSlice"
import { setGallery } from "../../../../State/Features/superadmin/gallerySlice"
import Pagination from "../Pagination/Pagination"
import Enterdetails from "../Popupdelete/enterDetails/Enterdetails"
import Moreoption from "../Table/Moreoption/Moreoption"
import Tableheader from "../Table/Tableheader/Tableheader"
import Tablethreecol from "../Table/Threecolmn/Tablethreecol"
import styles from "./Account.module.css"

const moreOptions = [
  {
    id: "buyer",
    label: "Buyer",
    value: "buyer",
  },
  {
    id: "curator",
    label: "Curator",
    value: "curator",
  },
  {
    id: "affiliate",
    label: "Affiliate",
    value: "affiliate",
  },
  {
    id: "artist",
    label: "Artists",
    value: "artist",
  },
  {
    id: "gallery",
    label: "Gallery",
    value: "gallery",
  },
]

const upcominghead = [
  {
    id: "like",
    label: "Artist Name",
  },
  {
    id: "date",
    label: "Created Date",
  },
  {
    id: "followe",
    label: "Mobile No",
  },
  {
    id: "location",
    label: "Email",
  },
]


const sortdata = [
  { label: "By Name ↓", value: "low" },
  { label: "By Name ↑", value: "high" },
]
const filterdata = [
  { label: "old to new ", value: "old" },
  { label: "new to old ", value: "new" },
]
export default function Account() {
  const [button, setButton] = useState({ type: "add", text: "Create" })
  const [activetab, setAcitvetab] = useState("buyer")
  const [open, setOpen] = useState(false)
  const [data, setData] = useState({ offset: 1, limit: 20 })
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })

  const dispatch = useDispatch()
  const buyerdt = useSelector((state) => state.buyerData.data)
  const artistdt = useSelector((state) => state.artistdata.data)
  const curatordt = useSelector((state) => state.curatorData.data)
  const affiliatedt = useSelector((state) => state.affiliatedata.data)
  const gallerydt = useSelector((state) => state.galleryData.data)

 
  
  useEffect(() => {
    dispatch(fetchArtistData(data))
    dispatch(fetchAffiliateData(data))
    dispatch(fetchBuyerData(data))
    dispatch(fetchCuratorData(data))
    dispatch(fetchGalleryData(data))
  }, [data])

  const handleMorecategories = (e) => {
    setAcitvetab(e)
  }
  const handleFilter = (event) => {
    const value = event.target.value
    if(value === "old"){
      setCondition({ ...condition, filter: value })
      dispatch(
        setBuyerdata({
          data:
            buyerdt.data &&
            [...buyerdt.data].sort((a, b) => {
              return new Date(a.createdAt) - new Date(b.createdAt)
            }),
          totalCount: buyerdt.totalCount,
        })
      )
      dispatch(
        setArtistdata({
          data:
            artistdt.data &&
            [...artistdt.data].sort((a, b) => {
              return new Date(a.createdAt) - new Date(b.createdAt)
            }),
          totalCount: artistdt.totalCount,
        })
      )
      dispatch(
        setAffiliatedata({
          data:
            affiliatedt.data &&
            [...affiliatedt.data].sort((a, b) => {
              return new Date(a.createdAt) - new Date(b.createdAt)
            }),
          totalCount: affiliatedt.totalCount,
        })
      )
      dispatch(
        setCuratordata({
          data:
            curatordt.data &&
            [...curatordt.data].sort((a, b) => {
              return new Date(a.createdAt) - new Date(b.createdAt)
            }),
          totalCount: curatordt.totalCount,
        })
      )
      dispatch(
        setGallery({
          data:
            gallerydt.data &&
            [...gallerydt.data].sort((a, b) => {
              return new Date(a.createdAt) - new Date(b.createdAt)
            }),
          totalCount: gallerydt.totalCount,
        })
      )
    }else if (value === "new"){
      setCondition({ ...condition, sorting: value })
      dispatch(
        setBuyerdata({
          data:
            buyerdt.data &&
            [...buyerdt.data].sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt)
            }),
          totalCount: buyerdt.totalCount,
        })
      )
      dispatch(
        setArtistdata({
          data:
            artistdt.data &&
            [...artistdt.data].sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt)
            }),
          totalCount: artistdt.totalCount,
        })
      )
      dispatch(
        setAffiliatedata({
          data:
            affiliatedt.data &&
            [...affiliatedt.data].sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt)
            }),
          totalCount: affiliatedt.totalCount,
        })
      )
      dispatch(
        setCuratordata({
          data:
            curatordt.data &&
            [...curatordt.data].sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt)
            }),
          totalCount: curatordt.totalCount,
        })
      )
      dispatch(
        setGallery({
          data:
            gallerydt.data &&
            [...gallerydt.data].sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt)
            }),
          totalCount: gallerydt.totalCount,
        })
      )
    }else {
      dispatch(fetchArtistData(data))
      dispatch(fetchAffiliateData(data))
      dispatch(fetchGalleryData(data))
      dispatch(fetchCuratorData(data))
      dispatch(fetchBuyerData(data))
      setCondition({ ...condition, sorting: value })
    }
   
  }
  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }
  const handleSorting = (e) => {
    let value = e.target.value
    if (value === "low") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setBuyerdata({
          data:
            buyerdt.data &&
            [...buyerdt.data].sort((a, b) => {
              if (
                a.first_name.toLowerCase() + a.last_name.toLowerCase() <
                b.first_name.toLowerCase() + b.first_name.toLowerCase()
              )
                return -1
            }),
          totalCount: buyerdt.totalCount,
        })
      )
      dispatch(
        setArtistdata({
          data:
            artistdt.data &&
            [...artistdt.data].sort((a, b) => {
              if (
                a.first_name.toLowerCase() + a.last_name.toLowerCase() <
                b.first_name.toLowerCase() + b.first_name.toLowerCase()
              )
                return -1
            }),
          totalCount: artistdt.totalCount,
        })
      )
      dispatch(
        setAffiliatedata({
          data:
            affiliatedt.data &&
            [...affiliatedt.data].sort((a, b) => {
              if (
                a.first_name.toLowerCase() + a.last_name.toLowerCase() <
                b.first_name.toLowerCase() + b.first_name.toLowerCase()
              )
                return -1
            }),
          totalCount: affiliatedt.totalCount,
        })
      )
      dispatch(
        setCuratordata({
          data:
            curatordt.data &&
            [...curatordt.data].sort((a, b) => {
              if (
                a.first_name.toLowerCase() + a.last_name.toLowerCase() <
                b.first_name.toLowerCase() + b.first_name.toLowerCase()
              )
                return -1
            }),
          totalCount: curatordt.totalCount,
        })
      )
      dispatch(
        setGallery({
          data:
            gallerydt.data &&
            [...gallerydt.data].sort((a, b) => {
              if (
                a.first_name.toLowerCase() + a.last_name.toLowerCase() <
                b.first_name.toLowerCase() + b.first_name.toLowerCase()
              )
                return -1
            }),
          totalCount: gallerydt.totalCount,
        })
      )
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setBuyerdata({
          data:
            buyerdt.data &&
            [...buyerdt.data].sort((a, b) => {
              if (
                a.first_name.toLowerCase() + a.last_name.toLowerCase() >
                b.first_name.toLowerCase() + b.first_name.toLowerCase()
              )
                return -1
            }),
          totalCount: buyerdt.totalCount,
        })
      )
      dispatch(
        setArtistdata({
          data:
            artistdt.data &&
            [...artistdt.data].sort((a, b) => {
              if (
                a.first_name.toLowerCase() + a.last_name.toLowerCase() >
                b.first_name.toLowerCase() + b.first_name.toLowerCase()
              )
                return -1
            }),
          totalCount: artistdt.totalCount,
        })
      )
      dispatch(
        setAffiliatedata({
          data:
            affiliatedt.data &&
            [...affiliatedt.data].sort((a, b) => {
              if (
                a.first_name.toLowerCase() + a.last_name.toLowerCase() >
                b.first_name.toLowerCase() + b.first_name.toLowerCase()
              )
                return -1
            }),
          totalCount: affiliatedt.totalCount,
        })
      )
      dispatch(
        setCuratordata({
          data:
            curatordt.data &&
            [...curatordt.data].sort((a, b) => {
              if (
                a.first_name.toLowerCase() + a.last_name.toLowerCase() >
                b.first_name.toLowerCase() + b.first_name.toLowerCase()
              )
                return -1
            }),
          totalCount: curatordt.totalCount,
        })
      )
      dispatch(
        setGallery({
          data:
            gallerydt.data &&
            [...gallerydt.data].sort((a, b) => {
              if (
                a.first_name.toLowerCase() + a.last_name.toLowerCase() >
                b.first_name.toLowerCase() + b.first_name.toLowerCase()
              )
                return -1
            }),
          totalCount: gallerydt.totalCount,
        })
      )
    } else {
      dispatch(fetchArtistData(data))
      dispatch(fetchAffiliateData(data))
      dispatch(fetchGalleryData(data))
      dispatch(fetchCuratorData(data))
      dispatch(fetchBuyerData(data))
      setCondition({ ...condition, sorting: value })
    }
  }


  return (
    <div className={styles.mains}>
      {open ? (
        <Enterdetails poptype="account" open={open} setOpen={setOpen} />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          setOpen={setOpen}
          // button={button}
        />
        <Moreoption
          handleMorecategories={handleMorecategories}
          activetab={activetab}
          setAcitvetab={setAcitvetab}
          moreOptions={moreOptions}
          setData={setData}
        />
        {activetab === "buyer" ? (
          <>
            <Tablethreecol
              activetab={activetab}
              superadmin={"addcolaccount"}
              head={upcominghead}
              data={
                buyerdt &&
                buyerdt.data.filter((val) =>
                  (
                    val.first_name.toLowerCase() +
                    " " +
                    val.last_name.toLowerCase()
                  ).includes(condition.search.toLowerCase())
                )
              }
            />
            <Pagination
              totaldata={buyerdt && buyerdt.totalCount}
              pagination={data}
              setPagination={setData}
            />
          </>
        ) : activetab === "curator" ? (
          <>
            <Tablethreecol
              activetab={activetab}
              superadmin={"addcolaccount"}
              head={upcominghead}
              data={
                curatordt &&
                curatordt.data.filter((val) =>
                  (
                    val.first_name.toLowerCase() +
                    " " +
                    val.last_name.toLowerCase()
                  ).includes(condition.search.toLowerCase())
                )
              }
            />
            <Pagination
              totaldata={curatordt && curatordt.totalCount}
              pagination={data}
              setPagination={setData}
            />
          </>
        ) : activetab === "affiliate" ? (
          <>
            <Tablethreecol
              activetab={activetab}
              superadmin={"addcolaccount"}
              head={upcominghead}
              data={
                affiliatedt &&
                affiliatedt.data.filter((val) =>
                  (
                    val.first_name.toLowerCase() +
                    " " +
                    val.last_name.toLowerCase()
                  ).includes(condition.search.toLowerCase())
                )
              }
            />
            <Pagination
              totaldata={affiliatedt && affiliatedt.totalCount}
              pagination={data}
              setPagination={setData}
            />
          </>
        ) : activetab === "artist" ? (
          <>
            <Tablethreecol
              activetab={activetab}
              superadmin={"addcolaccount"}
              head={upcominghead}
              data={
                artistdt &&
                artistdt.data.filter((val) =>
                  (
                    val.first_name.toLowerCase() +
                    " " +
                    val.last_name.toLowerCase()
                  ).includes(condition.search.toLowerCase())
                )
              }
            />
            <Pagination
              totaldata={artistdt && artistdt.totalCount}
              pagination={data}
              setPagination={setData}
            />
          </>
        ) : (
          <>
            <Tablethreecol
              activetab={activetab}
              superadmin={"addcolaccount"}
              head={upcominghead}
              data={
                gallerydt &&
                gallerydt.data.filter((val) =>
                  (
                    val.first_name.toLowerCase() +
                    " " +
                    val.last_name.toLowerCase()
                  ).includes(condition.search.toLowerCase())
                )
              }
            />
            <Pagination
              totaldata={gallerydt && gallerydt.totalCount}
              pagination={data}
              setPagination={setData}
            />
          </>
        )}
        {}
      </div>
    </div>
  )
}
